<template>
  <div>
    <v-overlay :value="open" color="rgba(5, 32, 46, 0.5)" />
    <v-dialog
      :value="open"
      width="350px"
      hide-overlay
      persistent
      content-class="dashboard-filter-editor"
    >
      <div
        class="dashboard-filter-editor__title w-100 d-flex align-center justify-space-between"
      >
        {{ title }}
        <v-icon class="hover" @click="$emit('cancel')" :size="20">
          mdi-close
        </v-icon>
      </div>

      <div class="dashboard-filter-editor__content text-sm">
        <slot />
      </div>

      <div
        class="dashboard-filter-editor__actions d-flex justify-space-between"
      >
        <div />
        <div>
          <v-btn
            outlined
            @click.native="$emit('cancel')"
            :disabled="disabled"
            class="mr-2"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn
            color="error"
            @click.native="$emit('confirm')"
            :loading="loading"
            :disabled="disabled"
          >
            {{ confirmText }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'ConfirmDialog',
  props: {
    open: { type: Boolean, default: false },
    title: { type: String, default: '' },
    cancelText: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    cancel: { type: Function, default: () => {} },
    confirm: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  computed: {
  },

  methods: {
  }

}

</script>

<i18n locale="en" src="@/i18n/en/pages/Codingv2.json" />
<i18n locale="de" src="@/i18n/de/pages/Codingv2.json" />