<template>
  <div class="main-content" ref="main-content" id="subscription">
    <v-alert v-if="!$hasPermission('subscription_mgmt')"
             type="error"
             border="left"
             prominent
             text
    >
      <span v-html="$t('permission.no_permission', { email: user.organization.root_user_email })" />
    </v-alert>
    <loading v-else
             :is-loading="loading"
             :title="$t('checkout.loading_title')"
             :tagline="$t('checkout.loading_tagline')"
    >
      <page-header>
        <template slot="info">
          <span v-html="$t('subscription_info')" /><br>
          <span v-html="$t('subscription_helptip')" />
        </template>
        <template slot="headline">
          <span v-text="
            success ?
              readyForCheckout ? $t('success.title_purchase') : $t('success.title_cancel') :
              $t('change_subscription')"
          />
        </template>
        <template slot="subtitle" v-if="!success">
          <span v-html="$t(`current_plan_${getSubscriptionRefillInterval(user.subscription.credit_refill_interval_months)}`, {
            plan: user.subscription.plan_name,
            credits_recurring: user.credits_remaining_recurring,
            credits_oneoff: user.credits_remaining_oneoff
          })"
          />
        </template>

        <template slot="page-buttons">
          <v-btn outlined
                 color="warning"
                 exact
                 v-if="!success && !user.subscription.will_end && user.subscription.plan_id !== PLAN_FREE"
                 @click="cancel = true"
          >
            {{ $t('cancel_subscription.title') }}
          </v-btn>
        </template>

        <template slot="navigation-buttons">
          <v-btn outlined
                 color="green"
                 exact
                 v-if="success"
                 :to="{ name: 'projects-manage' }"
          >
            <v-icon left>
              mdi-file-multiple
            </v-icon>
            {{ $t('menu.projects_group') }}
          </v-btn>

          <v-btn outlined
                 color="green"
                 exact
                 :to="{ name: 'account' }"
          >
            <v-icon left>
              mdi-account-box
            </v-icon>
            {{ $t('menu.profile') }}
          </v-btn>
        </template>
      </page-header>

      <!-- ====== SUCCESS ====== -->
      <v-alert prominent
               type="success"
               outlined
               text
               border="left"
               v-if="success"
      >
        <div class="title" v-text="readyForCheckout ? $t('success.subtitle_purchase') : $t('success.subtitle_cancel')" />
        <span v-if="oneOff"
              v-html="$t('success.text_credits_filled', {
                credits: oneOffCreditsDisplayed
              })"
        />
        <span v-else-if="cancel"
              v-html="$t('success.text_cancel', {
                end: $options.filters.dateshort(user.subscription.end),
                plan: user.subscription.plan_name
              })"
        />
        <span v-else
              v-html="$t('success.text_subscription_paid', {
                plan: planFeatures[plan].name,
                interval: $t(`success.interval_${payment.yearly ? 'year' : 'month'}`)
              })"
        />
      </v-alert>

      <!-- ====== COUPON ====== -->
      <v-alert prominent
               type="success"
               outlined
               text
               border="left"
               icon="mdi-percent-outline"
               v-if="!success && !cancel && initialLoad && coupon !== null"
      >
        <span v-html="$t('coupon_loaded', {
          name: coupon.name
        })"
        />
      </v-alert>

      <!-- ====== PLAN SELECTION ====== -->
      <div class="basket-container" v-if="!success && !cancel && initialLoad">
        <div class="base-plan">
          <div class="yearly-switch">
            <v-switch v-model="payment.yearly"
                      hide-details
                      color="green"
                      :disabled="loadingCheckout"
                      :label="$t('switch_yearly')"
            />
          </div>
          <div class="title">
            <helptip :width="400">
              <span v-html="$t('subscription_helptip')" />
            </helptip>
          </div>

          <div class="plan-container">
            <v-card class="plan selectable"
                    v-for="planID in PLANS_ACTIVE"
                    v-if="initialLoad"
                    :key="planID"
                    :class="{ 'current': user.subscription.plan_id === planID && plan === -1, 'selected': plan === planID }"
                    @click="selectPlan(planID)"
            >
              <v-card-title>
                <div class="title">
                  {{ planFeatures[planID].name }}
                  <span v-if="user.subscription.plan_id === planID">({{ $t('current') }})</span>
                </div>
                <v-divider />
                <div class="price" v-if="planID !== 134">
                  <div class="yearly" v-if="payment.yearly">
                    <span class="no-discount">
                      €{{ planFeatures[planID].price_monthly * 12 }}
                    </span>
                    <span class="discounted">
                      €{{ planFeatures[planID].price_monthly * 11 }} {{ $t('price_yearly') }}
                    </span>
                  </div>
                  <template v-else>
                    €{{ planFeatures[planID].price_monthly }} {{ $t('price_monthly') }}
                  </template>
                </div>
                <div v-else class="price">
                  On Request
                </div>
              </v-card-title>
              <v-card-text>
                <div class="previous-plus" v-if="previousPlus[planID]">
                  {{ $t('previous_plus', { previous: previousPlus[planID] }) }}:
                </div>
                <div class="credits">
                  {{ planFeatures[planID].credits_recurring }} {{ $t(`credits_${getSubscriptionRefillInterval(planFeatures[planID].credit_refill_interval_months)}`) }}
                  <helptip :width="400" position="top">
                    <div v-html="$t(`credits_${getSubscriptionRefillInterval(planFeatures[planID].credit_refill_interval_months)}_helptip`)" /><br>
                    <div v-html="$t('credits_helptip')" />
                  </helptip>
                </div>

                <plan-features :plan-id="planID" :show-plan-features="showPlanFeatures">
                  <v-icon slot="icon-feature">
                    mdi-check
                  </v-icon>
                  <v-icon slot="icon-account">
                    mdi-account
                  </v-icon>
                  <v-icon slot="icon-support">
                    mdi-face-agent
                  </v-icon>
                  <v-icon slot="icon-admin">
                    mdi-credit-card-outline
                  </v-icon>
                  <v-divider slot="header-account" />
                  <v-divider slot="header-support" />
                  <v-divider slot="header-admin" />
                </plan-features>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <!-- ====== One off BOX ====== -->
        <div class="one-off">
          <div class="title">
            <helptip :width="650" x-offset="-200">
              <span v-html="$t('oneoff_helptip')" />
            </helptip>
          </div>

          <v-card class="plan" :class="{ selected: oneOff }">
            <v-card-title>
              {{ $t('oneoff_title') }}
              <v-divider />
              <div class="price" v-text="$t('oneoff_subtitle')" />
            </v-card-title>

            <v-card-text>
              <v-slider v-model="oneOffCreditsSlider"
                        :max="oneoffCreditsSliderMax"
                        :min="0"
                        :disabled="loadingCheckout"
                        hide-details
              />

              <div class="results">
                <div class="title n-credits">
                  {{ oneOffCreditsDisplayed }} credits
                </div>
                <div class="title price">
                  €{{ oneOffPrice }} <span>(€{{ oneOffPricePerCredit }} / {{ $t('credit.item') }})</span>
                </div>
              </div>

              <v-btn outlined
                     color="primary"
                     v-text="$t('select_package')"
                     :disabled="loadingCheckout"
                     @click="buyOneoff"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!-- ====== cancel ====== -->
      <v-alert prominent
               type="warning"
               class="flex-center"
               outlined
               text
               border="left"
               v-if="cancel && !success"
      >
        <div style="flex: 1">
          <div class="title" v-text="$t('cancel_subscription.subtitle')" />
          <div v-html="$t('cancel_subscription.text', {
            plan: user.subscription.plan_name,
            date: nextCancellationDate
          })"
          />
          <div v-html="$t('cancel_subscription.text_pricing_change')" />
        </div>

        <div>
          <v-spacer />
          <v-btn outlined color="secondary" @click="cancel = false" style="margin-right: 8px">
            {{ $t('back') }}
          </v-btn>
          <v-btn color="accent" @click="submitCheckout">
            {{ $t('cancel_subscription.confirm') }}
          </v-btn>
        </div>
      </v-alert>

      <!-- ====== CHECKOUT ====== -->
      <v-card class="checkout" v-if="readyForCheckout && !success">
        <v-card-title>
          <div>
            <div class="title">
              {{ $t('checkout.title') }}
            </div>
            <div class="grey--text">
              {{ $t('checkout.subtitle') }}
            </div>
          </div>
        </v-card-title>

        <alert type="error" v-if="stripeScriptIsLoading || stripePaymentMethods.failed">
          {{ $t('checkout.pre_payment_issue') }}
        </alert>

        <v-alert v-if="checkoutError"
                 type="error"
                 border="left"
                 prominent
                 text
        >
          <span v-html="checkoutError" />
        </v-alert>

        <v-divider />
        <v-card-text v-if="!stripeScriptIsLoading && !stripePaymentMethods.failed">
          <div class="section order-summary" data-private>
            <div class="title grey--text">
              {{ $t('checkout.order') }}
            </div>
            <div class="section-content">
              <!-- PRODUCT -->
              <div class="products">
                <span class="item-descr item-name">
                  {{ oneOff ?
                    `${oneOffCreditsDisplayed} ${$t('oneoff_title')}` :
                    planFeatures[plan].name + ' ' + $t('subscription_title') }}
                </span>
                <strong>€{{ amountOrder | round(2, true) }}</strong>
                {{ $t(`checkout.recurrence.${oneOff ? 'oneoff' : payment.yearly ? 'yearly' : 'monthly'}`) }}
              </div>

              <!-- DISCOUNT -->
              <div v-if="coupon !== null">
                <span class="item-descr">{{ $t('checkout.discount.prefix') }}: {{ coupon.name }}</span>
                <strong>€{{ amountDiscount | round(2, true) }}*</strong>
                <div style="font-size: small; font-style: italic" v-if="!oneOff">
                  *{{ $t('checkout.discount.applies_to', {
                    duration: $t(`checkout.discount.duration.${coupon.duration}`, { months: coupon.duration_in_months })
                  }) }}
                </div>
              </div>

              <!-- VAT -->
              <div><span class="item-descr">{{ $t('checkout.vat') }}</span> <strong>€{{ amountVAT | round(2, true) }}</strong></div>

              <v-divider />
              <div>
                <span class="item-descr body-2" style="font-size: large">Total</span>
                <span class="body-2" style="font-size: large">€{{ amountTotal | round(2, true) }}</span>
              </div>
              <span style="font-size: small"
                    v-html="$t(`checkout.auto_renewal.${oneOff ? 'oneoff' : payment.yearly ? 'yearly' : 'monthly'}`)"
              />
            </div>
          </div>
          <v-divider />
          <div class="section">
            <div class="title grey--text">
              {{ $t('checkout.billing') }}
            </div>
            <div class="section-content">
              <div class="form-row">
                <!-- First Name -->
                <v-text-field v-model="address.first_name"
                              maxlength="30"
                              data-private
                              counter
                              :disabled="!$root.isRootAccount || loadingCheckout"
                              :label="$t('profile.settings.first_name')"
                />

                <!-- Last Name -->
                <v-text-field v-if="$root.isRootAccount"
                              v-model="address.last_name"
                              maxlength="30"
                              data-private
                              counter
                              :disabled="!$root.isRootAccount || loadingCheckout"
                              :label="$t('profile.settings.last_name')"
                />
              </div>

              <div class="form-row">
                <v-text-field v-model="address.company_name"
                              maxlength="100"
                              counter
                              data-private
                              :disabled="!$root.isRootAccount || loadingCheckout"
                              :label="$t('profile.settings.company_name')"
                              :error-messages="addressError.company_name"
                />

                <v-text-field v-model="address.billing_email"
                              data-private
                              :disabled="!$root.isRootAccount || loadingCheckout"
                              :placeholder="user.email"
                              :label="$t('profile.settings.billing_email')"
                              :error-messages="addressError.billing_email"
                />
              </div>

              <!-- Street -->
              <v-textarea v-model="address.address_street"
                          maxlength="255"
                          rows="2"
                          data-private
                          counter
                          :disabled="!$root.isRootAccount || loadingCheckout"
                          :label="$t('profile.settings.address')"
              />

              <!-- ZIP -->
              <div class="form-row">
                <!-- City -->
                <v-text-field v-model="address.address_city"
                              maxlength="50"
                              counter
                              :disabled="!$root.isRootAccount || loadingCheckout"
                              data-private
                              :label="$t('profile.settings.city')"
                />

                <v-text-field v-model="address.address_zip"
                              required
                              maxlength="20"
                              :label="$t('profile.settings.zip')"
                              :disabled="!$root.isRootAccount || loadingCheckout"
                              data-private
                              :error-messages="addressError.address_zip"
                />
              </div>

              <!-- Country -->
              <div class="form-row">
                <v-autocomplete v-model="address.address_country"
                                required
                                :label="$t('profile.settings.country')"
                                :items="countries"
                                item-text="name"
                                item-value="iso"
                                :disabled="!$root.isRootAccount || loadingCheckout"
                                data-private
                                :error-messages="addressError.address_country"
                />

                <!-- Customer Reference -->
                <v-text-field v-model="customer_reference"
                              required
                              maxlength="30"
                              :label="$t('checkout.customer_reference')"
                              :disabled="loadingCheckout"
                              data-private
                />
              </div>
            </div>
          </div>
          <v-divider />
          <div class="section">
            <div class="title grey--text">
              {{ $t('checkout.payment') }}
            </div>
            <div class="section-content">
              <div>
                <v-radio-group v-model="payment.method" :disabled="loadingCheckout" data-private>
                  <div v-for="card in stripePaymentMethods.cards" :key="card.id" style="display: flex">
                    <v-radio :value="card.id"
                             label=""
                             hide-details
                    />
                    <span class="cc-container">
                      <label class="v-label" @click="payment.method = card.id">
                        {{ $t('checkout.use_card') }}
                        <span class="credit-card">
                          {{ card.brand }}
                          **** {{ card.last4 }},
                          {{ $t('valid_until') }}
                          {{ card.exp_month }} / {{ card.exp_year }}
                        </span>
                      </label>
                      <a href=""
                         v-if="stripeCardsRemovable"
                         @click.prevent.stop="openRemovePaymentMethod(card.id)"
                         :disabled="loadingCheckout"
                      >
                        <small>({{ $t('remove_card.action') }})</small>
                      </a>
                    </span>
                  </div>
                  <v-radio :value="false" :label="$t('checkout.use_other_card')" />
                  <v-tooltip :disabled="canPayByInvoice" top>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-radio value="invoice"
                                 :label="$t('checkout.invoice_payment.radio')"
                                 v-on="on"
                                 :disabled="!canPayByInvoice"
                        />
                      </div>
                    </template>
                    <span v-html="$t('checkout.invoice_payment.disabled_tooltip')" />
                  </v-tooltip>
                </v-radio-group>
              </div>
            </div>
          </div>

          <v-divider />
          <alert type="warning" v-if="zipInvalid || countryInvalid">
            {{ $t('checkout.validate.invalid') }}
          </alert>
          <div class="section">
            <div class="title grey--text" />
            <div class="section-content submit">
              <v-btn color="primary"
                     :loading="loadingCheckout"
                     @click="checkout"
              >
                {{ $t('checkout.confirm') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </loading>

    <v-dialog v-model="removePaymentMethod.active" max-width="600" @keydown.esc="closeRemovePaymentMethod(false)">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div class="title">
              {{ $t('remove_card.dialog_title') }}
            </div>
          </div>
        </v-card-title>

        <v-card-text v-html="$t('remove_card.dialog_content', { card: removePaymentMethod.label })" />

        <v-card-actions>
          <v-spacer />
          <v-btn outlined color="primary" @click="closeRemovePaymentMethod(false)">
            {{ $t('no') }}
          </v-btn>
          <v-btn color="primary" @click="closeRemovePaymentMethod(true)">
            {{ $t('yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <stripe-payment-method v-model="showAddPaymentMethod"
                           v-if="!stripeScriptIsLoading"
                           @added="addedPaymentMethodLocal"
                           @cancelled="loadingCheckout = false"
                           close-on-success
    />
  </div>
</template>

<script>
import Vuex from 'vuex'
import pricing from '@/mixins/pricing.js'
import stripePayment from '@/mixins/stripePayment.js'

import StripePaymentMethod from '@/components/StripePaymentMethod'
import PlanFeatures from '@/components/PlanFeatures'
import axios from 'axios'
import { overridePropsIfExistRecursive } from '@/utils/funcs'

import {
  PLAN_FREE, PLAN_ONEOFF, PLANS_ACTIVE, MEETING_LINK
} from '@/settings/constants'

export default {
  codit: true,
  name: 'ChangeSubscription',
  components: {
    'stripe-payment-method': StripePaymentMethod,
    'plan-features': PlanFeatures
  },

  mixins: [stripePayment, pricing],
  data () {
    return {
      // Initial interval to check if user has loaded
      checkGetUserInterval: null,

      // Array of valid countries
      countries: [],

      // Checkout process user data
      plan: -1,
      loading: false,
      loadingCheckout: false,
      initialLoad: false,
      payment: { method: false, is_new: false, yearly: false },
      address: {
        first_name: '',
        last_name: '',
        billing_email: '',
        company_name: '',
        address_street: '',
        address_city: '',
        address_zip: '',
        address_country: ''
      },
      zipInvalid: '',
      countryInvalid: '',
      addressError: {},
      customer_reference: '',
      success: false,
      checkoutError: '',

      oneOff: false,
      cancel: false,

      // Information about the different plans
      planFeatures: {},
      showAddPaymentMethod: false,

      invoiceReview: {
        loading: false,
        success: false
      },

      coupon: null,

      CREDIT_PRICES: [],
      PLAN_FREE,
      PLANS_ACTIVE
    }
  },
  computed: {
    /**
     * The date as formatted string, when the next cancellation is possible
     * @return {String}
     */
    nextCancellationDate () {
      return this.$options.filters.dateshort(this.$moment(this.user.subscription.next_renewal_datetime).subtract(1, 'day'))
    },

    /**
     * If the current selection is valid for checkout. True if
     * @return {Boolean}
     */
    readyForCheckout () {
      return (this.plan !== -1 || this.oneOff) && !this.cancel
    },

    /**
     * The total amount of the current checkout process, without VAT
     * @return {Number}
     */
    amountOrder () {
      if (this.oneOff) return this.oneOffPrice
      else {
        let priceMonthly = this.planFeatures[this.plan].price_monthly
        if (this.payment.yearly) return priceMonthly * 11
        else return priceMonthly
      }
    },

    /**
     * If the user can bay by invoice
     * @return {Boolean}
     */
    canPayByInvoice () {
      if (this.oneOff) return this.user.subscription.features.invoice_payment
      else return this.planFeatures[this.plan].invoice_payment
    },

    amountDiscount () {
      if (this.coupon === null) return 0
      else if (this.coupon.amount_off !== null) return this.coupon.amount_off / 100
      else if (this.coupon.percent_off !== null) return Math.round(this.coupon.percent_off * this.amountOrder) / 100
      else throw Error(`Coupon nod handled: ${this.coupon.id}`)
    },

    /**
     * The amount of VAT added to the order
     * @return {Number}
     */
    amountVAT () {
      if (this.address.address_country !== 'CH') return 0
      else return Math.max(Math.round((this.amountOrder - this.amountDiscount) * 7.7) / 100, 0)
    },

    /**
     * The total order amount, including VAT
     * @return {Number}
     */
    amountTotal () { return Math.max(this.amountOrder - this.amountDiscount + this.amountVAT, 0) },

    ...Vuex.mapState(['user'])
  },

  watch: {
    /**
     * When the user clicks on a valid plan or one-off option, scroll down to make him see the checkout form
     */
    plan: 'scrollDown',
    oneOff: 'scrollDown',

    /**
     * Set the yearly payment according to the interval of the current subscription
     */
    'user.subscription.yearly_contract': {
      immediate: true,
      handler (val) { this.payment.yearly = val }
    },

    /**
     * When the payment methods are loaded, set the first one as default method for checkout
     */
    'stripePaymentMethods.cards' (val) {
      // If there already is payment info saved, use that by default
      if (!this.payment.method && val.length) this.payment.method = val[0].id
    }
  },

  created () {
    // Copy the address from the user
    overridePropsIfExistRecursive(this.address, this.user)
    if (!this.$root.isRootAccount) this.address.first_name = this.user.organization.root_user_name

    // Get the list of valid countries
    let r1 = api.get('/api/constants/countries').then(res => this.$set(this, 'countries', res.data))
    let r2 = api.get('/api/plans').then(res => this.$set(this, 'planFeatures', _.keyBy(res.data, 'id')))
    let r3 = api.get('/api/constants/credit-pricing').then(res => this.$set(this, 'CREDIT_PRICES', res.data))

    if ('coupon' in this.$route.query) this.getCoupon(this.$route.query.coupon)

    axios.all([r1, r2, r3]).then(() => {
      this.initialLoad = true
      this.parseQueryParams()
    })
  },

  methods: {
    /**
     * Check if there are parameters given in through the URL query, such as the plan
     * if yes, check their validity and set them accordingly
     */
    parseQueryParams () {
      let queryPlan = _.toNumber(this.$route.query.plan)
      if (PLANS_ACTIVE.indexOf(queryPlan) !== -1) this.plan = queryPlan
      if ('yearly' in this.$route.query) this.payment.yearly = true
    },

    /**
     * Get the given coupon and set it as local property
     * @param  {String} coupon The coupon ID
     */
    getCoupon (coupon) {
      api.get(`/api/subscription/coupons/${coupon}`, { dontReport: [404] }).then((res) => {
        if (res.status === 200) this.$set(this, 'coupon', res.data)
        else throw Error(`Get coupon returned failed, status=${res.status}, data=${JSON.stringify(res.data)}`)
      }).catch(err => {
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Scroll down a bit, to make checkout form visible
     */
    scrollDown () {
      this.$nextTick(() => this.$vuetify.goTo(1000, { duration: 300 }))
    },

    /**
     * Select a plan for checkout
     * @param  {Number} plan The plan ID
     */
    selectPlan (plan) {
      if (this.loadingCheckout) return
      if (plan === 134) {
        window.open(MEETING_LINK, '_blank').focus()
        return
      }
      let isNewPlan = this.user.subscription.plan_id !== plan
      let changedBillingPeriod = this.user.subscription.yearly_contract !== this.payment.yearly
      // The plan can be selected, if either
      // * It is not the same plan as before
      // * It is not the same billing period as before
      // * The plan is currently canceled
      if (isNewPlan || changedBillingPeriod || this.user.subscription.will_end) this.plan = plan
      else this.plan = -1
      this.oneOff = false
    },

    /**
     * Buy a one-off package
     */
    buyOneoff () {
      this.oneOff = true
      this.plan = -1
    },

    /**
     * Save the address the user entered in the checkout form
     */
    saveAddress () {
      return this.$store.dispatch('updateUser', this.address).then(res => {
        if (res.status !== 200) {
          this.$set(this, 'addressError', { ...res.data })
          throw Error('Invalid address data: ' + JSON.stringify(res.data))
        }
      })
    },

    /**
     * Initiate the checkout process
     */
    checkout () {
      // Validate input
      if (this.address.zip < 100) {
        this.zipInvalid = this.$t('checkout.validate.zip_invalid')
        return
      }
      if (this.address.country === '') {
        this.countryInvalid = this.$t('checkout.validate.country_invalid')
        return
      }

      this.zipInvalid = ''
      this.countryInvalid = ''

      // First update the user if the address has changed
      this.loadingCheckout = true
      if (this.$root.isRootAccount) {
        this.saveAddress().then(this.checkPaymentMethod).catch(err => {
          this.loadingCheckout = false
          console.error(err)
        })
      } else this.checkPaymentMethod()
    },

    /**
     * See if the user has selected "new card" in checkout. If yes, open the add payment dialog
     */
    checkPaymentMethod () {
      // If it's a new card, open the show add payment method dialog
      if (this.payment.method === false) this.showAddPaymentMethod = true
      // If it's not a new card, directly go to checkout
      else this.submitCheckout()
    },

    /**
     * Callback when a new payment method has been added: Set it as the currently valid payment method
     * @param  {String} pm The id of the new payment method
     */
    addedPaymentMethodLocal (pm) {
      // Call the added payment method from the mixin
      this.addedPaymentMethod(pm)
      this.payment.method = pm.id
      this.submitCheckout()
    },

    /**
     * If the user wants to pay by invoice, send that request
     */
    requestInvoiceReview () {
      this.invoiceReview.loading = true
      this.saveAddress().then(() => {
        api.post('/api/subscription/request-invoice-review').then(() => {
          this.invoiceReview.loading = false
          this.invoiceReview.success = true
        }).catch(err => {
          this.invoiceReview.loading = false
          this.$maybeRaiseAPIPromiseErr(err)
        })
      })
    },

    /**
     * Actually submit the checkout request
     */
    submitCheckout () {
      this.loadingCheckout = false
      this.checkoutError = ''
      this.loading = true

      api.post('/api/subscription/checkout', {
        plan: this.oneOff ? PLAN_ONEOFF : this.cancel ? PLAN_FREE : this.plan,
        n_credits: this.oneOff ? this.oneOffCredits : -1,
        payment: this.payment,
        address: this.address,
        customer_reference: this.customer_reference,
        coupon: this.coupon !== null ? this.coupon.id : null
      }, { dontReport: [400] }).then((res) => {
        this.loading = false
        if (res.status === 200 && res.data.success === true) {
          this.success = true
          // Update the profile with the new credit & subscription status
          let properties = {
            credits_remaining_recurring: res.data.credits_remaining_recurring,
            credits_remaining_oneoff: res.data.credits_remaining_oneoff,
            dontPatch: true
          }
          if (!this.oneOff) properties.subscription = res.data.subscription
          this.$store.dispatch('updateUser', properties)
        } else if ('card_error' in res.data) {
          this.checkoutError = res.data.card_error
        } else throw Error('Failed to process checkout.')
      }).catch(err => {
        this.loading = false
        this.checkoutError = this.$t('checkout.error')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    getSubscriptionRefillInterval (refillIntervalMonths) {
      if (refillIntervalMonths === 12) return 'yearly'
      else if (refillIntervalMonths === 1) return 'monthly'
      else throw Error(`Unsupported credit refill interval ${refillIntervalMonths}`)
    }
  }
}

</script>

<style lang=scss>

#subscription {
  .v-card .title { margin-bottom: 0 }
  .v-card .grey--text {
    font-size: 14px;
    line-height: 1.2rem;
  }
  .basket-container {
    display: flex;
    margin-bottom: 32px;

    .base-plan, .one-off {
      > .title {
        margin-bottom: 15px;
        font-style: italic;
        text-align: center;
        .helptip {
          font-style: normal;
          font-weight: normal;
          .anchor .mdi {
            font-size: inherit!important;
            line-height: 1.2;
            color: var(--v-primary-base);
          }
        }
      }
    }

    .base-plan {
      flex: 5;
      margin-right: 16px;
      padding-right: 16px;
      border-right: 1px solid rgba(0,0,0,.12);
      position: relative;
      padding-bottom: 32px;
    }

    .yearly-switch {
      position: absolute;
      bottom: -20px;
      left: -20px;
      width: 100%;
      justify-content: center;
      display: flex;
    }

    .one-off {
      flex: 2;
    }
  }

  .credit-card {
    padding: 6px 5px 4px;
    margin: 0 8px;
    background: #FFF;
    border-radius: 3px;
    font-family: "Consolas", "Courier New", "Courier";
  }

  .plan-container {
    display: flex;
    position: relative;

    .alert-info {
      position: absolute;
      bottom: 0px;
      left: 24px;
      right: 24px;
    }
  }

  .plan {
    flex: 1;
    transition: all 0.2s ease-out;
    box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);
    border: 2px solid $border-color;

    margin-right: 18px;
    overflow: visible;

    &:focus::before {
      opacity: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.highlight {
      background: #fbddad;
    }

    .v-card__title {
      background: #EEE;
      display: block;
      text-align: center;
      transition: background 0.2s ease-out;

      .title {
        color: #0e5d88;
        transition: color 0.2s ease-out;
      }
      .price {
        font-size: 14px;
        line-height: 14px;
        margin-top: 2px;
        color: #424242;

        .yearly {
          .no-discount {
            text-decoration: line-through;
            margin-right: 6px;
            font-size: smaller;
          }
          .discounted {
            color: var(--v-green-base);
          }
        }
      }
    }

    .v-card__text {
      padding-top: 18px;
      padding-bottom: 42px;
    }

    .name { text-transform: uppercase; }

    .overlay-subscribe {
      transition: opacity 0.2s ease-out;
      position: absolute;
      left: 0;
      bottom: 5px;
      right: 0;
      font-size: larger;
      text-align: center;
      vertical-align: middle;
      color: #FFF;
      opacity: 0;
      font-weight: bold;
      text-transform: uppercase;
    }
    &.selectable:hover:not(.current)  {
      z-index: 1;
      transform: scale(1.03, 1.03);
      .v-card__title {
        background: #E9FCF7;
        .title { color: var(--v-green-base); }
      }
    }

    &.selected {
      border: 2px solid var(--v-primary-base);
    }

    &.selected, &.current {
      .v-card__title {
        background: rgba(14, 93, 136, 0.1);
      }
    }

    &:hover:not(.current) .overlay-subscribe {
      opacity: 1;
    }

    .v-divider {
      margin: 3px 0 8px;
    }

    .v-card__text .v-divider {
      margin: 12px 0;
    }

    .credits {
      color: #424242;
      font-weight: bold;
      text-align: center;
      font-size: smaller;
      margin: 5px 0 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .helptip {
        font-weight: normal;
      }
    }

    .previous-plus {
      color: var(--v-primary-base);
      font-weight: bold;
      margin-bottom: 12px;
      font-style: italic;
    }

    .feature {
      display: flex;
      .v-icon {
        margin-right: 10px;
        opacity: 0.5;
      }
      .helptip {
        margin-left: 4px;
        .anchor {
          color: #666;
        }
      }
    }

    .results {
      background: rgba(14, 93, 136, 0.1);
      padding: 8px;
      border-radius: 8px;
      color: #424242;
      font-weight: bold;
      width: 220px;
      margin: 0 auto;
      text-align: center;
      .n-credits {
        color: var(--v-primary-base);
      }
      .price {
        margin-top: 8px;
        font-weight: normal;
        font-weight: bold;
        font-size: 1rem!important;
        line-height: 1.2rem!important;
        > span {
          display: block;
          font-weight: normal;
        }
      }
    }
    .v-btn {
      width: 220px;
      margin: 28px auto 0;
      display: block;
    }
  }

  .checkout {
    > .v-alert {
      margin: 8px 16px;
    }
    .section {
      display: flex;
      .title { flex: 0 0 250px; }
      .section-content {
        border-radius: 4px;
        flex: 1 0 auto;
        max-width: 700px;
        background: #EEE;
        padding: 8px 16px;
        .cc-container {
          color: rgba(0,0,0,.6);
          label { cursor: pointer }
        }
        &.submit {
          background: none;
          display: flex;
          .v-button { flex: 1; }
        }

        .form-row {
          display: flex;
          .v-input { flex: 1; }
          &:not(.one-el) .v-input:first-child { margin-right: 16px; }
        }

        .one-el {
          justify-content: flex-end;
          .v-input { flex: 0 0 calc(50% - 8px) }
        }
      }

      &.order-summary .section-content {
        .products {
          font-size: larger;
          color: var(--v-primary-base);
          margin-bottom: 8px;
        }
      }
    }

    .item-descr {
      display: inline-block;
      width: 300px;
    }

    .item-name {
      font-weight: bold;
    }

    .v-divider {
      margin: 10px 0 14px;
    }
  }
}

</style>
<i18n locale='en' src='@/i18n/en/Subscription.json' />
<i18n locale='de' src='@/i18n/de/Subscription.json' />
<i18n locale='en' src='@/i18n/en/pages/Account.json' />
<i18n locale='de' src='@/i18n/de/pages/Account.json' />
