<template>
  <div class="main-content uspg" ref="main-content">
    <!-- ====== Step: UPLOAD ANSWERS ====== -->
    <loading v-model="loading.percentage"
             :is-loading="loading.active && !project.name.length"
             :dont-animate-entry="true"
             :title="$t('loading.title')"
             :tagline="loading.eta"
    >
      <v-alert v-if="loading.failed !== null"
               prominent
               type="error"
               border="left"
               text
      >
        {{ $t(loading.failed) }}
      </v-alert>
      <!--
      <template v-if="done.yes">
        <v-alert text
                 prominent
                 type="success"
                 border="left"
        >
          <div v-html="$t('auxiliaries.success.msg')" />
          <div v-if="done.stats.changed.length">
            {{ $t('auxiliaries.success.stats.charts_changed') }}:&nbsp; <b>{{ $_.map(done.stats.changed, 'name').join(', ') }}</b>
          </div>
          <div v-if="done.stats.removed.length">
            {{ $t('auxiliaries.success.stats.charts_removed') }}:&nbsp;
            <ul style="position: relative">
              <li v-for="(c, cIdx) in done.stats.removed" :key="cIdx">
                <router-link :to="{ name: 'chart-details', params: { id: c.id } }"
                             target="_blank"
                             v-text="c.name"
                />
              </li>
            </ul>
          </div>
        </v-alert>
      </template> -->

      <div v-else>
        <div class="progress-bar" />

        <v-tabs
          v-model="step"
          class="upload-tabs"
          background-color="white"
          :ripple="false"
          grow
        >
          <v-tab
            v-for="(item, index) in steps"
            :ripple="false"
            :key="item"
            :disabled="index + 1 > step"
          >
            {{ index + 1 + '. ' + $t(`steps.${item}.label`) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="step">
          <v-tab-item
            key="upload"
            :transition="false"
            :reverse-transition="false"
          >
            <step-upload :replace="true" @next="goToOrganize" />
            <div class="upload-footer pl-4 pr-4 default-bs">
              <v-btn
                class="upload-footer__back"
                color="white"
                @click="$router.go(-1)"
                x-large
                elevation="0"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                :disabled="!validReplaceUpload"
                :loading="loading.active"
                @click="goToOrganize"
                color="primary"
                x-large
                class="default-bs"
              >
                {{ $t('steps.upload.append_next') }}
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item
            key="organize"
            :transition="false"
            :reverse-transition="false"
          >
            <div class="d-flex flex-column align-center">
              <step-organize :replace="true" />
              <div class="upload-footer pl-4 pr-4 default-bs">
                <v-btn
                  @click="goToUpload"
                  class="upload-footer__back"
                  color="white"
                  x-large
                  elevation="0"
                >
                  {{ $t('back') }}
                </v-btn>
                <v-btn
                  :disabled="!validReplace"
                  :loading="loading.updatingActive || loading.active"
                  @click="updateUpload"
                  color="primary"
                  x-large
                  class="default-bs"
                >
                  {{ $t('steps.replace.next') }}
                </v-btn>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <v-dialog
          v-model="showConfirm"
          @keydown.esc="() => showConfirm = false"
          width="500px"
        >
          <v-card class="chart-download-dialog d-flex">
            <v-card-title primary-title class="headline grey lighten-4">
              {{ $t('steps.replace.confirm.title') }}
            </v-card-title>
            <v-card-text class="pt-2 pb-2">
              {{ $t('steps.replace.confirm.text_changed') }}:
              <br>
              {{ replaceChartsChanged.join(', ') }}
            </v-card-text>
            <v-card-text class="pt-2 pb-2">
              {{ $t('steps.replace.confirm.text_removed') }}:
              <br>
              {{ replaceChartsRemoved.join(', ') }}
            </v-card-text>
            <v-card-actions>
              <div class="d-flex justify-end w-100">
                <v-btn
                  outlined
                  @click="() => showConfirm = false"
                  class="mr-2"
                >
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="confirmChangeAndReplace"
                  :loading="loading.updatingActive"
                >
                  {{ $t('steps.replace.confirm.confirm') }}
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </loading>
  </div>
</template>

<script>

import Vuex from 'vuex'
import StepUpload from '@/components/upload/StepUpload'
import StepOrganize from '@/components/upload/StepOrganize'
import ApiTaskMixin from '@/mixins/apitask'

export default {
  codit: true,
  name: 'Replace',
  components: {
    'step-upload': StepUpload,
    'step-organize': StepOrganize
  },
  mixins: [ApiTaskMixin],

  /**
   * Warn about losing changes when going to another page (in the app)
   */
  beforeRouteLeave (to, from, next) {
    let doIt = true
    // If there is an uploaded file, confirm navigating away
    if (this.answersInput.file.length && !to.params.skipWarning) doIt = confirm(this.$t('steps.upload.confirm_navigate_away'))
    next(doIt)
  },

  data () {
    return {
      step: 0,
      showConfirm: false
    }
  },

  computed: {
    /* Returns form steps */
    steps () {
      return ['upload', 'replace']
    },

    /**
     * Returns changed charts from replacing
     * @return {Array}
     */
    replaceChartsChanged () {
      return _.map(this.replacing.ds_modified_stats.changed, chart => chart.name)
    },

    /**
     * Returns removed charts from replacing
     * @return {Array}
     */
    replaceChartsRemoved () {
      return _.map(this.replacing.ds_modified_stats.removed, chart => chart.name)
    },

    /**
     * Checks if replacing columns will change chart data
     * @return {Boolean}
     */
    modifiedDs () {
      return this.replaceChartsChanged.length + this.replaceChartsRemoved.length
    },

    ...Vuex.mapState({
      user: 'user',
      loading: state => state.upload.loading,
      answersInput: state => state.upload.answersInput,
      replacing: state => state.upload.replacing,
      project: state => state.upload.project
    }),

    ...Vuex.mapGetters([
      'userCreditsRemaining',
      'validAppend',
      'validReplaceUpload',
      'validReplace',
      'validUpload',
      'validOrganize'
    ])
  },

  mounted () {
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },

  created () {
    let id = this.$route.params.id

    this.$store.commit('setReplace', true)

    this.$store.dispatch('fetchProject', id)
      .then(project => {
        // this.$set(this, 'project', {
        //   ...res.data,
        //   nRows: res.data.questions.length ? res.data.questions[0].nanswers : 0,
        //   auxColumnsSet: new Set(res.data.auxiliary_column_names)
        // })

        this.$store.commit('setBreadcrumbProps', {
          projectID: project.id,
          projectName: project.name
        })
      })
      .catch(err => this.$maybeRaiseAPIPromiseErr(err))
  },

  methods: {
    /**
     * Return true if there might be unsaved changes and that the user should be warned thorugh an alert
     * @param  {Object} $event The navigate away event
     * @return {Boolean}
     */
    beforeUnload ($event) {
      if (this.answersInput.file.length) $event.returnValue = true
    },

    /**
     * Go to upload step
     */
    goToUpload () {
      this.step = 0
    },

    /**
     * Go to organize step
     */
    goToOrganize () {
      this.step = 1
    },

    /**
    * Update the upload then replace the columns
    */
    updateUpload () {
      this.$store.dispatch('updateUpload', () =>
        // Dry run
        this.$store.dispatch('replaceColumns')
          .then(() => {
            if (!this.modifiedDs) {
              this.finalReplace()
            } else {
              this.showConfirm = true
            }
          })
          .catch(err => this.$maybeRaiseAPIPromiseErr(err))
      )
    },

    /**
     * Confirm chart modifications / deletions and replace columns
     */
    confirmChangeAndReplace () {
      this.showConfirm = false
      this.finalReplace()
    },

    /**
     * Final call to api for replacement without dry run
     */
    finalReplace () {
      this.$store.dispatch('replaceColumnsAsync', this.asyncCb)
    },

    /**
     * Update loading status
     */
    updateCb (eta, percentage) {
      this.$store.commit('setLoading', { key: 'eta', value: eta })
      this.$store.commit('setLoading', { key: 'percentage', value: percentage })
    },

    asyncCb (res) {
      console.log(res, res.headers['task-id'])

      this.showConfirm = false

      this.getApiTaskStatus(res.headers['task-id'], parseFloat(res.headers['task-eta']), this.updateCb)
        .then(() => this.afterSave(res.data.id))
        .catch(err => {
          this.$store.commit('setLoading', { key: 'failed', value: 'error_replacing_columns' })
          this.$store.commit('setLoading', { key: 'active', value: false })

          throw err
        })
    },

    /**
     * Redirect after project save
    */
    afterSave (projectid) {
      this.$root.snackMsg(this.$t('steps.replace.snack_success'))
      this.$router.push({ name: 'projects-manage-id', params: { id: projectid, skipWarning: true } })
    }
  }
}

</script>

<style lang=scss>
  @import '../css/upload.scss';
</style>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />
<i18n locale='en' src='@/i18n/en/AppPage.json' />
<i18n locale='de' src='@/i18n/de/AppPage.json' />
