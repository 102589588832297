<template>
  <div>
    <loading
      v-if="loading"
      class="h-100 position-relative"
      :is-loading="true"
      title=""
      tagline=""
      dont-animate-entry
    />
    <empty-state-v2
      v-else-if="!loading && !templates.length && !failed"
      :title="$t('no_templates')"
      text=" "
    />
    <error-state
      v-else-if="failed && !loading"
      title="Data fetching error"
      text="There was an error fetching templates, please try again."
      :action="$t('retry')"
      :action-click="() => getTemplates()"
    />
    <div
      v-else
      class="coding__column__options coding__column__options--topic-search d-flex"
      :class="!selectedTemplate && 'mb-0'"
    >
      <div class="mr-4">
        <label class="font-weight-medium text-sm text-label">
          Language
        </label>
        <v-select
          v-model="language"
          :items="templateLanguageOptions"
          class="search-bar__search search-bar__search--large text-sm"
          placeholder="Select language"
          hide-details
          dense
          :height="40"
          outlined
          :menu-props="{
            'offset-y': true,
            'ripple': false
          }"
        />
      </div>
      <div>
        <label class="font-weight-medium text-sm text-label">
          Template
        </label>
        <v-select
          v-model="selectedTemplate"
          @input="handleTemplateSelect"
          :items="templateOptions"
          :no-data-text="$t('question_steps.choose_language')"
          class="search-bar__search search-bar__search--large text-sm"
          placeholder="Select template"
          hide-details
          dense
          :height="40"
          outlined
          :menu-props="{
            'offset-y': true,
            'ripple': false
          }"
        />
      </div>
    </div>
    <topics-editor
      v-if="selectedTemplate"
      :active="true"
      :editable="false"
      :id="id"
      store-name="wizard"
      history
      wizard-view
      template-select
    />
  </div>
</template>

<script>

import Vuex from 'vuex'
import TopicsEditor from '@/components/TopicsEditor'

export default {
  codit: true,
  name: 'TemplateSelector',
  components: {
    TopicsEditor
  },

  mixins: [],

  props: {
    id: { type: [String, undefined], default: undefined }
  },

  data () {
    return {
      loading: true,
      failed: false,
      templates: [],
      language: '',
      selectedTemplate: null
    }
  },

  computed: {
    ...Vuex.mapState({
      topics: state => state.wizard.topics,
      'languageOptions': 'languageOptions'
    }),

    /**
     * List of possible templates
     */
    templateOptions () {
      const languageFiltered = _.filter(this.templates, { language: this.language })

      return _.map(languageFiltered, ({ name }) => name)
    },

    /**
     * The languages available in the list of templates for use in v-select
     * @return {Array} List of iso language topics and their name {value,text}
     */
    templateLanguageOptions () {
      return _.map(_.uniq(_.map(this.templates, 'language')), v => ({ value: v, text: this.languageOptions.iso[v] }))
    }
  },

  watch: {
  },

  created () {
    this.getTemplates()
  },

  methods: {
    /**
     * Handle template selection by user
     */
    handleTemplateSelect (value) {
      const topics = _.find(this.templates, ({ name }) => name === value).topics
      this.$store.commit('setTopics', topics)
    },

    /**
     * Load the available topic templates
     */
    getTemplates () {
      this.failed = false
      this.loading = true

      api.get('/api/ui/projects/topic-templates/').then((res) => {
        this.loading = false
        let templates = res.data
        if (!Array.isArray(templates)) throw Error('Invalid return data')
        // Make sure topics of a category are displayed together
        templates.forEach(t => {
          t.topics = _(t.topics).groupBy('category').flatMap().value()
        })
        this.templates = templates
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<style lang="scss">
  @import '~css/filters';

  div.v-input__icon.v-input__icon--append {
    margin-right: 0 !important;
  }
</style>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />