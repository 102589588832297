import Vue from 'vue'

const store = {
  namespaced: true,

  state () {
    return {}
  },

  mutations: {
    register (state, { questionId, questionData }) {
      Vue.set(state, questionId, questionData)
    }
  },

  actions: {
    async register ({ state, commit, dispatch, rootState }, { entityId, questionIds }) {
      if (!rootState.coding.registerManager.entities[entityId]) return

      const list = [...new Set(questionIds)].filter(id => !state[id])
      const questionData = await Promise.all(
        list.map(questionId => {
          return dispatch('fetch', { questionId })
        })
      )
      list.forEach((questionId, index) => commit('register', { questionId, questionData: questionData[index] }))
    },

    async fetch ({ commit, dispatch }, { questionId }) {
      let res
      try {
        res = await api.get(`/api/questions/${questionId}`)
      } catch (error) {
        this._vm.$maybeRaiseAPIPromiseErr(error)
        throw error
      }
      return _.pick(res.data, ['project_name', 'name', 'inherits_from', 'inherits_from_name'])
    }
  },

  getters: {
    overall: (state, getters) => { return _.cloneDeep(state) },

    state: (state, getters) => (id) => {
      return state[id]
    }
  }
}

export default store