export default {
  data () {
    return {
      internalValue: this.value,
      isUpdating: false
    }
  },
  watch: {
    internalValue: {
      deep: true,
      handler () {
        if (this.isUpdating) {
          return
        }
        this.$emit('input', _.cloneDeep(this.internalValue))
      }
    },
    value: {
      deep: true,
      handler () {
        this.isUpdating = true
        if (
          Array.isArray(this.value)
        ) {
          this.internalValue = _.cloneDeep(this.value)
        } else {
          this.internalValue = this.value
        }
        this.$nextTick(() => {
          this.isUpdating = false
        })
      }
    }
  }
}