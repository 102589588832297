import Vue from 'vue'

const basicSettings = {
  method: 'post'
}

const isChartCheck = entityData => ['P_TXT', 'P_VRB'].indexOf(entityData.type) === -1
const filterChartItems = list => list.filter(item => isChartCheck(item.entityData))

export default {
  namespaced: true,

  state () {
    return {
      entities: {}
    }
  },

  mutations: {
    register (state, { entityId, settings = {}, extraQueryParams }) {
      Vue.set(state.entities, entityId, {
        ...basicSettings,
        ...settings,
        extraQueryParams
      })
    },

    identify (state, { entityIdToSet, entityIdToRemove, settings = {} }) {
      Vue.set(state.entities, entityIdToSet, {
        ...state.entities[entityIdToRemove],
        ...settings
      })
      Vue.delete(state.entities, entityIdToRemove)
    },

    update (state, { entityId, settings = {}, extraQueryParams }) {
      Vue.set(state.entities, entityId, {
        ...state.entities[entityId],
        ...{
          ...settings,
          extraQueryParams
        }
      })
    },

    delete (state, { entityId }) {
      Vue.delete(state.entities, entityId)
    }
  },

  actions: {
    async register ({ commit, dispatch }, { entityId, entityData, extraQueryParams, settings }) {
      const id = entityId || `ch__${+new Date(entityData.created)}`
      const chartUpdated = {
        entityId: id,
        entityData,
        extraQueryParams
      }
      const questionIds = entityData.datasets?.map(({ question }) => question)

      commit('register', { entityId: id, extraQueryParams, settings })
      if (
        entityData.type !== 'P_TXT'
      ) {
        await dispatch('metaManager/register', chartUpdated, { root: true })
      }
      await dispatch('configManager/register', chartUpdated, { root: true })
      await dispatch('verbatimManager/register', chartUpdated, { root: true })
      if (
        questionIds && this._actions['questionManager/register']
      ) {
        await dispatch('questionManager/register', { entityId: id, questionIds }, { root: true })
      }

      if (
        isChartCheck(chartUpdated.entityData) && this._actions['valueManager/update']
      ) {
        await dispatch('valueManager/update', chartUpdated, { root: true })
      }

      return id
    },
    async registerMany ({ commit, dispatch }, { list, extraQueryParams }) {
      list.forEach(({ entityId, entityData, settings }) => {
        commit('register', { entityId, extraQueryParams, settings })
      })

      await dispatch('metaManager/registerMany', { list: list.filter(({ type }) => type !== 'P_TXT'), extraQueryParams }, { root: true })
      await dispatch('configManager/registerMany', { list }, { root: true })
      await dispatch('verbatimManager/registerMany', { list }, { root: true })

      if (
        this._actions['valueManager/registerMany']
      ) {
        await dispatch('valueManager/registerMany', {
          list: filterChartItems(list)
        }, { root: true })
      }
      // @sasha TODO questionManager/registerMany
    },

    identify ({ commit, dispatch }, { entityId, entityIdOld, entityData, settings }) {
      const entityIdToSet = entityId
      const entityIdToRemove = entityIdOld || `ch__${+new Date(entityData.created)}`

      commit('identify', { entityIdToSet, entityIdToRemove, settings })
      dispatch('configManager/identify', { entityIdToSet, entityIdToRemove }, { root: true })
      dispatch('metaManager/identify', { entityIdToSet, entityIdToRemove }, { root: true })
      dispatch('verbatimManager/identify', { entityIdToSet, entityIdToRemove }, { root: true })
    },

    identifyMany ({ commit, dispatch }, { list }) {
      list.forEach(({ entityId, entityData }) => dispatch('identify', { entityId, entityData }))
    },

    async update ({ commit, dispatch }, { entityId, entityData, settings, extraQueryParams }) {
      const id = entityId || `ch__${+new Date(entityData.created)}`
      const chartUpdated = {
        entityId: id,
        entityData,
        extraQueryParams
      }
      const questionIds = entityData.datasets?.map(({ question }) => question)

      commit('update', { entityId, settings, extraQueryParams })
      if (
        entityData.type !== 'P_TXT'
      ) {
        await dispatch('metaManager/update', chartUpdated, { root: true })
      }
      await dispatch('configManager/update', chartUpdated, { root: true })

      if (
        questionIds && this._actions['questionManager/register']
      ) {
        await dispatch('questionManager/register', { entityId: id, questionIds }, { root: true })
      }

      if (
        isChartCheck(chartUpdated.entityData) && this._actions['valueManager/update']
      ) {
        await dispatch('valueManager/update', chartUpdated, { root: true })
      }

      return id
    },

    async updateMany ({ commit, dispatch }, { list, extraQueryParams }) {
      list.forEach(({ entityId, entityData }) => dispatch('update', { entityId, entityData, extraQueryParams }))
    },

    async delete ({ commit, dispatch }, { entityId }) {
      await commit('delete', { entityId })
      await dispatch('configManager/delete', { entityId }, { root: true })
      await dispatch('metaManager/delete', { entityId }, { root: true })
      await dispatch('verbatimManager/delete', { entityId }, { root: true })
    },

    async deleteMany ({ dispatch }, { list }) {
      list.forEach((entityId) => dispatch('delete', { entityId }))
    }
  },

  getters: {
    overall: (state, getters) => { return _.cloneDeep(state.entities) },

    state: (state, getters) => (id) => {
      return state.entities[id]
    },

    isReady: (state, getters, rootGetters) => (id) => {
      const root = rootGetters.coding || rootGetters
      return !!(
        root.configManager[id] &&
        root.metaManager[id] &&
        root.verbatimManager[id]
      )
    }
  }
}
