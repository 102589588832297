<template>
  <div>
    <v-alert v-if="failed"
             prominent
             type="error"
             outlined
             text
             border="left"
             class="flex-center"
    >
      {{ $t('error', { 'step': this.$t('loading.while') }) }}

      <div class="spacer" />

      <v-btn color="accent" outlined @click="loadTransactions">
        {{ $t('actions.retry') }}
      </v-btn>
    </v-alert>

    <v-data-table :headers="headers"
                  :items="transactions"
                  :items-per-page="15"
                  :loading="loading"
                  class="elevation-1"
    >
      <template v-slot:header.payer_name="{ header }">
        {{ header.text }}
        <helptip :width="500" position="bottom">
          <span v-html="$t('helptip.payer')" />
        </helptip>
      </template>

      <template v-slot:header.user_name="{ header }">
        {{ header.text }}
        <helptip :width="500" position="bottom">
          <span v-html="$t('helptip.user')" />
        </helptip>
      </template>

      <template v-slot:header.amount_recurring="{ header }">
        <span v-html="header.text" />
        <helptip :width="500" position="bottom">
          <span v-html="$t('helptip.recurring')" />
        </helptip>
      </template>

      <template v-slot:header.amount_oneoff="{ header }">
        <span v-html="header.text" />
        <helptip :width="500" position="bottom">
          <span v-html="$t('helptip.oneoff')" />
        </helptip>
      </template>

      <template v-slot:item.timestamp="{ item }">
        {{ item.timestamp | datetimeshort }}
      </template>

      <template v-slot:item.payer_name="{ item }">
        <a v-if="item.payer_id !== '1'"
           :href="`mailto:${item.payer_email}`"
           v-text="item.payer_name"
           class="tooltip tooltip-top"
           :data-tooltip="item.payer_email"
        />
        <span v-else v-text="item.payer_name" />
      </template>

      <template v-slot:item.user_name="{ item }">
        <a :href="`mailto:${item.user_email}`"
           v-text="item.user_name"
           class="tooltip tooltip-top"
           :data-tooltip="item.user_email"
        />
      </template>

      <template v-slot:item.amount_recurring="{ item }">
        {{ item.amount_recurring }} <em>({{ item.balance_recurring === null ? '?' : item.balance_recurring }})</em>
      </template>

      <template v-slot:item.amount_oneoff="{ item }">
        {{ item.amount_oneoff }} <em>({{ item.balance_oneoff === null ? '?' : item.balance_oneoff }})</em>
      </template>

      <template v-slot:item.transaction_type="{ item }">
        <span v-if="!(item.transaction_type === 'UC' && item.description)"
              v-html="$t(`transaction_types.${item.transaction_type}`)"
        />
        <router-link v-if="item.question !== null"
                     :to="{ name: 'question-cockpit', params: { id: item.question.id } }"
                     v-text="`${item.question.name} | ${item.question.project_name}`"
        />
        <span class="deleted" v-else-if="item.description" v-text="item.description" />
      </template>

      <empty-state slot="no-data"
                   icon="mdi-circle-multiple-outline"
                   :label="$t('no_transactions')"
      />
    </v-data-table>

    <div style="display: flex; margin-top: 20px; color: rgba(0,0,0,.6)">
      <v-spacer />
      {{ $t('download_as') }}&nbsp;
      <a :href="`${downloadLink}?format=csv`" target="_blank">CSV</a>&nbsp;|&nbsp;
      <a :href="`${downloadLink}?format=json`" target="_blank">JSON</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CreditTransactions',
  codit: true,

  data () {
    return {
      transactions: [],
      loading: true,
      failed: false,
      headers: [
        { text: this.$t('header.timestamp'), value: 'timestamp' },
        { text: this.$t('header.payer'), value: 'payer_name' },
        { text: this.$t('header.user'), value: 'user_name' },
        { text: this.$t('header.recurring'), value: 'amount_recurring', align: 'end' },
        { text: this.$t('header.oneoff'), value: 'amount_oneoff', align: 'end' },
        { text: this.$t('header.transaction_type'), value: 'transaction_type' }
      ]
    }
  },

  computed: {
    /**
     * The base link of the credit transactions (format not specified)
     * @return {String}
     */
    downloadLink () {
      return `${window.API_HTTP_BASE_URI}/api/auth/user/credit-transactions`
    }
  },

  created () {
    this.loadTransactions()
  },

  methods: {
    /**
     * Load the credit transactions that involve the current user as user or payer
     */
    loadTransactions () {
      this.failed = false
      this.loading = true
      api.get('/api/auth/user/credit-transactions').then(res => {
        this.loading = false
        this.$set(this, 'transactions', res.data)
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/account/CreditTransactions.json' />
<i18n locale='de' src='@/i18n/de/components/account/CreditTransactions.json' />