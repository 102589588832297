<template>
  <!-- ====== Download dialog ===== -->
  <v-dialog v-model="valueComputed" width="80%" @keydown.esc="close" content-class="download-dialog-container">
    <v-card class="download-dialog">
      <v-card-title primary-title class="headline grey lighten-2">
        {{ $t('download.title') }}
      </v-card-title>

      <v-card-text>
        <v-progress-linear indeterminate v-if="loading" />
        <v-alert v-else-if="error" text prominent border="left" type="error" class="flex-center">
          {{ $t('error', { step: $t('loading.while')}) }}
          <div class="spacer" />

          <v-btn color="accent" outlined @click="doPrechecks">
            {{ $t('actions.retry') }}
          </v-btn>
        </v-alert>

        <template v-else>
          <credit-deduction-info :credits-required="creditsOpen" show-info />

          <v-alert type="warning" border="left" text v-if="excelDisabled">
            <span v-html="$t('download.warnings.excel_disabled', { nrows: MAX_EXCEL_EXPORT_ROWS })" />
          </v-alert>

          <template v-if="creditsOpen <= userCreditsRemaining">
            <div style="display: flex; margin-top: 12px">
              <v-select style="margin-right: 40px; flex: 1"
                        v-model="filetype"
                        dense
                        item-value="value"
                        :label="$t('download.filetype')"
                        :items="filetypeOptionsDisplayed"
                        :menu-props="{ 'contentClass': 'select-menu-with-helptips' }"
                        item-disabled="disabled"
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                  <helptip v-if="item.disabled && !(item.value === 'xlsx' && excelDisabled)"
                           position="bottom"
                           :width="250"
                           anchor-icon="mdi-cancel"
                  >
                    <feature-unavailable />
                  </helptip>
                </template>
              </v-select>

              <v-select style="flex: 1"
                        v-if="filetype !== 'json'"
                        v-model="format"
                        dense
                        :label="$t('download.format.title')"
                        :items="[{ value: 'inorder', text: $t('download.format.inorder') },
                                 { value: 'binary', text: $t('download.format.binary') }]"
              />
            </div>

            <div class="switch-container">
              <v-switch v-model="consolidateCategories"
                        :label="$t('download.consolidate_categories.label')"
              />
              <helptip position="top" :width="770" x-offset="-300" style="margin: -2px 0 0 5px">
                <div v-html="$t('download.consolidate_categories.helptip')" />
              </helptip>
            </div>

            <div v-if="filetype !== 'json'">
              <advanced-options :text="$t('download.advanced_options')">
                <div class="field-select-container">
                  <draggable class="field-select"
                             v-model="downloadFieldsSelected"
                             group="download-fields"
                             :animation="150"
                             draggable=".field"
                  >
                    <span v-for="field in downloadFieldsSelected"
                          class="field"
                          :key="field"
                          v-text="$t(`download.download_fields.fields.${field}.name`)"
                    />
                    <div class="group-title" slot="header">
                      {{ $t('download.download_fields.title') }}
                      <helptip position="bottom" :width="750" style="margin: -2px 0 0 5px">
                        <div v-html="$t('download.download_fields.helptip')" />
                        <ul>
                          <li v-for="(entry, key) in $ta('download.download_fields.fields')" :key="key">
                            <strong v-text="$ta(`download.download_fields.fields.${key}.name`)" />:
                            <span v-html="$ta(`download.download_fields.fields.${key}.description`)" />
                          </li>
                        </ul>
                      </helptip>
                    </div>
                  </draggable>

                  <draggable class="field-select unused"
                             v-model="downloadFieldsNotSelected"
                             group="download-fields"
                             :animation="150"
                             draggable=".field"
                  >
                    <span v-for="field in downloadFieldsNotSelected"
                          class="field"
                          :key="field"
                          v-text="$t(`download.download_fields.fields.${field}.name`)"
                    />
                    <div class="group-title" slot="header" v-text="$t('download.download_fields.unused')" />
                  </draggable>
                </div>

                <div class="field-select-container" style="margin-top: 36px;">
                  <draggable class="field-select"
                             v-model="codeFieldsSelected"
                             group="download-codes"
                             :animation="150"
                             draggable=".field"
                  >
                    <span v-for="field in codeFieldsSelected" class="field" :key="field" v-text="field" />
                    <div class="group-title" slot="header">
                      {{ $t('download.code_fields.title') }}
                      <helptip position="top" :width="550">
                        <div v-html="$t('download.code_fields.helptip')" />
                        <ul>
                          <li v-for="(entry, key) in $ta('download.code_fields.fields')" :key="key">
                            <strong v-text="$ta(`download.code_fields.fields.${key}.name`)" />:
                            <span v-text="$ta(`download.code_fields.fields.${key}.description`)" />
                          </li>
                        </ul>
                      </helptip>
                    </div>
                  </draggable>

                  <draggable class="field-select unused"
                             v-model="codeFieldsNotSelected"
                             group="download-codes"
                             :animation="150"
                             draggable=".field"
                  >
                    <span v-for="field in codeFieldsNotSelected" class="field" :key="field" v-text="field" />
                    <div class="group-title" slot="header" v-text="$t('download.download_fields.unused')" />
                  </draggable>
                </div>

                <div class="switch-container" v-if="format !== 'binary'">
                  <v-switch v-model="interleaveCodes"
                            :label="$t('download.interleave_codes.label')"
                  />
                  <helptip position="top" :width="500" x-offset="-100">
                    <div v-html="$t('download.interleave_codes.helptip')" />
                  </helptip>
                </div>
              </advanced-options>
            </div>
            <div class="button-list">
              <v-btn v-for="(r, key) in resources"
                     :key="key"
                     color="primary"
                     @click="download(key)"
              >
                {{ r.label || $t(`download.${key}`) }}
              </v-btn>
            </div>
          </template>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="close">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import Vuex from 'vuex'
import Draggable from 'vuedraggable'

import CreditDeductionInfo from '@/components/CreditDeductionInfo'
import AdvancedOptions from '@/components/AdvancedOptions'

import { MAX_EXCEL_EXPORT_ROWS } from '@/settings/constants'
import { DOWNLOAD_FIELD_ORDER, DOWNLOAD_CODES_ORDER, DOWNLOAD_CODES_INTERLEAVED } from '@/settings/overridable'

const VALID_DOWNLOAD_FIELDS = ['auxiliary', 'text', 'translated_text', 'source_language', 'codes', 'additional']
const VALID_CODES_FIELDS = ['id', 'name', 'category', 'description', 'keywords']

export default {
  codit: true,
  name: 'DownloadDialog',

  components: {
    'credit-deduction-info': CreditDeductionInfo,
    'draggable': Draggable,
    'advanced-options': AdvancedOptions
  },

  props: {
    value: { type: Boolean, default: false },
    creditsOpen: { type: Number, default: 0 },
    entityType: { type: String, default: '' },
    entityId: { type: String, default: '' },
    resources: { type: Object, default: () => ({ answers: true, codebook: true }) },
    nanswers: { type: Number, default: 0 }
  },

  data () {
    return {
      loading: true,
      error: true,
      downloaded: false,

      filetype: 'xlsx',
      filetypeOptions: [
        { label: 'Excel', params: 'format=xlsx', advanced_type: false, value: 'xlsx' },
        { label: 'CSV', params: 'format=csv', advanced_type: false, value: 'csv' },
        { label: 'JSON', params: 'format=json&orient=records', advanced_type: true, value: 'json' },
        { label: 'SPSS', params: 'format=sav', advanced_type: true, value: 'spss' }
      ],
      format: 'inorder',
      consolidateCategories: false,
      interleaveCodes: DOWNLOAD_CODES_INTERLEAVED,

      downloadFieldsSelected: _.cloneDeep(DOWNLOAD_FIELD_ORDER),
      downloadFieldsNotSelected: VALID_DOWNLOAD_FIELDS.filter(f => DOWNLOAD_FIELD_ORDER.indexOf(f) === -1),

      codeFieldsSelected: _.cloneDeep(DOWNLOAD_CODES_ORDER),
      codeFieldsNotSelected: VALID_CODES_FIELDS.filter(f => DOWNLOAD_CODES_ORDER.indexOf(f) === -1),

      MAX_EXCEL_EXPORT_ROWS
    }
  },

  computed: {
    /**
     * If the Excel export is disabled.
     * Happend for large datasets, as creation of excel is very slow and would lead to timeout otherwise
     * @return {Boolean}
     */
    excelDisabled () {
      return this.nanswers > MAX_EXCEL_EXPORT_ROWS
    },

    filetypeOptionsDisplayed () {
      return _.map(this.filetypeOptions, (v, k) => ({
        value: v.value,
        text: v.label,
        disabled: (v.advanced_type && !this.user.subscription.features.advanced_filetypes) ||
                  (v.value === 'xlsx' && this.excelDisabled)
      }))
    },

    ...Vuex.mapState([
      'user'
    ]),

    ...Vuex.mapGetters([
      'userCreditsRemaining'
    ]),

    valueComputed: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  },

  watch: {
    value () {
      // When opening the dialog, make sure things are up to date
      if (this.value) this.doPrechecks()
      // When closing the question and the question has been downloaded
      // update the account balance locally
      else if (this.downloaded) {
        this.$root.getUser()
      }
    },

    excelDisabled (val) {
      if (val && this.filetype === 'xlsx') this.filetype = 'csv'
    }
  },

  methods: {
    /**
     * Update the current account balance and save any unsaved answers (to not have inconsistencies)
     */
    doPrechecks () {
      this.loading = true
      this.error = false
      this.downloaded = false
      let requests = [this.$root.getUser()]
      // The saveAnswers method is of course only called when it exists
      // i.e. not in the manage view
      if (_.keys(this.$store._actions).findIndex(key => key === 'saveAnswers') !== -1) {
        requests.push(this.$store.dispatch('saveAnswers'))
      }
      axios.all(requests).then(() => { this.loading = false }).catch(() => { this.error = true })
    },

    /**
     * Do the actual download
     * @param  {String} resource The resource to download
     */
    download (resource) {
      this.downloaded = true
      this.$emit('downloaded')
      // If this is not a standard resource, call the custom function given by the resources object
      if (this.resources[resource].fn) {
        this.resources[resource].fn()
        this.$store.dispatch('billQuestion')
      // Otherwise open the resource directly
      } else {
        let ftOption = _.find(this.filetypeOptions, { value: this.filetype })
        let uri
        uri = `${window.API_HTTP_BASE_URI}/api/${this.entityType}s/${this.entityId}/download-${resource}?`
        uri += `${ftOption.params}&code_format=${this.format}`
        uri += `${this.consolidateCategories ? '&consolidate_categories' : ''}`
        uri += `${this.interleaveCodes ? '&codes_interleaved' : ''}`
        uri += `&field_order=${this.downloadFieldsSelected.join(',')}`
        uri += `&codes_order=${this.codeFieldsSelected.join(',')}`

        // Open a new window to prevent messing up the current session
        window.open(uri)
      }
    },

    close () { this.$emit('input', false) }
  }

}

</script>

<style lang=scss>

.download-dialog-container {
  max-width: 1200px;
}

.download-dialog {
  min-width: 800px;
  .button-list {
    display: flex;
    .v-btn {
      flex: 1;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .advanced-options {
    margin-bottom: 20px;
  }

  .field-select-container {
    margin: 12px 0;
    display: flex;
    .field-select {
      background: #DEDEDE;
      flex: 1;
      padding: 24px 8px 8px;
      position: relative;
      flex: 2;
      border-radius: 4px 0 0 4px;
      text-align: right;
      border: 1px solid #999;

      &:first-child {
        border-right: 2px solid #EEE;
      }

      &.unused {
        flex: 1;
        text-align: left;
        background-image: repeating-linear-gradient(45deg, #EEE, #EEE 2px, transparent 2px, transparent 16px)!important;
        border-radius: 0 4px 4px 0;

        .group-title {
          left: 15px;
          right: auto;
        }
      }

      .group-title {
        position: absolute;
        padding: 0px 5px;
        font-size: smaller;
        top: -7px;
        right: 15px;
        line-height: 1.3em;
        color: #424242;
        border: 2px solid #DEDEDE;
        border-radius: 3px;
        background: #FFF;

        .helptip {
          margin-left: 0!important;
        }
      }

      .field {
        display: inline-block;
        background: #666;
        color: #FFF;
        padding: 0px 8px;
        border-radius: 3px;
        margin: 0 8px 4px 0;
        cursor: move;
      }
    }
  }

  .switch-container {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    .helptip {
      margin: -2px 0 0 5px;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/DownloadDialog.json' />
<i18n locale='de' src='@/i18n/de/components/DownloadDialog.json' />