/**
 * Takes color string (Hext of RGB(A)) and returns either object with rgb(a) values or hexadecimal string
 * Throws error if invalid color was given
 * @param  {String} color
 * @return {Object}       Object or string with color
 */
function parseColor (color) {
  let mRgba = color.match(/^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)(?:,\s*(\d{0,1}(?:\.\d+)?))\s*\)$/)
  if (mRgba) return { r: parseInt(mRgba[1]), g: parseInt(mRgba[2]), b: parseInt(mRgba[3]), a: parseFloat(mRgba[4]) }

  let mRgb = color.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i)
  if (mRgb) return { r: parseInt(mRgb[1]), g: parseInt(mRgb[2]), b: parseInt(mRgb[3]), a: 1 }

  let mHex = color.match(/^#([0-9a-f]{6})$/i)
  if (mHex) return `${color}FF`

  // ex: #96f
  let shortHex = color.length === 4
  if (shortHex) {
    let c = color.substring(1).split('')
    c = [c[0], c[0], c[1], c[1], c[2], c[2]].join('')
    return `#${c}FF`
  }

  let mLongHex = color.match(/^#[0-9A-F]{8}$/i)
  if (mLongHex) return color

  return color
}

/**
 * Takes a color string and returns a hexadecimal string
 * representing the same color
 * @param  {String} color    The color object
 * @return {String}                 Hexadecimal representation of color
 */
function colorToHexA (color) {
  color = parseColor(color)

  // If the color is already a string, we assume its hexadecimal and return directly
  if (typeof color === 'string') return color
  // If it's an object with r, g, b props, parse it
  else if (typeof color === 'object' && _.has(color, 'r') && _.has(color, 'g') && _.has(color, 'b')) {
    // Convert ints to base16 strings
    let r = (+color.r).toString(16)
    let g = (+color.g).toString(16)
    let b = (+color.b).toString(16)

    if (r.length === 1) r = '0' + r
    if (g.length === 1) g = '0' + g
    if (b.length === 1) b = '0' + b

    let result = `#${r}${g}${b}`

    // If there is an alpha channel, add it
    if (_.has(color, 'a')) {
      let a = Math.round(+color.a * 255).toString(16)
      if (a.length === 1) a = '0' + a
      result += a
    }

    return result
  } else throw Error(`Unknown parsed color format: ${color}`)
}

/**
 * Takes a color string and returns an object with r, g, b, a properties
 * @param  {String} color The input color
 * @return {Object}       RGBA color object
 */
function colorToRgbaObject (color) {
  color = parseColor(color)

  if (typeof color === 'string' && color.length) {
    // We can expect a hexadecimal string including alpha channel
    // as parseColor will append FF to all hex strings *without* alpha
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)

    if (!result) throw Error(`Failed to convert parsed color string (should be hex) to rgba object: ${color}`)
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: parseInt(result[4], 16) / 255
    }
  } else if (typeof color === 'object' && _.has(color, 'r') && _.has(color, 'g') && _.has(color, 'b')) {
    return { r: color.r, g: color.g, b: color.b, a: color.a || 1.0 }
  } else return color
}

/**
 * https://css-tricks.com/converting-color-spaces-in-javascript/
 * Takes a hexA color string and return an rgba string
 * @param  {String} h The input hexA color
 * @return {Object} RGBA color string
 */
function hexAtoRGBAColor (h) {
  let r = 0
  let g = 0
  let b = 0
  let a = 1

  if (h.length === 5) {
    r = '0x' + h[1] + h[1]
    g = '0x' + h[2] + h[2]
    b = '0x' + h[3] + h[3]
    a = '0x' + h[4] + h[4]
  } else if (h.length === 9) {
    r = '0x' + h[1] + h[2]
    g = '0x' + h[3] + h[4]
    b = '0x' + h[5] + h[6]
    a = '0x' + h[7] + h[8]
  }

  a = +(a / 255).toFixed(3)

  // eslint-disable-next-line
  return "rgba(" + +r + "," + +g + "," + +b + "," + a + ")"
}

/**
 * Shade the given color string and return rgba string
 * Factors < 0 darken
 * Factors > 0 lighten
 * Inspired by
 *   https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
 *   #Micro Functions -> RGB_Linear_Shade
 * @param  {String} color  Valid color string (hex or rgb(a))
 * @param  {Number} factor Factor by which color should be darkened or lightened
 * @return {String}        rgba representation of lightened or darkened color
 */
function shadeColor (color, factor) {
  // let i = parseInt
  // let [a, b, c, d] = color.split(',')
  let { r, g, b, a } = colorToRgbaObject(color)
  // First use flexiblity in the alpha channel to change the instensity
  // Change the alpha within the range [0.2, 1.0]
  let aOrig = a
  a = Math.min(Math.max(aOrig - factor, 0.2), 1.0)
  // Get the difference by which the alpha was changed, and subtract it from the factor
  factor -= aOrig - a

  let P = factor < 0
  let t = P ? 0 : 255 * factor
  P = P ? 1 + factor : 1 - factor
  return 'rgba(' + Math.round(r * P + t) + ',' + Math.round(g * P + t) + ',' + Math.round(b * P + t) + ',' + a + ')'
}

/**
 * Return true if color is "light" and should have dark foreground text if used as background
 * @param  {String}  color Any supported color object
 * @return {Boolean}       true if color is light
 */
function isLightColor (color) {
  let { r, g, b, a } = colorToRgbaObject(color)
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) / Math.min(1.75 * a, 1)
  return hsp > 200
}

/**
 * Return a softer version of the given color
 * @param  {String} color Valid color string
 * @return {String}       Color string
 */
function getSofter (color) {
  return shadeColor(color, 0.4)
}

/**
 * Return a stronger version of the given color
 * @param  {String} color Valid color string
 * @return {String}       Color string
 */
function getStronger (color) {
  return shadeColor(color, -0.4)
}

/**
 * Get a very soft, but solid version of the color
 * @param  {String} color Valid color string
 * @return {String}       Color string
 */
function getSoftSolid (color) {
  let { r, g, b } = colorToRgbaObject(color)
  let a = 0.15
  // Project on white background, making it solid
  // Technically mixing white and this color
  r = Math.floor(r * a + 255 * (1 - a))
  g = Math.floor(g * a + 255 * (1 - a))
  b = Math.floor(b * a + 255 * (1 - a))
  return `rgba(${r}, ${g}, ${b}, 1.0)`
}

/**
 * Return three different shades (one darker, the color itself, and one lighter) for the given color
 * @param  {String} color Valid color string
 * @return {Object}       Object with keys soft, medium and strong with color strings as values
 */
function get3ShadesForColor (color) {
  return {
    softSolid: getSoftSolid(color),
    soft: getSofter(color),
    medium: color,
    strong: getStronger(color)
  }
}

/**
 * Sets the body background color
 * Used in pages that have been redesigned
 * @param  {String} color The color to set
 */
function setBackgroundColor (color) {
  const elem = document.querySelector('.v-application--wrap')
  const html = document.querySelector('html')

  elem.style.background = color
  html.style.background = color
}

export {
  setBackgroundColor,
  parseColor,
  colorToHexA,
  colorToRgbaObject,
  hexAtoRGBAColor,
  shadeColor,
  isLightColor,
  getSofter,
  getStronger,
  getSoftSolid,
  get3ShadesForColor
}