<template>
  <div class="user-settings">
    <v-card>
      <v-card-title>
        <div>
          <div class="title">
            {{ $t('profile.settings.title') }}
          </div>
          <div class="grey--text">
            {{ $t('profile.settings.description') }}
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <form @submit.prevent="saveUser" autocomplete="off" class="user-form">
          <div class="form-row">
            <!-- First Name -->
            <v-text-field v-model="userChanged.first_name"
                          maxlength="30"
                          data-private
                          counter
                          :label="$t('profile.settings.first_name')"
                          :error-messages="res.userError.first_name"
            />

            <!-- Last Name -->
            <v-text-field v-model="userChanged.last_name"
                          maxlength="30"
                          data-private
                          counter
                          :label="$t('profile.settings.last_name')"
                          :error-messages="res.userError.last_name"
            />
          </div>

          <div class="form-row one-el">
            <v-text-field v-model="userChanged.company_name"
                          maxlength="100"
                          counter
                          data-private
                          :disabled="!$root.isRootAccount"
                          :label="$t('profile.settings.company_name')"
                          :error-messages="res.userError.company_name"
            />
          </div>

          <!-- Email -->
          <div class="form-row">
            <v-text-field v-model="userChanged.email"
                          data-private
                          :label="$t('profile.settings.email')"
                          :error-messages="res.userError.email"
            />

            <v-text-field v-model="userChanged.billing_email"
                          data-private
                          :disabled="!$root.isRootAccount"
                          :placeholder="user.organization ? user.organization.root_user_email : userChanged.email"
                          :label="$t('profile.settings.billing_email')"
                          :error-messages="res.userError.billing_email"
            />
          </div>

          <!-- Street -->
          <v-textarea v-model="userChanged.address_street"
                      maxlength="255"
                      rows="2"
                      counter
                      data-private
                      :disabled="!$root.isRootAccount"
                      :label="$t('profile.settings.address')"
                      :error-messages="res.userError.address_street"
          />

          <div class="form-row">
            <!-- City -->
            <v-text-field v-model="userChanged.address_city"
                          maxlength="50"
                          counter
                          data-private
                          :disabled="!$root.isRootAccount"
                          :label="$t('profile.settings.city')"
                          :error-messages="res.userError.address_city"
            />

            <!-- ZIP -->
            <v-text-field v-model="userChanged.address_zip"
                          :label="$t('profile.settings.zip')"
                          maxlength="20"
                          data-private
                          :disabled="!$root.isRootAccount"
                          :error-messages="res.userError.address_zip"
            />
          </div>

          <!-- Country -->
          <div class="form-row one-el">
            <v-autocomplete v-model="userChanged.address_country"
                            :label="$t('profile.settings.country')"
                            :items="countries"
                            item-text="name"
                            item-value="iso"
                            data-private
                            :disabled="!$root.isRootAccount"
                            :error-messages="res.userError.address_country"
            />
          </div>

          <div>
            <v-checkbox v-model="userChanged.organization.user_settings.opt_out_tracking"
                        :label="$t('profile.settings.opt_out_tracking')"
            />
          </div>

          <div class="dummy-strings">
            <v-text-field v-for="k in 3"
                          :key="`dummy${k}`"
                          v-model="userChanged.organization.user_settings.dummy_strings[k - 1]"
                          :placeholder="dummyStringsPlaceholder.length > (k - 1) ? dummyStringsPlaceholder[k - 1] : ''"
                          maxlength="20"
                          data-private
                          counter
                          outlined
                          dense
                          :label="$t('profile.settings.dummy_string') + ` ${k}`"
            />
            <helptip :width="500" position="top" x-offset="-230">
              <span v-html="$t('profile.settings.dummy_string_helptip')" />
              <a target="_blank"
                 :href="HELP_RESOURCE_CREDITS" v-text="$t('actions.more_information')"
              />
            </helptip>
          </div>

          <!-- ====== Profile Settings ====== -->
          <div style="display: flex; margin: 20px -5px">
            <v-spacer />
            <v-btn class="primary"
                   type="submit"
                   :disabled="res.userLoading || changedUserdata===null"
            >
              {{ $t('save.title') }}
            </v-btn>
          </div>
        </form>
      </v-card-text>
    </v-card>

    <v-card class="misc-info">
      <v-card-title>
        <div>
          <div class="title">
            {{ $t('profile.infos.title') }}
          </div>
          <div class="grey--text">
            {{ $t('profile.infos.subtitle') }}
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <div>
          <span class="grey--text" style="width: 150px; display: inline-block;">
            {{ $t('profile.infos.created') }}
          </span>
          <code>{{ user.created | dateshort }}</code>
        </div>
        <div>
          <span class="grey--text" style="width: 150px; display: inline-block;">
            {{ $t('profile.infos.last_login') }}
          </span>
          <code>{{ user.last_login | fromnow }}</code>
        </div>
        <div>
          <span class="grey--text" style="width: 150px; display: inline-block;">
            {{ $t('profile.infos.number_of_projects') }}
          </span>
          <code>{{ user.n_projects }}</code>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import Vuex from 'vuex'
import { USER_SETTINGS_TEMPLATE } from '@/settings/constants'
import { overridePropsIfExistRecursive } from '@/utils/funcs'
import { HELP_RESOURCE_CREDITS } from '@/settings/help-resources'

export default {
  name: 'UserSettings',
  codit: true,

  data () {
    return {
      userChanged: {
        first_name: '',
        last_name: '',
        email: '',
        billing_email: '',
        company_name: '',
        address_street: '',
        address_city: '',
        address_zip: '',
        address_country: '',
        organization: { user_settings: _.cloneDeep(USER_SETTINGS_TEMPLATE) }
      },

      countries: [],
      res: {
        userLoading: false,
        userError: {}
      },

      HELP_RESOURCE_CREDITS
    }
  },

  computed: {
    /**
     * Object with all data the user has changed in the form
     * If nothing has changed, it is null
     * @return {Object,null} The changed data
     */
    changedUserdata () {
      let data = {}
      _.each(this.userChanged, (val, key) => {
        if (JSON.stringify(val) !== JSON.stringify(this.user[key])) data[key] = _.cloneDeep(val)
      })
      if (Object.keys(data).length === 0 && data.constructor === Object) return null
      return data
    },

    /**
     * The dummy strings which should be shown as placeholder: The ones from the org, if it exists
     * @return {Array}
     */
    dummyStringsPlaceholder () {
      const org = this.user.organization
      if (org && org.user_settings.dummy_strings) return org.user_settings.dummy_strings
      else return []
    },

    ...Vuex.mapState(['user'])
  },

  created () {
    // Get the list of valid countries
    api.get('/api/constants/countries').then(res => this.$set(this, 'countries', res.data))

    let userChanged = _.cloneDeep(this.userChanged)
    overridePropsIfExistRecursive(userChanged, this.user)

    this.$set(this, 'userChanged', userChanged)
  },

  methods: {
    /**
     * Save the details the user has changed, e.g. address, email
     */
    saveUser () {
      // get all the elements which have changed
      this.res.userLoading = true
      this.res.userError = { }
      this.$store.dispatch('updateUser', this.changedUserdata).then(res => {
        this.res.userLoading = false
        if (res.status === 200) this.$root.snackMsg(this.$t('profile.save.profile_success'))
        else this.$set(this.res, 'userError', { ...res.data })
      }).catch(err => {
        this.res.userLoading = false
        this.$root.snackMsg(this.$t('error', { 'step': this.$t('save.title') }))
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<style lang=scss>

.user-settings {
  flex: 1;
  display: flex;

  .user-form {
    max-width: 600px;

    .form-row {
      display: flex;
      &:not(.one-el) .v-input:first-child { margin-right: 16px; }
    }

    .one-el {
      justify-content: flex-end;
      .v-input { flex: 0 0 calc(50% - 8px) }
    }
  }

  .misc-info {
    flex: 1;
    align-self: flex-start;
  }

  .dummy-strings {
    display: flex;
    .v-input {
      margin-right: 8px;
    }

    .helptip {
      margin-top: 8px;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Account.json' />
<i18n locale='de' src='@/i18n/de/pages/Account.json' />