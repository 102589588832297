<template>
  <div class="coding__column__chart h-100" v-if="!question.loading">
    <div class="chart-container py-4">
      <div class="d-flex justify-center">
        <div class="d-inline-block">
          <switcher
            :items="availableChartsList"
            v-model="chart.typeSelection"
          />
        </div>
      </div>

      <component
        :is="`chart-${configManager.type}`"
        :value="configManager.config"
        :datasets="configManager.datasets"
        :type="configManager.type"
        :chart-unique-name="configManager.typeSelection"
        :meta="metaManager"
        :id="'ch__new'"
        hide-controls
        no-watermark
        ref="chart"
      />
      <div class="d-flex justify-center pl-4 pr-4">
        <div />
        <!-- <v-switch
          v-model="isSentimentChartShown"
          inset
          color="green"
          flat
          dense
          :ripple="false"
          class="v-input--reverse v-input--description v-input--switch--small mt-0"
          hide-details
          small
        >
          <template v-slot:label>
            <div class="d-flex align-center" style="position: relative; right: -32px;">
              <div class="text-xs text-label">
                {{ $t('chart_prerender.topic_sentiment_switcher') }}
              </div>
            </div>
          </template>
        </v-switch> -->
        <div class="d-flex flex-column align-center">
          <v-btn
            class="codebook-editor__category__new"
            @click="reload"
            depressed
            outlined
            small
          >
            {{ $t('actions.refresh') }}
          </v-btn>
          <div class="text-xs text-grey text-center mt-1">
            {{ $t('last_updated') }}: {{ chartLastUpdatedHumanReadable }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import ChartBar from '@/components/visualize/ChartBar'
import ChartSentimentBar from '@/components/visualize/ChartSentimentBar'
import Switcher from '@/components/customUi/switcher/Switcher.vue'

import {
  /*
  * use types of charts
  * can differs with unique name
  */
  CHART_TYPE_BAR,
  /*
  * use unique names of charts
  */
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_SENTIMENT_BAR
} from '@/settings/constants'

import CHART_LIST from '@/settings/chartList'
import { mapGettersWithKey } from '@/utils/vuex.js'

const I18N_PROXY = {
  [CHART_UNIQUE_BAR]: 'topics.category',
  [CHART_UNIQUE_SENTIMENT_BAR]: 'answer_fields.sentiment'
}
const CHART_SORT = [
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_BAR
]

export default {
  codit: true,
  name: 'CodingChart',

  components: {
    ChartBar, // eslint-disable-line
    ChartSentimentBar, // eslint-disable-line
    Switcher
  },

  data () {
    return {
      chartConfig: {},
      chartUpdateInterval: null,
      chartUpdateHumanReadableInterval: null,
      chartLastUpdated: null,
      chartLastUpdatedHumanReadable: '',

      chart: {
        type: CHART_TYPE_BAR,
        typeSelection: CHART_UNIQUE_BAR
      },

      CHART_UNIQUE_BAR,
      CHART_UNIQUE_SENTIMENT_BAR
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user',
      question: state => state.coding.question,
      sessionIssue: state => state.coding.sessionIssue,
      inferenceUpdater: state => state.coding.inferenceUpdater
    }),

    ...mapGettersWithKey({
      metaManager: 'metaManager/state',
      configManager: 'configManager/state'
    })(() => 'ch__new'),

    connectionLost () { return this.sessionIssue.type === 'connection_lost' },

    availableChartsList () {
      return _.chain(CHART_LIST)
        .cloneDeep()
        .pickBy(
          (item, index) =>
            [CHART_UNIQUE_BAR, CHART_UNIQUE_SENTIMENT_BAR].indexOf(index) > -1
        )
        .entries()
        .map(([key, value]) => ({
          label: this.$t(I18N_PROXY[key]),
          value: key
        }))
        .sortBy(({ value }) => CHART_SORT.indexOf(value))
        .value()
    }
  },

  watch: {
    connectionLost (val) {
      if (val) clearInterval(this.chartUpdateInterval)
    },

    inferenceUpdater (val, oldVal) {
      if (val && val !== oldVal) {
        this.reload()
      }
    },

    'chart.typeSelection' () {
      this.selectChartType(this.chart.typeSelection)
    }
  },

  created () {
    this.chartLastUpdated = this.$moment()
    this.computeChartLastUpdatedHumanReadable()
    this.chartUpdateInterval = setInterval(this.reload, 90000)
    this.chartUpdateHumanReadableInterval = setInterval(this.computeChartLastUpdatedHumanReadable, 5000)

    if (
      this.metaManager.topics_complete_union.some(({ sentiment_enabled }) => sentiment_enabled === true) // eslint-disable-line
    ) {
      this.selectChartType(CHART_UNIQUE_SENTIMENT_BAR)
    }
  },

  beforeDestroy () {
    clearInterval(this.chartUpdateInterval)
    clearInterval(this.chartUpdateHumanReadableInterval)
  },

  methods: {
    reload () {
      if (this.$refs.chart) this.$refs.chart.computeAllResults()
      this.chartLastUpdated = this.$moment().local()
      this.computeChartLastUpdatedHumanReadable()
    },

    computeChartLastUpdatedHumanReadable () {
      this.chartLastUpdatedHumanReadable = this.chartLastUpdated.fromNow()
    },

    /** ************************ UI METHODS ************************ **/

    /**
     * Select the chart type whose preview to display
     * @param  {String} chartType The chart type to display
     */
    selectChartType (chartType) {
      this.chart.typeSelection = chartType
      this.chart.type = CHART_LIST[chartType].type

      this.$store.dispatch(
        `registerManager/update`,
        {
          entityId: 'ch__new',
          entityData: {
            ..._.cloneDeep(this.configManager),
            type: CHART_LIST[chartType].type,
            typeSelection: chartType
          }
        }
      )
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Codingv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Codingv2.json' />
