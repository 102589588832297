import ApiTaskMixin from '@/mixins/apitask'

export default {
  mixins: [ApiTaskMixin],

  methods: {
    /**
     * Call the list autocoder endpoint asynchronously with the given postData
     * First performs HTTP request to initiate autocoding, then checks task
     * status through websockets until done or failed
     * @param  {Object}     postData Post params, optional {given_codebook,inherits_from,no_new_codes}
     * @return {Promise}    Promise which resolves when result is provided
     */
    callListAutocoder (postData, store = false) {
      return new Promise((resolve, reject) => {
        let query = `async&${store ? 'store=true' : ''}`
        api.post(`/api/questions/${this.question.id}/autocode-list?${query}`, postData, { dontReport: [422] }).then((res) => {
          if (res.status !== 200 || !('task-id' in res.headers)) throw Error('Calling autocoder failed')
          this.getApiTaskStatus(res.headers['task-id']).then(({ result }) => {
            if (!('codes' in result)) throw Error('Invalid return data')
            resolve(result)
          }).catch(err => {
            reject(err)
            this.$maybeRaiseAPIPromiseErr(err)
          })
        }).catch(err => {
          if (err.response && err.response.status === 422) {
            // Special status, indicating that this question is not suited as list question
            // We will fail, but not raise the error and give the caller the chance to fail gracefully
            err = Error(err.response.data)
            err.invalidListQuestion = true
            reject(err)
          } else {
            reject(err)
            this.$maybeRaiseAPIPromiseErr(err)
          }
        })
      })
    }
  }
}