<template>
  <!-- ====== Reset coding dialog ====== -->
  <modal
    :visible="true"
    :title="$t('title')"
    :action="$t('btn')"
    action-type="error"
    :loading="loading"
    :disabled="failedIrrecoverably"
    :action-click="() => resetQuestion()"
    :close="close"
    :persistent="failedIrrecoverably || loading"
    max-width="400"
    class-name="reset-dialog"
    :closeable="false"
  >
    <notification
      v-if="failed"
      type="error"
      title="Failed resetting question"
      :text="$t('error', {'step': $t('error_step') })"
      :action="failedIrrecoverably && 'Reload page'"
      :action-click="reload"
      class="mb-3"
    />
    <div class="text-sm" v-html="$t('description', { question: $escapeHtml(codingColumn.name) })" />
  </modal>
</template>

<script>

export default {
  codit: true,
  name: 'ResetCodingDialog',

  props: {
    /**
     * The column which we are resetting
     * @return {Object}
     */
    project: { type: Object, default: () => ({}) },

    /**
     * The column which we are resetting
     * @return {Object}
     */
    codingColumn: { type: Object, default: () => ({}) },
    /**
     * An optional callback function, which is called *after* the reset has completed.
     * If given, the dialog will stay in the loading state (& persistent) until the
     * callback function has resolved or rejected the promise. If the promise is rejected
     * the dialog will fail irreversibly, a state which cannot be undone.
     * @param  {Function}   The callbackfunction
     */
    callbackFunctionReturningPromise: { type: Function, default: () => new Promise((resolve) => resolve()) }
  },

  data () {
    return {
      loading: false,
      failed: false,
      failedIrrecoverably: false
    }
  },

  computed: {
  },

  watch: {
  },

  methods: {
    /**
     * Reload the current route
     * @return {[type]} [description]
     */
    reload () {
      this.$router.go()
    },

    /**
     * Close the dialog and go back to the previous view
     */
    close () {
      this.$router.go(-1)
    },

    /**
     * Close the research dialog and reset the column, if confirmed by the user
     * @param  {Boolean} doIt   If resetting should be performed or not
     */
    resetQuestion () {
      if (!this.loading) {
        this.failed = false
        this.loading = true

        api.post(`/api/ui/projects/${this.project.id}/coding/${this.codingColumn.ref}/reset`, {}).then((res) => {
          if (res.data.success) {
            // Once the reset has worked, call the callback function and wait
            // for the promise to be resolved or rejected
            this.callbackFunctionReturningPromise().then(() => {
              this.loading = false
            }).catch(() => {
              this.failed = true
              this.failedIrrecoverably = true
            })
          } else {
            this.failed = true
            this.loading = false
          }
        }).catch(err => {
          this.failed = true
          this.loading = false
          this.$maybeRaiseAPIPromiseErr(err)
        })
      }
    }
  }
}

</script>

<style lang=scss>
</style>

<i18n locale='en' src='@/i18n/en/components/ResetQuestionDialog.json' />
<i18n locale='de' src='@/i18n/de/components/ResetQuestionDialog.json' />