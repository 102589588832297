<template>
  <div
    id="dashboard-filters"
    class="dashboard-filters d-flex flex-wrap align-center"
    :class="{
      [`dashboard-filters--${variant}`]: variant,
      'flex-wrap': multiLine,
      'dashboard-filters--readonly': readonly
    }"
  >
    <div
      class="dashboard-filters__select-container d-flex align-center"
      @click="handleFilterAdd"
      v-if="!readonly"
    >
      <v-menu
        offset-y
        :close-on-content-click="true"
        :disabled="addFiltersDisabled"
        :rounded="'lg'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="filters__select"
            :class="{'dashboard-filters__select--disabled': disabled}"
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            hide-details
            solo
            dense
          >
            <v-icon>
              mdi-plus-circle
            </v-icon>
            {{ $t('ds.filters.add') }}
          </v-btn>
        </template>

        <v-list class="dashboard-filters__list pt-0">
          <div
            v-for="(item, index) in addOptions"
            :key="index"
          >
            <v-divider v-if="item.divider" class="dashboard-filters__list__separator" />
            <div
              v-else-if="item.header"
              class="dashboard-filters__list__header"
            >
              {{ item.header }}
              <helptip v-if="item.helptip" position="bottom" class="ml-1">
                <div v-html="$t('require_matching_auxiliaries')" />
              </helptip>
            </div>
            <template v-else>
              <div
                v-if="item.disabled"
                class="dashboard-filters__list__item dashboard-filters__list__item--disabled"
              >
                {{ item.text }}
                <helptip v-if="item.disabled" position="bottom" class="ml-1">
                  <div v-html="$t('disabled_filters.one_codes_or_categories_only')" />
                </helptip>
              </div>
              <div
                v-else
                class="dashboard-filters__list__item"
                @click="handleSelectItem(item.type)"
              >
                {{ item.text }}
              </div>
            </template>
          </div>
        </v-list>
      </v-menu>
    </div>
    <div
      v-for="(filter, fIdx) in computedFilters"
      :key="filter.idx"
    >
      <div
        @click="$emit('open', filter)"
        class="dashboard-filters__filter"
        :class="{
          'dashboard-filters__filter--disabled': disabled,
          'dashboard-filters__filter--warning': filter.hasWarning,
          'dashboard-filters__filter--readonly': readonly
        }"
      >
        <v-tooltip bottom :disabled="!(filter.hasWarning || filter.tooltip)">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="d-flex align-center justify-center h-100 text-truncate">
              <v-icon
                v-if="!readonly"
                class="dashboard-filters__filter__close hover"
                @click.stop="handleFilterRemove(filter.idx)"
              >
                mdi-close-circle
              </v-icon>
              <v-icon
                v-else-if="!fIdx"
                class="dashboard-filters__filter__readonly-icon"
              >
                mdi-filter
              </v-icon>
              <div class="dashboard-filters__filter__title">
                {{ filter.typeText }}:
              </div>
              <div class="dashboard-filters__filter__value">
                {{ filter.valueText }}
              </div>
            </div>
          </template>
          <span v-if="filter.hasWarning" v-html="$t('editing_toolbar.filter_has_warning')" />
          <div v-else-if="filter.tooltip">
            <strong>{{ filter.tooltip.title }}</strong>
            <ul v-if="filter.tooltip.listLines.length">
              <li v-for="(text, i) in filter.tooltip.listLines" :key="i">
                <span v-if="text">{{ text }}</span>
                <em v-else>{{ $t('ds.filters.empty_label') }}</em>
              </li>
            </ul>
            <div v-if="filter.tooltip.more > 0">
              {{ $t('ds.filters.tooltips_n_more', filter.tooltip) }}
            </div>
          </div>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'

export default {
  name: 'FiltersList',
  mixins: [answerFilters, auxiliaryColumnsMixin],
  props: {
    addOptions: { type: Array, required: false, default: () => ([]) },
    settings: { type: Object, default: () => ({}) },
    question: { type: Object, default: () => ({}) },
    availableSettings: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    maxFilters: { type: Number, default: -1 },
    applyMaster: { type: Function, default: () => ({}) },
    filters: { type: Array, default: () => ([]) },
    variant: { type: String, default: '' },
    multiLine: { type: Boolean, default: false }
  },

  data () {
    return {
      filtersInternal: []
    }
  },

  computed: {
    /**
     * discard empty filters
     * @return {Array}
     */
    nonEmptyFilters () {
      return this.filtersInternal.filter(filter => filter.type)
    },

    /**
     * discard empty filters
     * @return {Array}
     */
    computedFilters () {
      return this.nonEmptyFilters.map(filter => {
        const needsTooltip = (['auxiliary', 'categories', 'topics']).includes(filter.type)
        const typeText = this.getDisplayedFilterType(filter.idx)
        return {
          ...filter,
          typeText,
          valueText: this.getDisplayedFilterValue(filter.idx),
          tooltip: needsTooltip ? this.getDisplayedFilterDetails(filter.idx, typeText) : null
        }
      })
    },

    /**
     * list of auxiliaryTypes names
     * @return {Array}
     */
    auxiliaryTypes () {
      if (
        this.question.auxiliaryTypes
      ) {
        return this.question.auxiliaryTypes.map(auxiliaryType => auxiliaryType.name)
      } else if (
        this.question.auxiliary_column_metas
      ) {
        return this.question.auxiliary_column_metas.map(auxiliaryType => auxiliaryType.name)
      }

      return []
    },

    /**
     * If adding filters is disabled
     */
    addFiltersDisabled () {
      return (this.maxFilters !== -1 && this.filtersInternal.length >= this.maxFilters) || this.disabled
    },

    /**
     * If adding filters is disabled
     */
    codes () {
      return _.get(this.question, 'codebook', [])
    }
  },

  watch: {
    /**
     * Watch the internal state of filters for changes, emit update when change has occurred
     */
    filtersInternal: {
      deep: true,
      handler (filtersInternal) {
        this.$emit('update:filters', _.cloneDeep(this.filtersInternal))
        this.applyMaster()
        if (!_.isEqual(this.filters, filtersInternal)) {
          this.$emit('modified', true)
        }
      }
    },

    /**
     * Watch the state of the filters prop
     * If it has changed, check if the value is the same as the internal object
     * Note: The objects should *never* be the same as the internal one, as we cloneDeep when emitting
     */
    filters: {
      immediate: true,
      deep: true,
      handler (filters) {
        if (!_.isEqual(filters, this.filtersInternal)) {
          const filtersInternal = filters.map((filter, index) => ({ ...filter, idx: filter.idx || index }))
          this.$set(this, 'filtersInternal', filtersInternal)
        }
      }
    }
  },

  methods: {
    handleSelectItem ($event) {
      this.$emit('add', $event)
    },

    /**
     * directly call add event if options are not available
     */
    handleFilterAdd ($event) {
      if (!this.addOptions.length) {
        $event.preventDefault()
        this.handleSelectItem(null)
      }
    },

    /**
     * remove the selected filter
     */
    handleFilterRemove (idx) {
      this.filtersInternal.splice(idx, 1)
      if (this.isMaster) {
        this.$nextTick(() => this.applyMaster())
      }
    },

    /**
     * Filter type label
     */
    getDisplayedFilterType (idx) {
      const { type, value } = this.filtersInternal[idx]
      if (type !== 'auxiliary') return this.$t(`ds.filters.drawer_labels.${type}`)
      return _.truncate(this.auxiliaryTypes[value.col] ? this.auxiliaryTypes[value.col] : value.col, { length: 30 })
    },

    /**
     * Filter value label
     */
    getDisplayedFilterValue (idx) {
      const { type, value } = this.filtersInternal[idx]
      const filter = _.find(this.filterOptions, option => option.type === type)

      return filter.valueLabel(value)
    },

    /**
     * Filter value tooltip
     * gets title and translates code ids to code labels
     */
    getDisplayedFilterDetails (idx, typeText) {
      const { type, value, invert } = this.filtersInternal[idx]
      const isAuxilary = type === 'auxiliary'

      // just shows tooltip for select and text auxiliaries
      if (isAuxilary && !(['select', 'text']).includes(value.kind)) return null

      // special return for auxilary text column
      if (isAuxilary && value.kind === 'text') {
        return {
          title: this.$t(`ds.filters.tooltips.auxiliary_text`, { text: value.value }),
          listLines: [],
          more: 0
        }
      }

      // get values
      const filterValue = isAuxilary ? value.value : value

      let details = {
        title: this.$t(`ds.filters.tooltips.${invert ? 'inverted_' : ''}${type}`) + ':',
        listLines: filterValue.slice(0, 5),
        more: filterValue.slice(5).length
      }

      if (type === 'topics') {
        details.listLines = details.listLines.map(({ label }) => label)
      }

      return details
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />
