<template>
  <div class="no-op-wrapper">
    <v-select
      v-if="valueInternal.type === null"
      :items="filterOptionsSelect(allFilters, auxiliaryOptions, STANDARD_FILTERS_WITH_TOPICS)"
      :label="$t('ds.filters.type_label')"
      item-value="type"
      hide-details
      class="type-select mt-4"
      @change="setFilterType($event)"
      clearable
      outlined
      dense
    />

    <template v-else>
      <v-checkbox
        v-model="valueInternal.value"
        :label="$t(`ds.filters.labels.${valueInternal.type}`)"
        v-if="valueInternal.type === 'empty'"
        @change="changeFilterItem(false)"
        hide-details
        outlined
      />
      <div v-if="['reviewed', 'changed'].indexOf(valueInternal.type) !== -1">
        <v-radio-group
          v-model="valueInternal.value"
          @change="changeFilterItem(false)"
          row
          hide-details
          class="mt-0 mb-0"
        >
          <v-radio :label="$t('yes')" :value="true" class="mb-0" small :ripple="false" />
          <v-radio :label="$t('no')" :value="false" class="mb-0" small :ripple="false" />
        </v-radio-group>
      </div>

      <v-text-field
        v-if="valueInternal.type === 'text'"
        v-model="valueInternal.value"
        @input="changeFilterItem(true)"
        @blur="changeFilterItem"
        :label="$t(`ds.filters.labels.${valueInternal.type}`)"
        hide-details
        outlined
        dense
      />

      <div v-if="valueInternal.type === 'sentiment'">
        <v-range-slider
          v-model="valueInternal.value"
          @input="changeFilterItem(true)"
          @blur.self="changeFilterItem"
          :max="1"
          :min="-1"
          :step="0.1"
          thumb-label="always"
          thumb-size="20"
          hide-details
          class="mt-6"
          disabled
        />
      </div>

      <v-select
        v-if="valueInternal.type === 'sentiment_v2'"
        v-model="valueInternal.value"
        :items="sentimentV2Options"
        hide-details
        class="type-select mt-4 ml-0"
        @change="changeFilterItem(false)"
        dense
        :outlined="!isDashboardFilter"
      />

      <topic-select
        v-if="valueInternal.type === 'topics'"
        v-model="valueInternal.value"
        @update="value => {
          valueInternal.value = value
          changeFilterItem(true)
        }"
        :placeholder="$t('ds.filters.labels.topics')"
        :title="$t('ds.filters.labels.add_topics')"
        :id="id"
        :react-on-keydown="false"
        sync
        static
      />

      <topic-select
        v-if="valueInternal.type === 'categories'"
        v-model="valueInternal.value"
        @update="value => {
          valueInternal.value = value
          changeFilterItem(true)
        }"
        :title="$t('ds.filters.labels.add_categories')"
        :id="id"
        :react-on-keydown="false"
        sync
        static
        category-select
      />

      <template v-if="valueInternal.type === 'created' || valueInternal.type === 'modified'">
        <label class="font-weight-medium text-sm">
          {{ auxiliaryColumnNames[valueInternal.value.col] }}
        </label>
        <date-range-filter
          v-model="valueInternal.value"
          :format-value-label="formatValueLabel"
          @change="changeFilterItem(false)"
        />
      </template>

      <template v-if="valueInternal.type === 'auxiliary'">
        <v-text-field
          v-if="valueInternal.value.kind === 'text'"
          v-model="valueInternal.value.value"
          @input="changeFilterItem(true)"
          @blur="changeFilterItem"
          :label="$t('ds.filters.labels.auxiliary_column_text', {
            column: auxiliaryColumnNames[valueInternal.value.col]
          })"
          hide-details
          outlined
          dense
        />

        <template v-if="valueInternal.value.kind === 'range'">
          <label class="font-weight-medium text-sm">
            {{ auxiliaryColumnNames[valueInternal.value.col] }}
          </label>
          <div class="d-flex align-center mt-2">
            <v-number-field
              v-model="valueInternal.value.value[0]"
              @input="changeFilterItem(true)"
              @blur="changeFilterItem"
              hide-details
              :label="$t('ds.filters.labels.auxiliary_column_range_min', { column: '' })"
              class="min-max mr-1"
              outlined
              dense
            />

            <v-number-field
              v-model="valueInternal.value.value[1]"
              @input="changeFilterItem(true)"
              @blur="changeFilterItem"
              v-if="valueInternal.value.kind === 'range'"
              hide-details
              :label="$t('ds.filters.labels.auxiliary_column_range_max', { column: '' })"
              class="min-max ml-1"
              outlined
              dense
            />
          </div>
        </template>

        <template v-if="valueInternal.value.kind.startsWith('date_range')">
          <label class="font-weight-medium text-sm">
            {{ auxiliaryColumnNames[valueInternal.value.col] }}
          </label>
          <date-range-filter
            v-model="valueInternal.value"
            :format-value-label="formatValueLabel"
            :is-dashboard-filter="isDashboardFilter"
            @change="changeFilterItem(false)"
          />
        </template>

        <template v-if="valueInternal.value.kind === 'select'">
          <label class="font-weight-medium text-sm">
            {{ auxiliaryColumnNames[valueInternal.value.col] }}
          </label>
          <v-autocomplete
            v-model="valueInternal.value.value"
            @input="changeFilterItem(true)"
            @blur="changeFilterItem"
            dense
            class="mt-2 multiple-select"
            :items="auxiliaryTypes[valueInternal.value.col] ? auxiliaryTypes[valueInternal.value.col].vals : []"
            :placeholder="$t('ds.filters.labels.auxiliary_column_select', {
              column: auxiliaryColumnNames[valueInternal.value.col]
            })"
            hide-details
            multiple
            outlined
          >
            <template v-slot:selection="selectItem">
              <div class="v-select__selection" v-if="selectItem.index === 0">
                {{ selectItem.item }}&nbsp;
              </div>
              <span v-if="selectItem.index === 1" class="grey--text">
                &nbsp; [+{{ valueInternal.value.value.length - 1 }} {{ $t('ds.filters.select_others') }}] &nbsp;
              </span>
            </template>
          </v-autocomplete>
        </template>
      </template>

      <v-checkbox
        v-model="valueInternal.invert"
        @change="changeFilterItem(false)"
        hide-details
        v-if="['text', 'codes'].indexOf(valueInternal.type) !== -1 || (valueInternal.type === 'auxiliary' && valueInternal.value.kind === 'text')"
        class="invert mt-1 mb-1"
        :label="$t('ds.filters.invert')"
      />

      <slot name="options" />
    </template>
  </div>
</template>

<script>
import dateRangeFilter from '@/components/DateRangeFilter'
import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import TopicSelect from '@/components/coding/TopicSelectv2'

import { FILTER_OPTIONS_TYPES, SENTIMENT_RANGE } from '@/settings/constants'
const { DATASET_FILTERS, STANDARD_FILTERS_WITH_TOPICS } = FILTER_OPTIONS_TYPES

function getSentimentV2Options () {
  return SENTIMENT_RANGE.map(value => ({
    text: this.$t(`ds.filters.labels.sentiment_range.${value}`),
    value
  }))
}

export default {
  name: 'FilterEditor',
  codit: true,
  components: {
    'date-range-filter': dateRangeFilter,
    TopicSelect
  },

  mixins: [answerFilters, auxiliaryColumnsMixin],

  props: {
    /**
     * If are used in Dashboard page
     * Used in order not to debounce values as that causes issues, unneccessary because we have an apply button in Dashboard
     */
    isDashboardFilter: {
      type: Boolean,
      default: false
    },
    /**
     * Max code-chip width
     * @type {Number}
     */
    maxCodeWidth: {
      type: Number,
      default: 275
    },
    /**
     * The filter which is being modified by this component
     * @type {Object}
     */
    value: {
      type: Object,
      default: () => ({})
    },

    /**
     * The codebook of the question which is being filtered
     * @type {List}
     */
    codes: {
      type: Array,
      default: () => []
    },

    /**
     * Array of the auxiliary column names of the question (i.e. project)
     * @type {Array}
     */
    auxiliaryColumnNames: {
      type: Array,
      default: () => []
    },

    /**
     * The types of the auxiliary columns: These determine how the filtering looks like
     * All types need to be handled by the `answerFilters` mixin
     * @type {Object}
     */
    auxiliaryTypes: {
      type: Array,
      default: () => []
    },

    /**
     * A list of all other filters that are applied.
     * Will determine which options are available when creating new filter
     * E.g.: Filtering by empty rows is only allowed once
     * @type {List}
     */
    allFilters: {
      type: Array,
      default: () => []
    },

    /**
     * flag to load master filters only
     * @type {Boolean}
     */
    isMaster: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      // The internal copy of the filter
      valueInternal: {},
      debouncedEmit: _.debounce(() => {
        this.$emit('input', _.cloneDeep(this.valueInternal))
      }, 1000),
      STANDARD_FILTERS_WITH_TOPICS,
      sentimentV2Options: getSentimentV2Options.call(this)
    }
  },

  computed: {
    /**
     * Todays date in iso format for label
     */
    isoToday () {
      let today = new Date()
      return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    },

    /**
     * List of available auxiliary columns, in format which can be passed to by v-select
     * @return {Array} The list
     */
    auxiliaryOptions () {
      return this.getAuxiliaryOptions(this.auxiliaryColumnNames)
    },

    /**
     * The category colors in the order of the code categories array
     * @return {Array}
     */
    catColorsInOrder () {
      return this.codeCats.map(c => this.catColors[c])
    },

    filteOptions () {
      return this.filterOptionsSelect(this.allFilters, this.auxiliaryOptions, this.isMaster ? STANDARD_FILTERS_WITH_TOPICS : DATASET_FILTERS)
    },

    /**
     * return valueLabel provided by answerFilters mixin
     * @return {Function}
     */
    formatValueLabel () {
      const { type } = this.valueInternal
      const filterOption = _.find(this.filterOptions, option => option.type === type)
      return filterOption ? filterOption.valueLabel : v => (v.value || v)
    }
  },

  watch: {
    /**
     * Watch the prop value and update internal value as soon as it changes
     */
    value: {
      immediate: true,
      deep: true,
      handler () {
        if (!_.isEqual(this.value, this.valueInternal)) this.$set(this, 'valueInternal', _.cloneDeep(this.value))
      }
    }

  },

  methods: {
    /**
     * Set the default value of a filter, after the user has chosen its type from the dropdown
     */
    setFilterType (type) {
      let initialFilter = this.initializeNewFilter(type, this.auxiliaryTypes)

      this.$set(this.valueInternal, 'type', initialFilter.type)
      this.$set(this.valueInternal, 'value', initialFilter.value)

      // Wait for the next tick (until the new value has been emitted)
      // before emitting to update the count as well
      this.$nextTick(this.updateFilterCounts)
    },

    /**
     * Emit message that the row count for this filter needs to be updated
     */
    updateFilterCounts () {
      this.$emit('update-count')
    },

    /**
     * Callback when any filter item is changed
     */
    changeFilterItem (shouldDebounce = false) {
      if (shouldDebounce && !this.isDashboardFilter) {
        this.debouncedEmit()
      } else {
        this.$emit('input', _.cloneDeep(this.valueInternal))
      }

      this.$emit('update-count-debounced')
    }
  }
}

</script>

<style lang=scss>
.no-op-wrapper {
  all: inherit;
  max-width: 80%;

  .multiple-select {
    .v-input__append-inner {
      margin-top: 3px !important;
    }
  }
}

.v-autocomplete.v-select.v-input--is-focused input {
  min-width: 0 !important;
}
</style>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
