import { render, staticRenderFns } from "./FiltersListAddButton.vue?vue&type=template&id=412191a4"
import script from "./FiltersListAddButton.vue?vue&type=script&lang=js"
export * from "./FiltersListAddButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/pages/Dataset.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FFiltersList%2FFiltersListAddButton.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/pages/Dataset.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FFiltersList%2FFiltersListAddButton.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/i18n/en/components/visualize/ChartGlobals.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FFiltersList%2FFiltersListAddButton.vue&locale=en&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/i18n/de/components/visualize/ChartGlobals.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FFiltersList%2FFiltersListAddButton.vue&locale=de&external"
if (typeof block3 === 'function') block3(component)
import block4 from "@/i18n/en/pages/Dashboard.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FFiltersList%2FFiltersListAddButton.vue&locale=en&external"
if (typeof block4 === 'function') block4(component)
import block5 from "@/i18n/de/pages/Dashboard.json?vue&type=custom&index=5&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FFiltersList%2FFiltersListAddButton.vue&locale=de&external"
if (typeof block5 === 'function') block5(component)

export default component.exports