import { FILTER_OPTIONS_TYPES } from '@/settings/constants'
import _ from 'lodash'

const { DASHBOARD_FILTERS, DATASET_FILTERS, STANDARD_FILTERS, CODING_FILTERS } = FILTER_OPTIONS_TYPES

export default {
  data () {
    return {
      filterOptions: [
        {
          type: 'topics',
          multiple: true,
          default: [],
          showEmpty: false,
          dontShowEmpty: false,
          icon: 'mdi-tag-outline',
          logic: 'or',
          valueLabel: v => v.length > 1 ? `(${v.length})` : `${v[0].label}${v[0].sentiment ? ' ~ ' + v[0].sentiment : ''}`,
          fn: (a, v) => a.topics.some(c => v.has(c))
        },
        {
          type: 'categories',
          multiple: true,
          default: [],
          logic: 'or',
          icon: 'mdi-tag-multiple-outline',
          valueLabel: v => v.length > 1 ? `(${v.length})` : v[0],
          fn: (a, v) => a.codes.some(c => v.has(c))
        },
        {
          type: 'text',
          multiple: true,
          default: '',
          valueLabel: v => v,
          fn: (a, v) => v === '' || a.text.toLowerCase().indexOf(v) !== -1 },
        {
          type: 'sentiment',
          multiple: true,
          default: [],
          icon: 'mdi-circle-double',
          valueLabel: v => v.length === 1 ? v[0] : `(${v.length})`,
          fn: (a, v) => a.sentiment !== null && a.sentiment >= v[0] && a.sentiment <= v[1]
        },
        {
          type: 'highlighted',
          multiple: false,
          default: [],
          icon: 'mdi-star-outline',
          valueLabel: v => v[0] === 'True' ? this.$t('yes') : this.$t('no'),
          fn: (a, v) => a.highlighted === v
        },
        {
          type: 'reviewed',
          multiple: false,
          default: [],
          icon: 'mdi-check-circle-outline',
          valueLabel: v => v[0] === 'True' ? this.$t('yes') : this.$t('no'),
          fn: (a, v) => a.reviewed === v
        },
        {
          type: 'changed',
          multiple: false,
          default: [],
          valueLabel: v => v ? this.$t('yes') : this.$t('no'),
          fn: (a, v) => a.changed === v
        },
        {
          type: 'empty',
          multiple: false,
          default: [],
          icon: 'mdi-checkbox-blank-circle-outline',
          valueLabel: v => v[0] === 'True' ? this.$t('yes') : this.$t('no'),
          fn: (a, v) => a.reviewed === v
        },
        {
          type: 'created',
          multiple: false,
          external: false,
          default: [],
          icon: 'mdi-calendar-range-outline',
          valueLabel: v => {
            return Array.isArray(v)
              ? v
                .map(v => moment(v))
                .filter(m => m.isValid())
                .map(m => m.format('MMM DD, YYYY'))
                .join(' - ')
              : v
          },
          fn: (a, v) => !!v
        },
        {
          type: 'auxiliary',
          multiple: true,
          default: {},
          valueLabel: v => {
            if (v.kind === 'text') return v.value
            else if (v.kind === 'select') return v.value.length + ' ' + this.$t('selected')
            else if (v.kind === 'range') {
              return (v.value || []).join(' - ')
            } else if (v.kind === 'date_range') {
              return Array.isArray(v.value)
                ? v.value
                  .map(v => moment(v))
                  .filter(m => m.isValid())
                  .map(m => m.format('MMM DD, YYYY'))
                  .join(' - ')
                : v.value
            } else if (v.kind === 'date_range_dynamic') {
              return v.value ? this.$t(`ds.filters.date.${v.value}`) : ''
            }
          },
          fn: (a, v) => {
            let c = a.auxiliary_columns[v.col]

            if (v.kind === 'text') return v.value === '' || String(c).toLowerCase().indexOf(v.value) !== -1
            else if (v.kind === 'select') return v.value.has(c)
            else if (v.kind === 'range') return c >= v.value[0] && c <= v.value[1]
            else if (v.kind === 'date_range') return Array.isArray(v.value) ? (c >= v.value[0] && c <= v.value[1]) : !!v.value
            else if (v.kind === 'date_range_dynamic') return !!v.value
          }
        }
      ],
      codingOptions: [
        {
          type: 'contains',
          multiple: false,
          external: true,
          default: ''
        },
        {
          type: 'order_by',
          multiple: false,
          external: true,
          default: 'asc:upload_index'
        }
      ]
    }
  },

  computed: {
    filterOptionsStandard () {
      return this.filterOptions.map(o => ({ ...o, text: this.$t(`answer_fields.${o.type}`) }))
    },

    filterOptionsDashboard () {
      return this.filterOptionsStandard.filter(filter => filter.type !== 'codes' && filter.type !== 'categories' && filter.type !== 'changed' && filter.type !== 'reviewed')
    },

    filterOptionsDataset () {
      return this.filterOptionsStandard.filter(filter => filter.type !== 'codes' && filter.type !== 'categories')
    },

    filterOptionsCoding () {
      return [
        ...this.filterOptionsStandard.filter(filter => filter.type !== 'text' && filter.type !== 'changed'),
        ...this.codingOptions
      ]
    }
  },

  methods: {
    /**
     * List of "standard" filters (i.e. filters of auxiliary columns), like sentiment, text etc.
     * Those filters that are only valid once (e.g. sentiment) are filtered out if they already exist
     * @return {Array} The filtered list of standard filters
     */
    filterOptionsStandardFiltered (filters, filterTypes = STANDARD_FILTERS) {
      let ffn = o => o.type !== 'auxiliary' && (o.multiple || !_.find(filters, { type: o.type }))
      switch (filterTypes) {
        case DASHBOARD_FILTERS:
          return _.filter(this.filterOptionsDashboard, ffn)
        case DATASET_FILTERS:
          return _.filter(this.filterOptionsDataset, ffn)
        case CODING_FILTERS:
          return _.filter(this.filterOptionsCoding, ffn)
        case STANDARD_FILTERS:
        default:
          return _.filter(this.filterOptionsStandard, ffn)
      }
    },

    /**
     * List of "auxiliary" filters, given by the auxiliary columns of the project
     * Those filters that are only valid once (i.e. all except text filters) are filtered out if they already exist
     * @return {Array} The filtered list of auxiliary filters
     */
    filterOptionsAuxiliaryFiltered (filters, auxiliaryOptions) {
      let ffn = o => !_.some(filters, it => it.type === 'auxiliary' && it.value.col === o.value && it.value.kind !== 'text')

      return auxiliaryOptions.filter(ffn)
    },

    /**
     * The final list of filters options that will be fed to the v-select for adding a new filter
     * Concatenation of "standard" and auxiliary column options, whereby the auxiliary column filters still need
     * @return {[type]} [description]
     */
    filterOptionsSelect (filters, auxiliaryOptions, filterTypes = STANDARD_FILTERS) {
      let opts = []
      opts.push({ header: this.$t('answer_fields.standards') })
      opts.push(...this.filterOptionsStandardFiltered(filters, filterTypes))

      if (auxiliaryOptions.length) {
        opts.push({ divider: true })
        opts.push({ header: this.$t('answer_fields.auxiliary'), helptip: true })
        opts.push(...this.filterOptionsAuxiliaryFiltered(filters, auxiliaryOptions).map(({ text, value }) => ({ text, type: `auxiliary:${value}` })))
      }

      return opts
    },

    prepareFilterItemValue (item, codebook) {
      if (item.type === 'text') return item.value.toLowerCase()
      if (item.type === 'topics') return new Set(item.value)
      if (item.type === 'categories') {
        let categorySet = new Set(item.value)
        return new Set(_(codebook).filter(c => categorySet.has(c.category)).map('id').value())
      } else if (item.type === 'auxiliary' && item.value.kind === 'select') return { ...item.value, value: new Set(item.value.value) }
      else if (item.type === 'auxiliary' && item.value.kind === 'text') return { ...item.value, value: item.value.value.toLowerCase() }
      else return item.value
    },

    filterAnswers (filterItems, answers, codebook) {
      if (!filterItems.length) return answers
      let filters = filterItems.map(item => {
        if (item.type === null) return answer => true
        let opt = _.find(this.filterOptions, { type: item.type })
        let v = this.prepareFilterItemValue(item, codebook)
        if (item.invert) return answer => !opt.fn(answer, v)
        else return answer => opt.fn(answer, v)
      })

      return answers.filter(a => filters.every(f => f(a)))
    },

    /*
    * initialize New Filter Properties
    * @param {String} type
    */
    initializeNewFilter (type, auxiliaryTypes = {}, passedInDefaultValue = null) {
      let value

      if (type.startsWith('auxiliary')) {
        // Auxiliaries are a bit special: The `type` value is a concatenation between
        // the string 'auxiliary' and the index of the auxiliary column which it represents
        let col = parseInt(type.split(':')[1])

        // Depending on the type of the auxiliary column selected
        // set different defaults
        let tt = auxiliaryTypes[col]

        if (tt.type === 'numerical') value = { kind: 'range', value: [tt.metadata?.min || 0, tt.metadata?.max || 0], col }
        else if (tt.type === 'date') {
          value = { kind: 'date_range', value: tt.metadata.min ? [moment(tt.metadata?.min).format('YYYY-MM-DD'), moment(tt.metadata?.max).format('YYYY-MM-DD')] : [], col }
        } else if (tt.type === 'text' && tt.metadata?.has_few) value = { kind: 'select', value: [], col }
        else value = { kind: 'text', value: '', col }
        type = 'auxiliary'
      } else {
        // For the standard filters, the default values are defined in the `answerFilters` mixin
        value = _.clone(_.find(this.filterOptions, { type }) ? _.find(this.filterOptions, { type }).default : _.find(this.codingOptions, { type }).default)
      }

      if (passedInDefaultValue) value = passedInDefaultValue

      return { type, value }
    }
  }
}
