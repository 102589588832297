<template>
  <div class="caplena-iframe-controls">
    <v-btn
      class="fullscreen-button"
      fab
      @click="handleFullscreenClick"
    >
      <v-icon>{{ inFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'IframeControls',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const inFullscreen = ref(false)

    const handleFullscreenClick = () => {
      const app = document.querySelector('html')

      if (!app) return

      if (!inFullscreen.value) {
        app.requestFullscreen()
      } else {
        document.exitFullscreen()
      }

      inFullscreen.value = !inFullscreen.value
    }

    return { inFullscreen, handleFullscreenClick }
  }
})
</script>

<style lang="scss" scoped>
.caplena-iframe-controls {

  .fullscreen-button {
    position: fixed;
    top: auto;
    left: auto;
    bottom: 24px;
    right: 24px;
    z-index: 99999;
  }
}
</style>
