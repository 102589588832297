<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane v-model="config"
                    v-bind="controlPaneProps"
                    v-on="controlPaneEvents"
                    ref="control-pane"
                    slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
        <template v-slot:general>
          <settings-drawer-item
            :title="$t('controls.general.show_pie')"
          >
            <template slot="content">
              <v-checkbox v-model="config.showPie"
                          hide-details
                          class="mt-0"
                          :label="$t('controls.general.show_pie')"
              />
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.general.sort_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.sortBy" column hide-details class="mt-0">
                <v-radio :label="$t('controls.general.sort_by.none')" :value="null" />
                <v-radio :label="$t('controls.general.sort_by.frequency')" value="frequency" />
                <v-radio :label="$t('controls.general.sort_by.alphabet')" value="alphabet" />
              </v-radio-group>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.general.yfield.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.yField.field" column hide-details class="mt-0">
                <v-radio :label="$t('controls.general.yfield.topics')" value="topics" />
                <v-radio :label="$t('controls.general.yfield.categories')" value="categories" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.percentages"
                :label="$t('controls.value_axis.percentages')"
                :disabled="config.chartType === 'stacked'"
              />
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:ordinal-axis>
          <settings-drawer-item
            :title="$t('controls.general.chart_type')"
          >
            <template slot="content">
              <v-radio-group v-model="config.chartType" column hide-details class="mb-2">
                <v-radio :label="$t('controls.value_axis.line')" value="line" />
                <v-radio :label="$t('controls.value_axis.stacked')" value="stacked" @change="setToPercentage" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>
        <slot name="datasets" slot="datasets" />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <slot name="chart-type-selection" slot="chart-type-selection" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <v-chart
          ref="chart"
          v-if="initialReady"
          :style="chartStyle"
          v-bind="chartProps"
          v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'
import { encodeStr } from '@/utils/filters'

import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'

export default {
  codit: true,
  name: 'ChartLinePie',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [chartMixin],

  data () {
    return {
      resultKeys: ['counts'],
      APItype: 'LINEP',
      forceAggregate: false,

      config: {
        // General
        title: undefined,
        aggregate: undefined,
        yField: { field: 'categories' },
        sortBy: 'frequency',
        chartType: 'line',

        dimensions: undefined,
        showPie: true,

        // Color
        colorBy: {},
        colorPalette: undefined,
        colorPaletteValue: undefined,

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255,1)',

        // Ordinal axis
        ordinalAxisName: 'Datasets',
        reverseOrdinal: undefined,

        // Value axis
        valueAxisName: undefined,
        valueAxisToFullData: false,

        // Grid line
        gridLine: true,

        percentages: true,

        // Labels
        maxDataLabelLength: 25,
        labelsEnabled: true,
        dataLabelSize: 12,
        maxAxisLabelLength: 25,
        axisLabelSize: 12,
        decimalPlaces: 0,
        showLegend: true,
        // labelPosition: 'outside',
        // labelsTotal: false,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },

      availableSettingTiles: {
        ...this.availableSettingTiles,
        valueAxis: ['valueAxisName', 'valueAxisToFullData']
      },

      chartContainerWidth: 0,
      pieSeriesIdx: 0,
      // to persist enabled legend items (not for saving)
      enabledLegends: {/* initial value is not important. eCharts checks for false values only */}
    }
  },

  computed: {
    /*
      Overrides default setting from chart.js mixin. This is because showing non-aggregated results on this chart with multiple datasets does nothing
    */
    aggregateDisabled () {
      return true
    },

    /**
     * Makes sure topics of same category come after each other
     * performs all sorting if `plotCategories` is activated
     * @return {list} List of topics
     */
    topicsSorted () {
      if (!this.initialReady) return []

      let topics = _.clone(this.topics)

      if (
        this.config.sortBy === 'alphabet'
      ) {
        topics = _.orderBy(topics, c => c.label.toLowerCase(), 'asc')
      }

      if (this.config.sortBy === 'frequency' && this.results.counts.topics.length) {
        topics = _.orderBy(topics, topic => this.results.counts.topics.reduce(
          (sum, dataset, datasetIndex) => sum + dataset[topic.id]?.results.counts.per_dataset?.[datasetIndex], 0),
        'desc')
      }
      return topics.map(({ id }) => id)
    },

    topicCatsSorted () {
      if (!this.initialReady) return []

      let topicCats = _.keys(_.groupBy(this.topics, 'category'))
      if (this.config.sortBy === 'alphabet') topicCats = _.orderBy(topicCats, c => c.toLowerCase())
      if (this.results.counts.categories.length && this.config.sortBy === 'frequency') {
        topicCats = _.orderBy(topicCats, c => this.results.counts.categories[0][c], 'desc')
      }
      return topicCats
    },

    seriesNames () {
      let names
      switch (this.config.yField.field) {
        case 'topics':
          names = this.topicsSorted
          break
        case 'categories':
          names = this.topicCatsSorted
          break
        default:
          throw Error(`Unknown yField ${this.config.yField}!`)
      }
      return _.map(names, this.maybeTruncateLabel)
    },

    chartDataset () {
      if (
        !this.initialReady ||
        !this.isReady
      ) return []

      const datasetsToPlot = this.config.aggregate && this.datasets.length ? [this.datasets[0]] : this.datasets
      const nRows = this.globalResults.counts?.overall ? [this.globalResults.counts?.overall] : this.globalResults.counts?.per_dataset

      const headerDataset = [
        'yfield',
        ...datasetsToPlot.reduce((sum, item, index) => {
          let name = item.settings.name
          let incrementIndex = 0

          if (typeof sum.usedNames[name] !== 'undefined') {
            incrementIndex = sum.usedNames[name] + 1
            name = `${name} [${incrementIndex}]`
          }

          return {
            usedNames: {
              ...sum.usedNames,
              [item.settings.name]: incrementIndex
            },
            values: [
              ...sum.values,
              name
            ]
          }
        }, {
          usedNames: {},
          values: []
        })['values']
      ]

      const calcPercentage = (value, total) => value ? 100 * value / total : 0
      const calcItems = (array, valuesArray, datasets, total, preprocess, initialValue) => array.reduce((sum, item) => [
        ...sum,
        [
          item,
          ...datasets.reduce((sum, ds, dsIdx) => [
            ...sum,
            preprocess
              ? preprocess(valuesArray[dsIdx][item], total?.[dsIdx], dsIdx)
              : valuesArray[dsIdx][item]
          ], [])
        ]
      ], [])

      if (
        this.config.yField.field === 'categories'
      ) {
        return {
          counts: [
            headerDataset,
            ...calcItems(this.topicCatsSorted, this.results.counts.categories, datasetsToPlot)
          ],
          percentages: [
            headerDataset,
            ...calcItems(this.topicCatsSorted, this.results.counts.categories, datasetsToPlot, nRows, calcPercentage)
          ]
        }
      } else {
        return {
          counts: [
            headerDataset,
            ...calcItems(
              this.topicsSorted,
              this.results.counts.topics,
              datasetsToPlot,
              null,
              (value, total, index) => this.config.aggregate
                ? value?.results.counts.overall
                : value?.results.counts.per_dataset[index]
            )
          ],
          percentages: [
            headerDataset,
            ...calcItems(
              this.topicsSorted,
              this.results.counts.topics,
              datasetsToPlot,
              nRows,
              (value, total, index) => calcPercentage(
                this.config.aggregate
                  ? value?.results.counts.overall
                  : value?.results.counts.per_dataset[index],
                total
              )
            )
          ]
        }
      }
    },

    ordinalAxis () {
      // Destructuring variables for reactivity (and simplicity)
      const { maxAxisLabelLength } = this.config
      // counts of hidden labels after a visible label
      // 100 and 150 calculated by experimental
      const xAxisAvailableWidth = this.chartContainerWidth - 150
      const interval = Math.floor(this.datasets.length * 100 / xAxisAvailableWidth)
      // maximum available width for each label
      const maxLabelWidth = Math.round(xAxisAvailableWidth / this.datasets.length * (interval + 1))
      return {
        type: 'category',
        name: this.config.ordinalAxisName,
        nameLocation: this.config.reverseOrdinal ? 'start' : 'end',

        axisLabel: {
          interval,
          width: maxLabelWidth,
          fontSize: this.config.axisLabelSize,
          formatter: (lbl) => this.maybeTruncateLabel(lbl, { maxLength: maxAxisLabelLength }),
          overflow: 'truncate'
        },

        nameTextStyle: {
          fontStyle: 'italic',
          color: 'grey',
          align: 'right',
          verticalAlign: 'top',
          lineHeight: 30,
          fontSize: this.config.axisLabelSize,
          padding: [this.config.axisLabelSize + 5, 12, 0, 0] // 20px from above to prevent name overlap with axis labels
        },

        inverse: this.config.reverseOrdinal || false,
        triggerEvent: true,
        data: this.chartDataset[this.config.percentages ? 'percentages' : 'counts'][0].slice(1)
      }
    },

    valueAxis () {
      const config = {
        name: this.config.chartType === 'line' ? this.config.valueAxisName : '',
        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        triggerEvent: true,
        gridIndex: 0,
        axisLine: this.config.chartType === 'line' ? { show: true } : false,
        axisLabel: {
          fontSize: this.config.axisLabelSize,
          formatter: (value) => `${value}${this.valueUnit}`
        },
        splitLine: {
          show: this.config.gridLine
        }
      }

      if (this.config.valueAxisToFullData) {
        const dataMaxValue = this.config.percentages ? 100 : _.max(_.map(this.datasets, d => d.result.length))

        config.max = dataMaxValue
      }

      return config
    },

    xAxis () {
      return this.ordinalAxis
    },

    yAxis () {
      return this.valueAxis
    },

    minContainerWidth () {
      return this.seriesNames.length * 18 + 100
    },

    minContainerHeight () {
      return this.grid.top + 150
    },

    nLegendRows () {
      return (8 * _.sumBy([...this.seriesNames], c => c.length) + this.seriesNames.length * 20) / this.chartContainerWidth
    },

    legendHeight () {
      return ((this.config.showLegend ? Math.ceil(this.nLegendRows) : 0) * 30)
    },

    pieOffsetTop () {
      return this.legendHeight + (this.config.showPie ? 170 : 0)
    },

    valueUnit () { return this.config.percentages ? '%' : '' },

    grid () {
      return {
        top: this.pieOffsetTop + (this.config.showPie ? 1 : 0.5) * 200,
        left: 40 + (this.isHorizontal ? this.xLabelPadding : 0),
        bottom: 40 + (this.isVertical ? this.xLabelPadding : 0),
        right: 10,
        containLabel: true
      }
    },

    series () {
      // Destructuring variables for reactivity (and simplicity)
      const { decimalPlaces } = this.config
      const chartData = this.chartDataset[this.config.percentages ? 'percentages' : 'counts'].slice(1)

      let series = _.map(chartData, (ds, dsIdx) => {
        if (this.config.chartType === 'line') {
          return {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            name: ds[0],
            animation: false
          }
        }

        if (this.config.chartType === 'stacked') {
          let stackDs = []
          ds.forEach((item, index) => {
            let stackTotal = this.stackChartTotal(chartData, index)
            let stackPercentage = ((ds[index + 1] / stackTotal) * 100)
            stackDs.push(stackPercentage)
          })
          return {
            name: ds[0],
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            data: stackDs,
            animation: false
          }
        }
      })

      if (this.config.showPie) {
        series.push({
          type: 'pie',
          id: 'pie',
          radius: '25%',
          center: ['50%', this.pieOffsetTop],
          itemStyle: {
            borderColor: '#FFF',
            borderWidth: 2
          },
          label: {
            show: this.config.labelsEnabled,
            formatter: (el) => {
              let cnt = this.chartDataset.counts[el.dataIndex + 1][this.pieSeriesIdx + 1]
              let perc = this.chartDataset.percentages[el.dataIndex + 1][this.pieSeriesIdx + 1]

              if (this.config.yField.field === 'topics') {
                const item = this.topics.find(({ id: topicId }) => topicId === el.name)
                return `${item.category} \n${item.label}: ${Math.round(cnt)} (${this.toFixed(perc, decimalPlaces)}%)`
              } else {
                return `${el.name}: ${Math.round(cnt)} (${this.toFixed(perc, decimalPlaces)}%)`
              }
            },
            fontSize: this.config.dataLabelSize
          },
          legendHoverLink: true,
          encode: {
            itemName: 'yfield',
            value: this.chartDataset.counts[0][this.pieSeriesIdx + 1],
            tooltip: this.chartDataset.counts[0][this.pieSeriesIdx + 1]
          },
          animation: false
        })
      }

      return series
    },

    title () {
      return {
        text: this.config.title,
        triggerEvent: true
      }
    },

    legend () {
      return {
        selected: this.enabledLegends,
        show: this.config.showLegend,
        top: 30,
        formatter: (el) => {
          if (this.config.yField.field === 'topics') {
            const item = this.topics.find(({ id: topicId }) => topicId === el)
            return `${item.category}, ${item.label}`
          }
          return el
        }
      }
    },

    tooltip () {
      return {
        trigger: 'axis',
        showContent: true,
        confine: true,
        formatter: (params) => this.tooltipFormatter(params)
      }
    },

    colorsIncludingCBColors () {
      if (this.config.colorPalette === '__cb_colors__' && this.config.yField.field === 'categories') {
        return _.map(this.topicCatsSorted, cat => this.catColors[cat].medium)
      } else if (
        this.config.colorPalette === '__cb_colors__'
      ) {
        return _.reduce(
          this.topicsSorted, (sum, topic) => {
            const category = this.topics.find(({ id }) => id === topic)['category']
            return [
              ...sum,
              this.catColors[category].medium
            ]
          }, [])
      } else {
        return this.colors
      }
    },

    chartOptions () {
      if (this.initialReady) {
        return {
          ...this.defaultOptions,
          dataset: {
            source: this.chartDataset[this.config.percentages ? 'percentages' : 'counts']
          },
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          series: this.series,
          grid: this.grid,
          legend: this.legend,
          tooltip: this.tooltip,
          color: this.colorsIncludingCBColors,
          backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
        }
      }

      return {}
    }
  },

  watch: {
    /**
     * Hack, somehow the listener on the axisPointer seems to get lost again all the time
     * Therefore we need to set listener on & off all the time.
     */
    chartOptions: {
      immediate: false,
      handler (val) {
        if (this.$refs.chart && this.$refs.chart.chart) {
          this.$refs.chart.chart.off('updateAxisPointer', this.setPieSeries)
          setTimeout(() => { if (this.$refs.chart) this.$refs.chart.chart.on('updateAxisPointer', this.setPieSeries) }, 100)
        }
      }
    },

    results: {
      immediate: false,
      handler (val) {
        this.$nextTick(() => {
          if (this.$refs.chart) this.$refs.chart.refresh()
        })
      }
    }
  },

  methods: {
    // Set config percentage to true on Stacked chart active.
    setToPercentage () {
      this.config.percentages = true
    },

    /**
     * Make sure the original percentage is set on tooltip
     * Sort(DESC) data with same order of lines on the chart
     * Format the way tooltip should look like for stacked chart.
     * @return {Percentage, (Count)}
     */
    tooltipFormatter (params) {
      // convert params to array when trigger is 'item' on tooltip options
      if (!Array.isArray(params)) params = [params]
      const stackedDatas = params.map((data, index) => {
        let perc = this.chartDataset.percentages[data.seriesIndex + 1][this.pieSeriesIdx + 1]
        let cnt = this.chartDataset.counts[data.seriesIndex + 1][this.pieSeriesIdx + 1]

        if (this.config.yField.field === 'topics') {
          const item = this.topics.find(({ id: topicId }) => topicId === data.seriesName)
          return {
            label: `<div style="display: table;">${data.marker}${this.$escapeHtml(item.category)}, ${this.$escapeHtml(item.label)}:<span style="margin-left:5px;">${this.toFixed(perc, this.config.decimalPlaces)}% (${cnt})<span></div>`,
            perc
          }
        } else {
          return {
            label: `<div style="display: table;">${data.marker}${this.$escapeHtml(data.seriesName)}:<span style="margin-left:5px;">${this.toFixed(perc, this.config.decimalPlaces)}% (${cnt})<span></div>`,
            perc
          }
        }
      })

      const stackedFormatter = _.sortBy(stackedDatas, 'perc').reverse().map(data => data.label).join('')

      return `<div>${this.$escapeHtml(params[0].name)} ${stackedFormatter}</div>`
    },

    setPieSeries (event) {
      let xAxisInfo = event.axesInfo[0]
      if (xAxisInfo) {
        this.pieSeriesIdx = xAxisInfo.value
      }
    },

    /**
     * Get the sum of all category in the array
     * which is used to calculate the stacked area percentage of 100
     * @return {Total} categories
     */
    stackChartTotal (list, index) {
      let total = 0
      for (let i = 0; i < list.length; i++) {
        total += list[i][index + 1]
      }
      return total
    },

    /**
    * emits chart result per  dataset
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     dsIdx {Number}:    dataset index
    *     key   {String}:    result key from resultKeys
    */
    emitDatasetResult ({ res, dsIdx }) {
      this.$emit('result', dsIdx, res.results_global.counts.per_dataset[dsIdx])
    },

    chartClick ($evt) {
      let filter, payload

      if (
        $evt.componentType === 'title'
      ) {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      }
      if (
        $evt.componentType !== 'series' ||
        !this.isVerbatimEnabled
      ) {
        return
      }

      let pieId, lineId

      if (
        $evt.componentSubType === 'pie'
      ) {
        pieId = $evt.name
      } else if (
        $evt.componentSubType === 'line'
      ) {
        lineId = $evt.seriesName
      }

      if (
        this.config.yField.field === 'topics' &&
        $evt.componentSubType === 'pie'
      ) {
        const item = this.topics.find(({ id }) => id === pieId)
        filter = {
          type: 'text_to_analyze',
          value: `topic.${pieId}:any`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml(item.label)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;any`
        }
      } else if (
        this.config.yField.field === 'topics' &&
        $evt.componentSubType === 'line'
      ) {
        const item = this.topics.find(({ id }) => id === lineId)
        filter = {
          type: 'text_to_analyze',
          value: `topic.${lineId}:any`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml(item.label)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;any`
        }
      } else if (
        this.config.yField.field === 'categories' &&
        $evt.componentSubType === 'pie'
      ) {
        filter = {
          type: 'text_to_analyze',
          value: `topic.category.${encodeStr(pieId, true)}:any`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(pieId)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;any`
        }
      } else if (
        this.config.yField.field === 'categories' &&
        $evt.componentSubType === 'line'
      ) {
        filter = {
          type: 'text_to_analyze',
          value: `topic.category.${encodeStr(lineId, true)}:any`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(lineId)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;any`
        }
      }
      if (
        $evt.componentType !== 'series' ||
        $evt.data[$evt.componentIndex + 1] === 0
      ) {
        return
      }

      payload = {
        item: this.id || 'ch__new',
        filters: [filter]
      }
      if (
        !this.config.aggregate
      ) {
        payload.filters = [
          ...payload.filters,
          {
            type: 'dataset',
            value: [this.pieSeriesIdx],
            htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.segment')}</div>:&nbsp;${
              this.maybeTruncateLabel(this.$escapeHtml(this.datasets[this.pieSeriesIdx].settings.name), { maxLength: 25 })
            }`
          }
        ]
      }

      this.$store.dispatch('verbatimDialog/show', payload)
    },
    legendSelectedChanged ($evt) {
      this.enabledLegends = $evt.selected
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartLinePie.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartLinePie.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />