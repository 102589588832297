<template>
  <div class="branding" :class="{ static: dashboard.branding && dashboard.branding.static }">
    <div class="branding__inner d-flex align-center justify-space-between w-100">
      <a href="/"
         target="_blank"
         v-if="!dashboard.branding || !dashboard.branding.logo"
         class="no-external-icon d-flex"
      >
        <img src="@/assets/landing/logo.svg"
             alt="Logo Caplena.com"
             height="45"
        >
      </a>
      <img v-else
           :src="dashboard.branding.logo"
           :alt="`Logo ${dashboard.branding.organization}`"
           height="45"
      >
      <div class="powered-by">
        <template v-if="!dashboard.branding || !dashboard.branding.slogan">
          Powered by <a href="/" target="_blank">Caplena</a>
        </template>
        <template v-else>
          {{ dashboard.branding.slogan }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardBrandingPanel',
  codit: true,

  props: {
    dashboard: { type: Object, default: () => ({}), required: true }
  }
}
</script>
