import {
  PROJECT_NAME_DEMO_OPEN,
  PROJECT_NAME_DEMO_LIST
} from '@/settings/constants'

export default {
  methods: {
    /**
     * Check if this is a demo question. If yes, append the demo-{open,list} url query param
     * This enables us to target tutorials more easily
     * @param  {Object} furtherParams Further params to be appended to query, *if* this is a demo question
     */
    maybeAppendDemoInfoToURL (furtherParams = {}) {
      let demo = ''
      if (this.question.project_name === PROJECT_NAME_DEMO_OPEN) demo = 'open'
      if (this.question.project_name === PROJECT_NAME_DEMO_LIST) demo = 'list'

      let query = { ...this.$router.currentRoute.query, ...furtherParams, demo }

      if (demo && !_.isEqual(query, this.$router.currentRoute.query)) {
        this.$router.replace({
          name: this.$router.currentRoute.name,
          params: this.$router.currentRoute.params,
          query
        })
      }
    }
  }
}