import axios from 'axios'
import { HttpMaxAttemptsError } from '@/components/customUi/errors/httpError.js'

export default {
  data () {
    return {
      intervals: {},
      intervalAttempts: {}
    }
  },
  methods: {
    setExecuteTillCondition (key) {
      this.$set(this.intervals, key, null)
      this.$set(this.intervalAttempts, key, 0)
    },
    stopExecuteTillCondition (key) {
      clearInterval(this.intervals[key])
      this.setExecuteTillCondition(key)
    },
    /*
    * ASYNC-AWAIT UTILITIES
    */
    /**
    * It creates interval for fetching export list till condition is done.
    * @param {any} intervalName Name for interval, to abort if necessary
    * @param {any} condition Function it check if it's done
    * @param {any} maxAttempt Maximum number of attempts
    * @returns {Promise} A reference to promise
    */
    async executeTillCondition (intervalName, func, condition, maxAttempt = 30) {
      await new Promise((resolve, reject) => {
        this.intervals[intervalName] = setInterval(async () => {
          this.cancelTokens[intervalName]?.cancel()
          try {
            await func()
          } catch (error) {
            if (
              !axios.isCancel(error)
            ) {
              reject(error)
            }
          }

          const isDone = condition()

          if (
            isDone || this.intervalAttempts[intervalName] + 1 >= maxAttempt
          ) {
            clearInterval(this.intervals[intervalName])
            this.intervals[intervalName] = null
          }

          if (isDone) {
            resolve()
          } else if (
            this.intervalAttempts[intervalName] + 1 >= maxAttempt
          ) {
            reject(new HttpMaxAttemptsError('max attempts error'))
          } else {
            this.intervalAttempts[intervalName]++
          }
        }, 5000)
      })
    }
  }
}