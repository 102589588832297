var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{staticClass:"hint",attrs:{"max-width":"250","z-index":"100","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-alert',{staticClass:"hint__activator text-xs mb-0 px-2 py-1",attrs:{"icon":"mdi-information-outline","type":"warning","text":"","outlined":"","dense":""},on:{"mouseenter":function($event){return _vm.setTooltipDebounced(true)},"mouseleave":function($event){return _vm.setTooltipDebounced(false)}}},[_vm._v("\n      "+_vm._s(_vm.$t('warning_read_only_short'))+"\n    ")])]}}]),model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_vm._v(" "),_c('div',{staticClass:"hint__content",on:{"mouseenter":function($event){return _vm.setTooltipDebounced(true)},"mouseleave":function($event){return _vm.setTooltipDebounced(false)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('warning_read_only'))}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('request_access', {
      root_name: _vm.$escapeHtml(_vm.user.organization.root_user_name),
      root_email: _vm.user.organization.root_user_email,
      owner_name: _vm.$escapeHtml(_vm.dashboard.owner),
      owner_email: _vm.dashboard.owner_email
    })
    )}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }