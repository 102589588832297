<template>
  <div class="settings-drawer-item" :class="{ 'shake-short': animate }">
    <div
      class="pl-3 pr-1 overline sub-text bordered-bottom gray-bg overflown mb-3 d-flex align-center justify-space-between"
      :class="{ isFilter: isFilter }"
    >
      <div>
        {{ title || $t('ds.filters.type_label') }}
      </div>
      <div class="d-flex align-center">
        <button
          v-if="!isMaster && isFilter"
          @click="onMatchesClick"
          class="results-btn mr-1 d-flex align-center justify-center"
          small
          elevation="0"
        >
          {{ matches }}
        </button>
        <v-tooltip bottom v-if="isClosable">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="filters-btn" small @click="onRemoveClick">
              <v-icon class="delete" style="font-size: 17px; color: #6d7175;">
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('ds.filters.delete_helptip') }}</span>
        </v-tooltip>
      </div>
      <slot name="helptip" />
    </div>
    <slot name="content" />
    <div class="separator" />
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'SettingsDrawerItem',
  props: {
    title: { type: String, default: '' },
    renderSepearator: { type: Boolean, default: false },
    onRemoveClick: { type: Function, default: function () {} },
    isFilter: { type: Boolean, default: false },
    matches: { type: String, default: '' },
    onMatchesClick: { type: Function, default: () => ({}) },
    isMaster: { type: Boolean, default: false },
    applyMaster: { type: Function, default: () => ({}) },
    isClosable: { type: Boolean, default: false },
    animate: { type: Boolean, default: false }
  },

  mounted () {
    setTimeout(() => {
      if (this.animate && this.$el) {
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }, 500)
  }
}
</script>

<style lang=scss>
.settings-drawer-item {
  .separator {
    border-bottom: none !important;
  }

  .dropdown-menu {
    width: 200%;
  }

  .v-input__slider {
    position: relative;
    top: 10px;
  }

  .helptip-content.dark {
    letter-spacing: normal;
  }

  .overflown {
    border-top: 1px solid $border-color;
    border-bottom: none;
    line-height: 20px !important;
    padding: 8px 0;

    .results-btn {
      color: white;
      background-color: $green-color !important;
      text-transform: lowercase;
      border-radius: 25px !important;
      height: 23px !important;
      padding: 0 8px !important;
      position: relative;
      left: 4px;
    }
  }
}
</style>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />