/**
 * Gets list of data to provide
 * and create getter/setter for each
 * Accepts input of array {
 *   nameToUse: relative way to variable to provide reactivity
 * }
 *
 * Example:
 *
 * provide () {
 *   return generateProvide.call(this, {
 *     isAdditionalFieldEnabled: 'formState.settings'
 *   })
 * }
 *
 * @param {Object} object
 * @returns {Array} array
 */
export const generateProvide = function (object) {
  return Object.entries(object).reduce(
    (sum, [index, item]) => ({
      ...sum,
      [`get_${index}`]: () => _.get(this, item),
      [`set_${index}`]: (value) => {
        const pathToVariable = item.split('.')
        const obj = pathToVariable.reduce((sum, item, index) => {
          if (
            index === pathToVariable.length - 1
          ) {
            return sum
          }
          return sum[item]
        }, this)
        this.$set(obj, pathToVariable[pathToVariable.length - 1], value)
      }
    }), {}
  )
}

/**
 * Gets flat list of variables to inject
 * and accepts getter/setter for each
 * in order to reuse it as computed variable with getter/setter
 *
 * Example:
 * inject: generateInject(['isAdditionalFieldEnabled'])
 *
 * @param {Array} array
 * @returns {Array} array
 */
export const generateInject = array => array.reduce(
  (sum, item) => [
    ...sum,
    `get_${item}`,
    `set_${item}`
  ], []
)

/**
 * Gets flat list of variables to inject
 * and creates computed variable with getter/setter
 * and refers to injected values
 *
 * Make computations reative
 * and allows set computed variable as if an usual variable
 *
 * Example:
 * computed: {
 *  ...injectComputedValues(['isAdditionalFieldEnabled'])
 * }
 *
 * @param {Array} array
 * @returns {Array} array
 */
export const injectComputedValues = array => array.reduce(
  (sum, item) => ({
    ...sum,
    [item]: {
      get () {
        return this[`get_${item}`]()
      },
      set (value) {
        this[`set_${item}`](value)
      }
    }
  }), {}
)
