<template>
  <div
    :class="[
      'number-stepper d-flex body-2',
      {
        'number-stepper--border': isBordered
      }
    ]"
  >
    <div class="number-stepper__slot d-flex align-center px-2 text-no-wrap" v-if="$slots.prepend">
      <slot name="prepend" />
    </div>
    <v-text-field
      v-model.number="lazyInternalValue"
      v-bind="attrs"
      v-on="listeners"
      type="number"
      class="number-stepper__input mt-0 pa-0"
      hide-details
      flat
      solo
    />
    <div class="d-flex flex-column">
      <div class="number-stepper__button flex-grow-1" @click="incriminate">
        <div class="number-stepper__button-icon-placeholder">
          <v-icon small>
            mdi-chevron-up
          </v-icon>
        </div>
      </div>
      <div class="number-stepper__button flex-grow-1" @click="descriminate">
        <div class="number-stepper__button-icon-placeholder">
          <v-icon small>
            mdi-chevron-down
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataMixin from '@/components/customUi/mixins/data.js'

export default {
  mixins: [
    DataMixin
  ],
  props: {
    value: { type: [Number, null], default: null },

    isBordered: { type: Boolean, required: false, default: true },

    debounceTimeout: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data () {
    return {
      lazyInternalValue: this.value,
      debouncedSetInternalValue: this.debounceTimeout ? _.debounce(this.setInternalValue, this.debounceTimeout) : this.setInternalValue
    }
  },
  computed: {
    /**
     * safe minimum boundary
     * @return {Number}
     */
    minCalculated () {
      return !isNaN(this.$attrs.min) ? +this.$attrs.min : -Infinity
    },
    /**
     * safe maximum boundary
     * @return {Number}
     */
    maxCalculated () {
      return !isNaN(this.$attrs.max) ? +this.$attrs.max : +Infinity
    },

    attrs () {
      return _.omit(this.$attrs, 'bind')
    },

    /**
     * omits input event from $listeners to prevent double `@input` call on parent
     * @return {Object} of other listeners
     */
    listeners () {
      return _.omit(this.$listeners, ['input', 'blur'])
    }
  },
  watch: {
    lazyInternalValue (value) {
      this.setLazyValue(value)
      this.debouncedSetInternalValue()
    }
  },
  methods: {
    incriminate () {
      this.setLazyValue(this.lazyInternalValue + 1)
    },
    descriminate () {
      this.setLazyValue(this.lazyInternalValue - 1)
    },
    setLazyValue (value) {
      if (
        value !== null
      ) {
        this.lazyInternalValue = +value
      } else {
        this.lazyInternalValue = value
        return
      }

      this.$nextTick(() => {
        if (
          value === ''
        ) {
          this.lazyInternalValue = null
        } else if (value < this.minCalculated) {
          this.lazyInternalValue = this.minCalculated
        } else if (value > this.maxCalculated) {
          this.lazyInternalValue = this.maxCalculated
        }
      })
    },
    setInternalValue (value) {
      if (
        this.internalValue !== this.lazyInternalValue
      ) {
        this.internalValue = this.lazyInternalValue
      }
    }
  }
}
</script>

<style lang="scss">
  .number-stepper {
    overflow: hidden;
    user-select: none;

    &--border{
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    }

    &__slot{
      cursor: default;
      border-right: 1px solid rgba(0, 0, 0, 0.15)
    }
    &__input{
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0 !important;

      &.v-text-field.v-text-field--solo{
        .v-input__control{
          min-height: 0;
          height: 100%;
          font-size: 14px;
        }
      }
      .v-input__slot:before{
        content: none !important;
      }
      input{
        min-width: 20px !important;
        min-height: 30px;
        height: 30px;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    &__button{
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      padding: 0 12px;
      background: rgba(0, 0, 0, 0.1);

      &:not(:last-child){
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }

      &-icon{
        &-placeholder{
          margin: -2px 0;
        }
      }
    }
  }
</style>