import { generateProvide } from '@/components/customUi/mixins/provideInject'

export default {
  props: {
    isSummaryEnabled: { type: Boolean, default: false },
    queryParams: { type: Object, default: () => ({}) }
  },

  data () {
    return {
      state: {
        loadingSummary: false,
        loadingRows: false
      }
    }
  },

  provide () {
    return generateProvide.call(this, {
      loadingSummary: 'state.loadingSummary',
      loadingRows: 'state.loadingRows'
    })
  },

  computed: {
    filtersFromQuery () {
      if (!this.queryParams?.filters) return []
      return JSON.parse(this.queryParams.filters).filter(item => !!Object.keys(item).length)
    },
    filtersProps () {
      return {
        id: this.id,
        filtersFromQuery: this.filtersFromQuery,
        filtersFromConfig: this.config?.filters || [],
        filtersFromOpenAction: this.filterFromOpenAction || []
      }
    },
    paginationString () {
      return this.$store.getters['verbatimManager/paginationString'](this.id)
    }
  }
}
