<!-- ====== View Options dialog ===== -->
<template>
  <div>
    <v-overlay :value="viewOptions.open" color="rgba(5, 32, 46, 0.5)" />
    <v-dialog
      v-model="viewOptions.open"
      @keydown.esc="viewOptions.open = false"
      persistent
      hide-overlay
      eager
      width="400px"
      content-class="dashboard-filter-editor"
    >
      <div>
        <div class="dashboard-filter-editor__title w-100 d-flex align-center justify-space-between">
          {{ $t('view-opts.title') }}
          <v-icon
            class="hover"
            @click.stop="viewOptions.open = false"
            :size="20"
          >
            mdi-close
          </v-icon>
        </div>
        <div class="dashboard-filter-editor__content">
          <label class="font-weight-medium text-sm">
            {{ $t('view-opts.additionalColumn') }}
          </label>
          <!-- TODO: change this into textfield and v-menu, v-select was dumb... -->
          <v-select
            :value="columnsShownSelectValue"
            :items="auxiliaryColumnOptions"
            class="search-bar__search search-bar__search--large w-100 mb-5 mt-1"
            multiple
            dense
            hide-details
            :placeholder="$t('view-opts.additionalColumnPlaceholder')"
            label=""
            :height="40"
            item-text="name"
            item-value="ref"
            :menu-props="{
              'offset-y': true,
              'ripple': false
            }"
          >
            <template v-slot:selection="selectItem">
              <div v-if="!columnsShown.length">
                <span class="text-sm text-grey">
                  {{ $t('view-opts.additionalColumnPlaceholder') }}
                </span>
              </div>
              <div v-if="!selectItem.index && columnsShown.length" class="v-select__selection text-sm">
                {{ selectItem.item.name }}
              </div>
              <span v-if="selectItem.index === 1 && columnsShown.length > 1" class="text-xs ml-1 text-grey">
                (+{{ columnsShown.length - 1 }} {{ $t('view-opts.others') }})
              </span>
            </template>
            <template v-slot:item="{ item, index }">
              <div
                class="filters__list__check w-100 d-flex align-center"
                :key="index"
              >
                <v-checkbox
                  :input-value="auxiliaryColumnsShownList[getItemIndex(item)]"
                  @change="toggleShownAuxiliaryColumn(getItemIndex(item))"
                  primary
                  hide-details
                  :ripple="false"
                  color="green"
                  off-icon="mdi-checkbox-blank"
                  flat
                  :label="item.name"
                />
              </div>
            </template>
          </v-select>
          <div class="pt-2">
            <v-switch
              v-model="viewOptions.groupIdentical"
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--reverse v-input--description w-100"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <div class="text-sm font-weight-medium text-color mb-1">
                    {{ $t('group_identical.title') }}
                  </div>
                  <div class="text-grey text-sm pr-2" v-html="$t('group_identical.helptip')" />
                </div>
              </template>
            </v-switch>
          </div>

          <div v-if="project.translation_engine" class="mt-2">
            <v-switch
              v-model="viewOptions.showTranslation"
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--reverse v-input--description w-100"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <div class="text-sm font-weight-medium text-color mb-1">
                    {{ $t('view-opts.translations') }}
                  </div>
                  <div class="text-grey text-sm pr-2" v-html="$t('view-opts.translations_helptip')" />
                </div>
              </template>
            </v-switch>
          </div>
        </div>
        <div class="dashboard-filter-editor__actions d-flex justify-space-between">
          <div />
          <div>
            <v-btn
              outlined
              @click.stop="viewOptions.open = false"
              class="mr-2"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              @click.stop="$emit('apply')"
            >
              {{ $t('apply') }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

import Vuex from 'vuex'
import { mapGettersWithKey } from '@/utils/vuex.js'

export default {
  codit: true,
  name: 'ViewOptions',
  props: {
    value: { type: Object, default: () => ({}) },
    id: { type: String, default: '' }
  },
  computed: {
    viewOptions: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    },
    ...Vuex.mapState({
      rows: state => state.coding.rows,
      editable: state => state.coding.editable,
      codingColumn: state => state.coding.codingColumn,
      project: state => state.coding.project
    }),

    ...Vuex.mapGetters([
      'auxiliaryColumns'
    ]),

    ...mapGettersWithKey({
      columnsShown: 'verbatimManager/columnsShown'
    })(() => 'ch__new'),

    auxiliaryColumnOptions () {
      return this.auxiliaryColumns
    },

    columnsShownSelectValue () {
      return _.map(this.columnsShown, colIdx => this.auxiliaryColumns[colIdx])
    },

    auxiliaryColumnsShownList () {
      return _.map(this.auxiliaryColumnOptions, (col, index) => {
        if (this.columnsShown.includes(index)) return true
        return false
      })
    }
  },

  methods: {
    getItemIndex (item) {
      return _.findIndex(this.auxiliaryColumns, col => _.isEqual(col, item))
    },

    /**
     * Toggle showing auxiliary column
    */
    toggleShownAuxiliaryColumn (idx) {
      if (this.columnsShown.includes(idx)) {
        this.$store.commit('verbatimManager/setAuxiliaryColumnIdxShown', { id: this.id, value: this.columnsShown.filter(i => i !== idx) })
      } else {
        this.$store.commit('verbatimManager/setAuxiliaryColumnIdxShown', { id: this.id, value: [...this.columnsShown, idx] })
      }
    }
  }

}

</script>

<i18n locale='en' src='@/i18n/en/pages/CodingFullyOpen.json' />
<i18n locale='de' src='@/i18n/de/pages/CodingFullyOpen.json' />