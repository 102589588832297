<template>
  <div
    class="dropdown coding__drawer__code-select"
    v-c-click-outside="onClickOutside"
    :class="dropdownClasses"
    @click="toggleDropdown"
    @mousemove="onMouseMove"
  >
    <div v-if="!hideLabel && showResults" class="coding__drawer__applied-codes">
      {{
        $t(
          `sidebar.${categorySelect ? "applied_categories" : "applied_codes"}`,
          { count: value.length }
        )
      }}
    </div>
    <div v-if="!value.length && !hideLabel && showResults" class="text-sm mt-1">
      {{ $t(`sidebar.${categorySelect ? "empty_categories" : "empty_codes"}`) }}
    </div>
    <div ref="toggle">
      <draggable
        v-model="draggableValue"
        draggable="div.moving-container"
        :animation="150"
        :fallback-tolerance="20"
        :disabled="!editable"
        @start="draggingStarted"
        @end="draggingCompleted"
        style="align-self: center; flex: 1; position: relative;"
        ref="draggable"
        :class="{
          'is-dragging': isDragging,
          'is-dragging-lazy': isDraggingLazy
        }"
      >
        <template v-if="categorySelect && showResults">
          <code-chip
            v-for="(category, index) in value"
            :key="index"
            :id="String(index)"
            :sentiment-enabled="false"
            :category="category"
            :label="''"
            :color="catColors[category]"
            :is-highlighted="isHighlighted(category)"
            :deletable="editable"
            @delete="removeCategory(category)"
          />
        </template>
        <div class="moving-container"
             v-else-if="showResults"
             v-for="topic in value"
             :key="topic.id"
        >
          <v-tooltip
            bottom
            :disabled="!topicID2Topic[topic.id].description || !topicID2Topic[topic.id].description.length"
            max-width="400px"
          >
            {{ topicID2Topic[topic.id].description }}
            <template v-slot:activator="{ on }">
              <div v-on="on">
                {{ topic.description }}
                <code-chip
                  :id="topic.id"
                  :sentiment-enabled="
                    static
                      ? topic.sentiment && topicID2Topic[topic.id].sentiment_enabled
                      : topicID2Topic[topic.id].sentiment_enabled
                  "
                  :sentiment="topic.sentiment"
                  :category="topicID2Topic[topic.id].category"
                  :label="topicID2Topic[topic.id].label"
                  :color="catColors[topicID2Topic[topic.id].category]"
                  :is-highlighted="isHighlighted(topic)"
                  :deletable="editable"
                  @delete="deselect(topic)"
                  @change-sentiment="selectTopicSentiment"
                  from-drawer
                />
              </div>
            </template>
          </v-tooltip>
        </div>
        <div
          class="coding__drawer__input"
          :class="{
            'coding__drawer__applied-codes--margin': showResults
          }"
        >
          <div
            v-if="editable && !showOnlyResults"
            :class="[
              'coding__drawer__applied-codes',
              {
                'mt-1': hideLabel && !value.length
              }
            ]"
          >
            {{ title }}
          </div>
          <div v-if="editable && !showOnlyResults" class="position-relative">
            <input
              :class="[
                'coding__drawer__code-select__input',
                'text-sm',
                {
                  'coding__drawer__code-select__input--disabled': disabled,
                  'coding__drawer__code-select__input--open':
                    dropdownOpen &&
                    !selectedOptionPointer &&
                    !(selectedOptionPointer === 0)
                }
              ]"
              :placeholder="
                placeholder.length ? placeholder : $t('sidebar.search_codes')
              "
              ref="search"
              v-model="search"
              :maxlength="MAX_TOPIC_LABEL_LEN"
              @keydown="onKeyDown"
              @keydown.delete="onDeleteDown"
              @keyup.delete="onDeleteUp"
              @keydown.left.stop="onLeft"
              @keydown.right.stop="onRight"
              @keydown.esc.prevent.stop="onEscape"
              @keydown.up.prevent.stop="onUp"
              @keydown.down.prevent.stop="onDown"
              @keydown.enter.prevent="onEnter"
              type="search"
              :disabled="disabled || !editable || blockedByModals"
              aria-label="Search for option"
            >
            <v-icon
              class="coding__drawer__code-select__input__indicator"
              :class="
                dropdownOpen &&
                  'coding__drawer__code-select__input__indicator--open'
              "
            >
              mdi-chevron-down
            </v-icon>
          </div>
          <div
            v-if="dropdownOpen && editable"
            ref="topic-list"
            class="coding__drawer__code-select__dropdown"
            :class="{
              'coding__drawer__code-select__dropdown--static': !renderMenuOnTop && static,
              'coding__drawer__code-select__dropdown--top': renderMenuOnTop
            }"
            :style="{
              'max-height': maxDropDownHeight,
              'min-height': renderMenuOnTop ? 'none' : '150px'
            }"
          >
            <div ref="categoriesList">
              <div
                v-for="(catOptions, category) in filteredOptionsByCategory"
                class="coding__drawer__code-select__dropdown__category"
                :key="cats2catIdx[category]"
                :ref="`category-${cats2catIdx[category]}`"
                :style="{ color: catColors[category].strong }"
                :class="{
                  'coding__drawer__code-select__dropdown__category--highlighted': isHighlighted(
                    category
                  ),
                  'coding__drawer__code-select__dropdown__category--open':
                    categoryIsExpanded(category) && !categorySelect
                }"
                :title="category"
                @click.stop.prevent="toggleCategory(category)"
              >
                <div
                  class="d-flex align-center justify-space-between coding__drawer__code-select__dropdown__category__padding"
                  @mouseover="onMouseOverOption(category, $event)"
                >
                  <div
                    class="coding__drawer__code-select__dropdown__category__title"
                    v-html="catOptions[0].categoryMatched"
                  />
                  <v-btn
                    class="coding__drawer__code-select__dropdown__category__icon"
                    icon
                    small
                  >
                    <v-icon
                      :size="20"
                      class="coding__drawer__code-select__input__indicator coding__drawer__code-select__input__indicator--category"
                      :class="
                        categoryIsExpanded(category) &&
                          'coding__drawer__code-select__input__indicator--open'
                      "
                    >
                      {{ !categorySelect ? "mdi-chevron-down" : "" }}
                    </v-icon>
                  </v-btn>
                </div>
                <div
                  v-if="categoryIsExpanded(category) && !categorySelect"
                  ref="codesList"
                  class="coding__drawer__code-select__dropdown__category__dropdown"
                  :style="{ 'max-height': '100%' }"
                >
                  <div
                    v-for="(option, index) in catOptions"
                    :key="option['id']"
                    :ref="`topic-${option['id']}`"
                    class="coding__drawer__code-select__dropdown__category__topic"
                    :class="{
                      'coding__drawer__code-select__dropdown__category__topic--highlighted': isHighlighted(
                        option
                      )
                    }"
                    :title="option['description']"
                    :data-title="option['description']"
                    @mouseover="onMouseOverOption(option, $event)"
                    @click.stop="select(option)"
                  >
                    <div
                      class="d-flex align-center justify-space-between coding__drawer__code-select__dropdown__category__padding w-100"
                    >
                      <div class="d-flex align-center">
                        <div
                          class="coding__drawer__code-select__dropdown__category__topic__title"
                          v-html="option['label']"
                        />
                        <div
                          v-if="option.sentiment_enabled && showSentiment"
                          class="d-flex coding__drawer__code-select__dropdown__category__topic__sentiments"
                        >
                          <v-tooltip
                            :value="sentimentPointer === 0"
                            :attach="
                              `#${getTooltipForSentimentTopic(index, category)}`
                            "
                            top
                          >
                            <template v-slot:activator="{ on }">
                              <div
                                v-on="on"
                                :id="getTooltipForSentimentTopic(index, category)"
                              >
                                <v-btn
                                  icon
                                  small
                                  @click.stop="
                                    selectTopicSentiment(option.id, 'positive')
                                  "
                                  :class="sentimentPointer === 0 && 'highlighted'"
                                  @mouseover="resetSentimentPointer"
                                >
                                  <sentiment sentiment="positive" />
                                </v-btn>
                              </div>
                            </template>
                            <span v-html="$t('sentiment.positive')" />
                          </v-tooltip>
                          <v-tooltip
                            :value="sentimentPointer === 1"
                            :attach="
                              `#${getTooltipForSentimentTopic(index, category)}`
                            "
                            top
                          >
                            <template v-slot:activator="{ on }">
                              <div
                                v-on="on"
                                :id="getTooltipForSentimentTopic(index, category)"
                              >
                                <v-btn
                                  icon
                                  small
                                  @click.stop="
                                    selectTopicSentiment(option.id, 'neutral')
                                  "
                                  :class="sentimentPointer === 1 && 'highlighted'"
                                  @mouseover="resetSentimentPointer"
                                >
                                  <sentiment sentiment="neutral" />
                                </v-btn>
                              </div>
                            </template>
                            <span v-html="$t('sentiment.neutral')" />
                          </v-tooltip>
                          <v-tooltip
                            :value="sentimentPointer === 2"
                            :attach="
                              `#${getTooltipForSentimentTopic(index, category)}`
                            "
                            top
                          >
                            <template v-slot:activator="{ on }">
                              <div
                                v-on="on"
                                :id="getTooltipForSentimentTopic(index, category)"
                              >
                                <v-btn
                                  icon
                                  small
                                  @click.stop="
                                    selectTopicSentiment(option.id, 'negative')
                                  "
                                  :class="sentimentPointer === 2 && 'highlighted'"
                                  @mouseover="resetSentimentPointer"
                                >
                                  <sentiment sentiment="negative" />
                                </v-btn>
                              </div>
                            </template>
                            <span v-html="$t('sentiment.negative')" />
                          </v-tooltip>
                        </div>
                      </div>
                      <div
                        class="coding__drawer__code-select__dropdown__category__topic__id"
                        v-html="option['idKey']"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!filteredOptions.length"
              class="coding__drawer__code-select__dropdown__empty"
            >
              {{
                categorySelect
                  ? $t("filters.categories_no_match")
                  : $t("filters.topics_no_match")
              }}
            </div>
            <v-btn
              class="coding__drawer__code-select__dropdown__new"
              :class="
                selectedOption &&
                  selectedOption.type === 'new' &&
                  'coding__drawer__code-select__dropdown__new--highlighted'
              "
              ref="new-topic"
              depressed
              outlined
              v-if="allowNew"
              @click.stop.prevent="triggerNewCode()"
              @mouseover="onMouseOverOption('new', $event)"
            >
              {{
                $t("new_topic.name_label", {
                  label: search.length ? `'${search}'` : ""
                })
              }}
            </v-btn>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

import draggable from 'vuedraggable'

import CodeChip from '@/components/coding/CodeChipv2'
import Sentiment from '@/components/coding/Sentiment'
import { isElementVisible } from '@/utils/funcs'

import { mapGettersWithKey } from '@/utils/vuex.js'

import { MAX_TOPIC_LABEL_LEN } from '@/settings/constants'

const SCROLL_UP = 1
const SCROLL_DOWN = -1
const SENTIMENT_KEY_MAP = {
  0: 'positive',
  1: 'neutral',
  2: 'negative'
}

export default {
  codit: true,
  name: 'TopicSelect',
  components: {
    draggable,
    CodeChip,
    Sentiment
  },

  props: {
    /**
     * If a new code creation may be triggered from this select
     * If true that option appears when no matches are found
     */

    allowNew: {
      type: Boolean,
      default: false
    },

    /* eslint-disable vue/require-default-prop */
    /* eslint-disable vue/require-prop-types */
    /**
     * Contains the currently selected value. Very similar to a
     * `value` attribute on an <input>. You can listen for changes
     * using 'change' event using v-on
     * @type {Object||String||null}
     */
    value: {
      default: null
    },

    placeholder: {
      type: String,
      default: ''
    },

    sync: {
      type: Boolean,
      default: false
    },

    static: {
      type: Boolean,
      default: false
    },

    categorySelect: {
      type: Boolean,
      default: false
    },

    /**
     *
     * Disable the entire component.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * Close a dropdown when an option is chosen. Set to false to keep the dropdown
     * open (useful when combined with multi-select, for example)
     * @type {Boolean}
     */
    closeOnSelect: {
      type: Boolean,
      default: false
    },

    /**
     * An optional callback function that is called each time the selected
     * value(s) change. When integrating with Vuex, use this callback to trigger
     * an action, rather than using :value.sync to retreive the selected value.
     * @type {Function}
     * @param {Object || String} val
     */
    onChange: {
      type: Function,
      default: function () {
        this.$emit('change')
      }
    },

    /**
     * Don't display the ID in the list (and also don't make it searchable)
     * @type {Boolean}
     */
    hideId: {
      type: Boolean,
      default: false
    },

    hideLabel: {
      type: Boolean,
      default: false
    },

    editable: {
      type: Boolean,
      default: true
    },

    blockedByModals: {
      type: Boolean,
      default: false
    },

    previousRow: {
      type: Function,
      default: () => {}
    },

    nextRow: {
      type: Function,
      default: () => {}
    },

    title: {
      type: String,
      default: function () {
        return this.$t('sidebar.add_codes')
      }
    },

    showResults: {
      type: Boolean,
      default: true
    },

    showOnlyResults: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      default: ''
    },

    showSentiment: {
      type: Boolean,
      default: true
    },

    reactOnKeydown: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      search: '',
      open: false,
      selectedOptionPointer: null,
      sentimentPointer: null,
      deleting: false,
      preventMouseOver: true,
      newCodeSelected: false,
      newCodeTriggeredFromHere: false,
      isDragging: false,
      // Variable that keeps activated after drag until the next mousemove
      // To fix https://bugs.chromium.org/p/chromium/issues/detail?id=410328
      isDraggingLazy: false,
      categoriesOpen: [],
      inputOffset: 0,

      MAX_TOPIC_LABEL_LEN
    }
  },

  computed: {
    // ???
    ...Vuex.mapState({
      activeRowIndex: state => state.coding.activeRowIndex,
      newTopicDialog: state => state.coding.newTopicDialog
    }),

    ...Vuex.mapGetters(['activeRow', 'bulkAssign']),

    ...mapGettersWithKey({
      dummyRows: 'verbatimManager/dummyRows',
      topicID2Topic: 'metaManager/topicID2Topic',
      catColors: 'metaManager/catColors',
      topicCats: 'metaManager/topicCats'
    })(function () {
      return this.id
    }),

    topics () {
      return this.$store.getters['metaManager/state'](this.id)['topics_complete_union']
    },

    /**
     * Max height of the dropdown window
     */
    maxDropDownHeight () {
      if (this.renderMenuOnTop) {
        return this.inputOffset - 95 + 'px'
      }
      if (this.static) return 'none'
      return window.innerHeight - this.inputOffset - 70 + 'px'
    },

    renderMenuOnTop () {
      if (window.innerHeight - this.inputOffset < 400) return true
      return false
    },

    draggableValue: {
      get () {
        return this.value
      },
      set (value) {
        this.resortTopics(value)
      }
    },

    /**
     * Classes to be output on .dropdown
     * @return {Object}
     */
    dropdownClasses () {
      return {
        open: this.dropdownOpen,
        searching: this.searching,
        disabled: this.disabled
      }
    },

    /**
     * Return the current state of the
     * search input
     * @return {Boolean} True if non empty value
     */
    searching () {
      return !!this.search
    },

    /**
     * Return the current state of the
     * dropdown menu.
     * @return {Boolean} True if open
     */
    dropdownOpen () {
      return this.open
    },

    topicsNotSelected () {
      return this.topics.filter(
        topic => !_.find(this.value, t => t.id === topic.id)
      )
    },

    topicsSorted () {
      return _(this.topicsNotSelected)
        .groupBy('category')
        .flatMap()
        .value()
    },

    filteredOptionsByCategory () {
      return this.categorySelect
        ? _.omit(_.groupBy(this.filteredOptions, 'category'), this.value)
        : _.groupBy(this.filteredOptions, 'category')
    },

    dropdownOptionsStateMap () {
      let state = []

      _.forEach(this.value, assignedTopic => {
        state.push({ type: 'chip', option: assignedTopic })
      })

      _.forEach(this.filteredOptionsByCategory, (value, key) => {
        state.push({ type: 'category', option: key })

        if (this.categoryIsExpanded(key) && !this.categorySelect) {
          _.forEach(value, topic => {
            state.push({ type: 'topic', option: topic })
          })
        }
      })

      if (this.allowNew) state.push({ type: 'new', option: 'new' })

      return state
    },

    selectedOption () {
      return this.dropdownOptionsStateMap[this.selectedOptionPointer]
    },

    /**
     * Mapping of a category to a unique index
     * (used for referecing in DOM, to prevent issues with special characters)
     * @return {Objects} String -> Number
     */
    cats2catIdx () {
      let mapping = {}
      this.topicCats.forEach((cat, idx) => {
        mapping[cat] = idx
      })
      return mapping
    },

    /**
     * The currently displayed options, filtered
     * by the search elements value. If tagging
     * true, the search text will be prepended
     * if it doesn't already exist.
     *
     * @return {array}
     */
    filteredOptions () {
      let options = []
      let optionsByCategory = {}
      this.topicCats.forEach(c => {
        optionsByCategory[c] = { score: 0, codes: [] }
      })
      this.topicsSorted.forEach(option => {
        if (this.value.indexOf(option['id']) === -1) {
          let terms = _.trim(this.search.toLowerCase()).split(' ').map(t => t.replace(/&/g, '&amp;'))
          let lbl = this.$escapeHtml(option['label'])
          let cat = this.$escapeHtml(option['category'])
          let ident = this.hideId
            ? ''
            : option.sentiment_enabled && !this.static
              ? `${option.sentiment_positive.code} / ${option.sentiment_neutral.code} / ${option.sentiment_negative.code}`
              : `${option.sentiment_neutral.code}`
          let catMatch = ''
          let identMatch = ''
          let lblMatch = ''

          let score = 0
          let found = true

          terms.forEach(t => {
            if (!found || t.length === 0) return

            let foundTerm = false

            // Search for matching category
            let idxCat = cat.toLowerCase().indexOf(t)
            if (idxCat !== -1) {
              // Remove the text portion and wrap + add it to resulting category string
              score -= Number(idxCat === 0) * 1000 - cat.length
              catMatch +=
                cat.slice(0, idxCat) +
                '<b>' +
                cat.slice(idxCat, idxCat + t.length) +
                '</b>'
              cat = cat.slice(idxCat + t.length)
              foundTerm = true
            }

            // Search for matching identifier
            let idxIdent = ident.indexOf(t)
            if (
              (foundTerm && idxIdent === 0) ||
              (!foundTerm && idxIdent !== -1)
            ) {
              score -= Number(idxIdent === 0) * 10000 - ident.length
              identMatch +=
                ident.slice(0, idxIdent) +
                '<b>' +
                ident.slice(idxIdent, idxIdent + t.length) +
                '</b>'
              ident = ident.slice(idxIdent + t.length)
              foundTerm = true
            }

            // Search for matching label
            let idxLbl = lbl.toLowerCase().indexOf(t)
            if ((foundTerm && idxLbl === 0) || (!foundTerm && idxLbl !== -1)) {
              score -= Number(idxLbl === 0) * 10000 - lbl.length
              lblMatch +=
                lbl.slice(0, idxLbl) +
                '<b>' +
                lbl.slice(idxLbl, idxLbl + t.length) +
                '</b>'
              lbl = lbl.slice(idxLbl + t.length)
              foundTerm = true
            }

            found = found && foundTerm
          })

          if (found) {
            let opt = {
              ...option,
              id: option['id'],
              idKey: this.hideId ? '' : `${identMatch}${ident}`,
              label: `${lblMatch}${lbl}`,
              categoryMatched: catMatch + cat,
              category: option['category'],
              description: option['description'],
              score
            }

            let optCat = optionsByCategory[option['category']]
            optCat.score = Math.min(score, optCat.score)
            optCat.codes.push(opt)
          }
        }
      })

      _.sortBy(optionsByCategory, 'score').forEach(cat => {
        _.sortBy(cat.codes, 'score').forEach(code => options.push(code))
      })

      return options
    },

    /**
     * Check if there aren't any options selected.
     * @return {Boolean}
     */
    isValueEmpty () {
      if (this.value) {
        if (typeof this.value === 'object') {
          return !Object.keys(this.value).length
        }
        return !this.value.length
      }

      return true
    }
  },

  watch: {
    /**
     * When the value prop changes, update
     * the internal value.
     * @param  {mixed} val
     * @return {void}
     */
    filteredOptions (opts) {
      this.selectedOptionPointer = this.search.length ? 0 : null
      this.newCodeSelected = opts.length === 0
    },

    activeRow (val, oldVal) {
      if (!_.isEqual(val, oldVal)) {
        this.categoriesOpen = []
        this.search = ''
      }
    },

    search (val) {
      this.selectedOptionPointer = null
      // Special case when there are no other codes: The `filteredOptions` property didn't change
      // therefore focus on the new code if anything is typed
      if (!this.filteredOptions.length && this.search) {
        this.newCodeSelected = true
      } else if (this.newCodeSelected) this.newCodeSelected = false

      if (val.length) {
        this.categoriesOpen = _.map(
          this.filteredOptionsByCategory,
          (catOptions, category) => category
        )

        const foundTopicIndex = _.findIndex(
          this.dropdownOptionsStateMap,
          option => option.type === 'topic'
        )
        const newTopicIndex = _.findIndex(
          this.dropdownOptionsStateMap,
          option => option.type === 'new'
        )

        this.selectedOptionPointer =
          foundTopicIndex !== -1 ? foundTopicIndex : newTopicIndex || 0
      } else this.categoriesOpen = []
    },

    // idx () {
    //   this.selectedOptionPointer = null
    //   this.deleting = false
    // },

    open (value) {
      this.$emit('open', value)
    }
  },

  updated () {
    if (!this.editable || this.showOnlyResults) return
    this.inputOffset = this.$refs.search.getBoundingClientRect().top
  },

  /**
   * Clone props into mutable values,
   * attach any event listeners.
   */
  created () {
    if (!(this.topicsNotSelected instanceof Array)) {
      console.error('[vue-select] Expecting options to be of type Array')
    }
    if (!(this.value instanceof Array)) {
      console.error('[vue-select] Expecting value to be of type Array')
    }
  },

  mounted () {
    window.addEventListener('keydown', this.keydown)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.keydown)
  },

  destroyed () {},

  methods: {
    keydown (e) {
      this.preventMouseOver = true

      const inp = String.fromCharCode(e.keyCode)

      // Dropdown is closed and user typed something, or pressed enter
      if (
        !this.open &&
        (/[a-zA-Z0-9-_ ]/.test(inp) || e.keyCode === 13) &&
        !this.newTopicDialog?.open &&
        !e.metaKey &&
        !e.ctrlKey &&
        this.reactOnKeydown
      ) {
        this.openDropdown()
      }
    },

    onMouseOverOption (option, e) {
      // Only fire if the mouse really moved
      if (this.preventMouseOver) return
      this.selectedOptionPointer = _.findIndex(
        this.dropdownOptionsStateMap,
        options => _.isEqual(options.option, option)
      )
    },

    addTopic (topicID) {
      if (this.sync) {
        const topic = _.find(this.topics, topic => topic.id === topicID)
        this.$emit('update', [...this.value, _.clone(topic)])
      } else {
        this.$store.commit('addTopicToReview', { topicID })
        this.$emit('update')
      }
    },

    removeTopic (topicID) {
      if (this.sync) {
        this.$emit(
          'update',
          _.filter(this.value, topic => topic.id !== topicID)
        )
      } else {
        this.$store.commit('removeTopicFromReview', { topicID })
        this.$emit('update')
        // }
      }
    },

    resortTopics (topics) {
      if (this.sync) {
        this.$emit('update', topics)
      } else {
        this.$store.commit('resortTopics', { topics })
        this.$emit('update')
      }
    },

    selectTopicSentiment (topicID, sentiment, shouldFocus = true) {
      const prevPointer = this.selectedOptionPointer

      if (this.sync) {
        const topicIdx = _.findIndex(this.value, topic => topic.id === topicID)

        if (topicIdx !== -1) {
          this.$emit(
            'update',
            _.map(this.value, v => {
              if (v.id === topicID) return { ...v, sentiment }
              return v
            })
          )
        } else {
          const topic = _.clone(
            _.find(this.topics, topic => topic.id === topicID)
          )
          topic.sentiment = sentiment

          this.$emit('update', [...this.value, topic])
        }
      } else {
        this.$store.dispatch('changeReviewSentiment', { topicID, sentiment })
        this.onAfterSelect()
        this.$emit('update')
      }

      if (shouldFocus) {
        this.focus()
        this.search = ''
        this.$nextTick(() => { this.selectedOptionPointer = null })
      } else {
        // chip sentiment select
        this.$nextTick(() => {
          this.selectedOptionPointer = prevPointer
        })
      }
    },

    /**
     * Checks if topic sentiment is enabled
     * reason behind this is that sentiment_enabled is only available on state.topics
     * rather than on every row
     */
    checkIfSentimentEnabled (topidId) {
      const found = _.find(this.topics, topic => topic.id === topidId)

      return found ? found.sentiment_enabled : false
    },

    draggingStarted () {
      this.isDraggingLazy = this.isDragging = true
    },

    draggingCompleted () {
      this.isDragging = false
      if (this.open) {
        this.focus()
      }
    },

    getTooltipForSentimentTopic (index, category) {
      return `coding__drawer__code-select__dropdown__category__topic__sentiments--positive--${index}--${this.cats2catIdx[category]}`
    },

    onValueChanged () {
      if (this.onChange) this.onChange()
    },

    onMouseMove () {
      if (this.preventMouseOver) this.preventMouseOver = false
      if (!this.isDragging && this.isDraggingLazy) {
        this.$nextTick(() => {
          this.isDraggingLazy = false
        })
      }
    },

    onKeyDown () {
      this.preventMouseOver = true
    },

    focus () {
      this.$refs.search && this.$refs.search.focus()
    },

    blur () {
      this.$refs.search && this.$refs.search.blur()
    },

    triggerNewCode () {
      this.$store.commit('setNewTopicDialog', { key: 'open', value: true })
      this.$store.commit('setNewTopicDialog', {
        key: 'name',
        value: this.search
      })
    },

    /**
     * Select a given option.
     * @param  {Object|String} option
     * @return {void}
     */
    select (option) {
      if (
        option.sentiment_enabled &&
        !this.static &&
        this.sentimentPointer === null
      ) {
        this.$root.snackMsg(this.$t('force_sentiment'))
      } else {
        this.addTopic(option['id'])
        this.onAfterSelect()
      }
    },

    /**
     * Expand the category
     * @param {Object} category
     * @return {void}
     */
    toggleCategory (category) {
      if (this.categorySelect) {
        this.$emit('update', [...this.value, category])
      } else {
        if (!this.categoryIsExpanded(category)) {
          this.categoriesOpen = [...this.categoriesOpen, category]
        } else {
          this.categoriesOpen = _.filter(
            this.categoriesOpen,
            cat => !(cat === category)
          )
        }
      }

      this.focus()
    },

    /**
     * Checks if category is open
     * @param {Object} category
     * @return {Boolean}
     */
    categoryIsExpanded (category) {
      return _.includes(this.categoriesOpen, category)
    },

    isHighlighted (option) {
      if (this.selectedOptionPointer === null || !this.selectedOption) return false
      else if (typeof this.selectedOption.option === 'string') {
        return this.selectedOption.option === option
      } else {
        return this.selectedOption.option.id === option.id
      }
    },

    /**
     * De-select a given option.
     * @param  {Object|String} option
     * @return {void}
     */
    deselect (topic) {
      this.onValueChanged()
      this.removeTopic(topic.id)
    },

    /**
     * De-select given catgory
     * @return {void}
     */
    removeCategory (category) {
      this.onValueChanged()
      this.$emit(
        'update',
        this.value.filter(cat => cat !== category)
      )
    },

    /**
     * Called from this.select after each selection.
     * @param  {Object|String} option
     * @return {void}
     */
    onAfterSelect () {
      // unselect chips
      if (this.closeOnSelect) {
        this.open = !this.open
        this.closeDropdown()
      } else {
        this.search = ''
        this.focus()
      }
      this.onValueChanged()
    },

    /**
     * Toggle the visibility of the dropdown menu.
     * @param  {Event} e
     * @return {void}
     */
    toggleDropdown (e) {
      if (this.open) {
        this.closeDropdown()
      } else {
        if (!this.disabled) {
          this.openDropdown()
        }
      }
    },

    /**
     * If there is any text in the search input, remove it.
     * Otherwise, blur the search input to close the dropdown.
     * @return {void}
     */
    onEscape (e) {
      if (!this.search.length) {
        this.closeDropdown()
      } else {
        this.search = ''
      }
    },

    onLeft (e) {
      if (!this.selectedOption) return

      const { type, option } = this.selectedOption

      if (type === 'topic' && this.topicID2Topic[option.id].sentiment_enabled) {
        this.sentimentPointer > 0 && this.sentimentPointer !== null
          ? (this.sentimentPointer -= 1)
          : (this.sentimentPointer = 2)
      }

      if (type === 'chip' && this.topicID2Topic[option.id].sentiment_enabled) {
        const selectedSentimentKey = Number(
          _.findKey(SENTIMENT_KEY_MAP, o => o === option.sentiment)
        )

        // TODO: Maybe shift key ?
        if (selectedSentimentKey - 1 !== -1) {
          this.selectTopicSentiment(
            option.id,
            SENTIMENT_KEY_MAP[selectedSentimentKey - 1],
            false
          )
        } else {
          this.selectTopicSentiment(option.id, SENTIMENT_KEY_MAP[2], false)
        }
      }

      if (type === 'category') {
        this.toggleCategory(option)
      }
    },

    onRight (e) {
      if (!this.selectedOption) return

      const { type, option } = this.selectedOption

      if (type === 'topic' && this.topicID2Topic[option.id].sentiment_enabled) {
        this.sentimentPointer < 2 && this.sentimentPointer !== null
          ? (this.sentimentPointer += 1)
          : (this.sentimentPointer = 0)
      }

      // TODO: Maybe shift key ?
      if (type === 'chip' && this.topicID2Topic[option.id].sentiment_enabled) {
        const selectedSentimentKey = Number(
          _.findKey(SENTIMENT_KEY_MAP, o => o === option.sentiment)
        )

        if (selectedSentimentKey + 1 !== 3) {
          this.selectTopicSentiment(
            option.id,
            SENTIMENT_KEY_MAP[selectedSentimentKey + 1],
            false
          )
        } else {
          this.selectTopicSentiment(option.id, SENTIMENT_KEY_MAP[0], false)
        }
      }

      if (type === 'category') {
        this.toggleCategory(option)
      }
    },

    /**
     * Close the dropdown on click outside.
     * @emits  {search:click-outside}
     * @return {void}
     */
    onClickOutside () {
      if (!this.open) return
      this.closeDropdown()
    },

    closeDropdown () {
      // Don't blur if we opened the code menu
      if (this.newCodeTriggeredFromHere || this.blockedByModals) return
      this.blur()
      this.resetSentimentPointer()
      // this.search = ''
      this.open = false
      this.selectedOptionPointer = null
    },

    openDropdown () {
      if (!this.editable || this.blockedByModals || this.showOnlyResults) {
        return
      }

      this.open = true
      this.focus()
      this.resetSentimentPointer()
      this.selectedOptionPointer = null
    },

    onDeleteDown () {
      if (
        !this.selectedOption ||
        !this.reactOnKeydown
      ) return

      const { type, option } = this.selectedOption

      if (type === 'chip') {
        this.deselect(option)
      }

      this.deleting = true
    },

    onDeleteUp () {
      this.deleting = false
    },

    onUp (e) {
      if (
        this.$refs.search.value.length &&
        this.selectedOptionPointer <= this.value.length
      ) {
        return
      }

      if (e.shiftKey) {
        if (
          this.selectedOption &&
          this.selectedOption.type === 'chip' &&
          this.selectedOptionPointer !== 0
        ) {
          const previousPointer = this.selectedOptionPointer
          let resorted = _.clone(this.value)
          resorted.splice(
            this.selectedOptionPointer - 1,
            0,
            resorted.splice(this.selectedOptionPointer, 1)[0]
          )
          this.draggableValue = resorted
          this.$nextTick(() => {
            this.selectedOptionPointer = previousPointer - 1
            this.sentimentPointer = 0
          })
        } else if (this.activeRowIndex - 1 >= 0) {
          this.$emit('prev')
        }

        return
      }

      if (this.selectedOptionPointer === null) {
        this.selectedOptionPointer = this.value.length - 1
      } else if (this.selectedOptionPointer > 0) {
        this.selectedOptionPointer -= 1
      } else {
        return
      }

      this.resetSentimentPointer()
      this.scrollListIfNeeded(SCROLL_UP)
    },

    onDown (e) {
      if (e.shiftKey || e.metaKey || e.ctrlKey) {
        if (
          this.selectedOption &&
          this.selectedOption.type === 'chip' &&
          this.selectedOptionPointer < this.value.length - 1
        ) {
          const previousPointer = this.selectedOptionPointer
          let resorted = _.clone(this.value)
          resorted.splice(
            this.selectedOptionPointer + 1,
            0,
            resorted.splice(this.selectedOptionPointer, 1)[0]
          )
          this.draggableValue = resorted
          this.$nextTick(() => {
            this.selectedOptionPointer = previousPointer + 1
            this.sentimentPointer = 0
          })
        } else if (this.activeRowIndex + 1 < this.dummyRows.length) {
          this.$emit('next')
        }

        return
      }

      if (this.selectedOptionPointer === null) {
        this.selectedOptionPointer = this.value.length
      } else if (
        this.selectedOptionPointer <
        this.dropdownOptionsStateMap.length - 1
      ) {
        this.selectedOptionPointer += 1
      } else {
        return
      }

      this.resetSentimentPointer()
      this.scrollListIfNeeded(SCROLL_DOWN)
    },

    resetSentimentPointer () {
      this.sentimentPointer = null
    },

    scrollListIfNeeded (direction) {
      if (!this.selectedOption || !this.dropdownOpen) return

      let selectedEl
      const { option, type } = this.selectedOption

      if (type === 'chip') return

      switch (type) {
        case 'category':
          selectedEl = this.$refs[`category-${this.cats2catIdx[option]}`][0]
          break
        case 'topic':
          selectedEl = this.$refs[`topic-${option.id}`][0]
          break
        case 'new':
          selectedEl = this.$refs['new-topic'].$el
          break
      }

      if (!isElementVisible(selectedEl, this.$refs['topic-list'])) {
        selectedEl.scrollIntoView({
          block: direction === SCROLL_DOWN ? 'end' : 'start'
        })
      }
    },

    onEnter (e) {
      if (!this.selectedOption || e.metaKey) return
      if (this.selectedOptionPointer !== null) e.stopPropagation()

      const { type, option } = this.selectedOption

      switch (type) {
        case 'category':
          this.toggleCategory(option)
          break
        case 'topic':
          if (!this.topicID2Topic[option.id].sentiment_enabled || !this.showSentiment) this.select(option)
          else if (this.topicID2Topic[option.id].sentiment_enabled && this.sentimentPointer !== null) {
            this.selectTopicSentiment(
              option.id,
              SENTIMENT_KEY_MAP[this.sentimentPointer]
            )
            this.onAfterSelect()
          }
          break
        case 'new':
          this.triggerNewCode()
          break
      }
    }
  }
}
</script>

<style lang="scss">
@import "~css/coding";
@import "~css/filters";
</style>

<i18n locale="en" src="@/i18n/en/pages/Codingv2.json" />
<i18n locale="de" src="@/i18n/de/pages/Codingv2.json" />