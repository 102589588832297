<template>
  <div class="topic-chip-container" tabindex="0" :class="chipClasses" :title="description">
    <div class="topic-chip" :style="{ 'max-width': maxWidth === 0 ? 'auto' : `${maxWidth}px`, background: topicChipBG, 'border-color': colorObject.soft, 'border-bottom-color': categoryBG }">
      <!-- 'box-shadow': `inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 1px 2px ${categoryBG}`, -->
      <div v-if="sentiment"
           class="sentiment-indicator"
           :class="{ positive: sentiment === '+', negative: sentiment === '-', 'mixed': sentiment === '+-' }"
      />
      <template v-if="onlyAvatar">
        <span
          class="avatar"
          :class="hoverAvatar && 'avatar--hover'"
          :style="{
            background: categoryBG,
            color,
            'box-shadow': `inset 0 0 0 1px ${categoryBG}`
          }"
          @mouseover="hoverAvatarDebounced(true)"
          @mouseleave="hoverAvatarDebounced(false)"
          @click.prevent.stop
        >
          {{ hoverAvatar ? label : avatarChar.toUpperCase() }}
        </span>
      </template>

      <template v-else-if="avatarChar">
        <span class="avatar" :style="{ background: categoryBG }"> {{ avatarChar.toUpperCase() }}</span>
        <span v-html="$escapeHtml(label)" />
      </template>

      <template v-else-if="category">
        <span class="chip-category" :style="{ background: categoryBG }"> {{ category.toUpperCase() }}</span>
        <span class="chip-content" :style="chipContentStyle"><span v-html="$escapeHtml(label)" /> </span>
      </template>

      <template v-else>
        <!-- <span class="chip-category" :style="{ background: categoryBG }"> {{ category.toUpperCase() }}</span> -->
        <span class="chip-tanline" :style="{ 'border-color': categoryBG }" />
        <span class="chip-content" :style="chipContentStyle"><span v-html="$escapeHtml(label)" /> </span>
      </template>

      <div v-if="deletable" @click.stop.prevent="$emit('delete', $event)" class="delete-btn">
        <div class="c-icon" />
      </div>
    </div>
    <div
      v-if="tooltipEnabled"
      class="tooltip-pseudo-el tooltip"
      :class="[`tooltip-${tooltipPosition}`]"
    >
      <span class="manual-content" v-html="$escapeHtml(tooltipHTML)" />
    </div>
  </div>
</template>

<script>

import { isLightColor, get3ShadesForColor } from '@/utils/colorUtils'

export default {
  codit: true,
  name: 'TopicChip',

  props: {
    color: {
      type: [Object, String],
      default: ''
    },

    backgroundColor: {
      type: String,
      default: ''
    },

    avatarChar: {
      type: String,
      default: ''
    },

    sentiment: {
      type: String,
      default: ''
    },

    category: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    avatarLabel: {
      type: String,
      default: ''
    },

    expandAvatarOnHover: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    deletable: {
      type: Boolean,
      default: false
    },

    onlyAvatar: {
      type: Boolean,
      default: false
    },

    tooltipPosition: {
      type: String,
      default: 'top'
    },

    maxWidth: {
      type: Number,
      default: 275
    },

    disableTooltip: {
      type: Boolean,
      default: false
    },

    description: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      hasOverflow: false,
      hoverAvatar: false,
      font: {
        label: {
          size: null,
          weight: null
        },
        category: {
          size: null,
          weight: null
        },
        family: ''
      },

      checkIfOverflowDebounced: _.debounce(this.checkIfOverflow),
      hoverAvatarDebounced: _.debounce(this.handleHoverAvatar, 150)
    }
  },

  computed: {
    chipClasses () {
      return {
        'deletable': this.deletable,
        'active': this.active,
        'only-avatar': this.onlyAvatar,
        'has-content': !this.onlyAvatar,
        'has-avatar': this.avatarChar !== '',
        'has-category': this.category !== '',
        'only-category': this.label === '',
        'only-tanline': !this.category && !this.avatarChar && !this.onlyAvatar,
        'color-is-light': this.categoryIsLight,
        'avatar-hovered': this.hoverAvatar
      }
    },

    tooltipEnabled () { return !this.disableTooltip && (this.onlyAvatar || (this.category && this.hasOverflow)) },

    tooltipHTML () {
      return (this.category ? `${this.category}: <br />` : '') + this.label
    },

    colorObject () {
      return _.isObject(this.color) ? this.color : get3ShadesForColor(this.color)
    },

    /**
     * Calculate if the given color is light - if yes the font color needs to be adjusted
     * @return {Boolean} True if color is light
     */
    categoryIsLight () {
      return isLightColor(this.categoryBG)
    },

    topicChipBG () {
      return this.colorObject.getSoftSolid
    },

    categoryBG () {
      return this.colorObject.medium
    },

    chipContentStyle () {
      return { 'text-shadow': `0px 0px ${this.categoryBG}, 0px 1px #FFF` }
    }
  },

  watch: {
    /**
     * Whenever the category changes, we need to recompute if there is a potential overflow
     */
    category: {
      immediate: true,
      handler: 'checkIfOverflowDebounced'
    },

    /**
     * Whenever the label changes, we need to recompute if there is a potential overflow
     */
    label: {
      immediate: true,
      handler: 'checkIfOverflowDebounced'
    }
  },

  methods: {
    handleHoverAvatar (hovered) {
      this.hoverAvatar = hovered
    },

    /**
     * Get the font style (size, weight, family) in the current setting
     * Do this by quickly inserting a node into the root of this element,
     * measuring the desired values and then removing it again.
     */
    determineComputedFonts () {
      let topicChipContainer = document.createElement('div')
      topicChipContainer.className = 'topic-chip-container'
      topicChipContainer.style.display = 'none'
      let topicChip = document.createElement('div')
      topicChip.className = 'topic-chip'
      topicChipContainer.appendChild(topicChip)
      let chipCategory = document.createElement('div')
      chipCategory.className = 'chip-category'
      let chipContent = document.createElement('div')
      chipContent.className = 'chip-content'
      topicChip.appendChild(chipCategory)
      topicChip.appendChild(chipContent)
      this.$el.appendChild(topicChipContainer)
      this.font.label.size = getComputedStyle(chipContent).getPropertyValue('font-size')
      this.font.label.weight = getComputedStyle(chipContent).getPropertyValue('font-weight')
      this.font.category.size = getComputedStyle(chipCategory).getPropertyValue('font-size')
      this.font.category.weight = getComputedStyle(chipCategory).getPropertyValue('font-weight')
      this.font.family = getComputedStyle(chipContent).getPropertyValue('font-family')
      this.$el.removeChild(topicChipContainer)
    },

    /**
     * Check if we have a text overflow situation, leading to the display of a tooltip
     */
    checkIfOverflow () {
      if (this.maxWidth === 0) return
      if (!this.font.family) this.determineComputedFonts()

      // Get the size of the label & category by measuring it on a canvas
      let canvas = document.createElement('canvas')
      let context = canvas.getContext('2d')
      context.font = `${this.font.label.weight} ${this.font.label.size} ${this.font.family}`
      let widthLabel = context.measureText(this.label).width
      context.font = `${this.font.category.weight} ${this.font.category.size} ${this.font.family}`
      let widthCategory = context.measureText(this.category.toUpperCase()).width

      // Subtract the padding and width of delete button
      let maxWidth = this.maxWidth - (this.deletable ? 40 : 20)
      this.hasOverflow = widthLabel > maxWidth || widthCategory > maxWidth
    }
  }
}

</script>

<style lang=scss>

$topic-chip-height: 34px;
$tanline-height: 6px;

.topic-chip-container {
  position: relative;
  display: inline-block;
  margin: 2px 2px;

  .tooltip-pseudo-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }

  .topic-chip {
    background: #FFF;
    color: rgba(0, 0, 0, 0.65);
    height: $topic-chip-height;
    display: inline-block;
    cursor: default;
    transition-property: background-color, color, opacity, transform, box-shadow;
    will-change: background-color, color, opacity, transform, box-shadow;
    transition-duration: .3s;
    font-size: 13px;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    border-style: solid;
    border-width: 1px 0px 2px 0px
  }

  &.only-tanline .topic-chip {
    height: $topic-chip-height * 0.8;
  }

  &.has-content .topic-chip {
    min-width: 100px;
  }

  &.has-avatar .topic-chip {
    padding-left: 44px;
    padding-right: 10px;
    border-radius: $topic-chip-height;
    line-height: $topic-chip-height;
  }

  &.deletable.has-avatar .topic-chip {
    padding-right: 30px;
    .delete-btn {
    }
  }

  &.avatar-hovered .topic-chip {
    padding: 0 !important;
  }

  .sentiment-indicator {
    position: absolute;
    right: -4px;
    bottom: -4px;
  }

  &:not(.only-category):not(.only-avatar) .topic-chip {
    border-radius: $topic-chip-height / 4;
    line-height: $topic-chip-height / 2 - 2px;
  }

  .avatar {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50px;
    display: inline-block;
    width: $topic-chip-height;
    height: $topic-chip-height;
    line-height: $topic-chip-height;
    text-align: center;
    vertical-align: center;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;
    }

    &--hover {
      position: static;
      width: auto;
      padding: 0 14px;
    }
  }

  .chip-category {
    height: $topic-chip-height / 2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    font-size: smaller;
    font-variant: small-caps;
    font-weight: bold;
    line-height: $topic-chip-height / 2;
    padding: 1px 10px;
    text-overflow: ellipsis;
    border-radius: $topic-chip-height / 4 $topic-chip-height / 4 0 0;
    color: rgba(255, 255, 255, 0.9);
  }

  &.color-is-light .chip-category {
    color: #666;
  }

  .chip-tanline {
    height: $tanline-height;
    top: 0;
    left: 0;
    display: block;
    // width: 4px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 0 0 0 5px;
    width: 10px;
    display: none;
    border-radius: $topic-chip-height / 4 0 0 $topic-chip-height / 4;
  }

  .chip-content {
    padding: 0 10px;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    > span {
      display: inline-block;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.only-tanline .chip-content {
    font-size: 14px;
    // height: $topic-chip-height - $tanline-height;
    height: 100%;
  }

  &.only-avatar .avatar { color: #FFF; }

  &:focus {
    outline: none;
  }

  &.active {
    cursor: pointer;
    .topic-chip { color: inherit; }
  }

  &.deletable:not(.only-category) .topic-chip {
    .chip-category, .chip-content { padding-right: 30px }
  }

  .delete-btn {
    width: 18px;
    height: 18px;
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 4;
    // transform: translate3D(0, -50%, 0);
    transition-duration: .3s;
    cursor: pointer;
    border-radius: 50%;
    display: inline;
    background: #FFF;
    &:hover {
      background-color: #666;
      .c-icon {
        color: #fefefe;
      }
    }
    &, .c-icon { transition: all 0.3s ease-out; }

    .c-icon {
      // display: inline-block;
      margin: 1px 0 0 1px;
      display: flex;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      // background: #FFF;
      line-height: 16px;
      font-size: 16px;
      font-family: Material Design Icons;
      color: rgba(0, 0, 0, 0.54);
      &::before {
        content: "\F0159"
      }
    }

    &:hover {
      .v-icon {
        background: #666;
        color: #FEFEFE;
      }
    }
  }

  &.selected .topic-chip {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  &.selected .topic-chip, &.active:hover .topic-chip {
    background-color: #BDBDBD;
    .v-icon {
      background: #BDBDBD;
      /* color: #EEE; */
    }

    .v-icon:hover {
      background: #666;
    }
  }

  &.only-avatar .topic-chip {
    padding: 0 $topic-chip-height / 2;
  }

  &.only-category .topic-chip {
    padding: 0;
    background: none!important;
  }

  &.only-category .chip-category {
    height: $topic-chip-height;
    line-height: $topic-chip-height;
    border-radius: $topic-chip-height / 4;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    position: static;
  }

  &.only-category.deletable .chip-category {
    padding-right: 40px;
  }

  &:hover, &:focus, &.selected {
    .tooltip-pseudo-el {
      .manual-content {
        opacity: 0.9;
      }

      &.tooltip-top .manual-content {
        transform: translate(-50%, -5px);
      }
      &.tooltip-bottom .manual-content {
        transform: translate(-50%, 5px);
      }
      &.tooltip-left .manual-content {
        transform: translate(-5px, 50%);
      }
      &.tooltip-right .manual-content {
        transform: translate(5px, 50%);
      }
    }
  }
}

</style>
