<template>
  <div class="wizard-la">
    <page-header feature-name="cb-basis">
      <span slot="info" v-html="$t('autocoder.info')" />
    </page-header>

    <loading :is-loading="loading"
             :dont-animate-entry="initial"
             :title="$t('processing')"
    >
      <div v-if="failed">
        <v-alert prominent
                 type="error"
                 outlined
                 border="left"
                 class="flex-center"
        >
          <span v-if="invalidListQuestion" v-html="$t('autocoder.invalid_list_question')" />
          <span v-else v-html="$t('autocoder.failed')" />
          <div class="spacer" />
          <v-btn v-if="!invalidListQuestion" color="accent" @click="runAutocoder">
            {{ $t('actions.retry') }}
          </v-btn>
        </v-alert>
      </div>

      <v-alert v-else-if="result.codes.length === 0"
               prominent
               type="warning"
               outlined
               border="left"
      >
        <span v-html="$t('autocoder.no_codes')" />
      </v-alert>

      <div style="display: flex;" v-if="!initial">
        <div style="flex: 0 0 300px; margin-right: 25px">
          <div class="settings elevation-1">
            <template v-if="LIMITS.MIN_CNT[0] !== LIMITS.MIN_CNT[1]">
              <div class="grey--text" style="margin-bottom: 10px">
                {{ $t('autocoder.settings.min_cnt') }}
                <helptip position="bottom" x-offset="75px">
                  <span v-html="$t('autocoder.settings.min_cnt_helptip')" />
                </helptip>
              </div>
              <div class="slider-container">
                <v-slider :value="settings.min_cnt"
                          @change="settingsTmp.min_cnt = $event; settings.min_cnt = $event"
                          thumb-label="always"
                          thumb-size="20"
                          hide-details
                          :min="LIMITS.MIN_CNT[0]"
                          :max="LIMITS.MIN_CNT[1]"
                />
                <v-text-field v-model="settingsTmp.min_cnt"
                              outlined
                              dense
                              hide-details
                              type="number"
                              @input="maybeUpdateSliderValDebounced($event, 'settings.min_cnt', 'settingsTmp.min_cnt', LIMITS.MIN_CNT)"
                              :min="LIMITS.MIN_CNT[0]"
                              :max="LIMITS.MIN_CNT[1]"
                />
              </div>
            </template>

            <div class="grey--text" style="margin-bottom: 10px">
              {{ $t('autocoder.settings.similarity') }}
              <helptip position="bottom" x-offset="75px">
                <span v-html="$t('autocoder.settings.similarity_helptip')" />
              </helptip>
            </div>
            <div class="slider-container">
              <v-slider :value="settings.similarity"
                        model="settingsTmp.similarity"
                        thumb-size="20"
                        hide-details
                        @change="settingsTmp.similarity = $event; settings.similarity = $event"
                        :min="LIMITS.SIMILARITY[0]"
                        :max="LIMITS.SIMILARITY[1]"
                        thumb-label="always"
              />
              <v-text-field v-model="settingsTmp.similarity"
                            outlined
                            dense
                            hide-details
                            type="number"
                            @input="maybeUpdateSliderValDebounced($event, 'settings.similarity', 'settingsTmp.similarity', LIMITS.SIMILARITY)"
                            :min="LIMITS.MIN_CNT[0]"
                            :max="LIMITS.MIN_CNT[1]"
              />
            </div>

            <div class="grey--text" style="margin-top: 8px">
              {{ $t('autocoder.settings.other') }}
              <helptip position="bottom" x-offset="150px" :width="650">
                <span v-html="$t('autocoder.settings.other_helptip')" />
              </helptip>
            </div>
            <v-radio-group v-model="settings.other" hide-details>
              <v-radio v-for="option in ['none', 'unassigned', 'rare']"
                       :key="option"
                       :label="$t(`autocoder.settings.other_options.${option}`)"
                       :value="option"
              />
            </v-radio-group>
            <v-checkbox v-if="question.inherits_from || givenCB.length"
                        v-model="settings.keepOld"
                        :label="$t('autocoder.settings.keep_old')"
                        hide-details
                        dense style="margin-top: 10px"
            />
            <v-checkbox class="new-checkbox"
                        :disabled="!givenCB.length && question.inherits_from === null"
                        v-model="settings.newCodes"
                        hide-details
                        dense
            >
              <div slot="label">
                <span v-html="$t('autocoder.settings.new_codes.label', {
                  newCodeName: `<span class='new-label'>${$t('autocoder.settings.new_codes.name')}</span>`
                })"
                />
                <helptip position="bottom" x-offset="150px" :width="650" v-if="question.inherits_from">
                  <span v-html="$t('autocoder.settings.new_codes.helptip', { inheritedQuestionName: $escapeHtml(question.inherits_from_name) })" />
                </helptip>
              </div>
            </v-checkbox>
          </div>
          <div class="stats grey--text">
            <table>
              <tr>
                <td>{{ $t('autocoder.stats.n_tokens') }}</td>
                <td><code>{{ result.meta.n_tokens }}</code></td>
              </tr>
              <tr>
                <td>{{ $t('autocoder.stats.n_tokens_unique') }}</td>
                <td><code>{{ result.meta.n_tokens_unique }}</code></td>
              </tr>
              <tr>
                <td>{{ $t('autocoder.stats.ncodes') }}</td>
                <td><code>{{ nCodes }}</code></td>
              </tr>
            </table>
          </div>
        </div>

        <div style="flex: 1 0 400px; display: flex">
          <div class="resulting-codebook">
            <alert type="warning" v-if="maxCodesReached">
              <span v-html="$t('autocoder.codelist.warnings.codes_limit', { maxCodes: MAX_TOPICS_SEMIOPEN })" />
            </alert>
            <v-data-table :headers="headers"
                          :items="displayedCodebook"
                          :items-per-page="1000"
                          hide-default-footer
            >
              <template slot="item" slot-scope="{ item }">
                <tr :class="{ 'row-rare': item.isRare, 'row-new': item.category === '___NEW___' }">
                  <td class="font-weight-medium">
                    {{ item.label }}
                  </td>
                  <td class="text-right">
                    {{ item.cnt }}
                  </td>
                  <td class="text-right">
                    {{ item.nchildren }}
                  </td>
                  <td>
                    <v-chip v-for="c in item.children.slice(0, 10)" :key="c.label" v-if="c.cnt > 0">
                      {{ truncate(c.label) }} ({{ c.cnt }})
                    </v-chip>
                    <span v-if="item.nchildren > 10" class="more-children">
                      {{ $t('autocoder.codelist.dotdot_and') }}
                      <helptip
                        :width="600"
                        position="top"
                        :anchor-text="`${item.nchildren - 10} ${$t('autocoder.codelist.more')}`"
                      >
                        <div>
                          {{ $_.map(item.children.filter(c => c.cnt > 0).slice(10), c => `${c.label} (${c.cnt})`).join(', ') }}
                        </div>
                      </helptip>
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </loading>

    <div class="btn-next-container">
      <v-btn class="btn-prev"
             :disabled="loading"
             @click="$emit('back')"
      >
        {{ $t('back') }}
      </v-btn>

      <v-btn color="primary"
             @click="next"
             :disabled="loading || failed"
             v-text="$t('next')"
      />
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import ListAutocoderMixin from '@/mixins/listAutocoder'
import colorPalettes from '@/mixins/colorPalettes'

import { MAX_TOPICS_SEMIOPEN } from '@/settings/overridable.js'

const INITIAL_CODES_GOAL = 20
const SIMILARITY_INITIAL = 75
const TOKEN_MAXLEN = 40

const OTHER_CODE_ID = 999

export default {
  codit: true,
  name: 'WizardListAutocoder',

  mixins: [ListAutocoderMixin, colorPalettes],

  props: {
    active: { type: Boolean, default: false }
  },

  data () {
    return {
      loading: true,
      initial: true,
      failed: false,
      invalidListQuestion: false,

      resultTab: 0,
      maxCodesReached: false,

      LIMITS: {
        MIN_CNT: [2, 1000],
        SIMILARITY: [65, 100]
      },

      settingsTmp: {
        min_cnt: -1,
        similarity: -1
      },

      settings: {
        min_cnt: 4,
        similarity: SIMILARITY_INITIAL,
        other: 'unassigned',
        keepOld: true,
        newCodes: true
      },

      storeCBHasChanged: false,
      givenCB: [],

      result: { codes: [] },

      maybeUpdateSliderValDebounced: _.debounce(this.maybeUpdateSliderVal, 1000)
    }
  },

  computed: {
    /**
     * The header of the result table
     * @return {Array} List of objects, representing the header columns
     */
    headers () {
      return [
        { text: this.$t('autocoder.table.code_name'), value: 'label', width: 150 },
        { text: this.$t('autocoder.table.count'), value: 'cnt', align: 'end', width: 125 },
        { text: this.$t('autocoder.table.number_unique_tokens'), value: 'nchildren', align: 'end', width: 125 },
        { text: this.$t('autocoder.table.unique_tokens'), value: 'children', sortable: false }
      ]
    },

    /**
     * The dataset used for the chart displaying the results
     */
    codeCountDs () {
      // create datasets for plotting code counts
      return [{
        label: 'CODES',
        data: _.map(this.displayedCodebook, 'cnt'),
        backgroundColor: this.$color.getSoft(0),
        hoverBackgroundColor: this.$color.getStrong(0)
      }]
    },

    /**
     * Return the inherited "other" code or undefined
     * @return {[type]} [description]
     */
    existingOtherCode () {
      return this.question.inherits_from && _.find(this.result.codes, c => c.id === OTHER_CODE_ID && c.category !== '___NEW___')
    },

    /**
     * The codebook as it is displayed in the results view
     * Note: The format of the codes in this array do *not* match the format of the general code object
     * @return {Array} Array of pseudo-code objects
     */
    displayedCodebook () {
      let cb = []
      const makeOtherCode = this.settings.other !== 'none' && !this.existingOtherCode
      let rares = {
        id: 0,
        label: `\uFEFF${this.$t('autocoder.code_rare')}`,
        cnt: 0,
        nchildren: 0,
        isRare: true,
        children: []
      }
      let maxCodesReached = false
      this.result.codes.forEach((c, idx) => {
        // Keep & display the inherited other code if it has keywords, i.e. if it wasn't just the "unassigned"
        // background code
        let isInheritedOther = false
        if (this.existingOtherCode && c.id === this.existingOtherCode.id) {
          if (this.existingOtherCode.keywords.length) isInheritedOther = true
          else return
        }

        // If inherited and we keep all codes
        let keepCauseInherited = (this.question.inherits_from || this.givenCB.length) && c.category !== '___NEW___' && this.settings.keepOld

        // If the code occurs often enough or is kept because of inheritance
        let enoughOrInherited = c.cnt >= this.settings.min_cnt || keepCauseInherited

        // New codes are allowed or the code is not new
        let allowNewOrNotNew = this.settings.newCodes || c.category !== '___NEW___'

        if (isInheritedOther || (!maxCodesReached && enoughOrInherited && allowNewOrNotNew)) {
          cb.push({ ...c })
          maxCodesReached = (cb.length + makeOtherCode) === MAX_TOPICS_SEMIOPEN
        } else {
          rares.children.push(...c.children)
          rares.cnt += c.cnt
          rares.nchildren += c.nchildren
        }
      })

      this.maxCodesReached = maxCodesReached // eslint-disable-line vue/no-side-effects-in-computed-properties
      cb.push(rares)

      return cb
    },

    /**
     * The resulting codebook derived from the displayedCodebook, but with codes in the standard format
     * @return {Array} List of resulting codes
     */
    resultingCodebook () {
      let codes = _.map(this.displayedCodebook.slice(0, -1), ({ id, label, category, children }) => ({
        id,
        label,
        category: category === '___NEW___' ? 'NEW' : category,
        description: '',
        keywords: _.map(children, 'label'),
        new: category === '___NEW___' || this.question.inherits_from === null
      }))

      const _getNewOtherCodeID = () => {
        let currID = OTHER_CODE_ID
        while (_.find(codes, { id: currID })) currID++
        return currID
      }

      // Handle the other code
      if (['rare', 'unassigned'].indexOf(this.settings.other) !== -1) {
        let isUnassigned = this.settings.other === 'unassigned'
        let otherCode
        let _existingOtherCode = _.find(codes, { id: OTHER_CODE_ID })
        // If there has been an other code already, we merge the new code with the old one
        // For the unassigned mode, only do this if the existing other code doesn't have any keywords
        if (_existingOtherCode && (!isUnassigned || !_existingOtherCode.keywords.length)) {
          otherCode = _existingOtherCode
        // Otherwise, create a new code
        } else {
          otherCode = {
            id: _getNewOtherCodeID(),
            label: this.$t(`autocoder.code_other_labels.${this.settings.other}`),
            category: 'CODE',
            keywords: [],
            new: true,
            description: this.$t(`autocoder.code_other_descriptions.${this.settings.other}`)
          }
          codes.push(otherCode)
        }

        // Unassigned mode means this is a special code, which cannot be edited in the codebook editor
        otherCode.special = isUnassigned
        // For the rare mode, add all children to the keywords
        if (!isUnassigned) {
          let rares = this.displayedCodebook.slice(-1)[0]
          otherCode.keywords.push(..._.map(rares.children, 'label'))
        }

        // Set the value of the unassigned code in the store
        // Note: Only the unassigned mode needs this, in the rare mode the other code is treated like
        // a normal code
        this.$store.commit('setListUnassignedCode', isUnassigned ? otherCode.id : null)
      }

      return this.addColorToCodes(codes)
    },

    /**
     * The number of codes in the resulting codebook
     * @return {Number} [description]
     */
    nCodes () {
      return (this.displayedCodebook.length + (this.settings.other !== 'none'))
    },

    /**
     * Proxy for the constant (overridable per user) of how many codes are allowed per survey at max
     * @type {Number}
     */
    MAX_TOPICS_SEMIOPEN: {
      cache: false,
      get: () => MAX_TOPICS_SEMIOPEN
    },

    ...Vuex.mapState({
      user: 'user',
      question: state => state.wizard.question,
      codes: state => state.wizard.codes
    }),

    ...Vuex.mapGetters(['isListQuestion'])
  },

  watch: {
    active: {
      immediate: true,
      /**
       * When the status changes to active, we run the autocoder if it hasn't run yet
       * Note: The list-autocoder component is only loaded when the current step is at least there
       * Therefore, the autocoder is run every time the step comes here from a lower number, but not
       * if it comes here from a higher number
       * @return {[type]} [description]
       */
      handler () {
        if (this.active) {
          this.settings.newCodes = this.$store.state.wizard.runAutocoder
          if (this.initial) {
            // If codes are given, this means that either we have inherited or
            // that the user has uploaded a file. In the second case, we need to pass
            // that codebook to the autocoder endpoint manually
            if (this.codes.length && this.question.inherits_from === null) this.givenCB = _.cloneDeep(this.codes)
            this.runAutocoder()

          // If the codebook has changed in the following step, set it to the value we have here again
          } else if (this.storeCBHasChanged) this.setStoreCodebook()
          this.storeCBHasChanged = false
        }
      }
    },

    /**
     * When the similarity slider changes, re-run the autocoder
     */
    'settings.similarity' () { setTimeout(this.runAutocoder, 10) },

    /**
     * When the newCodes checkbox changes, re-run the autocoder
     */
    'settings.newCodes' () { setTimeout(this.runAutocoder, 10) },

    /**
     * When the other code radio changes, re-run the autocoder
     */
    'settings.other' () { setTimeout(this.runAutocoder, 10) },

    /**
     * When the resulting codebook changes and the component is active, save it to the store
     */
    'resultingCodebook' () {
      if (!this.active) return
      this.setStoreCodebook()
    },

    /**
     * Note when the codes change while this component is not active, such that we can revert to
     * "our" version of the codebook should the user return to this step
     */
    codes () {
      if (!this.active) this.storeCBHasChanged = true
    }
  },

  methods: {
    /**
     * Go to the next step of the wizard
     */
    next () {
      this.$store.commit('setQuestionModelProps', { codebook_generator_list: {
        min_cnt: this.settings.min_cnt,
        similarity: this.settings.similarity * 0.01,
        unassigned_code: this.$store.state.wizard.listUnassignedCode
      } })
      this.$emit('next')
    },
    /**
     * Make a copy of the current codebook and save it to the store
     */
    setStoreCodebook () {
      this.$store.commit('setCodes', _.cloneDeep(this.resultingCodebook))
    },

    /**
     * Run the list-autocoder
     */
    runAutocoder () {
      this.loading = true
      this.failed = false

      let params = {
        similarity: this.settings.similarity * 0.01,
        no_new_codes: !this.settings.newCodes,
        unassigned_code: this.settings.unassigned_code
      }
      if (this.question.inherits_from !== null) params.inherits_from = this.question.inherits_from
      else if (this.givenCB.length) params.given_codebook = this.givenCB

      this.callListAutocoder(params).then(result => {
        this.$set(this, 'result', result)

        if (this.initial) {
          this.determineInitialSettings()
          this.initial = false
        }

        // Set the maximum of min count to the maximum count in the resulting codes list
        this.LIMITS.MIN_CNT.splice(1, 1, (this.result.codes.length ? Math.max(_.maxBy(this.result.codes, 'cnt').cnt, 2) : 2))

        this.loading = false
        this.invalidListQuestion = false
      }).catch(err => {
        this.failed = true
        this.loading = false
        this.invalidListQuestion = err.invalidListQuestion
        if (!this.invalidListQuestion) this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Make an educated guess on good settigs for the list autocoder
     */
    determineInitialSettings () {
      // Set the min group size to a value, such that we get close to the INITIAL_CODES_GOAL number of codes
      // Assumes the results are already sorted by number of codes
      let lastCodeToDisplay = this.result.codes[INITIAL_CODES_GOAL]
      this.settings.min_cnt = Math.max(2, (lastCodeToDisplay !== undefined ? lastCodeToDisplay.cnt - 1 : 1))
      this.settingsTmp.similarity = SIMILARITY_INITIAL
      this.settingsTmp.min_cnt = this.settings.min_cnt
      this.settingsTmp.similarity = this.settings.similarity
    },

    /**
     * Truncate a given string and append ellipsis
     * @param  {String} s The string to truncate
     * @return {String}   The truncated string
     */
    truncate: s => (s.length > TOKEN_MAXLEN ? `${s.slice(0, TOKEN_MAXLEN)} [...]` : s),

    /**
     * Potentially update the value of a slider through direct input.
     * * If validation succeeds set targetPath to new value
     * * If validation fails, set selfPath to value of targetPath
     * @param  {Number} val         The number to set
     * @param  {String} targetPath  The path to the property which is to be set on successful validation
     * @param  {String} selfPath    The path of value, which is reset in case of failed validation
     * @param  {Array} limits       The limits within which the value must lie
     */
    maybeUpdateSliderVal (val, targetPath, selfPath, limits) {
      val = Number(val)
      if (Number.isInteger(val) && val >= limits[0] && val <= limits[1]) {
        _.set(this, targetPath, val)
      } else {
        _.set(this, selfPath, _.get(this, targetPath))
      }
    }

    // saveResults () {
    //   setTimeout(() => {
    //     // Create the final codebook, which has been precomputed already
    //     // Skip the "rare terms" code, change the category name for new codes
    //     let codebook = this.displayedCodebook.slice(0, -1).map(({ id, label, category }) =>
    //       ({ id, label, category: (category === '___NEW___' ? 'NEW' : category) })
    //     )

    //     if (this.settings.other !== 'none' && !this.alreadyHasOtherCode) {
    //       codebook.push({
    //         id: 999,
    //         label: this.$t('autocoder.code_other'),
    //         category: this.$t('autocoder.code_other')
    //       })
    //     }

    //     // Get the IDs of the cdes grouped into the rare codes
    //     let rareTermsIDs = new Set(this.displayedCodebook.slice(-1)[0].codeIdsContained)

    //     // Build up code id to index array, needed for getting idx_sorted of answers
    //     let codeID2Index = {}
    //     codebook.forEach((c, idx) => { codeID2Index[c.id] = idx })

    //     this.result.answers.forEach((a, idx) => {
    //       let acodes = a.codes

    //       // Remove codes that have been put into rare terms
    //       a.codes = _.filter(a.codes, c => c in codeID2Index)
    //       // Rare mode: check if any of the original codes were in the "rare" category
    //       if (this.settings.other === 'rare' && acodes.filter(c => rareTermsIDs.has(c)).length) a.codes.push(999)
    //       // Unassigned mode: If the answer has no code, add the other code
    //       else if (this.settings.other === 'unassigned' && !a.codes.length) a.codes.push(999)
    //       // Sort the answers by the first code that they have
    //       a.idx_sorted = (a.codes.length > 0 ? codeID2Index[a.codes[0]] : 999)
    //       // Set all answers to reviewed, if they have a code
    //       a.reviewed = !(a.codes.length === 1 && a.codes[0] === 999)
    //     })

    //     let cbl = { min_cnt: this.settings.min_cnt, similarity: this.settings.similarity }
    //     this.$store.dispatch('saveQuestionModelProps', { codebook_generator_list: cbl }).then(() => {
    //       this.$store.commit('setCodes', codebook)
    //       this.$store.dispatch('setAnswersCodesSortedIdxReviewed', this.result.answers)
    //       this.jamesSay('autocoder.generated', 'info', true)
    //     }).catch(err => this.$maybeRaiseAPIPromiseErr(err))
    //   }, 500)
    // }
  }
}

</script>

<style lang=scss>

.wizard-la {
  .result-tabs {
    width: 100%;
  }

  .settings {
    flex: 1;
    font-size: 14px;
    border-radius: 4px;
    padding: 15px 12px 10px;
    background: $col-soft-orange;
    margin-bottom: 20px;
    .v-slider {
      margin-top: 7px;
    }
    .v-label {
      font-size: 12px;
    }
    .v-input--radio-group {
      margin-top: 2px;
    }
    .v-radio {
      margin-bottom: 0;
    }

    .v-input--checkbox {
      margin-top: 0;
    }

    .slider-container {
      display: flex;
      align-items: center;
      .v-text-field {
        margin-left: 4px;
        flex: 0 0 55px;
        font-size: .75rem;
      }
    }

    .new-checkbox {
      .new-label {
        background: $col-medium-turq;
        padding: 3px 4px;
        border-radius: 2px;
      }
    }
  }

  .stats {
    font-size: 14px;
    border-radius: 4px;
    background: #EEE;
    padding: 5px 12px 5px;
    margin-bottom: 0px;
    table {
      line-height: 1.5em;
      width: 100%;
      tr {
        height: 2em;
      }
    }
  }

  .resulting-codebook {
    flex: 1;
    overflow-y: auto;

    td {
      height: 40px;
    }

    td.text-right {
      text-align: right;
    }

    .row-new {
      background: $col-soft-turq;
    }

    .row-rare {
      background: $col-soft-pink;
    }

    .more-children {
      display: inline-block;
      margin: 3px 0;

      .helptip-content > div {
        max-height: 250px;
        overflow-y: scroll;
      }
    }

    .v-chip { margin: 2px 4px }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Wizard.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizard.json' />
<i18n locale='en' src='@/i18n/en/components/wizard/ListAutocoder.json' />
<i18n locale='de' src='@/i18n/de/components/wizard/ListAutocoder.json' />