<template>
  <div
    class="wizard__suggestions"
  >
    <div
      v-for="suggestion in filteredSuggestions"
      class="overflow-hidden"
      :key="suggestion.id"
    >
      <suggestion :suggestion="suggestion" />
    </div>
    <div v-if="movedSuggestions.length > 0" class="wizard__moved">
      <div class="wizard__moved__title">
        {{ $t('suggestions.moved_topic_title') }}
      </div>
      <div v-for="(suggestion, index) in movedSuggestions"
           class="overflow-hidden"
           :key="index"
      >
        <suggestion :suggestion="suggestion" />
      </div>
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import Suggestion from '@/components/wizard/Suggestion'
import { MOVED_TOPIC } from '@/settings/constants'

export default {
  codit: true,
  name: 'Suggestions',
  components: {
    Suggestion
  },

  mixins: [],

  data () {
    return {
      mousePos: {
        x: null,
        y: null
      }
    }
  },

  computed: {
    ...Vuex.mapState({
      refinementStep: state => state.wizard.refinementStep,
      suggestions: state => state.wizard.suggestions
    }),

    filteredSuggestions () {
      return this.suggestions.filter(suggestion => suggestion.action !== MOVED_TOPIC)
    },

    movedSuggestions () {
      return this.suggestions.filter(suggestion => suggestion.action === MOVED_TOPIC && this.refinementStep === 1)
    }
  },

  watch: {
  },

  methods: {
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />