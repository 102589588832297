<template>
  <v-card class="preview-card">
    <v-alert v-if="failed"
             prominent
             type="error"
             outlined
             text
             border="left"
             class="flex-center"
    >
      {{ $t('data_preview.loading_failed') }}

      <div class="spacer" />

      <v-btn color="accent" outlined @click="$emit('retry')">
        {{ $t('actions.retry') }}
      </v-btn>
    </v-alert>

    <v-tabs v-model="tab" v-else icons-and-text centered>
      <v-tabs-slider />

      <v-tab value="projects" v-if="projects !== null">
        {{ $t('data_preview.tab_projects') }}
        <v-icon>mdi-clipboard-text</v-icon>
      </v-tab>

      <v-tab value="charts" v-if="chartsInternal !== null">
        {{ $t('data_preview.tab_charts') }}
        <v-icon>mdi-chart-bar</v-icon>
      </v-tab>

      <v-tab value="dashboards" v-if="dashboardsInternal !== null">
        {{ $t('data_preview.tab_dashboards') }}
        <v-icon>mdi-monitor-dashboard</v-icon>
      </v-tab>

      <v-tab-item v-if="projects !== null">
        <div class="scroll-container" :class="{ 'has-actions': !!$slots['actions-projects'] }">
          <template v-if="loading">
            <v-skeleton-loader v-for="k in 3"
                               :key="k"
                               type="image"
            />
          </template>

          <empty-state v-else-if="!projectsInternal.length"
                       icon="mdi-clipboard-text"
                       :label="$t('data_preview.no_projects.title')"
                       :description="$t('data_preview.no_projects.description')"
          />

          <template v-else>
            <v-card v-for="(project, projectIdx) in projectsInternal"
                    :key="projectIdx"
                    :to="{ name: 'projects-manage-id', params: { id: project.id } }"
            >
              <v-card-text class="project-el">
                <v-tooltip v-if="project.status === 2" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-archive
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.status.completed') }}</span>
                </v-tooltip>
                <v-tooltip v-else-if="project.status === 1" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-timelapse
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.status.inprogress') }}</span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-new-box
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.status.new') }}</span>
                </v-tooltip>

                <div class="name" v-text="project.name" />
                <div class="details">
                  <div>
                    {{ $t('data_preview.n_questions', {
                      n: project.questions.length,
                      rows: project.n_rows
                    }) }}
                  </div>
                  <div>
                    {{ $t('data_preview.created') }} {{ project.created | dateshort }}
                    |
                    <a @click.stop :href="`mailto:${project.owner_email}`" v-text="project.owner" />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </div>

        <div class="actions-container" v-if="$slots['actions-projects']">
          <slot name="actions-projects" />
        </div>
      </v-tab-item>

      <!-- CHARTS -->
      <v-tab-item v-if="chartsInternal !== null">
        <div class="scroll-container" :class="{ 'has-actions': !!$slots['actions-charts'] }">
          <template v-if="loading">
            <v-skeleton-loader v-for="k in 3"
                               :key="k"
                               type="image"
            />
          </template>

          <empty-state v-else-if="!chartsInternal.length"
                       icon="mdi-chart-bar"
                       :label="$t('data_preview.no_charts.title')"
                       :description="$t('data_preview.no_charts.description')"
          />

          <template v-else>
            <v-card v-for="(chart, chartIdx) in chartsInternal"
                    :key="chartIdx"
                    :to="{ name: 'chart-details', params: { id: chart.id } }"
            >
              <v-card-text class="chart-el">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-badge
                      bottom
                      :color="CHART_BADGE_IMAGES[chart.type] ? 'transparent' : 'white'"
                      :value="!!CHART_BADGE_IMAGES[chart.type] || !!CHART_BADGES[chart.type]"
                      :icon="`${CHART_BADGES[chart.type]} text--secondary mr-0`"
                      overlap
                    >
                      <template
                        v-if="CHART_BADGE_IMAGES[chart.type]"
                        v-slot:badge
                      >
                        <v-img :src="CHART_BADGE_IMAGES[chart.type]" class="h-4 ml-n1 mt-n1" />
                      </template>
                      <i class="v-icon notranslate mdi theme--light" :class="CHART_ICONS[chart.type]" v-on="on" />
                    </v-badge>
                  </template>
                  <span>
                    {{ $t(`chart.types.${API_ENUM_TO_CHART_TYPES[chart.type]}.title`) }}
                  </span>
                </v-tooltip>
                <div class="name" v-text="chart.name" />
                <div class="details">
                  <div>{{ $t('data_preview.created') }} {{ chart.created | dateshort }}</div>
                  <div><a @click.stop :href="`mailto:${chart.owner_email}`" v-text="chart.owner" /></div>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </div>

        <div class="actions-container" v-if="$slots['actions-charts']">
          <slot name="actions-charts" />
        </div>
      </v-tab-item>

      <!-- DASHBOARDS -->
      <v-tab-item v-if="dashboardsInternal !== null">
        <div class="scroll-container" :class="{ 'has-actions': !!$slots['actions-dashboards'] }">
          <template v-if="loading">
            <v-skeleton-loader v-for="k in 3"
                               :key="k"
                               type="image"
            />
          </template>

          <empty-state v-else-if="!dashboardsInternal.length"
                       icon="mdi-monitor-dashboard"
                       :label="$t('data_preview.no_dashboards.title')"
                       :description="$t('data_preview.no_dashboards.description')"
          />

          <template v-else>
            <v-card v-for="(dashboard, dashboardIdx) in dashboardsInternal"
                    :key="dashboardIdx"
                    :to="{ name: 'dashboard-details', params: { id: dashboard.id } }"
            >
              <v-card-text class="dashboard-el">
                <v-tooltip v-if="dashboard.is_public" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-lock-open-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('data_preview.is_public.true') }}</span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-lock
                    </v-icon>
                  </template>
                  <span>{{ $t('data_preview.is_public.false') }}</span>
                </v-tooltip>
                <div class="name" v-text="dashboard.name" />
                <div class="details">
                  <div>{{ $t('data_preview.n_elements', { n: dashboard.n_elements }) }}</div>
                  <div>
                    {{ $t('data_preview.created') }} {{ dashboard.created | dateshort }}
                    |
                    <a @click.stop :href="`mailto:${dashboard.owner_email}`" v-text="dashboard.owner" />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </div>

        <div class="actions-container" v-if="$slots['actions-dashboards']">
          <slot name="actions-dashboards" />
        </div>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>

import Vuex from 'vuex'

import projectListMixin from '@/mixins/ProjectList'
import filterDemoData from '@/mixins/filterDemoData'

import {
  /*
  * use types of charts
  * can differs with unique name
  */
  API_ENUM_TO_CHART_TYPES
} from '@/settings/constants'
import {
  icons as CHART_ICONS,
  badges as CHART_BADGES,
  badgeImages as CHART_BADGE_IMAGES
} from '@/settings/chartIcons'

export default {
  name: 'DataPreviewTabs',
  codit: true,

  mixins: [projectListMixin, filterDemoData],

  props: {
    projects: { type: [Array, null], default: null },
    charts: { type: [Array, null], default: null },
    dashboards: { type: [Array, null], default: null },
    loading: { type: Boolean, default: false },
    failed: { type: Boolean, default: false }
  },

  data () {
    return {
      tab: 0,
      projectsInternal: [],

      API_ENUM_TO_CHART_TYPES,
      CHART_ICONS,
      CHART_BADGES,
      CHART_BADGE_IMAGES
    }
  },

  computed: {
    /**
     * Filter out the foreign demo charts
     */
    chartsInternal () {
      if (this.charts === null) return null
      else return this.charts.filter(this.isNotForeignDemoChart)
    },

    /**
     * Filter out the foreign demo dashboard
     */
    dashboardsInternal () {
      if (this.dashboards === null) return null
      else return this.dashboards.filter(this.isNotForeignDemoDashboard)
    },

    ...Vuex.mapState(['user'])
  },

  watch: {
    projects: {
      immediate: true,
      handler () {
        // Don't show demo projects not belonging to the user itself. That's just clutter.
        if (this.projects === null) return null
        this.$set(this, 'projectsInternal', _.cloneDeep(this.projects.filter(this.isNotForeignDemoProject)))
        if (this.projectsInternal !== null) this.computeProjectProperties('projectsInternal')
      }
    }
  }
}

</script>

<style lang=scss>

.preview-card.v-card {
  position: relative;
  background-color: #EEE!important;
  background-image: url(../assets/pattern_topology.svg);
  background-repeat: repeat;

  .empty-state {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.5);
    margin-bottom: 12px;
    color: #555;
    .v-icon {
      color: #999;
    }
  }

  .v-skeleton-loader {
    height: 46px;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  .v-tabs {
    position: absolute;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .v-window {
    height: 100%;
  }

  .v-tabs-items {
    background: none;
    max-height: calc(100% - 72px);
  }

  .v-window-item {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .v-btn {
      margin-top: 12px;
      background: #FFF;
    }
  }

  .scroll-container {
    padding: 8px 16px;
    overflow: auto;
    flex: 1 1 auto;
    &.has-actions {
      max-height: calc(100% - 38px);
      margin-bottom: 5px;
    }
  }

  .v-card {
    margin-bottom: 8px;
    transition: all 0.15s ease;
    cursor: pointer;
    text-align: left;
    border-left: 0px solid rgba(0,0,0,.12);

    .chart-el, .dashboard-el, .project-el {
      display: flex;
      align-items: center;
      .name {
        transition: all 0.15s ease;
        margin-left: 8px;
        margin-right: 8px;
        padding-right: 4px;
        flex: 1;
      }
      .details {
        text-align: right;
        font-size: smaller;
      }
    }

    &:hover {
      border-left-width: 4px;
      background: $col-ans-focus-bg;
      .chart-el, .dashboard-el, .project-el {
        .name {
          transform: translateX(8px);
          padding-right: 0;
        }
      }
    }
  }

  .actions-container {
    flex: 0 0 auto;
    margin: 5px 0;
    .v-btn {
      background: #FFF;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/DataPreviewTabs.json' />
<i18n locale='de' src='@/i18n/de/components/DataPreviewTabs.json' />
