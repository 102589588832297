<template>
  <div class="code-chip-container" tabindex="0" :class="chipClasses" :title="description">
    <div class="code-chip" :style="{ 'max-width': maxWidth === 0 ? 'auto' : `${maxWidth}px` }">
      <template v-if="onlyAvatar">
        <span
          class="avatar"
          :class="hoverAvatar && 'avatar--hover'"
          :style="{
            background: backgroundColor,
            color,
            'box-shadow': `inset 0 0 0 1px ${backgroundColor}`
          }"
          @mouseover="hoverAvatarDebounced(true)"
          @mouseleave="hoverAvatarDebounced(false)"
          @click.prevent.stop
        >
          {{ hoverAvatar ? label : avatarChar.toUpperCase() }}
        </span>
      </template>

      <template v-else-if="avatarChar">
        <span class="avatar" :style="{ background: color }"> {{ avatarChar.toUpperCase() }}</span>
        <span v-html="$escapeHtml(label)" />
      </template>

      <template v-else-if="category">
        <span class="chip-category" :style="{ background: color }"> {{ category.toUpperCase() }}</span>
        <span class="chip-content" v-html="$escapeHtml(label)" />
      </template>

      <v-btn icon v-if="deletable" @click.stop.prevent="$emit('delete', $event)" class="delete-btn" small>
        <v-icon size="21">
          mdi-close-circle
        </v-icon>
      </v-btn>
    </div>
    <div
      v-if="tooltipEnabled"
      class="tooltip-pseudo-el tooltip"
      :class="[`tooltip-${tooltipPosition}`]"
    >
      <span class="manual-content" v-html="$escapeHtml(tooltipHTML)" />
    </div>
  </div>
</template>

<script>

import { isLightColor } from '@/utils/colorUtils'

export default {
  codit: true,
  name: 'CodeChip',

  props: {
    color: {
      type: String,
      default: ''
    },

    backgroundColor: {
      type: String,
      default: ''
    },

    avatarChar: {
      type: String,
      default: ''
    },

    category: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    avatarLabel: {
      type: String,
      default: ''
    },

    expandAvatarOnHover: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    deletable: {
      type: Boolean,
      default: false
    },

    onlyAvatar: {
      type: Boolean,
      default: false
    },

    tooltipPosition: {
      type: String,
      default: 'top'
    },

    maxWidth: {
      type: Number,
      default: 275
    },

    disableTooltip: {
      type: Boolean,
      default: false
    },

    description: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      hasOverflow: false,
      hoverAvatar: false,
      font: {
        label: {
          size: null,
          weight: null
        },
        category: {
          size: null,
          weight: null
        },
        family: ''
      },

      checkIfOverflowDebounced: _.debounce(this.checkIfOverflow),
      hoverAvatarDebounced: _.debounce(this.handleHoverAvatar, 150)
    }
  },

  computed: {
    chipClasses () {
      return {
        'deletable': this.deletable,
        'active': this.active,
        'only-avatar': this.onlyAvatar,
        'has-content': !this.onlyAvatar,
        'has-avatar': this.avatarChar !== '',
        'has-category': this.category !== '',
        'only-category': this.label === '',
        'color-is-light': this.colorIsLight,
        'avatar-hovered': this.hoverAvatar
      }
    },

    tooltipEnabled () { return !this.disableTooltip && (this.onlyAvatar || (this.category && this.hasOverflow)) },

    tooltipHTML () {
      return (this.category ? `${this.category}: <br />` : '') + this.label
    },

    /**
     * Calculate if the given color is light - if yes the font color needs to be adjusted
     * @return {Boolean} True if color is light
     */
    colorIsLight () {
      return isLightColor(this.color)
    }
  },

  watch: {
    /**
     * Whenever the category changes, we need to recompute if there is a potential overflow
     */
    category: {
      immediate: true,
      handler: 'checkIfOverflowDebounced'
    },

    /**
     * Whenever the label changes, we need to recompute if there is a potential overflow
     */
    label: {
      immediate: true,
      handler: 'checkIfOverflowDebounced'
    }
  },

  methods: {
    handleHoverAvatar (hovered) {
      this.hoverAvatar = hovered
    },

    /**
     * Get the font style (size, weight, family) in the current setting
     * Do this by quickly inserting a node into the root of this element,
     * measuring the desired values and then removing it again.
     */
    determineComputedFonts () {
      let codeChipContainer = document.createElement('div')
      codeChipContainer.className = 'code-chip-container'
      codeChipContainer.style.display = 'none'
      let codeChip = document.createElement('div')
      codeChip.className = 'code-chip'
      codeChipContainer.appendChild(codeChip)
      let chipCategory = document.createElement('div')
      chipCategory.className = 'chip-category'
      let chipContent = document.createElement('div')
      chipContent.className = 'chip-content'
      codeChip.appendChild(chipCategory)
      codeChip.appendChild(chipContent)
      this.$el.appendChild(codeChipContainer)
      this.font.label.size = getComputedStyle(chipContent).getPropertyValue('font-size')
      this.font.label.weight = getComputedStyle(chipContent).getPropertyValue('font-weight')
      this.font.category.size = getComputedStyle(chipCategory).getPropertyValue('font-size')
      this.font.category.weight = getComputedStyle(chipCategory).getPropertyValue('font-weight')
      this.font.family = getComputedStyle(chipContent).getPropertyValue('font-family')
      this.$el.removeChild(codeChipContainer)
    },

    /**
     * Check if we have a text overflow situation, leading to the display of a tooltip
     */
    checkIfOverflow () {
      if (this.maxWidth === 0) return
      if (!this.font.family) this.determineComputedFonts()

      // Get the size of the label & category by measuring it on a canvas
      let canvas = document.createElement('canvas')
      let context = canvas.getContext('2d')
      context.font = `${this.font.label.weight} ${this.font.label.size} ${this.font.family}`
      let widthLabel = context.measureText(this.label).width
      context.font = `${this.font.category.weight} ${this.font.category.size} ${this.font.family}`
      let widthCategory = context.measureText(this.category.toUpperCase()).width

      // Subtract the padding and width of delete button
      let maxWidth = this.maxWidth - (this.deletable ? 40 : 20)
      this.hasOverflow = widthLabel > maxWidth || widthCategory > maxWidth
    }
  }
}

</script>

<style lang=scss>

$code-chip-height: 32px;

.code-chip-container {
  position: relative;
  display: inline-block;
  margin: 2px 2px;

  .tooltip-pseudo-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }

  .code-chip {
    background: #FFF;
    color: #888;
    height: $code-chip-height;
    display: inline-block;
    cursor: default;
    transition-property: background-color, color, opacity, transform, box-shadow;
    will-change: background-color, color, opacity, transform, box-shadow;
    transition-duration: .3s;
    font-size: 13px;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
  }

  &:not(.has-category) {
  }

  &.has-content .code-chip {
    min-width: 100px;
  }

  &.has-avatar .code-chip {
    padding-left: 44px;
    padding-right: 10px;
    border-radius: $code-chip-height;
    line-height: $code-chip-height;
  }

  &.deletable.has-avatar .code-chip {
    padding-right: 30px;
    .delete-btn {
    }
  }

  &.has-category:not(.only-category) .code-chip {
    border-radius: $code-chip-height / 4;
    line-height: $code-chip-height / 2;
  }

  .avatar {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50px;
    display: inline-block;
    width: $code-chip-height;
    height: $code-chip-height;
    line-height: $code-chip-height;
    text-align: center;
    vertical-align: center;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;
    }

    &--hover {
      position: static;
      width: auto;
      padding: 0 14px;
    }
  }

  .chip-category {
    height: $code-chip-height / 2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    font-size: smaller;
    font-variant: small-caps;
    font-weight: 450;
    line-height: $code-chip-height / 2;
    padding: 1px 10px;
    text-overflow: ellipsis;
    border-radius: $code-chip-height / 4 $code-chip-height / 4 0 0;
    color: #FFF;
  }

  &.color-is-light .chip-category {
    color: #666;
  }

  &.avatar-hovered .code-chip {
    padding: 0 !important;
  }

  .chip-content {
    padding: 0 10px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  &.only-avatar .avatar { color: #FFF; }

  &:focus {
    outline: none;
  }

  &.active {
    cursor: pointer;
    .code-chip { color: inherit; }
  }

  &.deletable:not(.only-category) .code-chip {
    .chip-category, .chip-content { padding-right: 30px }
  }

  .v-btn {
    width: 26px;
    min-width: 26px;
    height: 26px;
    margin: 0;
    position: absolute;
    top: 50%;
    right: 1px;
    z-index: 4;
    transform: translate3D(0, -50%, 0);
    transition-duration: .3s;
    font-size: 26px;

    .v-icon {
      border-radius: 100%;
      background: #FFF;
    }

    &:hover {
      .v-icon {
        background: #666;
      }
    }
  }

  &.has-category .v-btn {
    top: 50%;
  }

  &.selected .code-chip {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  &.selected .code-chip, &.active:hover .code-chip {
    background-color: #BDBDBD;
    .v-icon {
      background: #BDBDBD;
      /* color: #EEE; */
    }

    .v-icon:hover {
      background: #666;
    }
  }

  &.only-avatar .code-chip {
    padding: 0 $code-chip-height / 2;
  }

  &.only-category .code-chip {
    padding: 0;
    background: none!important;
  }

  &.only-category .chip-category {
    height: $code-chip-height;
    line-height: $code-chip-height;
    border-radius: $code-chip-height / 4;
    align-items: center;
    justify-content: center;
    font-size: inherit;
    position: static;
  }

  &.only-category.deletable .chip-category {
    padding-right: 40px;
  }

  &:hover, &:focus, &.selected {
    .tooltip-pseudo-el {
      .manual-content {
        opacity: 0.9;
      }

      &.tooltip-top .manual-content {
        transform: translate(-50%, -5px);
      }
      &.tooltip-bottom .manual-content {
        transform: translate(-50%, 5px);
      }
      &.tooltip-left .manual-content {
        transform: translate(-5px, 50%);
      }
      &.tooltip-right .manual-content {
        transform: translate(5px, 50%);
      }
    }
  }

  .delete-btn .v-icon {
    width: 24px;
    height: 24px;
  }
  .delete-btn:hover .v-icon {
    color: #FEFEFE;
  }
}

</style>
