import axios from 'axios'
import { HttpMaxAttemptsError } from '@/components/customUi/errors/httpError.js'

export default {
  data () {
    return {
      // export related data
      exports: {
        loading: false,
        list: [],
        isRowsListShown: false,
        isTopicsListShown: false
      },

      cancelTokens: {
        listFetching: null
      }
    }
  },

  watch: {
    /**
    * Update export list suits data kind shown
    */
    async 'formData.kind' () {
      this.stateChangeListUpdater()
    },
    /**
    * Update export list while opened
    * or abort data fetching while closed
    * @returns {any}
    */
    async internalValue (val, old) {
      if (val) {
        this.stateChangeListUpdater()
      } else {
        this.cancelListFetching()
        this.cancelGenerationUpdate()
      }
    }
  },

  computed: {
    isExportsListFullyShown () {
      return (
        this.formData.kind === 0 && this.exports.isRowsListShown
      ) || (
        this.formData.kind === 1 && this.exports.isTopicsListShown
      )
    }
  },

  created () {
    this.setExecuteTillCondition('listFetching')
  },

  methods: {
    setFileExpiredStatus (exportId) {
      _.find(this.exports.list, ({ id }) => id === exportId).status = 4
    },
    /**
    * Update export list takign into account that
    * here is a need to retry request if files are being prepared.
    * Throw nofitication if something goes wrong(bad request, no internet or so)
    */
    async stateChangeListUpdater () {
      this.cancelListFetching()
      this.exports.list = []
      this.exports.loading = true
      try {
        await this.getLastExports()
        this.exports.loading = false
        if (
          this.isListUpdateReady()
        ) {
          return
        }
        await this.executeTillCondition(
          'listFetching',
          this.getLastExports,
          () => this.isListUpdateReady()
        )
      } catch (error) {
        this.exports.loading = false
        if (
          error instanceof HttpMaxAttemptsError
        ) {
          this.$root.snackMsg(this.$t('download_dialog.errors.timeout'))
        } else if (
          error.message === 'Network Error'
        ) {
          this.cancelListFetching()
          this.$root.snackMsg(this.$t('download_dialog.errors.offline'))
        } else if (
          !axios.isCancel(error)
        ) {
          this.$root.snackMsg(this.$t('download_dialog.errors.bad_request'))
        }
      }
    },

    /*
    * NETWORK AND INTERVALS UTILITIES
    */
    /**
    * Abort all related stuff for fetching list of export
    */
    cancelListFetching () {
      this.cancelTokens.listFetching?.cancel()
      this.stopExecuteTillCondition('listFetching')
      this.exports.loading = false
    },

    /*
    * CHECKS UTILITIES
    */
    /**
    * Check if all export files are ready.
    * Otherwise it retries requests and wait till all are done
    * @returns {Boolean} If files are done
    *
    * NOTE:
    * Statuses of export
    *
    * NEW = 0, 'nothing exported yet'
    * IN_PROGRESS = 1, 'an asynchronous export is currently in progress'
    * COMPLETED = 2, 'export is completed'
    * FAILED = 3, 'an asynchronous export failed'
    * EXPIRED = 4, 'exported file is no longer available'
    */

    isListUpdateReady () {
      return this.exports.list.every(({ status }) => status > 1)
    },

    /*
    * MODIFYING DATA UTILITIES
    */
    /**
    * Modifies form data to use in filters fetching export list
    * @returns {any}
    */
    aggregatedFilterData () {
      return {
        kind: this.sources.kindOptions[this.formData.kind].value
      }
    },

    /*
    * LOW LEVEL FUNCTIONS
    * REQUESTS AND OTHER NETWORK STUFF
    */
    /**
    * Creates new export file with generated query
    * @returns {Object} Object with reference to new export file
    */
    /**
    * Fetches previous exports
    */
    async getLastExports () {
      this.cancelTokens.listFetching = axios.CancelToken.source()
      let payload = {
        params: {
          project_id: this.projectId,
          ...this.aggregatedFilterData()
        },
        cancelToken: this.cancelTokens.listFetching.token
      }

      if (this.refId) {
        payload.params.ref = this.refId
      }

      let { data } = await api.get('/api/ui/exports', payload)

      this.exports.list = data.results
    }
  }
}