<template>
  <div>
    <div class="chart-ds-container">
      <router-link
        v-if="renderQuestionName && question && questionMetaData"
        :to="{ name: 'question-cockpit', params: { id: question } }"
        class="subtitle-2 mb-2"
      >
        {{ questionMetaData.project_name }}
      </router-link>
      <v-card
        class="chart-config-el chart-ds default-bs mb-3 mt-1"
        :class="{ duplicated: status.duplicated }"
      >
        <v-card-title>
          <div class="d-flex align-center justify-center w-100 h-100" v-if="status.loading">
            <v-progress-circular indeterminate size="25" width="4" color="primary" />
          </div>
          <v-icon color="error" v-else-if="status.failed">
            mdi-alert-circle
          </v-icon>

          <div class="chart-ds__header d-flex align-center justify-space-between w-100">
            <div class="d-flex align-center">
              <v-btn
                v-if="draggable && editable"
                class="move-handler drag-handle"
                :class="{ draggable: !status.loading && draggable }"
                icon
                small
              >
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12ZM8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14C6 12.8954 6.89543 12 8 12ZM2 6C3.10457 6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6ZM8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6ZM2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0ZM8 0C9.10457 0 10 0.89543 10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0Z" fill="#708189" />
                </svg>
              </v-btn>
              <div class="ml-1">
                <!--
                <router-link
                  v-if="renderQuestionName && question && questionMetaData"
                  :to="{ name: 'question-cockpit', params: { id: question } }"
                  class="chart-ds__header__project"
                >
                  {{ questionMetaData.project_name }}
                </router-link>
                -->
                <div
                  class="chart-ds__header__title"
                  :class="!editable && 'chart-ds__header__title--disabled'"
                  @click="$emit('show-settings')"
                >
                  {{ settings.name }}
                </div>
              </div>
            </div>
            <div class="ds-actions ml-2">
              <v-tooltip bottom v-if="editable">
                <span>{{ $t('ds.actions.edit') }}</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="$emit('show-settings')"
                    icon
                    small
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H17ZM6.29289 9.29289L13.2929 2.29289C13.6534 1.93241 14.2206 1.90468 14.6129 2.2097L14.7071 2.29289L17.7071 5.29289C18.0676 5.65338 18.0953 6.22061 17.7903 6.6129L17.7071 6.70711L10.7071 13.7071C10.5508 13.8634 10.3481 13.9626 10.1314 13.9913L10 14H7C6.48716 14 6.06449 13.614 6.00673 13.1166L6 13V10C6 9.77899 6.07316 9.56552 6.20608 9.39197L6.29289 9.29289L13.2929 2.29289L6.29289 9.29289ZM14 4.41421L8 10.4142V12H9.58579L15.5858 6L14 4.41421Z" fill="#708189" />
                    </svg>
                  </v-btn>
                </template>
              </v-tooltip>

              <v-tooltip bottom v-if="duplicatable">
                <span>{{ $t('ds.actions.duplicate') }}</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    small
                    @click="$emit('duplicate')"
                    icon
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 14L3.11662 13.9933C3.5757 13.9399 3.93995 13.5757 3.99327 13.1166L4 13V4H13L13.1166 3.99327C13.614 3.93551 14 3.51284 14 3C14 2.48716 13.614 2.06449 13.1166 2.00673L13 2H3L2.88338 2.00673C2.4243 2.06005 2.06005 2.4243 2.00673 2.88338L2 3V13L2.00673 13.1166C2.06005 13.5757 2.4243 13.9399 2.88338 13.9933L3 14ZM17 18L17.1166 17.9933C17.5757 17.9399 17.9399 17.5757 17.9933 17.1166L18 17V7L17.9933 6.88338C17.9444 6.46255 17.6343 6.12142 17.2293 6.02641L17.1166 6.00673L17 6H7L6.88338 6.00673C6.4243 6.06005 6.06005 6.4243 6.00673 6.88338L6 7V17L6.00673 17.1166C6.05561 17.5374 6.36575 17.8786 6.77071 17.9736L6.88338 17.9933L7 18H17ZM8 8V16H16V8H8Z" fill="#708189" />
                    </svg>
                  </v-btn>
                </template>
              </v-tooltip>
              <v-tooltip bottom v-if="deletable">
                <span>{{ $t('ds.actions.remove') }}</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    v-on="on"
                    @click="deleteDs"
                    icon
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15.5523 8 16 8.44772 16 9V15C16 16.6569 14.6569 18 13 18H7C5.34315 18 4 16.6569 4 15V9C4 8.44772 4.44772 8 5 8H15ZM14 10H6V15C6 15.5523 6.44772 16 7 16H13C13.5523 16 14 15.5523 14 15V10ZM7 3C7 2.44772 7.44772 2 8 2H12C12.5523 2 13 2.44772 13 3V4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5C3 4.44772 3.44772 4 4 4H7V3Z" fill="#708189" />
                    </svg>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>

          <div class="d-flex align-center w-100 chart-ds__actions">
            <div class="matches-container-header mr-2" v-if="!status.failed">
              <v-tooltip bottom :max-width="170">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="results-btn default-bs"
                    @click="$emit('show-answers')"
                    v-on="on"
                    small
                    elevation="0"
                  >
                    {{ $t('ds.filters.n_matches', { n: resultCount }) }}
                  </v-btn>
                </template>
                <span>{{ $t('ds.verbatim_browser.helptip_ds') }}</span>
              </v-tooltip>
            </div>

            <div class="matches-container-header" v-if="!status.failed && !status.loading && !disableFilters">
              <v-tooltip bottom :max-width="170">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="filters-btn"
                    @click="$emit('show-filters')"
                    v-on="on"
                    small
                    elevation="0"
                  >
                    {{ $t('ds.filters.count', { n: filters.length }) }}
                  </v-btn>
                </template>
                <span>{{ $t('ds.filters.count_helptip') }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-card-title>
      </v-card>
    </div>
    <slot name="default" />
  </div>
</template>

<script>

import colorPalettes from '@/mixins/colorPalettes'
import answerFilters from '@/mixins/answerFilters'
import { mapGettersWithKey } from '@/utils/vuex.js'

_ = require('lodash')

export default {
  codit: true,
  name: 'Dataset',

  mixins: [colorPalettes, answerFilters],

  props: {
    settings: { type: Object, default: () => ({}) },
    filters: { type: Array, default: () => [] },
    result: { type: [Number, Array], default: null },
    question: { type: String, default: '' },
    status: { type: Object, default: () => ({}) },
    disableFilters: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    deletable: { type: Boolean, default: false },
    duplicatable: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    renderQuestionName: { type: Boolean, default: false },
    meta: { type: Object, default: () => ({}) }
  },

  computed: {
    ...mapGettersWithKey({
      questionMetaData: 'questionManager/state'
    })(function () { return this.question }),

    /**
     * Returns the number of matches
     */
    resultCount () {
      return _.isNumber(this.result) ? this.result : ''
    }
  },

  methods: {
    /**
     * Delete the dataset
     */
    deleteDs () {
      this.$emit('delete')
      this.$emit('modified', true)
    }
  }
}

</script>

<style lang=scss>

.sortable-ghost {
  opacity: 0.5;
  .v-list {
    background: none;
  }
  .v-card__title, .v-card__text {
    animation: highlight-ani 1s;
  }
}

.chart-ds-container {
  .subtitle-2 {
    color: #666;
    margin-left: 1px;
    font-weight: 400 !important;
  }
}

.chart-ds {
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.5,1) !important;

  &__header {
    border-bottom: 1px solid #EBF0F4 !important;
    padding: 8px;

    &__title {
      font-size: 15px !important;
      /* line-height: 17px; */

      &--disabled {
        pointer-events: none;

        &:hover {
          cursor: default;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__project {
      font-size: 12px;
      /* line-height: 0px; */
      color: $grey-color !important;
    }
  }

  &__actions {
    padding: 16px 12px;
  }

  .v-card__title {
    position: relative;
    padding: 0;

    .drag-handle {
      cursor: ns-resize;
    }

    .title {
      font-size: 17px !important;
      max-width: 90%;
      font-size: 19px !important;
      font-weight: 500 !important;
      display: block;
      position: relative;
      &.cursor-pointer {
        cursor: pointer;
      }
    }

    .matches-container-header {
      display: block;
      white-space: nowrap;
      .v-btn {
        margin: 0;
        text-transform: lowercase;
        border-radius: 4px;
      }

      .results-btn {
        height: 26px;
        font-size: 13px;
        padding: 3px 10px;
        letter-spacing: normal;
        border-radius: 25px;
        color: white;
        font-weight: 500;
        background-color: $green-color !important;

        .v-btn__content {
          text-transform: lowercase !important;
        }
      }

      .filters-btn {
        height: 26px;
        font-size: 13px;
        padding: 3px 10px;
        text-transform: lowercase !important;
        letter-spacing: normal;
        border-radius: 25px;
        font-weight: 500;
        color: $text-color;
        /* background: rgba(14, 93, 136, 0.1) !important; */
        background: white !important;
        border: 1px solid #D8E2E8 !important;

        .v-btn__content {
          text-transform: lowercase !important;
        }
      }
    }
  }

  &.duplicated {
    .v-card__title, .v-card__text {
      animation: highlight-ani 1s;
    }
  }

  .filter-item, .setting-item {
    .v-list-item__content {
      justify-content: space-between;
      .auxiliary-select {
        flex: 0 0 120px;
        margin-right: 2em;
      }

      label.from, label.to {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: -15px;
        font-size: 10.5px;
      }

      .min-max {
        flex: 1 1 80px!important;
        margin-top: 0!important;
      }

      .v-input.invert {
        margin-right: 0;
      }
    }

    .matches-container {
      flex: 0 0 50px;
      margin-right: 10px;
      margin-bottom: 4px;
      margin-left: 5px;
      text-align: right;
    }

    .v-list-item__action {
      .helptip {
        margin-right: 4px;
        .anchor { color: rgba(0, 0, 0, 0.54) }
      }
    }
  }

  .ds-actions {
    flex-shrink: 0;
    .delete-ds {
      position: relative;
      z-index: 1;
      color: white;

      &.delete-clicked {
        width: auto;
      }
    }
  }

  .color-indicator {
    width: 30px;
    height: 30px;
    flex: 0 0 auto;
    margin: 0 auto 0 12px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.4s ease;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

.settings-drawer {
  .v-input__append-inner {
    padding-left: 0 !important;
  }

  .overflown {
    width: calc(100% + 24px);
    margin-right: -12px;
    margin-left: -12px;
  }

  .separator {
    width: calc(100% + 24px);
    border-bottom: 1px solid $border-color;
    margin: 15px -12px 0px -12px;
  }

  .matches-container {
    position: relative;
    top: 6px;
    left: 5px;

    .results-btn {
      color: white;
      background-color: white !important;
      /* border: 1px solid #c3e6cb; */
      text-transform: lowercase;
      border-radius: 25px;
    }
  }
  .filters-btn {
    &.overflown {
      /* color: white !important; */
      border-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 45px !important;

      background-color: #DCEEFB !important;
      color: #0A558C !important;
    }
  }

  button.master-filters-btn {
    border-radius: 4px;
    bottom: -15px;
  }

  .filter-type {
    font-weight: 400;
    color: black !important;
    font-size: 15px;
    text-transform: capitalize !important;
    line-height: 1rem !important;
    letter-spacing: normal !important;
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
