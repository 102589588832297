<template>
  <div class="main-content wizard wizard-page wizard-v2-page">
    <div v-if="loading" class="loading d-flex justify-content-center mt-5 ml-auto mr-auto">
      <v-progress-circular size="50" indeterminate color="primary" />
    </div>
    <reset-coding-dialog
      v-if="!loading && isNotFresh"
      :project="project"
      :coding-column="codingColumn"
      :callback-function-returning-promise="resetCodingCallback"
    />
    <question-steps v-if="!loading" />
    <codebook-refinement
      v-if="!loading"
      :init="init"
      :infer-data="getWizardRemoteResults"
      :reset-data="resetData"
    >
      <row-browser
        v-if="!loading"
        :id="id"
        :store-name="id"
        :reset-button="false"
        :coding-view="false"
        :translations-enabled="false"
        :wizard-view="true"
        :data="answersFiltered"
        :init="init"
        :load-data="getWizardRemoteResults"
        :reset-data="resetData"
      />
    </codebook-refinement>
  </div>
</template>

<script>

import Vuex from 'vuex'
import ResetCodingDialog from '@/components/wizard/ResetCodingDialog'
import QuestionSteps from '@/components/wizard/QuestionSteps.vue'
import CodebookRefinement from '@/components/wizard/CodebookRefinement.vue'
import { getterWithKeyDeconstructed } from '@/utils/vuex.js'
import RowBrowserEnv from '@/mixins/rowBrowserEnv.js'
import RowBrowser from '@/components/RowBrowser'

export default {
  codit: true,
  name: 'Wizard',
  components: {
    QuestionSteps,
    CodebookRefinement,
    RowBrowser,
    ResetCodingDialog
  },

  mixins: [RowBrowserEnv],

  data () {
    return {
      wizardView: true
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user',
      id: state => state.wizard.id,
      loading: state => state.wizard.loading,
      loadingFailed: state => state.wizard.loadingFailed,
      project: state => state.wizard.project,
      codingColumn: state => state.wizard.codingColumn,
      isNotFresh: state => state.wizard.isNotFresh,
      topics: state => state.wizard.topics,
      inheritsFrom: state => state.wizard.inheritsFrom,
      questionID: state => state.wizard.questionID,
      suggestions: state => state.wizard.suggestions,
      step: state => state.wizard.step,
      projectID: state => state.wizard.projectID,
      columnRef: state => state.wizard.columnRef,
      success: state => state.wizard.success
    }),

    ...getterWithKeyDeconstructed('verbatimManager/state')(() => 'wizard')(['loaded'])
  },

  watch: {
  },

  async created () {
    await this.$store.dispatch(`verbatimManager/register`, {
      entityId: this.id
    }, { root: true })

    // Load the initial data
    const projectID = this.$route.params.id
    const columnRef = this.$route.params.ref

    this.$store.dispatch('loadData', { projectID, columnRef }).then(() => {
      if (!this || this.destroying) return
      // Set breadcrumbs
      this.$store.commit('setBreadcrumbProps', {
        projectID: this.project.id,
        projectName: this.project.name,
        ref: this.codingColumn.ref,
        questionName: this.codingColumn.name
      })
    })
  },

  beforeDestroy () {
    this.destroying = true
  },

  mounted () {
  },

  updated () {
  },

  beforeRouteLeave (to, from, next) {
    let doIt = true
    // If we are beyond step 1, confirm navigating away
    if (this.step > 1 && !this.success) doIt = confirm(this.$t('confirm_navigate_away'))
    next(doIt)
  },

  methods: {
    /**
     * Callback before navigating to another web page
     * If we are beyond step 1, make the user confirm the navigation
     * @param  {Event} $event   The navigation event
     */
    beforeUnload ($event) {
      if (this.step > 1) $event.returnValue = true
    },

    /**
     * Reload the current route
     * @return {[type]} [description]
     */
    reload () {
      this.$router.go()
    },

    /**
     * The callback, which is called *after* a successful reset
     * @return {Promise}  The promise, upon whose completion the reset dialog will be closed
     */
    resetCodingCallback () {
      // If this is the question the entire wizard is about
      // Just reload the page, makes things simpler
      return new Promise((resolve) => {
        resolve()
        this.reload()
      })
    }
  }
}

</script>

<style lang=scss>
  @import '~css/wizard';
  @import '~css/coding';
  @import '~css/codebookv2';
  @import '~css/filters';
</style>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />