<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane v-model="config"
                    v-bind="controlPaneProps"
                    v-on="controlPaneEvents"
                    ref="control-pane"
                    slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
        <template v-slot:general>
          <div class="list-allow-overflow mb-4">
            <settings-drawer-item
              :title="$t('controls.general.plot_value.title')"
            >
              <template slot="content">
                <v-radio-group v-model="config.plotValue" column hide-details class="mt-0">
                  <template v-slot:label>
                    <div class="d-flex align-center justify-space-between mt-0">
                      <span>{{ $t('controls.general.plot_value.title') }}&nbsp;</span>
                      <helptip position="bottom" :width="600">
                        <div v-html="$t('controls.general.plot_value.helptip', {
                          href_docs: 'https://caplena.com/docs/knowledge-base/6abgdel2j5c3p-correlation-metrics',
                        })"
                        />
                      </helptip>
                    </div>
                  </template>

                  <v-radio :label="$t('controls.general.plot_value.cooccurrences')" value="cooccurrences" />
                  <v-radio :label="$t('controls.general.plot_value.jaccard')" value="jaccard" />
                  <v-radio :label="$t('controls.general.plot_value.kulc')" value="kulc" />
                </v-radio-group>
              </template>
            </settings-drawer-item>
          </div>

          <settings-drawer-item
            :title="$t('controls.general.min_edge_value')"
          >
            <template slot="content">
              <v-slider v-if="config.minEdgeThresh !== null"
                        :value="config.minEdgeThresh"
                        :max="maxCooccurrenceCount"
                        :min="0"
                        @input="setMinEdgeThreshDebounced"
                        thumb-label="always"
                        :step="1"
                        thumb-size="20"
                        dense
                        hide-details
                        class="mt-5"
              />
            </template>
          </settings-drawer-item>
        </template>
        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('settings.decimal_places.title')"
          >
            <template slot="content">
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-checkbox
            v-model="config.showSampleSize"
            hide-details
            :label="$t('settings.sample_size.label_control')"
            class="mt-2"
          />
        </template>

        <slot
          name="datasets"
          slot="datasets"
          :dataset-props="{ editable: false }"
        />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <slot name="chart-type-selection" slot="chart-type-selection" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <v-chart ref="chart"
                 v-if="initialReady"
                 :style="chartStyle"
                 v-bind="chartProps"
                 v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'

import 'echarts/lib/chart/graph'

import { mapGettersWithKey } from '@/utils/vuex.js'

import {
  SENTIMENT_RANGE
} from '@/settings/constants'
const RELATION_METHODS = {
  'cooccurrences': 'cooccurrence',
  'jaccard': 'jaccards',
  'kulc': 'kulcynski'
}

export default {
  codit: true,
  name: 'ChartGraph',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [chartMixin],

  data () {
    return {
      resultKeys: ['counts', 'cooccurrence', 'jaccards', 'kulcynski'],
      APItype: 'GRAPH',
      forceAggregate: true,

      config: {
        // General
        title: undefined,
        aggregate: undefined,
        plotValue: 'kulc',
        minEdgeThresh: null,
        dimensions: undefined,

        // Color
        colorBy: {},
        colorPalette: undefined,
        colorPaletteValue: undefined,

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255,1)',

        // Labels
        maxDataLabelLength: 15,
        labelsEnabled: true,
        dataLabelSize: 12,
        showLegend: true,
        showSampleSize: true,
        axisLabelSize: 11,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },

      chartContainerWidth: 0,

      setMinEdgeThreshDebounced: _.debounce(val => {
        this.config.minEdgeThresh = val
      }, 250)
    }
  },

  computed: {
    /*
    *
    * VUEX REFERENCES
    *
    */
    ...mapGettersWithKey({
      topicCats2CatIdx: 'metaManager/topicCats2CatIdx',
      topicID2Topic: 'metaManager/topicID2Topic',
      isReady: 'registerManager/isReady'
    })(function () { return this.id }),

    topicsSortedCats () {
      return _.chain(this.topics)
        .groupBy('category')
        .keys()
        .value()
    },

    /*
    *
    * CHART SETTINGS PANEL
    * AND GENERAL VISUALISATION BY LIBRARY
    * AND RELATED
    *
    */

    /**
     * The size of the biggest node, depending on the number of topics that we have to plot
     * @return {Number}
     */
    maxNodeSize () {
      const topicsAmount = Object.values(this.results.counts.topics[0]).length
      const multiplier = 60
      if (
        topicsAmount <= 20
      ) {
        return multiplier
      }
      return multiplier * 20 / topicsAmount
    },

    /**
     * The maximum value of two topics' cooccurrences
     * @return {Number}
     */
    maxCooccurrenceCount () {
      return this.getMaxCoocurenceValues(this.coocuranceRelations)
    },

    /**
     * Overrides the color palettes defined in chart mixin
     * We want the strong palette here, such that nodes are solid
     * @return {Object} Object with mapping of palette name -> palette
     */
    colorPalettes () {
      return { ...this.configColorPalettes, default: this.$color.paletteStrong, bold: this.colorPaletteBold, ...this.userColorPalettes }
    },

    /**
     * Return true if the palette should be according to the colors defined on the topics
     * @return {Boolean}
     */
    useCBColors () {
      return this.config.colorPalette === '__cb_colors__'
    },

    /*
    *
    * CHART DATA RELATED STUFF
    *
    */

    /**
    * The mapped value of calculated relation method
    * @returns {any}
    */
    relationSource () {
      return RELATION_METHODS[this.config.plotValue]
    },

    /**
    * The sorted list of sorted topics ids
    * @returns {Array}
    */
    topicsToRenderIdsList () {
      return this.topics.map(({ id }) => id)
    },

    /**
    * The maximum number of entries of one topic
    * @returns {Number}
    */
    maxTopicCount () {
      return Math.max(
        ...Object.values(
          Object.values(this.results.counts.topics[0]).map(
            item => this.getDataItemSource(item, this.sentimentShown)?.counts?.overall
          )
        )
      )
    },

    /**
    * The maximum value of two topics' cooccurrences for current method
    * @return {Number}
    */
    maxActualCooccurrenceCount () {
      return this.getMaxCoocurenceValues(this.relations)
    },

    /**
    * Calculated list of relations to apply to the chart
    * made with applied relation method.
    * Key format is ${relationFromTopic}-${relationToTopic}
    * @return {Object}
    */

    relations () {
      if (
        !Object.keys(this.coocuranceRelations).length
      ) {
        return {}
      }

      let source
      if (
        this.relationSource === 'cooccurrence'
      ) {
        source = this.coocuranceRelations
      } else {
        source = this.calculateRelationList(this.relationSource)
      }

      return _.pickBy(source, (value, key) => this.coocuranceRelations[key] >= this.config.minEdgeThresh) // removes too small values
    },

    /**
    * Calculated list of relations to apply to the chart
    * made with cooccurrence method.
    * Necessary to have it due to UI calculations.
    * Key format is ${relationFromTopic}-${relationToTopic}
    * @return {Object}
    */

    coocuranceRelations () {
      return this.calculateRelationList('cooccurrence')
    },

    /**
    * Calculated list of nodes
    * Every topic has one node
    * @return {Array} Array of nodes
    */

    nodes () {
      if (
        !this.results.counts?.topics?.length
      ) {
        return []
      }

      return this.generateChartData(this.results.counts.topics[0], this.calculateNodeItem, [])
    },

    /**
    * Calculated list of links: A link is created for every pair of topics which has
    * a cooccurrence count >= config.minEdgeThresh
    * Links are created in both directions on purpose: This makes the edges
    * have colors mixed from both nodes.
    * @return {Array}
    */
    links () {
      if (
        !Object.keys(this.relations).length
      ) {
        return []
      }

      return Object.entries(this.relations).map(([relation, value]) => this.calculateLinkItem(relation, value))
    },

    /*
    * !!!
    * CHART SETTINGS ASSIGNMENTS
    * FOLLOWS ACTUAL ORDER OF USAGE
    * !!!
    */

    /**
    * Series settings
    * in use for graph chart.
    * Will be combined into chart options
    * @return {Object}
    */
    series () {
      return [{
        name: this.datasets.length ? this.datasets[0].settings.name : '',
        width: '60%',
        height: '45%',
        type: 'graph',
        layout: 'circular',
        nodes: this.nodes,
        links: this.links,
        // How much the nodes scale when zooming: 1.0 means they scale exactly as much as is zoomed
        nodeScaleRatio: 0.9,
        // Hover effect putting emphasis on specific nodes / links
        // focusNodeAdjacency: true,
        emphasis: { focus: 'adjacency' },
        categories: _.map(this.topicCats, cat => ({ name: cat })),
        circular: {
          // The label are displayed in star pattern
          rotateLabel: true
        },
        label: {
          show: this.config.labelsEnabled,
          position: 'insideRight',
          overflow: 'break',
          lineOverflow: 'truncate',
          width: 80,
          fontSize: this.config.dataLabelSize
        },
        // Allow panning only
        roam: 'pan',

        // For reference: The force settings, not relevant for circular mode
        force: {
          // repulsion: 500,
          initLayout: 'circular',
          // gravity: 2,
          repulsion: 500
          // edgeLength: [10, 100]
        },
        lineStyle: {
          // Always get the color from source, but as we plot both directions, the two edges
          // are overlayed and thus form the mixed color from source and destination
          color: 'source',
          curveness: 0.3,
          opacity: 0.3
        },
        animation: false
      }]
    },

    /**
    * Tooltip settings
    * in use for graph chart.
    * Will be combined into chart options
    * @return {Object}
    */
    tooltip () {
      return {
        formatter: (el) => {
          if (
            el.dataType === 'node'
          ) {
            return `${this.$escapeHtml(el.data.label)}<br>${Math.round(el.data.cnt)} (${this.toFixed(el.data.perc, this.config.decimalPlaces)}%)`
          } else if (
            el.dataType === 'edge'
          ) {
            const source = this.topicID2Topic[el.data.source]
            const target = this.topicID2Topic[el.data.target]
            return `${this.$escapeHtml(source.label)} < > ${this.$escapeHtml(target.label)}: ${el.data.val}`
          }
        },
        confine: true
      }
    },

    /**
    * Colors settings
    * in use for graph chart.
    * Will be combined into chart options
    * @return {Object}
    */
    colorsIncludingCBColors () {
      if (this.config.colorPalette === '__cb_colors__') {
        return _.map(this.topicsSortedCats, cat => this.catColors[cat].medium)
      } else return this.colors
    },

    /**
    * Legend settings
    * in use for graph chart.
    * Will be combined into chart options
    * @return {Object}
    */
    legend () {
      return {
        show: this.config.showLegend,
        data: this.topicsSortedCats?.map(value => ({ name: value })),
        top: 30
      }
    },

    title () {
      const options = {
        textStyle: {
          fontStyle: 'italic',
          fontSize: this.config.axisLabelSize,
          fontWeight: 'normal',
          color: 'grey',
          width: 200,
          align: 'right'
        },
        bottom: 0,
        right: 0
      }

      if (
        this.config.showSampleSize &&
        typeof this.globalResults?.counts?.overall === 'number'
      ) {
        options.text = `n=${this.globalResults?.counts?.overall}`
      }

      return options
    },

    /*
    * Returns combined compicated options for chart
    * @return {Object}
    */
    chartOptions () {
      return {
        ...this.defaultOptions,
        series: this.series,
        tooltip: this.tooltip,
        color: this.colorsIncludingCBColors,
        title: this.title,
        legend: this.legend,
        backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
      }
    }
  },

  watch: {
    /*
    * hack in order to rerender the chart after result refresh
    */
    results: {
      immediate: true,
      handler (newVal, oldVal) {
        this.$nextTick(() => {
          if (this.$refs.chart) this.$refs.chart.refresh()

          // not initialized component
          // has no items in results.counts?.categories?.[0]
          // until it's fully loaded
          if (
            this.config.minEdgeThresh !== null &&
            !_.isEqual(newVal, oldVal) &&
            !!newVal?.counts?.categories?.[0] &&
            !!oldVal?.counts?.categories?.[0] &&
            !!Object.keys(newVal.counts?.categories?.[0]).length &&
            !!Object.keys(oldVal.counts?.categories?.[0]).length
          ) {
            this.updateEdgeThresh()
          } else if (
            !!newVal.counts?.categories?.[0] &&
            !!Object.keys(newVal.counts?.categories?.[0]).length &&
            this.config.minEdgeThresh === null
          ) {
            this.updateEdgeThresh()
          }
        })
      }
    }
  },

  methods: {
    /*
    *
    * CHART MIXINS METHODS REASSIGNMENT
    *
    */

    /**
    * prepares the resultsComputed
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     dsIdx {Number}:    dataset index
    *     key   {String}:    result key from resultKeys
    */
    computeResults ({ res, dsIdx, key }) {
      let { catResult, topicResult, catResultSentiment } = this._getEmptyResultsContainer()
      // Set the code results

      // Set the category results
      res?.results_per_category?.forEach(cat => {
        catResult[cat.category] = this.aggregate ? cat.results[key].overall : cat.results[key].per_dataset[dsIdx]
      })

      // Set the topic results
      res?.results_per_topic?.forEach(topic => {
        topicResult[topic.id] = topic
      })

      /*
      * sentiment data for categories
      * use it for sentiment bar chart
      */
      res?.results_per_category?.forEach(cat => {
        catResultSentiment[cat.category] = cat?.sentiments
      })

      this.$set(this.resultsComputed[key].categories, dsIdx, catResult)
      this.$set(this.resultsComputed[key].topic, dsIdx, topicResult)
      this.$set(this.resultsComputed[key].categoriesWithSentiment, dsIdx, catResultSentiment)
    },

    /*
    *
    * COMPUTED RELATED METHODS
    *
    */

    /**
    * Returns maximum value of two topics' cooccurrences for a method
    * @return {Number}
    */
    getMaxCoocurenceValues (relationList) {
      return Math.max(...Object.values(relationList))
    },

    /*
    *
    * CHART DATA RELATED STUFF
    *
    */

    /**
    * Creates list of values
    * filtering input first
    * and calling data handlers
    * @return {Object|Array}
    */
    generateChartData (dataSource, formatter, defaultValue) {
      return Object.values(dataSource)
        .filter(
          (topic) => this.topicsToRenderIdsList.indexOf(topic.id) > -1 && ( // plotTopic and plotCategories application
            SENTIMENT_RANGE.indexOf(this.sentimentShown) === -1 || // in sentiment toogle set, filter reduntant
            topic.sentiment_enabled
          )
        )
        .reduce((sum, topic) => {
          const result = formatter(topic, this.sentimentShown, sum)
          if (
            Array.isArray(sum)
          ) {
            return [
              ...sum,
              result
            ]
          } else if (
            typeof sum === 'object'
          ) {
            return {
              ...sum,
              ...result
            }
          }
        }, defaultValue)
    },

    /**
    * Returns topic data source
    * corresponding the sentiment shown
    * @return {Object}
    */
    getDataItemSource (topic, sentiment) {
      if (
        SENTIMENT_RANGE.indexOf(sentiment) === -1
      ) {
        return topic.results
      } else {
        return topic.sentiments[sentiment]
      }
    },

    /**
    * Returns combined chart node item settings and data
    * @return {Object}
    */
    calculateNodeItem (topic, sentiment) {
      let dataSource = this.getDataItemSource(topic, sentiment)

      return {
        id: topic.id, // (str) identifies node for links
        name: this.maybeTruncateLabel(topic.label), // (str) Truncated name, displayed as label
        label: topic.label, // (str) Custom: Untruncated name & description used for tooltip
        value: Math.max(this.maxNodeSize * dataSource?.counts?.overall / this.maxTopicCount || 0, 10), // (int) Code count scaled such that largest node has value 75
        symbolSize: Math.max(this.maxNodeSize * dataSource?.counts?.overall / this.maxTopicCount || 0, 10), // (int) Same as value (both need to be set - symbolSize determines size of node and value determines distance to neighbours)
        category: this.topicCats2CatIdx?.[topic.category], // (int), which is then again mapped back to string in series definition
        categoryLabel: topic.category, // Custom: The category name used for tooltip
        cnt: dataSource?.counts?.overall, // Custom: The unscaled counts used for tooltip
        perc: !dataSource?.counts?.overall ? 0 : 100 * dataSource?.counts?.overall / this.globalResults?.counts?.overall, // Custom: The percentage used for tooltips
        color: '#000',
        itemStyle: this.useCBColors ? {
          color: this.catColors[topic.category].strong
        } : undefined
      }
    },

    /**
    * Creates a list of relations for a relation method.
    * Key format is ${relationFromTopic}-${relationToTopic}
    * Iterates topics and collect available relation for each, can be more that 1 result
    * @return {Object}
    */
    calculateRelationList (method) {
      if (
        !this.results[method].topics?.length
      ) {
        return {}
      }

      return this.generateChartData(this.results[method].topics[0], (topicFrom, sentiment, sum) => this.calculateRelationItem(topicFrom, sentiment, sum, method), {})
    },

    /**
    * Creates relation items
    * handling 1 topic's data.
    * @return {Object}
    */
    calculateRelationItem (topicFrom, sentiment, sum, method) {
      let dataSource = this.getDataItemSource(topicFrom, sentiment)

      if (
        !dataSource[method]?.overall
      ) {
        return sum
      }

      return {
        ...sum,
        ...dataSource[method].overall
          .filter(({ id }) => typeof sum[`${id}-${topicFrom.id}`] === 'undefined')
          .filter(({ val }) => !!val)
          .filter(({ id }) => topicFrom.id !== id)
          .reduce((sum, topicTo) => ({
            ...sum,
            [`${topicFrom.id}-${topicTo.id}`]: topicTo.val
          }), {})
      }
    },

    /**
    * Returns combined chart link item settings and data
    * @return {Object}
    */
    calculateLinkItem (relation, value) {
      const [source, target] = relation.split('-')

      return {
        source, // (str) ID of source node
        target, // (str) ID of target node
        val: value,
        lineStyle: {
          // Line width is corresponding to the number of cooccurrences,
          // scaled such that the most important edge always has a value of 15
          width: 15 * Math.abs(value) / this.maxActualCooccurrenceCount
        }
      }
    },

    /*
    *
    * USER INITIATED METHODS
    *
    */

    /**
    * Assigns minimal number of relations to render
    */
    updateEdgeThresh () {
      this.config.minEdgeThresh = Math.round(this.maxCooccurrenceCount / 5)
    },

    /*
    *
    * OUTSIDE CALLS
    *
    */

    /**
    * Handles for chart clicks made by the user
    * @param {Object} $evt Event from echarts
    */
    chartClick ($evt) {
      let filter, payload

      if ($evt.componentType === 'title') {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'series' &&
        $evt.dataType === 'node' &&
        !$evt.data.cnt
      ) {
        return
      } else if (
        $evt.componentType === 'series' &&
        $evt.dataType === 'node'
      ) {
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${$evt.data.id}:${this.sentimentShown}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml($evt.data.label)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${this.sentimentShown}`
        }
      } else if (
        $evt.componentType === 'series' &&
        $evt.dataType === 'edge'
      ) {
        const source = this.topicID2Topic[$evt.data.source]
        const target = this.topicID2Topic[$evt.data.target]

        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${$evt.data.source}:${this.sentimentShown};topic.${$evt.data.target}:${this.sentimentShown}`,
          htmlText: `
            <div class="font-weight-medium">
              ${this.$t('answer_fields.topics')}
            </div>:&nbsp;${this.$escapeHtml(source.label)} &nbsp;=>&nbsp;${this.$escapeHtml(target.label)} &&nbsp; <div class="font-weight-medium">
              ${this.$t('answer_fields.sentiment')}
            </div>:&nbsp;${this.sentimentShown}`
        }
      }

      if (
        $evt.componentType !== 'series' ||
        $evt.data.cnt === 0 ||
        !this.isVerbatimEnabled
      ) {
        return
      }

      payload = {
        item: this.id || 'ch__new',
        filters: [filter]
      }

      this.$store.dispatch('verbatimDialog/show', payload)
    }
  }
}

</script>

<style lang=scss>

</style>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGraph.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGraph.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />
