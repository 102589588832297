<template>
  <div @keydown.esc="hideQuestions" id="project-list">
    <div v-if="title && title.length" class="mb-0 font-weight-medium text-xl">
      {{ title }}
    </div>
    <div v-if="showTabs && tabs.length" class="tabs">
      <div class="tabs__slider-container">
        <swiper
          class="swiper component"
          :options="swiperOption"
          :auto-update="false"
          ref="tabSwiper"
        >
          <swiper-slide
            v-for="(tab, idx) in tabs"
            :key="idx"
            class="tabs__tab"
            :class="{
              'tabs__tab--active': tab && filters.tab && filters.tab.value && tab.value === filters.tab.value,
              'tabs__tab--disabled': loading
            }"
            :style="`border-color: ${tab.color};`"
            @click.native="setActiveTab(idx)"
          >
            <div
              v-if="tab.isTag"
              class="tabs__tab__tag-indicator"
              :style="`background-color: ${tab.color};`"
            />
            {{ tab.name }}
          </swiper-slide>
          <div
            class="swiper-button-prev prev-button"
            slot="button-prev"
            @click.prevent.stop
          >
            <v-icon
              :size="25"
            >
              mdi-chevron-left
            </v-icon>
          </div>
          <div
            class="swiper-button-next next-button"
            slot="button-next"
            @click.prevent.stop
          >
            <v-icon
              :size="25"
            >
              mdi-chevron-right
            </v-icon>
          </div>
        </swiper>
      </div>
    </div>
    <div class="default-bs project-list-container">
      <div class="search-bar">
        <div class="d-flex align-center">
          <v-text-field
            class="search-bar__search"
            prepend-inner-icon="mdi-magnify"
            dense
            :elevation="0"
            :placeholder="$t('search_term')"
            @input="debounceHandleSearchChange"
            :value="filters.search"
            hide-details
          />
          <v-checkbox
            v-if="archivedFilterEnable"
            color="green"
            class="search-bar__archived"
            :label="$t('show_completed')"
            off-icon="mdi-checkbox-blank"
            hide-details
            :ripple="false"
            flat
            v-model="filters.archived"
          />
        </div>
        <template v-if="addEnable">
          <v-btn color="green" :to="{ name: 'upload' }" elevation="0">
            {{ $t('new_project') }}
          </v-btn>
        </template>
      </div>

      <transition name="toolbar-squeeze" mode="out-in">
        <div
          v-if="projectsSelected.length < 1"
          class="filters d-flex justify-space-between"
        >
          <div
            class="d-flex"
          >
            <v-menu
              offset-y
              :close-on-content-click="false"
              :rounded="'lg'"
              class="filters__list-container"
              v-model="filterSelectorShown"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="filters__select"
                  :class="filterSelectorShown && 'filters__select--active'"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  hide-details
                  solo
                  dense
                >
                  <v-icon>
                    mdi-plus-circle
                  </v-icon>
                  {{ $t('filters.add_filter') }}
                </v-btn>
              </template>

              <div class="d-flex">
                <div class="filters__list">
                  <div class="text-xxs text-grey text-uppercase font-weight-500 mb-1 pl-3">
                    {{ $t('filter_by') }}
                  </div>
                  <div
                    class="filters__list__item"
                    @click="handleChangeActiveFilterSelectorItem($t('filters.labels'))"
                    :class="activeFilterSelectorTab === $t('filters.labels') && 'filters__list__item--active'"
                  >
                    <div class="d-flex align-center">
                      <v-icon class="filters__list__item__icon" :size="18">
                        mdi-label-outline
                      </v-icon>
                      {{ $t('filters.labels') }}
                    </div>
                    <v-icon :size="17" class="filters__list__item__arrow">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                  <div
                    class="filters__list__item"
                    @click="handleChangeActiveFilterSelectorItem($t('filters.owner'))"
                    :class="activeFilterSelectorTab === $t('filters.owner') && 'filters__list__item--active'"
                  >
                    <div class="d-flex align-center">
                      <v-icon class="filters__list__item__icon" :size="18">
                        mdi-account-circle-outline
                      </v-icon>
                      {{ $t('filters.owner') }}
                    </div>
                    <v-icon :size="17" class="filters__list__item__arrow">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                  <div
                    class="filters__list__item"
                    @click="handleChangeActiveFilterSelectorItem($t('filters.date_created'))"
                    :class="activeFilterSelectorTab === $t('filters.date_created') && 'filters__list__item--active'"
                  >
                    <div class="d-flex align-center">
                      <v-icon class="filters__list__item__icon" :size="18">
                        mdi-calendar-range-outline
                      </v-icon>
                      {{ $t('filters.date_created') }}
                    </div>
                    <v-icon :size="17" class="filters__list__item__arrow">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                  <div
                    class="filters__list__item"
                    @click="handleChangeActiveFilterSelectorItem($t('filters.date_modified'))"
                    :class="activeFilterSelectorTab === $t('filters.date_modified') && 'filters__list__item--active'"
                  >
                    <div class="d-flex align-center">
                      <v-icon class="filters__list__item__icon" :size="18">
                        mdi-calendar-range-outline
                      </v-icon>
                      {{ $t('filters.date_modified') }}
                    </div>
                    <v-icon :size="17" class="filters__list__item__arrow">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                  <div
                    class="filters__list__item"
                    @click="handleChangeActiveFilterSelectorItem($t('filters.language'))"
                    :class="activeFilterSelectorTab === $t('filters.language') && 'filters__list__item--active'"
                  >
                    <div class="d-flex align-center">
                      <svg class="filters__list__item__icon" width="18" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_129:1979)">
                          <path d="M5.73381 8.35329C5.70976 8.23288 4.88119 4.09001 4.85491 3.95873C4.81386 3.75333 4.63348 3.60547 4.42401 3.60547H3.54511C3.33563 3.60547 3.15525 3.75333 3.11421 3.95873C3.08752 4.09224 2.2581 8.23933 2.23531 8.35326C2.1877 8.59127 2.34206 8.82277 2.58004 8.87035C2.81802 8.91796 3.04955 8.76359 3.09713 8.52562L3.37803 7.12109H4.59109L4.87199 8.52564C4.9196 8.76374 5.15122 8.91799 5.38908 8.87038C5.62705 8.82277 5.78142 8.59127 5.73381 8.35329ZM3.55381 6.24219L3.90537 4.48437H4.06375L4.41531 6.24219H3.55381Z" fill="black" fill-opacity="0.54" />
                          <path d="M12.7734 7.12109H11.4551V6.68164C11.4551 6.43895 11.2583 6.24219 11.0156 6.24219C10.7729 6.24219 10.5762 6.43895 10.5762 6.68164V7.12109H9.25781C9.01512 7.12109 8.81836 7.31785 8.81836 7.56055C8.81836 7.80324 9.01512 8 9.25781 8H9.36416C9.61456 8.80894 9.99155 9.42986 10.4064 9.92744C10.0687 10.2363 9.72709 10.4896 9.42275 10.733C9.23323 10.8846 9.2025 11.1612 9.35414 11.3507C9.50584 11.5403 9.7824 11.5708 9.97181 11.4193C10.2778 11.1745 10.6447 10.9024 11.0156 10.562C11.3868 10.9026 11.7543 11.1752 12.0594 11.4193C12.249 11.5709 12.5255 11.5402 12.6771 11.3507C12.8287 11.1612 12.798 10.8846 12.6085 10.733C12.305 10.4901 11.9629 10.2366 11.6249 9.92744C12.0397 9.42986 12.4167 8.80894 12.6671 8H12.7734C13.0161 8 13.2129 7.80324 13.2129 7.56055C13.2129 7.31785 13.0161 7.12109 12.7734 7.12109ZM11.0156 9.28159C10.7351 8.92944 10.4827 8.50912 10.2925 7.99707H11.7387C11.5485 8.50912 11.2961 8.92944 11.0156 9.28159Z" fill="black" fill-opacity="0.54" />
                          <path d="M13.6816 3.16602H7.34177L7.15339 1.65482C7.07109 0.996465 6.50871 0.5 5.84522 0.5H1.31836C0.591416 0.5 0 1.09142 0 1.81836V11.5156C0 12.2426 0.591416 12.834 1.31836 12.834H5.02392L5.20989 14.3452C5.29204 15.0022 5.85442 15.5 6.51809 15.5H13.6816C14.4086 15.5 15 14.9086 15 14.1816V4.48438C15 3.75743 14.4086 3.16602 13.6816 3.16602ZM1.31836 11.9551C1.07604 11.9551 0.878906 11.7579 0.878906 11.5156V1.81836C0.878906 1.57604 1.07604 1.37891 1.31836 1.37891H5.84522C6.06639 1.37891 6.25386 1.54437 6.28125 1.76369C6.34494 2.27451 7.48852 11.4484 7.55168 11.9551H1.31836ZM6.05637 14.0278L5.90944 12.834H7.08847L6.05637 14.0278ZM14.1211 14.1816C14.1211 14.424 13.924 14.6211 13.6816 14.6211H6.70529L8.37826 12.6859C8.4639 12.5894 8.50228 12.4611 8.48458 12.3337L7.45134 4.04492H13.6816C13.924 4.04492 14.1211 4.24206 14.1211 4.48438V14.1816Z" fill="black" fill-opacity="0.54" />
                        </g>
                        <defs>
                          <clipPath id="clip0_129:1979">
                            <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ $t('filters.language') }}
                    </div>
                    <v-icon :size="17" class="filters__list__item__arrow">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                  <div
                    class="filters__list__item"
                    @click="handleChangeActiveFilterSelectorItem($t('filters.source'))"
                    :class="activeFilterSelectorTab === $t('filters.source') && 'filters__list__item--active'"
                  >
                    <div class="d-flex align-center">
                      <svg class="filters__list__item__icon" width="18" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7498 8.625C13.584 8.625 13.425 8.69085 13.3078 8.80806C13.1906 8.92527 13.1248 9.08424 13.1248 9.25V11.8831C13.1243 12.3449 12.9406 12.7877 12.614 13.1143C12.2875 13.4408 11.8447 13.6245 11.3829 13.625H3.61663C3.15481 13.6245 2.71204 13.4408 2.38549 13.1143C2.05893 12.7877 1.87525 12.3449 1.87476 11.8831V9.25C1.87476 9.08424 1.80891 8.92527 1.6917 8.80806C1.57449 8.69085 1.41552 8.625 1.24976 8.625C1.084 8.625 0.925024 8.69085 0.807814 8.80806C0.690604 8.92527 0.624756 9.08424 0.624756 9.25V11.8831C0.625583 12.6764 0.941063 13.4369 1.50197 13.9978C2.06288 14.5587 2.82339 14.8742 3.61663 14.875H11.3829C12.1761 14.8742 12.9366 14.5587 13.4975 13.9978C14.0584 13.4369 14.3739 12.6764 14.3748 11.8831V9.25C14.3748 9.08424 14.3089 8.92527 14.1917 8.80806C14.0745 8.69085 13.9155 8.625 13.7498 8.625Z" fill="black" fill-opacity="0.54" />
                        <path d="M4.19201 5.94199L6.87513 3.25886V11.1251C6.87513 11.2909 6.94098 11.4498 7.05819 11.5671C7.1754 11.6843 7.33437 11.7501 7.50013 11.7501C7.66589 11.7501 7.82486 11.6843 7.94207 11.5671C8.05928 11.4498 8.12513 11.2909 8.12513 11.1251V3.25886L10.8083 5.94199C10.9261 6.05583 11.084 6.11883 11.2479 6.11741C11.4118 6.11598 11.5685 6.05025 11.6844 5.93437C11.8003 5.81849 11.866 5.66173 11.8674 5.49786C11.8689 5.33399 11.8059 5.17611 11.692 5.05824L7.94201 1.30824C7.8248 1.19107 7.66586 1.12524 7.50013 1.12524C7.3344 1.12524 7.17546 1.19107 7.05826 1.30824L3.30826 5.05824C3.19441 5.17611 3.13141 5.33399 3.13284 5.49786C3.13426 5.66173 3.19999 5.81849 3.31587 5.93437C3.43175 6.05025 3.58851 6.11598 3.75238 6.11741C3.91625 6.11883 4.07413 6.05583 4.19201 5.94199Z" fill="black" fill-opacity="0.54" />
                      </svg>
                      {{ $t('filters.source') }}
                    </div>
                    <v-icon :size="17" class="filters__list__item__arrow">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                </div>
                <div
                  v-if="activeFilterSelectorTab === $t('filters.labels')"
                  class="filters__list filters__list--values"
                >
                  <v-text-field
                    v-model="tagFilterSearch"
                    single-line
                    class="search-bar__search search-bar__search--borderless w-100"
                    dense
                    :elevation="0"
                    :label="$t('filters.tag_search_placeholder')"
                    hide-details
                  />
                  <div class="filters__list__separator" />
                  <div
                    v-if="tagsFiltered.length"
                    class="filters__list__padding pt-3"
                  >
                    <div
                      class="filters__list__check d-flex align-center"
                      v-for="(tag, idx) in tagsFiltered"
                      :key="idx"
                    >
                      <v-checkbox
                        primary
                        hide-details
                        :ripple="false"
                        :color="tag.color"
                        off-icon="mdi-checkbox-blank"
                        @change="handleFilterTagChange(tag)"
                        :input-value="tagIncludedInFilters(tag)"
                        flat
                      >
                        <template v-slot:label>
                          <div
                            class="filters__list__check__indicator"
                            :style="`background-color: ${tag.color};`"
                          />
                          {{ tag.name }}
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                  <div class="filters__list__padding pt-3 text-center" v-else>
                    <div class="text-sm text-grey">
                      {{ $t('filters.tags_empty') }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="activeFilterSelectorTab === $t('filters.owner')"
                  class="filters__list filters__list--values"
                >
                  <v-text-field
                    v-model="ownerFilterSearch"
                    single-line
                    class="search-bar__search search-bar__search--borderless w-100"
                    dense
                    :elevation="0"
                    :label="$t('filters.owner_search_placeholder')"
                    hide-details
                  />
                  <div class="filters__list__separator" />
                  <div class="filters__list__padding pt-0">
                    <div
                      class="filters__list__check filters__list__check--auto d-flex align-center"
                      v-for="(owner, idx) in owners"
                      :key="idx"
                    >
                      <v-checkbox
                        primary
                        hide-details
                        :ripple="false"
                        color="green"
                        off-icon="mdi-checkbox-blank"
                        :label="`${owner.full_name}`"
                        @change="handleFilterChange('owner', owner.email)"
                        :input-value="valueIncludedInFilters('owner', owner.email)"
                        class="filters__list__check__owner"
                        flat
                      >
                        <template v-slot:label>
                          <div>
                            <div class="filters__list__check__owner__name">
                              {{ owner.full_name }}
                            </div>
                            <div class="filters__list__check__owner__email">
                              {{ owner.email }}
                            </div>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                </div>

                <div
                  v-if="activeFilterSelectorTab === $t('filters.language')"
                  class="filters__list filters__list--values"
                >
                  <v-text-field
                    v-model="languageFilterSearch"
                    single-line
                    class="search-bar__search search-bar__search--borderless w-100"
                    dense
                    :elevation="0"
                    :label="$t('filters.language_search_placeholder')"
                    hide-details
                  />
                  <div class="filters__list__separator" />
                  <div class="filters__list__padding pt-3">
                    <div
                      class="filters__list__check d-flex align-center"
                      v-for="(language, idx) in languages"
                      :key="idx"
                    >
                      <v-checkbox
                        primary
                        hide-details
                        :ripple="false"
                        color="green"
                        off-icon="mdi-checkbox-blank"
                        :label="languageOptions.iso[language]"
                        @change="handleFilterChange('language', language)"
                        :input-value="valueIncludedInFilters('language', language)"
                        flat
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="activeFilterSelectorTab === $t('filters.source')"
                  class="filters__list filters__list--values"
                >
                  <v-text-field
                    v-model="sourceFilterSearch"
                    single-line
                    class="search-bar__search search-bar__search--borderless w-100"
                    dense
                    :elevation="0"
                    :label="$t('filters.source_search_placeholder')"
                    hide-details
                  />
                  <div class="filters__list__separator" />
                  <div class="filters__list__padding pt-3">
                    <div
                      class="filters__list__check d-flex align-center"
                      v-for="(source, idx) in sources"
                      :key="idx"
                    >
                      <v-checkbox
                        primary
                        hide-details
                        :ripple="false"
                        color="green"
                        off-icon="mdi-checkbox-blank"
                        :label="$t(`integrations.${source}`)"
                        @change="handleFilterChange('data_source_provider', source)"
                        :input-value="valueIncludedInFilters('data_source_provider', source)"
                        flat
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="activeFilterSelectorTab === $t('filters.date_created') || activeFilterSelectorTab === $t('filters.date_modified')"
                  class="filters__list filters__list--values"
                >
                  <v-select
                    v-if="activeFilterSelectorTab === $t('filters.date_created')"
                    v-model="filters.created"
                    :items="commonDateFilters"
                    single-line
                    class="search-bar__search search-bar__search--borderless w-100"
                    dense
                    :elevation="0"
                    :label="$t('filters.common_dates_placeholder')"
                    hide-details
                  />
                  <v-select
                    v-else
                    v-model="filters.last_modified"
                    :items="commonDateFilters"
                    single-line
                    class="search-bar__search search-bar__search--borderless w-100"
                    dense
                    :elevation="0"
                    :label="$t('filters.common_dates_placeholder_modified')"
                    hide-details
                  />
                  <div class="filters__list__separator" />
                  <div
                    class="filters__list__padding pt-3 pb-0"
                  >
                    <date-range-filters
                      v-if="activeFilterSelectorTab === $t('filters.date_created')"
                      v-model="filters.created"
                      :show-picker="true"
                    />
                    <date-range-filters
                      v-else
                      v-model="filters.last_modified"
                      show-picker
                    />
                  </div>
                </div>
              </div>
            </v-menu>

            <div
              v-if="!(selectedFiltersFromFilterSelector.length)"
              class="filters__empty"
            >
              {{ $t('filters.empty') }}
            </div>

            <div class="d-flex flex-wrap" v-else>
              <div
                v-for="(filter, idx) in selectedFiltersFromFilterSelector"
                :key="idx"
                @click="handleChangeActiveFilterSelectorItem(filter.type)"
              >
                <div
                  class="filters__filter"
                >
                  <div class="d-flex align-center justify-center h-100">
                    <v-icon
                      class="filters__filter__close hover"
                      @click.stop="handleFilterRemove(filter.type)"
                    >
                      mdi-close-circle
                    </v-icon>
                    <div class="filters__filter__title">
                      {{ filter.type }}:
                    </div>
                    <div
                      v-if="(filter.type === $t('filters.date_modified'))"
                      class="filters__filter__value"
                    >
                      {{ filter.values[0] | dateverbal }} ~ {{ filter.values[1] | dateverbal }}
                    </div>
                    <div
                      v-else-if="(filter.type === $t('filters.date_created'))"
                      class="filters__filter__value"
                    >
                      {{ filter.values[0] | dateverbal }} ~ {{ filter.values[1] | dateverbal }}
                    </div>
                    <div
                      class="filters__filter__value"
                      v-else-if="(filter.type === $t('filters.source'))"
                    >
                      {{ filter.values.length > 1 ? `(${filter.values.length})` : $t(`integrations.${filter.values[0]}`) }}
                    </div>
                    <div
                      v-else
                      class="filters__filter__value"
                    >
                      {{ filter.values.length > 1 ? `(${filter.values.length})` : filter.values[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="filters__pagination d-flex">
            <div class="filters__pagination__text pt-1">
              {{ paginationPageCounter }} {{ $t('pagination.of') }} {{ config.count }}
            </div>
            <v-btn
              :disabled="!config.hasPrev || loading"
              @click="handlePaginationChange(PREV)"
              icon
              small
            >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="!config.hasNext || loading"
              @click="handlePaginationChange(NEXT)"
              icon
              small
            >
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </div>

        <div v-else class="actions d-flex align-center justify-space-between" key="edit">
          <div>
            <slot name="header-multiple-selected-actions" :projects-selected="projectsSelected" />
          </div>
          <span class="actions__count" key="selected">
            {{ $t('n_items_selected', { item: '', cnt: projectsSelected.length }) }}
          </span>
        </div>
      </transition>

      <div class="list">
        <div class="list__row list__row--white list__row--header">
          <div
            class="list__row__cell list__row__cell--select"
            :class="!selectable && 'list__row__cell--select--short'"
          >
            <v-checkbox
              v-if="selectable"
              primary
              hide-details
              color="green"
              :ripple="false"
              off-icon="mdi-checkbox-blank"
              @change="handleSelectAllProjects"
              :input-value="projectSelected"
              :indeterminate="projectSelected && !allProjectsSelected"
              flat
            />
          </div>
          <div
            class="list__row__cell list__row__cell--sortable list__row__cell--name flex-row align-center justify-start"
            :class="isSortedByColumn('name') && 'list__row__cell--sorted'"
            @click="handleSortClick('name')"
          >
            {{ $t('table.name') }}
            <v-icon :size="14" class="ml-1">
              {{ (!filters.order_by || isSortedByAscColumn('name')) ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant' }}
            </v-icon>
          </div>
          <div
            v-if="!('last_modified' in hideColumns)"
            class="list__row__cell list__row__cell--date list__row__cell--sortable flex-row align-center justify-end"
            :class="isSortedByColumn('last_modified') && 'list__row__cell--sorted'"
            @click="handleSortClick('last_modified')"
          >
            <v-icon :size="14" class="mr-1" style="transform: scaleX(-1);">
              {{ (!filters.order_by || isSortedByAscColumn('last_modified')) ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant' }}
            </v-icon>
            {{ $t('table.date_modified') }}
          </div>
          <div
            v-if="!('created' in hideColumns)"
            class="list__row__cell list__row__cell--date list__row__cell--sortable list__row__cell--date-created flex-row align-center justify-end"
            :class="isSortedByColumn('created') && 'list__row__cell--sorted'"
            @click="handleSortClick('created')"
          >
            <v-icon :size="14" class="mr-1" style="transform: scaleX(-1);">
              {{ (!filters.order_by || isSortedByAscColumn('created')) ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant' }}
            </v-icon>
            {{ $t('table.date_created') }}
          </div>
          <div
            v-if="!('language' in hideColumns)"
            class="list__row__cell list__row__cell--language"
          >
            {{ $t('table.language') }}
          </div>
          <div
            v-if="!('source' in hideColumns)"
            class="list__row__cell list__row__cell--source"
          >
            {{ $t('table.source') }}
          </div>
          <div
            v-if="!('nanswers' in hideColumns)"
            class="list__row__cell list__row__cell--responses"
          >
            {{ $t('table.responses') }}
          </div>
          <div
            v-if="!('labels' in hideColumns)"
            class="list__row__cell list__row__cell--tags"
          >
            {{ $t('table.labels') }}
          </div>
        </div>

        <div v-if="loading">
          <div
            v-for="index in currentMinPageCount"
            :key="index"
            class="list__row"
          >
            <div
              class="list__row__cell list__row__cell--select"
              :class="!selectable && 'list__row__cell--select--short'"
            >
              <div
                v-if="selectable"
                class="list__row__cell__loading"
              />
            </div>
            <div class="list__row__cell list__row__cell--name">
              <div class="list__row__cell__title">
                <div class="list__row__cell__loading" />
              </div>
              <div class="list__row__cell__subtitle">
                <div class="list__row__cell__loading" />
              </div>
            </div>
            <div
              v-if="!('last_modified' in hideColumns)"
              class="list__row__cell list__row__cell--date"
            >
              <div class="list__row__cell__title">
                <div class="list__row__cell__loading" />
              </div>
              <div class="list__row__cell__subtitle">
                <div class="list__row__cell__loading" />
              </div>
            </div>
            <div
              v-if="!('created' in hideColumns)"
              class="list__row__cell list__row__cell--date list__row__cell--date-created"
            >
              <div class="list__row__cell__title">
                <div class="list__row__cell__loading" />
              </div>
              <div class="list__row__cell__subtitle">
                <div class="list__row__cell__loading" />
              </div>
            </div>
            <div
              v-if="!('language' in hideColumns)"
              class="list__row__cell list__row__cell--language"
            >
              <div class="list__row__cell__title">
                <div class="list__row__cell__loading" />
              </div>
              <div class="list__row__cell__subtitle">
                <div class="list__row__cell__loading" />
              </div>
            </div>
            <div
              v-if="!('source' in hideColumns)"
              class="list__row__cell list__row__cell--source"
            >
              <div class="list__row__cell__title">
                <div class="list__row__cell__loading" />
              </div>
              <div class="list__row__cell__subtitle">
                <div class="list__row__cell__loading" />
              </div>
            </div>
            <div
              v-if="!('nanswers' in hideColumns)"
              class="list__row__cell list__row__cell--responses"
            >
              <div class="list__row__cell__title">
                <div class="list__row__cell__loading" />
              </div>
              <div class="list__row__cell__subtitle">
                <div class="list__row__cell__loading" />
              </div>
            </div>
            <div
              v-if="!('labels' in hideColumns)"
              class="list__row__cell list__row__cell--tags"
            >
              <div class="list__row__cell__loading" />
            </div>
          </div>
        </div>

        <error-state
          v-else-if="failed"
          title="Data fetching error"
          :text="$t('error', {'step': $t('loading.while')})"
          :action="$t('actions.retry')"
          :action-click="() => $emit('refetch')"
        />

        <empty-state-v2
          v-else-if="!projects.length"
          :title="$t('no_projects.title')"
          :text="$t('no_projects.details')"
          :action="$t('clear_search')"
          :action-click="resetFilters"
        />

        <router-link
          v-else
          v-for="(project, index) in projects"
          :key="index"
          class="list__row"
          :class="{
            'list__row--active': (questions.active && questions.project === project.id) || tagSelectorShownForProject(project)}
          "
          :to="{ name: 'projects-manage-id', params: { id: project.id } }"
          event="''"
          @click.native.prevent.stop="showQuestions(project.id)"
        >
          <div
            class="list__row__cell list__row__cell--select"
            :class="!selectable && 'list__row__cell--select--short'"
          >
            <v-checkbox
              v-if="selectable"
              primary
              hide-details
              color="green"
              off-icon="mdi-checkbox-blank"
              :ripple="false"
              :input-value="isProjectSelected(project)"
              @change="handleProjectSelectChange(project)"
              @click.native.prevent.stop
            />
          </div>
          <div class="list__row__cell list__row__cell--name">
            <div class="d-flex align-center">
              <div>
                <v-tooltip
                  bottom
                  :disabled="truncatedName(project.name, usersOnlineByProject[project.id].nQuestions, project.ui_status === 'new').length === project.name.length"
                >
                  <template v-slot:activator="{ on }">
                    <div class="list__row__cell__title" v-on="on">
                      {{ truncatedName(project.name, usersOnlineByProject[project.id].nQuestions, project.ui_status === 'new') }}
                    </div>
                  </template>
                  <span>{{ project.name }}</span>
                </v-tooltip>
                <div
                  v-if="!('owner' in hideColumns || !user.organization)"
                  class="list__row__cell__subtitle"
                >
                  {{ project.owner_name }}
                </div>
                <div
                  class="list__row__cell__subtitle"
                  v-else
                />
              </div>
              <div
                v-if="project.ui_status === 'new'"
                class="list__row__cell__new"
              >
                NEW
              </div>
              <v-tooltip bottom v-if="usersOnlineByProject[project.id].nQuestions">
                <template v-slot:activator="{ on }">
                  <span class="users-active" v-on="on" />
                </template>
                <span>{{ $tc('eprops.users_online.status_project', usersOnlineByProject[project.id].users.length, {
                  users: usersOnlineByProject[project.id].usersFormatted,
                  n_questions: usersOnlineByProject[project.id].nQuestions })
                }}</span>
              </v-tooltip>
            </div>
          </div>
          <div
            v-if="!('last_modified' in hideColumns)"
            class="list__row__cell list__row__cell--date"
          >
            <div class="list__row__cell__title">
              {{ project.last_modified | dateverbal }}
            </div>
            <div class="list__row__cell__subtitle">
              {{ project.last_modified | datehour }}
            </div>
          </div>
          <div
            v-if="!('created' in hideColumns)"
            class="list__row__cell list__row__cell--date list__row__cell--date-created"
          >
            <div class="list__row__cell__title">
              {{ project.created | dateverbal }}
            </div>
            <div class="list__row__cell__subtitle">
              {{ project.created | datehour }}
            </div>
          </div>
          <div
            v-if="!('language' in hideColumns)"
            class="list__row__cell list__row__cell--language"
          >
            <div class="list__row__cell__title">
              {{ languageOptions.iso[project.language] }}
            </div>
            <div class="list__row__cell__subtitle">
              {{ project.translation_engine ? $t(`translations.${project.translation_engine}`) : '-' }}
            </div>
          </div>
          <div
            v-if="!('source' in hideColumns)"
            class="list__row__cell list__row__cell--source"
          >
            <div class="d-flex align-center">
              <div>
                <div class="list__row__cell__title">
                  {{ project.data_source_provider !== 'upload' ? $t('integration') : $t('user_uploaded') }}
                </div>
                <div class="list__row__cell__subtitle">
                  {{ project.data_source_provider !== 'upload' ? $t(`integrations.${project.data_source_provider}`) : '-' }}
                </div>
              </div>
              <v-tooltip
                v-if="project.data_source && project.data_source.sync_enabled"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <svg v-on="on" xmlns="http://www.w3.org/2000/svg" class="ml-2 list__row__cell--source__icon" width="16px" height="16px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" stroke="#00c58d" />
                  </svg>
                </template>
                <span>{{ $t('sync_on') }}</span>
              </v-tooltip>
            </div>
          </div>
          <div
            v-if="!('nanswers' in hideColumns)"
            class="list__row__cell list__row__cell--responses"
          >
            <div class="list__row__cell__title">
              {{ project.questions_count }} {{ $tc('question.count', project.questions_count) }}
            </div>
            <div class="list__row__cell__subtitle">
              {{ project.rows_count }} {{ $tc('question.answer_count', project.rows_count) }}
            </div>
          </div>
          <div v-if="!('labels' in hideColumns)" class="h-100">
            <div
              class="list__row__cell list__row__cell--tags h-100"
            >
              <swiper
                class="swiper component"
                :options="swiperOption"
                :auto-update="false"
              >
                <swiper-slide
                  v-for="(label, idx) in project.labels"
                  :key="idx"
                >
                  <div
                    class="list__row__cell__tag"
                    @click.prevent.stop=""
                    @dblclick.prevent.stop="setActiveTab(getLabelIdx(label))"
                  >
                    <div
                      class="list__row__cell__tag__indicator"
                      :style="`background-color: ${$color.getStrong(label2idx[label])}`"
                    />
                    {{ label }}
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <v-btn
                    v-if="projectsSelected.length < 2 && !filtersInternal"
                    text
                    color="green"
                    class="list__row__cell--tags__add d-flex align-center"
                    :class="!project.labels.length || (projectsSelected.length === 1 && projectsSelected[0] && projectsSelected[0].id === project.id) && 'list__row__cell--tags__add--visible'"
                    @click.native.prevent.stop="e => handleTagAddClick(e, project)"
                    :disabled="projectsSelected.length > 1 || (projectsSelected[0] && projectsSelected[0].id !== project.id)"
                    small
                  >
                    <v-icon :size="16">
                      mdi-plus
                    </v-icon>
                    Add tag
                  </v-btn>
                </swiper-slide>
                <div
                  class="swiper-button-prev prev-button"
                  slot="button-prev"
                  @click.prevent.stop
                >
                  <v-icon
                    :size="25"
                  >
                    mdi-chevron-left
                  </v-icon>
                </div>
                <div
                  class="swiper-button-next next-button"
                  slot="button-next"
                  @click.prevent.stop
                >
                  <v-icon
                    :size="25"
                  >
                    mdi-chevron-right
                  </v-icon>
                </div>
              </swiper>
            </div>
          </div>
        </router-link>
      </div>
      <div
        v-if="showProjectsPerPage"
        class="filters filters--bottom d-flex align-center justify-space-between"
      >
        <div />
        <div class="filters__pagination d-flex align-center">
          <div class="filters__pagination__select d-flex align-center">
            <div class="filters__pagination__text">
              {{ $t('pagination.description') }}:
            </div>
            <v-menu
              :close-on-content-click="true"
              :rounded="'lg'"
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="filters__pagination__select__button"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  solo
                  small
                  dense
                >
                  {{ filters.pagination.rowsPerPage }}
                  <v-icon :size="20">
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list class="filters__list filters__list--page-size">
                <div
                  v-for="item in PAGE_SIZE_OPTIONS"
                  :key="item"
                  class="filters__list__item"
                  @click="handlePageSizeChange(item)"
                >
                  {{ item }}
                </div>
              </v-list>
            </v-menu>
          </div>
          <div class="filters__pagination d-flex align-center">
            <div class="filters__pagination__text">
              {{ paginationPageCounter }} {{ $t('pagination.of') }} {{ config.count }}
            </div>
            <v-btn
              :disabled="!config.hasPrev || loading"
              @click="handlePaginationChange(PREV)"
              icon
              small
            >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              :disabled="!config.hasNext || loading"
              @click="handlePaginationChange(NEXT)"
              icon
              small
            >
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="questions.active" v-if="questions.active" @click.native="hideQuestions" />
    <v-navigation-drawer
      v-model="questions.active"
      right
      :absolute="inDialog"
      :fixed="!inDialog"
      temporary
      stateless
      hide-overlay
      class="questions-container"
      width="440"
      ref="questionsContainer"
    >
      <template v-if="loadingActiveProject">
        <div class="pa-6">
          <v-skeleton-loader
            loading
            type="sentences, actions"
          />
        </div>
      </template>
      <template v-else-if="activeProject.data_source && activeProject.rows_count === 0">
        <v-alert
          border="left"
          type="info"
          text
        >
          <span v-html="$t('integration_not_ready')" />
        </v-alert>
      </template>
      <template v-else-if="activeProject.questions.length">
        <v-theme-provider dark v-if="$scopedSlots['header-actions']">
          <div class="project-actions-in-sidebar">
            <div class="header" v-text="$t('actions.project_actions')" />
            <div class="pac-container">
              <slot name="header-actions" :projects-selected="[activeProject]" />
            </div>
          </div>
        </v-theme-provider>
        <template>
          <v-alert
            border="left"
            close-text="Close Alert"
            type="info"
            dark
            dense
            text
            :icon="false"
          >
            <span v-html="$tc('drawer_title', activeProject.questions.length, {
              n: activeProject.questions.length, project: $escapeHtml(activeProject.name.replace('|', '//-//'))
            }).replace('//-//', '|')"
            />
          </v-alert>
          <v-alert
            v-if="activeProject.data_source && activeProject.data_source.last_synced"
            border="left"
            class="position-relative"
            :type="syncAlertType"
            dense
            text
            :icon="false"
          >
            <span v-html="$t('sync_status', {
              lastSyncedDate: $moment(lastSyncedDate).fromNow(),
              syncStatus: $t(`sync_status_${activeProject.data_source.sync_status}`),
              responses: activeProject.data_source.last_synced_responses
            })"
            />
            <helptip
              class="sync-helptip"
              position="bottom"
            >
              <div>
                {{ $t('sync_helptip_text') }}
              </div>
              <a href="https://caplena.com/docs/knowledge-base/2784zjo1mosah-integrations-in-detail" target="_blank">
                {{ $t('actions.more_information') }}
              </a>
            </helptip>
          </v-alert>
          <v-alert
            v-if="activeProject.data_source && !activeProject.data_source.sync_enabled"
            border="left"
            type="warning"
            dense
            text
            :icon="false"
          >
            <span v-html="$t('sync_off')" />
          </v-alert>

          <v-card class="question"
                  hover
                  v-for="question in activeProject.questions"
                  :key="question.id"
          >
            <v-card-title>
              <div>
                <div class="title" style="">
                  {{ question.name }}
                </div>
                <!-- <div class="subtitle-1" style="margin: -2px 0"><em></em></div> -->
              </div>
            </v-card-title>

            <v-card-text class="actions">
              <slot name="action" :question="question" />
            </v-card-text>
            <v-divider light />

            <v-card-text>
              <div v-if="question.question_category !== QUESTION_CATEGORY_NONE">
                {{ $t('question.category.title') }}:
                {{ $t(`question.category.${question.question_category}`) }}
              </div>
              <div v-html="$t('question.info_str', {
                nonempty_count: question.metadata.nonempty_count,
                reviewed_nonempty_count: question.reviewed_nonempty_count,
                last_modified: $options.filters.fromnow(question.last_modified),
                ncodes: question.ncodes
              })"
              />
            </v-card-text>
            <v-divider light />
            <v-card-text>
              <span>{{ $t('question.inherits.from') }}
                <a
                  v-if="question.inherits_from"
                  @click.stop="showQuestions(question.inherits_from_obj.project_obj.id)"
                >
                  {{ $t('question.of_project', {
                    question: question.inherits_from_obj.name,
                    project: question.inherits_from_obj.project_obj.name
                  }) }}
                </a>
                <span v-else>{{ $t('question.inherits.none') }}</span><br>
                <span v-if="question.inherits_to_objs">{{ $t('question.inherits.to', { n: question.inherits_to_objs.length }) }}</span>
              </span>
            </v-card-text>
            <v-divider light />
            <v-card-actions @click.stop>
              <v-tooltip bottom v-if="usersOnline.data[question.project] && usersOnline.data[question.project][question.id] && usersOnline.data[question.project][question.id].length">
                <template v-slot:activator="{ on }">
                  <span class="users-active ml-2" v-on="on" />
                </template>
                <span>
                  {{
                    $tc('eprops.users_online.status_question', usersOnline.data[question.project][question.id].length, {
                      users: $options.filters.enumerate($_.map(usersOnline.data[question.project][question.id], 'name'), $t('and'))
                    })
                  }}
                </span>
              </v-tooltip>
              <template v-if="question.ui_status === 'completed'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-archive
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.status.completed') }}</span>
                </v-tooltip>
              </template>
              <template v-else-if="question.ui_status === 'in_progress'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-timelapse
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.status.inprogress') }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-new-box
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.status.new') }}</span>
                </v-tooltip>
              </template>
              <template v-if="question.inherits_final_leaf">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-format-vertical-align-top
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.inherits_question.final_leaf') }}</span>
                </v-tooltip>
              </template>
              <template v-else-if="question.inherits_from">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-swap-vertical
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.inherits_question.to_and_from') }}</span>
                </v-tooltip>
              </template>
              <template v-else-if="question.inherits_to_objs.length">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-call-split
                    </v-icon>
                  </template>
                  <span>{{ $t('eprops.inherits_question.to') }}</span>
                </v-tooltip>
              </template>
              <v-spacer />
              <!-- <v-btn @click="showFlowtree(question)" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-swap-vertical-variant
                    </v-icon>
                  </template>
                  <span>{{ $t('question.inherits.show_relationships') }}</span>
                </v-tooltip>
              </v-btn> -->
              <slot name="question-actions" :question="question" />
            </v-card-actions>
          </v-card>
        </template>
      </template>
      <div v-else style="text-align: center">
        <div style="margin: 20px 0">
          <v-icon size="60">
            mdi-forum
          </v-icon>
        </div>
        <div class="headline" style="margin: 0 0 20px 0">
          {{ $t('question.empty_headline') }}
        </div>
      </div>
    </v-navigation-drawer>

    <!-- ====== Flowtree DIALOG ====== -->
    <v-dialog v-model="flowtree.active" @keydown.esc="flowtree.active = false" style="background: #FFF">
      <v-card v-if="flowtree.cog">
        <v-card-title class="headline" v-html="$t('flowtree.title', { question: $escapeHtml(flowtree.cog.name) })" />

        <alert v-if="!flowtree.cog.inherits_from&&!flowtree.cog.inherits_to_objs.length"
               type="info"
        >
          {{ $t('flowtree.not_related') }}
        </alert>

        <v-card-text id="flowtree">
          <div :style="{ width: `${flowtree.maxX - flowtree.minX}px`, height: `${flowtree.maxY}px` }"
               class="flowtree-container"
          >
            <svg xmlns="http://www.w3.org/2000/svg"
                 :viewBox="`${flowtree.minX} ${-flowtree.maxY} ${flowtree.maxX - flowtree.minX} ${flowtree.maxY}`"
                 class="svg-arrows"
            >
              <defs>
                <marker id="end" orient="auto" markerWidth="3" markerHeight="3"
                        refX="2" refY="1.5"
                >
                  <path d="M0,0 V3 L3,1.5 Z" :fill="$vuetify.theme.themes.light.primary" />
                </marker>
                <marker id="start" orient="auto" markerWidth="4" markerHeight="4" refX="1" refY="1">
                  <circle cx="1" cy="1" r="1" fill="#999" />
                </marker>
              </defs>
              <g transform="scale(1,-1)">
                <path v-for="(arr, arrIdx) in flowtree.arrows"
                      marker-end="url(#end)"
                      marker-start="url(#start)"
                      stroke-width="5" fill="none"
                      stroke="#DDD"
                      :key="arrIdx"
                      :d="`M${arr.xStart},${arr.yStart} L${arr.xEnd},${arr.yEnd}`"
                />
              </g>
            </svg>

            <div v-for="(node, idx) in flowtree.nodes"
                 :key="idx"
                 :style="{ bottom: `${node.y}px`,
                           left: `${node.x - flowtree.minX}px` }"
                 class="node"
            >
              <div :class="{ cog: node.cog }">
                <span>{{ node.question.name }}</span>
                <a @click="showQuestions(node.question.project); flowtree.active=false">
                  {{ $t('project.item') }} {{ node.question.project_obj.name }}
                </a>
                {{ $t('flowtree.created') }} {{ node.question.created | dateshort }}
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click.native="flowtree.active=false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vuex from 'vuex'

import DateRangeField from '@/components/DateRangeField.vue'
import ProjectListMixin from '@/mixins/ProjectList'
// import filterDemoData from '@/mixins/filterDemoData'
import hideBodyScrollForDialogs from '@/mixins/hideBodyScrollForDialogs'
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/swiper.esm.js'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.min.css'

import {
  QUESTION_CATEGORY_NONE,
  PROJECT_NAME_DEMO_OPEN,
  PROJECT_NAME_DEMO_LIST,
  PROJECT_LIST_FILTERS_DEFAULT as FILTERS_DEFAULT
} from '@/settings/constants'

SwiperClass.use([Pagination, Navigation])

const MAX_SOCKET_FAILED_CNT = 15
let OLD_FILTER_VAL = null
const PREV = -1
const NEXT = 1
const PAGE_SIZE_OPTIONS = [5, 6, 7, 8, 9, 10, 15, 25, 50]

export default {
  codit: true,
  name: 'ProjectList',

  components: {
    'date-range-filters': DateRangeField,
    Swiper,
    SwiperSlide
  },

  mixins: [hideBodyScrollForDialogs, ProjectListMixin],

  props: {
    title: { type: String, default: '' },
    externalProjects: { type: Array, default: () => [] },
    externalConfig: { type: Object, default: () => {} },
    externalLoading: { type: Boolean, default: false },
    externalFailed: { type: Boolean, default: false },
    initialState: { type: Object, default: () => ({}) },
    addEnable: { type: Boolean, default: false },
    dontHideProjectDetailsOnClick: { type: Boolean, default: false },
    tagSelectorOpen: { type: Boolean, default: false },
    showTabs: { type: Boolean, default: false },
    archivedFilterEnable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    hideColumns: { type: Object, default: () => Object() },
    projectToFocus: { type: [String, null], default: null },
    observeUsersOnline: { type: Boolean, default: false },
    inDialog: { type: Boolean, default: false },
    showProjectsPerPage: { type: Boolean, default: false },
    filtersInternal: { type: Boolean, default: false },
    inheritable: { type: [Boolean, String, Object], default: false }
  },

  data () {
    return {
      filters: _.cloneDeep(FILTERS_DEFAULT),

      projectsSelected: [],

      questions: {
        active: false,
        project: ''
      },

      showQuestionMutex: false,
      isDestroying: false,

      usersOnline: {
        socketv1: null,
        socket: null,
        data: {},
        failedCnt: 0
      },

      flowtree: {
        active: false,
        // Center of gravity, the question that is referred
        cog: null,
        minX: 0,
        maxX: 0,
        maxY: 0,
        arrows: [
          { xStart: 10, xEnd: 20, yStart: 10, yEnd: 50 }
        ],

        // constants
        scaler: 200,
        ySpacer: 60,
        nodeWidth: 300
      },

      loadingActiveProject: false,
      activeProject: { questions: [], name: '' },

      filterSelectorShown: false,
      activeFilterSelectorTab: this.$t('filters.labels'),
      debounceHandleSearchChange: _.debounce(this.handleSearchChange, 300),
      tagFilterSearch: '',
      ownerFilterSearch: '',
      languageFilterSearch: '',
      sourceFilterSearch: '',

      windowWidth: window.innerWidth,

      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        mousewheel: true,
        freeMode: true,
        shortSwipes: false,
        simulateTouch: false,
        navigation: {
          nextEl: '.next-button',
          prevEl: '.prev-button'
        }
      },

      QUESTION_CATEGORY_NONE,
      NEXT,
      PREV,
      PAGE_SIZE_OPTIONS
    }
  },

  computed: {
    /**
     * If the list is rendered inside a modal
     */
    insideModal () {
      return !!this.filtersInternal
    },

    /**
     * If the project or config fetching has failed to use based on if the project list is rendered inside a modal, or in projects manage page
    */
    failed () {
      return this.filtersInternal ? this.projectsFailed : this.externalFailed
    },

    /**
     * Projects to use based on if the project list is rendered inside a modal, or in projects manage page
     */
    projects: {
      get () { return this.filtersInternal ? this.internalProjects : this.externalProjects },
      set (value) { this.externalProjects = value }
    },

    /**
     * Config to use based on if the project list is rendered inside a modal, or in projects manage page
     */
    config () {
      return this.filtersInternal ? this.projectsConfig : this.externalConfig
    },

    /**
     * Loading to use based on if the project list is rendered inside a modal, or in the projects manage page
     */
    loading () {
      return this.filtersInternal ? this.projectsLoading : this.externalLoading
    },

    /**
     * Returns common date filter options for v-select
     * @return {Array}
    */
    commonDateFilters () {
      const today = new Date()
      const todayIso = this.$options.filters.shortiso(new Date())
      const last30Days = this.$options.filters.shortiso(new Date().setDate(today.getDate() - 30))
      const thisYear = this.$options.filters.shortiso(new Date(new Date().getFullYear(), 0, 1))
      const pastYear = this.$options.filters.shortiso(new Date(new Date().getFullYear() - 1, 0, 1))
      const thisMonth = this.$options.filters.shortiso(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
      const pastMonth = this.$options.filters.shortiso(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1))
      const firstDayThisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      const lastDayLastMonth = this.$options.filters.shortiso(new Date().setDate(firstDayThisMonth.getDate() - 1))

      return [
        { text: this.$t('filters.date.all_time'), value: [] },
        { text: this.$t('filters.date.last_30_days'), value: [last30Days, todayIso] },
        { text: this.$t('filters.date.this_month'), value: [thisMonth, todayIso] },
        { text: this.$t('filters.date.past_month'), value: [pastMonth, lastDayLastMonth] },
        { text: this.$t('filters.date.this_year'), value: [thisYear, todayIso] },
        { text: this.$t('filters.date.past_year'), value: [pastYear, thisYear] }
      ]
    },

    /**
     * Returns all filter values that are visible in the v-menu filter selector, for displaying filter tabs purposes
     * @return {Array}
     */
    selectedFiltersFromFilterSelector () {
      let selectedFilters = []

      if (this.filters.labels && this.filters.labels.length) {
        selectedFilters.push({
          type: this.$t('filters.labels'),
          values: this.filters.labels
        })
      }

      if (this.filters.owner && this.filters.owner.length) {
        selectedFilters.push({
          type: this.$t('filters.owner'),
          values: this.filters.owner
        })
      }

      if (this.filters.language && this.filters.language.length) {
        selectedFilters.push({
          type: this.$t('filters.language'),
          values: this.filters.language
        })
      }

      if (this.filters.data_source_provider && this.filters.data_source_provider.length) {
        selectedFilters.push({
          type: this.$t('filters.source'),
          values: this.filters.data_source_provider
        })
      }

      if (this.filters.created.length === 2) {
        selectedFilters.push({
          type: this.$t('filters.date_created'),
          values: this.filters.created
        })
      }

      if (this.filters.last_modified.length === 2) {
        selectedFilters.push({
          type: this.$t('filters.date_modified'),
          values: this.filters.last_modified
        })
      }

      return selectedFilters
    },

    currentMinPageCount () {
      const { rowsPerPage } = this.filters.pagination

      return _.min([ this.projects.length, rowsPerPage ])
    },

    /**
     * Rendered string showing range of currently showed results ex: 1-10
     */
    paginationPageCounter () {
      const { rowsPerPage, page } = this.filters.pagination
      const displayedResults = (page - 1) * rowsPerPage

      return `${displayedResults + 1} - ${displayedResults + this.currentMinPageCount}`
    },

    /**
     * Tags from config omiting 'All projects' and 'My projects'
     * @return {Array}
     */
    labels () {
      return this.config.tabs.slice(2).map(tab => tab)
    },

    /**
     * Transform labels by value into idx for color fetching
     * @return {Object}
     */
    label2idx () {
      let lbl2idx = {}
      this.labels.forEach((lbl, idx) => { lbl2idx[lbl.value] = idx })
      return lbl2idx
    },

    /**
     *  Preset tab filters shown on top of the page
     * @return {Array}
     */
    tabs () {
      return _.map(this.config.tabs, (tab, idx) => ({
        ...tab,
        isTag: idx > 1,
        color: idx > 1 ? this.$color.getStrong(this.label2idx[tab.value]) : undefined
      }))
    },

    /**
     * Tags with their respective colors
     * @return {Array}
     */
    tags () {
      return _.map(this.labels, label => ({
        ...label,
        color: this.$color.getStrong(this.label2idx[label.value])
      }))
    },

    /**
     * Tags filtered by the search field in the filter box
     * @return {Array}
     */
    tagsFiltered () {
      if (!this.tagFilterSearch.length) return this.tags
      return _.filter(this.tags, tag => _.includes(_.lowerCase(tag.name), _.lowerCase(this.tagFilterSearch)))
    },

    /**
     * List of possible owner filters
     * @return {Array}
     */
    owners () {
      const owners = this.config.filters.owner.allowed_values
      if (!this.ownerFilterSearch.length) return owners

      return _.filter(owners, owner => _.includes(_.lowerCase(owner.full_name), _.lowerCase(this.ownerFilterSearch)) || _.includes(_.lowerCase(owner.email), _.lowerCase(this.ownerFilterSearch)))
    },

    /**
     * List of possible language filters
     * @return {Array}
     */
    languages () {
      const languages = this.config.filters.language.allowed_values

      if (!this.languageFilterSearch.length) return languages
      return _.filter(languages, owner => _.includes(_.lowerCase(owner), _.lowerCase(this.languageFilterSearch)))
    },

    /**
     * List of possible language filters
     * @return {Array}
     */
    sources () {
      const sources = this.config.filters.data_source_provider.allowed_values

      if (!this.sourceFilterSearch.length) return sources
      return _.filter(sources, owner => _.includes(_.lowerCase(owner), _.lowerCase(this.sourceFilterSearch)))
    },

    /**
     * Todays date in iso format for label
     */
    isoToday () {
      return this.$options.filters.shortiso(new Date())
    },

    /**
     * If all projects on page have been selected
     */
    allProjectsSelected () {
      return _.every(this.projects, (project) => _.find(this.projectsSelected, project))
    },

    /**
     * If at least one of the projects on the current page is selected
     */
    projectSelected () {
      return _.find(this.projects, (project) => _.find(this.projectsSelected, project))
    },

    /**
     * Alert type based on sync_status
     */
    syncAlertType () {
      switch (this.activeProject.data_source.sync_status) {
        case 'complete':
          return 'success'
        case 'failed':
          return 'error'
        default:
          return 'info'
      }
    },

    /**
     * Last synced date of active project
     */
    lastSyncedDate () {
      return this.activeProject.data_source.last_synced
    },

    /**
     * Proxy to variable if flowtree is active. Required for the hideBodyScrollForDialogs mixin
     * @return {Boolean}
     */
    dialogIsOpen () {
      return this.flowtree.active
    },

    /**
     * Returns mapping of question ID to its parent project
     * @return {Object} Mapping of Number -> Number
     */
    questionID2ProjectID () {
      let mapping = {}
      this.projects.forEach(p => {
        p.questions.forEach(q => { mapping[q.id] = p.id })
      })
      return mapping
    },

    /**
     * Returns users currently editing given project
     */
    usersOnlineByProject () {
      let uobp = {}
      _.each(this.usersOnline.data, (questions, pID) => {
        let nQuestions = _.sum(_.toArray(_.mapValues(questions, (qUsers, qID) => Number(!!qUsers.length))))
        let users = _.flatten(_.toArray(_.mapValues(questions, (qUsers, qID) => _.map(qUsers, 'name'))))
        let usersFormatted = this.$options.filters.enumerate(users, this.$t('and'))
        uobp[pID] = { nQuestions, users, usersFormatted }
      })
      return uobp
    },

    ...Vuex.mapState(['user', 'languageOptions'])
  },

  watch: {
    filters: {
      deep: true,
      handler (newVal, oldVal) {
        if (
          OLD_FILTER_VAL &&
          (
            !this.getObjectDiff(newVal, OLD_FILTER_VAL).includes('pagination') ||
            this.getObjectDiff(newVal, OLD_FILTER_VAL).length > 1 ||
            OLD_FILTER_VAL.pagination.rowsPerPage !== newVal.pagination.rowsPerPage
          )
        ) {
          this.filters.pagination.page = 1
          this.projectsSelected = []
        }

        if (!this.showQuestionMutex) {
          if (this.filtersInternal) {
            if (OLD_FILTER_VAL !== null) this.loadProjects().then(() => { this.projectsLoading = false })
          } else {
            this.$emit('filters', newVal)
          }

          OLD_FILTER_VAL = _.cloneDeep(newVal)
        }
      }
    },

    loading (val) {
      if (!val) {
        this.maybeFocusProject()
      }
    },

    questions: {
      deep: true,
      async handler () {
        const getQuestions = (columns, project) => {
          return columns.filter(col => col.type === 'text_to_analyze').map(q => {
            q = {
              ...q,
              ...q.metadata,
              id: q.metadata.question_id,
              nreviewed: q.metadata.reviewed_count || 0,
              ncodes: q.topics.length,
              nanswers: project.rows_count,
              question_category: q.metadata.category,
              project_obj: project
            }

            if (q.metadata.learns_from) {
              const [projectName, name] = q.metadata.learns_from ? q.metadata.learns_from.label.split(' | ') : []
              q.inherits_from_obj = {
                name: name || 'Unkown',
                project_obj: {
                  id: q.learns_from.project,
                  name: projectName
                }
              }
              q.inherits_from = q.metadata.learns_from.ref
            }

            if (q.metadata.learning_from_this) {
              q.inherits_to_objs = q.metadata.learning_from_this.map(o => ({ ...o, id: o.ref }))
            }

            return q
          })
        }

        // When the question browser becomes inactive, set a delay so the out animation runs smoothly
        if (!this.questions.active) {
          this.activeQuestionTimeout = setTimeout(() => this.$set(this, 'activeProject', { questions: [] }), 750)
        } else {
          clearTimeout(this.activeQuestionTimeout)
          this.loadingActiveProject = true
          try {
            const project = _.find(this.projects, { id: this.questions.project })
            const response = await api.get(`/api/ui/projects/${this.questions.project}${this.queryParamsString}`)
            const questions = getQuestions(response.data.columns, project)

            this.$set(this, 'activeProject', {
              ...project,
              questions
            })
          } catch (e) {
            this.$maybeRaiseAPIPromiseErr(e)
          }
          this.loadingActiveProject = false
        }
      }
    },

    projectToFocus: 'maybeFocusProject',

    projects: {
      immediate: true,
      handler () {
        // Create mapping of project ids to empty object, for observing state of users online
        this.$set(this.usersOnline, 'data', _.mapValues(_.mapKeys(this.projects, (val, key) => val.id), () => ({})))
        this.updateObserver()
      }
    },

    initialState: {
      immediate: false,
      handler: 'setInitialState'
    }
  },

  mounted () {
    if (this.filtersInternal) {
      this.filters.pagination.rowsPerPage = 5
      this.loadAllData()
    }

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },

  created () {
    if (this.observeUsersOnline) this.openObserver()
  },

  beforeDestroy () {
    this.isDestroying = true
    if (this.usersOnline.socketv1) this.usersOnline.socketv1.close()
    if (this.usersOnline.socket) this.usersOnline.socket.close()
  },

  methods: {
    /**
     * If the tag selector has been opened for the given project
     */
    tagSelectorShownForProject (project) {
      if (this.tagSelectorOpen && _.find(this.projectsSelected, project)) return true
      return false
    },

    getLabelIdx (label) {
      return _.findIndex(this.tabs, tab => tab.name === label)
    },

    /**
     * Show tag selector for given project, fired after clicking on "+" in single project tag list
     */
    handleTagAddClick (e, project) {
      e.stopPropagation()
      this.projectsSelected = _.uniq([...this.projectsSelected, project])
      this.$emit('show-tag-selector', e, this.projectsSelected, true)
    },

    /**
     * If column is being sorted && being sorted in asc order
     */
    isSortedByAscColumn (columnName) {
      if (this.isSortedByColumn(columnName) && this.filters.order_by.order === 'asc') return true
      return false
    },

    /**
     * Check if passed in column is being currently sorted
     */
    isSortedByColumn (sortedBy) {
      if (this.filters.order_by && this.filters.order_by.column === sortedBy) return true
      return false
    },

    /**
     * Handle clicking sort on project list column
     */
    handleSortClick (sortBy) {
      let newOrderBy = null

      if (this.filters.order_by && this.filters.order_by.order === 'asc') {
        newOrderBy = {
          column: sortBy,
          order: 'desc'
        }
      }

      if (!this.filters.order_by) {
        newOrderBy = {
          column: sortBy,
          order: 'asc'
        }
      }

      this.$set(this.filters, 'order_by', newOrderBy)
    },

    /*
    * Compare two objects by reducing an array of keys in obj1, having the
    * keys in obj2 as the intial value of the result. Key points:
    *
    * - All keys of obj2 are initially in the result.
    *
    * - If the loop finds a key (from obj1, remember) not in obj2, it adds
    *   it to the result.
    *
    * - If the loop finds a key that are both in obj1 and obj2, it compares
    *   the value. If it's the same value, the key is removed from the result.
    */
    getObjectDiff (obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key)
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key)
          result.splice(resultKeyIndex, 1)
        }
        return result
      }, Object.keys(obj2))

      return diff
    },

    /**
     * Checks if project is selected
     * @return {Boolean}
     */
    isProjectSelected (project) {
      return _.find(this.projectsSelected, project)
    },

    /**
     * Adds or deletes project from selected projects
     */
    handleProjectSelectChange (project) {
      if (this.isProjectSelected(project)) this.projectsSelected = this.projectsSelected.filter(p => p !== project)
      else this.projectsSelected = [...this.projectsSelected, project]
    },

    /**
     * Select or deselect all currently visible projects
     */
    handleSelectAllProjects () {
      if (this.allProjectsSelected) this.projectsSelected = []
      else this.projectsSelected = _.uniq([...this.projectsSelected, ...this.projects])
    },

    /**
     * Clear selected projects
     */
    clearSelected () { this.projectsSelected.splice(0) },

    /**
     * Remove filter from close icon on filter 'tab'
     */
    handleFilterRemove (type) {
      switch (type) {
        case (this.$t('filters.labels')):
          this.$set(this.filters, 'labels', FILTERS_DEFAULT.labels)
          break

        case (this.$t('filters.owner')):
          this.$set(this.filters, 'owner', FILTERS_DEFAULT.owner)
          break

        case (this.$t('filters.date_created')):
          this.$set(this.filters, 'created', [])
          break

        case (this.$t('filters.date_modified')):
          this.$set(this.filters, 'last_modified', [])
          break

        case (this.$t('filters.language')):
          this.$set(this.filters, 'language', FILTERS_DEFAULT.language)
          break

        case (this.$t('filters.source')):
          this.$set(this.filters, 'data_source_provider', FILTERS_DEFAULT.data_source_provider)
          break

        default:
          break
      }
    },

    /**
     * Opens the filter selector with the selected type active
     */
    handleChangeActiveFilterSelectorItem (type) {
      this.$nextTick(() => {
        this.filterSelectorShown = true
        this.activeFilterSelectorTab = type
      })
    },

    /**
     * Checkbox value in the tag filter selector
     * @return {Boolean}
     */
    tagIncludedInFilters (tag) {
      if (_.includes(this.filters.labels, tag.value)) return true
      return false
    },

    /**
     * Toggle tag filter checkbox change
     */
    handleFilterTagChange (tag) {
      let newTagFilters = [...this.filters.labels]

      if (_.includes(newTagFilters, tag.value)) {
        newTagFilters = _.filter(newTagFilters, t => t !== tag.value)
      } else {
        newTagFilters.push(tag.value)
      }

      this.$set(this.filters, 'labels', newTagFilters)
    },

    /**
     * Checkbox value in the filter selector
     */
    valueIncludedInFilters (key, value) {
      if (_.includes(this.filters[key], value)) return true
      return false
    },

    /**
     * Toggle filter checkbox change
     */
    handleFilterChange (key, value) {
      let newFilters = [...this.filters[key]]

      if (_.includes(newFilters, value)) {
        newFilters = _.filter(newFilters, v => v !== value)
      } else {
        newFilters.push(value)
      }

      this.$set(this.filters, key, newFilters)
    },

    /**
     * Handle search change
     */
    handleSearchChange (value) {
      this.$set(this.filters, 'search', value)
    },

    /**
     * Handle page change
    */
    handlePaginationChange (direction) {
      if (direction === PREV) {
        this.$set(this.filters.pagination, 'page', Number(this.filters.pagination.page) - 1)
      }

      if (direction === NEXT) {
        this.$set(this.filters.pagination, 'page', Number(this.filters.pagination.page) + 1)
      }

      this.projectsSelected = []
    },

    /**
     * Handle page size change
     */
    handlePageSizeChange (size) {
      this.$set(this.filters.pagination, 'rowsPerPage', size)
      this.$emit('rows-per-page', size)
    },

    /**
     * Reset filters
     */
    resetFilters () {
      this.filters = _.cloneDeep(FILTERS_DEFAULT)
    },

    /**
     * If the intial state of pagination and filters is given by the parent object, set those keys here
     * Otherwise, reset the values to their default
     */
    setInitialState (newVal, oldVal) {
      _(FILTERS_DEFAULT).keys().each(k => {
        let val = this.initialState.filters && this.initialState.filters[k]
        this.$set(this.filters, k, val || _.cloneDeep(FILTERS_DEFAULT[k]))
      })

      this.$emit('filters', this.filters)
    },

    /**
     * Truncates project name
     */
    truncatedName (projectName, withIndicator = false, withNewflag = false) {
      if (this.windowWidth > 1500 && !this.insideModal) return projectName

      let truncatedLength = 45
      if (withIndicator) truncatedLength -= 4
      if (withNewflag) truncatedLength -= 17

      return _.truncate(projectName, { length: truncatedLength })
    },

    /**
     * Change the active tab and refetch projects
     */
    setActiveTab (tabIdx) {
      if (this.loading) return

      const tab = this.tabs[tabIdx]

      if (!tab || !tab.value || !this.filters.tab || !this.filters.tab.value || tab.value === this.filters.tab.value) return

      _(FILTERS_DEFAULT).keys().each(k => {
        if (k === 'tab') return
        this.$set(this.filters, k, _.cloneDeep(FILTERS_DEFAULT[k]))
      })

      this.$set(this.filters, 'tab', tab)
      this.projectsSelected = []
    },

    /**
     * Check if the focusProject prop matches the internal state
     * if not, either open the project or close the project overlay
     */
    maybeFocusProject () {
      if (this.loading) return
      // If not focused on this project, open it
      if (this.projectToFocus !== null && (!this.questions.active || this.projectToFocus !== this.questions.project)) {
        let find = {}
        // For the tutorials, it could also be the special identifier 'demo-{open,list}'
        if (this.projectToFocus.startsWith('demo-')) {
          if (this.projectToFocus === 'demo-open') find.name = PROJECT_NAME_DEMO_OPEN
          else if (this.projectToFocus === 'demo-list') find.name = PROJECT_NAME_DEMO_LIST
          else return
          // Make sure we focus on the own demo project, for users that are part of an organization
          find.owner_id = this.user.id
        // In the "normal" case the projectToFocus is the ID of a project
        } else if (this.projectToFocus) find.id = this.projectToFocus
        let project = _.find(this.projects, find)
        if (project !== undefined) {
          this.$nextTick(() => this.showQuestions(project.id))
        }
      } else if (this.projectToFocus === null && this.questions.active) this.hideQuestions()
    },

    /**
     * Closes the usersOnline v1 websocket
     */
    closeObserverv1 () {
      this.usersOnline.socketv1.close()
      this.usersOnline.socketv1 = null
    },

    /**
     * Closes the usersOnline websocket
     */
    closeObserver () {
      this.usersOnline.socket.close()
      this.usersOnline.socket = null
    },

    /**
     * Opens websocket connection, observing the currently visible questions
     * for other users that might be editing them
     * @return {[type]} [description]
     */
    openObserver () {
      if (this.isDestroying) return
      ws.open(`ws/project-observer`).then(socket => {
        // It might be that in the meantime another socket has been opened. In that case close the previous one.
        if (this.usersOnline.socketv1 !== null) this.closeObserverv1()
        if (this.usersOnline.socket !== null) this.closeObserver()
        console.log('Project observer connection established.')
        this.usersOnline.failedCnt = 0
        this.usersOnline.socket = socket
        socket.onappmessage = (data) => {
          if ('error' in data) throw new Error(`Socket returned error: ${data.error}`)
          // Bring the Object of question IDs into hierarchical format, grouped by project
          if ('users_online' in data) {
            _.each(data.users_online, (pVal, projectID) => {
              // It might be that the response is late and the project is not actually visible anymore
              // Only if it's still visible, set the result
              if (projectID in this.usersOnline.data) {
                let pValSet = {}
                pVal.forEach(uo => {
                  pValSet[uo.question_id] = pValSet[uo.question_id] || []
                  pValSet[uo.question_id].push(uo)
                })
                this.$set(this.usersOnline.data, projectID, pValSet)
              }
            })
          }
        }

        socket.onclose = (event) => {
          if (event.wasClean) console.log(`Project observer cid=${socket.connectionID} cleanly closed.`)
          else {
            console.error(`Project observer closed unexpectedly. Code=${event.code} reason=${event.reason}`)
            setTimeout(this.openObserver, 5000)
          }
        }

        if (this.projects.length) this.updateObserver()
      }).catch(({ event, err }) => {
        this.usersOnline.failedCnt += 1
        if (this.usersOnline.failedCnt >= MAX_SOCKET_FAILED_CNT) {
          this.$onError(new Error(`Failed to connect to socket ${MAX_SOCKET_FAILED_CNT} times, stopping.`))
        } else setTimeout(this.openObserver, 10000)
        console.error(err)
        console.error('Project observer failed to connect.')
      })
    },

    /**
     * Send the current list of questions displayed to the observer websocket,
     * to get updates on which ones are currently being edited
     */
    updateObserver () {
      if (!this.observeUsersOnline) return

      let questions = []
      // Create list of question ids on current page
      this.projects.forEach(p => questions.push(..._.map(p.questions, 'id')))

      if (this.usersOnline.socket) {
        this.usersOnline.socket.send(JSON.stringify({ project_ids: _.map(this.projects, ({ id }) => id) }))
      }
    },

    showQuestions (projectId) {
      // It might be that the projectId is undefined, e.g. if the user doesn't have access
      // to the project of a related question
      if (!projectId || this.tagSelectorOpen) return
      // If the referenced project is not on the current page / in the current filter set
      // clear the filters & sorting and move to the appropriate page
      let project = _.find(this.projects, { id: projectId })
      if (_.find(this.projects, { id: projectId }) === undefined) {
        // Flag to prevent some watchers from firing and interfering with our settings
        this.showQuestionMutex = true
        let idxOfProject = _.findIndex(this.projects, { id: projectId })
        if (idxOfProject === -1) return
        this.filters.pagination.page = Math.floor(idxOfProject / this.filters.pagination.rowsPerPage) + 1
      }
      this.$nextTick(() => {
        this.projectsSelected = []
        this.questions.active = true
        this.questions.project = project.id
        this.showQuestionMutex = false
        this.$emit('open-project', { id: project.id, name: project.name })
      })
    },

    hideQuestions () {
      if (this.questions.active && !this.dontHideProjectDetailsOnClick && !this.flowtree.active) {
        this.questions.active = false
        this.$emit('open-project')
      }
    },

    showFlowtree (cog) {
      const scaler = this.flowtree.scaler
      const ySpacer = this.flowtree.ySpacer / scaler

      // Find origin
      let origin = cog
      // Check for circular dependencies
      let questionIDsTraversed = new Set()
      while (origin.inherits_from !== null && !questionIDsTraversed.has(origin.id)) {
        questionIDsTraversed.add(origin.id)
        origin = origin.inherits_from_obj
      }

      // Recursively go through inheritances until there are no further elements in tree
      let nodes = []
      let arrows = []

      let minX = 0
      let maxX = 0
      let maxY = 0

      let breadthPerQuestion = {}

      let questionIDsTraversedBreadth = new Set()
      const determineTreeBreadth = (question) => {
        // Check for circular dependencies
        if (questionIDsTraversedBreadth.has(question.id)) return
        questionIDsTraversedBreadth.add(question.id)
        breadthPerQuestion[question.id] = 0
        if (question.inherits_to_objs.length >= 2) {
          // If there is a split here, go down the rabbit hole again and increase the breadth for all previous questions by one
          let q = question
          while (q.inherits_from) {
            q = q.inherits_from_obj
            if (questionIDsTraversedBreadth.has(q.id)) return
            questionIDsTraversedBreadth.add(q.id)
            if (q.inherits_to_objs.length > 1) breadthPerQuestion[q.id] += 1
          }
        }
        question.inherits_to_objs.forEach(determineTreeBreadth)
      }

      // Check for circular dependencies
      let questionIDsTraversedNodes = new Set()
      /**
       * Recursive function going through all linked questions and creating the array of nodes and arrows
       */
      const createNodeAndArrows = (question, nSplits, prevX, prevY, diffX, diffY = 0.5) => {
        if (questionIDsTraversedNodes.has(question.id)) return
        questionIDsTraversedNodes.add(question.id)

        const n = question.inherits_to_objs.length
        const breadth = breadthPerQuestion[question.id] || 0 //  * (breadth / n)
        // The coordinates of the current element
        const currX = prevX + diffX
        const currY = prevY + diffY

        minX = Math.min(minX, currX * scaler)
        maxX = Math.max(maxX, currX * scaler)
        maxY = Math.max(maxY, currY * scaler)

        // Compute the offset for children in x direction, depending on how many child elements the current node has
        const offsetX = -(n + breadth - 1) / 2
        const idxMultiplier = 1 + (n > 1 ? breadth / (n - 1) : 0)
        nodes.push({ question, x: currX * scaler, y: currY * scaler, cog: question === cog })
        // Only create new arrow if node is not at same position (i.e. first node)
        if (diffX !== 0 || diffY !== 0) {
          arrows.push({
            xStart: prevX * scaler,
            yStart: prevY * scaler,
            xEnd: currX * scaler,
            yEnd: currY * scaler - 5
          })
        }
        question.inherits_to_objs.forEach((q, qIdx) => {
          createNodeAndArrows(q, nSplits, currX, currY + ySpacer, offsetX + qIdx * idxMultiplier)
        })
      }

      determineTreeBreadth(origin)
      createNodeAndArrows(origin, 0, 0, 0.25, 0, 0)

      this.$set(this.flowtree, 'arrows', arrows)
      this.$set(this.flowtree, 'nodes', nodes)

      // add padding to the min / max values and apply minimum value
      this.flowtree.cog = cog
      this.flowtree.minX = minX - this.flowtree.nodeWidth / 2
      this.flowtree.maxX = maxX + this.flowtree.nodeWidth / 2
      this.flowtree.maxY = maxY + 0.2 * scaler + this.flowtree.ySpacer

      this.flowtree.active = true
    }
  }
}

</script>

<style lang=scss>
  @import '~css/project-list';
  @import '~css/filters';
</style>

<i18n locale='en' src='@/i18n/en/AppPage.json' />
<i18n locale='de' src='@/i18n/de/AppPage.json' />
<i18n locale='en' src='@/i18n/en/pages/ProjectList.json' />
<i18n locale='de' src='@/i18n/de/pages/ProjectList.json' />