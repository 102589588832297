<template>
  <div>
    <sidebar-wrapper
      @close="closeDialog"
      v-model="internalValue"
    >
      <sidebar-header>
        <template slot="title">
          {{ title }}
        </template>
        <template slot="controls">
          <v-btn
            icon
            small
            @click="closeDialog"
            class="export-data-sidebar-drawer__close-button"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </sidebar-header>
      <sidebar-content>
        <export-data-tabs
          v-model="formData.kind"
          :sources="sources"
        />
      </sidebar-content>
      <sidebar-content class="px-4 py-6">
        <export-data-form
          v-model="formData"
          :sources="sources"
        />
      </sidebar-content>
      <sidebar-content
        class="px-4 py-6"
        v-if="exports.list.length || exports.loading"
      >
        <export-data-previous-exports
          :items="exports.list"
          :loading="exports.loading"
          :kind="formData.kind"
          :shown-fully="isExportsListFullyShown"
          @download="downloadFile"
        />
      </sidebar-content>
      <template slot="bottom">
        <sidebar-actions>
          <template slot="leftSide">
            <v-btn
              color="primary"
              @click="generateAndDownloadNewFile"
              :loading="state.loading"
              :disabled="state.loading"
              class="mr-2"
            >
              {{ $t('download_dialog.controls.download') }}
            </v-btn>
            <v-btn
              outlined
              @click="closeDialog"
              class="mr-6"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              v-if="formData.kind === 0 && exports.list.length > 5 && !exports.isRowsListShown"
              outlined
              @click="showAllExports"
            >
              {{ $t('download_dialog.controls.expand_export_list') }}
            </v-btn>
            <v-btn
              v-if="formData.kind === 1 && exports.list.length > 5 && !exports.isTopicsListShown"
              outlined
              @click="showAllExports"
            >
              {{ $t('download_dialog.controls.expand_export_list') }}
            </v-btn>
          </template>
        </sidebar-actions>
      </template>
    </sidebar-wrapper>
  </div>
</template>

<script>
import SidebarWrapper from '@/components/customUi/sidebar/SidebarWrapper'
import SidebarHeader from '@/components/customUi/sidebar/SidebarHeader'
import SidebarContent from '@/components/customUi/sidebar/SidebarContent'
import SidebarActions from '@/components/customUi/sidebar/SidebarActions'

import ExportDataTabs from '@/components/coding/ExportData/ExportDataTabs'
import ExportDataForm from '@/components/coding/ExportData/ExportDataForm'
import ExportDataPreviousExports from '@/components/coding/ExportData/ExportDataPreviousExports'

import ControlsMixin from '@/components/coding/ExportData/composition/controls.js'
import DataPrefetch from '@/components/coding/ExportData/composition/dataPrefetch.js'
import FormMixin from '@/components/coding/ExportData/composition/form.js'
import GenerationMixin from '@/components/coding/ExportData/composition/generation.js'
import ExportsMixin from '@/components/coding/ExportData/composition/exports.js'
import ExecuteTillConditionMixin from '@/components/customUi/mixins/executeTillCondition.js'

import DataMixin from '@/components/customUi/mixins/data'
import ApiTaskMixin from '@/mixins/apitask'

export default {
  codit: true,
  name: 'DownloadSidebarDrawer',

  components: {
    'sidebar-wrapper': SidebarWrapper,
    'sidebar-header': SidebarHeader,
    'sidebar-content': SidebarContent,
    'sidebar-actions': SidebarActions,
    'export-data-tabs': ExportDataTabs,
    'export-data-form': ExportDataForm,
    'export-data-previous-exports': ExportDataPreviousExports
  },

  mixins: [
    ControlsMixin,
    DataPrefetch,
    FormMixin,
    GenerationMixin,
    ExportsMixin,
    ExecuteTillConditionMixin,
    DataMixin,
    ApiTaskMixin
  ],

  props: {
    value: { type: Boolean, default: false }
  },

  data () {
    return ({
      coding: true
    })
  }
}

</script>

<style lang="scss">
@import '@/css/override.scss';

.export-data-sidebar-drawer{
  &__close-button{
    position: absolute !important;
    top: 11px;
    right: 22px;
    z-index: 10;
  }
}
</style>

<i18n locale='en' src='@/i18n/en/components/ExportData.json' />
<i18n locale='de' src='@/i18n/de/components/ExportData.json' />