<template>
  <DialogWrapper
    :value="true"
    :width="280"
    :closable="false"
  >
    <DialogHeader>
      <template slot="title">
        {{ $t('permission.no_permission_title') }}
      </template>
    </DialogHeader>
    <DialogContent
      class="px-4 py-5"
    >
      <span v-html="$t('upload_not_allowed.text_free')" />
      <span
        v-if="!$root.isRootAccount"
        v-html="$t('feature_not_included.contact_admin', { email: user.organization.root_user_email })"
      />
    </DialogContent>
    <DialogActions>
      <template
        v-if="$root.isRootAccount"
        slot="leftSide"
      >
        <v-btn
          outlined
          @click="goToMainPage()"
          class="mr-2"
        >
          {{ $t('to_main_page') }}
        </v-btn>
      </template>
      <template slot="rightSide">
        <v-btn :href="MEETING_LINK"
               v-if="$root.isRootAccount"
               color="orange"
               dark
               target="_blank"
               class="mr-2"
        >
          {{ $t('upload_not_allowed.talk_to_sales') }}
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="goToMainPage()"
          class="mr-2"
        >
          {{ $t('to_main_page') }}
        </v-btn>
      </template>
    </DialogActions>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import { MEETING_LINK } from '@/settings/constants'

import Vuex from 'vuex'

export default {
  name: 'ManageIntegrations',
  codit: true,

  components: {
    DialogWrapper,
    DialogHeader,
    DialogContent,
    DialogActions
  },

  data () {
    return {
      MEETING_LINK
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user'
    })
  },

  methods: {
    goToMainPage () {
      this.$router.replace({
        name: 'home'
      })
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />