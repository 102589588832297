<template>
  <div class="main-content uspg" ref="main-content">
    <!-- ====== Step: UPLOAD ANSWERS ====== -->
    <loading v-model="loading.percentage"
             :is-loading="loading.active && !project.name.length"
             :dont-animate-entry="true"
             :title="$t('loading.title')"
             :tagline="loading.eta"
    >
      <div
        v-if="loading.failed !== null"
        class="d-flex w-100 d-flex justify-center"
      >
        <notification
          prominent
          type="error"
          border="left"
          text
          class="w-100"
          style="max-width: 1224px;"
        >
          {{ $t(loading.failed) }}
        </notification>
      </div>
      <div v-else>
        <div class="progress-bar" />

        <v-tabs
          v-model="step"
          class="upload-tabs"
          background-color="white"
          :ripple="false"
          grow
        >
          <v-tab
            v-for="(item, index) in steps"
            :ripple="false"
            :key="item"
            :disabled="index + 1 > step"
          >
            {{ index + 1 + '. ' + $t(`steps.${item}.label`) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="step">
          <v-tab-item
            key="upload"
            :transition="false"
            :reverse-transition="false"
          >
            <step-upload :append="true" @next="goToOrganize" />
            <div class="upload-footer pl-4 pr-4 default-bs">
              <v-btn
                class="upload-footer__back"
                color="white"
                @click="$router.go(-1)"
                x-large
                elevation="0"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                :disabled="!validAppendUpload"
                :loading="loading.active"
                @click="goToOrganize"
                color="primary"
                x-large
                class="default-bs"
              >
                {{ $t('steps.upload.append_next') }}
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item
            key="organize"
            :transition="false"
            :reverse-transition="false"
          >
            <div class="d-flex flex-column align-center">
              <step-organize :append="true" />
              <div class="upload-footer pl-4 pr-4 default-bs">
                <v-btn
                  @click="goToUpload"
                  class="upload-footer__back"
                  color="white"
                  x-large
                  elevation="0"
                >
                  {{ $t('back') }}
                </v-btn>
                <v-btn
                  :disabled="!validAppend"
                  :loading="loading.updatingActive || loading.active"
                  @click="appendColumns"
                  color="primary"
                  x-large
                  class="default-bs"
                >
                  {{ $t('steps.append.next') }}
                </v-btn>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </loading>

    <UploadNoPermission v-if="!loading.active && !$hasPermission('projects_append', project)" />
    <UploadTrial v-if="user.subscription.plan_id === PLAN_FREE && user.eligible_for_trial" />
    <UploadSubscription v-if="user.subscription.plan_id === PLAN_FREE && !user.eligible_for_trial" />
  </div>
</template>

<script>

import Vuex from 'vuex'
// import axios from 'axios'
import StepUpload from '@/components/upload/StepUpload'
import StepOrganize from '@/components/upload/StepOrganize'

import UploadNoPermission from '@/components/modals/UploadNoPermission'
import UploadTrial from '@/components/modals/UploadTrial'
import UploadSubscription from '@/components/modals/UploadSubscription'

import ApiTaskMixin from '@/mixins/apitask'

import { PLAN_FREE } from '@/settings/constants'

export default {
  codit: true,
  name: 'Append',
  components: {
    'step-upload': StepUpload,
    'step-organize': StepOrganize,
    UploadNoPermission,
    UploadTrial,
    UploadSubscription
  },
  mixins: [ApiTaskMixin],

  /**
   * Warn about losing changes when going to another page (in the app)
   */
  beforeRouteLeave (to, from, next) {
    let doIt = true
    // If there is an uploaded file, confirm navigating away
    if (this.answersInput.file.length && !to.params.skipWarning) doIt = confirm(this.$t('steps.upload.confirm_navigate_away'))
    next(doIt)
  },

  data () {
    return {
      step: 0,
      steps: ['upload', 'append'],
      PLAN_FREE
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user',
      project: state => state.upload.project,
      loading: state => state.upload.loading,
      answersInput: state => state.upload.answersInput
    }),

    ...Vuex.mapGetters([
      'userCreditsRemaining',
      'validAppend',
      'validAppendUpload',
      'validOrganize',
      'isFastAppendAvailable'
    ])
  },

  mounted () {
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },

  created () {
    let id = this.$route.params.id

    this.$store.commit('setAppend', true)

    this.$store.dispatch('fetchProject', id)
      .then(project => {
        this.$store.commit('setBreadcrumbProps', {
          projectID: project.id,
          projectName: project.name
        })
      })
      .catch(err => this.$maybeRaiseAPIPromiseErr(err))
  },

  methods: {
    /**
     * Return true if there might be unsaved changes and that the user should be warned thorugh an alert
     * @param  {Object} $event The navigate away event
     * @return {Boolean}
     */
    beforeUnload ($event) {
      if (this.answersInput.file.length) $event.returnValue = true
    },

    /**
     * Go to upload step
     */
    goToUpload () {
      this.step = 0
    },

    /**
     * Go to organize step
     */
    goToOrganize () {
      this.step = 1
    },

    /**
     * Append the columns
     */
    appendColumns () {
      this.$store.dispatch('appendColumns', false)
        .then(res => this.asyncCb(res))
        // .then(res => this.afterSave(this.$route.params.id))
        .catch(err => this.$maybeRaiseAPIPromiseErr(err))
    },

    updateCb (eta, percentage) {
      this.$store.commit('setLoading', { key: 'eta', value: eta })
      this.$store.commit('setLoading', { key: 'percentage', value: percentage })
    },

    asyncCb (res) {
      this.getApiTaskStatus(res.headers['task-id'], parseFloat(res.headers['task-eta']), this.updateCb)
        .then(() => this.afterSave(this.$route.params.id))
        .catch(err => {
          this.$store.commit('setLoading', { key: 'failed', value: 'steps.organize.error_appending_columns' })
          this.$store.commit('setLoading', { key: 'active', value: false })

          throw err
        })
    },

    /**
     * Redirect after project save
    */
    afterSave (projectid) {
      this.$root.snackMsg(this.$t('steps.append.snack_success'))
      this.$router.push({ name: 'projects-manage-id', params: { id: projectid, skipWarning: true } })
    }
  }
}

</script>

<style lang=scss>
  @import '../css/upload.scss';
</style>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />
<i18n locale='en' src='@/i18n/en/AppPage.json' />
<i18n locale='de' src='@/i18n/de/AppPage.json' />
