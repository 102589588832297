<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane v-model="config"
                    v-bind="controlPaneProps"
                    v-on="controlPaneEvents"
                    :color-palettes="config.colorPalettes"
                    :has-valid-cat-colors="false"
                    ref="control-pane"
                    slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>

        <template
          v-if="showChartTypeSettingsMenu"
          v-slot:general
        >
          <settings-drawer-item
            :title="$t('type.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.type" hide-details class="mt-0 mb-0">
                <v-radio :label="$t('type.gauge')" value="pie-gauge" />
                <v-radio :label="$t('type.pie')" value="pie" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:colors>
          <settings-drawer-item
            :title="$t('colors.palette.title')"
          >
            <template slot="content">
              <div
                class="d-flex align-center theme--light v-label mb-3"
                v-for="(range, idx) in config.ranges"
                :key="idx"
              >
                <div
                  class="color-indicator mr-2"
                  :style="{ 'background-color': range.color }"
                  @click.stop="handleShowRangeColorPicker(idx, $event)"
                />
                {{ range.label }}
              </div>
            </template>
          </settings-drawer-item>
          <color-picker
            :show.sync="showColorPicker"
            v-if="config.ranges[selectedCustomColor]"
            v-model="config.ranges[selectedCustomColor].color"
            :offset-top="rangeColorPickerConfig.offsetTop"
            :offset-left="rangeColorPickerConfig.offsetLeft + 5"
            :min-top="-80"
            lazy
          />
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.percentages"
                :label="$t('controls.labels.percentages')"
              />
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>
          <settings-drawer-item :title="$t('score.method_label')" v-if="showChartTitleSettingsMenu">
            <template slot="content">
              <v-text-field
                v-model="config.scoringMethodLabel"
                :label="$t('score.method_label')"
                dense
                outlined
                hide-details
              />
            </template>
          </settings-drawer-item>
          <settings-drawer-item :title="$t('score.range_labels')">
            <template slot="content">
              <div
                v-for="(range, idx) in config.ranges"
                :key="idx"
                class="d-flex justify-space-between align-center mt-4"
              >
                <v-text-field
                  v-model="range.label"
                  :label="`${$t('score.range_label')} #${idx + 1}`"
                  dense
                  outlined
                  hide-details
                />
              </div>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-checkbox
            v-model="config.showSampleSize"
            hide-details
            :label="$t('settings.sample_size.label_control')"
            class="mt-2"
          />
        </template>

        <template v-slot:score>
          <slot name="score" />
          <settings-drawer-item :title="$t('score.method_label')" v-if="showChartTitleSettingsMenu">
            <template slot="content">
              <v-text-field
                v-model="config.scoringMethodLabel"
                :label="$t('score.method_label')"
                dense
                outlined
                hide-details
              />
            </template>
          </settings-drawer-item>
          <settings-drawer-item :title="$t('score.boundries')" v-if="showBoundariesSettingsMenu">
            <template slot="helptip">
              <div class="d-flex align-center">
                <v-btn small color="error" text class="pr-2 pl-2" @click="resetSettings">
                  {{ $t('score.reset') }}
                </v-btn>
              </div>
            </template>
            <template slot="content">
              <vue-slider
                v-model="config.rangeBoundries"
                v-bind="sliderOptions"
                :min="sliderMinValue"
                :max="sliderMaxValue"
                :dot-options="sliderDotOptions"
                :process="sliderProcess"
                :silent="true"
                :lazy="true"
                class="mt-12"
                @change="onChangeSlider"
              />
            </template>
          </settings-drawer-item>
          <settings-drawer-item
            v-for="(range, idx) in config.ranges"
            :key="idx"
            :title="`${$t('score.range')} #${idx + 1}`"
          >
            <template slot="helptip">
              <div class="d-flex align-center">
                <v-btn small color="error" text class="pr-2 pl-2" @click="removeRange(idx)" :disabled="config.ranges.length < 3">
                  {{ $t('actions.delete') }}
                </v-btn>
              </div>
            </template>
            <template slot="content">
              <div class="d-flex justify-space-between align-center mt-4">
                <v-text-field
                  v-model="range.label"
                  :label="$t('score.range_label')"
                  class="mr-3"
                  dense
                  outlined
                  hide-details
                />
                <div class="d-flex align-center theme--light v-label">
                  <div
                    class="color-indicator"
                    :style="{ 'background-color': range.color }"
                    @click.stop="handleShowRangeColorPicker(idx, $event)"
                  />
                </div>
              </div>
              <div
                v-if="isRangeButtonShown(idx)"
                class="d-flex justify-center"
              >
                <v-btn
                  class="filters__select mt-3"
                  :class="{'dashboard-filters__select--disabled': config.ranges.length > 4}"
                  @click="addRange"
                  hide-details
                  solo
                  dense
                >
                  <v-icon>
                    mdi-plus-circle
                  </v-icon>
                  {{ $t('score.add_range') }}
                </v-btn>
              </div>
            </template>
          </settings-drawer-item>
          <color-picker
            :show.sync="showColorPicker"
            v-if="config.ranges[selectedCustomColor]"
            v-model="config.ranges[selectedCustomColor].color"
            :offset-top="rangeColorPickerConfig.offsetTop"
            :offset-left="rangeColorPickerConfig.offsetLeft + 5"
            :min-top="-80"
            lazy
          />
        </template>

        <slot
          name="datasets"
          slot="datasets"
          :dataset-props="{ editable: false }"
        />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <template v-slot:chart-type-selection="{ warnings = {} }">
          <slot name="chart-type-selection" />
          <slot name="score" :warnings="warnings" />
        </template>
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{chartStyle}">
        <v-chart
          ref="chart"
          v-if="initialReady && chartDataset.length"
          :style="chartStyle"
          v-bind="chartProps"
          v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>
/*
* the component should work with satisfaction data and single scoring charts
*/

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import ColorPicker from '@/components/ColorPicker'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'
import VueSlider from 'vue-slider-component'

import { chartMixin } from '@/mixins/chart'
import { colorToRgbaObject } from '@/utils/colorUtils'
import {
  PNG,
  SCORING_TYPE_NPS,
  SCORING_TYPE_CSAT,
  SCORING_TYPE_AVERAGE,
  SCORING_TYPE_SENTIMENT,
  SATISFACTION_SCORING_TYPES,
  UNIQUE_SCORING_TYPES,
  UNIQUE_CHARTS_FOR_UNIQUE_SCORING_TYPES
} from '@/settings/constants'

import 'vue-slider-component/theme/default.css'
import 'echarts/lib/chart/pie'

const NPS_TYPE_DETRACTOR = 'detractor'
const NPS_TYPE_PASSIVE = 'passive'
const NPS_TYPE_PROMOTER = 'promoter'

const CSAT_TYPE_DETRACTOR = 'unsatisfied'
const CSAT_TYPE_PASSIVE = 'neutral'
const CSAT_TYPE_PROMOTER = 'satisfied'

const EMPTY_RESULT = 'EMPTY'

export default {
  codit: true,
  name: 'ChartScore',
  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'color-picker': ColorPicker,
    'settings-drawer-item': SettingsDrawerItem,
    'vue-slider': VueSlider
  },
  mixins: [chartMixin],
  data () {
    return {
      resultKeys: ['counts'],
      forceAggregate: true,
      config: {
        // General
        title: undefined,
        type: 'pie-gauge', // pie-gauge | pie
        dimensions: undefined,
        aggregate: undefined,

        colorBy: {},

        // Canvas background
        enableBackground: false,
        background: '#ffffff',

        // Labels
        percentages: true,
        // maxDataLabelLength: 15,
        dataLabelSize: 15,
        axisLabelSize: 11,
        labelsEnabled: true,
        showLegend: true,
        showSampleSize: true,
        decimalPlaces: 0,
        // Master Filters
        filters: [],

        // Ranges
        ranges: [],
        rangeBoundries: [],
        scoringMethodLabel: '',
        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },
      sliderProcess: dotsPos => {
        return _.map(dotsPos, (pos, index) => {
          if (index === 0) {
            return [0, pos, { backgroundColor: this.config.ranges[index].color }]
          }

          return [dotsPos[index - 1], pos, { backgroundColor: this.config.ranges[index].color }]
        })
      },
      sliderOptions: {
        enableCross: false,
        minRange: 1,
        tooltip: 'always',
        contained: true
      },
      rangeColorPickerConfig: {},
      selectedCustomColor: 0,
      chartContainerWidth: 0,
      showColorPicker: false,
      downloadTypes: [PNG],
      availableSettingTiles: {
        ...this.availableSettingTiles,
        outputs: [''],
        score: ['ranges', 'rangeBoundries']
      },
      editedByUser: false, // use it to know if range boundaries were edited by user
      previousSliderMax: null // use it if user changed range boundaries and now we can use bigger range for slider
    }
  },

  computed: {
    /*
    * use it to define API type for request
    * possibly it will be unique for every type of chart
    * @return {String} Returns ENUM for requests to api
    */
    APItype () {
      if (
        this.scoringType === SCORING_TYPE_SENTIMENT
      ) {
        return 'SENT'
      }
      return 'NPS'
    },

    minContainerWidth () {
      if (
        this.config.type === 'pie-doughnut' &&
        this.isDashboard
      ) {
        return 250
      }
      return 380
    },
    minContainerHeight () {
      if (
        this.config.type === 'pie-doughnut' &&
        this.isDashboard
      ) {
        return 250
      }
      return 400
    },

    /**
     * styling options for the dots on the boundry slider
     * @return {Object}
     */
    sliderDotOptions () {
      return _.map(this.config.ranges, range => ({
        style: {
          background: range.color,
          opacity: 1,
          'box-shadow': 'none'
        },
        tooltipStyle: {
          background: range.color,
          color: 'white',
          'border-color': range.color
        }
      }))
    },

    /**
     * Returns the currently selected analysis type
     * unless value is clearly defined
     * we use NPS by default
     * @return {String} Actual scoring type
    */
    scoringType () {
      /*
      * define usage of specific charts with single responsibility
      */
      if (
        UNIQUE_CHARTS_FOR_UNIQUE_SCORING_TYPES[this.chartUniqueName]
      ) {
        return UNIQUE_CHARTS_FOR_UNIQUE_SCORING_TYPES[this.chartUniqueName]
      }
      return this.datasets[0]?.settings.scoring_type || SCORING_TYPE_NPS
    },

    /**
    * Does the computation for the dataset
    * @return {Object} Returns prepared results
    */
    computedResultsWithWeight () {
      if (
        !this.globalResults ||
        !this.globalResults.counts_raw ||
        !this.resultsPerRange ||
        !this.resultsPerRange.length
      ) {
        return null
      }
      let datasets = this.config.ranges.map((range, idx) => ({
        key: range.label,
        value: Number(this.resultsPerRange[idx]?.results.overall) || 0
      }))
      const sum = this.globalResults.counts?.overall
      return { datasets, sum }
    },

    /**
     * The chart dataset, contains hacked value of EMPTY_RESULT for chart type of pie-gauge
     * @return {Array}
     */
    chartDataset () {
      if (!this.computedResultsWithWeight || this.remoteResults.loading) return []

      const { datasets, sum } = this.computedResultsWithWeight

      let detractors
      let promoters

      /*
      * define calucation of detractors/promoters only for satisfaction scoring
      */
      if (
        SATISFACTION_SCORING_TYPES.indexOf(this.scoringType) > -1
      ) {
        detractors = this.config.ranges[0].label
        promoters = this.config.ranges[this.config.ranges.length - 1].label
      }

      let score

      if (
        UNIQUE_SCORING_TYPES.indexOf(this.scoringType) > -1
      ) {
        score = sum
      } else if (
        this.scoringType === SCORING_TYPE_NPS &&
        typeof this.globalResults.nps === 'number'
      ) {
        score = this.toFixed(this.globalResults.nps * 100, this.config.decimalPlaces)
      } else if (
        this.scoringType === SCORING_TYPE_CSAT &&
        typeof this.globalResults.csat === 'number'
      ) {
        score = this.toFixed(this.globalResults.csat * 100, this.config.decimalPlaces)
      } else if (
        this.scoringType === SCORING_TYPE_AVERAGE &&
        typeof this.globalResults.avg === 'number'
      ) {
        score = this.toFixed(this.globalResults.avg, this.config.decimalPlaces)
      }

      const epsilon = 0.0000001

      let result = datasets.map((dataset, index) => {
        const range = this.config.ranges.find(({ label }) => label === dataset.key)
        return {
          key: dataset.key,
          name: dataset.key, // it's default property to use iin echart
          label: range.label, // it's in use for exact render of label
          perc: this.toFixed(dataset.value / sum * 100, this.config.decimalPlaces),
          value: sum !== 0 ? dataset.value : 1 * epsilon,
          color: range.color,
          score,
          promoters,
          detractors
        }
      })

      if (this.config.type === 'pie-gauge') {
        result = [
          ...result,
          {
            key: EMPTY_RESULT,
            name: EMPTY_RESULT,
            value: 0.7 * (sum !== 0 ? sum : datasets.length * epsilon),
            silent: true,
            trigger: 'axis',
            itemStyle: {
              color: 'rgba(0,0,0,0)',
              borderColor: 'transparent'
            }
          }
        ]
      }

      return result
    },

    /**
     * The legend's dataset, removes EMPTY_RESULT from dataset, for displaying purposes
     * @return {Array}
     */
    chartDatasetLegend () {
      const legendDataSet = [...this.chartDataset]
      legendDataSet.pop()

      return this.config.type === 'pie-gauge' ? legendDataSet : this.chartDataset
    },

    /**
     * returns data for selected auxiliary column
     * @return {Object}
     */
    selectedAuxilaryColumn () {
      const ds = this.datasets[0]
      return this.meta.auxiliary_column_metas?.[ds?.settings?.scoring_column]
    },

    /**
     * The actual echarts series, including EMPTY_RESULT for type of 'pie-gauge' chart
     * @return {Array}
     */
    series () {
      let seriesOptions = {
        name: this.datasets[0]?.settings.name,
        width: '100%',
        height: this.isDashboard ? '100%' : 'calc(100vh - 100px)',
        type: 'pie',
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2
        },
        nodeClick: false,
        roam: 'pan',
        top: 100,
        percentages: this.config.percentages,
        label: {
          show: this.config.labelsEnabled,
          formatter: (el) => el.data.label + (this.config.percentages ? `\n(${el.data.perc}%)` : ''),
          fontSize: this.config.dataLabelSize
        },
        data: this.chartDataset,
        color: this.config.ranges.map(({ color }) => color),
        animation: false
      }

      // specific view for sentiment chart
      if (this.config.type === 'pie-doughnut') {
        seriesOptions = {
          ...seriesOptions,
          top: 0,
          height: '100%',
          radius: ['75%', '98%'],
          label: {
            show: false
          }
        }
      }

      if (this.config.type === 'pie-gauge') {
        seriesOptions = {
          ...seriesOptions,
          height: '100%',
          top: 35,
          startAngle: 196,
          endAngle: 10,
          radius: ['50%', '70%'],
          animation: false,
          richLabel: this.config.scoringMethodLabel, // required to make it responsive
          label: {
            show: this.config.labelsEnabled,
            position: 'center',
            padding: [0, 0, 20, 0],
            formatter: data => {
              return [`{label| ${this.config.scoringMethodLabel}}`, `{value|${this.chartDataset[0].score}}`].join('\n')
            },
            rich: {
              label: {
                fontSize: this.config.dataLabelSize * (this.isDashboard ? 2 : 3) * 0.9,
                color: '#303030'
              },
              value: {
                fontSize: this.config.dataLabelSize * (this.isDashboard ? 7 : 10) * (0.9 - this.config.decimalPlaces * 0.1),
                color: '#303030'
              }
            }
          }
        }
      }

      return [seriesOptions]
    },

    /**
     * Title settings
     * @return {Object}
     */
    title () {
      const options = {
        textStyle: {
          fontStyle: 'italic',
          fontSize: this.config.axisLabelSize,
          fontWeight: 'normal',
          color: 'grey',
          width: 200,
          align: 'right'
        },
        bottom: 0,
        right: 0
      }

      if (
        this.config.showSampleSize &&
        typeof this.globalResults?.counts?.overall === 'number'
      ) {
        options.text = `n=${this.globalResults.counts.overall}`
      }

      return options
    },

    /**
     * Legend settings
     * @return {Object}
     */
    legend () {
      let legendConfig = {
        show: this.config.showLegend && this.chartDataset.length,
        orient: 'horizontal',
        top:
          this.config.type === 'pie' ||
          this.config.type === 'pie-doughnut' ||
          this.isDashboard ? 30 : '70%',
        selectedMode: false,
        backgroundColor:
          this.isDashboard ? 'white'
            : !this.config.enableBackground ? '#eee'
              : 'transparent',
        borderRadius: 3,
        padding: this.isDashboard ? [0, 0, 0, 0] : [15, 30, 15, 30],
        itemGap: 40,
        textStyle: {
          fontSize: this.config.dataLabelSize,
          color: el => el,
          rich: {
            label: {
              fontSize: this.config.dataLabelSize
            },
            value: {
              fontWeight: 'bold',
              fontSize: this.config.dataLabelSize,
              align: 'middle',
              verticalAlign: 'middle',
              lineHeight: 20,
              color: el => ''
            }
          }
        },
        data: this.chartDatasetLegend,
        formatter: (el) => {
          if (el === EMPTY_RESULT) return null
          let ds = _.find(this.chartDataset, { name: el })

          return [`{label| ${ds.label}}`, `{value| ${this.config.percentages ? ds.perc + '%' : (ds.value).toFixed(0)}}`].join('\n')
        }
      }

      // specific view for sentiment chart
      if (this.config.type === 'pie-doughnut') {
        legendConfig = {
          ...legendConfig,
          ...{
            orient: 'vertical',
            x: 'center',
            y: 'center',
            itemGap: 30
          },
          formatter: (el) => {
            if (el === EMPTY_RESULT) return null
            let ds = _.find(this.chartDataset, { name: el })
            return `{label| ${ds.label}} {value| ${this.config.percentages ? ds.perc + '%' : (ds.value).toFixed(0)}}`
          }
        }
        delete legendConfig.top
      }

      return legendConfig
    },

    /**
     * Tooltip settings
     * @return {Object}
     */
    tooltip () {
      return {
        formatter: (el) => {
          if (el.name === EMPTY_RESULT) return

          const tt = `${this.$escapeHtml(el.data.label)}: ${el.data.value.toFixed(0)} (${el.data.perc}%)`
          return tt
        },
        confine: true
      }
    },

    /**
     * The final chart options sent to echarts
     * @return {Object}
     */
    chartOptions () {
      return {
        ...this.defaultOptions,
        series: this.series,
        tooltip: this.tooltip,
        legend: this.legend,
        title: this.title,
        color: this.colors,
        backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
      }
    },
    /*
    * define if we need to render boundaries settings
    * for now available for satisfaction charts
    * @return {Boolean} If boundaries block is to use
    */
    showBoundariesSettingsMenu () {
      return SATISFACTION_SCORING_TYPES.indexOf(this.scoringType) > -1
    },
    /*
    * define if we need to render chart view settings
    * for now available for satisfaction charts
    * @return {Boolean} If chart view checkboxes are to use
    */
    showChartTypeSettingsMenu () {
      return SATISFACTION_SCORING_TYPES.indexOf(this.scoringType) > -1
    },
    /*
    * define if we need to render chart title for editing
    * @return {Boolean} If chart title available for editing
    */
    showChartTitleSettingsMenu () {
      return SATISFACTION_SCORING_TYPES.indexOf(this.scoringType) > -1
    },
    /*
    * define min value for ranges slider
    * to exclude negative values
    * @return {Number} Min value for ranges slider
    */
    sliderMinValue () {
      if (
        this.selectedAuxilaryColumn.min < 0
      ) {
        return 0
      }
      return this.selectedAuxilaryColumn.min
    },
    /*
    * define max value for ranges slider
    * to override case if user changed ranges and switched column/method
    * and current chart default settings are not very suitable
    * or use predifened value for NPS/CSAT
    * @return {Number} Max value for ranges slider
    */
    sliderMaxValue () {
      if (
        this.editedByUser &&
        this.previousSliderMax
      ) {
        return this.previousSliderMax
      } else if (
        this.scoringType === SCORING_TYPE_CSAT
      ) {
        return 5
      } else if (
        this.scoringType === SCORING_TYPE_NPS &&
        this.selectedAuxilaryColumn.semantic_type === 'GENERIC_AUXILIARY'
      ) {
        return 10
      }
      return this.selectedAuxilaryColumn.max
    },
    rangeBoundriesUnique () {
      return [...this.config.rangeBoundries]
    }
  },

  watch: {
    /*
    * Reset data if ranges were not set by user
    * and data exists
    */
    selectedAuxilaryColumn: {
      deep: true,
      handler (val, oldVal) {
        if (
          this.editedByUser
        ) {
          return
        }
        if (val && (!this.config.ranges || !this.config.ranges.length) && (!this.config.rangeBoundries || !this.config.rangeBoundries.length)) {
          this.resetAnalysis()
        }
        if (val && oldVal && !_.isEqual(val, oldVal)) {
          this.resetAnalysis()
        }
      }
    },
    /*
    * Reset data if ranges were not set by user
    * or change chart label
    */
    scoringType (val, oldVal) {
      if (
        SATISFACTION_SCORING_TYPES.indexOf(val) > -1 &&
        !this.selectedAuxilaryColumn
      ) {
        this.globalResults = null
        this.resultsPerRange = null
        return
      }
      /*
      * define hard reset if charts with single responsibility are in use
      * or user didn't edit the chart
      */
      if (val === oldVal) {
        return
      }

      if (
        SATISFACTION_SCORING_TYPES.indexOf(oldVal) > -1 &&
        SATISFACTION_SCORING_TYPES.indexOf(val) > -1 &&
        this.editedByUser
      ) {
        this.handleScoringTypeChange()
      } else {
        this.resetAnalysis()
      }
    },

    async rangeBoundriesUnique (val, oldVal) {
      if (_.isEqual(val, oldVal)) {
        return
      }
      if (this.config.type === SCORING_TYPE_AVERAGE) {
        this.fixRangeLabels()
      }

      if (
        typeof this.externalResults === 'undefined'
      ) {
        this.remoteResults.loading = true

        const results = await this.getRemoteResults()
        this.parseRawResultsData(results?.data)
      }
    }
  },

  created () {
    /*
    * define init for charts with single responsibility
    * and for satisfaction charts of rangeBoundries exists and full
    */
    if (
      UNIQUE_SCORING_TYPES.indexOf(this.scoringType) > -1 &&
      !this.config.ranges.length
    ) {
      this.resetAnalysis()
    } else if (
      SATISFACTION_SCORING_TYPES.indexOf(this.scoringType) > -1 &&
      !this.config.rangeBoundries.length
    ) {
      this.resetAnalysis()
    }
  },
  /*
  * Prepare top level state for next use
  */
  beforeDestroy () {
    this.$emit('editedByUser', false)
  },
  methods: {
    /**
     * Default ranges for score type
     * @return {Array} Array of pairs label text-color to render on chart
    */
    generateDefaultRanges (type) {
      /*
      * define usage of ranges model for coloring and labeling of items
      */
      if (
        UNIQUE_SCORING_TYPES.indexOf(type) > -1
      ) {
        /*
        * define usage of ranges for sentiment scoring
        */
        return [
          {
            label: this.$t(`type.negative`),
            color: this.colorToRGBA(this.$color.sentiment.negative)
          },
          {
            label: this.$t(`type.neutral`),
            color: this.colorToRGBA(this.$color.sentiment.neutral)
          },
          {
            label: this.$t(`type.positive`),
            color: this.colorToRGBA(this.$color.sentiment.positive)
          }
        ]
      }

      if (!this.selectedAuxilaryColumn) {
        return []
      }

      switch (type) {
        case SCORING_TYPE_CSAT:
          return [
            {
              label: this.$t(`type.${CSAT_TYPE_DETRACTOR}`),
              color: this.colorToRGBA(this.$color.sentiment.negative)
            },
            {
              label: this.$t(`type.${CSAT_TYPE_PASSIVE}`),
              color: this.colorToRGBA(this.$color.sentiment.neutral)
            },
            {
              label: this.$t(`type.${CSAT_TYPE_PROMOTER}`),
              color: this.colorToRGBA(this.$color.sentiment.positive)
            }
          ]
        case SCORING_TYPE_NPS:
          return [
            {
              label: this.$t(`type.${NPS_TYPE_DETRACTOR}`),
              color: this.colorToRGBA(this.$color.sentiment.negative)
            },
            {
              label: this.$t(`type.${NPS_TYPE_PASSIVE}`),
              color: this.colorToRGBA(this.$color.sentiment.neutral)
            },
            {
              label: this.$t(`type.${NPS_TYPE_PROMOTER}`),
              color: this.colorToRGBA(this.$color.sentiment.positive)
            }
          ]
        case SCORING_TYPE_AVERAGE: {
          const defaultBoundries = this.generateDefaultBoundries(type)
          const labels = this.labelsByBoundaries(defaultBoundries)
          const colors = [this.$color.sentiment.negative, this.$color.sentiment.neutral, this.$color.sentiment.positive]
          return defaultBoundries.map((boundary, index) => ({
            label: labels[index],
            color: this.colorToRGBA(colors[Math.min(2, index)])
          }))
        }
        default:
          return []
      }
    },

    /**
     * Default boundries for score type
     * Use predifined values for NPS/CSAT
     * and calculate for AVG
     * @return {Array}
    */
    generateDefaultBoundries (type) {
      if (
        this.scoringType === SCORING_TYPE_CSAT
      ) {
        return [2, 3, 5]
      } else if (
        this.scoringType === SCORING_TYPE_AVERAGE
      ) {
        const step = (this.sliderMaxValue - this.sliderMinValue) / 3
        return [
          _.ceil(this.sliderMinValue + step),
          _.ceil(this.sliderMinValue + step * 2),
          this.sliderMaxValue
        ]
      } else if (
        this.scoringType === SCORING_TYPE_NPS &&
        this.selectedAuxilaryColumn.semantic_type === 'NPS_100'
      ) {
        return [60, 80, 100]
      } else if (
        this.scoringType === SCORING_TYPE_NPS
      ) {
        return [6, 8, 10]
      }
    },

    /**
     * Default settings for score type
     * @return {Object}
    */
    getDefaultSettingsForScoringType (type = this.scoringType) {
      return {
        scoringMethodLabel: type,
        ranges: this.generateDefaultRanges(type),
        rangeBoundries: this.generateDefaultBoundries(type)
      }
    },
    /**
     * Reset the scoring type
     */
    handleScoringTypeChange () {
      if (this.selectedAuxilaryColumn) {
        switch (this.scoringType) {
          case SCORING_TYPE_CSAT:
            this.config.scoringMethodLabel = SCORING_TYPE_CSAT
            break
          case SCORING_TYPE_AVERAGE:
            this.config.scoringMethodLabel = SCORING_TYPE_AVERAGE
            break
          default:
            this.config.scoringMethodLabel = SCORING_TYPE_NPS
            break
        }
      }
    },
    /**
    * Reset the analysis to the selected type
    * Defines some variables for specific use
    */
    resetAnalysis () {
      if (
        UNIQUE_SCORING_TYPES.indexOf(this.scoringType) > -1
      ) {
        /*
        * define/clear necessary properties for charts with single responsibility
        * here is a case when it's update not a first render
        */
        const rangesData = this.generateDefaultRanges(this.scoringType)
        this.$set(this.config, 'ranges', rangesData)
      } else if (
        this.selectedAuxilaryColumn
      ) {
        /*
        * define necessary properties for NPS/CSAT/AVG charts
        */
        const { scoringMethodLabel, ranges, rangeBoundries } = this.getDefaultSettingsForScoringType()
        this.$set(this.config, 'scoringMethodLabel', scoringMethodLabel)
        this.$set(this.config, 'ranges', ranges)
        this.$set(this.config, 'rangeBoundries', rangeBoundries)
      }
    },

    /**
     * Remove range at given index
     * @param {Number} index
    */
    removeRange (index) {
      this.$delete(this.config.rangeBoundries, index)
      this.$delete(this.config.ranges, index)
    },

    /**
     *
     */
    generateNewRange (number) {
      return {
        label: `${this.$t('score.range')} #${number + 1}`,
        color: '#296A91'
      }
    },
    /**
    * Check from template whether "add range" button is shown or not
    * Hide it for specific charts
    * @param {Number} index
    * @return {Boolean} the result if it works
    */
    isRangeButtonShown (index) {
      if (
        this.scoringType === SCORING_TYPE_SENTIMENT
      ) {
        return false
      }
      return index === this.config.ranges.length - 1
    },
    /**
     * Adds another range
     */
    addRange () {
      const lastRange = this.config.rangeBoundries[this.config.rangeBoundries.length - 1]
      this.config.ranges = [...this.config.ranges, this.generateNewRange(this.config.rangeBoundries.length)]

      if (Number(lastRange) === Number(this.selectedAuxilaryColumn.max)) {
        this.config.rangeBoundries[this.config.rangeBoundries.length - 1] = lastRange - 1
      }
      this.config.rangeBoundries = [...this.config.rangeBoundries, this.selectedAuxilaryColumn.max]
    },

    /**
     * Handler for a single click chart event
     * Calculate some data to transfer it to verbatim
     * @param  {Object} $evt
     */
    chartClick ($evt) {
      let filter
      if (
        $evt.data.name === EMPTY_RESULT
      ) {
        return
      } else if (
        $evt.componentType === 'title'
      ) {
        this.showPaneDrawer('general')
      } else if (
        this.scoringType !== SCORING_TYPE_SENTIMENT &&
        !this.selectedAuxilaryColumn
      ) {
        return
      } else {
        filter = {
          type: 'range_label',
          value: $evt.data.key,
          htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.range')}</div>:&nbsp;${this.$escapeHtml($evt.data.key)}`
        }
      }

      if (
        !this.isVerbatimEnabled
      ) {
        return
      }

      if ($evt.componentType === 'series') {
        this.$store.dispatch('verbatimDialog/show', {
          item: this.id || 'ch__new',
          filters: [filter]
        })
      }
    },

    /**
     * Converts hexadecimal color to rgba()
     * @param  {String} color
     * @return {String}
     */
    colorToRGBA (color) {
      let { r, g, b, a } = colorToRgbaObject(color)
      return `rgba(${r}, ${g}, ${b}, ${a})`
    },

    /**
     * Handles showing and properly aligning the color picker in control panel
     * @param  {Number} index index of the selected range
     * @param  {Object} $event
    */
    handleShowRangeColorPicker (index, $event) {
    // set previously picked color's active property to false
      if (this.selectedCustomColor) {
        this.showColorPicker = false
      }

      if (!this.rangeColorPickerConfig) this.rangeColorPickerConfig = {}

      this.selectedCustomColor = index
      this.rangeColorPickerConfig.offsetLeft = $event.target.offsetLeft
      this.rangeColorPickerConfig.offsetTop = $event.target.offsetTop + 5

      this.showColorPicker = true
    },

    /**
     * generate numeric labels ('x-y' or 'y') by boundaries
     * if integers are in use we set integers as labels
     * @param  {Array<Number>} boundaries
     * @return {Array<String>} labels
    */
    labelsByBoundaries (boundaries) {
      let boundaryStart = 0
      const labels = []
      boundaries.forEach((boundary, index) => {
        labels.push(
          boundaryStart === boundary ||
          (
            index > 0 &&
            boundaries[index - 1] === boundary
          )
            ? String(boundary)
            : `${boundaryStart}-${boundary}`
        )
        boundaryStart = boundary + 1
      })
      return labels
    },

    /**
     * changes ranges labels if not edited by user
    */
    fixRangeLabels () {
      const { ranges, rangeBoundries } = this.config
      // get new labels from current boundaries
      const labels = this.labelsByBoundaries(rangeBoundries)
      // map current ranges with new labels
      const newRanges = ranges.map((range, index) => {
        // change only if label is a range string
        if (/^\d+$/.test(range.label) || /^\d+-\d+$/.test(range.label) || !range.label) {
          range.label = labels[index]
        }
        return range
      })
      if (!_.isEqual(ranges, newRanges)) {
        this.$set(this.config, 'ranges', newRanges)
      }
    },

    /**
    * emits chart result per  dataset
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     dsIdx {Number}:    dataset index
    *     key   {String}:    result key from resultKeys
    */
    emitDatasetResult ({ res, dsIdx }) {
      if (
        this.scoringType === SCORING_TYPE_SENTIMENT
      ) {
        // emit data for sentiment chart
        this.$emit('result', dsIdx, res.results_global.counts_raw.per_dataset[dsIdx])
      } else {
        // emit data for the others
        const pieGaugeMatchesCount = res.results_global.counts_raw.per_dataset
          .reduce((sum, count) => sum + count, 0)
        this.$emit('result', dsIdx, pieGaugeMatchesCount)
      }
    },
    /*
    * Update variables and emit
    * if user changed range boundaries
    */
    onChangeSlider () {
      this.editedByUser = true
      this.$emit('editedByUser', this.editedByUser)
      this.previousSliderMax = this.sliderMaxValue
    },
    /*
    * Reset state by intentional action
    */
    resetSettings () {
      this.editedByUser = false
      this.$emit('editedByUser', false)
      this.resetAnalysis()
      this.previousSliderMax = null
    }
  }
}
</script>

<i18n locale="en" src="@/i18n/en/components/visualize/ChartGlobals.json" />
<i18n locale="de" src="@/i18n/de/components/visualize/ChartGlobals.json" />
<i18n locale="de" src="@/i18n/de/components/visualize/ChartScore.json" />
<i18n locale="en" src="@/i18n/en/components/visualize/ChartScore.json" />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />
