<template>
  <div class="chart-layout">
    <div class="layout-col controls-col" v-if="!hideControls">
      <slot name="control-pane" />
    </div>
    <div class="layout-col chart-col" :style="chartColStyle">
      <div
        class="chart-container dims-auto"
        ref="chart-container"
        :style="containerStyle"
        @contextmenu.prevent
      >
        <div class="chart-container-header">
          <h1 v-if="!hideControls" class="chart-container-header__title">
            <slot name="chart-title" />
          </h1>
          <div class="toolbox" v-if="!hideControls">
            <v-tooltip bottom>
              <span>{{ $t('download.title') }}</span>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="openDownloadDialog"
                  class="btn-download"
                  small
                  icon
                  color="primary"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 12C17.5128 12 17.9355 12.386 17.9933 12.8834L18 13V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V13C2 12.4477 2.44772 12 3 12C3.51284 12 3.93551 12.386 3.99327 12.8834L4 13V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V13C16 12.4477 16.4477 12 17 12ZM10 2C10.5523 2 11 2.44772 11 3V10.585L12.2929 9.29289C12.6534 8.93241 13.2206 8.90468 13.6129 9.2097L13.7071 9.29289C14.0676 9.65338 14.0953 10.2206 13.7903 10.6129L13.7071 10.7071L10.7071 13.7071C10.3466 14.0676 9.77939 14.0953 9.3871 13.7903L9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.65338 8.93241 7.22061 8.90468 7.6129 9.2097L7.70711 9.29289L9 10.585V3C9 2.44772 9.44772 2 10 2Z" fill="#0E5D88" />
                  </svg>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip
              v-if="!excelReportDisabled && !!hasVisualizationsAccess"
              bottom
            >
              <span>{{ excelReportTooltip }}</span>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    @click="$emit('downloadExcelReport')"
                    class="btn-excel-download"
                    icon color="primary"
                    small
                  >
                    <v-icon>mdi-table-arrow-down</v-icon>
                  </v-btn>
                </span>
              </template>
            </v-tooltip>
            <helptip position="bottom" :width="480" x-offset="-200" v-if="helptip">
              <span v-html="helptip" />
            </helptip>
          </div>
        </div>

        <div class="before-chart-slot" v-if="!hideMasterFilters">
          <slot name="before-chart" />
        </div>

        <div class="loading-overlay" v-if="resultsLoading">
          <v-progress-linear indeterminate height="46" rounded color="secondary" />
        </div>

        <div class="failed-overlay" v-else-if="resultsLoadingFailed">
          <v-alert type="error" text outlined prominent class="flex-center">
            <span v-html="$t('error', {'step': $t('loading.while')})" />
            <div class="spacer" />

            <v-btn color="accent" outlined @click="reloadResults">
              {{ $t('actions.retry') }}
            </v-btn>
          </v-alert>
        </div>
        <slot :chart-style="chartStyle" />
        <slot name="after-chart" />
      </div>
    </div>

    <v-dialog v-model="showDownload" @keydown.esc="toggleDownloadDialog" width="500px">
      <v-card class="chart-download-dialog d-flex">
        <v-card-title primary-title class="headline grey lighten-4">
          {{ $t('download.title') }}
        </v-card-title>
        <v-card-text class="pt-2 pb-2">
          <v-alert
            type="info"
            border="left"
            text
            class="mb-0"
          >
            <span v-html="$t('download.info_resize')" />
          </v-alert>
        </v-card-text>
        <v-card-text class="pt-2 pb-2">
          <div class="d-flex download-buttons">
            <v-text-field
              :value="config.dimensions.width"
              :label="$t('download.width')"
              type="number"
              outlined
              @input="val => {
                $emit('set-download-options-val', 'width', val)
                $emit('set-download-options-val', 'predefinedSize', null)
              }"
              class="mr-1"
            />
            <v-text-field
              :value="config.dimensions.height"
              :label="$t('download.height')"
              type="number"
              outlined
              @input="val => {
                $emit('set-download-options-val', 'height', val)
                $emit('set-download-options-val', 'predefinedSize', null)
              }"
              class="ml-1"
            />
          </div>
          <div class="text-center mb-2">
            or
          </div>
          <v-select
            :items="predefinedFormatSizesLabels"
            :label="$t('download.predefined_size')"
            :value="config.dimensions.predefinedSize"
            @input="setPredefinedSize"
            outlined
          />
        </v-card-text>
        <v-card-text class="pt-2 pb-2 grey lighten-4">
          <v-select
            :items="availableDownloadFormats"
            :label="$t('download.format')"
            :value="config.dimensions.format"
            @input="val => $emit('set-download-options-val', 'format', val)"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <credit-deduction-info :credits-required="meta.credits_open" show-info />
          <div class="d-flex justify-end w-100">
            <v-btn outlined @click="toggleDownloadDialog" class="mr-2">
              {{ $t('close') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="download"
            >
              {{ $t('download.btn_png') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import axios from 'axios'

import Vuex from 'vuex'

import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import { CHART_TYPE_SCORE, PNG, SVG } from '@/settings/constants'

export default {
  codit: true,
  name: 'ChartScaffold',

  components: {
  },

  mixins: [auxiliaryColumnsMixin],

  props: {
    datasets: { type: Array, default: () => [] },
    config: { type: Object, default: () => ({}) },
    chartOptions: { type: Object, default: () => ({}) },
    codesUnion: { type: Array, default: () => [] },
    containerStyle: { type: Object, default: () => ({}) },
    chartColStyle: { type: Object, default: () => ({}) },
    verbatimBrowser: { type: Object, default: () => ({}) },
    sumsOfWeightingColumn: { type: Array, default: () => [] },
    ready: { type: Boolean, default: false },
    initialLoadingFailed: { type: Boolean, default: false },
    resultsLoading: { type: Boolean, default: false },
    resultsLoadingFailed: { type: Boolean, default: false },
    helptip: { type: String, default: '' },
    hideMasterFilters: { type: Boolean, default: false },
    hideControls: { type: Boolean, default: false },
    resultKeys: { type: Array, default: () => [] },
    availableDownloadFormats: { type: Array, default: () => [PNG, SVG] },
    type: { type: String, default: () => '' },
    name: { type: String, default: () => '' },
    aggregate: { type: Boolean, default: false },
    id: { type: [String, null], default: null },
    isDirty: { type: Boolean, default: false },
    fieldsToDeleteBeforeRequest: { type: Array, default: () => ([]) },
    meta: { type: Object, default: () => ({}) }
  },

  data () {
    return {
      isDownloading: false,
      billingFailed: false,
      containerWidth: 0,
      showDownload: false
    }
  },

  computed: {
    /**
     * Tooltip for the excel report
     */
    excelReportTooltip () {
      if (this.type === CHART_TYPE_SCORE) {
        return this.$t('download.excel_unavailable_type')
      }

      if (!this.hasVisualizationsAccess) {
        return this.$t('download.excel_unavailable_in_plan')
      }

      return this.$t('download.excel_title')
    },

    excelReportDisabled () {
      return this.type === CHART_TYPE_SCORE
    },

    /**
     * Download templates
     * @return {Array}
     */
    predefinedDownloadSizes () {
      return [
        {
          label: this.$t('predefined_sizes.full'),
          width: 1280,
          height: 720
        },
        {
          label: this.$t('predefined_sizes.half_horizontal'),
          width: 630,
          height: 720
        },
        {
          label: this.$t('predefined_sizes.half_vertical'),
          width: 1280,
          height: 360
        }
      ]
    },

    /**
     * Labels of download templates
     * @return {Array}
     */
    predefinedFormatSizesLabels () {
      return this.predefinedDownloadSizes.map(({ label }) => label)
    },

    /**
     * Styles passed into chart style slot
     * @return {Object}
     */
    chartStyle () {
      return {
        'width': '100%',
        'height': 'calc(100% - 100px)'
      }
    },

    /**
     * Checks users visualization permissions
     * @return {Boolean}
     */
    hasVisualizationsAccess () {
      return this.user?.subscription?.features?.visualizations_access
    },

    ...Vuex.mapGetters([
      'userCreditsRemaining'
    ]),

    ...Vuex.mapState(['user'])
  },

  watch: {
    containerWidth (val) { this.$emit('container-width', val) }
  },

  mounted () {
    this.$nextTick(() => {
      // legacy reasons, charts didn't use to have an option to select format.
      if (!this.config.dimensions.format) {
        this.config.dimensions.format = this.availableDownloadFormats[0]
      }
    })

    this.setContainerWidth()
  },

  methods: {
    /*
      Programatically sets container widths to chart-containers scrollwidth
    */
    setContainerWidth () {
      this.$nextTick(() => {
        this.containerWidth = this.$refs['chart-container'].scrollWidth
      })
    },

    /**
     * Reload the remotely computed results, only available if it failed before.
     */
    reloadResults () {
      this.$emit('reload-results')
    },

    /*
      Changes the download config options to passed in predefined template
      @param  {string} label of template to set
    */
    setPredefinedSize (val) {
      const { height, width, label } = _.find(this.predefinedDownloadSizes, ({ label }) => label === val)

      this.$emit('set-download-options-val', 'height', height)
      this.$emit('set-download-options-val', 'width', width)
      this.$emit('set-download-options-val', 'predefinedSize', label)
    },

    /**
     * Open the download dialog and set the user's the width + height to user's chart size
     */
    openDownloadDialog () {
      // Update the user info, to make sure the account balance is up to date
      this.$root.getUser()

      const { offsetWidth, offsetHeight } = this.$refs['chart-container']

      this.$emit('set-download-options-val', 'height', this.config.dimensions.height || offsetHeight)
      this.$emit('set-download-options-val', 'width', this.config.dimensions.width || offsetWidth)
      this.$emit('set-download-options-val', 'predefinedSize', this.config.dimensions.predefinedSize || null)

      this.toggleDownloadDialog()
    },

    /**
     * Toggle the visibility of the download dialog
    */
    toggleDownloadDialog () {
      this.showDownload = !this.showDownload
    },

    /**
     * Download the current chart as image
     */
    download () {
      // If there are unbilled answers, bill them, otherwise do nothing
      let questionsToBill = _.uniqBy(_.filter(_.map(this.datasets, ds => ds.question), q => q.credits_open), q => q.id)

      if (!questionsToBill.length) this.$emit('download')
      else {
        let requests = []
        this.isDownloading = true
        questionsToBill.forEach(question => {
          requests.push(api.post(`/api/questions/${question.id}/bill`, {}, { dontReport: [400] }).then(res => {
            if (!res.data.success) throw Error(`Billing failed, got ${JSON.stringify(res.data)}, expected success`)
          }).catch(err => {
            this.billingFailed = true
            this.$maybeRaiseAPIPromiseErr(err)
            // Re-raise the error, such that axios.all doesn't think it's a success
            throw err
          }))
        })

        axios.all(requests).then(v => {
          // Set the balance on all questions to zero
          _.each(this.datasets, ds => { ds.question.credits_open = 0 })
          // Update the account balance
          this.$root.getUser()
          this.isDownloading = false
          this.billingFailed = false
          this.$emit('download')
        }).catch(() => {
          // No need to do anything else, error has alredy been dealt with in single requests
          this.isDownloading = false
        })
      }
    }
  }
}

</script>

<style lang=scss>

.chart-download-dialog {
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;

  .v-text-field {
    border-top: 1px solid #eee;
  }

  .v-text-field__details {
    display: none;
  }

  .credit-deduction .v-alert {
    .flex-center { display: block !important; }
    .v-btn { margin: 20px 0 0; }
  }
}

.btn-download {
  top: 1px;
}

.btn-excel-download {
  margin-left: 5px !important;
  margin-right: 5px !important;
  top: 2px;
}

.chart-editor .chart-layout {
  padding-top: 12px;

  .layout-col.controls-col {
    width: 320px;
    flex: none;
    /* margin-bottom: 40px; */

    .controls-container {
      position: relative;

      &__categories {
        position: absolute;
        top: 0;
        left: 0;
      }

      &__settings {
        position: absolute;
        top: 0;
        left: 0;
      }

    }
  }
}

.slide-height-enter-active,
.slide-height-leave-active {
  transition: all 0.25s ease-in-out;
  max-height: 120px;
}
.slide-height-enter,
.slide-height-leave-to
{
  opacity: 0;
  max-height: 0px;
}
</style>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
