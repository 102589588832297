<template>
  <v-dialog v-model="showDialog"
            max-width="500"
            @keydown.esc="showDialog = false"
  >
    <v-card class="dialog-badges">
      <v-card-title class="headline grey lighten-2"
                    primary-title
                    v-text="$t('badges.browser_title')"
      />

      <v-card-text>
        <v-expansion-panels :value="focusedBadgeIdx">
          <v-expansion-panel v-for="(props, b) in BADGES" :key="b">
            <v-expansion-panel-header>
              {{ $t(`badges.${b}.title`) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <div class="description">
                <span v-html="$t(`badges.${b}.description`)" />
                <span v-if="$te(`badges.${b}.description_expanded`)" v-html="$t(`badges.${b}.description_expanded`)" />
              </div>
              <div class="details">
                <div class="details-title" v-text="$t('badges.available_levels')" />
                <ul>
                  <li v-for="level in props.maxLevel"
                      :key="`${b}-${level}`"
                      :class="{ 'user-level': userLevels[b] === level }"
                  >
                    <div class="badge">
                      <img :src="getBadgeImage(b, level)">
                      <div class="level" v-text="level" />
                    </div>
                    <div class="requires">
                      <span class="level">{{ $t('badges.level') }} {{ level }}</span>:
                      <span v-html="$t(`badges.${b}.levels.${level}`)" />
                    </div>
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { BADGES } from '@/settings/constants'

export default {
  name: 'BadgesBrowser',
  codit: true,

  props: {
    value: { type: Boolean, default: false },
    focus: { type: String, default: '' },
    userLevels: { type: Object, default: () => ({}) }
  },

  data () {
    return {
      BADGES
    }
  },

  computed: {
    /**
     * Mapping of badges to their index (order in which they're displayed)
     * @return {Object} The mapping
     */
    badges2Idx () {
      let mapping = {}
      let cnt = 0
      _.each(BADGES, (props, b) => { mapping[b] = cnt++ })
      return mapping
    },

    /**
     * The currently focused badge index, or -1 if no focus is provided
     * @return {Number}
     */
    focusedBadgeIdx () {
      if (this.focus in this.badges2Idx) return this.badges2Idx[this.focus]
      else return -1
    },

    /**
     * Proxy for showing the dialog
     */
    showDialog: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  },

  methods: {
    /**
     * Get the image for a badge and level
     * @param  {String} badge The badge identifier
     * @param  {Number} level The level of the badge
     * @return {String}       Path to relevant image
     */
    getBadgeImage (badge, level) {
      return require(`../assets/badges/${badge}_${Math.max(level, 1)}.svg`)
    }
  }
}

</script>

<style lang=scss>

.badge {
  position: relative;
  .level {
    position: absolute;
    top: 13%;
    right: 3%;
    font-size: 10px;
    font-weight: bold;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    border-radius: 100%;
    background: #424242;
    color: #FFF;
  }

  &.level0 {
    img {
      opacity: 0.5;
      filter: grayscale(100%);
    }
    &:before {
      content: ' ';
      pointer-events: none;
      left: 0;
      top: 50%;
      width: 100%;
      height: 20px;
      margin-top: -10px;
      background: #FFF;
      z-index: 1;
      transform: rotate(35deg);
      opacity: 0.5;
      position: absolute;
    }
  }
}

.dialog-badges {
  .description {
    font-style: italic;
  }

  .badge {
    width: 60px;
    height: 60px;
  }

  .v-expansion-panel-header--active {
    color: var(--v-primary-base);
    font-weight: bold;
  }

  .details {
    .details-title {
      font-weight: bold;
      margin-top: 12px;
      padding-top: 8px;
      border-top: 1px solid #DDD;
    }

    ul {
      list-style: none;
      margin: 0 auto;
      padding: 12px 0 0;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .badge {
          flex: 0 0 60px;
        }
        .requires {
          margin-left: 12px;
          color: #666;
          .level {
            font-weight: bold;
          }
        }
        &.user-level .requires .level {
          border-bottom: 1px dashed var(--v-primary-base);
          color: var(--v-primary-base);
        }
      }
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/Badges.json' />
<i18n locale='de' src='@/i18n/de/components/Badges.json' />