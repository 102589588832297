<template>
  <div v-if="chart.type === 'P_TXT'" class="p-container w-100 h-100 d-flex flex-column justify-center">
    <div
      class="title"
      :style="{
        color: chart.config.colorTitle,
        'text-align': chart.config.alignTitle
      }"
    >
      {{ chart.config.title }}
    </div>
    <div
      class="markdown-wrapper"
      :style="{
        color: chart.config.colorText,
        'text-align': chart.config.alignText
      }"
    >
      <MarkdownRenderer :text="chart.config.text" />
    </div>
  </div>
</template>

<script>
import MarkdownRenderer from './MarkdownRenderer.vue'

export default {
  components: {
    MarkdownRenderer
  },
  props: {
    chart: { type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss" scoped>
.markdown-wrapper {
  max-height: calc(100% - 32px);
}
</style>