var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('loading',{staticClass:"h-100 position-relative",attrs:{"is-loading":true,"title":"","tagline":"","dont-animate-entry":""}}):(!_vm.loading && !_vm.templates.length && !_vm.failed)?_c('empty-state-v2',{attrs:{"title":_vm.$t('no_templates'),"text":" "}}):(_vm.failed && !_vm.loading)?_c('error-state',{attrs:{"title":"Data fetching error","text":"There was an error fetching templates, please try again.","action":_vm.$t('retry'),"action-click":() => _vm.getTemplates()}}):_c('div',{staticClass:"coding__column__options coding__column__options--topic-search d-flex",class:!_vm.selectedTemplate && 'mb-0'},[_c('div',{staticClass:"mr-4"},[_c('label',{staticClass:"font-weight-medium text-sm text-label"},[_vm._v("\n        Language\n      ")]),_vm._v(" "),_c('v-select',{staticClass:"search-bar__search search-bar__search--large text-sm",attrs:{"items":_vm.templateLanguageOptions,"placeholder":"Select language","hide-details":"","dense":"","height":40,"outlined":"","menu-props":{
          'offset-y': true,
          'ripple': false
        }},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1),_vm._v(" "),_c('div',[_c('label',{staticClass:"font-weight-medium text-sm text-label"},[_vm._v("\n        Template\n      ")]),_vm._v(" "),_c('v-select',{staticClass:"search-bar__search search-bar__search--large text-sm",attrs:{"items":_vm.templateOptions,"no-data-text":_vm.$t('question_steps.choose_language'),"placeholder":"Select template","hide-details":"","dense":"","height":40,"outlined":"","menu-props":{
          'offset-y': true,
          'ripple': false
        }},on:{"input":_vm.handleTemplateSelect},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}})],1)]),_vm._v(" "),(_vm.selectedTemplate)?_c('topics-editor',{attrs:{"active":true,"editable":false,"id":_vm.id,"store-name":"wizard","history":"","wizard-view":"","template-select":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }