<template>
  <div class="manage-integrations">
    <div class="d-flex align-center justify-space-between w-100">
      <div>
        <div class="manage-integrations__title title">
          {{ $t('title') }}
        </div>
        <div class="manage-integrations__subtitle mt-1">
          {{ $t('subtitle') }}
        </div>
      </div>
      <!-- <v-select
        :items="integrationTypes"
        :label="$t('add_account')"
        :value="editor.mockValue"
        @change="handleOpenEditor"
        primary
        :disabled="loading"
        class="manage-integrations__select"
        :class="{'manage-integrations__select--disabled': loading}"
        prepend-inner-icon="mdi-plus-circle-outline"
        append-icon=""
        small
        hide-details
        solo
        dense
      /> -->

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            dense
            hide-details
            primary
            v-bind="attrs"
            :disabled="loading"
            color="primary"
            class="manage-integrations__button"
          >
            <v-icon :size="18" class="mr-2">
              mdi-plus-circle-outline
            </v-icon>
            {{ $t('add_account') }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in services"
            :key="index"
          >
            <v-list-item-title @click="handleMenuItemClick(item)">
              <span class="text--pointer">
                {{ item }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="manage-integrations__separator w-100" />

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      class="manage-integrations__loading w-100"
    />
    <div
      v-else-if="!hasAccountsConnected"
      class="manage-integrations__empty w-100 text-center"
    >
      {{ $t('empty') }}
    </div>
    <div v-else>
      <v-alert
        v-if="failed"
        prominent
        type="error"
        outlined
        text
        border="left"
        class="flex-center"
      >
        {{ failed }}
      </v-alert>
      <div class="d-flex flex-wrap">
        <div
          v-for="(integration, idx) in allIntegrations"
          :key="idx"
          class="manage-integrations__integration"
        >
          <div class="d-flex align-center justify-space-between">
            <img v-if="integration.integrationType === 'qualtrics'" class="manage-integrations__integration__logo" src="@/assets/integrations/qualtrics.svg">
            <img v-else class="manage-integrations__integration__logo" src="@/assets/integrations/brandwatch.png">
            <div class="d-flex">
              <div class="manage-integrations__integration__tag">
                {{ $t('connected') }}
              </div>
              <v-icon
                class="manage-integrations__integration__close ml-3"
                @click="handleShowDeleteConfirm(integration)"
              >
                mdi-close
              </v-icon>
            </div>
          </div>
          <div class="manage-integrations__integration__title mb-0">
            {{ integration.name }}
          </div>
          <div class="manage-integrations__integration__text text-primary mt-0" v-if="integration.integrationType === 'qualtrics'">
            {{ integration.meta.organization_id }}: {{ integration.meta.email }}
          </div>
          <div class="manage-integrations__integration__text text-primary mt-0" v-else>
            {{ integration.meta.project }}
          </div>
          <div class="manage-integrations__integration__text">
            {{ integration.integrationType === 'qualtrics' ? $t('qualtrics.summary') : $t('brandwatch.summary') }}
            <router-link :to="{ name: 'upload', query: { tab: 'integration', integrationType: integration.integrationType } }">
              {{ $t('import_page') }}.
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Qualtrics Integration editor  -->
    <v-dialog
      v-if="editor.open"
      v-model="editor.open"
      persistent
      width="500px"
      content-class="integration-editor"
    >
      <div>
        <div class="integration-editor__title w-100 d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <img src="@/assets/integrations/qualtrics.svg" height="25px">
            <!-- {{ $t('editor_title', { type: editor.open }) }} -->
          </div>
          <v-icon
            class="hover"
            @click="handleEditorClose"
            :size="20"
          >
            mdi-close
          </v-icon>
        </div>
        <div class="integration-editor__content">
          <v-text-field
            v-model="qualtricsIntegration.name"
            :label="$t(`qualtrics.name`)"
            hide-details
            outlined
            dense
          />
          <div class="d-flex align-center mt-4 mb-4 pt-3 pb-3">
            <v-text-field
              v-model="qualtricsIntegration.auth.api_key"
              :label="$t(`qualtrics.api_key`)"
              hide-details
              outlined
              dense
            />
            <helptip position="bottom" :width="250" class="ml-2">
              <span v-html="$t('qualtrics.api_key_helptip')" />
            </helptip>
          </div>
          <div class="d-flex align-center">
            <v-text-field
              v-model="qualtricsIntegration.auth.datacenter"
              :label="$t(`qualtrics.datacenter`)"
              hide-details
              outlined
              dense
            />
            <helptip position="bottom" :width="250" class="ml-2">
              <span v-html="$t('qualtrics.datacenter_helptip')" />
            </helptip>
          </div>

          <v-alert
            v-if="editor.failed"
            type="error"
            border="left"
            text
            dense
            class="mt-4 mb-0"
          >
            {{ editor.failed }}
          </v-alert>
        </div>
        <div class="integration-editor__actions d-flex justify-end">
          <div>
            <v-btn
              outlined
              @click="handleEditorClose"
              class="mr-2"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="qualtricsIntegrationDisabled"
              :loading="editor.loading"
              @click="handleIntegrationAdd()"
            >
              {{ $t('add') }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-if="modalsShown.brandwatch"
      v-model="modalsShown.brandwatch"
      persistent
      width="500px"
      content-class="integration-editor"
    >
      <BrandWatchIntegration
        @close-dialog="modalsShown.brandwatch = false"
        @save-integration="handleIntegrationAdd"
      />
    </v-dialog>

    <!-- Deleting account confirmation modal  -->
    <v-dialog
      v-if="deleteConfirm.open"
      v-model="deleteConfirm.open"
      persistent
      width="500px"
      content-class="integration-editor"
    >
      <div>
        <div class="integration-editor__title w-100 d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            {{ $t('delete.title', { type: editor.open }) }}
          </div>
          <v-icon
            class="hover"
            @click="handleDeleteConfirmClose"
            :size="20"
          >
            mdi-close
          </v-icon>
        </div>
        <div class="integration-editor__content" v-html=" $t('delete.text', { name: $escapeHtml(deleteConfirm.open.name) })" />
        <div
          v-if="deleteConfirm.failed"
          class="pl-3 pr-3"
        >
          <v-alert
            type="error"
            border="left"
            text
            dense
            class="mt-4 mb-0"
          >
            {{ deleteConfirm.failed }}
          </v-alert>
        </div>
      </div>
      <div class="integration-editor__actions d-flex justify-end">
        <div>
          <v-btn
            outlined
            @click="handleDeleteConfirmClose"
            class="mr-2"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="error"
            :loading="deleteConfirm.loading"
            @click="handleIntegrationRemove(deleteConfirm.open.id)"
            :elevation="0"
          >
            {{ $t('actions.delete') }}
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <ZapierIntegration v-model="modalsShown.zapier" />
  </div>
</template>

<script>

import Vuex from 'vuex'

import ZapierIntegration from '@/components/modals/ZapierIntegration.vue'
import BrandWatchIntegration from './BrandWatchIntegration.vue'

export default {
  name: 'ManageIntegrations',
  codit: true,

  components: {
    ZapierIntegration,
    BrandWatchIntegration
  },

  data () {
    return {
      failed: false,
      loading: false,
      integrations: {
        qualtrics: [],
        brandwatch: []
      },
      editor: {
        open: false,
        loading: false,
        failed: false,
        mockValue: undefined
      },
      deleteConfirm: {
        open: false,
        failed: false,
        loading: false
      },
      qualtricsIntegration: {
        name: '',
        auth: {
          api_key: '',
          datacenter: ''
        }
      },
      services: [
        'Qualtrics',
        'Zapier',
        'Brandwatch'
      ],
      modalsShown: {
        zapier: false,
        brandwatch: false
      }
    }
  },

  computed: {
    allIntegrations () {
      return [...this.integrations.qualtrics.map(i => ({ ...i, integrationType: 'qualtrics' })), ...this.integrations.brandwatch.map(i => ({ ...i, integrationType: 'brandwatch' }))]
    },

    hasAccountsConnected () {
      return (this.integrations.qualtrics && this.integrations.qualtrics.length > 0) || (this.integrations.brandwatch && this.integrations.brandwatch.length > 0)
    },
    /**
     * Qualtrics integration add button validation
     */
    qualtricsIntegrationDisabled () {
      // eslint-disable-next-line
      const { name, auth: { api_key, datacenter } } = this.qualtricsIntegration

      return !name.length || !api_key.length || !datacenter.length
    },

    /**
     * Supported types of integrations
     */
    integrationTypes () {
      return ['Qualtrics']
    },

    ...Vuex.mapState(['user'])
  },

  watch: {},

  created () {
    this.loadIntegrations()
  },

  methods: {
    handleMenuItemClick (model) {
      if (model === 'Qualtrics') {
        this.handleOpenEditor('Qualtrics')
      } else if (
        model === 'Zapier'
      ) {
        this.modalsShown.zapier = true
      } else if (model === 'Brandwatch') {
        this.modalsShown.brandwatch = true
      }
    },
    /**
     * Load the currently integrated accounts for the user
     */
    loadIntegrations () {
      this.loading = true
      this.failed = false
      api.get('/api/integrations/').then(res => {
        this.integrations = res.data
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.failed = this.$t('loading.failed')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Add integration
      */
    handleIntegrationAdd (integration) {
      this.editor.loading = true
      this.editor.failed = false

      const integrationToSave = integration || {
        integration: this.editor.open.toLowerCase(),
        ...this.qualtricsIntegration
      }

      api.post('/api/integrations/', integrationToSave, { dontReport: [400] })
        .then(res => {
          if ((res.data && res.data.error) || res.status === 400) {
            this.editor.failed = res.data.auth[0] || this.$t('integration_failed')
            this.editor.loading = false
            return
          }

          this.editor.loading = false
          this.handleEditorClose()
          this.loadIntegrations()
        })
        .catch(err => {
          this.editor.failed = this.$t('integration_failed')
          this.editor.loading = false
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    handleDeleteConfirmClose () {
      this.deleteConfirm.open = false
      this.deleteConfirm.failed = false
    },

    /**
     * Show deleting integration account confirmation modal
     */
    handleShowDeleteConfirm (integration) {
      this.deleteConfirm.open = integration
    },

    /**
     * Remove selected integration
     */
    handleIntegrationRemove (id) {
      this.deleteConfirm.loading = true
      this.deleteConfirm.failed = false

      api.delete(`/api/integrations/${id}`)
        .then(res => {
          this.deleteConfirm.loading = false
          this.handleDeleteConfirmClose()
          this.loadIntegrations()
        })
        .catch(err => {
          this.deleteConfirm.loading = false
          this.deleteConfirm.failed = err.response?.data?.non_field_errors || this.$t('deleting_failed')
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    /**
     * Close the editor
     */
    handleEditorClose () {
      this.editor.open = false
      this.modalsShown = {
        zapier: false,
        brandwatch: false
      }
    },

    /**
     * Open the editor
     */
    handleOpenEditor (type) {
      // reset v-select value, in order to always show '+ add account'
      this.editor.mockValue = type
      this.$nextTick(() => { this.editor.mockValue = '' })
      this.editor.open = type
    }

  }
}

</script>

<style lang=scss>
  @import '../../css/manage-integrations.scss';

  .text--pointer{
    cursor: pointer;
  }
</style>

<i18n locale='en' src='@/i18n/en/components/account/ManageIntegrations.json' />
<i18n locale='de' src='@/i18n/de/components/account/ManageIntegrations.json' />