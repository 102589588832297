export default {
  data () {
    return {
      defaultAPIFilters: []
    }
  },

  methods: {
    async initExisitng () {
      this.status.loading = true
      let res
      try {
        res = await api.get(`/api/dashboards/${this.$route.params.id}`)
      } catch (error) {
        // If it's not a public link and there's no logged in user, redirect to login page
        // This is called automatically for other pages if the csrf test fails, but as public
        // dashboards should be accessible by anonymous users, here it's not
        if (!this.isPublic && this.user.id === '') this.$root.logout(true)
        this.status.loading = false
        this.status.loadingFailed = true
        this.$maybeRaiseAPIPromiseErr(error)
        return
      }

      // Nasty Error: Outlooks advanced threat protection will replce links in mails with a
      // safelinks.protection.outlook link. When clicking such a link, a request will be made
      // by a microsoft server to our server, but *without* the correct CORS headers.
      // The response will be empty, but still have a response code 200.
      // We can safely ignore that (display err msg but not report it).
      if (res.status === 200 && !res.data) {
        this.status.loading = false
        this.status.loadingFailed = true
        return
      }

      this.$set(this, 'dashboard', res.data)

      if (res.data.default_filters) this.defaultAPIFilters = this.filtersTransformedFromQueryParams(res.data.default_filters)

      this.dashboard.elements.forEach(
        el => {
          this.addDashboardElement({
            ...el,
            chart: this.preprocessLoadedChart(el.chart)
          })
        }
      )
      this.dashboard.elements = undefined
      this.status.loading = false
      this.status.dirty = false // Clear the dirty flag again, which might have been triggered by the watcher
    },
    initFilters () {
      let filtersTransformed = null

      try {
        filtersTransformed = this.$route.query.filters ? this.filtersTransformedFromQueryParams(JSON.parse(_.cloneDeep(this.$route.query.filters))) : null
      } catch (error) {
        console.warn('Can\'t transform query filters', error)
      }

      if (
        this.$route.query.filters &&
        !filtersTransformed
      ) {
        this.$root.snackMsg(this.$t('errors.invalid_url_query'))
        this.$router.replace({
          name: 'dashboard-details',
          query: _.omit(_.cloneDeep(this.$route.query), ['filters'])
        })
      } else if (
        this.$route.query.filters &&
        filtersTransformed
      ) {
        this.dashboardFilters.filters = filtersTransformed
        this.handleFilterApply(false)
      // Special case, no query params are present but there are default filters coming from API
      } else if (this.defaultAPIFilters.length > 0) {
        this.dashboardFilters.filters = this.defaultAPIFilters
        this.handleFilterApply(false)
      }
    },
    async initFromQuery () {
      // How many charts to put in a row
      const chartsPerRow = 3
      // How much space a chart should occupy (depending on number of columns)
      const xIncr = Math.floor(10 / chartsPerRow)
      // Multiple charts are split by a colon
      this.$route.query.charts.split(',').forEach(async (id, idx) => {
        // Create a new dashboard element for each chart
        let el = {
          x: (idx % chartsPerRow) * xIncr,
          y: Math.floor(idx / chartsPerRow) * xIncr,
          width: xIncr,
          height: xIncr,
          chart: { id, type: '', typeSelection: '' },
          status: { loading: true, warning: false }
        }
        this.addDashboardElement({
          ...el,
          chart: this.preprocessLoadedChart(await this.loadChart(id))
        })
      })
    }
  }
}