/**
 * Vue.js 2.0 plugin
 *
 */

import Vue from 'vue'
import * as Sentry from '@sentry/browser'

// where the plugin is used
export function useSentry () {
  if (process.env.VUE_APP_CAPLENA_ENV !== 'local') {
    Sentry.init({
      dsn: 'https://c234c513073a4d918054e903432315cd@sentry2.caplena.com/3',
      debug: process.env.VUE_APP_CAPLENA_ENV !== 'prod',
      environment: process.env.VUE_APP_CAPLENA_ENV
    })
  }

  // save sentry root Vue instance
  Vue.prototype.$Sentry = Sentry
}