<template>
  <div>
    <alert
      v-if="
        internalValue.datasets &&
          internalValue.datasets[0] &&
          internalValue.datasets[0].status &&
          internalValue.datasets[0].status.failed
      "
      type="error"
    >
      {{ $t("error", { step: $t("loading.while") }) }}
    </alert>
    <ProjectList
      v-if="questionId === null"
      filters-internal
      archived-filter-enable
      in-dialog
      ref="projectList"
    >
      <template slot="title">
        <div style="margin-left: -16px">
          {{ $t("add_or_edit.verbatims.title_question") }}
        </div>
      </template>
      <template slot="action" slot-scope="{ question }">
        <v-btn
          outlined
          color="primary"
          @click="selectQuestionForVerbatims(question)"
        >
          {{ $t("add_or_edit.verbatims.select_question") }}
        </v-btn>
      </template>
    </ProjectList>

    <template v-else>
      <div class="verbatim-addition">
        <!-- <CreditDeductionInfo :credits-required="creditsOpen" show-info /> -->
        <v-text-field
          v-model="internalValue.name"
          maxlength="50"
          counter
          :label="$t('add_or_edit.title_label')"
          class="title"
          outlined
        />
        <div class="pb-12 mb-4">
          <v-card class="w-100 default-bs settings-drawer mb-2">
            <div
              class="d-flex align-items-center pt-4 pb-4 pl-3 pr-3 position-relative"
            >
              <div class="title capitalize">
                {{ $t("add_or_edit.filters_title") }}
              </div>
            </div>
            <div class="pl-3 pr-3 pt-0 pb-1 mb-1">
              <DatasetFilters
                :settings.sync="internalValue.datasets[0].settings"
                :filters.sync="internalValue.config.filters"
                :question="getDatasetFiltersQuestion()"
                :status="internalValue.datasets[0].status"
                @result="$set(internalValue.datasets[0], 'result', $event)"
                is-master
                is-dashboard-filter
                :id="getDatasetFiltersId()"
              />
            </div>
          </v-card>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProjectList from '@/components/ProjectList'
import DatasetFilters from '@/components/visualize/DatasetFilters'
// import CreditDeductionInfo from '@/components/CreditDeductionInfo'

import DataMixin from '@/components/customUi/mixins/data'

import Vuex from 'vuex'

import {
  DS_FILTERS_TEMPLATE,
  DS_SETTINGS_TEMPLATE
} from '@/settings/constants'

const DS_STATUS_TEMPLATE = { loading: false, failed: false }

export default {
  components: {
    ProjectList,
    DatasetFilters
    // CreditDeductionInfo
  },

  mixins: [DataMixin],

  props: {
    value: { type: Object, default: () => ({}), required: true }
  },

  data () {
    return {
      questionId: this.value?.datasets?.[0]?.question || null
    }
  },

  computed: {
    ...Vuex.mapGetters({
      metaManager: 'metaManager/overall'
    })
  },

  methods: {
    getDatasetFiltersId () {
      if (
        this.internalValue.id
      ) {
        return this.internalValue.id
      } else if (
        this.internalValue.created
      ) {
        return `ch__${+this.internalValue.created}`
      } else {
        return 'ch__new'
      }
    },

    getDatasetFiltersQuestion () {
      const chartId = this.internalValue?.id || 'ch__new'
      if (
        !this.metaManager[chartId]
      ) {
        return {}
      }
      return {
        auxiliary_column_metas: this.metaManager[chartId].auxiliary_column_metas,
        codebook: this.metaManager[chartId].topics_complete_union
      }
    },

    selectQuestionForVerbatims (question) {
      this.questionId = question
      // this.creditsOpen = question.credits_open
      // this.creditsOpenQuestions = [question.id]

      this.$set(this.internalValue, 'datasets', [{
        filters: _.cloneDeep(DS_FILTERS_TEMPLATE),
        settings: { ..._.cloneDeep(DS_SETTINGS_TEMPLATE), name: this.$t('add_or_edit.verbatims.title_dataset') },
        status: { ..._.cloneDeep(DS_STATUS_TEMPLATE), loading: true },
        question: question.id,
        answers: [],
        result: []
      }])

      this.$store.dispatch(
        'registerManager/register',
        {
          entityId: 'ch__new',
          entityData: _.cloneDeep(this.internalValue),
          settings: {
            method: 'post'
          }
        }
      )
    }
  }
}
</script>

<i18n locale="en" src="@/i18n/en/pages/Dashboard.json" />
<i18n locale="de" src="@/i18n/de/pages/Dashboard.json" />
