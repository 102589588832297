<template>
  <dialog-wrapper
    @close="closeDialog"
    :value="isShown"
    :width="700"
    class="h-100 d-flex flex-column justify-space-between"
  >
    <dialog-header>
      <template slot="title">
        {{ title }}
      </template>
      <template slot="controls">
        <div>
          <template v-if="isSummaryEnabled">
            <v-btn
              outlined
              class="mr-2 my-n2"
              v-if="isSummaryShown"
              @click="toggleSummaryDisplaying"
            >
              {{ $t('verbatim.controls.switch_to_rows') }}
            </v-btn>
            <v-btn
              v-else
              outlined
              class="mr-2 my-n2"
              @click="toggleSummaryDisplaying"
            >
              {{ $t('verbatim.controls.switch_to_summary') }}
            </v-btn>
          </template>
          <v-icon
            @click="closeDialog"
            :size="20"
          >
            mdi-close
          </v-icon>
        </div>
      </template>
    </dialog-header>

    <div style="
      max-height: calc(90vh - 126px);
      overflow: hidden;
      display: flex;
      flex-direction: column;
    "
    >
      <dialog-content
        class="pl-4 pt-3 pb-3 pr-4"
        v-if="filtersProps.filtersFromQuery.length || filtersProps.filtersFromConfig.length || filtersProps.filtersFromOpenAction.length"
      >
        <VerbatimBrowserv2Filters
          v-bind="filtersProps"
        />
      </dialog-content>
      <slot name="alert" />
      <dialog-content
        v-if="id && !isSummaryShown"
        class="d-flex w-100 h-100 overflow-hidden flex-1"
      >
        <VerbatimBrowserv2RowsViewer
          :id="id"
          :query-params="queryParams"
          :extra-query-params="extraQueryParams"
          :datasets="datasets"
          :chart-type="chartType"
          :config="config"
        />
      </dialog-content>
      <dialog-content
        v-else-if="id"
        class="d-flex w-100 h-100 overflow-hidden flex-1"
      >
        <div class="overflow-y-auto w-100">
          <VerbatimBrowserv2SummaryViewer
            :id="id"
            :query-params="queryParams"
            :extra-query-params="extraQueryParams"
          />
        </div>
      </dialog-content>
    </div>

    <dialog-actions>
      <template slot="leftSide" v-if="!isSummaryShown && !state.loadingRows">
        <div class="coding__column__options__pagination d-flex align-center">
          {{ paginationString }}
        </div>
      </template>
      <template slot="rightSide">
        <v-btn
          outlined
          @click="closeDialog"
          class="mr-2"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </dialog-actions>
  </dialog-wrapper>
</template>

<script>
import Vuex from 'vuex'

import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import VerbatimBrowserv2Filters from '@/components/VerbatimBrowserv2/components/VerbatimBrowserv2Filters.vue'
import VerbatimBrowserv2RowsViewer from '@/components/VerbatimBrowserv2/components/VerbatimBrowserv2RowsViewer'
import VerbatimBrowserv2SummaryViewer from '@/components/VerbatimBrowserv2/components/VerbatimBrowserv2SummaryViewer'

import VerbatimBrowserv2Mixin from '@/components/VerbatimBrowserv2/mixins/VerbatimBrowserv2'

import { getterWithKeyDeconstructed } from '@/utils/vuex.js'

export default {
  codit: true,
  name: 'VerbatimBrowser',

  components: {
    'dialog-wrapper': DialogWrapper,
    'dialog-header': DialogHeader,
    'dialog-content': DialogContent,
    'dialog-actions': DialogActions,
    VerbatimBrowserv2Filters,
    VerbatimBrowserv2RowsViewer,
    VerbatimBrowserv2SummaryViewer
  },

  mixins: [
    VerbatimBrowserv2Mixin
  ],

  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('verbatim.title')
      }
    }
  },

  data () {
    return {
      isShown: false,

      isSummaryShown: false
    }
  },

  computed: {
    ...Vuex.mapState('verbatimDialog', {
      id: state => state.itemShown,
      filterFromOpenAction: state => state.filters,
      datasetsToRender: state => state.datasets
    }),

    ...getterWithKeyDeconstructed('configManager/state')(function () { return this.id })({
      config: 'config',
      datasets: 'datasets',
      chartType: 'typeSelection'
    }),

    extraQueryParams () {
      return this.filterFromOpenAction.reduce((sum, { type, value }) => ({ ...sum, [type]: value }), {})
    }
  },

  watch: {
    id (val) {
      this.isShown = !!val
      if (this.isSummaryShown) this.isSummaryShown = false
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
      this.$store.dispatch('verbatimDialog/hide')
    },

    toggleSummaryDisplaying () {
      this.isSummaryShown = !this.isSummaryShown
    }
  }
}
</script>

<style lang=scss>
@import '~@/css/tooltips.scss';
</style>

<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />
<i18n locale='en' src='@/i18n/en/components/Translate.json' />
<i18n locale='de' src='@/i18n/de/components/Translate.json' />