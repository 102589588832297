<template>
  <textarea ref="input"
            class="integration__links__link__input"
            wrap="off"
            rows="1"
            :value="value"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="$emit('input', $event)"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="$emit('enter', $event)"
            @paste="handlePaste"
  />
</template>

<script>
export default {
  name: 'CIntegrationTextInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    provider: {
      type: String,
      default: undefined
    }
  },
  emits: ['input', 'enter', 'paste'],
  methods: {
    handlePaste (e) {
      e.stopPropagation()
      e.preventDefault()

      const pasteData = e.clipboardData.getData('text/plain')
      const splitRegex = this.provider !== 'google_places' ? /\r?\n|[, ]/ : /\r?\n/
      const data = pasteData.split(splitRegex).filter(Boolean).map(val => val.trim()) || []

      this.$emit('paste', { data, event: e })
    },
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>