<template>
  <div>
    <v-tabs v-model="internalValue" class="px-4">
      <v-tab
        v-for="(item, itemIndex) in sources.kindOptions"
        :key="itemIndex"
        class="text-sm mr-4 px-1 text-capitalize font-weight-medium letter-spacing-normal"
        :ripple="false"
      >
        <span :class="{
          'grey--text text--darken-4 font-weight-regular': internalValue !== itemIndex
        }"
        >
          {{ item.text }}
        </span>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import DataMixin from '@/components/customUi/mixins/data'

export default {
  mixins: [
    DataMixin
  ],
  props: {
    value: { type: Number, default: 0 },
    sources: { type: Object, default: () => {} }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/components/ExportData.json' />
<i18n locale='de' src='@/i18n/de/components/ExportData.json' />