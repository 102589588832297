<template>
  <ConfirmDialog
    :open="open"
    @cancel="$emit('cancel')"
    @confirm="$emit('confirm')"
    :loading="bulkAssignOptions.loading"
    :disabled="bulkAssignOptions.loading"
    :title="$t(`confirm_dialog.title`)"
    :confirm-text="$t('confirm_dialog.replace')"
    :cancel-text="$t('cancel')"
  >
    <div v-html="$t(`confirm_dialog.details`)" />
  </ConfirmDialog>
</template>

<script>

import Vuex from 'vuex'
import ConfirmDialog from './ConfirmDialog.vue'

export default {
  codit: true,
  name: 'ConfirmReplaceDialog',
  components: {
    ConfirmDialog
  },
  props: {
    cancel: { type: Function, default: () => {} },
    confirm: { type: Function, default: () => {} },
    open: { type: Boolean, default: false }
  },
  computed: {
    ...Vuex.mapState({
      bulkAssignOptions: state => state.coding.bulkAssignOptions
    })
  },

  methods: {
  }

}

</script>

<i18n locale="en" src="@/i18n/en/pages/Codingv2.json" />
<i18n locale="de" src="@/i18n/de/pages/Codingv2.json" />