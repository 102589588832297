<template>
  <div class="main-content wizard-page">
    <div class="step-container">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :step="stepNumber['q-type']"
            :complete="step > stepNumber['q-type']"
            class="step-q-type"
          >
            {{ $t('steps.q-type.title') }}
          </v-stepper-step>
          <v-divider />

          <template v-if="isMergableListQuestion">
            <v-stepper-step :step="stepNumber['q-merge']"
                            :complete="step > stepNumber['q-merge']"
                            class="step-q-merge"
            >
              {{ $t('steps.q-merge.title') }}
            </v-stepper-step>
            <v-divider />
          </template>

          <v-stepper-step :step="stepNumber['cb-basis']"
                          :complete="step > stepNumber['cb-basis']"
                          class="step-cb-basis"
          >
            {{ $t('steps.cb-basis.title') }}
          </v-stepper-step>
          <v-divider />

          <template v-if="isListQuestion">
            <v-stepper-step :step="stepNumber['list-autocoder']"
                            :complete="step > stepNumber['list-autocoder']"
                            class="step-list-autocoder"
            >
              {{ $t('steps.list-autocoder.title') }}
            </v-stepper-step>
            <v-divider />
          </template>

          <v-stepper-step :step="stepNumber['cb-editor']"
                          :complete="step > stepNumber['cb-editor']"
                          class="step-cb-editor"
          >
            {{ $t('steps.cb-editor.title') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content :step="stepNumber['q-type']"
                             :class="{ complete: step > stepNumber['q-type'] }"
          >
            <v-alert v-if="loadingFailed"
                     prominent
                     type="error"
                     outlined
                     text
                     border="left"
                     class="flex-center"
            >
              {{ $t('loading_failed') }}

              <div class="spacer" />

              <v-btn color="accent" outlined @click="reload">
                {{ $t('actions.retry') }}
              </v-btn>
            </v-alert>

            <v-alert v-else-if="questionIsDirty"
                     prominent
                     type="error"
                     outlined
                     text
                     border="left"
                     class="flex-center"
            >
              {{ $t('alert_question_dirty') }}
              <div class="spacer" />
            </v-alert>

            <wizard-question-type v-else @next="nextStep" :loading="loading">
              <v-progress-linear v-if="loading"
                                 slot="next-container"
                                 rounded
                                 indeterminate
              />
            </wizard-question-type>
          </v-stepper-content>

          <v-stepper-content v-if="isMergableListQuestion" :step="stepNumber['q-merge']">
            <wizard-questions-merge :active="stepNumber['q-merge'] === step"
                                    :v2-identifier="v2identifier"
                                    @next="nextStep"
                                    @back="previousStep"
            />
          </v-stepper-content>

          <v-stepper-content :step="stepNumber['cb-basis']">
            <wizard-codebook-basis v-if="!questionIsDirty"
                                   :active="stepNumber['cb-basis'] === step"
                                   @next="nextStep"
                                   @back="previousStep"
            />
          </v-stepper-content>

          <v-stepper-content v-if="isListQuestion" :step="stepNumber['list-autocoder']">
            <wizard-list-autocoder v-if="step >= stepNumber['list-autocoder']"
                                   :active="stepNumber['list-autocoder'] === step"
                                   @next="nextStep"
                                   @back="previousStep"
            />
          </v-stepper-content>

          <v-stepper-content :step="stepNumber['cb-editor']"
                             :class="{ 'active-no-overflow': stepNumber['cb-editor'] === step }"
          >
            <loading :is-loading="saving"
                     :title="$t('saving.title')"
                     :tagline="$t('saving.tagline')"
            >
              <v-alert v-if="savingFailed"
                       prominent
                       type="error"
                       outlined
                       text
                       border="left"
                       class="flex-center"
              >
                {{ $t('saving.failed') }}

                <div class="spacer" />

                <v-btn color="accent" outlined @click="finish">
                  {{ $t('actions.retry') }}
                </v-btn>
              </v-alert>

              <wizard-codebookgenerator v-else-if="!questionIsDirty"
                                        :active="stepNumber['cb-editor'] === step"
                                        @next="finish"
                                        @back="previousStep"
              />
            </loading>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import axios from 'axios'

import answerFilters from '@/mixins/answerFilters'
import translateMixin from '@/mixins/translation'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import colorPalettes from '@/mixins/colorPalettes'
import ListAutocoderMixin from '@/mixins/listAutocoder'
import demoDatasetsMixin from '@/mixins/demoDatasets'

import WizardQuestionType from '@/components/wizard/QuestionType'
import WizardQuestionsMerge from '@/components/wizard/QuestionsMerge'
import WizardCodebookBasis from '@/components/wizard/CodebookBasis'
import WizardListAutocoder from '@/components/wizard/ListAutocoder'
import CodebookGenerator from '@/components/wizard/CodebookGenerator.vue'

import { QUESTION_CATEGORY_LIST } from '@/settings/constants'

const STEPS_OPEN = ['q-type', 'cb-basis', 'cb-editor']
const STEPS_LIST = ['q-type', 'q-merge', 'cb-basis', 'list-autocoder', 'cb-editor']

export default {
  codit: true,
  name: 'Wizard',
  components: {
    'wizard-question-type': WizardQuestionType,
    'wizard-questions-merge': WizardQuestionsMerge,
    'wizard-codebook-basis': WizardCodebookBasis,
    'wizard-list-autocoder': WizardListAutocoder,
    'wizard-codebookgenerator': CodebookGenerator
  },

  mixins: [colorPalettes, answerFilters, auxiliaryColumnsMixin, ListAutocoderMixin, translateMixin, demoDatasetsMixin],

  data () {
    return {
      step: 1,

      saving: false,
      savingFailed: false,
      success: false,
      retryCntGetPredictions: 0,

      auxiliaryTypes: [],

      inference: {
        initialCoding: false,
        codebook: [],
        isDirty: false,
        loading: false,
        sampleLoading: false,
        failed: false,
        nSamplesInferred: 0
      },

      destroying: false,

      v2identifier: {
        project_id: '',
        ref: '',
        refs: []
      }
    }
  },

  computed: {
    /**
     * The actual sequence of steps, depending on if the user chose list question
     * and if there are other mergeable questions in the project
     * @return {Array}  Array of strings with step names
     */
    steps () {
      let steps = this.isListQuestion ? _.clone(STEPS_LIST) : _.clone(STEPS_OPEN)
      if (this.isListQuestion && !this.isMergableListQuestion) steps.splice(steps.indexOf('q-merge'), 1)
      return steps
    },

    /**
     * If the merge step should be shown or not
     * @return {Boolean}
     */
    isMergableListQuestion () {
      return this.isListQuestion && this.project.questions.length > 1
    },

    /**
     * The numeric representation of the current step, in {1,...,nSteps}
     * @return {Number}
     */
    stepNumber () {
      return _(this.steps).mapKeys().mapValues(s => this.steps.indexOf(s) + 1).value()
    },

    /**
     * The numeric representation of the current step
     * @return {Number}
     */
    currentStepNumber () {
      return this.stepNumber[this.step]
    },

    /**
     * If we're currently in the codebook-editor step
     * @return {Boolean}
     */
    isCodebookStep () {
      return this.step === this.stepNumber['cb-editor']
    },

    /**
     * Cleans the codebook up for saving purposes (having only allowed keys left)
     * @return {Array} List of codes
     */
    saveCodebook () {
      return _.map(this.codes, c => ({ ...c, new: undefined, special: undefined }))
    },

    ...Vuex.mapState({
      user: 'user',
      loading: state => state.wizard.loading,
      loadingFailed: state => state.wizard.loadingFailed,
      question: state => state.wizard.question,
      project: state => state.wizard.project,
      questionIsDirty: state => state.wizard.questionIsDirty,
      answers: state => state.wizard.answers,
      codes: state => state.wizard.codes
    }),

    ...Vuex.mapGetters(['isListQuestion'])
  },

  watch: {
    /**
     * If the mergeable status has changed from true to false, this means there are no more questions to merge
     * the merge step will therefore become invisible. To prevent a blank screen, go back and forth again.
     */
    'isMergableListQuestion' (newVal, oldVal) {
      if (oldVal && !newVal && this.step === 2) {
        this.step = 1
        this.$nextTick(this.nextStep)
      }
    },

    isCodebookStep (now, before) {
      if (!now && before) {
        this.inference.initialCoding = false
        this.inference.nSamplesInferred = 0
      }
    }
  },

  created () {
    // Load the initial data
    this.$store.dispatch('loadData', this.$route.params.id).then(() => {
      // Set auxiliary types
      this.auxiliaryTypes = this.inferAuxiliaryTypes(this.question, this.answers)
      // Set breadcrumbs
      this.$store.commit('setBreadcrumbProps', {
        projectID: this.question.project,
        projectName: this.question.project_name,
        questionID: this.question.id,
        questionName: this.question.name
      })

      api.get(`/api/ui/projects/${this.question.project}/to-refs`).then((res) => {
        if (this.destroying) return
        this.v2identifier.project_id = res.data.project_id
        this.v2identifier.ref = res.data.questions_to_ref[this.question.id]
        this.v2identifier.refs = res.data.questions_to_ref
      })

      this.maybeAppendDemoInfoToURL()

      setTimeout(this.initTutorials, 300)

      if (this.$route.query.list_and_step_2 && !this.questionIsDirty) {
        this.$store.commit('setQuestionCategory', QUESTION_CATEGORY_LIST)
        this.step = 2
      }
    })
  },

  beforeDestroy () {
    this.destroying = true
    window.removeEventListener('beforeunload', this.beforeUnload)
  },

  mounted () {
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  updated () {
  },

  beforeRouteLeave (to, from, next) {
    let doIt = true
    // If we are beyond step 1, confirm navigating away
    if (this.step > 1 && !this.success) doIt = confirm(this.$t('confirm_navigate_away'))
    next(doIt)
  },

  methods: {
    /**
     * Start the wizard tutorials
     */
    initTutorials () {
      if (!window.userpilot) return
      if (this.destroying) return
      if (this.user.id === 'us_wpvvw') return // don't show tutorial to demo user

      const USERPILOT_WIZARD_IDS = {
        general: '1589537141sOfh2934',
        open: '1589403592lXld6653',
        list: '1592315006tYfo0845',
        restart: '1594286089mCgd4715',
        gotoDemo: '1594285209kJkf2748'
      }

      // Logic for userpilot tutorials:
      // When we're on a demo question
      // * Re-trigger the general wiard modal again (that one will only run once by default)
      // * Once that's completed, launch the actual tutorial
      if (this.$route.query.demo) {
        const maybeLaunchTutorial = (event) => {
          if (event.token !== USERPILOT_WIZARD_IDS.general) return
          if (!this.$route.query.demo) return
          window.userpilot.trigger(USERPILOT_WIZARD_IDS[this.$route.query.demo])
        }

        window.userpilot.once('completed', maybeLaunchTutorial)
        window.userpilot.once('dismissed', maybeLaunchTutorial)

        window.userpilot.trigger(USERPILOT_WIZARD_IDS.general)
        this.$store.commit('setPageTutorialID', USERPILOT_WIZARD_IDS.restart)
        // The tutorial link is another plain modal, saying to reload the page if you
        // want to restart the tutorial
      // If we're not on a demo question, the tutorial link is just a modal saying to open
      // a demo question
      } else this.$store.commit('setPageTutorialID', USERPILOT_WIZARD_IDS.gotoDemo)
    },

    /** ************************ NAVIGATION ************************ **/

    /**
     * Go to the next step
     */
    nextStep () {
      this.step += 1
    },

    /**
     * Go to the previous step
     */
    previousStep () {
      this.step -= 1
      if (this.step === 1) {
        this.$router.replace({
          name: 'question-wizard-v2',
          params: {
            id: this.v2identifier.project_id,
            ref: this.v2identifier.ref
          }
        })
      }
    },

    /**
     * Callback before navigating to another web page
     * If we are beyond step 1, make the user confirm the navigation
     * @param  {Event} $event   The navigation event
     */
    beforeUnload ($event) {
      if (this.step > 1) $event.returnValue = true
    },

    /**
     * Reload the current route
     * @return {[type]} [description]
     */
    reload () {
      this.$router.go()
    },

    /** ************************ SAVING ************************ **/

    /**
     * Complete the wizard
     * If inference hasn't run, run it now. Then save.
     */
    finish () {
      this.saving = true
      this.save()
    },

    /**
     * Save the resulting codebook as well as any changes that have been made during the wizard
     */
    save () {
      let saveKeys = ['inherits_from', 'model', 'question_category']
      let saveParams = {
        ..._.pick(this.question, saveKeys),
        show_sentiment: !this.isListQuestion
      }
      let requests = [api.patch(`/api/questions/${this.question.id}`, saveParams)]

      let v2codebook = this.saveCodebook.map(code => ({
        id: null,
        color: code.color,
        label: code.label,
        category: code.category,
        keywords: code.keywords,
        description: code.description,
        sentiment_neutral: {
          code: code.id
        }
      }))

      axios.all(requests).then(() => {
        api.patch(`/api/ui/projects/${this.v2identifier.project_id}/${this.v2identifier.ref}/codebook`, { topics: v2codebook }).then(() => {
          this.success = true
          this.goToCockpit()
        }).catch(err => {
          this.saving = false
          this.savingFailed = true
          this.$maybeRaiseAPIPromiseErr(err)
        })
      }).catch(err => {
        this.saving = false
        this.savingFailed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Go to the cockpit
     */
    goToCockpit () {
      this.$router.push({
        name: 'question-cockpit',
        params: { id: this.question.id }
      })
    }
  }
}

</script>

<style lang=scss>

.wizard-page {
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin: -12px!important;

  .step-container {
    flex: 1;
    height: 100%;

    .v-stepper {
      box-shadow: none;
      min-height: 100%;
      height: 100%;

      .v-alert {
        span > span { font-weight: bold }
      }
    }

    .v-stepper__step {
      padding: 22px 24px;
    }

    .v-stepper__header {
      height: 67px;
    }

    .v-stepper__content {
      padding-top: 12px;
    }

    .v-stepper__items {
      min-height: 100%;
    }

    .v-stepper__content {
    }

    .v-stepper__content.active-no-overflow .v-stepper__wrapper {
      overflow: visible!important;
    }
  }

  .code-chip-container:not(:hover):not(.selected) .code-chip {
    background: $col-ans-bg;
  }

  .btn-next-container {
    display: flex;
    margin-top: 24px;
    .v-btn { margin-right: 12px; }
  }

  @keyframes expander-pulsate {
    from {
      border-color: var(--v-primary-base);
      color: var(--v-primary-base);
    }
    to {
      border-color: var(--v-green-base);
      color: var(--v-green-base);
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Wizard.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizard.json' />