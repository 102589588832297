<template>
  <div
    class="coding coding-v2"
    :class="wideScreenView && 'coding--wide'"
  >
    <credit-deduction-info :credits-required="codingColumn.credits_open" />

    <div class="notification-container">
      <notification
        v-if="isGroupingDuplicates === false"
        :title="$t('grouping_duplicates.title')"
        :text="$t('grouping_duplicates.text')"
        closeable
      />
      <notification
        v-if="bulkAssignOptions.failed"
        type="error"
        :title="$t('bulk_assign.failed.title')"
        :text="$t('bulk_assign.failed.text')"
        closeable
      />
      <notification
        v-if="bulkAssignOptions.failedDeletion"
        type="error"
        :title="$t('bulk_assign.failed.title_delete')"
        :text="$t('bulk_assign.failed.text_delete')"
        closeable
      />
      <notification
        v-if="mergeFailed"
        type="error"
        :title="$t('merge_failure.title')"
        :text="$t('merge_failure.text')"
        closeable
      />
      <notification
        v-if="isListColumn"
        type="info"
        :title="$t('alert_not_codable_list_title')"
        :text="$t('alert_not_codable_list')"
        :max-width="'400px'"
        closeable
      />
      <div
        v-if="!editable && readOnlyNotificationShown"
        class="notification notification--warning"
        style="max-width: 400px;"
      >
        <template v-if="['multiple_users', 'takeover_by'].indexOf(sessionIssue.type) !== -1">
          <div
            class="notification__close"
            @click="readOnlyNotificationShown = false"
          >
            <v-icon
              class="hover"
              :size="20"
            >
              mdi-close
            </v-icon>
          </div>
          <div class="notification__icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.9999 6.66667V10M9.9999 13.3333H10.0082M8.5749 2.38334L1.51656 14.1667C1.37104 14.4187 1.29403 14.7044 1.29322 14.9954C1.2924 15.2865 1.3678 15.5726 1.51192 15.8254C1.65603 16.0783 1.86383 16.289 2.11465 16.4366C2.36547 16.5841 2.65056 16.6635 2.94156 16.6667H17.0582C17.3492 16.6635 17.6343 16.5841 17.8851 16.4366C18.136 16.289 18.3438 16.0783 18.4879 15.8254C18.632 15.5726 18.7074 15.2865 18.7066 14.9954C18.7058 14.7044 18.6288 14.4187 18.4832 14.1667L11.4249 2.38334C11.2763 2.13843 11.0672 1.93594 10.8176 1.79541C10.568 1.65488 10.2863 1.58105 9.9999 1.58105C9.71345 1.58105 9.43184 1.65488 9.18223 1.79541C8.93263 1.93594 8.72345 2.13843 8.5749 2.38334Z" stroke="#DC6803" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>
            <div class="notification__title">
              {{ $t('read_only') }}
            </div>
            <div class="notification__text">
              {{ $t('alert_not_editable_other_user') }}
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="notification__close"
            @click="readOnlyNotificationShown = false"
          >
            <v-icon
              class="hover"
              :size="20"
            >
              mdi-close
            </v-icon>
          </div>
          <div class="notification__icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.9999 6.66667V10M9.9999 13.3333H10.0082M8.5749 2.38334L1.51656 14.1667C1.37104 14.4187 1.29403 14.7044 1.29322 14.9954C1.2924 15.2865 1.3678 15.5726 1.51192 15.8254C1.65603 16.0783 1.86383 16.289 2.11465 16.4366C2.36547 16.5841 2.65056 16.6635 2.94156 16.6667H17.0582C17.3492 16.6635 17.6343 16.5841 17.8851 16.4366C18.136 16.289 18.3438 16.0783 18.4879 15.8254C18.632 15.5726 18.7074 15.2865 18.7066 14.9954C18.7058 14.7044 18.6288 14.4187 18.4832 14.1667L11.4249 2.38334C11.2763 2.13843 11.0672 1.93594 10.8176 1.79541C10.568 1.65488 10.2863 1.58105 9.9999 1.58105C9.71345 1.58105 9.43184 1.65488 9.18223 1.79541C8.93263 1.93594 8.72345 2.13843 8.5749 2.38334Z" stroke="#DC6803" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>
            <div class="notification__title mb-1">
              {{ $t('alert_not_editable_permissions') }}
            </div>
            <div class="notification__text" v-html="$t('request_access', {
              root_name: $escapeHtml(user.organization.root_user_name),
              root_email: user.organization.root_user_email,
              owner_name: $escapeHtml(project.owner_name),
              owner_email: project.owner_email
            })
            "
            />
          </div>
        </template>
      </div>
    </div>
    <div class="d-flex align-center justify-space-between coding__header">
      <div class="d-flex align-center">
        <div>
          <div class="coding__title">
            {{ project.name }}
          </div>
          <div class="coding__subtitle">
            {{ codingColumn.name }}
          </div>
        </div>
        <div class="coding__stats d-flex align-center justify-space-between">
          <div class="d-flex align-center ,r">
            <helptip width="auto" class="mr-2">
              <template slot="anchor-icon">
                <div
                  class="coding__stats__model-score mr-0"
                  :class="(!modelScore || !(modelScore >= 0)) ? 'coding__stats__model-score--undefined' : 'hover'"
                  @click="handleModelScoreClick"
                >
                  <svg v-if="modelScore && modelScore >= 0 && !codingUpdateETA" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66604 12.487L5.06917 13.9137C4.71839 14.1064 4.28453 13.9655 4.10012 13.5989C4.02669 13.453 4.00135 13.2858 4.02802 13.1232L4.52398 10.1014C4.5639 9.85816 4.48673 9.60995 4.31761 9.43768L2.2167 7.29762C1.93291 7.00855 1.92711 6.5338 2.20373 6.23725C2.31388 6.11915 2.45821 6.0423 2.61438 6.01859L5.51777 5.57771C5.75149 5.54222 5.95353 5.38882 6.05805 5.16751L7.35649 2.41816C7.53188 2.04679 7.96215 1.89432 8.31752 2.0776C8.45903 2.15058 8.57358 2.27028 8.64342 2.41816L9.94185 5.16751C10.0464 5.38882 10.2484 5.54222 10.4821 5.57771L13.3855 6.01859C13.7777 6.07814 14.0494 6.45865 13.9924 6.86848C13.9698 7.03168 13.8962 7.18251 13.7832 7.29762L11.6823 9.43768C11.5132 9.60995 11.436 9.85816 11.4759 10.1014L11.9719 13.1232C12.0389 13.5314 11.7765 13.919 11.386 13.9891C11.2304 14.0169 11.0704 13.9905 10.9307 13.9137L8.33386 12.487C8.12482 12.3722 7.87509 12.3722 7.66604 12.487Z" fill="#F7F7F7" />
                  </svg>
                  <svg v-else-if="codingUpdateETA" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9945 3.85074C11.9182 2.81588 11.0544 2 10 2H6L5.85074 2.00549C4.81588 2.08183 4 2.94564 4 4V6L4.00772 6.12414C4.04351 6.41043 4.20188 6.66977 4.4453 6.83205L6.196 8L4.4453 9.16795C4.1671 9.35342 4 9.66565 4 10V12C4 13.1046 4.89543 14 6 14H10C11.1046 14 12 13.1046 12 12V10C12 9.66565 11.8329 9.35342 11.5547 9.16795L9.803 8L11.5547 6.83205L11.6537 6.75676C11.8721 6.56818 12 6.29256 12 6V4L11.9945 3.85074ZM6 10.536L8 9.203L10 10.536V12H6V10.536Z" fill="#F7F7F7" />
                  </svg>
                  <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 7C13.5523 7 14 7.44772 14 8C14 8.55228 13.5523 9 13 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H13Z" fill="#F7F7F7" />
                  </svg>
                </div>
              </template>
              <div>
                <div v-if="stats.model_last_update">
                  {{ $t('last_updated') }}: {{ $options.filters.datetimeverbal(stats.model_last_update) }}
                </div>
                <div v-else-if="!modelScore || modelScore < 0" v-html="$t('model_score_unavailable_explanation')" />
                <div v-else-if="modelScore && modelScore >= 0 && !codingUpdateETA" v-html="$t('learn_more', { link: 'https://caplena.com/docs/knowledge-base/b2f8d57b8eb95-fine-tuning-view#ai-score' })" />
              </div>
            </helptip>
            <div v-if="modelScore && modelScore >= 0 && !codingUpdateETA">
              <div class="coding__stats__name">
                {{ $t('score') }} {{ modelScore }}
              </div>
              <div class="coding__stats__stat">
                {{ $t('until_update') }} <b>{{ changesRemainingUntilRetraining }}</b>
              </div>
            </div>
            <div v-else-if="codingUpdateETA">
              <div class="coding__stats__name">
                {{ $t('arrives') }} {{ codingUpdateETA }}
              </div>
              <div class="coding__stats__stat">
                {{ $t('until_update') }} <b>{{ changesRemainingUntilRetraining }}</b>
              </div>
            </div>
            <div v-else>
              <div class="coding__stats__name">
                {{ $t('score_undefined') }}
              </div>
              <div class="coding__stats__stat">
                {{ $t('until_update') }} <b>{{ changesRemainingUntilRetraining }}</b>
              </div>
            </div>
            <div>
              <div class="coding__stats__name">
                {{ $t('info.created_label') }}
              </div>
              <div class="coding__stats__stat">
                {{ $t('info.created', { created: $options.filters.dateverbal(project.created), owner: project.owner_name }) }}
              </div>
            </div>
            <div>
              <div class="coding__stats__name">
                {{ $t('info.type') }}
              </div>
              <div class="coding__stats__stat">
                {{ isListColumn ? $t('semiopen') : $t('fullyopen') }}
              </div>
            </div>
            <div>
              <div class="coding__stats__name">
                {{ $t('info.edited_label') }}
              </div>
              <div class="coding__stats__stat">
                {{ $options.filters.dateverbal(project.last_modified) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <v-tooltip
          bottom
          v-if="editable"
        >
          <span>{{ $t('focus_mode.title') }}</span>
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
              class="coding__header__keyboard coding__header__keyboard--focus mr-2"
              :class="focusMode && 'coding__header__keyboard--active'"
              @click="toggleFocusMode"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C10.5523 2 11 2.44772 11 3L11.0008 4.08309C13.5128 4.50488 15.4954 6.48757 15.917 8.99963L17 9C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L15.917 11.0004C15.4954 13.5124 13.5128 15.4951 11.0008 15.9169L11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17L9.00019 15.9171C6.48776 15.4957 4.50475 13.5128 4.08304 11.0005L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9L4.08287 9.00052C4.50422 6.4877 6.48742 4.50441 9.00019 4.08293L9 3C9 2.44772 9.44772 2 10 2ZM10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6ZM10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8Z" :fill="focusMode ? 'white' : '#3B3B3B'" />
              </svg>
            </div>
          </template>
        </v-tooltip>
        <v-tooltip bottom>
          <span>{{ $t('keyboard_shortcuts.title') }}</span>
          <template v-slot:activator="{ on }">
            <div
              class="coding__header__keyboard"
              v-on="on"
              @click="() => $store.commit('setKeyboardShortcutsDialog', true)"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 5C17.1046 5 18 5.89543 18 7L18 13C18 14.1046 17.1046 15 16 15L4 15C2.89543 15 2 14.1046 2 13L2 7C2 5.89543 2.89543 5 4 5L16 5ZM6 11H4V13H6V11ZM16 11H14V13H16V11ZM13 11L7 11V13L13 13V11ZM6 7L4 7L4 9H6V7ZM9 7H7V9H9V7ZM12 7L10 7L10 9L12 9L12 7ZM16 7L13 7L13 9H16V7Z" fill="#0E5D88" />
              </svg>
            </div>
          </template>
        </v-tooltip>
        <v-btn
          v-if="$hasPermission('projects_download', project)"
          class="coding__stats__download ml-2"
          color="primary"
          @click="openDownloadSidebar"
          data-testid="showDownloadV2Button"
        >
          {{ $t('download.btn') }}
        </v-btn>
      </div>
    </div>
    <div class="d-flex coding__workspace">
      <filters :id="'ch__new'" ref="filters">
        <row-browser
          :blocked-by-modals="blockedByModals"
          :id="'ch__new'"
          @clear-search="() => $refs.filters.initializeFilters(false)"
        />
      </filters>
      <div
        class="coding__column coding__column--codebook"
        :class="((activeCodingTab === 1 && !wideScreenView) || (wideScreenView && activeCodingTab === 0)) && 'coding__column coding__column--model-score'"
      >
        <div class="d-flex coding__column__options justify-space-between align-center pr-2 coding__column__tabs-container pt-0 pb-0">
          <v-tabs
            class="coding__column__tabs"
            v-model="activeCodingTab"
            :show-arrows="false"
            :ripple="false"
            height="53"
          >
            <v-tab
              v-for="tab in codingTabs"
              :key="tab"
              :ripple="false"
            >
              {{ tab }}
            </v-tab>
          </v-tabs>
        </div>

        <v-tabs-items v-model="activeCodingTab">
          <v-tab-item eager :transition="false">
            <topics-editor
              store-name="coding"
              :id="'ch__new'"
              :active="true"
              :editable="editable"
              coding-view
              :expandable="true"
              @set-topics-filter="filterByTopic"
            />
          </v-tab-item>
          <v-tab-item eager class="model-score" :transition="false">
            <model-score-visualizer />
          </v-tab-item>
          <v-tab-item :transition="false">
            <coding-chart />
          </v-tab-item>
          <v-tab-item eager :transition="false">
            <coding-statistics />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <coding-drawer
      :blocked-by-modals="blockedByModals"
      :id="'ch__new'"
    />

    <focus-mode
      :blocked-by-modals="blockedByModals"
      :id="'ch__new'"
    />

    <modal
      :visible="fullscreenTopicsMode"
      :close="() => $store.commit('setFullscreenMode', false)"
      :title="$t('topics_editor')"
      class-name="topic-fullscreen-modal"
    >
      <topics-editor
        store-name="coding"
        :id="'ch__new'"
        :active="true"
        :expandable="false"
        :editable="editable"
        coding-view
        @set-topics-filter="filterByTopic"
      />
    </modal>
    <keyboard-shortcuts />

    <connection-error />

    <question-worker-session-issue-dialog :socket="socket" @retake-session-control="retakeSessionControl" />

    <export-data-sidebar-drawer
      v-if="$route.params.id && question"
      v-model="showDownloadV2"
      :project-id="$route.params.id"
      :project-name="question.project_name"
      :ref-id="$route.params.ref"
      :question-name="question.name"
      :use-query-as-filters="true"
      :prefetch-data="true"
      data-testid="exportDataSidebarDrawer"
    />

    <!-- VERBATIM BROWSER V2 (WITH SENTIMENT) -->
    <verbatim-browser-v2-dialog />
  </div>
</template>

<script>
import Vuex from 'vuex'

import ConnectionError from '@/components/coding/ConnectionErrorv2'
import TopicsEditor from '@/components/TopicsEditor'
import RowBrowser from '@/components/RowBrowser'
import CodingDrawer from '@/components/coding/CodingDrawer'
import Filters from '@/components/coding/Filtersv2'
import CodingStatistics from '@/components/coding/CodingStatistics'
import CodingChart from '@/components/coding/CodingChart'
import ModelScoreVisualizer from '@/components/coding/ModelScoreVisualizer'
import KeyboardShortcuts from '@/components/coding/KeyboardShortcuts'
import QuestionWorkerSessionIssueDialog from '@/components/coding/QuestionWorkerSessionIssueDialog'
import ExportDataSidebarDrawer from '@/components/coding/ExportData/ExportDataSidebarDrawer.vue'
import FocusMode from '@/components/coding/FocusModeModal.vue'
import { createQueryStringFromObj } from '@/utils/filters'
import VerbatimBrowserv2Dialog from '@/components/VerbatimBrowserv2/VerbatimBrowserv2Dialog'

import colorPalettes from '@/mixins/colorPalettes'
import demoDatasetsMixin from '@/mixins/demoDatasets'
import WSProjectWorkerMixin from '@/mixins/WSProjectWorker'

import { blankChart } from '@/settings/mocks.js'
import { getterWithKeyDeconstructed } from '@/utils/vuex.js'

import { CHART_TYPE_BAR, DS_FILTERS_TEMPLATE, DS_SETTINGS_TEMPLATE } from '@/settings/constants'

const WIDE_SCREEN_THRESHOLD = 1750

export default {
  codit: true,
  name: 'CodingV2',
  components: {
    // 'download-dialog': DownloadDialog,
    'export-data-sidebar-drawer': ExportDataSidebarDrawer,
    'connection-error': ConnectionError,
    'topics-editor': TopicsEditor,
    'row-browser': RowBrowser,
    'coding-drawer': CodingDrawer,
    'verbatim-browser-v2-dialog': VerbatimBrowserv2Dialog,
    Filters,
    'coding-statistics': CodingStatistics,
    'coding-chart': CodingChart,
    'model-score-visualizer': ModelScoreVisualizer,
    'question-worker-session-issue-dialog': QuestionWorkerSessionIssueDialog,
    'keyboard-shortcuts': KeyboardShortcuts,
    'focus-mode': FocusMode
  },
  mixins: [colorPalettes, demoDatasetsMixin, WSProjectWorkerMixin],
  data () {
    return {
      showDownload: false,
      windowWidth: window.innerWidth,
      activeCodingTab: 0,
      showDownloadV2: false,
      readOnlyNotificationShown: true,
      observer: null,
      blockedByModals: false
    }
  },

  computed: {
    ...Vuex.mapGetters([
      'isNotCodableList',
      'userCreditsRemaining',
      'codingDrawerOpen',
      'isListColumn'
    ]),

    // downloadDialogResources () {
    //   return {
    //     answers: true,
    //     codebook: true,
    //     chart: { label: this.$t('download.graph'), fn: this.downloadChart }
    //   }
    // },
    /**
     * If there have been enough saving failures to show the connection issue dialog
     * @return {Boolean}
     */

    ...Vuex.mapState({
      languageOptions: 'languageOptions',
      user: 'user',
      question: state => state.coding.question.data,
      project: state => state.coding.project,
      stats: state => state.coding.stats,
      codingColumn: state => state.coding.codingColumn,
      topics: state => state.coding.topics,
      filters: state => state.coding.filters,
      isInitialized: state => state.coding.isInitialized,
      editable: state => state.coding.editable,
      sessionIssue: state => state.coding.sessionIssue,
      savingError: state => state.coding.savingError,
      modelScore: state => state.coding.modelScore,
      changesRemainingUntilRetraining: state => state.coding.changesRemainingUntilRetraining,
      codingUpdateETA: state => state.coding.codingUpdateETA,
      focusMode: state => state.coding.focusMode,
      activeRowIndex: state => state.coding.activeRowIndex,
      focusRowIndex: state => state.coding.focusRowIndex,
      bulkAssignOptions: state => state.coding.bulkAssignOptions,
      mergeFailed: state => state.coding.mergeFailed,
      fullscreenTopicsMode: state => state.coding.fullscreenTopicsMode,
      refetchAfterRowChange: state => state.coding.refetchAfterRowChange,
      isGroupingDuplicates: state => state.coding.isGroupingDuplicates,
      filtersShown: state => state.coding.filtersShown
    }),

    ...Vuex.mapGetters(['rowsPerPage']),

    ...getterWithKeyDeconstructed('verbatimManager/state')(() => 'ch__new')(['loaded']),

    wideScreenView () {
      return this.windowWidth >= WIDE_SCREEN_THRESHOLD
    },

    /**
     * Tab options based on screen width
     * @return {Array}
     */
    codingTabs () {
      const tabs = [this.$t('tabs.codes'), this.$t('tabs.model'), this.$t('tabs.chart'), this.$t('tabs.stats')]

      return tabs
    }
  },

  watch: {
    isInitialized (val) {
      // Only start question worker if the user can actually edit the project
      if (val && this.editable) this.openWs(this.project.id, this.codingColumn.ref)
    },

    'question.name' () {
      this.callRegisterManager('update')
    }
  },

  async created () {
    await this.$store.dispatch(`verbatimManager/register`, {
      entityId: 'ch__new'
    }, { root: true })

    const projectID = this.$route.params.id
    const columnRef = this.$route.params.ref

    try {
      await this.$store.dispatch('loadData', { projectID, columnRef })
    } catch (error) {
      return
    }

    await this.$store.commit('setPageTutorialID', '1661417315bJgr6913')
    await this.callRegisterManager('register')

    // select the target node
    const target = document.querySelector('#app')

    // create an observer instance
    this.observer = new MutationObserver(() => {
      if (document.querySelector('.v-overlay--active') || document.querySelector('.overlay-err') || this.filtersShown) {
        this.blockedByModals = true
      } else {
        this.$nextTick(() => {
          this.blockedByModals = false
        })
      }
    })

    // configuration of the observer:
    var config = { attributes: true, childList: true, characterData: true, subtree: true }

    // pass in the target node, as well as the observer options
    this.observer.observe(target, config)

    this.$nextTick(() => {
      window.addEventListener('resize', this.onWindowResize)
    })
  },

  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
    window.removeEventListener('resize', this.onWindowResize)
  },

  methods: {
    openDownloadSidebar () {
      setTimeout(() => { this.showDownloadV2 = true })
    },

    ...Vuex.mapMutations([]),

    onInferenceCompleted () {
      console.log('inference-complete')

      // Because the chart & model score are responsible for fetching their own data
      // they will watch the inferenceUpdater variable and refetch when it is incremented
      this.$store.commit('incrementInferenceUpdater')

      // Stats
      this.$store.dispatch('loadStats')

      if (this.codingDrawerOpen || this.focusMode) {
        // Set variable to only refetch rows after
        this.$store.commit('setRefetchAfterRowChange', true)
      } else {
        // Rows
        this.reloadRows()
      }
    },

    reloadRows () {
      _.each(this.rowsPerPage, (value, page) => {
        this.$store.dispatch(
          'verbatimManager/loadRows',
          {
            id: 'ch__new',
            scrolledPage: page,
            queryString: createQueryStringFromObj(this.$router.currentRoute.query),
            soft: true
          },
          { root: true }
        )
      })
    },

    onWindowResize () {
      this.windowWidth = window.innerWidth
    },

    handleModelScoreClick () {
      if (this.modelScore && this.modelScore >= 0 && !this.codingUpdateETA) {
        this.activeCodingTab = 1
      }
    },

    toggleFocusMode () {
      if (!this.focusMode) this.$root.snackMsg(this.$t('focus_mode.on'))

      this.$store.dispatch('toggleFocusMode')
    },

    filterByTopic (topic) {
      this.$store.commit('setTopicFilter', topic)
    },

    async callRegisterManager (action) {
      const questionID = _.find(this.$store.state.coding.project.columns, column => column.ref === this.$store.state.coding.columnRef)?.metadata?.question_id
      let entityData = _.merge(
        _.cloneDeep(blankChart),
        {
          config: {},
          datasets: [{
            settings: {
              ...DS_SETTINGS_TEMPLATE,
              name: `${this.question?.name}, ${this.question?.project_name}`
            },
            filters: DS_FILTERS_TEMPLATE,
            status: {
              ready: !this.question?.loading && !this.question?.failed
            },
            question: questionID,
            answers: [],
            result: []
          }],
          type: CHART_TYPE_BAR,
          typeSelection: CHART_TYPE_BAR
        }
      )

      await this.$store.dispatch(`registerManager/${action}`, {
        entityId: 'ch__new',
        entityData
      }, { root: true })
    }
  }
}

</script>

<style lang=scss>
  @import '~css/coding';
  @import '~css/filters';
</style>

<i18n locale='en' src='@/i18n/en/pages/Codingv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Codingv2.json' />
