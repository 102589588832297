<template>
  <div class="d-flex w-100" v-if="step < 5">
    <div class="wizard__back hover text-primary font-weight-medium" @click="handleBackClick">
      <svg class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 10.5523 17.5523 11 17 11L5.415 11L7.70711 13.2929C8.06759 13.6534 8.09532 14.2206 7.7903 14.6129L7.70711 14.7071C7.34662 15.0676 6.77939 15.0953 6.3871 14.7903L6.29289 14.7071L2.29289 10.7071L2.2515 10.6631V10.6631L2.19633 10.5953V10.5953L2.12467 10.4841V10.4841L2.07123 10.3713V10.3713L2.03585 10.266V10.266L2.00683 10.1175V10.1175L2 10V10L2.00279 9.92476V9.92476L2.02024 9.79927V9.79927L2.04974 9.68786V9.68786L2.09367 9.57678V9.57678L2.146 9.47929V9.47929L2.21279 9.38325C2.23767 9.35153 2.26443 9.32136 2.29289 9.29289L6.29289 5.29289C6.68342 4.90237 7.31658 4.90237 7.70711 5.29289C8.06759 5.65338 8.09532 6.22061 7.7903 6.6129L7.70711 6.70711L5.415 9L17 9C17.5523 9 18 9.44772 18 10Z" fill="#0E5D88" />
      </svg>
      {{ $t('back') }}
    </div>
    <Transition name="slide-fade">
      <div v-if="step === 0" class="d-flex flex-column align-center w-100 wizard__question">
        <div class="wizard__title">
          {{ $t('question_steps.text_type_title') }}
        </div>
        <div class="wizard__subtitle">
          {{ $t('question_steps.text_type_question') }}
        </div>
        <div class="d-flex">
          <v-tooltip bottom max-width="220px">
            <span>{{ $t(`question_steps.text_type_open_subtitle`) }}</span>
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="wizard__question__tile"
                @click="setProjectSetting('type', 'open')"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 11V8C7 5.23858 9.23858 3 12 3C14.419 3 16.4367 4.71776 16.9 7M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V15.8C21 14.1198 21 13.2798 20.673 12.638C20.3854 12.0735 19.9265 11.6146 19.362 11.327C18.7202 11 17.8802 11 16.2 11H7.8C6.11984 11 5.27976 11 4.63803 11.327C4.07354 11.6146 3.6146 12.0735 3.32698 12.638C3 13.2798 3 14.1198 3 15.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="wizard__question__tile__title">
                  {{ $t('question_steps.open') }}
                </div>
              </div>
            </template>
          </v-tooltip>
          <v-tooltip bottom max-width="220px">
            <span>{{ $t(`question_steps.text_type_closed_subtitle`) }}</span>
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="wizard__question__tile"
                @click="goToV1Wizard"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 11V8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8V11M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V15.8C21 14.1198 21 13.2798 20.673 12.638C20.3854 12.0735 19.9265 11.6146 19.362 11.327C18.7202 11 17.8802 11 16.2 11H7.8C6.11984 11 5.27976 11 4.63803 11.327C4.07354 11.6146 3.6146 12.0735 3.32698 12.638C3 13.2798 3 14.1198 3 15.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="wizard__question__tile__title">
                  {{ $t('question_steps.semi_open') }}
                </div>
              </div>
            </template>
          </v-tooltip>
        </div>
      </div>
    </Transition>
    <Transition name="slide-fade">
      <div v-if="step === 1" class="d-flex flex-column align-center w-100 wizard__question">
        <div class="wizard__title">
          {{ $t('question_steps.scratch_title') }}
        </div>
        <div class="wizard__subtitle">
          {{ $t('question_steps.scratch_subtitle') }}
        </div>
        <div class="d-flex">
          <div class="wizard__question__tile" @click="() => {
            setProjectSetting('import', false)
            // skip import step
            $store.commit('incrementStep')
            // skip generate question
            setProjectSetting('generate', true)
          }"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('yes') }}
            </div>
          </div>
          <div class="wizard__question__tile" @click="() => {
            setProjectSetting('import', true)
          }"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0001 2V13M22.0001 9.8V5.2C22.0001 4.07989 22.0001 3.51984 21.7821 3.09202C21.5903 2.71569 21.2844 2.40973 20.908 2.21799C20.4802 2 19.9202 2 18.8001 2H8.11806C6.65658 2 5.92584 2 5.33563 2.26743C4.81545 2.50314 4.37335 2.88242 4.06129 3.36072C3.70722 3.90339 3.59611 4.62564 3.37388 6.07012L2.8508 9.47012C2.5577 11.3753 2.41114 12.3279 2.69386 13.0691C2.94199 13.7197 3.4087 14.2637 4.01398 14.6079C4.70358 15 5.66739 15 7.59499 15H8.40005C8.96011 15 9.24013 15 9.45404 15.109C9.64221 15.2049 9.79519 15.3578 9.89106 15.546C10.0001 15.7599 10.0001 16.0399 10.0001 16.6V19.5342C10.0001 20.896 11.104 22 12.4659 22C12.7907 22 13.0851 21.8087 13.217 21.5119L16.5778 13.9502C16.7306 13.6062 16.807 13.4343 16.9278 13.3082C17.0346 13.1967 17.1658 13.1115 17.311 13.0592C17.4753 13 17.6635 13 18.0398 13H18.8001C19.9202 13 20.4802 13 20.908 12.782C21.2844 12.5903 21.5903 12.2843 21.7821 11.908C22.0001 11.4802 22.0001 10.9201 22.0001 9.8Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('no') }}
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="slide-fade">
      <div v-if="step === 2" class="d-flex flex-column align-center w-100 wizard__question">
        <div class="wizard__title">
          {{ $t('question_steps.import_question') }}
        </div>
        <div class="wizard__subtitle">
          {{ $t('question_steps.import_question_subtitle') }}
        </div>
        <div class="d-flex">
          <div class="wizard__question__tile" @click="() => setInheritModal('project')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 2.0028C9.82495 2.01194 9.4197 2.05103 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8.05103 3.4197 8.01194 3.82495 8.0028 4.5M19.5 2.0028C20.1751 2.01194 20.5803 2.05103 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C21.949 3.4197 21.9881 3.82494 21.9972 4.49999M21.9972 13.5C21.9881 14.175 21.949 14.5803 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.5803 15.949 20.1751 15.9881 19.5 15.9972M22 7.99999V9.99999M14.0001 2H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('question_steps.other_project') }}
            </div>
          </div>
          <div class="wizard__question__tile" @click="() => setInheritModal('template')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 9H21M3 15H21M12 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('question_steps.template') }}
            </div>
          </div>
          <file-inherit />
        </div>
      </div>
    </Transition>
    <Transition name="slide-fade">
      <div v-if="step === 3" class="d-flex flex-column align-center w-100 wizard__question">
        <div class="wizard__title">
          {{ $t('question_steps.topic_generation_question') }}
        </div>
        <div class="wizard__subtitle">
          {{ $t('question_steps.topic_generation_question_subtitle') }}
        </div>
        <div class="d-flex">
          <div
            class="wizard__question__tile"
            @click="() => {
              setProjectSetting('generate', true)
              maybeSkipSentimentQuestion()
            }"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('yes') }}
            </div>
          </div>
          <div
            class="wizard__question__tile"
            @click="() => {
              setProjectSetting('generate', false)
              maybeSkipSentimentQuestion()
            }"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0001 2V13M22.0001 9.8V5.2C22.0001 4.07989 22.0001 3.51984 21.7821 3.09202C21.5903 2.71569 21.2844 2.40973 20.908 2.21799C20.4802 2 19.9202 2 18.8001 2H8.11806C6.65658 2 5.92584 2 5.33563 2.26743C4.81545 2.50314 4.37335 2.88242 4.06129 3.36072C3.70722 3.90339 3.59611 4.62564 3.37388 6.07012L2.8508 9.47012C2.5577 11.3753 2.41114 12.3279 2.69386 13.0691C2.94199 13.7197 3.4087 14.2637 4.01398 14.6079C4.70358 15 5.66739 15 7.59499 15H8.40005C8.96011 15 9.24013 15 9.45404 15.109C9.64221 15.2049 9.79519 15.3578 9.89106 15.546C10.0001 15.7599 10.0001 16.0399 10.0001 16.6V19.5342C10.0001 20.896 11.104 22 12.4659 22C12.7907 22 13.0851 21.8087 13.217 21.5119L16.5778 13.9502C16.7306 13.6062 16.807 13.4343 16.9278 13.3082C17.0346 13.1967 17.1658 13.1115 17.311 13.0592C17.4753 13 17.6635 13 18.0398 13H18.8001C19.9202 13 20.4802 13 20.908 12.782C21.2844 12.5903 21.5903 12.2843 21.7821 11.908C22.0001 11.4802 22.0001 10.9201 22.0001 9.8Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('no') }}
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="slide-fade">
      <div v-if="step === 4" class="d-flex flex-column align-center w-100 wizard__question">
        <div class="wizard__title">
          {{ $t('question_steps.sentiment_question') }}
        </div>
        <div class="wizard__subtitle">
          {{ $t('question_steps.sentiment_question_subtitle') }}
        </div>
        <div class="d-flex">
          <div class="wizard__question__tile" @click="setProjectSetting('sentiment', true)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('yes') }}
            </div>
          </div>
          <div class="wizard__question__tile" @click="setProjectSetting('sentiment', false)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.0001 2V13M22.0001 9.8V5.2C22.0001 4.07989 22.0001 3.51984 21.7821 3.09202C21.5903 2.71569 21.2844 2.40973 20.908 2.21799C20.4802 2 19.9202 2 18.8001 2H8.11806C6.65658 2 5.92584 2 5.33563 2.26743C4.81545 2.50314 4.37335 2.88242 4.06129 3.36072C3.70722 3.90339 3.59611 4.62564 3.37388 6.07012L2.8508 9.47012C2.5577 11.3753 2.41114 12.3279 2.69386 13.0691C2.94199 13.7197 3.4087 14.2637 4.01398 14.6079C4.70358 15 5.66739 15 7.59499 15H8.40005C8.96011 15 9.24013 15 9.45404 15.109C9.64221 15.2049 9.79519 15.3578 9.89106 15.546C10.0001 15.7599 10.0001 16.0399 10.0001 16.6V19.5342C10.0001 20.896 11.104 22 12.4659 22C12.7907 22 13.0851 21.8087 13.217 21.5119L16.5778 13.9502C16.7306 13.6062 16.807 13.4343 16.9278 13.3082C17.0346 13.1967 17.1658 13.1115 17.311 13.0592C17.4753 13 17.6635 13 18.0398 13H18.8001C19.9202 13 20.4802 13 20.908 12.782C21.2844 12.5903 21.5903 12.2843 21.7821 11.908C22.0001 11.4802 22.0001 10.9201 22.0001 9.8Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="wizard__question__tile__title">
              {{ $t('no') }}
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <modal
      :visible="!!inheritModal"
      :close="() => setInheritModal(false)"
      :title="$t('question_steps.inherit')"
      :class-name="`wizard__inherit ${inheritModal === 'template' && 'topic-fullscreen-modal'}`"
      :action="inheritModal !== 'project' ? $t('confirm') : ''"
      :action-click="inheritModal !== 'project' ? setTopicsFromInherit : noop"
      :disabled="confirmDisabled"
    >
      <project-list
        v-if="inheritModal === 'project'"
        filters-internal
        :inheritable="{ inheritable_column_ref: columnRef, inheritable_project_id: projectID }"
        in-dialog
        ref="projectList"
      >
        <template slot="action" slot-scope="{ question }">
          <v-btn outlined color="primary" :disabled="question.project_obj.id == projectID && question.ref == columnRef" @click="setTopicsFromInherit(question)">
            {{ $t('question_steps.inherit') }}
          </v-btn>
        </template>
      </project-list>
      <template-selector
        v-else-if="inheritModal === 'template'"
        :id="id"
      />
    </modal>
    <div
      v-if="rowsShown"
      class="wizard__sidebar wizard__sidebar--questions"
    >
      <div class="d-flex justify-space-between align-center pr-2 coding__column__tabs-container pt-0 pb-0">
        <v-tabs
          class="coding__column__tabs"
          :show-arrows="false"
          :ripple="false"
        >
          <v-tab
            v-for="(tab, index) in tabs"
            :key="tab"
            class="pt-0"
            :ripple="false"
          >
            {{ tabLabels[index] }}
          </v-tab>
        </v-tabs>
        <v-icon
          class="hover mr-2 text-color"
          @click.stop="toggleRowsShown"
          :size="20"
        >
          mdi-close
        </v-icon>
      </div>
      <row-browser
        :id="id"
        :store-name="id"
        :reset-button="false"
        :coding-view="false"
        :translations-enabled="false"
        :wizard-view="true"
        :data="answersFiltered"
        :init="init"
        :load-data="getWizardRemoteResults"
        :reset-data="resetData"
      />
    </div>
    <div @click="toggleRowsShown" class="wizard__back wizard__row-preview hover text-primary font-weight-medium">
      {{ $t('see_reviews') }}
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import ProjectList from '@/components/ProjectList'
import TemplateSelector from '@/components/wizard/TemplateSelector'
import ProjectListMixin from '@/mixins/ProjectList'
import FileInherit from '@/components/wizard/FileInherit'
import RowBrowserEnv from '@/mixins/rowBrowserEnv.js'
import RowBrowser from '@/components/RowBrowser'

export default {
  codit: true,
  name: 'QuestionSteps',
  components: {
    ProjectList,
    FileInherit,
    TemplateSelector,
    RowBrowser
  },

  mixins: [ProjectListMixin, RowBrowserEnv],

  props: {
  },

  data () {
    return {
      inheritModal: false,
      rowsShown: false,
      wizardView: true,
      textType: 'open'
    }
  },

  computed: {
    ...Vuex.mapState({
      id: state => state.wizard.id,
      step: state => state.wizard.step,
      projectID: state => state.wizard.projectID,
      columnRef: state => state.wizard.columnRef,
      topics: state => state.wizard.topics,
      questionID: state => state.wizard.questionID,
      isColumnEligibleForList: state => state.wizard.isColumnEligibleForList,
      settings: state => state.wizard.settings
    }),

    /**
     * Template selection confirm button disabled state
     */
    confirmDisabled () {
      if (this.inheritModal === 'template' && !this.topics.length) return true
      return false
    },

    /**
     * Tab options
     * @return {Array}
     */
    tabs () {
      return ['row_browser']
    },

    tabLabels () {
      return [this.$t('row_browser')]
    }
  },

  watch: {
    isColumnEligibleForList (val) {
      if (!val) {
        this.setProjectSetting('type', 'open')
      }
    }
  },

  mounted () {
    if (!this.isColumnEligibleForList) {
      this.setProjectSetting('type', 'open')
    }
  },

  methods: {
    // Inherited topics contain a sentiment_enabled flag, skip the next question
    maybeSkipSentimentQuestion () {
      if (_.find(this.topics, topic => topic.sentiment_enabled)) this.$store.commit('incrementStep')
    },

    /**
     * Handler for the back button in questions
     */
    handleBackClick () {
      if (this.step === 0 || (this.step === 1 && !this.isColumnEligibleForList)) this.$router.go(-1)
      if (this.step === 4 && !this.settings.import) {
        this.$store.commit('decrementStep')
        this.$store.commit('decrementStep')
        this.$store.commit('decrementStep')
      } else this.$store.commit('decrementStep')
    },

    /**
     * An empty function
     */
    noop () {},

    /**
     * Set the project's setting
     */
    setProjectSetting (setting, value) {
      this.$store.commit('setProjectSetting', { setting, value })
      this.$store.commit('incrementStep')
    },

    /**
     * Open project list to inherit a question
     */
    setInheritModal (value) {
      this.inheritModal = value
    },

    /**
     * Callback from project list question select
     */
    setTopicsFromInherit (question) {
      if (this.inheritModal === 'project') {
        this.$store.commit('setTopics', question.topics)
        this.$store.commit('incrementStep')

        this.$store.commit('setInheritsFrom', {
          projectID: question.project_obj.id,
          columnRef: question.ref,
          name: question.project_obj.name + ' | ' + question.name
        })

        this.$root.snackMsg(this.$t('question_steps.imported_topics', { projectName: this.$escapeHtml(question.project_obj.name), topicCount: question.topics.length }))
      }

      if (this.inheritModal === 'template') {
        this.$store.commit('incrementStep')

        this.$root.snackMsg(this.$t('question_steps.inherited_topics'))
      }

      this.setInheritModal(false)
    },

    /**
     * User selected semi-open question, handled by v1 wizard.
     */
    goToV1Wizard () {
      this.$router.push({
        name: 'question-wizard',
        params: { id: this.questionID },
        query: { list_and_step_2: 'true' }
      })
    },

    /**
     * Handler for showing rows in questions mode
     */
    toggleRowsShown () {
      this.rowsShown = !this.rowsShown
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />