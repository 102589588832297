<template>
  <v-card-text
    class="dashboard-filter-editor__content"
  >
    <alert
      v-if="fetchState.failed"
      v-html="$t('error', {'step': $t('loading.while')})"
      type="error"
    />
    <div
      class="d-flex justify-center"
      v-if="fetchState.loading"
    >
      <v-progress-circular
        class="mx-auto"
        color="primary"
        indeterminate
        :size="50"
      />
    </div>
    <ChartList
      v-else
      :charts="charts"
      :loading="fetchState.loading"
      search-filter-enable
      questions-filter-enable
      :rows-per-page-options="[5]"
      :rows-per-page-default="5"
      ref="chartList"
      @open-chart="selectChart($event)"
    >
      <template slot="title">
        <div style="margin-left: -16px">
          {{ $t("add_or_edit.chart.title") }}
        </div>
      </template>
    </ChartList>
    <credit-deduction-info :credits-required="creditsOpen" show-info />
  </v-card-text>
</template>

<script>
import ChartList from '@/components/ChartList'
import CreditDeductionInfo from '@/components/CreditDeductionInfo'

import DataMixin from '@/components/customUi/mixins/data'
import { generateInject, injectComputedValues } from '@/components/customUi/mixins/provideInject'

export default {
  components: {
    ChartList,
    CreditDeductionInfo
  },

  mixins: [DataMixin],

  props: {
    value: { type: Object, default: () => ({}), required: true }
  },

  data () {
    return {
      fetchState: {
        loading: false,
        failed: false
      },
      chart: {
        id: null
      },
      charts: null
    }
  },

  inject: generateInject(['creditsOpen', 'creditsOpenQuestions']),

  computed: {
    ...injectComputedValues([
      'creditsOpen',
      'creditsOpenQuestions'
    ])
  },

  created () {
    this.loadChartsList()
  },

  methods: {
    async loadChartsList () {
      try {
        this.fetchState.loading = true
        const { data } = await api.get('/api/charts/')
        this.$set(this, 'charts', data)
        this.fetchState.loading = false
      } catch (error) {
        this.fetchState.failed = true
        this.fetchState.loading = false
        this.$root.snackMsg(this.$t('error', { 'step': this.$t('loading.while') }))
        this.$maybeRaiseAPIPromiseErr(error)
      }
    },

    /**
     * Select a chart in the addOrEdit dialog.
     * If the chart's questions don't have any open credits, select it and close the dialog
     * Otherwise just select it and display informational message
     * @param  {Number} id The question id to select
     */
    selectChart (id) {
      // Check if there are any questions in this chart, that are not yet billed
      let chart = _.find(this.charts, { id })
      let creditsOpen = 0

      chart.questions.forEach(qs => {
        creditsOpen += qs.credits_open
        if (qs.credits_open) this.addOrEdit.creditsOpenQuestions.push(qs.id)
      })

      if (creditsOpen > 0) {
        this.creditsOpen = creditsOpen
      } else {
        this.internalValue.chart.id = id
      }
    }
  }
}
</script>

<style scoped>
  .dashboard-filter-editor__content{
    border-top: none;
    border-bottom: none;
  }
</style>

<i18n locale="en" src="@/i18n/en/pages/Dashboard.json" />
<i18n locale="de" src="@/i18n/de/pages/Dashboard.json" />
