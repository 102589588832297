<template>
  <v-card-text
    class="step-text"
  >
    <v-label>{{ $t("add_or_edit.text.instructions") }}</v-label>

    <div class="inputrow">
      <v-text-field
        v-model="internalValue.config.title"
        :label="$t('add_or_edit.title_label')"
        tabindex="1"
        class="mt-2"
        outlined
        hide-details
      />

      <v-btn-toggle v-model="internalValue.config.alignTitle" class="align-select">
        <v-btn text value="left">
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>
        <v-btn text value="center">
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>
        <v-btn text value="right">
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>

      <div
        class="color-indicator"
        :style="{ 'background-color': internalValue.config.colorTitle }"
        @click.stop="showColorPicker('colorTitle', $event)"
      >
        <span>{{ $t("color") }}</span>
      </div>
    </div>

    <div class="inputrow">
      <v-textarea
        v-model="internalValue.config.text"
        outlined
        tabindex="2"
        :label="$t('add_or_edit.text.text')"
        value=""
        hide-details
      />

      <v-btn-toggle v-model="internalValue.config.alignText" class="align-select">
        <v-btn text value="left">
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>
        <v-btn text value="center">
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>
        <v-btn text value="right">
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>

      <div
        class="color-indicator"
        :style="{ 'background-color': internalValue.config.colorText }"
        @click.stop="showColorPicker('colorText', $event)"
      >
        <span>{{ $t("color") }}</span>
      </div>
    </div>

    <div class="inputrow">
      <v-spacer />
      <v-label style="margin: 0 12px">
        Background
      </v-label>
      <div
        class="color-indicator"
        :style="{ 'background-color': internalValue.config.colorBG }"
        @click.stop="showColorPicker('colorBG', $event)"
      >
        <span>{{ $t("color") }}</span>
      </div>
    </div>

    <v-divider style="margin-top: 36px" />
    <v-subheader>{{ $t("add_or_edit.text.preview") }}</v-subheader>

    <div class="bg-sample" :style="{ 'background-color': internalValue.config.colorBG }">
      <div
        class="title"
        :style="{
          color: internalValue.config.colorTitle,
          'text-align': internalValue.config.alignTitle,
        }"
      >
        {{ internalValue.config.title }}
      </div>
      <div
        :style="{
          color: internalValue.config.colorText,
          'text-align': internalValue.config.alignText,
        }"
      >
        <MarkdownRenderer :text="internalValue.config.text" />
      </div>
    </div>

    <ColorPicker
      v-model="colorPicker.val"
      :show.sync="colorPicker.active"
      :offset-top="colorPicker.offsetTop"
      :offset-left="colorPicker.offsetLeft"
      :indicator-height="25"
      :indicator-width="25"
      lazy
    />
  </v-card-text>
</template>

<script>
import ColorPicker from '@/components/ColorPicker'
import MarkdownRenderer from '@/components/visualize/MarkdownRenderer.vue'

import DataMixin from '@/components/customUi/mixins/data'

export default {
  components: {
    ColorPicker,
    MarkdownRenderer
  },

  mixins: [DataMixin],

  props: {
    value: { type: Object, default: () => ({}), required: true }
  },

  data () {
    return {
      colorPicker: {
        active: false,
        offsetLeft: 0,
        offsetTop: 0,
        val: 'rgba(0,0,0,1.0)',
        prop: ''
      }
    }
  },

  watch: {
    'colorPicker.val' (val) {
      if (!this.colorPicker.prop) return
      if (this.colorPicker.prop) this.$set(this.internalValue.config, this.colorPicker.prop, val)
    }
  },

  methods: {
    showColorPicker (prop, $event) {
      this.colorPicker.offsetLeft = $event.target.offsetLeft
      this.colorPicker.offsetTop = $event.target.offsetTop
      this.colorPicker.prop = prop
      this.colorPicker.val = this.internalValue.config[prop]
      this.colorPicker.active = true
    }
  }
}
</script>

<i18n locale="en" src="@/i18n/en/pages/Dashboard.json" />
<i18n locale="de" src="@/i18n/de/pages/Dashboard.json" />
