import Vue from 'vue'
import Vuex from 'vuex'

import appStore from './App'
import coding from './Coding'

Vue.use(Vuex)

export const store = new Vuex.Store(appStore)
store.hasAction = (actionName) => actionName in store._actions

export const DynamicModules = {
  coding
}
