<template>
  <div class="main-content cockpit">
    <v-alert v-if="loadingFailed"
             prominent
             type="error"
             outlined
             text
             border="left"
             class="flex-center"
    >
      {{ $t('loading_failed') }}

      <div class="spacer" />

      <v-btn color="accent" outlined @click="init">
        {{ $t('actions.retry') }}
      </v-btn>
    </v-alert>

    <page-header>
      <span slot="info" v-html="$t('info')" />
      <template slot="headline">
        {{ question.project_name }}
      </template>
      <template slot="subtitle">
        {{ question.name }}
      </template>

      <template slot="page-buttons">
        <v-btn v-if="$hasPermission('projects_download', question)"
               outlined
               color="primary"
               @click.stop="showDownloadV2 = true"
               data-testid="showDownloadV2Button"
        >
          <v-icon left>
            mdi-cloud-download
          </v-icon>
          {{ $t('download_btn') }}
        </v-btn>
      </template>

      <template slot="navigation-buttons">
        <v-btn v-if="v2identifier.project_id"
               outlined
               color="grey"
               :to="{ name: 'question-wizard-v2', params: { id: v2identifier.project_id, ref: v2identifier.ref } }"
        >
          <v-icon left>
            mdi-eraser
          </v-icon>
          {{ $t('actions.wizard') }}
        </v-btn>

        <v-btn v-if="v2identifier.project_id"
               outlined
               color="green"
               :to="{ name: 'coding-v2', params: { id: v2identifier.project_id, ref: v2identifier.ref } }"
        >
          <v-icon left>
            mdi-keyboard-variant
          </v-icon>
          {{ $t('actions.coding_view') }}
        </v-btn>
      </template>
    </page-header>

    <v-alert v-if="!editable" type="warning" outlined border="left">
      <template v-if="['multiple_users', 'takeover_by'].indexOf(sessionIssue.type) !== -1">
        {{ this.$t('alert_not_editable_other_user') }}
      </template>
      <template v-else>
        <div>{{ this.$t('alert_not_editable_permissions') }}</div>
        <div v-html="$t('request_access', {
          root_name: $escapeHtml(user.organization.root_user_name),
          root_email: user.organization.root_user_email,
          owner_name: $escapeHtml(question.owner),
          owner_email: question.owner_email
        })
        "
        />
      </template>
    </v-alert>

    <div class="top-block">
      <v-card class="block-el chart-teaser">
        <div class="placeholder is-training" v-if="trainingCompletedETAText">
          <v-alert color="blue-grey"
                   prominent
                   type="info"
                   outlined
                   icon="mdi-timer"
                   border="left"
          >
            <div v-html="$t('info_computing_coding')" />
            <span v-text="$t('stats.model.eta_predictions')" />
            <code v-text="trainingCompletedETAText" />
          </v-alert>
          <img src="@/assets/in_progress.svg" class="placeholder-img">
        </div>

        <div class="placeholder" v-else-if="questionIsEmpty">
          <v-alert color="blue-grey"
                   prominent
                   type="info"
                   outlined
                   border="left"
          >
            <div style="display: flex; align-items: center">
              <span v-html="$t('info_empty_question')" />
              <v-spacer />
              <v-btn v-if="v2identifier.project_id"
                     outlined
                     color="green"
                     :to="{ name: 'question-wizard-v2', params: { id: v2identifier.project_id, ref: v2identifier.ref } }"
              >
                <v-icon left>
                  mdi-auto-fix
                </v-icon>
                {{ $t('actions.init') }}
              </v-btn>
            </div>
          </v-alert>
          <img src="@/assets/blank_state.svg" class="placeholder-img">
        </div>

        <template v-else>
          <div class="chart-types py-2 white ">
            <div class="d-flex flex-wrap justify-center chart-types__group">
              <div
                v-for="(chartSettings, chartIndex) in availableChartsList"
                :key="chartIndex"
              >
                <div @click="selectChartType(chartIndex)">
                  <ChartTypePreview
                    :chart-type="chartIndex"
                    :chart-settings="chartSettings"
                    :active="configManager['ch__new'] && configManager['ch__new'].typeSelection === chartIndex"
                    :disabled="!chartSettings.enabled"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="the-chart" v-if="!trainingCompletedETAText">
            <div
              class="d-flex justify-center align-center"
              v-if="chartTypeLoading || loading"
            >
              <v-progress-circular
                class="mx-auto"
                color="primary"
                indeterminate
                :size="50"
              />
            </div>
            <component
              :is="`chart-${configManager['ch__new'].type}`"
              v-if="!loading && !chartTypeLoading && configManager['ch__new']"
              v-model="chart.config"
              :datasets="configManager['ch__new'].datasets"
              :type="chart.type"
              :chart-unique-name="chart.typeSelection"
              :meta="metaManager['ch__new']"
              :id="'ch__new'"
              hide-controls
              no-watermark
            />
            <v-btn outlined
                   v-if="!chartTypeLoading && !loading && configManager['ch__new']"
                   class="new-chart-btn mt-2 mb-2"
                   small
                   color="green"
                   :to="{ name: 'chart-details', params: { id: 'new' }, query: { question: questionID, chart: configManager['ch__new'].typeSelection } }"
            >
              {{ $t('actions.new_chart_from_teaser') }}
            </v-btn>
          </div>
        </template>
      </v-card>

      <data-preview-tabs :loading="viz.loading"
                         :failed="viz.failed"
                         :charts="viz.charts"
                         :dashboards="viz.dashboards"
                         @retry="loadViz"
                         class="block-el"
      >
        <v-btn slot="actions-charts"
               outlined
               small
               color="green"
               :to="{ name: 'chart-details', params: { id: 'new' }, query: { question: questionID, chart: CHART_UNIQUE_BAR} }"
               v-text="$t('viz.new_chart')"
        />

        <v-btn outlined
               slot="actions-dashboards"
               small
               color="green"
               :to="{ name: 'dashboard-details', params: { id: 'new' } }"
               v-text="$t('viz.new_dashboard')"
        />
      </data-preview-tabs>
    </div>
    <div class="bottom-block">
      <v-card class="block-el edit">
        <v-card-title class="title" v-text="$t('edit.title')" />

        <alert type="error" text v-if="editQuestionStatus.failed">
          <div style="display: flex; align-items: center">
            {{ $t('edit.failed') }}

            <div class="spacer" />

            <v-btn color="white" outlined @click="saveEditQuestion">
              {{ $t('actions.retry') }}
            </v-btn>
          </div>
        </alert>

        <alert type="success" text v-if="editQuestionStatus.success">
          {{ $t('edit.success') }}
        </alert>

        <v-card-text class="edit-form">
          <v-form ref="edit-form">
            <v-text-field :value="editQuestionVals.name"
                          v-lazy-model="'editQuestionVals.name'"
                          :rules="[validationRules.questionName]"
                          :label="$t('eprops.name')"
                          :disabled="!editable || loading"
                          counter
                          maxlength="50"
            />

            <v-textarea :value="editQuestionVals.description"
                        v-lazy-model="'editQuestionVals.description'"
                        :rules="[validationRules.questionDescription]"
                        :label="$t('eprops.question_wording')"
                        :disabled="!editable || loading"
                        rows="1"
                        counter
                        maxlength="255"
            />

            <v-text-field :value="editQuestionVals.inherits_from_name || 'None'"
                          outlined
                          hide-details
                          class="inherits-from"
                          :class="{ none: editQuestionVals.inherits_from === null }"
                          :label="$t('eprops.inherits_from')"
                          readonly
            >
              <template v-slot:append v-if="editable && !loading">
                <v-btn @click="openInheritDialog"
                       small
                       text
                       outlined
                       v-text="$t('edit.inherit.change')"
                       class="tooltip tooltip-top"
                       :data-tooltip="$t('edit.inherit.change_tooltip')"
                />

                <v-btn @click="resetInheritFrom"
                       icon
                       dense
                       small
                       class="tooltip tooltip-top"
                       :data-tooltip="$t('edit.inherit.remove_tooltip')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-switch v-model="editQuestionVals.completed"
                      color="green"
                      :disabled="!editable || loading"
                      :label="$t('eprops.status.completed')"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="editQuestionStatus.saving || !editable || loading || loadingFailed"
                 @click="resetEditQuestion"
                 outlined
                 method="resetEditQuestion"
          >
            {{ $t('edit.action_reset') }}
          </v-btn>
          <v-btn color="primary"
                 @click="saveEditQuestion"
                 :loading="editQuestionStatus.saving"
                 :disabled="!editQuestionIsDirty || !editable || loading || loadingFailed"
          >
            {{ $t('save.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="block-el stats">
        <v-card-title v-text="$t('stats.title')" />
        <v-card-text>
          <question-stats v-if="!loading" />
        </v-card-text>
      </v-card>
    </div>

    <v-dialog
      v-model="projectsInheritable.active"
      max-width="100%"
      @keydown.esc="closeInheritDialog(false)"
      content-class="dashboard-filter-editor project-selector"
    >
      <v-card v-if="projectsInheritable.active">
        <div class="dashboard-filter-editor__title w-100 d-flex align-center justify-space-between">
          {{ $t('inherit.dialog_title') }}
          <v-btn
            @click.native="closeInheritDialog(false)"
            icon
            small
          >
            <v-icon
              :size="20"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <div class="dashboard-filter-editor__content">
          <project-list
            :project-to-focus="projectsInheritable.initialProjectFocus"
            filters-internal
            :inheritable="question.project"
            in-dialog
            ref="projectList"
          >
            <template slot="action" slot-scope="{ question }">
              <v-btn outlined color="primary" @click="closeInheritDialog(question)">
                {{ $t('inherit.select') }}
              </v-btn>
            </template>
          </project-list>
        </div>

        <v-spacer />

        <div class="dashboard-filter-editor__actions d-flex justify-space-between">
          <div />
          <v-btn
            color="primary"
            outlined
            @click.native="closeInheritDialog(false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <download-dialog
      v-model="showDownload"
      entity-type="question"
      :credits-open="question.credits_open"
      :entity-id="question.id"
      :nanswers="question.nanswers"
      @downloaded="() => $store.commit('setQuestionBilled')"
    />
    <export-data-dialog
      v-if="v2identifier.project_id"
      v-model="showDownloadV2"
      :project-id="v2identifier.project_id"
      :project-name="question.project_name"
      :ref-id="v2identifier.ref"
      :question-name="question.name"
      data-testid="exportDataDialog"
    />
    <question-worker-session-issue-dialog :socket="socket" @retake-session-control="retakeSessionControl" />

    <!-- VERBATIM BROWSER V2 (WITH SENTIMENT) -->
    <verbatim-browser-v2-dialog />
  </div>
</template>

<script>

import Vuex from 'vuex'
import axios from 'axios'

import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import WSQuestionWorkerMixin from '@/mixins/WSQuestionWorker'
import trainingCompletedETA from '@/mixins/trainingCompletedETA'
import demoDatasetsMixin from '@/mixins/demoDatasets'
import chartListMixin from '@/mixins/chartList'

import DownloadDialog from '@/components/coding/DownloadDialog'
import ExportDataDialog from '@/components/coding/ExportData/ExportDataDialog.vue'
import VerbatimBrowserv2Dialog from '@/components/VerbatimBrowserv2/VerbatimBrowserv2Dialog'

import ProjectList from '@/components/ProjectList'
import DataPreviewTabs from '@/components/DataPreviewTabs'
import QuestionStats from '@/components/coding/QuestionStats'

// chart components
import ChartBar from '@/components/visualize/ChartBar'
import ChartLinePie from '@/components/visualize/ChartLinePie'
import ChartDateLinePie from '@/components/visualize/ChartDateLinePie'
import ChartRowsCountBar from '@/components/visualize/ChartRowsCountBar'
import ChartTreemap from '@/components/visualize/ChartTreemap'
import ChartGraph from '@/components/visualize/ChartGraph'
import ChartSentimentBar from '@/components/visualize/ChartSentimentBar'
import ChartScore from '@/components/visualize/ChartScore'
import ChartDriverScatter from '@/components/visualize/ChartDriverScatter'
import ChartDynamicBar from '@/components/visualize/ChartDynamicBar'
// end chart components

import QuestionWorkerSessionIssueDialog from '@/components/coding/QuestionWorkerSessionIssueDialog'

import ChartTypePreview from '@/components/customUi/chartTypePreview/ChartTypePreview'

import { mapGettersWithKey } from '@/utils/vuex.js'
import { generateSigleFunctionCallComputedWithSameArgs } from '@/utils/genericComputed.js'

import {
  CHART_TYPE_BAR,
  CHART_TYPE_SENTIMENT_BAR,
  CHART_TYPE_LINE_PIE,
  CHART_TYPE_DATE_LINE_PIE,
  CHART_TYPE_ROWS_COUNT_BAR,
  CHART_TYPE_TREEMAP,
  CHART_TYPE_GRAPH,
  CHART_TYPE_DRIVER_SCATTER,
  CHART_TYPE_SCORE,
  CHART_TYPE_DYNAMIC_BAR,
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_ROWS_COUNT_BAR,
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_DRIVER_SCATTER,
  CHART_UNIQUE_DYNAMIC_BAR,
  DS_FILTERS_TEMPLATE,
  DS_SETTINGS_TEMPLATE,
  MIN_QUESTION_NAME_LEN,
  MIN_QUESTION_DESCRIPTION_LEN
} from '@/settings/constants'

import CHART_LIST from '@/settings/chartList'

export default {
  codit: true,
  name: 'Cockpit',

  components: {
    'project-list': ProjectList,
    'data-preview-tabs': DataPreviewTabs,
    'question-stats': QuestionStats,
    'question-worker-session-issue-dialog': QuestionWorkerSessionIssueDialog,
    'download-dialog': DownloadDialog,
    'export-data-dialog': ExportDataDialog,
    'verbatim-browser-v2-dialog': VerbatimBrowserv2Dialog,
    ChartTypePreview,
    [`chart-${CHART_TYPE_BAR}`]: ChartBar,
    [`chart-${CHART_TYPE_SENTIMENT_BAR}`]: ChartSentimentBar,
    [`chart-${CHART_TYPE_LINE_PIE}`]: ChartLinePie,
    [`chart-${CHART_TYPE_DATE_LINE_PIE}`]: ChartDateLinePie,
    [`chart-${CHART_TYPE_ROWS_COUNT_BAR}`]: ChartRowsCountBar,
    [`chart-${CHART_TYPE_TREEMAP}`]: ChartTreemap,
    [`chart-${CHART_TYPE_GRAPH}`]: ChartGraph,
    [`chart-${CHART_TYPE_SCORE}`]: ChartScore,
    [`chart-${CHART_TYPE_DRIVER_SCATTER}`]: ChartDriverScatter,
    [`chart-${CHART_TYPE_DYNAMIC_BAR}`]: ChartDynamicBar
  },

  mixins: [
    auxiliaryColumnsMixin,
    answerFilters,
    WSQuestionWorkerMixin,
    trainingCompletedETA,
    demoDatasetsMixin,
    chartListMixin
  ],

  data () {
    return {
      loading: true,
      loadingFailed: false,
      destroyed: false,
      showDownload: false,
      showDownloadV2: false,

      chartTypeLoading: false,
      chartTypeLoadingTimeout: null,

      v2identifier: {
        project_id: '',
        ref: ''
      },

      editQuestionVals: {
        name: '',
        description: '',
        completed: false,
        inherits_from: null,
        inherits_from_name: ''
      },

      projectsInheritable: {
        initialProjectFocus: null,
        active: false
      },

      editQuestionStatus: {
        inheritsFromProject: null,
        saving: false,
        failed: false,
        success: false
      },

      viz: {
        charts: [],
        dashboards: [],
        loading: true,
        failed: false
      },

      validationRules: {
        questionName: val => val.length >= MIN_QUESTION_NAME_LEN ||
                      this.$t('edit.question_name_len', { n: MIN_QUESTION_NAME_LEN }),
        questionDescription: val => val.length >= MIN_QUESTION_DESCRIPTION_LEN ||
                      this.$t('edit.question_description_len', { n: MIN_QUESTION_DESCRIPTION_LEN })
      },

      chart: {
        type: CHART_TYPE_BAR,
        typeSelection: CHART_UNIQUE_BAR,
        config: {}
      },

      datasets: [],

      settings: {
        driver_target_column: null,
        scoring_type: null,
        scoring_column: null
      },

      CHART_UNIQUE_BAR
    }
  },

  computed: {
    // Objects from store
    ...Vuex.mapState({
      languageOptions: 'languageOptions',
      user: 'user',
      question: state => state.coding.question,
      codes: state => state.coding.codes,
      answers: state => state.coding.answers,
      editable: state => state.coding.editable,
      sessionIssue: state => state.coding.sessionIssue
    }),

    ...Vuex.mapGetters({
      metaManager: 'metaManager/overall',
      configManager: 'configManager/overall'
    }),

    ...mapGettersWithKey({
      isReady: 'registerManager/isReady'
    })(() => 'ch__new'),

    ...generateSigleFunctionCallComputedWithSameArgs({
      potentialDateColumnSelectItems: 'getPotentialDateColumnSelectItems',
      potentialNumberColumnSelectItems: 'getPotentialScoringColumnSelectItems',
      potentialDriverColumnSelectItems: 'getPotentialDriverColumnSelectItems',
      potentialDYBARSelectItems: 'getPotentialDYBARColumns'
    })(function () {
      return [
        this?.metaManager['ch__new']?.auxiliary_column_metas,
        this?.metaManager['ch__new']?.auxiliary_column_metas?.map(({ name }) => name)
      ]
    }),

    /**
     * Returns this.datasets[0] or a empty dataset template to prevent "this.datasets[0] is undefined" error
     * @return {Object} The first data set
     */
    firstDataset () {
      return this.datasets[0] ? this.datasets[0] : {}
    },

    availableChartsList () {
      const list = Object.fromEntries(
        Object.entries(CHART_LIST).map(
          ([key, value]) => [key, {
            ...value,
            enabled: true
          }]
        )
      )

      delete list[CHART_UNIQUE_LINE_PIE]

      /*
      * Manage features availability
      * depends on data
      */
      list[CHART_UNIQUE_DATE_LINE_PIE].enabled = !!this.potentialDateColumnSelectItems?.length
      list[CHART_UNIQUE_DYNAMIC_BAR].enabled = !!this.potentialDYBARSelectItems?.length
      list[CHART_UNIQUE_ROWS_COUNT_BAR].enabled = !!this.potentialDateColumnSelectItems?.length
      list[CHART_UNIQUE_SCORE].enabled = !!this.potentialNumberColumnSelectItems?.length
      list[CHART_UNIQUE_DRIVER_SCATTER].enabled = !!this.potentialDriverColumnSelectItems?.length
      return list
    },

    /**
     * If there are unsaved changes in the edit question form
     * @return {Boolean}
     */
    editQuestionIsDirty () {
      return !_.isEqual(this.editQuestionVals, _.pick(this.question, _.keys(this.editQuestionVals)))
    },

    /**
     * If there are no codes in the question
     * @return {Boolean} True if the question is empty
     */
    questionIsEmpty () {
      return !this.loading && !this.loadingFailed && !this.codes.length
    },

    /**
     * The ID of the current question. Whie loading, this is the id given through the params
     * When loaded, it's the ID received by the API request.
     * We use both, as the value returned by the API is preferred (always the new, string ID),
     * but we still want to provide navigation availability during loading.
     * @return {String,Number} The question ID
     */
    questionID () {
      return this.question.id || this.$route.params.id
    }
  },

  watch: {
    /**
     * When the store is destroyed, call the local destroy function as well
     */
    storeDestroyed: 'doDestroy',

    'chart.config': {
      deep: true,
      handler () {
        if (
          !this.isReady
        ) {
          return
        }
        this.$store.dispatch(
          `configManager/update`,
          {
            entityId: 'ch__new',
            entityData: {
              datasets: _.cloneDeep(this.datasets),
              ..._.cloneDeep(this.chart)
            }
          }
        )
      }
    }
  },

  async created () {
    this.loading = true

    await this.load(this.$route.params.id)

    this.datasets = [{
      settings: {
        ...DS_SETTINGS_TEMPLATE,
        name: `${this.question.name}, ${this.question.project_name}`
      },
      filters: DS_FILTERS_TEMPLATE,
      status: {
        ready: true
      },
      question: this.questionID,
      answers: this.answers,
      result: this.answers
    }]

    // Load the question & answers

    await this.$store.dispatch(
      `registerManager/register`,
      {
        entityId: 'ch__test',
        entityData: {
          config: _.cloneDeep(this.chart.config),
          datasets: _.cloneDeep(this.datasets),
          type: CHART_LIST[CHART_TYPE_BAR].type,
          typeSelection: CHART_TYPE_BAR
        }
      }
    )

    if (this.metaManager['ch__test'].topics_complete_union?.some(
        ({ sentiment_enabled }) => !!sentiment_enabled // eslint-disable-line
    )) {
      this.chart.typeSelection = CHART_TYPE_SENTIMENT_BAR
      this.chart.type = CHART_LIST[CHART_TYPE_SENTIMENT_BAR].type
      this.callRegisterManager('register', CHART_TYPE_SENTIMENT_BAR)
    } else {
      await this.callRegisterManager('register', CHART_TYPE_BAR)
    }

    await this.$store.dispatch(
      `registerManager/delete`,
      {
        entityId: 'ch__test'
      }
    )

    this.loading = false
    this.prefillConfigValues()
    this.setDefaultAxisNames()
    this.$store.commit('setPageTutorialID', '1592512218cKkj4597')
    window.addEventListener('beforeunload', this.doDestroy)
  },

  beforeDestroy () {
    this.doDestroy()
  },

  methods: {
    /** ************************ LOADING METHODS ************************ **/

    /**
     * Proxy for calling load function
     */
    async init () {
      this.loading = true
      await this.load(this.$route.params.id)
      this.loading = false
    },

    /**
     * Load this question & the answers
     * @param  {Number} questionID The question ID to load
     */
    load (questionID) {
      this.loadingFailed = false

      let question = null

      // Fetch question info and codebook
      let axiosQuestion = api.get(`/api/questions/${questionID}`).then((res) => {
        if (this.destroyed) return
        if (this.$route.params.id !== res.data.id) {
          this.$router.replace({
            name: `question-cockpit`,
            params: { id: res.data.id },
            query: this.$route.query
          })
        }
        question = res.data
        // Load the visualizations
        this.loadViz()
        // Get the v2 identifier
        api.get(`/api/ui/projects/${question.project}/to-refs`).then((res) => {
          this.v2identifier.project_id = res.data.project_id
          this.v2identifier.ref = res.data.questions_to_ref[question.id]
        })
      })

      // Only set loaded to true when both requests have been successful
      return axios.all([axiosQuestion]).then(v => {
        if (this.destroyed) return
        this.setQuestion(question)
        // Manually call the first iteration, to make sure the getting predictions
        // placeholder is shown directly, not first the chart
        this.updateTrainingInfoText()
      }).catch((err) => {
        this.$maybeRaiseAPIPromiseErr(err)
        this.loadingFailed = true
      })
    },

    /**
     * Initialize the store with the loaded question & answers
     * @param {Object} question  Question object
     * @param {Array} answers    Array of answers
     */
    setQuestion (question) {
      if (this.destroyed) return
      if (!this.$hasPermission('projects_edit', question)) this.$store.commit('isNotEditable')

      this.$store.commit('setQuestion', question)
      // this.$store.commit('setAnswers', answers)

      this.resetEditQuestion()

      this.$store.commit('setBreadcrumbProps', {
        projectID: question.project,
        projectName: question.project_name,
        questionID: question.id,
        questionName: question.name
      })

      this.maybeAppendDemoInfoToURL({ 'has-codebook': String(Boolean(this.codes.length)) })

      if (this.question.is_training || this.question.training_requested) this.$store.dispatch('setTrainingRequestedTime', moment())
      if (this.editable) this.openWs(this.question.id)

      this.$nextTick(() => this.$store.dispatch('countCodes'))
    },

    /**
     * Load the charts which are somehow related to this question
     */
    loadViz () {
      this.viz.loading = true
      this.viz.failed = false

      let rCharts = api.get('/api/charts/').then((res) => {
        if (this.destroyed) return
        // Only keep charts that have this question
        let charts = res.data.filter(c => _.find(c.questions, qs => qs.id === this.questionID))
        this.$set(this.viz, 'charts', charts)
      })

      let rDashboards = api.get('/api/dashboards/').then((res) => {
        if (this.destroyed) return
        // Only keep dashboards that have charts that have datasets with this question
        let dashboards = res.data.filter(ds => ds.questions.indexOf(this.questionID) !== -1)
        this.$set(this.viz, 'dashboards', dashboards)
      })

      axios.all([rCharts, rDashboards]).then(() => {
        if (this.destroyed) return
        this.viz.loading = false
      }).catch((err) => {
        this.viz.loading = false
        this.viz.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /** ************************ UI METHODS ************************ **/

    /**
     * Select the chart type whose preview to display
     * @param  {String} chartType The chart type to display
     */
    selectChartType (chartType) {
      if (
        !this.availableChartsList[chartType].enabled
      ) {
        return
      }

      clearTimeout(this.chartTypeLoadingTimeout)
      this.chartTypeLoading = true

      this.chart.typeSelection = chartType
      this.chart.type = CHART_LIST[chartType].type
      this.prefillSettingValues(this.metaManager['ch__new'])
      this.applyDatasetSettings()
      this.prefillConfigValues()
      this.setDefaultAxisNames()

      this.callRegisterManager('update', chartType)
      // As the ChartScaffold has a delay of 1000ms to render the chart, show skeleton loader in the mean time
      this.chartTypeLoadingTimeout = setTimeout(() => { this.chartTypeLoading = false }, 1000)
    },

    /**
     * Reset all changes that the user has made in the edit form to the original state
     */
    resetEditQuestion () {
      _.each(this.editQuestionVals, (val, key) => { this.$set(this.editQuestionVals, key, this.question[key]) })
    },

    /**
     * Save the changes made in the edit form
     */
    saveEditQuestion () {
      // First run validation, only continue if it doesn't fail
      if (!this.$refs['edit-form'].validate()) return

      this.editQuestionStatus.saving = true
      this.editQuestionStatus.success = false
      this.editQuestionStatus.failed = false

      api.patch(`/api/questions/${this.question.id}`, this.editQuestionVals).then((res) => {
        // Set the question in the store with the new response received
        // This makes sure the check for edited properties works again
        this.$store.commit('setQuestion', res.data)
        // this.resetEditQuestion()
        this.editQuestionStatus.saving = false
        this.editQuestionStatus.success = true
      }).catch(err => {
        this.editQuestionStatus.saving = false
        this.editQuestionStatus.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })

      setTimeout(() => { this.editQuestionStatus.saving = false }, 5000)
    },

    /**
     * Clear the question's inheritance
     */
    resetInheritFrom () {
      this.editQuestionVals.inherits_from = null
      this.editQuestionVals.inherits_from_name = ''
      this.editQuestionStatus.inheritsFromProject = null
    },

    /**
     * Select question to inherit from inheritable dialog
     */
    openInheritDialog () {
      this.projectsInheritable.active = true
      // If the *project* (not question, we always have that) from which the question inherits is given
      // focus on that project. This is only the case if the user has already changed the inheritance in this view
      if (this.editQuestionStatus.inheritsFromProject) {
        this.projectsInheritable.initialProjectFocus = this.editQuestionStatus.inheritsFromProject
      // Otherwise, focus nothing initially
      } else this.projectsInheritable.initialProjectFocus = null
    },

    /**
     * Close the inheritance dialog and potentially set the selected question as inherited
     * @param  {Boolean||Object} result False if nothing was selected, the selected question otherwise
     */
    closeInheritDialog (result) {
      if (result !== false) {
        // Set the new inherited properties
        this.editQuestionVals.inherits_from = result.id
        this.editQuestionStatus.inheritsFromProject = result.project
        this.editQuestionVals.inherits_from_name = `${result.name}, ${result.project_obj.name}`
      }
      this.projectsInheritable.active = false
      this.projectsInheritable.initialProjectFocus = null
    },

    /**
     * Call the save answers method of the store
     */
    saveAnswers () {
      this.$store.dispatch('saveAnswers').then(res => {
        this.$store.commit('setLastSaved', this.$moment())
        console.log('answers saved')
      }).catch(err => {
        this.$root.snackMsg(this.$t('save_answers_error'))
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Call destructors
     */
    doDestroy () {
      if (!this.destroyed) {
        this.destroyed = true
        this.closeWs()
        window.removeEventListener('beforeunload', this.doDestroy)
      }
    },

    callRegisterManager (action, chartType = CHART_TYPE_BAR) {
      return this.$store.dispatch(
        `registerManager/${action}`,
        {
          entityId: 'ch__new',
          entityData: {
            config: _.cloneDeep(this.chart.config),
            datasets: _.cloneDeep(this.datasets),
            type: CHART_LIST[chartType].type,
            typeSelection: chartType
          }
        }
      )
    }
  }
}

</script>

<style lang=scss>

.cockpit {
  position: relative;

  .nav-container {
    display: flex;
  }

  .top-block {
    display: flex;
    max-width: 100%;

    .chart-teaser {
      flex: 1;
      background: #EEE;
      min-height: 40vh;

      .placeholder {
        display: flex;
        flex-direction: column;

        .v-alert {
          margin: 24px 24px;
          background: #FFF!important;
        }

        .placeholder-img {
          align-self: center;
          max-height: 40vh;
          margin-bottom: 24px;
        }

      }

      .chart-types{
        border-bottom: 1px solid rgb(232, 240, 247);
        margin-bottom: 1px;
      }

      .the-chart {
        min-height: 50vh;
        padding: 0;
        /* padding-bottom: 40px; */
        background: white;
        position: relative;
        height: 1px;
        overflow: hidden;

        & > div {
          height: calc(100% - 44px) !important;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .new-chart-btn {
          /* position: absolute; */
          /* bottom: 5px; */
          background: white;
          left: 50%;
          margin-left: -100px;
          z-index: 4;
        }

        .v-skeleton-loader {
          position: absolute;
          top: 32px;
          left: 32px;
          right: 32px;
          height: calc(100% - 64px);
          .v-skeleton-loader__image {
            height: 100%;
          }
        }
      }
    }
  }

  .bottom-block {
    margin-top: 16px;
    display: flex;
    .v-card__title {
      margin-bottom: 8px;
      background: #EEE;
    }
    .v-card:nth-child(2) { margin-left: 16px; }

    .edit {
      flex: 5;

      .edit-form {
        .inherits-from {
          margin-top: 16px;
          background: #EEE;
          &.none input {
            font-style: italic;
          }
          .v-input__append-inner {
            margin-top: 14px;
            .v-btn:first-child {
              margin-right: 4px;
            }
          }
        }
      }
    }
    .stats {
      flex: 3;
      max-width: 400px;
      .stats-container {
        max-height: 350px;
        overflow: auto;
      }
    }
  }

  .preview-card {
    margin-left: 16px;
    width: 400px;
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Cockpit.json' />
<i18n locale='de' src='@/i18n/de/pages/Cockpit.json' />
<i18n locale='en' src='@/i18n/en/components/coding/QuestionStats.json' />
<i18n locale='de' src='@/i18n/de/components/coding/QuestionStats.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />