/** Extends Error for HTTP Specific errors */
class HttpError extends Error {
  constructor (message) {
    super(message)
    this.name = this.constructor.name
  }
}

/** Extends HttpError for case we have too many request attempts */
class HttpMaxAttemptsError extends HttpError { }

export {
  HttpError,
  HttpMaxAttemptsError
}