<!-- ====== New topic dialog ===== -->
<template>
  <div>
    <v-overlay
      :value="newTopicDialog.open"
      color="rgba(5, 32, 46, 0.5)"
      :z-index="focusMode ? 203 : 201"
    />
    <v-dialog
      :value="newTopicDialog.open"
      @keydown.esc="closeDialog"
      persistent
      hide-overlay
      width="400px"
      content-class="dashboard-filter-editor"
    >
      <div>
        <div class="dashboard-filter-editor__title w-100 d-flex align-center justify-space-between">
          {{ $t('new_topic.title') }}
          <v-icon
            class="hover"
            @click.stop="closeDialog"
            :size="20"
          >
            mdi-close
          </v-icon>
        </div>
        <div class="dashboard-filter-editor__content pt-4">
          <label class="font-weight-medium text-xs mb-1">
            {{ $t('new_topic.name') }}
          </label>
          <v-text-field
            ref="name"
            :value="newTopicDialog.name"
            @input="value => $store.commit('setNewTopicDialog', { key: 'name', value })"
            :disabled="newTopicDialog.loading"
            :maxlength="MAX_TOPIC_LABEL_LEN"
            class="search-bar__search w-100"
            hide-details
            outlined
            dense
            :placeholder="$t('new_topic.name_placeholder')"
            single-line
            small
            :elevation="0"
          />
          <div class="pt-5">
            <label class="font-weight-medium text-xs mb-1">
              {{ $t('new_topic.category') }}
            </label>
            <v-autocomplete
              :value="newTopicDialog.category"
              @input="value => $store.commit('setNewTopicDialog', { key: 'category', value })"
              :search-input="newTopicDialog.search"
              :disabled="newTopicDialog.loading"
              @update:search-input="onCategorySearchChange"
              :items="categoryOptions"
              single-line
              class="search-bar__search search-bar__search--large search-bar__search--category-select w-100 text-xs"
              dense
              :elevation="0"
              :placeholder="$t('new_topic.category_placeholder')"
              :height="40"
              hide-details
              :maxlength="MAX_TOPIC_CATEGORY_LEN"
              :disable-lookup="true"
              :menu-props="{
                'offset-y': true,
                'ripple': false,
                'contentClass': 'p-0 search-bar__search--category__menu'
              }"
            >
              <template v-slot:selection="{ item }">
                <div
                  class="font-weight-medium text-xs"
                  :style="{ color: catColors[item] ? catColors[item].strong : 'black' }"
                >
                  {{ item }}
                </div>
              </template>

              <template v-slot:item="{ item, index }">
                <div
                  class="d-flex align-center justify-space-between
                  coding__drawer__code-select__dropdown__category__padding coding__drawer__code-select__dropdown__category w-100"
                  :key="index"
                  :style="{ color: catColors[item] ? catColors[item].strong : 'black', height: '40px' }"
                >
                  <div class="coding__drawer__code-select__dropdown__category__title font-weight-medium pl-0">
                    {{ item }}
                  </div>
                </div>
              </template>
            </v-autocomplete>

            <div
              v-if="!isListColumn"
              class="mt-4"
            >
              <v-tooltip
                bottom
                :disabled="!sentimentDisabled"
                max-width="300px"
              >
                <span v-html="$t('alert_topic_limit', { n: MAX_TOPICS })" />
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="w-100">
                    <v-switch
                      inset
                      color="green"
                      flat
                      :disabled="newTopicDialog.loading || sentimentDisabled"
                      :label="$t('topics.sentiment_enabled')"
                      dense
                      :ripple="false"
                      class="v-input--reverse v-input--description v-input--switch--small w-100"
                      hide-details
                      small
                      @change="value => $store.commit('setNewTopicDialog', { key: 'sentimentEnabled', value })"
                      :input-value="newTopicDialog.sentimentEnabled"
                    >
                      <template v-slot:label>
                        <div class="d-flex align-center">
                          <div class="text-xs font-weight-medium text-label">
                            {{ $t('topics.sentiment_enabled') }}
                          </div>
                        </div>
                      </template>
                    </v-switch>
                  </div>
                </template>
              </v-tooltip>
            </div>
          </div>
          <notification
            v-if="maxTopicsReached"
            type="warning"
            :title="$t('alert_topic_limit_title')"
            :text="$t('alert_topic_limit', { n: MAX_TOPICS })"
            class="mt-2"
          />
        </div>
        <div class="dashboard-filter-editor__actions d-flex justify-space-between">
          <div />
          <div>
            <v-btn
              outlined
              @click.stop="closeDialog"
              class="mr-2"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="disabled"
              :loading="newTopicDialog.loading"
              :class="disabled && 'disabled'"
              @click.stop="createNewTopic"
            >
              {{ $t('apply') }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

import Vuex from 'vuex'
import { MIN_TOPIC_LABEL_LEN, MAX_TOPIC_LABEL_LEN, MIN_TOPIC_CATEGORY_LEN, MAX_TOPIC_CATEGORY_LEN } from '@/settings/constants'
import { MAX_TOPICS } from '@/settings/overridable'

export default {
  codit: true,
  name: 'NewTopic',
  components: {},

  props: {
    value: { type: Object, default: () => ({}) },
    maxTopicsReached: { type: Boolean, default: false },
    sentimentDisabled: { type: Boolean, default: false }
  },

  data () {
    return {
      MAX_TOPIC_LABEL_LEN,
      MAX_TOPIC_CATEGORY_LEN,
      MAX_TOPICS
    }
  },

  computed: {
    ...Vuex.mapState({
      newTopicDialog: state => state.coding.newTopicDialog,
      focusMode: state => state.coding.focusMode
    }),

    ...Vuex.mapGetters([
      'topicCats',
      'catColors',
      'isListColumn'
    ]),

    /**
     * Invalid form state flag
     */
    disabled () {
      const { name, category, loading } = this.newTopicDialog

      if (this.maxTopicsReached) return true
      if (!name || name.length < MIN_TOPIC_LABEL_LEN) return true
      if (!category || category.length < MIN_TOPIC_CATEGORY_LEN) return true
      if (loading) return true

      return false
    },

    categoryOptions () {
      let options = [...this.topicCats]

      if (this.newTopicDialog.search && this.newTopicDialog.search.length) options.push(this.newTopicDialog.search.toUpperCase())

      if (this.newTopicDialog.category && this.newTopicDialog.category.length) options.push(this.newTopicDialog.category.toUpperCase())

      return options
    }
  },

  watch: {
    'newTopicDialog.open' (val, oldVal) {
      if (val && val !== oldVal) {
        this.$nextTick(() => {
          const theElement = this.$refs.name.$el
          const input = theElement.querySelector('input:not([type=hidden]),textarea:not([type=hidden])')
          if (input) {
            setTimeout(() => {
              input.focus()
            }, 0)
          }
        })
      }
    },

    sentimentDisabled: {
      immediate: true,
      handler (val) {
        this.$store.commit('setNewTopicDialog', { key: 'sentimentEnabled', value: !val })
      }
    }
  },

  methods: {
    /**
     * Cloes the dialog via the store
     */
    closeDialog () {
      this.$store.commit('setNewTopicDialog', { key: 'open', value: false })
      this.$store.commit('setNewTopicDialog', { key: 'name', value: '' })
      this.$store.commit('setNewTopicDialog', { key: 'category', value: null })
      this.$store.commit('setNewTopicDialog', { key: 'search', value: '' })
      this.$store.commit('setNewTopicDialog', { key: 'loading', value: false })
    },

    /**
     * Create the new topic
     */
    createNewTopic () {
      this.$store.commit('setNewTopicDialog', { key: 'loading', value: true })

      this.$emit('apply', this.newTopicDialog.name, this.newTopicDialog.category, undefined, '', [], true, this.sentimentDisabled || this.isListColumn ? false : this.newTopicDialog.sentimentEnabled)
    },

    /**
     * Handle a category search change
     */
    onCategorySearchChange (value) {
      if (value && value.length) this.$store.commit('setNewTopicDialog', { key: 'category', value: null })
      this.$nextTick(() => this.$store.commit('setNewTopicDialog', { key: 'search', value: value && value.toUpperCase() }))
    }
  }

}

</script>

<i18n locale='en' src='@/i18n/en/components/TopicsEditor.json' />
<i18n locale='de' src='@/i18n/de/components/TopicsEditor.json' />
<i18n locale='en' src='@/i18n/en/pages/Codingv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Codingv2.json' />