<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane
        v-model="config"
        v-bind="controlPaneProps"
        v-on="controlPaneEvents"
        ref="control-pane"
        slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
        <template v-slot:general>
          <settings-drawer-item
            :title="$t('controls.general.bar_orientation.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.barOrientation" hide-details class="mt-0 mb-2">
                <v-radio :label="$t('controls.general.bar_orientation.horizontal')" value="horizontal" />
                <v-radio :label="$t('controls.general.bar_orientation.vertical')" value="vertical" />
              </v-radio-group>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.ordinal_axis.plot_categories')"
          >
            <template slot="content">
              <v-tooltip bottom :disabled="!plotCategoriesDisabled">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-checkbox
                      v-model="config.plotCategories"
                      :disabled="plotCategoriesDisabled"
                      hide-details
                      :label="$t('controls.ordinal_axis.plot_categories')"
                    />
                  </div>
                </template>

                <span v-html="$t('controls.ordinal_axis.tooltip_plot_categories_disabled')" />
              </v-tooltip>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.general.stack.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.xField" column hide-details class="mb-0">
                <v-radio :label="$t('controls.general.stack.topics')" value="topicLabel" />
                <v-radio :label="$t('controls.general.stack.category')" value="topicCategory" />
              </v-radio-group>
              <v-checkbox
                v-model="config.stackDatasets"
                :label="$t('controls.general.stack_datasets')"
                :disabled="config.aggregate"
                v-if="datasets.length > 1"
                hide-details
                class="mt-1"
              />
              <div class="list-allow-overflow d-flex align-center justify-space-between mt-2">
                <v-checkbox
                  v-model="config.normStacks"
                  :disabled="maxStackDepth <= 1"
                  class="mt-0"
                  hide-details
                  :label="$t('controls.general.norm_stacks')"
                />
                <helptip position="top" :width="480" class="ml-auto">
                  <span v-html="$t('controls.general.norm_stacks_helptip')" />
                </helptip>
              </div>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:colors>
          <settings-drawer-item
            :title="$t('controls.colors.color_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.colorBy.field" column hide-details class="mt-0">
                <v-radio :label="$t('controls.colors.color_by.category')" value="category" />
                <v-radio :label="$t('controls.colors.color_by.topic')" value="topic" />
                <v-radio :label="$t('controls.colors.color_by.dataset')" value="dataset" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-tooltip bottom :disabled="!(maxStackDepth > 1)">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-checkbox
                      v-model="config.percentages"
                      :disabled="maxStackDepth > 1"
                      :label="$t('controls.value_axis.percentages')"
                    />
                  </div>
                </template>
                <span v-html="$t('controls.value_axis.tooltip_percentages_disabled')" />
              </v-tooltip>
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.labels.label_position.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.labelPosition" column hide-details class="mt-0">
                <v-tooltip bottom :disabled="!(maxStackDepth > 1 && !config.labelsTotal)">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-radio
                        :label="$t('controls.labels.label_position.outside')"
                        value="outside"
                        :disabled="maxStackDepth > 1 && !config.labelsTotal"
                      />
                    </div>
                  </template>

                  <span v-html="$t('controls.labels.tooltips.label_position_outside_disabled')" />
                </v-tooltip>

                <v-tooltip bottom :disabled="!config.labelsTotal">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-radio
                        :label="$t('controls.labels.label_position.inside')"
                        value="inside"
                        :disabled="config.labelsTotal"
                      />
                    </div>
                  </template>
                  <span v-html="$t('controls.labels.tooltips.label_position_inside_disabled')" />
                </v-tooltip>
              </v-radio-group>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.labels.total')"
          >
            <template slot="content">
              <v-tooltip bottom :disabled="!(maxStackDepth <= 1)">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-checkbox
                      v-model="config.labelsTotal"
                      :disabled="maxStackDepth <= 1"
                      hide-details
                      :label="$t('controls.labels.labels_total')"
                    />
                  </div>
                </template>
                <span v-html="$t('controls.labels.tooltips.total_disabled')" />
              </v-tooltip>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-tooltip
            bottom
            :disabled="colorByMapping === 'dsName' || !!config.aggregate || datasets.length === 1"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  v-model="config.showSampleSize"
                  :disabled="!config.aggregate && colorByMapping !== 'dsName' && datasets.length > 1"
                  hide-details
                  :label="$t('settings.sample_size.label_control')"
                  class="mt-2"
                />
              </div>
            </template>
            <span
              v-html="$t('settings.sample_size.tooltip_label_control_disabled_aggregated_or_segmented')"
            />
          </v-tooltip>
        </template>

        <template v-slot:ordinal-axis>
          <settings-drawer-item
            :title="$t('controls.ordinal_axis.sort_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.sortOrdinalBy" column hide-details class="mt-0">
                <v-radio :label="$t('controls.ordinal_axis.sort_by.none')" :value="null" />
                <v-radio :label="$t('controls.ordinal_axis.sort_by.frequency')" value="frequency" />
                <v-radio :label="$t('controls.ordinal_axis.sort_by.alphabet')" value="alphabet" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:value-axis-display>
          <v-checkbox
            v-model="config.reverseOrdinal"
            :label="$t('controls.ordinal_axis.reverse')"
            hide-details
          />
        </template>

        <!-- TODO: component of just slots, is there a better way ?? -->
        <slot name="datasets" slot="datasets" />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <slot name="chart-type-selection" slot="chart-type-selection" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <v-chart ref="chart"
                 v-if="initialReady"
                 :style="chartStyle"
                 v-bind="chartProps"
                 v-on="chartEvents"
        />
      </template>
    </chart-scaffold>

    <div>
      <v-dialog v-model="stackChangeWarning.show"
                v-if="!hideControls"
                content-class="chart-overlay-warning"
                @keydown.esc="stackChangeWarning.show = false"
      >
        <div>
          <alert type="warning" :class="'mt-0'" style="font-weight: 500">
            {{ $t('stack_warning.alert') }}
          </alert>

          <div class="warning-content">
            <div>
              <div class="title" v-html="$t('stack_warning.standalone.title')" />
              <div v-html="$t('stack_warning.standalone.description')" />
            </div>

            <v-icon class="mdi" :class="{ [stackChangeWarning.mode === 'stack' ? 'mdi-arrow-right' : 'mdi-arrow-left']: true }" />

            <div>
              <div class="title" v-html="$t('stack_warning.stacked.title')" />
              <div v-html="$t('stack_warning.stacked.description')" />
            </div>

            <div class="example">
              <em>{{ $t('stack_warning.example.intro') }}</em>
              <code-chip :color="$color.getMedium(0)"
                         :category="$t('stack_warning.example.category')"
                         :label="$t('stack_warning.example.code1')"
                         active
              />
              <code-chip :color="$color.getMedium(0)"
                         :category="$t('stack_warning.example.category')"
                         :label="$t('stack_warning.example.code2')"
                         active
              />
              <em>{{ $t('stack_warning.example.outro') }}</em> <span class="label">{{ $t('stack_warning.example.category') }}</span>
            </div>

            <div class="how-often">
              <code>{{ $t('stack_warning.standalone.how_often') }}</code>
            </div>

            <v-icon class="mdi" :class="{ [stackChangeWarning.mode === 'stack' ? 'mdi-arrow-right' : 'mdi-arrow-left']: true }" />

            <div class="how-often">
              <code>{{ $t('stack_warning.stacked.how_often') }}</code>
            </div>
          </div>
          <div class="d-flex justify-end w-100 pr-4 pb-1 pt-3">
            <v-btn @click="stackChangeWarning.show = false" color="primary" style="margin: 0 0 10px">
              {{ $t('stack_warning.btn_got_it') }}
            </v-btn>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'
import { encodeStr } from '@/utils/filters'
import {
  SENTIMENT_RANGE
} from '@/settings/constants'

import 'echarts/lib/chart/bar'

const CODE_SPLIT_TOK = '-&/_&/_-'

const ICON_PLUS_SVG = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\'%3E%3Cpath fill=\'%23999\' d=\'M19 19V5H5v14h14m0-16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-8 4h2v4h4v2h-4v4h-2v-4H7v-2h4V7z\'/%3E%3C/svg%3E'
const ICON_MINUS_SVG = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\'%3E%3Cpath fill=\'%23999\' d=\'M19 19V5H5v14h14m0-16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-2 8v2H7v-2h10z\'/%3E%3C/svg%3E'

export default {
  codit: true,
  name: 'ChartBar',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [chartMixin],

  data () {
    return {
      // Settings for chart.js mixin
      resultKeys: ['counts'],
      APItype: 'BAR',
      forceAggregate: false,
      // end settings

      config: {
        // General
        title: undefined,
        aggregate: undefined,
        barOrientation: 'horizontal',
        xField: 'topicLabel',
        xFieldGroup: {},
        stackDatasets: false,
        normStacks: false,
        dimensions: undefined,

        // Color
        colorBy: { field: 'category' },
        colorPalette: undefined,
        colorPaletteValue: undefined,

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255, 1)',

        // Ordinal axis
        ordinalAxisName: undefined,

        plotCategories: true,
        reverseOrdinal: undefined,
        sortOrdinalBy: 'frequency',

        // Value axis
        valueAxisName: undefined,
        valueAxisToFullData: false,

        // Grid line
        gridLine: true,

        percentages: false,

        // Labels
        dataLabelSize: 11,
        maxAxisLabelLength: 25,
        axisLabelSize: 11,
        labelsEnabled: true,
        labelPosition: 'outside',
        labelsTotal: false,
        showLegend: false,
        showSampleSize: true,
        decimalPlaces: 0,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        // Expansion of categories
        categoriesExpanded: {},

        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },

      availableSettingTiles: {
        ...this.availableSettingTiles,
        valueAxis: ['valueAxisName', 'valueAxisToFullData', 'axisLabelSize', 'maxAxisLabelLength']
      },

      stackChangeWarning: {
        show: false,
        mode: ''
      }
    }
  },

  computed: {
    minContainerHeight () {
      return this.isVertical ? 600 : 400
    },

    chartColStyle () {
      return {
        right: this.hasScrollBar ? '-15px' : '0px',
        'min-height': this.isVertical ? `${this.minContainerHeight + 100}px` : 'calc(100% - 100px)'
      }
    },

    chartContainerStyle () {
      return {
        'min-height': this.isHorizontal ? `${this.minCategoryDimSize + 100}px` : '',
        'min-width': this.isVertical ? `${this.minCategoryDimSize + 100}px` : ''
      }
    },

    /**
     * Mapping of the normal select values to the dataset columns
     * @return {String}
     */
    colorByMapping () {
      return {
        category: 'topicCategory',
        topic: 'topicLabel',
        dataset: 'dsName'
      }[this.config.colorBy.field]
    },

    /**
     * Mapping of the modified dataset names.
     * Ds names can be the same for several datasets
     * but in order to render it correctly and for chart renderer
     * these must be unique.
     * If there is a double, the name must be "<original name> <appearance index>"
     * @return {Array}
     */
    dsNamesByIndex () {
      const temp = new Map()

      return this.datasets
        .map(item => item.settings.name)
        .map(item => {
          const isIndex = temp.get(item)
          if (
            typeof isIndex === 'undefined'
          ) {
            temp.set(item, 0)
            return item
          } else {
            temp.set(item, isIndex + 1)
            return `${item} [${isIndex + 1}]`
          }
        })
    },

    /**
     * Makes sure topics of same category come after each other
     * performs all sorting if `plotCategories` is activated
     * @return {list} List of topics
     */
    topicsSorted () {
      if (!this.initialReady) return []

      let topics = _.clone(this.topics)
      if (
        this.config.sortOrdinalBy === 'frequency'
      ) {
        const topicsIds = this.topics.map(({ id }) => id)
        topics = this.topicsSortedByFrequency.filter(({ id }) => topicsIds.indexOf(id) > -1)
      } else if (
        this.config.sortOrdinalBy === 'alphabet'
      ) {
        topics = _.orderBy(topics, c => c.label.toLowerCase(), 'asc')
      }

      if (
        !this.config.plotCategories
      ) {
        return topics
      }

      let orderFunction
      let orderDirection = 'desc'

      if (
        this.config.sortOrdinalBy === 'frequency' &&
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 &&
        this.config.aggregate
      ) {
        orderFunction = g => this.results.counts.categoriesSentiment.reduce(
          (sum, category) => {
            return sum + category[g[0].category]?.[this.sentimentShown].counts.overall
          }, 0)
      } else if (
        this.config.sortOrdinalBy === 'frequency' &&
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
      ) {
        orderFunction = g => this.results.counts.categoriesSentiment.reduce(
          (sum, category, categoryIndex) => {
            return sum + category[g[0].category]?.[this.sentimentShown].counts.per_dataset?.[categoryIndex]
          }, 0)
      } else if (
        this.config.sortOrdinalBy === 'frequency'
      ) {
        orderFunction = g => this.results.counts.categories.reduce(
          (sum, category) => {
            return sum + category[g[0].category]
          }, 0)
      } else if (
        this.config.sortOrdinalBy === 'alphabet'
      ) {
        orderFunction = g => g[0].category.toLowerCase()
        orderDirection = 'asc'
      }

      return _.chain(topics).groupBy('category').orderBy(orderFunction, orderDirection).flatMap().value()
    },

    isHorizontal () { return this.config.barOrientation === 'horizontal' },
    isVertical () { return this.config.barOrientation === 'vertical' },

    isDataLabelInside () { return this.config.labelPosition === 'inside' },
    isDataLabelOutside () { return this.config.labelPosition === 'outside' },

    chartDataset () {
      if (!this.initialReady) return []

      let res = []
      let dsNamesUsed = {}

      const datasetsToPlot = this.config.aggregate && this.datasets.length ? [this.datasets[0]] : this.datasets

      datasetsToPlot.forEach((ds, dsIdx) => {
        let prevCat = null
        let dsName
        if (ds.settings.name in dsNamesUsed) dsName = `${ds.settings.name} [${dsNamesUsed[ds.settings.name]++}]`
        else (dsNamesUsed[ds.settings.name] = 1) && (dsName = ds.settings.name)

        const nRows = this.globalResults.counts?.overall || this.globalResults.counts?.per_dataset?.[dsIdx]

        this.topicsSorted.forEach(topic => {
          if (this.config.plotCategories && topic.category !== prevCat) {
            // Only add the topic categories if configs say we should, and if this is an unseen category
            let catIcon = _.get(this.config.categoriesExpanded, topic.category) ? 'min' : 'pls'

            let value

            if (
              SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 &&
              this.config.aggregate
            ) {
              value = this.results.counts.categoriesSentiment[dsIdx][topic.category]?.[this.sentimentShown].counts.overall
            } else if (
              SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
            ) {
              value = this.results.counts.categoriesSentiment[dsIdx][topic.category]?.[this.sentimentShown].counts.per_dataset?.[dsIdx]
            } else {
              value = this.results.counts.categories[dsIdx][topic.category]
            }

            let entry = {
              topicID: -100,
              topicLabel: `CAT${CODE_SPLIT_TOK}{${catIcon}|} ${topic.category}`,
              topicCategory: topic.category,
              count: value,
              perc: 100 * value / nRows,
              isCategory: true,
              dsName,
              dsIdx
            }

            entry.x = entry[this.config.xField]

            res.push(entry)
            prevCat = topic.category
          }

          // Push the topics if the category is expanded
          if (_.get(this.config.categoriesExpanded, topic.category) || !this.config.plotCategories) {
            let value
            if (
              SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 &&
              this.config.aggregate
            ) {
              value = this.results.counts.topics?.[dsIdx][topic.id]?.sentiments[this.sentimentShown].counts.overall
            } else if (
              SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
            ) {
              value = this.results.counts.topics?.[dsIdx][topic.id]?.sentiments[this.sentimentShown].counts.per_dataset?.[dsIdx]
            } else if (
              this.config.aggregate
            ) {
              value = this.results.counts.topics?.[dsIdx][topic.id]?.results.counts.overall
            } else {
              value = this.results.counts.topics?.[dsIdx][topic.id]?.results.counts.per_dataset?.[dsIdx]
            }

            let entry = {
              topicID: topic.id,
              topicLabel: `${topic.id}${CODE_SPLIT_TOK}{cod|${topic.label}}`,
              topicLabelOrig: topic.label,
              topicCategory: topic.category,
              topicDescription: topic.description,
              count: value,
              perc: 100 * value / nRows,
              isCategory: false,
              itemStyle: {
                opacity: this.config.plotCategories && this.colorByMapping !== 'topicLabel' ? 0.7 : 1.0
              },
              dsName,
              dsIdx
            }

            entry.x = entry[this.config.xField]
            res.push(entry)
          }
        })
      })

      if (this.doInternalReverse) {
        // it keeps datasets order, but change categories/codes order
        res = _.flatMap(Object.values(_.groupBy(res, 'dsIdx')).map(
          datasetItems => datasetItems.reverse()
        ))
      } else {
        res = _.flatMap(Object.values(_.groupBy(res, 'dsIdx')).reverse())
      }

      return res
    },

    chartDatasetsGrouped () {
      return _(this.chartDataset)
        .groupBy(row => row.dsName + (this.config.stackBy !== null ? String(row[this.config.stackBy]) : ''))
        .map((val, key) => ({ source: val }))
        .value()
    },

    doInternalReverse () {
      return (!this.config.reverseOrdinal && this.isHorizontal) || (this.config.reverseOrdinal && this.isVertical)
    },

    /**
     * The class names of the elements that define the colors (topics, categories, groups or datasets)
     * Ordered in the same way as in the chart
     * @return {Array}
     */
    colorClasses () {
      let classes = _.uniq(_.map(_.cloneDeep(this.chartDataset).reverse(), this.colorByMapping))

      if (
        this.colorByMapping === 'dsName'
      ) {
        classes.reverse()
      }
      // if (this.maxStackDepth > 1) classes = _.orderBy(classes)
      return classes
    },

    /**
     * The dataset indexes, as they are ordered in the chart (ordered by same logic as colorClass)
     * Empty array if ordering is not by dataset
     * @return {Array}
     */
    colorClassDatasetIndexes () {
      if (this.colorByMapping !== 'dsName') return []
      let classIdxs = _.uniq(_.map(this.chartDataset, 'dsIdx'))
      if (
        this.doInternalReverse &&
        this.colorByMapping !== 'dsName'
      ) {
        classIdxs.reverse()
      }
      // if (this.maxStackDepth > 1) classIdxs = _.orderBy(classIdxs, (val, idx) => this.colorClasses[idx])
      return classIdxs
    },

    ordinalAxis () {
      return {
        type: 'category',
        name: this.config.ordinalAxisName,
        axisTick: {
          show: false
        },

        triggerEvent: true,

        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        nameRotate: (this.isVertical ? 90 : 0),

        axisLabel: {
          show: true,
          rotate: (this.isVertical ? 90 : 0),
          align: 'right',
          interval: 0,
          rich: {
            // It's important that all rich elements have the same tag name length
            // as this is take into consideration when computing the correct padding
            cod: {
              fontSize: this.config.axisLabelSize,
              color: '#666'
            },
            pls: {
              height: 14,
              width: 14,
              align: 'right',
              verticalAlign: 'top',
              backgroundColor: {
                image: ICON_PLUS_SVG
              }
            },
            min: {
              height: 14,
              width: 14,
              align: 'right',
              verticalAlign: 'top',
              backgroundColor: {
                image: ICON_MINUS_SVG
              }
            }
          },

          fontSize: this.config.axisLabelSize,
          formatter: (lbl) => this.maybeTruncateLabel(
            // For stacking reasons, we have concatenated the ID with the topic label
            // (as topic labels might be identical for different topics)
            // Now separate them again to only get the topic labels
            this.config.xField === 'topicLabel' ? lbl.split(CODE_SPLIT_TOK)[1] : lbl,
            // Also, there is extra formatting in the topicLabels for the topics
            // Add that to the maximum label length that is allowed
            { maxLength: this.config.maxAxisLabelLength + (this.config.xField === 'topicLabel' ? (5 + lbl.startsWith('CAT') * 2) : 0) }
          )
        }
      }
    },

    valueField () {
      return this.config.normStacks ? 'pseudoPerc' : this.config.percentages ? 'perc' : 'count'
    },

    valueUnit () { return this.config.percentages || this.config.normStacks ? '%' : '' },

    /**
     * The stacked version of the chartDatasets.
     * If stacking is turned off:
     *   this is a mapping of the topic ID + name + datasetIdx to an array of 1 containing that element.
     *   The mapping will contain the same number of elements as the input array.
     *
     * If stacking is turned on:
     *   This is the mapping of the topic ID + name to an array of all the elements in that stack
     *   The mapping will contain number of input elements / number of datasets
     * @return {[type]} [description]
     */
    chartDatasetsStacked () {
      let res = _.groupBy(this.chartDataset,
        row => row.x + String(row.isCategory) + row[this.config.stackDatasets ? '.' : 'dsIdx']
      )

      // If we should norm the stacks
      // add the pseudoPerc property, to norm every stack to 100%
      if (this.config.normStacks) {
        _.each(res, (entries, key) => {
          let percTotal = _.sumBy(entries, 'perc')
          entries.forEach(e => {
            e.pseudoPerc = 100 * e.perc / percTotal
          })
        })
      }

      return res
    },

    maxStackDepth () {
      if (!this.ready) return undefined
      return _(this.chartDatasetsStacked).map((val, key) => val.length).max()
    },

    stacksInfo () {
      let res = {}
      _.each(this.chartDatasetsStacked, (val, key) => {
        let dsIdx = this.config.stackDatasets ? 0 : val[0].dsIdx
        res[val[0].x] || (res[val[0].x] = {})
        res[val[0].x][dsIdx] = {
          sum: _.sumBy(val, this.valueField),
          cnt: val.length,
          lastCodeID: val.slice(-1)[0].topicID }
      })
      return res
    },

    maxVal () {
      const dataMaxValue = this.config.percentages ? 100 : _.max(_.map(this.datasets, d => d.result && d.result.length))

      return this.config.valueAxisToFullData ? dataMaxValue : _.max(_.flatMap(this.stacksInfo, o => _.flatMap(o, v => v.sum)))
    },

    valueAxis () {
      const options = {
        name: this.config.valueAxisName,
        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        type: 'value',
        max: this.maxVal,
        triggerEvent: true,
        axisLabel: {
          fontSize: this.config.axisLabelSize,
          formatter: (value) => this.maybeTruncateLabel(
            `${Math.round(value)}${this.config.percentages || this.config.normStacks ? ' %' : ''}`,
            { maxLength: this.config.maxAxisLabelLength }
          )
        },
        splitLine: {
          show: this.config.gridLine
        }
      }
      // For showing sample size:
      if (
        this.config.aggregate &&
        this.config.showSampleSize &&
        typeof this.globalResults.counts?.overall === 'number'
      ) {
        options.name += ` (n=${this.globalResults.counts.overall})`
      } else if (
        !this.config.aggregate &&
        this.datasets.length === 1 &&
        this.config.showSampleSize &&
        typeof this.globalResults.counts?.per_dataset[0] === 'number'
      ) {
        options.name += ` (n=${this.globalResults.counts.per_dataset[0]})`
      }

      return options
    },

    xAxis () {
      let axis = (this.isVertical ? this.ordinalAxis : this.valueAxis)

      return {
        ...axis,
        nameLocation: 'end',
        nameTextStyle: {
          ...axis.nameTextStyle,
          align: 'right',
          verticalAlign: 'top',
          lineHeight: this.isVertical ? 0 : 60 + this.config.axisLabelSize * 2,
          padding: [0, 3, 0, 0]
        }
      }
    },

    yAxis () {
      const { isVertical, isDataLabelOutside, labelsVerticalFactor } = this
      const { labelsEnabled } = this.config
      let axis = this.isHorizontal ? this.ordinalAxis : this.valueAxis
      let nameTextStylePaddingBottom = 0
      if (isVertical && isDataLabelOutside && labelsEnabled) {
        nameTextStylePaddingBottom = labelsVerticalFactor * 8
      }
      return {
        ...axis,
        nameTextStyle: {
          ...axis.nameTextStyle,
          padding: [0, 0, nameTextStylePaddingBottom, 0]
        }
      }
    },

    minCategoryDimSize () {
      let nCategories = _.uniq(_.map(this.chartDataset, 'x')).length
      let barsPerGroup = this.chartDatasetsGrouped.length
      return nCategories * (10 + barsPerGroup * 8) + this.nLegendRows * 24 + 100
    },

    nLegendRows () {
      return (8 * _.sumBy(this.colorClasses, c => this.legendLabelFormatter(c).length) + this.colorClasses.length * 20) / (this.chartContainerWidth - 50)
    },

    /**
     * The color array, extended from the usual colors array
     * Handles two cases specially:
     * 1) When the coloring is according to the codebook colors, it returns the codebook colors
     * 2) When the coloring is according to the datasets, and datasets have the settings.color.overwrite setting activated,
     *    return the dataset color settings (settings.color.value)
     * @return {[type]} [description]
     */
    colorsIncludingCBAndDatasetColors () {
      if (this.colorByMapping === 'topicCategory' && this.config.colorPalette === '__cb_colors__') {
        return _.map(this.colorClasses, cc => this.catColors[cc].medium)
        // eslint-disable-next-line
      } else {
        let colors = _.clone(this.colors)
        if (this.colorByMapping === 'dsName') {
          colors = _.map(colors, (c, idx) => {
            let ds = this.datasets[this.colorClassDatasetIndexes[idx]]
            if (ds && ds.settings.color && ds.settings.color.override) return ds.settings.color.value
            else return c
          })
        }
        return colors
      }
    },

    visualMap () {
      return {
        show: this.config.showLegend,
        orient: 'horizontal',
        calculable: true,
        horizontal: true,
        left: 'center',
        top: 15,
        padding: [(this.hideControls ? 5 : 30), 0],
        categories: this.colorClasses,
        dimension: this.colorByMapping,
        inRange: {
          color: this.colorsIncludingCBAndDatasetColors,
          symbol: 'circle',
          symbolSize: 2
        },
        outOfRange: {
          color: 'transparent',
          opacity: 0
        },
        formatter: this.legendLabelFormatter
      }
    },

    grid () {
      const { isVertical, isHorizontal, nLegendRows, isDataLabelOutside, hideControls, labelsVerticalFactor } = this
      const { decimalPlaces, dataLabelSize, percentages, labelsEnabled, showLegend } = this.config
      const left = 15
      const bottom = 35
      let top = ((showLegend ? Math.ceil(nLegendRows) : 0) * 30) + (hideControls ? 40 : 65)
      let right = 30
      if (isHorizontal && isDataLabelOutside && labelsEnabled) {
        right = Math.floor((percentages ? ((2 + decimalPlaces) * 12) : 20) * dataLabelSize / 10)
      }
      if (isVertical && isDataLabelOutside && labelsEnabled) {
        top += labelsVerticalFactor * 8
      }
      return { top, left, bottom, right, containLabel: true }
    },

    barWidthEstimation () {
      return this.chartContainerWidth - this.grid.left - 0.1 * this.chartContainerWidth
    },

    labelsVerticalFactor () {
      const { decimalPlaces, dataLabelSize, percentages } = this.config
      return Math.floor((percentages ? (3 + decimalPlaces * 1.3) : 2) * dataLabelSize / 10)
    },

    series () {
      // Destructuring variables for reactivity (and simplicity)
      const {
        toFixed, isVertical, isDataLabelInside, isDataLabelOutside, chartDatasetsGrouped, valueField,
        stacksInfo, maxVal, valueUnit, labelsVerticalFactor, barWidthEstimation
      } = this
      const { labelsTotal, decimalPlaces, labelPosition, labelsEnabled, dataLabelSize, stackDatasets, xField } = this.config

      let offsetX = (isDataLabelOutside ? 1 : -1) * Math.floor(2 + (dataLabelSize / 2))
      let offsetY = (isDataLabelOutside ? 1 : -1) * labelsVerticalFactor * 3

      return _.map(chartDatasetsGrouped, (ds, dsIdx) => ({
        type: 'bar',
        top: 30,
        barGap: '10%',
        name: dsIdx,
        encode: {
          y: (isVertical ? valueField : 'x'),
          x: (isVertical ? 'x' : valueField)
        },

        label: {
          show: !!labelPosition && labelsEnabled,
          position: isDataLabelInside ? (isVertical ? 'insideTop' : 'insideRight') : (isVertical ? 'top' : 'right'),
          rotate: isVertical ? 90 : 0,
          offset: isVertical ? [offsetY, offsetX] : [0, 0],
          fontSize: dataLabelSize,
          distance: isVertical ? 12 : 0,
          padding: [2, 3],
          color: labelsTotal ? '#000' : undefined,
          formatter: (params) => {
            let val = valueField === 'perc' ? toFixed(params.data.perc, decimalPlaces) : params.data.count
            let stackInfo = stacksInfo[params.data.x][stackDatasets ? 0 : params.data.dsIdx]
            if (labelsTotal) return params.data.topicID === stackInfo.lastCodeID ? Math.round(stackInfo.sum) + valueUnit : ''
            else return (params.data[valueField] / maxVal * barWidthEstimation > 20 || isDataLabelOutside ? val + valueUnit : '')
          }
        },

        datasetIndex: dsIdx,
        stack: (xField !== 'topicLabel' || stackDatasets
          ? stackDatasets ? 'stack' : String(ds.source[0].dsIdx) : null),

        tooltip: {
          confine: true,
          formatter: (el) => {
            if (!el.value) return
            let res = `${this.$escapeHtml(el.value.dsName)}<br>${this.$escapeHtml(el.value.topicCategory)}`
            if (!el.value.isCategory) res += ` | ${this.$escapeHtml(el.value.topicLabelOrig)}`
            res += `: ${Math.round(el.value.count)} (${toFixed(el.value.perc, decimalPlaces)}%)`
            if (!el.value.isCategory && el.value.topicDescription) res += `<br>${this.$escapeHtml(el.value.topicDescription)}`
            return res
          }
        },

        itemStyle: {
          emphasis: {
            barBorderRadius: [5, 5]
          },
          normal: {
            barBorderRadius: [5, 5]
          }
        },

        animation: false
      }))
    },

    plotCategoriesDisabled () {
      return ['topicCategory', 'xFieldGroup'].indexOf(this.config.xField) !== -1
    },

    title () {
      return {
        text: this.config.title,
        triggerEvent: true,
        backgroundColor: 'transparent'
      }
    },

    chartOptions () {
      return {
        ...this.defaultOptions,
        dataset: this.chartDatasetsGrouped,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
        grid: this.grid,
        visualMap: this.visualMap,
        backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
      }
    }
  },

  watch: {
    'config.labelsTotal' (val) {
      if (val) this.config.labelPosition = 'outside'
      if (!val && this.maxStackDepth > 1 && this.config.labelPosition === 'outside') this.config.labelPosition = 'inside'
    },

    'maxStackDepth' (val, oldVal) {
      if (val > 1 && !this.config.labelsTotal) {
        this.config.labelPosition = 'inside'
        this.config.percentages = false
      }

      if (val <= 1) {
        this.config.labelsTotal = false
        this.config.normStacks = false
      }

      if (oldVal === 1 && val > 1) this.stackChangeWarning.mode = 'stack'
      if (oldVal > 1 && val === 1) this.stackChangeWarning.mode = 'unstack'
      // if (this.stackChangeWarning.mode) this.stackChangeWarning.show = true
    },
    'config.xField' (val, oldVal) {
      if (this.plotCategoriesDisabled) this.config.plotCategories = false
      if (val === 'topicCategory') this.config.colorBy.field = 'topic'
      if (val !== oldVal && this.stackChangeWarning.mode) this.stackChangeWarning.show = true
    },

    'config.plotCategoriesFilter.mode' (val, oldVal) {
      if (
        oldVal === 'all' &&
        this.config.xField === 'topicLabel'
      ) {
        this.config.plotCategories = true
        this.config.colorBy.field = 'category'
      }
    },

    'config.plotTopicsFilter.mode' (val, oldVal) {
      if (
        oldVal === 'all' &&
        this.config.xField === 'topicLabel'
      ) {
        this.config.plotCategories = true
        this.config.colorBy.field = 'category'
      }
    },

    'config.colorBy.field' (val) {
      this.config.showLegend = ['category', 'topic'].indexOf(val) === -1
    },

    'showTopNTopics' (val, old) {
      if (
        old !== null ||
        this.isDashboard
      ) {
        return
      }
      this.config.plotCategories = false
    }
  },

  methods: {
    legendLabelFormatter (lbl) {
      // For the topic / category labels, we have to do some ugly hacking to get the nicely formatted topic name back:
      // Remove all rich text formatting and separate by the topic splitter
      if (this.colorByMapping === 'topicLabel') {
        let lblCleaned = lbl.match(`^(.+)?${CODE_SPLIT_TOK}{cod\\|(.*)}$`, 'g')
        let catCleaned = lbl.match(`^.*\\{(min|pls)\\|\\} (.*)$`, 'g')
        lbl = lblCleaned ? lblCleaned[2] : catCleaned ? catCleaned[2] : lbl
      }

      // For showing segment size:
      // get index first and if data is already fetched, render it
      let dsIndexToGetCount
      if (
        !this.config.aggregate &&
        this.colorByMapping === 'dsName' &&
        this.config.showSampleSize &&
        this.datasets.length > 1 &&
        this.globalResults.counts?.per_dataset?.length
      ) {
        dsIndexToGetCount = this.dsNamesByIndex.findIndex(item => item === lbl)
      }

      if (
        typeof this.globalResults.counts?.per_dataset?.[dsIndexToGetCount] === 'number'
      ) {
        lbl += ` (n=${this.globalResults.counts.per_dataset[dsIndexToGetCount]})`
      }

      return this.maybeTruncateLabel(lbl, { maxLength: this.config.maxAxisLabelLength })
    },

    /**
     * Check if the user clicked on a category. If yes, toggle its visibility
     * @param  {String} target The label of the ordinal axis value item that was clicked
     */
    maybeToggleEntry (target) {
      // Split the label into the type (CAT or topic ID) and the rest
      let [type, value] = target.split(CODE_SPLIT_TOK)
      if (type === 'CAT') {
        // If it's the category, remove the icon to get the categories real name
        value = value.split('|} ')[1]
        // Toggle the value of the value in the categoriesExpanded array
        if (_.get(this.config.categoriesExpanded, value)) this.config.categoriesExpanded[value] = false
        else this.$set(this.config.categoriesExpanded, value, true)
      }
    },

    chartClick ($evt) {
      let filter, payload
      const sentiment = SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 ? this.sentimentShown : 'any'

      if ($evt.componentType === 'title') {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisLabel' &&
        this.isVertical
      ) {
        this.maybeToggleEntry($evt.value)
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisLabel' &&
        this.isHorizontal
      ) {
        this.maybeToggleEntry($evt.value)
      } else if (
        $evt.componentType === 'series' &&
        $evt.data.topicID === -100
      ) {
        let category = $evt.data.topicCategory
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.category.${encodeStr(category, true)}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(category)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        $evt.componentType === 'series'
      ) {
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${$evt.data.topicID}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml($evt.data.topicLabelOrig)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      }
      if (
        $evt.componentType !== 'series' ||
        $evt.data.count === 0 ||
        !this.isVerbatimEnabled
      ) {
        return
      }

      payload = {
        item: this.id || 'ch__new',
        filters: [filter]
      }

      if (
        !this.config.aggregate
      ) {
        payload.filters = [
          ...payload.filters,
          {
            type: 'dataset',
            value: [$evt.data.dsIdx],
            htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.segment')}</div>:&nbsp;${
              this.maybeTruncateLabel(this.$escapeHtml(this.datasets[$evt.data.dsIdx].settings.name), { maxLength: 25 })
            }`
          }
        ]
      }

      if ($evt.componentType === 'series') {
        this.$store.dispatch('verbatimDialog/show', payload)
      }
    }
  }
}

</script>

<style lang=scss>

@keyframes slide-in-left-opacity {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }

    15% {
      opacity: 0.1;
    }

    30% {
      transform: translateX(0);
      opacity: 0.9;
    }

    100% {
    }
}

@keyframes slide-in-right-opacity {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }

    15% {
      opacity: 0.1;
    }

    30% {
      transform: translateX(0);
      opacity: 0.9;
    }

    100% {
    }
}

.chart-overlay-warning {
  width: 100%;
  max-width: 860px;
  overflow: hidden;
  background: #FFF;

  .warning-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    padding: 0 15px;
    > div {
      flex: 1;
      background: #EEE;
      border-radius: 3px;
      padding: 12px 16px;
      margin-bottom: 12px;
      .title { margin-bottom: 5px }
    }

    .v-icon {
      flex: 0 0 auto;
      margin: 0 10px;
      font-size: 36px;
      color: var(--v-accent-darken1);
      animation: 2s cubic-bezier(0, 0, 0.1, 1.4) infinite;

      &.mdi-arrow-right {
        animation-name: slide-in-left-opacity;
      }

      &.mdi-arrow-left {
        animation-name: slide-in-right-opacity;
      }

    }

    .example {
      flex: 100%;
    }

    .codes-example {
      text-align: center;
    }

    .how-often {
      background: none;
      text-align: center;
      font-size: 24px;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartBar.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartBar.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />