import Vue from 'vue'

import Helptip from '@/components/Helptip'
import Truncate from '@/components/Truncate'
import Loading from '@/components/Loading'
import Alert from '@/components/Alert'
import CodeChip from '@/components/CodeChip'
import TopicChip from '@/components/TopicChip'
import AdvancedOptions from '@/components/AdvancedOptions'
import EmptyState from '@/components/EmptyState'
import EmptyStatev2 from '@/components/EmptyStatev2'
import ErrorState from '@/components/ErrorState'
import PageHeader from '@/components/PageHeader'
import InputFile from '@/components/InputFile'
import NumberField from '@/components/NumberField'
import NoPermission from '@/components/NoPermission'
import FeatureUnavailable from '@/components/FeatureUnavailable'
import Notification from '@/components/Notification'
import Modal from '@/components/Modal'
import CreditDeductionInfo from '@/components/CreditDeductionInfo.vue'

export function defineGlobalComponents () {
  Vue.component('truncate', Truncate)
  Vue.component('loading', Loading)
  Vue.component('helptip', Helptip)
  Vue.component('alert', Alert)
  Vue.component('code-chip', CodeChip)
  Vue.component('topic-chip', TopicChip)
  Vue.component('advanced-options', AdvancedOptions)
  Vue.component('empty-state', EmptyState)
  Vue.component('empty-state-v2', EmptyStatev2)
  Vue.component('error-state', ErrorState)
  Vue.component('input-file', InputFile)
  Vue.component('v-number-field', NumberField)
  Vue.component('feature-unavailable', FeatureUnavailable)
  Vue.component('no-permission', NoPermission)
  Vue.component('page-header', PageHeader)
  Vue.component('notification', Notification)
  Vue.component('modal', Modal)
  Vue.component('credit-deduction-info', CreditDeductionInfo)
}