<template>
  <v-card-text>
    <div class="type-select">
      <v-card class="element-option" @click="gotoAddElementStep('chart')">
        <v-card-title>
          <div class="title">
            {{ $t(`add_or_edit.type_select.chart.title`) }}
          </div>
          <div class="subtitle">
            {{ $t(`add_or_edit.type_select.chart.subtitle`) }}
          </div>
        </v-card-title>
        <v-responsive class="tile-img-container">
          <img
            src="@/assets/visualize/tile-graph.svg"
          >
        </v-responsive>
      </v-card>

      <v-card class="element-option" @click="gotoAddElementStep('text')">
        <v-card-title>
          <div class="title">
            {{ $t(`add_or_edit.type_select.text.title`) }}
          </div>
          <div class="subtitle">
            {{ $t(`add_or_edit.type_select.text.subtitle`) }}
          </div>
        </v-card-title>
        <v-responsive class="tile-img-container">
          <v-icon>mdi-text-shadow</v-icon>
        </v-responsive>
      </v-card>

      <v-card class="element-option" @click="gotoAddElementStep('verbatims')">
        <v-card-title>
          <div class="title">
            {{ $t(`add_or_edit.type_select.verbatim.title`) }}
          </div>
          <div class="subtitle">
            {{ $t(`add_or_edit.type_select.verbatim.subtitle`) }}
          </div>
        </v-card-title>
        <v-responsive class="tile-img-container">
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-responsive>
      </v-card>
    </div>
  </v-card-text>
</template>

<script>

export default {
  methods: {
    gotoAddElementStep (value) {
      this.$emit('gotoAddElementStep', value)
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />