<template>
  <div class="stats-container">
    <table>
      <tr>
        <th class="subtitle-1" colspan="2">
          {{ $t('stats.general') }}
        </th>
      </tr>
      <tr>
        <td>{{ $t('stats.owner') }}:&nbsp;</td>
        <td><code>{{ question.owner }}</code></td>
      </tr>
      <tr>
        <td>{{ $t('stats.created') }}:&nbsp;</td>
        <td><code>{{ question.created | datetimeverbose }}</code></td>
      </tr>
      <tr>
        <td>{{ $t('stats.last_modified') }}:&nbsp;</td>
        <td><code>{{ lastModifiedText }}</code></td>
      </tr>
      <tr>
        <td>{{ $t('stats.credits_billed') }}:&nbsp;</td>
        <td>
          <code>{{ (question.credits_total - question.credits_open) }}</code>
          <helptip position="bottom" :width="250">
            {{ $t('stats.credits_billed_helptip') }}:&nbsp;
          </helptip>
        </td>
      </tr>
      <tr>
        <td>{{ $t('stats.credits_open') }}:&nbsp;</td>
        <td>
          <code>{{ question.credits_open }}:&nbsp;</code>
          <helptip position="bottom" :width="250">
            {{ $t('stats.credits_open_helptip') }}
          </helptip>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="divider" />
      </tr>
      <tr>
        <th class="subtitle-1" colspan="2">
          {{ $t('stats.rows') }}
        </th>
      </tr>
      <tr>
        <td>{{ $t('stats.n_answers') }}:&nbsp;</td>
        <td><code>{{ innerStats.rows_count }}</code></td>
      </tr>
      <tr v-if="innerStats.rows_unique_count">
        <td>{{ $t('stats.n_answers_empty') }}:&nbsp;</td>
        <td><code>{{ innerStats.rows_empty_count }}</code></td>
      </tr>
      <tr v-if="innerStats.rows_unique_count">
        <td>{{ $t('stats.n_answers_unique') }}:&nbsp;</td>
        <td><code>{{ innerStats.rows_unique_count }}</code></td>
      </tr>
      <tr v-if="innerStats.rows_unique_count">
        <td>{{ $t('stats.number_reviewed') }}:&nbsp;</td>
        <td><code>{{ innerStats.rows_unique_reviewed_count }} ({{ reviewedPercentage }}%)</code></td>
      </tr>
      <tr v-if="innerStats.rows_changed_count">
        <td>{{ $t('stats.number_manual') }}:&nbsp;</td>
        <td><code>{{ innerStats.rows_changed_count }} ({{ 100 * innerStats.rows_changed_count / innerStats.rows_unique_count | round }}%)</code></td>
      </tr>
      <tr v-if="innerStats.rows_changed_count">
        <td>{{ $t('stats.number_nocodes') }}:&nbsp;</td>
        <td><code>{{ innerStats.rows_without_code_count }} ({{ 100 * innerStats.rows_without_code_count / innerStats.rows_unique_count | round }}%)</code></td>
      </tr>
      <tr>
        <td colspan="2" class="divider" />
      </tr>
      <tr>
        <td colspan="2" class="divider" />
      </tr>
      <template v-if="question.data_source">
        <template v-for="(item, index) in question.data_source.items">
          <tr :key="'1tr' + index">
            <th class="subtitle-1" colspan="2">
              {{ item.name }}
            </th>
          </tr>
          <tr :key="'2tr' + index">
            <td>
              {{ $t('stats.last_synced') }}
            </td>
            <td>
              <code>
                {{ $moment(item.last_synced).fromNow() }}
              </code>
            </td>
          </tr>
          <tr :key="'3tr' + index">
            <td>
              {{ $t('stats.sync_status') }}
            </td>
            <td>
              <code>
                {{ item.sync_status }}
              </code>
            </td>
          </tr>
          <tr :key="'4tr' + index">
            <td>
              {{ $t('stats.synced_count') }}
            </td>
            <td>
              <code>
                {{ item.last_synced_responses }}
              </code>
            </td>
          </tr>
          <tr :key="'5tr' + index">
            <td colspan="2" class="divider" />
          </tr>
        </template>
      </template>
      <template v-if="!question.no_training">
        <tr id="modelInfo" @click.shift="$emit('request-predictions')">
          <th class="subtitle-1">
            {{ $t('stats.model.title') }}
          </th>
        </tr>
        <tr>
          <td>{{ $t('stats.model.last_update') }}:&nbsp;</td>
          <template v-if="!questionTrainingCompleted">
            <td>{{ $t('stats.model.no_predictions') }}</td>
          </template>
          <template v-else>
            <td><code>{{ lastTrainedText }}</code></td>
          </template>
        </tr>
        <template v-if="score > 0">
          <tr>
            <td>{{ $t('stats.model.score') }}:&nbsp;</td>
            <td>
              <code>{{ score }}</code>
              <helptip position="top" :width="250">
                {{ $t('stats.model.score_helptip') }}
                <a href="https://caplena.com/docs/knowledge-base/b2f8d57b8eb95-fine-tuning-view#ai-score"
                   class="accent--text"
                   target="_blank"
                   v-text="$t('stats.model.score_helptip_link')"
                />.
              </helptip>
            </td>
          </tr>
          <tr v-if="stats.nPredicted">
            <td>{{ $t('stats.model.updated') }}:&nbsp;</td>
            <td><code>{{ stats.nPredicted }}</code></td>
          </tr>
          <tr>
            <td>{{ $t('stats.model.number_training') }}:&nbsp;</td>
            <td><code>{{ question.ntrainings }}</code></td>
          </tr>
        </template>
      </template>
    </table>
  </div>
</template>

<script>

import Vuex from 'vuex'

export default {
  codit: true,
  name: 'QuestionStats',

  data () {
    return {
      updateTimeIntervals: null,
      lastModifiedText: '',
      lastTrainedText: '',
      innerStats: {
        rows_unique_count: 0,
        rows_empty_count: 0,
        rows_count: 0,
        rows_reviewed_count: 0,
        rows_unique_reviewed_count: 0,
        rows_changed_count: 0,
        rows_without_code_count: 0
      }
    }
  },

  computed: {
    /**
     * The percentage of reviewed answers, only taking unique answers into account
     * @return {Number} The percentage as rounded Number
     */
    reviewedPercentage () {
      return (100 * this.innerStats.rows_unique_reviewed_count / this.innerStats.rows_unique_count).toFixed()
    },

    score () {
      return (
        this.question.model ? Math.max(this.question.model.score_remaining, this.question.model.score) * 100 : 0
      ).toFixed()
    },

    ...Vuex.mapState({
      question: state => state.coding.question,
      codes: state => state.coding.codes,
      stats: state => state.coding.stats,
      lastSaved: state => state.coding.lastSaved || state.coding.question.last_modified
    }),
    ...Vuex.mapGetters([
      'questionTrainingCompleted',
      'nextRetrainThresh',
      'prevRetrainThresh',
      'isListQuestion',
      'userCreditsRemaining',
      'codeCats',
      'codesByCat',
      'codesSorted',
      'codeID2Cat',
      'codeID2Idx',
      'nCodesTotal',
      'nAnswersInList'
    ])
  },

  watch: {
    lastSaved: 'updateTimeTexts',
    'question.training_completed': 'updateTimeTexts',
    question: {
      async handler () {
        try {
          const response = await api.get(`/api/ui/projects/${this.question.project}/to-refs`)
          const ref = response.data.questions_to_ref[this.question.id]

          if (!ref) return

          const statsResponse = await api.get(`/api/ui/projects/${this.question.project}/coding/${ref}/stats`)
          this.innerStats = statsResponse.data
        } catch (e) {
          this.$maybeRaiseAPIPromiseErr(e)
        }
      },
      immediate: true
    }
  },

  created () {
    this.updateTimeIntervals = setInterval(this.updateTimeTexts, 30000)
    this.updateTimeTexts()
  },

  beforeDestroy () {
    clearInterval(this.updateTimeIntervals)
  },

  methods: {
    updateTimeTexts () {
      this.lastModifiedText = this.$options.filters.fromnow(this.lastSaved)
      this.lastTrainedText = this.$options.filters.fromnow(this.question.training_completed)
    }
  }
}

</script>

<style lang=scss>

.stats-container {
  table {
    width: 100%;
    th { text-align: left }
    td.divider {
      height: 10px;
      border-bottom: 1px solid rgba(0,0,0,.12);
    }
    td:first-child {
      width: 200px;
      color: #9e9e9e;
    }
    td .helptip .anchor {
      vertical-align: middle;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/coding/QuestionStats.json' />
<i18n locale='de' src='@/i18n/de/components/coding/QuestionStats.json' />