export default {
  methods: {
    patchItem (chart) {
      return api.patch(`/api/charts/${chart.id}`, this.chartToAPIObject(chart)).then(res => {
        if (res.status >= 400) throw Error('Chart creation failed.')
        return res.data
      })
    },

    saveItem (chart) {
      return api.post(`/api/charts/`, this.chartToAPIObject(chart)).then(res => {
        if (res.status >= 400) throw Error('Chart creation failed.')
        return res.data
      })
    },

    async loadChart (id) {
      try {
        const res = await api.get(`/api/charts/${id}`)
        if (res.status !== 200) {
          throw Error('Failed to load chart')
        }
        return res.data
      } catch (error) {
        this.$maybeRaiseAPIPromiseErr(error)
      }
    }
  }
}