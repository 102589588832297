<template>
  <div class="advanced-options">
    <v-divider v-if="dividerTop" />
    <div class="control-container">
      <v-btn small fab @click="show=!show" class="advanced-options__button">
        <!--  style="margin: 7px -5px 0 0" -->
        <v-icon>{{ (shouldShow ? 'mdi-chevron-up' : 'mdi-chevron-down') }}</v-icon>
      </v-btn>
      <v-subheader v-html="subtitle" />
    </div>

    <slot v-if="shouldShow" />
    <v-divider v-if="dividerBottom" style="margin-top: 10px" />
  </div>
</template>

<script>
export default {
  codit: true,
  name: 'AdvancedOptions',
  props: {
    dividerTop: { type: Boolean, default: false },
    dividerBottom: { type: Boolean, default: false },
    text: { type: String, default: undefined },
    forceShow: { type: Boolean, default: false }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    subtitle () { return (this.text !== undefined ? this.text : this.$t('subtitle-1')) },
    shouldShow () { return this.forceShow || this.show }
  }
}
</script>

<i18n>
{
  "de": {
    "subtitle-1": "Erweiterte Optionen"
  },
  "en": {
    "subtitle-1": "Advanced options"
  }
}
</i18n>

<style lang=scss>

.advanced-options {
  > .v-divider {
    margin-bottom: 8px
  }
  .control-container {
    display: flex;
    justify-content: flex-start;
  }

  &__button {
    @extend .default-bs;
  }
}

</style>