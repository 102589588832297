<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane v-model="config"
                    v-bind="controlPaneProps"
                    v-on="controlPaneEvents"
                    ref="control-pane"
                    slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
        <template v-slot:general>
          <settings-drawer-item
            :title="$t('controls.general.show_pie')"
          >
            <template slot="content">
              <v-checkbox v-model="config.showPie"
                          hide-details
                          class="mt-0"
                          :label="$t('controls.general.show_pie')"
              />
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.general.sort_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.sortBy" column hide-details class="mt-0">
                <v-radio :label="$t('controls.general.sort_by.none')" :value="null" />
                <v-radio :label="$t('controls.general.sort_by.frequency')" value="frequency" />
                <v-radio :label="$t('controls.general.sort_by.alphabet')" value="alphabet" />
              </v-radio-group>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.general.yfield.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.yField.field" column hide-details class="mt-0">
                <v-radio :label="$t('controls.general.yfield.topics')" value="topics" />
                <v-radio :label="$t('controls.general.yfield.categories')" value="categories" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.percentages"
                :label="$t('controls.value_axis.percentages')"
                :disabled="config.chartType === 'stacked'"
              />
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:chart-type-selection>
          <slot name="chart-type-selection" />
          <!-- START: temporary date configuration slot (APP-535) -->
          <settings-drawer-item
            :title="$t('settings.date.select_column_label')"
          >
            <template slot="content">
              <div class="settings-selector">
                <div class="d-flex align-center justify-space-between">
                  <v-select
                    v-model="config.dateColumn"
                    :items="potentialDateColumnSelectItems"
                    :label="`${$t('settings.column.label')}`"
                    item-text="text"
                    style="max-width: 89%;"
                    item-value="value"
                    hide-details
                    outlined
                    dense
                  />
                  <helptip position="bottom" class="ml-3">
                    <span v-html="$t('settings.date.column_helptip')" />
                  </helptip>
                </div>
              </div>
            </template>
          </settings-drawer-item>
          <settings-drawer-item
            :title="$t('settings.date.select_aggregation_label')"
          >
            <template slot="content">
              <div class="settings-selector">
                <v-radio-group v-model="config.dateAggregation" column hide-details class="mt-0">
                  <v-radio v-for="opt in dateAggregationOptions" :key="opt" :label="$t(`settings.date.aggregations.${opt}`)" :value="opt" />
                </v-radio-group>
              </div>
            </template>
          </settings-drawer-item>
          <!-- END: temporary date configuration slot (APP-535) -->
        </template>

        <template v-slot:show-legend-count>
          <v-checkbox
            v-model="config.showSampleSize"
            hide-details
            :label="$t('settings.sample_size.label_control')"
            class="mt-2"
          />
        </template>

        <template v-slot:ordinal-axis>
          <settings-drawer-item
            :title="$t('controls.general.chart_type')"
          >
            <template slot="content">
              <v-radio-group v-model="config.chartType" column hide-details class="mb-2">
                <v-radio :label="$t('controls.value_axis.line')" value="line" />
                <v-radio :label="$t('controls.value_axis.stacked')" value="stacked" @change="setToPercentage" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>
        <slot
          name="datasets"
          slot="datasets"
          :dataset-props="{ disableFilters: true, duplicatable: false, editable: false }"
          :disable-master-settings="true"
        />
        <!-- <slot name="master-settings" slot="master-settings" /> -->
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <!-- <slot name="dataset-filters" slot="dataset-filters" /> -->
        <slot name="matches" slot="matches" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <v-chart
          ref="chart"
          v-if="initialReady"
          :style="chartStyle"
          v-bind="chartProps"
          v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'
import { generateSigleFunctionCallComputedWithSameArgs } from '@/utils/genericComputed.js'

import {
  DATE_AGG_ENUM,
  SENTIMENT_RANGE // eslint-disable-line
} from '@/settings/constants'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import { encodeStr } from '@/utils/filters'

import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'

export default {
  codit: true,
  name: 'ChartDateLinePie',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [chartMixin, auxiliaryColumnsMixin],

  data () {
    return {
      resultKeys: ['counts'],
      resultsComputedPerTick: {},
      APItype: 'DLINP',
      forceAggregate: false,

      dateAggregationOptions: Object.values(DATE_AGG_ENUM),

      config: {
        // General
        title: undefined,
        aggregate: undefined,
        yField: { field: 'categories' },
        sortBy: 'frequency',
        chartType: 'line',

        dimensions: undefined,
        showPie: true,

        // Color
        colorBy: {},
        colorPalette: undefined,
        colorPaletteValue: undefined,

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255,1)',

        // Ordinal axis
        ordinalAxisName: 'Datasets',
        reverseOrdinal: undefined,

        // Value axis
        valueAxisName: undefined,
        valueAxisToFullData: false,

        // Grid line
        gridLine: true,

        percentages: true,

        // Labels
        maxDataLabelLength: 25,
        labelsEnabled: true,
        dataLabelSize: 12,
        maxAxisLabelLength: 25,
        axisLabelSize: 12,
        decimalPlaces: 0,
        showLegend: true,
        showSampleSize: true,
        // labelPosition: 'outside',
        // labelsTotal: false,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        // date configs
        dateColumn: null,
        dateAggregation: 'MONTH',

        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },

      availableSettingTiles: {
        ...this.availableSettingTiles,
        valueAxis: ['valueAxisName', 'valueAxisToFullData']
      },

      chartContainerWidth: 0,
      pieSeriesIdx: 0,
      // to persist enabled legend items (not for saving)
      enabledLegends: {/* initial value is not important. eCharts checks for false values only */}
    }
  },

  computed: {
    ...generateSigleFunctionCallComputedWithSameArgs({
      potentialDateColumnSelectItems: 'getPotentialDateColumnSelectItems'
    })(function () {
      return [
        this.meta.auxiliary_column_metas,
        this.auxiliaryColumnNames
      ]
    }),

    /*
      Overrides default setting from chart.js mixin. This is because showing non-aggregated results on this chart with multiple datasets does nothing
    */
    aggregateDisabled () {
      return true
    },

    /**
     * Makes sure topics of same category come after each other
     * performs all sorting if `plotCategories` is activated
     * @return {list} List of topics
     */
    topicsSorted () {
      if (!this.initialReady) return []

      if (
        this.config.sortBy === 'alphabet'
      ) {
        return _.orderBy(this.topics, c => c.label.toLowerCase(), 'asc')
      } else if (
        this.config.sortBy === 'frequency'
      ) {
        const topTopicsIds = this.topicsSortedByFrequency.map(({ id }) => id)
        return this.topics.filter(({ id }) => topTopicsIds.indexOf(id) > -1)
      } else {
        return this.topics
      }
    },

    topicsSortedIds () {
      return this.topicsSorted.map(({ id }) => id)
    },

    topicCatsSorted () {
      if (!this.initialReady) return []

      let topicCats = _.keys(_.groupBy(
        this.topicsSorted,
        'category')
      )

      if (this.config.sortBy === 'alphabet') topicCats = _.orderBy(topicCats, c => c.toLowerCase())

      if (
        Object.keys(this.results.counts.categoriesRaw[0]).length &&
        this.config.sortBy === 'frequency'
      ) {
        topicCats = _.orderBy(
          topicCats,
          c => this.results.counts.categoriesRaw[0][c] && this.getDataItemSource(this.results.counts.categoriesRaw[0][c], this.sentimentShown),
          'desc'
        )
      }

      return topicCats
    },

    seriesNames () {
      let names
      switch (this.config.yField.field) {
        case 'topics':
          names = this.topicsSortedIds
          break
        case 'categories':
          names = this.topicCatsSorted
          break
        default:
          throw Error(`Unknown yField ${this.config.yField}!`)
      }
      return _.map(names, this.maybeTruncateLabel)
    },

    chartDataset () {
      if (
        ['topics', 'categories'].indexOf(this.config.yField.field) === -1
      ) {
        throw Error(`Unknown yField ${this.config.yField}!`)
      } else if (
        !this.initialReady
      ) {
        return []
      }

      const headerDataset = [
        'yfield',
        ...this.globalTicks.map(item => item)
      ]

      const calcPercentage = (value, total) => value ? 100 * value / total : 0
      const calcItems = (array, valuesArray, total, preprocess, initialValue) => array.reduce((sum, item) => {
        if (typeof valuesArray?.[0]?.[item] === 'undefined') return sum
        return [
          ...sum,
          [
            item,
            ...valuesArray.map(
              (mapIitem, index) => preprocess
                ? preprocess(mapIitem[item], total[index])
                : mapIitem[item]
            )
          ]
        ]
      }, [])

      if (
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 &&
        this.config.yField.field === 'categories'
      ) {
        return {
          counts: [
            headerDataset,
            ...calcItems(this.topicCatsSorted, this.resultsPerTick.counts.categoriesSentiment, [], item => item[this.sentimentShown])
          ],
          percentages: [
            headerDataset,
            ...calcItems(this.topicCatsSorted, this.resultsPerTick.counts.categoriesSentiment, this.globalResults.counts?.per_tick, (item, total) => calcPercentage(item[this.sentimentShown], total))
          ]
        }
      } else if (
        this.config.yField.field === 'categories'
      ) {
        return {
          counts: [
            headerDataset,
            ...calcItems(this.topicCatsSorted, this.resultsPerTick.counts.categories)
          ],
          percentages: [
            headerDataset,
            ...calcItems(this.topicCatsSorted, this.resultsPerTick.counts.categories, this.globalResults.counts?.per_tick, calcPercentage)
          ]
        }
      } else if (
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
      ) {
        return {
          counts: [
            headerDataset,
            ...calcItems(this.topicsSortedIds, this.resultsPerTick.counts.topicsSentiment, [], item => item[this.sentimentShown])
          ],
          percentages: [
            headerDataset,
            ...calcItems(this.topicsSortedIds, this.resultsPerTick.counts.topicsSentiment, this.globalResults.counts?.per_tick, (item, total) => calcPercentage(item[this.sentimentShown], total))
          ]
        }
      } else {
        return {
          counts: [
            headerDataset,
            ...calcItems(this.topicsSortedIds, this.resultsPerTick.counts.topics)
          ],
          percentages: [
            headerDataset,
            ...calcItems(this.topicsSortedIds, this.resultsPerTick.counts.topics, this.globalResults.counts?.per_tick, calcPercentage)
          ]
        }
      }
    },

    source () {
      return this.chartDataset[this.config.percentages ? 'percentages' : 'counts']
    },

    ordinalAxis () {
      // Destructuring variables for reactivity (and simplicity)
      const { maxAxisLabelLength } = this.config
      // counts of hidden labels after a visible label
      // 100 and 150 calculated by experimental
      const xAxisAvailableWidth = this.chartContainerWidth - 150
      const interval = Math.floor(this.globalTicks.length * 100 / xAxisAvailableWidth)
      // maximum available width for each label
      const maxLabelWidth = Math.round(xAxisAvailableWidth / this.datasets.length * (interval + 1))
      return {
        type: 'category',
        name: this.config.ordinalAxisName,
        nameLocation: this.config.reverseOrdinal ? 'start' : 'end',

        axisLabel: {
          interval,
          width: maxLabelWidth,
          fontSize: this.config.axisLabelSize,
          formatter: (lbl) => {
            let isFocused = this.globalTicks[this.pieSeriesIdx] === lbl
            lbl = this.maybeTruncateLabel(lbl, { maxLength: maxAxisLabelLength })
            return `{${isFocused ? 'selected' : 'normal'}|${lbl}}`
          },
          overflow: 'truncate',
          rich: {
            normal: {},
            selected: {
              fontWeight: 'bolder'
            }
          }
        },

        nameTextStyle: {
          fontStyle: 'italic',
          color: 'grey',
          align: 'right',
          verticalAlign: 'top',
          lineHeight: 30,
          fontSize: this.config.axisLabelSize,
          padding: [this.config.axisLabelSize + 5, 12, 0, 0] // 20px from above to prevent name overlap with axis labels
        },

        inverse: this.config.reverseOrdinal || false,
        triggerEvent: true,
        data: this.source[0].slice(1)
      }
    },

    valueAxis () {
      const options = {
        name: this.config.chartType === 'line' ? this.config.valueAxisName : '',
        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        triggerEvent: true,
        gridIndex: 0,
        axisLine: this.config.chartType === 'line' ? { show: true } : false,
        axisLabel: {
          fontSize: this.config.axisLabelSize,
          formatter: (value) => `${value}${this.valueUnit}`
        },
        splitLine: {
          show: this.config.gridLine
        }
      }

      if (this.config.valueAxisToFullData) {
        const dataMaxValue = this.config.percentages ? 100 : _.max(_.map(this.datasets, d => d.result.length))

        options.max = dataMaxValue
      }

      // For showing sample size:
      if (
        this.config.showSampleSize &&
        typeof this.globalResults.counts?.per_dataset?.[0] === 'number'
      ) {
        options.name += ` (n=${this.globalResults.counts?.per_dataset[0]})`
      }

      return options
    },

    xAxis () {
      return this.ordinalAxis
    },

    yAxis () {
      return this.valueAxis
    },

    minContainerWidth () {
      return this.seriesNames.length * 18 + 100
    },

    minContainerHeight () {
      return this.grid.top + 150
    },

    nLegendRows () {
      return (8 * _.sumBy([...this.seriesNames], c => c.length) + this.seriesNames.length * 20) / this.chartContainerWidth
    },

    legendHeight () {
      return ((this.config.showLegend ? Math.ceil(this.nLegendRows) : 0) * 30)
    },

    pieOffsetTop () {
      return this.legendHeight + (this.config.showPie ? 170 : 0)
    },

    valueUnit () { return this.config.percentages ? '%' : '' },

    grid () {
      return {
        top: this.pieOffsetTop + (this.config.showPie ? 1 : 0.5) * 200,
        left: 40 + (this.isHorizontal ? this.xLabelPadding : 0),
        bottom: 40 + (this.isVertical ? this.xLabelPadding : 0),
        right: 10,
        containLabel: true
      }
    },

    series () {
      // Destructuring variables for reactivity (and simplicity)
      const { decimalPlaces } = this.config
      const chartData = this.source.slice(1)

      let series = _.map(chartData, (ds, dsIdx) => {
        if (this.config.chartType === 'line') {
          return {
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            name: ds[0],
            animation: false
          }
        }

        if (this.config.chartType === 'stacked') {
          let stackDs = []
          ds.forEach((item, index) => {
            let stackTotal = this.stackChartTotal(chartData, index)
            let stackPercentage = ((ds[index + 1] / stackTotal) * 100)
            stackDs.push(stackPercentage)
          })
          return {
            name: ds[0],
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            data: stackDs,
            animation: false
          }
        }
      })

      if (this.config.showPie) {
        series.push({
          type: 'pie',
          id: 'pie',
          radius: '25%',
          center: ['50%', this.pieOffsetTop],
          itemStyle: {
            borderColor: '#FFF',
            borderWidth: 2
          },
          label: {
            show: this.config.labelsEnabled,
            formatter: (el) => {
              let cnt = this.chartDataset.counts[el.dataIndex + 1][this.pieSeriesIdx + 1]
              let perc = this.chartDataset.percentages[el.dataIndex + 1][this.pieSeriesIdx + 1]

              if (this.config.yField.field === 'topics') {
                const item = this.topics.find(({ id: topicId }) => topicId === el.name)
                return `${item.category} \n${item.label}: ${Math.round(cnt)} (${this.toFixed(perc, decimalPlaces)}%)`
              } else {
                return `${el.name}: ${Math.round(cnt)} (${this.toFixed(perc, decimalPlaces)}%)`
              }
            },
            fontSize: this.config.dataLabelSize
          },
          legendHoverLink: true,
          encode: {
            itemName: 'yfield',
            value: this.chartDataset.counts[0][this.pieSeriesIdx + 1],
            tooltip: this.chartDataset.counts[0][this.pieSeriesIdx + 1]
          },
          animation: false
        })
      }

      return series
    },

    /**
     * Return the results for the topics, categories for each dataset for each resultKey
     * Make sure an entry is returned for every dataset, even if that dataset is not really computed yet.
     * @return {Object}
     * Structure:
     * {
     *   topics: [{ ID1: {},}, { ...dataset2 }, ...]
     *   categories: [{ category1: categoryCount1, category2: categoryCount2}, { ...dataset2 }, ...]
     * }
     */
    resultsPerTick () {
      let res = {}
      _.each(this.resultKeys, key => {
        let catResultsAll = []
        let catResultsSentimentAll = []
        let topicResultsAll = []
        let topicsWithSentimentResultsAll = []

        if (this.resultsComputedPerTick[key]) {
          this.globalTicks.forEach((_, tckIdx) => {
            catResultsAll.push(this.resultsComputedPerTick[key].categories[tckIdx] || {})
            catResultsSentimentAll.push(this.resultsComputedPerTick[key].categoriesWithSentiment[tckIdx] || {})
            topicResultsAll.push(this.resultsComputedPerTick[key].topic[tckIdx] || {})
            topicsWithSentimentResultsAll.push(this.resultsComputedPerTick[key].topicsWithSentiment[tckIdx] || {})
          })
        }

        res[key] = {
          categories: catResultsAll,
          categoriesSentiment: catResultsSentimentAll,
          topics: topicResultsAll,
          topicsSentiment: topicsWithSentimentResultsAll
        }
      })
      return res
    },

    title () {
      return {
        text: this.config.title,
        triggerEvent: true
      }
    },

    legend () {
      return {
        show: this.config.showLegend,
        selected: this.enabledLegends,
        top: 30,
        formatter: (el) => {
          if (this.config.yField.field === 'topics') {
            const item = this.topics.find(({ id: topicId }) => topicId === el)
            return `${item.category}, ${item.label}`
          }
          return el
        }
      }
    },

    tooltip () {
      return {
        trigger: 'axis',
        showContent: true,
        confine: true,
        formatter: (params) => {
          // convert params to array when trigger is 'item' on tooltip options
          if (!Array.isArray(params)) params = [params]
          const stackedDatas = params.map((data, index) => {
            let cnt = this.chartDataset.counts[data.componentIndex + 1][this.pieSeriesIdx + 1]
            let perc = this.chartDataset.percentages[data.componentIndex + 1][this.pieSeriesIdx + 1]

            if (this.config.yField.field === 'topics') {
              const item = this.topics.find(({ id: topicId }) => topicId === data.seriesName)
              return {
                label: `<div style="display: table;">${data.marker}${this.$escapeHtml(item.category)}, ${this.$escapeHtml(item.label)}:<span style="margin-left:5px;">${this.toFixed(perc, this.config.decimalPlaces)}% (${cnt})<span></div>`,
                perc
              }
            } else {
              return {
                label: `<div style="display: table;">${data.marker}${this.$escapeHtml(data.seriesName)}:<span style="margin-left:5px;">${this.toFixed(perc, this.config.decimalPlaces)}% (${cnt})<span></div>`,
                perc
              }
            }
          })

          const stackedFormatter = _.sortBy(stackedDatas, 'perc').reverse().map(data => data.label).join('')

          return `<div>${params[0].name} ${stackedFormatter}</div>`
        }
      }
    },

    colorsIncludingCBColors () {
      if (this.config.colorPalette === '__cb_colors__' && this.config.yField.field === 'categories') {
        return _.map(this.topicCatsSorted, cat => this.catColors[cat].medium)
      } else if (
        this.config.colorPalette === '__cb_colors__'
      ) {
        return this.topicsSorted.map(({ category }) => this.catColors[category].medium)
      } else {
        return this.colors
      }
    },

    chartOptions () {
      if (this.initialReady) {
        return {
          ...this.defaultOptions,
          dataset: {
            source: this.source
          },
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          series: this.series,
          grid: this.grid,
          legend: this.legend,
          tooltip: this.tooltip,
          color: this.colorsIncludingCBColors,
          backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
        }
      }

      return {}
    }
  },

  watch: {
    /**
     * Hack, somehow the listener on the axisPointer seems to get lost again all the time
     * Therefore we need to set listener on & off all the time.
     */
    chartOptions: {
      immediate: false,
      handler (val) {
        if (this.$refs.chart && this.$refs.chart.chart) {
          this.$refs.chart.chart.off('updateAxisPointer', this.setPieSeries)
          setTimeout(() => { if (this.$refs.chart) this.$refs.chart.chart.on('updateAxisPointer', this.setPieSeries) }, 100)
        }
      }
    },

    resultsPerTick: {
      immediate: false,
      handler (val) {
        this.$nextTick(() => {
          if (this.$refs.chart) this.$refs.chart.refresh()
        })
      }
    },

    // 'chartDataset.overalIndex' () {
    //   this.pieSeriesIdx = this.chartDataset.overalIndex
    // },

    'config.controls.showTopNTopics' (newVal, oldVal) {
      if (newVal > 0 && !oldVal) {
        this.config.yField.field = 'topics'
      } else if (!newVal && this.config.yField.field === 'topics') {
        this.config.yField.field = 'categories'
      }
    },

    'config.dateColumn' (newVal, oldVal) {
      if (this.initialReady && !_.isEqual(newVal, oldVal)) {
        this.pieSeriesIdx = 0
        this.computeAllResults()
      }
    },

    'config.dateAggregation' (newVal, oldVal) {
      if (this.initialReady && !_.isEqual(newVal, oldVal)) {
        this.pieSeriesIdx = 0
        this.computeAllResults()
      }
    },

    'config.yField.field' () {
      this.pieSeriesIdx = 0
    }
  },

  methods: {
    // Set config percentage to true on Stacked chart active.
    setToPercentage () {
      this.config.percentages = true
    },

    /*
    * Callback for echarts to update selected legend items
    */
    legendSelectedChanged ($evt) {
      this.enabledLegends = $evt.selected
    },

    setPieSeries (event) {
      let xAxisInfo = event.axesInfo[0]
      if (xAxisInfo) {
        this.pieSeriesIdx = xAxisInfo.value
      }
    },

    /**
     * Get the sum of all category in the array
     * which is used to calculate the stacked area percentage of 100
     * @return {Total} categories
     */
    stackChartTotal (list, index) {
      let total = 0
      for (let i = 0; i < list.length; i++) {
        total += list[i][index + 1]
      }
      return total
    },

    /**
     * Create entries in results object for each of the defint resultKeys (e.g. counts, relative_impact etc.)
     */
    initResultsContainer () {
      this.resultKeys.forEach(key => {
        this.$set(this.resultsComputed, key, _.cloneDeep({
          categories: [],
          topic: [],
          categoriesWithSentiment: [],
          topicsWithSentiment: [],
          categoriesRaw: []
        }))
      })
      this.resultKeys.forEach(key => {
        this.$set(this.resultsComputedPerTick, key, _.cloneDeep({
          categories: [],
          topic: [],
          categoriesWithSentiment: [],
          topicsWithSentiment: [],
          categoriesRaw: []
        }))
      })
    },

    /**
    * prepares data after loading values from API (getRemoteResults)
    * @params {Object} req        request body from chartAPIObject
    * @params {Object} res        API response.data from /api/charts/:id/values
    */
    prepareRemoteResults (req, res) {
      const dsIdxes = this.getDsIdxes()
      _.each(this.resultKeys, key => {
        if (this.resultsComputed[key]) {
          // Reset the datasets array (when switching aggregate or changing number of datasets)

          dsIdxes.forEach(dsIdx => {
            this.emitDatasetResult({ req, res, dsIdx, key })
            this.computeResults({ req, res, dsIdx, key })
          })

          res.ticks.forEach((_, tckIdx) => {
            this.computeResultsPerTick({ req, res, tckIdx, key })
          })

          this.fixResultsData()
        }
      })
    },

    /**
    * emits chart result per  dataset
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     dsIdx {Number}:    dataset index
    *     key   {String}:    result key from resultKeys
    */
    emitDatasetResult ({ res, dsIdx }) {
      this.$emit('result', dsIdx, _.get(res.results_global.counts.per_dataset, dsIdx, 0))
    },

    /**
    * prepares the resultsComputed
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     tckIdx{Number}:    tick index
    *     key   {String}:    result key from resultKeys
    */
    computeResultsPerTick ({ res, tckIdx, key }) {
      let { catResult, topicResult } = this._getEmptyResultsContainer()

      // Set the category results
      catResult = res?.results_per_category?.reduce((sum, cat) => ({
        ...sum,
        [cat.category]: cat.results[key].per_tick[tckIdx]
      }), {})

      const catSentimentResult = res?.results_per_category?.reduce((sum, cat) => ({
        ...sum,
        [cat.category]: Object.entries(cat.sentiments).reduce((sum, [key, value]) => ({
          ...sum,
          [key]: value.counts.per_tick[tckIdx]
        }), {})
      }), {})

      // Set the topic results
      topicResult = res?.results_per_topic?.reduce((sum, topic) => {
        return {
          ...sum,
          [topic.id]: topic.results.counts.per_tick[tckIdx]
        }
      }, {})

      const topicSentimentResult = res?.results_per_topic?.reduce((sum, topic) => {
        return {
          ...sum,
          [topic.id]: Object.entries(topic.sentiments).reduce((sum, [key, value]) => ({
            ...sum,
            [key]: value.counts.per_tick[tckIdx]
          }), {})
        }
      }, {})

      this.$set(this.resultsComputedPerTick[key].categories, tckIdx, catResult)
      this.$set(this.resultsComputedPerTick[key].categoriesWithSentiment, tckIdx, catSentimentResult)
      this.$set(this.resultsComputedPerTick[key].topic, tckIdx, topicResult)
      this.$set(this.resultsComputedPerTick[key].topicsWithSentiment, tckIdx, topicSentimentResult)
    },

    fixResultsData () {
      const ids = this.topics.map(({ id }) => id)
      ids.forEach(id => {
        if (typeof this.resultsComputed.counts.topic[0][id] === 'undefined') return
        this.resultsComputed.counts.topic[0][id].results.counts.per_dataset[0] =
          _.sum(this.resultsComputedPerTick.counts.topic.map(tick => tick[id]))
      })
    },

    /**
    * Returns topic data source
    * corresponding the sentiment shown
    * @return {Object}
    */
    getDataItemSource (topic, sentiment) {
      if (
        SENTIMENT_RANGE.indexOf(sentiment) === -1
      ) {
        return topic.results
      } else {
        return topic.sentiments[sentiment]
      }
    },

    chartClick ($evt) {
      let filter, payload
      const sentiment = SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 ? this.sentimentShown : 'any'

      if (
        $evt.componentType === 'title'
      ) {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      }
      if (
        $evt.componentType !== 'series' ||
        !this.isVerbatimEnabled
      ) {
        return
      }

      let tick = this.chartDataset.counts[0][this.pieSeriesIdx + 1]
      let pieId, lineId

      if (
        $evt.componentSubType === 'pie'
      ) {
        pieId = $evt.name
      } else if (
        $evt.componentSubType === 'line'
      ) {
        lineId = $evt.seriesName
      }

      if (
        this.config.yField.field === 'topics' &&
        $evt.componentSubType === 'pie'
      ) {
        alert(123)
        const item = this.topics.find(({ id }) => id === pieId)
        filter = {
          type: 'text_to_analyze',
          value: `topic.${pieId}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml(item.label)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        this.config.yField.field === 'topics' &&
        $evt.componentSubType === 'line'
      ) {
        const item = this.topics.find(({ id }) => id === lineId)
        filter = {
          type: 'text_to_analyze',
          value: `topic.${lineId}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml(item.label)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        this.config.yField.field === 'categories' &&
        $evt.componentSubType === 'pie'
      ) {
        filter = {
          type: 'text_to_analyze',
          value: `topic.category.${encodeStr(pieId, true)}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(pieId)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        this.config.yField.field === 'categories' &&
        $evt.componentSubType === 'line'
      ) {
        filter = {
          type: 'text_to_analyze',
          value: `topic.category.${encodeStr(lineId, true)}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(lineId)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      }

      if (['line', 'pie'].indexOf($evt.componentSubType) === -1) {
        return
      }

      payload = {
        item: this.id || 'ch__new',
        filters: [
          filter,
          {
            type: 'tick',
            value: tick,
            htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.tick')}</div>:&nbsp;${tick}`
          }
        ]
      }

      this.$store.dispatch('verbatimDialog/show', payload)
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartLinePie.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartLinePie.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />