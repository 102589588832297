<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane v-model="config"
                    v-bind="controlPaneProps"
                    v-on="controlPaneEvents"
                    ref="control-pane"
                    slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
        <template v-slot:general>
          <settings-drawer-item
            :title="$t('controls.general.plot_values.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.plotValues.field" column hide-details class="mt-0 mb-0">
                <v-radio :label="$t('answer_fields.topics')" value="topics" />
                <v-radio :label="$t('controls.general.plot_values.categories')" value="categories" />
              </v-radio-group>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.general.symbol_size')"
          >
            <template slot="content">
              <v-slider v-model="config.symbolSize"
                        hide-details
                        min="2"
                        max="50"
                        thumb-label="always"
                        thumb-size="20"
                        class="mt-5"
              />
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.general.arrows_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.showArrows"
                :disabled="datasets.length < 2 || config.aggregate"
                :label="$t('controls.general.arrows')"
                hide-details
                class="mt-0 mb-0"
              />
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:colors>
          <settings-drawer-item
            :title="$t('controls.colors.color_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.colorBy.field" column hide-details class="mb-2">
                <v-radio
                  :label="$t('controls.colors.color_by.category')"
                  value="category"
                />
                <v-radio
                  :label="$t('controls.colors.color_by.topic')"
                  value="topic"
                />
                <v-radio
                  :label="$t('controls.colors.color_by.dataset')"
                  value="dataset"
                />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('settings.decimal_places.title')"
          >
            <template slot="content">
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-tooltip bottom :disabled="!!config.aggregate || datasets.length === 1">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  v-model="config.showSampleSize"
                  :disabled="!config.aggregate && datasets.length > 1"
                  hide-details
                  :label="$t('settings.sample_size.label_control')"
                  class="mt-2"
                />
              </div>
            </template>
            <span v-html="$t('settings.sample_size.tooltip_label_control_disabled_aggregated')" />
          </v-tooltip>
        </template>

        <template v-slot:axis>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox v-model="config.percentages"
                          :label="$t('controls.percentages')"
                          hide-details
              />
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.gridlines_label')"
          >
            <template slot="content">
              <v-checkbox v-model="config.gridLines"
                          :label="$t('controls.show_gridlines')"
                          hide-details
              />
            </template>
          </settings-drawer-item>
        </template>
        <slot name="datasets" slot="datasets" />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <template v-slot:chart-type-selection="{ warnings = {} }">
          <slot name="chart-type-selection" />
          <slot name="score" :warnings="warnings" />
        </template>
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <!-- <label @click="showPaneDrawer('axis')" class="scatter-y-axis-label hover">{{ config.yAxisName }}</label> -->
        <v-chart ref="chart"
                 v-if="initialReady"
                 :style="chartStyle"
                 v-bind="chartProps"
                 v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'
import { encodeStr } from '@/utils/filters'

import { mapGettersWithKey } from '@/utils/vuex.js'

import 'echarts/lib/chart/scatter'
import 'echarts/lib/component/polar'

import {
  SENTIMENT_RANGE
} from '@/settings/constants'

export default {
  codit: true,
  name: 'ChartDriverScatter',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [chartMixin],

  data () {
    return {
      resultKeys: ['counts', 'relative_impact'],
      APItype: 'DR_SC',
      forceAggregate: false,

      config: {
        // General
        title: undefined,
        aggregate: undefined,
        plotValues: { field: 'topics' },
        dimensions: undefined,
        showArrows: true,
        symbolSize: 12,

        // Labels
        maxDataLabelLength: 15,
        labelsEnabled: true,
        dataLabelSize: 12,
        axisLabelSize: 12,
        decimalPlaces: 0,
        showSampleSize: true,

        // Color
        colorBy: { field: 'category' },
        colorPalette: undefined,
        colorPaletteValue: undefined,

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255, 1)',

        // Axis values
        xAxisName: this.$t('x_axis'),
        yAxisName: this.$t('y_axis.counts'),
        gridLines: true,
        percentages: false,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },

      availableSettingTiles: {
        ...this.availableSettingTiles,
        ordinalAxis: ['ordinalAxisName', 'reverseOrdinal'],
        axis: ['xAxisName', 'yAxisName', 'axisLabelSize']
      },

      chartContainerWidth: 0
    }
  },

  computed: {
    /*
    *
    * VUEX REFERENCES
    *
    */
    ...mapGettersWithKey({
      topicCats2CatIdx: 'metaManager/topicCats2CatIdx'
    })(function () { return this.id }),

    /**
     * Some statistics on the extend of topics and drivers (min & max each)
     * @return {Object}
     */
    stats () {
      let count = { min: 0, max: 0 }
      let impact = { min: 0, max: 0 }

      _.forEach(this.results.counts?.[this.config.plotValues.field], (ds, dsIdx) => {
        _.each(ds, (item, valueKey) => {
          if (this.config.plotValues.field === 'topics' && !(valueKey in this.topicID2Topic)) return
          let value
          if (
            this.config.plotValues.field === 'topics' &&
            this.config.aggregate
          ) {
            value = _.max(Object.values(item.sentiments).map(item => item.counts.overall))
          } else if (
            this.config.plotValues.field === 'topics'
          ) {
            value = _.max(Object.values(item.sentiments).map(item => item.counts.per_dataset?.[dsIdx]))
          } else {
            value = item
          }
          count.max = Math.max(count.max, value)
          count.min = Math.min(count.min, value)
          let valueImpact = this.results.relative_impact[this.config.plotValues.field][dsIdx][valueKey]
          impact.max = Math.max(impact.max, valueImpact)
          impact.min = Math.min(impact.min, valueImpact)
        })
      })
      return { count, impact }
    },

    topicsSortedById () {
      return this.topics.map(({ id }) => id)
    },
    topicsSortedCatsById () {
      return this.topics.map(({ category }) => category)
    },

    /**
     * The chart datasets, combining count, relative_impact, color and label values
     * @return {Array}
     */
    chartDatasets () {
      const calculateItem = (valueCount, valueKey, dsIdx, sentiment) => {
        const nRows = this.globalResults?.counts.overall || this.globalResults?.counts?.per_dataset?.[dsIdx]
        let topic = _.find(this.topics, ({ id }) => valueCount?.id === id)

        let labelVal
        let color
        let cat
        let identifier

        if (
          this.config.plotValues.field === 'topics'
        ) {
          identifier = topic?.id
          cat = topic?.category
        } else {
          identifier = valueKey
          cat = valueKey
        }

        if (
          this.config.plotValues.field === 'topics' &&
          SENTIMENT_RANGE.indexOf(sentiment) > -1
        ) {
          // As this is remotely computed, it might be that the current topic
          // actually is not selected for plotting anymore. Skip it then.
          labelVal = {
            tooltip: `${topic[`sentiment_${sentiment}`].label} (${topic.label}, ${sentiment})`,
            grid: `${topic.category}\n${topic.label}`,
            verbatim: topic.label
          }
        } else if (
          this.config.plotValues.field === 'topics'
        ) {
          // As this is remotely computed, it might be that the current topic
          // actually is not selected for plotting anymore. Skip it then.
          labelVal = {
            tooltip: topic.label,
            grid: `${topic.category}\n${topic.label}`,
            verbatim: topic.label
          }
        } else {
          labelVal = {
            tooltip: valueKey,
            grid: valueKey
          }
        }

        // Set the coloring
        if (this.useCBColors) color = this.catColors[cat].medium
        // also by category
        else if (this.config.colorBy.field === 'category') {
          color = this.colors[this.topicCats2CatIdx[cat] % this.colors.length]
        } else if (this.config.colorBy.field === 'topic') {
          color = this.colors[idx % this.colors.length]
        } else if (this.config.colorBy.field === 'dataset') {
          let dsSettings = this.datasets[dsIdx].settings
          color = dsSettings.color && dsSettings.color.override ? dsSettings.color.value : this.colors[dsIdx % this.colors.length]
        } else throw Error(`Unknown colorBy field ${this.config.colorBy.field}`)

        let relativeImpact
        let value

        if (
          this.config.plotValues.field === 'topics' &&
          this.config.aggregate &&
          Object.keys(this.results.relative_impact[this.config.plotValues.field][dsIdx])?.length
        ) {
          relativeImpact = _.find(
            this.results.relative_impact[this.config.plotValues.field][dsIdx],
            ({ id }) => valueCount.id === id
          ).sentiments[sentiment].relative_impact?.overall
          value = _.find(
            this.results.relative_impact[this.config.plotValues.field][dsIdx],
            ({ id }) => valueCount.id === id
          ).sentiments[sentiment].counts?.overall
        } else if (
          this.config.plotValues.field === 'topics' &&
          Object.keys(this.results.relative_impact[this.config.plotValues.field][dsIdx])?.length
        ) {
          relativeImpact = _.find(
            this.results.relative_impact[this.config.plotValues.field]?.[dsIdx],
            ({ id }) => valueCount.id === id
          ).sentiments[sentiment]?.relative_impact?.per_dataset?.[dsIdx]
          value = _.find(
            this.results.relative_impact[this.config.plotValues.field]?.[dsIdx],
            ({ id }) => valueCount.id === id
          ).sentiments[sentiment]?.counts?.per_dataset?.[dsIdx]
        } else if (
          Object.keys(this.results.relative_impact[this.config.plotValues.field][dsIdx])?.length
        ) {
          relativeImpact = this.results.relative_impact[this.config.plotValues.field][dsIdx][valueKey]
          value = this.results.counts[this.config.plotValues.field][dsIdx][valueKey]
        }

        idx += 1
        let count = value

        let percentage = 100 * count / nRows
        return {
          identifier,
          value: [relativeImpact, this.config.percentages ? percentage : count],
          count,
          percentage,
          relativeImpact,
          label: {
            show: dsIdx === 0 && this.config.labelsEnabled, // only show labels for first dataset, the others can be related by arrows
            color: this.getStronger(color),
            ...labelVal,
            fontSize: this.config.dataLabelSize
          },
          itemStyle: {
            color
          },
          color,
          sentiment,
          sentiment_enabled: valueCount.sentiment_enabled,
          dsIdx: dsIdx
        }
      }
      if (!this.datasets.length || !this.globalResults.counts) return []

      let idx = 0

      let datasetsToPlot = this.config.aggregate ? [this.results.counts?.[this.config.plotValues.field][0]] : this.results.counts?.[this.config.plotValues.field]

      if (
        this.config.plotValues.field === 'topics'
      ) {
        datasetsToPlot = datasetsToPlot.map((ds, dsIdx) => {
          return Object.values(ds).filter(
            ({ id }) => this.topicsSortedById.indexOf(id) > -1
          )
        })
      } else {
        datasetsToPlot = datasetsToPlot.map((ds, dsIdx) => {
          return Object.entries(ds).filter(
            ([key, item]) => this.topicsSortedCatsById.indexOf(key) > -1
          ).reduce(
            (sum, [key, item]) => ({
              ...sum,
              [key]: item
            }), {}
          )
        })
      }

      let data = datasetsToPlot.map((ds, dsIdx) => {
        return _.map(ds, (item, index) => {
          if (
            this.config.plotValues.field === 'topics' &&
            !!item.sentiment_enabled
          ) {
            return SENTIMENT_RANGE.reduce((sum, sentiment) =>
              [...sum, calculateItem(item, index, dsIdx, sentiment)],
            [])
          } else if (
            this.config.plotValues.field === 'topics' &&
            !item.sentiment_enabled
          ) {
            return calculateItem(item, index, dsIdx, 'none')
          }
          return calculateItem(item, index, dsIdx)
        })
      })

      return data.map(
        item => Array.isArray(item) ? _.flatten(item).filter(item => item) : item.filter(item => item)
      )
    },

    /**
     * The data for the graph series, linking together the same elements of multiple datasets
     * @return {Object} Object { links, data }
     */
    changeArrowDatasets () {
      let links = []
      let data = []
      let idx = 0
      let epsX = this.chartContainerWidth / 2
      let epsY = this.chartContainerWidth / this.stats.count.max
      let minDist2 = 2000

      if (!this.config.aggregate && !this.remoteResults.loading && !this.valueManagerFetchState?.isLoading && !this.remoteResults.failed && !this.loading && this.chartDatasets.length) {
        for (let dsIdx = 0; dsIdx < (this.datasets.length - 1); dsIdx++) {
          for (let pointIdx = 0; pointIdx < this.chartDatasets[0].length; pointIdx++) {
            let p1 = this.chartDatasets[dsIdx][pointIdx]
            let p2 = this.chartDatasets[dsIdx + 1][pointIdx]

            if (p1 && p2) {
              let distX = epsX * (p1.value[0] - p2.value[0])
              let distY = epsY * (p1.value[1] - p2.value[1])
              // Only plot arrows that have minimum distance
              if ((distX ** 2 + distY ** 2) > minDist2) {
                data.push([p1.value[0], p1.value[1]], [p2.value[0], p2.value[1]])
                links.push({ source: idx, target: idx + 1 })
                idx += 2
              }
            }
          }
        }
      }

      return { links, data }
    },

    /**
     * The actual echarts series, combining the scatter dots and the arrows
     * @return {Array}
     */
    series () {
      return [
        ..._.map(this.chartDatasets, (ds, dsIdx) => ({
          name: this.datasets[dsIdx].settings.name,
          type: 'scatter',
          // percentages: this.config.percentages, // required to make it responsive
          label: {
            fontSize: this.config.dataLabelSize,
            position: 'right',
            formatter: (el) => this.maybeTruncateLabel(el.data.label.grid, { maxLength: this.config.maxDataLabelLength })
          },
          labelLayout: {
            hideOverlap: true
          },
          symbolSize: this.config.symbolSize,
          data: ds,
          color: ds[0] && ds[0].color,
          z: 10,
          animation: false
        })),
        {
          type: 'graph',
          show: false,
          edgeSymbol: ['none', 'arrow'],
          // coordinateSystem: 'calendar',
          links: this.changeArrowDatasets.links,
          coordinateSystem: 'cartesian2d',
          symbol: 'none',
          // calendarIndex: 0,
          lineStyle: {
            color: 'rgba(0, 0, 0, 0.3)',
            width: 1
          },
          tooltip: {
            show: false
          },
          data: this.config.showArrows ? this.changeArrowDatasets.data : [],
          z: 1,
          animation: false
        }
      ]
    },

    /**
     * Return true if the palette should be according to the colors defined on the topics
     * @return {Boolean}
     */
    useCBColors () {
      return this.config.plotValues.field !== 'groups' && this.config.colorPalette === '__cb_colors__'
    },

    /**
     * Title settings
     * @return {Object}
     */
    title () {
      const options = {
        ...this.defaultOptions.title,
        text: this.config.yAxisName,
        textStyle: {
          fontStyle: 'italic',
          fontSize: this.config.axisLabelSize,
          fontWeight: 'normal',
          color: 'grey',
          width: 200,
          align: 'right'
        },
        padding: [10, 3, 0, 0]
      }

      // For showing sample size:
      if (
        this.config.aggregate &&
        this.config.showSampleSize &&
        typeof this.globalResults.counts?.overall === 'number'
      ) {
        options.text += ` (n=${this.globalResults.counts.overall})`
      } else if (
        this.config.showSampleSize &&
        this.datasets.length === 1 &&
        typeof this.globalResults.counts?.per_dataset[0] === 'number'
      ) {
        options.text += ` (n=${this.globalResults.counts.per_dataset[0]})`
      }

      return options
    },

    ordinalAxis () {
      return {
        ...this.axisDefaults,
        name: this.config.xAxisName,
        nameLocation: 'end',
        nameTextStyle: {
          ...this.axisDefaults.nameTextStyle,
          align: 'right',
          verticalAlign: 'top',
          padding: [20, 0, 0, 0]
        },
        splitLine: {
          show: this.config.gridLines
        },
        axisLabel: {
          ...this.axisDefaults.axisLabel,
          formatter: (value) => `${value}${this.config.percentages ? '%' : ''}`,
          align: 'left',
          verticalAlign: 'top'
        },
        triggerEvent: true,
        z: 0
      }
    },

    valueAxis () {
      return {
        ...this.axisDefaults,
        name: '',
        nameLocation: 'end',
        nameTextStyle: {
          ...this.axisDefaults.nameTextStyle,
          // The padding is needed to put the label *below* the axis, and still make it clickable
          align: 'left',
          verticalAlign: 'bottom',
          lineHeight: 30 + this.config.axisLabelSize * 2
        }
      }
    },

    xAxis () {
      return this.ordinalAxis
    },

    yAxis () {
      return this.valueAxis
    },

    /**
     * Legend settings
     * @return {Object}
     */
    legend () {
      return {
        top: 30,
        show: this.datasets.length > 1 && !this.config.aggregate && this.config.colorBy.field === 'dataset',
        data: _.map(this.datasets, ds => ds.settings.name)
      }
    },

    /**
     * Formatter of the tooltip
     * @return {String}
     */
    tooltip () {
      return {
        formatter: (el) => {
          let v = `${this.$escapeHtml(el.data.label.tooltip)}`
          v += `<br>${this.$escapeHtml(this.config.yAxisName)}: ${Math.round(el.data.count)} (${this.toFixed(el.data.percentage)}%)`
          v += `<br>${this.$escapeHtml(this.config.xAxisName)}: ${Math.round(el.data.relativeImpact * 100) / 100}`
          return v
        },
        confine: true
      }
    },

    /**
     * Default axis settings
     * @return {Object}
     */
    axisDefaults () {
      return {
        splitLine: {
          show: this.config.gridLines
        },
        triggerEvent: true,
        nameTextStyle: {
          fontStyle: 'italic',
          color: 'grey',
          fontSize: this.config.axisLabelSize,
          lineHeight: 30 + this.config.axisLabelSize * 2
        },
        axisLabel: {
          fontSize: this.config.axisLabelSize
        }
      }
    },

    /**
     * The percentage of the chart which shows the "negative impact"
     * Required for hacky positioning of y-axis label
     * @return {Number} [0,1]
     */
    // percentageOfChartWhichIsNegative () {
    //   let min = Math.floor(this.stats.impact.min * 10) / 10
    //   let max = Math.ceil(this.stats.impact.max * 10) / 10
    //   if (min >= 0) return 0
    //   if (max <= 0) return 1
    //   return Math.abs(min) / (Math.abs(min) + max)
    // },

    grid () {
      return {
        left: 5,
        right: 15,
        bottom: 50,
        containLabel: true
      }
    },

    /**
     * The final chart options sent to echarts
     * @return {Object}
     */
    chartOptions () {
      let opts = {
        ...this.defaultOptions,
        series: this.series,
        tooltip: this.tooltip,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        grid: this.grid,
        title: this.title,
        legend: this.legend,
        backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
      }
      if (!this.useCBColors) opts.color = this.colors
      return opts
    }
  },

  watch: {
    initialReady: {
      immediate: false,
      handler () {
        setTimeout(() => {
          if (this && this.$refs.chart) this.$refs.chart.chart.on('updateAxisPointer', this.setPieSeries)
        }, 100)
      }
    }
  },

  methods: {
    /**
     * Handler for a single click chart event
     * @param  {Object} $evt
     */
    chartClick ($evt) {
      let filter, payload

      if (
        $evt.componentType === 'title' &&
        $evt.event.offsetY > 30
      ) {
        this.showPaneDrawer('axis')
      } else if (
        $evt.componentType === 'title'
      ) {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axis')
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axis')
      } else if (
        $evt.componentType === 'series' &&
        this.config.plotValues.field === 'categories' &&
        $evt.data
      ) {
        filter = {
          type: 'text_to_analyze',
          value: `topic.category.${encodeStr($evt.data.identifier, true)}:any`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml($evt.data.identifier)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;any`
        }
      } else if (
        $evt.componentType === 'series' &&
        this.config.plotValues.field === 'topics' &&
        $evt.data
      ) {
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${$evt.data.identifier}:${$evt.data.sentiment}`,
          htmlText: `<div class="font-weight-medium">Topic</div>:&nbsp;${this.$escapeHtml($evt.data.label.verbatim)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${$evt.data.sentiment}`
        }
      }

      if (
        $evt.componentType !== 'series' ||
        !$evt.data.count ||
        !this.isVerbatimEnabled
      ) {
        return
      }

      payload = {
        item: this.id || 'ch__new',
        filters: [filter]
      }

      if (
        !this.config.aggregate
      ) {
        payload.filters = [
          ...payload.filters,
          {
            type: 'dataset',
            value: [$evt.data.dsIdx],
            htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.segment')}</div>:&nbsp;${
              this.maybeTruncateLabel(this.$escapeHtml(this.datasets[$evt.data.dsIdx].settings.name), { maxLength: 25 })
            }`
          }
        ]
      }

      if ($evt.componentType === 'series') {
        this.$store.dispatch('verbatimDialog/show', payload)
      }
    }
  }
}
</script>

<style lang=scss>
  .scatter-y-axis-label {
    position: absolute;
    color: gray;
    font-size: 13px;
    font-style: italic;
    top: 50px;
    left: 54px;
    z-index: 10;
  }
</style>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartDriverScatter.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartDriverScatter.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />