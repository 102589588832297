/**
 * Override all properties of objOrig with the respective property of objOverride
 * Only override the keys that really exist on objOverride
 * Example:
 * overridePropsIfExistRecursive(
 *   { a: 1, b: { c: 2, d: 3 }},
 *   { b: { c: 3, f: 5 }}
 * ) == { a: 1, b: { c: 3, d: 3 }}
 * @param  {Object} objOrig     The original object -> only keys present here will be overwritten
 * @param  {Object} objOverride The override object -> only keys present here will be overwritten (haha, same)
 * @return {Object}
 */
const overridePropsIfExistRecursive = (objOrig, objOverride) => {
  _.each(objOrig, (val, key) => {
    // eslint-disable-next-line no-useless-return
    if (!objOverride[key]) return
    else if (_.isObject(val) && !_.isArray(val)) {
      overridePropsIfExistRecursive(objOrig[key], objOverride[key])
    } else objOrig[key] = _.cloneDeep(objOverride[key])
  })
}

const escapeStrForRegex = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

/**
 * Check if the keypress event will trigger a visible character to be created
 * @param  {Object} evt Event object
 * @return {Boolean}
 */
const nonCharacterKeys = new Set([8, 9, 27, 37, 39])
const isEventKeyACharacter = (evt) => {
  if (typeof evt.which === 'undefined') {
    // This is IE, which only fires keypress events for printable keys
    return true
  } else if (typeof evt.which === 'number' && evt.which > 0) {
    // In other browsers except old versions of WebKit, evt.which is
    // only greater than zero if the keypress is a printable key.
    // We need to filter out backspace and ctrl/alt/meta key combinations and tab / esc and arrow keys
    return !evt.ctrlKey && !evt.metaKey && !evt.altKey && !nonCharacterKeys.has(evt.which)
  }
  return false
}
/**
*
* Get chart preview image
* @param {string} chartType - The chart type ie: 'nps'
* @return {File} either svg or jpg image
*/
function getChartTileImage (chartType) {
  try {
    return require(`../assets/visualize/tile-${chartType}.svg`)
  } catch (e) {
    return require(`../assets/visualize/tile-${chartType}.jpg`)
  }
}

/**
*
* Get integration preview image
* @param {string} chartType - The chart type ie: 'nps'
* @return {File} either svg or jpg image
*/
function getIntegrationImage (path) {
  try {
    return require(`../assets/integrations/${path}.svg`)
  } catch (e) {
    return require(`../assets/integrations/${path}.png`)
  }
}

// https://tylercipriani.com/blog/2014/07/12/crossbrowser-javascript-scrollbar-detection/
function checkScrollbar () {
  // The Modern solution
  if (typeof window.innerWidth === 'number') { return window.innerWidth > document.documentElement.clientWidth }

  // rootElem for quirksmode
  const rootElem = document.documentElement || document.body

  // Check overflow style property on body for fauxscrollbars
  let overflowStyle

  if (typeof rootElem.currentStyle !== 'undefined') { overflowStyle = rootElem.currentStyle.overflow }

  overflowStyle = overflowStyle || window.getComputedStyle(rootElem, '').overflow

  // Also need to check the Y axis overflow
  let overflowYStyle

  if (typeof rootElem.currentStyle !== 'undefined') { overflowYStyle = rootElem.currentStyle.overflowY }

  overflowYStyle = overflowYStyle || window.getComputedStyle(rootElem, '').overflowY

  const contentOverflows = rootElem.scrollHeight > rootElem.clientHeight
  const overflowShown = /^(visible|auto)$/.test(overflowStyle) || /^(visible|auto)$/.test(overflowYStyle)
  const alwaysShowScroll = overflowStyle === 'scroll' || overflowYStyle === 'scroll'

  return (contentOverflows && overflowShown) || (alwaysShowScroll)
}

function omitDeep (collection, excludeKeys) {
  const clonedCollection = _.cloneDeep(collection)

  function omitFn (value) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach(key => {
        delete value[key]
      })
    }
  }

  return _.cloneDeepWith(clonedCollection, omitFn)
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference (object, base) {
  function changes (object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value
      }
    })
  }

  return changes(object, base)
}

/**
* https://stackoverflow.com/questions/21229180/convert-column-index-into-corresponding-column-letter
* Converts column number to excel letter
* @return {String}
*/
function columnToExcelLetter (columnIndex) {
  let temp
  let letter = ''

  while (columnIndex > 0) {
    temp = (columnIndex - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    columnIndex = (columnIndex - temp - 1) / 26
  }

  return letter
}

function truncateMiddle (fullStr, strLen, separator) {
  if (fullStr.length <= strLen) return fullStr

  separator = separator || '...'

  const sepLen = separator.length
  const charsToShow = strLen - sepLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
}

function isExcelFile (type) {
  const fileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.xlsx', '.xls']

  return _.includes(fileTypes, type)
}

function isElementVisible (el, holder) {
  if (!el) return

  const { top, bottom, height } = el.getBoundingClientRect()
  const holderRect = holder.getBoundingClientRect()

  return top <= holderRect.top ? holderRect.top - top + height <= height : bottom - holderRect.bottom + height <= height
}

function isValidLink (potentialLink) {
  var a = document.createElement('a')
  a.href = potentialLink
  return (a.host && a.host !== window.location.host)
}

export {
  overridePropsIfExistRecursive,
  escapeStrForRegex,
  isEventKeyACharacter,
  getChartTileImage,
  isElementVisible,
  getIntegrationImage,
  checkScrollbar,
  omitDeep,
  columnToExcelLetter,
  truncateMiddle,
  isExcelFile,
  difference,
  isValidLink
}
