<template>
  <div class="empty d-flex align-center justify-center text-center py-5" :className="className">
    <div>
      <div class="empty__icon mb-2">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16663 1.66675C13.3088 1.66675 16.6666 5.02461 16.6666 9.16675C16.6666 10.9374 16.053 12.5647 15.0269 13.8477L18.0892 16.9108C18.4147 17.2363 18.4147 17.7639 18.0892 18.0893C17.7888 18.3897 17.3161 18.4128 16.9892 18.1587L16.9107 18.0893L13.8476 15.027C12.5646 16.0532 10.9373 16.6667 9.16663 16.6667C5.02449 16.6667 1.66663 13.3089 1.66663 9.16675C1.66663 5.02461 5.02449 1.66675 9.16663 1.66675ZM9.16663 3.33341C5.94497 3.33341 3.33329 5.94509 3.33329 9.16675C3.33329 12.3884 5.94497 15.0001 9.16663 15.0001C12.3883 15.0001 15 12.3884 15 9.16675C15 5.94509 12.3883 3.33341 9.16663 3.33341Z" fill="#475467" />
        </svg>
      </div>
      <div class="empty__title font-weight-medium mb-1" v-html="title || this.$t('no_results')" />
      <div class="empty__subtitle text-sm text-grey" v-html="text || this.$t('no_results_subtitle')" />
      <v-btn
        v-if="action && action.length && actionClick"
        class="codebook-editor__category__new mt-3"
        depressed
        outlined
        @click="actionClick"
      >
        {{ action }}
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'EmptyStatev2',
  props: {
    title: { type: String, default: null },
    text: { type: String, default: null },
    className: { type: String, default: '' },
    action: { type: String, default: '' },
    actionClick: { type: Function, default: () => {} }
  },
  computed: {
  }
}

</script>

<style lang="scss">
  .empty {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      /* Gray/100 */
      background: #F2F4F7;
      /* Gray/50 */
      border: 8px solid #F9FAFB;
      border-radius: 28px;
      margin: 0 auto;
      margin-bottom: 6px;
    }

    &__title {

    }

    &__subtitle {
      max-width: 250px;
    }
  }
</style>