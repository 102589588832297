<template>
  <div class="color-picker elevation-12"
       :class="{ show: showInternal }"
       :style="{
         left: `${left}px`,
         top: `${top}px`
       }"
       v-c-click-outside="hide"
       @click.stop
  >
    <div class="arrow"
         ref="arrow"
         :class="arrowLocation"
         :style="{ top: `${offsetTop - top - 6 + indicatorHeight / 2}px` }"
    />
    <v-color-picker v-model="color"
                    mode="rgba"
                    :swatches="[colorPalettesAsHexA[palette]]"
                    show-swatches
                    flat
    />
    <v-radio-group v-model="palette"
                   :label="$t('color.palette.title')"
                   hide-details
                   class="palette-chooser"
    >
      <v-radio v-for="(p, name) in colorPalettes"
               :key="name"
               :label="$te(`color.palette.${name}`) ? $t(`color.palette.${name}`) : name"
               :value="name"
      />
    </v-radio-group>
  </div>
</template>

<script>

import colorPalettes from '@/mixins/colorPalettes'
import { colorToHexA, hexAtoRGBAColor } from '@/utils/colorUtils'

export default {
  codit: true,
  name: 'ColorPicker',

  mixins: [colorPalettes],

  props: {
    /**
     * The current rgba value of the selected color
     * @type {String}
     */
    value: {
      type: String,
      default: ''
    },

    /**
     * The left offset to the next relatively positioned parent element
     * @type {Number}
     */
    offsetLeft: {
      type: Number,
      default: 0
    },

    /**
     * The top offset to the next relatively positioned parent element
     * @type {Number}
     */
    offsetTop: {
      type: Number,
      default: 0
    },

    /**
     * The minimum value that `top` is allowed to take, by default 0
     * @type {Number}
     */
    minTop: {
      type: Number,
      default: 0
    },

    /**
     * The width of the indicator object, according to which the picker is positioned
     * @type {Number}
     */
    indicatorWidth: {
      type: Number,
      default: 22
    },

    /**
     * The height of the indicator object, according to which the picker is positioned
     * @type {Number}
     */
    indicatorHeight: {
      type: Number,
      default: 22
    },

    /**
     * If the color picker should be displayed or not
     * @type {Boolean}
     */
    show: {
      type: Boolean,
      default: false
    },

    /**
     * If true, the value will only be emitted when the color picker is closed
     * @type {Boolean}
     */
    lazy: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      // The value of the current color: Object({ r, g, b, a })
      // is the parsed value of the "value" prop
      color: '',
      // Internal state for the show prop
      showInternal: false,
      // If the value has been changed since the last time it was emitted
      valueIsDirty: false,
      // The selected color palette
      palette: 'default',

      // Should be a constant: The width of the color picker box
      widgetWidth: 300,
      arrowWidth: 12,

      // Function to emit the internal value in debounced fashion
      emitValueDebounced: _.debounce(() => {
        this.emitValue()
      }, 100)
    }
  },

  computed: {
    arrowLocation () {
      if (this.offsetLeftIfArrowRight > 0) return 'right'
      else return 'left'
    },

    offsetLeftIfArrowRight () {
      return this.offsetLeft - this.widgetWidth - this.arrowWidth - 2
    },

    offsetLeftIfArrowLeft () {
      return this.offsetLeft + this.arrowWidth + this.indicatorWidth + 2
    },

    left () {
      if (this.arrowLocation === 'right') return this.offsetLeftIfArrowRight
      else return this.offsetLeftIfArrowLeft
    },

    top () {
      return Math.max(this.offsetTop - 250, this.minTop) + 10
    },

    valueInternal () {
      return hexAtoRGBAColor(this.color)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        // When the value given by the parent component changes
        // parse it to the object and set the local color variable
        if (this.valueInternal !== this.value) {
          this.color = colorToHexA(this.value)
        }
      }
    },

    valueInternal () {
      // When the internal value changes, emit it (if not being in lazy mode)
      if (!this.lazy) this.emitValue()
      else {
        this.valueIsDirty = true
        this.emitValueDebounced()
      }
    },

    show: {
      immediate: true,
      handler () {
        if (this.show !== this.showInternal) this.showInternal = this.show
      }
    },

    showInternal () {
      this.$emit('update:show', this.showInternal)
      // If the color picker was hidden, and the internal value has not been emitted yet
      // (i.e. the model is updated lazily) emit the updated now
      if (!this.show && this.valueIsDirty) this.emitValue()
    }
  },

  methods: {
    hide () { this.showInternal = false },
    emitValue () {
      this.$emit('input', this.valueInternal)
      this.valueIsDirty = false
    }
  }
}

</script>

<style lang="scss">

.color-picker {
  border-radius: 4px;
  margin: 0 auto;
  position: absolute;
  visibility: hidden;
  transition: all 0.2s ease-out;
  opacity: 0;
  transform: scale(0.75, 0.75) translateY(-300px);
  background: #FFF;
  z-index: 2;
  padding: 2px;

  &.show {
    visibility: visible;
    opacity: 1;
    transform: scale(1, 1);
  }

  .arrow {
    position: absolute;
    content: '';
    width:0;
    height: 0;
    border:6px solid transparent;
    top: 61px;
    transition: all 0.2s ease-out;

    &.right {
      right:-12px;
      border-left-color:#333;
    }

    &.left {
      left:-12px;
      border-right-color:#333;
    }
  }

  .v-color-picker__swatch {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .v-color-picker__dot {
    overflow: visible;

    & > div {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 1px inset;
      border-radius: 50%;
    }
  }

  .v-label {
    font-size: smaller;
  }

  .palette-chooser {
    margin: 0 0 8px 16px;
  }
}

.color-indicator {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  box-shadow: rgba(255, 255, 255, .2) 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.2) 0px 0px 0px 1px inset;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  flex-shrink: 0;

  &::before {
    content: ' ';
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    border-radius: 100%;
  }
  > span {
    position: absolute;
    text-align: center;
    pointer-events: none;
    content: 'Color';
    font-size: 10px;
    top: 28px;
    color: rgba(0,0,0,.42);
    width: 100%;
    white-space: nowrap;
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/ColorPicker.json' />
<i18n locale='de' src='@/i18n/de/components/ColorPicker.json' />