<template>
  <DialogWrapper
    :value="true"
    :width="280"
    :closable="false"
  >
    <DialogHeader>
      <template slot="title">
        {{ $t('permission.no_permission_title') }}
      </template>
    </DialogHeader>
    <DialogContent
      class="px-4 py-5"
    >
      <span v-html="$t('permission.no_permission', { email: user.organization.root_user_email } )" />
    </DialogContent>
    <DialogActions>
      <template slot="rightSide">
        <v-btn
          outlined
          @click="goToMainPage()"
          class="mr-2"
        >
          {{ $t('to_main_page') }}
        </v-btn>
      </template>
    </DialogActions>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import Vuex from 'vuex'

export default {
  name: 'ManageIntegrations',
  codit: true,

  components: {
    DialogWrapper,
    DialogHeader,
    DialogContent,
    DialogActions
  },

  computed: {
    ...Vuex.mapState({
      user: 'user'
    })
  },

  methods: {
    goToMainPage () {
      this.$router.replace({
        name: 'home'
      })
    }
  }
}
</script>