<template>
  <DialogWrapper
    :value="true"
    :width="600"
    :closable="false"
  >
    <DialogHeader>
      <template slot="title">
        {{ $t('upload_not_allowed.title_trial') }}
      </template>
    </DialogHeader>
    <DialogContent
      class="px-4 py-5"
    >
      <p v-html="$t('upload_not_allowed.text_trial')" />
    </DialogContent>
    <DialogActions>
      <template slot="leftSide">
        <v-btn
          outlined
          @click="goToMainPage()"
          class="mr-2"
        >
          {{ $t('to_main_page') }}
        </v-btn>
      </template>
      <template slot="rightSide">
        <v-btn :href="MEETING_LINK"
               color="orange"
               dark
               target="_blank"
               class="mr-2"
        >
          {{ $t('upload_not_allowed.btn_book_meeting') }}
        </v-btn>
        <!-- <v-btn color="orange"
               dark
               outlined
               class="request-trial-now"
               exact
               @click="openTrialActivationSurvey"
        >
          {{ $t('upload_not_allowed.btn_activate_now') }}
        </v-btn> -->
      </template>
    </DialogActions>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import TrialActivationSurveyMixin from '@/mixins/trialActivationSurvey'

import { MEETING_LINK } from '@/settings/constants'

export default {
  name: 'ManageIntegrations',
  codit: true,

  components: {
    DialogWrapper,
    DialogHeader,
    DialogContent,
    DialogActions
  },

  mixins: [TrialActivationSurveyMixin],

  data () {
    return {
      MEETING_LINK
    }
  },

  methods: {
    goToMainPage () {
      this.$router.replace({
        name: 'home'
      })
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />