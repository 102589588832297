<template>
  <v-dialog v-model="showDialog"
            max-width="500"
            @keydown.esc="showDialog = false"
  >
    <v-card class="dialog-roles">
      <v-card-title class="headline grey lighten-2"
                    primary-title
                    v-text="$t('roles_browser.title')"
      />

      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel v-for="role in roles" :key="role.id">
            <v-expansion-panel-header :disable-icon-rotate="!role.available">
              {{ getRoleName(role) }}
              <template v-if="!role.available" v-slot:actions>
                <div class="tooltip tooltip-left" :data-tooltip="$t('roles_browser.not_in_plan')">
                  <v-icon>
                    mdi-cancel
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="description" v-html="getRoleDescription(role)" />
              <div class="details">
                <div class="details-title" v-text="$t('roles_browser.permission_details')" />
                <ul>
                  <li v-for="perm in role.permissions"
                      :key="perm"
                      v-html="$t(`permissions.${perm}`)"
                  />
                  <li v-html="$t('permissions.owner')" />
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'RolesBrowser',
  codit: true,

  props: {
    value: { type: Boolean, default: false },
    roles: { type: Array, default: () => [] }
  },

  computed: {
    showDialog: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  },

  methods: {
    getRoleName (role) {
      return this.$te(`roles.${role.name}.name`) ? this.$t(`roles.${role.name}.name`) : role.name
    },

    getRoleDescription (role) {
      if (role.owner) return this.$t('roles_browser.custom_role_description')
      else return this.$t(`roles.${role.name}.description`)
    }
  }
}

</script>

<style lang=scss>

.dialog-roles {
  .description {
    font-style: italic;
  }

  .v-expansion-panel-header--active {
    color: var(--v-primary-base);
    font-weight: bold;
  }

  .details {
    .details-title {
      margin-top: 12px;
      padding-top: 8px;
      border-top: 1px solid #DDD;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/Roles.json' />
<i18n locale='de' src='@/i18n/de/Roles.json' />