var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chart.type === 'P_TXT')?_c('div',{staticClass:"p-container w-100 h-100 d-flex flex-column justify-center"},[_c('div',{staticClass:"title",style:({
      color: _vm.chart.config.colorTitle,
      'text-align': _vm.chart.config.alignTitle
    })},[_vm._v("\n    "+_vm._s(_vm.chart.config.title)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"markdown-wrapper",style:({
      color: _vm.chart.config.colorText,
      'text-align': _vm.chart.config.alignText
    })},[_c('MarkdownRenderer',{attrs:{"text":_vm.chart.config.text}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }