import Vuex from 'vuex'

// Maximum number of ties we allow socket to fail before irreversibly closing session
const MAX_SOCKET_FAILED_CNT = 10
const MAX_INACTIVITY_S = 210

export default {
  data () {
    return {
      socket: {
        questionID: '',
        conn: null,
        failedCnt: 0,
        created: null,
        lastOnline: null,
        waiting: false,
        shouldClose: false,
        closeConnectionTimeout: null,
        takeoverSessionTimeout: null
      }
    }
  },

  created () {
    this.socket.created = this.$moment()
  },

  computed: {
    /**
     * The last activity resembles the last moment, where there definitely was a
     * connetion to the server: Either when the page was loaded, or when the last
     * websocket message was sent. We want to prevent situations, where the computer
     * sleeps for hours / days, then the connection re-opens because there was no failed
     * connection
     * @return {Object} moment js object
     */
    lastActivity () {
      if (this.socket.lastOnline !== null) return this.socket.lastOnline
      else return this.socket.created
    },

    ...Vuex.mapState({
      sessionIssue: state => state.coding.sessionIssue
    })
  },

  watch: {
    // Make sure the takeoverSessionTimeout is cleared when closing the takeover dialog
    'sessionIssue.show' (val) { if (!val && this.takeoverSessionTimeout !== null) clearTimeout(this.takeoverSessionTimeout) }
  },

  beforeDestroy () {
    // Make sure the takeoverSessionTimeout is cleared when leaving the page
    if (this.takeoverSessionTimeout !== null) clearTimeout(this.takeoverSessionTimeout)
  },

  methods: {
    /**
     * Close the websocket connection
     */
    closeWs () {
      this.socket.shouldClose = true
      if (this.socket.closeConnectionTimeout) clearTimeout(this.socket.closeConnectionTimeout)
      if (this.socket.conn) {
        console.log(`Closing q=${this.socket.questionID}?${this.socket.conn.connectionID}`)
        this.socket.conn.close()
      } else console.log(`websocket worker q=${this.socket.questionID} seems already closed...`)
    },

    /**
     * Check if the last active time has been too long ago
     * If yes, close the session and do not reopen it again
     * @return {Boolean} True if should be inactive, False if not
     */
    checkIfInactiveForTooLong () {
      if (moment().diff(this.lastActivity, 'seconds') > MAX_INACTIVITY_S) {
        console.log(`Closing session because of max inactivity time ${MAX_INACTIVITY_S}`)
        this.$store.commit('hasSessionIssue', 'connection_lost')
        this.closeWs()
        return true
      } else return false
    },

    /**
     * Open the websocket connection, keep it open and parse the messages
     * @param  {Number} questionID The question ID to open the worker for
     */
    openWs (questionID) {
      if (this.checkIfInactiveForTooLong()) return
      if (this.socket.questionID && this.socket.questionID !== questionID) {
        throw Error(`Tried opening websocket connection, where another question has already open connection: ${questionID} vs ${this.socket.questionID}`)
      }
      this.socket.questionID = questionID
      if (this.socket.shouldClose || this.destroyed || this.storeDestroyed) {
        console.log(`Socket q=${questionID} is dead / coding destroyed / wrong question. Not opening worker.`)
        this.closeWs()
        return
      }
      console.log(`Opening worker for q=${questionID}`)
      ws.open(`ws/question-worker/${questionID}/`).then(socket => {
        console.log(`Question worker q=${questionID}?${socket.connectionID} connection established`)
        if (this.socket.shouldClose) {
          socket.close()
          this.socket.conn = null
          return
        }
        if (this.socket.closeConnectionTimeout) clearTimeout(this.socket.closeConnectionTimeout)
        // Close the socket every 20 hours, to prevent error from being closed by server after 24h
        // and to check that authentication session is still valid
        this.socket.closeConnectionTimeout = setTimeout(() => {
          console.log(`Closing & reopening websocket worker q=${questionID}?${socket.connectionID} connection (long-lived connection)`)
          // Close the connection
          // But as the shouldClose flag is not set, the socket connection will automatically
          // tried to be re-established
          if (this.socket.conn) this.socket.conn.close()
          this.socket.closeConnectionTimeout = null
        }, 1000 * 3600 * 20)
        this.socket.conn = socket
        this.socket.failedCnt = 0
        socket.onappheartbeat = () => {
          if (!this.checkIfInactiveForTooLong()) this.socket.lastOnline = this.$moment()
        }
        socket.onappmessage = (data) => {
          this.socket.waiting = false
          if ('error' in data) throw new Error(`Socket returned error: ${data.error}`)
          if ('users_online' in data) this.$store.commit('setUsersOnline', data.users_online[questionID])
          if ('predictions' in data) this.setPredictions(data.predictions)
          switch (data.user_err) {
            case 'multiple_tabs':
              this.$store.commit('hasSessionIssue', 'multiple_tabs')
              this.closeWs()
              break
            case 'multiple_users':
              this.$store.commit('isNotEditable')
              this.$store.commit('hasSessionIssue', 'multiple_users')

              // Make sure the user cannot keep the takeover dialog open for ages, and then take over control
              // This might lead to inconsistencies in the data, as another user might have worked on the question
              // in the mean time. Therefore, close this takeover dialog after 60s when no action has been taken
              this.takeoverSessionTimeout = setTimeout(() => {
                if (this.sessionIssue && this.sessionIssue.type === 'multiple_users') {
                  this.$store.commit('closeSessionIssue')
                }
              }, 60000)
              break
            case 'takeover_by':
              this.$store.commit('isNotEditable')
              this.$store.commit('hasSessionIssue', 'takeover_by')
              this.closeWs()
              break
            case 'rows_uploaded':
              this.$store.commit('hasSessionIssue', 'rows_uploaded')
              this.closeWs()
              break
            case 'takeover_confirm':
              this.$store.commit('isEditable')
              this.$store.commit('hasSessionIssue', 'takeover_confirm')
              break
          }
        }

        socket.onclose = (event) => {
          this.socket.conn = null
          console.log(`Question worker connection q=${questionID}?${socket.connectionID} closed.`)
          if (event.wasClean && this.socket.shouldClose) return
          // Try reconnecting in 5 seconds
          console.error(`Question worker q=${questionID}?${socket.connectionID} closed unexpectedly. Code=${event.code} reason=${event.reason}. Reestablishing connection in 5s`)
          setTimeout(() => this.openWs(questionID), 5000)
        }
      }).catch(({ err, event }) => {
        if (event.status === 403 || event.status === 401) {
          console.error('Websockets got permission denied.')
          this.$root.on403()
        }
        this.socket.failedCnt += 1
        if (this.socket.failedCnt >= MAX_SOCKET_FAILED_CNT) {
          this.$store.commit('hasSessionIssue', 'connection_lost')
          this.$onError(new Error(`Failed to connect to socket q=${questionID} ${MAX_SOCKET_FAILED_CNT} times, stopping session`))
          // Make sure no attempt is made to re-open session
          this.closeWs()
        } else setTimeout(() => this.openWs(questionID), 5000)
        console.error(err)
        console.error(`Question worker ${questionID} failed to connect.`)
      })
    },

    /**
     * Take back control of the session if another user opened it in the meantime
     */
    retakeSessionControl () {
      this.socket.waiting = true
      this.socket.conn.send(JSON.stringify({ 'takeover_control': true }))
    },

    /**
     * Set the retrieved predictions
     */
    setPredictions (predictions) {
      this.$store.dispatch('setPredictions', {
        currentAnswerID: (this.idxFocused >= 0 ? this.ansFocused.id : -1),
        predictions: predictions
      })
    }
  }
}