<template>
  <div class="w-100">
    <div class="text-center w-100 d-flex align-center flex-column mb-5">
      <h1 v-if="!append && !replace" class="font-weight-medium text-center w-100">
        {{ $t('steps.organize.title') }}
      </h1>
      <h1 v-if="append" class="font-weight-medium text-center w-100">
        {{ $t('steps.append.organize_title') }}
      </h1>
      <h1 v-if="replace" class="font-weight-medium text-center w-100">
        {{ $t('steps.replace.organize_title') }}
      </h1>

      <div v-if="!append && !replace"
           class="mt-1 mr-2 grey--text text--darken-1 text-center w-100 apend-instructions"
           v-html="$t('steps.organize.subtitle')"
      />

      <div v-if="append"
           class="mt-1 mr-2 grey--text text--darken-1 text-center w-100 apend-instructions"
           v-html="$t('steps.append.organize_instructions')"
      />

      <div
        v-if="replace"
        v-html="$t('steps.replace.organize_instructions')"
        class="mt-1 mr-2 grey--text text--darken-1 text-center w-100 apend-instructions"
      />

      <div v-if="append || replace" class="d-flex align-center mt-1">
        <span
          v-for="(question, index) in project.questions"
          :key="index"
          class="font-weight-medium"
          :class="{'text-primary': index < questionsToAppend.length}"
          color="text-primary"
        >
          {{ question.name }}
          <span
            v-if="index + 1 < project.questions.length"
            class="mr-1"
            style="position: relative; right: 2px;"
          >
            ,
          </span>
        </span>
      </div>
    </div>
    <div class="d-flex w-100 d-flex justify-center">
      <div class="upload-container mr-3">
        <div class="organize-tabs d-flex mt-5">
          <div
            v-for="(sheet, index) in answersRes.sheet_names"
            @click="handleSheetChange(answersRes.sheet_names[index])"
            :class="{
              'active': (answersInput.sheetName === answersRes.sheet_names[index] || (!answersInput.sheetName && index === 0)),
              'disabled': loading.active
            }"
            :key="sheet"
            :disabled="loading.active"
            class="hover organize-tabs__tab mr-1"
          >
            {{ sheet }}
          </div>
        </div>
        <div class="organize-columns">
          <div
            v-if="!isIntegrationTab"
            class="w-100 pl-5 pr-5 pb-5 pt-5 organize-columns__container organize-columns__container--top-border d-flex align-center"
          >
            <v-select
              v-if="(answersRes.type==='csv' || answersRes.type==='txt') && !isInputTab"
              :value="answersInput.encoding"
              @change="handleEncodingChange"
              :label="$t('steps.organize.encoding')"
              :items="encodingOptions"
              class="organize-header__select mr-4 search-bar__search search-bar__search--large w-100 text-sm"
              outlined
              dense
              flat
              hide-details
            />
            <v-checkbox
              :input-value="answersInput.hasHeader"
              @change="handleHeaderChange"
              :label="$t('steps.organize.use_header')"
              :ripple="false"
              :disabled="loading.active"
              class="mt-0 mb-0 pt-0 organize-header__checkbox mr-4"
              primary
              hide-details
              flat
            />
            <v-checkbox
              v-if="append"
              :input-value="!answersInput.uploadIdenticalAnswers"
              @change="handleUploadIdenticalAnswersChange"
              :label="$t('steps.append.identical_answers')"
              :ripple="false"
              :disabled="loading.active"
              class="mt-0 mb-0 pt-0 organize-header__checkbox mr-2"
              primary
              hide-details
              flat
            />
            <helptip
              v-if="append && !isFastAppendAvailable"
              position="bottom"
              color="primary"
              :width="400"
            >
              <div
                class="warning--text"
              >
                {{ $t('steps.append.no_fast_append_controls_tooltip') }}
              </div>
            </helptip>

            <!-- <v-btn
              v-if="!replace && !append"
              color="primary"
              text
              small
              class="ml-auto pl-0 pr-0"
              @click="clearQuestions"
            >
              {{ $t('steps.organize.clear_questions') }}
            </v-btn> -->
            <div v-if="append" class="d-flex align-center ml-auto">
              <v-select
                :value="answersInput.matchingAuxilary"
                :label="$t('steps.append.auxilary_matching')"
                @change="handleMatchingChange"
                :items="matchingAuxilaryOptions"
                class="organize-header__select mr-2 search-bar__search search-bar__search--large w-100 text-sm"
                outlined
                clearable
                dense
                flat
                hide-details
              />
              <helptip
                position="bottom"
                color="primary"
                :width="400"
              >
                <div v-html="$t('steps.append.auxilary_matching_helptip')" />

                <div v-if="!isFastAppendAvailable" class="mt-3 warning--text">
                  {{ $t('steps.append.no_fast_append_controls_tooltip') }}
                </div>
              </helptip>
            </div>
          </div>

          <div v-if="loading.active" class="d-flex flex-column justify-center pt-5 pb-10 align-center">
            <loading
              v-model="loading.percentage"
              :is-loading="true"
              :dont-animate-entry="true"
              :title="$t('processing')"
              :tagline="loading.eta"
            />
          </div>
          <div
            v-else-if="filteredColumnsWithIdx.length"
            v-for="column in filteredColumnsWithIdx"
            :key="column.idx"
            class="w-100 pl-5 pr-5 organize-columns__container position-relative"
            :ref="`column-${column.idx}`"
          >
            <column
              :column="column"
              :answers-input="answersInput"
              :append="append"
              :replace="replace"
              :questions-to-append="questionsToAppend"
              :project="project"
              :get-type-label="getTypeLabel"
              :get-type-label-description="getTypeLabelDescription"
              :get-column-colors="getColumnColors"
              :get-column-label="getColumnLabel"
              @toggle-question-change="toggleColumnIsQuestion"
              @type-change="handleColumnTypeChange"
              @name-change="handleQuestionNameChange"
              @wording-change="handleQuestionWordingChange"
            />
          </div>
          <empty-state
            v-else
            slot="no-data"
            icon="mdi-playlist-remove"
            :label="$t('steps.organize.no_columns')"
          />
        </div>
      </div>
      <div
        class="upload-filters ml-3 position-relative"
        :style="answersRes.sheet_names ? 'margin-top: 65px;' : 'margin-top: 21px;'"
      >
        <div class="upload-filters__fixed">
          <v-autocomplete
            v-if="!append"
            :value="answersInput.search"
            :items="columnNames"
            @change="handleSearchChange"
            hide-details
            :label="$t('steps.organize.search')"
            append-icon="mdi-chevron-down"
            solo
            outlined
            class="mb-3 upload-filters__search search-bar__search search-bar__search--large"
            small
            dense
            height="40"
          />
          <h3
            v-if="!append"
            class="mb-1 text-lg"
          >
            {{ $t('steps.organize.filters') }}
          </h3>
          <div
            v-if="!append"
            class="upload-filters__fixed__container mb-4"
          >
            <div class="upload-filters__fixed__types pb-2">
              <div
                class="d-flex justify-space-between align-center pb-0 upload-filters__check upload-filters__check--all"
              >
                <div class="d-flex align-center">
                  <v-checkbox
                    :input-value="filters.allSelected"
                    @change="toggleAllTypeFilter"
                    class="mb-2 mt-2"
                    :label="'All'"
                    :ripple="false"
                    hide-details
                    flat
                  />
                </div>
                <div class="d-flex align-center">
                  <label
                    class="font-weight-medium upload-filters__check__count"
                  >
                    {{ answersRes.column_metas.length }}
                  </label>
                </div>
              </div>

              <div class="mb-2 mt-2">
                <div class="font-weight-medium text-sm mb-1 mt-1">
                  {{ $t('steps.organize.text_columns') }}
                </div>
                <div
                  v-for="columnType in textColumns"
                  :key="columnType"
                >
                  <div
                    class="d-flex justify-space-between align-center upload-filters__check"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        :input-value="filters.type[columnType]"
                        @change="() => toggleTypeFilter(columnType)"
                        class="mb-1 mt-0"
                        :label="getTypeLabel(columnType, false)"
                        :ripple="false"
                        :color="getColumnColors(columnType)"
                        hide-details
                        flat
                      >
                        <template v-slot:label>
                          <helptip
                            position="bottom"
                            class="mr-1"
                          >
                            <span v-html="getTypeLabelDescription(columnType)" />
                          </helptip>
                          {{ getTypeLabel(columnType, false) }}
                        </template>
                      </v-checkbox>
                    </div>
                    <div class="d-flex align-center">
                      <label
                        class="font-weight-medium upload-filters__check__count"
                        :style="`
                          color: ${getColumnColors(columnType)};
                          background-color: ${getColumnColors(columnType, true)};
                        `"
                      >
                        {{ countByColumnType(columnType) }}
                      </label>
                      <!-- <div
                        class="column-indicator column-indicator--filters ml-2"
                        :style="`background-color: ${getColumnColors(columnType)};`"
                      /> -->
                    </div>
                  </div>
                </div>
                <div class="font-weight-medium text-sm mt-2 mb-1">
                  {{ $t('steps.organize.non_text_columns') }}
                </div>
                <div
                  v-for="columnType in nonTextColumns"
                  :key="columnType"
                >
                  <div
                    class="d-flex justify-space-between align-center upload-filters__check"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        :input-value="filters.type[columnType]"
                        @change="() => toggleTypeFilter(columnType)"
                        class="mb-1 mt-0"
                        :label="getTypeLabel(columnType, false)"
                        :ripple="false"
                        :color="getColumnColors(columnType)"
                        hide-details
                        flat
                      >
                        <template v-slot:label>
                          <helptip
                            position="bottom"
                            class="mr-1"
                          >
                            <span v-html="getTypeLabelDescription(columnType)" />
                          </helptip>
                          {{ getTypeLabel(columnType, false) }}
                        </template>
                      </v-checkbox>
                    </div>
                    <div class="d-flex align-center">
                      <label
                        class="font-weight-medium upload-filters__check__count"
                        :style="`
                          color: ${getColumnColors(columnType)};
                          background-color: ${getColumnColors(columnType, true)};
                        `"
                      >
                        {{ countByColumnType(columnType) }}
                      </label>
                      <!-- <div
                        class="column-indicator column-indicator--filters ml-2"
                        :style="`background-color: ${getColumnColors(columnType)};`"
                      /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3 class="mb-1 text-lg">
            {{ $t('steps.organize.summary.title') }}
          </h3>
          <div
            v-if="replace"
            class="upload-filters__fixed__container upload-filters__fixed__container--summary mb-3"
          >
            <div class="d-flex align-center justify-space-between">
              <div v-html="$t('steps.replace.warnings.cols_identical')" />
              <b class="d-flex align-center">
                {{ colsIdentical.length }}
                <helptip
                  v-if="colsIdentical.length"
                  position="left"
                  color="primary"
                  class="ml-1"
                  :width="500"
                >
                  <ul class="disc">
                    <li v-for="item in colsIdentical" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </helptip>
              </b>
            </div>
            <div class="d-flex align-center justify-space-between">
              <div v-html="$t('steps.replace.warnings.cols_removed')" />
              <b class="d-flex align-center">
                {{ colsRemoved.length }}
                <helptip
                  v-if="colsRemoved.length"
                  position="left"
                  color="primary"
                  class="ml-1"
                  :width="500"
                >
                  <ul class="disc">
                    <li v-for="item in colsRemoved" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </helptip>
              </b>
            </div>
            <div class="d-flex align-center justify-space-between" style="border-bottom: 0;">
              <div v-html="$t('steps.replace.warnings.cols_new')" />
              <b class="d-flex align-center">
                {{ colsNew.length }}
                <helptip
                  v-if="colsNew.length"
                  position="left"
                  color="primary"
                  class="ml-1"
                  :width="500"
                >
                  <ul class="disc">
                    <li v-for="item in colsNew" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </helptip>
              </b>
            </div>
          </div>
          <div
            v-if="!append && !replace"
            class="upload-filters__fixed__container upload-filters__fixed__container--summary mb-3"
          >
            <div class="d-flex align-center justify-space-between">
              <div>
                {{ $t('steps.organize.summary.column_number') }}
              </div>
              <b>{{ answersRes.column_metas.length }}</b>
            </div>
            <div
              class="d-flex align-center justify-space-between"
              :style="!isIntegrationTab ? '' : 'border-bottom: 0;'"
            >
              <div>
                {{ $t('steps.organize.summary.row_number') }}
              </div>
              <b>{{ answersRes.n_rows }}</b>
            </div>
            <div
              v-if="!isIntegrationTab"
              class="d-flex align-center justify-space-between"
              style="border-bottom: 0;"
            >
              <div>
                {{ $t('steps.organize.summary.empty_row_number') }}
              </div>
              <b>{{ answersRes.dropped }}</b>
            </div>
          </div>
          <div
            v-if="append"
            class="upload-filters__fixed__container upload-filters__fixed__container--summary mb-3"
          >
            <div class="d-flex align-center justify-space-between mb-1">
              <div>
                {{ $t('steps.append.summary.n_rows') }}
              </div>
              <b>{{ nRows }}</b>
            </div>

            <div class="d-flex align-center justify-space-between mb-1">
              <div>
                {{ $t('steps.append.summary.identical_rows') }}
              </div>
              <b>{{ matching.n_matches || 0 }}</b>
            </div>

            <div class="d-flex align-center justify-space-between" style="border-bottom: 0;">
              <div>
                {{ $t('steps.organize.summary.empty_row_number') }}
              </div>
              <b>{{ answersRes.dropped }}</b>
            </div>
          </div>
          <credit-deduction-info
            v-if="!replace"
            :append="append"
            :is-integration-tab="isIntegrationTab"
            :credits-required="creditsToDeduct"
            :help-article-link="HELP_RESOURCE_CREDITS"
            :intro-text="$t('steps.settings.bill_on_upload_info')"
            show-info
          />

          <!-- COMMON ERRORS  -->
          <div class="upload-errors mb-0">
            <notification
              v-if="answersRes.error && !Array.isArray(answersRes.error)"
              type="error"
              text
              class="mb-0 mt-3 default-bs"
              border="left"
              dense
            >
              <span v-html="displayTextOrTranslation(answersRes.error, {
                nOld: replace ? project.questions[0].nanswers : projectColumnsLength,
                nNew: replace ? answersRes.n_rows : answersRes.column_metas.length
              })"
              />
            </notification>
            <notification
              v-else-if="answersRes.error && Array.isArray(answersRes.error)"
              v-for="(err, index) in answersRes.error"
              :key="index"
              type="error"
              text
              class="mb-0 mt-3 default-bs"
              border="left"
              dense
            >
              <span v-html="displayTextOrTranslation(err)" />
            </notification>
            <notification
              v-if="answersRes.warn && !Array.isArray(answersRes.warn)"
              type="warning"
              border="left"
              class="mb-0 mt-3 default-bs"
              text
              dense
            >
              <span v-html="displayTextOrTranslation(answersRes.warn)" />
            </notification>
            <notification
              v-else-if="answersRes.warn && Array.isArray(answersRes.warn)"
              v-for="(err, index) in answersRes.warn"
              :key="index"
              type="warning"
              border="left"
              class="mb-0 mt-3 default-bs"
              text
              dense
            >
              <span v-html="displayTextOrTranslation(err)" />
            </notification>
            <notification
              v-if="loading.failed"
              type="error"
              border="left"
              text
              class="mb-0 mt-3"
            >
              {{ $t(loading.failed) }}
            </notification>
            <notification
              v-if="append && !isFastAppendAvailable && !isNoFastAppendRulesFollowed"
              class="mb-0 mt-3"
              type="warning"
              outlined
              text
              prominent
            >
              {{ $t('steps.append.no_fast_append_warning') }}
            </notification>
          </div>

          <!-- UPLOAD SPECIFIC ERRORS -->
          <div class="upload-errors mb-0" v-if="!append && !replace">
            <notification
              v-if="languageIdentifierColumns.length > 1"
              type="error"
              class="mb-0 mt-3 default-bs"
              border="left"
              text
              dense
              :icon="false"
            >
              <span v-html="$t('steps.organize.multiple_question_columns_error')" />
            </notification>
            <notification
              v-if="!questionColumns.length"
              type="warning"
              class="mb-0 mt-3 default-bs"
              border="left"
              text
              dense
            >
              <span v-html="$t('steps.organize.no_question_error')" />
            </notification>
            <notification
              v-if="questionsWithErrors.length"
              type="warning"
              class="mb-0 mt-3 default-bs"
              border="left"
              text
              dense
              :icon="false"
            >
              <ul class="pl-0">
                <span class="mb-2">
                  {{ $t('steps.organize.question_error_detected') }}
                </span>
                <li
                  v-for="(question, index) in questionsWithErrors"
                  :key="index"
                  @click="scrollToColumn(question.idx)"
                  class="hover font-weight-medium ml-4"
                >
                  {{ getColumnLabel(question.name) }}
                </li>
              </ul>
            </notification>
          </div>

          <!-- REPLACE ERRORS -->
          <div class="upload-errors mb-0" v-if="replace">
            <notification
              v-if="answersRes.replaceError && !Array.isArray(answersRes.replaceError)"
              type="error"
              text
              class="mb-0 mt-3 default-bs"
              border="left"
              dense
            >
              <span v-html="displayTextOrTranslation(answersRes.replaceError)" />
            </notification>
            <notification
              v-else-if="answersRes.replaceError && Array.isArray(answersRes.replaceError)"
              v-for="(err, index) in answersRes.replaceError"
              :key="index"
              type="error"
              text
              class="mb-0 mt-3 default-bs"
              border="left"
              dense
            >
              <span v-html="displayTextOrTranslation(err)" />
            </notification>
          </div>

          <!-- APPEND ERRORS -->
          <div class="upload-errors mb-0" v-if="append">
            <notification
              v-if="answersRes.appendError && !Array.isArray(answersRes.appendError)"
              type="error"
              text
              class="mb-0 mt-3 default-bs"
              border="left"
              dense
            >
              <span v-html="displayTextOrTranslation(answersRes.appendError)" />
            </notification>
            <notification
              v-else-if="answersRes.appendError && Array.isArray(answersRes.appendError)"
              v-for="(err, index) in answersRes.appendError"
              :key="index"
              type="error"
              text
              class="mb-0 mt-3 default-bs"
              border="left"
              dense
            >
              <span v-html="displayTextOrTranslation(err)" />
            </notification>
            <notification
              v-if="nRows < 1"
              type="error"
              border="left"
              class="mb-0 mt-3 default-bs"
              text
              dense
            >
              <span v-html="$t('steps.append.no_rows_wraning')" />
            </notification>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import {
  UPLOAD_ENCODING_OPTIONS,
  COLUMN_TYPE_QUESTION,
  COLUMN_TYPE_NULL,
  COLUMN_TYPE_LANGUAGE
} from '@/settings/constants'
import { HELP_RESOURCE_CREDITS } from '@/settings/help-resources'
import { validateQuestionName, validateQuestionWording } from '@/utils/validators'
import { truncateMiddle, columnToExcelLetter } from '@/utils/funcs'
import Column from '@/components/upload/Column'

export default {
  codit: true,
  name: 'StepOrganize',

  components: { 'column': Column },

  mixins: [],

  props: {
    append: { type: Boolean, default: false },
    replace: { type: Boolean, default: false }
  },

  data () {
    return {
      HELP_RESOURCE_CREDITS
    }
  },

  computed: {
    projectColumnsLength () {
      if (this.project && this.project.auxiliary_column_names && this.project.questions) {
        return this.project.auxiliary_column_names.length + this.project.questions.length
      }

      return 0
    },

    /**
     * Checks if upload method is file upload
     * @return {Boolean}
     */
    isFileTab () {
      return this.answersInput.tab === 'file'
    },

    /**
     * Checks if upload method is direct input
     * @return {Boolean}
     */
    isInputTab () {
      return this.answersInput.tab === 'input'
    },

    /**
     * Checks if upload method is integration
     * @return {Boolean}
     */
    isIntegrationTab () {
      return this.answersInput.tab === 'integration'
    },

    /**
     * Returns encoding options
     */
    encodingOptions () {
      return UPLOAD_ENCODING_OPTIONS
    },

    /**
     * Returns matching auxilary options
     */
    matchingAuxilaryOptions () {
      return _.map(this.project.auxiliary_column_names, (name, idx) => ({
        value: idx,
        text: truncateMiddle(name, 50)
      }))
    },

    /**
     * Returns filtered columns with idx key
     * @return {Array}
     */
    filteredColumnsWithIdx () {
      // return _.sortBy(_.filter(this.filteredColumns, ({ idx }) => _.isNumber(idx)), ({ semantic_type: type }) => type !== COLUMN_TYPE_QUESTION)
      return _.filter(this.filteredColumns, ({ idx }) => _.isNumber(idx))
    },

    /**
     * Returns filtered columns
     * @return {Array}
     */
    filteredColumns () {
      if (this.answersInput.search) {
        const col = _.find(this.answersRes.column_metas, { name: this.answersInput.search })

        return col ? [col] : []
      }

      if (this.append) {
        return this.answersRes.column_metas.filter(column => {
          return _.includes([column.semantic_type, ...column.alternative_semantic_types], COLUMN_TYPE_QUESTION)
        })
      }

      if (this.filters.allSelected) return this.answersRes.column_metas

      return _.flatten(
        _.map(this.columnTypesWithNull, type => {
          if (this.filters.type[type]) {
            if (type === COLUMN_TYPE_NULL) return _.filter(this.answersRes.column_metas, { 'type': 'null' })
            if (type === COLUMN_TYPE_QUESTION || type === COLUMN_TYPE_LANGUAGE) {
              return _.filter(this.columnsToSave, { 'semantic_type': type })
            }
            if (type === 'string') return _.filter(this.columnsToSave, ({ type, semantic_type: semanticType }) => (type === 'string' && semanticType !== COLUMN_TYPE_QUESTION))
            return _.filter(this.columnsToSave, { 'type': type })
          }

          return []
        }
        )
      )
    },

    /**
     * Returns v-autocomplete list of items to search by column.
     * @return {Array}
    */
    columnNames () {
      return _.map(this.answersRes.column_metas, ({ name }) => ({ text: this.getColumnLabel(name), value: name }))
    },

    /**
     * Returns column types with null as unused column
     */
    columnTypesWithNull () {
      // Removing ability to not save column for now.
      return [
        COLUMN_TYPE_QUESTION,
        'string',
        'number',
        COLUMN_TYPE_LANGUAGE,
        'null',
        'date'
      ]
    },

    /**
     * Returns text columns
     */
    textColumns () {
      return [
        COLUMN_TYPE_QUESTION,
        'string',
        COLUMN_TYPE_LANGUAGE
      ]
    },

    /**
     * Returns non-text columns
     */
    nonTextColumns () {
      return [
        'date',
        'number',
        'null'
      ]
    },

    ...Vuex.mapState({
      user: 'user',
      loading: state => state.upload.loading,
      answersInput: state => state.upload.answersInput,
      answersRes: state => state.upload.answersRes,
      filters: state => state.upload.filters,
      matching: state => state.upload.matching,
      project: state => state.upload.project
    }),

    ...Vuex.mapGetters([
      'nRows',
      'columnsToSave',
      'questionsToAppend',
      'questionsWithErrors',
      'questionNames',
      'colsIdentical',
      'colsRemoved',
      'colsNew',
      'questionColumns',
      'languageIdentifierColumns',
      'isFastAppendAvailable',
      'validAppend',
      'isNoFastAppendRulesFollowed',
      'creditsToDeduct'
    ])
  },

  watch: {
    filters (val) {
      if (!_.find(val.type, type => type === true) && !val.allSelected) {
        this.toggleAllTypeFilter()
      }
    }
  },

  methods: {
    /**
     * A hack to determine if a translation or text should be shown directly,
     * reason behind this is that errors are displayed from both, frontend and backend (which already comes with translation)
     * and would cause missing translation errors in production
     */
    displayTextOrTranslation (string, params = {}) {
      // if (this.$t(string, params) === string) {
      //   return string
      // }

      if (string.includes(' ')) {
        return this.$escapeHtml(string)
      }

      return this.$t(string, params)
    },

    /**
     * Clears all question columns
     */
    clearQuestions () {
      this.$store.dispatch('clearOpenQuestions')
    },

    /**
     * Scrolls screen to selected column
     */
    scrollToColumn (idx) {
      const el = this.$refs[`column-${idx}`][0]
      const y = el.getBoundingClientRect().top + window.pageYOffset - 100

      if (el) window.scrollTo({ top: y, behavior: 'smooth' })
    },

    /**
     * sets answersInput.search via Store
     */
    handleSearchChange (value) {
      this.$store.commit('setSearch', value)
    },

    /**
     * Toggles visiblity of all filters
     */
    toggleAllTypeFilter () {
      this.$store.commit('toggleAllFilterType')
    },

    /**
     * Toggles given filter type
     */
    toggleTypeFilter (type) {
      this.$store.commit('toggleFilterType', type)
    },

    /** Returns number of column by type
     * @return {Number}
     */
    countByColumnType (type) {
      if (type === COLUMN_TYPE_NULL) return _.filter(this.answersRes.column_metas, { 'type': 'null' }).length
      if (type === COLUMN_TYPE_QUESTION || type === COLUMN_TYPE_LANGUAGE) return _.filter(this.columnsToSave, { 'semantic_type': type }).length
      if (type === 'string') return _.filter(this.columnsToSave, { 'type': type }).length - this.questionColumns.length - this.languageIdentifierColumns.length
      return _.filter(this.columnsToSave, { 'type': type }).length
    },

    /**
     * Changes the file's encoding and re-uploads
     */
    handleEncodingChange (value) {
      this.$store.dispatch('changeEncoding', value)
    },

    /*
    * Changes the file's matching appending option and re-uploads
    */
    handleMatchingChange (value) {
      this.$store.dispatch('changeMatchingAuxilary', value)
    },

    /**
     * Changes the selected sheet and re-uploads the file
     */
    handleSheetChange (name) {
      this.$store.dispatch('changeSheet', name)
    },

    /**
    * returns the columns label based on header preferences
    * @return {String}
    */
    getColumnLabel (name) {
      const columnNumber = Number(name.split(' ')[1]) + 1

      return this.answersInput.hasHeader
        ? truncateMiddle(name, 50)
        // Column names without header are returned as 'Column 0' etc...
        : columnNumber ? columnToExcelLetter(columnNumber) : name
    },

    /**
     * Gets column data styles by type
     * @return {string}
     */
    getColumnColors (type, alpha = false) {
      // let type = (column.semantic_type === COLUMN_TYPE_QUESTION || column.semantic_type === COLUMN_TYPE_LANGUAGE) ? column.semantic_type : column.type

      if (this.append) {
        return !alpha ? '#005e8C' : 'rgba(0, 94, 140, 0.1)'
      }

      switch (type) {
        case COLUMN_TYPE_QUESTION:
          return !alpha ? '#005e8C' : 'rgba(0, 94, 140, 0.1)'
        case COLUMN_TYPE_LANGUAGE:
          return !alpha ? '#E26139' : 'rgba(226, 97, 57, 0.1)'
        case 'null':
          return !alpha ? '#8C8D8E' : '#E9EEF2'
        case 'date':
          return !alpha ? '#B86AD5' : 'rgba(184, 106, 213, 0.1)'
        case 'string':
          return !alpha ? '#D47295' : '#FFEDF4'
        case 'number':
          return !alpha ? '#E9B949' : 'rgba(233, 185, 73, 0.1)'
      }
    },

    /**
     * Gets the options label
     * @return {String}
     */
    getTypeLabel (type, withPrefix = false) {
      switch (type) {
        case COLUMN_TYPE_QUESTION:
          return `${withPrefix ? this.replace ? this.$t('steps.replace.is') : this.$t('steps.organize.use') : ''} ${this.$t('steps.organize.column_types.question')}`
        case 'string':
          return `${withPrefix ? this.replace ? this.$t('steps.replace.is') : this.$t('steps.organize.use') : ''} ${this.$t('steps.organize.column_types.string')}`
        case 'date':
          return `${withPrefix ? this.replace ? this.$t('steps.replace.is') : this.$t('steps.organize.use') : ''} ${this.$t('steps.organize.column_types.date')}`
        case 'number':
          return `${withPrefix ? this.replace ? this.$t('steps.replace.is') : this.$t('steps.organize.use') : ''} ${this.$t('steps.organize.column_types.number')}`
        case COLUMN_TYPE_LANGUAGE:
          return `${withPrefix ? this.replace ? this.$t('steps.replace.is') : this.$t('steps.organize.use') : ''} ${this.$t('steps.organize.column_types.language')}`
        case 'null':
          return `${withPrefix ? this.replace ? this.$t('steps.replace.is') : this.$t('steps.organize.use') : ''} ${this.$t('steps.organize.column_types.null')}`
      }
    },

    /**
     * Gets the options label description
     * @return {String}
    */
    getTypeLabelDescription (type) {
      switch (type) {
        case COLUMN_TYPE_QUESTION:
          return `${this.$t('steps.organize.column_types.question_description')}`
        case 'string':
          return `${this.$t('steps.organize.column_types.string_description')}`
        case 'date':
          return `${this.$t('steps.organize.column_types.date_description')}`
        case 'number':
          return `${this.$t('steps.organize.column_types.number_description')}`
        case COLUMN_TYPE_LANGUAGE:
          return `${this.$t('steps.organize.column_types.language_description')}`
        case 'null':
          return `${this.$t('steps.organize.column_types.null_description')}`
      }
    },

    /**
     * Changes the header option and re-uploads file
     */
    handleHeaderChange () {
      this.$store.dispatch('changeHeaderSettings')
    },

    /**
     * Changes the upload identical answers settings and re-uploads file
     */
    handleUploadIdenticalAnswersChange () {
      this.$store.dispatch('changeUploadIdenticalAnswers')
    },

    /**
     * Changes the column type via Upload store
     */
    handleColumnTypeChange (value, column, index) {
      this.$store.commit(
        'setColumn',
        {
          index,
          column: {
            ...column,
            semantic_type: value
          }
        }
      )
    },

    /**
    * Toggle if column should be considered a question
    */
    toggleColumnIsQuestion (column, index) {
      this.$store.commit(
        'setColumn', {
          index,
          column: {
            ...column,
            isQuestion: !column.isQuestion
          }
        }
      )

      this.$store.dispatch('appendColumns')
    },

    /**
     * Changes question name via Upload store
     */
    handleQuestionNameChange (value, column, index) {
      this.$store.commit(
        'setColumn',
        {
          index,
          column: {
            ...column,
            questionName: {
              value,
              error: !column.sample_data.length ? false : validateQuestionName(value, this.questionNames)
            }
          }
        }
      )
    },

    /**
     * Changes question wording via Upload store
     */
    handleQuestionWordingChange (value, column, index) {
      this.$store.commit(
        'setColumn',
        {
          index,
          column: {
            ...column,
            questionWording: {
              value,
              error: !column.sample_data.length ? false : validateQuestionWording(value)
            }
          }
        }
      )
    }
  }
}
</script>

<style lang=scss>
  @import '~css/filters';
  @import '~css/coding';
</style>

<i18n locale='en' src='@/i18n/en/AppPage.json' />
<i18n locale='de' src='@/i18n/de/AppPage.json' />
<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />