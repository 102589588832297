import Vue from 'vue'

function parseDateString (val) {
  if (val === 'now') return moment()
  else return moment.utc(val)
}

export const dateapi = val => parseDateString(val).local().format()
export const shortiso = val => parseDateString(val).local().format('YYYY-MM-DD')
export const dateverbal = val => parseDateString(val).local().format('ll')
export const datehour = val => parseDateString(val).local().format('LT')
export const datetimeverbose = val => parseDateString(val).local().format('dddd, MMMM Do YYYY, H:mm')
export const datetimeshort = val => parseDateString(val).local().format('DD.MM.YY, H:mm')
export const datetimeverbal = val => parseDateString(val).local().format('LLL')
export const datelong = val => parseDateString(val).local().format('D. MMMM YYYY')
export const dateshort = val => parseDateString(val).local().format('DD.MM.YYYY')
export const datemonthyear = val => parseDateString(val).local().format('MMMM YYYY')
export const fromnow = val => parseDateString(val).local().fromNow()
export const fromchoice = (idx, choices) => { let c = ''; choices.forEach(v => { if (v[0] === idx) c = v[1] }); return c }
export const round = (val, digits, keep) => { let fixed = val.toFixed(digits); return keep ? fixed : +fixed }
export const percent = (val, base) => (val === 0 ? 0 : 100 * val / base)
export const uppercase = val => (typeof val === 'string' ? val.toUpperCase() : val)
export const enumerate = (val, glue) => val.length > 1 ? `${val.slice(0, val.length - 1).join(', ')} ${glue} ${val.slice(-1)}` : val.join('')

export function defineVueFilters () {
  Vue.filter('dateapi', dateapi)
  Vue.filter('shortiso', shortiso)
  Vue.filter('dateverbal', dateverbal)
  Vue.filter('datehour', datehour)
  Vue.filter('datetimeverbose', datetimeverbose)
  Vue.filter('datetimeshort', datetimeshort)
  Vue.filter('datetimeverbal', datetimeverbal)
  Vue.filter('datelong', datelong)
  Vue.filter('dateshort', dateshort)
  Vue.filter('datemonthyear', datemonthyear)
  Vue.filter('fromnow', fromnow)
  Vue.filter('fromchoice', fromchoice)
  Vue.filter('round', round)
  Vue.filter('percent', percent)
  Vue.filter('uppercase', uppercase)
  Vue.filter('enumerate', enumerate)
}
