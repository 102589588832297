<template>
  <div
    class="wizard__suggestion"
    :class="{
      'wizard__suggestion--completed': suggestion.done,
      'wizard__suggestion--active': !suggestion.done && clicked
    }"
    @click="e => !suggestion.done && handleSuggestionClick(e, suggestion)"
    @mouseover="!clickedSuggestion && !suggestion.done && !(suggestion.action === MOVED_TOPIC) && $store.commit('setActiveSuggestion', suggestion)"
    @mouseleave="!clickedSuggestion && !clicked && !suggestion.done && $store.commit('setActiveSuggestion', null)"
  >
    <v-tooltip
      v-if="suggestion.action !== MOVED_TOPIC"
      eager
      bottom
      :disabled="isTooltipDisabled(suggestion)"
    >
      {{ getSuggestionTooltipText(suggestion) }}
      <template v-slot:activator="{ on }">
        <v-checkbox
          primary
          hide-details
          :ripple="false"
          color="#05202E"
          off-icon="mdi-checkbox-blank"
          label=""
          :input-value="suggestion.done"
          @click.stop.prevent
          @change="value => handleSuggestionStatusChange(value, suggestion)"
          flat
        />
        <div
          class="wizard__suggestion__content"
        >
          <div v-on="on">
            <div
              class="wizard__suggestion__title"
              v-html="$t(`suggestions.${suggestion.action}_title`)"
            />
            <div
              v-if="!suggestion.done"
              class="wizard__suggestion__text pb-2"
              v-html="$t(`suggestions.${suggestion.action}_description${suggestionTopics && suggestionTopics.length === 1 ? '_single' : ''}`, {
                topics: getSuggestedTopicsList(suggestion)
              })"
            />
          </div>
          <span
            v-if="!suggestion.done && suggestion.action !== MOVED_TOPIC"
            class="wizard__suggestion__action wizard__suggestion__action--dismiss"
            @click.stop.prevent="handleSuggestionStatusChange(true, suggestion)"
          >
            {{ $t('done') }}
          </span>
        </div>
      </template>
    </v-tooltip>
    <div v-else class="wizard__suggestion__content">
      <div
        class="wizard__suggestion__text pb-2"
        v-html="$t(`suggestions.${suggestion.action}_description${suggestionTopics && suggestionTopics.length === 1 ? '_single' : ''}`, {
          topics: getSuggestedTopicsList(suggestion),
          category: getSuggestedCategory(suggestion)
        })"
      />
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import { REFINE_CATEGORIES, GENERATE_TOPICS, DELETE_TOPIC, ADDED_TOPICS, OTHER_CATEGORY, MOVED_TOPIC } from '@/settings/constants'

export default {
  codit: true,
  name: 'Suggestions',
  components: {
  },

  mixins: [],

  props: {
    suggestion: { type: Object, default: () => {} }
  },

  data () {
    return {
      tooltipDisabled: false,
      MOVED_TOPIC
    }
  },

  computed: {
    ...Vuex.mapState({
      id: state => state.wizard.id,
      step: state => state.wizard.step,
      refinementStep: state => state.wizard.refinementStep,
      suggestions: state => state.wizard.suggestions,
      topics: state => state.wizard.topics,
      activeSuggestion: state => state.wizard.activeSuggestion,
      clickedSuggestion: state => state.wizard.clickedSuggestion
    }),

    ...Vuex.mapGetters([
      'catColors'
    ]),

    clicked () {
      return _.isEqual(this.suggestion, this.clickedSuggestion)
    },

    filteredTopics () {
      return _.filter(this.suggestion.topics, topic => _.includes(_.map(this.topics, topic => topic.id), topic.id))
    },

    suggestionTopics () {
      return this.filteredTopics.map(topic => {
        const topicData = this.topics.find(t => t.id === topic.id)

        return {
          ...topic,
          ...topicData
        }
      })
    }
  },

  watch: {
  },

  methods: {
    /**
     * Handler for suggestion status change
     */
    handleSuggestionStatusChange (value, suggestion) {
      this.$store.commit('setClickedSuggestion', false)
      this.$store.commit('setSuggestionStatus', { value, suggestion })
      this.$store.commit('setActiveSuggestion', false)
    },

    /**
     * Handler for clicking on a suggestions action
     */
    handleSuggestionClick (e, suggestion) {
      if (suggestion.action === REFINE_CATEGORIES) return

      if (this.clicked) return this.$store.commit('setClickedSuggestion', false)

      if (suggestion.action === GENERATE_TOPICS) {
        this.$store.commit('setGenerationMoreShown', true)
      }

      if (suggestion.action === DELETE_TOPIC || suggestion.action === ADDED_TOPICS || suggestion.action === OTHER_CATEGORY) {
        this.$store.commit('setActiveSuggestion', suggestion)
        this.$store.commit('setClickedSuggestion', suggestion)
      }
    },

    /**
     * Checks if given suggestion has currently highlighted topics
     */
    isActiveSuggestion (suggestion) {
      return _.isEqual(suggestion.id, this.activeSuggestion?.id)
    },

    /**
     * Returns the description of the given suggestion
     */
    getSuggestionDescription (suggestion) {
      if (suggestion.action === DELETE_TOPIC && this.suggestionTopics.length === 1) {
        return this.$t(`suggestions.${suggestion.action}_description${this.suggestionTopics.length === 1 && '_single'}`, {
          topics: this.getSuggestedTopicsList(suggestion)
        })
      }

      return this.$t(`suggestions.${suggestion.action}_description`, {
        topics: this.getSuggestedTopicsList(suggestion)
      })
    },

    /**
     * Colored topics to be rendered in the translation
     */
    getSuggestedTopicsList (suggestion) {
      if ((suggestion.action === ADDED_TOPICS || suggestion.action === DELETE_TOPIC || suggestion.action === OTHER_CATEGORY) && !this.suggestionTopics.length) {
        this.handleSuggestionStatusChange(true, suggestion)
        this.$root.snackMsg(this.$t('suggestion_completed'))
      }

      if (suggestion.action === MOVED_TOPIC) return _.join(_.map(this.suggestionTopics, ({ label }) => `"${this.$escapeHtml(label)}"`), ', ')

      return _.join(_.map(this.suggestionTopics, ({ label, category }) => `<span style="color: ${this.catColors[category]?.strong || this.catColors[category]}">"${this.$escapeHtml(label)}"</span>`), ', ')
    },

    /**
     * Colored category to be rendered in the translation
     */
    getSuggestedCategory (suggestion) {
      return _.join(_.map(suggestion.categories, (category) => `<span style="color: ${this.catColors[category]?.strong || this.catColors[category]}">"${this.$escapeHtml(category)}"</span>`), ', ')
    },

    /**
     * If the action tooltip should be disabled
     */
    isTooltipDisabled (suggestion) {
      return suggestion.done || suggestion.action === REFINE_CATEGORIES || this.tooltipDisabled || suggestion.action === MOVED_TOPIC
    },

    getSuggestionTooltipText (suggestion) {
      if (suggestion.action === DELETE_TOPIC || suggestion.action === ADDED_TOPICS || suggestion.action === OTHER_CATEGORY) {
        return this.$t(!this.clicked ? 'suggestions.persist_highlight' : 'suggestions.depersist_highlight')
      }

      if (suggestion.action === GENERATE_TOPICS) {
        return this.$t('suggestions.generate_more_tooltip')
      }

      // handled by disabled state
      return ''
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />