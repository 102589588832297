<template>
  <div
    v-if="shown"
    class="notification"
    :class="`notification--${type} ${className}`"
    :style="`max-width: ${maxWidth};`"
  >
    <div
      class="notification__close"
      @click.stop="shown = false"
      v-if="closeable"
    >
      <v-icon
        class="hover"
        :size="20"
      >
        mdi-close
      </v-icon>
    </div>
    <div v-if="!noIcon" class="notification__icon">
      <svg v-if="type === 'warning'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.9999 6.66667V10M9.9999 13.3333H10.0082M8.5749 2.38334L1.51656 14.1667C1.37104 14.4187 1.29403 14.7044 1.29322 14.9954C1.2924 15.2865 1.3678 15.5726 1.51192 15.8254C1.65603 16.0783 1.86383 16.289 2.11465 16.4366C2.36547 16.5841 2.65056 16.6635 2.94156 16.6667H17.0582C17.3492 16.6635 17.6343 16.5841 17.8851 16.4366C18.136 16.289 18.3438 16.0783 18.4879 15.8254C18.632 15.5726 18.7074 15.2865 18.7066 14.9954C18.7058 14.7044 18.6288 14.4187 18.4832 14.1667L11.4249 2.38334C11.2763 2.13843 11.0672 1.93594 10.8176 1.79541C10.568 1.65488 10.2863 1.58105 9.9999 1.58105C9.71345 1.58105 9.43184 1.65488 9.18223 1.79541C8.93263 1.93594 8.72345 2.13843 8.5749 2.38334Z" stroke="#DC6803" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <svg v-else-if="type === 'info'" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.49512 1.5C13.6373 1.5 16.9951 4.85786 16.9951 9C16.9951 13.1421 13.6373 16.5 9.49512 16.5C5.35298 16.5 1.99512 13.1421 1.99512 9C1.99512 4.85786 5.35298 1.5 9.49512 1.5ZM9.49512 3C6.18141 3 3.49512 5.68629 3.49512 9C3.49512 12.3137 6.18141 15 9.49512 15C12.8088 15 15.4951 12.3137 15.4951 9C15.4951 5.68629 12.8088 3 9.49512 3ZM9.49512 7.5C9.90933 7.5 10.2451 7.83579 10.2451 8.25V12.75C10.2451 13.1642 9.90933 13.5 9.49512 13.5C9.0809 13.5 8.74512 13.1642 8.74512 12.75V8.25C8.74512 7.83579 9.0809 7.5 9.49512 7.5ZM9.49512 4.5C9.90933 4.5 10.2451 4.83579 10.2451 5.25C10.2451 5.66421 9.90933 6 9.49512 6C9.0809 6 8.74512 5.66421 8.74512 5.25C8.74512 4.83579 9.0809 4.5 9.49512 4.5Z" fill="#0E5D88" />
      </svg>
      <svg v-else-if="type === 'error'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0001 6.66669V10M10.0001 13.3334H10.0084M18.3334 10C18.3334 14.6024 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6024 1.66675 10C1.66675 5.39765 5.39771 1.66669 10.0001 1.66669C14.6025 1.66669 18.3334 5.39765 18.3334 10Z" stroke="#D92D20" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <svg v-else-if="type === 'success'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3334 9.23336V10C18.3324 11.797 17.7505 13.5456 16.6745 14.9849C15.5986 16.4242 14.0862 17.4771 12.3629 17.9866C10.6396 18.4961 8.7978 18.4349 7.11214 17.8122C5.42648 17.1894 3.98729 16.0384 3.00922 14.5309C2.03114 13.0234 1.56657 11.2401 1.68481 9.44696C1.80305 7.65383 2.49775 5.94697 3.66531 4.58092C4.83288 3.21488 6.41074 2.26285 8.16357 1.86682C9.91641 1.47079 11.7503 1.65198 13.3918 2.38336M18.3334 3.33336L10.0001 11.675L7.50009 9.17503" stroke="#039855" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div class="d-flex flex-column justify-center">
      <div v-if="title.length" class="notification__title" v-html="title" />
      <div v-if="text.length" class="notification__text" v-html="text" />
      <div v-if="action.length" @click="actionClick" class="notification__action hover" v-html="action" />
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'Notification',
  props: {
    type: { type: String, default: 'info' },
    closeable: { type: Boolean, default: false },
    maxWidth: { type: String, default: '100%' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    className: { type: String, default: '' },
    action: { type: String, default: '' },
    actionClick: { type: Function, default: () => {} },
    noIcon: { type: Boolean, default: false }
  },

  data () {
    return {
      shown: true
    }
  },

  computed: {
  }
}

</script>

<style lang="scss">
  .notification {
    border: 1px solid #F2F4F7;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    padding: 12px;
    padding-right: 52px;
    background: white;
    font-size: 14px;
    display: flex;
    position: relative;

    &__title {
      color: $text-color;
      font-weight: 500;
    }

    &__text {
      color: $grey-color;
    }

    &__close {
      position: absolute;
      top: 12px;
      right: 12px;
      color: $grey-color;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex-shrink: 0;
    }

    &__action {
      color: $primary-color;
      margin-top: 3px;
      &:hover {
        text-decoration: underline;
      }
    }

    &--warning {
      .notification__icon {
        background: #FEF0C7;
        border: 6px solid #FFFAEB;
      }
    }

    &--info {
      .notification__icon {
        background: #D1E0FF;
        border: 6px solid #EFF4FF;
      }
    }

    &--error {
      .notification__icon {
        background: #FEE4E2;
        border: 6px solid #FEF3F2;
      }
    }

    &--success {
      .notification__icon {
        background: #D1FADF;
        border: 6px solid #ECFDF3;
      }
    }
  }
</style>
