<template>
  <div class="d-flex flex-wrap align-center mb-n2">
    <div
      v-for="(filter, index) in filtersList"
      :key="index"
      class="filters__filter filters__filter--small ml-0 mr-2 mb-2"
    >
      <div class="d-flex align-center justify-center h-100">
        <v-icon size="16" class="mr-1">
          {{ filter.icon }}
        </v-icon>
        <div class="filters__filter__title" v-if="filter.title">
          {{ filter.title }}:
        </div>
        <div class="filters__filter__value" v-if="filter.value">
          {{ filter.value }}
        </div>
        <div class="filters__filter__value capitalize d-flex" v-if="filter.HTMLvalue" v-html="filter.HTMLvalue" />

        <template v-if="filter.sentiment">
          <div class="filters__filter__value">
            &nbsp;&
          </div>&nbsp;
          <div class="filters__filter__title">
            {{ $t('answer_fields.sentiment') }}:
          </div>
          <div class="filters__filter__value">
            {{ filter.sentiment }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getterDeconstructed } from '@/utils/vuex.js'

export default {
  props: {
    filtersFromQuery: { type: Array, default: () => [] },
    filtersFromConfig: { type: Array, default: () => [] },
    filtersFromOpenAction: { type: Array, default: () => [] },
    id: { type: String, default: '' }
  },

  computed: {
    ...getterDeconstructed('metaManager/overall')(function () { return `${this.id}` })({
      auxiliary_columns: 'auxiliary_column_metas'
    }),

    filtersList () {
      return [
        ...this.formatFilterList(this.filtersFromQuery, 'mdi-filter-variant'),
        ...this.formatFilterList(this.filtersFromConfig, 'mdi-filter'),
        ...this.convertedFiltersFromOpenAction
      ]
    },

    convertedFiltersFromOpenAction () {
      return this.filtersFromOpenAction.map(item => ({
        HTMLvalue: item.htmlText,
        icon: 'mdi-chart-bar'
      }))
    }
  },

  methods: {
    capitalizeFirstLetter (string) {
      if (
        typeof string !== 'string' ||
        string.length === 0
      ) {
        return false
      }
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formatFilterItemTitle (filter) {
      if (
        filter.type === 'auxiliary'
      ) {
        return _.truncate(
          this.auxiliary_columns[filter.value.col].name,
          { length: 25 }
        )
      } else {
        return this.$t(`ds.filters.labels.${filter.type}`)
      }
    },
    formatFilterList (list, icon) {
      return list.map(item => {
        let filter = {
          title: this.formatFilterItemTitle(item),
          value: this.formatFilterItemValues(item),
          icon
        }

        if (
          item.type === 'topics' &&
          item.value.length === 1
        ) {
          filter.sentiment = this.capitalizeFirstLetter(item.value[0].sentiment) || this.$i18n.t(`topics.sentiment_any`).label || ''
        }

        return filter
      })
    },
    formatFilterItemValues (filter) {
      let result
      if (
        filter.type === 'topics' &&
        filter.value.length === 1
      ) {
        result = this.capitalizeFirstLetter(filter.value[0]?.label) || ''
      } else if (
        filter.type === 'sentiment'
      ) {
        result = filter.value.join(' - ')
      } else if (
        filter.type === 'sentiment_v2'
      ) {
        result = this.$t(`ds.filters.labels.sentiment_range.${filter.value}`)
      } else if (
        filter.type === 'text'
      ) {
        result = _.truncate(filter.value, { length: 30 })
      } else if (
        typeof filter.value === 'boolean'
      ) {
        result = filter.value ? this.$t('yes') : this.$t('no')
      } else if (
        Array.isArray(filter.value)
      ) {
        result = filter.value.length === 1 ? _.truncate(filter.value[0], { length: 30 }) : `${filter.value.length} items`
      } else if (
        filter.type === 'auxiliary' &&
        filter.value.kind === 'range'
      ) {
        result = filter.value.value.join(' - ')
      } else if (
        filter.type === 'auxiliary' &&
        filter.value.kind === 'date_range'
      ) {
        result = filter.value.value.join(' - ')
      } else if (
        filter.type === 'auxiliary' &&
        filter.value.kind === 'text'
      ) {
        result = filter.value.value
      } else if (
        filter.type === 'auxiliary' &&
        filter.value.kind === 'select'
      ) {
        result = filter.value.value.length === 1 ? _.truncate(filter.value.value[0], { length: 30 }) : `${filter.value.value.length} items`
      } else if (
        filter.type === 'auxiliary' &&
        filter.value.kind === 'date_range_dynamic'
      ) {
        result = filter.value.value
      }

      if (
        filter.invert
      ) {
        result += `(${this.$t('verbatim.filters.inverted')})`
      }

      return result
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />