var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-deduction default-bs"},[(_vm.creditsRequired > _vm.userCreditsRemaining)?[_c('notification',{staticClass:"pr-0 mb-0",attrs:{"type":"warning","text":"","border":"left","prominent":"","outlined":"","icon":false}},[(_vm.introText)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.introText)}}),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":_vm.helpArticleLink},domProps:{"textContent":_vm._s(_vm.$t('billing.more_info'))}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"pr-2"},[_vm._v("\n        "+_vm._s(_vm.$t('billing.not_enough_credits', {
          required: _vm.creditsRequired,
          remaining: _vm.userCreditsRemaining
        }))+"\n        "),(_vm.$root.isRootAccount)?_c('router-link',{staticClass:"buy-credits",attrs:{"color":"warning","to":{ name: 'account', query: { tab: 'subscription' } }}},[_vm._v("\n          "+_vm._s(_vm.$t('billing.buy_additional'))+"\n        ")]):_vm._e()],1),_vm._v(" "),(_vm.showReload)?_c('v-btn',{staticClass:"mr-2 refresh",attrs:{"dense":"","x-small":"","icon":"","color":"warning"},on:{"click":_vm.refresh}},[_c('v-icon',{class:{ loading: _vm.loading },attrs:{"small":""}},[_vm._v("\n          mdi-reload\n        ")])],1):_vm._e(),_vm._v(" "),(!_vm.$root.isRootAccount)?_c('div',{staticStyle:{"margin-top":"6px"},domProps:{"innerHTML":_vm._s(_vm.$t('billing.contact_admin', { email: _vm.user.organization.root_user_email }))}}):_vm._e()],2)]:(_vm.unknownCredits || (_vm.creditsRequired > 0 && _vm.showInfo))?_c('notification',{staticClass:"mb-0",class:_vm.className,attrs:{"type":"info","no-icon":_vm.confirmation,"text":"","border":"left"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.isIntegrationTab)?_c('span',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(_vm.$t(`billing.integration_bill_credits_amount`, {
          n_credits: _vm.unknownCredits ? _vm.$t('billing.n_credits_unknown') : _vm.$t('billing.n_credits_known', { n: _vm.creditsRequired }),
          credits_available: _vm.userCreditsRemaining,
          column_count: _vm.append ? _vm.questionsToAppend.length : _vm.questionColumns.length,
          confirm: _vm.confirmation ? _vm.$t('billing.please_confirm') : ''
        }))}}):_c('span',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(_vm.$t(`billing.bill_credits_amount`, {
          n_credits: _vm.unknownCredits ? _vm.$t('billing.n_credits_unknown') : _vm.$t('billing.n_credits_known', { n: _vm.creditsRequired }),
          credits_available: _vm.userCreditsRemaining,
          column_count: _vm.append ? _vm.questionsToAppend.length : _vm.questionColumns.length,
          confirm: _vm.confirmation ? _vm.$t('billing.please_confirm') : ''
        }))}}),_vm._v(" "),(_vm.introText)?[_c('span',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(_vm.introText)}}),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":_vm.helpArticleLink},domProps:{"textContent":_vm._s(_vm.$t('billing.more_info'))}})]:_vm._e(),_vm._v(" "),(!_vm.introText)?_c('a',{staticClass:"mt-2",attrs:{"target":"_blank","href":_vm.helpArticleLink},domProps:{"textContent":_vm._s(_vm.$t('billing.more_info'))}}):_vm._e()],2),_vm._v(" "),(_vm.confirmation)?_c('v-checkbox',{staticClass:"mt-0 mb-0 pt-0 ml-3",attrs:{"input-value":_vm.answersInput.creditsConfirmed,"label":"","primary":"","hide-details":"","flat":""},on:{"change":_vm.handleCreditsConfirmedChange}}):_vm._e()],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }