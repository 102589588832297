<template>
  <DialogWrapper
    v-model="internalValue"
    :width="280"
    :closable="false"
  >
    <DialogHeader>
      <template slot="title">
        <div v-html="$t('not_enough_credits')" />
      </template>
    </DialogHeader>
    <DialogContent
      class="px-4 py-5"
    >
      <CreditDeductionInfo :credits-required="creditsRequired" />
    </DialogContent>
    <DialogActions>
      <template slot="rightSide">
        <v-btn
          outlined
          @click="goToMainPage"
          class="mr-2"
        >
          {{ $t('to_main_page') }}
        </v-btn>
      </template>
    </DialogActions>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import CreditDeductionInfo from '@/components/CreditDeductionInfo'

import DataMixin from '@/components/customUi/mixins/data'
import DialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'DashboardDetailNotEnoughCredits',
  codit: true,

  components: {
    DialogWrapper,
    DialogHeader,
    DialogContent,
    DialogActions,
    CreditDeductionInfo
  },

  mixins: [DataMixin, DialogMixin],

  props: {
    creditsRequired: { type: Number, default: 0, required: true }
  },

  methods: {
    goToMainPage () {
      this.$router.replace({
        name: 'home'
      })
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />