import axios from 'axios'

export default {
  props: {
    prefetchData: { type: Boolean, default: false }
  },

  data () {
    return {
      prefetched: {
        rowsCount: null
      },
      // helpers for renewing request and http aborts
      cancelTokens: {
        rowsCount: null
      }
    }
  },

  watch: {
    '$route.query' () {
      if (
        !this.prefetchData ||
        !this.internalValue
      ) {
        return
      }
      this.updateRowCount()
    },
    async internalValue (val) {
      if (
        !this.prefetchData
      ) {
        return
      }

      if (val) {
        this.updateRowCount()
      } else {
        this.cancelTokens.rowsCount?.cancel()
      }
    }
  },

  methods: {
    async updateRowCount () {
      try {
        this.cancelTokens.rowsCount?.cancel()
        await this.getRowsCount()
      } catch (error) {
        this.prefetched.rowsCount = null
      }
    },
    async getRowsCount () {
      this.cancelTokens.rowsCount = axios.CancelToken.source()
      let { data } = await api.get(`/api/ui/projects/${this.projectId}/coding/${this.refId}/rows`, {
        params: {
          group_identical: false,
          ...this.$route.query
        },
        cancelToken: this.cancelTokens.rowsCount.token
      })

      this.prefetched.rowsCount = data?.count
    }
  }
}