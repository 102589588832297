<template>
  <div>
    <label class="font-weight-medium text-sm text-label">
      {{ $t('download_dialog.form_items.filetype.label') }}
    </label>
    <v-select
      v-model="internalValue.format"
      :items="formatOptionsDisplayed"
      dense
      hide-details
      :height="40"
      :menu-props="{
        'offset-y': true,
        'ripple': false,
        'contentClass': 'select-menu-with-helptips'
      }"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.text" />
        </v-list-item-content>
        <v-list-item-action class="ma-0" v-if="item.disabled">
          <helptip
            position="left"
            :width="250"
            anchor-icon="mdi-cancel"
          >
            <feature-unavailable />
          </helptip>
        </v-list-item-action>
      </template>
    </v-select>

    <template v-if="internalValue.kind === 0">
      <div class="mt-2">
        <label class="font-weight-medium text-sm text-label">
          {{ $t('download_dialog.form_items.format.label') }}
        </label>
      </div>
      <v-select
        v-model="internalValue.codes_format"
        dense
        hide-details
        :height="40"
        :items="sources.codesFormatOptions"
        :menu-props="{
          'offset-y': true,
          'ripple': false
        }"
      />

      <v-expansion-panels
        flat
        accordion
        v-model="isAdditionalFieldEnabled"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            class="no-border p-0 mt-4"
          >
            <label class="text-sm text-grey d-flex align-center justify-end text-hover">
              Advanced options
            </label>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <label class="font-weight-medium text-label text-sm d-flex align-center">
                {{ $t('download_dialog.form_items.download_fields.title') }}
                <helptip position="bottom" :width="368" x-offset="36px" class="ml-1" style="position: relative; bottom: 1px;">
                  <div v-html="$t('download_dialog.form_items.download_fields.helptip.title')" />
                  <ul>
                    <li v-for="(entry, key) in $ta('download_dialog.form_items.download_fields.list')" :key="key">
                      <strong v-text="$ta(`download_dialog.form_items.download_fields.list.${key}.name`)" />:
                      <span v-html="$ta(`download_dialog.form_items.download_fields.list.${key}.description`)" />
                    </li>
                  </ul>
                </helptip>
              </label>
            </div>
            <select-multiple-draggable
              v-model="internalValue.field_order"
              :items="downloadFieldsDisplayed"
            >
              <template slot="comment">
                {{ $t('download_dialog.form_items.code_fields.comment') }}
              </template>
            </select-multiple-draggable>

            <div class="mt-4">
              <label class="font-weight-medium text-label text-sm d-flex align-center">
                {{ $t('download_dialog.form_items.code_fields.title') }}
                <helptip position="top" :width="368" x-offset="-6px" class="ml-1" style="position: relative; bottom: 1px;">
                  <div v-html="$t('download_dialog.form_items.code_fields.helptip.title')" />
                  <ul>
                    <li v-for="(entry, key) in $ta('download_dialog.form_items.code_fields.list')" :key="key">
                      <strong v-text="$ta(`download_dialog.form_items.code_fields.list.${key}.name`)" />:
                      <span v-text="$ta(`download_dialog.form_items.code_fields.list.${key}.description`)" />
                    </li>
                  </ul>
                </helptip>
              </label>
            </div>
            <select-multiple-draggable
              v-model="internalValue.codes_order"
              :items="codeFieldsDisplayed"
              :available-items="availableCodesOrderOptions"
            >
              <template slot="comment">
                {{ $t('download_dialog.form_items.download_fields.comment') }}
              </template>
            </select-multiple-draggable>

            <div
              class="mt-5"
            >
              <v-checkbox
                v-model="internalValue.legacy_format"
                primary
                hide-details
                :ripple="false"
                color="green"
                off-icon="mdi-checkbox-blank"
                flat
              >
                <template v-slot:label>
                  <div class="text-sm text-label font-weight-medium" style="position: relative; top: 2px;">
                    {{ $t('download_dialog.form_items.legacy_format.label') }}
                    <div class="text-sm text-grey font-weight-regular" v-html="$t('download_dialog.form_items.legacy_format.helptip')" />
                  </div>
                </template>
              </v-checkbox>

              <template
                v-if="internalValue.codes_format === 'inorder'"
              >
                <v-checkbox
                  class="mt-5"
                  v-model="internalValue.consolidate_categories"
                  primary
                  hide-details
                  :ripple="false"
                  color="green"
                  off-icon="mdi-checkbox-blank"
                  flat
                >
                  <template v-slot:label>
                    <div class="text-sm text-label font-weight-medium" style="position: relative; top: 2px;">
                      {{ $t('download_dialog.form_items.consolidate_categories.label') }}
                      <div class="text-sm text-grey font-weight-regular" v-html="$t('download_dialog.form_items.consolidate_categories.helptip')" />
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox
                  class="mt-5"
                  v-model="internalValue.codes_interleaved"
                  primary
                  hide-details
                  :ripple="false"
                  color="green"
                  off-icon="mdi-checkbox-blank"
                  flat
                >
                  <template v-slot:label>
                    <div class="text-sm text-label font-weight-medium" style="position: relative; top: 2px;">
                      {{ $t('download_dialog.form_items.interleave_codes.label') }}
                      <div class="text-sm text-grey font-weight-regular" v-html="$t('download_dialog.form_items.interleave_codes.helptip')" />
                    </div>
                  </template>
                </v-checkbox>
              </template>
              <v-checkbox
                class="mt-5"
                v-if="internalValue.codes_format === 'binary'"
                v-model="internalValue.add_category_columns"
                primary
                hide-details
                :ripple="false"
                color="green"
                off-icon="mdi-checkbox-blank"
                flat
              >
                <template v-slot:label>
                  <div class="text-sm text-label font-weight-medium" style="position: relative; top: 2px;">
                    {{ $t('download_dialog.form_items.add_category_columns.label') }}
                    <div class="text-sm text-grey font-weight-regular" v-html="$t('download_dialog.form_items.add_category_columns.helptip')" />
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import Vuex from 'vuex'

import selectMultipleDraggable from '@/components/customUi/selectMultipleDraggable/selectMultipleDraggable'

import DataMixin from '@/components/customUi/mixins/data'
import { generateInject, injectComputedValues } from '@/components/customUi/mixins/provideInject'

const CODES_ORDER_NO_LEGACY_CODES_FORMAT_INORDER = [ 'id', 'category', 'sentiment', 'label' ]

export default {
  codit: true,
  name: 'ExportDataForm',

  components: {
    'select-multiple-draggable': selectMultipleDraggable
  },

  mixins: [
    DataMixin
  ],

  inject: generateInject(['isAdditionalFieldEnabled']),

  props: {
    value: { type: Object, default: () => {} },
    sources: { type: Object, default: () => {} }
  },

  computed: {
    ...injectComputedValues(['isAdditionalFieldEnabled']),
    ...Vuex.mapState([
      'user'
    ]),

    /**
    * Filtered list of formats of export
    * @returns {Array} Formats filtered
    */
    formatOptionsDisplayed () {
      return _.map(this.sources.formatOptions, (v, k) => ({
        text: v.text,
        value: v.value,
        disabled: v.advanced_type && !this.user.subscription.features.advanced_filetypes
      }))
    },

    codeFieldsDisplayed () {
      return this.sources.codeFields.reduce((sum, item) => [
        ...sum,
        {
          text: this.$t(`download_dialog.form_items.code_fields.list.${item}.name`),
          value: item
        }
      ], [])
    },

    downloadFieldsDisplayed () {
      return this.sources.downloadFields.reduce((sum, item) => [
        ...sum,
        {
          text: this.$t(`download_dialog.form_items.download_fields.list.${item}.name`),
          value: item
        }
      ], [])
    },

    /**
    * Codes to render
    * depends on specific case
    * @returns {Array} Codes suited for a case
    */
    availableCodesOrderOptions () {
      if (
        this.internalValue.codes_format === 'inorder' &&
        !this.internalValue.legacy_format
      ) {
        return [ 'id', 'sentiment', 'sentiment_label', 'label', 'category' ]
      } else if (
        this.internalValue.codes_format === 'binary' &&
        !this.internalValue.legacy_format
      ) {
        return ['label', 'category']
      }
      return ['id', 'name', 'category']
    }
  },

  watch: {
    /**
    * Update codes for specific cases
    */
    'internalValue.legacy_format' () {
      this.updateSelectedCodesOrderOptions()
    },
    /**
    * Update codes for specific cases
    */
    'internalValue.codes_format' () {
      this.updateSelectedCodesOrderOptions()
    }
  },

  methods: {
    /**
    * Set values for codes field.
    * Specific sets necessary for several legacy/format combinations
    * @returns {any}
    */
    updateSelectedCodesOrderOptions () {
      if (
        this.internalValue.codes_format === 'inorder' &&
        !this.internalValue.legacy_format
      ) {
        this.internalValue.codes_order = CODES_ORDER_NO_LEGACY_CODES_FORMAT_INORDER
      } else {
        this.internalValue.codes_order = this.availableCodesOrderOptions
      }
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/ExportData.json' />
<i18n locale='de' src='@/i18n/de/components/ExportData.json' />