<template>
  <div class="wizard-qmerge">
    <v-alert color="blue-grey"
             prominent
             type="info"
             outlined
             icon="mdi-hand-pointing-right"
             border="left"
    >
      <span v-html="$t('qmerge.info')" />
    </v-alert>

    <v-alert v-if="success"
             prominent
             type="success"
             outlined
             border="left"
    >
      <span v-html="$t('qmerge.success')" />
      <div v-if="creditsReimbursed" v-html="$t('qmerge.credits_reimbursed', { n: creditsReimbursed })" />
    </v-alert>
    <div class="question-list">
      <v-card class="question"
              hover
              v-for="(q, qIdx) in project.questions"
              :key="q.id"
              :class="{
                selected: merge.indexOf(q.id) !== -1,
                current: q.id === question.id
              }"
      >
        <v-card-title class="tooltip-bottom tooltip-manual"
                      :class="{ tooltip: questionIsDirty[qIdx] }"
        >
          <span class="manual-content" v-html="$t('qmerge.tooltip_reset_required')" v-if="questionIsDirty[qIdx]" />
          <div>
            <v-checkbox v-model="merge"
                        :value="q.id"
                        :disabled="questionIsDirty[qIdx] || q.id === question.id"
                        hide-details
            >
              <div slot="label" class="title" style="">
                {{ q.name }}
              </div>
            </v-checkbox>
          </div>
          <div v-if="q.id === question.id"
               class="subtitle-1 grey--text"
               v-text="'(current question)'"
          />
        </v-card-title>

        <v-card-text class="infos">
          <div>{{ q.ncodes }} {{ $tc('codes.items', q.ncodes) }}</div>
          <div>{{ q.nreviewed }} {{ $tc('qmerge.reviewed_answers', 2) }}</div>
        </v-card-text>
      </v-card>
    </div>

    <div class="btn-next-container">
      <v-btn class="btn-prev"
             @click="$emit('back')"
      >
        {{ $t('back') }}
      </v-btn>

      <v-btn color="accent"
             v-if="merge.length > 1"
             @click="confirm = true"
             v-text="$t('qmerge.btn_merge', { n: merge.length - 1 })"
      />

      <v-btn v-else
             color="primary"
             @click="$emit('next')"
             v-text="$t('next')"
      />
    </div>

    <v-dialog v-model="confirm" max-width="600" @keydown.esc="confirm = false" :persistent="loading">
      <v-card>
        <v-card-title class="headline grey lighten-2"
                      primary-title
                      v-text="$t('qmerge.confirm.title')"
        />

        <alert v-if="failed"
               type="error"
        >
          <span v-html="$t('error', {'step': $t('qmerge.error_step') })" />
        </alert>

        <v-card-text v-html="$t('qmerge.confirm.description', { n: merge.length - 1 })" />

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn color="primary"
                 outlined
                 @click.native="merge.splice(1); confirm = false"
                 :disabled="loading"
          >
            {{ $t('no') }}
          </v-btn>
          <v-btn color="accent"
                 @click.native="mergeQuestions"
                 :loading="loading"
                 :disabled="loading"
          >
            {{ $t('qmerge.btn_merge', { n: merge.length - 1 }) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Vuex from 'vuex'

export default {
  codit: true,
  name: 'QuestionsMerge',

  props: {
    v2Identifier: { type: Object, default: () => {} }
  },

  data () {
    return {
      merge: [],
      confirm: false,
      loading: false,
      failed: false,
      success: false,
      creditsReimbursed: 0
    }
  },

  computed: {
    /**
     * Array of booleans of same length as number of questions in project
     * Entry is true if the question either has codes or reviewed answers
     * @return {Array}
     */
    questionIsDirty () {
      return _.map(this.project.questions, q => q.nreviewed > 0 || q.ncodes > 0)
    },

    ...Vuex.mapState({
      user: 'user',
      question: state => state.wizard.question,
      project: state => state.wizard.project
    }),

    ...Vuex.mapGetters(['isListQuestion'])
  },

  watch: {
  },

  created () {
    // The current question is always checked
    this.merge.push(this.question.id)
  },

  methods: {
    /**
     * Do the merging of questions (already has been confimed)
     * @return {[type]} [description]
     */
    mergeQuestions () {
      this.loading = true
      this.failed = false

      // Do the post request to the merging endpoint
      // The questions to be merged are all the entries [1:] in the merge array
      api.post(`/api/ui/projects/${this.question.project}/merge-columns`, {
        column_main: this.v2Identifier.refs[this.merge[0]],
        columns_to_merge: _.map(this.merge.slice(1), qId => this.v2Identifier.refs[qId])
      }).then((res) => {
        if (res.data && res.status === 200) {
          this.creditsReimbursed = res.data.credits_reimbursed
          // If the merging was successfull, we reload all data
          // (theoretically only answers and project would be enough, but for simplicity we reload everything)
          this.$store.dispatch('loadData', this.question.id).then(() => {
            // If there are no more other questions visible here, go to the previous step
            // to prevent weird rendering problems
            this.merge.splice(1)
            this.success = true
            this.loading = false
            this.confirm = false
          })
        } else {
          this.failed = true
          this.loading = false
        }
      }).catch(err => {
        this.failed = true
        this.loading = false
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<style lang=scss>

.wizard-qmerge {
  .question-list {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    position: relative;
  }
  .question {
    background: #FFF;
    border-radius: 3px;
    margin: 16px 12px;
    cursor: default;
    min-width: 200px;
    max-width: 30%;
    transition: all 0.15s ease;

    .v-card__title {
      background: #EEE;
      transition: all 0.3s ease;
      display: block;

      .v-input--checkbox {
        margin: 0;
        padding: 0;
      }

      .subtitle-1 {
        margin-top: -6px;
        font-size: 14px!important;
        text-align: right;
      }
    }

    &.selected {
      transform: translateY(-15px) scale(1.05);
      .v-card__title {
        background: $col-soft-pink;
      }
    }

    &.current {
      .v-card__title {
        background: $col-soft-turq;
      }
    }

    .infos {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .actions {
      padding-top: 8px;
      padding-bottom: 8px;
      transition: background 0.15s ease-in-out;
      .v-btn {
        background: #FFF;
        &:not(:last-child) { margin-right: 10px; }
      }
    }

    &:hover .actions { background: $col-ans-focus-bg;}

    .headline { margin-bottom: 10px }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Wizard.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizard.json' />
<i18n locale='en' src='@/i18n/en/components/wizard/QuestionsMerge.json' />
<i18n locale='de' src='@/i18n/de/components/wizard/QuestionsMerge.json' />