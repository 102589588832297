import axios from 'axios'
import { HttpMaxAttemptsError } from '@/components/customUi/errors/httpError.js'

export default {
  data () {
    return {
      // dialog state, to control view
      state: {
        loading: false,
        newExport: null
      },

      cancelTokens: {
        creation: null,
        fileGeneration: null
      }
    }
  },
  methods: {
    /*
    * TOP LEVEL METHODS
    */
    /**
    * Get form data, generate file, fetch blob and download
    * throw nofitication if something goes wrong(bad request, no internet or so)
    */
    async generateAndDownloadNewFile () {
      this.cancelListFetching()
      this.state.loading = true

      try {
        this.state.newExport = await this.generateExportFile()
        await this.getApiTaskStatus(this.state.newExport.task_id, this.state.newExport.id)
        let fileData = await this.fetchFile(this.state.newExport.id)
        this.downloadBlob(fileData)
      } catch (error) {
        if (
          error instanceof HttpMaxAttemptsError
        ) {
          this.$root.snackMsg(this.$t('download_dialog.errors.timeout'))
        } else if (
          error.message === 'Network Error'
        ) {
          this.cancelListFetching()
          this.$root.snackMsg(this.$t('download_dialog.errors.offline'))
        } else if (
          !axios.isCancel(error)
        ) {
          this.$root.snackMsg(this.$t('download_dialog.errors.bad_request'))
        }
      } finally {
        this.state.loading = false
        this.state.newExport = null
      }

      this.stateChangeListUpdater()
    },

    /**
    * Fetch file as blob and download it locally
    * @param {String} id of file
    */
    async downloadFile (id) {
      try {
        let fileData = await this.fetchFile(id)
        this.downloadBlob(fileData)
      } catch (error) {
        if (
          error.message === 'Invalid API response: Status 422'
        ) {
          this.setFileExpiredStatus(id)
          this.$root.snackMsg(this.$t('download_dialog.errors.expired'))
        } else if (
          error.message === 'Network Error'
        ) {
          this.$root.snackMsg(this.$t('download_dialog.errors.offline'))
        } else if (
          !axios.isCancel(error)
        ) {
          this.$root.snackMsg(this.$t('download_dialog.errors.bad_request'))
        }
      }
    },

    /*
    * CHECKS UTILITIES
    */
    /**
    * Check if new export file is ready.
    * Otherwise it retries requests and wait till it's done
    * @param {String} newExportId
    * @returns {Boolean} If a file is done
    */
    isNewExportReady (newExportId) {
      return this.exports.list.some(({ id, status }) => id === newExportId && status === 2)
    },

    /*
    * NETWORK AND INTERVALS UTILITIES
    */
    /**
    * Abort all related stuff for generating new export
    */
    cancelGenerationUpdate () {
      this.cancelTokens.fileCreating?.cancel()
      this.cancelTokens.fileGeneration?.cancel()
      this.state.loading = false
    },

    /*
    * MODIFYING DATA UTILITIES
    */
    /**
    * Modifies form data to use as query generating new export
    */
    aggregatedFormData () {
      let draft = { ...this.formData }
      draft.kind = this.sources.kindOptions[draft.kind].value

      // return data for topic export
      if (
        this.formData.kind === 1
      ) {
        return _.pick(draft, ['kind', 'format'])
      }

      // return data for rows export with default settings
      if (
        !Number.isInteger(this.formState.settings)
      ) {
        return _.pick(draft, ['kind', 'format', 'codes_format'])
      }

      if (
        this.formData.codes_format === 'binary'
      ) {
        return _.omit(draft, ['consolidate_categories', 'codes_interleaved'])
      }
      if (
        this.formData.codes_format === 'inorder'
      ) {
        return _.omit(draft, ['add_category_columns'])
      }
    },

    /*
    * LOW LEVEL FUNCTIONS
    * REQUESTS AND OTHER NETWORK STUFF
    */
    /**
    * Creates new export file with generated query
    * @returns {Object} Object with reference to new export file
    */
    async generateExportFile () {
      let queryString = {
        project_id: this.projectId,
        ...this.aggregatedFormData()
      }

      if (
        this.useQueryAsFilters
      ) {
        queryString = {
          ...queryString,
          ...this.$route.query
        }
      }

      if (
        this.refId !== ''
      ) {
        queryString.ref = this.refId
      }
      queryString = Object.entries(queryString).map(([key, val]) => {
        if (Array.isArray(val)) {
          return val.reduce((sum, item) => sum === '' ? `${key}=${item}` : [sum, `${key}=${encodeURIComponent(item)}`].join('&'), '')
        }
        return `${key}=${encodeURIComponent(val)}`
      }).join('&')

      this.cancelTokens.fileCreating = axios.CancelToken.source()

      let { data } = await api.post(`/api/ui/exports?${queryString}`, {}, {
        cancelToken: this.cancelTokens.fileCreating.token
      })
      return data
    },
    /**
    * Fetch file blob
    * @param {String} id ID of file to be downloaded
    * @returns {Object} File blob and filename
    */
    async fetchFile (id) {
      this.cancelTokens.fileGeneration = axios.CancelToken.source()

      let response = await api.get(`/api/ui/exports/${id}/file`, {
        responseType: 'blob',
        cancelToken: this.cancelTokens.fileGeneration.token
      })

      const string = decodeURIComponent(response.headers['content-disposition'])

      let filenameWithSpecialCharactersParsed = /^attachment; filename\*=utf-8''(.*)/.exec(string)?.[1]
      let filenameSimpleParsed = /filename="(.*)"/.exec(string)?.[1]

      const filename = filenameWithSpecialCharactersParsed || filenameSimpleParsed || this.$t('download_dialog.text_calculated_failbacks.filename')

      return {
        blob: await new Blob([response.data]),
        filename
      }
    },
    /**
    * Downloads blob as file creating a real link
    * @param {any} blob Blob to be downloaded
    * @param {any} filename Name of file to be set
    */
    downloadBlob ({ blob, filename }) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')

      // Set link's href to point to the Blob URL
      link.href = blobUrl
      link.download = filename

      // Append link to the body
      document.body.appendChild(link)

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )

      // Remove link from body
      document.body.removeChild(link)
    }
  }
}