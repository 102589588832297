<template>
  <div
    class="dashboard-filters__select-container d-flex align-center"
    @click="handleFilterAdd"
  >
    <v-menu
      offset-y
      :close-on-content-click="true"
      :disabled="disabled"
      :rounded="'lg'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="filters__select"
          :class="{'dashboard-filters__select--disabled': disabled}"
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          hide-details
          solo
          dense
        >
          <v-icon>
            mdi-plus-circle
          </v-icon>
          {{ $t('ds.filters.add') }}
        </v-btn>
      </template>

      <v-list class="dashboard-filters__list pt-0">
        <div
          v-for="(item, index) in addOptions"
          :key="index"
        >
          <v-divider v-if="item.divider" class="dashboard-filters__list__separator" />
          <div
            v-else-if="item.header"
            class="dashboard-filters__list__header"
          >
            {{ item.header }}
            <helptip v-if="item.helptip" position="bottom" class="ml-1">
              <div v-html="$t('require_matching_auxiliaries')" />
            </helptip>
          </div>
          <template v-else>
            <div
              v-if="item.disabled"
              class="dashboard-filters__list__item dashboard-filters__list__item--disabled"
            >
              {{ item.text }}
              <helptip v-if="item.disabled" position="bottom" class="ml-1">
                <div v-html="$t('disabled_filters.one_codes_or_categories_only')" />
              </helptip>
            </div>
            <div
              v-else
              class="dashboard-filters__list__item"
              @click="handleSelectItem(item.type)"
            >
              {{ item.text }}
            </div>
          </template>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'FiltersListAddButton',

  props: {
    addOptions: { type: Array, required: false, default: () => ([]) },
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleSelectItem ($event) {
      this.$emit('add', $event)
    },

    /**
     * directly call add event if options are not available
     */
    handleFilterAdd ($event) {
      if (!this.addOptions.length) {
        $event.preventDefault()
        this.handleSelectItem(null)
      }
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />
