<template>
  <div>
    <div class="d-flex text-sm mb-2">
      <label class="font-weight-medium">
        {{ $t('download_dialog.previous_exports.title') }}
      </label>
    </div>

    <div class="text-center pb-5" v-if="loading">
      <v-progress-circular indeterminate />
    </div>
    <div class="exports" v-else>
      <div
        v-for="(item, itemIndex) in exportListInUse"
        :key="itemIndex"
        class="exports__item d-flex justify-space-between mb-3 px-4 py-3"
      >
        <div class="d-flex">
          <div class="text-sm">
            <div class="font-weight-medium">
              {{ item.owner_name }}
            </div>
            <div class="d-flex text-grey">
              <div>
                {{ $options.filters.dateverbal(item.created) }}
              </div>
              &nbsp;-&nbsp;{{ item.rows_count }}&nbsp;{{ $tc('question.answer_count',item.rows_count) }}
            </div>
          </div>
          <div>
            <helptip
              v-if="checkIfExpired(item.status)"
              position="top"
              :width="368"
              x-offset="36px"
              class="ml-3"
              style="position: relative; bottom: 1px;"
            >
              <div v-html="$t('download_dialog.previous_exports.hints.export_expired')" />
            </helptip>
          </div>
        </div>

        <div>
          <v-btn
            text
            small
            class="pr-0 pl-0"
            icon
            :disabled="checkIfDisabled(item.status)"
            :loading="checkIfLoading(item.status)"
            @click="download(item.id)"
          >
            <v-icon v-if="getIcon(item.status) !== 'mdi-download'">
              {{ getIcon(item.status) }}
            </v-icon>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17 12C17.5128 12 17.9355 12.386 17.9933 12.8834L18 13V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18L5 18C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15L2 13C2 12.4477 2.44772 12 3 12C3.51284 12 3.93551 12.386 3.99327 12.8834L4 13L4 15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16L15 16C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V13C16 12.4477 16.4477 12 17 12ZM10 2C10.5523 2 11 2.44772 11 3L11 10.585L12.2929 9.29289C12.6534 8.93241 13.2206 8.90468 13.6129 9.2097L13.7071 9.29289C14.0676 9.65338 14.0953 10.2206 13.7903 10.6129L13.7071 10.7071L10.7071 13.7071C10.3466 14.0676 9.77939 14.0953 9.3871 13.7903L9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.65338 8.93241 7.22061 8.90468 7.6129 9.2097L7.70711 9.29289L9 10.585L9 3C9 2.44772 9.44772 2 10 2Z" fill="#0e5d88" />
            </svg>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const InProgressStatuses = [0, 1]

export default {
  codit: true,
  name: 'ExportDataPreviousExports',

  props: {
    items: { type: Array, default: () => [] },
    kind: { type: Number, default: 0 },
    shownFully: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  computed: {
    /**
    * List of export to render
    * To be shrinked by default and expanded by click
    * @returns {Array} Exported data list
    */
    exportListInUse () {
      if (
        !this.shownFully
      ) {
        return this.items.slice(0, 5)
      }
      return this.items
    }
  },

  methods: {
    getIcon (status) {
      if (
        status === 0 ||
        status === 3 ||
        (
          !this.loading &&
          InProgressStatuses.indexOf(status) > -1
        )
      ) {
        return 'mdi-cancel'
      } else if (status === 1 || status === 2) {
        return 'mdi-download'
      } else if (
        status === 4
      ) {
        return 'mdi-alarm-note-off'
      }
    },
    checkIfDisabled (status) {
      return status !== 2
    },
    checkIfLoading (status) {
      return InProgressStatuses.indexOf(status) > -1
    },
    checkIfExpired (status) {
      return status === 4
    },
    download (id) {
      this.$emit('download', id)
    }
  }
}

</script>

<style lang="scss">
.exports {
  &__item {
    border: 1px solid #EAECF0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  }
}
</style>

<i18n locale='en' src='@/i18n/en/components/ExportData.json' />
<i18n locale='de' src='@/i18n/de/components/ExportData.json' />
<i18n locale='en' src='@/i18n/en/pages/ProjectList.json' />
<i18n locale='de' src='@/i18n/de/pages/ProjectList.json' />