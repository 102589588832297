<template>
  <div class="api-management">
    <v-alert v-if="!user.subscription.features.api_access"
             type="warning"
             text
             prominent
             border="left"
    >
      <feature-unavailable />
    </v-alert>
    <template v-else>
      <v-alert type="info"
               text
      >
        <h3 v-text="$t('api.info.title')" />

        <div style="margin-top: 15px">
          <span v-html="$t('api.info.apikey')" />
        </div>

        <div style="margin-top: 15px" v-html="$t('api.info.docs', { url: APIDocsURL })" />
        <div style="margin-top: 15px" v-html="$t('api.info.library', { url: 'https://caplena.com/docs/sdk/python/' })" />
      </v-alert>

      <v-card>
        <v-card-title>
          <div>
            <div class="title">
              {{ $t('api.title') }}
            </div>
            <div class="grey--text">
              {{ $t('api.subtitle') }}
            </div>
          </div>
        </v-card-title>
        <v-progress-linear indefinite v-if="loading" class="progress" />

        <template v-else>
          <v-card-text>
            <v-alert v-if="failed"
                     prominent
                     type="error"
                     outlined
                     text
                     border="left"
                     class="flex-center"
            >
              {{ $t('api.loading_failed') }}

              <div class="spacer" />

              <v-btn color="accent" outlined @click="loadAPIKeys">
                {{ $t('actions.retry') }}
              </v-btn>
            </v-alert>
            <template v-else>
              <v-alert v-if="!apikeys.length"
                       type="info"
                       outlined
                       text
                       border="left"
                       class="flex-center"
              >
                {{ $t('api.info_no_keys') }}
              </v-alert>

              <template>
                <ul class="existing-apikeys">
                  <li v-for="(k, idx) in apikeys" :key="idx">
                    <span class="enabled tooltip"
                          :data-tooltip="$t(`api.key.disabled.${k.disabled ? 'true' : 'false'}`)"
                    >
                      <v-icon v-if="k.disabled" class="true">
                        mdi-close
                      </v-icon>
                      <v-icon v-else class="false">
                        mdi-check
                      </v-icon>
                    </span>

                    <span class="name">
                      <code>{{ k.key_hashed.slice(-8) }}</code>
                      <helptip :width="300" position="top">
                        <span v-html="$t('api.key.key_hashed_helptip')" />
                      </helptip>
                    </span>
                    <div class="times">
                      <span class="created">
                        <span class="fieldname">{{ $t('api.key.created') }}:</span>
                        {{ k.created | datetimeshort }}
                      </span>
                      <span class="last-used">
                        <span class="fieldname">{{ $t('api.key.last_used') }}:</span>
                        <template v-if="k.last_used === null">{{ $t('api.key.never') }}</template>
                        <template v-else>{{ k.last_used | fromnow }}</template>
                      </span>
                    </div>
                    <v-spacer />
                    <span class="actions">
                      <a @click="openDeleteDialog(k.key_hashed)" v-text="$t('delete.title')" />
                      <a @click="openDisableDialog(k.key_hashed)" v-text="$t(`api.disable.btn_${k.disabled ? 'enable' : 'disable'}`)" />
                    </span>
                  </li>
                </ul>
              </template>
            </template>
          </v-card-text>

          <v-card-actions v-if="!loading && !failed">
            <v-spacer />
            <v-btn color="primary"
                   :outlined="apikeys.length > 0"
                   v-if="newAPIKeyAvailable"
                   @click="openNewDialog"
            >
              {{ $t('api.btn_new') }}
            </v-btn>
            <v-alert v-else
                     type="info"
                     text
                     border="left"
            >
              {{ $t('api.alert_limit_reached') }}
            </v-alert>
          </v-card-actions>
        </template>
      </v-card>
    </template>

    <v-dialog v-model="dialogActive"
              max-width="600"
              @keydown.esc="cancelDialog"
              :persistent="dialog.loading"
    >
      <v-card class="dialog-apikey" v-if="!!dialog.mode">
        <v-card-title class="headline grey lighten-2"
                      primary-title
                      v-text="$t(`api.${dialog.mode}.title`)"
        />

        <v-expand-transition>
          <div v-show="dialog.error !== ''" class="alert-container">
            <v-alert type="error"
                     border="left"
                     text
            >
              {{ dialog.error }}
            </v-alert>
          </div>
        </v-expand-transition>

        <v-stepper v-model="dialog.step" vertical v-if="!dialog.success">
          <v-stepper-step :complete="dialog.step > 1" :step="1">
            {{ $t('api.password.title') }}
          </v-stepper-step>

          <!-- VERIFY PASSWORD STEP -->
          <v-stepper-content :step="1">
            <v-text-field v-model="dialog.password"
                          :label="$t('api.password.label')"
                          :disabled="dialog.loading"
                          class="password"
                          type="password"
                          outlined
                          hide-details
                          ref="password"
                          @keydown.enter="submitPassword"
            />

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary"
                     :disabled="!dialog.password.length"
                     :loading="dialog.loading"
                     @click="submitPassword"
              >
                {{ $t('next') }}
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- CONFIRM DELETE / DISABLE STEP -->
          <v-stepper-step v-if="['delete', 'disable'].indexOf(dialog.mode) !== -1" :complete="dialog.step > 2" :step="2">
            {{ $t(`api.${dialog.mode}.step_title`) }}
          </v-stepper-step>

          <v-stepper-content :step="2" v-if="['delete', 'disable'].indexOf(dialog.mode) !== -1">
            <v-alert v-if="dialog.mode === 'delete' || !deleteOrDisableKey.disabled"
                     type="warning"
                     class="confirm-delete"
                     outlined
                     text
                     border="left"
            >
              <div v-html="$t(`api.${dialog.mode}.alert`, { key: deleteOrDisableKey.key_hashed.slice(-8) })" />
            </v-alert>

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary"
                     @click="confirmDeleteOrDisable"
                     :loading="dialog.loading"
              >
                {{ dialog.mode === 'delete' ? $t('delete.title') : $t(`api.disable.btn_${deleteOrDisableKey.disabled ? 'enable' : 'disable'}`) }}
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- NEW KEY STEP -->
          <v-stepper-step v-if="dialog.mode === 'new'" :step="2">
            {{ $t('api.new.step_title') }}
          </v-stepper-step>

          <v-stepper-content :step="2" v-if="dialog.mode === 'new'">
            <v-alert type="success"
                     text
            >
              <span v-html="$t(`api.new.success_info`)" />
            </v-alert>

            <div class="new-key-real">
              {{ dialog.newApiKey.key }}
            </div>

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('close') }}
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Vuex from 'vuex'

export default {
  name: 'APIManagement',
  codit: true,

  data () {
    return {
      failed: false,
      loading: false,
      apikeys: [],

      dialog: {
        active: false,
        step: 1,
        loading: false,
        success: false,
        password: '',
        error: '',
        mode: '',
        deleteOrDisableID: '',
        newApiKey: {}
      }
    }
  },

  computed: {
    /**
     * Proxy for the `dialog.active` property.
     * Make sure the cancelDialog is called when closing the dialog, to clear current inputs.
     */
    dialogActive: {
      get () { return this.dialog.active },
      set (val) { if (!val) this.cancelDialog() }
    },

    newAPIKeyAvailable () { return this.apikeys.length < 3 },

    APIDocsURL () {
      return `https://caplena.com/docs/developers`
    },

    /**
     * The hash of the API key marked for deletion
     * @return {Object} API key
     */
    deleteOrDisableKey () { return _.find(this.apikeys, { key_hashed: this.dialog.deleteOrDisableID }) || {} },

    ...Vuex.mapState(['user'])
  },

  watch: {},

  created () {
    this.loadAPIKeys()
  },

  methods: {
    /**
     * Load the currently configured 2FA devices for the user
     */
    loadAPIKeys () {
      this.loading = true
      this.failed = false
      api.get('/api/auth/user/apikeys/').then(res => {
        this.$set(this, 'apikeys', res.data)
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Helper method, resetting the dialog properties to default values
     */
    _resetDialogProps () {
      this.dialog.step = 1
      this.dialog.loading = false
      this.dialog.success = false
      this.dialog.password = ''
      this.dialog.error = ''
      this.dialog.mode = ''
      this.dialog.deleteOrDisableID = ''
      this.dialog.newApiKey = {}
    },

    /**
     * Open the dialog in the "new apikey" mode
     */
    openNewDialog () {
      this.openDialog(this.generateNewKey)
      this.dialog.mode = 'new'
    },

    /**
     * Open the dialog in the "delete key" method
     * @param  {String} keyID   The ID of the apikey to delete
     */
    openDeleteDialog (keyID) {
      this.openDialog()
      this.dialog.deleteOrDisableID = keyID
      this.dialog.mode = 'delete'
    },

    /**
     * Open the dialog in the "disable key" method (toggle between enabled / disabled)
     * @param  {String} keyID   The ID of the apikey to disable / enable
     */
    openDisableDialog (keyID) {
      this.openDialog()
      this.dialog.deleteOrDisableID = keyID
      this.dialog.mode = 'disable'
    },

    generateNewKey () {
      this.dialog.loading = true
      this.dialog.error = ''
      api.post('/api/auth/user/apikeys/').then(res => {
        this.dialog.loading = false
        this.dialog.step = 2
        if (res.status === 201) {
          this.$set(this.dialog, 'newApiKey', res.data)
          this.loadAPIKeys()
        } else this.dialog.error = this.$t('error_generic')
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Generic method when opening the dialog
     * As all specific modes require a second layer of user authentication,
     * always check if the authentication process is already active
     * @param  {Function,undefined} processActiveCallback   Callback for when the `is-process-active` endpoint
     *                                                      returned   active=true If not defined, just go to the second step
     */
    openDialog (processActiveCallback) {
      this._resetDialogProps()
      this.dialog.active = true
      this.dialog.loading = true
      api.get('/api/auth/user/apikeys/is-process-active').then(res => {
        this.dialog.loading = false
        if (res.data.active === true) {
          if (processActiveCallback) processActiveCallback()
          else this.dialog.step = 2
        } else this.$nextTick(() => this.$refs['password'].focus())
      }).catch(err => {
        this.dialog.loading = false
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Cancel the current dialog and reset the props
     */
    cancelDialog () {
      if (this.dialog.loading) return
      this._resetDialogProps()
      this.dialog.active = false
    },

    /**
     * Submit the password in the first step of the dialog
     */
    submitPassword () {
      this.dialog.loading = true
      this.dialog.error = ''
      api.post('/api/auth/user/apikeys/initiate-setup', { password: this.dialog.password }, { dontReport: [400] }).then(res => {
        this.dialog.loading = false
        this.dialog.password = ''
        if (res.data.success === true) {
          if (this.dialog.mode === 'new') this.generateNewKey()
          else this.dialog.step += 1
        } else {
          if ('error' in res.data) this.dialog.error = res.data.error
          else this.dialog.error = this.$t('error_generic')
        }
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Confirm deletion / disabling of apikey
     */
    confirmDeleteOrDisable () {
      this.dialog.loading = true
      this.dialog.error = ''

      let path = `/api/auth/user/apikeys/${this.dialog.deleteOrDisableID}`
      let options = { dontReport: [400] }
      let call
      if (this.dialog.mode === 'delete') call = api.delete(path, options)
      else call = api.patch(path, { disabled: !this.deleteOrDisableKey.disabled }, options)
      call.then(res => {
        this.dialog.loading = false
        if (res.data.success === true || res.status === 200) {
          this.$root.snackMsg(this.$t(`api.${this.dialog.mode}.success${this.dialog.mode === 'disable' ? this.deleteOrDisableKey.disabled ? '_enabled' : '_disabled' : ''}`))
          this.cancelDialog()
          this.loadAPIKeys()
        } else {
          if ('error' in res.data) this.dialog.error = res.data.error
          else this.dialog.error = this.$t('error_generic')
        }
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<style lang=scss>

.api-management {
  display: flex;
  margin-left: 8px;

  .feature-unavailable {
    display: flex;
    .info-text {
      flex: 1;
    }
  }

  > * {
    flex: 1;

    .progress {
      margin: 8px 16px 24px;
      width: auto;
    }
  }

  > .v-alert {
    a { color: var(--v-accent-base); }
  }

  .existing-apikeys {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      margin: 4px 0;
      background: #EEE;
      border-radius: 4px;
      border-bottom: 1px solid #DDD;
      border-left: 3px solid #DDD;

      .enabled {
        margin-right: 12px;
        .true { color: #bd4147 }
      }

      .name {
        width: 150px;
      }

      .times {
        > span {
          display: block;

          .fieldname { color: #666; }
        }
      }

      .actions {
        width: 80px;
        a { display: block; }
      }
    }
  }
}

.dialog-apikey {
  .alert-container {
    padding: 8px 16px;
    .v-alert {
      margin: 0!important;
    }
  }

  .password {
    margin-top: 5px!important;
    width: 300px;
  }

  .controls-container {
    margin-top: 12px;
  }

  .new-key-real {
    font-family: monospace,monospace;
    padding: 8px 16px;
    margin-bottom: 4px;
    background: #EEE;
    border-radius: 4px;
    border: 1px solid #DDD;
    border-left: 3px solid #DDD;
    text-align: center;
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/account/ManageAPIKeys.json' />
<i18n locale='de' src='@/i18n/de/components/account/ManageAPIKeys.json' />