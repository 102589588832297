<template>
  <div class="credit-deduction default-bs">
    <template v-if="creditsRequired > userCreditsRemaining">
      <!-- warning if not enough credits available -->
      <notification
        type="warning"
        text
        border="left"
        prominent
        outlined
        class="pr-0 mb-0"
        :icon="false"
      >
        <template v-if="introText">
          <span v-html="introText" />
          <a target="_blank" :href="helpArticleLink" v-text="$t('billing.more_info')" />
        </template>
        <div class="pr-2">
          {{ $t('billing.not_enough_credits', {
            required: creditsRequired,
            remaining: userCreditsRemaining
          }) }}
          <router-link v-if="$root.isRootAccount"
                       class="buy-credits"
                       color="warning"
                       :to="{ name: 'account', query: { tab: 'subscription' } }"
          >
            {{ $t('billing.buy_additional') }}
          </router-link>
        </div>

        <v-btn @click="refresh" v-if="showReload" dense x-small icon color="warning" class="mr-2 refresh">
          <v-icon :class="{ loading }" small>
            mdi-reload
          </v-icon>
        </v-btn>

        <div v-if="!$root.isRootAccount"
             v-html="$t('billing.contact_admin', { email: user.organization.root_user_email })"
             style="margin-top: 6px;"
        />
      </notification>
    </template>
    <notification
      v-else-if="unknownCredits || (creditsRequired > 0 && showInfo)"
      type="info"
      :no-icon="confirmation"
      text
      border="left"
      class="mb-0"
      :class="className"
    >
      <div class="d-flex">
        <div class="d-flex flex-column">
          <span v-if="isIntegrationTab" class="mb-1" v-html="$t(`billing.integration_bill_credits_amount`, {
            n_credits: unknownCredits ? $t('billing.n_credits_unknown') : $t('billing.n_credits_known', { n: creditsRequired }),
            credits_available: userCreditsRemaining,
            column_count: append ? questionsToAppend.length : questionColumns.length,
            confirm: confirmation ? $t('billing.please_confirm') : ''
          })"
          />

          <span v-else class="mb-1" v-html="$t(`billing.bill_credits_amount`, {
            n_credits: unknownCredits ? $t('billing.n_credits_unknown') : $t('billing.n_credits_known', { n: creditsRequired }),
            credits_available: userCreditsRemaining,
            column_count: append ? questionsToAppend.length : questionColumns.length,
            confirm: confirmation ? $t('billing.please_confirm') : ''
          })"
          />

          <template v-if="introText">
            <span v-html="introText" class="mt-2" />
            <a target="_blank" :href="helpArticleLink" v-text="$t('billing.more_info')" />
          </template>
          <a
            v-if="!introText"
            target="_blank"
            :href="helpArticleLink" v-text="$t('billing.more_info')"
            class="mt-2"
          />
        </div>
        <v-checkbox
          v-if="confirmation"
          :input-value="answersInput.creditsConfirmed"
          @change="handleCreditsConfirmedChange"
          label=""
          class="mt-0 mb-0 pt-0 ml-3"
          primary
          hide-details
          flat
        />
      </div>
    </notification>
  </div>
</template>

<script>

import Vuex from 'vuex'
import { HELP_RESOURCE_CREDITS } from '@/settings/help-resources'

export default {
  name: 'CreditDeduction',

  props: {
    creditsRequired: { type: Number, default: 0 },
    showInfo: { type: Boolean, default: false },
    showReload: { type: Boolean, default: true },
    introText: { type: String, default: '' },
    helpArticleLink: { type: String, default: HELP_RESOURCE_CREDITS },
    confirmation: { type: Boolean, default: false },
    className: { type: String, default: '' },
    append: { type: Boolean, default: false },
    isIntegrationTab: { type: Boolean, default: false }
  },

  data () {
    return {
      loading: false,
      confirmed: false
    }
  },

  computed: {
    ...Vuex.mapState({
      answersInput: state => state.upload?.answersInput
    }),

    unknownCredits () {
      return this.canContinueDespiteTimeout || !_.isNumber(this.creditsRequired)
    },

    ...Vuex.mapState([
      'user'
    ]),
    ...Vuex.mapGetters([
      'userCreditsRemaining',
      'questionColumns',
      'questionsToAppend',
      'canContinueDespiteTimeout'
    ])
  },

  methods: {
    refresh () {
      this.loading = true
      const ts = new Date()
      const endLoading = () => { setTimeout(() => { this.loading = false }, Math.max(1000 - (new Date() - ts), 0)) }
      this.$root.getUser().then(endLoading).catch(err => { endLoading(); throw err })
    },

    handleCreditsConfirmedChange (val) {
      this.$store.commit('setCreditsConfirmed', val)
    }
  }
}

</script>

<style lang=scss>

.credit-deduction {
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .buy-credits {
    text-decoration: underline;
    color: var(--v-warning-base) !important;
    font-weight: 500;
  }

  .refresh {
    position: absolute;
    bottom: 8px;
    right: 0;
  }

  .loading {
    animation: rotating 1s ease;
  }

  .flex-center {
    flex-wrap: wrap;
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Billing.json' />
<i18n locale='de' src='@/i18n/de/pages/Billing.json' />
