<template>
  <div
    :class="[
      'd-flex mx-1 my-2 m vertical-accordion__item',
      {
        'vertical-accordion__item--border': isBordered,
        'vertical-accordion__item--active': isActive && !isOpen
      }
    ]"
    style="height: 32px;"
  >
    <div
      :class="[
        'vertical-accordion__item-trigger d-flex caption px-2',
        {
          'vertical-accordion__item-trigger--border': !isBordered,
          'vertical-accordion__item-trigger--active': isActive && !isOpen
        }
      ]"
      @click="triggerClickHandler"
    >
      <v-tooltip
        bottom
        :disabled="!tooltipText"
        :max-width="200"
        :open-delay="250"
      >
        <template v-slot:activator="{ on }">
          <div
            class="d-flex align-center justify-center"
            v-on="on"
          >
            <slot name="accordion-trigger" />
          </div>
        </template>
        <span v-if="tooltipText" v-html="tooltipText" />
      </v-tooltip>
    </div>
    <div
      v-if="isOpen"
      :class="[
        'vertical-accordion__item-content',
        {
          'vertical-accordion__item-content--border': isBordered
        }
      ]"
    >
      <slot name="accordion-content" />
    </div>
  </div>
</template>

<script>
import { generateInject, injectComputedValues } from '@/components/customUi/mixins/provideInject'

export default {
  props: {
    isBordered: { type: Boolean, required: false, default: true },
    isActive: { type: Boolean, required: false, default: false },
    tooltipText: { type: [String, null], required: false, default: null }
  },
  inject: generateInject(['accordionItemCount', 'activeAccordionItem']),
  data () {
    return {
      currentItemIndex: null
    }
  },
  computed: {
    ...injectComputedValues(['activeAccordionItem', 'accordionItemCount']),
    isOpen () {
      return this.currentItemIndex === this.activeAccordionItem
    }
  },
  created () {
    this.currentItemIndex = this.accordionItemCount + 1
    this.accordionItemCount = this.currentItemIndex
  },
  methods: {
    triggerClickHandler () {
      if (
        this.isOpen
      ) {
        this.activeAccordionItem = null
      } else {
        this.activeAccordionItem = this.currentItemIndex
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-accordion__item {
  &--border{
    border: 1px solid #d8e2e8;
    border-radius: 5px;
  }
  &--active {
    border-color: var(--v-primary-base);
  }
  &-trigger{
    min-width: 32px;
    cursor: pointer;
    &--border{
      border: 1px solid #d8e2e8;
      border-radius: 5px;
    }
    &--active {
      border-color: var(--v-primary-base);
    }
  }
  &-content{
    &--border{
      border-left: 1px solid #d8e2e8;
    }
  }
}
</style>