import Vue from 'vue'
import axios from 'axios'
import { TEXT_TO_ANALYZE_CATEGORY_LIST, TOPIC_ADHOC_ID_PREFIX, DEFAULT_ROW_LIMIT } from '@/settings/constants'
// import { get3ShadesForColor } from '@/utils/colorUtils'

import topicsMixin from '@/store/mixins/topics'

import registerManager from '@/store/managers/register'
import metaManager from '@/store/managers/meta'
import configManager from '@/store/managers/config'
import verbatimManager from '@/store/managers/verbatim'
import questionManager from '@/store/managers/question'

import verbatimDialog from '@/store/mixins/verbatimDialog'

const activeRowCache = {
  page: null,
  rowIdx: null
}

const store = {
  strict: process.env.NODE_ENV !== 'production',
  state () {
    return {
      codingView: true,
      id: `ch__new`,
      isInitialized: false,
      destroyed: false,
      editable: true,
      listCodable: false,
      loadingRowsLock: false,

      projectID: '',
      columnRef: '',
      project: {},

      stats: {},

      codingColumn: {},
      filtersInitialized: false,

      isGroupingDuplicates: true,

      activeRowID: null,
      activeRowIndex: null,

      focusRowIndex: null,

      allRowsSelected: false,

      codingDrawerDropdownOpen: false,

      refetchAfterRowChange: false,

      savingError: {
        saveRowsFailures: {},
        saveTopicsFailures: 0,
        authenticationError: false,
        networkError: false
      },

      newTopicDialog: {
        open: false,
        name: '',
        category: null,
        search: '',
        sentimentEnabled: true,
        loading: false
      },

      bulkAssignOptions: {
        mode: 'add',
        reviewed: null,
        failed: false,
        newTopic: null,
        loading: false,
        failedDeletion: false
      },

      sessionIssue: {
        show: false,
        type: null
      },

      question: {
        data: null,
        loading: true,
        failed: false
      },

      modelScore: null,

      focusMode: false,
      fullscreenTopicsMode: false,
      filtersShown: false,

      changesRemainingUntilRetraining: null,
      codingUpdateETA: null,
      inferenceUpdater: 0,

      keyboardShortcutsDialog: false,

      topicSentimentDrawer: false,

      ...topicsMixin.state()
    }
  },

  mutations: {
    /** =========== Global Page State =========== **/
    isInitialized: state => { state.isInitialized = true },
    isDestroyed: state => { state.destroyed = true },
    isNotEditable: state => { state.editable = false },
    isEditable: state => { state.editable = true },
    listIsCodable: state => { state.listCodable = true },

    /** =========== Connection / Session related  =========== **/
    hasSessionIssue: (state, type) => {
      state.sessionIssue.type = type
      state.sessionIssue.show = true
    },

    closeSessionIssue: (state) => {
      state.sessionIssue.show = false
    },

    setUsersOnline: (state, usersOnline) => {
      Vue.set(state, 'usersOnline', usersOnline)
    },

    /**
     * Depending on the return status of a failed request, set the appropriate error flags
     * @param  {int,undefined} status  The return status of the failed request
     */
    savingErrorStatus: (state, status) => {
      state.savingError.networkError = !status
      state.savingError.authenticationError = (status === 401 || status === 403)
    },

    incrementInferenceUpdater: (state) => {
      state.inferenceUpdater = state.inferenceUpdater + 1
    },

    trainingCountdownUpdate: (state, value) => {
      state.changesRemainingUntilRetraining = value
    },

    setCodingUpdateETA: (state, value) => {
      state.codingUpdateETA = value
    },

    setFiltersShown: (state, value) => {
      state.filtersShown = value
    },

    /**
     * Increase the counter for failed topic saving requests
     */
    savingTopicsError: (state) => {
      state.savingError.saveTopicsFailures += 1
    },

    /**
     * Reset the counter for failed topic saving requests
     */
    clearSavingTopicsError: state => { state.savingError.saveTopicsFailures = 0 },

    /**
     * Increment the error count for a failed row saving request
     * @param  {String} rowID The row ID which failed
     */
    savingRowErrorIncrement: (state, rowID) => {
      if (!(rowID in state.savingError.saveRowsFailures)) Vue.set(state.savingError.saveRowsFailures, rowID, 0)
      state.savingError.saveRowsFailures[rowID] += 1
    },

    /**
     * Clear the save error count for a row
     * @param  {String} rowID The row ID which now succeeded
     */
    savingRowErrorClear: (state, rowID) => {
      Vue.set(state.savingError.saveRowsFailures, rowID, 0)
    },

    /** =========== Project related  =========== **/

    /**
     * Set the project & column identifiers for this page
     * @param {String} options.projectID The project ID
     * @param {String} options.columnRef The column reference
     */
    setIdentifiers (state, { projectID, columnRef }) {
      state.projectID = projectID
      state.columnRef = columnRef
    },

    setProject (state, { project, columnRef }) {
      // Set all key in question object that are already defined on this data obj
      // _.each(state.question, (val, key) => { Vue.set(state.question, key, question[key]) })

      Vue.set(state, 'project', project)
      Vue.set(state, 'codingColumn', _.find(project.columns, { ref: columnRef }))
    },

    setTopicFilter (state, topic) {
      Vue.set(state, 'topicFilter', topic)
    },

    setActiveRow (state, row) {
      let id
      if (_.isArray(row)) id = row
      else if (!row) id = null
      else id = row.id
      Vue.set(state, 'activeRowID', id)
    },

    setActiveRowIndex (state, idx) {
      Vue.set(state, 'activeRowIndex', idx)
    },

    setFocusRowIndex (state, idx) {
      Vue.set(state, 'focusRowIndex', idx)
    },

    setRefetchAfterRowChange (state, val) {
      Vue.set(state, 'refetchAfterRowChange', val)
    },

    setGroupingDuplicates (state, value) {
      state.isGroupingDuplicates = value
    },

    openSentimentDrawer (state, value) {
      state.topicSentimentDrawer = value
    },

    addTopicToReview (state, { topicID }) {
      const activeRow = store.getters.activeRow(state)
      Vue.set(activeRow.codingColumn, 'topics', [...activeRow.codingColumn.topics, { id: topicID, sentiment: '' }])
    },

    removeTopicFromReview (state, { topicID }) {
      const activeRow = store.getters.activeRow(state)
      Vue.set(activeRow.codingColumn, 'topics', _.filter(activeRow.codingColumn.topics, topic => topic.id !== topicID))
    },

    setCodingDrawerDropdownOpen (state, value) {
      state.codingDrawerDropdownOpen = value
    },

    setTopicSentiment (state, { topicIdx, sentiment }) {
      const activeRow = store.getters.activeRow(state)
      Vue.set(activeRow.codingColumn.topics[topicIdx], 'sentiment', sentiment)
    },

    resortTopics (state, { topics }) {
      const activeRow = store.getters.activeRow(state)
      Vue.set(activeRow.codingColumn, 'topics', topics)
    },

    setActiveRowAsReviewed (state, reviewed) {
      const activeRow = store.getters.activeRow(state)
      Vue.set(activeRow.codingColumn, 'was_reviewed', reviewed)
    },

    setProjectCodingColumnMetadata (state, props) {
      if (_.has(props, 'doGroupDuplicates')) Vue.set(state.codingColumn.metadata, 'do_group_duplicates', props.doGroupDuplicates)
      if (_.has(props, 'doShowTranslations')) Vue.set(state.codingColumn.metadata, 'do_show_translations', props.doShowTranslations)
    },

    setRowTranslateOverwritten (state, { row, overwrite }) {
      Vue.set(row, 'translateOverwritten', overwrite)
    },

    setHighlight (state, { row, highlight }) {
      Vue.set(row.codingColumn, 'highlight', highlight)
    },

    setReviewed (state, { row, reviewed }) {
      Vue.set(row.codingColumn, 'was_reviewed', reviewed)
    },

    setRowSentiment (state, { row, sentiment }) {
      Vue.set(row.codingColumn, 'sentiment_overall', sentiment)
    },

    setNewTopicDialog (state, { key, value }) {
      Vue.set(state.newTopicDialog, key, value)
    },

    setQuestion (state, { key, value }) {
      Vue.set(state.question, key, value)
    },

    setFocusMode (state, value) {
      state.focusMode = value
    },

    setFullscreenMode (state, value) {
      state.fullscreenTopicsMode = value
    },

    setBulkAssignOption (state, { key, value }) {
      Vue.set(state.bulkAssignOptions, key, value)
    },

    toggleAllRowsSelected (state, value) {
      state.allRowsSelected = !state.allRowsSelected
      state.activeRowID = null
    },

    setAllRowsSelected (state, value) {
      state.allRowsSelected = value
    },

    setModelScore (state, value) {
      state.modelScore = value
    },

    setStats (state, stats) {
      Vue.set(state, 'stats', stats)
    },

    setKeyboardShortcutsDialog (state, value) {
      state.keyboardShortcutsDialog = value
    },

    setMergeFailed (state, value) {
      state.mergeFailed = value
    },

    ...topicsMixin.mutations
  },

  getters: {
    isListColumn: (state) => state.codingColumn.metadata?.category === TEXT_TO_ANALYZE_CATEGORY_LIST,
    codingDrawerOpen: (state, getters) => (!!getters.activeRow || state.allRowsSelected) && !state.focusMode,
    auxiliaryColumns: (state) => state.project.columns ? state.project.columns.filter(column => !_.isEqual(column, state.codingColumn)) : [],
    showTranslations: (state) => state.codingColumn.metadata.do_show_translations,
    bulkAssign: (state) => _.isArray(state.activeRowID) || state.allRowsSelected,

    rowsPerPage: (rootState) => rootState.verbatimManager.ch__new.rowsPerPage,

    getRowByID: (rootState) => (id) => {
      const rowsPerPage = rootState.verbatimManager.ch__new.rowsPerPage

      let res
      _.each(rowsPerPage, (rows, page) => {
        if (res) return
        res = _.find(rows, { id })
      })
      if (res) return res
      throw Error(`Row id=${id} could not be found`)
    },

    getRowByIndex: (rootState) => (index) => {
      const rowsPerPage = rootState.verbatimManager.ch__new.rowsPerPage

      const page = _.floor(index / DEFAULT_ROW_LIMIT) + 1
      if (page in rowsPerPage) return rowsPerPage[page][index % DEFAULT_ROW_LIMIT]
      else return undefined
    },

    activeRow: (rootState) => {
      const rowsPerPage = rootState.verbatimManager?.ch__new?.rowsPerPage || {}

      if (!rootState.activeRowID === null) return null
      else if (_.isArray(rootState.activeRowID)) return rootState.activeRowID
      else if (rowsPerPage[activeRowCache.page]?.[activeRowCache.rowIdx]?.id === rootState.activeRowID) {
        return rowsPerPage[activeRowCache.page][activeRowCache.rowIdx]
      }

      let res
      _.each(rowsPerPage, (rows, page) => {
        if (res) return
        let rowIdx = _.findIndex(rows, { id: rootState.activeRowID })
        if (rowIdx >= 0) {
          res = rows[rowIdx]
          activeRowCache.rowIdx = rowIdx
          activeRowCache.page = page
        }
      })
      if (res) return res
      else return null
    },

    ...topicsMixin.getters
  },

  actions: {
    // Add these actions here on purpose, so that we may override them
    ...topicsMixin.actions,

    /**
     * Perform the initial data loading
     * @param  {str} options.projectID       The ID of the project to load
     * @param  {str} options.columnRef       The reference of the column we're coding
     */
    loadData ({ commit, state, getters, dispatch }, { projectID, columnRef }) {
      commit('setIdentifiers', { projectID, columnRef })
      // commit('loadingFailedReset')

      let project
      // Fetch the project, including the initial version of the codebook
      let projectRequest = api.get(`/api/ui/projects/${projectID}?expand=${columnRef}`).then((res) => {
        project = res.data
      }).catch((err) => {
        dispatch('maybeRaiseAPIPromiseErr', err)
        dispatch('verbatimManager/loadingFailed', { entityId: state.id, value: true })
        // Re-raise the error, such that axios.all doesn't think it's a success
        throw err
      })

      return axios.all([projectRequest]).then(v => {
        if (state.destroyed) return
        commit('setProject', { project, columnRef })
        commit('setTopics', state.codingColumn.topics)
        commit('isInitialized')
        if (!window.app.hasPermission('projects_edit', project)) {
          commit('isNotEditable')
        }
        dispatch('loadQuestion')
        dispatch('loadStats')
      }).catch((err) => {
        dispatch('maybeRaiseAPIPromiseErr', err)
        throw err
      })
    },

    /**
     * Load this question & the answers
     * @param  {Number} questionID The question ID to load
    */
    loadQuestion ({ commit, state, getters, dispatch }) {
      // if (!this.isInitialized) return

      const questionID = _.find(state.project.columns, column => column.ref === state.columnRef)?.metadata?.question_id
      if (!questionID) return

      // commit('setQuestion', { key: 'loading', value: true })
      commit('setQuestion', { key: 'failed', value: false })

      // Fetch question info and codebook
      return api.get(`/api/questions/${questionID}`)
        .then((res) => {
          const question = res.data
          commit('setQuestion', { key: 'data', value: question })
          commit('setQuestion', { key: 'loading', value: false })
          commit('setBreadcrumbProps', {
            projectID: question.project,
            projectName: question.project_name,
            questionID: question.id,
            questionName: question.name
          })
        })
        .catch((err) => {
          dispatch('maybeRaiseAPIPromiseErr', err)
          commit('setQuestion', { key: 'loading', value: false })
          commit('setQuestion', { key: 'failed', value: true })
          throw err
        })
    },

    /**
     * Load the statistics for the current column to analyze
     */
    loadStats ({ commit, state, getters, dispatch }) {
      return api.get(`/api/ui/projects/${state.projectID}/coding/${state.columnRef}/stats`)
        .then((res) => {
          commit('setStats', res.data)
        })
        .catch((err) => {
          dispatch('maybeRaiseAPIPromiseErr', err)
          throw err
        })
    },

    toggleFocusMode ({ commit, state, getters, dispatch }) {
      commit('setFocusMode', !state.focusMode)
      commit('setFocusRowIndex', null)
    },

    saveRow ({ commit, state, getters, dispatch }, row) {
      const patchObj = {
        was_reviewed: row.codingColumn.was_reviewed,
        topics: row.codingColumn.topics.map(topic => ({ id: topic.id, sentiment: topic.sentiment || 'neutral' })),
        highlight: row.codingColumn.highlight && row.codingColumn.highlight.length ? row.codingColumn.highlight : null,
        update_identicals: state.isGroupingDuplicates,
        client_timestamp: new Date().toISOString()
      }

      const doPatch = (data) => {
        return api.patch(`/api/ui/projects/${state.project.id}/coding/${state.codingColumn.ref}/rows/${row.id}`, patchObj)
          .then(res => {
            commit('savingRowErrorClear', row.id)
            commit('setRowSentiment', { row, sentiment: res.data.sentiment_overall })
          })
          .catch(err => {
            if (state.destroyed) return
            commit('savingErrorStatus', err?.response?.status)
            commit('savingRowErrorIncrement', row.id)
            setTimeout(() => doPatch(data), 5000)
            dispatch('maybeRaiseAPIPromiseErr', err)
            throw err
          })
      }

      return doPatch(patchObj)
    },

    /**
     * Check if there is at least one reviewed row of the given topic
     */
    hasTopicRevieweds ({ commit, state, getters, dispatch }, { topicID }) {
      return new Promise((resolve, reject) => {
        api.get(`/api/ui/projects/${state.projectID}/coding/${state.columnRef}/has-topic-reviewed-rows?topic=${topicID}`).then(res => {
          resolve(res.data.has_reviewed_rows)
        }).catch(err => {
          dispatch('maybeRaiseAPIPromiseErr', err)
        })
      })
    },

    /**
     * Currently selected active row has changed
     */
    handleActiveRowChange ({ commit, state, getters, dispatch }) {
      // Update the active row as a change has been made in the RowBrowser (right side pane;)
      dispatch('saveRow', getters.activeRow)
    },

    /**
     * Set the row's highlight if necessary and save changes
     */
    setRowHighlight ({ commit, state, getters, dispatch }, { id, highlight }) {
      const row = getters.getRowByID(id)

      // If the values are the same there's no need to update
      if (!highlight.length && (!row.codingColumn.highlight || !row.codingColumn.highlight.length)) {
        return
      }

      commit('setHighlight', { row, highlight })
      dispatch('saveRow', row)
    },

    setRowReviewStatus ({ commit, state, getters, dispatch }, { id, reviewed }) {
      const row = getters.getRowByID(id)
      commit('setReviewed', { row, reviewed })
      dispatch('saveRow', row)
    },

    setGroupingDuplicates ({ commit, state, getters, dispatch }, value) {
      commit('setGroupingDuplicates', value)
    },

    /**
     * Set the currently selected row was_reviewed property
     */
    maybeSetActiveRowAsReviewed ({ commit, state, getters, dispatch }) {
      // TODO: Maybe trigger warning here if false
      // commit('setActiveRowAsReviewed', !!state.activeRow.codingColumn.topics.length)
      commit('setActiveRowAsReviewed', true)
    },

    /**
     * Patch the project question with props
     */
    patchProjectWithProps ({ commit, state, getters, dispatch }, props) {
      commit('setProjectCodingColumnMetadata', props)

      // this feels dumb todo: clean up
      return api.patch(`/api/ui/projects/${state.project.id}`, {
        columns: [{
          ref: state.codingColumn.ref,
          type: state.codingColumn.type,
          metadata: {
            // Only these 2 properties can get updated
            do_group_duplicates: _.has(props, 'doGroupDuplicates') ? props.doGroupDuplicates : state.codingColumn.metadata.do_group_duplicates,
            do_show_translations: _.has(props, 'doShowTranslations') ? props.doShowTranslations : state.codingColumn.metadata.do_show_translations
          }
        }]
      })
    },

    /**
     * Save the topics
     */
    saveTopics ({ commit, state, getters, dispatch }, { retry }) {
      if (state.destroyed) return

      // Remove the temporary code ids, they represent new codes
      // ids for new codes will be generated by the backend
      let saveTopics = _.map(state.topics, cd => ({ ...cd, id: cd.id.startsWith(TOPIC_ADHOC_ID_PREFIX) ? null : cd.id }))

      return api.patch(`/api/ui/projects/${state.project.id}/${state.codingColumn.ref}/codebook`, {
        topics: saveTopics,
        inference_training_debounce_delay: 'default',
        client_timestamp: new Date().toISOString()
      })
        .then(res => {
          if (res.status !== 200) {
            if (retry) setTimeout(() => dispatch('saveTopics', { retry: true }), 5000) // Retry in 5s
            commit('savingTopicsError')
            commit('savingErrorStatus', res?.status)
          } else {
            commit('clearSavingTopicsError')
            commit('setTopics', _.cloneDeep(res.data))
            dispatch('metaManager/manualUpdate', { entityId: state.id, data: _.cloneDeep(res.data) }, { root: true })
          }
        })
        .catch(err => {
          if (retry) setTimeout(() => dispatch('saveTopics', { retry: true }), 5000) // Retry in 5s
          commit('savingTopicsError', (err.response && err.response.status))
          dispatch('maybeRaiseAPIPromiseErr', err)
          throw err
        })
    },

    /**
     * Override the mergeTopics from the topics mixin
     * @param  {Number} options.parentID       The id of the parent topic
     * @param  {String} options.parentAttitude The attitude to merge the parent topic into, or null
     * @param  {Array}  options.childID        The child topic to merge into the parent
     */
    mergeTopics: ({ commit, state, getters, dispatch }, { parentID, childID, childTargetSentiment, parentTargetSentiment, newProps }) => {
      commit('setMergeFailed', false)
      dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: true })
      return api.post(`/api/ui/projects/${state.project.id}/${state.codingColumn.ref}/codebook/merge`, {
        parent_topic_id: parentID,
        child_topic_id: childID,
        child_target_sentiment: childTargetSentiment,
        parent_target_sentiment: parentTargetSentiment,
        parent_topic_new_props: newProps
      })
        .then(res => {
          commit('setTopics', res.data)
          dispatch('metaManager/manualUpdate', { entityId: state.id, data: res.data }, { root: true })
          dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: false })
        })
        .catch(err => {
          commit('setMergeFailed', true)
          dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: false })
          dispatch('maybeRaiseAPIPromiseErr', err)
          throw err
        })
    },

    changeReviewSentiment ({ commit, state, getters, dispatch }, { topicID, sentiment }) {
      const topicIdx = _.findIndex(getters.activeRow.codingColumn.topics, topic => topic.id === topicID)

      if (topicIdx !== -1) {
        commit('setTopicSentiment', { topicIdx, sentiment })
      } else {
        commit('addTopicToReview', { topicID })
        const newTopicIdx = _.findIndex(getters.activeRow.codingColumn.topics, topic => topic.id === topicID)
        commit('setTopicSentiment', { topicIdx: newTopicIdx, sentiment })
      }
    },

    /**
     * Bulk row selected rows
     */
    applyBulkCoding ({ commit, state, getters, dispatch }, { queryString, topics }) {
      commit('setBulkAssignOption', { key: 'failed', value: false })

      const { mode, reviewed } = state.bulkAssignOptions
      const apiTopics = _.map(topics, t => ({
        id: t.id,
        sentiment: t.sentiment || 'neutral'
      }))

      let options = {
        topics: apiTopics,
        mode,
        set_reviewed: reviewed === 'set_reviewed',
        set_unreviewed: reviewed === 'set_unreviewed',
        update_identicals: state.isGroupingDuplicates
      }

      if (!state.allRowsSelected) {
        options.row_ids = state.activeRowID
      }

      dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: true })
      dispatch('resetRows')

      return api.patch(`api/ui/projects/${state.project.id}/coding/${state.codingColumn.ref}/bulk/rows?${queryString.length ? queryString : ''}`, options)
        .then((data) => {
          dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: false })
          return dispatch('hardRefetchRows', { queryString })
        })
        .catch(err => {
          dispatch('maybeRaiseAPIPromiseErr', err)
          dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: false })
          commit('setBulkAssignOption', { key: 'failed', value: true })
          throw err
        }
        )
    },

    /**
     * Delete selected rows from the coding drawer
     */
    deleteSelectedRows ({ commit, state, getters, dispatch }, { queryString, asyncCb }) {
      commit('setBulkAssignOption', { key: 'failedDeletion', value: false })
      commit('setBulkAssignOption', { key: 'loading', value: true })

      dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: true })

      let options = {}

      if (!state.allRowsSelected) {
        options.row_ids = state.activeRowID
      }

      return api.post(`api/ui/projects/${state.project.id}/coding/${state.codingColumn.ref}/bulk/rows/delete${queryString.length ? '?' + queryString : ''}`, options)
        .then(res => {
          return asyncCb(res)
        })
        .catch(err => {
          dispatch('verbatimManager/setLoadingRowsLock', { entityId: state.id, value: false })
          dispatch('maybeRaiseAPIPromiseErr', err)
          commit('setBulkAssignOption', { key: 'loading', value: false })
          commit('setBulkAssignOption', { key: 'failedDeletion', value: true })
          throw err
        })
    },

    /**
     * Used in the coding page for refreshing currently selected rows
     */
    resetRows ({ commit, state, getters, dispatch }) {
      commit('verbatimManager/setRowDataFetching', { id: state.id, value: true }, { root: true })
      commit('setActiveRow', null)
      commit('setActiveRowIndex', null)
      commit('verbatimManager/resetRowsPerPage', { id: state.id }, { root: true })
    },

    /**
     * Refetch all rows
     */
    hardRefetchRows ({ commit, state, getters, dispatch }, { queryString }) {
      return dispatch('verbatimManager/loadRows', { id: state.id, entiscrolledPage: 1, queryString }, { root: true })
    },

    onUnload ({ commit, state, getters, dispatch }) {
      commit('isDestroyed')
    },

    /**
     * Proxy for calling the api error handler on the parent vm
     */
    maybeRaiseAPIPromiseErr  ({ commit, state, getters, dispatch }, err) {
      this._vm.$maybeRaiseAPIPromiseErr(err)
    }
  },

  modules: {
    registerManager,
    metaManager,
    configManager,
    verbatimManager,
    questionManager,
    verbatimDialog
  }
}

export default store