import Vue from 'vue'

const modifyData = (entityId, entityData) => {
  const topics = utilityData[entityId].topics
  let modifiedData = _.cloneDeep(entityData)

  if (
    !topics?.length
  ) {
    return modifiedData
  }

  if (
    modifiedData.config?.plotTopicsFilter?.select
  ) {
    const plotTopicsFilterList = [...modifiedData.config.plotTopicsFilter.select]
    modifiedData.config.plotTopicsFilter.select = topics.filter(({ id }) => plotTopicsFilterList.indexOf(id) > -1)
  }

  const filterIndex = _.findIndex(modifiedData.config.filters, ({ type }) => type === 'topics')

  /*
  * should fix data format in use
  * if the data is downloaded and used onlu in store
  */
  if (
    filterIndex > -1 &&
    Array.isArray(modifiedData.config.filters[filterIndex].value?.[0]) &&
    modifiedData.config.filters[filterIndex].value?.[0].length === 2
  ) {
    const topicsFilterList = modifiedData.config.filters[filterIndex].value.reduce((sum, item) => ({
      ...sum,
      [item[0]]: item[1]
    }), {})
    modifiedData.config.filters[filterIndex].value = topics.filter(
      ({ id }) => topicsFilterList[id]
    ).map(item => {
      if (
        topicsFilterList[item.id] === 'any'
      ) {
        return item
      }
      return {
        ...item,
        sentiment: topicsFilterList[item.id]
      }
    })
  }

  return modifiedData
}
const fullfillUtilityData = rootState => entityId => {
  utilityData[entityId] = {
    topics: rootState.coding.metaManager[entityId]?.topics_complete_union
  }
}

let utilityData = {}

export default {
  namespaced: true,

  state () {
    return {}
  },

  mutations: {
    register (state, { entityId, entityData, topics }) {
      Vue.set(
        state,
        entityId,
        modifyData(entityId, entityData, utilityData[entityId].topics)
      )
    },

    identify (state, { entityIdToSet, entityIdToRemove }) {
      Vue.set(state, entityIdToSet, state[entityIdToRemove])
      Vue.delete(state, entityIdToRemove)
    },

    delete (state, { entityId }) {
      Vue.delete(state, entityId)
    }
  },

  actions: {
    async register ({ rootState, commit, dispatch }, { entityId, entityData }) {
      fullfillUtilityData(rootState)(entityId)
      commit('register', { entityId, entityData })
    },
    async registerMany ({ commit, rootState }, { list }) {
      list.forEach(({ entityId, entityData }) => {
        fullfillUtilityData(rootState)(entityId)
        commit('register', { entityId, entityData })
      })
    },
    identify ({ commit, dispatch }, { entityIdToSet, entityIdToRemove }) {
      commit('identify', { entityIdToSet, entityIdToRemove })
    },
    async update ({ commit, rootState }, { entityId, entityData }) {
      fullfillUtilityData(rootState)(entityId)
      commit('register', { entityId, entityData })
    },
    delete ({ commit, dispatch }, { entityId }) {
      commit('delete', { entityId })
    }
  },

  getters: {
    overall: (state, getters) => { return _.cloneDeep(state) },

    state: (state, getters) => (id) => {
      return state[id]
    }
  }
}
