<template>
  <DialogWrapper
    v-model="internalValue"
    :width="280"
  >
    <DialogHeader>
      <template slot="title">
        <div v-html="$t('remove_element_confirm')" />
      </template>
      <template slot="controls">
        <v-icon
          @click="closeDialog"
          :size="20"
        >
          mdi-close
        </v-icon>
      </template>
    </DialogHeader>
    <DialogActions>
      <template slot="rightSide">
        <v-btn
          color="primary"
          outlined
          class="mr-2"
          @click.native="closeDialog"
        >
          {{ $t('no') }}
        </v-btn>
        <v-btn
          color="primary"
          @click.native="removeElement"
        >
          {{ $t('yes') }}
        </v-btn>
      </template>
    </DialogActions>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import DataMixin from '@/components/customUi/mixins/data'
import DialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'DashboardDetailRemoveElement',
  codit: true,

  components: {
    DialogWrapper,
    DialogHeader,
    DialogActions
  },

  mixins: [DataMixin, DialogMixin],

  methods: {
    removeElement () {
      this.closeDialog()
      this.$emit('removeElement')
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />