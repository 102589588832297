import { render, staticRenderFns } from "./DashboardDetailAddOrEditElementCharts.vue?vue&type=template&id=5a3b7f96&scoped=true"
import script from "./DashboardDetailAddOrEditElementCharts.vue?vue&type=script&lang=js"
export * from "./DashboardDetailAddOrEditElementCharts.vue?vue&type=script&lang=js"
import style0 from "./DashboardDetailAddOrEditElementCharts.vue?vue&type=style&index=0&id=5a3b7f96&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3b7f96",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/pages/Dashboard.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fmodals%2FDashboardDetailAddOrEditElement%2FDashboardDetailAddOrEditElementCharts.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/pages/Dashboard.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fmodals%2FDashboardDetailAddOrEditElement%2FDashboardDetailAddOrEditElementCharts.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)

export default component.exports