var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'd-flex mx-1 my-2 m vertical-accordion__item',
    {
      'vertical-accordion__item--border': _vm.isBordered,
      'vertical-accordion__item--active': _vm.isActive && !_vm.isOpen
    }
  ],staticStyle:{"height":"32px"}},[_c('div',{class:[
      'vertical-accordion__item-trigger d-flex caption px-2',
      {
        'vertical-accordion__item-trigger--border': !_vm.isBordered,
        'vertical-accordion__item-trigger--active': _vm.isActive && !_vm.isOpen
      }
    ],on:{"click":_vm.triggerClickHandler}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.tooltipText,"max-width":200,"open-delay":250},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center"},on),[_vm._t("accordion-trigger")],2)]}}],null,true)},[_vm._v(" "),(_vm.tooltipText)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.tooltipText)}}):_vm._e()])],1),_vm._v(" "),(_vm.isOpen)?_c('div',{class:[
      'vertical-accordion__item-content',
      {
        'vertical-accordion__item-content--border': _vm.isBordered
      }
    ]},[_vm._t("accordion-content")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }