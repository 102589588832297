var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard__suggestion",class:{
    'wizard__suggestion--completed': _vm.suggestion.done,
    'wizard__suggestion--active': !_vm.suggestion.done && _vm.clicked
  },on:{"click":e => !_vm.suggestion.done && _vm.handleSuggestionClick(e, _vm.suggestion),"mouseover":function($event){!_vm.clickedSuggestion && !_vm.suggestion.done && !(_vm.suggestion.action === _vm.MOVED_TOPIC) && _vm.$store.commit('setActiveSuggestion', _vm.suggestion)},"mouseleave":function($event){!_vm.clickedSuggestion && !_vm.clicked && !_vm.suggestion.done && _vm.$store.commit('setActiveSuggestion', null)}}},[(_vm.suggestion.action !== _vm.MOVED_TOPIC)?_c('v-tooltip',{attrs:{"eager":"","bottom":"","disabled":_vm.isTooltipDisabled(_vm.suggestion)},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-checkbox',{attrs:{"primary":"","hide-details":"","ripple":false,"color":"#05202E","off-icon":"mdi-checkbox-blank","label":"","input-value":_vm.suggestion.done,"flat":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"change":value => _vm.handleSuggestionStatusChange(value, _vm.suggestion)}}),_vm._v(" "),_c('div',{staticClass:"wizard__suggestion__content"},[_c('div',_vm._g({},on),[_c('div',{staticClass:"wizard__suggestion__title",domProps:{"innerHTML":_vm._s(_vm.$t(`suggestions.${_vm.suggestion.action}_title`))}}),_vm._v(" "),(!_vm.suggestion.done)?_c('div',{staticClass:"wizard__suggestion__text pb-2",domProps:{"innerHTML":_vm._s(_vm.$t(`suggestions.${_vm.suggestion.action}_description${_vm.suggestionTopics && _vm.suggestionTopics.length === 1 ? '_single' : ''}`, {
              topics: _vm.getSuggestedTopicsList(_vm.suggestion)
            }))}}):_vm._e()]),_vm._v(" "),(!_vm.suggestion.done && _vm.suggestion.action !== _vm.MOVED_TOPIC)?_c('span',{staticClass:"wizard__suggestion__action wizard__suggestion__action--dismiss",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSuggestionStatusChange(true, _vm.suggestion)}}},[_vm._v("\n          "+_vm._s(_vm.$t('done'))+"\n        ")]):_vm._e()])]}}],null,false,1609172099)},[_vm._v("\n    "+_vm._s(_vm.getSuggestionTooltipText(_vm.suggestion))+"\n    ")]):_c('div',{staticClass:"wizard__suggestion__content"},[_c('div',{staticClass:"wizard__suggestion__text pb-2",domProps:{"innerHTML":_vm._s(_vm.$t(`suggestions.${_vm.suggestion.action}_description${_vm.suggestionTopics && _vm.suggestionTopics.length === 1 ? '_single' : ''}`, {
        topics: _vm.getSuggestedTopicsList(_vm.suggestion),
        category: _vm.getSuggestedCategory(_vm.suggestion)
      }))}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }