import {
  CHART_TYPE_BAR,
  CHART_TYPE_SENTIMENT_BAR,
  CHART_TYPE_LINE_PIE,
  CHART_TYPE_DATE_LINE_PIE,
  CHART_TYPE_ROWS_COUNT_BAR,
  CHART_TYPE_TREEMAP,
  CHART_TYPE_GRAPH,
  CHART_TYPE_DRIVER_SCATTER,
  CHART_TYPE_SCORE,
  CHART_TYPE_DYNAMIC_BAR,
  /*
  * use unique names of charts
  */
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_ROWS_COUNT_BAR,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_DRIVER_SCATTER,
  CHART_UNIQUE_SENTIMENT,
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_DYNAMIC_BAR
} from '@/settings/constants'

import {
  TPL_INH,
  TPL_AUX_GEN,
  TPL_AUX_SCORE,
  TPL_SENT,
  SETT_DRIVER,
  SETT_SCORING_TYPE,
  SETT_NPS,
  SETT_DATE_COLUMN
} from '@/settings/chartFeatures'

export default {
  [CHART_UNIQUE_BAR]: {
    type: CHART_TYPE_BAR,
    additionalDatasetSettings: { coloring: true, weighting: true },
    templates: { TPL_INH, TPL_AUX_GEN, TPL_AUX_SCORE, TPL_SENT }
  },
  [CHART_TYPE_LINE_PIE]: {
    type: CHART_UNIQUE_LINE_PIE,
    requiredSettings: {},
    disabledDatasetSettings: {},
    templates: {}
  },
  [CHART_UNIQUE_DATE_LINE_PIE]: {
    type: CHART_TYPE_DATE_LINE_PIE,
    requiredSettings: { SETT_DATE_COLUMN },
    disabledDatasetSettings: { name: true },
    templates: {}
  },
  [CHART_UNIQUE_TREEMAP]: {
    type: CHART_TYPE_TREEMAP,
    additionalDatasetSettings: { weighting: true },
    templates: {}
  },
  [CHART_UNIQUE_GRAPH]: {
    type: CHART_TYPE_GRAPH,
    additionalDatasetSettings: { weighting: true },
    templates: {}
  },
  [CHART_UNIQUE_SENTIMENT_BAR]: {
    type: CHART_TYPE_SENTIMENT_BAR,
    additionalDatasetSettings: {},
    templates: {},
    beta: true
  },
  [CHART_UNIQUE_DYNAMIC_BAR]: {
    type: CHART_TYPE_DYNAMIC_BAR,
    requiredSettings: { },
    templates: { }
  },
  [CHART_UNIQUE_SCORE]: {
    type: CHART_TYPE_SCORE,
    requiredSettings: { SETT_NPS, SETT_SCORING_TYPE },
    additionalDatasetSettings: { weighting: true },
    templates: {},
    beta: true
  },
  [CHART_UNIQUE_DRIVER_SCATTER]: {
    type: CHART_TYPE_DRIVER_SCATTER,
    additionalDatasetSettings: {
      coloring: true,
      driver: true,
      weighting: true
    },
    templates: { TPL_INH, TPL_AUX_GEN },
    requiredSettings: { SETT_DRIVER },
    beta: true
  },
  [CHART_UNIQUE_SENTIMENT]: {
    type: CHART_TYPE_SCORE,
    requiredSettings: {},
    additionalDatasetSettings: {},
    templates: {},
    beta: true
  },
  [CHART_UNIQUE_ROWS_COUNT_BAR]: {
    type: CHART_TYPE_ROWS_COUNT_BAR,
    requiredSettings: { SETT_DATE_COLUMN },
    templates: {}
  }
}
