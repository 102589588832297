export default {
  namespaced: true,

  state () {
    return {
      itemShown: null,
      filters: []
    }
  },

  mutations: {
    setState (state, val) {
      state.itemShown = val
    },
    updateFilters (state, val) {
      state.filters = val
    }
  },

  actions: {
    show: ({ commit, state, getters, dispatch }, { item, filters }) => {
      commit('setState', item)
      commit('updateFilters', filters)
    },
    hide: ({ commit }) => {
      commit('setState', null)
      commit('updateFilters', [])
    }
  }
}
