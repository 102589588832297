<template>
  <div class="main-content slpg" style="margin-bottom: 80px">
    <alert type="error" class="retry" v-if="failed">
      <div class="text">
        {{ $t('error', {'step': $t('loading.while')}) }}
      </div>
      <v-btn @click="loadCharts">
        <v-icon left dark>
          mdi-reload
        </v-icon> {{ $t('actions.retry') }}
      </v-btn>
    </alert>

    <chart-list v-else
                :charts="charts"
                :loading="loading"
                :question-to-filter="questionToFilter"
                add-enable
                search-filter-enable
                questions-filter-enable
                selectable
                @open-chart="openChart"
                ref="chartList"
    >
      <template slot="title">
        {{ $t('title') }}
      </template>

      <template v-slot:empty-state="{ filters }">
        <v-btn color="green" outlined exact :to="{
          name: 'chart-details',
          params: { id: 'new' },
          query: !filters.name.length && filters.questions.length === 1 & filters.questions[0] === $route.query.questionID ?
            { question: $route.query.questionID } : {}
        }"
        >
          {{ $t('menu.new_chart') }}
        </v-btn>
      </template>

      <template slot="header-actions" slot-scope="{ chartsSelected }">
        <v-btn @click="newDashboardFromCharts(chartsSelected)"
               icon
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-monitor-dashboard
              </v-icon>
            </template>
            <span>{{ $t('new_dashboad_from_charts') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn @click="openDeleteCharts(chartsSelected)"
               :disabled="!chartsSelected.every(c => $hasPermission('dashboards_edit', c))"
               icon
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('delete.title') }}</span>
          </v-tooltip>
        </v-btn>
      </template>
    </chart-list>

    <!-- ====== Delete charts DIALOG ====== -->
    <v-dialog v-model="deleteChart.active" max-width="600" @keydown.esc="closeDeleteCharts(false)">
      <v-card>
        <v-card-title class="headline" primary-title>
          <div v-html="$t('delete.are_you_sure', { type: $tc('chart.items', chartsSelectedCached.length),
                                                   name: $escapeHtml(deleteChart.label) })"
          />
        </v-card-title>

        <v-card-text v-html="$t('delete.description')" />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" outlined @click.native="closeDeleteCharts(false)">
            {{ $t('no') }}
          </v-btn>
          <v-btn color="primary" @click.native="closeDeleteCharts(true)">
            {{ $t('yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChartListComponent from '@/components/ChartList'

import Vuex from 'vuex'

export default {
  codit: true,
  name: 'ManageCharts',
  components: {
    'chart-list': ChartListComponent
  },

  data () {
    return {
      chartsSelectedCached: [],

      deleteChart: {
        active: false,
        label: ''
      },

      charts: [],

      loading: true,
      failed: false,

      download: {
        active: false,
        project: 0,
        creditsOpen: 0
      }
    }
  },

  computed: {
    /**
     * The question ID by which to filter, given by the query of the current route
     * @return {String,null}
     */
    questionToFilter () {
      if ('questionID' in this.$route.query) return this.$route.query
      else return null
    },

    ...Vuex.mapState(['user'])
  },

  watch: {
    'deleteChart.active': 'dialogActivityChanged'
  },

  created () {
    this.$store.commit('setPageTutorialID', '1592835846qOtj8608')
  },

  mounted () {
    this.loadCharts()
  },

  methods: {
    openChart (id) {
      this.$router.push({
        name: 'chart-details',
        params: { id }
      })
    },

    /**
     * Creates a new dashboard from the selected charts
     * @param  {Array} charts List of chart objects
     */
    newDashboardFromCharts (charts) {
      this.$router.push({
        name: 'dashboard-details',
        params: { id: 'new' },
        query: { charts: _.map(charts, 'id').join(',') }
      })
    },

    // Safety measure: Make sure the selected projects are cleared, so no relics can show up during next edit step
    dialogActivityChanged (n, o) { if (!n && o) this.chartsSelectedCached.splice(0) },

    loadCharts () {
      this.failed = false
      api.get('/api/charts/').then((res) => {
        let charts = res.data
        this.$set(this, 'charts', charts)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.failed = true
        this.$root.snackMsg(this.$t('error', { 'step': this.$t('loading.while') }))
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    openDeleteCharts (chartsSelected) {
      this.deleteChart.label = _.map(chartsSelected, 'name').join(', ')
      this.deleteChart.active = true
      this.chartsSelectedCached = chartsSelected.slice()
    },

    closeDeleteCharts (doIt) {
      if (doIt) {
        let n = this.chartsSelectedCached.length
        this.chartsSelectedCached.forEach(s => {
          api.delete('/api/charts/' + s.id).then(response => {
            this.$root.snackMsg(this.$t('delete.done', { name: this.$escapeHtml(this.deleteChart.label),
              item: this.$tc('chart.items', n) }
            ))
          }).catch(err => {
            this.$root.snackMsg(this.$t('error', { 'step': this.$t('delete.title') }))
            this.$maybeRaiseAPIPromiseErr(err)
          })
        })

        let IDsToDelete = new Set(_.map(this.chartsSelectedCached, 'id'))
        _.remove(this.charts, s => IDsToDelete.has(s.id))
        // Hack to make reactive again
        this.charts = [...this.charts]
      }
      this.deleteChart.active = false
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/ChartList.json' />
<i18n locale='de' src='@/i18n/de/pages/ChartList.json' />