<!-- ====== New topic dialog ===== -->
<template>
  <div>
    <v-overlay :value="keyboardShortcutsDialog" color="rgba(5, 32, 46, 0.5)" />
    <v-dialog
      :value="keyboardShortcutsDialog"
      @keydown.esc="closeDialog"
      @input="closeDialog"
      hide-overlay
      eager
      width="950px"
      content-class="dashboard-filter-editor"
    >
      <div>
        <div class="dashboard-filter-editor__title w-100 d-flex align-center justify-space-between">
          {{ $t('keyboard_shortcuts.title') }}
          <v-icon
            class="hover"
            @click="closeDialog"
            :size="20"
          >
            mdi-close
          </v-icon>
        </div>
        <div class="dashboard-filter-editor__content d-flex">
          <div class="col-6 pr-5 pt-0">
            <div class="mb-3 text-sm font-weight-medium">
              {{ $t('keyboard_shortcuts.closed_title') }}
            </div>
            <div class="d-flex align-center text-sm justify-space-between mb-3">
              {{ $t('keyboard_shortcuts.previous') }}
              <div class="keyboard-shortcut">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 14.4001C7.55817 14.4001 7.2 14.0419 7.2 13.6001L7.2 4.3321L5.36569 6.16578C5.0773 6.45417 4.62351 6.47635 4.30968 6.23233L4.23431 6.16578C3.94593 5.8774 3.92374 5.42361 4.16776 5.10978L4.23431 5.03441L7.43431 1.83441L7.46948 1.80129V1.80129L7.52375 1.75717V1.75717L7.61275 1.69983V1.69983L7.70293 1.65708V1.65708L7.78721 1.62877V1.62877L7.90602 1.60557V1.60557L8 1.6001L8.06019 1.60233V1.60233L8.16058 1.61629V1.61629L8.24971 1.63989V1.63989L8.33858 1.67504V1.67504L8.41657 1.71689V1.71689L8.4934 1.77033C8.51877 1.79023 8.54291 1.81164 8.56569 1.83441L11.7657 5.03441C12.0781 5.34683 12.0781 5.85336 11.7657 6.16578C11.4773 6.45417 11.0235 6.47635 10.7097 6.23233L10.6343 6.16578L8.8 4.3321L8.8 13.6001C8.8 14.0419 8.44183 14.4001 8 14.4001Z" fill="#05202E" />
                </svg>
              </div>
            </div>

            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.next') }}
              <div class="keyboard-shortcut">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.6001C8.44183 1.6001 8.8 1.95827 8.8 2.4001L8.8 11.6681L10.6343 9.83441C10.9227 9.54602 11.3765 9.52384 11.6903 9.76786L11.7657 9.83441C12.0541 10.1228 12.0763 10.5766 11.8322 10.8904L11.7657 10.9658L8.56569 14.1658L8.53052 14.1989V14.1989L8.47625 14.243V14.243L8.38725 14.3004V14.3004L8.29707 14.3431V14.3431L8.21279 14.3714V14.3714L8.09398 14.3946V14.3946L8 14.4001V14.4001L7.93981 14.3979V14.3979L7.83942 14.3839V14.3839L7.75029 14.3603V14.3603L7.66142 14.3252V14.3252L7.58343 14.2833V14.2833L7.5066 14.2299C7.48123 14.21 7.45709 14.1886 7.43431 14.1658L4.23431 10.9658C3.9219 10.6534 3.9219 10.1468 4.23431 9.83441C4.5227 9.54602 4.97649 9.52384 5.29032 9.76786L5.36569 9.83441L7.2 11.6681L7.2 2.4001C7.2 1.95827 7.55817 1.6001 8 1.6001Z" fill="#05202E" />
                </svg>
              </div>
            </div>

            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.reviewed_next') }}
              <div class="d-flex align-center">
                <div class="keyboard-shortcut mr-2">
                  <div>⌘ / ctrl</div>
                </div>
                +
                <div class="keyboard-shortcut ml-2">
                  <div>enter</div>
                </div>
              </div>
            </div>

            <div class="d-flex align-center justify-space-between text-sm">
              {{ $t('keyboard_shortcuts.close') }}
              <div class="keyboard-shortcut">
                <div>esc</div>
              </div>
            </div>
          </div>
          <div class="col-6 pl-5 keyboard-shortcut-column pt-0">
            <div class="mb-3 text-sm font-weight-medium">
              {{ $t('keyboard_shortcuts.open_title') }}
            </div>

            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.reviewed_next') }}
              <div class="d-flex align-center">
                <div class="keyboard-shortcut mr-2">
                  <div>⌘ / ctrl</div>
                </div>
                +
                <div class="keyboard-shortcut ml-2">
                  <div>enter</div>
                </div>
              </div>
            </div>

            <div class="d-flex align-center text-sm justify-space-between mb-3">
              {{ $t('keyboard_shortcuts.open_previous') }}
              <div class="d-flex align-center">
                <div class="keyboard-shortcut mr-2">
                  <div>shift</div>
                </div>
                +
                <div class="keyboard-shortcut ml-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 14.4001C7.55817 14.4001 7.2 14.0419 7.2 13.6001L7.2 4.3321L5.36569 6.16578C5.0773 6.45417 4.62351 6.47635 4.30968 6.23233L4.23431 6.16578C3.94593 5.8774 3.92374 5.42361 4.16776 5.10978L4.23431 5.03441L7.43431 1.83441L7.46948 1.80129V1.80129L7.52375 1.75717V1.75717L7.61275 1.69983V1.69983L7.70293 1.65708V1.65708L7.78721 1.62877V1.62877L7.90602 1.60557V1.60557L8 1.6001L8.06019 1.60233V1.60233L8.16058 1.61629V1.61629L8.24971 1.63989V1.63989L8.33858 1.67504V1.67504L8.41657 1.71689V1.71689L8.4934 1.77033C8.51877 1.79023 8.54291 1.81164 8.56569 1.83441L11.7657 5.03441C12.0781 5.34683 12.0781 5.85336 11.7657 6.16578C11.4773 6.45417 11.0235 6.47635 10.7097 6.23233L10.6343 6.16578L8.8 4.3321L8.8 13.6001C8.8 14.0419 8.44183 14.4001 8 14.4001Z" fill="#05202E" />
                  </svg>
                </div>
              </div>
            </div>

            <div class="d-flex align-center text-sm justify-space-between mb-3">
              {{ $t('keyboard_shortcuts.open_next') }}
              <div class="d-flex align-center">
                <div class="keyboard-shortcut mr-2">
                  <div>shift</div>
                </div>
                +
                <div class="keyboard-shortcut ml-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.6001C8.44183 1.6001 8.8 1.95827 8.8 2.4001L8.8 11.6681L10.6343 9.83441C10.9227 9.54602 11.3765 9.52384 11.6903 9.76786L11.7657 9.83441C12.0541 10.1228 12.0763 10.5766 11.8322 10.8904L11.7657 10.9658L8.56569 14.1658L8.53052 14.1989V14.1989L8.47625 14.243V14.243L8.38725 14.3004V14.3004L8.29707 14.3431V14.3431L8.21279 14.3714V14.3714L8.09398 14.3946V14.3946L8 14.4001V14.4001L7.93981 14.3979V14.3979L7.83942 14.3839V14.3839L7.75029 14.3603V14.3603L7.66142 14.3252V14.3252L7.58343 14.2833V14.2833L7.5066 14.2299C7.48123 14.21 7.45709 14.1886 7.43431 14.1658L4.23431 10.9658C3.9219 10.6534 3.9219 10.1468 4.23431 9.83441C4.5227 9.54602 4.97649 9.52384 5.29032 9.76786L5.36569 9.83441L7.2 11.6681L7.2 2.4001C7.2 1.95827 7.55817 1.6001 8 1.6001Z" fill="#05202E" />
                  </svg>
                </div>
              </div>
            </div>

            <div class="d-flex align-center text-sm justify-space-between mb-3">
              {{ $t('keyboard_shortcuts.previous_topic') }}
              <div class="keyboard-shortcut">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 14.4001C7.55817 14.4001 7.2 14.0419 7.2 13.6001L7.2 4.3321L5.36569 6.16578C5.0773 6.45417 4.62351 6.47635 4.30968 6.23233L4.23431 6.16578C3.94593 5.8774 3.92374 5.42361 4.16776 5.10978L4.23431 5.03441L7.43431 1.83441L7.46948 1.80129V1.80129L7.52375 1.75717V1.75717L7.61275 1.69983V1.69983L7.70293 1.65708V1.65708L7.78721 1.62877V1.62877L7.90602 1.60557V1.60557L8 1.6001L8.06019 1.60233V1.60233L8.16058 1.61629V1.61629L8.24971 1.63989V1.63989L8.33858 1.67504V1.67504L8.41657 1.71689V1.71689L8.4934 1.77033C8.51877 1.79023 8.54291 1.81164 8.56569 1.83441L11.7657 5.03441C12.0781 5.34683 12.0781 5.85336 11.7657 6.16578C11.4773 6.45417 11.0235 6.47635 10.7097 6.23233L10.6343 6.16578L8.8 4.3321L8.8 13.6001C8.8 14.0419 8.44183 14.4001 8 14.4001Z" fill="#05202E" />
                </svg>
              </div>
            </div>
            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.next_topic') }}
              <div class="keyboard-shortcut">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.6001C8.44183 1.6001 8.8 1.95827 8.8 2.4001L8.8 11.6681L10.6343 9.83441C10.9227 9.54602 11.3765 9.52384 11.6903 9.76786L11.7657 9.83441C12.0541 10.1228 12.0763 10.5766 11.8322 10.8904L11.7657 10.9658L8.56569 14.1658L8.53052 14.1989V14.1989L8.47625 14.243V14.243L8.38725 14.3004V14.3004L8.29707 14.3431V14.3431L8.21279 14.3714V14.3714L8.09398 14.3946V14.3946L8 14.4001V14.4001L7.93981 14.3979V14.3979L7.83942 14.3839V14.3839L7.75029 14.3603V14.3603L7.66142 14.3252V14.3252L7.58343 14.2833V14.2833L7.5066 14.2299C7.48123 14.21 7.45709 14.1886 7.43431 14.1658L4.23431 10.9658C3.9219 10.6534 3.9219 10.1468 4.23431 9.83441C4.5227 9.54602 4.97649 9.52384 5.29032 9.76786L5.36569 9.83441L7.2 11.6681L7.2 2.4001C7.2 1.95827 7.55817 1.6001 8 1.6001Z" fill="#05202E" />
                </svg>
              </div>
            </div>
            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.close_topic_select') }}
              <div class="keyboard-shortcut">
                <div>esc</div>
              </div>
            </div>
            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.remove_topic') }}
              <div class="keyboard-shortcut">
                <div>← / delete</div>
              </div>
            </div>

            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.select_topic') }}
              <div class="keyboard-shortcut">
                <div>enter</div>
              </div>
            </div>

            <!-- <div class="d-flex align-center justify-space-between text-sm mb-3">
              Expand category (if highlighted)
              <div class="d-flex align-center">
                <div class="keyboard-shortcut mr-2">
                  <div>enter</div>
                </div>
                or
                <div class="keyboard-shortcut ml-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.59961 8C1.59961 7.55817 1.95778 7.2 2.39961 7.2L11.6676 7.2L9.83392 5.36569C9.54554 5.0773 9.52335 4.62351 9.76737 4.30968L9.83392 4.23431C10.1223 3.94593 10.5761 3.92374 10.8899 4.16776L10.9653 4.23431L14.1653 7.43431L14.1984 7.46948V7.46948L14.2425 7.52375V7.52375L14.2999 7.61275V7.61275L14.3426 7.70293V7.70293L14.3709 7.78721V7.78721L14.3941 7.90602V7.90602L14.3996 8L14.3974 8.06019V8.06019L14.3834 8.16058V8.16058L14.3598 8.24971V8.24971L14.3247 8.33858V8.33858L14.2828 8.41657V8.41657L14.2294 8.4934C14.2095 8.51877 14.1881 8.54291 14.1653 8.56569L10.9653 11.7657C10.6529 12.0781 10.1463 12.0781 9.83392 11.7657C9.54554 11.4773 9.52335 11.0235 9.76737 10.7097L9.83392 10.6343L11.6676 8.8L2.39961 8.8C1.95778 8.8 1.59961 8.44183 1.59961 8Z" fill="#05202E" />
                  </svg>
                </div>
              </div>
            </div> -->
            <div class="d-flex align-center justify-space-between text-sm mb-3">
              {{ $t('keyboard_shortcuts.shift_right') }}
              <div class="d-flex align-center">
                <div class="keyboard-shortcut ml-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.59961 8C1.59961 7.55817 1.95778 7.2 2.39961 7.2L11.6676 7.2L9.83392 5.36569C9.54554 5.0773 9.52335 4.62351 9.76737 4.30968L9.83392 4.23431C10.1223 3.94593 10.5761 3.92374 10.8899 4.16776L10.9653 4.23431L14.1653 7.43431L14.1984 7.46948V7.46948L14.2425 7.52375V7.52375L14.2999 7.61275V7.61275L14.3426 7.70293V7.70293L14.3709 7.78721V7.78721L14.3941 7.90602V7.90602L14.3996 8L14.3974 8.06019V8.06019L14.3834 8.16058V8.16058L14.3598 8.24971V8.24971L14.3247 8.33858V8.33858L14.2828 8.41657V8.41657L14.2294 8.4934C14.2095 8.51877 14.1881 8.54291 14.1653 8.56569L10.9653 11.7657C10.6529 12.0781 10.1463 12.0781 9.83392 11.7657C9.54554 11.4773 9.52335 11.0235 9.76737 10.7097L9.83392 10.6343L11.6676 8.8L2.39961 8.8C1.95778 8.8 1.59961 8.44183 1.59961 8Z" fill="#05202E" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="d-flex align-center justify-space-between text-sm">
              {{ $t('keyboard_shortcuts.shift_left') }}
              <div class="d-flex align-center">
                <div class="keyboard-shortcut ml-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3996 8C14.3996 8.44183 14.0414 8.8 13.5996 8.8L4.33161 8.8L6.16529 10.6343C6.45368 10.9227 6.47587 11.3765 6.23185 11.6903L6.16529 11.7657C5.87691 12.0541 5.42312 12.0763 5.10929 11.8322L5.03392 11.7657L1.83392 8.56569L1.80081 8.53052V8.53052L1.75668 8.47625V8.47625L1.69935 8.38725V8.38725L1.65659 8.29707V8.29707L1.62829 8.21279V8.21279L1.60508 8.09398V8.09398L1.59961 8V8L1.60184 7.93981V7.93981L1.6158 7.83942V7.83942L1.6394 7.75029V7.75029L1.67455 7.66142V7.66142L1.71641 7.58343V7.58343L1.76984 7.5066C1.78974 7.48123 1.81115 7.45709 1.83392 7.43431L5.03392 4.23431C5.34634 3.9219 5.85287 3.9219 6.16529 4.23431C6.45368 4.5227 6.47587 4.97649 6.23185 5.29032L6.16529 5.36569L4.33161 7.2L13.5996 7.2C14.0414 7.2 14.3996 7.55817 14.3996 8Z" fill="#05202E" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-filter-editor__actions d-flex justify-space-between">
          <div />
          <div>
            <v-btn
              outlined
              @click="closeDialog"
            >
              {{ $t('close') }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

import Vuex from 'vuex'

export default {
  codit: true,
  name: 'KeyboardShortcuts',
  components: {},

  props: {
    value: { type: Object, default: () => ({}) }
  },

  computed: {
    ...Vuex.mapState({
      keyboardShortcutsDialog: state => state.coding.keyboardShortcutsDialog
    })
  },

  methods: {
  /**
   * Cloes the dialog via the store
   */
    closeDialog () {
      this.$store.commit('setKeyboardShortcutsDialog', false)
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Codingv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Codingv2.json' />