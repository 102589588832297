<template>
  <div class="main-content account" ref="main-content">
    <v-tabs v-model="tab"
            vertical
            dark
            height="353px"
            class="account-tabs"
    >
      <div class="account-tabs__title">
        {{ $t('header') }}
      </div>
      <v-tab>
        <v-icon left>
          mdi-cog-outline
        </v-icon>
        {{ $t('profile.title') }}
      </v-tab>
      <template v-if="!isDashboardOnlyUser">
        <v-tab>
          <v-icon left>
            mdi-credit-card-outline
          </v-icon>
          {{ $t('subscription.title') }}
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-shield-lock-outline
          </v-icon>
          {{ $t('security.title') }}
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-account-group
          </v-icon>
          {{ $t('organization.title') }}
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-circle-multiple-outline
          </v-icon>
          {{ $t('credit_transactions.title') }}
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-lan-connect
          </v-icon>
          {{ $t('api.title') }}
        </v-tab>
      </template>

      <v-tab v-if="!isDashboardOnlyUser">
        <v-icon left>
          mdi-link
        </v-icon>
        {{ $t('integrations.title') }}
      </v-tab>

      <!-- ====== BASIC SETTINGS ====== -->
      <v-tab-item>
        <user-settings />
      </v-tab-item>

      <!-- ====== SUBSCRIPTION / PAYMENT ====== -->
      <v-tab-item>
        <!-- ====== Subscription ====== -->
        <v-card>
          <v-card-title>
            <div>
              <div class="title">
                {{ $t('subscription.title') }}
              </div>
              <div class="grey--text">
                {{ $t('subscription.subtitle') }}
              </div>
            </div>
          </v-card-title>

          <v-card-text>
            <v-alert type="info"
                     outlined
                     text
                     border="left"
                     prominent
            >
              <span v-html="$t('subscription.infotext_plan', { plan: user.subscription.plan_name })" />
              <span v-if="user.subscription.will_end"
                    v-html="$t('subscription.will_end', { date: $options.filters.datelong(user.subscription.end) })"
              />
              <!-- TODO: decide what to do with this -->
              <span v-if="!user.subscription.will_end" v-html="(user.subscription.price_monthly > 0 ?
                $t('subscription.infotext_paid', {
                  date: $options.filters.datelong(user.subscription.next_renewal_datetime),
                  interval: $t(`subscription.interval_${user.subscription.yearly_contract ? 'yearly' : 'monthly'}`)
                }) : '')"
              />
              <div style="margin-top: 4px"
                   v-if="!user.subscription.will_end && user.subscription.credits || user.subscription.features.credits_recurring"
                   v-html="$t(`subscription.infotext_refill_${currentSubscriptionRefillInterval}`, {
                     credits: user.subscription.credits || user.subscription.features.credits_recurring,
                     date: $options.filters.dateshort(user.subscription.next_fillup_datetime)
                   })"
              />
            </v-alert>
            <div>
              <span class="grey--text" style="display: inline-block; width: 250px">
                {{ $t(`subscription.credits_remaining_${currentSubscriptionRefillInterval}`) }}</span>
              <code>{{ user.credits_remaining_recurring }}</code>
              <helptip :width="500" position="top">
                <span v-html="$t(`credits_${currentSubscriptionRefillInterval}_helptip`)" />
              </helptip>
            </div>
            <div>
              <span class="grey--text" style="display: inline-block; width: 250px">
                {{ $t('subscription.credits_remaining_oneoff') }}</span>
              <code>{{ user.credits_remaining_oneoff }}</code>
              <helptip :width="500" position="top">
                <span v-html="$t('credits_oneoff_helptip')" />
              </helptip>
            </div>
          </v-card-text>

          <v-card-actions v-if="!subscriptionMgmtDisabled">
            <v-spacer />
            <v-btn class="primary"
                   :class="{ 'green': user.subscription.plan_id === 10 }"
                   :to="{ name: 'subscription' }"
            >
              {{ $t('subscription.action_change') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <div style="display: flex">
          <!-- ====== Payment methods ====== -->
          <v-card v-if="!subscriptionMgmtDisabled" style="flex: 1">
            <v-card-title>
              <div>
                <div class="title">
                  {{ $t('subscription.payment_methods.title') }}
                </div>
                <div class="grey--text">
                  {{ $t('subscription.payment_methods.subtitle') }}
                </div>
              </div>
            </v-card-title>

            <v-card-text v-if="stripePaymentMethods.loaded">
              <ul id="payment-methods" v-if="stripePaymentMethods.cards.length">
                <li v-for="(card, idx) in stripePaymentMethods.cards" :key="idx">
                  <div class="card-container">
                    <span class="credit-card">
                      {{ card.brand }}
                      **** {{ card.last4 }}
                      {{ $t('valid_until') }}
                      {{ card.exp_month }} / {{ card.exp_year }}
                    </span>
                  </div>
                  <a v-if="stripeCardsRemovable"
                     class="remove"
                     href=""
                     @click.prevent.stop="openRemovePaymentMethod(card.id)"
                  >
                    <small>({{ $t('remove_card.action') }})</small>
                  </a>
                  <small class="not-removable" v-else>({{ $t('remove_card.not_possible') }})</small>
                </li>
              </ul>
              <empty-state
                v-else
                icon="mdi-credit-card"
                :label="$t('subscription.payment_methods.no_cards')"
              />
            </v-card-text>

            <v-spacer />

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="showAddPaymentMethod = true" outlined>
                {{ $t('subscription.payment_methods.action_add') }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- ====== Payment history ====== -->
          <v-card v-if="!subscriptionMgmtDisabled" style="flex: 1">
            <v-card-title>
              <div>
                <div class="title">
                  {{ $t('subscription.payment_history.title') }}
                </div>
                <div class="grey--text">
                  {{ $t('subscription.payment_history.subtitle') }}
                </div>
              </div>
            </v-card-title>

            <v-card-text v-if="stripeInvoices.loaded">
              <ul id="payment-history" v-if="stripeInvoices.invoices.length">
                <li v-for="(invoice, idx) in stripeInvoices.invoices" :key="idx">
                  <div class="first-col">
                    <div class="date">
                      {{ invoice.date | datelong }}
                    </div>
                    <div class="description" v-text="invoice.description" />
                  </div>
                  <div class="second-col">
                    <strong class="amount">{{ invoice.amount }} {{ invoice.currency | uppercase }}</strong>
                    <a :href="invoice.invoice_url" target="_blank" v-text="$t('subscription.payment_history.view_invoice')" />
                  </div>
                </li>
              </ul>
              <empty-state
                v-else
                icon="mdi-receipt"
                :label="$t('subscription.payment_history.no_payments')"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-tab-item>

      <!-- ====== SECURITY ====== -->
      <v-tab-item>
        <div style="display: flex">
          <v-card style="flex: 1">
            <v-card-title>
              <div>
                <div class="title">
                  {{ $t('security.password.title') }}
                </div>
                <div class="grey--text">
                  {{ $t('security.password.subtitle') }}
                </div>
              </div>
            </v-card-title>
            <v-card-text v-if="user.has_sso">
              <v-alert type="info"
                       outlined
                       text
                       border="left"
              >
                <div v-html="$t('security.password.has_sso')" />
              </v-alert>
            </v-card-text>
            <template v-else>
              <v-card-text>
                <v-alert v-if="pwd.error"
                         type="error"
                         i
                         outlined
                         text
                         border="left"
                >
                  {{ pwd.error }}
                </v-alert>
                <form @submit.prevent="changePassword" @keydown.enter="changePassword">
                  <v-text-field v-model="pwd.old_password"
                                type="password"
                                :error-messages="('old_password' in pwd.errorByField) ? pwd.errorByField.old_password : ''"
                                :disabled="pwd.loading"
                                :label="$t('security.password.old_password')"
                  />

                  <v-text-field v-model="pwd.new_password1"
                                type="password"
                                :disabled="pwd.loading"
                                :error-messages="('new_password1' in pwd.errorByField) ? pwd.errorByField.new_password1 : ''"
                                :label="$t('security.password.new_password')"
                  />

                  <v-text-field v-model="pwd.new_password2"
                                type="password"
                                :disabled="pwd.loading"
                                :error-messages="('new_password2' in pwd.errorByField) ? pwd.errorByField.new_password2 : ''"
                                :label="$t('security.password.new_password_repeat')"
                  />
                </form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary"
                       type="submit"
                       :loading="pwd.loading"
                       @click="changePassword"
                >
                  {{ $t('save.title') }}
                </v-btn>
              </v-card-actions>
            </template>
          </v-card>

          <manage-2fa style="flex: 1" />
        </div>
      </v-tab-item>

      <v-tab-item>
        <manage-team />
      </v-tab-item>

      <v-tab-item>
        <!-- ====== Credit Transactions ====== -->
        <v-card>
          <v-card-title>
            <div>
              <div class="title">
                {{ $t('credit_transactions.title') }}
              </div>
              <div class="grey--text">
                {{ $t('credit_transactions.subtitle') }}
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <credit-transactions />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <!-- ====== API ====== -->
        <manage-apikeys />
      </v-tab-item>

      <v-tab-item>
        <!-- INTEGRATIONS -->
        <manage-integrations />
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="removePaymentMethod.active" max-width="600" @keydown.esc="closeRemovePaymentMethod(false)">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div class="title">
              {{ $t('remove_card.dialog_title') }}
            </div>
          </div>
        </v-card-title>

        <v-card-text v-html="$t('remove_card.dialog_content', { card: removePaymentMethod.label })" />

        <v-card-actions>
          <v-spacer />
          <v-btn outlined color="primary" @click="closeRemovePaymentMethod(false)">
            {{ $t('no') }}
          </v-btn>
          <v-btn color="primary" @click="closeRemovePaymentMethod(true)">
            {{ $t('yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <stripe-payment-method v-model="showAddPaymentMethod"
                           v-if="!stripeScriptIsLoading"
                           @added="addedPaymentMethod"
    />
  </div>
</template>

<script>
import Vuex from 'vuex'
import StripePaymentMethod from '@/components/StripePaymentMethod'
import UserSettings from '@/components/account/UserSettings'
import Manage2FA from '@/components/account/Manage2FA'
import ManageAPIKeys from '@/components/account/ManageAPIKeys'
import ManageIntegrations from '@/components/account/ManageIntegrations.vue'
import ManageTeam from '@/components/account/ManageTeam'
import CreditTransactions from '@/components/account/CreditTransactions'

import stripePayment from '../mixins/stripePayment.js'

const TAB_NAMES = [undefined, 'subscription', 'security', 'team', 'credit-usage', 'api', 'integrations']

export default {
  codit: true,
  name: 'Account',

  components: {
    'stripe-payment-method': StripePaymentMethod,
    'user-settings': UserSettings,
    'manage-2fa': Manage2FA,
    'manage-apikeys': ManageAPIKeys,
    'manage-team': ManageTeam,
    'credit-transactions': CreditTransactions,
    'manage-integrations': ManageIntegrations
  },
  mixins: [stripePayment],

  data () {
    return {
      tab: 0,

      pwd: {
        loading: false,
        failed: false,
        error: '',
        errorByField: {},
        old_password: '',
        new_password1: '',
        new_password2: ''
      },

      showAddPaymentMethod: false
    }
  },

  /**
   * Update the tab index, if the route changes within the account component (i.e. only query changed)
   */
  beforeRouteUpdate (to, from, next) {
    next()
    let tabIndex = TAB_NAMES.indexOf(to.query.tab)
    if (this.tab !== tabIndex) this.tab = tabIndex
  },

  computed: {
    /**
     * If subscription management features are disabled for this user. Can be for two reasons
     * 1) User is part of organization and *not* the root user
     * 2) User is on a "managed" plan, thus he cannot change it by himself
     * @return {Boolean}
     */
    subscriptionMgmtDisabled () {
      // All but the "usual" plans are managed
      return !this.$hasPermission('subscription_mgmt') || !this.user.subscription.features.is_self_service
    },

    currentSubscriptionRefillInterval () {
      const val = this.user.subscription.credit_refill_interval_months
      if (val === 12) return 'yearly'
      else if (val === 1) return 'monthly'
      else throw Error(`Unsupported credit refill interval ${val}`)
    },

    /**
     * The name of the currently selected tab
     * @return {String}
     */
    tabName () {
      return TAB_NAMES[this.tab]
    },

    isDashboardOnlyUser () {
      return this.$root?.isDashboardOnlyUser
    },

    ...Vuex.mapState(['user'])
  },

  watch: {
    /**
     * Watch for tab changes and update the query param accordingly
     */
    tab (valNew, valOld) {
      if (valNew !== valOld && valOld !== undefined && this.$route.query.tab !== this.tabName) {
        let method = valOld === 0 ? 'push' : 'replace'
        this.$router[method]({
          name: 'account',
          query: { tab: this.tabName }
        })
      }
    }
  },

  created () {
    // Get the payment history of the customer
    if (!this.subscriptionMgmtDisabled) this.loadStripeInvoices()
    // Set the tab name according to the query parameter, if given
    let tabIndex = TAB_NAMES.indexOf(this.$route.query.tab)
    if (tabIndex >= 0 && this.tab !== tabIndex) this.tab = tabIndex
  },

  methods: {
    /**
     * Save the password the user has entered
     */
    changePassword () {
      this.pwd.loading = true
      this.pwd.error = ''
      this.pwd.errorByField = {}
      api.post('/api/ui/auth/user/password-change/', {
        old_password: this.pwd.old_password,
        new_password1: this.pwd.new_password1,
        new_password2: this.pwd.new_password2
      }, { dontReport: [400] }).then(response => {
        this.pwd.loading = false
        // reset password
        if (response.status === 200) {
          this.pwd.old_password = this.pwd.new_password1 = this.pwd.new_password2 = ''
          this.$root.snackMsg(this.$t('profile.save.password_success'))
        } else {
          this.$set(this.pwd, 'errorByField', response.data) // set error field
          this.pwd.error = this.$t('error', { 'step': this.$t('save.saving') })
        }
      }).catch(err => {
        this.pwd.loading = false
        const message = err.response.data.message || this.$t('error', { 'step': this.$t('save.saving') })
        this.pwd.error = message
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<style lang=scss>

.account-tabs {

  .v-card {
    border-radius: 10px !important;
    box-shadow: 0px 4px 8px rgba(14, 93, 136, 0.05);
    overflow: hidden;
    /* padding: 16px; */
  }

  &__title {
    color: $text-color;
    border-bottom: 1px solid $border-color;
    padding: 16px;
    padding-bottom: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .v-tabs-slider-wrapper {
    width: 3px !important;

    .v-tabs-slider {
      background-color: $green-color;
    }
  }

  > .v-tabs-bar {
    background-color: white !important;
    box-shadow: 0px 4px 8px rgba(14, 93, 136, 0.05);
    border-radius: 10px;
    overflow: hidden;
    width: 320px;
    /* border: 1px solid $grey-color; */
  }
  .v-tab {
    text-align: left;
    justify-content: flex-start;
    text-transform: capitalize !important;
    color: $grey-color !important;
    padding: 0;
    letter-spacing: normal;
    padding: 10px 16px;
    height: auto !important;
    font-size: 16px;
    font-weight: 400;

    &:last-child {
      margin-bottom: 10px;
    }

    i {
      color: $grey-color !important;
      font-size: 20px;
      margin-right: 13px;
    }

    &:hover {
      background-color: #F7F8FA !important;
      opacity: 1;
    }

    &--active {
      color: $text-color !important;
      background-color: #F7F8FA !important;

      i {
        color: $text-color !important;
      }
    }
  }

  .v-window {
    background: transparent !important;
  }
}

#payment-methods {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #DDD;
    display: flex;
    align-items: center;
    .card-container {
      flex: 1 0 auto;
      align-items: center;
      display: flex;
      .credit-card {
      }
    }
    .not-removable {
      flex: 0 1 auto;
    }
    .remove {
      flex: 0 1 auto;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1280px) {
  #payment-methods li {
    flex-direction: column;
  }
}

#payment-history {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
  li {
    margin: 0;
    padding: 6px 12px;
    border-bottom: 1px solid #DDD;
    display: flex;

    &:hover { background: $col-ans-focus-bg; }

    .first-col {
      flex: 1;
    }
    .second-col {
      flex: 0 0 auto;
      border-left: 1px solid #EEE;
      padding-left: 12px;
      text-align: right;
      > * { display: block; }
    }
    .description {
      font-style: italic;
    }
  }
}

.account {
  transform: translate3d(0,0,0); /* Fix for strange flickering in firefox when hovering inputs */

  .v-card {
    display: flex;
    flex-direction: column;
  }

  .credit-card {
    padding: 6px 5px 4px;
    margin: 0 8px;
    background: #EEE;
    border-radius: 3px;
    font-family: "Consolas", "Courier New", "Courier";
  }

  .v-card .title { margin-bottom: 0 }
  .v-card .grey--text {
    font-size: 14px;
    line-height: 1.2rem;
  }

  .v-card {
    margin: 0 8px 16px 16px;
  }

  .v-btn {
    text-transform: capitalize !important;
  }
}

</style>
<i18n locale='en' src='@/i18n/en/pages/Account.json' />
<i18n locale='de' src='@/i18n/de/pages/Account.json' />
<i18n locale='en' src='@/i18n/en/Subscription.json' />
<i18n locale='de' src='@/i18n/de/Subscription.json' />