export default {
  data () {
    return {
      showTranslations: _.get(this, 'question.show_translation', false)
    }
  },

  watch: {
    /**
     * As when the answer object is created, the prop `question` is not available yet
     * Therefore listen to changes
     */
    'question.show_translation': {
      immediate: true,
      handler (val) {
        this.showTranslations = val
      }
    }
  },

  computed: {
    questionIsTranslated () {
      return this.question.translated > 0
    }
  },

  methods: {
    answerIsTranslated (ans) {
      return this.questionIsTranslated && _.get(ans, 'translated', ans.translated_text && ans.source_language !== this.question.language)
    },
    getDisplayedAnswerText (ans) {
      return this.answerIsTranslated(ans) && this.showTranslations ? ans.translated_text : ans.text
    }
  }
}