<template>
  <div>
    <v-navigation-drawer
      :value="internalValue"
      @close="closeDialog"
      v-c-click-outside="maybeCloseDialogOnClickOutside"
      class="overrided sidebar"
      width="590"
      right
      :absolute="true"
      :fixed="false"
      hide-overlay
      stateless
    >
      <div class="h-100 d-flex flex-column" @click.self.prevent>
        <div>
          <slot />
        </div>
        <slot name="bottom" />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataMixin from '@/components/customUi/mixins/data'

export default {
  mixins: [
    DataMixin
  ],

  props: {
    value: { type: Boolean, default: false }
  },

  methods: {
    maybeCloseDialogOnClickOutside (el) {
      if (
        !document.querySelector('.v-application--wrap').contains(el.srcElement)
      ) {
        return
      }
      this.closeDialog()
    },
    closeDialog () {
      this.internalValue = false
    }
  }
}
</script>

<style lang="scss">
.sidebar{
  box-shadow: 0px 24px 40px rgb(2 14 20 / 20%) !important;

  &__header{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    border-bottom: 1px solid #E8F0F7;

    & + .sidebar__actions{
      border-top: none;
    }
  }
  &__content{
    & + .sidebar__content{
      border-top: 1px solid #E8F0F7;
    }
  }
  &__actions{
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 1;
    border-top: 1px solid #E8F0F7;
  }
}
</style>