<template>
  <RowBrowser
    :coding-view="false"
    :data="answersFiltered"
    :load-data="getRemoteResults"
    :translations-enabled="showTranslations"
    :id="id"
    :reset-button="false"
  />
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import RowBrowser from '@/components/RowBrowser'

import { getterWithKeyDeconstructed, getterDeconstructed } from '@/utils/vuex.js'
import { generateInject, injectComputedValues } from '@/components/customUi/mixins/provideInject'

import VerbatimBrowservQueryParserMixin from '@/components/VerbatimBrowserv2/mixins/VerbatimBrowservQueryParser.js'

import { modifyChartPayloadConfig } from '@/utils/chartConfigModificator.js'
import { SCORING_TYPE_AVERAGE, CHART_TYPES_TO_API_ENUM, DEFAULT_ROW_LIMIT } from '@/settings/constants'

export default {
  components: {
    RowBrowser
  },

  mixins: [
    VerbatimBrowservQueryParserMixin
  ],

  props: {
    id: {
      type: [String, null],
      default: null,
      required: true
    },
    queryParams: {
      type: [Object],
      default: () => ({})
    },
    extraQueryParams: {
      type: [Object],
      default: () => ({})
    },
    fieldsToDeleteBeforeRequest: {
      type: Array,
      default: () => ([])
    },
    datasets: {
      type: Array,
      default: () => ([])
    },
    chartType: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      collapsed: false,
      remoteResults: {
        rowsPerPage: {},
        page: 1,
        pagesFetched: [],
        hasNext: false,
        hasPrev: false,
        loading: true,
        failed: false,
        count: 0
      },
      cancelTokens: {
        rows: null
      }
    }
  },

  inject: generateInject(['loadingRows']),

  computed: {
    ...injectComputedValues(['loadingRows']),

    ...getterWithKeyDeconstructed('verbatimManager/state')(function () { return this.id })(['loadingRowsLock', 'pagesLoading']),

    ...getterWithKeyDeconstructed('registerManager/state')(function () { return this.id })(['method']),

    ...getterDeconstructed('metaManager/overall')(function () { return `${this.id}` })({
      showTranslations: 'show_translation'
    }),

    /**
     * The answers array filtered by the current (internal) filters
     * @return {Array} List of filtered answers
     */
    answersFiltered () {
      return this.remoteResults.rowsPerPage
    },

    isLoading () {
      return this.remoteResults.loading
    },

    /**
     * Total amount of results, remote results are paginated hence the count property
     */
    totalAnswersCount () {
      return this.remoteResults.count
    }
  },

  watch: {
    'queryParams.filters' () {
      this.resetData()
      this.init()
    },
    'config.filters' () {
      this.resetData()
      this.init()
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      this.getRemoteResults({ initialFetch: true, page: 1 })
    },

    resetData () {
      this.$set(this, 'remoteResults', {
        rowsPerPage: {},
        page: 1,
        pagesFetched: [],
        hasNext: false,
        hasPrev: false,
        loading: true,
        failed: false,
        count: 0
      })
    },

    /**
    * Gets the chart config for api request, basically a normalizer for the API with differences for FE
    * Could delete redundant fields if they are defined
    * @return {Object} Current config to put into request
    */

    getChartConfig () {
      if (!this.config) return

      return {
        ...modifyChartPayloadConfig(this.config, this.fieldsToDeleteBeforeRequest),
        aggregate: this.forceAggregate ? true : this.config.aggregate
      }
    },

    /**
     * Normalizer for API
     */
    normalizeDatasetSettings (settings) {
      if (settings.scoring_type === SCORING_TYPE_AVERAGE) {
        return _.omit(settings, 'scoring_type')
      }

      return settings
    },

    /**
     * Body for results requst
     */
    getChartApiObject () {
      return {
        type: CHART_TYPES_TO_API_ENUM[this.chartType],
        config: this.getChartConfig(),
        datasets: _.map(this.datasets, ({ filters, settings, question }) => ({
          question: question.id ? question.id : question,
          filters: filters.filter(f => !!f.type), // don't save empty filters,
          settings: this.normalizeDatasetSettings(settings)
        }))
      }
    },

    setPageInRowsPerPage ({ rows, page }) {
      Vue.set(this.remoteResults.rowsPerPage, page, rows)
      // Delete pages which are too far from the current page
      _.each(this.remoteResults.rowsPerPage, (pageContent, pageKey) => {
        if (Math.abs(pageKey - page) > 3) Vue.delete(this.remoteResults.rowsPerPage, pageKey)
      })
    },

    /**
    * Fetch answers remotely
    */
    getRemoteResults ({ page = 1 }) {
      const id = this.method === 'post' ? 'ch__' : this.id
      const persistentId = this.id
      const initialFetch = page === 1
      const hasRowsToFetch = page > 0 && page <= _.round(this.remoteResults.count / DEFAULT_ROW_LIMIT) + 1

      const pageLoadingIdentifier = `${page}`

      if (this.loadingRowsLock) return

      if (this.pagesLoading.has(pageLoadingIdentifier)) {
        console.debug(`Page identifier ${pageLoadingIdentifier} already loading.`)
        return
      }

      this.loadingRows = true

      this.$store.dispatch('verbatimManager/addPageLoading', { entityId: this.id || 'ch__new', value: pageLoadingIdentifier })
      this.$store.dispatch('verbatimManager/setRowDataFetching', { entityId: this.id || 'ch__new', value: true })

      if (initialFetch || hasRowsToFetch) {
        this.cancelTokens.rows = axios.CancelToken.source()
        api.request(
          `/api/ui/charts/${id}/rows${this.getQueryParams(page, DEFAULT_ROW_LIMIT)}`,
          {
            method: this.method,
            data: this.getChartApiObject(),
            cancelToken: this.cancelTokens.rows.token
          }
        ).then(({ data }) => {
          const { count, has_next: hasNext, has_prev: hasPrev, page: returnedPage, results } = data

          this.$emit('warning', data.warnings)

          let rows = []

          if (results.length) {
            rows = _.map(results, (row, index) => ({
              type: 'row',
              id: row.id,
              index: row.upload_index,
              translateOverwritten: null,
              codingColumn: row.text_to_analyze,
              otherColumns: row.columns
            }))
          }

          const { commit } = this.$store

          // TODO: This probably can't happen here but needs to be passed in as a prop
          commit('verbatimManager/setRowDataMeta', { id: this.id, count, hasNext, hasPrev })

          this.setPageInRowsPerPage({ page, rows })

          this.loadingRows = false
          this.remoteResults = {
            ...this.remoteResults,
            hasNext,
            hasPrev,
            count,
            page: returnedPage,
            loading: false,
            failed: false
          }
          this.$store.dispatch('verbatimManager/removePageLoading', {
            entityId: this.id,
            value: pageLoadingIdentifier
          })
          this.$store.dispatch('verbatimManager/setRowDataFetching', { entityId: this.id || 'ch__new', value: false })
        })
          .catch(err => {
            this.$store.dispatch('verbatimManager/removePageLoading', {
              entityId: persistentId, // use presistent id, since this.id isn't avaialable at that moment of time
              value: pageLoadingIdentifier
            })
            this.loadingRows = false
            this.remoteResults.loading = false
            this.remoteResults.failed = true
            if (
              !axios.isCancel(err)
            ) {
              this.$maybeRaiseAPIPromiseErr(err)
            }
          })
      }
    }
  }
}

</script>