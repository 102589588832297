import { render, staticRenderFns } from "./ModelScoreVisualizer.vue?vue&type=template&id=476f6d8f&scoped=true"
import script from "./ModelScoreVisualizer.vue?vue&type=script&lang=js"
export * from "./ModelScoreVisualizer.vue?vue&type=script&lang=js"
import style0 from "./ModelScoreVisualizer.vue?vue&type=style&index=0&id=476f6d8f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476f6d8f",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/components/coding/ModelScoreVisualizer.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fcoding%2FModelScoreVisualizer.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/components/coding/ModelScoreVisualizer.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fcoding%2FModelScoreVisualizer.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)

export default component.exports