<template>
  <v-alert type="error" prominent text outlined border="left">
    <span v-html="$t('permission.no_permission', { email: adminEmail } )" />
  </v-alert>
</template>

<script>
import Vuex from 'vuex'

export default {
  codit: true,
  name: 'NoPermission',
  computed: {
    ...Vuex.mapState({
      user: 'user'
    }),
    adminEmail () { return this.user.organization.root_user_email }
  }
}

</script>
