<template>
  <div
    :class="classes"
    :style="styles"
    class="dashboard-el"
    @dragstart="$onDragOrResizeStart($event, el, 'drag')"
    @dragend="$onDragEnd($event, el)"
    @dragover.prevent.stop="$onDragOrResizeOver($event, el)"
    draggable
  >
    <div class="dashboard-el__header header d-flex align-center">
      <v-btn
        v-if="hasEditingPermissions"
        icon
        @mousedown="startDrag"
        class="move-handler"
      >
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12ZM8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14C6 12.8954 6.89543 12 8 12ZM2 6C3.10457 6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6ZM8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6ZM2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0ZM8 0C9.10457 0 10 0.89543 10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0Z"
            fill="#708189"
          />
        </svg>
      </v-btn>
      <span
        class="dashboard-el__title"
        v-if="!el.status.loading && el.width > 1"
      >{{ el.chart.name }}</span>
      <v-spacer />
      <helptip
        v-if="el.warning && el.warning[0]"
        anchor-icon="mdi-alert-circle-outline"
        position="bottom"
        :width="200"
        x-offset="-50"
        icon-style="color: #fb8c00; margin-left: 4px; font-size: 21px; margin-right: 7px;"
      >
        <span v-html="$escapeHtml(el.warning[0].message)" />
      </helptip>
      <div v-if="$slots['header-controls']">
        <slot name="header-controls" />
      </div>
      <template v-if="hasEditingPermissions">
        <v-btn
          v-if="el.chart.type.startsWith('P_')"
          icon
          @click="edit()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H17ZM6.29289 9.29289L13.2929 2.29289C13.6534 1.93241 14.2206 1.90468 14.6129 2.2097L14.7071 2.29289L17.7071 5.29289C18.0676 5.65338 18.0953 6.22061 17.7903 6.6129L17.7071 6.70711L10.7071 13.7071C10.5508 13.8634 10.3481 13.9626 10.1314 13.9913L10 14H7C6.48716 14 6.06449 13.614 6.00673 13.1166L6 13V10C6 9.77899 6.07316 9.56552 6.20608 9.39197L6.29289 9.29289L13.2929 2.29289L6.29289 9.29289ZM14 4.41421L8 10.4142V12H9.58579L15.5858 6L14 4.41421Z"
              fill="#708189"
            />
          </svg>
        </v-btn>
        <v-btn
          v-else
          :to="{ name: 'chart-details', params: { id: el.chart.id } }"
          icon
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H17ZM6.29289 9.29289L13.2929 2.29289C13.6534 1.93241 14.2206 1.90468 14.6129 2.2097L14.7071 2.29289L17.7071 5.29289C18.0676 5.65338 18.0953 6.22061 17.7903 6.6129L17.7071 6.70711L10.7071 13.7071C10.5508 13.8634 10.3481 13.9626 10.1314 13.9913L10 14H7C6.48716 14 6.06449 13.614 6.00673 13.1166L6 13V10C6 9.77899 6.07316 9.56552 6.20608 9.39197L6.29289 9.29289L13.2929 2.29289L6.29289 9.29289ZM14 4.41421L8 10.4142V12H9.58579L15.5858 6L14 4.41421Z"
              fill="#708189"
            />
          </svg>
        </v-btn>
        <v-btn
          @click="remove"
          icon
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.707 12.293C14.098 12.684 14.098 13.316 13.707 13.707C13.512 13.902 13.256 14 13 14C12.744 14 12.488 13.902 12.293 13.707L10 11.414L7.707 13.707C7.512 13.902 7.256 14 7 14C6.744 14 6.488 13.902 6.293 13.707C5.902 13.316 5.902 12.684 6.293 12.293L8.586 10L6.293 7.707C5.902 7.316 5.902 6.684 6.293 6.293C6.684 5.902 7.316 5.902 7.707 6.293L10 8.586L12.293 6.293C12.684 5.902 13.316 5.902 13.707 6.293C14.098 6.684 14.098 7.316 13.707 7.707L11.414 10L13.707 12.293ZM10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2Z"
              fill="#708189"
            />
          </svg>
        </v-btn>
      </template>
    </div>
    <div class="content">
      <alert v-if="el.status.failed" type="error">
        {{ $t('error', {'step': $t('loading.while')}) }}
      </alert>

      <div
        v-else
        class="w-100 h-100"
      >
        <slot />
      </div>
    </div>
    <v-icon :size="0.6 * p2p" class="dragging-placeholder">
      mdi-cursor-move
    </v-icon>
    <div
      v-if="hasEditingPermissions"
      class="resize-handler"
      @mousedown="resize($event)"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.5 13C10.3284 13 11 13.6716 11 14.5C11 15.3284 10.3284 16 9.5 16C8.67157 16 8 15.3284 8 14.5C8 13.6716 8.67157 13 9.5 13ZM4.5 13C5.32843 13 6 13.6716 6 14.5C6 15.3284 5.32843 16 4.5 16C3.67157 16 3 15.3284 3 14.5C3 13.6716 3.67157 13 4.5 13ZM14.5 13C15.3284 13 16 13.6716 16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13ZM14.5 3C15.3284 3 16 3.67157 16 4.5C16 5.32843 15.3284 6 14.5 6C13.6716 6 13 5.32843 13 4.5C13 3.67157 13.6716 3 14.5 3ZM14.5 8C15.3284 8 16 8.67157 16 9.5C16 10.3284 15.3284 11 14.5 11C13.6716 11 13 10.3284 13 9.5C13 8.67157 13.6716 8 14.5 8ZM9.5 8C10.3284 8 11 8.67157 11 9.5C11 10.3284 10.3284 11 9.5 11C8.67157 11 8 10.3284 8 9.5C8 8.67157 8.67157 8 9.5 8Z"
          fill="#708189"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  inject: [
    '$dragOrResize',
    '$hasEditingPermissions',
    '$p2p',
    '$spacing',
    '$openDialog',
    '$removeElementHandler',
    '$itemStartDrag',
    '$onDragOrResizeStart',
    '$onDragEnd',
    '$onDragOrResizeOver'
  ],
  props: {
    el: { type: Object, default: () => ({}) },
    classes: { type: Object, default: () => ({}) },
    styles: { type: Object, default: () => ({}) }
  },
  computed: {
    hasEditingPermissions () {
      return this.$hasEditingPermissions()
    },
    p2p () {
      return this.$p2p()
    },
    spacing () {
      return this.$spacing()
    }
  },

  methods: {
    edit () {
      this.$openDialog(this.el)
    },
    startDrag () {
      this.$itemStartDrag(this.el)
    },
    remove () {
      this.$removeElementHandler(this.el)
    },
    resize ($event) {
      this.$onDragOrResizeStart($event, this.el, 'resize')
    }
  }
}
</script>

<style lang="scss">
  .dashboard-el{
    &__header{
      min-height: 57px;
    }
  }
</style>