import { MIN_QUESTION_NAME_LEN, MAX_QUESTION_NAME_LEN, MIN_QUESTION_DESCRIPTION_LEN, MAX_TOPIC_DESCRIPTION_LEN } from '@/settings/constants'

export const validateQuestionName = (name, questionNames) => {
  let error = null

  if (name && name.length <= MIN_QUESTION_NAME_LEN) {
    error = 'steps.organize.name_short'
  }

  if (name && name.length > MAX_QUESTION_NAME_LEN) {
    error = 'steps.organize.name_long'
  }

  if (!name || !name.length) {
    error = 'steps.organize.required'
  }

  let count = 0

  questionNames.forEach(qName => {
    if (qName === name) count += 1
  })

  if (count >= 1) {
    error = 'steps.organize.name_duplicate'
  }

  return error
}

export const validateQuestionWording = wording => {
  let error = null

  if (wording && wording.length < MIN_QUESTION_DESCRIPTION_LEN) {
    error = 'steps.organize.description_short'
  }

  if (wording && wording.length > MAX_TOPIC_DESCRIPTION_LEN) {
    error = 'steps.organize.description_long'
  }

  if (!wording || !wording.length) {
    error = 'steps.organize.required'
  }

  return error
}