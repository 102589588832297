<template>
  <div class="d-flex w-100 h-100">
    <div class="d-flex wizard__codebook__topics flex-column">
      <div class="wizard-v2-cb-editor">
        <loading
          v-if="loading"
          class="h-100 position-relative"
          :is-loading="true"
          :title="$t('loading.title')"
          :tagline="$t('loading.tagline')"
          dont-animate-entry
        />
        <error-state
          v-else-if="failed"
          title="Generation error"
          :text="$t('generator_failed')"
          :action="$t('actions.retry')"
          :action-click="generatorRerun"
        />
        <topics-editor
          v-else
          :active="!loading"
          :editable="!loading"
          :id="id"
          store-name="wizard"
          history
          ref="codebook-editor"
          @initial-state="codebookIsOriginal = true"
          :wizard-view="true"
        />
        <div class="upload-footer pl-4 pr-4 default-bs">
          <v-btn
            class="upload-footer__back"
            color="white"
            :disabled="loading"
            @click="goBack"
            x-large
            elevation="0"
          >
            {{ $t('back') }}
          </v-btn>
          <v-btn
            @click="$emit('next')"
            color="primary"
            :disabled="loading"
            x-large
            class="default-bs"
          >
            {{ $t('save.title') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'

import ApiTaskMixin from '@/mixins/apitask'
import TopicsEditor from '@/components/TopicsEditor'

let originalGeneratedCodebook = []

export default {
  codit: true,
  name: 'CodebookGenerator',

  components: {
    TopicsEditor
  },

  mixins: [ApiTaskMixin],

  props: {
    active: { type: Boolean, default: false }
  },

  data () {
    return {
      codebookFromPreviousStep: [],

      failedRawTopicCollectionTimeoutCount: 0,

      meta: {},
      destroying: false,

      codebookIsOriginal: false
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user',
      id: state => state.wizard.id,
      project: state => state.wizard.project,
      codingColumn: state => state.wizard.codingColumn,
      topics: state => state.wizard.topics,
      settings: state => state.wizard.settings,
      loading: state => state.wizard.generatorLoading,
      failed: state => state.wizard.generatorFailed,
      generatorRerun: state => state.wizard.generatorRerun
    }),

    // codebookIsOriginal () {
    //   return this.topics.length === originalGeneratedCodebook.length && _.isEqual(this.topics, originalGeneratedCodebook)
    // },

    ...Vuex.mapGetters(['isListColumn', 'codeCats'])
  },

  watch: {
    topics: {
      deep: true,
      handler () {
        if (this.codebookIsOriginal && !_.isEqual(originalGeneratedCodebook, this.topics)) this.codebookIsOriginal = false
      }
    }
  },

  beforeDestroy () {
    this.destroying = true
  },

  async mounted () {
    if (this.settings.generate) {
      this.codebookFromPreviousStep = _.cloneDeep(this.topics)

      await this.updateRawTopicCollection()
    } else if (!this.settings.generate) {
      this.$store.dispatch('metaManager/registerManually', {
        entityId: this.id,
        metaData: {
          topics_complete_union: this.topics
        }
      },
      {
        root: true
      })
    }
  },

  methods: {
    /**
     * When the back button is clicked, make the user confirm the navigation away
     */
    goBack () {
      let doIt = confirm(this.$t('confirm_navigate_away'))
      if (doIt) {
        // Make sure the ears are closed, to prevent errors from popping up
        const cbEditor = this.$refs['codebook-editor']
        if (cbEditor) cbEditor.closeEar()
        this.$emit('back')
      }
    },

    async getTaskId () {
      return api.post(`/api/ui/topicer/projects/${this.project.id}/columns/${this.codingColumn.ref}/update-raw-topic-collection-async`, {
        prompt: ''
      })
        .then(res => res.data.task_id)
        .catch(err => {
          this.$store.commit('setGeneratorRerun', () => this.updateRawTopicCollection())
          this.$store.commit('setGeneratorFailed', true)
          this.$store.commit('setGeneratorLoading', false)
          setTimeout(() => { throw Error('Failed getting raw topic collection task id.') })
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    async callTopicCollectionWithTaskId (taskId) {
      return api.get(`/api/ui/topicer/projects/${this.project.id}/columns/${this.codingColumn.ref}/update-raw-topic-collection-async/${taskId}`)
        .then(res => {
          if (res.data.status === 'succeeded' && this.failedRawTopicCollectionTimeoutCount <= 120) {
            return this.runCodebookGenerator()
          } else if (res.data.status === 'in_progress') {
            this.failedRawTopicCollectionTimeoutCount = this.failedRawTopicCollectionTimeoutCount + 1
            return setTimeout(() => this.callTopicCollectionWithTaskId(taskId), 5000)
          } else {
            this.$store.commit('setGeneratorRerun', () => this.updateRawTopicCollection())
            this.$store.commit('setGeneratorFailed', true)
            this.$store.commit('setGeneratorLoading', false)
          }
        })
        .catch(err => {
          this.$store.commit('setGeneratorRerun', () => this.updateRawTopicCollection())
          this.$store.commit('setGeneratorFailed', true)
          this.$store.commit('setGeneratorLoading', false)
          setTimeout(() => { throw Error(`Failed updating raw topic collection with id: ${taskId}`) })
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    async updateRawTopicCollection () {
      this.failedRawTopicCollectionTimeoutCount = 0
      this.$store.commit('setGeneratorLoading', true)
      this.$store.commit('setGeneratorFailed', false)

      const taskId = await this.getTaskId()
      if (taskId) await this.callTopicCollectionWithTaskId(taskId)
    },

    /**
     * Run the codebook generator
     */
    runCodebookGenerator () {
      this.$store.commit('setGeneratorLoading', true)
      this.$store.commit('setGeneratorFailed', false)

      api.post(
        `/api/ui/projects/${this.project.id}/generate-topics/${this.codingColumn.ref}`,
        {
          mode: 'new',
          given_topics: this.topics,
          sentiment_enabled: this.settings.sentiment
        }
      ).then((res) => {
        this.onTopicsGenerated({ ...res.data })
      }).catch(err => {
        this.$store.commit('setGeneratorRerun', () => this.runCodebookGenerator())
        this.$store.commit('setGeneratorFailed', true)
        this.$store.commit('setGeneratorLoading', false)
        setTimeout(() => { throw Error('Codebook generator v2 failed / timed out.') })
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Callback when codebook generator function has been completed
     * @param  {Object} result The result object, containing keywords & generated codebook
     */
    onTopicsGenerated ({ topics, suggestions = [], meta }) {
      if (this.destroying) return
      let newTopics = [...this.topics]
      const codebookFromPreviousStep = this.topics

      // Validate properties of new codes and give them colors
      // Caution: Always base the difference on the codebook from the previous step
      let codesByCat = _.groupBy(codebookFromPreviousStep, 'category')
      let codeCats = _.keys(codesByCat)
      let catColors = _.mapValues(codesByCat, cc => cc[0].color)
      let codeIDS = new Set(_.map(codebookFromPreviousStep, 'id'))

      topics.forEach(c => {
        if (!codeIDS.has(c.id)) {
          if (codeCats.indexOf(c.category) === -1) codeCats.push(c.category)
          if (!(c.category in catColors)) catColors[c.category] = this.$color.getMedium(codeCats.indexOf(c.category))
          newTopics.push({
            ...c,
            color: catColors[c.category]
          })
        }
      })

      this.$store.commit('setCoverage', meta?.coverage)
      _.map(suggestions, suggestion => this.$store.commit('addSuggestion', suggestion))
      this.$store.commit('setTopics', newTopics)
      this.$store.dispatch('metaManager/registerManually', {
        entityId: this.id,
        metaData: {
          topics_complete_union: newTopics
        }
      },
      {
        root: true
      })

      this.codebookIsOriginal = true
      this.$store.commit('setGeneratorLoading', false)
      originalGeneratedCodebook = _.cloneDeep(newTopics)
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/wizard/CodebookGeneratorv2.json' />
<i18n locale='de' src='@/i18n/de/components/wizard/CodebookGeneratorv2.json' />