import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=b2fc766e"
import script from "./ProjectList.vue?vue&type=script&lang=js"
export * from "./ProjectList.vue?vue&type=script&lang=js"
import style0 from "./ProjectList.vue?vue&type=style&index=0&id=b2fc766e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/AppPage.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FProjectList.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/AppPage.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FProjectList.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/i18n/en/pages/ProjectList.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FProjectList.vue&locale=en&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/i18n/de/pages/ProjectList.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2FProjectList.vue&locale=de&external"
if (typeof block3 === 'function') block3(component)

export default component.exports