import Vue from 'vue'
import colormap from '@/components/colormap.js'
import { API, WS } from '@/api.js'
import lodash from 'lodash'

export function defineGlobalVariables () {
  window.api = API
  window.ws = WS
  window._ = lodash

  Vue.prototype.$color = colormap
  Vue.prototype.$_ = lodash

  Vue.prototype.$hasPermission = function (permission, obj) { return this.$root.hasPermission(permission, obj) }
  // Manual error handler which can be called by app
  Vue.prototype.$onError = function (error, info, context) { throw error }

  Vue.prototype.$maybeRaiseAPIPromiseErr = function (err) {
  // Function to catch errors of API call promises
  // We only report the error if it hasn't already been reported by the API error handler
    if (!err.response || !err.response.apiErrReported) {
    // setTimeout(() => { throw err }, 0)
      this.$onError(err)
    }
  }
  Vue.prototype.$maybeTruncate = function (string, maxLen, dots = '...') {
    if (string.length > maxLen) return string.slice(0, maxLen) + dots
    else return string
  }

  Vue.prototype.$uuidv4 = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
}

Vue.prototype.$escapeHtml = function (unsafe) {
  return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;')
}