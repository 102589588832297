<template>
  <div>
    <v-select
      class="w-100 mt-1"
      multiple
      dense
      hide-details
      :height="40"
      v-model="internalValue"
      :items="items"
      :menu-props="{
        'offset-y': true,
        ripple: false
      }"
    >
      <template slot="selection" slot-scope="{ item, index }">
        <span v-if="!index">{{ item.text }}</span>
        <span class="grey--text caption" v-else-if="index === 1">
          &nbsp;(+{{ internalValue.length - 1 }} others)
        </span>
      </template>
      <template v-slot:list-wrapper>
        <v-list flat class="overrided p-0">
          <v-list-item-group>
            <draggable
              class="field-select"
              group="download-codes"
              :animation="150"
              draggable=".test"
              :list="internalValue"
              multiple
              items="items"
            >
              <v-list-item
                v-for="(item, i) in internalValue"
                :key="i"
                :ripple="false"
                class="test"
              >
                <v-list-item-content>
                  <v-checkbox
                    class="w-100 text-sm"
                    :ripple="false"
                    primary
                    hide-details
                    color="green"
                    off-icon="mdi-checkbox-blank"
                    flat
                    :value="item"
                    v-model="internalValue"
                  >
                    <template v-slot:label>
                      <span class="text-sm">
                        {{ getLabel(item) }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-list-item-content>
                <v-list-item-icon
                  class="text-grey cursor-grab m-0 mt-2 d-flex align-center"
                >
                  <svg class="move-handler" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12ZM8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14C6 12.8954 6.89543 12 8 12ZM2 6C3.10457 6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6ZM8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6ZM2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0ZM8 0C9.10457 0 10 0.89543 10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0Z" fill="#708189" />
                  </svg>
                </v-list-item-icon>
              </v-list-item>
            </draggable>
            <template v-for="(item, i) in items">
              <v-list-item
                :key="i"
                :ripple="false"
                class="test"
                v-if="isItemShownAvaialable (item.value)"
              >
                <v-list-item-content>
                  <v-checkbox
                    class="w-100"
                    :ripple="false"
                    primary
                    hide-details
                    color="green"
                    off-icon="mdi-checkbox-blank"
                    flat
                    :value="item.value"
                    v-model="internalValue"
                  >
                    <template v-slot:label>
                      <span class="text-sm">
                        {{ item.text }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </template>
    </v-select>
    <div class="text-xs text-gray mt-1">
      <slot name="comment" />
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import DataMixin from '@/components/customUi/mixins/data'

export default {
  components: {
    'draggable': Draggable
  },
  mixins: [
    DataMixin
  ],
  props: {
    value: { type: Array, required: true },
    items: { type: Array, required: true },
    availableItems: { type: Array, default: null }
  },
  methods: {
    getLabel (item) {
      return _.find(this.items, ({ value }) => item === value).text
    },
    isItemShownAvaialable (item) {
      return this.internalValue.indexOf(item) === -1 && (
        !this.availableItems ||
        this.availableItems.indexOf(item) > -1
      )
    }
  }
}
</script>