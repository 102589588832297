export default {
  data () {
    return {
      copyLinkOptions: [
        {
          text: this.$t('copy_public'),
          value: 'public-link'
        },
        {
          text: this.$t('copy_public_with_filters'),
          value: 'public-link-with-filters'
        },
        {
          text: this.$t('copy_embed'),
          value: 'embed-link'
        },
        {
          text: this.$t('copy_embed_with_filters'),
          value: 'embed-link-with-filters'
        }
      ],

      isFilterSticky: false
    }
  },

  computed: {
    /**
     * If the user has the visualizations included in their subscription
     */
    hasVisualizationsAccess () {
      return this.user?.subscription?.features?.visualizations_access
    },

    /**
     * If the dashboard is public
     * @return {Boolean}
     */
    isPublic () {
      return String(this.$route.params.id).startsWith('p-')
    },

    /**
     * The domain which should be used for public links - may be customized by organization settings
     * @return {String}
     */
    publicLinkDomain () {
      return this.user.app_settings.CLIENT_DASHBOARD_DOMAIN || window.location.origin
    },

    /** The public link to the dashboard */
    publicLink () {
      return `${this.publicLinkDomain}/app${this.$route.matched[0].path.replace(':id', 'p-' + this.dashboard.public_uuid)}`
    },

    /** The public link to the dashboard with filter query params */
    publicLinkWithFilters () {
      return `${this.publicLinkDomain}/app${this.$route.matched[0].path.replace(':id', 'p-' + this.dashboard.public_uuid)}?filters=${encodeURIComponent(this.filtersTransformedToQueryParams())}`
    },

    /** The iframe with public link */
    embedLink () {
      return this.generateIframeWithPublicLink(this.publicLink)
    },

    /** The iframe with public link with filter query params */
    embedLinkWithFilters () {
      return this.generateIframeWithPublicLink(this.publicLinkWithFilters)
    }
  },

  mounted () {
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },

  beforeRouteLeave (to, from, next) {
    let doIt = true
    // If there are unsaved changes, and the persistent open credits description is not open,
    // confirm navigating away
    if (this.isDirty && !this.modalsShown.notEnoughCredits) doIt = confirm(this.$t('confirm_navigate_away'))
    next(doIt)
  },

  methods: {
    beforeUnload ($event) {
      if (this.isDirty && !this.modalsShown.notEnoughCredits) $event.returnValue = true
    },

    /*
    * Generate iFrame
    */
    generateIframeWithPublicLink (link) {
      return `<iframe
        src="${link}"
        allow="fullscreen"
        width="100%"
        height="800"
        style="border: none;"
        loading="lazy"
      >
      </iframe>`
    },

    /**
     * Copy the public dashboard link to clipboard
     */
    copySharableText (value) {
      // reset v-select value, in order to always show label
      this.dashboardFilters.mockFilterValue = value
      this.$nextTick(() => { this.dashboardFilters.mockFilterValue = '' })

      let codeToCopy = document.querySelector(`#${value}`)

      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()

      document.execCommand('copy')
      this.$root.snackMsg(this.$t('copied'))

      /* unselect the range */
      codeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    onWindowScroll (e) {
      this.isFilterSticky = e.target.documentElement.scrollTop >= this.$refs.filterPanelTrigger.offsetTop
    }
  }
}