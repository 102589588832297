<template>
  <div :class="[
    'vertical-accordion d-flex flex-wrap mx-n1 my-n2',
    {
      'justify-center': isCentered
    }
  ]"
  >
    <slot />
  </div>
</template>

<script>
import { generateProvide } from '@/components/customUi/mixins/provideInject'
import dataMixin from '@/components/customUi/mixins/data.js'

export default {
  mixins: [
    dataMixin
  ],
  props: {
    value: { type: [Number, null], default: null },
    isCentered: { type: Boolean, default: false }
  },
  data () {
    return {
      accordionItemCount: -1
    }
  },
  provide () {
    return generateProvide.call(this, {
      accordionItemCount: 'accordionItemCount',
      activeAccordionItem: 'internalValue'
    })
  }
}
</script>

<style lang="scss" scoped>
.vertical-accordion {
  user-select: none;
}
</style>