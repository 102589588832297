import Vue from 'vue'
import { TRAINING_TIME_ESTIMATOR_BERT, QUESTION_CATEGORY_LIST } from '@/settings/constants'

import { get3ShadesForColor } from '@/utils/colorUtils'

import registerManager from '@/store/managers/register'
import metaManager from '@/store/managers/meta'
import configManager from '@/store/managers/config'
import verbatimManager from '@/store/managers/verbatim'
import questionManager from '@/store/managers/question'

import verbatimDialog from '@/store/mixins/verbatimDialog'

let _getAnsWeight = ans => 1 + ('identical_ids' in ans ? ans.identical_ids.length : 0)

const touchAnsState = (state) => { state.storeState.answers += 1 }
const touchSortState = (state) => { state.storeState.sorting += 1 }

const REVIEWED_PREDICTION_THRESH = [20, 50, 100, 150, 200, 250, 300, 450, 600, 800]

export default {
  strict: process.env.NODE_ENV !== 'production',
  state () {
    return {

      storeState: {
        answers: 0,
        sorting: 0
      },

      // Loading
      loaded: false,
      destroyed: false,
      editable: true,
      listCodable: false,
      processing: false,

      // DB data
      question: {
        id: '',
        name: '',
        description: '',
        language: '',
        owner: '',
        owner_id: '',
        created: '',
        codebook: [],
        last_modified: '',
        training_completed: '',
        training_requested: '',
        ntrainings: 0,
        nanswers: 0,
        nreviewed: 0,
        is_training: false,
        completed: false,
        inherits_from: null,
        inherits_from_name: null,
        translated: 0,
        model: { score: 0, score_remaining: 0, score_per_code: [], active_learning: false },
        auxiliary_column_names: [],
        group_identical: false,
        group_identical_exclude: '',
        show_sentiment: true,
        show_translation: false,
        smart_sort: false,
        model_certainty_thresh: 50,
        credits_total: 0,
        credits_open: 0,
        no_training: false,
        question_category: '',
        project: '',
        project_name: '',
        permissions: {},
        data_source: {}
      },

      codebookv2: [],

      answers: [],
      groupedAnswerIDs: {},
      codes: [],

      answerID2Idx: {},
      answerIdx2SortedIdx: {},

      // UI
      focusMode: false,
      dialogIsOpen: false,

      // Function reference to James.say
      james: {
        say: null,
        counters: {}
      },

      // Stats
      stats: {
        nanswers_unique: 0,
        nanswers_empty: 0,
        nchanged: 0,
        nchanged_unique: 0,
        nreviewed: 0,
        nreviewed_unique: 0,
        nnocodes: 0,
        nnocodes_unique: 0,
        codeCounts: {},
        codeCatCounts: {},
        nPredicted: 0,
        lastTrainedAtNreviewed: -1,
        trainingRequestedTime: null,
        trainingCompletedETA: null,
        codebookDirtySinceTrainingRequested: false
      },
      predictionsCachedReady: false,

      // Saving
      lastSaved: null,
      unsavedAnswers: [],
      savingError: {
        cntAnswers: 0,
        cntCodes: 0,
        authenticationError: false,
        networkError: false
      },

      usersOnline: [],

      sessionIssue: {
        show: false,
        type: null
      },

      // New code trigger
      newCode: {
        cb: null,
        name: ''
      }
    }
  },

  /**
  * Mutations only accept the objects to be modified, no indexes or IDs (except when removing, then the idx may be provided)
  */
  mutations: {
    isLoaded: state => { state.loaded = true },

    reloading: state => { state.loaded = false },

    isDestroyed: state => { state.destroyed = true },

    isProcessing: state => { state.processing = true },
    processingDone: state => { state.processing = false },

    /** =========== Initial Setters =========== **/
    isNotEditable: state => { state.editable = false },
    isEditable: state => { state.editable = true },
    listIsCodable: state => { state.listCodable = true },
    /** =========== Initial Setters =========== **/
    hasSessionIssue: (state, type) => {
      state.sessionIssue.type = type
      state.sessionIssue.show = true
    },

    closeSessionIssue: (state) => {
      state.sessionIssue.show = false
    },

    setUsersOnline: (state, usersOnline) => {
      Vue.set(state, 'usersOnline', usersOnline)
    },

    setQuestion (state, question) {
      // Set all key in question object that are already defined on this data obj
      _.each(state.question, (val, key) => { Vue.set(state.question, key, question[key]) })
      Vue.set(state, 'codes', question.codebook)
    },

    setCodebookv2 (state, codebookv2) {
      Vue.set(state, 'codebookv2', codebookv2)
      // Add the v2 code id as id_internal to all v1 codes for record keeping
      let userid2v2id = {}
      codebookv2.forEach(v2code => {
        userid2v2id[v2code.sentiment_neutral.code] = v2code.id
      })
      state.codes.forEach(c => {
        // It can be that there is no internal id yet for one code
        // if two new codes have been created quickly after each other
        if (c.id in userid2v2id) c.id_internal = userid2v2id[c.id]
      })
    },

    setAnswers (state, answers) {
      state.stats.nanswers_empty = 0
      state.stats.nchanged = state.stats.nchanged_unique = 0
      state.stats.nreviewed = state.stats.nreviewed_unique = 0
      state.stats.nnocodes = state.stats.nnocodes_unique = 0

      const MUTABLE_PROPS = new Set(['codes', 'idx_sorted', 'marking', 'reviewed', 'changed', 'sentiment_changed', 'sentiment'])

      let makeImmutable = ans => {
        // Get properties
        let propNames = Object.getOwnPropertyNames(ans)

        // Set properties to non-writable which are not explicitely allowed
        for (let name of propNames) {
          // if (!MUTABLE_PROPS.has(name)) console.log(name)
          if (!MUTABLE_PROPS.has(name)) {
            Object.defineProperty(ans, name, { writable: false, configurable: false })
          }
        }
        Object.seal(ans)
      }

      let answerID2Idx = {}
      answers.forEach((ans, idx) => {
        let _ansWeight = _getAnsWeight(ans)
        state.stats.nanswers_empty += Number(ans.text === '') * _ansWeight
        state.stats.nchanged += Number(ans.changed) * _ansWeight
        state.stats.nchanged_unique += Number(ans.changed)
        state.stats.nreviewed += Number(ans.reviewed) * _ansWeight
        state.stats.nreviewed_unique += Number(ans.reviewed)
        state.stats.nnocodes += Number(!ans.codes.length) * _ansWeight
        state.stats.nnocodes_unique += Number(!ans.codes.length)

        ans.translated = state.question.translated > 0 && ans.translated_text && ans.source_language !== state.question.language

        answerID2Idx[ans.id] = idx
        if ('identical_ids' in ans) ans.identical_ids.forEach(identID => { state.groupedAnswerIDs[identID] = ans })

        makeImmutable(ans)
      })

      if (state.stats.lastTrainedAtNreviewed === -1) {
        // Set the last threshold crossed to the current number of reviewed responses
        state.stats.lastTrainedAtNreviewed = state.stats.nreviewed_unique
      }

      state.stats.nanswers_unique = answers.length

      Vue.set(state, 'answers', answers)
      Vue.set(state, 'answerID2Idx', answerID2Idx)
      touchAnsState(state)
    },
    /** =========== Cross Component communication =========== **/

    triggerNewCode: (state, { name, cb }) => {
      if (typeof cb !== 'function') throw new Error('Received invalid new code callback object.')
      state.newCode.cb = cb
      state.newCode.name = name
    },

    resetNewCodeTrigger: (state) => {
      state.newCode.cb = null
      state.newCode.name = ''
    },

    setJamesSay (state, say) { state.james.say = say },

    jamesReset (state, key) { Vue.set(state.james.counters, key, 0) },

    jamesIncrement (state, key) {
      if (!(key in state.james.counters)) Vue.set(state.james.counters, key, 1)
      else state.james.counters[key] = Math.min(state.james.counters[key] + 1, 4)
    },

    jamesDecrement (state, key) {
      if (!(key in state.james.counters)) Vue.set(state.james.counters, key, -1)
      else state.james.counters[key] = Math.max(state.james.counters[key] - 1, -4)
    },

    startedTraining: state => { state.isTraining = true },
    completedTraining: state => { state.isTraining = false },

    focusModeOn: state => { state.focusMode = true },
    focusModeOff: state => { state.focusMode = false },

    /** =========== SURVEY Modifiers =========== **/
    setQuestionName (state, { name }) {
      state.question.name = name
    },

    setQuestionDescription (state, { description }) {
      state.question.description = description
    },

    setQuestionCompleted (state, { completed }) {
      state.question.completed = completed
    },

    setQuestionGroupIdentical (state, { groupIdentical }) {
      state.question.group_identical = groupIdentical
    },

    setQuestionGroupIdenticalExclude (state, { groupIdenticalExclude }) {
      state.question.group_identical_exclude = groupIdenticalExclude
    },

    setQuestionShowSentiment (state, { showSentiment }) {
      state.question.show_sentiment = showSentiment
    },

    setQuestionShowTranslation (state, { showTranslation }) {
      state.question.show_translation = showTranslation
    },

    setQuestionSmartSort (state, { smartSort }) {
      state.question.smart_sort = smartSort
    },

    setQuestionInheritsFrom (state, { inheritsFrom }) {
      state.question.inherits_from = inheritsFrom
    },

    setQuestionInheritsFromName (state, { inheritsFromName }) {
      state.question.inherits_from_name = inheritsFromName
    },

    setQuestionModel (state, { model }) {
      Vue.set(state.question, 'model', model)
    },

    setQuestionBilled: state => { state.question.credits_open = 0 },

    /** =========== CODE Modifiers =========== **/

    codebookIsDirty: (state) => { state.stats.codebookDirtySinceTrainingRequested = true },
    codebookIsClean: (state) => { state.stats.codebookDirtySinceTrainingRequested = false },

    setCodes: (state, codes) => Vue.set(state, 'codes', codes),
    addCode: (state, { code, position }) => state.codes.splice(position > -1 ? position : state.codes.length, 0, code),
    modifyCode: (state, { code, newAttributes }) => {
      _.each(newAttributes, (val, key) => {
        Vue.set(code, key, newAttributes[key])
      })
    },
    deleteCode: (state, { codeIdx }) => state.codes.splice(codeIdx, 1),
    setCodeCounts: (state, { counts, catCounts }) => {
      Vue.set(state.stats, 'codeCounts', counts)
      Vue.set(state.stats, 'codeCatCounts', catCounts)
    },

    savingCodesError: (state, status) => {
      // Saving fail
      state.savingError.networkError = !status
      state.savingError.authenticationError = (status === 401 || status === 403)
      state.savingError.cntCodes += 1
    },

    clearSavingCodesError: state => { state.savingError.cntCodes = 0 },

    /** =========== ANSWER Modifiers =========== **/

    answersStateChanged: (state) => touchAnsState(state),

    addCodeToAnswer: (state, { answer, codeID }) => { answer.codes.push(codeID); touchAnsState(state) },
    removeCodeFromAnswer: (state, { answer, codeIdx }) => { answer.codes.splice(codeIdx, 1); touchAnsState(state) },
    replaceCodeInAnswer: (state, { answer, codeIdx, newCode }) => { answer.codes.splice(codeIdx, 1, newCode); touchAnsState(state) },
    setAnswerCodes: (state, { answer, codes }) => { Vue.set(answer, 'codes', codes); touchAnsState(state) },
    setAnswerProps: (state, { answer, props }) => {
      _.each(props, (v, k) => Vue.set(answer, k, v))
      touchAnsState(state)
      if ('idx_sorted' in props) touchSortState(state)
    },
    setAnswerIdxSorted: (state, { answer, idxSorted }) => { Vue.set(answer, 'idx_sorted', idxSorted); touchSortState(state) },

    setAnswerMarking: (state, { answer, marking }) => { Vue.set(answer, 'marking', marking); touchAnsState(state) },
    setAnswerSentiment: (state, { answer, sentiment }) => {
      answer.sentiment = sentiment
      answer.sentiment_changed = true
      touchAnsState(state)
    },
    setAnswerChanged: (state, { answer, changed }) => {
      if (answer.changed === changed) return
      state.stats.nchanged += (answer.changed ? -1 : 1) * _getAnsWeight(answer)
      state.stats.nchanged_unique += (answer.changed ? -1 : 1)
      answer.changed = changed
    },
    setAnswerReviewed: (state, { answer, reviewed }) => {
      if (answer.reviewed === reviewed) return
      state.stats.nreviewed += (answer.reviewed ? -1 : 1) * _getAnsWeight(answer)
      state.stats.nreviewed_unique += (answer.reviewed ? -1 : 1)
      answer.reviewed = reviewed
      touchAnsState(state)
    },
    addToUnsaved: (state, { answer }) => {
      if (state.unsavedAnswers.indexOf(answer) === -1) state.unsavedAnswers.push(answer)
    },
    clearUnsaved: (state) => state.unsavedAnswers.splice(0),

    savingAnswerError: (state, status) => {
      // Saving fail
      state.savingError.networkError = !status
      state.savingError.authenticationError = (status === 401 || status === 403)
      state.savingError.cntAnswers += 1
    },

    clearSavingAnswersError: state => { state.savingError.cntAnswers = 0 },

    /** =========== STATS Modifiers =========== **/
    modifyCodeCatCountBy: (state, { cat, by }) => { state.stats.codeCatCounts[cat] += by },

    decrementNoCodeCount: (state, { by }) => {
      // This mutation is expected to be called for *one* answer
      state.stats.nnocodes -= by
      state.stats.nnocodes_unique -= 1
    },

    incrementNoCodeCount: (state, { by }) => {
      // This mutation is expected to be called for *one* answer
      state.stats.nnocodes += by
      state.stats.nnocodes_unique += 1
    },

    setPredictedMeta: (state, { trainingCompleted, nTrainings, model, nPredicted }) => {
      state.question.training_completed = trainingCompleted
      state.question.ntrainings = nTrainings
      state.stats.nPredicted = nPredicted
      Vue.set(state.question, 'model', model)
    },

    setStats: (state, { nreviewed, nreviewedUnique, nchanged, nchangedUnique, nnocodes, nnocodesUnique }) => {
      state.stats.nchanged = nchanged
      state.stats.nchanged_unique = nchangedUnique
      state.stats.nreviewed = nreviewed
      state.stats.nreviewed_unique = nreviewedUnique
      state.stats.nnocodes = nnocodes
      state.stats.nnocodes_unique = nnocodesUnique
    },

    predictionsCachedReady: (state) => { state.predictionsCachedReady = true },

    setLastSaved: (state, val) => { state.lastSaved = val },

    updateLastTrainedNReviewed: (state) => { state.stats.lastTrainedAtNreviewed = state.stats.nreviewed_unique },
    setTrainingRequestedTime: (state, val) => {
      state.stats.trainingRequestedTime = val
      if (val !== null) {
        let expectedDuration = TRAINING_TIME_ESTIMATOR_BERT(state.answers.length, state.codes.length)
        state.stats.trainingCompletedETA = moment(state.stats.trainingRequestedTime).add(expectedDuration, 'seconds')
      } else state.stats.trainingCompletedETA = null
    },

    /** =========== UI Modifiers =========== **/
    dialogOpened: (state) => { state.dialogIsOpen = true },
    dialogClosed: (state) => { state.dialogIsOpen = false }
  },

  getters: {
    codesByCat: (state, getters) => { return _.groupBy(state.codes, 'category') },

    // Make sure the codes are sorted by category, after first appearance
    codesSorted: (state, getters) => _.flatMap(getters.codesByCat),

    codeCats: (state, getters) => { return _.keys(getters.codesByCat) },

    codeCats2CatIdx: (state, getters) => {
      let mapping = {}
      getters.codeCats.forEach((cat, idx) => { mapping[cat] = idx })
      return mapping
    },

    codeID2Code: (state, getters) => {
      let mapping = {}
      state.codes.forEach((c, idx) => { mapping[c.id] = c })
      return mapping
    },

    codeID2Idx: (state, getters) => {
      let mapping = {}
      state.codes.forEach((c, idx) => { mapping[c.id] = idx })
      return mapping
    },

    codeID2IdxSorted: (state, getters) => {
      // mapping of code ids to code indexes (in codes)
      let idToIdx = {}
      getters.codesSorted.forEach((code, codeIdx) => { idToIdx[code.id] = codeIdx })
      return idToIdx
    },

    codeID2CatIdx: (state, getters) => {
      let mapping = {}
      state.codes.forEach(c => { mapping[c.id] = getters.codeCats2CatIdx[c.category] })
      return mapping
    },

    codeID2Cat: (state, getters) => {
      let mapping = {}
      state.codes.forEach(c => { mapping[c.id] = c.category })
      return mapping
    },

    codeCat2CatIdx: (state, getters) => {
      let mapping = {}
      getters.codeCats.forEach((c, idx) => { mapping[c] = idx })
      return mapping
    },

    catColors: (state, getters) => {
      let mapping = {}
      getters.codeCats.forEach(cat => {
        mapping[cat] = get3ShadesForColor(getters.codesByCat[cat][0].color)
      })
      return mapping
    },

    codeByID: (state, getters) => id => {
      if (!(id in getters.codeID2Code)) throw new Error(`Invalid code ID ${id} provided`)
      else return getters.codeID2Code[id]
    },

    nCodesTotal: (state, getters) => {
      // count total number of annotations in question
      let cnt = 0
      _.each(state.stats.codeCounts, (codeCnt) => { cnt += codeCnt })
      return cnt
    },
    nAnswersInList: state => state.answers.length,

    answerByID: (state, getters) => id => {
      if (!(id in state.answerID2Idx)) throw new Error(`Invalid answer ID ${id} provided`)
      else return state.answers[state.answerID2Idx[id]]
    },

    questionTrainingCompleted: (state) => state.question.training_completed !== null,

    prevRetrainThresh: (state, getters) => {
      // Find the previous threshold where predictions were requested
      let nextThreshold = getters.nextRetrainThresh
      let nextThresholdIdx = REVIEWED_PREDICTION_THRESH.indexOf(nextThreshold)

      // If the exact number doesn't exist in the REVIEWED_PREDICTION_THRESH arr
      // this means that we are above the maximum defined there already
      if (nextThresholdIdx === -1) return nextThreshold - 250
      // If it is the first index, the previous threshold would've been zero
      else if (nextThresholdIdx === 0) return 0
      else return REVIEWED_PREDICTION_THRESH[nextThresholdIdx - 1]
    },

    nextRetrainThresh: (state, getters) => {
      // Find the next threshold where predictions are requested
      let nextThreshold = _.filter(REVIEWED_PREDICTION_THRESH, v => v > state.stats.lastTrainedAtNreviewed)[0]
      // If it's above the max value defined in REQ_PRED_THRESH, it's the next multiple of 250
      nextThreshold = (nextThreshold === undefined ? (Math.floor(state.stats.lastTrainedAtNreviewed / 250) + 1) * 250 : nextThreshold)

      return nextThreshold
    },

    isListQuestion: (state) => state.question.question_category === QUESTION_CATEGORY_LIST,

    isNotCodableList: (state) => {
      return state.question.question_category === QUESTION_CATEGORY_LIST && !state.listCodable
    }
  },

  actions: {
    /** =========== SURVEY actions =========== **/
    billQuestion ({ commit, state, getters, dispatch }) {
      // If there are unbilled answers, bill them, otherwise do nothing
      if (!state.question.credits_open) return
      // It doesn't matter if the download is called first or the billing api call, both ways should work
      api.post(`/api/questions/${state.question.id}/bill`).then((res) => {
        if (res.data.success) {
          // If successfull, the balance of this question is now zero
          commit('setQuestionBilled')
          // Update the account balance
          dispatch('updateUser', {
            credits_remaining_monthly: res.data.credits_remaining_monthly,
            credits_remaining_oneoff: res.data.credits_remaining_oneoff,
            dontPatch: true
          })
        } else setTimeout(() => { throw res })
      }).catch(err => this._vm.$maybeRaiseAPIPromiseErr(err))
    },

    saveQuestionProps ({ commit, state, getters, dispatch }, props) {
      _.each(props, (v, k) => commit(`setQuestion${_.upperFirst(k)}`, { [k]: v }))
      return api.patch('/api/questions/' + state.question.id, _.mapKeys(props, (v, k) => _.snakeCase(k)))
    },

    saveQuestionModelProps ({ commit, state, getters, dispatch }, props) {
      let model = _.clone(state.question.model)
      _.each(props, (v, k) => { model[k] = v })
      dispatch('saveQuestionProps', { model })
    },

    loadCodebookv2 ({ commit, state, getters, dispatch }, questionID) {
      return api.get('/api/ui/questions/' + questionID + '/codebook')
    },

    onUnload ({ commit, state, getters, dispatch }) {
      commit('isDestroyed')
    },

    setTrainingRequestedTime ({ commit }, val) {
      commit('setTrainingRequestedTime', val)
      commit('codebookIsClean')
    },

    setPredictions ({ commit, state, getters, dispatch }, { predictions, currentAnswerID, allowCurrentAnswerOverwrite, sorting = true }) {
      // todo(mg) simplify for new bert, generalize for answer property updates from websocket
      console.log('Setting predictions')

      let predAnswersValid, minSortIdx, predsSortIndex
      let nPredicted = 0

      if (sorting) {
        // Sorting only applies to the open mode
        let MIN_SORT_OFFSET = 4
        // the sorted idx of the currently focused answer plus padding
        minSortIdx = currentAnswerID < 0 ? 0 : (getters.answerByID(currentAnswerID).idx_sorted + MIN_SORT_OFFSET)

        // First resort the "reviewed" answers, so they are displayed first
        let resortReviewed = state.answers.filter(a => a.idx_sorted >= minSortIdx && a.reviewed)

        let resortReviewedSorted = resortReviewed.sort((a1, a2) => a1.idx_sorted - a2.idx_sorted)
        resortReviewedSorted.forEach((answer, answerIdx) => {
          commit('setAnswerIdxSorted', { answer, idxSorted: minSortIdx + answerIdx })
        })

        let predsSortValues = []

        predAnswersValid = []
        // create array for all predictions which will be sorted
        predictions.answers.forEach(resAns => {
          if (resAns.id in state.groupedAnswerIDs) return
          let currAns
          try {
            currAns = getters.answerByID(resAns.id)
          } catch (err) {
            // this._vm.$onError(err)
            return
          }
          if (currAns.idx_sorted >= minSortIdx) {
            resAns.idxInSortArr = predsSortValues.length
            if (currAns.reviewed) predsSortValues.push(-1000)
            else predsSortValues.push(resAns.sortval || resAns.idx_sorted)
          } else {
            // We need to reset the idxInSortArr, if this has been previously set
            if (resAns.idxInSortArr !== undefined) {
              setTimeout(() => { throw new Error(`How da fuck can this happen!?\n${JSON.stringify(resAns)}`) })
            }
            resAns.idxInSortArr = undefined
          }
          predAnswersValid.push(resAns)
        })

        // do argsort on the index array
        let predsSortValuesArgsort = predsSortValues
          .map((val, idx) => [val, idx])
          .sort(([val1], [val2]) => val1 - val2)

        // Switch key and values, i.e. index of prediction which should be sorted -> relative sort value
        predsSortIndex = Array(predsSortValuesArgsort.length)
        predsSortValuesArgsort.forEach(([, val], idx) => { predsSortIndex[val] = idx })

        minSortIdx += resortReviewed.length
      } else {
        // This is the list mode
        predAnswersValid = predictions.answers
      }

      predAnswersValid.forEach(resAns => {
        // Don't set prediction for the grouped IDs
        if (resAns.id in state.groupedAnswerIDs) return
        let answer = getters.answerByID(resAns.id)
        // Update answer if it hasn't been reviewed yet and is not currently focused
        if (!answer.reviewed && (allowCurrentAnswerOverwrite || answer.id !== currentAnswerID)) {
          // Remove codes with too low accuracy or that don't exist anymore
          let codes = _.filter(resAns.codes, (c, cidx) => (c in getters.codeID2Idx))
          // Compare to current codes, only update if not equal
          if (answer.codes.length !== codes.length || !answer.codes.every((v, i) => v === codes[i])) {
            // Check that all the predicted codes still exist
            commit('setAnswerCodes', { answer, codes })
          }
          // Update the sorted idx if applicable
          if (resAns.idxInSortArr !== undefined) {
            commit('setAnswerIdxSorted', { answer, idxSorted: minSortIdx + predsSortIndex[resAns.idxInSortArr] })
          }
          nPredicted += 1
        }
      })

      commit('setPredictedMeta', {
        model: predictions.model || state.question.model,
        trainingCompleted: predictions.training_completed || moment(),
        nTrainings: predictions.ntrainings || 1,
        nPredicted: nPredicted || predictions.answers.length
      })

      commit('setTrainingRequestedTime', null)

      dispatch('countCodes')
    },

    /** =========== CODE Actions =========== **/
    /**
     * Add a new code to the codebook
     * @param  {Object} code             Object with all properties a code needs to have
     */
    addCode: ({ commit, state, getters, dispatch }, { code, position }) => {
      commit('addCode', { code, position })
      commit('codebookIsDirty')
    },

    modifyCode: ({ commit, state, getters, dispatch }, { codeID, newAttributes, position }) => {
      if (position !== undefined) {
        let code = getters.codeByID(codeID)
        commit('deleteCode', { codeIdx: getters.codeID2Idx[codeID] })
        commit('addCode', { code, position })
      }

      let code = getters.codeByID(codeID)
      let labelChanged = 'label' in newAttributes && !_.isEqual(newAttributes.label, code.label)
      let keywordsChanged = 'keywords' in newAttributes && !_.isEqual(newAttributes.keywords, code.keywords)

      commit('modifyCode', { code, newAttributes })
      if (labelChanged || keywordsChanged) commit('codebookIsDirty')

      // If the id was modified, do a complete reload to ease things
      if ('id' in newAttributes) {
        commit('reloading')
        // Save the current answers, then set unload them
        // This makes sure some super smart logic in other places doesn't remove the code id we changed
        // then reload the entire page for simplicity reasons
        dispatch('saveAnswers')
        commit('setAnswers', [])
        let dc = dispatch('saveCodes')
        commit('isDestroyed') // prevent saveCodes from firing again
        dc.then(() => window.location.reload())
      }
    },

    deleteCode: ({ commit, state, getters, dispatch }, codeID) => {
      // delete the code from all the answers
      state.answers.forEach((a, ansIdx) => {
        let codeInAnswerIdx = a.codes.indexOf(codeID)
        if (codeInAnswerIdx !== -1) {
          commit('removeCodeFromAnswer', { codeIdx: codeInAnswerIdx, answer: a })
          commit('addToUnsaved', { answer: a })
        }
      })

      // delete the code from the code book (by codeIdx)
      let codeIdx = getters.codeID2Idx[codeID]
      commit('deleteCode', { codeIdx })
    },

    saveCodes ({ commit, dispatch, state }) {
      if (state.destroyed) return

      // Bring codes into v2 format
      // We hack this, as sentiment is still always disabled we can do a direct mapping of user ids -> v2 ids
      let v2codes = state.codes.map(code => ({
        id: code.id_internal,
        color: code.color,
        label: code.label,
        category: code.category,
        keywords: code.keywords,
        description: code.description,
        sentiment_neutral: {
          code: code.id
        }
      }))

      return api.patch(`/api/ui/questions/${state.question.id}/codebook`, v2codes).then(res => {
        if (res.status !== 200) {
          setTimeout(() => dispatch('saveCodes'), 5000) // Retry in 5s
          commit('savingCodesError', (res && res.status))
        } else {
          commit('setCodebookv2', res.data)
          commit('clearSavingCodesError')
        }
      }).catch(err => {
        setTimeout(() => dispatch('saveCodes'), 5000) // Retry in 5s
        commit('savingCodesError', (err.response && err.response.status))
        this._vm.$maybeRaiseAPIPromiseErr(err)
      })
    },

    mergeCodes: ({ commit, state, getters, dispatch }, { parentID, childIDs }) => {
      // To freeze the UI and display a processing message, we emit a promise
      // indicating when things are done
      // First we set the processint state.. then give the UI some time to display
      // the processing illustration
      commit('isProcessing')
      return new Promise(resolve => {
        let MIN_PROCESSING_TIME = 2000
        let startTime = new Date().getTime()
        // After half a second, we start with the real computations
        setTimeout(() => {
          state.answers.forEach(answer => {
            let newParentSet = answer.codes.indexOf(parentID) !== -1
            // First update all answers to only have the id of the new code
            childIDs.forEach(child => {
              let childIdx = answer.codes.indexOf(child)
              if (childIdx === -1) return
              else if (!newParentSet) {
                commit('replaceCodeInAnswer', { answer, codeIdx: childIdx, newCode: parentID })
                newParentSet = true
              } else commit('removeCodeFromAnswer', { answer, codeIdx: childIdx })
              commit('addToUnsaved', { answer })
            })
          })

          // Move all keywords to the parent code, if they not already exist
          let parentCodeIdx = getters.codeID2Idx[parentID]
          let parentCode = state.codes[parentCodeIdx]

          let parentKeywords = _.cloneDeep(parentCode.keywords)

          childIDs.forEach(child => {
            let childIdx = getters.codeID2Idx[child]
            state.codes[childIdx].keywords.forEach(kw => {
              if (parentKeywords.indexOf(kw) === -1) parentKeywords.push(kw)
            })

            // Remove the merged code
            commit('deleteCode', { codeIdx: childIdx })
          })

          commit('modifyCode', { code: parentCode, newAttributes: { keywords: parentKeywords } })

          let doneTime = new Date().getTime()
          // In the end make sure the processing was visible for some minimum amount of time
          // otherwise it may look ugly when the overlay flashes too quick
          setTimeout(() => {
            commit('processingDone')
            resolve()
          }, Math.max(MIN_PROCESSING_TIME - (doneTime - startTime), 0))
        }, 500)
      })
    },

    /** =========== STATISTICS actions =========== **/
    countCodes: ({ commit, state, getters, dispatch }) => {
      // code and category counts
      let counts = {}
      let catCounts = {}
      _.each(state.codes, code => {
        if (!(code.category in catCounts)) catCounts[code.category] = 0
        counts[code.id] = 0
      })

      let nchanged = 0
      let nchangedUnique = 0
      let nreviewed = 0
      let nreviewedUnique = 0
      let nnocodes = 0
      let nnocodesUnique = 0

      state.answers.forEach(a => {
        let _catCounts = {}
        let _ansWeight = _getAnsWeight(a)

        nchanged += Number(a.changed) * _ansWeight
        nchangedUnique += Number(a.changed)
        nreviewed += Number(a.reviewed) * _ansWeight
        nreviewedUnique += Number(a.reviewed)
        nnocodes += Number(!a.codes.length) * _ansWeight
        nnocodesUnique += Number(!a.codes.length)

        getters.codeCats.forEach(cat => { _catCounts[cat] = false })
        a.codes.forEach(c => {
          // Do some housekeeping (delete not existing codes from answers)
          if (!(c in getters.codeID2Cat) || !(c in counts)) {
            commit('removeCodeFromAnswer', { answer: a, codeIdx: a.codes.indexOf(c) })
            commit('addToUnsaved', { answer: a })
          } else {
            counts[c] += _ansWeight
            _catCounts[getters.codeID2Cat[c]] = true
          }
        })
        _.forEach(_catCounts, (hasCode, cat) => { catCounts[cat] += hasCode * _ansWeight })
      })

      commit('setCodeCounts', { counts, catCounts })
      commit('setStats', {
        nreviewed: nreviewed || state.question.nreviewed,
        nreviewedUnique,
        nchanged,
        nchangedUnique,
        nnocodes,
        nnocodesUnique })
    },

    /** =========== STATISTICS actions =========== **/
    mutateDialogOpen: ({ commit }, val) => {
      if (val) commit('dialogOpened')
      else commit('dialogClosed')
    }
  },

  modules: {
    registerManager,
    metaManager,
    configManager,
    verbatimManager,
    questionManager,
    verbatimDialog
  }
}
