import {
  PROJECT_NAME_DEMO_OPEN,
  PROJECT_NAME_DEMO_LIST,
  DASHBOARD_NAME_DEMO,
  CHART_NAME_DEMO
} from '@/settings/constants'

export default {
  name: 'filterDemoDataMixin',
  codit: true,

  methods: {
    /**
     * Return true if this project is not a demo project of another user
     * @param  {Object}  p Project
     * @return {Boolean}
     */
    isNotForeignDemoProject (p) {
      if ((p.name.startsWith(PROJECT_NAME_DEMO_OPEN) || p.name === PROJECT_NAME_DEMO_LIST) && p.owner_id !== this.user.id) {
        return false
      } else return true
    },

    /**
     * Return true if this dashboard is not a demo dashboard of another user
     * @param  {Object}  d Dashboard
     * @return {Boolean}
     */
    isNotForeignDemoDashboard (d) {
      // Don't show demo projects not belonging to the user itself. That's just clutter.
      if (d.name === DASHBOARD_NAME_DEMO && d.owner_id !== this.user.id) {
        return false
      } else return true
    },

    /**
     * Return true if this chart is not a demo chart of another user
     * @param  {Object}  c Chart
     * @return {Boolean}
     */
    isNotForeignDemoChart (c) {
      // Don't show demo projects not belonging to the user itself. That's just clutter.
      if (c.name.startsWith(CHART_NAME_DEMO) && c.owner_id !== this.user.id) {
        return false
      } else return true
    }
  }
}