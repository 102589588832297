import Vue from 'vue'

import { MAX_ANSWERS_PER_SURVEY, MAX_UPLOAD_FILESIZE_MB, ACCEPT_UPLOAD_FILETYPES } from '@/settings/overridable'
import {
  COLUMN_TYPE_QUESTION,
  MIN_PROJECT_NAME_LEN,
  COLUMN_TYPE_AUXILIARY,
  COLUMN_TYPE_DATE,
  COLUMN_TYPE_LANGUAGE,
  DEFAULT_INTEGRATION_LINK,
  INTEGRATION_CONSISTENCY_LIMIT_COUNT,
  MAX_PROJECT_ROWS_COUNT_FOR_FAST_APPEND
} from '@/settings/constants'

import { validateQuestionName, validateQuestionWording } from '@/utils/validators'
import { omitDeep, truncateMiddle, isExcelFile } from '@/utils/funcs'
import { parseApiDate } from '@/utils/filters'

const DEFAULT_FILTERS = {
  search: '',
  allSelected: true,
  type: {
    [COLUMN_TYPE_QUESTION]: false,
    string: false,
    number: false,
    [COLUMN_TYPE_LANGUAGE]: false,
    null: false,
    date: false
  }
}

const DEFAULT_ANSWERS_RES = {
  column_metas: [],
  n_rows: 0,
  type: null,
  error: null,
  appendError: null,
  replaceError: null,
  warn: null,
  mismatchedColumnNames: [],
  success: false,
  dropped: 0,
  integrationId: null,
  n_credits: 0
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  state () {
    return {
      append: false,
      replace: false,
      uploadedFileId: null,
      answersInput: {
        tab: 'file',
        input: '',
        file: [],
        integration: null,
        integrationLinks: [DEFAULT_INTEGRATION_LINK],
        hasHeader: true,
        encoding: 'Auto',
        sheetName: null,
        uploadIdenticalAnswers: false,
        search: '',
        matchingAuxilary: undefined,
        account: null,
        survey: null,
        creditsConfirmed: false,
        deductionInfoHover: false
      },
      answersRes: DEFAULT_ANSWERS_RES,
      filters: DEFAULT_FILTERS,
      project: {
        name: '',
        language: window.language,
        labels: [],
        questions: [],
        translated: 0,
        translation_engine: 'GT',
        limit: 50000,
        start_date: null,
        end_date: null,
        brandwatch_filters: {
          pageType: null,
          accountType: null
        }
      },
      projectsInheritable: [],
      loading: {
        active: false,
        updatingActive: false,
        qualtricsIntegrationActive: false,
        percentage: null,
        eta: '',
        failed: null
      },
      matching: {
        n_added: null,
        n_matches: null
      },
      replacing: {
        ds_modified_stats: {
          changed: [],
          removed: []
        },
        success: null
      }
    }
  },

  mutations: {
    toggleAllFilterType (state) {
      state.filters = {
        ...DEFAULT_FILTERS,
        allSelected: !state.filters.allSelected,
        search: state.filters.search
      }
    },

    toggleFilterType (state, type) {
      state.filters = {
        search: state.filters.search,
        allSelected: false,
        type: {
          ...state.filters.type,
          [type]: !state.filters.type[type]
        }
      }
    },

    setAnswersInput (state, input) {
      state.answersInput = {
        ...state.answersInput,
        input
      }
    },

    setInputTab (state, tab) {
      state.answersInput = {
        ...state.answersInput,
        tab,
        file: [],
        input: '',
        integration: null,
        integrationLinks: [DEFAULT_INTEGRATION_LINK]
      }

      state.answersRes = DEFAULT_ANSWERS_RES
    },

    setIntegration (state, integration) {
      state.answersInput = {
        ...state.answersInput,
        integration
      }
    },

    setIntegrationLinks (state, integrationLinks) {
      state.answersInput = {
        ...state.answersInput,
        integrationLinks: integrationLinks.map(l => ({
          ...l,
          external_id: typeof l.external_id === 'string' ? l.external_id.replace(/[\u200B-\u200D\uFEFF]/g, '') : ''
        }))
      }
    },

    setUploadIdenticalAnswers (state, value) {
      state.answersInput = {
        ...state.answersInput,
        uploadIdenticalAnswers: value
      }
    },

    setMatching (state, matching) {
      state.matching = {
        ...state.matching,
        ...matching
      }
    },

    setReplacing (state, replacing) {
      state.replacing = {
        ...state.replacing,
        ...replacing
      }
    },

    setSearch (state, search) {
      state.answersInput = {
        ...state.answersInput,
        search
      }
    },

    setCreditsConfirmed (state, creditsConfirmed) {
      state.answersInput = {
        ...state.answersInput,
        creditsConfirmed
      }
    },

    setDeductionHover (state, deductionInfoHover) {
      state.answersInput = {
        ...state.answersInput,
        deductionInfoHover
      }
    },

    setProject (state, project) {
      state.project = project
    },

    setAppend (state, value) {
      state.append = value
    },

    setReplace (state, value) {
      state.replace = value
    },

    setProjectValue (state, { key, value }) {
      _.set(state.project, key, value)
    },

    setAnswersResValue (state, { key, value }) {
      _.set(state.answersRes, key, value)
    },

    setProjectsInheritable (state, projects) {
      state.projectsInheritable = projects
    },

    setLoading (state, { key, value }) {
      state.loading = {
        ...state.loading,
        [key]: value
      }
    },

    setFileHeader (state, hasHeader) {
      state.answersInput = {
        ...state.answersInput,
        hasHeader
      }
    },

    setFile (state, file) {
      state.uploadedFileId = null

      state.answersInput = {
        ...state.answersInput,
        file
      }
    },

    setAccount (state, account) {
      state.answersInput = {
        ...state.answersInput,
        account
      }
    },

    setSurvey (state, survey) {
      state.answersInput = {
        ...state.answersInput,
        survey
      }
    },

    setAnswers (state, answers) {
      state.answersRes = {
        ...state.answersRes,
        ...answers
      }
    },

    setIntegrationLinkInterval (state, interval) {
      if (!interval) clearInterval(state.answersInput.integrationLinkProgressInterval)

      state.answersInput = {
        ...state.answersInput,
        integrationLinkProgressInterval: interval
      }
    },

    setIntegrationId (state, integrationId) {
      state.answersRes = {
        ...state.answersRes,
        integrationId
      }
    },

    setUploadedFileId (state, uploadedFileId) {
      state.uploadedFileId = uploadedFileId
    },

    setAnswersError (state, error) {
      state.answersRes = {
        ...state.answersRes,
        error
      }
    },

    setAppendError (state, error) {
      state.answersRes = {
        ...state.answersRes,
        appendError: error
      }
    },

    setReplaceError (state, error) {
      state.answersRes = {
        ...state.answersRes,
        replaceError: error
      }
    },

    setAnswersWarn (state, warn) {
      state.answersRes = {
        ...state.answersRes,
        warn
      }
    },

    setAnswersSuccess (state, success) {
      state.answersRes = {
        ...state.answersRes,
        success
      }
    },

    setColumn (state, { index, column }) {
      Vue.set(state.answersRes.column_metas, index, column)
    },

    setSheetName (state, sheetName) {
      state.answersInput = {
        ...state.answersInput,
        sheetName
      }
    },

    setEncoding (state, encoding) {
      state.answersInput = {
        ...state.answersInput,
        encoding
      }
    },

    setMatchingAuxilary (state, matchingAuxilary) {
      state.answersInput = {
        ...state.answersInput,
        matchingAuxilary
      }
    },

    setCredits (state, credits) {
      state.answersRes = {
        ...state.answersRes,
        n_credits: credits
      }
    }
  },

  getters: {
    creditsToDeduct: (state, getters) => {
      return getters.isIntegration ? (_.min([state.project.limit, getters.nRows]) * (state.append ? getters.questionsToAppend.length : getters.questionColumns.length)) : state.answersRes.n_credits
    },

    isIntegration: (state) => {
      return state.answersInput.tab === 'integration'
    },

    isQualtricsIntegration: (state) => {
      return state.answersInput.integration?.provider === 'qualtrics'
    },

    isZapierIntegration: (state) => {
      return state.answersInput.integration?.provider === 'zapier'
    },

    isBrandwatchIntegration: (state) => {
      return state.answersInput.integration?.provider === 'brandwatch'
    },

    qualtricsIntegrationInProgress: (state) => {
      return state.loading.qualtricsIntegrationActive
    },

    validIntegrate: (state) => {
      if (!state.answersInput.integrationLinks || !state.answersInput.integrationLinks.length) return false
      if (!state.answersInput.integrationLinks.filter(link => link.external_id !== '').length) return false
      if (_.find(state.answersInput.integrationLinks, link => link.duplicate_error)) return false
      if (state.answersRes.integrationId && !state.answersInput.integrationLinks.filter(link => link.external_id !== '').filter(link => link.sync_status !== 'complete').length) return false

      return true
    },

    validQualtricsIntegrate: (state) => {
      if (!state.answersInput.account) return false
      if (!state.answersInput.survey) return false
      return true
    },

    integrationInProgress: (state) => {
      return !!_.find(state.answersInput.integrationLinks, link => link.sync_status === 'in_progress')
    },

    hasValidIntegration: (state) => {
      return !!_.find(state.answersInput.integrationLinks, link => link.sync_status === 'complete')
    },

    hasInvalidIntegration: (state) => {
      return !!_.find(state.answersInput.integrationLinks, link => link.sync_status === 'failed')
    },

    hasInvalidLinkInIntegration: state => {
      return !!_.find(state.answersInput.integrationLinks, link => link.sync_error === 'invalid_item')
    },

    canContinueDespiteTimeout: (state, getters) => {
      return _.every(state.answersInput.integrationLinks, link => {
        return link.sync_status === 'complete' || (link.sync_status === 'failed' && link.sync_error === 'timeout')
      })
    },

    questionsWithErrors: (state, getters) => {
      return getters.questionColumns.filter(({ questionName, questionWording }) =>
        (questionName.error || questionWording.error)
      )
    },

    validAppendUpload: (state, getters) => {
      return getters.validUpload ||
        (state.answersInput.file.length &&
        isExcelFile(state.answersInput.file[0].type) &&
        state.answersRes.sheet_names && state.answersRes.sheet_names.length > 1 &&
        state.answersRes.error === 'steps.organize.auxiliary_columns_count_missmatch')
    },

    validReplaceUpload: (state, getters) => {
      return getters.validUpload ||
        (state.answersInput.file.length &&
        isExcelFile(state.answersInput.file[0].type) &&
        state.answersRes.sheet_names && state.answersRes.sheet_names.length > 1 &&
        state.answersRes.error === 'steps.replace.rows_not_matching')
    },

    validReplace: (state, getters) => {
      return getters.validUpload && state.replacing.success && !state.answersRes.replaceError
    },

    validAppend: (state, getters) => {
      if (
        state.append &&
        !getters.isFastAppendAvailable &&
        !getters.isNoFastAppendRulesFollowed
      ) return false

      if (state.matching.n_added < 1 && getters.nRows < 1) return false
      if (getters.questionsToAppend.length !== state.project.questions.length) return false
      if (state.answersRes.error) return false
      if (state.answersRes.appendError) return false

      return true
    },

    validUpload: state => {
      if (state.loading.active) return false
      if (state.answersRes.error) return false
      if (state.answersInput.tab === 'input') return state.answersInput.input.length > 0
      if (!state.answersInput.file.length) return false

      return state.answersRes.success
    },

    validIntegrationUpload: (state, getters) => {
      if (!state.answersInput.integration || state.answersInput.integration.provider !== 'qualtrics') {
        if (getters.integrationInProgress) return false
        if (!getters.hasValidIntegration && !getters.canContinueDespiteTimeout) return false
        if (getters.hasInvalidLinkInIntegration) return false
      } else {
        if (!state.answersInput.account) return false
        if (!state.answersInput.survey) return false
        if (!state.answersRes.integrationId) return false
        if (getters.qualtricsIntegrationInProgress) return false
      }
      if (state.loading.active) return false
      if (state.answersRes.error) return false
      return true
    },

    isFastAppendAvailable: state => state.project?.n_rows < MAX_PROJECT_ROWS_COUNT_FOR_FAST_APPEND,

    isNoFastAppendRulesFollowed: state => state.answersInput.uploadIdenticalAnswers || typeof state.answersInput.matchingAuxilary !== 'undefined',

    columnsToSave: state => {
      return _.filter(state.answersRes.column_metas, 'save')
    },

    questionColumns: (state, getters) => {
      return _.filter(getters.columnsToSave, { 'semantic_type': COLUMN_TYPE_QUESTION })
    },

    auxilaryColumns: (state, getters) => {
      return _.filter(getters.columnsToSave, ({ semantic_type: type }) => type !== COLUMN_TYPE_QUESTION)
    },

    languageIdentifierColumns: (state, getters) => {
      return _.filter(getters.columnsToSave, ({ semantic_type: type }) => type === COLUMN_TYPE_LANGUAGE)
    },

    auxilaryColumnNames: (state, getters) => {
      return getters.auxilaryColumns.map(({ name }) => name)
    },

    questionNames: (state, getters) => {
      return getters.questionColumns.map(({ questionName }) => questionName && questionName.value)
    },

    validOrganize: (state, getters) => {
      if (state.loading.active) return false

      // Has at least one question
      let hasErrors = false

      _.forEach(getters.questionColumns, ({ questionName, questionWording }) => {
        if (questionName.error || questionWording.error) hasErrors = true
      })

      return getters.questionColumns.length && getters.languageIdentifierColumns.length < 2 && !hasErrors
    },

    validSettings: (state, getters) => {
      return state.project.name.length >= MIN_PROJECT_NAME_LEN && !state.loading.active && (state.answersInput.creditsConfirmed || getters.canContinueDespiteTimeout) && (getters.isQualtricsIntegration ? Number(state.project.limit) >= 0 : (Number(state.project.limit) >= 0 && Number(state.project.limit) <= INTEGRATION_CONSISTENCY_LIMIT_COUNT))
    },

    nRows: (state, getters) => {
      let nanswers = 0

      // If input type is text field, count the number of lines
      if (state.answersInput.tab === 'input') nanswers = state.answersInput.input.split(/\r\n|\r|\n/).length
      // Otherwise either return all rows, or subtract the identical ones (if in append mode and ignore identical is enabled)
      else nanswers = state.answersRes.n_rows - (state.answersInput.uploadIdenticalAnswers ? 0 : state.matching.n_matches)

      return nanswers
    },

    projectUploadData: (state, getters) => {
      const data = {
        ..._.omit(state.project, ['limit', 'brandwatch_filters', 'start_date', 'end_date']),
        upload_id: state.answersRes.id,
        questions: getters.questionColumns.map(({ questionName, questionWording, idx }) => ({
          name: questionName.value,
          description: questionWording.value,
          column_index: idx,
          // Legacy reasons
          codebook: []
        }))
      }

      if (state.answersInput.tab === 'integration') {
        data.include_items = getters.integrationLinks
        data.sync_enabled = !!state.project.sync_enabled
        data.filters = {}
        data.filters.limit = typeof state?.project?.limit !== 'undefined' || getters.isQualtricsIntegration ? +state.project.limit : 50000

        if (state.project.start_date) data.filters.start_date = parseApiDate(state.project.start_date, true)
        if (state.project.end_date) data.filters.end_date = parseApiDate(state.project.end_date, false)
      }

      if (getters.isBrandwatchIntegration) {
        const accountType = state.project.brandwatch_filters.accountType
        const pageType = state.project.brandwatch_filters.pageType

        data.filters.brandwatch_filters = {}
        if (accountType) data.filters.brandwatch_filters.accountType = accountType
        if (pageType) data.filters.brandwatch_filters.pageType = pageType
      }

      return data
    },

    projectAppendData: (state, getters) => {
      return {
        upload_id: state.answersRes.id,
        questions: getters.questionsToAppend.map((question, index) => {
          const appendingQuestion = state.project.questions[index]
          const { id, name } = appendingQuestion

          return {
            id,
            name,
            column_index: question.idx,
            // Legacy reasons
            codebook: []
          }
        })
      }
    },

    integrationLinks: (state) => {
      // eslint-disable-next-line
      return state.answersInput.integrationLinks.map(({ external_id }) => external_id).filter(external_id => external_id !== '')
    },

    questionsToAppend: (state, getters) => {
      return _.filter(getters.columnsToSave, 'isQuestion')
    },

    colsIdentical (state, getters) {
      return _.intersection(getters.auxilaryColumnNames, state.project.auxiliary_column_names)
    },

    colsRemoved (state, getters) {
      return _.difference(state.project.auxiliary_column_names, getters.auxilaryColumnNames)
    },

    colsNew (state, getters) {
      return _.difference(getters.auxilaryColumnNames, state.project.auxiliary_column_names)
    }
  },

  actions: {
    changeSheet ({ commit, dispatch }, sheetName) {
      commit('setSheetName', sheetName)

      dispatch('uploadAnswers')
    },

    changeEncoding ({ commit, dispatch }, value) {
      commit('setEncoding', value)

      dispatch('uploadAnswers')
    },

    changeMatchingAuxilary ({ commit, dispatch }, value) {
      commit('setMatchingAuxilary', value)
      commit('setLoading', { key: 'active', value: true })

      dispatch('appendColumns')
    },

    changeHeaderSettings ({ commit, state, dispatch }) {
      commit('setFileHeader', !state.answersInput.hasHeader)

      dispatch('uploadAnswers')
    },

    changeUploadIdenticalAnswers ({ commit, state, dispatch }) {
      commit('setUploadIdenticalAnswers', !state.answersInput.uploadIdenticalAnswers)
    },

    clearOpenQuestions ({ commit, state, dispatch }) {
      _.map(state.answersRes.column_metas, col => {
        if (col.semantic_type === COLUMN_TYPE_QUESTION) {
          commit('setColumn', {
            index: col.idx,
            column: {
              ...col,
              semantic_type: COLUMN_TYPE_AUXILIARY
            }
          })
        }
      })
    },

    startIntegrationLinkProgressInterval ({ commit, state, dispatch, getters }) {
      commit('setIntegrationLinkInterval', setInterval(() => {
        let counter = 0
        const newLinks = _.cloneDeep(state.answersInput.integrationLinks)

        newLinks.forEach((link, idx) => {
          if (link.sync_status === 'in_progress') {
            newLinks[idx].progress = newLinks[idx].progress + 1 / state.answersInput.integration.preview_timeout
            counter += 1
          }
        })

        commit('setIntegrationLinks', newLinks)

        if (!counter) {
          clearInterval(state.answersInput.integrationLinkProgressInterval)
          commit('setIntegrationLinkInterval', null)
        }
      }, 1000))
    },

    integrateAnswers ({ commit, state, dispatch, getters }) {
      commit('setIntegrationLinks', state.answersInput.integrationLinks.filter(link => link.external_id !== '').map(link => {
        // eslint-disable-next-line
        const { external_id, sync_status: syncStatus } = link

        return {
          ...link,
          external_id,
          sync_status: syncStatus === 'complete' ? syncStatus : 'in_progress',
          progress: 0
        }
      }))

      dispatch('startIntegrationLinkProgressInterval')

      const data = {
        'provider': state.answersInput.integration.provider,
        'items': getters.integrationLinks
      }

      return api.post(`/api/data-sources/`, data, { dontReport: [400] })
        .catch((err) => {
          commit('setIntegrationLinks', state.answersInput.integrationLinks.map(link => {
          // eslint-disable-next-line
            const { external_id, sync_status: syncStatus } = link

            return {
              ...link,
              external_id,
              sync_status: 'failed'
            }
          }))

          commit('setAnswersSuccess', false)
          commit('setAnswersError', 'steps.organize.default')
          this._vm.$maybeRaiseAPIPromiseErr(err)
          throw err
        })
    },

    integrateQualtricsAnswers ({ commit, state, dispatch, getters }) {
      commit('setLoading', { key: 'qualtricsIntegrationActive', value: true })

      const data = {
        provider: state.answersInput.integration.provider,
        integration: state.answersInput.account,
        items: Array.isArray(state.answersInput.survey) ? state.answersInput.survey : [state.answersInput.survey]
      }

      return api.post(`/api/data-sources/`, data, { dontReport: [400] })
        .catch(err => {
          commit('setAnswersSuccess', false)
          commit('setLoading', { key: 'qualtricsIntegrationActive', value: false })
          commit('setAnswersError', 'steps.organize.default')
          this._vm.$maybeRaiseAPIPromiseErr(err)
        })
    },

    getColumnsFromIntegration ({ commit, state, dispatch }, callback) {
      const integrationId = state.answersRes.integrationId

      commit('setAnswersError', null)
      commit('setAnswersWarn', null)
      commit('setSearch', '')
      commit('setLoading', { key: 'active', value: true })

      return api.get(`/api/data-sources/${integrationId}/columns`)
        .then(res => {
          if (res.data.warnings) {
            commit('setAnswersWarn', res.data.warnings)
          }

          commit('setAnswers', res.data)
          dispatch('generateColumnHelpers')
          commit('setAnswersSuccess', true)
          if (callback) callback()
          commit('setLoading', { key: 'active', value: false })
        })
        .catch((err) => {
          commit('setLoading', { key: 'active', value: false })
          commit('setAnswersSuccess', false)
          commit('setAnswersError', 'steps.organize.default')
          this._vm.$maybeRaiseAPIPromiseErr(err)
          throw err
        })
    },

    async uploadAnswers ({ commit, state, getters, dispatch }, callback) {
      const { encoding, hasHeader, sheetName, file: fileArr, tab, input } = state.answersInput

      const file = fileArr[0]

      if (tab === 'file') {
        if (file === undefined || file === null) return

        if (file.size > MAX_UPLOAD_FILESIZE_MB * 1024 * 1024) {
          commit('setAnswersError', 'steps.upload.error_file_too_big')
          return
        }
        if (!_.includes(ACCEPT_UPLOAD_FILETYPES, file.type)) {
          commit('setAnswersError', 'steps.upload.error_file_type')
          commit('setFile', [])
          return
        }
      }

      commit('setAnswersError', null)
      commit('setAnswersWarn', null)
      commit('setSearch', '')
      commit('setLoading', { key: 'active', value: true })

      let body

      if (tab !== 'file') {
        body = new FormData()
        body.append('string', input)
        if (encoding) body.append('encoding', encoding)
        if (hasHeader) body.append('has_header', hasHeader)
        if (sheetName) body.append('sheet_name', sheetName)
      } else {
        if (!state.uploadedFileId) {
          const id = await api.post(`/api/ui/projects/upload-direct`, {
            file_name: file.name,
            mime_type: file.type
          }).then(async res => {
            const { url, id: fileUploadId, file_upload_headers: headers } = res.data

            await fetch(url, {
              method: 'PUT',
              body: file.file,
              headers
            })

            // start long polling to get the upload status
            return new Promise((resolve, reject) => {
              const interval = setInterval(() => {
                api.get(`/api/ui/projects/upload-direct/${fileUploadId}`).then(statusRes => {
                  if (statusRes.data.status === 'uploading') return
                  clearInterval(interval)

                  if (statusRes.data.status === 'ready-to-parse') {
                    resolve(statusRes.data.id)
                  } else if (statusRes.data.status === 'quarantined') {
                    commit('setAnswers', DEFAULT_ANSWERS_RES)
                    commit('setAnswersError', 'steps.organize.quarantined')
                    commit('setLoading', { key: 'active', value: false })

                    commit('setAnswersSuccess', false)
                    reject(new Error('File is quarantined'))
                  }
                })
              }, 500)
            })
          })

          commit('setUploadedFileId', id)
        }

        body = {
          fileupload_id: state.uploadedFileId,
          has_header: hasHeader || false
        }

        if (encoding) body.encoding = encoding
        if (sheetName) body.sheet_name = sheetName
      }

      return api.post(`/api/upload/${!(tab === 'file') ? '?force_question=True' : ''}`, body, { dontReport: [400], timeout: 400000 }).then(res => {
        if (res.data.warnings) {
          commit('setAnswersWarn', res.data.warnings)
        }

        if (res.status >= 400) {
          commit('setAnswers', DEFAULT_ANSWERS_RES)
          commit('setAnswersError', res.detail || res.data[Object.keys(res.data)[0]] || 'steps.organize.default')
          commit('setLoading', { key: 'active', value: false })
          commit('setAnswersSuccess', false)
        } else if (res.data.n_rows > MAX_ANSWERS_PER_SURVEY) {
          commit('setAnswersError', 'steps.organize.too_many')
          commit('setAnswersSuccess', false)
          commit('setLoading', { key: 'active', value: false })
        } else {
          // Validation
          if (state.append) {
            // Check that the number of columns of the uploaded files matches that of the project
            const nColsOld = state.project.auxiliary_column_names.length + state.project.questions.length
            const nColsNew = res.data.column_metas.length

            if (nColsOld !== nColsNew) {
              commit('setAnswers', res.data)
              commit('setAnswersError', 'steps.organize.auxiliary_columns_count_missmatch')
              commit('setAnswersSuccess', false)
              commit('setLoading', { key: 'active', value: false })
              return
            }
          }

          if (state.replace) {
            const nRowsNew = res.data.n_rows
            const nRowsOld = state.project.n_rows

            // TODO: remove the - 1 once project saves info if the original upload used header column
            if (nRowsNew !== nRowsOld && nRowsNew + 1 !== nRowsOld && nRowsNew - 1 !== nRowsOld) {
              commit('setAnswers', res.data)
              commit('setAnswersError', 'steps.replace.rows_not_matching')
              commit('setAnswersSuccess', false)
              commit('setLoading', { key: 'active', value: false })

              return
            }
          }

          if (!state.answersRes.error) {
            commit('setAnswers', res.data)
            dispatch('generateColumnHelpers')
            commit('setAnswersSuccess', true)

            if (
              state.append &&
              !getters.isFastAppendAvailable &&
              !state.answersInput.uploadIdenticalAnswers &&
              typeof state.answersInput.matchingAuxilary === 'undefined'
            ) {
              commit('setLoading', { key: 'active', value: false })
            } else if (
              state.append
            ) {
              dispatch('appendColumns')
            } else if (state.replace) {
              const existingQuestionColumnNames = _.map(state.project.questions, q => q.name)

              res.data.column_metas.forEach(column => {
                if (!existingQuestionColumnNames.includes(column.name)) {
                  commit('setColumn', { index: column.idx,
                    column: {
                      ...column,
                      semantic_type: column.semantic_type === COLUMN_TYPE_QUESTION ? COLUMN_TYPE_AUXILIARY : column.semantic_type,
                      alternative_semantic_types: column.alternative_semantic_types.filter(type => type !== COLUMN_TYPE_QUESTION)
                    } })
                }
              })

              dispatch('replaceColumns', true)
            } else {
              commit('setAnswersSuccess', true)
              if (callback) callback()
              commit('setLoading', { key: 'active', value: false })
            }
          }
        }
      }).catch((err) => {
        commit('setLoading', { key: 'active', value: false })
        commit('setAnswersSuccess', false)
        commit('setAnswersError', 'steps.organize.default')
        this._vm.$maybeRaiseAPIPromiseErr(err)
        throw err
      })
    },

    replaceColumnsAsync ({ commit, state, getters }, asyncCallback) {
      commit('setLoading', { key: 'active', value: true })
      commit('setLoading', { key: 'failed', value: null })
      commit('setLoading', { key: 'percentage', value: null })

      return api.post(`/api/upload/${state.answersRes.id}/projects/${state.project.id}/replace-auxiliaries-async?no_match_existing=${_.capitalize(state.answersInput.uploadIdenticalAnswers)}`,
        {},
        { dontReport: [400] })
        .then((res) => {
          asyncCallback(res)
        })
        .catch((err) => {
          commit('setLoading', { key: 'active', value: false })
          commit('setLoading', { key: 'failed', value: 'error_replacing_columns' })

          this._vm.$maybeRaiseAPIPromiseErr(err)
        })
    },

    replaceColumns ({ commit, state, getters }, dry = true) {
      if (!dry) {
        commit('setLoading', { key: 'active', value: true })
      }

      return api.post(
        `/api/upload/${state.answersRes.id}/projects/${state.project.id}/replace-auxiliaries?dry_run=${_.capitalize(dry)}&no_match_existing=${_.capitalize(state.answersInput.uploadIdenticalAnswers)}`,
        {},
        { dontReport: [400] }
      ).then(res => {
        if ((res.data && res.data.error) || res.status === 400) {
          commit('setLoading', { key: 'active', value: false })
          commit('setLoading', { key: 'updatingActive', value: false })
          commit('setReplacing', res.data)
          commit('setReplaceError', res.data.error || res.data[Object.keys(res.data)[0]])

          return
        } else {
          commit('setReplaceError', null)
        }

        commit('setReplacing', res.data)
        commit('setLoading', { key: 'active', value: false })
        commit('setLoading', { key: 'updatingActive', value: false })

        return res
      }).catch((err) => {
        commit('setLoading', { key: 'active', value: false })
        commit('setLoading', { key: 'updatingActive', value: false })
        commit('setLoading', { key: 'failed', value: 'error_replacing_columns' })

        if (!dry) {
          this._vm.$maybeRaiseAPIPromiseErr(err)
        }

        throw err
      })
    },

    appendColumns ({ commit, state, getters }, dry = true) {
      if (!dry) {
        commit('setLoading', { key: 'active', value: true })
      }

      return api.post(
        `/api/upload/${state.answersRes.id}/projects/${state.project.id}/rows?dry_run=${_.capitalize(dry)}&no_match_existing=${_.capitalize(state.answersInput.uploadIdenticalAnswers)}${state.answersInput.matchingAuxilary !== undefined ? `&matching_auxiliary_column_index=${state.answersInput.matchingAuxilary}` : ''}`,
        getters.projectAppendData,
        { dontReport: [400] }
      ).then(res => {
        if ((res.data && res.data.error) || res.status === 400) {
          commit('setAppendError', res.data.error || res.data[Object.keys(res.data)[0]])
          commit('setLoading', { key: 'active', value: false })
          commit('setLoading', { key: 'updatingActive', value: false })
          commit('setCredits', 0)
          return
        } else {
          commit('setAppendError', null)
        }

        if (dry) {
          commit('setMatching', res.data)
          commit('setLoading', { key: 'active', value: false })
          commit('setLoading', { key: 'updatingActive', value: false })
        }

        if (res.data.n_credits) commit('setCredits', res.data.n_credits)

        return res
      }).catch((err) => {
        commit('setLoading', { key: 'active', value: false })
        commit('setLoading', { key: 'updatingActive', value: false })
        commit('setLoading', { key: 'failed', value: 'error_appending_columns' })

        if (!dry) {
          this._vm.$maybeRaiseAPIPromiseErr(err)
        }

        throw err
      })
    },

    updateUpload ({ commit, state, dispatch }, callback) {
      // eslint-disable-next-line
      const { id, column_metas } = state.answersRes

      commit('setLoading', { key: 'updatingActive', value: true })

      // Now remove helpers, possibly separate into different function ?

      // get rid of the helper keys
      // omitDeep creates a copy internally before mutating object
      let data = omitDeep(column_metas, ['questionName', 'questionWording'])

      // 1) Remove selected column type from alternative_semantic_types
      // 2) Change all column's semantic_type of type date to the first alternative_semantic_type

      // TODO, handle changing date column, to something else.
      data = _.map(data, column => ({
        ...column,
        alternative_semantic_types: _.filter(column.alternative_semantic_types, type => type !== column.semantic_type),
        semantic_type: column.type === 'date' ? COLUMN_TYPE_AUXILIARY : column.semantic_type
      }))

      return api.patch(
        state.answersInput.tab === 'integration' ? `/api/data-sources/${state.answersRes.integrationId}/columns` : `/api/upload/${id}`,
        JSON.stringify({ column_metas: data }),
        { dontReport: [400] }
      )
        .then(res => {
          commit('setLoading', { key: 'updatingActive', value: false })

          if ((res.data && res.data.error) || res.status === 400) {
            commit('setAnswersError', res.data.error || res.data[Object.keys(res.data)[0]] || 'steps.settings.error_save_project')
            return
          } else {
            commit('setAnswersError', null)
          }

          // Update the column metas, mainly for the language_distribution key which might haved changed
          res.data.column_metas.forEach((column, index) => commit('setColumn', {
            column: { ...column_metas[index], ...column },
            index
          })) // eslint-disable-camelcase

          commit('setCredits', res.data.n_credits)

          if (callback) {
            return callback()
          }
        })
        .catch(err => {
          commit('setLoading', { key: 'updatingActive', value: false })
          commit('setAnswersError', 'steps.organize.default')
          this._vm.$maybeRaiseAPIPromiseErr(err)
          throw err
        })
    },

    saveProject ({ commit, state, getters }, asyncCallback) {
      commit('setLoading', { key: 'active', value: true })
      commit('setLoading', { key: 'failed', value: null })
      commit('setLoading', { key: 'percentage', value: null })

      return api.post(state.answersInput.tab === 'integration'
        ? `/api/data-sources/${state.answersRes.integrationId}/import`
        : `/api/upload/${state.answersRes.id}/projects?async`, getters.projectUploadData, { dontReport: [400] }
      )
        .then(res => {
          if (res.data.id) {
            asyncCallback(state.answersInput.tab === 'integration' ? res.data.id : res)
          } else {
            commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
            commit('setLoading', { key: 'active', value: false })
          }
        })
        .catch((err) => {
          commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
          commit('setLoading', { key: 'active', value: false })

          throw err
        })
    },

    fetchProject ({ commit, state, getters }, id) {
      commit('setLoading', { key: 'active', value: true })
      commit('setLoading', { key: 'failed', value: null })
      commit('setLoading', { key: 'percentage', value: null })

      return api.get(`/api/projects/${id}`)
        .then(res => {
          commit('setProject', res.data)
          commit('setLoading', { key: 'active', value: false })

          return res.data
        })
        .catch(err => {
          commit('setLoading', { key: 'failed', value: 'steps.upload.error_loading_project' })
          commit('setLoading', { key: 'active', value: false })

          throw err
        })
    },

    generateColumnHelpers ({ commit, state, getters, dispatch }) {
      const columns = state.answersRes.column_metas

      if (!columns.length) return

      if (state.append) {
        // Validate column names here, since the question names can be
        // changed, only compare auxiliary columns
        let sameNamesDetected = true

        state.project.auxiliary_column_names.forEach(name => {
          if (!_.find(state.answersRes.column_metas, { name })) {
            sameNamesDetected = false
            commit('setAnswersResValue', { key: 'mismatchedColumnNames', value: [...state.answersRes.mismatchedColumnNames, name] })
          }
        })

        if (sameNamesDetected) {
          _.map(columns, (column, index) => {
            const isQuestion = _.includes([column.semantic_type, ...column.alternative_semantic_types], COLUMN_TYPE_QUESTION) ? !!_.find(state.project.questions, q => q.name === column.name) : undefined
            commit('setAnswersResValue', { key: 'mismatchedColumnNames', value: [] })

            return (
              commit(
                'setColumn',
                {
                  index,
                  column: {
                    ...column,
                    isQuestion,
                    semantic_type: isQuestion ? column.semantic_type : COLUMN_TYPE_AUXILIARY,
                    alternative_semantic_types: !isQuestion ? [...column.alternative_semantic_types, COLUMN_TYPE_QUESTION] : column.alternative_semantic_types
                  }
                }
              )
            )
          })
        } else {
          commit('setAnswersWarn', 'steps.append.auxiliary_columns_name_missmatch')
        }

        dispatch('updateUpload')
      } else {
        _.map(columns, (column, index) =>
          commit(
            'setColumn',
            {
              index,
              column: {
                ...column,
                /**
                 * This needs to be handled in update
                 */
                semantic_type: column.type === 'date' ? COLUMN_TYPE_DATE : column.semantic_type,
                /*
                  When updating the upload via api, column.semantic_type
                  will need to be removed from alternative_semantic_types
                */
                alternative_semantic_types: [
                  ...column.alternative_semantic_types,
                  column.semantic_type
                ],
                questionName: {
                  value: truncateMiddle(column.name, 50),
                  error: !column.sample_data.length ? false : validateQuestionName(truncateMiddle(column.name, 50), getters.questionNames)
                },
                questionWording: {
                  value: truncateMiddle(column.description || column.name, 255),
                  error: !column.sample_data.length ? false : validateQuestionWording(truncateMiddle(column.description || column.name, 255))
                }
              }
            }
          )
        )
      }
    }
  }
}
