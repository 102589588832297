export const blankChart = {
  type: 'bar',
  datasets: [{
    settings: {
      weighting_column: null,
      weighting_normalize: false,
      name: ', ',
      driver_target_column: null,
      scoring_type: null,
      scoring_column: null,
      color: { override: false, value: 'rgba(255,0,0,1.0)' }
    },
    filters: [],
    status: { ready: false },
    question: '',
    answers: [],
    result: []
  }],
  config: {}
}