<template>
  <div>
    <div class="d-flex justify-space-between ma-2" :class="{ 'pb-2 mb-0': !showPicker }">
      <div class="filters__list__date-range-input">
        <div class="filters__list__label pl-0">
          {{ $t('filters.from') }}
        </div>
        <v-text-field
          v-model="internalModel[0]"
          v-mask="datePickerFocusTarget === 0 ? isoDateMask(internalModel[0]) : undefined"
          single-line
          class="search-bar__search w-100"
          dense
          outlined
          :elevation="0"
          label="1970-01-01"
          @focus="datePickerFocusTarget = 0"
          @blur="datePickerFocusTarget = -1; internalModel[0] = safeInternalModelFrom"
          :error="!safeInternalModelFrom"
          hide-details
        />
      </div>
      <div class="filters__list__date-range-input">
        <div class="filters__list__label pl-0">
          {{ $t('filters.to') }}
        </div>
        <v-text-field
          v-model="internalModel[1]"
          v-mask="datePickerFocusTarget === 1 ? isoDateMask(internalModel[1]) : undefined"
          single-line
          class="search-bar__search w-100"
          dense
          outlined
          :elevation="0"
          :label="isoToday"
          @focus="datePickerFocusTarget = 1"
          @blur="datePickerFocusTarget = -1; internalModel[1] = safeInternalModelTo"
          :error="!safeInternalModelTo"
          hide-details
        />
      </div>
    </div>
    <v-date-picker
      v-if="showPicker"
      v-model="safeInternalModel"
      :picker-date="datePickerFocus"
      class="filters__list__date-picker w-100"
      :elevation="0"
      color="green"
      :events="events"
      flat
      range
    />
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
  name: 'DateRangeFilter',

  directives: {
    mask: VueMaskDirective
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    // external model value
    value: {
      type: Array,
      default: () => {
        let isoToday = moment.utc(new Date()).local().format('YYYY-MM-DD')

        return { kind: 'date_range', value: [isoToday, isoToday] }
      },
      required: true
    },
    events: { type: Array, default: () => ([]) },
    showPicker: { type: Boolean, default: false }
  },

  data () {
    let isoToday = this.$options.filters.shortiso(new Date())
    return {
      internalModel: [isoToday, isoToday],
      manualModel: [isoToday, isoToday],
      datePickerFocusTarget: -1,
      isoToday
    }
  },

  computed: {
    /**
     * calculate calendar focus to force date-picker jump to target month when manual fields are focused
     * @returns {String} Date in ISO date format (YYYY-MM-DD)
     */
    datePickerFocus () {
      if (!this.showPicker || this.datePickerFocusTarget === -1) return undefined
      let focus = this.internalModel[this.datePickerFocusTarget] || ''
      const [y0 = ''] = this.isoToday.split('-')
      const [y = '', m = '', d = ''] = focus.split('-')
      return ([
        y && y.length === 4 ? y : y0,
        m && m !== '0' ? m : '01',
        d && d !== '0' ? d : '01'
      ]).join('-')
    },

    safeInternalModelFrom () {
      return (this.internalModel[0] || '').match(/\d{4}-\d{2}-\d{2}/) ? this.internalModel[0] : ''
    },

    safeInternalModelTo () {
      return (this.internalModel[1] || '').match(/\d{4}-\d{2}-\d{2}/) ? this.internalModel[1] : ''
    },

    safeInternalModel: {
      get () {
        if (this.safeInternalModelTo.length && this.safeInternalModelFrom.length) return [this.safeInternalModelFrom, this.safeInternalModelTo]
        if (this.safeInternalModelFrom.length && !this.safeInternalModelTo.length) return [this.safeInternalModelFrom]
        if (this.safeInternalModelTo.length && !this.safeInternalModelFrom.length) return [this.safeInternalModelTo]
        return []
      },
      set (model) {
        this.internalModel = model
      }
    }
  },

  /**
   * duplexed model watchers to syncronize internal and external v-model(s)
   */
  watch: {
    value: {
      deep: true,
      handler (v) {
        if (!_.isEqual(this.internalModel, v)) {
          this.internalModel = [...v]
        }
      }
    },
    safeInternalModel: {
      deep: true,
      handler (v) {
        let value = v.filter(a => !!a)

        if (value.length === 2) {
          if (this.compareTime(value[0], value[1])) {
            value = [value[1], value[0]]
            this.safeInternalModel = value
          }

          this.$emit('input', value)
        }
      }
    }
  },

  mounted () {
    this.internalModel = this.value
  },

  methods: {
    // Check if time 2 is after time 1
    compareTime (time1, time2) {
      return new Date(time1) > new Date(time2) // true if time1 is later
    },

    /**
     * calculate iso mask for iso date field based on current value
     * @returns {Array<RegExp, String>} mask for valid dates between 1900~2100 in ISO date format (YYYY-MM-DD)
     */
    isoDateMask (v = '') {
      v = v || ''
      // gets YYYY from ISO date format (YYYY-MM-DD)
      let YYYY = +v.substring(0, 4)
      // gets MM from ISO date format (YYYY-MM-DD)
      let MM = +v.substring(5, 7)
      // calculate last day of selected month from YYYY year and MM month
      let maxDD = moment().year(YYYY).month(MM - 1).endOf('month').date()
      // split last day of selected month to digits
      let [mh, ml] = String(String(maxDD)).split('')
      return [
        // possible values for ? in ?YYY-MM-DD
        /[1-2]/,
        // possible values for ? in Y?YY-MM-DD
        /\d/,
        // possible values for ? in YY?Y-MM-DD
        /\d/,
        // possible values for ? in YYY?-MM-DD
        /\d/,
        // seperator
        '-',
        // possible values for ? in YYYY-?M-DD
        /[0-1]/,
        // possible values for ? in YYYY-M?-DD
        v.charAt(5) === '1' ? /[0-2]/ : /[1-9]/,
        // seperator
        '-',
        // possible values for ? in YYYY-MM-?D
        new RegExp(`[0-${mh}]`),
        // possible values for ? in YYYY-MM-D?
        v.charAt(8) === '0' ? /[1-9]/ : v.charAt(8) === mh ? new RegExp(`[0-${ml}]`) : /\d/
      ]
    }
  }
}
</script>

<style lang="scss">
.filters__list {
  &__date-picker {
    display: block;
    max-width: 320px;
    margin: 0 auto;

    .v-picker__title {
      display: none;
    }
    .v-picker__body {
      width: 100% !important;
      margin: 0;
    }
    .v-date-picker-table {
      .v-btn--rounded {
        border-radius: 5px;
      }
    }
  }
  &__actions {
    background: rgba(243, 247, 249, 0.5);
  }
  &__menu {
    background: rgba(243, 247, 249, 0.5);
    .h-100-after_footer {
      height: calc(100% - 57px);
      background: white;
    }
  }
  &__date-range-input {
    width: 47%;
  }
}
</style>

<i18n locale='en' src='@/i18n/en/pages/ProjectList.json' />
<i18n locale='de' src='@/i18n/de/pages/ProjectList.json' />
