<template>
  <div class="w-100 d-flex align-center flex-column step-settings">
    <h1 class="font-weight-medium text-center">
      {{ $t('steps.settings.label') }}
    </h1>
    <div class="grey--text text--darken-1 mb-5 text-center mr-0">
      {{ $t('steps.settings.subtitle') }}
    </div>
    <div v-if="loading.active" class="upload-settings upload-settings-form mb-5 mt-2">
      <loading
        v-model="loading.percentage"
        :is-loading="true"
        :dont-animate-entry="true"
        :title="$t('processing')"
        :tagline="loading.eta"
      />
    </div>
    <div v-else class="w-100 d-flex align-center flex-column">
      <div
        :class="loading.active && 'upload-settings-form--disabled'"
        class="upload-settings upload-settings-form mb-5 mt-2"
      >
        <notification
          v-if="loading.failed"
          type="error"
          border="left"
          text
          class="mb-6"
        >
          {{ $t(loading.failed) }}
        </notification>

        <label class="font-weight-medium text-label text-xs">
          {{ $t('steps.settings.name') }}
        </label>
        <v-text-field
          :value="project.name"
          :disabled="loading.active"
          @input="value => {
            handleProjectChange('name', value)
            checkProjectNameDebounced()
          }"
          :placeholder="$t('steps.settings.name')"
          :error="errors.name.length > 0"
          :error-messages="errors.name"
          class="search-bar__search search-bar__search--large search-bar__search--wide mb-3"
          required
          counter
          outlined
          dense
          maxlength="50"
        />

        <tag-select
          class="save-labels mb-3"
          :value="clonedLabels"
          :on-change="value => handleProjectChange('labels', value)"
          :sync="true"
          :options="labelOptions"
          :disabled="loading.active"
          allow-new
          dense
          :placeholder="$t('steps.settings.labels_placeholder')"
          max-height="250px"
          @new-label="saveAttachLabel"
        />
        <label class="font-weight-medium text-label text-xs">
          {{ $t('steps.settings.language') }}
        </label>
        <v-select
          :value="project.language"
          @change="value => handleProjectChange('language', value)"
          item-value="value"
          :disabled="loading.active"
          item-text="text"
          class="search-bar__search search-bar__search--large w-100 text-sm mb-3"
          dense
          outlined
          label=""
          :height="40"
          :items="supportedLanguageOptions"
          hide-details
          :menu-props="{
            'offset-y': true,
            'ripple': false
          }"
        />
      </div>
      <div
        :class="loading.active && 'upload-settings-form--disabled'"
        class="upload-settings upload-settings-form mb-5 mt-2 pt-4"
      >
        <label class="text-sm font-weight-medium mb-2 d-flex">{{ $t('advanced_settings') }}</label>

        <v-tooltip bottom :disabled="user.subscription.features.anonymization">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-switch
                :input-value="project.anonymize"
                @change="value => handleProjectChange('anonymize', value)"
                :disabled="loading.active || !user.subscription.features.anonymization"
                inset
                color="green"
                flat
                dense
                :ripple="false"
                class="v-input--reverse v-input--description v-input--switch--small w-100 mt-0"
                :style=" !user.subscription.features.anonymization && `opacity: 0.5;`"
                hide-details
              >
                <template v-slot:label>
                  <div>
                    <div class="text-xs font-weight-medium text-color mb-1">
                      {{ $t('steps.settings.anonymization.label') }}
                      <helptip position="top" :width="600">
                        <div v-html="$t('steps.settings.anonymization.helptip')" />
                      </helptip>
                    </div>
                  </div>
                </template>
              </v-switch>
            </div>
          </template>
          <span v-html="$t('steps.settings.anonymization.disabled')" />
        </v-tooltip>

        <v-switch
          :input-value="project.translated"
          @change="value => handleProjectChange('translated', value ? 1 : 0)"
          :disabled="loading.active"
          inset
          color="green"
          flat
          dense
          :ripple="false"
          class="v-input--reverse v-input--description v-input--switch--small w-100 mt-2"
          hide-details
        >
          <template v-slot:label>
            <div>
              <div class="text-xs font-weight-medium text-color mb-1">
                {{ $t('steps.settings.translate.input') }}
                <helptip position="top" :width="600">
                  <div v-html="$t('steps.settings.translate.helptip_content')" />
                </helptip>
              </div>
            </div>
          </template>
        </v-switch>
        <template v-if="project.translated">
          <div class="d-flex mt-2">
            <div class="w-100">
              <label class="font-weight-medium text-label text-xs">
                {{ $t('steps.settings.translate.engine') }}
              </label>
              <v-select
                :value="project.translation_engine"
                @change="value => handleProjectChange('translation_engine', value)"
                :disabled="loading.active"
                class="search-bar__search search-bar__search--large w-100 text-sm"
                dense
                outlined
                label=""
                :height="40"
                :items="translationEngineOptions"
                hide-details
                :menu-props="{
                  'offset-y': true,
                  'ripple': false,
                  'contentClass': 'select-menu-with-helptips'
                }"
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                  <helptip
                    v-if="item.disabled && !user.subscription.features.deepl"
                    position="bottom"
                    :width="250"
                  >
                    <feature-unavailable />
                  </helptip>
                </template>
              </v-select>
            </div>
            <!-- <div class="d-flex align-center ml-2">
              <helptip position="top" :width="500" :max-width="500">
                <div v-html="$t('steps.settings.translate.helptip_engine', {
                  google_translate_languages: translationLanguages.GT,
                  deepl_languages: translationLanguages.DL
                })"
                />
              </helptip>
            </div> -->
          </div>
          <notification
            :closeable="false"
            max-width="100%"
            class="mt-4"
          >
            <span v-html="$t('steps.settings.translate.info_general', { engine: $t(`steps.settings.translate.${project.translation_engine}`) })" />
            <p
              v-if="project.translation_engine === 'DL'"
              v-html="$t('steps.settings.translate.info_deepl', { languages: translationLanguages.DL })"
              class="mt-2 mb-0"
            />
          </notification>
        </template>
      </div>
      <div
        v-if="isIntegrationTab"
        :class="{
          'upload-settings-form--disabled': loading.active,
          'pb-5': isQualtricsIntegration,
          'pb-3': !isQualtricsIntegration
        }"
        class="upload-settings upload-settings-form upload-settings-form--small mb-5 mt-0 pt-4"
      >
        <label class="text-sm font-weight-medium mb-2 d-flex">{{ $t('steps.settings.integration_settings') }}</label>
        <div class="d-flex align-center">
          <v-switch
            :value="project.sync_enabled"
            @change="value => handleProjectChange('sync_enabled', value)"
            :disabled="loading.active || !!project.end_date"
            inset
            color="green"
            flat
            dense
            :ripple="false"
            class="v-input--reverse v-input--switch--small w-100 mt-1 pt-0 mb-2"
            hide-details
          >
            <template v-slot:label>
              <div>
                <div class="text-xs font-weight-medium text-color mb-1">
                  {{ $t('project.interval.label') }}
                  <helptip position="top" :width="600">
                    <div v-html="$t('project.interval.helptip')" />
                    <div>
                      <a
                        target="_blank"
                        class="mt-1"
                        href="https://caplena.com/docs/knowledge-base/2784zjo1mosah-integrations-in-detail#synchronization" v-text="$t('actions.more_information')"
                      />
                    </div>
                  </helptip>
                </div>
              </div>
            </template>
          </v-switch>
        </div>
        <div class="d-flex align-center mb-3" :class="{ 'mb-3': !isBrandwatchIntegration }">
          <v-number-field
            :value="project.limit"
            :disabled="loading.active"
            @input="value => {
              handleProjectChange('limit', value)
              checkLimitDebounced()
            }"
            :label="isAConnectedAccountIntegration ? $t('steps.settings.limit.label_connected') : $t('steps.settings.limit.label')"
            type="number"
            class="mb-1"
            :error="errors.limit.length > 0"
            :error-messages="errors.limit"
            required
            outlined
            hide-details
            dense
          >
            <template v-slot:label>
              <div class="ml-1 d-flex align-center" style="position: relative; bottom: 1px;">
                <helptip>
                  <div v-html="isAConnectedAccountIntegration ? $t('steps.settings.limit.helptip_connected') : $t('steps.settings.limit.helptip')" />
                </helptip>
              </div>
            </template>
          </v-number-field>
        </div>
        <div v-if="isBrandwatchIntegration || isQualtricsIntegration">
          <div v-if="isBrandwatchIntegration">
            <label class="font-weight-medium text-label text-xs">
              {{ $t('steps.settings.brandwatch_filters.page_type') }}
            </label>
            <v-select
              :value="project.brandwatch_filters.pageType"
              @change="value => handleProjectChange('brandwatch_filters.pageType', value)"
              :disabled="loading.active"
              class="search-bar__search search-bar__search--large w-100 text-sm mb-3"
              dense
              outlined
              label=""
              :height="40"
              clearable
              :items="brandWatchPageTypeOptions"
              hide-details
              :menu-props="{
                'offset-y': true,
                'ripple': false
              }"
            />
          </div>

          <div v-if="isBrandwatchIntegration">
            <label class="font-weight-medium text-label text-xs">
              {{ $t('steps.settings.brandwatch_filters.account_type') }}
            </label>
            <v-select
              :value="project.brandwatch_filters.accountType"
              @change="value => handleProjectChange('brandwatch_filters.accountType', value)"
              :disabled="loading.active"
              class="search-bar__search search-bar__search--large w-100 text-sm mb-3"
              dense
              outlined
              label=""
              :height="40"
              :items="brandWatchAccountTypeOptions"
              hide-details
              clearable
              :menu-props="{
                'offset-y': true,
                'ripple': false
              }"
            />
          </div>

          <label class="font-weight-medium text-label text-xs">
            {{ $t('steps.settings.brandwatch_filters.start_date') }}
          </label>
          <v-menu
            v-model="startDatePickerShown"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="project.start_date"
                class="search-bar__search search-bar__search--large search-bar__search--wide mb-3"
                required
                outlined
                dense
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :value="project.brandwatch_filters.start_date"
              @input="startDatePickerShown = false"
              @change="value => handleProjectChange('start_date', value)"
            />
          </v-menu>

          <label class="font-weight-medium text-label text-xs">
            {{ $t('steps.settings.brandwatch_filters.end_date') }}
          </label>
          <v-menu
            v-model="endDatePickerShown"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="project.end_date"
                readonly
                class="search-bar__search search-bar__search--large search-bar__search--wide mb-3"
                required
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :value="project.end_date"
              @input="endDatePickerShown = false"
              @change="value => {
                handleProjectChange('end_date', value)
                handleProjectChange('sync_enabled', false)
              }"
            />
          </v-menu>
        </div>
        <notification
          v-if="engineNonSupportedLanguages.length && !project.translated"
          class="mb-3"
          type="warning"
          style="max-width: 480px"
        >
          <span v-html="$t('steps.settings.translate.warning_engine_non_supported', {
            languages: engineNonSupportedLanguages.join(', ')
          })"
          />
        </notification>
      </div>
    </div>
    <notification
      v-if="isIntegrationTab && shouldShowInconsistencyLimitAlert"
      type="info"
      style="max-width: 480px"
      class="mb-3"
    >
      <span v-html="$t('steps.upload.integration_inconsistent_limit', { limit: project.limit ? project.limit : integrationConsistencyLimitCount })" />
    </notification>
    <credit-deduction-info
      v-if="!loading.active"
      :is-integration-tab="isIntegrationTab"
      :credits-required="creditsToDeduct"
      :help-article-link="HELP_RESOURCE_CREDITS"
      :confirmation="true"
      :class-name="answersInput.deductionInfoHover ? 'hovered' : ''"
      show-info
    />
  </div>
</template>

<script>

import Vuex from 'vuex'

import ProjectListMixin from '@/mixins/ProjectList'
import TagSelect from '@/components/TagSelectv2'

import { HELP_RESOURCE_CREDITS } from '@/settings/help-resources'

import {
  MIN_PROJECT_NAME_LEN, UPLOAD_ANSWERS_TRANSLATE_BILL_THRESHOLD, INTEGRATION_CONSISTENCY_LIMIT_COUNT
} from '@/settings/constants'

import { ANONYMIZATION_DEFAULT } from '@/settings/overridable'

export default {
  codit: true,
  name: 'StepSettings',

  components: {
    'tag-select': TagSelect
  },

  mixins: [ProjectListMixin],

  data () {
    return {
      errors: { name: [], limit: [] },
      checkProjectNameDebounced: _.debounce(() => {
        if (this.project.name.length < MIN_PROJECT_NAME_LEN) {
          this.errors.name = [this.$t('steps.settings.name_too_short', { n: MIN_PROJECT_NAME_LEN })]
        } else this.errors.name = []
      }, 250),
      checkLimitDebounced: _.debounce(() => {
        if (Number(this.project?.limit) <= 0) {
          this.errors.limit = [this.$t('steps.settings.limit_below_zero')]
        } else if (this.project.limit && Number(this.project.limit) > INTEGRATION_CONSISTENCY_LIMIT_COUNT && !this.isQualtricsIntegration) {
          this.errors.limit = [this.$t('steps.settings.limit_too_high', { n: INTEGRATION_CONSISTENCY_LIMIT_COUNT })]
        } else {
          this.errors.limit = []
        }
      }, 250),
      startDatePickerShown: false,
      endDatePickerShown: false,
      UPLOAD_ANSWERS_TRANSLATE_BILL_THRESHOLD,
      MIN_PROJECT_NAME_LEN,
      HELP_RESOURCE_CREDITS
    }
  },

  computed: {
    /**
     * Checks if upload method is file upload
     * @return {Boolean}
     */
    isFileTab () {
      return this.answersInput.tab === 'file'
    },

    /**
     * Checks if upload method is direct input
     * @return {Boolean}
     */
    isInputTab () {
      return this.answersInput.tab === 'input'
    },

    /**
     * Checks if upload method is integration
     * @return {Boolean}
     */
    isIntegrationTab () {
      return this.answersInput.tab === 'integration'
    },

    /**
     * Returns the interval sync options for integrations
     * @return {Array}
     */
    intervalOptions () {
      return [
        {
          value: false,
          text: this.$t('project.interval.disabled')
        },
        {
          value: true,
          text: this.$t('project.interval.enabled')
        }
      ]
    },

    /**
     * Returns the possible translation_engine options
     * @return {Array}
    */
    translationEngineOptions () {
      return [
        { text: this.$t('steps.settings.translate.GT'), value: 'GT' },
        { text: this.$t('steps.settings.translate.DL'), value: 'DL', disabled: !this.projectLanguageSupportedByDeepL }
      ]
    },

    /**
     * Checks if the selected integration comes from the connected accounts
     */
    isAConnectedAccountIntegration () {
      return this.isQualtricsIntegration || this.isZapierIntegration
    },

    /**
     * Returns currently selected labels cloned in order for the label-select component not to mutate vuex state
     * @return {Array}
     */
    clonedLabels () {
      return _.clone(this.project.labels)
    },

    /** Returns possible lebl options
     * @return {Array}
     */
    labelOptions () {
      return this.projectsInheritable
    },

    /**
     * Return mapping of translation engine to string containing list of languages supported by that engine
     */
    translationLanguages () {
      return {
        GT: _.map(this.languageOptions.google_translate, v => this.languageOptions.iso[v]).join(', '),
        DL: _.map(this.languageOptions.deepl, v => this.languageOptions.iso[v]).join(', ')
      }
    },

    /**
     * Return true if the currently selected project language is supported by DeepL
     * @return {Bool}
     */
    projectLanguageSupportedByDeepL () {
      return this.languageOptions.deepl.indexOf(this.project.language) !== -1 && this.user.subscription.features.deepl
    },

    /** Return possible language options
     * @return {Array}
     */
    supportedLanguageOptions () {
      return _.map(this.languageOptions.caplena_supported, v => ({ text: this.languageOptions.iso[v], value: v }))
    },

    /**
     * Possible brandwatch page type options
     * @return {Array}
     */
    brandWatchPageTypeOptions () {
      return ['news', 'review', 'four_chan', 'tumblr', 'qq', 'reddit', 'facebook', 'dark_web', 'blog', 'vk', 'instagram', 'youtube', 'lexis_nexis_licensed_news', 'sina_weibo', 'forum', 'twitter']
    },

    /**
     * Possible branchwatch account type options
     * @return {Array}
     */

    brandWatchAccountTypeOptions () {
      return ['organisational', 'individual']
    },

    /**
     * Return list of languages which are present in data but not supported by our AI
     * @return {Array}
     */
    engineNonSupportedLanguages () {
      let caplenaSupported = new Set(this.languageOptions.caplena_supported)
      let nonSupportedLangs = new Set()
      this.questionColumns.forEach(qcol => {
        if (qcol.language_distribution) {
          qcol.language_distribution.forEach(({ lang }) => {
            if (!caplenaSupported.has(lang)) nonSupportedLangs.add(this.languageOptions.iso[lang])
          })
        }
      })
      return Array.from(nonSupportedLangs)
    },

    integrationConsistencyLimitCount () {
      return INTEGRATION_CONSISTENCY_LIMIT_COUNT
    },

    shouldShowInconsistencyLimitAlert () {
      let maxIntegrationResponses = _.max(_.map(this.answersInput.integrationLinks, ({ meta }) => meta && meta.num_responses))

      if (this.project.limit && this.project.sync_enabled) {
        return maxIntegrationResponses > this.project.limit
      }

      return maxIntegrationResponses > this.integrationConsistencyLimitCount && this.project.sync_enabled
    },

    ...Vuex.mapState({
      user: 'user',
      languageOptions: 'languageOptions',
      loading: state => state.upload.loading,
      answersInput: state => state.upload.answersInput,
      answersRes: state => state.upload.answersRes,
      projectsInheritable: state => state.upload.projectsInheritable,
      project: state => state.upload.project
    }),

    ...Vuex.mapGetters([
      'nRows',
      'questionColumns',
      'canContinueDespiteTimeout',
      'isQualtricsIntegration',
      'isZapierIntegration',
      'creditsToDeduct',
      'isBrandwatchIntegration'
    ])
  },

  watch: {
    'project.translated' (val) {
      if (val) {
        this.checkTranslationSettings()
      }
    },

    'project.language': 'checkTranslationSettings'
  },

  created () {
    this.checkTranslationSettings()

    if (this.isIntegrationTab) {
      this.handleProjectChange('sync_enabled', false)
    }

    if (this.isQualtricsIntegration) {
      this.handleProjectChange('limit', null)
    } else {
      this.handleProjectChange('limit', 50000)
    }

    if (this.user.subscription.features.anonymization && ANONYMIZATION_DEFAULT) {
      this.handleProjectChange('anonymize', true)
    }
  },

  methods: {
    /**
     * Updates the project entity via Vuex uplaod store by key/value pair
    */
    handleProjectChange (key, value) {
      this.$store.commit('setProjectValue', { key, value })
    },

    /**
     * Check if the current translation settings (engine & language) are valid
     * If not, switch to default (i.e. Google Translate)
     * @return {[type]} [description]
     */
    checkTranslationSettings () {
      if (this.project.translation_engine === 'DL' && !this.projectLanguageSupportedByDeepL) {
        this.handleProjectChange('translation_engine', 'GT')
      }
    },

    saveAttachLabel (label) {
      if (this.project.labels.indexOf(label) === -1) {
        this.handleProjectChange('labels', [ ...this.project.labels, label ])
      }
    },

    ...Vuex.mapMutations([
      'setProjectValue'
    ])
  }
}

</script>

<style lang=scss>
  @import '~css/filters';
  @import '~css/coding';
</style>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />
