<template>
  <div
    :class="[
      'body-2 switcher',
      {
        'switcher--border': isBordered
      }
    ]"
  >
    <div
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :class="[
        'switcher__item d-flex align-center',
        {
          'switcher__item--active': item.value === internalValue
        }
      ]"
      @click="setInternalValue(item.value)"
    >
      <v-tooltip
        top
        v-if="item.icon"
      >
        <span>{{ item.icon.label }}</span>
        <template v-slot:activator="{ on }">
          <img
            class="switcher__icon"
            :src="item.icon.image"
            v-on="on"
          >
        </template>
      </v-tooltip>
      <span v-else>
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
import DataMixin from '@/components/customUi/mixins/data'

export default {
  mixins: [
    DataMixin
  ],
  props: {
    value: { type: [String, Number, Boolean], required: false, default: null },
    items: { type: Array, required: true },

    isBordered: { type: Boolean, required: false, default: true }
  },
  methods: {
    setInternalValue (value) {
      this.internalValue = value
    }
  }
}
</script>

<style lang="scss">
.switcher{
  display: flex;
  overflow: hidden;
  user-select: none;

  &--border{
    border: 1px solid #D8E2E8;
    border-radius: 5px;
  }

  &__item{
    padding: 4px 12px;
    flex: 1;
    text-align: center;
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid #D8E2E8;
    }

    &--active{
      background: rgba(0,0,0,.1);
    }
  }
  &__icon{
    max-width: 22px;
  }
}
</style>