<template>
  <div class="coding__column__statistics">
    <!-- GENERAL  -->
    <div class="coding__column__statistics__title d-flex justify-space-between">
      {{ $t('stats.general') }}
      <!-- <div class="d-flex justify-end">
        <div class="text-xs text-grey text-end">
          {{ $t('last_updated') }}: {{ statsLastUpdatedHumanReadable }}.&nbsp;
        </div>
        <div class="text-xs text-primary text-hover">
          Reload
        </div>
      </div> -->
    </div>

    <!-- owner  -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.owner') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.owner }}
      </div>
    </div>

    <!-- date created -->
    <div class="coding__column__statistics__option coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.created') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.uploaded | dateverbal }}
      </div>
    </div>

    <!-- modified  -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.last_modified') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.last_modified | dateverbal }}
      </div>
    </div>

    <!-- billed -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.credits_billed') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.credits_billed_count }}
      </div>
    </div>

    <!-- unbilled  -->
    <div class="coding__column__statistics__option coding__column__statistics__option--last">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.credits_open') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.credits_unbilled_count }}
      </div>
    </div>

    <!-- RESPONSES -->
    <div class="coding__column__statistics__title">
      {{ $tu('rows') }}
    </div>

    <!-- # of responses -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.n_rows') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_count }}
      </div>
    </div>

    <!-- % of empty responess -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.n_answers_empty') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_empty_count }} ({{ ((stats.rows_empty_count / stats.rows_count) * 100).toFixed(0) }}%)
      </div>
    </div>

    <!-- unique responses -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.n_rows_unique') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_unique_count }}
      </div>
    </div>

    <!-- manually reviewed  -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.number_reviewed') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_reviewed_count }} ({{ ((stats.rows_reviewed_count / stats.rows_count) * 100).toFixed(0) }}%)
      </div>
    </div>

    <!-- unique manually reviewed  -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.number_reviewed_unique') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_unique_reviewed_count }} ({{ ((stats.rows_unique_reviewed_count / stats.rows_unique_count) * 100).toFixed(0) }}%)
      </div>
    </div>

    <!-- non-empty manually reviewed  -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.number_reviewed_nonempty') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_nonempty_reviewed_count }} ({{ ((stats.rows_nonempty_reviewed_count / (stats.rows_count - stats.rows_empty_count)) * 100).toFixed(0) }}%)
      </div>
    </div>

    <!-- manually changed -->
    <!-- <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.number_manual') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_changed_count }}
      </div>
    </div> -->

    <!-- without code -->
    <div class="coding__column__statistics__option coding__column__statistics__option--last">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.number_notopic') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.rows_without_code_count }} ({{ ((stats.rows_without_code_count / stats.rows_count) * 100).toFixed(0) }}%)
      </div>
    </div>

    <!-- TOPICS -->
    <div class="coding__column__statistics__title">
      {{ $tc('topics.items', 2) }}
    </div>

    <!-- # of topics  -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.n_topics') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.topics_count }}
      </div>
    </div>

    <!-- # of categories  -->
    <div class="coding__column__statistics__option coding__column__statistics__option--last">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.n_cats') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.topic_categories_count }}
      </div>
    </div>

    <!-- MODEL SCORE  -->
    <div class="coding__column__statistics__title">
      {{ $tu('stats.model.title') }}
    </div>

    <!-- Last updated -->
    <div class="coding__column__statistics__option">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.model.score') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.model_score && stats.model_score > 0 ? (stats.model_score * 100).toFixed(0) : $t('stats.score_undefined') }}
      </div>
    </div>

    <!-- Last updated -->
    <div class="coding__column__statistics__option" v-if="stats.model_last_update">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.model.updated') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ $options.filters.dateverbal(stats.model_last_update) }}
      </div>
    </div>

    <!-- # of trainings  -->
    <div class="coding__column__statistics__option coding__column__statistics__option--last">
      <div class="coding__column__statistics__option__label">
        {{ $t('stats.model.number_training') }}:&nbsp;
      </div>
      <div class="coding__column__statistics__option__value">
        {{ stats.model_training_runs_count }}
      </div>
    </div>

    <div class="coding__column__statistics__title" />
    <div class="d-flex justify-end mt-5 mb-1">
      <v-btn
        class="codebook-editor__category__new"
        @click="reload"
        depressed
        small
        outlined
      >
        <v-icon :size="16" class="mr-1 text-grey">
          mdi-reload
        </v-icon>
        {{ $t('actions.refresh') }}
      </v-btn>
    </div>
    <div class="d-flex justify-end">
      <div class="text-xs text-grey text-end">
        {{ $t('last_updated') }}: {{ statsLastUpdatedHumanReadable }}.&nbsp;
      </div>
    </div>

    <!-- <div class="d-flex justify-end">
        <div class="text-xs text-grey text-end">
          {{ $t('last_updated') }}: {{ statsLastUpdatedHumanReadable }}.&nbsp;
        </div>
        <div class="text-xs text-primary text-hover">
          Reload
        </div>
      </div> -->
  </div>
</template>

<script>

import Vuex from 'vuex'

export default {
  codit: true,
  name: 'CodingStatistics',

  data () {
    return {
      statsUpdateInterval: null,
      statsUpdateHumanReadableInterval: null,
      statsLastUpdated: null,
      statsLastUpdatedHumanReadable: ''
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user',
      project: state => state.coding.project,
      stats: state => state.coding.stats,
      topics: state => state.coding.topics,
      sessionIssue: state => state.coding.sessionIssue
    }),

    connectionLost () { return this.sessionIssue.type === 'connection_lost' },

    ...Vuex.mapGetters([
      'topicCats'
    ])
  },

  watch: {
    connectionLost (val) {
      if (val) clearInterval(this.statsUpdateInterval)
    }
  },

  created () {
    this.statsLastUpdated = this.$moment()
    this.computeChartLastUpdatedHumanReadable()
    this.statsUpdateInterval = setInterval(this.reload, 60000)
    this.statsUpdateHumanReadableInterval = setInterval(this.computeChartLastUpdatedHumanReadable, 5000)
  },

  beforeDestroy () {
    clearInterval(this.statsUpdateInterval)
    clearInterval(this.statsUpdateHumanReadableInterval)
  },

  methods: {
    reload () {
      this.$store.dispatch('loadStats').then(() => {
        this.statsLastUpdated = this.$moment().local()
        this.computeChartLastUpdatedHumanReadable()
      })
    },

    computeChartLastUpdatedHumanReadable () {
      this.statsLastUpdatedHumanReadable = this.statsLastUpdated.fromNow()
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/coding/QuestionStats.json' />
<i18n locale='de' src='@/i18n/de/components/coding/QuestionStats.json' />
