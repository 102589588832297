const EL_STATUS_TEMPLATE = { dirty: false, loading: false, failed: false }

export default {
  data () {
    return {
      dashboardElements: [],

      removeElement: {
        elIdx: null
      }
    }
  },

  computed: {
    dashboardElementsWarnings () {
      return Object.entries(_.cloneDeep(this.valueManager)).reduce((sum, [key, value]) => {
        let warnings = null

        if (
          value?.warnings?.[0]
        ) {
          warnings = value.warnings
        }

        return {
          ...sum,
          [key]: warnings
        }
      }, {})
    }
  },

  watch: {
    '$route.params.id' () {
      this.removeElement.elIdx = null
    }
  },

  methods: {
    addDashboardElement ({ x, y, width, height, chart, status }) {
      return this.$set(this.dashboardElements, this.dashboardElements.length, {
        id: this.$uuidv4(), // this is only a local id, used by vue and js, not persistent
        x,
        y,
        width: width || 1,
        height: height || 1,
        status: { ..._.cloneDeep(EL_STATUS_TEMPLATE), ...status },
        chart: chart || null
      })
    },

    /**
     * Removes the dashboard element defined by its index
     */
    async removeDashboardElement () {
      const entityId = this.dashboardElements[this.removeElement.elIdx].chart.id || `ch__${+this.dashboardElements[this.removeElement.elIdx].chart.created}`

      if (
        this.dashboardElements.filter(item => item.chart.id === entityId).length === 1
      ) {
        await this.$store.dispatch(
          'registerManager/delete',
          { entityId }
        )
      }

      this.$delete(this.dashboardElements, this.removeElement.elIdx)
      this.status.dirty = true
      this.modalsShown.removeElement = false
      this.status.dirty = true
    },

    removeElementHandler ({ id }) {
      this.removeElement.elIdx = _.findIndex(this.dashboardElements, item => item.id === id)
      this.modalsShown.removeElement = true

      delete this.shownItems[id]
      delete this.visibleItems[id]
    }
  }
}