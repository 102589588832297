import registerManager from '@/store/managers/register'
import metaManager from '@/store/managers/meta'
import configManager from '@/store/managers/config'
import verbatimManager from '@/store/managers/verbatim'
import valueManager from '@/store/managers/value'

import verbatimDialog from '@/store/mixins/verbatimDialog'

const store = {
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    registerManager,
    metaManager,
    configManager,
    verbatimManager,
    verbatimDialog,
    valueManager
  }
}

export default store