var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content slpg",staticStyle:{"margin-bottom":"80px"}},[(_vm.failed)?_c('alert',{staticClass:"retry",attrs:{"type":"error"}},[_c('div',{staticClass:"text"},[_vm._v("\n      "+_vm._s(_vm.$t('error', {'step': _vm.$t('loading.while')}))+"\n    ")]),_vm._v(" "),_c('v-btn',{on:{"click":_vm.loadCharts}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("\n        mdi-reload\n      ")]),_vm._v(" "+_vm._s(_vm.$t('actions.retry'))+"\n    ")],1)],1):_c('chart-list',{ref:"chartList",attrs:{"charts":_vm.charts,"loading":_vm.loading,"question-to-filter":_vm.questionToFilter,"add-enable":"","search-filter-enable":"","questions-filter-enable":"","selectable":""},on:{"open-chart":_vm.openChart},scopedSlots:_vm._u([{key:"empty-state",fn:function({ filters }){return [_c('v-btn',{attrs:{"color":"green","outlined":"","exact":"","to":{
        name: 'chart-details',
        params: { id: 'new' },
        query: !filters.name.length && filters.questions.length === 1 & filters.questions[0] === _vm.$route.query.questionID ?
          { question: _vm.$route.query.questionID } : {}
      }}},[_vm._v("\n        "+_vm._s(_vm.$t('menu.new_chart'))+"\n      ")])]}},{key:"header-actions",fn:function({ chartsSelected }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.newDashboardFromCharts(chartsSelected)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("\n              mdi-monitor-dashboard\n            ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('new_dashboad_from_charts')))])])],1),_vm._v(" "),_c('v-btn',{attrs:{"disabled":!chartsSelected.every(c => _vm.$hasPermission('dashboards_edit', c)),"icon":""},on:{"click":function($event){return _vm.openDeleteCharts(chartsSelected)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("\n              mdi-delete\n            ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('delete.title')))])])],1)]}}])},[_c('template',{slot:"title"},[_vm._v("\n      "+_vm._s(_vm.$t('title'))+"\n    ")])],2),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDeleteCharts(false)}},model:{value:(_vm.deleteChart.active),callback:function ($$v) {_vm.$set(_vm.deleteChart, "active", $$v)},expression:"deleteChart.active"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",attrs:{"primary-title":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('delete.are_you_sure', { type: _vm.$tc('chart.items', _vm.chartsSelectedCached.length),
                                                 name: _vm.$escapeHtml(_vm.deleteChart.label) }))}})]),_vm._v(" "),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.$t('delete.description'))}}),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){return _vm.closeDeleteCharts(false)}}},[_vm._v("\n          "+_vm._s(_vm.$t('no'))+"\n        ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.closeDeleteCharts(true)}}},[_vm._v("\n          "+_vm._s(_vm.$t('yes'))+"\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }