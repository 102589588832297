export default {
  props: {
    value: { type: Boolean, default: false },
    projectId: { type: String, default: '', required: true },
    projectName: { type: String, default: '', required: true },
    refId: { type: String, default: '' },
    questionName: { type: String, default: '' },
    useQueryAsFilters: { type: Boolean, default: false }
  },

  computed: {
    title () {
      if (
        this.prefetchData &&
        typeof this.prefetched.rowsCount === 'number'
      ) {
        if (this.coding && _.isEmpty(this.$route.query) && this.prefetchData) {
          return this.$t('download_dialog.header.no_filters_applied')
        }

        return this.$tc('download_dialog.header.filtered', this.prefetched.rowsCount, { count: this.prefetched.rowsCount })
      } else if (
        this.prefetchData
      ) {
        return this.$t('download_dialog.header.no_filters_applied')
      }
      return _.truncate(`${this.projectName} ${this.questionName !== '' ? ` - ${this.questionName}` : ''}`, { length: 45 })
    }
  },

  methods: {
    /**
    * Set if full list of previous export shown.
    * Be default we show last 5 exports
    */
    showAllExports () {
      if (
        this.formData.kind === 0
      ) {
        this.exports.isRowsListShown = true
      } else if (
        this.formData.kind === 1
      ) {
        this.exports.isTopicsListShown = true
      }
    },
    /**
    * Close dialog emitting value for v-model
    */
    closeDialog () {
      this.internalValue = false
    }
  }
}