<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane
        v-model="config"
        v-bind="controlPaneProps"
        v-on="controlPaneEvents"
        ref="control-pane"
        slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
        <template v-slot:general>
          <settings-drawer-item
            :title="$t('controls.general.bar_orientation.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.barOrientation" hide-details class="mt-0 mb-2">
                <v-radio :label="$t('controls.general.bar_orientation.horizontal')" value="horizontal" />
                <v-radio :label="$t('controls.general.bar_orientation.vertical')" value="vertical" />
              </v-radio-group>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.ordinal_axis.plot_categories')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.plotCategories"
                hide-details
                :label="$t('controls.ordinal_axis.plot_categories')"
              />
            </template>
          </settings-drawer-item>
        </template>
        <template v-slot:colors>
          <settings-drawer-item
            :title="$t('controls.colors.color_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.colorBy.field" column hide-details class="mt-0">
                <v-radio :label="$t('controls.colors.color_by.category')" value="category" />
                <v-radio :label="$t('controls.colors.color_by.topic')" value="topic" />
                <v-radio :label="$t('controls.colors.color_by.segment')" value="segment" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>
        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.percentages"
                :disabled="maxStackDepth > 1"
                :label="$t('controls.value_axis.percentages')"
              />

              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.labels.label_position.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.labelPosition" column hide-details class="mt-0">
                <v-radio
                  :label="$t('controls.labels.label_position.outside')"
                  value="outside"
                />
                <v-radio
                  :label="$t('controls.labels.label_position.inside')"
                  value="inside"
                />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:chart-type-selection>
          <slot name="chart-type-selection" />
          <settings-drawer-item
            :title="$t('settings.group.select_column_label')"
          >
            <template slot="content">
              <div class="settings-selector">
                <div class="d-flex align-center justify-space-between">
                  <v-select
                    v-model="config.groupByColumn"
                    :items="potentialDYBARSelectItems"
                    :label="$t('segment_by.column_title')"
                    item-value="name"
                    item-text="name"
                    hide-details
                    class="type-select"
                    outlined
                    dense
                    style="max-width: 89%;"
                  />
                  <helptip position="bottom" class="ml-3">
                    <span v-html="$t('settings.group.column_helptip')" />
                  </helptip>
                </div>
              </div>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-checkbox
            v-model="config.showSampleSize"
            hide-details
            :label="$t('settings.sample_size.label_control')"
            class="mt-2"
          />
        </template>

        <template v-slot:ordinal-axis>
          <settings-drawer-item
            :title="$t('controls.ordinal_axis.sort_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.sortOrdinalBy" column hide-details class="mt-0">
                <v-radio :label="$t('controls.ordinal_axis.sort_by.none')" :value="null" />
                <v-radio :label="$t('controls.ordinal_axis.sort_by.frequency')" value="frequency" />
                <v-radio :label="$t('controls.ordinal_axis.sort_by.alphabet')" value="alphabet" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:value-axis-display>
          <v-checkbox
            v-model="config.reverseOrdinal"
            :label="$t('controls.ordinal_axis.reverse')"
            hide-details
          />
        </template>

        <!-- TODO: component of just slots, is there a better way ?? -->
        <slot
          name="datasets"
          slot="datasets"
          :dataset-props="{ disableFilters: true, duplicatable: false, editable: false }"
          :disable-master-settings="true"
        />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <slot name="chart-type-selection" slot="chart-type-selection" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <v-chart ref="chart"
                 v-if="initialReady"
                 :style="chartStyle"
                 v-bind="chartProps"
                 v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'
import { generateSigleFunctionCallComputedWithSameArgs } from '@/utils/genericComputed.js'
import { chartMixin } from '@/mixins/chart'
import { encodeStr } from '@/utils/filters'

import {
  SENTIMENT_RANGE
} from '@/settings/constants'

import 'echarts/lib/chart/bar'

const CODE_SPLIT_TOK = '-&/_&/_-'

const ICON_PLUS_SVG = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\'%3E%3Cpath fill=\'%23999\' d=\'M19 19V5H5v14h14m0-16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-8 4h2v4h4v2h-4v4h-2v-4H7v-2h4V7z\'/%3E%3C/svg%3E'
const ICON_MINUS_SVG = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\'%3E%3Cpath fill=\'%23999\' d=\'M19 19V5H5v14h14m0-16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-2 8v2H7v-2h10z\'/%3E%3C/svg%3E'

export default {
  codit: true,
  name: 'ChartDynamicBar',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [chartMixin],

  data () {
    return {
      // Settings for chart.js mixin
      resultKeys: ['counts'],
      APItype: 'DYBAR',
      forceAggregate: false,
      // end settings

      config: {
        // General
        title: undefined,
        aggregate: false, // must be false

        xField: 'topicLabel',

        barOrientation: 'horizontal',

        dimensions: undefined,

        colorBy: { field: 'category' },
        colorPalette: '__cb_colors__',
        colorPaletteValue: undefined,

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255,1)',

        // Ordinal axis
        ordinalAxisName: undefined,
        plotCategories: true,
        reverseOrdinal: undefined,
        sortOrdinalBy: 'frequency',

        // Value axis
        valueAxisName: undefined,
        valueAxisToFullData: false,

        // Grid line
        gridLine: true,

        percentages: true,

        // Labels
        dataLabelSize: 11,
        maxAxisLabelLength: 25,
        axisLabelSize: 11,
        labelsEnabled: true,
        labelPosition: 'outside',
        showLegend: true,
        showSampleSize: true,
        decimalPlaces: 0,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        // Expansion of categories
        categoriesExpanded: {},

        controls: {
          sentimentShown: 'any',
          groupByColumnValue: 0
        },
        groupByColumn: undefined
      },

      availableSettingTiles: {
        ...this.availableSettingTiles,
        valueAxis: ['valueAxisName', 'valueAxisToFullData', 'axisLabelSize', 'maxAxisLabelLength']
      },

      stackChangeWarning: {
        show: false,
        mode: ''
      }
    }
  },

  computed: {
    ...generateSigleFunctionCallComputedWithSameArgs({
      potentialDYBARSelectItems: 'getPotentialDYBARColumns'
    })(function () {
      return [
        this.meta?.auxiliary_column_metas,
        this.auxiliaryColumnNames
      ]
    }),

    minContainerHeight () {
      return this.isVertical ? 600 : 400
    },

    chartColStyle () {
      return {
        right: this.hasScrollBar ? '-15px' : '0px',
        'min-height': this.isVertical ? `${this.minContainerHeight + 100}px` : 'calc(100% - 100px)'
      }
    },

    chartContainerStyle () {
      return {
        'min-height': this.isHorizontal ? `${this.minCategoryDimSize + 100}px` : '',
        'min-width': this.isVertical ? `${this.minCategoryDimSize + 100}px` : ''
      }
    },

    /**
     * Mapping of the normal select values to the dataset columns
     * @return {String}
     */
    colorByMapping () {
      return {
        category: 'topicCategory',
        topic: 'topicLabel'
      }[this.config.colorBy.field]
    },

    /**
     * The color array, extended from the usual colors array
     * Handles two cases specially:
     * 1) When the coloring is according to the codebook colors, it returns the codebook colors
     * 2) When the coloring is according to the datasets, and datasets have the settings.color.overwrite setting activated,
     *    return the dataset color settings (settings.color.value)
     * @return {[type]} [description]
     */
    colorsIncludingCBAndDatasetColors () {
      if (this.colorByMapping === 'topicCategory' && this.config.colorPalette === '__cb_colors__') {
        return _.map(this.colorClasses, cc => this.catColors[cc].medium)
      } else {
        let colors = _.clone(this.colors)
        if (this.colorByMapping === 'dsName') {
          colors = _.map(colors, (c, idx) => {
            let ds = this.datasets[this.colorClassDatasetIndexes[idx]]
            if (ds && ds.settings.color && ds.settings.color.override) return ds.settings.color.value
            else return c
          })
        }
        return colors
      }
    },

    /**
     * Mapping of the modified dataset names.
     * Ds names can be the same for several datasets
     * but in order to render it correctly and for chart renderer
     * these must be unique.
     * If there is a double, the name must be "<original name> <appearance index>"
     * @return {Array}
     */
    dsNamesByIndex () {
      const temp = new Map()

      return this.datasets
        .map(item => item.settings.name)
        .map(item => {
          const isIndex = temp.get(item)
          if (
            typeof isIndex === 'undefined'
          ) {
            temp.set(item, 0)
            return item
          } else {
            temp.set(item, isIndex + 1)
            return `${item} [${isIndex + 1}]`
          }
        })
    },

    /**
     * Makes sure topics of same category come after each other
     * performs all sorting if `plotCategories` is activated
     * @return {list} List of topics
     */
    topicsSorted () {
      if (!this.initialReady) return []

      let topics = _.clone(this.topics)

      // Only responsible for sorting if plotCategories is enabled
      // otherwise sorting happens in `ordinalClassesSorted` getter
      if (
        this.config.sortOrdinalBy === 'frequency' &&
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 &&
        this.config.aggregate
      ) {
        topics = _.orderBy(topics, topic => {
          return this.results.counts.topics[0][topic.id]?.sentiments[this.sentimentShown].counts.overall
        }, 'desc')
      } else if (
        this.config.sortOrdinalBy === 'frequency' &&
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
      ) {
        topics = _.orderBy(topics, topic => this.results.counts.topics.reduce(
          (sum, dataset, datasetIndex) => sum + dataset[topic.id]?.sentiments[this.sentimentShown].counts.per_dataset?.[datasetIndex], 0),
        'desc')
      } else if (
        this.config.sortOrdinalBy === 'frequency' &&
        this.config.aggregate
      ) {
        topics = _.orderBy(topics, topic => {
          return this.results.counts.topics[0][topic.id]?.results.counts.overall
        }, 'desc')
      } else if (
        this.config.sortOrdinalBy === 'frequency'
      ) {
        topics = _.orderBy(topics, topic => this.results.counts.topics.reduce(
          (sum, dataset, datasetIndex) => sum + dataset[topic.id]?.results.counts.per_dataset?.[datasetIndex], 0),
        'desc')
      } else if (
        this.config.sortOrdinalBy === 'alphabet'
      ) {
        topics = _.orderBy(topics, c => c.label.toLowerCase(), 'asc')
      }

      if (this.colorByMapping === 'topicCategory' || this.config.plotCategories) {
        let grouped = _.groupBy(topics, 'category')
        if (this.config.plotCategories) {
          if (
            this.config.sortOrdinalBy === 'frequency' &&
            SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 &&
            this.config.aggregate
          ) {
            grouped = _.orderBy(grouped, g => this.results.counts.categoriesSentiment.reduce(
              (sum, category) => {
                return sum + category[g[0].category]?.[this.sentimentShown].counts.overall
              }, 0),
            'desc'
            )
          } else if (
            this.config.sortOrdinalBy === 'frequency' &&
            SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
          ) {
            grouped = _.orderBy(grouped, g => this.results.counts.categoriesSentiment.reduce(
              (sum, category, categoryIndex) => {
                return sum + category[g[0].category]?.[this.sentimentShown].counts.per_dataset?.[categoryIndex]
              }, 0),
            'desc'
            )
          } else if (this.config.sortOrdinalBy === 'frequency') {
            grouped = _.orderBy(grouped, g => this.results.counts.categories.reduce(
              (sum, category) => {
                return sum + category[g[0].category]
              }, 0),
            'desc'
            )
          } else if (this.config.sortOrdinalBy === 'alphabet') {
            grouped = _.orderBy(grouped, g => g[0].category.toLowerCase(), 'asc')
          }
        }
        topics = _.flatMap(grouped)
      }

      return topics
    },

    isHorizontal () { return this.config.barOrientation === 'horizontal' },
    isVertical () { return this.config.barOrientation === 'vertical' },

    chartDataset () {
      if (!this.initialReady) return []

      let res = []

      const datasetsToPlot = this.config.aggregate && this.datasets.length ? [this.datasets[0]] : this.datasets
      const nRows = this.globalResults.counts?.per_dataset?.[0]
      const nRowsCompareValue = this.globalResults.counts?.per_tick?.[this.groupByColumnValue]

      datasetsToPlot.forEach((ds, dsIdx) => {
        let prevCat = null

        this.topicsSorted.forEach((topic) => {
          if (this.config.plotCategories && topic.category !== prevCat) {
            // Only add the topic categories if configs say we should, and if this is an unseen category
            let catIcon = _.get(this.config.categoriesExpanded, topic.category) ? 'min' : 'pls'
            let entry = {
              topicID: -100,
              topicLabel: `CAT${CODE_SPLIT_TOK}{${catIcon}|} ${topic.category}`,
              topicCategory: topic.category,
              isCategory: true,
              overall_count: this.getDataItemSource(this.results?.counts?.categoriesRaw?.[0]?.[topic.category], this.sentimentShown)?.counts?.per_dataset?.[0],
              overall_perc: this.getDataItemSource(this.results?.counts?.categoriesRaw?.[0]?.[topic.category], this.sentimentShown)?.counts?.per_dataset?.[0] / nRows * 100,

              compare_count: this.getDataItemSource(this.results?.counts?.categoriesRaw?.[0]?.[topic.category], this.sentimentShown)?.counts?.per_tick?.[this.groupByColumnValue],
              compare_perc: this.getDataItemSource(this.results?.counts?.categoriesRaw?.[0]?.[topic.category], this.sentimentShown)?.counts?.per_tick?.[this.groupByColumnValue] / nRowsCompareValue * 100
            }

            entry.x = entry[this.config.xField]

            res.push(entry)

            prevCat = topic.category
          }

          // Push the topics if the category is expanded
          if (_.get(this.config.categoriesExpanded, topic.category) || !this.config.plotCategories) {
            let entry = {
              topicID: topic.id,
              topicLabel: `${topic.id}${CODE_SPLIT_TOK}{cod|${topic.label}}`,
              topicLabelOrig: topic.label,
              topicCategory: topic.category,
              topicDescription: topic.description,

              isCategory: false,
              itemStyle: {
                opacity: this.config.plotCategories ? 0.7 : 1.0
              },
              overall_count: this.getDataItemSource(this.results?.counts?.topics?.[0]?.[topic.id], this.sentimentShown)?.counts?.per_dataset?.[0],
              overall_perc: this.getDataItemSource(this.results?.counts?.topics?.[0]?.[topic.id], this.sentimentShown)?.counts?.per_dataset?.[0] / nRows * 100,

              compare_count: this.getDataItemSource(this.results?.counts?.topics?.[0]?.[topic.id], this.sentimentShown)?.counts?.per_tick?.[this.groupByColumnValue],
              compare_perc: this.getDataItemSource(this.results?.counts?.topics?.[0]?.[topic.id], this.sentimentShown)?.counts?.per_tick?.[this.groupByColumnValue] / nRowsCompareValue * 100
            }

            entry.x = entry[this.config.xField]

            res.push(entry)
          }
        })
      })

      if (this.doInternalReverse) {
        // it keeps datasets order, but change categories/codes order
        res = _.flatMap(Object.values(_.groupBy(res, 'dsIdx')).map(
          datasetItems => datasetItems.reverse()
        ))
      } else {
        res = _.flatMap(Object.values(_.groupBy(res, 'dsIdx')).reverse())
      }

      return res
    },

    ordinalClasses () {
      return _.uniq(
        _.map(this.chartDataset, ({ topicLabel }) => topicLabel)
      )
    },

    /**
     * Makes sure topics of same category come after each other
     * performs all sorting if `plotCategories` is activated
     * @return {list} List of topics
     */
    dataSorted () {
      let items = _.clone(this.resultsComputed.counts.topic[0])
      // Only responsible for sorting if plotCategories is enabled
      // otherwise sorting happens in `ordinalClassesSorted` getter
      if (
        this.config.sortOrdinalBy === 'frequency'
      ) {
        items = _.orderBy(items, c => {
          return this.results.counts.topics.reduce((sum, topic) => sum + topic[c.id], 0)
        }, 'desc')
      } else if (
        this.config.sortOrdinalBy === 'alphabet'
      ) {
        items = _.orderBy(items, c => c.label.toLowerCase(), 'asc')
      }

      if (this.colorByMapping === 'topicCategory' || this.config.plotCategories) {
        let grouped = _.groupBy(items, 'category')
        if (this.config.plotCategories) {
          if (this.config.sortOrdinalBy === 'frequency') grouped = _.orderBy(grouped, g => this.results.counts.categories.reduce((sum, category) => sum + category[g[0].category], 0), 'desc')
          if (this.config.sortOrdinalBy === 'alphabet') grouped = _.orderBy(grouped, g => g[0].category.toLowerCase(), 'asc')
        }
        items = _.flatMap(grouped)
      }

      return items
    },

    doInternalReverse () {
      return (!this.config.reverseOrdinal && this.isHorizontal) || (this.config.reverseOrdinal && this.isVertical)
    },

    ordinalClassesSorted () {
      // When plotting the categories, sorting here doesn't work
      // there won't be any stacks in this case anyways
      // Sorting will be applied in the `chartDataset` getter
      if (this.config.plotCategories) return this.classes

      let classes = _.clone(this.ordinalClasses)
      if (this.config.sortOrdinalBy === 'alphabet') classes = _.sortBy(this.ordinalClasses, c => c.toLowerCase())
      if (this.config.sortOrdinalBy === 'frequency') classes = _.orderBy(this.ordinalClasses, c => this.stacksInfo[c][0].sum, 'desc')

      return this.doInternalReverse ? classes.reverse() : classes
    },

    /**
     * The class names of the elements that define the colors (topics, categories, groups or datasets)
     * Ordered in the same way as in the chart
     * @return {Array}
     */
    colorClasses () {
      let classes = _.uniq(_.map(this.chartDataset, this.colorByMapping))
      if (
        this.config.colorBy.field === 'segment'
      ) {
        classes = this.series.map(({ name }) => name)
      }
      if (
        this.doInternalReverse
      ) {
        classes.reverse()
      }
      return classes
    },

    /**
     * The dataset indexes, as they are ordered in the chart (ordered by same logic as colorClass)
     * Empty array if ordering is not by dataset
     * @return {Array}
     */
    colorClassDatasetIndexes () {
      let classIdxs = _.uniq(_.map(this.chartDataset, 'dsIdx'))
      if (
        this.doInternalReverse
      ) {
        classIdxs.reverse()
      }
      // if (this.maxStackDepth > 1) classIdxs = _.orderBy(classIdxs, (val, idx) => this.colorClasses[idx])
      return classIdxs
    },

    ordinalAxis () {
      return {
        type: 'category',
        name: this.config.ordinalAxisName,
        axisTick: {
          show: false
        },

        triggerEvent: true,

        data: this.ordinalClasses,
        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        nameRotate: (this.isVertical ? 90 : 0),

        axisLabel: {
          show: true,
          rotate: (this.isVertical ? 90 : 0),
          align: 'right',
          interval: 0,
          rich: {
            // It's important that all rich elements have the same tag name length
            // as this is take into consideration when computing the correct padding
            cod: {
              fontSize: this.config.axisLabelSize,
              color: '#666'
            },
            pls: {
              height: 14,
              width: 14,
              align: 'right',
              verticalAlign: 'top',
              backgroundColor: {
                image: ICON_PLUS_SVG
              }
            },
            min: {
              height: 14,
              width: 14,
              align: 'right',
              verticalAlign: 'top',
              backgroundColor: {
                image: ICON_MINUS_SVG
              }
            }
          },

          fontSize: this.config.axisLabelSize,
          formatter: (lbl) => this.maybeTruncateLabel(
          // For stacking reasons, we have concatenated the ID with the topic label
          // (as topic labels might be identical for different topics)
          // Now separate them again to only get the topic labels
            lbl.split(CODE_SPLIT_TOK)[1],
            // Also, there is extra formatting in the topicLabels for the topics
            // Add that to the maximum label length that is allowed
            {
              maxLength: this.config.maxAxisLabelLength + 5 + lbl.startsWith('CAT') * 2
            }
          )
        }
      }
    },

    valueField () {
      return this.config.percentages ? 'perc' : 'count'
    },

    valueUnit () { return this.config.percentages || this.config.normStacks ? '%' : '' },

    maxStackDepth () {
      if (!this.ready) return undefined
      return _(this.chartDatasetsStacked).map((val, key) => val.length).max()
    },

    stacksInfo () {
      let res = {}
      _.each(this.chartDatasetsStacked, (val, key) => {
        let dsIdx = this.config.stackDatasets ? 0 : val[0].dsIdx
        res[val[0].x] || (res[val[0].x] = {})
        res[val[0].x][dsIdx] = {
          sum: _.sumBy(val, this.valueField),
          cnt: val.length,
          lastCodeID: val.slice(-1)[0].topicID }
      })
      return res
    },

    maxVal () {
      let dataMaxValue

      const maxValueOverall = Math.max(
        ...this.chartDataset.map(
          item => item[`overall_${this.valueField}`] // eslint-disable-line
        )
      )
      const maxValueCompare = Math.max(
        ...this.chartDataset.map(
          item => item[`compare_${this.valueField}`] // eslint-disable-line
        )
      )

      if (this.config.percentages) {
        dataMaxValue = 100
      } else {
        dataMaxValue = undefined
      }

      if (
        this.config.valueAxisToFullData
      ) {
        return dataMaxValue
      } else if (
        this.config.percentages
      ) {
        return maxValueOverall > maxValueCompare ? maxValueOverall : maxValueCompare
      } else {
        return maxValueOverall
      }
    },

    valueAxis () {
      const options = {
        name: this.config.valueAxisName,
        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        type: 'value',
        max: this.maxVal,
        triggerEvent: true,
        axisLabel: {
          fontSize: this.config.axisLabelSize,
          formatter: (value) => this.maybeTruncateLabel(
            `${Math.round(value)}${this.config.percentages || this.config.normStacks ? ' %' : ''}`,
            { maxLength: this.config.maxAxisLabelLength }
          )
        },
        splitLine: {
          show: this.config.gridLine
        }
      }

      // For showing sample size:
      if (
        this.config.showSampleSize &&
        typeof this.globalResults.counts?.per_dataset?.[0] === 'number' &&
        this.config.colorBy.field !== 'segment'
      ) {
        options.name += ` (n=${this.globalResults.counts.per_dataset[0]})`
      }

      return options
    },

    xAxis () {
      let axis = (this.isVertical ? this.ordinalAxis : this.valueAxis)
      return {
        ...axis,
        nameLocation: 'end',
        nameTextStyle: {
          ...axis.nameTextStyle,
          align: 'right',
          verticalAlign: 'top',
          lineHeight: this.isVertical ? 0 : 60 + this.config.axisLabelSize * 2,
          padding: [0, 3, 0, 0]
        }
      }
    },

    yAxis () {
      let axis = this.isHorizontal ? this.ordinalAxis : this.valueAxis
      let nameTextStylePaddingBottom = 0

      return {
        ...axis,
        nameTextStyle: {
          ...axis.nameTextStyle,
          padding: [0, 0, nameTextStylePaddingBottom, 0]
        }
      }
    },

    legend () {
      return {
        show: this.config.showLegend,
        orient: 'horizontal',
        calculable: true,
        horizontal: true,
        left: 'center',
        top: 15,
        padding: [(this.hideControls ? 5 : 30), 0],
        icon: 'circle',
        formatter: this.legendLabelFormatter
      }
    },
    visualMap () {
      return {
        show: this.config.showLegend,
        orient: 'horizontal',
        calculable: true,
        horizontal: true,
        left: 'center',
        top: 15,
        padding: [(this.hideControls ? 5 : 30), 0],
        categories: this.colorClasses,
        dimension: this.colorByMapping,
        inRange: {
          color: this.colorsIncludingCBAndDatasetColors,
          symbol: 'circle',
          symbolSize: 2
        },
        outOfRange: {
          color: '#ff0000', // @sasha
          opacity: 1
        },
        formatter: this.legendLabelFormatter
      }
    },

    minCategoryDimSize () {
      let nCategories = _.uniq(_.map(this.chartDataset, 'x')).length
      let barsPerGroup = 2
      return nCategories * (10 + barsPerGroup * 8) + this.nLegendRows * 24 + 100
    },

    nLegendRows () {
      return (8 * _.sumBy(this.colorClasses, c => this.legendLabelFormatter(c).length) + this.colorClasses.length * 20) / (this.chartContainerWidth - 50)
    },

    // isHorizontal () { return this.config.barOrientation === 'horizontal' },
    // isVertical () { return this.config.barOrientation === 'vertical' },

    isDataLabelInside () { return this.config.labelPosition === 'inside' },
    isDataLabelOutside () { return this.config.labelPosition === 'outside' },

    grid () {
      const { isVertical, isHorizontal, isDataLabelOutside, labelsVerticalFactor } = this
      const { decimalPlaces, dataLabelSize, percentages, labelsEnabled } = this.config

      let left = 15
      let bottom = 35
      let top = ((this.config.showLegend ? Math.ceil(this.nLegendRows) : 0) * 30) + (this.hideControls ? 40 : 65)

      let right = 30

      if (isHorizontal && isDataLabelOutside && labelsEnabled) {
        right += Math.floor((percentages ? ((2 + decimalPlaces) * 12) : 20) * dataLabelSize / 10)
      }
      if (isVertical && isDataLabelOutside && labelsEnabled) {
        top += labelsVerticalFactor * 8
      }

      return { top, left, bottom, right, containLabel: true }
    },

    barWidthEstimation () {
      return this.chartContainerWidth - this.grid.left - 0.1 * this.chartContainerWidth
    },

    labelsVerticalFactor () {
      const { decimalPlaces, dataLabelSize, percentages } = this.config
      return Math.floor((percentages ? (3 + decimalPlaces * 1.3) : 2) * dataLabelSize / 10)
    },

    series () {
      if (!this.initialReady) return []

      // Destructuring variables for reactivity (and simplicity)
      const {
        isVertical
      } = this
      const { labelsTotal, labelsEnabled, dataLabelSize } = this.config

      const generateBasicSeries = () => ({
        type: 'bar',
        top: 30,
        barGap: '10%',
        label: {
          show: labelsEnabled,
          position: this.config.labelPosition === 'inside' ? (isVertical ? 'insideTop' : 'insideRight') : (isVertical ? 'top' : 'right'),
          rotate: isVertical ? 90 : 0,
          offset: [0, 0],
          fontSize: dataLabelSize,
          distance: isVertical ? 12 : 0,
          padding: [2, 3],
          color: labelsTotal ? '#000' : undefined
        },
        tooltip: {
          confine: true,
          formatter: (el) => {
            if (!el.value) return
            let res = `${el.seriesName}<br>${this.$escapeHtml(el.value.topicCategory)}`
            if (!el.value.isCategory) res += ` | ${this.$escapeHtml(el.value.topicLabelOrig)}`
            const perfix = el.seriesIndex === 0 ? 'overall' : 'compare'
            res += `: ${Math.round(el.value[`${perfix}_count`])} (${this.toFixed(el.value[`${perfix}_perc`], this.config.decimalPlaces)}%)`
            if (!el.value.isCategory && el.value.topicDescription) res += `<br>${this.$escapeHtml(el.value.topicDescription)}`
            return res
          }
        },
        animation: false
      })
      const generateBarSeries = () => ({
        name: this.$t('segment_names.overall'),
        encode: {
          y: (isVertical ? `overall_${this.valueField}` : 'x'),
          x: (isVertical ? 'x' : `overall_${this.valueField}`)
        },
        itemStyle: {
          emphasis: {
            barBorderRadius: [5, 5]
          },
          normal: {
            barBorderRadius: [5, 5]
          }
        },
        label: {
          show: labelsEnabled,
          position: this.config.labelPosition === 'inside' ? (isVertical ? 'insideTop' : 'insideRight') : (isVertical ? 'top' : 'right'),
          rotate: isVertical ? 90 : 0,
          offset: [0, 0],
          fontSize: dataLabelSize,
          distance: isVertical ? 12 : 0,
          padding: [2, 3],
          formatter: (el) => {
            let res
            res = this.toFixed(el.value[`overall_${this.valueField}`], this.config.decimalPlaces)
            if (this.valueField === 'perc') {
              res += '%'
            }
            return res
          }
        }
      })
      const generateTickSeries = (tickName) => ({
        name: this.globalTicks[this.groupByColumnValue] || this.$t('segment_names.compare'),
        encode: {
          y: (isVertical ? `compare_${this.valueField}` : 'x'),
          x: (isVertical ? 'x' : `compare_${this.valueField}`)
        },

        stack: 'total',
        itemStyle: {
          emphasis: {
            barBorderRadius: [5, 5]
          },
          normal: {
            barBorderRadius: [5, 5]
          }
        },
        label: {
          show: labelsEnabled,
          position: this.config.labelPosition === 'inside' ? (isVertical ? 'insideTop' : 'insideRight') : (isVertical ? 'top' : 'right'),
          rotate: isVertical ? 90 : 0,
          offset: [0, 0],
          fontSize: dataLabelSize,
          distance: isVertical ? 12 : 0,
          padding: [2, 3],
          formatter: (el) => {
            let res
            res = this.toFixed(el.value[`compare_${this.valueField}`], this.config.decimalPlaces)
            if (this.valueField === 'perc') {
              res += '%'
            }
            return res
          }
        }
      })

      return [
        {
          ...generateBasicSeries(),
          ...generateBarSeries()
        },
        {
          ...generateBasicSeries(),
          ...generateTickSeries()
        }
      ]
    },

    chartOptions () {
      let config = {
        ...this.defaultOptions,
        dataset: [
          {
            source: this.chartDataset
          }
        ],
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
        grid: this.grid,
        backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
      }

      if (
        this.config.colorBy.field === 'segment'
      ) {
        config.color = this.colorsIncludingCBAndDatasetColors
        config.legend = this.legend
      } else {
        config.visualMap = this.visualMap
      }
      return config
    }
  },

  watch: {
    'globalTicks': {
      deep: true,
      handler () {
        this.$emit('updateGlobalChartTicks', _.cloneDeep(this.globalTicks))
      }
    },

    'config.groupByColumn' (newVal, oldVal) {
      if (this.initialReady && !_.isEqual(newVal, oldVal)) {
        this.computeAllResults()
      }
    },

    'config.plotCategoriesFilter.mode' (val, oldVal) {
      if (
        oldVal === 'all' &&
        this.config.xField === 'topicLabel'
      ) {
        this.config.plotCategories = true
        this.config.colorBy.field = 'segment'
      }
    },

    'config.plotTopicsFilter.mode' (val, oldVal) {
      if (
        oldVal === 'all' &&
        this.config.xField === 'topicLabel'
      ) {
        this.config.plotCategories = true
        this.config.colorBy.field = 'segment'
      }
    }
  },

  beforeDestroy () {
    this.$emit('updateGlobalChartTicks', null)
  },

  methods: {
    legendLabelFormatter (lbl) {
      // For the topic / category labels, we have to do some ugly hacking to get the nicely formatted topic name back:
      // Remove all rich text formatting and separate by the topic splitter
      if (this.colorByMapping === 'topicLabel') {
        let lblCleaned = lbl.match(`^(.+)?${CODE_SPLIT_TOK}{cod\\|(.*)}$`, 'g')
        let catCleaned = lbl.match(`^.*\\{(min|pls)\\|\\} (.*)$`, 'g')
        lbl = lblCleaned ? lblCleaned[2] : catCleaned ? catCleaned[2] : lbl
      }

      if (
        this.config.showSampleSize &&
        this.config.colorBy.field === 'segment' &&
        lbl === this.$t('segment_names.overall') &&
        typeof this.globalResults.counts?.per_dataset?.[0] === 'number'
      ) {
        lbl += ` (n=${this.globalResults.counts?.per_dataset?.[0]})`
      } else if (
        this.config.showSampleSize &&
        this.config.colorBy.field === 'segment' &&
        typeof this.globalResults.counts?.per_tick?.[this.groupByColumnValue] === 'number'
      ) {
        lbl += ` (n=${this.globalResults.counts?.per_tick?.[this.groupByColumnValue]})`
      }

      return this.maybeTruncateLabel(lbl, { maxLength: this.config.maxAxisLabelLength })
    },

    /**
    * Returns topic data source
    * corresponding the sentiment shown
    * @return {Object}
    */
    getDataItemSource (data, sentiment) {
      if (
        SENTIMENT_RANGE.indexOf(sentiment) === -1
      ) {
        return data?.results
      } else {
        return data?.sentiments[sentiment]
      }
    },

    /**
     * Check if the user clicked on a category. If yes, toggle its visibility
     * @param  {String} target The label of the ordinal axis value item that was clicked
     */
    maybeToggleEntry (target) {
      // Split the label into the type (CAT or topic ID) and the rest
      let [type, value] = target.split(CODE_SPLIT_TOK)
      if (type === 'CAT') {
        // If it's the category, remove the icon to get the categories real name
        value = value.split('|} ')[1]
        // Toggle the value of the value in the categoriesExpanded array
        if (_.get(this.config.categoriesExpanded, value)) this.config.categoriesExpanded[value] = false
        else this.$set(this.config.categoriesExpanded, value, true)
      }
    },

    chartClick ($evt) {
      let filter, payload
      const sentiment = SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 ? this.sentimentShown : 'any'

      if ($evt.componentType === 'title') {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisLabel' &&
        this.isVertical
      ) {
        this.maybeToggleEntry($evt.value)
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisLabel' &&
        this.isHorizontal
      ) {
        this.maybeToggleEntry($evt.value)
      } else if (
        $evt.componentType === 'series' &&
        $evt.seriesIndex === 1 &&
        $evt.data.topicID === -100
      ) {
        let category = $evt.data.topicCategory
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.category.${encodeStr(category, true)}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(category)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        $evt.componentType === 'series' &&
        $evt.seriesIndex === 1
      ) {
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${$evt.data.topicID}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml($evt.data.topicLabelOrig)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        $evt.componentType === 'series' &&
        $evt.data.topicID === -100
      ) {
        let category = $evt.data.topicCategory
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.category.${encodeStr(category, true)}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(category)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        $evt.componentType === 'series'
      ) {
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${$evt.data.topicID}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml($evt.data.topicLabelOrig)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      }

      if (
        $evt.componentType !== 'series' ||
        $evt.data.count === 0 ||
        !this.isVerbatimEnabled
      ) {
        return
      } else if (
        $evt.componentType === 'series' &&
        $evt.seriesIndex === 0 &&
        $evt.data.overall_count === 0
      ) {
        return
      } else if (
        $evt.componentType === 'series' &&
        $evt.seriesIndex === 1 &&
        $evt.data.compare_count === 0
      ) {
        return
      }

      payload = {
        item: this.id || 'ch__new',
        filters: [
          filter
        ]
      }

      let datasetFilter = {
        type: 'dataset',
        value: [0]
      }
      if (
        $evt.seriesIndex === 0
      ) {
        datasetFilter.htmlText = `<div class="font-weight-medium">${this.$t('verbatim.filters.segment')}</div>:&nbsp;${
          this.maybeTruncateLabel(this.$t('segment_names.overall'), { maxLength: 25 })
        }`
      }

      payload.filters = [
        ...payload.filters,
        datasetFilter
      ]

      if (
        $evt.seriesIndex === 1
      ) {
        const index = this.meta.auxiliary_column_metas.findIndex(({ name }) => name === this.config.groupByColumn)
        payload.filters = [
          ...payload.filters,
          {
            type: 'columns',
            value: `${this.meta.auxiliary_column_metas[index].ref}[text]:${encodeStr(this.globalTicks[this.groupByColumnValue], true)}`,
            htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.segment')}</div>:&nbsp;${
              this.maybeTruncateLabel(this.globalTicks[this.groupByColumnValue], { maxLength: 25 })
            }`
          }
        ]
      }

      if ($evt.componentType === 'series') {
        this.$store.dispatch('verbatimDialog/show', payload)
      }
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartDynamicBar.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartDynamicBar.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />