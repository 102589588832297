export default {
  data () {
    return {
      tempAddOrNew: null
    }
  },

  watch: {
    '$route.params.id' () {
      this.tempAddOrNew = null
    }
  },

  methods: {
    openAddOrEdit (params) {
      this.modalsShown.addOrEdit = true
      this.$set(this, 'tempAddOrNew', params)
    },

    async closeAddOrEdit ({ payload, step, edit }) {
      let el
      if (
        step === 'chart'
      ) {
        this.addDashboardElement({
          ...payload,
          chart: this.preprocessLoadedChart(
            await this.loadChart(payload.chart.id)
          )
        })
      } else if (
        ['text', 'verbatims'].indexOf(step) > -1 &&
        !edit
      ) {
        this.addDashboardElement({
          ...payload,
          chart: this.preprocessLoadedChart(
            await this.saveItem(payload)
          )
        })
      } else if (
        ['text', 'verbatims'].indexOf(step) > -1 &&
        edit
      ) {
        el = await this.patchItem(payload)
        _.find(
          this.dashboardElements,
          item => item.chart.id === payload.id
        )['chart'] = el
      }

      if (!el) return

      // update vuex store after editing
      await this.$store.dispatch(
        `registerManager/update`,
        {
          entityId: el.id,
          entityData: _.cloneDeep(el),
          extraQueryParams: this.extraQueryParams,
          settings: {
            method: 'get'
          }
        }
      )

      // Notify that updated
      if (
        this.dashboard.id !== 'new'
      ) {
        this.$root.snackMsg(this.$t('add_or_edit.messages.updated'))
      }
    }
  }
}