<template>
  <div class="main-content chart-editor mb-0">
    <alert v-if="status.loadingFailed || (datasets.length && firstDataset.status.failed)" type="error">
      <span v-html="this.$t('error', {'step': this.$t('loading.while')})" />
    </alert>

    <loading v-else-if="status.loading"
             :is-loading="true"
             :dont-animate-entry="true"
             :title="$t('loading.title')"
             min-height="300px"
             tagline=""
    />

    <!-- THE ACTUAL CHART ELEMENT -->
    <component
      v-else
      :is="`chart-${chart.type}`"
      v-model="chart.config"
      :id="$route.params.id === 'new' ? 'ch__new' : $route.params.id"
      :read-only="status.readOnly"
      :is-dirty="status.isDirty"
      :name="chart.name"
      :selected-pane-option="selectedPaneOption"
      :show-pane-drawer="showPaneDrawer"
      :current-config="chart.config"
      :type="chart.type"
      :datasets="datasets"
      :prev-chart-configs="prevChartConfigs"
      :all-datasets-ready="allDatasetsReady"
      :invalid-settings-for-chart-type="!requiredSettingsValid"
      :chart-unique-name="chart.typeSelection"
      :fields-to-delete-before-request="fieldsToDeleteBeforeRequest"
      :meta="meta"
      :auxiliary-column-names="auxiliaryColumnNames"
      :sentiment-shown="chart.config.controls ? chart.config.controls.sentimentShown : 'any'"
      :show-top-n-topics="chart.config.controls ? chart.config.controls.showTopNTopics : null"
      :group-by-column-value="chart.config.controls ? chart.config.controls.groupByColumnValue : 0"
      @result="setDatasetResult"
      @editedByUser="updateEditedByUser"
      @updateGlobalChartTicks="updateChartTicks"
      ref="chart"
    >
      <template v-slot:actions>
        <v-alert type="error" text outlined prominent v-if="status.savingFailed">
          {{ $t('error', {'step': $t('save.saving')}) }}
        </v-alert>

        <credit-deduction-info :credits-required="meta.credits_open" />
        <div class="d-flex pt-1">
          <v-text-field
            v-model.trim="chart.name"
            :label="$t('chart_name.label')"
            :placeholder="$t('chart_name.label')"
            :error="!chart.name.length"
            counter
            class="default-bs chart-name"
            maxlength="50"
            hide-details
            outlined
          />
          <div class="d-flex ml-2">
            <v-tooltip bottom :disabled="!status.readOnly">
              <template v-slot:activator="{ on }">
                <div v-on="on" class="d-flex default-bs save-buttons">
                  <v-btn
                    color="primary"
                    @click="save"
                    :error="status.savingFailed"
                    :loading="status.saving || !allDatasetsReady"
                    :disabled="status.readOnly || status.saving || !status.isDirty || !chart.name.length || notEnoughCredits"
                    class="h-100 save-buttons__save"
                    :value="$t('save.title')"
                    elevation="0"
                  >
                    {{ $t('save.title') }}
                  </v-btn>
                  <v-menu
                    dense
                    bottom
                    offset-y
                    content-class="allow-overflow"
                    left
                    elevation="0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="primary"
                        class="h-100 pr-0 pl-0 save-buttons__select"
                        min-width="40"
                        :disabled="$route.params.id === 'new' || status.saving || !allDatasetsReady"
                        elevation="0"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pb-0 pt-0">
                      <v-list-item
                        @click="$event => save($event, true)"
                        :disabled="status.readOnly || !chart.name.length || notEnoughCredits"
                      >
                        <v-list-item-title>
                          {{ $t('save_new') }}
                        </v-list-item-title>
                      </v-list-item>

                      <v-menu v-if="$route.params.id && $route.params.id !== 'new'"
                              v-model="versions.active"
                              min-width="350"
                              dense
                              bottom
                              offset-y
                              content-class="allow-overflow"
                              left
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-title>
                              {{ $t('history.title') }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-subheader>
                          {{ $t('history.title') }}&nbsp;
                          <helptip position="bottom" :width="650" style="font-weight: normal">
                            <span v-html="$t('history.helptip')" />
                          </helptip>
                        </v-subheader>

                        <alert v-if="versions.failed" type="error">
                          <span v-html="this.$t('error', {'step': this.$t('loading.while')})" />
                        </alert>

                        <div v-if="versions.loading" style="display: flex; justify-content: center; margin: 16px 0">
                          <v-progress-circular size="34"
                                               width="5"
                                               indeterminate
                                               color="primary"
                          />
                        </div>

                        <v-list v-else class="pt-0 pb-0">
                          <v-list-item v-for="(v, idx) in versions.items" :key="idx" @click="selectVersion(v)">
                            <v-list-item-title>
                              {{ v.created | datetimeverbose }}
                              <small v-if="idx === 0">({{ $t('history.current') }})</small>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <span v-html="$t('controls.warning_read_only')" />
            </v-tooltip>
          </div>
        </div>
      </template>

      <!-- DATASETS -->
      <template v-slot:datasets="{ datasetProps = {}, disableMasterSettings = false, dsTitle = $t('ds.title_for_aggregated') }">
        <v-card class="chart-config-el mt-0">
          <v-list :disabled="!allDatasetsReady" class="pt-0 pb-0">
            <settings-tile
              :title="$t('master_filters_list.title')"
              style="border-top: none"
              @click.native="showMasterDataset('master_filters')"
            >
              <template v-slot:icon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00005 10.3205L2.18632 3.58124C1.71356 2.91937 2.18668 2 3.00005 2L17.0001 2C17.8134 2 18.2866 2.91937 17.8138 3.58124L13.0001 10.3205V15C13.0001 15.3788 12.7861 15.725 12.4473 15.8944L8.44727 17.8944C7.78237 18.2269 7.00005 17.7434 7.00005 17V10.3205ZM4.94324 4L8.81379 9.41876C8.93493 9.58836 9.00005 9.79158 9.00005 10V15.382L11.0001 14.382V10C11.0001 9.79158 11.0652 9.58836 11.1863 9.41876L15.0569 4L4.94324 4Z" fill="#708189" />
                </svg>
              </template>
            </settings-tile>
          </v-list>
        </v-card>
        <div class="pl-0 pr-0 d-flex align-center mb-1 justify-space-between">
          <div class="d-flex align-center">
            <div class="title mr-1 font-weight-medium">
              {{ isChartAlwaysAggregated ? dsTitle : $t('ds.title_for_non_aggregated') }}
            </div>
            <helptip v-if="!disableMasterSettings" position="bottom" :width="650" class="body-2">
              <span v-html="$t('ds.helptip')" />
            </helptip>
          </div>

          <v-tooltip bottom :max-width="170">
            <template v-slot:activator="{ on }">
              <v-btn
                text
                color="primary"
                small
                :disabled="!allDatasetsReady || disableMasterSettings"
                @click="($options) => showMasterDataset('master_settings', $options)"
                class="m-0 pr-1 pl-1 pt-0 pb-0 mt-1 d-flex align-center master-settings-btn"
                v-on="on"
              >
                <div class="d-flex align-center">
                  {{ $t('ds.show_master') }}
                </div>
              </v-btn>
            </template>
            <span v-if="disableMasterSettings">{{ $t('settings.helptip_not_available') }}</span>
            <span v-else>{{ $t('ds.settings.master_helptip') }}</span>
          </v-tooltip>
        </div>
        <draggable v-model="datasets" group="datasets" handle=".draggable" :animation="150">
          <dataset
            v-for="(ds, idx) in datasets"
            :key="ds.id"
            :settings.sync="ds.settings"
            :filters.sync="ds.filters"
            :result="ds.result"
            :question="ds.question"
            :render-question-name="renderQuestionName(idx)"
            :status="ds.status"
            :draggable="datasets.length > 1"
            :deletable="datasets.length > 1"
            :ds-idx="idx"
            :meta="meta"
            v-bind="{ editable: true, duplicatable: datasets.length < MAX_DATASET_COUNT, ...datasetProps }"
            @show-filters="handleShowDatasetDrawer(idx, 'dataset')"
            @show-settings="handleShowDatasetDrawer(idx, 'dataset')"
            @delete="deleteDataset(idx)"
            @duplicate="duplicateDataset(idx)"
            @show-answers="openVerbatimBrowserForDataset(
              idx
            )"
          />
        </draggable>
        <div class="pb-4">
          <v-tooltip
            v-if="isAddSegmentButtonShown"
            top
            :disabled="datasets.length < MAX_DATASET_COUNT"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  :disabled="datasets.length >= MAX_DATASET_COUNT"
                  @click="openQuestionSelect(true)"
                  class="mt-0 ml-auto mr-auto d-flex default-bs"
                  depressed
                  color="white"
                >
                  <v-icon size="18" class="mr-1">
                    mdi-plus-circle
                  </v-icon>
                  {{ isChartAlwaysAggregated ? $t('ds.add_dataset') : $t('ds.add_segment') }}
                </v-btn>
              </div>
            </template>
            <span>
              {{ $t('ds.max_dataset_count', { count: MAX_DATASET_COUNT }) }}
            </span>
          </v-tooltip>
        </div>
      </template>
      <template
        v-if="selectedPaneOption === 'dataset'"
        v-slot:dataset-settings
      >
        <dataset-settings
          :key="chosenDataset.id"
          :settings.sync="chosenDataset.settings"
          :available-settings="chart.type && CHART_LIST[chart.type].additionalDatasetSettings"
          :disabled-settings="chart.type && CHART_LIST[chart.type].disabledDatasetSettings"
          :question="masterDatasetQuestion"
          :status="chosenDataset.status"
          :is-master="false"
          @modified="setModifiedDataset(true)"
        />
      </template>
      <template
        v-if="selectedPaneOption === 'dataset'"
        v-slot:dataset-filters
      >
        <dataset-filters
          :key="chosenDataset.id"
          :settings.sync="chosenDataset.settings"
          :filters.sync="chosenDataset.filters"
          :question="masterDatasetQuestion"
          :status="chosenDataset.status"
          :ds-idx="chosenDatasetIndex"
          @dirty="$event => { if (!masterDataset.active) chosenDataset.status.dirty = $event }"
          :is-master="false"
          @modified="setModifiedDataset(true)"
          @show-answers="openVerbatimBrowserForDataset(
            chosenDatasetIndex
          )"
        />
      </template>
      <template
        v-if="selectedPaneOption === 'master_settings'"
        v-slot:master-settings
      >
        <dataset-settings
          :key="chosenDataset.id"
          :settings.sync="chosenDataset.settings"
          :available-settings="chart.type && CHART_LIST[chart.type].additionalDatasetSettings"
          :disabled-settings="chart.type && CHART_LIST[chart.type].disabledDatasetSettings"
          :question="masterDatasetQuestion"
          :status="chosenDataset.status"
          :is-master="true"
          :apply-master="applyMasterSettings"
          @modified="setModifiedDataset(true); applyMasterSettings()"
        />
      </template>
      <template
        v-if="selectedPaneOption === 'master_filters'"
        v-slot:master-filters
      >
        <dataset-filters
          :key="chosenDataset.id"
          :settings.sync="chosenDataset.settings"
          :filters.sync="chosenDataset.filters"
          :question="masterDatasetQuestion"
          :status="chosenDataset.status"
          :ds-idx="chosenDatasetIndex"
          :highlight-idx="chosenDataset.highlightIdx"
          :force-new="chosenDataset.forceNew"
          @dirty="$event => { if (!chosenDataset.active) chosenDataset.status.dirty = $event }"
          :is-master="true"
          :apply-master="applyMasterFilters"
          @modified="setModifiedDataset(true)"
          @show-answers="openVerbatimBrowserForDataset(
            chosenDatasetIndex
          )"
          :id="$route.params.id === 'new' ? 'ch__new' : $route.params.id"
        />
      </template>
      <template v-if="chosenDataset && chosenDataset.result && !masterDataset.active" v-slot:matches>
        <button
          class="dataset-results pl-2 pr-2 d-flex align-center justify-center"
          @click="openVerbatimBrowserForDataset(
            chosenDatasetIndex
          )"
          x-small
          elevation="0"
        >
          {{ chosenDataset.result }}
        </button>
      </template>

      <template v-slot:chart-filters="{ hideMasterSettings = false }">
        <div class="d-flex justify-space-between flex-wrap my-n2">
          <div class="my-2">
            <VerticalAccordion
              v-model="controllerAccordionFilterItemShown"
              v-if="isReady"
            >
              <VerticalAccordionItem
                v-if="!hideMasterSettings"
                :is-bordered="false"
                :is-active="!!(masterDataset.filters.length)"
                :tooltip-text="$t('controls_panel.filters.tooltip')"
              >
                <template slot="accordion-trigger">
                  <img src="@/assets/icons/filter.svg">
                  <span
                    v-if="masterDataset.filters.length > 0"
                    class="ml-1"
                  >
                    {{ masterDataset.filters.length }}
                  </span>
                </template>
              </VerticalAccordionItem>

              <template
                v-if="controllerAccordionFilterItemShown === 0"
              >
                <FiltersListAddButton
                  class="mx-1 my-2"
                  :add-options="masterFiltersAddOptions"
                  :disabled="!allDatasetsReady"
                  @add="showMasterDataset('master_filters', { forceNew: true }, $event)"
                />
                <FiltersListItem
                  class="mx-1 my-2"
                  v-for="(item, itemIndex) in masterDataset.filters"
                  :key="itemIndex"
                  :value="masterDataset.filters[itemIndex]"
                  :disabled="!allDatasetsReady"
                  :meta="meta.auxiliary_column_metas"
                  @open="$event => showMasterDataset('master_filters', { filterIndex: itemIndex })"
                  @remove="removeFilter(itemIndex)"
                />
              </template>
            </VerticalAccordion>
          </div>
          <div class="my-2">
            <VerticalAccordion
              v-model="controllerAccordionItemShown"
              v-if="isReady"
            >
              <div
                v-if="isSentimentToggleShown"
                class="d-flex mx-1 my-2"
              >
                <switcher
                  :items="SENTIMENT_RANGE"
                  v-model="chart.config.controls.sentimentShown"
                  style="height: 100%;"
                />
              </div>

              <VerticalAccordionItem
                v-if="isMinimimTopicValueStepperShown"
                :is-active="!!(chart.config.controls.showTopNTopics > 0)"
                :tooltip-text="$t('controls_panel.top_n_topics.tooltip')"
              >
                <template slot="accordion-trigger">
                  <img
                    src="@/assets/icons/breakout.svg"
                  >
                  <span
                    v-if="chart.config.controls.showTopNTopics > 0"
                    class="ml-1"
                  >
                    {{ chart.config.controls.showTopNTopics }}
                  </span>
                </template>
                <template slot="accordion-content">
                  <NumberStepper
                    v-model="chart.config.controls.showTopNTopics"
                    placeholder="00"
                    :debounce-timeout="250"
                    :min="0"
                    :max="availableTopTopicsNumber"
                    :is-bordered="false"
                  >
                    <template slot="prepend">
                      {{ $t('top_topics') }}
                    </template>
                  </NumberStepper>
                </template>
              </VerticalAccordionItem>
              <VerticalAccordionItem
                v-if="isGroupByColumnValueSelectShown"
                :is-active="true"
                :tooltip-text="$t('controls_panel.segmented_value.tooltip')"
              >
                <template slot="accordion-trigger">
                  <img src="@/assets/icons/scale.svg">
                </template>
                <template slot="accordion-content">
                  <v-autocomplete
                    v-model="chart.config.controls.groupByColumnValue"
                    :items="DYBARValues"
                    :label="$t('segment_by.value_title')"
                    item-value="value"
                    item-text="name"
                    hide-details
                    class="segmented-value-select"
                    outlined
                    dense
                    style="width: 220px;"
                    height="32"
                  />
                </template>
              </VerticalAccordionItem>
            </VerticalAccordion>
          </div>
        </div>
      </template>

      <template v-slot:chart-type-selection>
        <settings-drawer-item
          v-for="(group, index) in availableChartsList"
          :key="index"
          :title="`${$t('chart_type.label')} - ${$t(`chart_type.${index}`)}`"
        >
          <template slot="content">
            <div class="d-flex flex-wrap chart-types__group">
              <div
                v-for="(chartSettings, chartIndex) in group"
                class="d-flex align-center justify-space-between chart-types"
                :class="chartIndex"
                :key="chartIndex"
              >
                <div
                  @click="selectChartType(chartIndex)"
                >
                  <ChartTypePreview
                    :chart-type="chartIndex"
                    :chart-settings="chartSettings"
                    :active="chart.typeSelection === chartIndex"
                    :disabled="!chartSettings.enabled"
                  />
                </div>
              </div>
            </div>
          </template>
        </settings-drawer-item>

        <settings-drawer-item :title="$t('chart_type.template')" v-if="templates.length">
          <template slot="helptip">
            <div class="d-flex align-center">
              <v-btn small color="error" text class="pr-2 pl-2" @click="maybeSelectTemplate(() => selectTemplate(getCleanTemplate()))">
                {{ $t('settings.reset') }}
              </v-btn>
              <helptip position="bottom" class="mr-2 v-label" style="font-size: 16px; text-transform: none;">
                <span>
                  {{ $t('templates.subtitle') }}
                </span>
              </helptip>
            </div>
          </template>
          <template slot="content">
            <loading v-if="chart.typeSelection"
                     :is-loading="chart.typeSelection && !isReady"
                     :dont-animate-entry="true"
                     :title="$t('loading.title')"
                     tagline=""
            >
              <!-- TEMPLATE SELECTOR -->
              <div class="template-selector m-0">
                <div>
                  <v-radio-group
                    column
                    hide-details
                    class="mb-0 mt-0 pt-0 pb-0 select-template"
                    v-model="selectedDatasetTemplate"
                    @change="template => maybeSelectTemplate(() => selectTemplate(template))"
                  >
                    <div
                      class="d-flex align-start justify-space-between mb-2 auxiliary_settings"
                      v-for="(template, templateIdx) in templates"
                      :key="templateIdx"
                    >
                      <v-radio
                        :value="template"
                        class="mb-0 w-100"
                      >
                        <template slot="label">
                          <v-list-item-content class="pt-1 pb-1">
                            <v-list-item-title v-html="template.title" />
                            <div v-if="template.definition.type === 'auxiliary_nps' && potentialNPSColumnSelectItems.length" class="aux-custom-container mt-3 d-flex align-center flex-column" @click.stop>
                              <v-autocomplete
                                v-model="template.definition.col"
                                :items="potentialNPSColumnSelectItems"
                                :label="$t('templates.auxiliary_nps.label_col_select')"
                                :class="{ shake: template.definition.invalid }"
                                class="w-100 mb-3"
                                :error="template.definition.invalid"
                                item-text="text"
                                item-value="value"
                                hide-details
                                dense
                                outlined
                                @change="maybeSelectTemplate(() => selectTemplate(template))"
                              />
                            </div>
                            <div v-if="template.definition.type === 'auxiliary_choice' && templateAuxiliaryCategoricalColumns.length" class="aux-custom-container mt-3 d-flex align-center flex-column">
                              <v-autocomplete
                                v-model="template.definition.col"
                                :items="templateAuxiliaryCategoricalColumns"
                                :label="$t('templates.auxiliary_choice.label_col_select')"
                                :class="{ shake: template.definition.invalid }"
                                class="w-100 mb-3"
                                :error="template.definition.invalid"
                                item-text="text"
                                item-value="value"
                                hide-details
                                dense
                                outlined
                                @change="maybeSelectTemplate(() => selectTemplate(template))"
                              />
                            </div>

                            <div v-if="template.definition.type === 'auxiliary_number' && templateAuxiliaryNumberColumns.length" class="aux-custom-container mt-3 d-flex align-center flex-column">
                              <v-autocomplete
                                v-model="template.definition.col"
                                :items="templateAuxiliaryNumberColumns"
                                :label="$t('templates.auxiliary_number.label_col_select')"
                                :class="{ shake: template.definition.invalid }"
                                class="w-100 mb-3"
                                :error="template.definition.invalid"
                                item-text="text"
                                item-value="value"
                                hide-details
                                dense
                                outlined
                                @change="maybeSelectTemplate(() => selectTemplate(template))"
                              />
                              <v-text-field
                                v-if="template.definition.col >= 0 && meta.auxiliary_column_metas[template.definition.col].type === 'number'"
                                v-lazy-model="`templates[${templateAuxiliaryIndex}].definition.nSplits`"
                                :value="templates[templateAuxiliaryIndex].definition.nSplits"
                                :label="$t('templates.auxiliary_number.label_n_splits')"
                                type="number"
                                :min="2"
                                :max="templateAuxiliaryMaxSplits"
                                hide-details
                                dense
                                outlined
                                class="w-100"
                              />
                            </div>
                          </v-list-item-content>
                        </template>
                      </v-radio>
                      <helptip position="bottom" class="ml-3" v-if="template.subtitle">
                        <v-list-item-subtitle v-html="template.subtitle" />
                      </helptip>
                    </div>
                  </v-radio-group>
                </div>
              </div>
            </loading>
          </template>
        </settings-drawer-item>
      </template>
      <template v-slot:score="{ warnings }">
        <settings-drawer-item :title="$t('chart_type.settings')" v-if="isReady && chartTypeRequiresSettings">
          <!-- SETTINGS SELECTOR -->
          <template slot="content">
            <div class="settings-selector">
              <div v-if="isNpsChart">
                <label
                  class="mt-3 mb-4 d-block hover new-feature-tip"
                  @click="showPaneDrawer('score')"
                  v-if="selectedPaneOption === 'type'"
                  v-html="$t('chart_type.analysis_tip')"
                />
                <div class="d-flex align-center justify-space-between">
                  <v-select
                    v-model="settings.scoring_column"
                    :items="potentialNumberColumnSelectItems"
                    :label="`${$t('settings.column.label')}`"
                    item-text="text"
                    style="max-width: 89%;"
                    item-value="value"
                    hide-details
                    :error="typeof settings.scoring_column !== 'number' || !requiredSettingsValidations['SETT_NPS']"
                    outlined
                    dense
                  />
                  <helptip position="bottom" class="ml-3">
                    <span v-html="$t('settings.column.helptip') + $t('settings.column.supported_formats', { csat: CSATTermsFormatted })" />
                  </helptip>
                </div>
                <div class="d-flex align-center justify-space-between mt-4">
                  <v-select
                    v-model="settings.scoring_type"
                    :items="scoringTypes"
                    :label="$t('settings.column.type')"
                    hide-details
                    :error="!settings.scoring_type"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                  />
                  <helptip position="bottom" class="ml-3">
                    <span v-html="$t('settings.column.type_helptip')" />
                  </helptip>
                </div>
              </div>
              <div v-if="isDriverChart">
                <div class="d-flex align-center justify-space-between">
                  <v-select
                    v-model="settings.driver_target_column"
                    :items="potentialDriverColumnSelectItems"
                    :label="$t('settings.driver_column.label')"
                    style="max-width: 89%;"
                    item-text="text"
                    item-value="value"
                    :error="!settings.driver_target_column || !!warnings.invalidDriverColumnDatasets.length || !requiredSettingsValidations['SETT_DRIVER']"
                    hide-details
                    outlined
                    dense
                  />
                  <helptip position="bottom" class="ml-3">
                    <span v-html="$t('settings.driver_column.helptip') + $t('settings.driver_column.supported_formats', { csat: CSATTermsFormatted })" />
                  </helptip>
                </div>
              </div>
            </div>
          </template>
        </settings-drawer-item>
      </template>
    </component>

    <!-- TEMPLATE SELECT CONFIRM DIALOG -->
    <v-dialog
      :value="confirmTemplateDialogCasted"
      @keydown.esc="() => {
        confirmTemplateDialog = false
        selectedDatasetTemplate = null
      }"
      width="500px"
    >
      <v-card class="chart-download-dialog d-flex">
        <v-card-title primary-title class="headline grey lighten-4">
          {{ $t('template_select.title') }}
        </v-card-title>
        <v-card-text class="pt-2 pb-2">
          {{ confirmTemplateDialog }}
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end w-100">
            <v-btn
              outlined
              @click="() => {
                confirmTemplateDialog = false
                selectedDatasetTemplate = null
              }"
              class="mr-2"
            >
              {{ $t('close') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="() => {
                confirmTemplateDialogCallback()
                confirmTemplateDialog = false
                confirmTemplateDialogCallback = false
              }"
            >
              {{ $t('actions.confirm') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- QUESTION SELECT DIALOG -->
    <v-dialog
      v-model="questionSelect.active"
      content-class="dashboard-filter-editor project-selector"
      max-width="100%"
      @keydown.esc="datasets.length && closeQuestionSelect(false)"
      :persistent="!datasets.length"
    >
      <div>
        <div class="dashboard-filter-editor__title w-100 d-flex align-center justify-space-between">
          {{ $t('question_select.title') }}
          <v-btn
            @click.native="closeQuestionSelect(false)"
            :disabled="!datasets.length"
            icon
            small
          >
            <v-icon
              :size="20"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="dashboard-filter-editor__content">
          <project-list
            :project-to-focus="questionSelect.initialProjectFocus"
            filters-internal
            in-dialog
            ref="projectList"
          >
            <template slot="action" slot-scope="{ question }">
              <v-btn
                v-if="isSelectQuestionButtonEnabled(question.id)"
                outlined
                color="primary"
                @click="closeQuestionSelect(question)"
              >
                {{ $t('question_select.select') }}
              </v-btn>
              <b v-else class="d-block" style="margin-top: 7px;">
                {{ $t('question_select.select_disabled') }}
              </b>
            </template>
          </project-list>
        </div>
        <div class="dashboard-filter-editor__actions d-flex justify-space-between">
          <div />
          <div>
            <v-btn
              outlined
              @click="closeQuestionSelect(false)"
              :disabled="!datasets.length"
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- VERBATIM BROWSER V2 (WITH SENTIMENT) -->
    <verbatim-browser-v2-dialog />
  </div>
</template>

<script>

import Vuex from 'vuex'

import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import colorPalettes from '@/mixins/colorPalettes'
import answerFilters from '@/mixins/answerFilters'
import chartListMixin from '@/mixins/chartList'

import Dataset from '@/components/visualize/Dataset'
import DatasetSettings from '@/components/visualize/DatasetSettings'
import VerbatimBrowserv2Dialog from '@/components/VerbatimBrowserv2/VerbatimBrowserv2Dialog'
import DatasetFilters from '@/components/visualize/DatasetFilters'

// chart components
import ChartBar from '@/components/visualize/ChartBar'
import ChartLinePie from '@/components/visualize/ChartLinePie'
import ChartDateLinePie from '@/components/visualize/ChartDateLinePie'
import ChartRowsCountBar from '@/components/visualize/ChartRowsCountBar'
import ChartTreemap from '@/components/visualize/ChartTreemap'
import ChartGraph from '@/components/visualize/ChartGraph'
import ChartSentimentBar from '@/components/visualize/ChartSentimentBar'
import ChartScore from '@/components/visualize/ChartScore'
import ChartDriverScatter from '@/components/visualize/ChartDriverScatter'
import ChartDynamicBar from '@/components/visualize/ChartDynamicBar'
// end chart components

import ProjectList from '@/components/ProjectList'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'
import FiltersListAddButton from '@/components/FiltersList/FiltersListAddButton'
import FiltersListItem from '@/components/FiltersList/FiltersListItem'
import SettingsTile from '@/components/visualize/SettingsTile'
import Switcher from '@/components/customUi/switcher/Switcher.vue'
import NumberStepper from '@/components/customUi/numberStepper/NumberStepper.vue'
import ChartTypePreview from '@/components/customUi/chartTypePreview/ChartTypePreview'

import VerticalAccordion from '@/components/customUi/verticalAccordion/VerticalAccordion.vue'
import VerticalAccordionItem from '@/components/customUi/verticalAccordion/VerticalAccordionItem.vue'

import { modifyChartPayloadConfig } from '@/utils/chartConfigModificator.js'

import { generateSigleFunctionCallComputedWithSameArgs } from '@/utils/genericComputed.js'

import Draggable from 'vuedraggable'

import {
  QUESTION_CATEGORY_LIST,
  /*
  * use types of charts
  * can differs with unique name
  */
  CHART_TYPES_TO_API_ENUM,
  API_ENUM_TO_CHART_TYPES,
  CHART_TYPE_BAR,
  CHART_TYPE_SENTIMENT_BAR,
  CHART_TYPE_LINE_PIE,
  CHART_TYPE_DATE_LINE_PIE,
  CHART_TYPE_ROWS_COUNT_BAR,
  CHART_TYPE_TREEMAP,
  CHART_TYPE_GRAPH,
  CHART_TYPE_DRIVER_SCATTER,
  CHART_TYPE_SCORE,
  CHART_TYPE_DYNAMIC_BAR,
  /*
  * use unique names of charts
  */
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_ROWS_COUNT_BAR,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_DRIVER_SCATTER,
  CHART_UNIQUE_SENTIMENT,
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_DYNAMIC_BAR,
  /*
  * vars on scoring types
  */
  SCORE_CHARTS,
  CODE_CHARTS,
  SATISFACTION_SCORING_TYPES,
  OVERVIEW_CHARTS,
  /*
  * the others
  */
  DS_FILTERS_TEMPLATE,
  DS_SETTINGS_TEMPLATE,
  FILTER_OPTIONS_TYPES,
  SENTIMENT_RANGE,
  CHART_UNIQUE_LINE_PIE,
  MAX_DATASET_COUNT
} from '@/settings/constants'
import CHART_LIST from '@/settings/chartList'

import { MAX_VALS_TO_SUGGEST_SEGMENT } from '@/settings/overridable'

const { STANDARD_FILTERS_WITH_TOPICS } = FILTER_OPTIONS_TYPES

let OLD_CHART_API_OBJECT = null
let RES_CHART_API_OBJECT = null

const IGNORE_SETTINGS_KEYS = new Set(['name', 'color'])

/*
* define chart types with unique names and not unique types
*/
function generateSentimentRangeTuned () {
  return [
    'any',
    ...SENTIMENT_RANGE
  ].map((item, index) => {
    let switcherItemData = {
      value: item,
      label: this.$i18n.t(`topics.sentiment_${item}`).label,
      icon: {
        image: require(`@/assets/icons/sentiment_${item}.svg`),
        label: this.$i18n.t(`sentiment_toggle.${item}`)
      }
    }

    return switcherItemData
  })
}

export default {
  codit: true,
  name: 'ChartEditor',
  components: {
    'project-list': ProjectList,
    'dataset': Dataset,
    'dataset-settings': DatasetSettings,
    'dataset-filters': DatasetFilters,
    'draggable': Draggable,
    'settings-drawer-item': SettingsDrawerItem,
    'verbatim-browser-v2-dialog': VerbatimBrowserv2Dialog,
    FiltersListAddButton,
    FiltersListItem,
    'settings-tile': SettingsTile,
    Switcher,
    NumberStepper,
    ChartTypePreview,
    VerticalAccordion,
    VerticalAccordionItem,
    [`chart-${CHART_TYPE_BAR}`]: ChartBar,
    [`chart-${CHART_TYPE_SENTIMENT_BAR}`]: ChartSentimentBar,
    [`chart-${CHART_TYPE_LINE_PIE}`]: ChartLinePie,
    [`chart-${CHART_TYPE_DATE_LINE_PIE}`]: ChartDateLinePie,
    [`chart-${CHART_TYPE_ROWS_COUNT_BAR}`]: ChartRowsCountBar,
    [`chart-${CHART_TYPE_TREEMAP}`]: ChartTreemap,
    [`chart-${CHART_TYPE_GRAPH}`]: ChartGraph,
    [`chart-${CHART_TYPE_SCORE}`]: ChartScore,
    [`chart-${CHART_TYPE_DRIVER_SCATTER}`]: ChartDriverScatter,
    [`chart-${CHART_TYPE_DYNAMIC_BAR}`]: ChartDynamicBar
  },
  mixins: [
    auxiliaryColumnsMixin,
    colorPalettes,
    answerFilters,
    chartListMixin
  ],

  /**
   * Warn about losing changes when going to another page (in the app)
   */
  beforeRouteLeave (to, from, next) {
    let doIt = true

    if (to.path.includes('/values')) next(doIt)

    // If there is an active chart, confirm navigating away
    if (this.chart.type && this.status.isDirty) doIt = confirm(this.$t('confirm_navigate_away'))
    next(doIt)
  },

  /**
   * Warn about losing changes when going back to the chart editing overview page
   */
  beforeRouteUpdate (to, from, next) {
    let doIt = true

    if (to.path.includes('/values')) next(doIt)

    // If there is an active chart, confirm navigating away
    if (!this.status.programmaticRouteChange && this.chart.type && this.status.isDirty) doIt = confirm(this.$t('confirm_navigate_away'))

    next(doIt)
    if (doIt) this.setupInternalStateAfterNavigation(to.params, to.query)
  },

  data () {
    return {
      MAX_DATASET_COUNT,
      CHART_TYPES_TO_API_ENUM,
      questionSelect: {
        active: false,
        loading: true,
        initialProjectFocus: null,
        idToFocus: null,
        fromUserAction: false
      },

      projects: [],

      cache: {},

      datasets: [],

      masterDataset: {
        active: false,
        highlightIdx: null,
        forceNew: false,
        settings: _.cloneDeep(DS_SETTINGS_TEMPLATE),
        filters: []
      },

      chartTemplate: {
        id: 0,
        name: this.$t('chart_name.default'),
        type: CHART_TYPE_BAR,
        typeSelection: CHART_UNIQUE_BAR,
        selectedDatasetTemplate: null,
        config: {}
      },

      chart: {
        type: CHART_TYPE_BAR,
        typeSelection: CHART_UNIQUE_BAR
      },

      versions: {
        active: false,
        items: [],
        loading: false,
        failed: false
      },

      status: {
        isDirty: false,
        saving: false,
        savingFailed: false,
        programmaticRouteChange: false,
        loadingFailed: false,
        loading: false,
        initialLoadingCompleted: true,
        readOnly: false
      },

      dsIDHead: 0,

      templates: [],
      selectedDatasetTemplate: null,
      settings: {
        driver_target_column: null,
        scoring_type: null,
        scoring_column: null
      },
      selectedPaneOption: this.$route.params.id === 'new' ? 'type' : '',
      chosenDataset: null,
      datasetModified: !(this.$route.params.id === 'new'),
      confirmTemplateDialog: false,
      confirmTemplateDialogCallback: false,

      prevChartConfigs: {},
      editedByUser: false, // use it to know if use changed range boundaries in NPS
      meta: {},

      SENTIMENT_RANGE: generateSentimentRangeTuned.call(this),
      sentimentShownIndex: null,

      CHART_LIST, // to use it in template
      CHART_UNIQUE_BAR,

      controllerAccordionItemShown: null,
      controllerAccordionFilterItemShown: 0,

      internalChartTicks: null
    }
  },

  computed: {
    ...Vuex.mapGetters({
      userCreditsRemaining: 'userCreditsRemaining',
      questionsMetaData: 'questionManager/overall'
    }),

    ...generateSigleFunctionCallComputedWithSameArgs({
      potentialDateColumnSelectItems: 'getPotentialDateColumnSelectItems',
      potentialNumberColumnSelectItems: 'getPotentialScoringColumnSelectItems',
      potentialDriverColumnSelectItems: 'getPotentialDriverColumnSelectItems',
      potentialNPSColumns: 'getPotentialNPSColumns',
      potentialCSATColumns: 'getPotentialCSATColumns',
      potentialNumberColumns: 'getPotentialNumberColumns',
      potentialNPSColumnSelectItems: 'getPotentialNPSColumnSelectItems',
      potentialCSATColumnSelectItems: 'getPotentialCSATColumnSelectItems',
      potentialDYBARSelectItems: 'getPotentialDYBARColumns'
    })(function () {
      return [
        this.meta?.auxiliary_column_metas,
        this.auxiliaryColumnNames
      ]
    }),

    auxiliaryColumnNames () {
      return _.map(this.meta.auxiliary_column_metas, 'name')
    },

    availableTopTopicsNumber () {
      const topicsNumber = this.meta.topics_complete_union.length

      if (
        topicsNumber > 99
      ) {
        return 99
      }
      return topicsNumber
    },

    /*
    * Define groups of charts to set order of rendering
    * @return {Array} Grouped list of charts to use
    */
    availableChartsList () {
      let list = Object.fromEntries(
        Object.entries(CHART_LIST).map(
          ([key, value]) => [key, {
            ...value,
            enabled: true
          }]
        )
      )
      list = {
        CODE_CHARTS: _.pickBy(list, (item, index) => CODE_CHARTS.indexOf(index) > -1),
        SCORE_CHARTS: _.pickBy(list, (item, index) => SCORE_CHARTS.indexOf(index) > -1),
        OVERVIEW_CHARTS: _.pickBy(list, (item, index) => OVERVIEW_CHARTS.indexOf(index) > -1)
      }

      delete list.CODE_CHARTS[CHART_UNIQUE_LINE_PIE]

      /*
      * Manage features availability,
      * depends on data.
      * If no data for that type of chart,
      * we prevent the user from using this chart
      */
      list.CODE_CHARTS[CHART_UNIQUE_DATE_LINE_PIE].enabled = !!this.potentialDateColumnSelectItems.length
      list.CODE_CHARTS[CHART_UNIQUE_DYNAMIC_BAR].enabled = !!this.potentialDYBARSelectItems?.length
      list.OVERVIEW_CHARTS[CHART_UNIQUE_ROWS_COUNT_BAR].enabled = !!this.potentialDateColumnSelectItems.length
      list.SCORE_CHARTS[CHART_UNIQUE_SCORE].enabled = !!this.potentialNumberColumnSelectItems.length
      list.SCORE_CHARTS[CHART_UNIQUE_DRIVER_SCATTER].enabled = !!this.potentialDriverColumnSelectItems.length

      return list
    },

    isChartAlwaysAggregated () {
      return [CHART_UNIQUE_BAR, CHART_UNIQUE_DRIVER_SCATTER].indexOf(this.chart.typeSelection) === -1
    },

    categoryOrTopicsApplied () {
      const masterFilterTypes = _(this.chart.config.filters).map('type').value()
      return masterFilterTypes.includes('topics') || masterFilterTypes.includes('categories')
    },

    generateDefaultChartName () {
      return this.$t('chart_name.default')
    },

    confirmTemplateDialogCasted () {
      return !!this.confirmTemplateDialog
    },

    /**
     * List of available auxiliary columns, in format which can be passed to by v-select
     * @return {Array} The list
     */
    auxiliaryOptions () {
      return this.getAuxiliaryOptions(this.auxiliaryColumnNames)
    },

    /**
     * Return actual number of splits at auxiliary template
     * @return {Number}
     */
    auxiliaryTemplateSplitsValue () {
      return this.templates[this.templateAuxiliaryIndex]?.definition?.nSplits
    },

    /**
     * Return actual auxiliary template
     * @return {Number}
     */
    auxiliaryTemplateChoosen () {
      return this.templates?.[this.templateAuxiliaryIndex]?.definition?.col
    },

    /**
     * Return maximum number of available splits
     * @return {Number}
     */
    templateAuxiliaryMaxSplits () {
      /*
      * in case data is not ready return maximin allowed value
      */
      if (
        !this.templates.length ||
        !this.meta.auxiliary_column_metas.length
      ) {
        return MAX_VALS_TO_SUGGEST_SEGMENT
      }
      /*
      * calculate actual value
      */
      const col = this.meta.auxiliary_column_metas[this.templates[this.templateAuxiliaryIndex]?.definition?.col]
      return this.calculateAuxiliaryMaxSplits(col?.min, col?.max)
    },

    /**
     * Index of the chosen dataset, used in dataset filters to fetch counts of each filter
     */
    chosenDatasetIndex () {
      return _.findIndex(this.datasets, this.chosenDataset)
    },

    /**
     * List of potential column analysis types for score chart
     * it combines ENUM values with dictionary
     * @return {Array} Actual list of scoring types for satisfaction rate
     */
    scoringTypes () {
      return SATISFACTION_SCORING_TYPES.reduce(
        (sum, item) => [
          ...sum,
          {
            value: item,
            label: this.$t(`satisfactionScoringTypes.${item}`)
          }
        ], [])
    },

    isReady () {
      return !this.datasets.length ? false : !this.datasets[0].status.loading && this.meta.auxiliary_column_metas
    },

    /**
     * If all datasets are loaded and the results computed
     * @return {Boolean}
     */
    allDatasetsReady () {
      return _.every(this.datasets, ds => !ds.status.loading && !ds.status.dirty)
    },

    /**
     * If all the datasets are loaded
     * @return {Boolean}
     */
    allDatasetsLoaded () {
      return _.every(this.datasets, ds => !ds.status.loading)
    },

    /**
     * Pseudo question, which is passed to master dataset
     * Contains auxiliary columns which are valid across all datasets
     * @return {object} Object describing the pseudo question
     */
    masterDatasetQuestion () {
      if (!this.allDatasetsLoaded) {
        return {}
      } else {
        return {
          auxiliary_column_metas: this.meta.auxiliary_column_metas
        }
      }
    },

    /**
     * Object representing the chart in the way it is saved
     * TODO: Export to mixin to share functionality with chart.js
    */
    /**
    * Prepares chart data from frontend for sending it to backend
    * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
    * @param {Object} chart - Current chart reference
    * @return {Object} Combined data over rendering the chart
    */
    chartAPIObject () {
      let { name, typeSelection } = this.chart
      return {
        name,
        type: CHART_TYPES_TO_API_ENUM[typeSelection],
        config: this.getChartConfig(),
        datasets: _.map(_.cloneDeep(this.datasets), ({ filters, settings, question }) => ({
          question: question,
          filters: filters.filter(f => !!f.type), // don't save empty filters
          settings: settings
        }))
      }
    },

    /**
     * The columns which are available for selection when using the custom auxiliary template
     * "string" datasets with fewer than MAX_VALS_TO_SUGGEST_SEGMENT values
     * @return {Array} Array of objects for select: [{ text: 'colName', value: colIdx }, { ... }]
    */
    templateAuxiliaryCategoricalColumns () {
      return _(this.auxiliaryColumnNames).map((colName, colIdx) => ({
        text: colName,
        value: colIdx
      })).filter(col => {
        let ct = this.meta.auxiliary_column_metas[col.value]
        return (ct.type === 'string' && ct.few && ct.vals.length <= MAX_VALS_TO_SUGGEST_SEGMENT && ct.vals.length > 0)
      }).value()
    },

    /**
     * The columns which are available for selection when using the numerical auxiliary template
     *  "number" datasets
     * @return {Array} Array of objects for select: [{ text: 'colName', value: colIdx }, { ... }]
     */
    templateAuxiliaryNumberColumns () {
      return _(this.auxiliaryColumnNames).map((colName, colIdx) => ({
        text: colName,
        value: colIdx
      })).filter(col => {
        let ct = this.meta.auxiliary_column_metas[col.value]
        return ct.type === 'number' && ct.min !== ct.max
      }).value()
    },

    /**
     * Find the actual index of auxiliary template
     * @return {Number}
     */
    templateAuxiliaryIndex () {
      return _.findIndex(this.templates, (item) => item.definition.type === 'auxiliary_number')
    },

    /**
     * If the currently selected chart type requires any settings
     * @return {Boolean}
     */
    chartTypeRequiresSettings () {
      return _.keys(CHART_LIST[this.chart.typeSelection].requiredSettings).length > 0
    },

    /**
     * Validates all settings required for this chart
     * @return {Object}
     */
    requiredSettingsValidations () {
      const requiredSettings = CHART_LIST[this.chart.typeSelection].requiredSettings
      return {
        SETT_SCORING_TYPE: !('SETT_SCORING_TYPE' in requiredSettings) || this.settings.scoring_type,
        SETT_NPS: !('SETT_NPS' in requiredSettings) ||
          // Check that a valid column has been selected as column
          (this.settings.scoring_column >= 0 && this.auxiliaryColumnNames && this.settings.scoring_column < this.auxiliaryColumnNames.length),
        SETT_DRIVER: !('SETT_DRIVER' in requiredSettings) ||
          // Check that a valid column has been selected as driver_target_column
          (this.auxiliaryColumnNames && this.settings.driver_target_column >= 0 && this.settings.driver_target_column < this.auxiliaryColumnNames.length),
        SETT_DATE_COLUMN: !('SETT_DATE_COLUMN' in requiredSettings) ||
          // Check that a valid column has been selected as date aggregation column
          (this.auxiliaryColumnNames && this.chart.config.dateColumn && this.auxiliaryColumnNames.includes(this.chart.config.dateColumn))
      }
    },

    /**
     * If all settings required for this chart are validly chosen by the user
     * @return {Boolean}
     */
    requiredSettingsValid () {
      return _.every(
        CHART_LIST[this.chart.typeSelection].requiredSettings,
        (_, settingName) => this.requiredSettingsValidations[settingName]
      )
    },

    DYBARValues () {
      if (this.chart.typeSelection !== CHART_UNIQUE_DYNAMIC_BAR) return
      return this.internalChartTicks
        ?.map((item, index) => ({
          name: item,
          value: index
        }))
    },

    /**
     * If the user does not have enough credits to save / download this chart
     * @return {Boolean}
     */
    notEnoughCredits () {
      return this.meta.credits_open > this.userCreditsRemaining
    },
    /**
    * Calcs if chart is satisfaction chart
    * @return {Boolean} Says if it is
    */
    isNpsChart () {
      const chartType = this.chart.type
      const requiredSettings = chartType && CHART_LIST[chartType].requiredSettings

      return requiredSettings && requiredSettings.SETT_NPS
    },

    /**
    * Calcs if chart uses driver chart
    * @return {Boolean} Says if it is
    */
    isDriverChart () {
      const chartType = this.chart.type
      const requiredSettings = chartType && CHART_LIST[chartType].requiredSettings

      return requiredSettings && requiredSettings.SETT_DRIVER
    },

    MAX_VALS_TO_SUGGEST_SEGMENT: {
      cache: false,
      get: () => MAX_VALS_TO_SUGGEST_SEGMENT
    },

    /**
     * Returns this.datasets[0] or a empty dataset template to prevent "this.datasets[0] is undefined" error
     * @return {Object} The first data set
     */
    firstDataset () {
      return this.datasets[0] ? this.datasets[0] : {
        id: 0,
        settings: _.cloneDeep(DS_SETTINGS_TEMPLATE),
        filters: _.cloneDeep(DS_FILTERS_TEMPLATE),
        status: { loading: true, failed: false, dirty: true },
        question: null,
        answers: [],
        result: null
      }
    },

    masterFiltersAddOptions () {
      return this.filterOptionsSelect(this.masterDataset.filters, this.auxiliaryOptions, STANDARD_FILTERS_WITH_TOPICS)
        .map(f => this.filterOptionsTransformer(f))
    },

    /*
    * define some properties of config to delete
    * the component inherits most of features of NPS and uses it further in mixins and so on
    * so to use it is necessary to keep code comparable with chart.js
    * and to make requests to backend clearer
    * @return {Array} List of fields to delete
    */
    fieldsToDeleteBeforeRequest () {
      if (
        this.chart.typeSelection === CHART_UNIQUE_SENTIMENT
      ) {
        return ['rangeBoundries']
      }
      return null
    },
    /*
    * check if "add segment" button shown
    * for some charts multiply segments are not available
    * @return {Boolean} If show button
    */
    isAddSegmentButtonShown () {
      return [
        CHART_UNIQUE_GRAPH,
        CHART_UNIQUE_SENTIMENT_BAR,
        CHART_UNIQUE_ROWS_COUNT_BAR,
        CHART_UNIQUE_DYNAMIC_BAR
      ].indexOf(this.chart.typeSelection) === -1
    },

    isSentimentToggleShown () {
      return [
        CHART_UNIQUE_BAR,
        CHART_UNIQUE_DATE_LINE_PIE,
        CHART_UNIQUE_TREEMAP,
        CHART_UNIQUE_GRAPH,
        CHART_UNIQUE_DYNAMIC_BAR
      ].indexOf(this.chart.typeSelection) > -1 &&
      this.meta.topics_complete_union?.some(
        ({ sentiment_enabled }) => !!sentiment_enabled // eslint-disable-line
      ) &&
      this.chart?.config?.controls?.sentimentShown
    },

    isMinimimTopicValueStepperShown () {
      return [
        CHART_UNIQUE_BAR,
        CHART_UNIQUE_DATE_LINE_PIE,
        CHART_UNIQUE_TREEMAP,
        CHART_UNIQUE_GRAPH,
        CHART_UNIQUE_SENTIMENT_BAR,
        CHART_UNIQUE_DRIVER_SCATTER
      ].indexOf(this.chart.typeSelection) > -1 &&
      this.meta?.topics_complete_union?.length &&
      typeof this.chart?.config?.controls?.showTopNTopics !== 'undefined'
    },

    isGroupByColumnValueSelectShown () {
      return [
        CHART_UNIQUE_DYNAMIC_BAR
      ].indexOf(this.chart.typeSelection) > -1
    }
  },

  watch: {
    'chart.config.groupByColumn' () {
      this.$set(this.chart.config.controls, 'groupByColumnValue', 0)
    },

    async 'chart.typeSelection' () {
      if (!this.isReady) return

      if (
        this.chart.typeSelection === CHART_UNIQUE_DYNAMIC_BAR
      ) {
        this.controllerAccordionItemShown = 0
      } else {
        this.controllerAccordionItemShown = null
      }
      this.controllerAccordionFilterItemShown = 0

      // wrapped to let the chart emit default config
      this.$nextTick(async () => {
        await this.updateMeta(this.chart.id === 0 ? 'ch__' : this.chart.id)
        this.callRegister('update')
        this.doDataIntegrityChecks({ initial: false })
      })
    },

    'masterDataset.filters': {
      deep: true,
      handler () {
        if (!this.isReady || this.status.loading) return

        this.applyMasterFilters()
        this.setModifiedDataset(true)
      }
    },

    /**
     * The chart object as it will be saved is watched, to activate the save button
     * as soon as it has changed
     */
    chartAPIObject: {
      deep: true,
      async handler (val) {
        if (
          !this.isReady ||
          this.status.loading ||
          _.isEqual(val, OLD_CHART_API_OBJECT)
        ) {
          return
        }
        OLD_CHART_API_OBJECT = _.cloneDeep(val)
        this.status.isDirty = OLD_CHART_API_OBJECT !== null
      }
    },

    'versions.active' (val) {
      if (val) {
        this.versions.loading = true
        this.versions.failed = false
        api.get(`/api/charts/${this.$route.params.id}/versions`).then(res => {
          if (res.status !== 200) throw Error('Failed to load chart versions')
          this.versions.loading = false
          this.$set(this.versions, 'items', res.data)
        }).catch(err => {
          this.versions.failed = true
          this.versions.loading = false
          this.$maybeRaiseAPIPromiseErr(err)
        })
      }
    },

    isReady (val) {
      // If a chart type had already been selected when the first dataset wasn't loaded yet
      // create the templates for the dataset now
      if (val && this.chart.typeSelection && this.meta.auxiliary_column_metas) {
        this.generateTemplates()
        this.prefillSettingValues(this.meta)
        this.applyDatasetSettings()
      }
    },

    questionsMetaData (val) {
      this.updateNames()
    },

    chart: {
      deep: true,
      handler () {
        this.callConfigStoreUpdate()
      }
    },

    datasets: {
      deep: true,
      handler () {
        this.callConfigStoreUpdate()
        this.updateNames()
      }
    },

    'settings': {
      deep: true,
      handler (val, oldVal) {
        this.applyDatasetSettings()
      }
    },
    /**
    * When user changes number of segments
    * it checks whether number is allowed to be used
    * otherwise it set default value and shows error message
    * @param {Number} val Actual value of segments number input
    * @return {undefined} Side-effect function shouldn't return anything
    */
    auxiliaryTemplateSplitsValue (val) {
      if (
        this.selectedDatasetTemplate?.definition?.type !== 'auxiliary_number'
      ) {
        return
      }

      if (
        val > this.templateAuxiliaryMaxSplits || // more than allowed
        val < 2 // less than allowed
      ) {
        const valueToCorrect =
          val > this.templateAuxiliaryMaxSplits
            ? this.templateAuxiliaryMaxSplits // in case value more than allowed
            : 2 // in case value less than allowed

        this.$nextTick(() => {
          this.templates[this.templateAuxiliaryIndex].definition.nSplits = valueToCorrect
        })
        this.$root.snackMsg(this.$t('templates.auxiliary_number.errors.incorrectValue'))
        return
      }

      this.selectTemplate(this.selectedDatasetTemplate)
    },
    /**
    * When user changes auxiliary column for data segmentation
    * it update segment number input value and hints
    * @param {Number} val Actual value of segments number input
    * @return {undefined} Side-effect function shouldn't return anything
    */
    auxiliaryTemplateChoosen: {
      handler (val) {
        if (val === undefined) {
          return
        }

        const col = this.templates[this.templateAuxiliaryIndex].definition.col
        /*
        * change splits value if it's more than allowed
        */
        if (
          +this.auxiliaryTemplateSplitsValue > this.templateAuxiliaryMaxSplits
        ) {
          this.templates[this.templateAuxiliaryIndex].definition.nSplits = this.calculateDefaultSplits(this.meta.auxiliary_column_metas[col])
        }
        /*
        * actualaze hint for auxiliary column
        */
        let min = this.meta.auxiliary_column_metas[col].min
        let max = this.meta.auxiliary_column_metas[col].max
        this.templates[this.templateAuxiliaryIndex].subtitle = this.$t(
          'templates.auxiliary_number.subtitle',
          {
            max_splits: this.calculateAuxiliaryMaxSplits(min, max)
          }
        )
      }
    },
    /*
    * Set the best suitable method
    * when column changed
    * and user didn't change range boundaries
    */
    'settings.scoring_column' (newVal, oldVal) {
      if (
        oldVal === null ||
        this.settings.scoring_type === null ||
        this.scoringTypes.indexOf(this.settings.scoring_type) > -1 ||
        this.editedByUser
      ) {
        return
      }

      const initialTypeIndex = this.findInitialAuxiliaryScoringTypeIndexForNpsChartSelect(this.meta, this.settings.scoring_column) // eslint-disable-line
      this.$set(
        this.settings,
        'scoring_type',
        SATISFACTION_SCORING_TYPES[initialTypeIndex]
      )
    },

    'meta.auxiliary_column_metas': {
      deep: true,
      handler (val, oldVal) {
        if (
          !this.isReady ||
          _.isEqual(val, oldVal) ||
          this.status.loading
        ) return

        this.datasets.forEach(
          (item, index) =>
            this.$set(
              this.datasets[index],
              'settings',
              {
                ...this.datasets[index].settings,
                weighting_column: null,
                weighting_normalize: false
              }
            )
        )
      }
    }
  },

  mounted () {
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  beforeUnmount () {
    this.setBackgroundColor('white')
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },

  created () {
    this.setBackgroundColor('#F3F7F9')
    this.setupInternalStateAfterNavigation(this.$route.params, this.$route.query)
    this.$store.commit('setPageTutorialID', '1592835169bRxk1498')
  },

  methods: {
    removeFilter (index) {
      this.$delete(this.masterDataset.filters, index)
    },
    isSelectQuestionButtonEnabled (id) {
      return this.chart.typeSelection !== CHART_UNIQUE_DATE_LINE_PIE || id !== this.questionSelect.idToFocus
    },
    callRegister (action) {
      let id
      if (
        this.$route.params.id !== 'new'
      ) {
        id = this.$route.params.id
      } else {
        id = 'ch__new'
      }
      this.$store.dispatch(
        `registerManager/${action}`,
        {
          entityId: id,
          entityData: {
            ..._.cloneDeep(this.chart),
            ..._.cloneDeep(this.chartAPIObject)
          }
        }
      )
    },
    /*
    * Update vuex chart related store
    * to keep it consistent with local data
    */
    callConfigStoreUpdate () {
      this.$store.dispatch(
        `configManager/update`,
        {
          entityId: this.chart.id === 0 ? 'ch__new' : this.chart.id,
          entityData: {
            datasets: _.cloneDeep(this.datasets),
            ..._.cloneDeep(this.chart)
          }
        }
      )
    },

    updateNames () {
      this.datasets.forEach(dataset => {
        if (
          dataset.settings.name ||
          !this.questionsMetaData[dataset.question]
        ) {
          return
        }
        const { name, project_name } = this.questionsMetaData[dataset.question] // eslint-disable-line
        dataset.settings.name =  `${project_name}, ${name}` // eslint-disable-line
      })

      if (
        this.chart.name !== this.$t('chart_name.default') ||
        this.$route.params.id !== 'new' ||
        !this.questionsMetaData[this.firstDataset.question]
      ) {
        return
      }
      const { name, project_name } = this.questionsMetaData[this.firstDataset.question] // eslint-disable-line
      this.chart.name = `${project_name} — ${name}`.substring(0, 50) // eslint-disable-line
    },

    async updateMeta (id) {
      const payload = _.cloneDeep(this.chartAPIObject)

      if (
        payload.config.plotTopicsFilter?.select.length
      ) {
        payload.config.plotTopicsFilter.select = []
      }

      if (
        _.find(this.chart.config.filters, ({ type }) => type === 'topics')?.value?.length
      ) {
        const index = _.findIndex(this.chart.config.filters, ({ type }) => type === 'topics')
        this.$delete(payload.config.filters, index)
      }

      let { data } = await api.post(`/api/ui/charts/${id}/meta`, payload)
      this.meta = data
      return data
    },
    /**
    * Gets the chart config for api request, basically a normalizer for the API with differences for FE
    * Could delete redundant fields if they are defined
    * TODO: Move into mixin
    * @return {Object} Current config to put into request
    */
    getChartConfig () {
      if (!this.chart.config) return

      return {
        ...modifyChartPayloadConfig(this.chart.config, this.fieldsToDeleteBeforeRequest),
        aggregate:
          [
            CHART_TYPE_TREEMAP,
            CHART_TYPE_SCORE,
            CHART_TYPE_GRAPH
          ].indexOf(this.type) > -1
            ? true
            : [CHART_TYPE_DYNAMIC_BAR].indexOf(this.chart.config.type) > -1
              ? false
              : this.chart.config.aggregate
      }
    },

    /**
     * Sets the body background color, needs to be done here in order to color the overflow of the page
     */
    setBackgroundColor (color) {
      const elem = document.querySelector('.v-application--wrap')
      const html = document.querySelector('html')
      elem.style.background = color
      html.style.background = color
    },

    /**
    * If the next dataset's question matches the current dataset's
    * @return {Boolean}
    */
    renderQuestionName (index) {
      if (
        index === 0
      ) {
        return true
      }
      return this.datasets[index - 1].question !== this.datasets[index].question
    },
    /**
     * Checks if chart is in group
     * @param {Array} group
     * @param {String} type
     * @retrun {Boolean}
     */
    isChartInGroup (type, group) {
      return _.includes(group, type)
    },
    /**
     * Distinguishes verbatim queryParams based on chart type
     * @param  {Object} dataset
     * @param  {Number} dataset index in array
    */
    varbatimQueryBuilder (dataset, idx) {

    },
    /**
     * Open the verbatim browser
     * @param  {Number} dsIdx Dataset index to use
    */
    openVerbatimBrowserForDataset (dsIdx) {
      this.$store.dispatch('verbatimDialog/show', {
        item: this.chart.id || 'ch__new',
        filters: [
          {
            type: 'dataset',
            value: [dsIdx],
            htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.segment')}</div>:&nbsp;${
              this.maybeTruncateLabel(this.$escapeHtml(this.datasets[dsIdx].settings.name), { maxLength: 25 })
            }`
          }
        ]
      })
    },

    /**
     * Shortens a piece of text to set length and can add ellipsis
     * @param {any} text
     * @param {any} {maxLength=undefined
     * @param {any} ellipsis=true}={}
     * @returns {any}
     */
    maybeTruncateLabel (text, { maxLength = undefined, ellipsis = true } = {}) {
      if (text === undefined) return ''
      if (maxLength === undefined) maxLength = this.config.maxDataLabelLength
      return text.length > maxLength ? text.slice(0, maxLength) + (ellipsis ? '...' : '') + (text.endsWith('}') ? '}' : '') : text
    },

    /**
     * Sets flag which determines if any of the datasets have been modified by the user
     */
    setModifiedDataset (val) {
      this.datasetModified = val
    },

    /**
    * Open the master dataset
    */
    showMasterDataset (selectedPane, options = {}, filterType) {
      const settingsToApply = Object
        .entries(this.datasets[0].settings)
        .filter(([key, value]) => !IGNORE_SETTINGS_KEYS.has(key))
        .reduce((sum, [key, value]) => ({
          ...sum,
          [key]: value
        }), {})

      this.$set(
        this.masterDataset,
        'settings',
        settingsToApply
      )

      let highlightIdx = -1
      this.masterDataset.active = true
      if (filterType) {
        let initialFilter = this.initializeNewFilter(filterType, this.meta.auxiliary_column_metas)
        highlightIdx = this.masterDataset.filters.length
        this.masterDataset.filters.push({ type: initialFilter.type, value: initialFilter.value, invert: false })
      } else {
        highlightIdx = options.filterIndex
        this.masterDataset.forceNew = options.forceNew || false
      }
      this.chosenDataset = this.masterDataset
      this.selectedPaneOption = selectedPane

      this.masterDataset.highlightIdx = -1
      this.$nextTick(() => {
        this.masterDataset.highlightIdx = highlightIdx
      })
    },

    /**
     * Open dataset settings and filters
     * @param  {Number} $ Index of the dataset
     */
    handleShowDatasetDrawer (dsIdx, selectedPane) {
      this.chosenDataset = this.datasets[dsIdx]
      this.selectedPaneOption = selectedPane
    },

    showPaneDrawer (type) {
      this.selectedPaneOption = type
      this.masterDataset.active = false
    },

    /**
     * Return true if there might be unsaved changes and that the user should be warned thorugh an alert
     * @param  {Object} $event The navigate away event
     * @return {Boolean}
     */
    beforeUnload ($event) {
      if (this.chart.type && this.status.isDirty) $event.returnValue = true
    },

    /**
     * Dehydrate config.plotTopicsFilter
     * Change format from [<topic id>]
     * to [<full topic data from meta>]
     */
    convertPlotTopicsFilter () {
      const list = [...this.chart.config.plotTopicsFilter.select]
      this.$set(this.chart.config.plotTopicsFilter, 'select', this.meta.topics_complete_union.filter(({ id }) => list.indexOf(id) > -1))
    },
    /**
     * Dehydrate config.filters where filter is topics
     * Change format from [<topic id>]
     * to [<full topic data from meta>]
     */
    convertTopicsFilter () {
      const filterIndex = _.findIndex(this.chart.config.filters, ({ type }) => type === 'topics')
      if (filterIndex === -1) {
        return
      }
      const list = this.chart.config.filters[filterIndex].value.reduce((sum, item) => ({
        ...sum,
        [item[0]]: item[1]
      }), {})

      this.$set(
        this.chart.config.filters[filterIndex],
        'value',
        this.meta.topics_complete_union.filter(
          ({ id }) => list[id]
        ).map(item => {
          if (
            list[item.id] === 'any'
          ) {
            return item
          }
          return {
            ...item,
            sentiment: list[item.id]
          }
        })
      )
    },

    /**
     * Reads configuration from id in url and query parameter and sets internal state
     * @param  {Object} params Params passed through url
     * @param  {Object} query  URL query
     */
    async setupInternalStateAfterNavigation (params, query) {
      // If the change in navigation has been triggered by a programmatic change (not through the $router component)
      // do nothing
      if (
        this.status.programmaticRouteChange
      ) {
        this.status.programmaticRouteChange = false
        return
      } else if (
        params.id !== 'new'
      ) { // If a specific chart is given, all is settled, load it
        this.status.loading = true
        this.resetChart()
        await this.loadChart(params.id)

        // keep methods calls order
        // 1. get meta
        // 2. remove non-existing topics/categories for filters, plotFilters
        // 3. dehydrate data for filters, plotFilters
        // 4. set masterFilters

        await this.updateMeta(params.id)
        this.doDataIntegrityChecks({ initial: true })
        this.convertPlotTopicsFilter()
        this.convertTopicsFilter()
        this.masterDataset.filters = _.cloneDeep(_.get(this.chart.config, 'filters', []))

        // convert column string names to integers
        this.masterDataset.filters.forEach(item => {
          if (item.type !== 'auxiliary') return
          delete item.value.colIdxInDs0
          item.value.col = this.auxiliaryColumnNames.indexOf(item.value.col)
        })

        this.$nextTick(async () => {
          await this.callRegister('register')
          this.status.loading = false
        })
        return
      } else if (
        !query.question
      ) { // Otherwise, we need to check for question and chart type, and potentially set them
        this.resetChart()
        this.status.isDirty = true
        this.openQuestionSelect()
        return
      }
      // We have a new chart
      this.resetChart()
      this.status.isDirty = true
      const dataset = await this.addDataset(query.question)
      await this.updateMeta('ch__')
      this.addDatasetUpdate(dataset)
      await this.callRegister('register')
      await this.closeQuestionSelect(false)

      // The chart type is of course only valid if we have a valid question as well
      if (
        CHART_LIST[this.$route.query.chart]
      ) {
        // Not all charta allow to be set by query parameter, as they might required
        // some additional parameters to work correctly which are not in the query
        this.selectChartType(query.chart)
      } else {
        this.$router.replace({ // Unset the chart
          name: 'chart-details',
          params: 'id',
          query: { ...this.$route.query, chart: CHART_UNIQUE_BAR }
        })
      }
    },

    /**
     * Resets current datasets
     */
    resetDatasets () {
      this.datasets.splice(0)
    },

    /**
     * Resets current templates
     */
    resetTemplates () {
      this.templates.splice(0)
    },

    /**
     * Reset the internal state to the initial, clean version
     */
    resetChart () {
      this.$set(this, 'chart', _.cloneDeep(this.chartTemplate))
      this.resetDatasets()
      this.resetTemplates()
      this.selectedDatasetTemplate = null
    },

    /**
     * Select another saved chart version.
     * Ask user to confirm, if current state is dirty. Load that chart version.
     * @param  {Object} chartVersion
     */
    selectVersion (chartVersion) {
      if (this.status.isDirty && !confirm(this.$t('history.confirm_overwrite_current_dirty'))) return

      this.$set(this.chart, 'config', chartVersion.config)

      this.resetDatasets()
      chartVersion.datasets.forEach(ds => this.addDataset(ds.question, { settings: ds.settings, filters: ds.filters }))

      if (this.chart.typeSelection !== API_ENUM_TO_CHART_TYPES[chartVersion.type]) {
        this.selectChartType(API_ENUM_TO_CHART_TYPES[chartVersion.type])
      }
    },

    /**
     * Set the breadcrumbs
     */
    setBreadcrumbProps () {
      this.$store.commit('setBreadcrumbProps', {
        chartID: this.$route.params.id,
        chartName: this.chart.name
      })
    },

    /**
     * Load a specific chart given the ID
     * @param  {String} id
     */
    async loadChart (id) {
      this.status.isDirty = false
      return api.get(`/api/charts/${id}`).then(res => {
        if (res.status !== 200) throw Error('Failed to load chart')

        this.$set(this, 'chart', {
          ...this.chart,
          id: res.data.id,
          type: CHART_LIST[API_ENUM_TO_CHART_TYPES[res.data.type]]['type'],
          typeSelection: API_ENUM_TO_CHART_TYPES[res.data.type],
          name: res.data.name,
          config: res.data.config
        })
        if (!this.$hasPermission('dashboards_edit', res.data)) this.status.readOnly = true

        RES_CHART_API_OBJECT = res.data

        res.data.datasets.forEach(ds => this.addDataset(ds.question, { settings: ds.settings, filters: ds.filters }))
      }).catch(err => {
        this.status.loading = false
        this.status.loadingFailed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /*
    *
    * DATA INTERGRITY CHECKS
    *
    */

    /**
    * Perform all checks
    * if all corresponding data removed, collapse plotCategories/plotTopics
    * @param  {Boolean} initial - references to data differ depending on lifecycle
    */
    doDataIntegrityChecks ({ initial }) {
      this.doDataIntegrityTopicsFilterChecks({ initial })
      this.doDataIntegrityCategoriesFilterChecks()
      this.doDataIntegrityPlotTopicsFilterChecks({ initial })
      this.doDataIntegrityPlotCategoriesFilterChecks()
    },
    /**
    * Perfrom config.filters checks where filter is topics
    * @param  {Boolean} initial - references to data differ depending on lifecycle
    */
    doDataIntegrityTopicsFilterChecks ({ initial }) {
      const topicsFilterIndex = this.chart.config.filters?.findIndex(({ type }) => type === 'topics')

      if (
        typeof topicsFilterIndex !== 'number' ||
        !this.chart.config.filters?.[topicsFilterIndex]?.value
      ) return

      const idsSet = new Set(this.meta.topics_complete_union.map(({ id }) => id))

      this.$set(
        this.chart.config.filters[topicsFilterIndex],
        'value',
        this.chart.config.filters[topicsFilterIndex].value
          .filter(c => idsSet.has(initial ? c[0] : c.id))
      )
    },
    /**
    * Perfrom config.filters checks where filter is categories
    */
    doDataIntegrityCategoriesFilterChecks () {
      const categoriesFilterIndex = this.chart.config.filters?.findIndex(({ type }) => type === 'categories')

      if (
        typeof categoriesFilterIndex !== 'number' ||
        !this.chart.config.filters?.[categoriesFilterIndex]?.value
      ) return

      const idsSet = new Set(this.meta.topics_complete_union.map(({ category }) => category))

      this.$set(
        this.chart.config.filters[categoriesFilterIndex],
        'value',
        this.chart.config.filters[categoriesFilterIndex].value
          .filter(c => idsSet.has(c))
      )
    },
    /**
    * Perfrom config.plotTopicsFilter checks
    * @param  {Boolean} initial - references to data differ depending on lifecycle
    */
    doDataIntegrityPlotTopicsFilterChecks ({ initial }) {
      if (!this.chart.config?.plotTopicsFilter?.select) return

      const idsSet = new Set(this.meta.topics_complete_union.map(({ id }) => id))

      this.$set(
        this.chart.config.plotTopicsFilter,
        'select',
        this.chart.config.plotTopicsFilter.select
          .filter(c => idsSet.has(initial ? c : c.id))
      )
    },
    /**
    * Perfrom config.plotCategoriesFilter checks
    */
    doDataIntegrityPlotCategoriesFilterChecks () {
      if (!this.chart.config?.plotCategoriesFilter?.select) return

      const idsSet = new Set(this.meta.topics_complete_union.map(({ category }) => category))

      this.$set(
        this.chart.config.plotCategoriesFilter,
        'select',
        this.chart.config.plotCategoriesFilter.select
          .filter(c => idsSet.has(c))
      )
    },

    /**
     * Save the current chart. Either creates a new one or patches the existing one.
     * @param {Boolean} asNew
     */
    save ($event, asNew = false) {
      this.status.isDirty = false
      this.status.saving = true
      this.status.savingFailed = false
      let request

      if (asNew) {
        if (RES_CHART_API_OBJECT.name === this.chart.name) {
          let extension = ' [Copy]'
          this.chart.name = this.chart.name.substring(0, 50 - extension.length) + extension
        }
      }

      if (this.$route.params.id === 'new' || asNew) {
        request = api.post(`/api/charts/`, this.chartAPIObject)
      } else request = api.patch(`/api/charts/${this.$route.params.id}`, this.chartAPIObject)

      request.then((res) => {
        if (res.status >= 400) throw Error('Chart creation failed.')
        if (res.status === 201) {
          RES_CHART_API_OBJECT = res.data
          const entityIdOld = this.chart.id

          // Mark this as programmatic change, i.e. we don't need to update the state through the route guard
          this.status.programmaticRouteChange = true
          this.chart.id = res.data.id

          if (asNew) {
            this.$router.push({ // Set the id in the URI
              name: 'chart-details',
              params: { id: res.data.id },
              query: {}
            })

            this.$nextTick(() => { this.status.isDirty = false })
          } else {
            this.$router.replace({ // Set the id in the URI
              name: 'chart-details',
              params: { id: res.data.id },
              query: {}
            })
          }
          this.setBreadcrumbProps()
          this.$store.dispatch(
            `registerManager/identify`,
            {
              entityId: res.data.id,
              entityIdOld: entityIdOld || 'ch__new'
            }
          )
        }
        this.status.saving = false
      }).catch((err) => {
        this.status.saving = false
        this.status.savingFailed = true
        this.status.isDirty = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Apply the master dataset settings to all other datasets
     */
    closeMasterPanel () {
      this.selectedPaneOption = ''
      this.masterDataset.active = false
      this.masterDataset.highlightIdx = null
      this.masterDataset.forceNew = false
      this.$root.snackMsg(this.$t('ds.applied_master'))
    },

    /**
     * Apply the master dataset settings to all other datasets
     */
    applyMasterSettings () {
      if (!this.allDatasetsLoaded) return

      const settingsToApply = Object.entries(
        this.masterDataset.settings
      )
        .filter(([key, value]) => !IGNORE_SETTINGS_KEYS.has(key))
        .reduce((sum, [key, value]) => ({
          ...sum,
          [key]: value
        }), {})

      this.datasets.forEach(
        (item, index) => this.$set(
          this.datasets[index],
          'settings',
          {
            ...this.datasets[index].settings,
            ...settingsToApply
          }
        )
      )
    },

    /**
     * Apply the master dataset filters to all other datasets
     */
    applyMasterFilters (closePanel = false) {
      if (!this.allDatasetsLoaded) return

      const masterFilters = this.masterDataset.filters
        // skip empty filters
        .filter(masterFilter => masterFilter.type !== null)

        .map(masterFilter => {
          let f = _.cloneDeep(masterFilter)
          if (f.type === 'auxiliary') {
            const columnName = this.auxiliaryColumnNames[f.value.col]
            f.value.col = columnName
            f.value.colIdxInDs0 = columnName
          }
          return f
        })

      // set to new data structure
      this.$set(this.chart.config, 'filters', masterFilters)

      if (closePanel) {
        this.closeMasterPanel()
      }
    },

    /**
     * Set the result of a dataset
     * @param {Number} idx    Dataset index
     * @param {Object} result The result
     */
    setDatasetResult (idx, result) {
      let ds = this.datasets[idx]
      if (ds.result !== result) ds.result = result
    },

    /**
     * Delete a dataset
     * @param  {Number} idx Dataset index
     */
    deleteDataset (idx) {
      this.datasets.splice(idx, 1)
      this.setModifiedDataset(true)
      if (!this.datasets.length) this.openQuestionSelect()
      else this.updateMeta(this.chart.id === 0 ? 'ch__' : this.chart.id)
    },

    /**
     * Duplicate a dataset
     * @param  {Number} idx Dataset index
     */
    duplicateDataset (idx) {
      let origDs = this.datasets[idx]
      let newDs = {
        id: this.dsIDHead++,
        filters: _.cloneDeep(origDs.filters),
        settings: _.cloneDeep(origDs.settings),
        status: { loading: false, failed: false, dirty: false, duplicated: true },
        question: origDs.question,
        answers: origDs.answers,
        result: _.clone(origDs.result)
      }
      newDs.settings.name = this.$t('ds.copied_prefix') + newDs.settings.name
      this.datasets.splice(idx + 1, 0, newDs)
      this.setModifiedDataset(true)

      this.applyDatasetSettings()
      this.$nextTick(() => this.applyMasterFilters(true))
    },

    /**
     * Handler when one chart type is selected
     * @param  {String} chartType
     */
    selectChartType (chartType, confirmSelection = true) {
      const flatChartList = Object.values(this.availableChartsList).reduce((item, sum) => ({
        ...sum,
        ...item
      }))

      // return if chart shouldn't be displayed
      if (
        !flatChartList[chartType].enabled
      ) {
        return
      }

      if (confirmSelection && this.datasets.length > 1) {
        this.confirmTemplateDialog = this.$t('chart_type.change_notice')
        this.confirmTemplateDialogCallback = () => {
          this.setCleanDataset()
          this.selectChartType(chartType, false)
        }
        return
      }

      let datasetResults = []

      this.datasets.forEach(ds => {
        datasetResults.push(ds.result)
        ds.result = []
        ds.status.dirty = true
      })

      // keep a copy of type and config to save on next tick
      const prevChartType = this.chart.typeSelection
      const prevChartConfig = _.cloneDeep(this.chart.config)

      this.chart.typeSelection = chartType
      this.chart.type = CHART_LIST[chartType].type

      this.$nextTick(() => {
        this.prevChartConfigs[`${prevChartType}-config`] = prevChartConfig

        this.datasets.forEach((ds, idx) => {
          ds.result = datasetResults[idx]
          ds.status.dirty = false
        })

        if (this.isReady) {
          this.generateTemplates()
        }
        this.selectTemplate(this.getCleanTemplate())
      })

      this.prefillSettingValues(this.meta)
      this.applyDatasetSettings()
      this.prefillConfigValues()
      this.setDefaultAxisNames()
    },

    /*
      Fired after switching chart types, checks if selected template exists in new chart type
    */
    templateExistsInChartType (templates) {
      let exists = false

      templates.forEach((t, i) => {
        if (_.isEqual(t, this.selectedDatasetTemplate)) {
          exists = i + 1
        }
      })

      return exists
    },

    /**
     * Create a list of available templates, given the selected chart and question
     * @return {Array}
     */
    generateTemplates () {
      let templates = []
      let templatesToConsider = CHART_LIST[this.chart.typeSelection].templates

      if (templatesToConsider.TPL_SENT) {
        if (this.firstDataset.question.question_category !== QUESTION_CATEGORY_LIST) {
          templates.push({
            title: this.$t('templates.sentiment.title'),
            subtitle: this.$t('templates.sentiment.subtitle'),
            definition: { type: 'sentiment' }
          })
        }
      }

      if (
        templatesToConsider.TPL_INH &&
        this.questionsMetaData[this.firstDataset.question] &&
        this.questionsMetaData[this.firstDataset.question].inherits_from !== null
      ) {
        templates.push({
          title: this.$t('templates.inherit.title', { inherits_from_name: this.questionsMetaData[this.firstDataset.question].inherits_from_name }),
          subtitle: this.$t('templates.inherit.subtitle', { inherits_from_name: this.questionsMetaData[this.firstDataset.question].inherits_from_name }),
          definition: { type: 'inherit' }
        })
      }

      if (templatesToConsider.TPL_AUX_GEN || templatesToConsider.TPL_AUX_SCORE) {
        templates.push(...this._generateAuxiliaryTemplates(templatesToConsider.TPL_AUX_GEN, templatesToConsider.TPL_AUX_SCORE))
      }

      this.$set(this, 'templates', templates)

      if (this.templateExistsInChartType(templates)) {
        this.selectedDatasetTemplate = templates[this.templateExistsInChartType(templates) - 1]
      }
    },

    /*
      Generates clean dataset from question, used to be in templates, now hidden under reset button
    */
    getCleanTemplate () {
      return {
        title: `<strong>${this.$t('templates.clean.title')}</strong>`,
        description: '',
        definition: { type: 'clean' }
      }
    },

    /**
    * Generate the auxiliary column templates
    * @param {Boolean} general - is AUX_GEN template set as available for first chart
    * @param {Boolean} score - is TPL_AUX_SCORE template set as available for first chart
    * @return {Array} Brief description of the returning value here.
    */
    _generateAuxiliaryTemplates (general, score) {
      let templates = []
      const potentialNPSColumns = new Set(this.potentialNPSColumns)
      const potentialCSATColumns = new Set(this.potentialCSATColumns)

      let hasNPSTemplate = false
      let hasCategoricalTemplate = false
      let hasNumericalTemplate = false
      // let hasCSATTemplate = false
      this.meta?.auxiliary_column_metas?.forEach((ct, colIdx) => {
        let colName = this.auxiliaryColumnNames[colIdx]
        if (score && potentialNPSColumns.has(colIdx) && !hasNPSTemplate) {
          templates.push({
            title: this.$t('templates.auxiliary_nps.title', { column: this.$maybeTruncate(colName, 80) }),
            subtitle: this.$t('templates.auxiliary_nps.subtitle', { low: 0, high: ct.max > 10 ? 100 : 10 }),
            definition: { type: 'auxiliary_nps', col: colIdx },
            prio: 1
          })

          hasNPSTemplate = true
        }
        // } else if (score && potentialCSATColumns.has(colIdx) && !hasCSATTemplate) {
        //   templates.push({
        //     title: this.$t('templates.auxiliary_csat.title'),
        //     subtitle: this.$t('templates.auxiliary_csat.subtitle', {
        //       low: ct.type === 'number' ? 1 : CSAT_TERMS[0][0],
        //       high: ct.type === 'number' ? 5 : CSAT_TERMS[0][4]
        //     }),
        //     definition: { type: 'auxiliary_csat', col: colIdx },
        //     prio: 1
        //   })

        //   hasCSATTemplate = true
        // }

        if (general && ct.type === 'string' && ct.few && ct.vals.length > 0 && ct.vals.length <= MAX_VALS_TO_SUGGEST_SEGMENT && !hasCategoricalTemplate) {
          templates.push({
            title: this.$t('templates.auxiliary_choice.title'),
            subtitle: this.$t('templates.auxiliary_choice.subtitle', {
              n_values: ct.vals.length,
              column: this.$maybeTruncate(colName, 25)
            }),
            definition: { type: 'auxiliary_choice', col: colIdx },
            prio: potentialNPSColumns.has(colIdx) || potentialCSATColumns.has(colIdx) ? 0 : 1
          })

          hasCategoricalTemplate = true
        } else if (general && ct.type === 'number' && ct.min !== ct.max && !hasNumericalTemplate) {
          let nSplits = this.calculateDefaultSplits(ct)

          templates.push({
            title: this.$t('templates.auxiliary_number.title', { column: this.$maybeTruncate(colName, 80) }),
            subtitle: this.$t('templates.auxiliary_number.subtitle', {
              max_splits: this.calculateAuxiliaryMaxSplits(ct.min, ct.max),
              column: this.$maybeTruncate(colName, 25)
            }),
            definition: { type: 'auxiliary_number', col: colIdx, nSplits },
            prio: potentialNPSColumns.has(colIdx) || potentialCSATColumns.has(colIdx) ? 0 : 1
          })
          hasNumericalTemplate = true
        }
      })

      // if (templates.length > MAX_CHART_AUX_TEMPLATES) {
      //   templates = _.sortBy(templates, '-prio').slice(0, MAX_CHART_AUX_TEMPLATES)
      // }

      return templates
    },

    /*
    * calculate maximum splits for auxiliary number column
    * @param {Number} min Minimum value in auxiliary column
    * @param {Number} max Maximum value in auxiliary column
    * returns {Number}
    */
    calculateAuxiliaryMaxSplits (min, max) {
      const rangeOfValues = max - min + 1

      /*
      * differance between values exceeds MAX_VALS_TO_SUGGEST_SEGMENT
      * than use MAX_VALS_TO_SUGGEST_SEGMENT
      */
      if (
        rangeOfValues >= MAX_VALS_TO_SUGGEST_SEGMENT
      ) {
        return MAX_VALS_TO_SUGGEST_SEGMENT
      }
      return rangeOfValues
    },

    /**
     * Checks if the dataset has been modified by the user, if so, show dialog requesting user confirmation,
     * else callback
     */
    maybeSelectTemplate (callback) {
      if (this.datasetModified) {
        this.confirmTemplateDialog = this.$t('template_select.text')
        this.confirmTemplateDialogCallback = callback
      } else {
        callback()
      }
    },

    /**
     * Handler when clicking on a suggested template. Creates the relevant dataset,
     * applies chart settings
     * @param  {Object} template The chosen template
     */
    async selectTemplate (template) {
      // in case template doesn't exist in new chart type
      if (!template) return

      let type = template.definition.type
      let col = template.definition.col
      let colName = this.auxiliaryColumnNames[col]

      // Helper function for generating the dataset names for auxiliary column splits
      let _getAuxDsName = (colName, colType, val) => {
        if (colType === 'select') return String(val) || '[EMPTY]'
        else if (colType === 'number') {
          return `${this.$maybeTruncate(colName, 10)}: ${val}`
        } else if (colType === 'range') {
          let res = (this.chart.typeSelection !== 'line-pie' ? `${this.$maybeTruncate(colName, 10)}: ` : '')
          res += `${val.min} - ${val.max}`
          return res
        }
      }

      // const currentQuestionId = this.$route.query.question || this.$route.params.id
      const currentQuestionId = this.firstDataset.question
      if (
        !currentQuestionId
      ) {
        return
      }
      if (type === 'inherit') {
        const MAX_INHERIT_DATASETS = 5
        let cnt = 0

        let questionsSeen = new Set()
        let addInheritsFromToDatasets = (questionId) => {
          const question = this.questionsMetaData[questionId]
          questionsSeen.add(question)
          if (
            question.inherits_from !== null &&
            cnt < MAX_INHERIT_DATASETS &&
            !questionsSeen.has(question.inherits_from)
          ) {
            // There is another inherits path, add it
            cnt += 1
            this.addDataset(question.inherits_from, undefined, addInheritsFromToDatasets)
          }
        }
        addInheritsFromToDatasets(currentQuestionId)
      } else if (type === 'auxiliary_nps') {
        let auxType = this.meta.auxiliary_column_metas[col]

        let filters = _.map(_.range(3), () => ({ type: 'auxiliary', value: { kind: 'range', col } }))

        if (auxType.max <= 10) {
          filters[0].value.value = [0, 6]
          filters[1].value.value = [7, 8]
          filters[2].value.value = [9, 10]
        } else {
          filters[0].value.value = [0, 60]
          filters[1].value.value = [61, 80]
          filters[2].value.value = [81, 100]
        }

        this.setCleanDataset()

        this.firstDataset.filters.push(filters[0])
        this.firstDataset.settings.name = this.$t('templates.auxiliary_nps.ds_detractors')
        this.firstDataset.settings.color = { override: true, value: this.$color.sentiment.negative }

        this.addDataset(currentQuestionId, {
          settings: {
            ..._.cloneDeep(DS_SETTINGS_TEMPLATE),
            name: this.$t('templates.auxiliary_nps.ds_neutrals'),
            color: { override: true, value: this.$color.sentiment.neutral }
          },
          filters: [filters[1]]
        })

        this.addDataset(currentQuestionId, {
          settings: {
            ..._.cloneDeep(DS_SETTINGS_TEMPLATE),
            name: this.$t('templates.auxiliary_nps.ds_promoters'),
            color: { override: true, value: this.$color.sentiment.positive }
          },
          filters: [filters[2]]
        })

        this.chart.config.percentages = true
        this.chart.config.colorBy = { field: 'dataset', group: {} }
        this.chart.config.ordinalAxisName = this.chart.typeSelection === 'line-pie' ? this.$maybeTruncate(colName, 14) : undefined
      } else if (type === 'auxiliary_choice') {
        let auxVals = this.meta.auxiliary_column_metas[col].vals

        this.setCleanDataset()

        this.firstDataset.filters.push({ type: 'auxiliary', value: { kind: 'select', value: [auxVals[0]], col } })
        this.firstDataset.settings.name = _getAuxDsName(colName, 'select', auxVals[0])

        auxVals.slice(1).forEach(val => {
          this.addDataset(currentQuestionId, {
            settings: { ..._.cloneDeep(DS_SETTINGS_TEMPLATE), name: _getAuxDsName(colName, 'select', val) },
            filters: [{ type: 'auxiliary', value: { kind: 'select', value: [val], col } }]
          })
        })

        this.chart.config.percentages = true
        this.chart.config.colorBy = { field: 'dataset', group: {} }
        this.chart.config.ordinalAxisName = this.chart.typeSelection === 'line-pie' ? this.$maybeTruncate(colName, 14) : undefined
      } else if (
        type === 'auxiliary_number' &&
        this.meta.auxiliary_column_metas[col].dtype === 'int64'
      ) {
        /*
        * in case it set of auxiliary_number column data consists of integers
        */
        /*
        * there is the code commented for correct data distribution over chart
        * it deletes empty spaces in line of integers
        * it needs actual dataset
        * below you will see code to delete and uncomment
        * if possible to get actual dataset of integers then use this code
        */

        const nSplits = +template.definition.nSplits

        /*
        * if possible to get actual dataset of integers then
        * delete following code
        * start of code to delete
        */
        let min = this.meta.auxiliary_column_metas[col].min
        let max = this.meta.auxiliary_column_metas[col].max
        let rangeOfValues = max - min + 1 // get difference between limits
        /*
        * end of code to delete
        *
        * start of code to uncomment
        */
        /*
        const min = 0
        const datasetInUse = // put actual dataset here
        let rangeOfValues = datasetInUse.length
        */
        /*
        * end of code to uncomment
        */

        let step = Math.floor(rangeOfValues / nSplits)
        let rest = rangeOfValues - step * nSplits

        this.setCleanDataset()

        let segmentation = []
        /*
        * if possible to get actual dataset of integers then
        * delete following code
        * start of code to delete
        */
        for (
          let i = 0; i < nSplits; i++
        ) {
          const currMin = i === 0 ? min : i < rest ? min + step * i + i : min + step * i + rest
          const currMax = i < rest ? currMin + step : currMin + step - 1
          segmentation.push({ currMin, currMax })
        }
        /*
        * end of code to delete
        *
        * start of code to uncomment
        */
        /*
        for (
          let i = 0; i < nSplits; i++
        ) {
          const currMinIndex = i === 0 ? min : i < rest ? min + step * i + i : min + step * i + rest
          const currMaxIndex = i < rest ? currMinIndex + step : currMinIndex + step - 1
          const currMin = datasetInUse[currMinIndex]
          const currMax = datasetInUse[currMaxIndex]
          segmentation.push({ currMin, currMax })
        }
        */
        /*
        * end of code to uncomment
        */

        let currMin = segmentation[0].currMin
        let currMax = segmentation[0].currMax

        /*
         * prefill datasets
        */
        this.firstDataset.filters.push({
          type: 'auxiliary',
          value: {
            kind: 'range',
            value:
              [currMin, currMax],
            col
          }
        })
        this.firstDataset.settings.name =
          currMin === currMax
            /*
            * make label with integer
            */
            ? _getAuxDsName(colName, 'number', currMin)
            /*
            * make label with range
            */
            : _getAuxDsName(colName, 'range', { min: currMin, max: currMax })

        for (let stepIdx = 1; stepIdx < nSplits; stepIdx++) {
          /*
          * prepare data for step
          */
          let currMin = segmentation[stepIdx].currMin
          let currMax = segmentation[stepIdx].currMax
          /*
          * it works with ranges only
          * if you need to work with single numbers than use ranges
          */
          this.addDataset(currentQuestionId, {
            settings: {
              ..._.cloneDeep(DS_SETTINGS_TEMPLATE),
              name:
                currMin === currMax
                  /*
                  * make label with integer
                  */
                  ? _getAuxDsName(colName, 'number', currMin)
                  /*
                  * make label with range to show range with step
                  */
                  : _getAuxDsName(
                    colName,
                    'range',
                    {
                      min: currMin,
                      max: currMax
                    }
                  )
            },
            filters: [{
              type: 'auxiliary',
              value: {
                kind: 'range',
                /*
                * calculate data as range anyway; it's pseudo range if it is even line of integers
                */
                value: [currMin, currMax],
                col
              }
            }]
          })
        }
      } else if (type === 'auxiliary_number') {
        const nSplits = +template.definition.nSplits
        let min = this.meta.auxiliary_column_metas[col].min
        let max = this.meta.auxiliary_column_metas[col].max
        let step = (max - min) / nSplits
        let isInteger = (max - min) >= nSplits
        if (isInteger) step = Math.floor(step)
        const roundDigits = step > 10 ? 0 : step > 1 ? 1 : step === 1 ? 0 : Math.ceil(Math.abs(Math.log10(step))) + 1
        const _round = (val) => Math.round(val * 10 ** roundDigits) / 10 ** roundDigits

        this.setCleanDataset()

        this.firstDataset.filters.push({ type: 'auxiliary', value: { kind: 'range', value: [min, min + step], col } })
        this.firstDataset.settings.name = _getAuxDsName(colName, 'range', { min: _round(min), max: _round(min + step) })

        let currMin = min + step + isInteger

        for (let stepIdx = 1; stepIdx < nSplits; stepIdx++) {
          let currMax = ((stepIdx + 1) === nSplits) ? max : currMin + step
          this.addDataset(currentQuestionId, {
            settings: {
              ..._.cloneDeep(DS_SETTINGS_TEMPLATE),
              name: _getAuxDsName(colName, 'range', { min: _round(currMin), max: _round(currMax) })
            },
            filters: [{ type: 'auxiliary', value: { kind: 'range', value: [currMin, currMax], col } }]
          })
          currMin += step + isInteger
          currMin = Math.min(currMin, max)
        }

        this.chart.config.percentages = true
        this.chart.config.colorBy = { field: 'dataset', group: {} }
        this.chart.config.ordinalAxisName = this.chart.typeSelection === 'line-pie' ? this.$maybeTruncate(colName, 14) : undefined
      } else if (type === 'sentiment') {
        const chartType = this.chart.typeSelection || this.chart.type
        this.setCleanDataset()
        this.datasets[0].filters.push({ type: 'sentiment', value: [-1, -0.25000001] })
        this.datasets[0].settings.name = this.$t('templates.sentiment.ds_negative')

        this.addDataset(currentQuestionId, {
          settings: { ..._.cloneDeep(DS_SETTINGS_TEMPLATE), name: this.$t('templates.sentiment.ds_neutral') },
          filters: [{ type: 'sentiment', value: [-0.25, 0.25] }]
        })
        this.addDataset(currentQuestionId, {
          settings: { ..._.cloneDeep(DS_SETTINGS_TEMPLATE), name: this.$t('templates.sentiment.ds_positive') },
          filters: [{ type: 'sentiment', value: [0.25000001, 1] }]
        })

        this.chart.config.percentages = true
        if (chartType === CHART_TYPE_BAR) {
          this.firstDataset.settings.color = { override: true, value: this.$color.sentiment.negative }
          this.datasets[1].settings.color = { override: true, value: this.$color.sentiment.neutral }
          this.datasets[2].settings.color = { override: true, value: this.$color.sentiment.positive }
          this.chart.config.colorBy = { field: 'dataset', group: {} }
        }
        this.chart.config.ordinalAxisName = this.chart.typeSelection === 'line-pie' ? this.$t('templates.sentiment.axis_name') : undefined
      } else if (type === 'clean') {
        this.setCleanDataset()
      } else {
        throw Error(`Unknown template definition '${template.definition.type}'`)
      }

      if (
        type === 'auxiliary_number'
      ) {
        this.chart.config.percentages = true
        this.chart.config.colorBy = { field: 'dataset', group: {} }
        this.chart.config.ordinalAxisName = this.chart.typeSelection === 'line-pie' ? this.$maybeTruncate(colName, 14) : undefined
      }

      this.selectedDatasetTemplate = template
      this.resetMasterSettings()

      this.$nextTick(() => this.setModifiedDataset(false))
    },

    /**
    * Get number of splits
    * if it's array of integers
    * and range of values less than MAX_VALS_TO_SUGGEST_SEGMENT use the range as value
    * unless use default
    * @param {Object} col auxiliary column from dataser
    * @return {Number} Default number of splits for bar chart
    */
    calculateDefaultSplits (col) {
      if (
        col.dtype === 'int64' &&
        col.max - col.min + 1 < MAX_VALS_TO_SUGGEST_SEGMENT
      ) {
        return col.max - col.min + 1
      } else if (
        col.max - col.min <= 2
      ) {
        return 2
      }
      return 3
    },
    /**
    * Resets the master dataset to its default value
    */
    resetMasterSettings () {
      this.masterDataset = {
        active: false,
        highlightIdx: null,
        forceNew: false,
        settings: _.cloneDeep(DS_SETTINGS_TEMPLATE),
        filters: []
      }
    },

    /**
    * Resets the datasets to default question value
    */
    setCleanDataset () {
      const questionId = this.firstDataset.question || this.$route.query.question

      this.resetDatasets()

      this.addDataset(questionId, {}, undefined, true)
    },

    /**
     * Add and load a dataset (i.e. its question and answer) and do global preprocessing
     * @param {String} questionID
     * @param {Object} options.settings     Dataset options, otherwise created from templates
     * @param {Array} options.filters       Dataset filters, otherwise created from templates
     * @param {Function} questionLoadedCB   Function to be called when dataset is loaded
     * @param {Boolean} dontLoadFromChartAPI Flag to disable loading question / answers from the *chart*
     * @param {Boolean} shouldApplyMasterSettings Flag to apply master dataset settings
     *                                       (e.g. when there is no chart yet)
     */
    async addDataset (questionID, { settings, filters } = {}, questionLoadedCB, dontLoadFromChartAPI, shouldApplyMasterSettings = false) {
      // Create the dataset object
      let ds = {
        id: this.dsIDHead++,
        settings: settings || _.cloneDeep(DS_SETTINGS_TEMPLATE),
        filters: filters || _.cloneDeep(DS_FILTERS_TEMPLATE),
        status: { loading: false, failed: false, dirty: false },
        question: questionID,
        answers: [],
        result: null
      }

      // If there is a callback function, call it now
      // if (questionLoadedCB_) questionLoadedCB_(ds_.question)

      // Save the index this dataset will get in the future
      this.datasets.push(ds)

      // If this is the first dataset, set the breadcrumb entries
      if (this.datasets.length === 1) {
        this.setBreadcrumbProps()
      }
      if (shouldApplyMasterSettings) this.$nextTick(() => this.applyMasterFilters(true))

      // Apply settings from loaded datasets
      if (this.isReady) {
        this.prefillSettingValues(this.meta)
        this.applyDatasetSettings()
      }

      // If the question select has been opened from user's action (ie: clicking on add dataset, set modified dataset to true)
      if (this.questionSelect.fromUserAction) {
        this.setModifiedDataset(true)
      }
      return ds
    },

    addDatasetUpdate (dataset) {
      if (this.isDriverChart) {
        // Set the driver column from souces in this order:
        // 1) See if there is a setting defined on the chart editor (if the question was freshly created in this session)
        // 2) Take the driver column of the first dataset
        let colIdxInDs0 = this.settings.driver_target_column !== null ? this.settings.driver_target_column : this.firstDataset.settings.driver_target_column
        if (colIdxInDs0 !== null && this.meta.auxiliary_column_metas[colIdxInDs0]) {
          this.$set(dataset.settings, 'driver_target_column', colIdxInDs0)
        }
      }
      if (this.isNpsChart) {
        // Set the nps column from souces in this order:
        // 1) See if there is a setting defined on the chart editor (if the question was freshly created in this session)
        // 2) Take the nps column of the first dataset
        let colIdxInDs0 = this.settings.scoring_column !== null ? this.settings.scoring_column : this.firstDataset.settings.scoring_column
        if (colIdxInDs0 !== null && this.meta.auxiliary_column_metas[colIdxInDs0]) {
          this.$set(dataset.settings, 'scoring_column', colIdxInDs0)
        }
      }
    },

    /**
     * Open the dialog showing the question selection
     * @param {Boolean} fromUserAction if the dialog has been opened by user action
     */
    openQuestionSelect (fromUserAction = false) {
      this.questionSelect.idToFocus = this.datasets.length ? this.datasets.slice(-1)[0].question : undefined
      this.questionSelect.active = true
      this.questionSelect.fromUserAction = fromUserAction
    },

    /**
     * Close the dialog showing the question selection
     */
    async closeQuestionSelect (question) {
      this.questionSelect.active = false
      if (question !== false) {
        const dataset = await this.addDataset(question.id, {}, undefined, true, this.datasets.length > 0)
        await this.updateMeta(this.chart.id === 0 ? 'ch__' : this.chart.id)
        await this.callRegister('update')
        this.doDataIntegrityChecks({ initial: false })
        this.masterDataset.filters = _.get(this.chart.config, 'filters', [])
        this.addDatasetUpdate(dataset)

        if (this.$route.params.id === 'new' && !this.$route.query.question) {
          // Mark this as programmatic change, i.e. we don't need to update the state through the route guard
          this.status.programmaticRouteChange = true
          await this.callRegister('register')
          this.$router.push({
            name: 'chart-details',
            params: { id: this.$route.params.id },
            query: { ...this.$route.query, question: question.id, chart: CHART_UNIQUE_BAR }
          })
        }
      } else if (!this.datasets.length) {
        // When the dialog is dismissed and no dataset selected, go back to whatever page the user came from
        this.$router.go(-1)
      }

      this.questionSelect.fromUserAction = false
    },

    filterOptionsTransformer (opt) {
      if (this.categoryOrTopicsApplied && (['topics', 'categories']).includes(opt.type)) return { ...opt, disabled: true }
      return opt
    },
    /*
    * reacts on change in NPS
    * and set variable to know if the user changed range boundaries
    */
    updateEditedByUser (value) {
      this.editedByUser = value
    },

    updateChartTicks (value) {
      this.internalChartTicks = value
    }
  }
}

</script>

<style lang=scss>
  @import '~css/chart';

  .segmented-value-select {
    margin: -2px 0 0 0 !important;

    .v-input__slot{
      min-height: 32px !important;
      font-size: 14px !important;
    }
    .v-input__append-inner{
      margin-top: 5px !important;
    }
    fieldset{
      border: 0 !important;

      legend{
        background: #fff;
      }
    }
  }
</style>

<i18n locale='en' src='@/i18n/en/pages/ChartEditor.json' />
<i18n locale='de' src='@/i18n/de/pages/ChartEditor.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />