import Vue from 'vue'
import { DEFAULT_ROW_LIMIT } from '@/settings/constants'

const store = {
  namespaced: true,

  state () {
    return {}
  },

  mutations: {
    register (state, { entityId }) {
      Vue.set(state, entityId, {
        loaded: false,
        loadingFailed: false,
        loadingRowsLock: false,
        pagesLoading: new Set(),
        rowsPerPage: {},
        rowData: {
          loading: true,
          pagination: {},
          meta: {}
        },
        preventLoadFromListUpdate: false,
        dummyRows: [],
        auxiliaryColumnIdxShown: [],
        rowsQueryString: ''
      })
    },

    identify (state, { entityIdToSet, entityIdToRemove }) {
      Vue.set(state, entityIdToSet, _.cloneDeep(state[entityIdToRemove]))
      Vue.delete(state, entityIdToRemove)
    },

    delete (state, { entityId }) {
      Vue.delete(state, entityId)
    },

    /** =========== Global Page State =========== **/

    isLoaded: (state, id) => {
      state[id].loaded = true
      state[id].loadingFailed = false
    },
    failedLoading: (state, id) => {
      state[id].loaded = false
    },
    loadingFailed: (state, id) => { state[id].loadingFailed = true },
    loadingFailedReset: (state, id) => { state[id].loadingFailed = false },

    setRowDataFetching (state, { id, value }) {
      Vue.set(state[id].rowData, 'loading', value)
    },

    setPageInRowsPerPage (state, { id, rows, page }) {
      Vue.set(state[id].rowsPerPage, page, rows)
      // Delete pages which are too far from the current page
      _.each(state[id].rowsPerPage, (pageContent, pageKey) => {
        if (Math.abs(pageKey - page) > 3) Vue.delete(state[id].rowsPerPage, pageKey)
      })
    },

    resetRowsPerPage (state, { id }) {
      Vue.set(state[id], 'rowsPerPage', {})
    },

    setDummyRows (state, { id, value }) {
      Vue.set(state[id], 'dummyRows', value)
    },

    setRowDataMeta (state, { id, count, hasNext, hasPrev }) {
      Vue.set(state[id].rowData, 'meta', { count, hasNext, hasPrev })
    },

    setPagination (state, { id, startIndex, endIndex, displayStartIndex, displayEndIndex }) {
      Vue.set(state[id].rowData, 'pagination', { startIndex, endIndex, displayStartIndex, displayEndIndex })
    },

    setPreventLoadFromListUpdate: (state, { id, value }) => {
      state[id].preventLoadFromListUpdate = value
    },

    setAuxiliaryColumnIdxShown (state, { id, value }) {
      state[id].auxiliaryColumnIdxShown = value
    },

    addPageLoading (state, { entityId, value }) {
      state[entityId].pagesLoading.add(value)
    },

    removePageLoading (state, { entityId, value }) {
      state[entityId].pagesLoading.delete(value)
    },

    setLoadingRowsLock: (state, { entityId, value }) => {
      state[entityId].loadingRowsLock = value
    },

    setRowsQueryString (state, { entityId, value }) {
      state[entityId].rowsQueryString = value
    }
  },

  actions: {
    register ({ commit }, { entityId }) {
      commit('register', { entityId })
    },

    registerMany ({ commit }, { list }) {
      list.forEach(({ entityId, entityData }) => {
        commit('register', { entityId })
      })
    },

    identify ({ commit, dispatch }, { entityIdToSet, entityIdToRemove }) {
      commit('identify', { entityIdToSet, entityIdToRemove })
    },

    delete ({ commit, dispatch }, { entityId }) {
      commit('delete', { entityId })
    },

    async loadRows ({ commit, state, getters, dispatch, rootState, rootGetters }, { id, scrolledPage, queryString = '', soft = false }) {
      const page = scrolledPage || 1
      const pageLoadingIdentifier = `${page}_${queryString}`

      if (state[id].loadingRowsLock) return

      if (state[id].pagesLoading.has(pageLoadingIdentifier)) {
        console.debug(`Page identifier ${pageLoadingIdentifier} already loading.`)
        return
      }

      commit('addPageLoading', { entityId: id, value: pageLoadingIdentifier })
      commit('setRowsQueryString', { entityId: id, value: queryString })

      if (!soft) commit('setRowDataFetching', { id, value: true })

      let res
      try {
        const projectId = rootState.coding?.projectID || rootState.wizard.projectID
        const columnRef = rootState.coding?.columnRef || rootState.wizard.columnRef

        res = await api.get(`/api/ui/projects/${projectId}/coding/${columnRef}/rows?limit=${DEFAULT_ROW_LIMIT}&page=${page}${queryString.length ? `&${queryString}` : ''}`)
      } catch (error) {
        commit('removePageLoading', { entityId: id, value: pageLoadingIdentifier })
        this._vm.$maybeRaiseAPIPromiseErr(error)
        commit('loadingFailed', id)
        return
      }

      // It might be that the user has navigated away in the meantime
      if (!(rootState.coding || rootState.wizard)) return

      if (state[id].loadingRowsLock || !res.data || !res.data.rows) return

      const { count, has_next: hasNext, has_prev: hasPrev, results, context } = res.data.rows

      let rows = []
      if (results.length) {
        rows = _.map(results, (row, index) => ({
          type: 'row',
          id: row.id,
          index: row.upload_index,
          translateOverwritten: null,
          codingColumn: row.text_to_analyze,
          otherColumns: row.columns
        }))
      }

      if (getters.dummyRows(id).length !== count) commit('setDummyRows', { id, value: _.range(count) })
      if (
        this._actions['setGroupingDuplicates']
      ) {
        dispatch('setGroupingDuplicates', context.is_grouping_duplicates, { root: true })
      }

      commit('isLoaded', id)
      commit('setPageInRowsPerPage', { id, page, rows })
      commit('setRowDataFetching', { id, value: false })
      commit('setRowDataMeta', { id, count, hasNext, hasPrev })

      setTimeout(() => {
        if (!rootState.coding) return
        if (rootState.coding.focusMode) {
          if (count) {
            commit('setFocusRowIndex', 0, { root: true })
            commit('setActiveRow', rootGetters.getRowByIndex(0), { root: true })
          } else {
            commit('setFocusRowIndex', 'empty', { root: true })
          }
        }
      }, 10)
      commit('removePageLoading', { entityId: id, value: pageLoadingIdentifier })
    },

    addPageLoading ({ commit }, { entityId, value }) {
      commit('addPageLoading', { entityId, value })
    },

    removePageLoading ({ commit }, { entityId, value }) {
      commit('removePageLoading', { entityId, value })
    },

    setRowDataFetching ({ commit }, { entityId, value }) {
      commit('setRowDataFetching', { id: entityId, value })
    },

    loadingFailed ({ commit }, { entityId, value }) {
      commit('loadingFailed', entityId)
    },

    loadingFailedReset ({ commit }, { entityId, value }) {
      commit('loadingFailedReset', entityId)
    },

    setPreventLoadFromListUpdate: ({ commit }, { entityId, value }) => {
      commit('setPreventLoadFromListUpdate', { id: entityId, value })
    },

    setLoadingRowsLock: ({ commit }, { entityId, value }) => {
      commit('setLoadingRowsLock', { entityId, value })
    }
  },

  getters: {
    overall: (state, getters) => { return _.cloneDeep(state) },

    preventLoadFromListUpdate: (state, getters) => (id) => {
      return state[id]?.preventLoadFromListUpdate
    },
    rowData: (state, getters) => (id) => {
      return state[id]?.rowData
    },
    dummyRows: (state, getters) => (id) => {
      return state[id]?.dummyRows
    },
    paginationString: (state, getters) => (id) => {
      let string

      if (
        state[id]?.rowData?.pagination &&
        Object.keys(state[id]?.rowData.pagination).length
      ) {
        string = `${state[id].rowData.pagination.displayStartIndex} - ${state[id].rowData.pagination.displayEndIndex} of ${state[id].rowData.meta.count}`
      } else {
        string = '0 elements found'
      }

      return string
    },
    state: (state, getters) => (id) => {
      return _.pick(state[id], ['loaded', 'loadingFailed', 'loadingRowsLock', 'pagesLoading'])
    },
    rowsPerPage: (state, getters) => (id) => {
      return state[id]?.rowsPerPage
    },
    columnsShown: (state, getters) => (id) => {
      return state[id]?.auxiliaryColumnIdxShown
    },
    rowsQueryString: (state, getters) => (id) => {
      return state[id]?.rowsQueryString
    }
  }
}

export default store