<template>
  <v-menu
    v-if="topics.length"
    :disabled="!topics.length || refinementStep < 3 || loading"
    offset-y
    :close-on-content-click="false"
    :rounded="'lg'"
    class="filters__list-container"
    content-class="wizard__dropdown"
    :value="generateMoreShown"
    @input="value => $store.commit('setGenerationMoreShown', value)"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex">
        <!-- TODO: REMOVE DEV PURPOSES -->
        <!-- <v-btn
          class="filters__select mr-2"
          dark
          :ripple="false"
          hide-details
          solo
          dense
          @click="generatorRerun"
        >
          rerun generator
        </v-btn> -->
        <v-btn
          v-if="refinementStep === 3"
          class="filters__select"
          :class="generateMoreShown && 'filters__select--active'"
          dark
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          hide-details
          solo
          dense
        >
          {{ $t('refinement_steps.generate_more') }}
          <svg class="ml-1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 11L14 5H6L10 11Z" fill="#3B3B3B" />
          </svg>
        </v-btn>
        <div
          v-else
          class="wizard__generate-disabled"
        />
      </div>
    </template>

    <div
      v-if="refinementStep === 3"
      class="d-flex"
    >
      <div class="w-100">
        <div
          class="filters__list__item"
          @click="handleGenerateMore({ type: 'general' })"
        >
          <div class="d-flex align-center">
            {{ $t('refinement_steps.in_general') }}
          </div>
        </div>
        <v-menu
          offset-x
          :close-on-content-click="false"
          :rounded="'lg'"
          class="filters__list-container"
          content-class="inside-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="filters__list__item filters__list__item--button w-100 d-flex align-center justify-space-between text-sm font-weight-normal"
              v-bind="attrs"
              text
              v-on="on"
              :ripple="false"
              hide-details
              solo
              dense
            >
              {{ $t('refinement_steps.for_category') }}
              <v-icon :size="17">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </template>
          <div
            v-for="(cat, index) in generatedCatOptions"
            class="filters__list__item"
            @click="handleGenerateMore({ type: 'category', category: cat })"
            :key="index"
          >
            <div
              class="d-flex align-center"
              :style="`font-weight: 500; color: ${catColors[cat]?.strong || catColors[cat]}`"
            >
              {{ cat }}
            </div>
          </div>
        </v-menu>
      </div>
    </div>
  </v-menu>
</template>

<script>

import Vuex from 'vuex'
import { mapGettersWithKey } from '@/utils/vuex.js'

export default {
  codit: true,
  name: 'TopicGeneration',
  components: {
  },

  mixins: [],

  computed: {
    ...Vuex.mapState({
      id: state => state.wizard.id,
      topics: state => state.wizard.topics,
      generateMoreShown: state => state.wizard.generateMoreShown,
      project: state => state.wizard.project,
      codingColumn: state => state.wizard.codingColumn,
      refinementStep: state => state.wizard.refinementStep,
      loading: state => state.wizard.generatorLoading,
      generatorRerun: state => state.wizard.generatorRerun,
      generatedCatOptions: state => state.wizard.generatedCatOptions,
      settings: state => state.wizard.settings
    }),

    ...Vuex.mapGetters([
      'catColors'
    ]),

    ...mapGettersWithKey({
      topicID2Topic: 'metaManager/topicID2Topic'
    })(function () {
      return this.id
    })
  },

  watch: {
  },

  methods: {
    /**
     * Handler for activating generation
     */
    handleGenerateMore ({ type, category = {}, topic = {} }) {
      // TODO: Handle generate more options here
      this.$store.commit('setGeneratorLoading', true)
      this.$store.commit('setGeneratorError', false)
      this.$store.commit('setGenerationMoreShown', false)
      this.$store.commit('setAnimatedTopics', [])

      const body = {
        mode: 'more',
        given_topics: this.topics,
        additional_topics_hint: {},
        sentiment_enabled: this.settings.sentiment
      }

      if (type === 'category') {
        body.additional_topics_hint.category = category
        console.log('generate-more-category')
        this.$root.phclient?.capture('wizard/generate-more/category')
      } else if (type === 'topic') {
        body.additional_topics_hint.category = topic.category
        body.additional_topics_hint.topic_label = topic.label
      } else {
        console.log('general')
        this.$root.phclient?.capture('wizard/generate-more/general')
      }

      api.post(
        `/api/ui/projects/${this.project.id}/generate-topics/${this.codingColumn.ref}`,
        body
      )
        .then(res => {
          this.$store.commit('setGeneratorRerun', false)
          this.$store.commit('setGeneratorLoading', false)
          const codebookFromPreviousStep = this.topics
          let newTopics = [...this.topics]

          // Validate properties of new codes and give them colors
          // Caution: Always base the difference on the codebook from the previous step
          let codesByCat = _.groupBy(codebookFromPreviousStep, 'category')
          let codeCats = _.keys(codesByCat)
          let catColors = _.mapValues(codesByCat, cc => cc[0].color)
          let codeIDS = new Set(_.map(codebookFromPreviousStep, 'id'))

          res.data.topics.forEach(c => {
            if (!codeIDS.has(c.id)) {
              if (codeCats.indexOf(c.category) === -1) codeCats.push(c.category)
              if (!(c.category in catColors)) catColors[c.category] = this.$color.getMedium(codeCats.indexOf(c.category))
              newTopics.push({
                ...c,
                color: catColors[c.category]
              })
            }
          })

          this.$store.commit('setAnimatedTopics', _.differenceWith(res.data.topics, this.topics, _.isEqual))
          this.$store.commit('setCoverage', res.data.meta?.coverage)
          this.$store.commit('setTopics', newTopics)
          _.map(res.data.suggestions, suggestion => this.$store.commit('addSuggestion', suggestion))
          this.$root.snackMsg(this.$t('topics_generated'))
        })
        .catch(err => {
          this.$store.commit('setGeneratorRerun', () => this.handleGenerateMore({ type, category, topic }))
          this.$store.commit('setGeneratorLoading', false)
          this.$store.commit('setGeneratorError', true)
          this.$maybeRaiseAPIPromiseErr(err)
        })
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />