import { render, staticRenderFns } from "./ExportDataTabs.vue?vue&type=template&id=7ae8973d"
import script from "./ExportDataTabs.vue?vue&type=script&lang=js"
export * from "./ExportDataTabs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/components/ExportData.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fcoding%2FExportData%2FExportDataTabs.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/components/ExportData.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fcoding%2FExportData%2FExportDataTabs.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)

export default component.exports