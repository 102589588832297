<template>
  <div
    class="dashboard"
    ref="dashboard"
    :style="{ height: `${(nRows + 2) * p2p - spacing}px` }"
    :class="{
      dragging: dragOrResize.active && dragOrResize.mode === 'drag',
      resizing: dragOrResize.active && dragOrResize.mode === 'resize'
    }"
    @dragover.prevent.stop="onDragOrResizeOver($event)"
    @mousemove="onDragOrResizeOver($event)"
    @mouseup="onResizeEnd($event)"
  >
    <template v-if="width !== -1">
      <template
        v-for="(el, elIndex) in internalValue"
      >
        <slot
          v-bind="calculateSlotProp(elIndex)"
        />
      </template>
    </template>
    <template v-if="hasEditingPermissions && internalValue.length < itemsLimit">
      <div v-for="(el, idx) in placeholderDashboardElements"
           :key="`placeholder-el-${idx}`"
           :style="{
             width: `${p2p * el.width - spacing}px`,
             height: `${p2p * el.height - spacing}px`,
             left: `${p2p * el.x}px`,
             top: `${p2p * el.y}px`
           }"
           class="dashboard-placeholder-el"
           @click="openDialog({ x: el.x, y: el.y, width: el.width, height: el.height })"
      >
        <div class="dashboard-placeholder-el__text">
          <v-icon :size="20">
            mdi-plus
          </v-icon>
          {{ $t('add_or_edit.add_new') }}
        </div>
      </div>
      <div class="add-hint grey--text" :style="{ bottom: `${1.5 * p2p}px` }">
        <v-icon :size="22">
          mdi-plus
        </v-icon>
        <div>
          {{ $t('add_hint') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vuex from 'vuex'
import DataMixin from '@/components/customUi/mixins/data.js'

const MAX_PLACEHOLDER_X = 5
const MAX_PLACEHOLDER_Y = 4

export default {
  mixins: [
    DataMixin
  ],
  props: {
    value: { type: Array, default: () => [] },
    status: { type: Object, default: () => ({}) },
    warnings: { type: Object, default: () => ({}) },
    itemsLimit: { type: Number, default: 100 }
  },
  data () {
    return {
      SPACING_PERCENTAGE: 0.01,
      nCols: 10,
      width: -1,
      dragOrResize: {
        mode: null,
        onMoveHandler: false,
        active: false,
        element: {},
        mouseOffset: { x: 0, y: 0 },
        positionMatAtStart: [],
        isProcessing: false
      },

      getAndSetContainerWidthDebounced: _.debounce(() => {
        if (!this.$refs.dashboard) return
        this.width = this.$refs.dashboard.clientWidth
      }, 40)
    }
  },
  provide () {
    return {
      $dragOrResize: this.dragOrResize, // data
      $hasEditingPermissions: () => this.hasEditingPermissions, // computed
      $p2p: () => this.p2p,
      $spacing: () => this.spacing,
      $openDialog: this.openDialog, // methods
      $removeElementHandler: this.removeElementHandler,
      $itemStartDrag: this.itemStartDrag,
      $onDragOrResizeStart: this.onDragOrResizeStart,
      $onDragEnd: this.onDragEnd,
      $onDragOrResizeOver: this.onDragOrResizeOver
    }
  },
  computed: {
    ...Vuex.mapState(['user']),

    /**
     * The number of rows: Defined by the dashboard element with the heighest y + height value
     */
    nRows () {
      return _(this.internalValue).map(e => e.y + e.height).max() || 1 // prevent NaN when not having elements
    },
    /**
     * The space between two neighbouring elements
     */
    spacing () {
      return this.width * this.SPACING_PERCENTAGE
    },
    /**
     * Percentage to pixel factor:
     * Every dashboard elements width & height (integer values of {1...n}) are multiplied with this value to obtain the
     * actual screen width / height
     * @return {Number}
     */
    p2p () {
      return this.blockWidth + this.spacing
    },
    /**
     * The unit width & height of a dashboard element
     */
    blockWidth () {
      return this.width * (1 - (this.nCols - 1) * this.SPACING_PERCENTAGE) / this.nCols
    },

    /**
     * If the user can edit this dashboard
     */
    hasEditingPermissions () {
      return !this.user.isAnonymous && !this.status.readOnly
    },

    /**
     * 2D matrix representing dashboard canvas: A zero means no dashboard element is present there
     * any other value indicates the dashboard element with the given ID is there
     */
    dashboardPositionMatrix () {
      let mat = []
      for (let k = 0; k < this.nCols; k++) mat.push(new Array(this.nRows).fill(0))
      this.internalValue.forEach(del => this.addElementToPositionMat(del, mat))
      return mat
    },

    /**
     * List of placeholder elements, that one can click on to add a new dashboard element
     * @return {Array}  A list of elements that fill up the empty space intelligently (more or less..)
     */
    placeholderDashboardElements () {
      if (this.dragOrResize.active) return []
      let elements = []
      let placeholderMat = []
      for (let k = 0; k < this.nCols; k++) placeholderMat.push(new Array(this.nRows + 1).fill(false))
      for (let x = 0; x < this.nCols; x++) {
        for (let y = 0; y < (this.nRows + 2); y++) {
          if (!this.dashboardPositionMatrix[x][y] && !placeholderMat[x][y]) {
            // Try to grow the placeholder element as far as possible
            let width = 1
            let height = 1
            let goRight = true
            while (width <= MAX_PLACEHOLDER_X && height <= MAX_PLACEHOLDER_Y) {
              let tmpX = x + width + goRight - 1
              let tmpY = y + height + !goRight - 1
              let occupied = false
              if (tmpX >= this.nCols || tmpY > (this.nRows + 1)) break
              if (goRight) {
                for (let checkY = y; checkY <= tmpY; checkY++) {
                  if (this.dashboardPositionMatrix[tmpX][checkY] || placeholderMat[tmpX][checkY]) {
                    occupied = true
                    break
                  }
                }
              } else {
                for (let checkX = x; checkX <= tmpX; checkX++) {
                  if (this.dashboardPositionMatrix[checkX][tmpY] || placeholderMat[checkX][tmpY]) {
                    occupied = true
                    break
                  }
                }
              }

              if (occupied) break
              else {
                width += goRight
                height += !goRight
                goRight = !goRight
              }
            }
            for (let tmpX = x; tmpX < (x + width); tmpX++) {
              for (let tmpY = y; tmpY < (y + height); tmpY++) {
                placeholderMat[tmpX][tmpY] = true
              }
            }
            elements.push({ x, y, width, height })
          }
        }
      }
      return elements
    },

    elementID2element () {
      let mapping = {}
      this.internalValue.forEach(del => { mapping[del.id] = del })
      return mapping
    },

    dashboardElementClasses () {
      return _.map(this.internalValue, (el, elIdx) => {
        let isDraggingOrResizingEl = this.dragOrResize.element.id === el.id
        return {
          dragging: ((this.dragOrResize.active && this.dragOrResize.mode === 'drag') || this.dragOrResize.onMoveHandler) && isDraggingOrResizingEl,
          resizing: this.dragOrResize.active && this.dragOrResize.mode === 'resize' && isDraggingOrResizingEl,
          [el.chart.type]: true,
          'dashboard-el--public': !this.hasEditingPermissions,
          'dashboard-el--warning': this.warnings[el.chart.id]
        }
      })
    },
    dashboardElementStyles () {
      return _.map(this.internalValue, el => {
        return {
          width: `${this.p2p * el.width - this.spacing}px`,
          height: `${this.p2p * el.height - this.spacing}px`,
          left: `${this.p2p * el.x}px`,
          top: `${this.p2p * el.y}px`,
          // Add some special styles if we have a placeholder-chart element on our hands
          ...el.chart.type === 'P_TXT' && { 'background-color': el.chart.config.colorBG }
        }
      })
    }
  },

  mounted () {
    window.addEventListener('resize', this.getAndSetContainerWidthDebounced)
    window.addEventListener('mouseup', this.onResizeEnd)
    this.getAndSetContainerWidthDebounced()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getAndSetContainerWidthDebounced)
    window.removeEventListener('mouseup', this.onResizeEnd)
  },

  methods: {
    calculateSlotProp (index) {
      return {
        el: this.internalValue[index],
        styles: this.dashboardElementStyles[index],
        classes: this.dashboardElementClasses[index]
      }
    },

    openDialog (params) {
      this.$emit('openDialog', params)
    },

    removeElementHandler (el) {
      this.$emit('remove', el)
    },

    itemStartDrag (item) {
      this.dragOrResize.onMoveHandler = true
      this.dragOrResize.element = item
    },

    onResizeEnd ($event, element) {
      if (this.dragOrResize.active && this.dragOrResize.mode === 'resize') {
        this.dragOrResize.active = false
        // If a verbatim browser was resized, make sure the internal container is resized as well
        // Which will happen if we force an update event on the component
        // if (this.dragOrResize.element.chart.type === 'p-verb') {
        //   this.$refs[`verbatim-browser-${this.$dragOrResize.element.id}`][0].$forceUpdate()
        // }
      }
      if (this.dragOrResize.onMoveHandler) this.dragOrResize.onMoveHandler = false
    },

    onDragOrResizeStart ($event, element, mode) {
      if (mode !== 'drag' && mode !== 'resize') throw Error(`Got invalid mode ${mode}`)
      if (mode === 'drag' && !this.dragOrResize.onMoveHandler) return $event.preventDefault()

      this.dragOrResize.mode = mode
      this.dragOrResize.active = true
      this.dragOrResize.element = element
      this.dragOrResize.mouseOffset.x = $event.offsetX
      this.dragOrResize.mouseOffset.y = $event.offsetY

      this.dragOrResize.positionMatAtStart = _.cloneDeep(this.dashboardPositionMatrix)
      this.dragOrResize.elementArrAtStart = this.internalValue.map(el => _.pick(el, ['x', 'y', 'width', 'height']))
    },

    onDragEnd ($event, element) {
      this.dragOrResize.active = false
      this.dragOrResize.onMoveHandler = false
    },

    /**
     * Handler for draggig over valid drop zone
     * @param  {Event} $event     Original drag event
     * @param  {Object} element   The element being dragged (if hovering over itself), otherwise undefined
     */
    onDragOrResizeOver ($event, element) {
      const elementIdx = element ? _.findIndex(this.internalValue, item => item.id === element.id) : undefined

      // Check that we are only processing this function once in parallel
      // Drop other events

      if (!this.dragOrResize.active || this.dragOrResize.isProcessing) return
      if (this.dragOrResize.mode === 'drag' && $event.type === 'mousemove') return
      this.dragOrResize.isProcessing = true

      this.status.dirty = true

      // Get the position we are currently at
      // Add the position within the dragged element that was clicked,
      // to match not the mouse but the real dimension of the element
      let x = $event.offsetX - this.dragOrResize.mouseOffset.x
      let y = $event.offsetY - this.dragOrResize.mouseOffset.y

      // If we are actually hovering the dragged element itself
      // Add that element's position
      if (elementIdx !== undefined) {
        x += this.p2p * this.internalValue[elementIdx].x
        y += this.p2p * this.internalValue[elementIdx].y
      }

      // Round to the current block
      x = Math.round(x / this.p2p)
      y = Math.round(y / this.p2p)

      // Make sure the block isn't out of bounds
      x = Math.max(Math.min(x, this.nCols), 0)
      y = Math.max(Math.min(y, this.nRows + 1), 0)

      let elementNew
      if (this.dragOrResize.mode === 'drag') {
        x = Math.min(x, this.nCols - this.dragOrResize.element.width)
        if (this.dragOrResize.element.x !== x || this.dragOrResize.element.y !== y) {
          elementNew = { ...this.dragOrResize.element, x, y }
        }
      } else if (this.dragOrResize.mode === 'resize') {
        // Don't let an element shrink below the size of 1x1
        let width = Math.max(x - this.dragOrResize.element.x, 1)
        let height = Math.max(y - this.dragOrResize.element.y, 1)
        if (this.dragOrResize.element.width !== width || this.dragOrResize.element.height !== height) {
          elementNew = { ...this.dragOrResize.element, width, height }
        }
      }

      // Only if the position has changed compared to the last time, do the more expensive calculations
      if (elementNew) {
        // First, reset all element's positions to the state where the drag started
        this.internalValue.forEach((del, dIdx) => {
          let atStart = this.dragOrResize.elementArrAtStart[dIdx]
          del.x = atStart.x
          del.y = atStart.y
          del.width = atStart.width
          del.height = atStart.height
        })

        // Again, initial state, plus remove the currently dragging el from the position matrix
        let positionMat = _.cloneDeep(this.dragOrResize.positionMatAtStart)
        this.clearElementFromPositionMat(this.dragOrResize.element, positionMat)

        // Compute the new position of overlapping blocks recursively
        let movingResult = this.makeSpaceForElement(elementNew, positionMat, {}, true, true, true, true, 0)

        // This is actually never allowed to happen.. would mean a bug :/
        if (!movingResult.possible) throw Error('Making space didn\'t work!', movingResult)

        // Do all the updates of the elements, whose position has been temporarily set
        _.each(movingResult.elementsMoved, (movedEl) => {
          let realElement = this.elementID2element[movedEl.id]
          realElement.x = movedEl.x
          realElement.y = movedEl.y
          realElement.width = movedEl.width
          realElement.height = movedEl.height
        })
      }
      // Give the browser time to render the changes before re-computing the dragging / resizing position
      // -> Delay needs to be higher than the transition timing function
      if (this.dragOrResize.mode === 'drag') setTimeout(() => { this.dragOrResize.isProcessing = false }, 150)
      else this.dragOrResize.isProcessing = false
    },

    /**
     * Recursive function taking an element and making sure all other elements are positioned in a way that this element fits
     * @param  {Object} element         The element to position
     * @param  {2D-Mat} positionMat     Position matrix of [x][y] -> elementID
     * @param  {Object} elementsMoved   Changed elements at current envocation. Format: Mapping of elementID -> { x, y }
     * @param  {Boolean} downAllowed    If child elements that overlap are allowed to be shifted down
     * @param  {Boolean} upAllowed      If child elements that overlap are allowed to be shifted up
     * @param  {Boolean} rightAllowed   If child elements that overlap are allowed to be shifted right
     * @param  {Boolean} leftAllowed    If child elements that overlap are allowed to be shifted left
     * @param  {Number} recursionDepth  How deep in s*** we are already
     * @return {Object}                 The result of the move operation
     */
    makeSpaceForElement (
      element, positionMat, elementsMoved, downAllowed, upAllowed, rightAllowed, leftAllowed, recursionDepth
    ) {
      // Make sure we are not out-of-bounds, otherwise notify that this is invalid move
      if (element.x < 0 || element.y < 0 || (element.x + element.width) > this.nCols) return { possible: false }
      if (recursionDepth++ >= 200) throw Error('max recursion')

      // Count the number of elements that have been moved
      let nElementsMovedInTree = 0
      // The integral of block movement (distance * nblocks)
      let integratedBlockMovement = 0

      rightAllowed &= recursionDepth < 3
      upAllowed &= recursionDepth < 3
      leftAllowed &= recursionDepth < 3
      downAllowed &= recursionDepth < 3

      // Copy the position matrix, and add the new position of this element
      // But don't overwrite any other elements that may already be here
      // because we obviously still want to find thhose in the loop afterwards
      positionMat = _.cloneDeep(positionMat)
      this.addElementToPositionMat(element, positionMat, false)

      // Copy the elementsMoved object, and add this element to it
      elementsMoved = _.clone(elementsMoved)
      elementsMoved[element.id] = element

      if (!upAllowed && !rightAllowed && !leftAllowed) {
        // let originalY = this.elementID2element[element.id].y
        // let dy = element.y - originalY
        // let fromX = element.x
        // let toX = element.x + element.width
        let dxDims = _.map(_.range(this.nRows), idx => ({ from: NaN, to: NaN }))

        // Check how far down we have to go
        let dy = 0
        for (let y = element.y; y < Math.min(element.y + element.height, this.nRows); y++) {
          for (let x = element.x; x < element.x + element.width; x++) {
            if (positionMat[x][y] !== 0 && positionMat[x][y] !== element.id) {
              let overlappingEl = this.elementID2element[positionMat[x][y]]
              dy = Math.max(dy, element.y - overlappingEl.y + element.height)
              dxDims[y].from = element.x
              dxDims[y].to = element.x + element.width
            }
          }
        }

        // Check all blocks of the element for overlaps
        // let dyOpen = dy
        // let fromX = element.x
        // let toX = element.x + element.width
        let elementsMovedLocally = {}
        let ibm = 0
        for (let y = element.y; y < this.nRows; y++) {
          // let movedInRow = false
          // let localFromX = NaN
          // let localToX = NaN
          for (let x = dxDims[y].from; x < dxDims[y].to; x++) {
            if (positionMat[x][y] !== 0 && positionMat[x][y] !== element.id) {
              let overlappingEl = this.elementID2element[positionMat[x][y]]
              if (!(overlappingEl.id in elementsMovedLocally)) {
                // if (overlappingEl.id in elementsMoved) return { possible: false }
                let newY = overlappingEl.y + dy
                elementsMovedLocally[overlappingEl.id] = { ...overlappingEl, y: newY }
                this.clearElementFromPositionMat(overlappingEl, positionMat)
                ibm += overlappingEl.width * dy

                for (let ly = newY; ly < Math.min(newY + overlappingEl.height, this.nRows); ly++) {
                  const from = overlappingEl.x
                  const to = overlappingEl.x + overlappingEl.width
                  dxDims[ly].from = isNaN(dxDims[ly].from) ? from : Math.min(dxDims[ly].from, from)
                  dxDims[ly].to = isNaN(dxDims[ly].to) ? to : Math.max(dxDims[ly].to, to)
                }
              }
            }
          }
        }
        this.addElementToPositionMat(element, positionMat)
        _.each(elementsMovedLocally, (val, key) => {
          this.addElementToPositionMat(val, positionMat)
          elementsMoved[key] = val
        })
        return {
          possible: true,
          element,
          elementsMoved,
          positionMat,
          nElementsMovedInTree: nElementsMovedInTree + elementsMovedLocally.length,
          integratedBlockMovement: integratedBlockMovement + ibm
        }
      }

      // Check all blocks of the element for overlaps
      for (let x = element.x; x < (element.x + element.width); x++) {
        for (let y = element.y; y < (element.y + element.height); y++) {
          // if (y >= this.nRows) continue
          if (positionMat[x][y] !== 0 && positionMat[x][y] !== element.id) {
            // Something's here already
            // Try to move the block in any of the four directions
            // Take the result which works and impacts the least other element blocks / makes the least screen movement

            // Get the element which is overlapping
            let overlappingEl = this.elementID2element[positionMat[x][y]]

            // If the overlapping element has already been moved, this is not an option (performance)
            if (overlappingEl.id in elementsMoved) return { possible: false }

            // If the overlapping element has already been moved, pick it from elementsMoved (quality)
            // if (overlappingEl.id in elementsMoved) overlappingEl = elementsMoved[overlappingEl.id]

            // Remove this element from the position matrix temporarily
            // it will be re-added at its new position one recursion below
            this.clearElementFromPositionMat(overlappingEl, positionMat, true)

            let options = []
            if (upAllowed) {
              // Go up enough blocks to not have any overlap of element and the overlapping element anymore
              let goUp = overlappingEl.y + overlappingEl.height - element.y
              let movedOverlappingEl = { ...overlappingEl, y: overlappingEl.y - goUp }
              // Check if this move is possible
              // Do not allow moving down again, as we could get caught in infinite recursion
              options.push(this.makeSpaceForElement(
                movedOverlappingEl, positionMat, elementsMoved, false, upAllowed, rightAllowed, leftAllowed, recursionDepth
              ))
              options.slice(-1)[0].integratedBlockMovement += goUp * overlappingEl.width
            }

            if (leftAllowed) {
              // Go left enough blocks to not have any overlap of element and the overlapping element anymore
              let goLeft = overlappingEl.x + overlappingEl.width - element.x
              let movedOverlappingEl = { ...overlappingEl, x: overlappingEl.x - goLeft }
              // Check if this move is possible
              // Do not allow moving *right* again, as we could get caught in infinite recursion
              options.push(this.makeSpaceForElement(
                movedOverlappingEl, positionMat, elementsMoved, downAllowed, upAllowed, false, leftAllowed, recursionDepth
              ))
              options.slice(-1)[0].integratedBlockMovement += goLeft * overlappingEl.height
            }

            if (rightAllowed) {
              // Go right enough blocks to not have any overlap of element and the overlapping element anymore
              let goRight = element.x + element.width - overlappingEl.x
              let movedOverlappingEl = { ...overlappingEl, x: overlappingEl.x + goRight }
              // Check if this move is possible
              // Do not allow moving *left* again, as we could get caught in infinite recursion
              options.push(this.makeSpaceForElement(
                movedOverlappingEl, positionMat, elementsMoved, downAllowed, upAllowed, rightAllowed, false, recursionDepth
              ))
              options.slice(-1)[0].integratedBlockMovement += goRight * overlappingEl.height
            }

            if (downAllowed) {
              // Go down enough blocks to not have any overlap of element and the overlapping element anymore
              let goDown = element.y + element.height - overlappingEl.y
              let movedOverlappingEl = { ...overlappingEl, y: overlappingEl.y + goDown }
              // Check if this move is possible
              // Do not allow moving *up* again, as we could get caught in infinite recursion
              options.push(this.makeSpaceForElement(
                movedOverlappingEl, positionMat, elementsMoved, downAllowed, false, rightAllowed, leftAllowed, recursionDepth
              ))
              options.slice(-1)[0].integratedBlockMovement += goDown * overlappingEl.width
            }

            let bestOption = _(options).filter({ possible: true }).sortBy(['integratedBlockMovement']).value()[0]

            if (!bestOption) {
              return { possible: false }
            }

            positionMat = bestOption.positionMat
            elementsMoved = bestOption.elementsMoved
            nElementsMovedInTree += bestOption.nElementsMovedInTree + 1
            integratedBlockMovement += bestOption.integratedBlockMovement
          }
        }
      }

      // If there has been any overlap with other elements, the positionMat at those
      // positions had not been overwritten, when we had added this element's new position
      // to the matrix at the beginning of this function call
      // Therefore, make sure we it is completely present now, omitting the overwrite=false
      // parameter (although, it shouldn't make a difference if we call it with or without that param)
      this.addElementToPositionMat(element, positionMat)

      return {
        possible: true,
        element,
        elementsMoved,
        positionMat,
        nElementsMovedInTree,
        integratedBlockMovement
      }
    },

    addElementToPositionMat (element, positionMat, overwrite = true) {
      for (let x = element.x; x < element.x + element.width; x++) {
        for (let y = element.y; y < element.y + element.height; y++) {
          // If not overwriting, keep the original element, if there is one
          positionMat[x][y] = (!overwrite && positionMat[x][y]) || element.id
        }
      }
    },

    clearElementFromPositionMat (element, positionMat, onlyIfElement = false) {
      for (let x = element.x; x < element.x + element.width; x++) {
        for (let y = element.y; y < element.y + element.height; y++) {
          positionMat[x][y] = !onlyIfElement || positionMat[x][y] === element.id ? 0 : positionMat[x][y]
        }
      }
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />