<template>
  <ConfirmDialog
    :open="open"
    @cancel="$emit('cancel')"
    @confirm="$emit('confirm')"
    :loading="bulkAssignOptions.loading"
    :disabled="bulkAssignOptions.loading || deletePhrase !== confirmText"
    :title="$t(`confirm_dialog.delete_title`)"
    :confirm-text="$t('confirm_dialog.confirm')"
    :cancel-text="$t('cancel')"
  >
    <div class="mb-1" v-html="$t('confirm_dialog.delete_confirm_label', { phrase: deletePhrase })" />
    <v-text-field
      v-model="confirmText"
      ref="confirm-delete-input"
      class="search-bar__search"
      single-line
      dense
      :height="34"
      small
      hide-details
      :elevation="0"
      :placeholder="$t('confirm_dialog.delete_confirm_placeholder')"
    />
  </ConfirmDialog>
</template>

<script>

import Vuex from 'vuex'
import ConfirmDialog from './ConfirmDialog.vue'

export default {
  codit: true,
  name: 'ConfirmDeleteDialog',
  components: {
    ConfirmDialog
  },
  props: {
    cancel: { type: Function, default: () => {} },
    confirm: { type: Function, default: () => {} },
    open: { type: Boolean, default: false },
    count: { type: Number, default: 0 }
  },

  data () {
    return {
      confirmText: ''
    }
  },

  computed: {
    ...Vuex.mapState({
      bulkAssignOptions: state => state.coding.bulkAssignOptions
    }),

    deletePhrase () {
      return this.$t('confirm_dialog.delete_phrase', { count: this.count })
    }
  },

  watch: {
    open: {
      immediate: true,
      handler (val) {
        if (val) setTimeout(() => { this.$refs['confirm-delete-input'].focus() }, 200)
        else this.confirmText = ''
      }
    }
  },

  methods: {
  }

}

</script>

<i18n locale="en" src="@/i18n/en/pages/Codingv2.json" />
<i18n locale="de" src="@/i18n/de/pages/Codingv2.json" />