<template>
  <div
    @click="$emit('open', computedFilter)"
    class="dashboard-filters__filter"
    :class="{
      'dashboard-filters__filter--disabled': disabled,
      'dashboard-filters__filter--warning': computedFilter.hasWarning,
      'dashboard-filters__filter--readonly': readonly
    }"
  >
    <v-tooltip bottom :disabled="!(computedFilter.hasWarning || computedFilter.tooltip)">
      <template v-slot:activator="{ on }">
        <div v-on="on" class="d-flex align-center justify-center h-100 text-truncate">
          <v-icon
            v-if="!readonly"
            class="dashboard-filters__filter__close hover"
            @click.stop="handleFilterRemove()"
          >
            mdi-close-circle
          </v-icon>
          <v-icon
            v-else
            class="dashboard-filters__filter__readonly-icon"
          >
            mdi-filter
          </v-icon>
          <div class="dashboard-filters__filter__title">
            {{ computedFilter.typeText }}:
          </div>
          <div class="dashboard-filters__filter__value">
            {{ computedFilter.valueText }}
          </div>
        </div>
      </template>
      <span v-if="computedFilter.hasWarning" v-html="$t('editing_toolbar.filter_has_warning')" />
      <div v-else-if="computedFilter.tooltip">
        <strong>{{ computedFilter.tooltip.title }}</strong>
        <ul v-if="computedFilter.tooltip.listLines.length">
          <li v-for="(text, i) in computedFilter.tooltip.listLines" :key="i">
            <span v-if="text">{{ text }}</span>
            <em v-else>{{ $t('ds.filters.empty_label') }}</em>
          </li>
        </ul>
        <div v-if="computedFilter.tooltip.more > 0">
          {{ $t('ds.filters.tooltips_n_more', computedFilter.tooltip) }}
        </div>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'

export default {
  name: 'FiltersListItem',

  mixins: [answerFilters, auxiliaryColumnsMixin],

  props: {
    meta: { type: Array, default: () => ([]) },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { type: Object, default: () => ({}) }
  },

  computed: {
    /**
     * discard empty filters
     * @return {Array}
     */
    computedFilter () {
      const needsTooltip = (['auxiliary', 'categories', 'topics']).includes(this.value.type)
      return {
        ...this.value,
        typeText: this.getDisplayedFilterType(),
        valueText: this.getDisplayedFilterValue(),
        tooltip: needsTooltip ? this.getDisplayedFilterDetails() : null
      }
    },

    /**
     * list of auxiliaryTypes names
     * @return {Array}
     */
    auxiliaryTypes () {
      return this.meta.map(auxiliaryType => auxiliaryType.name)
    }
  },

  methods: {
    /**
     * remove the selected filter
     */
    handleFilterRemove (idx) {
      this.$emit('remove')
    },

    /**
     * Filter type label
     */
    getDisplayedFilterType () {
      const { type, value } = this.value
      if (type !== 'auxiliary') return this.$t(`ds.filters.drawer_labels.${type}`)
      return _.truncate(this.auxiliaryTypes[value.col] ? this.auxiliaryTypes[value.col] : value.col, { length: 30 })
    },

    /**
     * Filter value label
     */
    getDisplayedFilterValue () {
      const { type, value } = this.value
      const filter = _.find(this.filterOptions, option => option.type === type)
      return filter.valueLabel(value)
    },

    /**
     * Filter value tooltip
     * gets title and translates code ids to code labels
     */
    getDisplayedFilterDetails () {
      const { type, value, invert } = this.value
      const isAuxilary = type === 'auxiliary'

      // just shows tooltip for select and text auxiliaries
      if (isAuxilary && !(['select', 'text']).includes(value.kind)) return null

      // special return for auxilary text column
      if (isAuxilary && value.kind === 'text') {
        return {
          title: this.$t(`ds.filters.tooltips.auxiliary_text`, { text: value.value }),
          listLines: [],
          more: 0
        }
      }

      // get values
      const filterValue = isAuxilary ? value.value : value

      let details = {
        title: this.$t(`ds.filters.tooltips.${invert ? 'inverted_' : ''}${type}`) + ':',
        listLines: filterValue.slice(0, 5),
        more: filterValue.slice(5).length
      }

      if (type === 'topics') {
        details.listLines = details.listLines.map(({ label }) => label)
      }

      return details
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />
