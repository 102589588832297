<template>
  <div class="main-content uspg" ref="main-content">
    <div>
      <div class="progress-bar" />

      <v-tabs
        v-model="step"
        class="upload-tabs"
        background-color="white"
        :ripple="false"
        grow
        :transition="false"
        :reverse-transition="false"
      >
        <v-tab
          v-for="(item, index) in steps"
          :ripple="false"
          :key="item"
          :transition="false"
          :reverse-transition="false"
          :disabled="index + 1 > step"
        >
          {{ index + 1 + '. ' + $t(`steps.${item}.label`) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="step">
        <v-tab-item
          key="upload"
          :transition="false"
          :reverse-transition="false"
        >
          <step-upload
            @next="goToOrganize"
          />
          <div class="upload-footer pl-4 pr-4 default-bs">
            <v-btn
              class="upload-footer__back"
              color="white"
              @click="cancelUpload"
              :disabled="!answersInput.file.length && !answersInput.input.length && !answersInput.integrationLinks.filter(link => link.external_id !== '').length"
              x-large
              elevation="0"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              :disabled="answersInput.tab === 'integration' ? !validIntegrationUpload : !validUpload"
              :loading="loading.active"
              @click="goToOrganize"
              color="primary"
              x-large
              class="default-bs"
            >
              {{ $t('steps.upload.next') }}
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item
          key="organize"
          :transition="false"
          :reverse-transition="false"
        >
          <div class="d-flex flex-column align-center">
            <step-organize />
            <div class="upload-footer pl-4 pr-4 default-bs">
              <v-btn
                @click="goToUpload"
                class="upload-footer__back"
                color="white"
                x-large
                elevation="0"
              >
                {{ $t('back') }}
              </v-btn>
              <v-btn
                :disabled="!validOrganize"
                :loading="loading.updatingActive"
                @click="goToSettings"
                color="primary"
                x-large
                class="default-bs"
              >
                {{ $t('steps.organize.next') }}
              </v-btn>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item
          key="settings"
          :transition="false"
          :reverse-transition="false"
        >
          <div class="w-100 d-flex flex-column align-center">
            <step-settings />
            <div class="upload-footer pl-4 pr-4 default-bs">
              <v-btn
                @click="goToOrganize"
                class="upload-footer__back"
                color="white"
                x-large
                elevation="0"
              >
                {{ $t('back') }}
              </v-btn>

              <v-tooltip
                :disabled="answersInput.creditsConfirmed"
                top
              >
                <span>{{ $t('steps.settings.confirm_credits_helptip') }}</span>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <div
                      @mouseover="maybeHighlightDeductionInfo"
                      @mouseleave="hideHighlightDeductionInfo"
                    >
                      <v-btn
                        @click="saveProject"
                        :disabled="!validSettings || creditsToDeduct > userCreditsRemaining"
                        :loading="loading.active"
                        color="primary"
                        x-large
                        class="default-bs"
                      >
                        {{ $t('save.title') }}
                      </v-btn>
                    </div>
                  </div>
                </template>
              </v-tooltip>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <UploadNoPermission v-if="!$hasPermission('projects_create')" />
    <UploadTrial v-if="user.subscription.plan_id === PLAN_FREE && user.eligible_for_trial" />
    <UploadSubscription v-if="user.subscription.plan_id === PLAN_FREE && !user.eligible_for_trial" />
  </div>
</template>

<script>

import Vuex from 'vuex'
import StepUpload from '@/components/upload/StepUpload'
import StepOrganize from '@/components/upload/StepOrganize'
import StepSettings from '@/components/upload/StepSettings'
import ApiTaskMixin from '@/mixins/apitask'
import TrialActivationSurveyMixin from '@/mixins/trialActivationSurvey'

import UploadNoPermission from '@/components/modals/UploadNoPermission'
import UploadTrial from '@/components/modals/UploadTrial'
import UploadSubscription from '@/components/modals/UploadSubscription'

import { PLAN_FREE, DEFAULT_INTEGRATION_LINK } from '@/settings/constants'

export default {
  codit: true,
  name: 'Upload',

  components: {
    'step-upload': StepUpload,
    'step-organize': StepOrganize,
    'step-settings': StepSettings,
    UploadNoPermission,
    UploadTrial,
    UploadSubscription
  },

  mixins: [ApiTaskMixin, TrialActivationSurveyMixin],

  /**
   * Warn about losing changes when going to another page (in the app)
   */
  beforeRouteLeave (to, from, next) {
    let doIt = true

    // If there is an uploaded file, confirm navigating away
    if (this.answersInput.file.length && !to.params.skipWarning) doIt = confirm(this.$t('steps.upload.confirm_navigate_away'))
    next(doIt)
  },

  data () {
    return {
      step: 0,
      steps: ['upload', 'organize', 'settings'],
      PLAN_FREE
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user',
      loading: state => state.upload.loading,
      answersRes: state => state.upload.answersRes,
      answersInput: state => state.upload.answersInput,
      integrationId: state => state.upload.answersRes.integrationId
    }),

    ...Vuex.mapGetters([
      'userCreditsRemaining',
      'validOrganize',
      'validUpload',
      'validSettings',
      'validIntegrationUpload',
      'creditsToDeduct'
    ])
  },

  mounted () {
    window.addEventListener('beforeunload', this.beforeUnload)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },

  created () {
    api.get('/api/ui/projects/config')
      .then((res) => {
        let tags = res.data.tabs.filter(({ type }) => type !== 'filter')
        tags = tags.map(({ value }) => value)
        this.$store.commit('setProjectsInheritable', tags)
      })
      .catch(err => {
        this.$maybeRaiseAPIPromiseErr(err)
      })
  },

  methods: {
    /**
     * Return true if there might be unsaved changes and that the user should be warned thorugh an alert
     * @param  {Object} $event The navigate away event
     * @return {Boolean}
     */
    beforeUnload ($event) {
      if (this.answersInput.file.length) $event.returnValue = true
    },

    /* Removes currently selected file and resets warning / error fields */
    cancelUpload () {
      this.$store.commit('setAnswersWarn', null)
      this.$store.commit('setAnswersError', null)
      this.$store.commit('setIntegrationLinks', [DEFAULT_INTEGRATION_LINK])
      this.$store.commit('setAnswersInput', '')
      this.$store.commit('setFile', [])
      this.$store.commit('setSheetName', null)
    },

    /**
     * Go to upload step
     */
    goToUpload () {
      this.step = 0
    },

    /**
     * Maybe highlight credit deduction info dialog via vuex store
     */
    maybeHighlightDeductionInfo () {
      if (!this.answersInput.creditsConfirmed) this.$store.commit('setDeductionHover', true)
    },

    /**
     * Hide credit deduction info hover
     */
    hideHighlightDeductionInfo () {
      this.$store.commit('setDeductionHover', false)
    },

    /**
     * Go to organize step
     */
    goToOrganize () {
      if (this.answersInput.tab === 'input') {
        return this.$store.dispatch('uploadAnswers', () => { this.step = 1 })
      }

      if (this.answersInput.tab === 'integration') {
        return this.$store.dispatch('getColumnsFromIntegration', () => {
          this.$store.commit('setLoading', { key: 'qualtricsIntegrationActive', value: false })
          this.step = 1
        })
      }

      this.step = 1
    },

    /**
     * Go to settings step
     */
    goToSettings () {
      this.$store.dispatch('updateUpload', () => { this.step = 2 })
    },

    /*
    * Save the project
    */
    saveProject () {
      this.$store.dispatch('saveProject', this.answersInput.tab === 'integration' ? this.afterSave : this.asyncCb)
    },

    /**
     * Update loading status
     */
    updateCb (eta, percentage) {
      this.$store.commit('setLoading', { key: 'eta', value: eta })
      this.$store.commit('setLoading', { key: 'percentage', value: percentage })
    },

    asyncCb (res) {
      this.getApiTaskStatus(res.headers['task-id'], parseFloat(res.headers['task-eta']), this.updateCb)
        .then(() => this.afterSave(res.data.id))
        .catch(err => {
          this.$store.commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
          this.$store.commit('setLoading', { key: 'active', value: false })

          throw err
        })
    },

    /**
     * Redirect after project save
     */
    afterSave (projectid) {
      this.$root.snackMsg(this.answersInput.tab === 'integration' ? this.$t('steps.upload.snack_integration_success') : this.$t('steps.upload.snack_success'))
      this.$router.push({ name: 'projects-manage-id', params: { id: projectid, skipWarning: true } })
    }
  }
}

</script>

<style lang=scss>
  @import '../css/upload.scss';
</style>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />
<i18n locale='en' src='@/i18n/en/AppPage.json' />
<i18n locale='de' src='@/i18n/de/AppPage.json' />