import Vue from 'vue'
import { get3ShadesForColor } from '@/utils/colorUtils'

import {
  CHART_TYPES_TO_API_ENUM
} from '@/settings/constants'

export default {
  namespaced: true,

  state () {
    return {}
  },

  mutations: {
    register (state, { entityId, extraQueryParams }) {
      Vue.set(state, entityId, {
        extraQueryParams
      })
    },

    identify (state, { entityIdToSet, entityIdToRemove }) {
      Vue.set(state, entityIdToSet, state[entityIdToRemove])
      Vue.delete(state, entityIdToRemove)
    },

    delete (state, { entityId }) {
      Vue.delete(state, entityId)
    },

    setMeta (state, { entityId, metaData }) {
      Vue.set(state, entityId, metaData)
    },

    update (state, { entityId, data }) {
      let entityState = _.cloneDeep(state[entityId])
      entityState.topics_complete_union = data
      Vue.set(state, entityId, entityState)
    }
  },

  actions: {
    async register ({ commit, dispatch, rootState }, { entityId, entityData, extraQueryParams }) {
      if (!rootState.coding.registerManager.entities[entityId]) return

      commit('register', entityId, extraQueryParams)
      return dispatch('fetch', { entityId, entityData, extraQueryParams })
    },

    async registerManually ({ commit, dispatch }, { entityId, metaData, extraQueryParams }) {
      commit('register', entityId, extraQueryParams)
      commit('setMeta', { entityId, metaData })
    },

    async registerMany ({ commit, dispatch }, { list, extraQueryParams }) {
      list.forEach(({ entityId }) => {
        commit('register', { entityId })
      })
      return dispatch('fetchAll', { list, extraQueryParams })
    },

    async update ({ commit, dispatch, rootState }, { entityId, entityData, extraQueryParams }) {
      if (!rootState.coding.registerManager.entities[entityId]) return

      return dispatch('fetch', { entityId, entityData, extraQueryParams })
    },

    manualUpdate ({ commit, dispatch }, { entityId, data }) {
      commit('update', { entityId, data })
    },

    delete ({ commit, dispatch }, { entityId }) {
      commit('delete', { entityId })
    },

    async fetch ({ commit, rootState }, { entityId, entityData, extraQueryParams }) {
      const method = rootState.coding.registerManager.entities[entityId]?.method || 'post'
      const fetchChartId = method === 'post' ? 'ch__' : entityId

      let { data } = await api[method](
        `/api/ui/charts/${fetchChartId}/meta?${new URLSearchParams(extraQueryParams).toString()}`,
        _.chain(entityData)
          .cloneDeep()
          .merge({
            type: CHART_TYPES_TO_API_ENUM[entityData.type]
          })
          .omit(['id'])
      )

      commit('setMeta', { entityId, metaData: Object.freeze(data) })
      return data
    },

    async fetchAll ({ state, dispatch }, { list, extraQueryParams }) {
      const promises = list.map(
        ({ entityId, entityData }) => dispatch('fetch', { entityId, entityData, extraQueryParams })
      )
      return Promise.all(promises)
    },
    identify ({ commit, dispatch }, { entityIdToSet, entityIdToRemove }) {
      commit('identify', { entityIdToSet, entityIdToRemove })
    }
  },

  getters: {
    overall: (state, getters) => { return _.cloneDeep(state) },

    state: (state, getters) => (id) => {
      return state[id]
    },

    topicID2Topic: (state) => (id) => _.keyBy(state[id]?.topics_complete_union, 'id'),

    topicsByCat: (state) => (id) => _.groupBy(state[id]?.topics_complete_union, 'category'),

    topicCats: (state, getters) => (id) => _.keys(getters.topicsByCat(id)),

    catColors: (state, getters) => (id) => {
      let mapping = {}
      getters.topicCats(id).forEach(cat => {
        mapping[cat] = get3ShadesForColor(getters.topicsByCat(id)[cat][0].color)
      })
      return mapping
    },

    topicCats2CatIdx: (state, getters) => (id) => {
      let mapping = {}
      getters.topicCats(id).forEach((cat, idx) => { mapping[cat] = idx })
      return mapping
    }
  }
}
