<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane
        v-model="config"
        v-bind="controlPaneProps"
        v-on="controlPaneEvents"
        ref="control-pane"
        slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>
        <template v-slot:general>
          <settings-drawer-item
            :title="$t('controls.general.bar_orientation.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.barOrientation" hide-details class="mt-0 mb-2">
                <v-radio :label="$t('controls.general.bar_orientation.horizontal')" value="horizontal" />
                <v-radio :label="$t('controls.general.bar_orientation.vertical')" value="vertical" />
              </v-radio-group>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.ordinal_axis.plot_categories')"
          >
            <template slot="content">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-checkbox
                      v-model="config.plotCategories"
                      hide-details
                      :label="$t('controls.ordinal_axis.plot_categories')"
                    />
                  </div>
                </template>

                <span v-html="$t('controls.ordinal_axis.tooltip_plot_categories_disabled')" />
              </v-tooltip>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:colors>
          <settings-drawer-item
            :title="$t('colors.palette.title')"
          >
            <template slot="content">
              <div
                class="d-flex align-center theme--light v-label mb-3"
                v-for="(range, idx) in config.ranges"
                :key="idx"
              >
                <div
                  class="color-indicator mr-2"
                  :style="{ 'background-color': range.color }"
                  @click.stop="handleShowRangeColorPicker(idx, $event)"
                />
                {{ range.label }}
              </div>
            </template>
          </settings-drawer-item>
          <color-picker
            :show.sync="showColorPicker"
            v-if="config.ranges && config.ranges.length && config.ranges[selectedCustomColor]"
            v-model="config.ranges[selectedCustomColor].color"
            :offset-top="rangeColorPickerConfig.offsetTop"
            :offset-left="rangeColorPickerConfig.offsetLeft + 5"
            :min-top="-80"
            lazy
          />
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.percentages"
                :label="$t('controls.value_axis.percentages')"
              />

              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-checkbox
            v-model="config.showSampleSize"
            hide-details
            :label="$t('settings.sample_size.label_control')"
            class="mt-2"
          />
        </template>

        <template v-slot:ordinal-axis>
          <settings-drawer-item
            :title="$t('controls.ordinal_axis.sort_by.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.sortOrdinalBy" column hide-details class="mt-0">
                <v-radio :label="$t('controls.ordinal_axis.sort_by.none')" :value="null" />
                <v-radio :label="$t('controls.ordinal_axis.sort_by.frequency')" value="frequency" />
                <v-radio :label="$t('controls.ordinal_axis.sort_by.alphabet')" value="alphabet" />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:value-axis-display>
          <v-checkbox
            v-model="config.reverseOrdinal"
            :label="$t('controls.ordinal_axis.reverse')"
            hide-details
          />
        </template>

        <!-- TODO: component of just slots, is there a better way ?? -->
        <slot
          name="datasets"
          slot="datasets"
          :dataset-props="datasetProps"
          :disable-master-settings="true"
        />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <slot name="chart-type-selection" slot="chart-type-selection" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <v-chart
          ref="chart"
          v-if="initialReady"
          :style="chartStyle"
          v-bind="chartProps"
          v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ColorPicker from '@/components/ColorPicker'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'
import { encodeStr } from '@/utils/filters'
import {
  SENTIMENT_RANGE_FULL as SENTIMENT_RANGE
} from '@/settings/constants'

import 'echarts/lib/chart/bar'

const CODE_SPLIT_TOK = '-&/_&/_-'

const ICON_PLUS_SVG = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\'%3E%3Cpath fill=\'%23999\' d=\'M19 19V5H5v14h14m0-16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-8 4h2v4h4v2h-4v4h-2v-4H7v-2h4V7z\'/%3E%3C/svg%3E'
const ICON_MINUS_SVG = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\'%3E%3Cpath fill=\'%23999\' d=\'M19 19V5H5v14h14m0-16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-2 8v2H7v-2h10z\'/%3E%3C/svg%3E'

const DEFAULT_CHART_DATASET_FIELDS = {
  overall: 0,
  ...SENTIMENT_RANGE.reduce((sum, item) => {
    return {
      ...sum,
      [`${item}_count`]: 0,
      [`${item}_perc`]: 0
    }
  }, {})
}

export default {
  codit: true,
  name: 'ChartSentimentBar',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem,
    'color-picker': ColorPicker
  },

  mixins: [chartMixin],

  data () {
    return {
      // Settings for chart.js mixin
      resultKeys: ['counts'],
      forceAggregate: false,
      // end settings

      APItype: 'S_BAR',

      config: {
        // General
        title: undefined,
        aggregate: undefined,
        barOrientation: 'horizontal',
        xField: 'topicLabel',
        stackDatasets: false,
        normStacks: false,
        dimensions: undefined,

        colorBy: {},

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255, 1)',

        plotCategories: true,
        reverseOrdinal: undefined,
        sortOrdinalBy: 'frequency',

        // Value axis
        valueAxisName: undefined,
        valueAxisToFullData: false,

        ordinalAxisName: 'Topics',

        // Grid line
        gridLine: true,

        percentages: false,

        // Labels
        dataLabelSize: 11,
        maxAxisLabelLength: 25,
        axisLabelSize: 11,
        labelsEnabled: true,
        labelPosition: 'outside',
        labelsTotal: false,
        showLegend: true,
        decimalPlaces: 0,
        showSampleSize: true,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        // Expansion of categories
        categoriesExpanded: {},

        // ranges
        ranges: [],
        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },

      availableSettingTiles: {
        ...this.availableSettingTiles,
        valueAxis: ['valueAxisName', 'valueAxisToFullData', 'axisLabelSize', 'maxAxisLabelLength']
      },

      /*
      * variables for color picker
      */
      selectedCustomColor: 0,
      rangeColorPickerConfig: {},
      showColorPicker: false,

      sentimentRange: SENTIMENT_RANGE
    }
  },

  computed: {
    /*
    *
    * CHART SETTINGS PANEL
    * AND GENERAL VISUALISATION BY LIBRARY
    * AND RELATED
    *
    */

    /*
    * Return settings for dataset component
    * @return {Object}
    */
    datasetProps () {
      return { disableFilters: true, duplicatable: false, editable: false }
    },
    minContainerHeight () {
      return this.isVertical ? 600 : 400
    },
    chartColStyle () {
      return {
        right: this.hasScrollBar ? '-15px' : '0px',
        'min-height': this.isVertical ? `${this.minContainerHeight + 100}px` : 'calc(100% - 100px)'
      }
    },
    chartContainerStyle () {
      return {
        'min-height': this.isHorizontal ? `${this.minCategoryDimSize + 100}px` : '',
        'min-width': this.isVertical ? `${this.minCategoryDimSize + 100}px` : ''
      }
    },
    minCategoryDimSize () {
      let nCategories = _.uniq(_.map(this.chartDataset, 'x')).length
      let barsPerGroup = this.chartDatasetsGrouped.length
      return nCategories * (10 + barsPerGroup * 8) + 24 + 100
    },

    /*
    /*
    /* CHART RELATED VARIABLES COLLECTED
    /* in order to pass reactivity dependent function properties
    /*
    */

    chartDependentEnv () {
      return {
        config: this.config
      }
    },

    /*
    *
    * CHART DATA RELATED STUFF
    *
    */

    /*
    * Returns flat array of data eleemnts
    * to build chart.
    * Will be combined into chart options
    * after grouping
    * @return {Array}
    */
    chartDataset () {
      if (
        !this.initialReady ||
        !this.dataSorted ||
        !Object.keys(this.results.counts?.categoriesSentiment[0])?.length ||
        !Object.keys(this.results.counts?.topics[0])?.length
      ) return []

      let res = []
      let dsNamesUsed = {}

      const datasetsToPlot = this.config.aggregate && this.datasets.length ? [this.datasets[0]] : this.datasets

      // modify shrinked values so that
      // overall bar length match percentage
      // due to float calculation precision the numbers are incorrect
      // and are the sum f those are bigger
      const reduceSentimentShrinkedValues = (list, expectedCount, expectedPerc) => {
        // get real numbers
        const currentCount = SENTIMENT_RANGE.reduce((sum, item) => sum + list[`${item}_count`], 0)
        const currentPerc = SENTIMENT_RANGE.reduce((sum, item) => sum + list[`${item}_perc`], 0)

        // get diffs
        const reduceBy = parseFloat((currentCount - expectedCount).toFixed(2))
        const reduceByPerc = parseFloat((currentPerc - expectedPerc).toFixed(2))

        // reduce the length of last bar sentiment piece
        let modifiedList = { ...list }
        const reversedRangeList = [...SENTIMENT_RANGE].reverse()
        for (
          let item in reversedRangeList
        ) {
          if (modifiedList[`${reversedRangeList[item]}_count`] > 0) {
            modifiedList[`${reversedRangeList[item]}_count`] = modifiedList[`${reversedRangeList[item]}_count`] - reduceBy
            modifiedList[`${reversedRangeList[item]}_perc`] = modifiedList[`${reversedRangeList[item]}_perc`] - reduceByPerc
            break
          }
        }
        return modifiedList
      }

      const generateCategoryItem = (ds, dsIdx, topic, dsName) => {
        const shrinkBy = +(
          this.results.counts.categories[dsIdx]?.[topic.category] /
          this.calculateTotalSentimentCount(this.results.counts.categoriesSentiment[dsIdx]?.[topic.category], dsIdx)
        ).toFixed(2)

        let entry = {
          ...getCategoryBasicData(ds, dsIdx, topic, dsName),
          ...reduceSentimentShrinkedValues(
            getCategorySentimentData(dsIdx, topic, shrinkBy),
            this.results.counts.categories[dsIdx]?.[topic.category] || 0,
            100 * this.results.counts.categories[dsIdx]?.[topic.category] / this.globalResults?.counts?.per_dataset?.[0] || 0
          )
        }

        entry.x = entry[this.config.xField]
        return entry
      }
      const generateTopicItem = (dsIdx, topic, dsName) => {
        const shrinkBy = +(
          this.results.counts.topics[0]?.[topic.id]?.results.counts?.per_dataset?.[0] /
          this.calculateTotalSentimentCount(this.results.counts.topics[0]?.[topic.id]?.sentiments, dsIdx)
        ).toFixed(2) // get ration between rows counts and sentiments counts

        let entry = {
          ...getTopicBasicData(dsIdx, topic, dsName),
          ...getTopicSentimentData(dsIdx, topic, shrinkBy)
        }

        entry.x = entry[this.config.xField]
        return entry
      }
      const getCategoryBasicData = (ds, dsIdx, topic, dsName) => {
        // Only add the code categories if configs say we should, and if this is an unseen category
        let catIcon = _.get(this.config.categoriesExpanded, topic.category) ? 'min' : 'pls'
        return {
          topicLabel: `CAT${CODE_SPLIT_TOK}{${catIcon}|} ${topic.category}`,
          topicCategory: topic.category,
          isCategory: true,
          dsName,
          dsIdx,
          count: this.results.counts.categories[dsIdx]?.[topic.category],
          perc: 100 * this.results.counts.categories[dsIdx]?.[topic.category] / this.globalResults?.counts?.per_dataset?.[0] || 0,
          ...DEFAULT_CHART_DATASET_FIELDS
        }
      }
      const getTopicBasicData = (dsIdx, topic, dsName) => {
        return {
          topicLabel: `${topic.id}${CODE_SPLIT_TOK}{cod|${topic.label}}`,
          topicLabelOrig: topic.label,
          topicID: topic.id,
          topicCategory: topic.category,
          topicDescription: topic.description,
          isCategory: false,
          itemStyle: { opacity: this.config.plotCategories ? 0.7 : 1.0 },
          dsName,
          dsIdx,
          count: this.results.counts.topics[0]?.[topic.id]?.results.counts?.per_dataset?.[0],
          perc: 100 * this.results.counts.topics[0]?.[topic.id]?.results.counts?.per_dataset?.[0] / this.globalResults?.counts?.per_dataset?.[0] || 0,
          ...DEFAULT_CHART_DATASET_FIELDS
        }
      }
      const getCategorySentimentData = (dsIdx, topic, shrinkBy) => {
        if (
          typeof this.results.counts.categoriesSentiment[dsIdx]?.[topic.category] !== 'object'
        ) return {}
        return Object.keys(this.results.counts.categoriesSentiment[dsIdx]?.[topic.category]).reduce((sum, item) => {
          // _count and _ perc for bar length render
          // _count and _ perc for bar labels
          return {
            ...sum,
            ...{
              [`${item}_count`]: +(
                this.results.counts.categoriesSentiment[dsIdx][topic.category][item].counts?.per_dataset?.[0] * shrinkBy
              ).toFixed(2),
              [`${item}_perc`]: +(
                100 * this.results.counts.categoriesSentiment[dsIdx][topic.category][item].counts?.per_dataset?.[0] * shrinkBy /
                this.globalResults?.counts?.per_dataset?.[0]
              ).toFixed(2),
              [`${item}_count_label`]: this.results.counts.categoriesSentiment[dsIdx][topic.category][item].counts?.per_dataset?.[0],
              [`${item}_perc_label`]: 100 * this.results.counts.categoriesSentiment[dsIdx][topic.category][item].counts?.per_dataset?.[0] / this.globalResults?.counts?.per_dataset?.[0]
            }
          }
        }, {})
      }
      const getTopicSentimentData = (dsIdx, topic, shrinkBy) => {
        const itemInUse = this.results.counts.topics[dsIdx][topic.id]
        if (
          typeof itemInUse !== 'object'
        ) return {}
        return Object.keys(itemInUse.sentiments).reduce((sum, sentiment) => {
          // _count and _ perc for bar length render
          // _count and _ perc for bar labels
          return {
            ...sum,
            ...{
              [`${sentiment}_count`]: itemInUse.sentiments[sentiment].counts?.per_dataset?.[0],
              [`${sentiment}_perc`]: 100 * itemInUse.sentiments[sentiment].counts?.per_dataset?.[0] / this.globalResults?.counts?.per_dataset?.[0],
              [`${sentiment}_count_label`]: itemInUse.sentiments[sentiment].counts?.per_dataset?.[0],
              [`${sentiment}_perc_label`]: 100 * itemInUse.sentiments[sentiment].counts?.per_dataset?.[0] / this.globalResults?.counts?.per_dataset?.[0]
            }
          }
        }, {})
      }

      datasetsToPlot.forEach((ds, dsIdx) => {
        let prevCat = null
        let dsName
        if (ds.settings.name in dsNamesUsed) dsName = `${ds.settings.name} [${dsNamesUsed[ds.settings.name]++}]`
        else (dsNamesUsed[ds.settings.name] = 1) && (dsName = ds.settings.name)

        /*
        * We need to define *none_count* and *none_perc* for each item.
        * Otherwise it won't work.
        * Not sure why but it's repeatable in the echarts sandbox.
        * The exact problem is that single variable to use in bar stacked chart
        * defined only for 1 dataset element won't be rendered
        * and if we put it in every items it will
        */
        this.dataSorted.forEach(topic => {
          if (this.config.plotCategories && topic.category !== prevCat) {
            const entry = generateCategoryItem(ds, dsIdx, topic, dsName)
            res.push(entry)
            prevCat = topic.category
          }

          // Push the codes if the category is expanded
          if (_.get(this.config.categoriesExpanded, topic.category) || !this.config.plotCategories) {
            const entry = generateTopicItem(dsIdx, topic, dsName)
            res.push(entry)
          }
        })
      })

      if (this.doInternalReverse) res.reverse()

      return res
    },

    /**
     * The stacked version of the chartDatasets.
     * If stacking is turned off:
     *   this is a mapping of the topic ID + name + datasetIdx to an array of 1 containing that element.
     *   The mapping will contain the same number of elements as the input array.
     *
     * If stacking is turned on:
     *   This is the mapping of the topic ID + name to an array of all the elements in that stack
     *   The mapping will contain number of input elements / number of datasets
     * @return {[type]} [description]
     */
    chartDatasetsStacked () {
      let res = _.groupBy(this.chartDataset,
        row => row.x + String(row.isCategory) + row[this.config.stackDatasets ? '.' : 'dsIdx']
      )

      // If we should norm the stacks
      // add the pseudoPerc property, to norm every stack to 100%
      if (this.config.normStacks) {
        _.each(res, (entries, key) => {
          let percTotal = _.sumBy(entries, 'perc')
          entries.forEach(e => {
            e.pseudoPerc = 100 * e.perc / percTotal
          })
        })
      }

      return res
    },

    /**
     * Makes sure topics of same category come after each other
     * performs all sorting if `plotCategories` is activated
     * @return {list} List of topics
     */
    dataSorted () {
      let topics = this.topics

      // Only responsible for sorting if plotCategories is enabled
      // otherwise sorting happens in `ordinalClassesSorted` getter
      if (
        this.config.sortOrdinalBy === 'frequency'
      ) {
        topics = _.orderBy(topics, c => {
          return this.results?.counts?.topics?.[0]?.[c.id]?.results.counts?.per_dataset?.[0]
        }, 'desc')
      } else if (
        this.config.sortOrdinalBy === 'alphabet'
      ) {
        topics = _.orderBy(topics, c => c.label.toLowerCase(), 'asc')
      }

      if (this.config.plotCategories) {
        let grouped = _.groupBy(topics, 'category')
        if (this.config.plotCategories) {
          if (this.config.sortOrdinalBy === 'frequency') grouped = _.orderBy(grouped, g => this.results.counts.categories.reduce((sum, category) => sum + category[g[0].category], 0), 'desc')
          if (this.config.sortOrdinalBy === 'alphabet') grouped = _.orderBy(grouped, g => g[0].category.toLowerCase(), 'asc')
        }
        topics = _.flatMap(grouped)
      }

      return topics
    },

    doInternalReverse () {
      return (!this.config.reverseOrdinal && this.isHorizontal) || (this.config.reverseOrdinal && this.isVertical)
    },

    valueField () {
      return this.config.normStacks ? 'pseudoPerc' : this.config.percentages ? 'perc' : 'count'
    },
    valueUnit () { return this.config.percentages || this.config.normStacks ? '%' : '' },

    stacksInfo () {
      let res = {}
      _.each(this.chartDatasetsStacked, (val, key) => {
        let dsIdx = this.config.stackDatasets ? 0 : val[0].dsIdx
        res[val[0].x] || (res[val[0].x] = {})
        res[val[0].x][dsIdx] = {
          sum: _.sumBy(val, this.valueField),
          cnt: val.length,
          lastCodeID: val.slice(-1)[0].topicID }
      })
      return res
    },

    maxVal () {
      const dataMaxValue = this.config.percentages
        ? 100
        : _.max(_.map(this.datasets, d => d.result && d.result.length))
      const maxCategoryCount = _.max( // calculate using only 1 dataset
        Object.values(this.results.counts.categoriesSentiment[0]).map(
          item => this.calculateTotalSentimentCount(item, 0)
        )
      )
      return this.config.valueAxisToFullData
        ? dataMaxValue
        : this.config.percentages
          ? 100 * maxCategoryCount / this.globalResults?.counts?.per_dataset[0]
          : maxCategoryCount
    },

    /*
    *
    * GRID AND AXIS RELATED STUFF
    *
    */
    /*
    * Return ordinal axis in use as a letter
    * @return {String}
    */
    axis () {
      if (
        this.isVertical
      ) {
        return 'y'
      }
      return 'x'
    },
    isHorizontal () { return this.config.barOrientation === 'horizontal' },
    isVertical () { return this.config.barOrientation === 'vertical' },
    isDataLabelInside () { return this.config.labelPosition === 'inside' },
    labelsVerticalFactor () {
      const { decimalPlaces, dataLabelSize, percentages } = this.config
      return Math.floor((percentages ? (3 + decimalPlaces * 1.3) : 2) * dataLabelSize / 10)
    },
    ordinalClasses () {
      return _.uniq(_.map(this.chartDataset, 'x'))
    },
    ordinalClassesSorted () {
      // When plotting the categories, sorting here doesn't work
      // there won't be any stacks in this case anyways
      // Sorting will be applied in the `chartDataset` getter
      if (this.config.plotCategories) return this.classes

      let classes = _.clone(this.ordinalClasses)
      if (this.config.sortOrdinalBy === 'alphabet') classes = _.sortBy(this.ordinalClasses, c => c.toLowerCase())
      if (this.config.sortOrdinalBy === 'frequency') classes = _.orderBy(this.ordinalClasses, c => this.stacksInfo[c][0].sum, 'desc')

      return this.doInternalReverse ? classes.reverse() : classes
    },
    ordinalAxis () {
      return {
        type: 'category',
        name: this.config.ordinalAxisName,
        axisTick: {
          show: false
        },

        triggerEvent: true,

        data: this.ordinalClassesSorted,
        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        nameRotate: (this.isVertical ? 90 : 0),

        axisLabel: {
          show: true,
          rotate: (this.isVertical ? 90 : 0),
          align: 'right',
          interval: 0,
          rich: {
            // It's important that all rich elements have the same tag name length
            // as this is take into consideration when computing the correct padding
            cod: {
              fontSize: this.config.axisLabelSize,
              color: '#666'
            },
            pls: {
              height: 14,
              width: 14,
              align: 'right',
              verticalAlign: 'top',
              backgroundColor: {
                image: ICON_PLUS_SVG
              }
            },
            min: {
              height: 14,
              width: 14,
              align: 'right',
              verticalAlign: 'top',
              backgroundColor: {
                image: ICON_MINUS_SVG
              }
            }
          },

          fontSize: this.config.axisLabelSize,
          formatter: (lbl) => {
            return this.maybeTruncateLabel(
              // For stacking reasons, we have concatenated the ID with the topic label
              // (as topic labels might be identical for different topics)
              // Now separate them again to only get the topic labels
              this.config.xField === 'topicLabel' ? lbl.split(CODE_SPLIT_TOK)[1] : lbl,
              // Also, there is extra formatting in the topicLabels for the topics
              // Add that to the maximum label length that is allowed
              { maxLength: this.config.maxAxisLabelLength + (this.config.xField === 'topicLabel' ? (5 + lbl.startsWith('CAT') * 2) : 0) }
            )
          }
        }
      }
    },
    valueAxis () {
      const options = {
        name: this.config.valueAxisName,
        nameTextStyle: { fontStyle: 'italic', color: 'grey', fontSize: this.config.axisLabelSize },
        type: 'value',
        max: this.maxVal,
        triggerEvent: true,
        axisLabel: {
          fontSize: this.config.axisLabelSize,
          formatter: (value) => {
            return this.maybeTruncateLabel(
              `${Math.round(value)}${this.config.percentages ? ' %' : ''}`,
              { maxLength: this.config.maxAxisLabelLength }
            )
          }
        },
        splitLine: {
          show: this.config.gridLine
        }
      }

      // For showing sample size:
      if (
        this.config.showSampleSize &&
        typeof this.globalResults?.counts?.per_dataset?.[0] === 'number'
      ) {
        options.name += ` (n=${this.globalResults.counts?.per_dataset?.[0]})`
      }

      return options
    },

    /*
    * !!!
    * CHART SETTINGS ASSIGNMENTS
    * FOLLOWS ACTUAL ORDER OF USAGE
    * !!!
    */

    /**
    * dataset settings
    * Will be combined into chart options
    * @return {Object}
    */
    chartDatasetsGrouped () {
      return _(this.chartDataset)
        .groupBy(row => row.dsName + (this.config.stackBy !== null ? String(row[this.config.stackBy]) : ''))
        .map((val, key) => ({ source: val }))
        .value()
    },

    /**
    * xAxis settings
    * Will be combined into chart options
    * @return {Object}
    */
    xAxis () {
      let axis = (this.isVertical ? this.ordinalAxis : this.valueAxis)
      return {
        ...axis,
        nameLocation: 'end',
        nameTextStyle: {
          ...axis.nameTextStyle,
          align: 'right',
          verticalAlign: 'top',
          lineHeight: this.isVertical ? 0 : 60 + this.config.axisLabelSize * 2,
          padding: [0, 3, 0, 0]
        }
      }
    },

    /**
    * yAxis settings
    * Will be combined into chart options
    * @return {Object}
    */
    yAxis () {
      const { isVertical, labelsVerticalFactor } = this
      const { labelsEnabled } = this.config
      let axis = this.isHorizontal ? this.ordinalAxis : this.valueAxis
      let nameTextStylePaddingBottom = 0
      if (isVertical && labelsEnabled) {
        nameTextStylePaddingBottom = labelsVerticalFactor * 8
      }
      return {
        ...axis,
        nameTextStyle: {
          ...axis.nameTextStyle,
          padding: [0, 0, nameTextStylePaddingBottom, 0]
        }
      }
    },

    /**
    * Series settings
    * in use for sentiment bar chart.
    * Will be combined into chart options
    * @return {Object}
    */
    series () {
      // Destructuring variables for reactivity (and simplicity)
      const {
        toFixed, isVertical, chartDatasetsGrouped, valueField,
        labelsVerticalFactor, axis
      } = this
      const { labelsTotal, decimalPlaces, labelPosition, labelsEnabled, dataLabelSize, stackDatasets, xField } = this.config

      let offsetX = Math.floor(2 + (dataLabelSize / 2))
      let offsetY = labelsVerticalFactor * 3

      /*
      * Creates options for pseudo element
      * acting role of overall counter
      */
      const getBarOverallItem = (dsIdx) => {
        return {
          type: 'bar',
          datasetIndex: dsIdx,
          stack: 'total',
          name: 'overall',
          label: {
            fontSize: dataLabelSize,
            show: !!labelPosition && labelsEnabled,
            silent: true,
            formatter: (el) => {
              let res
              res = toFixed(el.data[this.valueField], decimalPlaces)
              if (this.valueField === 'perc') {
                res += '%'
              }
              return res
            },
            position: isVertical ? 'top' : 'right'
          },
          tooltip: {
            show: false
          },
          encode: {
            [axis]: 'overall'
          },
          animation: false
        }
      }
      /*
      * Creates basic options for both sentiment and simple bar charts
      */
      const getBarBasicItemOptions = (ds, dsIdx) => {
        return {
          type: 'bar',
          top: 30,
          barGap: '10%',
          encode: {
            y: (isVertical ? valueField : 'x'),
            x: (isVertical ? 'x' : valueField)
          },
          label: {
            show: !!labelPosition && labelsEnabled,
            rotate: isVertical ? 90 : 0,
            offset: isVertical ? [offsetY, offsetX] : [0, 0],
            fontSize: dataLabelSize,
            distance: isVertical ? 12 : 0,
            padding: [2, 3],
            color: labelsTotal ? '#000' : undefined
          },

          datasetIndex: dsIdx,
          stack: (xField !== 'topicLabel' || stackDatasets ? stackDatasets ? 'stack' : String(ds.source[0].dsIdx) : null),
          tooltip: {
            confine: true
          },
          itemStyle: {
            emphasis: {
              barBorderRadius: [5, 5]
            },
            normal: {
              barBorderRadius: [5, 5]
            }
          },
          animation: false
        }
      }

      /*
      * Creates basic options for sentiment bar charts
      * for single item
      */
      const getBarSentimentItemOptions = (item) => {
        const backgroundItemColor = this.config.ranges ? _.find(this.config.ranges, ({ id }) => id === item).color : ''
        const contrastWithBackground = backgroundItemColor !== '' ? this.getContrast(backgroundItemColor) : ''
        const labelColor = contrastWithBackground === 'low'
          ? '#000000'
          : contrastWithBackground === 'high'
            ? '#ffffff'
            : ''

        return {
          stack: 'total',
          color: backgroundItemColor,
          name: item,
          label: {
            color: labelColor,
            position: 'inside',
            show: false,
            formatter: (params) => {
              let value = this.toFixed(params.value[`${SENTIMENT_RANGE[params.seriesIndex]}_${this.valueField}_label`], this.config.decimalPlaces)
              if (this.valueField === 'perc') {
                value += '%'
              }
              return value
            }
          },
          tooltip: {
            formatter: (el) => {
              if (!el.value) return
              let res = `${this.$escapeHtml(el.value.topicCategory)}`
              if (!el.value.isCategory) res += ` | ${this.$escapeHtml(el.value.topicLabelOrig)}`
              res += `<br> ${
                SENTIMENT_RANGE[el.seriesIndex]
              }`

              res += `: ${
                Math.round(el.value[`${SENTIMENT_RANGE[el.seriesIndex]}_count_label`])
              } (${
                toFixed(el.value[`${SENTIMENT_RANGE[el.seriesIndex]}_perc_label`], decimalPlaces)
              }%)`
              return res
            }
          },
          encode: {
            [axis]: `${item}_${this.valueField}`
          },
          emphasis: {
            label: {
              show: true
            }
          }
        }
      }
      /*
      * Creates series options triade of sentiment elements
      * for each dataset.
      * I.e. we create 3 series items to show stacked bar
      * and assign to each specific value
      */
      const getSentimentBarItemsGroup = (ds, dsIdx) => {
        return SENTIMENT_RANGE.reduce((sum, item) => [
          ...sum,
          _.merge({}, getBarBasicItemOptions(ds, dsIdx), getBarSentimentItemOptions(item))
        ], [])
      }

      /*
      * It aggregates either simple bar item series
      * or senetiment chart ones
      */
      return chartDatasetsGrouped.reduce((sum, ds, dsIdx) => {
        return [...sum, ...getSentimentBarItemsGroup(ds, dsIdx), getBarOverallItem(dsIdx)]
      }, [])
    },

    /**
    * Grid settings
    * in use for sentiment bar chart.
    * Will be combined into chart options
    * @return {Object}
    */
    grid () {
      const { isVertical, isHorizontal, labelsVerticalFactor } = this
      const { decimalPlaces, dataLabelSize, percentages, labelsEnabled } = this.config
      const left = 15
      const bottom = 35
      let top = 100
      let right = 30
      if (isHorizontal && labelsEnabled) {
        right = Math.floor(
          (
            percentages ? ((2 + decimalPlaces) * 12) : 20
          ) * dataLabelSize / 10) + 20
      }
      if (isVertical && labelsEnabled) {
        top += labelsVerticalFactor * 8
      }
      return { top, left, bottom, right, containLabel: true }
    },

    /**
    * Legend settings
    * in use for sentiment bar chart.
    * Will be combined into chart options
    * @return {Object}
    */
    legend () {
      return {
        show: this.config.showLegend,
        orient: 'horizontal',
        top: this.isDashboard ? 20 : 30,
        selectedMode: false,
        backgroundColor: 'white',
        borderRadius: 3,
        padding: this.isDashboard ? [0, 0, 0, 0] : [15, 30, 15, 30],
        itemGap: 40,
        textStyle: {
          fontSize: this.config.dataLabelSize,
          color: (el) => this.$color.sentiment[el],
          rich: {
            label: {
              fontSize: this.config.dataLabelSize
            },
            value: {
              fontWeight: 'bold',
              fontSize: this.config.dataLabelSize,
              align: 'middle',
              verticalAlign: 'middle',
              lineHeight: 20,
              color: '#ffffff'
            }
          }
        },
        data: this.series.filter(({ name }) => name !== 'overall'),
        formatter: (el) => {
          let item = this.config.ranges?.find(({ id }) => id === el)

          return [`{label| ${item && item.label}}`]
        }
      }
    },

    /*
    * Returns combined compicated options for chart
    * @return {Object}
    */
    chartOptions () {
      return {
        ...this.defaultOptions,
        chartDependentEnv: this.chartDependentEnv,
        dataset: this.chartDatasetsGrouped,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
        grid: this.grid,
        legend: this.legend,
        backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
      }
    }
  },

  watch: {
    'showTopNTopics' (val, old) {
      if (
        old !== null ||
        this.isDashboard
      ) {
        return
      }
      this.config.plotCategories = false
    }
  },

  /*
  * set different dataset for charts
  * also, it's necessary to do the same while initialiting
  * and chart type is changed
  */
  created () {
    if (!this.config.ranges.length) {
      this.$set(this.config, 'ranges', this.generateDefaultRanges())
    }
  },

  methods: {
    /*
    * highlight value of sentiment
    * and set contrast text color for label
    * by updating series for exact element
    */
    mouseover ($evt) {
      if (
        $evt.componentType !== 'series' ||
        $evt.componentIndex === 3 ||
        $evt.componentIndex === 4
      ) {
        return
      }

      let updatedBarOverallItemSeries = _.merge({}, this.series[4], {
        label: {
          formatter: (el) => {
            if (
              el.dataIndex === $evt.dataIndex
            ) {
              return ''
            }
            let value = this.toFixed(el.value[this.valueField], this.config.decimalPlaces)
            if (this.valueField === 'perc') {
              value += '%'
            }
            return value
          }
        }
      })

      this.$refs.chart.chart.setOption({
        series: [
          updatedBarOverallItemSeries
        ]
      })
    },
    /*
    * unhighlight value of sentiment
    * by updating series for exact element
    */
    mouseout ($evt) {
      if (
        $evt.componentType !== 'series' ||
        $evt.componentIndex === 3 ||
        $evt.componentIndex === 4
      ) {
        return
      }

      let updatedBarOverallItemSeries = _.merge({}, this.series[4], {
        label: {
          formatter: (el) => {
            let value = this.toFixed(el.value[this.valueField], this.config.decimalPlaces)
            if (this.valueField === 'perc') {
              value += '%'
            }
            return value
          }
        }
      })
      this.$refs.chart.chart.setOption({
        series: [
          updatedBarOverallItemSeries
        ]
      })
    },
    /**
     * Handles showing and properly aligning the color picker in control panel
     * @param  {Number} index index of the selected range
     * @param  {Object} $event
    */
    handleShowRangeColorPicker (index, $event) {
    // set previously picked color's active property to false
      if (this.selectedCustomColor) {
        this.showColorPicker = false
      }

      if (!this.rangeColorPickerConfig) this.rangeColorPickerConfig = {}

      this.selectedCustomColor = index
      this.rangeColorPickerConfig.offsetLeft = $event.target.offsetLeft
      this.rangeColorPickerConfig.offsetTop = $event.target.offsetTop + 5

      this.showColorPicker = true
    },

    /**
     * Check if the user clicked on a category. If yes, toggle its visibility
     * @param  {String} target The label of the ordinal axis value item that was clicked
     */
    maybeToggleEntry (target) {
      // Split the label into the type (CAT or topic ID) and the rest
      let [type, value] = target.split(CODE_SPLIT_TOK)
      if (type === 'CAT') {
        // If it's the category, remove the icon to get the categories real name
        value = value.split('|} ')[1]
        // Toggle the value of the value in the categoriesExpanded array
        if (_.get(this.config.categoriesExpanded, value)) this.config.categoriesExpanded[value] = false
        else this.$set(this.config.categoriesExpanded, value, true)
      }
    },

    /**
     * Creates list of ranges in order to change color and label of sentiment
     * while editing chart
     * @param  {Array} Default values for predefined ranges
     */
    generateDefaultRanges () {
      return [
        ...SENTIMENT_RANGE.reduce((sum, item) => {
          return [
            ...sum,
            {
              id: item,
              color: this.$color.sentiment[item],
              label: this.$t(`topics.sentiment_${item}.label`)
            }
          ]
        }, [])
      ]
    },

    /**
    * Gets sentiment data from category/topic items
    * and returns sum of values
    * @param {Object} Object of semntiment related data
    * @returns {Number}
    */
    calculateTotalSentimentCount (path, dsIdx) {
      return _.chain(path)
        .map(item => item?.counts?.per_dataset?.[dsIdx])
        .sum()
        .value()
    },

    chartClick ($evt) {
      let filter, payload

      if (
        $evt.componentType === 'series' &&
        !$evt.data.topicID
      ) {
        let category = $evt.data.topicCategory
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.category.${encodeStr(category, true)}:${this.sentimentRange[$evt.seriesIndex]}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml(category)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${$evt.seriesName}`
        }
      } else if (
        $evt.componentType === 'series'
      ) {
        // FOCUS TOPIC HERE
        let topicID = $evt.data.topicID
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${topicID}:${this.sentimentRange[$evt.seriesIndex]}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml($evt.data.topicLabelOrig)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${$evt.seriesName}`
        }
      } else if ($evt.componentType === 'title') {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisLabel' &&
        this.isVertical
      ) {
        this.maybeToggleEntry($evt.value)
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisLabel' &&
        this.isHorizontal
      ) {
        this.maybeToggleEntry($evt.value)
      }

      if (
        $evt.componentType !== 'series' ||
        $evt.data.count === 0 ||
        !this.isVerbatimEnabled
      ) {
        return
      }
      payload = {
        item: this.id || 'ch__new',
        filters: [filter]
      }

      this.$store.dispatch('verbatimDialog/show', payload)
    }
  }
}

</script>

<style lang=scss>

@keyframes slide-in-left-opacity {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }

    15% {
      opacity: 0.1;
    }

    30% {
      transform: translateX(0);
      opacity: 0.9;
    }

    100% {
    }
}

@keyframes slide-in-right-opacity {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }

    15% {
      opacity: 0.1;
    }

    30% {
      transform: translateX(0);
      opacity: 0.9;
    }

    100% {
    }
}

.chart-overlay-warning {
  width: 100%;
  max-width: 860px;
  overflow: hidden;
  background: #FFF;

  .warning-content {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    padding: 0 15px;
    > div {
      flex: 1;
      background: #EEE;
      border-radius: 3px;
      padding: 12px 16px;
      margin-bottom: 12px;
      .title { margin-bottom: 5px }
    }

    .v-icon {
      flex: 0 0 auto;
      margin: 0 10px;
      font-size: 36px;
      color: var(--v-accent-darken1);
      animation: 2s cubic-bezier(0, 0, 0.1, 1.4) infinite;

      &.mdi-arrow-right {
        animation-name: slide-in-left-opacity;
      }

      &.mdi-arrow-left {
        animation-name: slide-in-right-opacity;
      }

    }

    .example {
      flex: 100%;
    }

    .how-often {
      background: none;
      text-align: center;
      font-size: 24px;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartBar.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartBar.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />