/**
 * Functions for displaying the ETA of the predictions currently in training
 */
import Vuex from 'vuex'

export default {
  created () {
    // Update the expected training completion text periodically
    this.updateLastTrainedInterval = setInterval(this.updateTrainingInfoText, 1000)
  },

  beforeDestroy () {
    // Clear the interval created in the `created` method
    clearInterval(this.updateLastTrainedInterval)
  },

  data () {
    return {
      updateLastTrainedInterval: null,
      lastTrainedText: '',
      trainingCompletedETAText: '',
      trainingCompletedETAOverdue: false
    }
  },
  computed: {
    ...Vuex.mapState({
      question: state => state.coding.question,
      stats: state => state.coding.stats
    })
  },

  watch: {
    trainingCompletedETAOverdue (val) {
      if (val) throw new Error(`Training is overdue for question ${this.question.id}`)
    }
  },

  methods: {
    /**
     * Update the text with a human-readable version of when predictions will be ready
     */
    updateTrainingInfoText () {
      // Set the human readable time when the last model training was completed
      if (this.question.training_completed) {
        this.lastTrainedText = this.$options.filters.fromnow(this.question.training_completed)
      }

      // When all answers have been reviewed, we don't expect predictions anymore
      if (this.stats.nreviewed === this.question.nanswers) this.$store.commit('setTrainingRequestedTime', null)

      this.trainingCompletedETAText = ''
      // Set the human readable time when we expect the next model training to be completed
      if (this.stats.trainingCompletedETA !== null) {
        let dt = this.stats.trainingCompletedETA.diff(this.$moment(), 'seconds')
        if (dt < 50) this.trainingCompletedETAText = this.$t('stats.model.eta_any_moment')
        else this.trainingCompletedETAText = this.stats.trainingCompletedETA.fromNow()
        this.trainingCompletedETAOverdue = dt < -60
      } else {
        this.trainingCompletedETAText = ''
        this.trainingCompletedETAOverdue = false
      }
    }
  }
}