import Vue from 'vue'
import axios from 'axios'
import { QUESTION_CATEGORY_LIST } from '@/settings/constants'
import { get3ShadesForColor } from '@/utils/colorUtils'
import { store } from '@/store'

export default {
  strict: process.env.NODE_ENV !== 'production',
  state () {
    return {
      // DB data
      question: {
        id: '',
        name: '',
        description: '',
        language: '',
        owner: '',
        owner_id: '',
        created: '',
        last_modified: '',
        training_completed: '',
        training_requested: '',
        ntrainings: 0,
        nanswers: 0,
        is_training: false,
        completed: false,
        inherits_from: null,
        inherits_from_name: null,
        translated: 0,
        model: { score: 0, score_remaining: 0, score_per_code: [], active_learning: false },
        auxiliary_column_names: [],
        group_identical: false,
        group_identical_exclude: '',
        show_sentiment: true,
        show_translation: false,
        smart_sort: false,
        model_certainty_thresh: 50,
        credits_open: 0,
        no_training: false,
        question_category: '',
        project: '',
        project_name: '',
        permissions: {},
        nreviewed: 0,
        nchanged: 0
      },

      loading: true,
      loadingFailed: false,

      questionIsDirty: false,
      isQuestionEligibleForList: true,

      project: {
        questions: []
      },

      codes: [],
      answers: [],
      groupedAnswerIDs: {},

      runAutocoder: true,

      listUnassignedCode: null,

      answerID2Idx: {},
      answerIdx2SortedIdx: {}
    }
  },

  /**
  * Mutations only accept the objects to be modified, no indexes or IDs (except when removing, then the idx may be provided)
  */
  mutations: {
    setRunAutocoder: (state, val) => { state.runAutocoder = val },

    setLoading: (state, val) => { state.loading = val },
    setLoadingFailed: (state, val) => { state.loadingFailed = val },

    setQuestion (state, question) {
      // Treat the question category specially, we don't overwrite was the user might have chosen here already
      let questionCategory = state.question.question_category
      // Set all key in question object that are already defined on this data obj
      _.each(state.question, (val, key) => { Vue.set(state.question, key, question[key]) })
      if (questionCategory !== '') state.question.question_category = questionCategory

      Vue.set(state, 'codes', question.codebook)
      // Check if the question is not clean
      if (state.question.nreviewed || state.codes.length) state.questionIsDirty = true
    },

    setQuestionEligibleForList: (state, eligibility) => { state.isQuestionEligibleForList = eligibility },

    setProject (state, project) {
      // Set all key in project object that are already defined on this data obj
      _.each(state.project, (val, key) => { Vue.set(state.project, key, project[key]) })
    },

    setQuestionCategory (state, category) {
      Vue.set(state.question, 'question_category', category)
    },

    setQuestionInheritsFrom (state, { questionID, name }) {
      Vue.set(state.question, 'inherits_from', questionID)
      Vue.set(state.question, 'inherits_from_name', name)
    },

    setQuestionModelProps (state, props) {
      _.each(props, (v, k) => Vue.set(state.question.model, k, v))
    },

    setAnswers (state, answers) {
      const MUTABLE_PROPS = new Set(['codes', 'coded', 'loading'])

      let makeImmutable = ans => {
        // Get properties
        let propNames = Object.getOwnPropertyNames(ans)

        // Set properties to non-writable which are not explicitely allowed
        for (let name of propNames) {
          if (!MUTABLE_PROPS.has(name)) {
            Object.defineProperty(ans, name, { writable: false, configurable: false })
          }
        }
        Object.seal(ans)
      }

      let answerID2Idx = {}
      let nReviewed = 0
      answers.forEach((ans, idx) => {
        ans.translated = state.question.translated > 0 && ans.translated_text && ans.source_language !== state.question.language
        ans.coded = false
        ans.loading = false
        nReviewed += Number(ans.reviewed)

        answerID2Idx[ans.id] = idx
        if ('identical_ids' in ans) ans.identical_ids.forEach(identID => { state.groupedAnswerIDs[identID] = ans })

        makeImmutable(ans)
      })

      Vue.set(state, 'answers', answers)
      Vue.set(state, 'answerID2Idx', answerID2Idx)
      if (nReviewed) state.questionIsDirty = true
    },

    /** =========== CODE Modifiers =========== **/
    setCodes: (state, codes) => {
      Vue.set(state, 'codes', codes)
    },

    addCode: (state, { code, position }) => state.codes.splice(position > -1 ? position : state.codes.length, 0, code),
    modifyCode: (state, { code, newAttributes }) => {
      _.each(newAttributes, (val, key) => {
        Vue.set(code, key, newAttributes[key])
      })
    },
    deleteCode: (state, { codeIdx }) => state.codes.splice(codeIdx, 1),

    setListUnassignedCode: (state, val) => { state.listUnassignedCode = val },

    /** =========== ANSWER Modifiers =========== **/

    clearAllCodesFromAnswers: (state) => {
      state.answers.forEach(a => Vue.set(a, 'codes', []))
    },

    addCodeToAnswer: (state, { answer, codeID }) => { answer.codes.push(codeID) },
    removeCodeFromAnswer: (state, { answer, codeIdx }) => { answer.codes.splice(codeIdx, 1) },
    replaceCodeInAnswer: (state, { answer, codeIdx, newCode }) => { answer.codes.splice(codeIdx, 1, newCode) },
    setAnswerCodes: (state, { answer, codes }) => { Vue.set(answer, 'codes', codes) },
    setAnswerProps: (state, { answer, props }) => {
      _.each(props, (v, k) => {
        Vue.set(answer, k, v)
      })
    }
  },

  getters: {
    answerByID: (state, getters) => id => {
      if (!(id in state.answerID2Idx)) throw new Error(`Invalid answer ID ${id} provided`)
      else return state.answers[state.answerID2Idx[id]]
    },

    codesByCat: (state, getters) => { return _.groupBy(state.codes, 'category') },

    // Make sure the codes are sorted by category, after first appearance
    codesSorted: (state, getters) => _.flatMap(getters.codesByCat),

    codeCats: (state, getters) => { return _.keys(getters.codesByCat) },

    codeCats2CatIdx: (state, getters) => {
      let mapping = {}
      getters.codeCats.forEach((cat, idx) => { mapping[cat] = idx })
      return mapping
    },

    codeID2Code: (state, getters) => {
      let mapping = {}
      state.codes.forEach((c, idx) => { mapping[c.id] = c })
      return mapping
    },

    codeID2Idx: (state, getters) => {
      let mapping = {}
      state.codes.forEach((c, idx) => { mapping[c.id] = idx })
      return mapping
    },

    codeID2IdxSorted: (state, getters) => {
      // mapping of code ids to code indexes (in codes)
      let idToIdx = {}
      getters.codesSorted.forEach((code, codeIdx) => { idToIdx[code.id] = codeIdx })
      return idToIdx
    },

    codeID2CatIdx: (state, getters) => {
      let mapping = {}
      state.codes.forEach(c => { mapping[c.id] = getters.codeCats2CatIdx[c.category] })
      return mapping
    },

    codeID2Cat: (state, getters) => {
      let mapping = {}
      state.codes.forEach(c => { mapping[c.id] = c.category })
      return mapping
    },

    codeCat2CatIdx: (state, getters) => {
      let mapping = {}
      getters.codeCats.forEach((c, idx) => { mapping[c] = idx })
      return mapping
    },

    catColors: (state, getters) => {
      let mapping = {}
      getters.codeCats.forEach(cat => {
        mapping[cat] = get3ShadesForColor(state.codesByCat[cat][0].color)
      })
      return mapping
    },

    codeByID: (state, getters) => id => {
      if (!(id in getters.codeID2Code)) throw new Error(`Invalid code ID ${id} provided`)
      else return getters.codeID2Code[id]
    },

    isListQuestion: (state) => state.question.question_category === QUESTION_CATEGORY_LIST
  },

  actions: {
    loadProject ({ commit, state, getters, dispatch }, projectID) {
      return api.get(`/api/projects/${projectID}`).then((res) => {
        if (!store.state.wizard) return
        commit('setProject', res.data)
      }).catch((err) => {
        commit('setLoadingFailed', true)
        this._vm.$maybeRaiseAPIPromiseErr(err)
        // Re-raise the error, such that axios.all doesn't think it's a success
        throw err
      })
    },

    loadData ({ commit, state, getters, dispatch }, questionID) {
      return new Promise((resolve, reject) => {
        let question, answers

        api.get(`/api/questions/${questionID}`).then((res) => {
          if (!store.state.wizard) return
          question = res.data

          let axiosProject = dispatch('loadProject', res.data.project)

          axios.all([axiosProject, axiosIsEligibleForListAutocoder]).then(v => {
            // If we have left the wizard in the mean time
            if (!store.state.wizard) return
            if (question.smart_sort) answers = _.sortBy(answers, 'idx_sorted')

            commit('setQuestion', question)
            commit('setAnswers', [])
            commit('setLoading', false)

            resolve()
          }).catch((err) => {
            commit('setLoading', false)
            reject(new Error('Failed to load project'))
            this._vm.$maybeRaiseAPIPromiseErr(err)
          })
        }).catch((err) => {
          commit('setLoadingFailed', true)
          reject(new Error('Failed to load question'))
          this._vm.$maybeRaiseAPIPromiseErr(err)
          // Re-raise the error, such that axios.all doesn't think it's a success
          throw err
        })

        let axiosIsEligibleForListAutocoder = api.get(`/api/questions/${questionID}/is-eligible-for-autocode-list`).then(res => {
          commit('setQuestionEligibleForList', res.data.eligible)
        })
      })
    },

    /** =========== SURVEY actions =========== **/
    saveQuestionModelProps ({ commit, state, getters, dispatch }, props) {
      let model = _.clone(state.question.model)
      _.each(props, (v, k) => { model[k] = v })
      dispatch('saveQuestionProps', { model })
    },

    /** =========== ANSWER Actions =========== **/
    setAnswerCodes ({ commit, state, getters, dispatch }, answerList) {
      answerList.forEach(({ id, codes }) => { // eslint-disable-line camelcase
        let answer = getters.answerByID(id)
        commit('setAnswerProps', { answer, props: { codes } })
      })
    },

    setAnswerProps ({ commit, state, getters, dispatch }, answerList) {
      answerList.forEach(({ id, ...props }) => {
        // Ignore answers that are grouped, nothing to change here
        if (id in state.groupedAnswerIDs) return
        let answer = getters.answerByID(id)
        commit('setAnswerProps', { answer, props })
      })
    },

    /** =========== CODE Actions =========== **/
    /**
     * Add a new code to the codebook
     * @param  {Object} code             Object with all properties a code needs to have
     */
    addCode: ({ commit, state, getters, dispatch }, { code, position }) => {
      commit('addCode', { code, position })
    },

    modifyCode: ({ commit, state, getters, dispatch }, { codeID, newAttributes, position }) => {
      if (position !== undefined) {
        let code = getters.codeByID(codeID)
        commit('deleteCode', { codeIdx: getters.codeID2Idx[codeID] })
        commit('addCode', { code, position })
      }

      // If the id was modified, we need to that that for all answers
      if ('id' in newAttributes) {
        let newCode = newAttributes.id
        state.answers.forEach(answer => {
          let codeInAnswerIdx = answer.codes.indexOf(codeID)
          if (codeInAnswerIdx !== -1) commit('replaceCodeInAnswer', { codeIdx: codeInAnswerIdx, answer, newCode })
        })
      }

      commit('modifyCode', { code: getters.codeByID(codeID), newAttributes })
    },

    /**
     * Remove a code from the codebook
     * @param  {[type]} the codeID to remove
     */
    deleteCode: ({ commit, state, getters, dispatch }, codeID) => {
      // delete the code from all the answers
      // state.answers.forEach((a, ansIdx) => {
      //   let codeInAnswerIdx = a.codes.indexOf(codeID)
      //   if (codeInAnswerIdx !== -1) {
      //     commit('removeCodeFromAnswer', { codeIdx: codeInAnswerIdx, answer: a })
      //   }
      // })

      // delete the code from the code book (by codeIdx)
      let codeIdx = getters.codeID2Idx[codeID]
      commit('deleteCode', { codeIdx })
    },

    mergeCodes: ({ commit, state, getters, dispatch }, { parentID, childIDs }) => {
      // Move all keywords to the parent code, if they not already exist
      let parentCodeIdx = getters.codeID2Idx[parentID]
      let parentCode = state.codes[parentCodeIdx]

      let parentKeywords = _.cloneDeep(parentCode.keywords)

      childIDs.forEach(child => {
        let childIdx = getters.codeID2Idx[child]
        state.codes[childIdx].keywords.forEach(kw => {
          if (parentKeywords.indexOf(kw) === -1) parentKeywords.push(kw)
        })

        // Remove the merged code
        commit('deleteCode', { codeIdx: childIdx })
      })

      commit('modifyCode', { code: parentCode, newAttributes: { keywords: parentKeywords } })
    }
  }
}
