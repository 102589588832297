import { decodeFilterQuery, createQueryObjFromFilters, createArrayFromConstraints } from '@/utils/filters'
import {
  PROJECT_LIST_FILTERS_DEFAULT as FILTERS_DEFAULT
} from '@/settings/constants'

window.popStateDetected = false

if (!window.onpopstate) {
  window.addEventListener('popstate', () => {
    window.popStateDetected = true
  })
}

export default {
  data () {
    return {
      projectsLoading: true,
      projectsFailed: false,
      projectsConfig: {
        tabs: [],
        filters: _.cloneDeep(FILTERS_DEFAULT)
      },
      internalProjects: []
    }
  },

  computed: {
    /**
     * The computed url query, given the current pagination and filters
     * @return {Object}
     */
    queryObj () {
      return createQueryObjFromFilters(this.filters, true, this.inheritable)
    },

    /**
     * queryObj converted to string and encoded for URI
     */
    queryParamsString () {
      let string = '?'

      _.mapKeys(this.queryObj, (value, key) => {
        string = string + `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`
      })

      return string
    }
  },

  methods: {
    /**
     * The initial state of pagination and filters, retrieved from local storage and query
     * The rowsPerPage is the only property that is *always* saved to local state
     * The other params, such as sorting, page and filters are retrieved from the query params
     * @return {Object} Object which is passed to projectList
     */
    setInitialState (query) {
      let filters = _.cloneDeep(FILTERS_DEFAULT)

      // Tab filter
      if (query.tab) {
        filters.tab = _.find(this.projectsConfig.tabs, {
          value: decodeFilterQuery(query.tab)[0].value
        })
      }

      // Search filter
      if (query.search) {
        filters.search = decodeFilterQuery(query.search)[0].value
      }

      // Archived filter
      if (query.archived) {
        filters.archived = Boolean(query.archived)
      }

      // Page filter
      if (query.page) {
        filters.pagination.page = _.toInteger(query.page)
      }

      // Limit filter
      if (query.limit) {
        filters.pagination.rowsPerPage = _.toInteger(query.limit)
      } else if (!query.limit && !window.popStateDetected) {
        const sessionRowsPerPage = _.toInteger(this.getLsGlobalSettings().rowsPerPage || 10)
        filters.pagination.rowsPerPage = (sessionRowsPerPage && sessionRowsPerPage !== -1) ? sessionRowsPerPage : 10
      }

      // Tag filter
      if (query.labels) {
        filters.labels = createArrayFromConstraints(decodeFilterQuery(query.labels, 'and'))
      }

      // Owner filter
      if (query.owner) {
        filters.owner = createArrayFromConstraints(decodeFilterQuery(query.owner))
      }

      // Language filter
      if (query.language) {
        filters.language = createArrayFromConstraints(decodeFilterQuery(query.language))
      }

      // Source filter
      if (query.data_source_provider) {
        filters.data_source_provider = createArrayFromConstraints(decodeFilterQuery(query.data_source_provider))
      }

      // Created date filter
      if (query.created) {
        filters.created[0] = this.$options.filters.shortiso(decodeFilterQuery(query.created, 'and')[0].value)
        filters.created[1] = this.$options.filters.shortiso(decodeFilterQuery(query.created, 'and')[1].value)
      }

      // Modified date filter
      if (query.last_modified) {
        filters.last_modified[0] = this.$options.filters.shortiso(decodeFilterQuery(query.last_modified, 'and')[0].value)
        filters.last_modified[1] = this.$options.filters.shortiso(decodeFilterQuery(query.last_modified, 'and')[1].value)
      }

      if (query.order_by) {
        const [order, column] = query.order_by.split(':')

        filters.order_by = {
          order,
          column
        }
      }

      this.$set(this, 'initialState', { filters })
    },

    /**
     * Fetches all data for page
     */
    loadAllData (callback) {
      this.projectsFailed = false

      return this.loadConfig()
        .then(() => this.loadProjects(callback))
        .then(() => {
          this.projectsLoading = false
        })
        .catch(err => {
          this.projectsLoading = false
          this.projectsFailed = true
          this.$root.snackMsg(this.$t('error', { 'step': this.$t('loading.while') }))
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    /**
     * Fetch projects with current config
     * @return {Promise}
     */
    loadProjects (callback) {
      this.projectsLoading = true
      this.projectsFailed = false

      let externalPath = this.$route.fullPath.slice(this.$route.path.length)

      if (this.$route.params.id) {
        if (externalPath.length) {
          externalPath += `&include=${this.$route.params.id}`
        } else {
          externalPath += `?include=${this.$route.params.id}`
        }
      }

      let queryParamsString = this.filtersInternal ? this.queryParamsString : externalPath

      return api.get(`/api/ui/projects${queryParamsString[queryParamsString.length - 1] === '&' ? queryParamsString.slice(0, -1) : queryParamsString}`)
        .then((res) => {
          const { count, has_next: hasNext, has_prev: hasPrev, results, page } = res.data

          if (callback && typeof callback === 'function') {
            callback(page)
          }

          this.$set(this, this.filtersInternal ? 'internalProjects' : 'projects', results)
          this.$set(this, 'projectsConfig', { ...this.projectsConfig, count, hasNext, hasPrev })
          this.computeProjectProperties(this.filtersInternal ? 'internalProjects' : 'projects')
          return res.data
        })
        .catch(err => {
          this.projectsLoading = false
          this.projectsFailed = true
          this.$root.snackMsg(this.$t('error', { 'step': this.$t('loading.while') }))
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    /**
     * Load the project list filters and tabs
     * @return {Promise} config
     */
    loadConfig () {
      return api.get('/api/ui/projects/config')
        .then((res) => {
          const { filters, tabs } = res.data
          this.$set(this, 'projectsConfig', { filters, tabs })

          // Only do this on projects page
          if (!this.filtersInternal) {
            this.setInitialState(this.$router.currentRoute.query)
          }
        })
        .catch(err => {
          this.projectsLoading = false
          this.projectsFailed = true
          this.$root.snackMsg(this.$t('error', { 'step': this.$t('loading.while') }))
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    /**
     * Sets additional properties on each object inside the projects list, based on other object properties
     * @return {[type]} [description]
     */
    computeProjectProperties (projectsKey) {
      // let questionIDToQestion = {}

      // // Set up mapping of question id to question
      // _.get(this, projectsKey).forEach(p => p.questions.forEach(q => {
      //   questionIDToQestion[q.id] = q
      //   q.inherits_to_objs = []
      // }))

      // _.get(this, projectsKey).forEach(p => {
      //   // Prepare the inheritance properties
      //   p.inherits_from = p.inherits_to_and_from = p.inherits_final_leaf = false

      //   // Compute the status per question and for the entire project
      //   let allCompleted = true
      //   let allNew = true
      //   p.questions.forEach(q => {
      //     // Status isn't an API property, to make it reactive we have to use set here
      //     this.$set(q, 'status', (q.completed ? 2 : q.ncodes > 0 ? 1 : 0))
      //     allCompleted = allCompleted && q.status === 2
      //     allNew = allNew && q.status === 0

      //     // Map back to the project, should we ever need that
      //     q.project_obj = p
      //     q.inherits_from_obj = null
      //     q.inherits_final_leaf = false

      //     // Build up the inherits_to chain
      //     if (q.inherits_from !== null) {
      //       q.inherits_from_obj = questionIDToQestion[q.inherits_from]
      //       // If the question inherits from a question that doesn't belong to the user
      //       // Create a custom inheritance object
      //       if (q.inherits_from_obj === undefined) {
      //         q.inherits_from_obj = {
      //           name: 'Unknown',
      //           id: q.inherits_from,
      //           inherits_from: null,
      //           inherits_to_objs: [],
      //           project_obj: { name: 'From other user' }
      //         }
      //       }
      //       q.inherits_from_obj.inherits_to_objs.push(q)
      //     }
      //   })

      //   // All the following properties are not given by the API
      //   // use $set to make them reactive
      //   this.$set(p, 'status', (allCompleted ? 2 : (allNew ? 0 : 1)))
      //   // Do some counting on the question objects
      //   this.$set(p, 'nanswers', p.questions.length ? p.questions[0].nanswers : 0)
      //   this.$set(p, 'nquestions', p.questions.length)
      //   this.$set(p, 'nreviewed', _.sumBy(p.questions, 'nreviewed'))
      // })

      // // Finally, loop through all questions again and set the inheritance properties on project level
      // _.values(questionIDToQestion).forEach(q => {
      //   // If the question inherits to _and from
      //   if (q.inherits_to_objs.length && q.inherits_from_obj) q.project_obj.inherits_to_and_from = true
      //   // If the question only inherits to anywhere
      //   else if (q.inherits_to_objs.length) q.project_obj.inherits_to = true
      //   // If the question contains final leaves (no outgoing nodes)
      //   else if (q.inherits_from_obj) {
      //     q.inherits_final_leaf = true
      //     q.project_obj.inherits_final_leaf = true
      //   }
      // })
    }
  }
}
