<template>
  <v-tooltip
    top
    :disabled="!tooltip"
  >
    <span>{{ $t(normalizedSentimentStringForTranslation) }}</span>
    <template v-slot:activator="{ on }">
      <div
        class="coding__row__topic__sentiment"
        v-on="on"
      >
        <div
          v-if="sentiment === 'negative' || sentiment === 'sentiment_negative'"
          class="d-flex align-center"
          style="position: relative; top: 3px;"
        >
          <!-- CIRCLE  -->
          <!-- <svg width="20" height="11" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 10.5C3 8.64348 3.7375 6.86301 5.05025 5.55025C6.36301 4.2375 8.14348 3.5 10 3.5C11.8565 3.5 13.637 4.2375 14.9497 5.55025C16.2625 6.86301 17 8.64348 17 10.5" stroke="#FF5151" stroke-width="6" />
      </svg> -->

          <!-- ROUNDED HALF-CIRCLE  -->
          <svg width="20" height="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round">
            <path d="M3,8 C6,2 14,2 17,8" style="fill:none;stroke:#FF5151;stroke-width:6px;" />
          </svg>

          <!-- THUMBS VERSION 1 -->
          <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0001 1.33301H4.00008C3.44675 1.33301 2.97341 1.66634 2.77341 2.14634L0.760081 6.84634C0.700081 6.99967 0.666748 7.15967 0.666748 7.33301V8.66634C0.666748 9.39967 1.26675 9.99967 2.00008 9.99967H6.20675L5.57341 13.0463L5.55341 13.2597C5.55341 13.533 5.66675 13.7863 5.84675 13.9663L6.55341 14.6663L10.9467 10.273C11.1867 10.033 11.3334 9.69967 11.3334 9.33301V2.66634C11.3334 1.93301 10.7334 1.33301 10.0001 1.33301ZM10.0001 9.33301L7.10675 12.2263L8.00008 8.66634H2.00008V7.33301L4.00008 2.66634H10.0001V9.33301ZM12.6667 1.33301H15.3334V9.33301H12.6667V1.33301Z" fill="#FF5151" />
      </svg> -->

          <!-- EMOJI  -->
          <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="white" />
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#FF5151" fill-opacity="0.4" />
        <path d="M11.3266 12.1689C11.3066 12.0893 10.8075 10.2227 7.99992 10.2227C5.19192 10.2227 4.69325 12.0893 4.67325 12.1689C4.6488 12.2653 4.69236 12.3653 4.77858 12.4151C4.86525 12.4644 4.97369 12.4498 5.04525 12.3809C5.05369 12.3724 5.91369 11.556 7.99992 11.556C10.0861 11.556 10.9466 12.3724 10.9546 12.3804C10.9972 12.4227 11.0541 12.4449 11.111 12.4449C11.1484 12.4449 11.1861 12.4355 11.2204 12.4164C11.3075 12.3667 11.351 12.2658 11.3266 12.1689Z" fill="#CC0000" />
        <path d="M5.33328 7.55545C5.94693 7.55545 6.44439 6.859 6.44439 5.99989C6.44439 5.14078 5.94693 4.44434 5.33328 4.44434C4.71963 4.44434 4.22217 5.14078 4.22217 5.99989C4.22217 6.859 4.71963 7.55545 5.33328 7.55545Z" fill="#CC0000" />
        <path d="M10.6668 7.55545C11.2804 7.55545 11.7779 6.859 11.7779 5.99989C11.7779 5.14078 11.2804 4.44434 10.6668 4.44434C10.0531 4.44434 9.55566 5.14078 9.55566 5.99989C9.55566 6.859 10.0531 7.55545 10.6668 7.55545Z" fill="#CC0000" />
      </svg> -->
        </div>

        <div
          v-else-if="sentiment === 'positive' || sentiment === 'sentiment_positive'"
          class="d-flex align-center"
          style="position: relative; top: 3px;"
        >
          <!-- CIRCLE  -->
          <!-- <svg width="21" height="11" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg" style="overflow: visible;">
        <path d="M17.5 0.500002C17.5 1.41926 17.3189 2.32951 16.9672 3.17879C16.6154 4.02807 16.0998 4.79974 15.4497 5.44975C14.7997 6.09976 14.0281 6.61538 13.1788 6.96716C12.3295 7.31894 11.4193 7.5 10.5 7.5C9.58075 7.5 8.67049 7.31894 7.82121 6.96716C6.97194 6.61537 6.20026 6.09976 5.55025 5.44975C4.90024 4.79974 4.38463 4.02806 4.03284 3.17878C3.68106 2.32951 3.5 1.41925 3.5 0.5" stroke="#00C58D" stroke-width="6" />
      </svg> -->

          <!-- ROUNDED HALF-CIRCLE  -->
          <svg width="20" height="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round">
            <path d="M3,5 C6,11 14,11 17,5" style="fill:none;stroke:#00C58D;stroke-width:6px;" />
          </svg>

          <!-- THUMBS VERSION 1 -->
          <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.00008 14.6663H12.0001C12.5534 14.6663 13.0267 14.333 13.2267 13.853L15.2401 9.15301C15.3001 8.99967 15.3334 8.83967 15.3334 8.66634V7.33301C15.3334 6.59967 14.7334 5.99967 14.0001 5.99967H9.79341L10.4267 2.95301L10.4467 2.73967C10.4467 2.46634 10.3334 2.21301 10.1534 2.03301L9.44675 1.33301L5.05341 5.72634C4.81341 5.96634 4.66675 6.29967 4.66675 6.66634V13.333C4.66675 14.0663 5.26675 14.6663 6.00008 14.6663ZM6.00008 6.66634L8.89341 3.77301L8.00008 7.33301H14.0001V8.66634L12.0001 13.333H6.00008V6.66634ZM0.666748 6.66634H3.33341V14.6663H0.666748V6.66634Z" fill="#00C58D" />
      </svg> -->

          <!-- EMOJI -->
          <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="white" />
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#00C58D" fill-opacity="0.4" />
        <path d="M4.67324 10.4987C4.69324 10.5782 5.19235 12.4449 7.99991 12.4449C10.8079 12.4449 11.3066 10.5782 11.3266 10.4987C11.351 10.4022 11.3075 10.3022 11.2212 10.2524C11.1346 10.2031 11.0261 10.2178 10.9546 10.2867C10.9461 10.2951 10.0861 11.1115 7.99991 11.1115C5.91369 11.1115 5.05324 10.2951 5.04524 10.2871C5.00258 10.2449 4.94569 10.2227 4.8888 10.2227C4.85147 10.2227 4.81369 10.232 4.77947 10.2511C4.69235 10.3009 4.6488 10.4018 4.67324 10.4987Z" fill="#006649" />
        <path d="M5.33328 7.55545C5.94693 7.55545 6.44439 6.859 6.44439 5.99989C6.44439 5.14078 5.94693 4.44434 5.33328 4.44434C4.71963 4.44434 4.22217 5.14078 4.22217 5.99989C4.22217 6.859 4.71963 7.55545 5.33328 7.55545Z" fill="#006649" />
        <path d="M10.6668 7.55545C11.2804 7.55545 11.7779 6.859 11.7779 5.99989C11.7779 5.14078 11.2804 4.44434 10.6668 4.44434C10.0531 4.44434 9.55566 5.14078 9.55566 5.99989C9.55566 6.859 10.0531 7.55545 10.6668 7.55545Z" fill="#006649" />
      </svg> -->
        </div>

        <div class="d-flex align-center" v-else-if="sentiment === 'neutral' || sentiment === 'sentiment_neutral'">
          <!-- CIRCLE -->
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <!-- <circle cx="10" cy="10.5" r="7" transform="rotate(-90 10 10.5)" stroke="#DAEAF2" stroke-width="6" /> -->
            <rect x="0" y="5" width="20" height="10" rx="5" fill="#DAEAF2" />
          </svg>

          <!-- THUMBS VERSION 1 -->
          <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2219_14732)">
          <path d="M1.33341 9.99967L1.33341 3.99967C1.33341 3.44634 1.66675 2.97301 2.14675 2.77301L6.84675 0.759675C7.00008 0.699674 7.16008 0.666341 7.33342 0.666341L8.66675 0.666342C9.40008 0.666342 10.0001 1.26634 10.0001 1.99968L10.0001 6.20634L13.0467 5.57301L13.2601 5.55301C13.5334 5.55301 13.7867 5.66634 13.9667 5.84634L14.6667 6.55301L10.2734 10.9463C10.0334 11.1863 9.70008 11.333 9.33341 11.333L2.66675 11.333C1.93341 11.333 1.33341 10.733 1.33341 9.99967ZM9.33341 9.99967L12.2267 7.10634L8.66675 7.99967L8.66675 1.99968L7.33342 1.99968L2.66675 3.99967L2.66675 9.99967L9.33341 9.99967ZM9.33341 15.333L9.33341 12.6663L1.33341 12.6663L1.33341 15.333L9.33341 15.333Z" fill="#0E5D88" />
        </g>
        <defs>
          <clipPath id="clip0_2219_14732">
            <rect width="16" height="16" fill="white" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 16 16)" />
          </clipPath>
        </defs>
      </svg> -->

          <!-- EMOJI -->
          <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8C16 12.4182 12.4182 16 8 16C3.58222 16 0 12.4182 0 8C0 3.58222 3.58222 0 8 0C12.4182 0 16 3.58222 16 8Z" fill="white" />
        <path d="M16 8C16 12.4182 12.4182 16 8 16C3.58222 16 0 12.4182 0 8C0 3.58222 3.58222 0 8 0C12.4182 0 16 3.58222 16 8Z" fill="#269AD9" fill-opacity="0.4" />
        <path d="M5.11111 8.88846C5.72476 8.88846 6.22222 8.19201 6.22222 7.3329C6.22222 6.47379 5.72476 5.77734 5.11111 5.77734C4.49746 5.77734 4 6.47379 4 7.3329C4 8.19201 4.49746 8.88846 5.11111 8.88846Z" fill="#0A3F5C" />
        <path d="M10.8889 8.88846C11.5026 8.88846 12.0001 8.19201 12.0001 7.3329C12.0001 6.47379 11.5026 5.77734 10.8889 5.77734C10.2753 5.77734 9.77783 6.47379 9.77783 7.3329C9.77783 8.19201 10.2753 8.88846 10.8889 8.88846Z" fill="#0A3F5C" />
        <path d="M11.111 11.5559H4.88878C4.64345 11.5559 4.44434 11.3572 4.44434 11.1114C4.44434 10.8657 4.64345 10.667 4.88878 10.667H11.111C11.3568 10.667 11.5554 10.8657 11.5554 11.1114C11.5554 11.3572 11.3568 11.5559 11.111 11.5559Z" fill="#0A3F5C" />
      </svg> -->
        </div>
      </div>
    </template>
  </v-tooltip>
</template>

<script>
export default {
  codit: true,
  name: 'Sentiment',

  props: {
    sentiment: {
      type: String,
      default: 'neutral'
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {

    }
  },

  computed: {
    normalizedSentimentStringForTranslation () {
      if (this.sentiment === 'negative' || this.sentiment === 'sentiment_negative') return 'negative'
      if (this.sentiment === 'positive' || this.sentiment === 'sentiment_positive') return 'positive'
      else return 'neutral'
    }
  },

  watch: {
  },

  methods: {
  }
}

</script>

<i18n>
{
  "en": {
    "neutral": "Neutral",
    "positive": "Positive",
    "negative": "Negative"
  },
  "de": {
    "neutral": "Neutral",
    "positive": "Positiv",
    "negative": "Negativ"
  }
}
</i18n>
