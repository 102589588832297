import Vue from 'vue'
import axios from 'axios'
import {
  TEXT_TO_ANALYZE_CATEGORY_LIST,
  MOVED_TOPIC
} from '@/settings/constants'

import topicsMixin from '@/store/mixins/topics'
import registerManager from '@/store/managers/register'
import metaManager from '@/store/managers/meta'
import configManager from '@/store/managers/config'
import verbatimManager from '@/store/managers/verbatim'
import questionManager from '@/store/managers/question'
import verbatimDialog from '@/store/mixins/verbatimDialog'

const DEFAULT_SUGGESTIONS = [
  // {
  //   id: REFINE_CATEGORIES,
  //   action: REFINE_CATEGORIES,
  //   done: false
  // }
]

export default {
  strict: process.env.NODE_ENV !== 'production',
  state () {
    return {
      id: 'wizard',

      settings: {
        type: null,
        generate: null,
        import: null,
        sentiment: true
      },

      questionID: '', // legacy
      projectID: '',
      columnRef: '',
      project: {},
      codingColumn: {},
      model: {},
      inheritsFrom: {},

      loading: true,
      loadingFailed: false,

      generatorLoading: false,
      generatorFailed: false,
      generatorError: false,
      generatorRerun: false,

      isNotFresh: false,
      isColumnEligibleForList: true,

      listUnassignedCode: null,

      mergeFailed: false,

      step: 0,
      refinementStep: 1,
      activeTab: 0,
      suggestions: DEFAULT_SUGGESTIONS,
      activeSuggestion: false,
      clickedSuggestion: false,
      generateMoreShown: false,
      rows: [],
      animatedTopics: [],
      emptyCategories: [],
      generatedCatOptions: [],
      coverage: 0,
      isInitialized: false,
      wizardUpdateNotification: false,

      success: false,

      ...topicsMixin.state()
    }
  },

  /**
  * Mutations only accept the objects to be modified, no indexes or IDs (except when removing, then the idx may be provided)
  */
  mutations: {
    incrementStep: (state) => { state.step = state.step + 1 },
    decrementStep: (state) => { state.step = state.step - 1 },
    incrementRefinementStep: (state) => { state.refinementStep = state.refinementStep + 1 },

    setGenerationMoreShown: (state, value) => { state.generateMoreShown = value },

    setGeneratedCatOptions (state, cats) {
      state.generatedCatOptions = cats
    },

    setProjectSetting: (state, { setting, value }) => {
      Vue.set(state.settings, setting, value)
    },

    setActiveTab: (state, value) => {
      state.activeTab = value
    },

    setWizardUpdateNotification: (state, value) => {
      state.wizardUpdateNotification = value
    },

    setIsInitialized: (state, value) => {
      state.isInitialized = value
    },

    resetWizard: (state) => {
      state.step = 0
      state.refinementStep = 1
      state.topics = []
      state.suggestions = DEFAULT_SUGGESTIONS
      state.emptyCategories = []
      state.animatedTopics = []
      state.coverage = 0
      state.inheritsFrom = {}
    },

    setSuccess: (state, value) => {
      state.success = value
    },

    setCoverage: (state, value) => {
      state.coverage = value
    },

    addSuggestion: (state, suggestion) => {
      if (suggestion.action !== MOVED_TOPIC) {
        // For non-MOVED_TOPIC actions or when there are no suggestions, simply add the suggestion
        state.suggestions = [...state.suggestions, { ...suggestion, done: false }]

        return
      }

      // Function to check if a suggestion contains shared topics
      const hasSharedTopics = (existingSuggestion, newSuggestion) => {
        const existingLabels = existingSuggestion.topics.map(topic => topic.label)
        const newLabels = newSuggestion.topics.map(topic => topic.label)
        return existingLabels.some(label => newLabels.includes(label))
      }

      // Update existing suggestions with shared topics and add the new suggestion
      const updatedSuggestions = state.suggestions.map(existingSuggestion => {
        if (hasSharedTopics(existingSuggestion, suggestion)) {
          return { ...existingSuggestion, done: true }
        }
        return existingSuggestion
      })

      // Add the new suggestion without checking for duplicates, assuming IDs are unique
      state.suggestions = [...updatedSuggestions, { ...suggestion, done: false }]
    },

    setAnimatedTopics: (state, topics) => {
      state.animatedTopics = topics
    },

    setEmptyCategories: (state, categories) => {
      state.emptyCategories = categories
    },

    setGeneratorLoading: (state, value) => {
      state.generatorLoading = value
    },

    setGeneratorFailed: (state, value) => {
      state.generatorFailed = value
    },

    setGeneratorError: (state, value) => {
      state.generatorError = value
    },

    setGeneratorRerun: (state, value) => {
      state.generatorRerun = value
    },

    setActiveSuggestion: (state, suggestion) => {
      state.activeSuggestion = suggestion
    },

    setClickedSuggestion: (state, suggestion) => {
      state.clickedSuggestion = suggestion
    },

    setRows: (state, rows) => { state.rows = rows },

    setLoading: (state, val) => { state.loading = val },
    setLoadingFailed: (state, val) => { state.loadingFailed = val },

    /**
     * Set the project & column identifiers for this page
     * @param {String} options.projectID The project ID
     * @param {String} options.columnRef The column reference
     */
    setIdentifiers (state, { projectID, columnRef }) {
      state.projectID = projectID
      state.columnRef = columnRef
    },

    /**
     * Set the legacy question id
     * @param {Object} state
     * @param {String} options.questionID
     */
    setQuestionID (state, { questionID }) {
      state.questionID = questionID
    },

    setSuggestionStatus (state, { value, suggestion }) {
      const suggestionIdx = _.findIndex(state.suggestions, ({ id }) => id === suggestion.id)

      Vue.set(state.suggestions[suggestionIdx], 'done', value)
    },

    setProject (state, { project, columnRef }) {
      Vue.set(state, 'project', project)
      Vue.set(state, 'codingColumn', _.find(project.columns, { ref: columnRef }))

      if (state.codingColumn.metadata.reviewed_count || state.codingColumn.topics.length) state.isNotFresh = true
    },

    setColumnEligibleForList: (state, eligibility) => { state.isColumnEligibleForList = eligibility },

    setColumnCategory (state, category) {
      state.codingColumn.metadata.category = category
    },

    setInheritsFrom (state, { projectID, columnRef, name }) {
      Vue.set(state, 'inheritsFrom', { projectID, columnRef, name })
    },

    setModelProps (state, props) {
      _.each(props, (v, k) => Vue.set(state.model, k, v))
    },

    setListUnassignedCode: (state, val) => { state.listUnassignedCode = val },

    setMergeFailed (state, value) {
      state.mergeFailed = value
    },

    ...topicsMixin.mutations
  },

  getters: {
    isListColumn: (state) => state.codingColumn.metadata?.category === TEXT_TO_ANALYZE_CATEGORY_LIST,

    ...topicsMixin.getters
  },

  actions: {
    /**
     * Perform the initial data loading
     * @param  {str} options.projectID       The ID of the project to load
     * @param  {str} options.columnRef       The reference of the column we're coding
     */
    loadData ({ commit, state, getters, dispatch }, { projectID, columnRef }) {
      return new Promise((resolve, reject) => {
        commit('setIdentifiers', { projectID, columnRef })

        let project
        // Fetch the project, including the initial version of the codebook
        let projectRequest = api.get(`/api/ui/projects/${projectID}?expand=${columnRef}`).then((res) => {
          project = res.data
        }).catch((err) => {
          dispatch('maybeRaiseAPIPromiseErr', err)
          commit('setLoadingFailed', true)
          commit('setLoading', false)
          // Re-raise the error, such that axios.all doesn't think it's a success
          throw err
        })

        axios.all([projectRequest]).then(v => {
          if (state.destroyed) return
          commit('setProject', { project, columnRef })
          // TODO: Check why this was happening
          commit('setTopics', state.codingColumn.topics)
          api.get(`/api/ui/projects/${projectID}/to-refs`).then(res => {
            if (state.destroyed) return
            _.each(res.data.questions_to_ref, (val, key) => {
              if (val === columnRef) {
                commit('setQuestionID', { questionID: key })
                api.get(`/api/questions/${state.questionID}/is-eligible-for-autocode-list`).then(res => {
                  if (state.destroyed) return
                  commit('setColumnEligibleForList', res.data.eligible)
                  commit('setLoading', false)
                })
              }
            })
          })
          resolve()
        }).catch((err) => {
          dispatch('maybeRaiseAPIPromiseErr', err)
          reject(err)
        })
      })
    },

    /** =========== SURVEY actions =========== **/
    saveQuestionModelProps ({ commit, state, getters, dispatch }, props) {
      let model = _.clone(state.question.model)
      _.each(props, (v, k) => { model[k] = v })
      dispatch('saveQuestionProps', { model })
    },

    ...topicsMixin.actions
  },

  modules: {
    registerManager,
    metaManager,
    configManager,
    verbatimManager,
    questionManager,
    verbatimDialog
  }
}
