<template>
  <div>
    <dialog-wrapper
      @close="closeDialog"
      v-model="internalValue"
      :width="800"
    >
      <dialog-content class="pa-8">
        <div
          class="d-flex justify-center"
          v-if="!ready"
        >
          <v-progress-circular
            class="mx-auto"
            color="primary"
            indeterminate
            :size="50"
          />
        </div>
        <zapier-full-experience
          v-show="ready"
          client-id="991TXDu2sg2ylJu9ONfrMwIv3YR5xW4mP7jtnmDV"
          theme="light"
          intro-copy-display="show"
          app-selection-display="hide"
          zap-style="row"
          zap-call-to-action-display="show"
          zap-create-from-scratch-display="show"
        />
      </dialog-content>
      <dialog-actions>
        <template slot="rightSide">
          <v-btn
            outlined
            @click="closeDialog"
            class="mr-2"
          >
            {{ $t('cancel') }}
          </v-btn>
        </template>
      </dialog-actions>
    </dialog-wrapper>
    <div ref="externalResourcesPlaceholder" />
  </div>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import DataMixin from '@/components/customUi/mixins/data'
import DialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'ManageIntegrations',
  codit: true,

  components: {
    'dialog-wrapper': DialogWrapper,
    'dialog-content': DialogContent,
    'dialog-actions': DialogActions
  },

  mixins: [DataMixin, DialogMixin],

  data () {
    return {
      ready: false
    }
  },

  watch: {
    async internalValue (value) {
      if (
        !value ||
        this.ready
      ) {
        return
      }

      this.loadCDN(
        'script',
        {
          src: 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js',
          type: 'module'
        },
        this.$refs.externalResourcesPlaceholder
      )
      this.loadCDN(
        'link',
        {
          href: 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css',
          rel: 'stylesheet'
        },
        this.$refs.externalResourcesPlaceholder
      )

      await new Promise(resolve => setTimeout(resolve, 1000))
      this.ready = true
    }
  },

  methods: {
    loadCDN (tag, props, placeholder) {
      return new Promise((resolve, reject) => {
        const element = document.createElement(tag)

        Object.entries(props).forEach(([key, value]) => {
          element[key] = value
        })

        element.async = true
        placeholder.appendChild(element)
        element.onload = resolve
        element.onerror = reject
      })
    }
  }
}
</script>