<template>
  <div class="d-flex w-100" v-if="step === 5">
    <div class="notification-container">
      <notification
        v-if="generatorError"
        type="error"
        max-width="400px"
        closeable
        class-name="mb-2"
        :title="$t('refinement_steps.generator_error_title')"
        :text="$t('refinement_steps.generator_error_text')"
        action="Retry"
        :action-click="generatorRerun"
      />
    </div>
    <div class="d-flex w-100">
      <div class="d-flex flex-column align-center wizard__codebook">
        <div class="wizard__stepper">
          <div class="wizard__stepper__step">
            <div
              class="wizard__stepper__step__number"
              :class="{
                'wizard__stepper__step__number--active': refinementStep === 1,
                'wizard__stepper__step__number--completed': refinementStep > 1
              }"
            >
              1
            </div>
            <div class="wizard__stepper__step__title">
              {{ $t('refinement_steps.step_one') }}
            </div>
          </div>
          <div class="wizard__stepper__separator" />
          <div class="wizard__stepper__step">
            <div
              class="wizard__stepper__step__number"
              :class="{
                'wizard__stepper__step__number--active': refinementStep === 2,
                'wizard__stepper__step__number--completed': refinementStep > 2
              }"
            >
              2
            </div>
            <div class="wizard__stepper__step__title">
              {{ $t('refinement_steps.step_two') }}
            </div>
          </div>
          <div class="wizard__stepper__separator" />
          <div class="wizard__stepper__step">
            <div
              class="wizard__stepper__step__number"
              :class="{
                'wizard__stepper__step__number--active': refinementStep === 3
              }"
            >
              3
            </div>
            <div class="wizard__stepper__step__title">
              {{ $t('refinement_steps.step_three') }}
            </div>
          </div>
        </div>
        <div class="wizard__title">
          {{ refinementStep }}. {{ currentRefinementStepTitle }}
        </div>
        <div class="wizard__subtitle d-flex align-center" style="max-width: 100%;">
          {{ currentRefinementStepSubtitle }}&nbsp;
          <div
            class="text-sm text-primary text-hover text-lowercase font-weight-medium"
            @click="goToNextRefinementStep"
          >
            {{ refinementStep === 3 ? $t('save.title') : $t('click_next') }}
          </div>.
        </div>
        <codebook-generator />
      </div>

      <!-- <div class="wizard__sidebar" :class="refinementStep > 2 && 'wizard__sidebar--coverage'"> -->
      <div class="wizard__sidebar">
        <div class="d-flex justify-space-between align-center pr-2 coding__column__tabs-container pt-0 pb-0">
          <v-tabs
            class="coding__column__tabs"
            :value="activeTab"
            @change="val => $store.commit('setActiveTab', val)"
            :show-arrows="false"
            :ripple="false"
          >
            <v-tab
              v-for="(tab, index) in tabs"
              :key="tab"
              class="pt-0"
              :ripple="false"
            >
              {{ tabLabels[index] }}
            </v-tab>
          </v-tabs>
        </div>
        <!-- <coverage /> -->
        <v-tabs-items
          :value="activeTab"
          @change="val => $store.commit('setActiveTab', val)"
        >
          <v-tab-item eager :transition="false">
            <suggestions />
          </v-tab-item>
          <v-tab-item
            :disabled="loading || failed"
            :transition="false"
          >
            <slot />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div class="upload-footer pl-4 pr-4 default-bs">
      <v-btn
        class="upload-footer__back"
        color="white"
        :disabled="false"
        @click="() => { cancelConfirmModal = true }"
        x-large
        elevation="0"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        @click="goToNextRefinementStep"
        color="primary"
        :disabled="loading || failed"
        x-large
        class="default-bs"
      >
        {{ refinementStep === 3 ? $t('save.title') : $t('next') }}
      </v-btn>
    </div>
    <modal
      :visible="cancelConfirmModal"
      :close="() => { cancelConfirmModal = false }"
      :title="$t('refinement_steps.cancel_analysis')"
      :action="$t('cancel')"
      max-width="400px"
      :action-click="() => {
        $store.commit('resetWizard')
        cancelConfirmModal = false
      }"
      action-type="error"
    >
      <div class="text-sm">
        {{ $t('refinement_steps.cancel_analysis_text') }}
      </div>
    </modal>
  </div>
</template>

<script>

import axios from 'axios'
import Vuex from 'vuex'
import CodebookGenerator from '@/components/wizard/CodebookGeneratorv2.vue'
import Suggestions from '@/components/wizard/Suggestions.vue'
import { MOVED_TOPIC } from '@/settings/constants'
// import Coverage from '@/components/wizard/Coverage.vue'

export default {
  codit: true,
  name: 'CodebookRefinement',
  components: {
    CodebookGenerator,
    Suggestions
    // Coverage
  },

  props: {
  },

  data () {
    return {
      cancelConfirmModal: false,
      generateMoreShown: false,
      generateMoreCategoriesShown: false
    }
  },

  computed: {
    ...Vuex.mapState({
      id: state => state.wizard.id,
      step: state => state.wizard.step,
      refinementStep: state => state.wizard.refinementStep,
      suggestions: state => state.wizard.suggestions,
      topics: state => state.wizard.topics,
      projectID: state => state.wizard.projectID,
      columnRef: state => state.wizard.columnRef,
      questionID: state => state.wizard.questionID,
      loading: state => state.wizard.generatorLoading,
      failed: state => state.wizard.generatorFailed,
      activeTab: state => state.wizard.activeTab,
      inheritsFrom: state => state.wizard.inheritsFrom,
      generatorRerun: state => state.wizard.generatorRerun,
      generatorError: state => state.wizard.generatorError
    }),

    currentRefinementStepTitle () {
      if (this.refinementStep === 1) return this.$t('refinement_steps.step_one_title')
      if (this.refinementStep === 2) return this.$t('refinement_steps.step_two_title')
      return this.$t('refinement_steps.step_three_title')
    },

    currentRefinementStepSubtitle () {
      if (this.refinementStep === 1) return this.$t('refinement_steps.step_one_subtitle')
      if (this.refinementStep === 2) return this.$t('refinement_steps.step_two_subtitle')
      return this.$t('refinement_steps.step_three_subtitle')
    },

    /**
     * Tab options
     * @return {Array}
     */
    tabs () {
      return ['suggestions', 'row_browser']
    },

    tabLabels () {
      return [`${this.$t('suggestions.title')} (${_.filter(this.suggestions, ({ done, action }) => !done && !(action === MOVED_TOPIC)).length})`, this.$t('row_browser')]
    }
  },
  methods: {
    /**
     * Handle going to next refinement step
     */
    goToNextRefinementStep () {
      if (this.refinementStep < 3) {
        if (this.refinementStep === 1) {
          // set organize categories suggestion to done
          if (this.suggestions.length) this.$store.commit('setSuggestionStatus', { value: true, suggestion: this.suggestions[0] })
        }

        if (this.refinementStep === 2) {
          // set all previous suggestions to done
          _.map(this.suggestions, (suggestion) => this.$store.commit('setSuggestionStatus', { value: true, suggestion }))
        }

        this.$store.commit('incrementRefinementStep')
      } else {
        this.save()
      }
    },

    /**
     * Save the resulting codebook as well as any changes that have been made during the wizard
     */
    save () {
      let saveProjectPayload = {
        columns: [{
          ref: this.columnRef,
          type: 'text_to_analyze',
          metadata: {
            learns_from: this.inheritsFrom.projectID ? {
              project: this.inheritsFrom.projectID,
              ref: this.inheritsFrom.columnRef
            } : null
          }
        }]
      }

      let saveTopics = _.map(this.topics, cd => ({ ...cd, id: null }))
      let requests = [
        api.patch(`/api/ui/projects/${this.projectID}`, saveProjectPayload),
        api.patch(`/api/ui/projects/${this.projectID}/${this.columnRef}/codebook`,
          {
            topics: saveTopics,
            inference_training_debounce_delay: 'sync'
          })
      ]
      axios.all(requests).then(() => {
        this.$store.commit('setSuccess', true)
        this.goToCockpit()
      }).catch(err => {
        this.saving = false
        this.savingFailed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Go to the cockpit
     */
    goToCockpit () {
      this.$router.push({
        name: 'question-cockpit',
        params: { id: this.questionID }
      })
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />