/* eslint-disable camelcase */
import {
  PROJECT_LIST_FILTERS_DEFAULT as FILTERS_DEFAULT
} from '@/settings/constants'

function encodeStr (str, strict) {
  let encoded = String(str).replaceAll('\\', '\\\\')
  encoded = encoded.replaceAll(/(:|,|;)/g, '\\$1')

  if (
    strict
  ) {
    encoded = encoded.replaceAll(/\./g, '\\.')
  }

  return encoded
}

function encodeFilterQuery (constraints, operator = 'or') {
  // validate input object
  if (!operator) throw new Error('`operator` must not be null.')
  if (operator !== 'and' && operator !== 'or') throw new Error('`operator` must be either `and` or `or`')
  if (!constraints || !Array.isArray(constraints)) throw new Error('`constraints` must be an array.')

  // encode variables and values accordingly
  const encodedParts = constraints.map(({ variable, value }) => {
    if (variable === '') throw new Error('`variable` must be either null or a non-empty string.')
    if (value == null) throw new Error('`value` must not be null.')

    let encoded = ''
    if (variable) encoded += encodeStr(variable) + ':'
    if (value) encoded += encodeStr(value)

    return encoded
  })

  const encodedFilterQuery = encodedParts.join(operator === 'and' ? ';' : ',')
  return encodedFilterQuery
}

function decodeFilterQuery (str, operator = 'or') {
  if (!operator) throw new Error('`operator` must not be null.')
  if (operator !== 'and' && operator !== 'or') throw new Error('`operator` must be either `and` or `or`')
  if (!str) throw new Error('parameter must be of type `string`.')

  // parse filter query formula
  const separator = operator === 'and' ? ';' : ','
  const formula = []
  let currentVar = ''
  let stream = ''
  let backslashOccurred = false
  let colonOccurred = false
  str = str + separator
  for (let i = 0; i < str.length; i++) {
    let char = str.charAt(i)

    if (backslashOccurred) {
      backslashOccurred = false
      if (char === '\\') {
        stream += '\\'
      } else if (char === ',' || char === ';' || char === ':') {
        stream += char
      } else {
        throw new Error('Invalid escape sequence: ' + '\\' + char)
      }
    } else {
      if (char === '\\') {
        backslashOccurred = true
      } else if (char === separator) {
        formula.push({ variable: currentVar === '' ? null : currentVar, value: stream })
        currentVar = ''
        stream = ''
        colonOccurred = false
      } else if (char === ';' || char === ',') {
        throw new Error('Forbidden Operator: ' + char)
      } else if (char === ':' && colonOccurred) {
        throw new Error('Could not parse literal: ' + currentVar + ':' + stream)
      } else if (char === ':') {
        colonOccurred = true
        currentVar = stream
        stream = ''
      } else {
        stream += char
      }
    }
  }

  return formula
}

/**
 * Creates constraints array passed into encodeFilterQuery function
 * @return {Array}
 */
function createQueryConstraintsFromArray (values) {
  return _.map(values, value => ({
    variable: null,
    value
  }))
}

/**
  * Creates array of values from passed in decoded query
  */
function createArrayFromConstraints (constraints) {
  return _.map(constraints, constraint => constraint.value)
}

function parseApiDate (val, from = false) {
  if (val === 'now') return moment().local().endOf('day').format()
  else {
    let date = moment.utc(val).local()

    if (from) return date.startOf('day').format()
    return date.endOf('day').format()
  }
}

/**
 * Returns query string from query object
 * @return {String}
 */
function createQueryStringFromObj (queryObj) {
  let string = ''

  _.mapKeys(queryObj, (value, key) => {
    if (_.isArray(value)) {
      value.forEach(v => {
        string = string + `${encodeURIComponent(key)}=${encodeURIComponent(v)}&`
      })
    } else {
      string = string + `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`
    }
  })

  return string
}

function createQueryObjFromFilters (filters, insideModal = false, inheritable = false) {
  let query = {}

  if (filters) {
    const {
      tab,
      archived,
      pagination: {
        page,
        rowsPerPage
      },
      search,
      labels,
      owner,
      language,
      data_source_provider,
      created,
      last_modified,
      order_by
    } = filters || {}

    // Tab filter
    if (tab && !(tab.value === FILTERS_DEFAULT.tab.value)) {
      query.tab = encodeFilterQuery([{ 'variable': tab.type, value: tab.value }])
    }

    // Archived filter - no need for encoding as it's just Boolean
    if (!(archived === FILTERS_DEFAULT.archived)) {
      query.archived = archived
    }

    // Page filter - no need for encoding as it's just Number
    if (!(page === FILTERS_DEFAULT.pagination.page)) {
      query.page = page
    }

    // Limit filter - - no need for encoding as it's just Number
    if (!(rowsPerPage === FILTERS_DEFAULT.pagination.rowsPerPage)) {
      query.limit = rowsPerPage
    }

    if (insideModal) query.limit = 5

    if (inheritable) {
      if (_.isString(inheritable)) query.inheritable_question_id = inheritable
      else if (_.isObject(inheritable)) query = { ...query, ...inheritable }
      else throw Error(`Unknown inheritable format ${inheritable}`)
    }

    // Search filter
    if (!(search === FILTERS_DEFAULT.search)) {
      query.search = encodeFilterQuery([{ 'variable': null, value: search }])
    }

    // Tag filter
    if (labels.length) {
      query.labels = encodeFilterQuery(createQueryConstraintsFromArray(labels), 'and')
    }

    // Owner filter
    if (owner.length) {
      query.owner = encodeFilterQuery(createQueryConstraintsFromArray(owner))
    }

    // Language filter
    if (language.length) {
      query.language = encodeFilterQuery(createQueryConstraintsFromArray(language))
    }

    // Source filter
    if (data_source_provider.length) {
      query.data_source_provider = encodeFilterQuery(createQueryConstraintsFromArray(data_source_provider))
    }

    // Created date filter - TODO: additional validation of iso type date here
    if (created.length === 2) {
      query.created = encodeFilterQuery([
        { variable: 'from', value: parseApiDate(created[0], true) },
        { variable: 'to', value: parseApiDate(created[1], false) }
      ], 'and')
    }

    // Modified date filter - TODO: additional validation of iso type date here
    if (last_modified.length === 2) {
      query.last_modified = encodeFilterQuery([
        { variable: 'from', value: parseApiDate(last_modified[0], true) },
        { variable: 'to', value: parseApiDate(last_modified[1], false) }
      ], 'and')
    }

    if (order_by) {
      query.order_by = `${order_by.order}:${order_by.column}`
    }
  }

  return query
}

export {
  encodeStr,
  createQueryConstraintsFromArray,
  encodeFilterQuery,
  decodeFilterQuery,
  createQueryObjFromFilters,
  createArrayFromConstraints,
  createQueryStringFromObj,
  parseApiDate
}
