<template>
  <div class="page-header">
    <v-expand-transition v-if="$slots.info">
      <div class="info-alert-container" v-show="showInfoAlertLocal">
        <v-alert color="blue-grey"
                 prominent
                 type="info"
                 outlined
                 icon="mdi-hand-pointing-right"
                 class="info-alert"
                 border="left"
        >
          <slot name="info" />
          <v-btn class="dismiss" icon small color="blue-grey" @click="toggleInfoAlert(featureName)">
            <v-icon>
              mdi-close-circle
            </v-icon>
          </v-btn>
        </v-alert>
        <div class="placeholder" />
      </div>
    </v-expand-transition>

    <transition name="toolbar-roll" mode="out-in">
      <div v-if="!$slots['roll-secondary']"
           class="title-toolbar normal-stuff"
           key="normal-stuff"
           :class="{ 'headline-and-subtitle': $slots.headline && $slots.subtitle }"
      >
        <div class="title-text">
          <div class="headline" v-if="$slots.headline">
            <slot name="headline" />
          </div>
          <div class="subtitle-1" v-if="$slots.subtitle">
            <slot name="subtitle" />
          </div>
          <slot name="title-raw" />
        </div>
        <div class="nav-container">
          <template v-if="$slots.info">
            <div v-if="!showInfoAlertLocal || infoBtnWasHere"
                 class="info-alert-dismissed"
                 :class="{ tooltip: showTooltip, 'tooltip-left': showTooltip }"
                 :data-tooltip="$t('info_alert_btn_tooltip')"
            >
              <v-btn @click="toggleInfoAlert(featureName)"
                     icon
                     color="blue-grey"
                     dark
                     :class="{ outro: showInfoAlertLocal && infoBtnWasHere, intro: !showInfoAlertLocal && infoBtnWasHere }"
              >
                <v-icon>mdi-hand-pointing-right</v-icon>
              </v-btn>
            </div>
            <v-divider vertical v-if="!$slots['page-buttons'] && $slots['navigation-buttons']" />
          </template>

          <template v-if="$slots['page-buttons']">
            <slot name="page-buttons" />
            <v-divider v-if="$slots['navigation-buttons']" vertical />
          </template>

          <slot name="navigation-buttons" />
        </div>
      </div>
      <div v-else key="roll-secondary">
        <slot name="roll-secondary" />
      </div>
    </transition>

    <v-divider style="margin: 12px 0" />
  </div>
</template>

<script>
export default {
  codit: true,
  name: 'PageHeader',

  props: {
    // If a prefix for a specific feature should be applied to the page name
    // for showing the info alert
    featureName: { type: String, default: '' }
  },

  data () {
    return {
      infoBtnWasHere: false,
      showTooltip: false,
      showInfoAlertLocal: false
    }
  },

  watch: {
    showInfoAlertLocal: {
      immediate: true,
      handler (valNew, valOld) {
        // valOld will be undefined on the initial call to this handler
        if (valOld !== undefined) this.infoBtnWasHere = true
        if (!valNew) {
          // Delay showing the tooltip, to prevent ugly flackering of tooltip when closing
          // the alert and toogle button is on right of navbar
          setTimeout(() => { this.showTooltip = true }, 2500)
        } else this.showTooltip = false
      }
    }
  },

  methods: {
    toggleInfoAlert () {
      this.showInfoAlertLocal = !this.showInfoAlertLocal
    }
  }
}

</script>

<style lang=scss>

.page-header {
  .info-alert-container {
    .v-alert {
      margin: 0;
      .v-alert__content {
        margin-right: 36px;
      }

      .v-btn.dismiss {
        position: absolute;
        right: 8px;
        top: 50%;
        margin-top: -12px;
      }
    }

    .placeholder {
      height: 16px;
    }
  }

  .title-toolbar {
    display: flex;
    align-items: center;

    &.headline-and-subtitle {
      min-height: 60px;
    }

    > .title-text {
      flex: 1;
    }
    .nav-container {
      display: flex;

      .info-alert-dismissed {
        .v-btn {
          margin-right: 12px;
          background: rgba(96, 125, 139, 0.1);
          transform: scaleX(-1) rotateZ(-20deg);

          &.intro {
            animation: info-btn-intro 0.8s;
          }

          &.outro {
            animation: info-btn-outro 0.5s forwards;
          }
        }
      }

      .v-btn {
        margin-left: 6px;
      }

      .v-divider {
        margin: 0 6px 0 12px;
      }
    }
  }

  @keyframes info-btn-intro {
    0% {
      box-shadow: 0 0 0 0 rgba(96, 125, 139, 0.6);
      transform: scale(-0.5, 0.5) rotateZ(-120deg);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(96, 125, 139, 0);
      transform: scale(-1.2, 1.2) rotateZ(-20deg);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(96, 125, 139, 0);
      transform: scale(-1, 1) rotateZ(-20deg);
    }
  }

  @keyframes info-btn-outro {
    100% {
      transform: scale(-0.5, 0.5);
      opacity: 0;
      visibility: hidden;
    }
  }

  .toolbar-roll-enter {
    opacity: 0;
    transform: translate(0, 60px)
  }

  .toolbar-roll-leave-to {
    opacity: 0;
    transform: translate(0, -60px)
  }

  .toolbar-roll-enter-active, .toolbar-roll-leave-active {
    transition: all 0.15s ease-out;
    will-change: opacity, transform;
  }
}

</style>