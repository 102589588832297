<template>
  <!-- ====== Session issue modal ====== -->
  <v-dialog v-model="sessionIssue.show" max-width="600" v-if="sessionIssue.show" persistent>
    <v-card>
      <v-card-title class="title" primary-title>
        {{ $t(`session_issue.${sessionIssue.type}.title`, { user: editingUser }) }}
      </v-card-title>

      <v-card-text>
        <span v-html=" $t(`session_issue.${sessionIssue.type}.content`, { user: $escapeHtml(editingUser) })" />

        <div style="display: flex">
          <v-spacer />
          <v-btn v-if="sessionIssue.type in {'connection_lost': 1, 'rows_uploaded': 1 }"
                 color="primary"
                 outlined
                 @click="reload"
          >
            {{ $t('actions.refresh') }}
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions v-if="['multiple_users', 'takeover_by', 'takeover_confirm'].indexOf(sessionIssue.type) !== -1">
        <v-spacer />
        <v-btn v-if="sessionIssue.type === 'multiple_users'"
               @click="$emit('retake-session-control')"
               :loading="socket.waiting"
               color="accent"
        >
          {{ $t('session_issue.multiple_users.takeover_control', { user: editingUser }) }}
        </v-btn>
        <v-btn outlined :disabled="socket.waiting" @click="$store.commit('closeSessionIssue')">
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Vuex from 'vuex'

export default {
  codit: true,
  name: 'QuestionWorkerSessionIssueDialog',

  props: {
    socket: { type: Object, default: () => ({}) }
  },

  computed: {
    editingUser () {
      let eu = _.find(this.usersOnline, { read_only: false })
      if (!eu) return this.$t('someone')
      else return eu.name
    },

    ...Vuex.mapState({
      usersOnline: state => state.coding.usersOnline,
      sessionIssue: state => state.coding.sessionIssue
    })
  },

  methods: {
    reload () {
      window.location.reload()
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/coding/QuestionWorkerSessionIssueDialog.json' />
<i18n locale='de' src='@/i18n/de/components/coding/QuestionWorkerSessionIssueDialog.json' />