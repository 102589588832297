<template>
  <div class="wizard-qtype">
    <v-alert v-if="!editable && !loading" type="warning" prominent outlined border="left">
      <div>{{ this.$t('question_type.alert_not_editable_permissions') }}</div>
      <div v-html="$t('request_access', {
        root_name: $escapeHtml(user.organization.root_user_name),
        root_email: user.organization.root_user_email,
        owner_name: $escapeHtml(question.owner),
        owner_email: question.owner_email
      })
      "
      />
    </v-alert>

    <template v-else>
      <page-header feature-name="qtype">
        <span slot="info" v-html="$t('question_type.info')" />
      </page-header>

      <v-alert v-if="!isQuestionEligibleForList"
               text
               type="warning"
               border="left"
               icon="mdi-lightbulb-on"
      >
        <span v-html="$t('question_type.not_list.alert')" />
      </v-alert>
      <div class="question-types">
        <v-card @click="setQuestionType('open')"
                class="question-type open-type"
                :class="{ selected: questionType === 'open' }"
        >
          <div class="media">
            <v-icon>mdi-message-text</v-icon>
            <div>
              <div class="title" v-text="$t('question_type.open.title')" />
              <div class="subtitle" v-text="$t('question_type.open.subtitle')" />
            </div>
          </div>

          <v-card-text>
            <p v-html="$t('question_type.open.ai')" style="font-weight: bold" />
            <p v-html="$t('question_type.open.description')" />
            <ul>
              <li v-for="(sample, idx) in $ta('question_type.open.examples')" :key="idx">
                {{ sample }}
              </li>
            </ul>
          </v-card-text>
        </v-card>

        <v-card @click="setQuestionType('list')"
                :ripple="isQuestionEligibleForList"
                class="question-type list-type"
                :class="{ selected: isListQuestion, disabled: !isQuestionEligibleForList, tooltip: !isQuestionEligibleForList }"
                :data-tooltip="$t('question_type.not_list.tooltip')"
        >
          <div class="media">
            <v-icon>mdi-bank</v-icon>
            <div>
              <div class="title" v-text="$t('question_type.list.title')" />
              <div class="subtitle" v-text="$t('question_type.list.subtitle')" />
            </div>
          </div>

          <v-card-text>
            <p v-html="$t('question_type.list.ai')" style="font-weight: bold" />
            <p v-html="$t('question_type.list.description')" />
            <ul>
              <li v-for="(sample, idx) in $ta('question_type.list.examples')" :key="idx">
                {{ sample }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </div>

      <transition name="slide-height">
        <div v-if="questionType === 'open'">
          <v-alert color="blue-grey"
                   class="question-category"
                   prominent
                   outlined
                   border="left"
                   style="margin: 0"
          >
            <div class="subtitle-1">
              {{ $t('question_type.question_category_subtitle-1') }}
            </div>
            <v-autocomplete v-model="questionCategory"
                            :items="questionCategoriesItems"
                            outlined
                            hide-details
                            :label="$t('question.category.title')"
            />
          </v-alert>
        </div>
      </transition>

      <div class="btn-next-container">
        <slot name="next-container">
          <v-btn color="primary"
                 @click="$emit('next')"
                 :disabled="!questionType"
                 :class="{ tooltip: !questionType, 'tooltip-right': true }"
                 :data-tooltip="$t('question_type.next_tooltip')"
          >
            {{ $t('next') }}
          </v-btn>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>

import Vuex from 'vuex'

import { QUESTION_CATEGORIES, QUESTION_CATEGORY_LIST, QUESTION_CATEGORY_NONE } from '@/settings/constants'

export default {
  codit: true,
  name: 'QuestionType',

  props: {
    loading: { type: Boolean, default: false }
  },

  data () {
    return {
      questionType: null
    }
  },

  computed: {
    /**
     * Getter and setter for the question_category attribute of the question
     * Proxy to the underlying store, which provides & modifies the attribute
     */
    questionCategory: {
      get () { return this.question.question_category },
      set (category) { this.setQuestionCategory(category) }
    },

    /**
     * The question categories formatted for use in v-select
     * @return {Array} Array of objects {value,text}
     */
    questionCategoriesItems () {
      let it = _.filter(QUESTION_CATEGORIES, c => (c !== QUESTION_CATEGORY_LIST && c !== QUESTION_CATEGORY_NONE))
      return _.map(it, c => ({ value: c, text: this.$t(`question.category.${c}`) }))
    },

    editable () {
      return this.$hasPermission('projects_edit', this.question)
    },

    ...Vuex.mapState({
      user: 'user',
      question: state => state.wizard.question,
      isQuestionEligibleForList: state => state.wizard.isQuestionEligibleForList
    }),

    ...Vuex.mapGetters(['isListQuestion'])
  },

  watch: {
    isQuestionEligibleForList (val) {
      if (!val) this.setQuestionType('open')
    }
  },

  created () {
  },

  destroyed () {
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    /**
     * Set the question *type* (either list of open)
     * Note: This is *not* the same thing as the question_category, it is an attribute
     * only present in the frontend and not a direct property of the DB question entry
     * Logic: `list` type (FE) is `list` category (DB), every other category in DB is `open` type
     * @param {String} qType {open,list}
     */
    setQuestionType (qType) {
      if (qType === 'list' && !this.isQuestionEligibleForList) return
      this.questionType = qType
      if (qType === 'list') this.setQuestionCategory(QUESTION_CATEGORY_LIST)
      else {
        this.setQuestionCategory(QUESTION_CATEGORY_NONE)
        // Remove the codebook_generator_list key from the model props
        this.$store.commit('setQuestionModelProps', { codebook_generator_list: undefined })
      }
    },

    /**
     * Sets the question *category*
     * Categories are a direct property of question in DB
     * @param {String} category One of the valid question category choices
     */
    setQuestionCategory (category) {
      this.$store.commit('setQuestionCategory', category)
    }
  }
}

</script>

<style lang=scss>

.wizard-qtype {
  .slide-height-enter-active,
  .slide-height-leave-active {
    transition: all 0.25s ease-in-out;
    max-height: 120px;
  }
  .slide-height-enter,
  .slide-height-leave-to
  {
    opacity: 0;
    max-height: 0px;
  }

  .question-category {
    background: #FAFAFAFA
  }

  .subtitle-1 {
    margin-bottom: 8px;
  }

  .question-types {
    display: flex;
    padding: 12px 0 24px;
    justify-content: center;

    .question-type {
      flex: 1;
      transition: all 0.1s ease-out;
      margin: 0 24px;
      cursor: pointer;
      max-width: 500px;

      &.list-type:not(.disabled) {
        &:hover, .media, &.selected { background: $col-soft-turq; }

      }

      &.open-type:not(.disabled) {
        &:hover, .media, &.selected { background: $col-soft-pink; }
      }

      &.disabled {
        &:hover, .media, &.selected { background: rgba(0, 0, 0, 0.2) }
        cursor: default;
      }

      .media {
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        padding: 20px 16px;
        .v-icon {
          font-size: 60px;
          margin-right: 25px;
        }

        .subtitle {
          line-height: 1.2;
          font-weight: normal;
          opacity: 0.8;
        }
      }

      .v-card__text {
        line-height: 1.3em;
        ul {
          margin-top: 8px;
          font-style: italic;
          li { line-height: 1.3em; }
        }
      }

      &:hover:not(.selected):not(.disabled) {
        transform: scale(1.05);
      }

      &.selected {
        transform: scale(1.1, 1.1);
      }
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/wizard/QuestionType.json' />
<i18n locale='de' src='@/i18n/de/components/wizard/QuestionType.json' />
<i18n locale='en' src='@/i18n/en/pages/Wizard.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizard.json' />