import Vuex from 'vuex'

import { parseColor, colorToHexA, get3ShadesForColor, getSofter, getStronger } from '@/utils/colorUtils'

export default {
  data () {
    return {
      colorPaletteBold: [
        '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83',
        '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'
      ]
    }
  },

  computed: {
    userColorPalettes () {
      return this.user.isAnonymous ? {} : this.user.app_settings.COLOR_PALETTES || {}
    },

    colorPalettes () {
      return {
        ...this.configColorPalettes,
        default: this.$color.paletteMedium,
        bold: this.colorPaletteBold,
        ...this.userColorPalettes }
    },

    colorPalettesParsed () {
      return _.mapValues(this.colorPalettes, palette => palette.map(parseColor))
    },

    colorPalettesAsHexA () {
      return _.mapValues(this.colorPalettes, palette => palette.map(colorToHexA))
    },

    ...Vuex.mapState(['user'])
  },

  methods: {
    get3ShadesForColor,
    getSofter,
    getStronger,

    /**
     * Take a codebook and add the 'color' property to every code
     * @param {Array} codes List of codes
     */
    addColorToCodes (codes) {
      let codeCats = _(codes).groupBy('category').keys().value()
      return _.map(codes, code => ({
        ...code,
        color: code.color && parseColor(code.color) ? code.color : this.$color.getMedium(codeCats.indexOf(code.category))
      }))
    },

    addColorToTopics (topics) {
      return this.addColorToCodes(topics)
    },

    /**
     * Calculates if color has high contrast.
     * Takes into account opacity of background.
     * @param {String} RGBA color of background color
     * @return {String} HEX white or black color topic
     */
    getContrast (rgbaColor) {
      const threshold = 180 /* about half of 256. Lower threshold equals more dark text on dark background  */

      const rgbaParsed = rgbaColor.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)

      const red = +rgbaParsed[1]
      const green = +rgbaParsed[2]
      const blue = +rgbaParsed[3]
      const opacity = +rgbaParsed[4]

      const cBrightness = (0.2126 * red) + (0.7152 * green) + (0.0722 * blue)

      if (
        opacity <= 0.4 ||
        cBrightness > threshold
      ) {
        return 'low'
      } else {
        return 'high'
      }
    }
  }
}
