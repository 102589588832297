<template>
  <div>
    <div v-if="loadingIntegrations" class="d-flex flex-column justify-center p-5 align-center">
      <loading
        :is-loading="true"
        :dont-animate-entry="true"
        title=""
        tagline=""
      />
    </div>
    <div class="d-flex flex-column align-center w-100" v-else>
      <div v-if="$refs.upload && $refs.upload.dropActive" class="drop-active d-flex justify-center align-center font-weight-medium">
        {{ $t('steps.upload.drop_file') }}
      </div>

      <!-- hack to enable dropping file anywhere on the screen -->
      <file-upload
        v-show="!answersInput.file.length && $refs.upload && $refs.upload.dropActive"
        ref="upload"
        :value="answersInput.file"
        @input="updateFile"
        :accept="acceptFileTypes"
        :multiple="false"
        :drop="true"
        :drop-directory="false"
        class="browse--full"
      />

      <div
        v-if="append"
        class="grey--text text--darken-1 font-weight-medium overline mb-0 append-title-label"
      >
        {{ $t('steps.append.title_label') }}
      </div>
      <div
        v-if="replace"
        class="grey--text text--darken-1 font-weight-medium overline mb-0 append-title-label"
      >
        {{ $t('steps.replace.title_label') }}
      </div>

      <h1
        class="font-weight-medium"
        v-html="append || replace ? $t('steps.append.title', { project: $escapeHtml(project.name) }) : $t('steps.upload.title')"
      />
      <div class="d-flex align-center justify-center mt-1">
        <div class="mr-2 grey--text text--darken-1 upload-subtitle" v-html="pageAndTabSubtitle" />
        <span
          v-if="!append && !replace && isFileTab"
          class="file-examples d-flex align-center"
        >
          <a class="font-weight-medium" href="/static/upload_example_answers_fullyopen.xlsx" :download="`Caplena_sample_${$t('answers')}.xlsx`">Excel</a>
          <span class="mr-2 ml-2">/</span>
          <a class="font-weight-medium" href="/static/upload_example_answers_fullyopen.csv" :download="`Caplena_sample_${$t('answers')}.csv`">CSV</a>
          <span class="mr-2 ml-2">/</span>
          <a class="font-weight-medium" href="/static/upload_example_answers_fullyopen.txt" :download="`Caplena_sample_${$t('answers')}.txt`">Plaintext</a>
        </span>
      </div>
      <div class="upload-settings upload-settings--wide mt-5">
        <div
          v-if="showInputTab"
          class="upload-settings__tabs-container d-flex"
        >
          <div
            :class="isIntegrationTab && 'active font-weight-medium'"
            class="upload-settings__tab hover d-flex align-center justify-center position-relative"
            @click="handleInputTabClick('integration')"
          >
            {{ $t('steps.upload.integrate') }}
          </div>
          <div
            :class="isFileTab && 'active font-weight-medium'"
            class="upload-settings__tab hover d-flex align-center justify-center"
            @click="handleInputTabClick('file')"
          >
            {{ $t('steps.upload.upload') }}
          </div>
          <div
            :class="{
              active: isInputTab,
              'font-weight-medium': isInputTab
            }"
            class="upload-settings__tab hover d-flex align-center justify-center"
            @click="handleInputTabClick('input')"
          >
            {{ $t('steps.upload.direct') }}
          </div>
        </div>
        <div
          v-if="isFileTab"
          class="upload-box d-flex align-center mt-0 position-relative"
          :class="!loading && answersInput.file.length && 'upload-box--file'"
        >
          <div v-if="!answersInput.file.length">
            <div class="d-flex flex-column justify-center p-5 align-center">
              <file-upload
                :value="answersInput.file"
                @input="updateFile"
                :accept="acceptFileTypes"
                :multiple="false"
                :drop="true"
                :drop-directory="false"
              >
                <img class="mb-2 hover" src="../../assets/icons/upload.svg" alt="Logo caplena.com" width="70">
              </file-upload>
              <div class="d-flex">
                <div class="upload-box__text" v-html="$t('steps.upload.drop')" />
                <file-upload
                  :value="answersInput.file"
                  @input="updateFile"
                  :accept="acceptFileTypes"
                  :multiple="false"
                  :drop="true"
                  :drop-directory="false"
                  class="browse ml-1 primary--text font-weight-medium hover"
                >
                  {{ $t('steps.upload.browse') }}
                </file-upload>
              </div>
              <label class="grey--text mt-1" v-html="$t('steps.upload.support')" />
            </div>
          </div>
          <div v-else-if="loading">
            <div class="d-flex flex-column justify-center p-5 align-center">
              <loading
                :is-loading="true"
                :dont-animate-entry="true"
                :title="$t('processing')"
                tagline=""
              />
            </div>
          </div>
          <div v-else class="d-flex align-center w-100">
            <div class="d-flex flex-column justify-center p-5 align-center w-100 h-100">
              <v-btn icon class="remove-file" small @click="removeFile">
                <v-icon color="primary" large>
                  mdi-close
                </v-icon>
              </v-btn>
              <img class="mb-2" src="../../assets/icons/check.svg" alt="Logo caplena.com" width="70">
              <div class="d-flex">
                <div class="upload-box__text">
                  {{ $t('steps.upload.success') }}
                </div>
              </div>
              <label class="grey--text">
                {{ fileName }}
              </label>
              <label class="grey--text">
                {{ fileSize }} KB
              </label>
            </div>
          </div>
        </div>
        <textarea
          v-if="isInputTab"
          :value="answersInput.input"
          @input="handleAnswersInputChange"
          class="upload-box upload-box--text-input"
          :placeholder="$t('steps.upload.input_placeholder')"
        />
        <div
          v-if="isIntegrationTab && !answersInput.integration"
          class="integration-list"
        >
          <!-- <div class="integration-list__label text-secondary text-center mb-6">
            {{ $t('steps.upload.integration_label') }}
          </div> -->
          <div class="d-flex flex-row flex-wrap">
            <div class="integration-list__subtitle w-100 mb-4">
              {{ $t('steps.upload.review_integrations') }}
            </div>
            <div
              v-for="integration in availableReviewIntegrations"
              :key="integration.provider"
            >
              <v-tooltip :disabled="!disabledIntegrations.includes(integration.provider)" top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <div
                      @click="handleIntegrationClick(integration)"
                      class="integration-list__item"
                      :class="disabledIntegrations.includes(integration.provider) && 'integration-list__item--disabled'"
                    >
                      <div class="integration-list__item__logo d-flex align-center justify-center">
                        <img :class="integration.provider" :src="integrationImage(integration.provider)" :alt="integration.name">
                      </div>
                      <div class="text-center mt-3 mb-6">
                        {{ integration.name }}
                      </div>
                    </div>
                  </div>
                </template>
                <span>{{ $t('steps.upload.integration_disabled') }}</span>
              </v-tooltip>
            </div>
            <div class="integration-list__separator mb-4" />
            <div class="integration-list__subtitle w-100 mb-4">
              {{ $t('steps.upload.custom_integrations') }}
            </div>
            <div
              class="integration-list__item"
              v-for="integration in availableCustomIntegrations"
              :key="integration.provider"
              @click="handleIntegrationClick(integration)"
            >
              <div class="integration-list__item__logo d-flex align-center justify-center" style="position: relative;">
                <img :class="integration.provider" :src="integrationImage(integration.provider)" :alt="integration.name">
                <div class="beta-burst" v-if="integration.provider === 'brandwatch'">
                  <span>Beta</span>
                </div>
              </div>
              <div class="text-center mt-3 mb-6">
                {{ integration.name }}
              </div>
            </div>
          </div>
          <router-link :to="{ name: 'account', query: { tab: 'integrations' } }" class="integration-list__link" v-html="$t('steps.upload.connect_account')" />
        </div>
        <!-- selected integration -->
        <div
          v-else-if="isIntegrationTab && answersInput.integration"
          class="integration"
        >
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <div class="integration__logo d-flex align-center justify-center">
                <img :src="integrationImage(answersInput.integration.provider)" :alt="answersInput.integration.name" :class="answersInput.integration.provider">
              </div>
              <div>
                <div class="integration__label ml-4 font-weight-medium d-flex align-center">
                  {{ answersInput.integration.name }}
                </div>
              </div>
            </div>
            <v-btn
              @click="handleIntegrationClick(null)"
              :disabled="loading"
              class="integration__back-button"
              icon
              outlined
              tile
              color="secondary"
            >
              <v-icon size="24">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <!-- QUALTRICS INTEGRATION -->
          <div v-if="answersInput.integration.requires_integration && answersInput.integration.provider === 'qualtrics'">
            <div class="integration__help mt-5 text-center d-flex justify-center mb-5">
              <div v-if="qualtricsIntegration.integrations.length">
                {{ $t('steps.upload.integration_description_specific.qualtrics') }}
              </div>
              <div v-else>
                <span v-html="$t('steps.upload.integration_description_specific.qualtrics_missing_account')" />
                <router-link :to="{ name: 'account', query: { tab: 'integrations' } }">
                  {{ $t('steps.upload.integration_description_specific.qualtrics_missing_account_link') }}
                </router-link>
              </div>
            </div>
            <div class="integration__links">
              <div class="integration__links__link" style="height: auto;">
                <div class="d-flex align-center justify-space-between">
                  <div class="integration__links__link__select">
                    <div class="integration__links__link__title font-weight-medium mt-5 mb-1">
                      {{ $t('steps.upload.integration_account') }}
                    </div>
                    <v-select
                      :items="qualtricsIntegration.integrations"
                      :label="answersInput.account ? '' : $t('steps.upload.integration_account')"
                      :elevation="0"
                      :value="answersInput.account"
                      @change="handleChangeIntegrationAccount"
                      :disabled="loading || qualtricsIntegrationInProgress"
                      primary
                      item-value="id"
                      item-text="name"
                      class=""
                      small
                      hide-details
                      outlined
                      dense
                    />
                  </div>
                  <div class="integration__links__link__select">
                    <div class="integration__links__link__title font-weight-medium mt-5 mb-1">
                      {{ $t('steps.upload.integration_survey') }}
                    </div>
                    <v-autocomplete
                      :items="availableSurveys"
                      :label="answersInput.survey ? '' : $t('steps.upload.integration_survey')"
                      :elevation="0"
                      :loading="loadingSurveys"
                      :disabled="!answersInput.account || loadingSurveys || qualtricsIntegrationInProgress"
                      :value="answersInput.survey"
                      @change="handleChangeIntegrationSurvey"
                      primary
                      item-value="id"
                      item-text="name"
                      small
                      hide-details
                      outlined
                      dense
                    />
                  </div>
                </div>
                <v-btn
                  @click="handleIntegrateQualtricsClick"
                  :disabled="(!validQualtricsIntegrate || qualtricsIntegrationInProgress) || (answersRes.integrationId && !qualtricsIntegrationInProgress)"
                  :loading="qualtricsIntegrationInProgress"
                  block
                  large
                  elevation="0"
                  class="integration__button mt-5"
                >
                  {{ answersRes.integrationId && !qualtricsIntegrationInProgress ? $t('steps.upload.integrated') : $t('steps.upload.integrate_confirm') }}
                </v-btn>
              </div>
            </div>
          </div>

          <!-- BRANDWATCH INTEGRATION -->
          <div v-else-if="answersInput.integration.requires_integration && isBrandwatchIntegration">
            <div class="integration__help mt-5 text-center d-flex justify-center mb-5">
              <div v-if="brandwatchIntegration.integrations.length">
                {{ $t('steps.upload.integration_description_specific.brandwatch') }}
              </div>
              <div v-else>
                <span v-html="$t('steps.upload.integration_description_specific.brandwatch_missing_account')" />
                <router-link :to="{ name: 'account', query: { tab: 'integrations' } }">
                  {{ $t('steps.upload.integration_description_specific.qualtrics_missing_account_link') }}
                </router-link>
              </div>
            </div>
            <div class="integration__links">
              <div class="integration__links__link" style="height: auto;">
                <div class="d-flex align-center justify-space-between">
                  <div class="integration__links__link__select">
                    <div class="integration__links__link__title font-weight-medium mt-5 mb-1">
                      {{ $t('steps.upload.integration_account') }}
                    </div>
                    <v-select
                      :items="brandwatchIntegration.integrations"
                      :label="answersInput.account ? '' : $t('steps.upload.integration_account')"
                      :elevation="0"
                      :value="answersInput.account"
                      @change="handleChangeIntegrationAccount"
                      :disabled="loading || qualtricsIntegrationInProgress"
                      primary
                      item-value="id"
                      item-text="name"
                      class=""
                      small
                      hide-details
                      outlined
                      dense
                    />
                  </div>
                  <div class="integration__links__link__select">
                    <div class="integration__links__link__title font-weight-medium mt-5 mb-1">
                      {{ $t('steps.upload.integration_query') }}
                    </div>
                    <v-autocomplete
                      :items="availableSurveys"
                      :label="answersInput.survey ? '' : $t('steps.upload.integration_query')"
                      :elevation="0"
                      :loading="loadingSurveys"
                      :disabled="!answersInput.account || loadingSurveys || qualtricsIntegrationInProgress"
                      :value="answersInput.survey"
                      @change="handleChangeIntegrationSurvey"
                      primary
                      item-value="id"
                      item-text="name"
                      small
                      hide-details
                      outlined
                      dense
                      multiple
                    />
                  </div>
                </div>
                <v-btn
                  @click="handleIntegrateQualtricsClick"
                  :disabled="(!validQualtricsIntegrate || qualtricsIntegrationInProgress) || (answersRes.integrationId && !qualtricsIntegrationInProgress)"
                  :loading="qualtricsIntegrationInProgress"
                  block
                  large
                  elevation="0"
                  class="integration__button mt-5"
                >
                  {{ answersRes.integrationId && !qualtricsIntegrationInProgress ? $t('steps.upload.integrated') : $t('steps.upload.integrate_confirm') }}
                </v-btn>
              </div>
            </div>
          </div>
          <!-- REVIEW INTEGRATION -->
          <div v-else>
            <div class="integration__help mt-5 text-center d-flex justify-center">
              {{
                $te(`steps.upload.integration_description_specific.${answersInput.integration.provider}`)
                  ? $t(`steps.upload.integration_description_specific.${answersInput.integration.provider}`)
                  : $t('steps.upload.integration_description')
              }}
              <helptip
                class="ml-1"
                position="bottom"
                :width="600"
              >
                <div class="font-weight-medium">
                  {{ $t('steps.upload.integration_helptip') }}
                </div>
                <div class="font-italic" v-html="$t(`steps.upload.integration_helptip_specific.${answersInput.integration.provider}`)" />
                <br><a href="https://caplena.com/docs/knowledge-base/2784zjo1mosah-integrations-in-detail" target="_blank">
                  {{ $t('steps.upload.help') }}
                </a>
              </helptip>
            </div>
            <div class="integration__help integration__help--tip text-center mb-5" v-html="$t('steps.upload.integration_tip')" />
            <notification
              v-if="answersInput.integration.provider === 'amazon'"
              type="warning"
              class="mb-2"
              :title="$t('steps.upload.amazon_integration_warning_title')"
              :text="$t('steps.upload.amazon_integration_warning_text')"
            />
            <div>
              <div class="integration__links">
                <div
                  v-for="(link, index) in answersInput.integrationLinks"
                  class="integration__links__link d-flex align-center"
                  :key="index"
                >
                  <div class="w-100 mb-2">
                    <div v-if="link.sync_status === 'complete'">
                      <div class="d-flex justify-space-between align-center">
                        <div style="width: 35%">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div class="integration__links__link__title font-weight-medium">
                                  {{ answersInput.integration.provider === 'google_places' ? $t('steps.upload.integration_address') : $t('steps.upload.integration_url') }}
                                </div>
                                <div
                                  class="integration__links__link__subtitle"
                                >
                                  {{ link.name }}
                                </div>
                              </div>
                            </template>
                            <span>
                              <div class="font-weight-medium">{{ link.name }}:</div>
                              <div>{{ link.external_id }}</div>
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="text-right">
                          <div class="integration__links__link__title font-weight-medium">
                            {{ $t('steps.upload.average_rating') }}
                          </div>
                          <div class="integration__links__link__subtitle">
                            {{ link.meta.average_rating }}
                          </div>
                        </div>
                        <div class="text-right">
                          <div class="integration__links__link__title font-weight-medium">
                            {{ $t('steps.upload.count') }}
                          </div>
                          <div class="integration__links__link__subtitle">
                            {{ link.meta.num_responses }}
                          </div>
                        </div>
                        <div class="integration__links__link__success font-weight-medium">
                          {{ $t('steps.upload.integrated') }}
                        </div>
                        <v-btn
                          class="integration__back-button ml-0"
                          icon
                          outlined
                          tile
                          color="secondary"
                          @click="handleIntegrationLinkDeleteClick(index)"
                        >
                          <v-icon size="24">
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div v-else-if="link.sync_status === 'failed'">
                      <div class="integration__links__title font-weight-medium mb-1">
                        {{ answersInput.integration.provider === 'google_places' ? $t('steps.upload.integration_address') : $t('steps.upload.integration_url') }}
                      </div>
                      <div class="d-flex align-center position-relative">
                        <integration-text-input
                          class="integration__links__link__input--failed"
                          :value="answersInput.integrationLinks[index].external_id"
                          :ref="`integration-link-${index}`"
                          :provider="answersInput.integration.provider"
                          :disabled="loading"
                          :placeholder="$t(`steps.upload.integration_specific_format.${answersInput.integration.provider}`)"
                          @input="handleIntegrationLinkInput(index, $event.target.value)"
                        />
                        <div class="integration__links__link__error">
                          {{ integrationLinkErrorTranslation(answersInput.integrationLinks[index].sync_error) }}
                        </div>
                        <v-btn
                          class="integration__back-button ml-3"
                          icon
                          outlined
                          tile
                          color="secondary"
                          @click="handleIntegrationLinkDeleteClick(index)"
                        >
                          <v-icon size="24">
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div
                      v-else-if="link.sync_status === 'in_progress'"
                      class="d-flex align-center justify-space-between"
                    >
                      <div style="width: 80%">
                        <div class="integration__links__link__title font-weight-medium mb-1">
                          {{ answersInput.integration.provider === 'google_places' ? $t('steps.upload.integration_address') : $t('steps.upload.integration_url') }}
                        </div>
                        <div class="integration__links__link__subtitle">
                          {{ link.external_id }}
                        </div>
                      </div>
                      <v-progress-circular
                        :size="40"
                        color="primary"
                        :value="link.progress * 100"
                      />
                    </div>
                    <div v-else>
                      <div class="integration__links__title font-weight-medium mb-1">
                        {{ answersInput.integration.provider === 'google_places' ? $t('steps.upload.integration_address') : $t('steps.upload.integration_url') }}
                      </div>
                      <div class="d-flex align-center position-relative">
                        <integration-text-input :ref="`integration-link-${index}`"
                                                :class="answersInput.integrationLinks[index].duplicate_error && 'integration__links__link__input--failed'"
                                                :value="answersInput.integrationLinks[index].external_id"
                                                :provider="answersInput.integration.provider"
                                                :disabled="loading"
                                                :placeholder="$t(`steps.upload.integration_specific_format.${answersInput.integration.provider}`)"
                                                @input="handleIntegrationLinkInput(index, $event.target.value)"
                                                @paste="arr => handleIntegrationLinkPaste(arr)"
                        />
                        <div class="integration__links__link__error">
                          {{ integrationLinkErrorTranslation(answersInput.integrationLinks[index].duplicate_error) }}
                        </div>
                        <v-btn
                          class="integration__back-button ml-3"
                          icon
                          outlined
                          tile
                          color="secondary"
                          @click="handleIntegrationLinkDeleteClick(index)"
                        >
                          <v-icon size="24">
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <v-btn
                    @click="handleIntegrationLinkAdd"
                    :disabled="answersInput.integrationLinks.length > answersInput.integration.max_items - 1 || integrationInProgress"
                    class="integration__links__add"
                    color="primary"
                    text
                  >
                    {{ $t('steps.upload.integration_add') }}
                  </v-btn>
                  <v-btn
                    @click="handleIntegrateClick"
                    :disabled="!validIntegrate || integrationInProgress"
                    block
                    large
                    elevation="0"
                    class="integration__button mt-5"
                  >
                    {{ $t('steps.upload.integrate_confirm') }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isInputTab && !loading" class="upload-errors mb-0">
          <notification
            v-if="answersRes.error"
            type="error"
            text
            class="mb-0 mt-5"
            border="left"
            dense
          >
            <span v-html="displayTextOrTranslation(answersRes.error, {
              nOld: replace ? project.n_rows : projectColumnsLength,
              nNew: replace ? answersRes.n_rows : answersRes.column_metas.length,
              max_answers_per_survey: MAX_ANSWERS_PER_SURVEY,
              batch_video_link: UPLOAD_BATCHES_VIDEO_LINK
            })"
            />
          </notification>
          <notification
            v-if="answersRes.warn && !Array.isArray(answersRes.warn)"
            type="warning"
            border="left"
            class="mb-0 mt-3 default-bs"
            text
            dense
          >
            <span v-html="displayTextOrTranslation(answersRes.warn)" />
            <span v-if="answersRes.mismatchedColumnNames && answersRes.mismatchedColumnNames.length" v-html="$t('steps.append.auxiliary_columns_missmatched', { columns: answersRes.mismatchedColumnNames.join(', ')})" />
          </notification>
          <notification
            v-else-if="answersRes.warn && Array.isArray(answersRes.warn)"
            v-for="(err, index) in answersRes.warn"
            :key="index"
            type="warning"
            border="left"
            class="mb-0 mt-3 default-bs"
            text
            dense
          >
            <span v-html="displayTextOrTranslation(err)" />
          </notification>
          <notification
            v-if="fewRowsWarning"
            type="info"
            border="left"
            text
            class="mb-0 mt-5"
            dense
          >
            <span v-html="$t('steps.upload.warning_few_rows')" />
          </notification>
          <notification
            v-if="hasInvalidIntegration && canContinueDespiteTimeout"
            type="warning"
            border="left"
            class="mb-0 mt-3 default-bs"
            text
            dense
          >
            <span v-html="$t('steps.upload.continue_despite_timeout_warning')" />
          </notification>
          <!-- <v-alert
            v-if="hasInvalidIntegration && hasValidIntegration && !hasInvalidLinkInIntegration"
            type="info"
            border="left"
            text
            class="mb-0 mt-5"
            dense
          >
            <span v-html="$t('steps.upload.integration_partial_fail')" />
          </v-alert> -->
          <notification
            v-if="answersRes.type === 'spss' && !user.subscription.features.advanced_filetypes"
            class="mb-0 mt-5"
            type="warning"
            border="left"
            text
            dense
          >
            <div class="d-flex align-center">
              <span v-html="$t('steps.upload.filetype_not_in_subscription', { type: answersRes.type.toUpperCase() })" />
              <v-spacer />
              <v-btn
                v-if="$root.isRootAccount"
                color="primary"
                outlined
                :to="{ name: 'account', query: { tab: 'subscription' } }"
              >
                {{ $t('feature_not_included.btn') }}
              </v-btn>
              <div v-else v-html="$t('feature_not_included.contact_admin', { email: user.organization.root_user_email })" />
            </div>
          </notification>
        </div>
      </div>
    </div>
    <ZapierIntegration v-model="modalsShown.zapier" />
  </div>
</template>

<script>

import Vuex from 'vuex'
import FileUpload from 'vue-upload-component'

import { ACCEPT_UPLOAD_FILETYPES, MAX_ANSWERS_PER_SURVEY } from '@/settings/overridable'
import { DEFAULT_INTEGRATION_LINK } from '@/settings/constants'
import { HELP_RESOURCE_UPLOAD_BATCHES_VIDEO } from '@/settings/help-resources'
import { getIntegrationImage } from '@/utils/funcs'
import ApiTaskMixin from '@/mixins/apitask'
import IntegrationTextInput from './IntegrationTextInput.vue'
import ZapierIntegration from '@/components/modals/ZapierIntegration.vue'

export default {
  codit: true,
  name: 'StepUpload',
  components: {
    FileUpload,
    IntegrationTextInput,
    ZapierIntegration
  },

  mixins: [ApiTaskMixin],

  props: {
    append: { type: Boolean, default: false },
    replace: { type: Boolean, default: false }
  },

  data () {
    return {
      acceptFileTypes: ACCEPT_UPLOAD_FILETYPES,
      appendWarning: '',
      availableIntegrations: [],
      loadingIntegrations: false,
      availableSurveys: [],
      loadingSurveys: false,
      failed: false,

      modalsShown: {
        zapier: false
      }
    }
  },

  computed: {
    MAX_ANSWERS_PER_SURVEY () {
      return MAX_ANSWERS_PER_SURVEY
    },

    UPLOAD_BATCHES_VIDEO_LINK () {
      return `<a href="${HELP_RESOURCE_UPLOAD_BATCHES_VIDEO}" target="_blank">${this.$t('actions.click_here')}</a>`
    },

    /**
     * Returns the qualtrics integration object from available integrations
     * @return {Object}
     */
    qualtricsIntegration () {
      return _.find(this.availableIntegrations, ({ provider }) => provider === 'qualtrics')
    },

    /**
     * Returns the brandwatch integration object from available integrations
     * @return {Object}
     */
    brandwatchIntegration () {
      return _.find(this.availableIntegrations, ({ provider }) => provider === 'brandwatch')
    },

    /**
     * Hardcoded list of integration providers that are currently disabled due to issues
     */
    disabledIntegrations () {
      return []
    },

    /**
     * Returns Integrations that require custom account setup
     * @return {Array}
     */
    availableCustomIntegrations () {
      return [
        ..._.filter(this.availableIntegrations, ({ requires_integration: requiresIntegration }) => requiresIntegration),
        {
          name: 'Zapier',
          provider: 'zapier'
        }
      ]
    },

    /**
     * Returns integrations that don't require custom account setup
     * @return {Array}
     */
    availableReviewIntegrations () {
      return _.filter(this.availableIntegrations, ({ requires_integration: requiresIntegration }) => !requiresIntegration)
    },

    /**
     * Returns appropriate page subtitle translation
     * @return {String}
     */
    pageAndTabSubtitle () {
      if (this.append) {
        return this.$t('steps.append.subtitle')
      }

      if (this.replace) {
        return this.$t('steps.replace.subtitle')
      }

      if (this.isFileTab) {
        return this.$t('steps.upload.subtitle')
      }

      if (this.isInputTab) {
        return this.$t('steps.upload.input_subtitle')
      }

      return this.$t('steps.upload.integration_subtitle')
    },

    /**
     * Checks if upload method is file upload
     * @return {Boolean}
     */
    isFileTab () {
      return this.answersInput.tab === 'file'
    },

    /**
     * Checks if upload method is direct input
     * @return {Boolean}
     */
    isInputTab () {
      return this.answersInput.tab === 'input'
    },

    /**
     * Checks if upload method is integration
     * @return {Boolean}
     */
    isIntegrationTab () {
      return this.answersInput.tab === 'integration'
    },

    /**
     * List of available integrations
     * @return {Array}
     */
    // availableIntegrations () {
    //   return AVAILABLE_INTEGRATIONS
    // },

    /**
     * Returns length of project columns
     * @return {Boolean}
     */
    projectColumnsLength () {
      if (this.project && this.project.auxiliary_column_names && this.project.questions) {
        return this.project.auxiliary_column_names.length + this.project.questions.length
      }

      return 0
    },

    /** Determines if warning should be shown */
    fewRowsWarning () {
      return this.answersInput.file.length && this.nRows < 100 && !this.append && !this.replace && !this.answersRes.error
    },

    /** Returns the file format
     * @return {String}
     */
    fileFormat () {
      const format = this.answersInput.file[0].name.split('.').pop()

      return format
    },

    /** Returns the file name
     * @return {String}
     */
    fileName () {
      return _.capitalize(this.answersInput.file[0].name)
    },

    /** Returns the file size
     * @return {String}
     */
    fileSize () {
      return _.round(this.answersInput.file[0].size / 1024, 2)
    },

    /** If the input tab should be shown
     * @return {Boolean}
     */
    showInputTab () {
      // Only show input option if this is a new project or the existing project has no auxiliary columns -->
      return !this.append && !this.replace
    },

    ...Vuex.mapState({
      user: 'user',
      loading: state => state.upload.loading.active,
      answersInput: state => state.upload.answersInput,
      answersRes: state => state.upload.answersRes,
      project: state => state.upload.project
    }),

    ...Vuex.mapGetters([
      'nRows',
      'validAppendUpload',
      'validIntegrate',
      'validQualtricsIntegrate',
      'integrationInProgress',
      'hasInvalidIntegration',
      'hasValidIntegration',
      'hasInvalidLinkInIntegration',
      'canContinueDespiteTimeout',
      'qualtricsIntegrationInProgress',
      'isBrandwatchIntegration'
    ])
  },

  mounted () {
    this.$store.commit('setAnswersError', null)
    this.$store.commit('setAnswersWarn', null)
    this.$store.commit('setUploadedFileId', null)
  },

  created () {
    this.fetchAvailableIntegrations()
  },

  methods: {
    /**
     * Fetches available integrations
     */
    fetchAvailableIntegrations () {
      this.loadingIntegrations = true
      this.failed = false

      api.get('/api/data-sources/providers')
        .then(res => {
          this.loadingIntegrations = false
          this.availableIntegrations = res.data

          if (this.$route.query.tab) {
            this.handleInputTabClick(this.$route.query.tab)
            const integrations = {
              qualtrics: this.qualtricsIntegration,
              brandwatch: this.brandwatchIntegration
            }

            this.handleIntegrationClick(integrations[this.$route.query.integrationType] || integrations.qualtrics)
          }
        })
        .catch(() => {
          this.loadingIntegrations = false
          this.failed = true
        })
    },

    /**
     * Returns translation of error
     * @return {String}
    */
    integrationLinkErrorTranslation (error) {
      switch (error) {
        case undefined:
          return ''
        case 'invalid_item':
          return this.$t('steps.upload.integration_invalid_link_error')
        case 'upload_failed':
          return this.$t('steps.upload.integration_link_upload_failed')
        case 'no_responses':
          return this.$t('steps.upload.integration_link_no_responses')
        case 'duplicate_error':
          return this.$t('steps.upload.integration_link_duplicate_error')
        case 'timeout':
          return this.$t('steps.upload.integration_link_timeout_error')
        // case 'none':
        //   return false
        // case 'unknown':
        //   return this.$t('steps.upload.integration_link_error')
        default:
          return this.$t('steps.upload.integration_link_error')
      }
    },

    /**
     * Integrate answers
    */
    handleIntegrateClick () {
      this.$store.dispatch('integrateAnswers')
        .then(res => {
          if (res.status >= 400) {
            this.$store.commit('setIntegrationLinks', this.answersInput.integrationLinks.map(link => {
              // eslint-disable-next-line
            const { external_id, sync_status: syncStatus } = link

              return {
                ...link,
                external_id,
                sync_status: 'failed'
              }
            }))

            this.$store.commit('setLoading', { key: 'active', value: false })
            this.$store.commit('setAnswersError', res.data.items[0][0] || 'steps.organize.default')

            return
          }

          this.$store.commit('setIntegrationId', res.data.id)
          this.$store.commit('setIntegrationLinks', _.merge(_.cloneDeep(this.answersInput.integrationLinks), res.data.items))
          this.trackIntegrateUpdates(res.data.id, this.updateCb)
            .catch(err => {
              this.$store.commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
              throw err
            })
        })
        .catch(err => {
          this.$store.commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
          throw err
        })
    },

    /**
     * Integrate qualtrics survey
     */
    handleIntegrateQualtricsClick () {
      this.$store.dispatch('integrateQualtricsAnswers')
        .then(res => {
          console.log(res, 'res')

          this.$store.commit('setIntegrationId', res.data.id)

          this.trackIntegrateUpdates(res.data.id, this.updateQualtricsCb)
            .catch(err => {
              this.$store.commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
              throw err
            })
        })
        .catch(err => {
          this.$store.commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
          throw err
        })
    },

    /**
     * Update the selected integrations account
     */
    handleChangeIntegrationAccount (account) {
      this.$store.commit('setAccount', account)
      this.resetIntegrationId()

      this.fetchSurveysForAccount()
    },

    /**
     * Fetches the Qualtrics or Branchwatch survey for the given account
     */
    fetchSurveysForAccount () {
      this.loadingSurveys = true

      this.$store.commit('setSurvey', null)
      api.get(`/api/integrations/${this.answersInput.account}/items`)
        .then(res => {
          this.loadingSurveys = false
          this.availableSurveys = res.data
        })
        .catch(() => {
          this.loadingSurveys = false
          this.$store.commit('setLoading', { key: 'failed', value: 'steps.settings.error_fetching_surveys' })
        })
    },

    /**
     * Update the selected Qualtrics or Brandwatch survey
     */
    handleChangeIntegrationSurvey (survey) {
      this.resetIntegrationId()

      this.$store.commit('setSurvey', survey)
    },

    /**
     * Reset currently setup integration
     */
    resetIntegrationId () {
      this.$store.commit('setIntegrationId', null)
      this.$store.commit('setAnswersError', null)
      this.$store.commit('setAnswersWarn', null)
    },

    /**
     * Update function for integration websocket
    */
    updateCb (res) {
      this.$store.commit('setIntegrationLinks', _.merge(_.cloneDeep(this.answersInput.integrationLinks), res.items))
    },

    /**
     * Update function for integration websocket
    */
    updateQualtricsCb (res) {
      if (res.sync_status === 'complete') {
        this.$store.commit('setIntegrationLinks', res.items)
        this.$emit('next')
      }

      if (res.sync_status === 'failed') {
        this.$store.commit('setLoading', { key: 'qualtricsIntegrationActive', value: false })
        this.$store.commit('setLoading', { key: 'failed', value: 'steps.settings.error_save_project' })
      }
    },

    /**
     * Get integrations logo
     */
    integrationImage (path) {
      return getIntegrationImage(path)
    },

    /**
     * A hack to determine if a translation or text should be shown directly,
     * reason behind this is that errors are displayed from both, frontend and backend (which already comes with translation)
     * and would cause missing translation errors in production
     */
    displayTextOrTranslation (string, params = {}) {
      // if (this.$t(string, params) === string) {
      //   return string
      // }

      if (string.includes(' ')) {
        return this.$escapeHtml(string)
      }

      return this.$t(string, params)
    },

    /**
     * Delete the selected integration link
     */
    handleIntegrationLinkDeleteClick (idx) {
      const newLinks = _.clone(this.answersInput.integrationLinks)
      newLinks.splice(idx, 1)

      this.$store.commit('setIntegrationLinks', newLinks.length ? newLinks : [DEFAULT_INTEGRATION_LINK])
    },

    /**
     * Update selected integration link
     */
    handleIntegrationLinkInput (idx, input) {
      const newLinks = _.clone(this.answersInput.integrationLinks)
      newLinks[idx] = {
        ...newLinks[idx],
        external_id: input,
        duplicate_error: _.find(newLinks, link => link.external_id === input) ? 'duplicate_error' : undefined
      }

      this.$store.commit('setIntegrationLinks', newLinks)
    },

    /**
     * Add another integration link
     */
    handleIntegrationLinkAdd () {
      this.$store.commit('setIntegrationLinks', [...this.answersInput.integrationLinks, DEFAULT_INTEGRATION_LINK])
    },

    /**
     * Handles paste event on integration text area
     */
    handleIntegrationLinkPaste ({ data, event }) {
      const inputHasValue = !!event.target.value
      const inputDifference = inputHasValue ? 0 : 1
      const availableInputs = this.answersInput.integration.max_items - this.answersInput.integrationLinks.length + inputDifference

      if (data.length) {
        let newValues = _.uniq(data).map(name => ({
          ...DEFAULT_INTEGRATION_LINK,
          external_id: name
        }))

        if (newValues.length > availableInputs) {
          newValues.length = availableInputs
        }

        this.$store.commit(
          'setIntegrationLinks',
          [
            ...this.answersInput.integrationLinks.filter(({ external_id: oldValue }) => oldValue && !newValues.includes(oldValue)),
            ...newValues
          ]
        )
      } else {
        this.handleIntegrationLinkAdd()
      }

      this.$nextTick(() => this.$refs[`integration-link-${this.answersInput.integrationLinks.length - 1}`][0].focus())
    },

    /**
     * Update selected integration via store
     */
    handleIntegrationClick (integration) {
      if (
        integration?.provider === 'zapier'
      ) {
        this.modalsShown.zapier = true
        return
      }

      this.$store.commit('setIntegration', integration)
      this.$store.commit('setIntegrationLinks', [DEFAULT_INTEGRATION_LINK])

      if (!integration) {
        this.$store.commit('setAccount', null)
        this.$store.commit('setSurvey', null)

        this.resetIntegrationId()
      }
    },

    /**
     * Update answers input via vuex upload store
     */
    handleAnswersInputChange (e) {
      this.$store.commit('setAnswersInput', e.target.value)
    },

    /**
     * Update input method
     */
    handleInputTabClick (value) {
      if (this.answersInput.tab !== value) {
        this.$store.commit('setInputTab', value)
      }
    },

    /* Update file */
    updateFile (file) {
      this.$store.commit('setFile', file)
      this.$store.dispatch('uploadAnswers', () => {
        if (!this.fewRowsWarning && !this.answersRes.warn.length) this.$emit('next')
      })
    },

    /* Removes currently selected file and resets warning / error fields */
    removeFile () {
      this.$store.commit('setAnswersWarn', null)
      this.$store.commit('setAnswersError', null)
      this.$store.commit('setFile', [])
      this.$store.commit('setSheetName', null)
    },

    ...Vuex.mapMutations([
      'setFile',
      'setAnswersWarn',
      'setAnswersError'
    ])
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />