<template>
  <span>
    {{ displayedText }}
    <a class="more-less-btn" @click="toggle" v-if="isTooLong">
      [{{ expanded ? lessLabel || this.$t('actions.less') : moreLabel || this.$t('actions.more') }}]
    </a>
  </span>
</template>

<script>
export default {
  codit: true,
  name: 'Truncate',

  props: {
    /**
     * The text which is to be potentially truncated
     */
    text: { type: String, default: '' },

    /**
     * Any prefix that will be shown before the text and is not subject to truncation
     */
    prefix: { type: String, default: '' },
    /**
     * Any postfix that will be shown after the text and is not subject to truncation
     */
    postfix: { type: String, default: '' },

    /**
     * The label of the 'more' link. If omitted, a default is taken
     */
    moreLabel: { type: String, default: '' },
    /**
     * The label of the 'less' link. If omitted, a default is taken
     */
    lessLabel: { type: String, default: '' },

    /**
     * The maximum length a text may have, after which it is subject to truncation
     */
    maxLength: { type: Number, default: 50 }
  },

  data () {
    return {
      expanded: false
    }
  },

  computed: {
    /**
     * Return true if the text is longer than the maxLength
     * @return {Boolean}
     */
    isTooLong () { return this.text.length > this.maxLength },

    /**
     * The text to be shown consisting of the prefix, the potentially truncated text and the postfix
     * @return {String} The text to be displayed
     */
    displayedText () {
      let text = this.text
      if (!this.expanded && this.isTooLong) text = text.slice(0, this.maxLength) + '...'
      return this.prefix + text + this.postfix
    }
  },

  methods: {
    /**
     * Toggle the expansion state
     */
    toggle () { this.expanded = !this.expanded }
  }
}
</script>

<style lang=scss>
.more-less-btn {
  font-weight: normal;
  font-size: .9em;
}
</style>
