<template>
  <div v-if="loadingFailed" class="h-100">
    <error-state
      title="Data fetching error"
      :text="$t('error', {'step': $t('loading.while')})"
      :action="$t('actions.retry')"
      :action-click="reloadRows"
    />
  </div>
  <DynamicScroller
    v-else-if="readyToRender && dummyRange.length"
    :items="dummyRange"
    :min-item-size="70"
    :buffer="200"
    class="coding__column__rows"
    ref="row-list"
    emit-update
    @update="handleListScrollDebounce"
  >
    <template v-slot="{ item: rowIndex, active }">
      <DynamicScrollerItem
        :index="rowIndex"
        :item="rowIndex"
        :active="active"
      >
        <Row
          :id="id"
          :ref="rowsAvailable[rowIndex] ? `row-${rowsAvailable[rowIndex].id}` : null"
          :row-index="rowIndex"
          :coding-view="codingView"
          :active="active"
          :row="rowsAvailable[rowIndex]"
          :multiple-select-held="multipleSelectHeld"
          :is-row-active="rowsActive[rowIndex]"
          :is-row-checked="rowsChecked[rowIndex]"
          :rows-are-selectable="rowsAreSelectable"
          :translations-enabled="translationsEnabled"
          :handle-show-auxiliary-selector="handleShowAuxiliarySelector"
          :wizard-view="wizardView"
        />
      </DynamicScrollerItem>
    </template>
    <template #before>
      <div v-if="wizardView && wizardUpdateNotification">
        <notification
          :title="$t('topics_change_title')"
          :text="$t('topics_change_text')"
          :action="$t('actions.refresh')"
          :action-click="() => handleWizardRefetch()"
          class-name="wizard__update-notification"
        />
      </div>
    </template>
    <template #after>
      <v-menu
        v-model="auxiliarySelector.shown"
        :position-x="auxiliarySelector.x"
        :position-y="auxiliarySelector.y"
        :close-on-content-click="false"
        :rounded="'lg'"
        offset-y
        absolute
      >
        <div
          v-if="auxiliarySelector.idx !== null"
          class="filters__list filters__list--values filters__list--columns"
        >
          <v-text-field
            v-model="auxiliarySelector.search"
            single-line
            class="search-bar__search search-bar__search--borderless w-100"
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            :elevation="0"
            :label="$t('filters.auxilary_search')"
            hide-details
          />
          <div class="filters__list__separator" />
          <div
            class="filters__list__padding pt-1 pb-1"
          >
            <div
              class="filters__list__check d-flex align-center"
              v-for="(col, idx) in codingView ? auxiliarySelector.idx !== null && rowsAvailable[auxiliarySelector.idx] && filterAuxBySearch(rowsAvailable[auxiliarySelector.idx].otherColumns) : filterAuxBySearch(additionalColumns)"
              :key="idx"
            >
              <v-checkbox
                :input-value="auxiliaryColumnsShownList[idxOfColumn(col.ref)]"
                @change="toggleShownAuxiliaryColumn(idxOfColumn(col.ref))"
                primary
                hide-details
                :ripple="false"
                color="green"
                off-icon="mdi-checkbox-blank"
                flat
                :label="codingView ? getOtherColumnLabel(col.ref) : col.name"
              />
            </div>
          </div>
        </div>
      </v-menu>
    </template>
  </DynamicScroller>
  <div
    v-else-if="!readyToRender || (!dummyRange.length && rowData.loading)"
    class="coding__column__rows coding__column__rows--loading"
  >
    <div
      v-for="item in [0,1,2,3,4,5,6,7,8,9]"
      :key="item"
    >
      <div
        class="coding__row coding__row__loading d-flex"
        :class="!codingView && 'pl-4'"
      >
        <div v-if="codingView" class="coding__row__index mr-1" />
        <div class="coding__row__index" />
        <div class="coding__row__sentiment" />
        <div class="w-100">
          <div class="coding__row__name" />
          <div class="d-flex coding__row__topics">
            <div class="coding__row__topic" />
            <div class="coding__row__topic coding__row__topic--2" />
          </div>
          <div class="d-flex">
            <div class="coding__row__auxiliaries mr-2" />
            <div class="coding__row__index mr-0" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="coding__column__rows">
    <empty-state-v2
      :title="$t('no_results')"
      :text="$t('no_results_subtitle')"
      :action="resetButton ? $t('clear_search') : null"
      :action-click="resetButton ? clearResults : null"
    />
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import Vuex from 'vuex'
import Row from '@/components/coding/Row'
import { isElementVisible } from '@/utils/funcs'
import { DEFAULT_ROW_LIMIT } from '@/settings/constants'
import { createQueryStringFromObj } from '@/utils/filters'
import { mapGettersWithKey, getterWithKeyDeconstructed, getterDeconstructed, mapGettersSilently } from '@/utils/vuex.js'

export default {
  codit: true,
  name: 'RowBrowser',
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    Row
  },
  mixins: [],

  props: {
    id: { type: String, default: '' },
    codingView: { type: Boolean, default: true },
    data: { type: Object, default: () => {} },
    loadData: { type: Function, default: () => ({}) },
    resetData: { type: Function, default: () => ({}) },
    translationsEnabled: { type: Boolean, default: true },
    blockedByModals: { type: Boolean, default: false },
    resetButton: { type: Boolean, default: true },
    wizardView: { type: Boolean, default: false },
    storeName: { type: String, default: 'coding' },
    init: { type: Function, default: () => ({}) }
  },

  data () {
    return {
      destroyed: false,

      currentPage: 1,
      handleListScrollDebounce: _.debounce(this.handleListScroll, 200),
      prevStartIndex: null,
      auxiliarySelector: {
        x: null,
        y: null,
        shown: false,
        idx: null,
        search: ''
      },
      multipleSelectHeld: false
    }
  },

  computed: {
    ...Vuex.mapState({
      project: state => state.coding?.project,
      focusMode: state => state.coding?.focusMode,
      codingDrawerDropdownOpen: state => state.coding?.codingDrawerDropdownOpen,
      editable: state => state.coding?.editable,
      newTopicDialog: state => state.coding?.newTopicDialog,
      allRowsSelected: state => state.coding?.allRowsSelected,
      topics: state => state.wizard?.topics,
      activeTab: state => state.wizard?.activeTab,
      wizardUpdateNotification: state => state.wizard?.wizardUpdateNotification
    }),

    ...mapGettersSilently([
      'activeRow',
      'codingDrawerOpen',
      'bulkAssign',
      'activeRow'
    ]),

    ...mapGettersWithKey({
      preventLoadFromListUpdate: 'verbatimManager/preventLoadFromListUpdate',
      dummyRows: 'verbatimManager/dummyRows',
      rowData: 'verbatimManager/rowData',
      rowsPerPage: 'verbatimManager/rowsPerPage',
      columnsShown: 'verbatimManager/columnsShown',
      rowsQueryString: 'verbatimManager/rowsQueryString'
    })(function () { return this.id }),

    ...getterWithKeyDeconstructed('verbatimManager/state')(function () { return this.id })(['loaded', 'loadingFailed']),

    ...getterDeconstructed('metaManager/overall')(function () { return `${this.id}` })({
      additionalColumns: 'auxiliary_column_metas'
    }),

    isInitialized: {
      get () {
        return this.$store.state[this.storeName].isInitialized
      }
    },

    dummyRange () {
      if (this.codingView) return this.dummyRows
      return _.range(this.rowData.meta.count)
    },

    rowsAvailable () {
      let rows = {}
      for (let index = Math.max(0, (this.currentPage - 1) * DEFAULT_ROW_LIMIT); index < Math.min(this.currentPage + 1, this.dummyRange.length) * DEFAULT_ROW_LIMIT; index++) {
        rows[index] = this.getRow(index)
      }
      return rows
    },

    rowsActive () {
      let active = {}
      let activeRowIds
      if (this.bulkAssign) activeRowIds = new Set(_.isArray(this.activeRow) ? this.activeRow : [])
      _.each(this.rowsAvailable, (row, index) => {
        let val = false
        if (row === undefined) val = false
        else if (this.allRowsSelected) val = true
        else if (this.bulkAssign) val = activeRowIds.has(row.id)
        else val = this.activeRow && row.id === this.activeRow.id
        active[index] = val
      })
      return active
    },

    rowsChecked () {
      let checked = {}
      let activeRowIds
      if (this.bulkAssign) activeRowIds = new Set(_.isArray(this.activeRow) ? this.activeRow : [])
      _.each(this.rowsAvailable, (row, index) => {
        let val = false
        if (row === undefined) val = false
        else if (this.allRowsSelected) val = true
        else if (this.bulkAssign) val = activeRowIds.has(row.id)
        checked[index] = val
      })

      return checked
    },

    activeRowIndex () {
      return this.activeRow ? Number(_.findKey(this.rowsAvailable, { id: this.activeRow.id })) : null
    },

    readyToRender () {
      if (this.wizardView) return this.isInitialized
      if (this.codingView) return this.loaded && this.isInitialized
      return true
    },

    auxiliaryColumns () {
      return this.codingView ? this.$store.getters.auxiliaryColumns : this.additionalColumns
    },

    auxiliaryColumnsShownList () {
      return _.map(this.auxiliaryColumns, (col, index) => {
        if (this.columnsShown.includes(index)) return true
        return false
      })
    },

    /**
     * If the rows can be selected
    */
    rowsAreSelectable () {
      return ((this.multipleSelectHeld && !this.allRowsSelected) || _.isArray(this.activeRow)) && this.codingView
    }
  },

  watch: {
  /**
   * When filters are changed, the scroller triggers an update
   * but for searching of text it doesn't happen.
   * So, it's a trick to use the same event model
   * while filtering data
   * @param {any} val
   * @returns {any}
   */
    preventLoadFromListUpdate (val) {
      if (!val) { return }
      this.handleListScroll(0, 0)
    },

    rowsQueryString () {
      if (this.$refs['row-list']) this.$refs['row-list'].scrollToItem(0)
    },

    activeRowIndex (val, oldVal) {
      if (val !== oldVal && val !== null && !this.bulkAssign && !this.focusMode) {
        this.$store.commit('setActiveRowIndex', val)
        this.scrollListIfNeeded()
      }
    },

    activeTab (val, oldVal) {
      if (this.wizardView && val !== oldVal) {
        if (val) {
          if (!this.isInitialized) {
            this.init()
            this.$store.commit('setIsInitialized', true)
          } else {
            this.$store.commit('setWizardUpdateNotification', false)
            this.resetData()
            this.loadData({ page: 1 })
          }
        } else {
          this.$refs['row-list']?.scrollToItem(0)
          this.resetData()
          this.$store.commit('setWizardUpdateNotification', false)
        }
      }
    }
  },

  mounted () {
    if (this.codingView) {
      window.addEventListener('copy', this.copy)
      window.addEventListener('keydown', this.keydown)
      window.addEventListener('keyup', this.keyup)
      window.addEventListener('beforeunload', this.doDestroy)
    }

    if (this.wizardView) {
      this.init()
      this.$store.commit('setIsInitialized', true)
    }
  },

  beforeDestroy () {
    this.destroyed = true
    if (this.codingView) {
      this.doDestroy()
    }
  },

  methods: {
    getRow (index) {
      if (this.codingView) return this.$store.getters.getRowByIndex(index)
      else {
        const page = _.floor(index / DEFAULT_ROW_LIMIT) + 1
        if (page in this.data) return this.data[page][index % DEFAULT_ROW_LIMIT]
        else return undefined
      }
    },

    handleWizardRefetch () {
      this.$refs['row-list']?.scrollToItem(0)
      this.$store.commit('setWizardUpdateNotification', false)
      this.resetData()
      this.loadData({ page: 1 })
    },

    clearResults () {
      this.$emit('clear-search')
    },

    /**
     * Reload rows upon failure
     */
    reloadRows () {
      this.$store.dispatch(
        'verbatimManager/loadingFailedReset',
        { entityId: this.id },
        { root: true }
      )

      _.each(this.rowsPerPage, (value, page) => {
        this.$store.dispatch(
          'verbatimManager/loadRows',
          { id: this.id, scrolledPage: page, queryString: createQueryStringFromObj(this.$router.currentRoute.query) },
          { root: true }
        )
      })
    },

    /**
     * Handles auxiliary filters search
     */
    filterAuxBySearch (otherColumns) {
      if (this.auxiliarySelector.search.trim().length) {
        return otherColumns.filter(col => {
          const columnLabel = this.codingView ? this.getOtherColumnLabel(col.ref) : col.name
          return _.includes(_.lowerCase(columnLabel), _.lowerCase(this.auxiliarySelector.search.toLowerCase()))
        })
      }

      return otherColumns
    },

    idxOfColumn (ref) {
      return _.findIndex(this.codingView ? this.rowsAvailable[this.auxiliarySelector.idx].otherColumns : this.additionalColumns, col => col.ref === ref)
    },

    /**
     * Show row browser's auxiliary selector v-menu. Responsible for selecting which auxiliary columns should be shown
     */
    handleShowAuxiliarySelector (e, idx) {
      e.preventDefault()

      if (this.auxiliarySelector.shown) {
        this.auxiliarySelector.shown = false
        this.auxiliarySelector.idx = null
        return
      }
      this.auxiliarySelector.x = e.clientX + 10
      this.auxiliarySelector.y = e.clientY
      this.auxiliarySelector.idx = idx
      this.auxiliarySelector.shown = true
    },

    /**
     * Debounced callfrom from virtual list scroll update
     */
    handleListScroll (startIndex, endIndex) {
      if (this.destroyed) return
      this.currentPage = _.floor(startIndex / DEFAULT_ROW_LIMIT) + 1
      const start = startIndex || 1
      const end = startIndex + DEFAULT_ROW_LIMIT / 2 - 1

      this.prevStartIndex = this.rowData.pagination?.startIndex

      this.$store.commit('verbatimManager/setPagination', {
        id: this.id,
        startIndex: start,
        endIndex: this.rowData.meta.count < end ? this.rowData.meta.count : end,
        displayStartIndex: start < 5 ? start : start + 3,
        displayEndIndex: endIndex
      })

      // If we're in the focus mode, loading is not handled by this method
      if (this.focusMode) return
      // Make sure at least two adjacient pages are loaded all the time
      let pagesToLoad = [this.currentPage]
      const adjacientPageDirection = startIndex % DEFAULT_ROW_LIMIT < (DEFAULT_ROW_LIMIT / 2) ? -1 : 1
      const adjacientPage = this.currentPage + adjacientPageDirection
      if (adjacientPage > 0) pagesToLoad.push(adjacientPage)

      pagesToLoad.forEach(page => {
        if (this.codingView ? !(page in this.rowsPerPage) && !this.preventLoadFromListUpdate : !(page in this.data)) {
          if (this.codingView) {
            this.$store.dispatch(
              'verbatimManager/loadRows',
              { id: this.id, scrolledPage: page, queryString: createQueryStringFromObj(this.$router.currentRoute.query) },
              { root: true }
            )
          } else {
            this.loadData({ page })
          }
        } else {
          this.$store.dispatch(
            'verbatimManager/setPreventLoadFromListUpdate',
            { entityId: this.id, value: false },
            { root: true }
          )
        }
      })
    },

    /**
     * Programatically scroll list if needed on keyboard control
     */
    scrollListIfNeeded () {
      const selectedRowEl = this.$refs[`row-${this.activeRow.id}`]?.$el

      if (!selectedRowEl) return

      if (!isElementVisible(selectedRowEl, this.$refs['row-list'].$el)) {
        this.$refs['row-list'].scrollToItem(this.activeRowIndex)
      }
    },

    /**
     * Method which is called before the component is destroyed
     * Note: The store might already be gone at this point, so be careful which values to access
     */
    doDestroy () {
      window.removeEventListener('keydown', this.keydown)
      window.removeEventListener('beforeunload', this.doDestroy)
      window.removeEventListener('keyup', this.keyup)
      window.removeEventListener('copy', this.copy)
    },

    /**
     * Keyboard keyup actions for row browser
     */
    keyup (e) {
      this.multipleSelectHeld = false
    },

    /**
     * Keyboard keydown actions for row browser
     */
    keydown (e) {
      if (!this.editable || this.blockedByModals) return

      let prevent = false

      if (e.shiftKey) {
        this.multipleSelectHeld = true

        if (e.keyCode === 40) {
          if (this.dummyRange.length) {
            if (this.activeRow) {
              if (this.activeRowIndex + 1 < this.dummyRange.length) {
                this.$store.commit('setActiveRow', this.rowsAvailable[this.activeRowIndex + 1])
              }
            } else {
              this.$store.commit('setActiveRow', this.rowsAvailable[0])
            }
          }

          prevent = true
          return
        }
      }

      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === 13 && this.codingDrawerOpen && !_.isArray(this.activeRow)) {
          this.$store.commit('setActiveRowAsReviewed', true)
          this.$store.dispatch('handleActiveRowChange')
          if (this.activeRowIndex + 1 < this.dummyRange.length) {
            this.$store.commit('setActiveRow', this.rowsAvailable[this.activeRowIndex + 1])
          }
        }
      }

      if (this.codingDrawerDropdownOpen || this.newTopicDialog.open || this.multipleSelectHeld) return

      // If a dialog is open, let key values be default
      switch (e.keyCode) {
        case 38:
          // Arrow up
          // Focus one previous, if not already at zero
          if (this.dummyRange.length && this.activeRow) {
            if (this.activeRowIndex - 1 >= 0) {
              this.$store.commit('setActiveRow', this.rowsAvailable[this.activeRowIndex - 1])
            }
          }

          prevent = true
          break
        case 40:
          // Arrow down
          // Focus next one, if not at last el or focus first if not selected
          if (this.dummyRange.length) {
            if (this.activeRow) {
              if (this.activeRowIndex + 1 < this.dummyRange.length) {
                this.$store.commit('setActiveRow', this.rowsAvailable[this.activeRowIndex + 1])
              }
            } else {
              this.$store.commit('setActiveRow', this.rowsAvailable[0])
            }
          }

          prevent = true
          break

        case 27:
          // Escape
          if (this.codingDrawerOpen) this.$store.commit('setActiveRow', null)
          break
        // default:
        //  pass
      }
      if (prevent) e.preventDefault()
    },

    /**
     * Toggle showing auxiliary column
     */
    toggleShownAuxiliaryColumn (idx) {
      if (this.columnsShown.includes(idx)) {
        this.$store.commit('verbatimManager/setAuxiliaryColumnIdxShown', { id: this.id, value: this.columnsShown.filter(i => i !== idx) })
      } else {
        this.$store.commit('verbatimManager/setAuxiliaryColumnIdxShown', { id: this.id, value: [...this.columnsShown, idx] })
      }
    },

    /**
     * Get the label of auxiliary column
     */
    getOtherColumnLabel (ref) {
      const column = _.find(this.project.columns, column => column.ref === ref)
      return column.name
    }
  }
}

</script>

<style lang=scss>
  @import '~css/coding';
  @import '~css/filters';
</style>

<i18n locale='en' src='@/i18n/en/pages/Codingv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Codingv2.json' />
<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />