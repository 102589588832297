export default {
  watch: {
    /**
     * Disable body scroll when a dialog is open
     */
    dialogIsOpen (val, valOld) {
      if (val === valOld) return
      if (val) this.disableBodyScroll()
      else this.enableBodyScroll()
    }
  },

  methods: {
    /**
     * Add class to body, disabling scroll
     */
    disableBodyScroll () { document.getElementsByTagName('html')[0].classList.add('no-scroll') },
    /**
     * Remove class from body, enabling scroll
     */
    enableBodyScroll () { document.getElementsByTagName('html')[0].classList.remove('no-scroll') }
  },

  /**
   * Make sure body scroll is enabled before navigating away from a page
   */
  beforeDestroy () {
    this.enableBodyScroll()
  }
}