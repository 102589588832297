<template>
  <div class="wizard-cb-editor">
    <loading :is-loading="loading && !initialDone"
             :title="$t('processing.title')"
             dont-animate-entry
             :tagline="$t('processing.tagline')"
    >
      <v-alert v-if="failed"
               prominent
               type="error"
               outlined
               border="left"
               class="with-margin flex-center"
      >
        <span v-html="$t('generator_failed')" />
        <div class="spacer" />
        <v-btn color="accent" @click="runCodebookGenerator">
          {{ $t('actions.retry') }}
        </v-btn>
      </v-alert>

      <codebook-editor v-else
                       :active="active && !loading"
                       :disabled="loading"
                       store-name="wizard"
                       history
                       @set-codes-filter="$emit('set-verbatims-filter', { type: 'codes', value: $event })"
                       :highlight-codes="highlight.code"
                       :highlight-categories="highlight.category"
                       ref="codebook-editor"
                       @initial-state="codebookIsOriginal = true"
      >
        <div class="generator-settings" slot="title-raw" v-if="runAutocoder && !isListQuestion">
          <fieldset v-if="!loading">
            <legend>
              {{ $t('settings.title') }}
              <helptip position="bottom" :width="500" x-offset="200px">
                <span v-html="$t('settings.helptip')" />
              </helptip>
            </legend>
            <div class="settings-container tooltip-bottom"
                 :class="{ tooltip: !codebookIsOriginal }"
            >
              <div v-if="!codebookIsOriginal"
                   class="manual-content"
                   style="white-space: normal"
                   v-html="$t('settings.tooltip_disabled')"
              />
              <div class="less tooltip tooltip-bottom" :data-tooltip="$t('settings.tooltip_less')">
                <v-icon>mdi-minus-circle</v-icon>
              </div>
              <div class="sliders">
                <v-slider v-model.lazy="settingsModel.category_resolution"
                          :min="0"
                          :max="100"
                          :step="5"
                          :label="$t('settings.category_resolution')"
                          :disabled="!codebookIsOriginal"
                          @change="resolutionSliderChanged('category', $event)"
                          ref="category-resolution-slider"
                          dense
                          hide-details
                />
                <v-slider v-model.lazy="settingsModel.code_resolution"
                          :min="0"
                          :max="100"
                          :step="5"
                          :label="$t('settings.code_resolution')"
                          @change="resolutionSliderChanged('code', $event)"
                          :disabled="!codebookIsOriginal"
                          ref="code-resolution-slider"
                          hide-details
                />
                <div class="default-indicator tooltip tooltip-bottom"
                     v-if="codebookIsOriginal"
                     :data-tooltip="$t('settings.tooltip_default')"
                     :style="{ left: `calc((100% - 177px) * ${settingsDefault.code_resolution}/100)` }"
                >
                  <v-icon
                    @click="setDefaultResolutions"
                  >
                    mdi-map-marker
                  </v-icon>
                </div>
              </div>
              <div class="more tooltip tooltip-bottom" :data-tooltip="$t('settings.tooltip_more')">
                <v-icon>mdi-plus-circle</v-icon>
              </div>
            </div>
          </fieldset>
          <v-skeleton-loader v-else type="image" />
        </div>
        <template slot="info">
          <span v-html="$t('cb_editor.info')" />
          <span v-if="isListQuestion" v-html="$t('cb_editor.list-mode')" />
        </template>
      </codebook-editor>

      <div class="btn-next-container">
        <v-btn class="btn-prev"
               :disabled="loading"
               @click="goBack"
        >
          {{ $t('back') }}
        </v-btn>

        <v-btn color="primary"
               @click="$emit('next')"
               :disabled="loading"
               v-text="$t('save.title')"
        />
      </div>
    </loading>
  </div>
</template>

<script>

import Vuex from 'vuex'

import ApiTaskMixin from '@/mixins/apitask'

import CodebookEditor from '@/components/CodebookEditor'

import { MAX_TOPIC_KEYWORD_LEN, MAX_TOPIC_LABEL_LEN, MAX_TOPIC_CATEGORY_LEN } from '@/settings/constants'

const DEFAULT_GENERATOR_SETTINGS = { category_resolution: 50, code_resolution: 50 }

let originalGeneratedCodebook = []

export default {
  codit: true,
  name: 'CodebookGenerator',

  components: {
    'codebook-editor': CodebookEditor
  },

  mixins: [ApiTaskMixin],

  props: {
    active: { type: Boolean, default: false }
  },

  data () {
    return {
      loading: false,
      failed: false,
      initialDone: false,

      codebookFromPreviousStep: [],
      lastChangedParam: null,

      settings: _.cloneDeep(DEFAULT_GENERATOR_SETTINGS),
      settingsModel: _.cloneDeep(DEFAULT_GENERATOR_SETTINGS),
      settingsDefault: _.cloneDeep(DEFAULT_GENERATOR_SETTINGS),

      highlight: {
        code: {},
        category: {},
        timeout: null
      },

      codebookIsOriginal: false,

      runCachedCodebookGeneratorDebounced: _.debounce(this.runCachedCodebookGenerator, 250)
    }
  },

  computed: {
    ...Vuex.mapState({
      user: 'user',
      question: state => state.wizard.question,
      codes: state => state.wizard.codes,
      runAutocoder: state => state.wizard.runAutocoder
    }),

    // codebookIsOriginal () {
    //   return this.codes.length === originalGeneratedCodebook.length && _.isEqual(this.codes, originalGeneratedCodebook)
    // },

    ...Vuex.mapGetters(['isListQuestion', 'codeID2Idx', 'codeCats'])
  },

  watch: {
    active: {
      immediate: true,
      /**
       * When this component becomes active and this isn't a list question, run the
       * codebook generator
       */
      handler () {
        if (this.active) {
          if (this.runAutocoder && !this.isListQuestion) {
            this.lastChangedParam = null
            this.initialDone = false
            this.runInitialCodebookGenerator()
            this.codebookFromPreviousStep = _.cloneDeep(this.codes)
          } else this.$emit('infer-sample')
        }
      }
    },

    codes: {
      deep: true,
      handler () {
        if (this.codebookIsOriginal && !_.isEqual(originalGeneratedCodebook, this.codes)) this.codebookIsOriginal = false
      }
    }
  },

  methods: {
    /**
     * When the back button is clicked, make the user confirm the navigation away
     */
    goBack () {
      let doIt = confirm(this.$t('confirm_navigate_away'))
      if (doIt) {
        // Make sure the ears are closed, to prevent errors from popping up
        this.$refs['codebook-editor'].closeEars()
        this.$emit('back')
      }
    },

    /**
     * Proxy function for the retry button.
     * Either calls the initial codebook generator method, or the cached version.
     */
    runCodebookGenerator () {
      if (this.initialDone) this.runCachedCodebookGenerator()
      else this.runInitialCodebookGenerator()
    },

    /**
     * Run the codebook generator
     */
    runInitialCodebookGenerator () {
      this.loading = true
      this.failed = false
      api.post(`/api/questions/${this.question.id}/codebook-generator?async`, { codebook: this.codes }).then((task) => {
        if (task.status !== 200 || !('task-id' in task.headers)) throw Error('Calling codebook generator failed')
        this.getApiTaskStatus(task.headers['task-id']).then(({ result }) => this.onCodebookGenerated(result)).catch(err => {
          this.failed = true
          this.loading = false
          this.$maybeRaiseAPIPromiseErr(err)
        })
      }).catch(err => {
        this.failed = true
        this.loading = false
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Run the codebook generator, on a already cached question
     * Can only be used once the intial codebook generator run has been completed
     * @return {[type]} [description]
     */
    runCachedCodebookGenerator () {
      this.loading = true
      this.failed = false

      this.$set(this.highlight, 'code', {})
      this.$set(this.highlight, 'category', {})
      clearTimeout(this.highlight.timeout)

      api.post(`/api/questions/${this.question.id}/codebook-generator-cached`, {
        ...this.settings,
        codebook: this.codebookFromPreviousStep
      }).then(({ data }) => this.onCodebookGenerated(data))
        .catch(err => {
          this.failed = true
          this.loading = false
          this.$maybeRaiseAPIPromiseErr(err)
        })
    },

    /**
     * Callback when codebook generator function has been completed
     * @param  {Object} result The result object, containing keywords & generated codebook
     */
    onCodebookGenerated (result) {
      if (!('codebook' in result)) throw Error('Invalid return data')

      let newCodes = []
      let defaults = { keywords: [], description: '' }
      // Validate properties of new codes and give them colors
      // Caution: Always base the difference on the codebook from the previous step
      let codesByCat = _.groupBy(this.codebookFromPreviousStep, 'category')
      let codeCats = _.keys(codesByCat)
      let catColors = _.mapValues(codesByCat, cc => cc[0].color)
      let codeIDS = new Set(_.map(this.codebookFromPreviousStep, 'id'))
      result.codebook.forEach(c => {
        if (!codeIDS.has(c.id)) {
          if (codeCats.indexOf(c.category) === -1) codeCats.push(c.category)
          newCodes.push({
            ...c,
            color: c.category in catColors ? catColors[c.category] : this.$color.getMedium(codeCats.indexOf(c.category)),
            category: c.category.slice(0, MAX_TOPIC_CATEGORY_LEN),
            label: c.label.slice(0, MAX_TOPIC_LABEL_LEN),
            keywords: _.map(c.keywords, k => k.slice(0, MAX_TOPIC_KEYWORD_LEN)),
            new: true
          })
        }
      })

      // Update the codebook settings parameters, returned from the generator
      _.each(result.parameters, (val, key) => {
        if (val !== this.settings[key]) {
          this.settings[key] = val
          this.settingsModel[key] = val
          this.settingsDefault[key] = val
        }
      })

      // Add the default values to codes where they don't exist yet
      let codes = _.map([...this.codebookFromPreviousStep, ...newCodes], c => ({ ..._.cloneDeep(defaults), ...c }))

      this.computeHighlightElements(codes, this.codes)

      this.$store.commit('setCodes', codes)
      this.codebookIsOriginal = true
      // When the codebook generator has run, we also want the sample to be inferred
      // Call the debounced version of the sample computation method
      // (if this isn't the first time a codebook was generated)
      // to avoid having too many calls to generic inference because of rapid adjustment of slider
      this.$emit('infer-sample', this.initialDone)
      this.loading = false
      originalGeneratedCodebook = _.cloneDeep(codes)
      this.initialDone = true
    },

    /**
     * Compute which elements of the new codebook to highlight
     * @param  {Object} newCodebook The new codebook
     * @param  {Object} oldCodebook The old codebook
     */
    computeHighlightElements (newCodebook, oldCodebook) {
      // We either highlight codes or categories
      // If the param wasn't changed yet (i.e. this is the initially generated codebook)
      // default to codes
      let param = this.lastChangedParam || 'code'

      let highlight = {}

      if (param === 'code') {
        let oldCodesByID = {}
        this.codes.forEach(c => { oldCodesByID[c.id] = c })

        newCodebook.forEach(c => {
          if (!_.isEqual(c, oldCodesByID[c.id])) highlight[c.id] = true
        })
      } else if (param === 'category') {
        let oldCats = new Set(_(oldCodebook).groupBy('category').keys())

        newCodebook.forEach(c => {
          if (!oldCats.has(c.category)) highlight[c.category] = true
        })
      } else throw Error(`Unknown param highlighting mode '${param}'`)

      this.$set(this.highlight, param, highlight)
      this.highlight.timeout = setTimeout(() => { if (this.highlight) this.$set(this.highlight, param, {}) }, 10000)
    },

    /**
     * Callback when resolution slider is changed. Needed to make sliders lazy.
     * @param  {String} param String, either {category,code} describing which slider was changed
     */
    resolutionSliderChanged (param) {
      // Make sure the keyboard events are not captured by the slider anymore
      this.$refs[`${param}-resolution-slider`].$refs.thumb.blur()
      this.lastChangedParam = param
      // we need to defer this to the next tick, as the vuetify slider might need a moment to update the value
      this.$nextTick(() => {
        this.$set(this.settings, `${param}_resolution`, this.settingsModel[`${param}_resolution`])
        this.runCachedCodebookGeneratorDebounced()
      })
    },

    /**
     * Set the resolutiosn back to the default value and call the codebook generator
     * if the current value is not already at the default
     */
    setDefaultResolutions () {
      if (_.isEqual(this.settingsDefault, this.settings)) return
      this.settings = _.cloneDeep(this.settingsDefault)
      this.settingsModel = _.cloneDeep(this.settingsDefault)
      this.runCachedCodebookGeneratorDebounced()
    }
  }
}

</script>

<style lang=scss>

.wizard-cb-editor {
  margin: -12px -16px 0 -24px;
  min-height: 100%;
  height: auto;
  margin-bottom: 300px;

  .v-alert.with-margin {
    margin: 12px 16px 0 24px;
  }

  .btn-next-container {
    margin-top: -250px!important;
    margin-left: 20px;
  }

  .generator-settings {
    width: 70%;
    min-width: 400px;
    max-width: 650px;

    .v-skeleton-loader {
      width: 100%;
      height: 67px;
      margin-top: 8px;
    }

    fieldset {
      border: 1px solid rgba(0,0,0,.12);
      border-radius: 4px;
      padding-left: 8px;

      .settings-container {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
      }

      > legend {
        color: #666;
        font-size: smaller;
        padding: 0 8px;
        flex: 0 0 auto;
        white-space: nowrap;
        font-weight: bold;
      }

      .more, .less {
        .v-icon { color: #DDD; }
        display: flex;
        align-items: center;
        margin: 0 12px;
        flex: 0 0 40px;
      }

      .less {
        position: absolute;
        margin: auto 0;
        top: 0;
        bottom: 0;
        left: 160px;
        z-index: 3;
      }

      .sliders {
        flex: 1;
        margin-left: 8px;
        position: relative;
        .v-input:not(:first-child) { margin-top: -10px; }

        .default-indicator {
          position: absolute;
          margin-left: 169px;
          top: -14px;
          .v-icon { font-size: 16px }
        }
      }

      label {
        display: flex;
        font-size: smaller;
        width: 165px;
      }
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/wizard/CodebookGenerator.json' />
<i18n locale='de' src='@/i18n/de/components/wizard/CodebookGenerator.json' />