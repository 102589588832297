export default {
  props: {
    value: { type: Boolean, default: false }
  },

  methods: {
    /**
    * Close dialog emitting value for v-model
    */
    closeDialog () {
      this.internalValue = false
    }
  }
}