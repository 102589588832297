import Vuex from 'vuex'

let STRIPE_PK = {
  'dev': 'pk_test_KYfZnst7aLZKF6hzmutq3gYz',
  'prod': 'pk_live_jl40H1nukjawAC2i2Xwd60rh'
}[(process.env.VUE_APP_CAPLENA_ENV === 'prod' ? 'prod' : 'dev')]

export default {
  data () {
    return {
      // Stripe resources (mostly for loading)
      stripeScriptIsLoading: true,
      stripeHandler: null,
      stripeScript: null,

      // Stripe information of user
      stripePaymentMethods: { loaded: false, cards: [], failed: false },

      // Helper data for removing a credit card
      removePaymentMethod: { active: false, label: '', id: '', idx: -1 },

      // payment history
      stripeInvoices: { loaded: false, invoices: [], failed: false },

      STRIPE_PK
    }
  },

  computed: {
    /**
     * If the user can remove the currently saved stripe cards
     * @return {Boolean}
     */
    stripeCardsRemovable () {
      // Cards are deletable only if
      // * the user is on the free plan or
      // * if is more than one card left or
      // * if the current subscription has been cancelled

      return ([10, 9].indexOf(this.user.subscription.plan_id) !== -1 ||
        this.stripePaymentMethods.cards.length > 1) ||
        this.user.subscription.will_end
    },

    ...Vuex.mapState(['user'])
  },
  created () {
    // get already existing payment information from user
    if (!this.$hasPermission('subscription_mgmt')) return

    this.loadPaymentMethods()

    // load stripe checkout script
    // First option: The element has not been inserted by another instance
    // of this mixin yet. Then do it now.
    if (!document.getElementById('stripe-script')) {
      let stripe = document.createElement('script')
      stripe.setAttribute('id', 'stripe-script')
      stripe.async = true
      stripe.onload = this.stripeLoaded
      stripe.src = 'https://js.stripe.com/v3/'
      this.stripeScript = stripe
      document.head.appendChild(this.stripeScript)
    // Second option: The script has already been inserted
    // Here we need to handle two cases:
    // 1) The script has already been loaded (most probably). Set the local state accordingly.
    // 2) The script has not been loaded yet. Set a new onload handler, which calls the old handler
    //    and also sets the local state.
    } else {
      this.stripeScript = document.getElementById('stripe-script')
      if (this.stripeScript.isLoaded) this.stripeLoaded()
      else {
        let oldOnLoad = this.stripeScript.onload
        this.stripeScript.onload = () => {
          oldOnLoad()
          this.stripeLoaded()
        }
      }
    }
  },

  beforeDestroy () {
    if (this.stripeHandler) this.stripeHandler.close()
  },

  methods: {
    /**
     * Load the currently saved stripe payment methods of the user
     */
    loadPaymentMethods () {
      api.get('/api/subscription/payment-methods').then((res) => {
        this.stripePaymentMethods.loaded = true
        this.$set(this.stripePaymentMethods, 'cards', res.data.cards)
      }).catch(err => {
        this.stripePaymentMethods.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Load the previous stripe invoices of the user
     */
    loadStripeInvoices () {
      api.post('/api/subscription/invoices').then(res => {
        this.$set(this.stripeInvoices, 'invoices', res.data.invoices)
        this.stripeInvoices.loaded = true
      }).catch(err => {
        this.stripeInvoices.failed = true
        this.$root.snackMsg('An error occurred while loading your payment history, we\'ll look into it')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Callback for when stripe script is loaded
     */
    stripeLoaded () {
      this.stripeScriptIsLoading = false
      this.stripeScript.isLoaded = true
    },

    /**
     * Callback when new payment method has been added: Store it in the currently available payment methods array
     * @param  {Object} pm The new payment method
     */
    addedPaymentMethod (pm) {
      this.stripePaymentMethods.cards.push(pm)
    },

    /**
     * Open the confirmation dialog for removing a payment id
     * @param  {String} id The ID of the payment method to remove
     */
    openRemovePaymentMethod (id) {
      this.removePaymentMethod.idx = _.findIndex(this.stripePaymentMethods.cards, { id })
      let toRemove = this.stripePaymentMethods.cards[this.removePaymentMethod.idx]
      this.removePaymentMethod.id = id
      this.removePaymentMethod.label = `${toRemove.brand} **** ${toRemove.last4}`
      this.removePaymentMethod.active = true
    },

    /**
     * Close the remove payment method dialog
     * @param  {Boolean} confirm If true, remove the payment method. If false, not.
     */
    closeRemovePaymentMethod (confirm) {
      if (confirm) {
        this.stripePaymentMethods.cards.splice(this.removePaymentMethod.idx, 1)
        api.post('/api/subscription/remove-payment-method', { payment_method: this.removePaymentMethod.id }).then(res => {
          this.$root.snackMsg(this.$t('remove_card.success'))
        }).catch(() => {
          this.$root.snackMsg(this.$t('remove_card.error'))
        })
      }

      this.removePaymentMethod.idx = -1
      this.removePaymentMethod.id = ''
      this.removePaymentMethod.active = false
      this.removePaymentMethod.label = ''
    }
  }
}
