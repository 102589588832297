var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('file-upload',{staticClass:"wizard__question__tile d-flex",attrs:{"accept":_vm.acceptFileTypes,"required":"","multiple":false,"drop":true,"drop-directory":false},on:{"input":_vm.handleFileUpload}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" "),_c('div',{staticClass:"wizard__question__tile__title"},[_vm._v("\n      "+_vm._s(_vm.$t('question_steps.file_upload'))+"\n    ")])]),_vm._v(" "),_c('modal',{attrs:{"visible":!!_vm.inheritModal,"close":() => _vm.closeInheritModal(),"title":_vm.$t('question_steps.inherit'),"class-name":"wizard__inherit topic-fullscreen-modal","action":_vm.$t('confirm'),"action-click":_vm.setTopicsFromInherit,"disabled":_vm.confirmDisabled,"persistent":"","max-width":"1375px"}},[(_vm.meta)?_c('div',{staticClass:"coding__column__options coding__column__options--topic-search coding__column__options--file-inherit d-flex justify-space-between"},[_c('div',{staticClass:"w-100 mb-3 d-flex align-center"},[(_vm.meta?.sheet_names?.length)?_c('div',{staticStyle:{"max-width":"24%"}},[_c('label',{staticClass:"font-weight-medium text-sm text-label"},[_vm._v("\n            Sheet\n          ")]),_vm._v(" "),_c('v-select',{staticClass:"search-bar__search search-bar__search--large text-sm",attrs:{"items":_vm.meta.sheet_names,"placeholder":"Select sheet","hide-details":"","dense":"","height":40,"outlined":"","menu-props":{
              'offset-y': true,
              'ripple': false
            }},on:{"input":v => _vm.handleFileUpload(_vm.file, v)},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-100 d-flex align-center ml-4",staticStyle:{"margin-top":"22px"}},[_c('v-checkbox',{attrs:{"primary":"","hide-details":"","ripple":false,"color":"green","off-icon":"mdi-checkbox-blank","flat":"","label":"Has header row"},on:{"change":v => _vm.handleFileUpload(_vm.file)},model:{value:(_vm.headerRow),callback:function ($$v) {_vm.headerRow=$$v},expression:"headerRow"}})],1)]),_vm._v(" "),_c('div',{staticStyle:{"max-width":"24%"}},[_c('label',{staticClass:"font-weight-medium text-sm text-label"},[_vm._v("\n          Topic column\n        ")]),_vm._v(" "),_c('v-select',{staticClass:"search-bar__search search-bar__search--large text-sm",attrs:{"items":_vm.meta.file_columns,"no-data-text":_vm.$t('question_steps.choose_language'),"placeholder":"Select template","hide-details":"","dense":"","height":40,"outlined":"","menu-props":{
            'offset-y': true,
            'ripple': false
          }},on:{"input":_vm.handleParameterChange},model:{value:(_vm.topicColumn),callback:function ($$v) {_vm.topicColumn=$$v},expression:"topicColumn"}})],1),_vm._v(" "),_c('div',{staticStyle:{"max-width":"24%"}},[_c('label',{staticClass:"font-weight-medium text-sm text-label"},[_vm._v("\n          Category column\n        ")]),_vm._v(" "),_c('v-select',{staticClass:"search-bar__search search-bar__search--large text-sm",attrs:{"items":_vm.meta.file_columns,"no-data-text":_vm.$t('question_steps.choose_language'),"placeholder":"Select template","hide-details":"","dense":"","height":40,"outlined":"","menu-props":{
            'offset-y': true,
            'ripple': false
          }},on:{"input":_vm.handleParameterChange},model:{value:(_vm.categoryColumn),callback:function ($$v) {_vm.categoryColumn=$$v},expression:"categoryColumn"}})],1),_vm._v(" "),_c('div',{staticStyle:{"max-width":"24%"}},[_c('label',{staticClass:"font-weight-medium text-sm text-label"},[_vm._v("\n          ID column\n        ")]),_vm._v(" "),_c('v-select',{staticClass:"search-bar__search search-bar__search--large text-sm",attrs:{"items":_vm.meta.file_columns,"no-data-text":_vm.$t('question_steps.choose_language'),"placeholder":"Select template","hide-details":"","dense":"","height":40,"outlined":"","menu-props":{
            'offset-y': true,
            'ripple': false
          }},on:{"input":_vm.handleParameterChange},model:{value:(_vm.idColumn),callback:function ($$v) {_vm.idColumn=$$v},expression:"idColumn"}})],1),_vm._v(" "),_c('div',{staticStyle:{"max-width":"24%"}},[_c('label',{staticClass:"font-weight-medium text-sm text-label"},[_vm._v("\n          Sentiment column\n        ")]),_vm._v(" "),_c('v-select',{staticClass:"search-bar__search search-bar__search--large text-sm",attrs:{"items":_vm.meta.file_columns,"no-data-text":_vm.$t('question_steps.choose_language'),"placeholder":"Select template","hide-details":"","dense":"","clearable":"","height":40,"outlined":"","menu-props":{
            'offset-y': true,
            'ripple': false
          }},on:{"input":_vm.handleParameterChange},model:{value:(_vm.sentimentColumn),callback:function ($$v) {_vm.sentimentColumn=$$v},expression:"sentimentColumn"}})],1)]):_vm._e(),_vm._v(" "),(!_vm.loading && !_vm.parameterError && !_vm.inheritError)?_c('topics-editor',{attrs:{"id":_vm.id,"active":true,"editable":false,"store-name":"wizard","history":"","wizard-view":"","template-select":""}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('loading',{staticClass:"h-100 position-relative mb-5 pb-5",attrs:{"title":"Uploading","is-loading":true,"dont-animate-entry":""}}):_vm._e(),_vm._v(" "),(_vm.parameterError || _vm.inheritError)?_c('error-state',{attrs:{"class-name":"mb-5 pb-5","title":"Parameter error","text":(_vm.parameterError || _vm.inheritError) ? (_vm.parameterError?.message || _vm.parameterError) || (_vm.inheritError?.message || _vm.inheritError) : 'Error uploading file with given parameters'}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"notification-container"},_vm._l((_vm.warnings),function(warning,idx){return _c('notification',{key:idx,attrs:{"type":"warning","title":"Warning","max-width":"400px","closeable":"","class-name":"mb-2","text":_vm.$escapeHtml(warning)}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }