import Vue from 'vue'
import colors from 'vuetify/es5/util/colors'

import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)

const VuetifyInstance = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0e5d88', // #E53935
        secondary: '#404850', // #FFCDD2
        info: '#0e5d88',
        accent: colors.red.accent2, // #3F51B5
        error: colors.red.accent2, // #3F51B5
        success: '#4CAF50',
        green: '#00C58D'
      },
      dark: {
        secondary: '#404850'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})

export default VuetifyInstance