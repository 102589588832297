<template>
  <div class="feature-unavailable">
    <div class="info-text" v-text="$t('feature_not_included.text')" />
    <v-btn v-if="$root.isRootAccount"
           color="white"
           class="manage-subscription"
           dense
           small
           :to="{ name: 'account', query: { tab: 'subscription' } }"
    >
      {{ $t('feature_not_included.btn') }}
    </v-btn>
    <div v-else
         style="margin-top: 10px"
         v-html="$t('feature_not_included.contact_admin', { email: user.organization.root_user_email })"
    />
  </div>
</template>

<script>

import Vuex from 'vuex'

export default {
  codit: true,

  computed: {
    ...Vuex.mapState([
      'user'
    ])
  }
}

</script>

<style lang=scss>

.helptip {
  .v-btn.manage-subscription { width: 100%; margin-top: 12px }
}

</style>