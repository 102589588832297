<template>
  <div class="two-fa">
    <v-card>
      <v-card-title>
        <div>
          <div class="title">
            {{ $t('2fa.title') }}
          </div>
          <div class="grey--text">
            {{ $t('2fa.subtitle') }}
          </div>
        </div>
      </v-card-title>
      <v-progress-linear indefinite v-if="loading" style="margin: 8px 16px 24px" />

      <template v-else>
        <v-card-text>
          <v-alert v-if="failed"
                   prominent
                   type="error"
                   outlined
                   text
                   border="left"
                   class="flex-center"
          >
            {{ $t('2fa.loading_failed') }}

            <div class="spacer" />

            <v-btn color="accent" outlined @click="load2FAMethods">
              {{ $t('actions.retry') }}
            </v-btn>
          </v-alert>
          <template v-else>
            <v-alert type="info"
                     outlined
                     text
                     border="left"
                     class="flex-center"
            >
              <template v-if="!methods.length">
                {{ $t('2fa.info_no_devices') }}
              </template>
              <template v-else>
                {{ $t('2fa.intro_text', { n: methods.length }) }}
              </template>
            </v-alert>

            <template>
              <ul class="existing-methods">
                <li v-for="(m, idx) in methods" :key="idx">
                  <span class="name" v-text="$t(`2fa.device_names.${m.name}`)" />
                  <span class="type"
                        :class="{ default: m.name === 'default' }"
                  >
                    {{ $t(`2fa.device_types.${m.device_type}`) }}
                    {{ m.number }}
                  </span>
                  <v-spacer />
                  <span class="actions"><a @click="openDelete2FADialog(m.id)" v-text="$t('delete.title')" />
                  </span>
                </li>
              </ul>
            </template>
          </template>
        </v-card-text>

        <v-card-actions v-if="!loading && !failed">
          <v-spacer />
          <v-btn color="primary"
                 outlined
                 v-if="methods.length"
                 @click="openBackupCodesDialog"
          >
            {{ $t('2fa.btn_backup_codes') }}
          </v-btn>
          <v-btn color="primary"
                 :outlined="methods.length > 0"
                 v-if="add2FAMethodAvailable"
                 @click="openAddDialog"
          >
            {{ $t('2fa.btn_add') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>

    <v-dialog v-model="dialogActive"
              max-width="600"
              @keydown.esc="cancelDialog"
              :persistent="dialog.loading"
    >
      <v-card class="dialog-2fa" v-if="!!dialog.mode">
        <v-card-title class="headline grey lighten-2"
                      primary-title
                      v-text="$t(`2fa.${dialog.mode}.title`)"
        />

        <v-expand-transition>
          <div v-show="dialog.error !== ''" class="alert-container">
            <v-alert type="error"
                     border="left"
                     text
            >
              {{ dialog.error }}
            </v-alert>
          </div>
        </v-expand-transition>

        <div v-if="dialog.success" class="alert-container">
          <v-alert type="success"
                   border="left"
                   text
          >
            <span v-html="$t('2fa.add.success')" />
          </v-alert>

          <div style="display: flex; padding-top: 10px">
            <v-spacer />
            <v-btn outlined
                   color="secondary"
                   class="btn-success"
                   @click="cancelDialog"
            >
              {{ $t('close') }}
            </v-btn>
          </div>
        </div>

        <v-stepper v-model="dialog.step" vertical v-if="!dialog.success">
          <v-stepper-step :complete="dialog.step > 1" :step="1">
            {{ $t('2fa.password.title') }}
          </v-stepper-step>

          <!-- VERIFY PASSWORD STEP -->
          <v-stepper-content :step="1">
            <v-text-field v-model="dialog.password"
                          :label="$t('2fa.password.label')"
                          :disabled="dialog.loading"
                          class="password"
                          type="password"
                          outlined
                          hide-details
                          ref="2fa-pw"
                          @keydown.enter="submitPassword"
            />

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary"
                     :disabled="!dialog.password.length"
                     :loading="dialog.loading"
                     @click="submitPassword"
              >
                {{ $t('next') }}
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- SELECT METHOD STEP -->
          <v-stepper-step v-if="dialog.mode === 'add'" :complete="dialog.step > 2" :step="2">
            {{ $t('2fa.add.method.title') }}
          </v-stepper-step>

          <v-stepper-content :step="2" v-if="dialog.mode === 'add'">
            <div class="type-select">
              <v-card class="type-option"
                      v-if="addAppVerificationEnabled"
                      :class="{ selected: dialog.method === 'app' }"
                      @click="selectAdd2FAOption('app')"
              >
                <v-card-title>
                  <div class="title">
                    {{ $t(`2fa.add.app.title`) }}
                  </div>
                  <div class="subtitle">
                    {{ $t(`2fa.add.app.subtitle`) }}
                  </div>
                </v-card-title>
                <v-responsive class="tile-img-container">
                  <v-icon>mdi-qrcode</v-icon>
                </v-responsive>
              </v-card>

              <v-card class="type-option"
                      :class="{ selected: dialog.method === 'sms' }"
                      @click="selectAdd2FAOption('sms')"
              >
                <v-card-title>
                  <div class="title">
                    {{ $t(`2fa.add.sms.title`) }}
                  </div>
                  <div class="subtitle">
                    {{ $t(`2fa.add.sms.subtitle`) }}
                  </div>
                </v-card-title>
                <v-responsive class="tile-img-container">
                  <v-icon>mdi-cellphone-text</v-icon>
                </v-responsive>
              </v-card>
            </div>

            <v-expand-transition>
              <div v-show="dialog.method === 'sms'" class="phone-container">
                <v-text-field v-model="dialog.phone"
                              :label="$t('2fa.add.sms.input_label')"
                              :disabled="dialog.loading"
                              @keydown.enter="add2FAGenerateChallenge"
                              class="phone-input"
                              ref="phone-input"
                              outlined
                              hide-details
                />
              </div>
            </v-expand-transition>

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary"
                     :disabled="!add2FAMethodValid"
                     :loading="dialog.loading"
                     @click="add2FAGenerateChallenge"
              >
                {{ $t('next') }}
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- CONFIRM DELETE STEP -->
          <v-stepper-step v-if="dialog.mode === 'delete'" :complete="dialog.step > 2" :step="2">
            {{ $t('2fa.delete.step_title') }}
          </v-stepper-step>

          <v-stepper-content :step="2" v-if="dialog.mode === 'delete'">
            <v-alert type="warning"
                     class="confirm-delete"
                     outlined
                     text
                     border="left"
            >
              <div v-html="$t('2fa.delete.alert', { device: deleteDeviceName })" />
              <div v-if="isLastDevice" style="font-weight: bold" v-text="$t('2fa.delete.alert_disable')" />
            </v-alert>

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary"
                     @click="confirmDelete"
                     :loading="dialog.loading"
              >
                {{ $t('delete.title') }}
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- BACKUP CODES STEP -->
          <v-stepper-step v-if="dialog.mode === 'backup'" :complete="dialog.step > 2" :step="2">
            {{ $t('2fa.backup.step_title') }}
          </v-stepper-step>

          <v-stepper-content :step="2" v-if="dialog.mode === 'backup'">
            <v-alert v-if="!dialog.failed && !dialog.codes.length"
                     type="info"
                     outlined
                     text
                     border="left"
            >
              <div v-html="$t('2fa.backup.alert_none')" />
            </v-alert>

            <ul class="backup-codes">
              <li v-for="(token, idx) in dialog.codes" :key="idx" v-text="token" />
            </ul>

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('close') }}
              </v-btn>
              <v-btn color="primary"
                     @click="generateBackupCodes"
                     :loading="dialog.loading"
              >
                {{ $t('2fa.backup.btn_generate') }}
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- VALIDATE ADD TOKEN STEP -->
          <v-stepper-step :step="3" v-if="dialog.mode === 'add'">
            {{ $t(`2fa.add.validate.title`) }}
          </v-stepper-step>
          <v-stepper-content :step="3" v-if="dialog.mode === 'add'">
            <v-alert v-if="dialog.step === 3"
                     type="info"
                     color="primary"
                     text
            >
              <span v-html="$t(`2fa.add.${dialog.method}.validate_instructions`, { number: dialog.number })" />
            </v-alert>

            <div class="validation-container">
              <div v-if="dialog.method === 'app'"
                   v-html="dialog.qrCode"
                   class="qr-code"
              />

              <div class="validate-input">
                <v-text-field v-model="dialog.token"
                              :label="$t('2fa.add.validate.token_label')"
                              :disabled="dialog.loading"
                              ref="2fa-token"
                              outlined
                              hide-details
                              :max="999999"
                              :maxlength="6"
                              @keydown.enter="add2FAGValidateChallenge"
                />
              </div>
            </div>

            <div class="controls-container">
              <v-btn color="primary"
                     outlined
                     @click="cancelDialog"
                     :disabled="dialog.loading"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary"
                     @click="add2FAGValidateChallenge"
                     :loading="dialog.loading"
              >
                {{ $t('save.title') }}
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'Manage2FA',
  codit: true,

  data () {
    return {
      loading: false,
      failed: false,
      methods: [],

      dialog: {
        step: 1,
        mode: '',
        active: false,
        loading: false,
        success: false,
        password: '',
        error: '',
        method: '',
        phone: '',
        token: '',
        qrCode: '',
        deleteID: '',
        codes: []
      }
    }
  },

  computed: {
    /**
     * If the method-selection step while adding new 2FA methods is valid
     * @return {Boolean}
     */
    add2FAMethodValid () {
      switch (this.dialog.method) {
        case 'app':
          return true
        case 'sms':
          return this.dialog.phone.length > 5
      }
      return false
    },

    /**
     * Proxy for the `dialog.active` property.
     * Make sure the cancelDialog is called when closing the dialog, to clear current inputs.
     */
    dialogActive: {
      get () { return this.dialog.active },
      set (val) { if (!val) this.cancelDialog() }
    },

    /**
     * The number of currently configured 2FA App devices
     * @return {Number}
     */
    n2FAAppDevices () {
      return _.filter(this.methods, { device_type: 'app' }).length
    },

    /**
     * The number of currently configured 2FA SMS devices
     * @return {Number}
     */
    n2FASMSDevices () {
      return _.filter(this.methods, { device_type: 'sms' }).length
    },

    /**
     * If the user can add a 2FA App device
     * @return {Boolean}
     */
    addAppVerificationEnabled () {
      return this.n2FAAppDevices === 0
    },

    /**
     * If the user can add another 2FA device
     * @return {Boolean}
     */
    add2FAMethodAvailable () {
      return (this.n2FAAppDevices + this.n2FASMSDevices) < 2
    },

    /**
     * The 2FA device currently marked for deletion
     * @return {Object} 2FA device
     */
    deviceToDelete () {
      return _.find(this.methods, { id: this.dialog.deleteID })
    },

    /**
     * The human-readable name for the device currently selected for deletion
     * @return {String}
     */
    deleteDeviceName () {
      let d = this.deviceToDelete
      if (!d) return ''

      let t = this.$t(`2fa.device_types.${d.device_type}`)
      if (d.device_type === 'sms') t += ' ' + d.number
      return t
    },

    /**
     * If the device selected for deletion is the last "real" 2FA device (i.e. not counting backup codes)
     * @return {Boolean}
     */
    isLastDevice () {
      return this.deviceToDelete.device_type !== 'codes' && (this.n2FASMSDevices + this.n2FAAppDevices) === 1
    }
  },

  watch: {
    'dialog.step' () {
      if (this.dialog.step === 2 && this.dialog.mode === 'add' && !this.addAppVerificationEnabled) {
        setTimeout(() => {
          this.dialog.method = 'sms'
        }, 1000)
      }
    },

    'dialog.method' (val) {
      if (val === 'sms') this.$nextTick(() => this.$refs['phone-input'].focus())
    }

  },

  created () {
    this.load2FAMethods()
  },

  methods: {
    /**
     * Load the currently configured 2FA devices for the user
     */
    load2FAMethods () {
      this.loading = true
      this.failed = false
      api.get('/api/auth/user/2fa/methods').then(res => {
        this.$set(this, 'methods', res.data)
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Helper method, resetting the dialog properties to default values
     */
    _resetDialogProps () {
      this.dialog.step = 1
      this.dialog.loading = false
      this.dialog.success = false
      this.dialog.password = ''
      this.dialog.error = ''
      this.dialog.method = ''
      this.dialog.phone = ''
      this.dialog.token = ''
      this.dialog.mode = ''
      this.dialog.deleteID = ''
      this.dialog.codes = []
      this.dialog.qrCode = ''
    },

    /**
     * Open the dialog in the "add device" mode
     * @return {[type]} [description]
     */
    openAddDialog () {
      this.openDialog()
      this.dialog.mode = 'add'
    },

    /**
     * Open the dialog in the "backup codes" mode
     */
    openBackupCodesDialog () {
      this.openDialog(this.getBackupCodes)
      this.dialog.mode = 'backup'
    },

    /**
     * Open the dialog in the "delete device" method
     * @param  {String} persistentID The ID of the device to delete
     */
    openDelete2FADialog (persistentID) {
      this.openDialog()
      this.dialog.deleteID = persistentID
      this.dialog.mode = 'delete'
    },

    /**
     * Generic method when opening the dialog
     * As all specific modes require a second layer of user authentication,
     * always check if the 2fa process is already active
     * @param  {Function,undefined} processActiveCallback   Callback for when the `is-process-active` endpoint returned active=true
     *                                                      If not defined, just go to the second step
     */
    openDialog (processActiveCallback) {
      this._resetDialogProps()
      this.dialog.active = true
      this.dialog.loading = true
      api.get('/api/auth/user/2fa/is-process-active').then(res => {
        this.dialog.loading = false
        if (res.data.active === true) {
          if (processActiveCallback) processActiveCallback()
          else this.dialog.step = 2
        } else this.$nextTick(() => this.$refs['2fa-pw'].focus())
      }).catch(err => {
        this.dialog.loading = false
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Cancel the current dialog and reset the props
     */
    cancelDialog () {
      if (this.dialog.loading) return
      this._resetDialogProps()
      this.dialog.active = false
    },

    /**
     * Submit the password in the first step of the dialog
     */
    submitPassword () {
      this.dialog.loading = true
      this.dialog.error = ''
      api.post('/api/auth/user/2fa/initiate-setup', { password: this.dialog.password }, { dontReport: [400] }).then(res => {
        this.dialog.loading = false
        this.dialog.password = ''
        if (res.data.success === true) {
          if (this.dialog.mode === 'backup') this.getBackupCodes()
          else this.dialog.step += 1
        } else {
          if ('error' in res.data) this.dialog.error = res.data.error
          else this.dialog.error = this.$t('error_generic')
        }
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Select a method for the new 2FA device
     * @param  {String} method
     */
    selectAdd2FAOption (method) {
      this.dialog.method = method
    },

    /**
     * Generate the challenge for the add new device mode
     */
    add2FAGenerateChallenge () {
      this.dialog.loading = true
      this.dialog.error = ''

      let postData = { method: this.dialog.method }
      if (this.dialog.method === 'sms') postData.phone_number = this.dialog.phone

      api.post('/api/auth/user/2fa/generate-challenge', postData, { dontReport: [400] }).then(res => {
        this.dialog.loading = false
        if (res.data.success === true) {
          this.dialog.qrCode = res.data.qr_svg
          this.dialog.number = res.data.number
          this.dialog.step += 1
          this.$nextTick(() => this.$refs['2fa-token'].focus())
        } else {
          if ('error' in res.data) this.dialog.error = res.data.error
          else if ('non_field_errors' in res.data) this.dialog.error = res.data.non_field_errors[0]
          else this.dialog.error = this.$t('error_generic')
        }
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Validate the challenge for the add new device mode
     */
    add2FAGValidateChallenge () {
      this.dialog.loading = true
      this.dialog.error = ''
      api.post('/api/auth/user/2fa/validate-challenge', { token: this.dialog.token }, { dontReport: [400] }).then(res => {
        this.dialog.loading = false
        if (res.data.success === true) {
          this.dialog.success = true
          this.load2FAMethods()
        } else {
          if ('error' in res.data) this.dialog.error = res.data.error
          else if ('token' in res.data) this.dialog.error = res.data.token[0]
          else this.dialog.error = this.$t('error_generic')
          this.$nextTick(() => this.$refs['2fa-token'].focus())
        }
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$nextTick(() => this.$refs['2fa-token'].focus())
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Confirm deletion of 2FA device
     */
    confirmDelete () {
      this.dialog.loading = true
      this.dialog.error = ''
      api.delete(`/api/auth/user/2fa/methods/${this.dialog.deleteID}`, { dontReport: [400] }).then(res => {
        this.dialog.loading = false
        if (res.data.success === true) {
          this.cancelDialog()
          this.load2FAMethods()
          this.$root.snackMsg(this.$t('2fa.delete.success'))
        } else {
          if ('error' in res.data) this.dialog.error = res.data.error
          else this.dialog.error = this.$t('error_generic')
        }
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Generate new backup codes
     */
    generateBackupCodes () {
      this.dialog.loading = true
      this.dialog.error = ''
      api.post(`/api/auth/user/2fa/backup-codes`).then(res => {
        this.dialog.loading = false
        if (res.status === 200) {
          this.$set(this.dialog, 'codes', res.data)
          this.load2FAMethods()
        } else this.dialog.error = this.$t('error_generic')
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Get the current backup codes
     */
    getBackupCodes () {
      this.dialog.loading = true
      this.dialog.error = ''
      api.get(`/api/auth/user/2fa/backup-codes`).then(res => {
        this.dialog.loading = false
        this.dialog.step = 2
        if (res.status === 200 || res.status === 204) this.$set(this.dialog, 'codes', res.data)
        else this.dialog.error = this.$t('error_generic')
      }).catch(err => {
        this.dialog.loading = false
        this.dialog.step = 2
        this.dialog.error = this.$t('error_generic')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<style lang=scss>

.two-fa {
  .existing-methods {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      padding: 8px 16px;
      margin: 4px 0;
      background: #EEE;
      border-radius: 4px;
      border-bottom: 1px solid #DDD;
      border-left: 3px solid #DDD;

      .name {
        width: 150px;
      }

      .default {
        font-weight: bold;
      }
    }
  }
}

.dialog-2fa {
  .alert-container {
    padding: 8px 16px;
    .v-alert {
      margin: 0!important;
    }
  }
  .type-select {
    display: flex;
    flex-direction: row;
    padding: 16px 24px;

    .type-option {
      box-shadow: 0 0px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      margin-right: 24px;
      flex: 1;
      transition: all 0.05s ease-in;

      &:last-child {
        margin-right: 0;
      }

      .v-card__title {
        display: block;
        background: #EEE;
        height: 100px;
      }

      .subtitle {
        font-size: 14px;
        line-height: 1.2;
        margin-top: 4px;
        font-weight: normal;
      }

      .tile-img-container {
        position: relative;
        padding: 12px 16px;
        background: #FFF;
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center;

        .v-icon {
          font-size: 8vw;
          transition: color 0.1s ease-in;
        }
      }

      &:hover {
        transform: scale(1.05, 1.05);
        z-index: 1;

        box-shadow: 0px 13px 6px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);

        .v-icon {
          color: var(--v-accent-base);
        }
      }

      &.selected {
        .v-icon { color: var(--v-accent-base); }
        .v-card__title { background: #DDD; }
        .tile-img-container { background: #EFEFEF }
        transform: scale(1.05, 1.05);
      }
    }
  }

  .phone-container {
    display: flex;
    .phone-input {
      flex: 0 0 300px;
      margin: 12px auto;
    }
  }

  .password {
    margin-top: 5px!important;
    width: 300px;
  }

  .controls-container {
    margin-top: 12px;
  }

  .validation-container {
    display: flex;
    .qr-code {
      text-align: center;
      background: #EEE;
      margin-right: 12px;
      border-radius: 4px;
      flex: 1;
      svg {
        width: 250px;
        height: 250px;
      }
    }
    .validate-input {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #EEE;
      border-radius: 4px;
      flex: 1;

      .v-input {
        background: #FFF;
        flex: 0 0 120px;
        input { text-align: center }
      }
    }
  }

  .backup-codes {
    list-style: none;
    padding: 0;
    columns: 2;
    li {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 4px 16px;
      margin-bottom: 4px;
      background: #EEE;
      border-radius: 4px;
      border: 1px solid #DDD;
      border-left: 3px solid #DDD;
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/account/Manage2FA.json' />
<i18n locale='de' src='@/i18n/de/components/account/Manage2FA.json' />