/**
* It allows to init many computed properties at once
* while all have the same arguments
* and everyone is to just call a fuction
*
* Example #1
* ...generateSigleFunctionCallComputedWithSameArgs({
*    computedName: 'method name to be called'
*  })(function () {
*    return [
*      arg1,
*      arg2
*    ]
*  })
*
*/

export const generateSigleFunctionCallComputedWithSameArgs = (listToConvert) => (args) => {
  return Object.entries(listToConvert).reduce((sum, [key, value]) => ({
    ...sum,
    [key]: function () {
      return this[value].apply(this, args.call(this)) // eslint-disable-line
    }
  }), {})
}