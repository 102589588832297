<template>
  <div class="error-state d-flex align-center justify-center text-center pt-5" :class="className">
    <div>
      <div class="error-state__icon mb-2">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.0001 6.66675V10.0001M10.0001 13.3334H10.0084M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="error-state__title font-weight-medium mb-1" v-html="title || this.$t('error')" />
      <div class="error-state__subtitle text-sm text-grey" v-html="text || this.$t('error_text')" />
      <v-btn
        v-if="action.length"
        class="codebook-editor__category__new mt-3"
        depressed
        outlined
        @click="actionClick"
      >
        {{ action }}
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'ErrorState',
  props: {
    title: { type: String, default: null },
    text: { type: String, default: null },
    className: { type: String, default: '' },
    action: { type: String, default: '' },
    actionClick: { type: Function, default: () => {} }
  },
  computed: {
  }
}

</script>

<style lang="scss">
  .error-state {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      /* Gray/100 */
      background: #FEE4E2;
      /* Gray/50 */
      border: 8px solid #FEF3F2;
      border-radius: 28px;
      margin: 0 auto;
      margin-bottom: 6px;
    }

    &__title {

    }

    &__subtitle {
      max-width: 200px;
    }
  }
</style>