<template>
  <div>
    <div class="integration-editor__title w-100 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <img src="@/assets/integrations/brandwatch.png" height="25px">
      </div>
      <v-icon
        class="hover"
        @click="$emit('close-dialog')"
        :size="20"
      >
        mdi-close
      </v-icon>
    </div>
    <div class="integration-editor__content">
      <div class="mb-4">
        <v-text-field
          v-model="integrationForm.name"
          :label="$t(`brandwatch.name`)"
          hide-details
          outlined
          dense
        />
      </div>
      <div class="mb-4">
        <v-text-field
          v-model="integrationForm.auth.username"
          :label="$t(`brandwatch.username`)"
          hide-details
          outlined
          dense
        />
      </div>
      <div class="mb-4">
        <v-text-field
          v-model="integrationForm.auth.password"
          :label="$t(`brandwatch.password`)"
          hide-details
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          outlined
          dense
        />
      </div>
      <div>
        <v-text-field
          v-model="integrationForm.auth.project"
          :label="$t(`brandwatch.project`)"
          hide-details
          outlined
          dense
        />
      </div>

      <v-alert
        v-if="error"
        type="error"
        border="left"
        text
        dense
        class="mt-4 mb-0"
      >
        {{ error }}
      </v-alert>
    </div>
    <div class="integration-editor__actions d-flex justify-end">
      <div>
        <v-btn
          outlined
          @click="$emit('close-dialog')"
          class="mr-2"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isFormValid"
          :loading="isLoading"
          @click="$emit('save-integration', integrationForm)"
        >
          {{ $t('add') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  name: 'BrandWatchIntegration',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  setup () {
    const integrationForm = reactive({
      integration: 'brandwatch',
      name: '',
      auth: {
        username: '',
        password: '',
        project: ''
      }
    })
    const showPassword = ref(false)

    const isFormValid = computed(() => {
      return integrationForm.name && integrationForm.auth.password && integrationForm.auth.project && integrationForm.auth.username
    })

    return {
      integrationForm,
      isFormValid,
      showPassword
    }
  }
})
</script>

<i18n locale='en' src='@/i18n/en/components/account/ManageIntegrations.json' />
<i18n locale='de' src='@/i18n/de/components/account/ManageIntegrations.json' />