var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dashboard",staticClass:"dashboard",class:{
    dragging: _vm.dragOrResize.active && _vm.dragOrResize.mode === 'drag',
    resizing: _vm.dragOrResize.active && _vm.dragOrResize.mode === 'resize'
  },style:({ height: `${(_vm.nRows + 2) * _vm.p2p - _vm.spacing}px` }),on:{"dragover":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onDragOrResizeOver($event)},"mousemove":function($event){return _vm.onDragOrResizeOver($event)},"mouseup":function($event){return _vm.onResizeEnd($event)}}},[(_vm.width !== -1)?[_vm._l((_vm.internalValue),function(el,elIndex){return [_vm._t("default",null,null,_vm.calculateSlotProp(elIndex))]})]:_vm._e(),_vm._v(" "),(_vm.hasEditingPermissions && _vm.internalValue.length < _vm.itemsLimit)?[_vm._l((_vm.placeholderDashboardElements),function(el,idx){return _c('div',{key:`placeholder-el-${idx}`,staticClass:"dashboard-placeholder-el",style:({
           width: `${_vm.p2p * el.width - _vm.spacing}px`,
           height: `${_vm.p2p * el.height - _vm.spacing}px`,
           left: `${_vm.p2p * el.x}px`,
           top: `${_vm.p2p * el.y}px`
         }),on:{"click":function($event){return _vm.openDialog({ x: el.x, y: el.y, width: el.width, height: el.height })}}},[_c('div',{staticClass:"dashboard-placeholder-el__text"},[_c('v-icon',{attrs:{"size":20}},[_vm._v("\n          mdi-plus\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t('add_or_edit.add_new'))+"\n      ")],1)])}),_vm._v(" "),_c('div',{staticClass:"add-hint grey--text",style:({ bottom: `${1.5 * _vm.p2p}px` })},[_c('v-icon',{attrs:{"size":22}},[_vm._v("\n        mdi-plus\n      ")]),_vm._v(" "),_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('add_hint'))+"\n      ")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }