import Vue from 'vue'

export default {
  strict: process.env.NODE_ENV !== 'production',
  state: {
    languageOptions: {
      iso: {},
      caplena_supported: [],
      google_translate: [],
      deepl: [],
      ui: []
    },
    /* translateLanguages: {}, */
    pageTitle: '',
    breadcrumbProps: {},
    pageTutorialID: null,
    errorStatus: 0,
    responsiveInfoAlertUpdate: 1,

    headwayNews: null,

    user: {
      id: '',
      loaded: false,
      isAnonymous: false,
      name: '',
      first_name: '',
      last_name: '',
      email: '',
      last_login: '',
      date_joined: '',
      app_settings: {},
      user_settings: {
        opt_out_tracking: false,
        dummy_strings: []
      },
      organization: {
        id: 0,
        name: '',
        root_user_email: '',
        root_user_name: '',
        root_user_id: '',
        user_settings: {
          opt_out_tracking: false,
          dummy_strings: []
        }
      },
      credits_remaining_recurring: 0,
      credits_remaining_oneoff: 0,
      intercom_user_hash: '',
      is_eligible_for_trial: false,
      has_sso: false,
      is_impersonated: false,
      company_name: '',
      billing_email: '',
      address_street: '',
      address_city: '',
      address_zip: '',
      address_country: '',
      language: '',
      subscription: {
        will_end: false,
        next_fillup_datetime: null,
        next_renewal_datetime: null,
        max_users: 0,
        plan_id: 0,
        plan_name: '',
        recurring: false,
        credit_refill_interval_months: 0,
        price_monthly: 0,
        features: {
          allowed_roles: [],
          api_access: false,
          visualizations_access: false,
          visualizations_whitelabel: false,
          advanced_filetypes: false,
          deepl: false,
          anonymization: false,
          max_users: 0,
          custom_roles: false,
          invoice_payment: false,
          is_public: false,
          is_self_service: false,
          credits_recurring: 0,
          alerts: 'no',
          reports: 'no',
          report_views: 'no',
          conversational_insights: 'no',
          data_import: 'no'
        },
        start: '',
        end: '',
        yearly_contract: false,
        payment_interval_months: 0,
        invoice_payment: false,
        amount_net: 0
      },
      role: {
        id: 0,
        name: '',
        permissions: [],
        owner: '',
        applicable: false
      },
      view_permissions: {
        projects_view: false,
        integrations_use: false,
        dashboards_edit: false,
        team_mgmt: false,
        subscription_mgmt: false,
        projects_create: false,
        projects_inherit: false,
        dashboards_view: false,
        projects_permissions: false,
        projects_download: false,
        projects_edit: false,
        projects_delete: false,
        projects_append: false,
        integrations_manage: false
      }
    }
  },

  mutations: {
    setLanguageOptions: (state, languageOptions) => {
      Vue.set(state, 'languageOptions', languageOptions)
    },

    setPageTitle: (state, pageTitle) => {
      state.pageTitle = pageTitle
    },

    setBreadcrumbProps: (state, props) => {
      Vue.set(state, 'breadcrumbProps', props)
    },

    setPageTutorialID: (state, pageTutorialID) => {
      state.pageTutorialID = pageTutorialID
    },

    setUser: (state, user) => {
      console.log('setUser', user)
      // Set the user, but never revert the isAnonymous flag
      Vue.set(state, 'user', { ...user, isAnonymous: state.user.isAnonymous })
    },

    setUserLoaded: (state) => { state.user.loaded = true },

    setHeadwayNews: (state, news) => { state.headwayNews = news },

    userIsAnonymous: (state) => {
      Vue.set(state.user, 'isAnonymous', true)
    },

    setUserProperty: (state, { key, value, path }) => {
      path = _.get(state.user, path) || state.user
      Vue.set(path, key, value)
    },

    setErrorStatus: (state, status) => {
      state.errorStatus = status
    }
  },

  getters: {
    /**
     * The total number of credits a user has remaining
     * Will never go to below zero, as this would lead to issues with downloading
     */
    userCreditsRemaining: state => Math.max(state.user.credits_remaining_recurring + state.user.credits_remaining_oneoff, 0)
  },

  actions: {
    setUser ({ commit, state, getters, dispatch }, properties) {
      commit('setUser', properties)
    },

    /** =========== SURVEY actions =========== **/
    updateUser ({ commit, state, getters, dispatch }, properties) {
      // Commit the changes to state
      let dontPatch = ('dontPatch' in properties) && properties.dontPatch
      if (dontPatch) delete properties['dontPatch']
      _.forOwn(properties, (value, key) => {
        commit('setUserProperty', { key, value })
      })
      if (!dontPatch) return api.patch('/api/ui/auth/user/', properties, { dontReport: [400] })
      else return true
    },

    /**
     * Toggle showing the info alert for a page or not
     * Save current state to user's profile
     */
    toggleInfoAlert ({ commit, state, getters, dispatch }, featureName = '') {

    }
  }
}
