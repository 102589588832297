<template>
  <div>
    <dialog-wrapper
      @close="closeDialog"
      v-model="internalValue"
    >
      <dialog-header>
        <template slot="title">
          {{ title }}
        </template>
        <template slot="controls">
          <v-icon
            @click="closeDialog"
            :size="20"
          >
            mdi-close
          </v-icon>
        </template>
      </dialog-header>
      <dialog-content>
        <export-data-tabs
          v-model="formData.kind"
          :sources="sources"
        />
      </dialog-content>
      <dialog-content
        class="px-4 py-5"
      >
        <export-data-form
          v-model="formData"
          :sources="sources"
        />
      </dialog-content>
      <dialog-content
        class="px-4 pt-5 pb-1"
        v-if="exports.list.length || exports.loading"
      >
        <export-data-previous-exports
          :items="exports.list"
          :loading="exports.loading"
          :kind="formData.kind"
          :shown-fully="isExportsListFullyShown"
          @download="downloadFile"
        />
      </dialog-content>
      <dialog-actions>
        <template slot="leftSide">
          <v-btn
            v-if="formData.kind === 0 && exports.list.length > 5 && !exports.isRowsListShown"
            outlined
            @click="showAllExports"
          >
            {{ $t('download_dialog.controls.expand_export_list') }}
          </v-btn>
          <v-btn
            v-if="formData.kind === 1 && exports.list.length > 5 && !exports.isTopicsListShown"
            outlined
            @click="showAllExports"
          >
            {{ $t('download_dialog.controls.expand_export_list') }}
          </v-btn>
        </template>
        <template slot="rightSide">
          <v-btn
            outlined
            @click="closeDialog"
            class="mr-2"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="generateAndDownloadNewFile"
            :loading="state.loading"
            :disabled="state.loading"
          >
            {{ $t('download_dialog.controls.download') }}
          </v-btn>
        </template>
      </dialog-actions>
    </dialog-wrapper>
  </div>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import ExportDataTabs from '@/components/coding/ExportData/ExportDataTabs'
import ExportDataForm from '@/components/coding/ExportData/ExportDataForm'
import ExportDataPreviousExports from '@/components/coding/ExportData/ExportDataPreviousExports'

import ControlsMixin from '@/components/coding/ExportData/composition/controls.js'
import DataPrefetch from '@/components/coding/ExportData/composition/dataPrefetch.js'
import FormMixin from '@/components/coding/ExportData/composition/form.js'
import GenerationMixin from '@/components/coding/ExportData/composition/generation.js'
import ExportsMixin from '@/components/coding/ExportData/composition/exports.js'
import ExecuteTillConditionMixin from '@/components/customUi/mixins/executeTillCondition.js'

import DataMixin from '@/components/customUi/mixins/data'
import ApiTaskMixin from '@/mixins/apitask'

export default {
  codit: true,
  name: 'DownloadDialog',

  components: {
    'dialog-wrapper': DialogWrapper,
    'dialog-header': DialogHeader,
    'dialog-content': DialogContent,
    'dialog-actions': DialogActions,
    'export-data-tabs': ExportDataTabs,
    'export-data-form': ExportDataForm,
    'export-data-previous-exports': ExportDataPreviousExports
  },

  mixins: [
    ControlsMixin,
    DataPrefetch,
    FormMixin,
    GenerationMixin,
    ExportsMixin,
    ExecuteTillConditionMixin,
    DataMixin,
    ApiTaskMixin
  ],

  props: {
    value: { type: Boolean, default: false }
  }
}

</script>

<style lang="scss">
@import '@/css/override.scss';
</style>

<i18n locale='en' src='@/i18n/en/components/ExportData.json' />
<i18n locale='de' src='@/i18n/de/components/ExportData.json' />