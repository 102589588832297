import {
  CHART_TYPES_TO_API_ENUM,
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_ROWS_COUNT_BAR,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_DRIVER_SCATTER,
  CHART_UNIQUE_DRIVER_HEAT,
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_SENTIMENT,
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_DYNAMIC_BAR
} from '@/settings/constants'

export const icons = {
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_BAR]]: 'mdi-chart-bar',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_LINE_PIE]]: 'mdi-chart-bell-curve',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DATE_LINE_PIE]]: 'mdi-chart-bell-curve',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_ROWS_COUNT_BAR]]: 'mdi-chart-bell-curve',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_TREEMAP]]: 'mdi-chart-tree',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_GRAPH]]: 'mdi-graphql',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DRIVER_SCATTER]]: 'mdi-car',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DRIVER_HEAT]]: 'mdi-car',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SCORE]]: 'mdi-gauge-low',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SENTIMENT]]: 'mdi-gauge-low',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SENTIMENT_BAR]]: 'mdi-chart-bar',
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DYNAMIC_BAR]]: 'mdi-chart-bar'
}
export const badges = {
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_BAR]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_LINE_PIE]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DATE_LINE_PIE]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_ROWS_COUNT_BAR]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_TREEMAP]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_GRAPH]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DRIVER_SCATTER]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DRIVER_HEAT]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SCORE]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SENTIMENT]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SENTIMENT_BAR]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DYNAMIC_BAR]]: null
}
export const badgeImages = {
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_BAR]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_LINE_PIE]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DATE_LINE_PIE]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_TREEMAP]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_GRAPH]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DRIVER_SCATTER]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DRIVER_HEAT]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SCORE]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SENTIMENT]]: null,
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_SENTIMENT_BAR]]: require('@/assets/icons/satisfaction.svg'),
  [CHART_TYPES_TO_API_ENUM[CHART_UNIQUE_DYNAMIC_BAR]]: null
}

export default {
  icons,
  badges,
  badgeImages
}