<template>
  <div>
    <v-navigation-drawer
      :value="codingDrawerOpen"
      @close="handleDrawerClose"
      v-c-click-outside="maybeHandleDrawerClose"
      class="coding__drawer"
      width="590"
      right
      :absolute="true"
      :fixed="false"
      hide-overlay
      stateless
    >
      <div
        class="h-100 d-flex flex-column justify-space-between coding__drawer__container"
        v-if="codingDrawerOpen && !bulkAssign"
      >
        <div class="coding__drawer__container__coding">
          <v-btn
            icon
            small
            @click="handleDrawerClose"
            class="coding__drawer__close"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
          <div class="coding__drawer__padding coding__drawer__container__coding__review" ref="reviewContainer">
            <div
              v-if="!isListColumn"
              class="coding__drawer__sentiment d-flex align-center"
            >
              <div>
                <sentiment-icon
                  :sentiment="activeRow.codingColumn.sentiment_overall"
                />
              </div>
              <div
                class="ml-2 coding__drawer__sentiment__text"
                :style="{
                  color: getColorForSentiment(
                    activeRow.codingColumn.sentiment_overall
                  )
                }"
              >
                {{ activeRow.codingColumn.sentiment_overall }}
              </div>
            </div>
            <div class="coding__drawer__review">
              {{
                shouldShowTranslatedValue
                  ? activeRow.codingColumn.translated_value
                  : activeRow.codingColumn.value
              }}
              <div
                class="d-flex align-center justify-end"
                style="position: relative; left: 45px;"
              >
                <div
                  v-if="
                    shouldShowTranslatedValue &&
                      activeRow.codingColumn.translated_value
                  "
                  class="coding__drawer__translate d-flex align-center"
                >
                  <svg
                    class="mr-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.499 13.5L10.999 6.5L7.49902 13.5"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.49902 11.5H13.499"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.49902 2V3.5"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.49902 3.5H9.49902"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.49902 3.5C7.49902 5.0913 6.86688 6.61742 5.74166 7.74264C4.61645 8.86786 3.09032 9.5 1.49902 9.5"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.04395 6C4.52307 7.04397 5.29176 7.9285 6.25871 8.54854C7.22567 9.16857 8.35026 9.49806 9.49893 9.49788"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    class="coding__row__auxiliaries__value coding__row__auxiliaries__value--clickable text-grey"
                    @click.stop="setTranslateRowOverwrite(activeRow, false)"
                  >
                    {{ $t("see_original") }} ({{
                      languageOptions.iso[
                        activeRow.codingColumn.source_language
                      ]
                    }})
                  </div>
                </div>
                <div
                  v-else-if="activeRow.codingColumn.translated_value"
                  class="coding__drawer__translate d-flex align-center"
                >
                  <svg
                    class="mr-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.499 13.5L10.999 6.5L7.49902 13.5"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.49902 11.5H13.499"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.49902 2V3.5"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.49902 3.5H9.49902"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.49902 3.5C7.49902 5.0913 6.86688 6.61742 5.74166 7.74264C4.61645 8.86786 3.09032 9.5 1.49902 9.5"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.04395 6C4.52307 7.04397 5.29176 7.9285 6.25871 8.54854C7.22567 9.16857 8.35026 9.49806 9.49893 9.49788"
                      stroke="#708189"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    class="coding__row__auxiliaries__value coding__row__auxiliaries__value--clickable text-grey"
                    @click.stop="setTranslateRowOverwrite(activeRow, true)"
                  >
                    {{ $t("see_translated") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="coding__drawer__padding coding__drawer__container__coding__topics"
          >
            <topic-select
              :value="topicSelectValue"
              allow-new
              :editable="editable"
              :blocked-by-modals="blockedByModals"
              @update="onRowUpdate"
              @open="$store.commit('setCodingDrawerDropdownOpen', $event)"
              @next="
                $store.commit('setActiveRow', getRowByIndex(activeRowIndex + 1))
              "
              @prev="
                $store.commit('setActiveRow', getRowByIndex(activeRowIndex - 1))
              "
              :id="id"
            />
            <div
              class="coding__drawer__translate d-flex align-center justify-end text-grey mt-0"
              @click.stop="markAsReviewedAndNext(activeRow)"
              v-if="!activeRow.codingColumn.was_reviewed && editable"
            >
              <svg
                class="mr-1"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.00025 8.00057C2.73625 8.00057 2.48125 7.89657 2.29325 7.70757L0.29325 5.70757C-0.09775 5.31657 -0.09775 4.68457 0.29325 4.29357C0.68425 3.90257 1.31625 3.90257 1.70725 4.29357L2.84525 5.43157L6.16825 0.445572C6.47425 -0.0144275 7.09425 -0.138428 7.55525 0.168572C8.01425 0.475572 8.13825 1.09557 7.83225 1.55557L3.83225 7.55557C3.66625 7.80457 3.39625 7.96657 3.09925 7.99557C3.06525 7.99857 3.03325 8.00057 3.00025 8.00057Z"
                  fill="#00C58D"
                />
              </svg>
              {{ $t("mark_reviewed_next") }}
            </div>
            <div
              v-else-if="activeRow.codingColumn.was_reviewed && editable"
              class="coding__drawer__translate d-flex align-center justify-end text-grey mt-0"
              @click.stop="markAsReviewedAndNext(activeRow)"
            >
              {{ $t("keyboard_shortcuts.next") }}
            </div>
          </div>
        </div>
        <div v-if="activeRow.otherColumns.length" class="coding__drawer__padding coding__drawer__auxilaries">
          <div
            v-for="(column, index) in activeRow.otherColumns"
            class="coding__drawer__auxiliary"
            :class="index === activeRow.otherColumns.length - 1 && 'mb-0 pb-3'"
            :key="index"
          >
            <div class="coding__drawer__auxiliary__title">
              {{ getColumnName(column.ref) }}
            </div>
            <div :class="`coding__drawer__auxiliary__value ${isValidLink(column.value) && 'coding__row__auxiliaries__value--clickable'}`"
                 @click="handleAuxiliaryClick(column.value)"
            >
              {{ $_.isNil(column.value) || column.value === '' ? '-' : column.value }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="codingDrawerOpen && bulkAssign">
        <v-btn
          icon
          small
          @click="handleDrawerClose"
          class="coding__drawer__close"
        >
          <v-icon color="black" :size="21">
            mdi-close
          </v-icon>
        </v-btn>
        <div class="coding__drawer__padding font-weight-medium text-sm">
          {{ $t("bulk_assign.bulk_assign") }} ({{
            allRowsSelected ? dummyRows.length : activeRow.length
          }})
        </div>
        <div
          class="coding__drawer__padding d-flex justify-center text-sm text-grey"
        >
          {{ $t("bulk_assign.instructions") }}
        </div>
        <div class="coding__drawer__padding position-relative" style="z-index: 4;">
          <topic-select
            v-model="bulkTopicSelectValue"
            :blocked-by-modals="blockedByModals"
            @update="onBulkRowUpdate"
            sync
            allow-new
            :id="id"
          />
        </div>
        <div class="coding__drawer__padding">
          <div class="text-sm font-weight-medium mb-2">
            {{ $t("bulk_assign.mode") }}
          </div>
          <div class="coding__drawer__switch">
            <v-switch
              :input-value="bulkAssignOptions.mode === 'add'"
              @change="
                value =>
                  $store.commit('setBulkAssignOption', {
                    key: 'mode',
                    value: value ? 'add' : 'replace'
                  })
              "
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--description w-100"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <div class="text-sm font-weight-medium text-color mb-1">
                    {{ $t("bulk_assign.add") }}
                  </div>
                  <div
                    class="text-grey text-sm pr-2"
                    v-html="$t('bulk_assign.add_helptip')"
                  />
                </div>
              </template>
            </v-switch>
          </div>

          <div class="mt-3 coding__drawer__switch">
            <v-switch
              :input-value="bulkAssignOptions.mode === 'replace'"
              @change="
                value =>
                  $store.commit('setBulkAssignOption', {
                    key: 'mode',
                    value: value ? 'replace' : 'add'
                  })
              "
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--description w-100 "
              hide-details
            >
              <template v-slot:label>
                <div>
                  <div class="text-sm font-weight-medium text-color mb-1">
                    {{ $t("bulk_assign.replace") }}
                  </div>
                  <div
                    class="text-grey text-sm pr-2"
                    v-html="$t('bulk_assign.replace_helptip')"
                  />
                </div>
              </template>
            </v-switch>
          </div>
        </div>
        <div class="coding__drawer__padding">
          <div class="text-sm font-weight-medium mb-2">
            {{ $t("bulk_assign.review_status") }}
          </div>
          <div class="coding__drawer__switch">
            <v-switch
              :input-value="bulkAssignOptions.reviewed === null"
              @change="
                value =>
                  $store.commit('setBulkAssignOption', {
                    key: 'reviewed',
                    value: null
                  })
              "
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--description w-100"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <div class="text-sm font-weight-medium text-color mb-1">
                    {{ $t("bulk_assign.reviewed_unset") }}
                  </div>
                  <div
                    class="text-grey text-sm pr-2"
                    v-html="$t('bulk_assign.reviewed_unset_helptip')"
                  />
                </div>
              </template>
            </v-switch>
          </div>
          <div class="coding__drawer__switch mt-3">
            <v-switch
              :input-value="bulkAssignOptions.reviewed === 'set_reviewed'"
              @change="
                value =>
                  $store.commit('setBulkAssignOption', {
                    key: 'reviewed',
                    value: 'set_reviewed'
                  })
              "
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--description w-100"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <div class="text-sm font-weight-medium text-color mb-1">
                    {{ $t("bulk_assign.set_reviewed") }}
                  </div>
                  <div
                    class="text-grey text-sm pr-2"
                    v-html="$t('bulk_assign.set_reviewed_helptip')"
                  />
                </div>
              </template>
            </v-switch>
          </div>
          <div class="coding__drawer__switch mt-3">
            <v-switch
              :input-value="bulkAssignOptions.reviewed === 'set_unreviewed'"
              @change="
                value =>
                  $store.commit('setBulkAssignOption', {
                    key: 'reviewed',
                    value: 'set_unreviewed'
                  })
              "
              inset
              color="green"
              flat
              dense
              :ripple="false"
              class="v-input--description w-100"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <div class="text-sm font-weight-medium text-color mb-1">
                    {{ $t("bulk_assign.set_unreviewed") }}
                  </div>
                  <div
                    class="text-grey text-sm pr-2"
                    v-html="$t('bulk_assign.set_unreviewed_helptip')"
                  />
                </div>
              </template>
            </v-switch>
          </div>
        </div>
        <div
          class="coding__drawer__padding coding__drawer__padding--borderless d-flex justify-space-between"
        >
          <v-tooltip bottom :disabled="isGroupingDuplicates ? false : (allRowsSelected ? dummyRows.length < MAX_DELETABLE_ROWS : activeRow.length < MAX_DELETABLE_ROWS) ">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn color="error" @click="confirmDeleteDialog = true" :disabled="isGroupingDuplicates || (allRowsSelected ? dummyRows.length >= MAX_DELETABLE_ROWS : activeRow.length >= MAX_DELETABLE_ROWS)">
                  {{ $t("bulk_assign.delete", { count: allRowsSelected ? dummyRows.length : activeRow.length }) }}
                </v-btn>
              </div>
            </template>
            <span>{{ isGroupingDuplicates ? $t('bulk_assign.grouping_duplicates') : $t('bulk_assign.max_delete', { n: MAX_DELETABLE_ROWS }) }}</span>
          </v-tooltip>
          <div>
            <v-btn
              outlined
              @click="handleDrawerClose"
              class="mr-2"
              style="border-color: #DAEAF2;"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="
                bulkAssignOptions.mode === 'replace'
                  ? (confirmDialog = true)
                  : applyBulkAssignOptions()
              "
            >
              {{ $t("apply") }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <ConfirmDeleteDialog
      v-if="confirmDeleteDialog"
      :open="confirmDeleteDialog"
      @cancel="confirmDeleteDialog = false"
      @confirm="deleteSelectedRows"
      :count="allRowsSelected ? dummyRows.length : activeRow.length"
    />
    <ConfirmReplaceDialog
      :open="confirmDialog"
      @cancel="confirmDialog = false"
      @confirm="applyBulkAssignOptions"
    />
  </div>
</template>

<script>
import Vuex from 'vuex'
import Sentiment from '@/components/coding/Sentiment'
import TopicSelect from '@/components/coding/TopicSelectv2'
import ConfirmDeleteDialog from '@/components/coding/ConfirmDeleteDialog'
import ConfirmReplaceDialog from '@/components/coding/ConfirmReplaceDialog'
import { createQueryStringFromObj } from '@/utils/filters'
import { mapGettersWithKey } from '@/utils/vuex.js'
import ApiTaskMixin from '@/mixins/apitask'
import { isValidLink } from '@/utils/funcs'

const MAX_DELETABLE_ROWS = 10000

export default {
  codit: true,
  name: 'CodingDrawer',

  components: {
    'sentiment-icon': Sentiment,
    TopicSelect,
    ConfirmDeleteDialog,
    ConfirmReplaceDialog
  },

  mixins: [ApiTaskMixin],

  props: {
    blockedByModals: { type: Boolean, default: false },

    id: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      bulkTopicSelectValue: [],
      confirmDialog: false,
      confirmDeleteDialog: false,
      topicsMaxHeight: 240,
      MAX_DELETABLE_ROWS,
      isValidLink
    }
  },

  computed: {
    ...Vuex.mapState({
      activeRowIndex: state => state.coding.activeRowIndex,
      activeRowID: state => state.coding.activeRowID,
      refetchAfterRowChange: state => state.coding.refetchAfterRowChange,
      project: state => state.coding.project,
      allRowsSelected: state => state.coding.allRowsSelected,
      bulkAssignOptions: state => state.coding.bulkAssignOptions,
      newTopicDialog: state => state.coding.newTopicDialog,
      editable: state => state.coding.editable,
      languageOptions: state => state.languageOptions,
      isGroupingDuplicates: state => state.coding.isGroupingDuplicates
    }),

    ...Vuex.mapGetters([
      'activeRow',
      'codingDrawerOpen',
      'topicID2Topic',
      'showTranslations',
      'bulkAssign',
      'getRowByIndex',
      'getRowByID',
      'isListColumn',
      'rowsPerPage'
    ]),

    ...mapGettersWithKey({
      dummyRows: 'verbatimManager/dummyRows',
      rowData: 'verbatimManager/rowData'
    })(() => 'ch__new'),

    topicSelectValue () {
      return this.activeRow.codingColumn.topics
    },

    /**
     * If the translated row value should be shown
     */
    shouldShowTranslatedValue () {
      if (this.activeRow.translateOverwritten === true) return true
      if (this.activeRow.translateOverwritten === false) return false
      if (this.showTranslations && this.activeRow.codingColumn.translated_value) { return true }
      return false
    },

    getTopicsHeight () {
      return window.innerHeight - 80 - this.topicsMaxHeight
    }
  },

  watch: {
    codingDrawerOpen (value) {
      if (!value) {
        if (this.refetchAfterRowChange) this.reload()
        this.$store.commit('setCodingDrawerDropdownOpen', false)
      }
    },

    'bulkAssignOptions.newTopic' (val, oldVal) {
      if (val && val !== oldVal) {
        this.bulkTopicSelectValue = [...this.bulkTopicSelectValue, val]
        this.$store.commit('setBulkAssignOption', {
          key: 'newTopic',
          value: null
        })
      }
    },

    activeRowIndex (val, oldVal) {
      if (val !== oldVal && this.refetchAfterRowChange) {
        this.$store.commit('setRefetchAfterRowChange', false)
        this.reload(this.rowData.meta.count)
      }
    }
  },

  updated () {
    this.topicsMaxHeight = this.$refs.reviewContainer?.offsetHeight
  },

  methods: {
    /**
     * Reload all rows for all loaded pages
     */
    async reload (oldTotalCount) {
      await Promise.all(_.map(this.rowsPerPage, (value, page) => this.$store.dispatch(
        'verbatimManager/loadRows',
        {
          id: 'ch__new',
          scrolledPage: page,
          queryString: createQueryStringFromObj(this.$router.currentRoute.query),
          soft: true
        },
        { root: true }
      )))
        .then(() => {
          if (oldTotalCount !== this.rowData.meta.count) {
            // Scroll to top
            // Argument pro: When having filtered for non-reviewed only
            // we could be in the middle of the scrollable list and the
            // actual current position is useless (could be showing
            // totally other rows than before, order is sort of irrelevant).
            // Argument contra: When going through all revieweds of a topic
            // again and having filtered for this topic, then an update
            // could also trigger a change, but we actually want to stay
            // near the row we were at.
          } if (this.codingDrawerOpen && !this.activeRow) {
            this.handleDrawerClose()
          }

          this.$root.snackMsg(this.$t('rows_updated'))
        })
    },

    /**
     * Manually set the row as reviewed by user
     */
    markAsReviewedAndNext (row) {
      this.$store.dispatch('setRowReviewStatus', {
        id: row.id,
        reviewed: true
      })
      this.$store.commit(
        'setActiveRow',
        this.getRowByIndex(this.activeRowIndex + 1)
      )
    },

    /**
     * Overwrites the translate row settings for given row
     */
    setTranslateRowOverwrite (row, overwrite) {
      this.$store.commit('setRowTranslateOverwritten', { row, overwrite })
    },

    /**
     * Get the displayed column name by ref
     */
    getColumnName (ref) {
      return _.find(this.project.columns, column => column.ref === ref).name
    },

    /**
     * Maybe close the coding drawer on outside click.
     * Return false if new topic dialog open
     */
    maybeHandleDrawerClose () {
      if (this.blockedByModals) return
      this.handleDrawerClose()
    },

    /**
     * Close the coding drawer
     */
    handleDrawerClose () {
      this.$store.commit('setActiveRow', null)
      this.$store.commit('setAllRowsSelected', false)
    },

    handleAuxiliaryClick (value) {
      const valid = isValidLink(value)

      if (!valid) return

      window.confirm(this.$t('leave_page_confirm'))
      window.open(value, '_blank', 'noopener,noreferrer')
    },

    /**
     * Update the rows was_reviewed property
     */
    onRowUpdate () {
      this.$store.dispatch('maybeSetActiveRowAsReviewed')
      this.$store.dispatch('handleActiveRowChange')
    },

    /**
     * Handle bulk row change
     */
    onBulkRowUpdate (value) {
      this.bulkTopicSelectValue = value
    },

    applyBulkAssignOptions () {
      // Run bulk assign here
      this.$store
        .dispatch('applyBulkCoding', {
          topics: this.bulkTopicSelectValue,
          queryString: createQueryStringFromObj(this.$router.currentRoute.query)
        })
        .then(() => {
          this.$root.snackMsg(this.$t('bulk_assign.complete'))
          this.bulkTopicSelectValue = []
        })

      if (this.confirmDialog) this.confirmDialog = false
    },

    deleteSelectedRows () {
      this.$store.dispatch('deleteSelectedRows', {
        queryString: createQueryStringFromObj(this.$router.currentRoute.query),
        asyncCb: this.asyncCb
      })
    },

    asyncCb (res) {
      this.getApiTaskStatus(res.headers['task-id'], parseFloat(res.headers['task-eta']))
        .then(() => this.afterDelete())
        .catch(err => {
          this.$store.commit('setBulkAssignOption', { key: 'failedDeletion', value: true })
          this.$store.commit('setBulkAssignOption', { key: 'loading', value: false })

          throw err
        })
    },

    afterDelete () {
      this.$root.snackMsg(this.$t('bulk_assign.delete_complete'))

      this.$store.commit('setBulkAssignOption', { key: 'loading', value: false })

      this.$store.dispatch('verbatimManager/setLoadingRowsLock', { entityId: this.id, value: false })
      this.$store.dispatch('hardRefetchRows', { queryString: createQueryStringFromObj(this.$router.currentRoute.query) })

      this.confirmDeleteDialog = false
      this.handleDrawerClose()
    },

    /**
     * Get color for sentiment
     */
    getColorForSentiment (sentiment) {
      if (sentiment === 'negative') return '#FF5151'
      if (sentiment === 'positive') return '#00C58D'
      return '#708189'
    }
  }
}
</script>

<i18n locale="en" src="@/i18n/en/pages/Codingv2.json" />
<i18n locale="de" src="@/i18n/de/pages/Codingv2.json" />