<template>
  <div class="w-100 summary-viewer">
    <div
      v-if="loadingSummary"
      class="pa-4 d-flex align-center justify-center h-100"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
    <div v-else-if="summaryError">
      <error-state
        :title="$t('verbatim.summary.error_title')"
        :text="summaryError"
        :action="$t('actions.retry')"
        :action-click="fetchData"
      />
    </div>

    <template v-else-if="summary">
      <div class="d-flex justify-space-between align-center px-4 mt-4">
        <div class="text-body-2 font-weight-medium">
          {{ $tc('verbatim.summary.count', summary.n_rows_considered, {n_rows_considered: summary.n_rows_considered}) }}
        </div>
        <div @click="copySummary" class="ml-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon size="16">
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </template>
            {{ $t('verbatim.controls.copy_to_clipboard') }}
          </v-tooltip>
        </div>
      </div>
      <MarkdownRenderer
        :text="summary.summary"
        class="text-body-2 pa-4 summary-viewer__markdown"
      />
    </template>
  </div>
</template>

<script>
import MarkdownRenderer from '@/components/visualize/MarkdownRenderer'
import { generateInject, injectComputedValues } from '@/components/customUi/mixins/provideInject'
import VerbatimBrowservQueryParserMixin from '@/components/VerbatimBrowserv2/mixins/VerbatimBrowservQueryParser.js'
import { copyTextToClipboard } from '@/utils/copyToClipboard.js'
import removeMd from 'remove-markdown'

import axios from 'axios'

export default {
  components: {
    MarkdownRenderer
  },

  mixins: [
    VerbatimBrowservQueryParserMixin
  ],

  props: {
    id: {
      type: [String, null],
      default: null,
      required: true
    },
    queryParams: {
      type: [Object],
      default: () => ({})
    },
    extraQueryParams: {
      type: [Object],
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      summary: null,
      summaryError: null,

      cancelTokens: {
        summary: null
      }
    }
  },

  inject: generateInject(['loadingSummary']),

  computed: {
    ...injectComputedValues(['loadingSummary'])
  },

  watch: {
    'queryParams.filters' (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) this.init()
    },
    'config.filters' (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) this.init()
    }
  },

  mounted () {
    this.init()
  },

  beforeDestroy () {
    this.cancelTokens.summary?.cancel()
  },

  methods: {
    copySummary () {
      copyTextToClipboard.call(this, removeMd(this.summary.summary))
    },
    init () {
      this.cancelTokens.summary?.cancel()

      this.fetchData()
    },
    async fetchData () {
      this.cancelTokens.summary = axios.CancelToken.source()
      this.loadingSummary = true
      this.summaryError = false

      try {
        let { data } = await api.get(
          `api/ui/charts/${this.id}/rows/summary${this.getQueryParams()}`,
          {
            cancelToken: this.cancelTokens.summary.token
          }
        )
        this.summary = data
        this.loadingSummary = false
      } catch (error) {
        this.loadingSummary = false
        this.summaryError = error.response?.data?.message || this.$t('verbatim.summary.error')
      }
    }
  }
}
</script>

<style lang="scss">
  .summary-viewer{
    &__markdown{
      ul, ol, h1, h2, h3 {
        margin-bottom: 16px;
      }
      hr {
        margin-top: 16px;
        margin-bottom: 16px;
      }
      & > *:last-child{
        margin-bottom: 0 !important;
      }
    }
  }
</style>

<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />