import { render, staticRenderFns } from "./ChartScaffold.vue?vue&type=template&id=0e0bcc52"
import script from "./ChartScaffold.vue?vue&type=script&lang=js"
export * from "./ChartScaffold.vue?vue&type=script&lang=js"
import style0 from "./ChartScaffold.vue?vue&type=style&index=0&id=0e0bcc52&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/components/visualize/ChartGlobals.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fvisualize%2FChartScaffold.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/components/visualize/ChartGlobals.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fcomponents%2Fvisualize%2FChartScaffold.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)

export default component.exports