import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import Home from '@/pages/Home'
import Account from '@/pages/Account'
import Subscription from '@/pages/Subscription'
import RequestTrial from '@/pages/RequestTrial'
import Codingv2 from '@/pages/Codingv2'
import Wizard from '@/pages/Wizard'
import Wizardv2 from '@/pages/Wizardv2'
import Cockpit from '@/pages/Cockpit'
import Upload from '@/pages/Upload'
import Append from '@/pages/Append'
import ReplaceAuxiliaries from '@/pages/ReplaceAuxiliaries'
import ProjectsManage from '@/pages/ProjectsManage'
import ChartsManage from '@/pages/ChartsManage'
import DashboardsManage from '@/pages/DashboardsManage'
import ChartEditor from '@/pages/ChartEditor'
import Dashboard from '@/pages/Dashboard'
import PageNotFound from '@/pages/PageNotFound'
import AccessDenied from '@/pages/AccessDenied.vue'

import { store } from '@/store'
import WizardStore from '@/store/Wizard'
import WizardStorev2 from '@/store/Wizardv2'
import UploadStore from '@/store/Upload'
import CodingStore from '@/store/Coding'
import CodingStorev2 from '@/store/Codingv2'
import Dashboardv2 from '@/store/Dashboardv2'

Vue.use(Router)
Vue.use(Meta)

let router = new Router({
  mode: 'history',
  base: '/app',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        breadcrumbName: function (props) { return this.$t('breadcrumbs.home') }
      }
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        allowDashboardOnlyUser: true,
        breadcrumbName: function (props) { return this.$t('breadcrumbs.account') }
      }
    },
    {
      path: '/account/subscription',
      name: 'subscription',
      component: Subscription,
      meta: {
        breadcrumbs: ['account'],
        breadcrumbName: function (props) { return this.$t('breadcrumbs.subscription') }
      }
    },

    {
      path: '/account/subscription/request-trial',
      name: 'request-trial',
      component: RequestTrial,
      meta: {
        breadcrumbs: ['account'],
        breadcrumbName: function (props) { return this.$t('breadcrumbs.subscription') }
      }
    },

    {
      path: '/cockpit/:id',
      name: 'question-cockpit',
      component: Cockpit,
      meta: {
        store: { name: 'coding', module: CodingStore },
        breadcrumbs: ['projects-manage', 'projects-manage-id'],
        breadcrumbName: function (props) { return props.questionName },
        breadcrumbParams: function (props) { return !props.questionID ? false : { id: props.questionID } }
      }
    },

    {
      path: '/coding/:id',
      name: 'question-coding',
      redirect: to => {
        return {
          name: 'question-cockpit',
          params: { id: to.params.id }
        }
      }
    },

    {
      path: '/projects/:id/coding/:ref',
      name: 'coding-v2',
      component: Codingv2,
      meta: {
        store: { name: 'coding', module: CodingStorev2 },
        breadcrumbs: ['projects-manage', 'projects-manage-id', 'question-cockpit'],
        breadcrumbName: function (props) { return this.$t('breadcrumbs.coding') }
        // breadcrumbParams: function (props) { return !props.questionID ? false : { id: props.questionID } }
      }
    },

    {
      path: '/wizard/:id',
      name: 'question-wizard',
      component: Wizard,
      meta: {
        store: { name: 'wizard', module: WizardStore },
        breadcrumbs: ['projects-manage', 'projects-manage-id', 'question-cockpit'],
        breadcrumbName: function (props) { return this.$t('breadcrumbs.wizard', { question: props.questionName }) },
        breadcrumbParams: function (props) { return !props.questionID ? false : { id: props.questionID } }
      }
    },

    {
      path: '/projects/:id/wizard/:ref',
      name: 'question-wizard-v2',
      component: Wizardv2,
      meta: {
        store: { name: 'wizard', module: WizardStorev2 },
        breadcrumbs: ['projects-manage', 'projects-manage-id', 'question-cockpit'],
        breadcrumbName: function (props) { return this.$t('breadcrumbs.wizard', { question: props.questionName }) },
        breadcrumbParams: function (props) { return !props.questionID ? false : { id: props.questionID } }
      }
    },

    {
      path: '/upload',
      name: 'upload',
      component: Upload,
      meta: {
        store: { name: 'upload', module: UploadStore },
        breadcrumbName: function (props) { return this.$t('breadcrumbs.upload') }
      }
    },
    {
      path: '/projects/:id/append',
      name: 'project-append',
      component: Append,
      meta: {
        store: { name: 'upload', module: UploadStore },
        breadcrumbs: ['projects-manage', 'projects-manage-id'],
        breadcrumbName: function (props) {
          return this.$t('breadcrumbs.append', { project: props.projectName })
        },
        breadcrumbParams: function (props) {
          return !props.projectID ? false : { id: props.projectID }
        }
      }
    },
    {
      path: '/projects/:id/replace-auxiliaries',
      name: 'project-replace-auxiliaries',
      component: ReplaceAuxiliaries,
      meta: {
        store: { name: 'upload', module: UploadStore },
        breadcrumbs: ['projects-manage', 'projects-manage-id'],
        breadcrumbName: function (props) { return this.$t('breadcrumbs.replace-auxiliaries', { project: props.projectName }) },
        breadcrumbParams: function (props) { return !props.projectID ? false : { id: props.projectID } }
      }
    },
    {
      path: '/projects',
      name: 'projects-manage',
      component: ProjectsManage,
      meta: {
        breadcrumbName: function (props) { return this.$t('breadcrumbs.projects-manage') }
      }
    },
    {
      path: '/projects/:id',
      name: 'projects-manage-id',
      component: ProjectsManage,
      meta: {
        breadcrumbs: ['projects-manage'],
        breadcrumbName: function (props) { return props.projectName },
        breadcrumbParams: function (props) { return !props.projectID ? false : { id: props.projectID } }
      }
    },

    // Visualizations
    {
      path: '/charts',
      name: 'charts-manage',
      component: ChartsManage,
      meta: {
        breadcrumbName: function (props) { return this.$t('breadcrumbs.charts-manage') }
      }
    },
    {
      path: '/charts/:id',
      name: 'chart-details',
      component: ChartEditor,
      meta: {
        store: { name: 'coding', module: CodingStorev2 },
        breadcrumbs: ['charts-manage'],
        breadcrumbName: function (props) { return props.chartName || '' },
        breadcrumbParams: function (props) { return !props.chartID ? false : { id: props.chartID } },
        breadcrumbQuery: function (props) { return props.questionID ? { question: props.questionID } : {} }
      }
    },

    {
      path: '/dashboards',
      name: 'dashboards-manage',
      component: DashboardsManage,
      meta: {
        allowDashboardOnlyUser: true,
        breadcrumbName: function (props) { return this.$t('breadcrumbs.dashboards-manage') }
      }
    },
    {
      path: '/dashboards/:id',
      name: 'dashboard-details',
      component: Dashboard,
      meta: {
        allowAnonymous: true,
        allowDashboardOnlyUser: true,
        store: { name: 'coding', module: Dashboardv2 },
        breadcrumbs: ['dashboards-manage'],
        breadcrumbName: function (props) { return props.dashboardName || '' },
        breadcrumbParams: function (props) { return !props.dashboardID ? false : { id: props.dashboardID } },
        breadcrumbQuery: function (props) { return props.charts ? { charts: props.charts } : {} }
      }
    },

    // the default route, when none of the above matches:
    {
      path: '*',
      name: 'not-found',
      component: PageNotFound,
      meta: {
        breadcrumbName: function (props) { return '404' }
      }
    },

    // The access denied route, which doesn't have a path either
    {
      path: '*',
      name: 'access-denied',
      component: AccessDenied,
      meta: {
        allowAnonymous: true,
        breadcrumbName: function (props) { return '403' }
      }
    }
  ]
})

/* router.beforeEach((to, from, next) => {
  // Check if a dynamic module should be loaded
  if (to.name in DynamicModules) store.registerModule(to.name, DynamicModules[to.name])
  next()
})
*/

/**
 * Returns true if the page should be accessible as anonymous user
 * Can be true if either
 * * The current route allows anonymous access or,
 * * The next route allows anonymous access, but it isn't loaded yet as the user has not loaded yet
 * @return {Boolean}
 */
router.allowAnonymous = function () {
  return this.currentRoute.meta.allowAnonymous || this._tmpAllowAnonymous
}

router.beforeEach((to, from, next) => {
  // main redirect for people not allowed to see all information
  if (
    router.app?.isDashboardOnlyUser &&
    !to.meta.allowDashboardOnlyUser &&
    from.name === 'dashboards-manage'
  ) {
    return
  } else if (
    router.app?.isDashboardOnlyUser &&
    !to.meta.allowDashboardOnlyUser
  ) {
    router.replace({
      name: 'dashboards-manage'
    })
  }

  // Only do these things if we're not actually on the same route
  if (from.name !== to.name) {
    // Check if there are stores defined for the from or to route
    // Load and unload those store modules respectively
    if (from.meta.store) {
      // If there is a `onUnload` action in the store, call it before unloading the store
      if (_.keys(store._actions).findIndex(key => key === 'onUnload') !== -1) store.dispatch('onUnload')
      store.unregisterModule(from.meta.store.name)
    }
    if (to.meta.store) store.registerModule(to.meta.store.name, to.meta.store.module)

    store.commit('setPageTitle', '')
    store.commit('setPageTutorialID', null)
    store.commit('setBreadcrumbProps', {})
  }

  store.commit('setErrorStatus', 0)
  // we must wait for the initial loading of the user
  if (
    store.state.user.id === '' &&
    !store.state.user.loaded
  ) {
    if (to.meta.allowAnonymous) router._tmpAllowAnonymous = true
    store.watch(
      (state) => state.user.loaded,
      (value) => {
        // Go to the actual route if either
        // * We have a valid, authenticated user or
        // * The required route allows anonymous access
        if (value && (store.state.user.id !== '' || to.meta.allowAnonymous)) next()
        router._tmpAllowAnonymous = false
      }
    )
  } else if (
    store.state.user.id !== '' || to.meta.allowAnonymous
  ) {
    next()
  }
})

router.afterEach((to, from) => {
  // When navigating to a new route, make userpilot aware of this
  // Give it some time, as the page may need a little to render
  if (from && from.name) setTimeout(() => window.userpilot && window.userpilot.reload(), 500)
  // Track segment page view, except the initial one
  let posthog = router.app.$root.phclient
  if (posthog) posthog.capture('$pageview', { path: to.fullPath })
})

export default router
