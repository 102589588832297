<template>
  <!-- ====== Permission Editor ===== -->
  <v-dialog v-model="show" width="900px" @keydown.esc="close">
    <v-card class="permission-editor">
      <v-card-title primary-title class="headline">
        {{ $t('title', {
          entity_type: $t(`${entityType}.item`),
          entity_name: entityName
        }) }}
      </v-card-title>

      <alert v-if="loadingFailed" type="error">
        {{ this.$t('error', {'step': this.$t('loading.while')}) }}
      </alert>

      <v-card-text v-else-if="transferOwnership.active">
        <v-alert type="warning"
                 text
                 border="left"
        >
          <span v-html="$t('make_owner.warning', {
            old_owner: $escapeHtml(transferOwnership.oldOwner.name),
            new_owner: $escapeHtml(transferOwnership.newOwner.name)
          })
          "
          />
        </v-alert>
        <div style="display: flex">
          <v-spacer />
          <v-btn outlined
                 :disabled="transferOwnership.loading"
                 @click="cancelTransferOwnership"
                 v-text="$t('cancel')"
                 style="margin-right: 8px"
          />

          <v-btn color="primary"
                 :loading="transferOwnership.loading"
                 @click="confirmTransferOwnership"
          >
            {{ $t('yes') }}
          </v-btn>
        </div>
      </v-card-text>

      <v-progress-linear :indeterminate="true" v-else-if="loading" />

      <v-card-text v-else class="container">
        <div style="min-width: 280px">
          <v-text-field :label="$t('search')"
                        outlined
                        color="primary"
                        :append-icon="!search ? 'mdi-account-search' : ''"
                        @input="updateSearchDebounced"
                        :clearable="!!search"
                        class="search"
                        hide-details
          />
          <div class="users" v-if="usersFiltered.length">
            <v-list two-line subheader>
              <v-list-item v-for="u in usersFiltered"
                           :key="u.id"
                           :class="{ selected: userSelected === u.id }"
                           class="user-tile"
                           @click="userSelected = u.id"
              >
                <v-list-item-content data-private>
                  <v-list-item-title>{{ u.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ u.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <div v-if="u.id === entityOwnerId"
                       class="tooltip tooltip-left"
                       :data-tooltip="$t('owner')"
                  >
                    <v-icon color="primary">
                      mdi-account-lock
                    </v-icon>
                  </div>
                  <div v-if="u.id === user.organization.root_user_id"
                       class="tooltip tooltip-left"
                       :data-tooltip="$t('root_user')"
                  >
                    <v-icon color="primary">
                      mdi-crown
                    </v-icon>
                  </div>
                  <div v-else-if="u.id !== entityOwnerId && hasPermissionByUserID[u.id]"
                       class="tooltip tooltip-left"
                       :data-tooltip="$t('has_access')"
                  >
                    <v-icon color="primary">
                      mdi-lock-open-variant-outline
                    </v-icon>
                  </div>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </div>
          <empty-state v-else
                       icon="mdi-account-remove-outline"
                       :size="80"
                       :label="$t('search_not_found')"
          />
        </div>

        <div class="permissions">
          <table>
            <tr>
              <th style="display: flex; flex-direction: column">
                <v-btn v-if="entityOwnerId !== userSelected"
                       small
                       dense
                       style="margin-right: auto"
                       @click="initTransferOwnership"
                >
                  {{ $t('make_owner.btn') }}
                </v-btn>
                <v-spacer />
                <div style="margin-top: auto; margin-left: 5px; text-align: center">
                  <a href="#" @click.prevent="selectAll">{{ $t('actions.select_all') }}</a> |
                  <a href="#" @click.prevent="deselectAll">{{ $t('actions.deselect_all') }}</a>
                </div>
              </th>
              <th><span>{{ $t('object_level') }}</span></th>
              <th>
                <helptip position="bottom" :width="700">
                  <span v-html="$t('organization_wide_helptip', {
                    root_email: user.organization.root_user_email,
                    root_name: $escapeHtml(user.organization.root_user_name)
                  })"
                  />
                </helptip>
                <span>{{ $t('organization_wide') }}</span>
              </th>
            </tr>

            <tbody>
              <tr v-for="(perm, permIdx) in permissionOptions"
                  :key="permIdx"
                  :class="{ disabled: permissionsSelected[perm].immutable }"
                  @click="clickPermission(perm)"
              >
                <td><label v-html="$t(`permissions.${perm}`)" /></td>

                <td @click.stop.prevent>
                  <v-checkbox
                    v-model="permissions[userSelected].permissions[perm].value"
                    dense
                    :disabled="permissionsSelected[perm].immutable"
                    hide-details
                    :ripple="false"
                    :class="{ disabled: permissionsSelected[perm].immutable }"
                  />
                </td>

                <td @click.stop.prevent>
                  <v-checkbox
                    v-model="permissionsSelected[perm].org_wide"
                    disabled
                    dense
                    hide-details
                    :ripple="false"
                    :class="{ disabled: permissionsSelected[perm].immutable }"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card-text>

      <v-card-actions v-if="!transferOwnership.active">
        <v-spacer />
        <v-btn outlined @click="close">
          {{ $t('close') }}
        </v-btn>
        <v-btn color="primary" @click="save">
          {{ $t('save.title') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vuex from 'vuex'

export default {
  codit: true,
  name: 'PermissionEditor',
  props: {
    value: { type: Boolean, default: false },
    entityType: { type: String, default: '' },
    entityId: { type: String, default: '' },
    entityName: { type: String, default: '' },
    entityOwnerId: { type: String, default: '' },
    permissionOptions: { type: Array, default: () => [] }
  },

  data () {
    return {
      search: '',

      loadingFailed: false,
      loading: true,

      permissions: {},

      userSelected: -1,

      transferOwnership: {
        active: false,
        oldOwner: {},
        newOwner: {},
        loading: false
      },

      updateSearchDebounced: _.debounce((val) => {
        this.search = val
      }, 250)
    }
  },

  computed: {
    show: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    },

    /**
     * Base URL for the entity being being displayed in the dialog
     * @return {String} The URL
     */
    entityBaseURL () {
      return `/api/${this.entityType}s/${this.entityId}`
    },

    /**
     * Parse the users from the object received from the permissions endpoint
     * @return {Array} Array with available users, some basic information about them and and their respective permissions
     */
    users () {
      return _(this.permissions).map((userPermissions, key) => userPermissions).value()
    },

    /**
     * List of users sorted by
     * 1) Their access status (true,false)
     * 2) Their name
     * @return {Array}
     */
    usersSorted () {
      return _.sortBy(this.users, u => [!this.hasPermissionByUserID[u.id], u.name.toLowerCase()])
    },

    /**
     * The users matching the search field
     * @return {Array}
     */
    usersFiltered () {
      if (!this.search) return this.usersSorted
      let s = this.search.toLowerCase()
      return this.usersSorted.filter(u =>
        u.name.toLowerCase().indexOf(s) !== -1 || u.email.toLowerCase().indexOf(s) !== -1
      )
    },

    /**
     * The permissions of the user currently selected
     * @return {Object} Object with permission names as keys
     */
    permissionsSelected () {
      return this.permissions[this.userSelected].permissions
    },

    /**
     * Mapping of user ID -> {True,False}
     * Indicating whether the respective user has any permissions on this resource or not
     * @return {Object} Mapping
     */
    hasPermissionByUserID () {
      let mapping = {}
      _.forEach(this.users, u => {
        mapping[u.id] = _.some(this.permissions[u.id].permissions, p => p.value)
      })
      return mapping
    },

    ...Vuex.mapState([
      'user'
    ])
  },

  watch: {
    show: {
      immediate: true,
      handler (val) {
        if (val) this.init()
      }
    }
  },

  methods: {
    /**
     * Load the permissions of this entity and prepare data structure
     * @return {Promise} Promise when loading is complete
     */
    init () {
      this.$set(this, 'permissions', {})
      this.userSelected = ''
      this.search = ''
      this.loading = true
      this.loadingFailed = false

      return api.get(`${this.entityBaseURL}/permissions`).then((res) => {
        let permissions = res.data

        let defaultPerms = Object.assign({}, ...this.permissionOptions.map(perm => ({ [perm]: { value: false } })))
        // The API only returns permissions which are set
        // For the UI to work responsively, we have to assign permissionOptions, also if they are false
        _.each(permissions, (userPermissions, key) => {
          userPermissions.permissions = { ..._.cloneDeep(defaultPerms), ...userPermissions.permissions }
        })

        this.$set(this, 'permissions', permissions)
        this.userSelected = String(this.usersSorted[0].id)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.loadingFailed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Handler when a row (=permission) is clicked. Toggle the selection of that permission, if not immutable
     * @param  {String} permission Permission identifier
     */
    clickPermission (permission) {
      let permSelected = this.permissions[this.userSelected].permissions[permission]
      if (!permSelected.immutable) permSelected.value = !permSelected.value
    },

    /**
     * Select all available permissions
     */
    selectAll () {
      _.each(this.permissions[this.userSelected].permissions, p => { p.value = true })
    },

    /**
     * Deselect all available permissions
     * @return {[type]} [description]
     */
    deselectAll () {
      _.each(this.permissions[this.userSelected].permissions, p => { if (!p.immutable) p.value = false })
    },

    /**
     * Close the dialog
     */
    close () { this.$emit('input', false) },

    /**
     * Save the current permission settings and close the dialog
     */
    save () {
      let name = _.clone(this.entityName)
      api.patch(`${this.entityBaseURL}/permissions`, this.permissions).then(res => {
        this.$root.snackMsg(this.$t('saving_done', { entity_name: this.$escapeHtml(name), entity_type: this.$t(`${this.entityType}.item`) }))
      }).catch(err => {
        this.$root.snackMsg(this.$t('error', { 'step': this.$t('save.saving') }))
        this.$maybeRaiseAPIPromiseErr(err)
      })

      this.show = false
    },

    /**
     * Start the ownership transfer to the currently selected user
     */
    initTransferOwnership () {
      this.transferOwnership.newOwner = _.find(this.users, { id: this.userSelected })
      this.transferOwnership.oldOwner = _.find(this.users, { id: this.entityOwnerId })
      this.transferOwnership.active = true
    },

    /**
     * Cancel the currently active ownership transfer
     * @return {[type]} [description]
     */
    cancelTransferOwnership () {
      this.transferOwnership.newOwner = this.transferOwnership.oldOwner = {}
      this.transferOwnership.active = false
    },

    /**
     * Confirm the currently active ownership transfer
     */
    confirmTransferOwnership () {
      let newOwner = _.cloneDeep(this.transferOwnership.newOwner)
      let userSelected = this.userSelected
      this.transferOwnership.loading = true
      api.patch(`${this.entityBaseURL}/change-ownership`, { new_owner: this.transferOwnership.newOwner.id }).then(res => {
        this.init().then(() => {
          this.$root.snackMsg(this.$t('make_owner.success'))
          this.transferOwnership.active = false
          this.transferOwnership.loading = false
          this.transferOwnership.newOwner = {}
          this.transferOwnership.oldOwner = {}
          this.userSelected = userSelected
          this.$emit('change-ownership', newOwner)
        })
      }).catch(err => {
        this.$root.snackMsg(this.$t('error', { 'step': this.$t('make_owner.while') }))
        this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }

}

</script>

<style lang=scss>

.permission-editor {
  .container {
    display: flex;
    flex-direction: row;
  }

  .permissions .v-input--checkbox i {
    color: rgba(0,0,0,.54);
  }

  /* .permissions .v-input--checkbox.disabled i {
    color: rgba(0,0,0,.26) !important;
  }

  .permissions .v-input--checkbox:not(.disabled):not(.primary--text) i {
    color: $primary-color !important;
  } */

  .search {
    margin: 0 8px 8px 0!important;
  }

  .users {
    flex: 1;
    z-index: 1;
    max-height: 380px;
    overflow-y: scroll;

    .v-list {
      background: none;
    }

    .user-tile {
      cursor: pointer;
      border-radius: 4px 0 0 4px!important;
      border: 1px solid transparent;
      border-right: 0;
      margin-bottom: 2px;
      &.selected {
        background: #EEE;
        border: 1px solid #DDD;
        border-right: 0;
        .v-list__tile__title { font-weight: bold; }
      }
      &:hover {
        background: $col-ans-focus-bg;
        border: 1px solid #DDD;
        border-right: 0;
      }
    }

    .v-list-item__icon {
      margin-right: 4px;
      margin-top: auto!important;
      margin-bottom: auto!important;
    }
  }

  .permissions {
    background: #EEE;
    padding: 8px 12px;
    flex: 3;
    border-radius: 4px;
    border: 1px solid #DDD;
    margin-left: -1px;

    table {
      width: 100%;
      border-spacing: 0;
    }

    th {
      height: 140px;
      position: relative;
      vertical-align: top;
      text-align: left;

      &:last-child {
        text-align: center;
      }

      > span {
        position: absolute;
        display: inline-block;
        transform: rotate(-90deg);
        transform-origin: 0;
        bottom: -5px;
        left: calc(50%);
        font-weight: normal;
        font-style: italic;
        white-space: nowrap;
      }

      > .helptip {
        font-weight: normal;
      }
    }

    tbody tr {
      cursor: pointer;

      label {
        pointer-events: none;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &:hover {
        background: $col-ans-focus-bg;
      }
    }

    td:nth-child(2), th:nth-child(2) {
      background-color: $col-soft-turq;
    }

    td:nth-child(3), th:nth-child(3) {
      background-color: #E3E3E3;
      cursor: default;
    }

    td {
      padding: 4px 0;
      &:nth-child(1) {
        padding-left: 6px;
      }
      &:nth-child(2), &:nth-child(3) {
        width: 40px;
      }
      .v-input {
        margin: 0;
        padding: 0;
        justify-content: flex-end;
        text-align: right;
        .v-input__slot {
          justify-content: center;
        }
        .v-input--selection-controls__input {
          margin: 0;
        }
      }
    }
  }

}

</style>

<i18n locale='en' src='@/i18n/en/components/PermissionEditor.json' />
<i18n locale='de' src='@/i18n/de/components/PermissionEditor.json' />