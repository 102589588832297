export default {
  methods: {
    /**
     * Returns properrly formated query params for verbatim browser request
     * Possible options:
     * dataset -> index of dataset
     * dataset_filter -> index of dataset filter
     * code
     * category
     * limit -> page size
     * smart_sort -> unsure, todo/ ask pascal
    */
    getQueryParams (page, limit) {
      const passedParams = (array) => Object.keys(array).map(key => {
        let normalizedKey = key

        if (key === 'categories') {
          normalizedKey = 'category'
        }

        if (key === 'codes') {
          normalizedKey = 'code'
        }

        if (key === 'auxiliary') {
          return
        }

        if (_.isArray(array[key])) {
          let params = _.map(array[key], (param, index) => `${normalizedKey}=${encodeURIComponent(array[key][index])}`)

          return params.join('&')
        }

        return `${normalizedKey}=${encodeURIComponent(array[key])}`
      })

      let query = `?`

      if (
        limit
      ) {
        query += `limit=${limit}&`
      }

      if (
        page
      ) {
        query += `page=${page}&`
      }

      if (
        Object.keys(this.queryParams).length
      ) {
        query += `${passedParams(this.queryParams).join('&')}&`
      }
      if (
        this.extraQueryParams &&
        Object.keys(this.extraQueryParams).length
      ) {
        query += `${passedParams(this.extraQueryParams).join('&')}`
      }

      return query
    }
  }
}
