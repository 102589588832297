/**
 * Mixin which provides functions that help dealing with auxiliary columns
 */

import { ADDITIONAL_COLUMNS, NPS_COL_TERMS, NPS_TERMS, CSAT_TERMS, CSAT_COL_TERMS } from '@/settings/constants'

const auxiliaryColumnsMixin = {
  computed: {
    CSATTermsFormatted () {
      return '{' + CSAT_TERMS.map(terms => Array.from(terms).join(',')).join(`} ${this.$t('or')} {`) + '}'
    },

    NPSTermsFormatted () {
      return '{' + CSAT_TERMS.map(terms => Array.from(terms).join(',')).join(`} ${this.$t('or')} {`) + '}'
    }
  },

  methods: {
    /**
     * Generate an array of all auxiliary columns, which can be passed to a v-select
     * @param  {Array} auxiliaryColumnNames  The array of auxiliary column names of the project
     * @return {Array}                       The array with an object {value,text} for each auxiliary column
     */
    getAuxiliaryOptions (auxiliaryColumnNames) {
      return auxiliaryColumnNames ? auxiliaryColumnNames.map((v, idx) => ({ value: idx, text: v })) : []
    },

    /**
     * Generate an array of all options that be displayed for an answer, which can be passed to a v-select
     * Consists of the "standard" fields (codes, sentiment etc.) as well as the auxiliary column
     * fields (user-given data)
     * @param  {Object}  auxiliaryColumnNames The question for which the options should be generated
     * @return {Array}                        The array with an object {value,text} for each option
     */
    getShowColumnOptions (auxiliaryColumnNames, columns = ADDITIONAL_COLUMNS) {
      let opts = []
      opts.push({ header: this.$t('answer_fields.standards') })
      opts.push(...columns.map((c, idx) => ({ text: this.$t(`answer_fields.${c}`), value: c })))

      let auxiliaryOptions = this.getAuxiliaryOptions(auxiliaryColumnNames)

      if (auxiliaryOptions.length) {
        opts.push({ divider: true })
        opts.push({ header: this.$t('answer_fields.auxiliary') })
        opts.push(...auxiliaryOptions)
      }

      return opts
    },

    /**
     * Determines the types (text, multiple choice, numerical, ...) of all auxiliary column options.
     * The type determines, how the filter is presented to the user.
     * The possible types are those that can be handled by the `answerFilters` mixin
     * @param  {Object} question The question for which the array should be generated
     * @param  {Array}  answers  The answers of this question
     * @return {Array}           An array with an entry for every auxiliary column
     */
    inferAuxiliaryTypes (question, answers) {
      // Go through all auxiliary columns, and determine the types and values
      const DISCRETE_VALS_IF_LESS_THAN_UNIQUE = 50

      if (!('auxiliary_column_names' in question)) return []
      if (!answers.length) return []
      if (!(answers[0].auxiliary_columns)) return []

      let types = []
      let vals = _.map(answers[0].auxiliary_columns, () => new Set())
      answers[0].auxiliary_columns.forEach((c, idx) => {
        if (typeof c === 'number') types.push({ type: 'number', min: Infinity, max: -Infinity, integer: true, few: true })
        else types.push({ type: 'string', few: true })
      })

      answers.forEach(a => {
        a.auxiliary_columns.forEach((c, idx) => {
          let tt = types[idx]
          if (tt.few && vals[idx].size <= DISCRETE_VALS_IF_LESS_THAN_UNIQUE) vals[idx].add(c)
          else tt.few = false
          if (tt.type === 'number') { // if any row does not contain a number, we fall back to string for entire column
            if (typeof c !== 'number') types[idx] = tt = { type: 'string', few: tt.few }
            else {
              if (tt.integer && !Number.isInteger(c)) tt.integer = false
              tt.min = Math.min(tt.min, c)
              tt.max = Math.max(tt.max, c)
            }
          }
        })
      })

      types.forEach((t, idx) => {
        if (t.type === 'string' && t.few) t.vals = Array.from(vals[idx]).sort()
      })

      return types
    },

    /**
     * The auxiliary columns which might represent NPS scores
     * Ordered by
     * - How "well" they fit the NPS numberical structure
     * - If they contain any of the NPS "terms" in the column name
     * - The order in which they appear on the question
     * @param  {Array} auxiliaryTypes The types of the auxiliary columns
     * @param  {Array} auxiliaryNames The names of the auxiliary columns
     * @return {Array} List of column indexes
     */
    getPotentialNPSColumns (auxiliaryTypes = [], auxiliaryNames) {
      let potentials = []

      auxiliaryTypes?.forEach((ct, colIdx) => {
        let colNameLooksFitting = _.some(NPS_COL_TERMS, t => auxiliaryNames.includes(t))
        if (ct.type === 'number') {
          if (ct.min >= 0 && ct.max <= 100) {
            potentials.push({
              colIdx,
              prio: ct.max <= 10
            })
          }
        } else if (ct.type === 'string' && ct.few && ct?.vals.length <= 15) {
          // Check that all strings contained in the values match an option in the NPS terms
          NPS_TERMS.forEach(terms => {
            if (ct.vals.every(val => terms.has(String(val).toLowerCase()))) {
              potentials.push({
                colIdx,
                prio: 2 + colNameLooksFitting
              })
            }
          })
        }
      })
      return _(potentials).sortBy('-prio').map('colIdx').value()
    },

    /**
     * The auxiliary columns which might represent CSAT scores
     * @param  {Array} auxiliaryTypes The types of the auxiliary columns
     * @param  {Array} auxiliaryNames The names of the auxiliary columns
     * @return {Array} List of column indexes
     */
    getPotentialCSATColumns (auxiliaryTypes = [], auxiliaryNames) {
      let potentials = []
      auxiliaryTypes?.forEach((ct, colIdx) => {
        let colNameLooksFitting = _.some(CSAT_COL_TERMS, t => auxiliaryNames.includes(t))
        if (ct.type === 'number') {
          if (ct.min >= 1 && ct.max <= 5) potentials.push({ colIdx, prio: colNameLooksFitting })
        } else if (ct.type === 'string' && ct.few && ct.vals.length <= 5) {
          // Check that all strings contained in the values match an option in the CSAT terms
          CSAT_TERMS.forEach(terms => {
            if (ct.vals.every(val => typeof val === 'string' && terms.has(val.toLowerCase()))) {
              potentials.push({
                colIdx,
                prio: colNameLooksFitting
              })
            }
          })
        }
      })

      return _(potentials).sortBy('-prio').map('colIdx').value()
    },

    /**
     * The auxiliary columns which consist of numbers
     * @param  {Array} auxiliaryTypes The types of the auxiliary columns
     * @param  {Array} auxiliaryNames The names of the auxiliary columns
     * @return {Array} List of column indexes
     */
    getPotentialNumberColumns (auxiliaryTypes = [], auxiliaryNames) {
      let potentials = []

      auxiliaryTypes?.forEach((ct, colIdx) => {
        if (ct.type === 'number' && ct.min >= -100 && ct.max <= 100) {
          potentials.push({ colIdx })
        }
      })

      return _(potentials).sortBy('-prio').map('colIdx').value()
    },

    /**
     * The auxiliary columns which consist of numbers
     * @param  {Array} auxiliaryTypes The types of the auxiliary columns
     * @param  {Array} auxiliaryNames The names of the auxiliary columns
     * @return {Array} List of column indexes
     */
    getPotentialDateColumns (auxiliaryTypes = [], auxiliaryNames) {
      let potentials = []

      auxiliaryTypes?.forEach((ct, colIdx) => {
        if (ct.type === 'date') {
          potentials.push({ colIdx })
        }
      })

      return _(potentials).sortBy('-prio').map('colIdx').value()
    },

    /**
     * The potential NPS columns in the form in which they can be used in select
     * @param  {Array} auxiliaryTypes The types of the auxiliary columns
     * @param  {Array} auxiliaryNames The names of the auxiliary columns
     * @return {Array}
     */
    getPotentialDriverColumnSelectItems (auxiliaryTypes, auxiliaryNames) {
      return _(this.getPotentialNPSColumns(auxiliaryTypes, auxiliaryNames).concat(this.getPotentialCSATColumns(auxiliaryTypes, auxiliaryNames)))
        .uniq()
        .map(colIdx => ({
          text: auxiliaryNames[colIdx],
          value: colIdx
        })).value()
    },

    /**
    * The potential scoring columns for NPS chart
    * in the form in which they can be used in select
    * @param  {Array} auxiliaryTypes The types of the auxiliary columns
    * @return {Array}
    */
    getPotentialScoringColumns (auxiliaryTypes, auxiliaryNames) {
      let potentials = []

      auxiliaryTypes?.forEach((ct, colIdx) => {
        if (ct.semantic_type === 'STAR_5') {
          potentials.push({ colIdx })
        } else if (ct.semantic_type === 'NPS_10') {
          potentials.push({ colIdx })
        } else if (ct.semantic_type === 'NPS_100') {
          potentials.push({ colIdx })
        } else if (ct.semantic_type === 'GENERIC_AUXILIARY' && ct.min === 1 && ct.max === 5) {
          potentials.push({ colIdx })
        } else if (ct.semantic_type === 'GENERIC_AUXILIARY' && ct.type === 'number') {
          potentials.push({ colIdx })
        }
      })

      return _(potentials).sortBy('-prio').map('colIdx').value()
    },

    getPotentialCSATColumnSelectItems (auxiliaryTypes, auxiliaryNames) {
      return _(this.getPotentialCSATColumns(auxiliaryTypes, auxiliaryNames))
        .uniq()
        .map(colIdx => ({
          text: auxiliaryNames[colIdx],
          value: colIdx
        })).value()
    },

    getPotentialNPSColumnSelectItems (auxiliaryTypes, auxiliaryNames) {
      return _(this.getPotentialNPSColumns(auxiliaryTypes, auxiliaryNames))
        .uniq()
        .map(colIdx => ({
          text: auxiliaryNames[colIdx],
          value: colIdx
        })).value()
    },

    getPotentialDateColumnSelectItems (auxiliaryTypes, auxiliaryNames) {
      return _(this.getPotentialDateColumns(auxiliaryTypes, auxiliaryNames))
        .uniq()
        .map(colIdx => ({
          text: auxiliaryNames[colIdx],
          value: auxiliaryNames[colIdx],
          colIdx
        })).value()
    },

    /**
    * The sorted and reduced
    * potential scoring columns for NPS chart
    * in the form in which they can be used in select
    * defined separatelly for NSP chart intentiannaly
    * @param  {Array} auxiliaryTypes The types of the auxiliary columns
    * @param  {Array} auxiliaryNames The names of the auxiliary columns
    * @return {Array}
    */
    getPotentialScoringColumnSelectItems (auxiliaryTypes, auxiliaryNames) {
      return _(this.getPotentialScoringColumns(auxiliaryTypes, auxiliaryNames))
        .uniq()
        .map(colIdx => ({
          text: auxiliaryNames[colIdx],
          value: colIdx
        })).value()
    },

    /**
    * The sorted out
    * potential columns for DYBAR chart
    * @param  {Array} auxiliaryTypes The types of the auxiliary columns
    * @return {Array}
    */
    getPotentialDYBARColumns (auxiliaryTypes) {
      return auxiliaryTypes?.filter(({ type, few }) => !!few && type === 'string')
    }
  }
}

export default auxiliaryColumnsMixin
