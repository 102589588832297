<template>
  <div class="cb-editor"
       :class="backgroundClasses"
       @dragover.prevent="onDragOverBackground"
       @dragenter="onDragEnterBackground"
       @dragleave="onDragLeaveBackground"
       @drop.prevent="onDropOnBackground"
  >
    <!-- ===== TOP TOOLBAR ===== -->
    <page-header feature-name="cb-editor">
      <slot name="info" slot="info" />
      <slot name="title-raw" slot="title-raw" />
      <template slot="page-buttons">
        <v-btn text
               icon
               v-if="history"
               @click="showConfirmDialog('reset')"
               :disabled="histSize < 2 || disabled"
               class="tooltip tooltip-left"
               :data-tooltip="$t('history_tooltips.reset')"
        >
          <v-icon style="transform: scaleX(-1)">
            mdi-refresh
          </v-icon>
        </v-btn>

        <v-btn text
               icon
               v-if="history"
               :disabled="currentHistPosition < 1 || disabled"
               @click.stop.prevent="undo"
               class="tooltip tooltip-left"
               :data-tooltip="$t('history_tooltips.undo')"
        >
          <v-icon>mdi-undo</v-icon>
        </v-btn>

        <v-btn text
               icon
               v-if="history"
               :disabled="(histSize - currentHistPosition) < 2 || disabled"
               @click="redo"
               class="tooltip tooltip-left"
               :data-tooltip="$t('history_tooltips.redo')"
        >
          <v-icon>mdi-redo</v-icon>
        </v-btn>
      </template>
      <div v-if="dragging.active" class="drag-hint grey--text" slot="roll-secondary">
        <div class="headline" v-html="dragHint" />
        <div class="subtitle">
          {{ $t('drag_hint.esc_to_cancel') }}
        </div>
      </div>
    </page-header>

    <v-alert type="error"
             v-if="maxCodesReached"
             border="left"
             text
    >
      <span v-html="$t('alert_code_limit', { n: MAX_TOPICS })" />
    </v-alert>

    <div class="drag-move-indicator"
         v-if="showDragMoveIndicator"
         :style="{
           left: `${dragging.moveIndicator.left}px`,
           top: `${dragging.moveIndicator.top}px`,
           height: `${dragging.moveIndicator.height}px`
         }"
    />

    <!-- ===== CODE EARS ===== -->
    <transition name="ears-anim">
      <div
        class="ears"
        v-if="ears.show"
        ref="ears"
        @keydown.esc="closeEars"
        @keydown.enter.stop="closeEars"
        @keydown.tab.stop
        :style=" {
          left: `${earsLeft}px`,
          top: `${ears.pos.codeTop - ears.pos.boxScrollTop + 40}px`
        }"
        :class="{ emphasize: ears.animate }"
        @dragenter.stop.prevent="onDragEnterEars"
        @dragleave.stop.prevent="onDragLeaveEars"
        @dragover.stop.prevent
        @drop.prevent.stop
      >
        <div
          class="ears-arrow"
          :style="{
            'left': `${codeChipMiddleOffsetLeft - earsLeft}px`,
            'border-bottom-color': catColors[codeID2Cat[ears.codeID]].strong
          }"
        />
        <div
          class="ears-toolbar"
          :style="{
            background: catColors[codeID2Cat[ears.codeID]].strong,
            left: `${codeChipMiddleOffsetLeft - earsLeft}px`
          }"
        >
          <v-tooltip top v-if="editable && !disabled">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn dark small icon @click="triggerRemoveCode(ears.codeID)">
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span v-html="$t('remove_code_tooltip')" />
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn dark small icon @click.prevent.stop="filterVerbatimsByCode(ears.codeID)">
                  <v-icon small>
                    mdi-format-list-bulleted
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span v-html="$t('filter_by_code_tooltip')" />
          </v-tooltip>

          <v-tooltip top v-if="editable && !disabled">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn dark :disabled="!resetEarAvailable" small icon @click.prevent.stop="resetEar">
                  <v-icon small>
                    mdi-undo
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span v-html="$t('reset_ear_tooltip')" />
          </v-tooltip>
        </div>
        <div class="ears-content"
             v-c-click-outside="closeEars"
             @click.stop
             :style="{ 'border-color': catColors[codeID2Cat[ears.codeID]].strong }"
        >
          <div v-if="ears.editCode.new || ears.editCode.special" class="special-code-info">
            <v-icon :size="18">
              <template v-if="ears.editCode.special">
                mdi-flash-outline
              </template>
              <template v-else>
                mdi-star-outline
              </template>
            </v-icon>
            <span v-if="ears.editCode.special">{{ $t('special_code_tooltip') }}</span>
            <span v-else>{{ $t('new_code_tooltip') }}</span>
          </div>
          <div class="inputs">
            <v-text-field :value="ears.editCode.label"
                          :disabled="!editable || disabled"
                          v-lazy-model="'ears.editCode.label'"
                          :key="ears.codeID"
                          outlined
                          counter
                          :maxlength="MAX_TOPIC_LABEL_LEN"
                          class="code-label"
                          :label="$t('codes.label')"
                          autofocus
                          dense
            >
              <template slot="append">
                <helptip position="bottom" :width="300">
                  <span v-html="$t('edit_label_tooltip')" />
                </helptip>
              </template>
            </v-text-field>

            <v-tooltip bottom :disabled="!ears.inheritsFromIDLock">
              <template v-slot:activator="{ on }">
                <div v-on="on"
                     @dblclick="ears.inheritsFromIDLock = false"
                     class="code-id"
                >
                  <!-- set key anew for every code, to prevent strange issues with empty strings not being accepted -->
                  <v-text-field v-if="ears.show"
                                :value="ears.editCode.id"
                                :disabled="!editable || ears.inheritsFromIDLock || ears.editCode.special || disabled"
                                v-lazy-model="'ears.editCode.id'"
                                :key="ears.codeID"
                                type="number"
                                outlined
                                label="ID"
                                :error="earsIDError.length > 0"
                                :error-messages="earsIDError"
                                @dblclick="ears.inheritsFromIDLock = false"
                                dense
                  >
                    <template slot="append">
                      <helptip position="bottom" :width="300">
                        <span v-html="$t('edit_id.helptip_base')" />
                      </helptip>
                    </template>
                  </v-text-field>
                </div>
              </template>
              <span v-html="$t('edit_id.tooltip_id_inherited_disabled')" />
            </v-tooltip>
          </div>
          <div class="inputs">
            <!-- set key anew for every code, to prevent strange issues with empty strings not being accepted -->
            <v-text-field :value="ears.editCode.description"
                          :disabled="!editable || disabled"
                          v-lazy-model="'ears.editCode.description'"
                          :key="ears.codeID"
                          outlined
                          counter
                          :maxlength="MAX_TOPIC_DESCRIPTION_LEN"
                          class="code-description"
                          :label="$t('codes.description')"
                          dense
            >
              <template slot="append">
                <helptip position="bottom" :width="300">
                  <span v-html="$t('edit_description_tooltip')" />
                </helptip>
              </template>
            </v-text-field>
          </div>
          <template v-if="!ears.editCode.special && showKeywords">
            <v-divider />
            <v-subheader>
              {{ $t('keywords.title') }}
              <helptip position="top" :width="400" style="margin-left: 5px" x-offset="3px">
                <span v-html="$t('keywords.tooltip')" />
              </helptip>
            </v-subheader>
            <div class="keywords-container">
              <template v-for="(kw, idx) in ears.editCode.keywords">
                <span v-if="idx !== ears.editingKeywordIdx"
                      :key="`kw-${idx}`"
                      class="keyword"
                      :class="{
                        'dragging-enter': dragging.active && dragging.mode === 'keyword' && dragging.keywordIdx === idx && dragging.enter,
                        'highlight': highlight.keywordIdx === idx
                      }"
                      @click.stop.prevent="startEditingKeyword(idx)"
                      @dragstart.stop="onDragStartKeyword(idx, $event)"
                      @dragend.stop="onDragEndKeyword(idx, $event)"
                      :draggable="editable && !disabled"
                >
                  {{ kw }}
                  <v-icon v-if="editable && !disabled"
                          @click.stop="removeKeywordFromEar(idx)"
                          :size="12"
                          class="delete-keyword"
                  >mdi-close</v-icon>
                </span>
                <v-text-field v-else
                              :key="`kw-input-${idx}`"
                              :value="kw"
                              v-lazy-model="'earsEditingKeyword'"
                              @blur="onBlurEditingKeyword(idx)"
                              @keydown.esc.stop="onBlurEditingKeyword(idx)"
                              @keydown.enter.stop="onBlurEditingKeyword(idx)"
                              outlined
                              :maxlength="MAX_TOPIC_KEYWORD_LEN"
                              class="keyword"
                              :label="$t('keywords.label')"
                              autofocus
                              hide-details
                              dense
                />
              </template>
              <span class="keyword new-keyword" @click="newKeyword" v-if="editable && !disabled && addKeywordAvailable">
                <v-icon :size="20" class="new-placeholder">mdi-plus</v-icon>
              </span>
            </div>
          </template>
        </div>
      </div>
    </transition>

    <!-- ===== CATEGORY TILES ===== -->
    <div id="category-tile-container" ref="category-tile-container" :class="{ disabled }">
      <div v-for="(cat, catIdx) in codeCatsSorted"
           :key="cat"
           class="category-tile-el"
           :class="categoryClasses[cat]"
           @dragstart="onDragStartCategory(cat, $event)"
           @dragend="onDragEndCategory(cat, $event)"
           @drop.stop.prevent="onDropOnCategory(cat, $event)"
           @dragenter="onMaybeDragEnterCategory(cat, $event)"
           @dragleave="onMaybeDragLeaveCategory(cat, $event)"
           :draggable="editable && editingCatName.cat !== cat && !disabled"
      >
        <div class="drag-overlay"
             v-if="dragging.active && dragging.mode === 'cat'"
             :style="{
               width: `${overlayDimensions[catIdx].width}px`,
               height: `${overlayDimensions[catIdx].height}px`
             }"
             @dragenter="onDragEnterCategory(cat, $event)"
             @dragleave="onDragLeaveCategory(cat, $event)"
        />
        <div class="header">
          <v-icon v-if="editable && editingCatName.cat !== cat"
                  @mousedown="dragging.startedByMoveHandler = true"
                  class="move-handler"
          >
            mdi-drag
          </v-icon>
          <span v-if="editingCatName.cat !== cat"
                @click="startEditingCatName(cat)"
                class="title"
          >{{ cat }}</span>
          <v-text-field v-else
                        :value="editingCatName.new"
                        v-lazy-model="'editingCatName.new'"
                        @blur="onBlurEditingCatName"
                        @keydown.esc.stop="onBlurEditingCatName"
                        @keydown.enter.stop="onBlurEditingCatName"
                        outlined
                        :maxlength="MAX_TOPIC_CATEGORY_LEN"
                        class="title-edit title"
                        :label="$t('edit_category.label')"
                        autofocus
                        hide-details
                        dense
          />
          <v-spacer />
          <div v-if="catColors[cat]"
               class="color-indicator"
               :style="{ 'background-color': catColors[cat].medium }"
               @click.stop="showColorPicker(cat, $event)"
          />

          <v-menu bottom left v-if="editable">
            <template v-slot:activator="{ on }">
              <v-btn icon
                     :disabled="disabled"
                     v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="startEditingCatName(cat)">
                <v-list-item-title>{{ $t('edit_category.rename') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="triggerRemoveCategory(cat)">
                <v-list-item-title>{{ $t('edit_category.delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <!-- <div class="frequency" :style="{ background: catColors[cat].soft }">
          <div class="filled" :style="{
            background: catColors[cat].strong,
            width: `${codeCatCounts[cat]}%`
          }"
          />
        </div> -->
        <div class="content">
          <code-chip v-for="code in codesByCat[cat]"
                     :key="code.id"
                     :label="code.label"
                     :category="code.category"
                     :color="catColors[cat].medium"
                     :deletable="editable"
                     @click.native.stop="showEars(code.id)"
                     @dblclick.native.stop="filterVerbatimsByCode(code.id)"
                     :ref="`code-${code.id}`"
                     :class="codeClasses[code.id]"
                     :draggable="editable && !disabled"
                     @dragstart.native.stop="onDragStartCode(code.id, $event)"
                     @dragover.native.prevent
                     @dragend.native="onDragEndCode(code.id, $event)"
                     @dragenter.native="onDragEnterCode(code.id, $event)"
                     @dragleave.native="onDragLeaveCode(code.id, $event)"
                     @drop.native.prevent.stop="onDropOnCode(code.id, $event)"
                     @delete="triggerRemoveCode(code.id)"
                     disable-tooltip
                     :max-width="0"
          />
          <div v-if="editable && !maxCodesReached"
               class="new-code"
               @click.stop="newCode('', cat)"
               :class="{ 'dragging-over': dragging.active && dragging.mode === 'keyword' && dragging.overCat === cat && dragging.overCodeID === 'new' }"
               @dragover.prevent
               @dragenter="onDragEnterCode('new', $event)"
               @dragleave="onDragLeaveCode('new', $event)"
               @drop.prevent.stop="onDropOnCode('new', $event)"
          >
            <v-icon :size="26" class="new-placeholder" :color="catColors[cat].strong">
              mdi-plus
            </v-icon>
          </div>
          <div v-if="!codesByCat[cat] || !codesByCat[cat].length" class="empty-placeholder">
            <div>
              <v-icon :size="80">
                mdi-delete-empty-outline
              </v-icon>
            </div>
            <div>
              {{ $t('edit_category.empty_hint') }}

              <v-btn small color="primary" outlined @click.native="removeCategory(cat)">
                {{ $t('edit_category.empty_btn_remove') }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div v-if="editable"
           class="category-tile-el new-category"
           @click="newCategory"
           :class="categoryClasses['new']"
           @dragenter="onDragEnterCategory('new', $event)"
           @dragleave="onDragLeaveCategory('new', $event)"
           @drop.stop.prevent="onDropOnCategory('new', $event)"
      >
        <div class="content new-placeholder">
          <v-icon :size="80">
            mdi-plus
          </v-icon>
        </div>
      </div>
    </div>

    <!-- ===== UTILITIES ===== -->
    <color-picker v-model="colorPicker.val"
                  :show.sync="colorPicker.active"
                  :offset-top="colorPicker.offsetTop"
                  :offset-left="colorPicker.offsetLeft"
                  lazy
    />

    <v-dialog v-model="confirmDialog.active" max-width="600" @keydown.esc="closeConfirmDialog(false)">
      <v-card v-if="confirmDialog.active">
        <v-card-title class="headline grey lighten-2"
                      primary-title
                      v-html="$t(`confirm_dialog.${confirmDialog.mode}.title`, confirmDialog.i18nProps)"
        />

        <v-card-text v-text="$t(`confirm_dialog.${confirmDialog.mode}.details`, confirmDialog.i18nProps)" />

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn color="primary" outlined @click.native="closeConfirmDialog(false)">
            {{ $t('no') }}
          </v-btn>
          <v-btn color="primary" @click.native="closeConfirmDialog(true)">
            {{ $t('yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Vuex from 'vuex'

import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import colorPalettes from '@/mixins/colorPalettes'

import ColorPicker from '@/components/ColorPicker'
import { MAX_TOPICS, MAX_TOPICS_SEMIOPEN } from '@/settings/overridable'

import {
  MIN_TOPIC_LABEL_LEN,
  MAX_TOPIC_LABEL_LEN,
  MIN_TOPIC_CATEGORY_LEN,
  MAX_TOPIC_CATEGORY_LEN,
  MAX_TOPIC_DESCRIPTION_LEN,
  MAX_TOPIC_KEYWORD_LEN,
  MAX_ADD_KEYWORDS
} from '@/settings/constants'

const MAX_HIST_SIZE = 20

const HIST_VARS = ['codes', 'codeCatsSorted']

// Some non-reactive properties
const NON_REACTIVE_DATA_TEMPLATE = {
  draggingOverCodeCounter: 0,
  draggingOverCatCounter: 0,
  draggingOverEarsCounter: 0,
  categoryTileColOffsets: [],
  categoryTileColWidths: [],
  categoryTileRowOffsets: [],
  categoryTileRowHeights: [],
  initialState: null,
  history: []
}

let nonReactiveData

export default {
  codit: true,
  name: 'CodebookEditor',
  components: {
    'color-picker': ColorPicker
  },

  mixins: [colorPalettes, answerFilters, auxiliaryColumnsMixin],

  props: {
    active: { type: Boolean, default: true },
    storeName: { type: String, default: '' },
    history: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    highlightCodes: { type: Object, default: () => ({}) },
    highlightCategories: { type: Object, default: () => ({}) }
  },

  data () {
    return {
      codeCatCounts: {},
      codeCatsSorted: [],

      dragging: {
        active: false,
        enter: false,
        startedByMoveHandler: false, // Flag which is used to check that drag initiation came from correct element
        overBackgroundCounter: 0,
        mode: 'code',
        codeID: -1,
        cat: '',
        keywordIdx: -1,
        overCodeID: -1,
        overCat: '',
        overEars: false,
        changed: false,
        moveIndicator: {
          top: 0,
          left: 0,
          height: 0
        }
      },

      highlight: {
        cat: '',
        codeID: -1,
        keywordIdx: -1
      },

      ears: {
        show: false,
        animate: false,
        codeID: -1,
        editingKeywordIdx: -1,
        inheritsFromIDLock: false,
        editCode: {
          label: '',
          id: 0,
          description: '',
          keywords: [],
          new: false,
          special: false
        },
        pos: {
          codeLeft: 0,
          codeTop: 0,
          codeWidth: 0,
          boxLeft: 0,
          boxScrollLeft: 0,
          boxScrollTop: 0,
          boxWidth: 0
        },
        justChangedCodeIDs: {}
      },

      editingCatName: {
        cat: '',
        new: ''
      },

      verbatimFilters: [],

      colorPicker: {
        active: false,
        offsetLeft: 0,
        offsetTop: 0,
        val: 'rgba(0,0,0,1.0)',
        cat: ''
      },

      confirmDialog: {
        active: false,
        title: '',
        details: '',
        onConfirm: null,
        mode: '',
        i18nProps: {}
      },

      histSize: 0,
      currentHistPosition: 0,

      overlayDimensions: [],

      MAX_TOPIC_LABEL_LEN,
      MAX_TOPIC_CATEGORY_LEN,
      MAX_TOPIC_DESCRIPTION_LEN,
      MAX_TOPIC_KEYWORD_LEN,

      updateCategoryTilePositionsDebounced: _.debounce(this.updateCategoryTilePositions, 200)
    }
  },

  computed: {
  /**
   * If all the keyword stuff should be shown
   * @return {bool}
   */
    showKeywords () {
      return this.isListQuestion
    },

    /**
     * Proxy for the constant (overridable per user) of how many codes are allowed per survey at max
     * @type {Number}
     */
    MAX_TOPICS: {
      cache: false,
      get () { return this.isListQuestion ? MAX_TOPICS_SEMIOPEN : MAX_TOPICS }
    },

    /**
     * If the maximum number of codes has been reached.
     * @return {Boolean}
     */
    maxCodesReached () {
      return this.codes.length >= this.MAX_TOPICS
    },

    /**
     * Check if the current code ID input is valid. If yes return an empty string, if not the error message.
     * @return {String}
     */
    earsIDError () {
      let e = this.ears
      if ((e.editCode.id in this.codeID2Code) && e.editCode.id !== e.codeID) return this.$t('edit_id.duplicate_id_error')
      else if (e.editCode.id < 1) return this.$t('edit_id.smaller_1_err')
      else return ''
    },

    /**
     * The colors by category. We overwrite this method explicitely from the codeUtils mixin,
     * as we also want empty categories to be represented
     * @return {Object} Mapping of category names to {soft,medium,strong} color object
     */
    catColors () {
      let mapping = {}
      this.codeCatsSorted.forEach(cat => {
        // If there is a code in that category, we generate the color from the first code in the cat
        // otherwise the color will be an empty string
        mapping[cat] = this.codesByCat[cat] ? this.get3ShadesForColor(this.codesByCat[cat][0].color) : ''
      })
      return mapping
    },

    /**
     * The absolute offset of the middle of the selected code-chip, relative to the left of the page
     * @return {Number} The left offset
     */
    codeChipMiddleOffsetLeft () {
      // Offset relative to the box of the code-chip.
      // If positive: There is visible space between left of code and box
      // If negative: Only part of the code is visile, as box is scrolled
      let codeOffsetWithinBox = this.ears.pos.codeLeft - this.ears.pos.boxLeft - this.ears.pos.boxScrollLeft
      // How much of the code with is on the right of the current scroll point
      // the codeOffsetWithinBox value only matters if it's negative, i.e. if box is scrolled
      let codeWidthRightOfScroll = Math.min(codeOffsetWithinBox, 0) + this.ears.pos.codeWidth
      // The width of the code that is actually visible
      let codeWidthVisible = Math.min(codeWidthRightOfScroll, this.ears.pos.boxWidth)

      // Add the box left, the space between the box and the code and half of the visible weight
      // then subtract a freaking random value of 2
      return this.ears.pos.boxLeft + Math.max(0, codeOffsetWithinBox) + codeWidthVisible / 2 - 2
    },

    /**
     * The offset of the left ear
     * @return {Number} The left offset
     */
    earsLeft () {
      let leftDefault = this.codeChipMiddleOffsetLeft - 150
      return Math.max(leftDefault, 20)
    },

    /**
     * The classes applied to background container
     * @return {Object} Object with classes
     */
    backgroundClasses () {
      let d = this.dragging
      return {
        'dragging-over': d.active && !d.overCat && !d.overEars && !d.overCode && (d.mode === 'code' || d.mode === 'keyword'),
        'dragging-cursor': d.active && !d.overCat && !d.overEars && !d.overCode && (d.mode === 'code' || d.mode === 'keyword') && !d.enter,
        'dragging-cat': d.active && d.mode === 'cat',
        'dragging-code': d.active && d.mode === 'code',
        'dragging-keyword': d.active && d.mode === 'keyword'
      }
    },

    /**
     * The classes applied to code-chips
     * @return {Object} Object with classes
     */
    codeClasses () {
      let classes = {}

      let d = this.dragging
      this.codes.forEach(c => {
        classes[c.id] = {
          'has-ears': this.ears.show && this.ears.codeID === c.id,
          'dragging': d.active && d.mode === 'code' && d.codeID === c.id,
          'dragging-enter': d.active && d.mode === 'code' && d.codeID === c.id && d.enter,
          'dragging-over': d.active && (d.mode === 'code' || d.mode === 'keyword') && c.id !== d.codeID && d.overCodeID === c.id && !(this.ears.show && this.ears.codeID === c.id),
          'highlight': this.highlight.codeID === c.id,
          'highlight-long': this.highlightCodes[c.id],
          'is-new': c.new
        }
      })

      return classes
    },

    /**
     * The classes to be applied to category-tiles
     * @return {Object} Object with classes
     */
    categoryClasses () {
      let classes = {}

      let d = this.dragging
      this.codeCatsSorted.concat(['new']).forEach(cat => {
        classes[cat] = {
          'dragging': d.active && d.mode === 'cat' && d.cat === cat,
          'dragging-over': d.active && d.cat !== cat && d.overCat === cat && (d.overCodeID < 0 || d.mode === 'cat') && d.mode !== 'keyword',
          'highlight': this.highlight.cat === cat,
          'highlight-long': this.highlightCategories[cat],
          'empty': !this.codesByCat[cat] || !this.codesByCat[cat].length,
          'is-new': this.isNewCat[cat]
        }
      })

      return classes
    },

    isNewCat () {
      return _.mapValues(this.codesByCat, codes => _.every(codes, { new: true }))
    },

    /**
     * The drag hint to be displayed to the user
     * @return {String} The hint
     */

    //
    dragHint () {
      let d = this.dragging
      // Drag hint depends on mode:
      // code is dragged
      if (d.mode === 'code') {
        if (d.cat === d.overCat && (d.overCodeID < 0 || d.overCodeID === d.codeID)) return this.$t('drag_hint.drag_code_init')
        else if (d.overCodeID > 0) return this.$t('drag_hint.merge_code', { width: this.$escapeHtml(this.codeID2Code[d.overCodeID].label) })
        else if (d.overCat === 'new') return this.$t('drag_hint.new_cat_from_code')
        else if (d.overCat) return this.$t('drag_hint.change_code_category', { category: this.$escapeHtml(d.overCat) })
        else return this.$t('drag_hint.remove_code')
      // category tile is dragged
      } else if (d.mode === 'cat') {
        if (d.overCat && d.cat !== d.overCat) return this.$t('drag_hint.merge_categories', { with: this.$escapeHtml(d.overCat) })
        else if (!d.overCat) return this.$t('drag_hint.move_category')
        else return this.$t('drag_hint.drag_category_init')
      } else if (d.mode === 'keyword') {
        if (d.overCodeID === 'new') return this.$t('drag_hint.new_code_from_keyword')
        else if (d.overCodeID > 0) return this.$t('drag_hint.move_keyword_to_code', { code: this.$escapeHtml(this.codeID2Code[d.overCodeID].label) })
        else if (d.overCat || d.overEars) return this.$t('drag_hint.drag_keyword_init')
        else return this.$t('drag_hint.remove_keyword')
      } else if (d.mode === '') return ''
      else throw new Error(`Unexpected drag mode ${d.mode}`)
    },

    /**
     * If the move position indicator (for categories) should be shown
     * @return {Boolean}
     */
    showDragMoveIndicator () {
      return this.dragging.active && this.dragging.mode === 'cat' && !this.dragging.overCat && this.dragging.overBackgroundCounter > 0
    },

    /**
     * If the reset code values button is available
     * @return {Boolean}
     */
    resetEarAvailable () {
      const valueKeys = ['label', 'id', 'description', 'keywords']
      let code = this.codeID2Code[this.ears.codeID]
      let valuesOrig = _.pick(code, valueKeys)
      let valuesNew = _.pick(this.ears.editCode, valueKeys)
      return !_.isEqual(valuesOrig, valuesNew)
    },

    /**
     * The keyword currently being edited in the ear
     */
    earsEditingKeyword: {
      get (val) { return this.ears.editingKeywordIdx >= 0 ? this.ears.editCode.keywords[this.ears.editingKeywordIdx] : '' },
      set (val) {
        if (this.ears.editingKeywordIdx >= 0) {
          this.ears.editCode.keywords[this.ears.editingKeywordIdx] = val
        }
      }
    },

    /**
     * If the add keyword button is shown
     * @return {Boolean}
     */
    addKeywordAvailable () {
      return this.isListQuestion || this.ears.editCode.keywords.length < MAX_ADD_KEYWORDS
    },

    /**
     * Return the maximum code ID currently assigned to any code
     * @return {Number} code ID
     */
    maxCodeID () {
      return this.codes.length ? _.maxBy(this.codes, 'id').id : 0
    },

    /**
     * The setter method on the codes is only used for history purpose
     * If history is disabled, this will never be called
     * @type {Object}
     */
    codes: {
      get () { return this.$store.state[this.storeName].codes },
      set (codes) { this.$store.commit('setCodes', codes) }
    },

    ...Vuex.mapState({
      user: 'user',
      question (state) { return state[this.storeName].question },
      answers (state) { return state[this.storeName].answers }
    }),

    ...Vuex.mapGetters([
      'codeCats',
      'codesByCat',
      'codeID2Code',
      'isListQuestion',
      'codeID2Cat',
      'codeID2Idx'
    ])

  },

  watch: {
    /**
     * Update the category color for which the color picker is currently active
     */
    'colorPicker.val' (nn, old) {
      let oldVal = this.catColors[this.colorPicker.cat]
      let newVal = this.get3ShadesForColor(this.colorPicker.val)
      // Only do the update if the color value really changed
      // Important mostly for history
      if (newVal.medium.replace(/\s/g, '') !== oldVal.medium.replace(/\s/g, '')) {
        this.setCategoryColor(this.colorPicker.cat, this.colorPicker.val)
        this.pushHistoryState()
      }
    },

    codes () {
      // If the users creates a new code with a new category somewhere else, we need to add that category here
      this.codeCats.forEach(cat => {
        if (this.codeCatsSorted.indexOf(cat) === -1) this.codeCatsSorted.push(cat)
      })
    },

    /**
     * When scrolling in Y direction within code category tile in which ear is active
     * Check that the code the ear is for is actually visible, otherwise close it
     */
    'ears.pos.boxScrollTop' (val) {
      let codeCat = this.codeID2Cat[this.ears.codeID]
      let catIdx = this.codeCatsSorted.indexOf(codeCat)
      let nCols = nonReactiveData.categoryTileColOffsets.length
      let catHeight = nonReactiveData.categoryTileRowHeights[Math.floor(catIdx / nCols)]
      let isAboveBox = (this.ears.pos.codeTop - 75) < val
      let isBelowBox = (catHeight + 75) < (this.ears.pos.codeTop - val)
      if (isAboveBox || isBelowBox) this.closeEars()
    },

    /**
     * If the component has already been created, is then set to inactive and back again
     * we need to re-initialize some values (assuming the store has changed)
     */
    active: {
      immediate: true,
      handler () { if (this.active) this.initState() }
    }
  },

  created () {
    this.initState()
  },

  mounted () {
    // Update the position and dimension of the category tiles after render
    this.updateCategoryTilePositions()
  },

  updated () {
    // Update the position and dimension of the category tiles after render
    this.updateCategoryTilePositionsDebounced()
  },

  methods: {
    /**
     * Initiate the state of the component or adapt it to external changes of the store
     */
    initState () {
      this.$set(this, 'codeCatsSorted', _.cloneDeep(this.codeCats))

      this.codeCats.forEach((cat, idx) => {
        this.codeCatCounts[cat] = Math.random() * 100
      })

      nonReactiveData = _.cloneDeep(NON_REACTIVE_DATA_TEMPLATE)

      this.pushHistoryState()
      nonReactiveData.initialState = this.getHistoryState()
    },

    /** ************************ CODEBOOK MANIPULATION METHODS ************************ **/

    /**
     * Add new code to codebook and return it
     * @param  {String} label       Code label
     * @param  {String} category    Code category
     * @param  {Number} id          Code ID
     * @param  {String} description Code description
     * @param  {Array}  keywords    Array of keywords (Strings)
     * @return {object}             Code
     */
    newCode (label, category = '', id, description = '', keywords = []) {
      label = label.length >= MIN_TOPIC_LABEL_LEN ? label : this.$t('new_code.label')
      category = category.length >= MIN_TOPIC_CATEGORY_LEN ? category : this.$t('new_code.category')
      id = _.isNumber(id) && id > 0 ? id : this.maxCodeID + 1

      if (this.codeCatsSorted.indexOf(category) === -1) this.codeCatsSorted.push(category)

      let color = this.catColors[category]
        ? this.catColors[category].medium
        : this.$color.getMedium(this.codeCatsSorted.length - 1)
      let code = { id, label, category, description, keywords, color }
      this.$store.dispatch('addCode', { code })
      this.$nextTick(() => this.showEars(code.id))
      this.pushHistoryState()

      return code
    },

    /**
     * Proxy for deleting a code.
     * If the codebook editor uses history mode, delete the code directly.
     * Otherwise open the confirmation dialog and ask the user to confirm first.
     * @param  {Number} codeID The code ID to delete
     */
    triggerRemoveCode (codeID) {
      // Make sure ears are not open, as this would lead to errors otherwise
      this.closeEars()

      if (codeID in this.ears.justChangedCodeIDs) {
        // If the user has just changed the code id of this code, wait for the
        // change to be rendered and then open the ears
        this.$nextTick(() => { this.triggerRemoveCode(this.ears.justChangedCodeIDs[codeID]) })
        return
      }

      if (!this.history) this.showConfirmDialog('delete_code', codeID)
      else this.removeCode(codeID)
    },

    /**
     * Removes a code from the codebook
     * @param  {Number} codeID to be removed
     */
    removeCode (codeID) {
      // Make sure ears are not open, as this would lead to errors otherwise
      this.closeEars()
      let codeIdx = _.findIndex(this.codes, { id: codeID })
      if (codeIdx === -1) throw Error(`Could not remove code: Invalid ID given: ${codeID}`)

      this.$store.dispatch('deleteCode', codeID)
      this.pushHistoryState()
    },

    /**
     * Change the category of a code
     * @param  {Number} codeID   The ID of the code to change
     * @param  {String} category The name of the new category
     */
    changeCodeCategory (codeID, category) {
      let code = this.codeID2Code[this.dragging.codeID]
      let color

      // If it was dropped on the new placeholder, create a new category
      // named like the code
      if (category === 'new') category = this.newCategory(code.label)

      if (!this.catColors[category]) color = this.$color.getMedium(this.codeCatsSorted.length - 1)
      else color = this.catColors[category].medium

      // What we'll also do, is move the code to be the last one of the new category
      // just to reduce potential visual confusion
      let currentCodeIdx = this.codeID2Idx[codeID]
      let lastCodeOfCatIdx = _.findLastIndex(this.codes, c => c.category === category)
      let position = lastCodeOfCatIdx + 1
      position -= currentCodeIdx < lastCodeOfCatIdx

      this.$store.dispatch('modifyCode', {
        codeID,
        newAttributes: { category, color },
        position
      })

      this.pushHistoryState()
    },

    /**
     * Proxy for merging codes.
     * If the codebook editor uses history mode, perform the merge instantly.
     * Otherwise open the confirmation dialog and ask the user to confirm first.
     * @param  {Number} codeID1 ID of code *into which* the merge should occur
     * @param  {Number} codeID2 ID of the code which should be merged
     */
    triggerMergeCodes (codeID1, codeID2) {
      if (!this.history) this.showConfirmDialog('merge_codes', { codeID1, codeID2 })
      else this.mergeCodes(codeID1, codeID2)
    },

    /**
     * Merge two codes
     * @param  {Number} codeID1 ID of code *into which* the merge should occur
     * @param  {Number} codeID2 ID of the code which should be merged
     */
    mergeCodes (codeID1, codeID2) {
      let doneCallback = () => {
        this.highlight.codeID = codeID2
        setTimeout(() => { this.highlight.codeID = '' }, 4150)

        this.$nextTick(() => this.showEars(codeID1))
        this.pushHistoryState()
      }

      let dispatchRes = this.$store.dispatch('mergeCodes', { parentID: codeID1, childIDs: [codeID2] })
      // mergeCodes can return a promise, indicating when merging is done
      if (typeof dispatchRes.then === 'function') dispatchRes.then(doneCallback)
      else doneCallback()
    },

    /**
     * Rename a category
     * @param  {String} cat     Old category name
     * @param  {String} newName New category name
     */
    renameCategory (cat, newName) {
      newName = newName.trim()
      if (newName.length < MIN_TOPIC_CATEGORY_LEN) return
      let catIdx = this.codeCatsSorted.indexOf(cat)
      if (catIdx === -1) throw new Error(`Could not find code to rename ${cat}`)
      // Update the name in the list of code categories
      this.codeCatsSorted.splice(catIdx, 1, newName)

      // Update the name in all the codes
      let newAttributes = { category: newName }
      this.codes.forEach(c => {
        if (c.category === cat) this.$store.dispatch('modifyCode', { codeID: c.id, newAttributes })
      })
      this.pushHistoryState()
    },

    /**
     * Proxy for deleting a category.
     * If the codebook editor uses history mode, delete the category directly.
     * Otherwise open the confirmation dialog and ask the user to confirm first.
     * @param  {String} cat The category to delete
     */
    triggerRemoveCategory (cat) {
      if (!this.history) this.showConfirmDialog('delete_category', cat)
      else this.removeCategory(cat)
    },

    /**
     * Remove a category and all the codes that belonged to it
     * @param  {String} cat The category to remove
     */
    removeCategory (cat) {
      let catIdx = this.codeCatsSorted.indexOf(cat)
      if (catIdx === -1) throw new Error(`Could not find category to remove ${cat}`)

      // If the ear is open for a code of the category to be removed, close the ear.
      // Can lead to ugly issues otherwise
      if (this.ears.show && this.codeID2Code[this.ears.codeID].category === cat) this.closeEars()

      // Important: We cannot loop over the codes array and call the delete function directly,
      // as the delete function actually modifies the codes array

      let codeIDsToDelete = []

      this.codes.forEach(c => {
        if (c.category === cat) codeIDsToDelete.push(c.id)
      })

      codeIDsToDelete.map(cid => this.$store.dispatch('deleteCode', cid))
      this.codeCatsSorted.splice(catIdx, 1)

      this.pushHistoryState()
    },

    /**
     * Add new category with default name
     */
    newCategory (name = '', addHist = true) {
      let newCatName = name.length >= MIN_TOPIC_CATEGORY_LEN ? name : this.$t('new_code.category')
      newCatName = newCatName.toUpperCase()

      // Check if the new code name already exists
      // If yes, add ({INCREMENT}) as often as needed to create unique category name
      if (this.codeCatsSorted.indexOf(newCatName) !== -1) {
        let incr = 1
        let newCatNameOrig = newCatName
        while (this.codeCatsSorted.indexOf(newCatName) !== -1) {
          newCatName = `${newCatNameOrig} (${incr})`
          incr++
        }
      }

      // Add the new category to the sorted category list
      this.codeCatsSorted.push(newCatName)

      // Reset the editing category data
      this.editingCatName.cat = this.editingCatName.new = newCatName

      this.highlight.cat = newCatName
      setTimeout(() => { this.highlight.cat = '' }, 4150)
      if (addHist) this.pushHistoryState()

      return newCatName
    },

    setCategoryColor (cat, color) {
      let newAttributes = { color }
      this.codes.forEach(c => {
        if (c.category === cat) this.$store.dispatch('modifyCode', { codeID: c.id, newAttributes })
      })
    },

    /**
     * Proxy for merging categories.
     * If the codebook editor uses history mode, perform the merge instantly.
     * Otherwise open the confirmation dialog and ask the user to confirm first.
     * @param  {String} category1 The category *into which* the merge should occur
     * @param  {String} category2 The category which should be merged
     */
    triggerMergeCategories (category1, category2) {
      if (!this.history) this.showConfirmDialog('merge_categories', { category1, category2 })
      else this.mergeCategories(category1, category2)
    },

    /**
     * Merge two categories
     * @param  {String} catNew The category *into which* the merge should occur
     * @param  {String} catOld The category which should be merged
     */
    mergeCategories (catNew, catOld) {
      // Set the new category for the codes
      let newAttributes = { category: catNew }
      this.codes.forEach(c => {
        if (c.category === catOld) this.$store.dispatch('modifyCode', { codeID: c.id, newAttributes })
      })

      this.codeCatsSorted.splice(this.codeCatsSorted.indexOf(catOld), 1)

      this.highlight.cat = catNew
      setTimeout(() => { this.highlight.cat = '' }, 4150)
      this.pushHistoryState()
    },

    /**
     * Reorder a category (change it's position)
     * @param  {String} cat            The category to move
     * @param  {Number} newPositionIdx The index of the new position, where we want the category to be located
     */
    reorderCategory (cat, newPositionIdx) {
      // Get the current index of the dragged category
      let currentCatIdx = _.indexOf(this.codeCatsSorted, cat)

      if (newPositionIdx !== currentCatIdx) {
        // remove it from the codeCatsSorted array
        this.codeCatsSorted.splice(currentCatIdx, 1)

        // Add the category at the new spot
        this.codeCatsSorted.splice(newPositionIdx, 0, cat)

        // Reorder the codes by category
        let codes = []
        this.codeCatsSorted.forEach(cat => { if (this.codesByCat[cat]) codes.push(...this.codesByCat[cat]) })
        this.$store.commit('setCodes', codes)

        this.pushHistoryState()
      }
    },

    /**
     * Start editing mode for given keyword in code ear
     * @param  {Number} keywordIdx the index of the keyword to edit within the currently open code ear
     */
    startEditingKeyword (keywordIdx) {
      if (this.editable) this.ears.editingKeywordIdx = keywordIdx
    },

    /**
     * Create new keyword in ear currently being edited
     */
    newKeyword () {
      this.ears.editingKeywordIdx = this.ears.editCode.keywords.length
      this.ears.editCode.keywords.push(this.$t('keywords.new'))
    },

    /**
     * Remove a keyword from the currently editing ear
     * @param  {Number} keywordIdx The index of the keyword to remove
     */
    removeKeywordFromEar (keywordIdx) {
      this.$set(this.ears.editCode, 'keywords', this.ears.editCode.keywords.filter((_, idx) => idx !== keywordIdx))
    },

    /**
     * Remove a keyword from a code
     * @param  {Number} codeID     The code ID from which to remove the keyword
     * @param  {Number} keywordIdx The index of the keyword to remove
     */
    removeKeywordFromCode (codeID, keywordIdx) {
      let oldOwnerCode = this.codeID2Code[codeID]
      let keywords = oldOwnerCode.keywords.filter((_, idx) => idx !== keywordIdx)
      this.$store.dispatch('modifyCode', { codeID: codeID, newAttributes: { keywords } })
      this.pushHistoryState()
    },

    /**
     * Move a keyword from one code to another
     * @param  {Number} oldCodeID  ID of the old owner code
     * @param  {Number} newCodeID  ID of the new owner code
     * @param  {Number} keywordIdx Index of the keyword to be moved
     */
    moveKeywordToCode (oldCodeID, newCodeID, keywordIdx) {
      if (oldCodeID !== newCodeID) {
        let oldOwnerCode = this.codeID2Code[oldCodeID]
        let newOwnerCode = this.codeID2Code[newCodeID]
        let keyword = oldOwnerCode.keywords[keywordIdx]

        this.$store.dispatch('modifyCode', {
          codeID: oldOwnerCode.id,
          newAttributes: { keywords: oldOwnerCode.keywords.filter((_, idx) => idx !== keywordIdx) }
        })
        this.$store.dispatch('modifyCode', {
          codeID: newOwnerCode.id,
          newAttributes: { keywords: [...newOwnerCode.keywords, keyword] }
        })
        this.pushHistoryState()
      }
    },

    /** ************************ UI METHODS ************************ **/

    /**
     * Helper function which updates the positions of the categoriy tiles after render events
     * Stores tile positions & dimensions in non reactive data object
     */
    updateCategoryTilePositions () {
      let colOffsets = []
      let rowOffsets = []
      let colWidths = []
      let rowHeights = []
      let rows = 0

      // Go through all tiles...
      if (!this.$refs['category-tile-container']) return
      let tiles = this.$refs['category-tile-container'].getElementsByClassName('category-tile-el')

      let overlayDimensions = []

      // Only go up to the second-last element, as we don't want to include the new category placeholder element
      _.slice(tiles, 0, tiles.length - 1).forEach(el => {
        overlayDimensions.push({ width: el.offsetWidth - 60, height: el.offsetHeight - 20 })
        if (el.offsetTop !== rowOffsets.slice(-1)[0]) {
          rows += rowOffsets.length > 0 ? 1 : 0
          rowOffsets.push(el.offsetTop)
          rowHeights.push(el.offsetHeight)
        }
        if (rows === 0 && el.offsetLeft !== colOffsets.slice(-1)[0]) {
          colOffsets.push(el.offsetLeft)
          colWidths.push(el.offsetWidth)
        }
      })

      if (!_.isEqual(this.overlayDimensions, overlayDimensions)) this.$set(this, 'overlayDimensions', overlayDimensions)

      nonReactiveData.categoryTileColOffsets = colOffsets
      nonReactiveData.categoryTileColWidths = colWidths
      nonReactiveData.categoryTileRowOffsets = rowOffsets
      nonReactiveData.categoryTileRowHeights = rowHeights
    },

    /**
     * Open the color picker, for changing the color of a category
     * @param  {String} cat    Category name
     * @param  {Object} $event The event which triggered the opening (required for position)
     */
    showColorPicker (cat, $event) {
      if (!this.editable) return
      this.closeEars()
      this.colorPicker.cat = cat
      this.colorPicker.offsetLeft = $event.target.offsetLeft
      this.colorPicker.offsetTop = $event.target.offsetTop
      this.colorPicker.val = this.catColors[cat].medium
      this.colorPicker.active = true
    },

    /**
     * Open the confirm dialog, which can be used for differnet purposes
     * e.g. confirming deletion of a category
     * @param  {String} mode        The mode for which the confirm dialog is used
     * @param  {Variable} variable  A free variable, whose usage depends on the mode
     */
    showConfirmDialog (mode, variable) {
      this.confirmDialog.active = true
      this.confirmDialog.mode = mode
      this.confirmDialog.i18nProps = {}

      let onConfirm
      switch (mode) {
        case 'delete_category':
          onConfirm = () => this.removeCategory(variable)
          this.confirmDialog.i18nProps = { category: this.$escapeHtml(variable) }
          break
        case 'delete_code':
          onConfirm = () => this.removeCode(variable)
          this.confirmDialog.i18nProps = { code: this.$escapeHtml(this.codeID2Code[variable].label) }
          break
        case 'merge_codes': {
          let { codeID1, codeID2 } = variable
          onConfirm = () => this.mergeCodes(codeID1, codeID2)
          this.confirmDialog.i18nProps = { code1: this.$escapeHtml(this.codeID2Code[codeID1].label), code2: this.$escapeHtml(this.codeID2Code[codeID2].label) }
          break
        }
        case 'merge_categories':
          onConfirm = () => this.mergeCategories(variable.category1, variable.category2)

          this.confirmDialog.i18nProps = {
            category1: this.$escapeHtml(variable.category1),
            category2: this.$escapeHtml(variable.category2)
          }

          break
        case 'reset':
          onConfirm = () => this.resetToInitialState()
          break
        default:
          throw Error(`Unknown confirm dialog mode '${mode}`)
      }

      this.confirmDialog.onConfirm = onConfirm
    },

    /**
     * Close the confirm dialog. If the action was approved, call the onSubmit callback
     * @param  {Boolean} doIt True if the user confirmed the action, false otherwise
     */
    closeConfirmDialog (doIt) {
      if (doIt) this.confirmDialog.onConfirm()
      this.confirmDialog.active = false
      this.confirmDialog.onConfirm = null
    },

    /**
     * Get the code chip element (dom node) of the currently focused code ID
     * @return {Node}
     */
    getSelectedCodeChipEl () {
      return this.$refs[`code-${this.ears.codeID}`][0].$el
    },

    /**
     * Event listener function, attached to scroll event of category of code which
     * is currently open in ear. Updates the position of the ear when scrolling.
     * @param  {Event} $evt The scroll event
     */
    updateEarsParentScroll ($evt) {
      this.ears.pos.boxScrollLeft = $evt.target.scrollLeft
      this.ears.pos.boxScrollTop = $evt.target.scrollTop
    },

    /**
     * Show the editing ear for the current code ID
     * @param  {Number} codeID The ID of the code to open the ear for
     */
    showEars (codeID) {
      if (this.ears.show) this.closeEars()
      if (codeID in this.ears.justChangedCodeIDs) {
        // If the user has just changed the code id of this code, wait for the
        // change to be rendered and then open the ears
        this.$nextTick(() => { this.showEars(this.ears.justChangedCodeIDs[codeID]) })
        return
      }
      let code = this.codeID2Code[codeID]

      this.ears.codeID = codeID
      let codeChipEl = this.getSelectedCodeChipEl()
      let { offsetTop, offsetLeft, offsetWidth } = codeChipEl
      let parentNode = codeChipEl.parentNode

      this.ears.editCode.label = code.label === this.$t('new_code.label') ? '' : code.label
      this.ears.editCode.id = code.id
      this.ears.editCode.description = code.description
      this.ears.editCode.keywords = _.cloneDeep(code.keywords)
      this.ears.editCode.new = code.new
      this.ears.editCode.special = code.special

      this.ears.pos.codeTop = offsetTop
      this.ears.pos.codeLeft = offsetLeft
      this.ears.pos.codeWidth = offsetWidth
      this.ears.pos.boxLeft = parentNode.offsetLeft
      this.ears.pos.boxWidth = parentNode.offsetWidth
      this.ears.pos.boxScrollLeft = parentNode.scrollLeft
      this.ears.pos.boxScrollTop = parentNode.scrollTop
      this.ears.inheritsFromIDLock = this.question.inherits_from !== null
      this.ears.show = this.ears.animate = true
      parentNode.addEventListener('scroll', this.updateEarsParentScroll)
      setTimeout(() => { this.ears.animate = false }, 400)
    },

    /**
     * Reset the values of the code currently being edited in the ear
     */
    resetEar () {
      let code = this.codeID2Code[this.ears.codeID]

      this.ears.editCode.label = code.label
      this.ears.editCode.id = code.id
      this.ears.editCode.description = code.description
      this.ears.editCode.keywords = _.cloneDeep(code.keywords)
    },

    /**
     * Close and save the ears (menu) belonging to a code
     */
    closeEars () {
      // Remove the scroll listener on the category, which adapted the position of the ears dialog
      if (this.ears.show) {
        this.getSelectedCodeChipEl().parentNode.removeEventListener('scroll', this.updateEarsParentScroll)

        // Assign the potentially modified code properties to the actual code
        let code = this.codeID2Code[this.ears.codeID]
        let newAttributes = {
          label: this.ears.editCode.label.length >= MIN_TOPIC_LABEL_LEN ? this.ears.editCode.label : code.label,
          description: this.ears.editCode.description,
          keywords: this.ears.editCode.keywords.filter(kw => _.trim(kw).length > 0)
        }

        let newID = _.toNumber(this.ears.editCode.id)
        if (!_.isNaN(newID) && newID !== code.id && !this.earsIDError) {
          let oldID = code.id
          // There is exactly one situation where the justChangedCodeIDs becomes relevant:
          // The user changes the code ID of a code, and then - without closing the ear
          // clicks again on the same code
          this.ears.justChangedCodeIDs[oldID] = newID
          setTimeout(() => { delete this.ears.justChangedCodeIDs[oldID] }, 100)
          newAttributes.id = newID
        }
        this.$store.dispatch('modifyCode', { codeID: code.id, newAttributes })
        // Hide the ears
        this.ears.show = false
      }
    },

    /**
     * When blurring the editing keyword input box: Unset the current editing keyword idx
     */
    onBlurEditingKeyword (idx) {
      // Hacky-hack to prevent blur event from firing before the click
      // which will lead to clicks on other keywords potentially be in nirvana and not firing
      setTimeout(() => {
        if (this.ears.editingKeywordIdx === idx) this.ears.editingKeywordIdx = -1
        // If this keyword index still exists (might not exist anymore because previous keyword was deleted)
        // then delete the keyword
        if (this.ears.editCode.keywords[idx] && !this.ears.editCode.keywords[idx].length) this.removeKeywordFromEar(idx)
      }, 100)
    },

    /**
     * Convert category title span to an input field, to edit category name
     * @param  {String}   The category to edit
     */
    startEditingCatName (cat) {
      if (!this.editable) return
      this.editingCatName.cat = cat
      this.editingCatName.new = cat
    },

    /**
     * When blurring the editing category input box: Unset the currently editing category name
     */
    onBlurEditingCatName () {
      // Only work on the next tick, as update of model is debounced
      this.$nextTick(() => {
        // If nothing changed, do nothing
        if (this.editingCatName.cat !== this.editingCatName.new) {
          // There is a special case we need to consider:
          // The new category name is already occupied by another category
          // In this case we merge the two
          let newName = this.editingCatName.new.toUpperCase()
          if (this.codeCatsSorted.indexOf(newName) !== -1) {
            this.mergeCategories(newName, this.editingCatName.cat)
          } else {
            this.renameCategory(this.editingCatName.cat, newName)
          }
          this.pushHistoryState()
        }
        this.editingCatName.cat = this.editingCatName.new = ''
      })
    },

    /** ************************ DRAG METHODS ************************ **/

    /**
     * Initialize the dragging mode (called indirectly via more specific event handlers)
     * @param  {String} mode The dragging mode {code,cat,keyword}
     * @param  {Event} $evt  The drag event
     */
    initDrag (mode, $evt) {
      if (mode !== 'keyword') this.closeEars()
      // firefox needs to have something to bite here.. otherwise won't initiate drag
      $evt.dataTransfer.setData('text', 'anything')
      this.dragging.active = true
      this.dragging.mode = mode
      this.dragging.enter = true
      setTimeout(() => { this.dragging.enter = false }, 10)
    },

    /**
     * Stop the drag mode
     */
    endDrag () {
      // If no successful drop has been performed, highlight the position
      if (!this.dragging.changed) {
        if (this.dragging.mode === 'code') {
          this.highlight.codeID = this.dragging.codeID
          setTimeout(() => { this.highlight.codeID = -1 }, 4150)
        } else if (this.dragging.mode === 'keyword') {
          this.showEars(this.dragging.codeID)
          this.highlight.keywordIdx = this.dragging.keywordIdx
          setTimeout(() => { this.highlight.keywordIdx = -1 }, 4150)
        }
      }

      this.dragging.active = false
      this.dragging.changed = false
      this.dragging.codeID = -1
      this.dragging.cat = ''
      this.dragging.keywordIdx = -1
      this.dragging.startedByMoveHandler = false
      this.dragging.mode = ''
      this.dragging.enter = false
      this.dragging.overCat = ''
      this.dragging.overCodeID = -1
      this.dragging.overEars = false

      nonReactiveData.draggingOverCodeCounter = 0
      nonReactiveData.draggingOverCatCounter = 0
      nonReactiveData.draggingOverEarsCounter = 0
      this.dragging.overBackgroundCounter = 0
    },

    /**
     * Start dragging a code
     * @param  {Number} codeID  The ID of the code being dragged
     * @param  {Event} $evt     The drag event
     */
    onDragStartCode (codeID, $evt) {
      this.initDrag('code', $evt)
      this.dragging.codeID = codeID
      this.dragging.cat = this.codeID2Cat[codeID]
    },

    /**
     * Start dragging a category
     * @param  {String} cat     The name of the category being dragged
     * @param  {Event} $evt     The drag event
     */
    onDragStartCategory (cat, $evt) {
      // If the drag event was not initiated from the drag handler icon, don't allow the dragging
      if (!this.dragging.startedByMoveHandler) {
        $evt.preventDefault()
        $evt.stopPropagation()
        return false
      }

      this.initDrag('cat', $evt)
      this.dragging.cat = cat
    },

    /**
     * Start dragging a keyword
     * @param  {Number} keywordIdx     The index of the keyword of the currently focused code which is being dragged
     * @param  {Event} $evt            The drag event
     */
    onDragStartKeyword (keywordIdx, $evt) {
      // Save the code it to the dragging object as well, as ears might close in between
      this.dragging.codeID = this.ears.codeID
      this.dragging.keywordIdx = keywordIdx
      this.initDrag('keyword', $evt)
    },

    /**
     * Stop dragging a code
     * @param  {Number} codeID The code which is stopped being dragged
     * @param  {Event} $evt    The drag event
     */
    onDragEndCode (codeID, $evt) {
      this.endDrag()
    },

    /**
     * Stop dragging a category
     * @param  {String} cat     The name of the category which is stopped being dragged
     * @param  {Event} $evt     The drag event
     */
    onDragEndCategory (cat, $evt) {
      this.endDrag()
    },

    /**
     * Stop dragging a category
     * @param  {Number} idx     The index of the keyword of the currently focused code which is stopped stopped being dragged
     * @param  {Event} $evt     The drag event
     */
    onDragEndKeyword (idx, $evt) {
      this.endDrag()
    },

    /**
     * When dropping the currently dragged element on the background
     * @param  {Event} $evt The drop event
     */
    onDropOnBackground ($evt) {
      let d = this.dragging
      if (!d.active) return
      if (this.dragging.mode === 'cat') {
        // category drag mode means the dragged category is newly sorted

        // Get the row and column index of category tile to left of mouse event position
        let leftCatIdx = d.moveIndicator.rowIdx * nonReactiveData.categoryTileColOffsets.length + d.moveIndicator.colIdx

        // If the dragged cat has been located before the newPositionIdx
        // subtract one from the new position to account for the splice
        let currentCatIdx = _.indexOf(this.codeCatsSorted, d.cat)
        leftCatIdx -= currentCatIdx < leftCatIdx

        this.reorderCategory(d.cat, leftCatIdx)

        this.highlight.cat = d.cat
        setTimeout(() => { this.highlight.cat = '' }, 4150)

        this.dragging.changed = true
      } else if (this.dragging.mode === 'code') {
        // code drag means this code is purged
        this.removeCode(this.dragging.codeID)
        this.dragging.changed = true
      } else if (this.dragging.mode === 'keyword') {
        // Dropping a keyword onto the background means removing it
        this.removeKeywordFromCode(this.dragging.codeID, this.dragging.keywordIdx)

        this.$nextTick(() => this.showEars(this.dragging.codeID))
        // Highlight both the new code as well as the keyword within the code
        this.highlight.codeID = this.dragging.codeID
        setTimeout(() => {
          this.highlight.codeID = -1
        }, 4150)
        this.dragging.changed = true
      }
    },

    /**
     * When dropping the currently dragged element on a category
     * @param  {String} cat The category onto which the element is dropped
     * @param  {Event} $evt The drop event
     */
    onDropOnCategory (cat, $evt) {
      let d = this.dragging
      if (!d.active) return
      if (this.dragging.mode === 'cat') {
        // When dropping a category on another category, this means merging the two
        // The dragged one will thereby cease to exist

        // Don't do anything if category was droppped on itself
        if (d.cat === cat) return

        this.triggerMergeCategories(cat, d.cat)

        this.dragging.changed = true
      } else if (this.dragging.mode === 'code') {
        // Dropping a code onto a category means the code's category has been changed
        this.changeCodeCategory(this.dragging.codeID, cat)

        // Highlight the new code position
        this.highlight.codeID = this.dragging.codeID
        setTimeout(() => { this.highlight.codeID = -1 }, 4150)
        this.dragging.changed = true
      }
    },

    /**
     * When dropping the currently dragged element on a code
     * @param  {Number} codeID  The code ID onto which the element is dropped
     * @param  {Event} $evt     The drop event
     * @param  {String} cat     The category of the code on which the keyword is dropped
     */
    onDropOnCode (codeID, $evt) {
      let d = this.dragging
      if (!d.active) return
      // Don't act if anything was done with the special code
      if (this.codeID2Code[this.dragging.codeID].special || (codeID !== 'new' && this.codeID2Code[codeID].special)) return
      if (this.dragging.mode === 'code') {
        // Only merge if the code was not dropped on itself
        if (codeID !== d.codeID) {
          // When dropping a code onto another code, this means merging the two
          this.triggerMergeCodes(codeID, d.codeID)
        }
        this.dragging.changed = true
      } else if (this.dragging.mode === 'keyword') {
        // Dropping a keyword onto another code means we remove it from the original location and add it to the new one

        let oldOwnerCode = this.codeID2Code[this.dragging.codeID]
        let keyword = oldOwnerCode.keywords[this.dragging.keywordIdx]
        if (codeID === 'new') codeID = this.newCode(keyword, this.dragging.overCat).id
        this.moveKeywordToCode(this.dragging.codeID, codeID, this.dragging.keywordIdx)

        this.$nextTick(() => this.showEars(codeID))

        // Highlight both the new code as well as the keyword within the code
        this.highlight.codeID = codeID
        setTimeout(() => {
          this.highlight.codeID = -1
          this.highlight.keywordIdx = -1
        }, 4150)
        this.dragging.changed = true
      }
    },

    /**
     * Start hovering over code during drag
     * @param  {codeID} codeID   The code over which the dragged element is hovering
     * @param  {Event} $evt      The mouseover event
     */
    onDragEnterCode (codeID, $evt) {
      nonReactiveData.draggingOverCodeCounter++
      this.dragging.overCodeID = codeID
    },

    /**
     * Stop hovering over code during drag
     * @param  {codeID} codeID   The code over which the dragged element is hovering
     * @param  {Event} $evt      The mouseover event
     */
    onDragLeaveCode (codeID, $evt) {
      if (--nonReactiveData.draggingOverCodeCounter === 0) this.dragging.overCodeID = -1
    },

    /**
     * Proxy for onDragEnterCategory: Only valid if not category mode, otherwise
     * dragging is controlled by drag overlay
     * @param  {String} category The category over which the dragged element is hovering
     * @param  {Event} $evt      The mouseover event
     */
    onMaybeDragEnterCategory (category, $evt) {
      if (this.dragging.mode !== 'cat') this.onDragEnterCategory(category, $evt)
    },

    /**
     * Start hovering over category during drag
     * @param  {String} category The category over which the dragged element is hovering
     * @param  {Event} $evt      The mouseover event
     */
    onDragEnterCategory (category, $evt) {
      nonReactiveData.draggingOverCatCounter++
      this.dragging.overCat = category
    },

    /**
     * Proxy for onDragLeaveCategory: Only valid if not category mode, otherwise
     * dragging is controlled by drag overlay
     * @param  {String} category The category over which the dragged element is hovering
     * @param  {Event} $evt      The mouseover event
     */
    onMaybeDragLeaveCategory (category, $evt) {
      if (this.dragging.mode !== 'cat') this.onDragLeaveCategory(category, $evt)
    },

    /**
     * Stop hovering over category during drag
     * @param  {String} category The category over which the dragged element is hovering
     * @param  {Event} $evt      The mouseover event
     */
    onDragLeaveCategory (category, $evt) {
      if (--nonReactiveData.draggingOverCatCounter === 0) this.dragging.overCat = ''
    },

    /**
     * Start hovering over background during drag
     * @param  {Event} $evt      The mouseover event
     */
    onDragEnterBackground ($evt) {
      this.dragging.overBackgroundCounter++
    },

    /**
     * Stop hovering over background during drag
     * @param  {Event} $evt      The mouseover event
     */
    onDragLeaveBackground ($evt) {
      this.dragging.overBackgroundCounter--
    },

    /**
     * Start hovering over ear during drag
     * @param  {Event} $evt      The mouseover event
     */
    onDragEnterEars ($evt) {
      nonReactiveData.draggingOverEarsCounter++
      this.dragging.overEars = true
    },

    /**
     * Stop hovering over ear during drag
     * @param  {Event} $evt      The mouseover event
     */
    onDragLeaveEars ($evt) {
      if (--nonReactiveData.draggingOverEarsCounter === 0) {
        this.closeEars()
        this.dragging.overEars = false
      }
    },

    /**
     * Event handler when category is dragged over background
     * If appicable, updates the position & dimension of the dragMoveIndicator
     * @param  {Object} Event object
     */
    onDragOverBackground ($evt) {
      if (this.showDragMoveIndicator) {
        // Get the row and column index of category tile to left of mouse event position
        let nRows = nonReactiveData.categoryTileRowOffsets.length
        let nCols = nonReactiveData.categoryTileColOffsets.length
        let colIdx = _.findLastIndex(nonReactiveData.categoryTileColOffsets, el => el - 100 < $evt.layerX)
        let rowIdx = _.findLastIndex(nonReactiveData.categoryTileRowOffsets, el => el < $evt.layerY)

        // Make sure that the indicator isn't shown in the last row, where no categories actually exist
        if ((rowIdx + 1) === nRows) colIdx = Math.min(colIdx, (this.codeCatsSorted.length - 1) % nCols + 1)

        // Look up the positions of the element on left
        let left = nonReactiveData.categoryTileColOffsets[colIdx]
        let width = nonReactiveData.categoryTileColWidths[colIdx]

        // If we're on the very right, add the width of the last element
        if (colIdx === (nCols - 1) && ($evt.layerX + 100) > (left + width)) {
          // Check if there are any valid elements to the right at all
          let canGoRight = (rowIdx + 1) < nRows || (((this.codeCatsSorted.length - 1) % nCols + 1) > colIdx)
          if (canGoRight) {
            left += (width + 16) * canGoRight
            colIdx += canGoRight
          }
        }

        let top = nonReactiveData.categoryTileRowOffsets[rowIdx]
        let height = nonReactiveData.categoryTileRowHeights[rowIdx]

        this.dragging.moveIndicator.left = left
        this.dragging.moveIndicator.top = top
        this.dragging.moveIndicator.height = height
        this.dragging.moveIndicator.colIdx = colIdx
        this.dragging.moveIndicator.rowIdx = rowIdx
      }
    },

    /** ************************ EMIT PROXIES ************************ **/

    /**
     * Add a filter to the verbatim browser, filtering by the given codeID
     * if such a filter already exists, do nothing
     * @param  {Number} codeID The code ID to look for
     */
    filterVerbatimsByCode (codeID) {
      this.$emit('set-codes-filter', [codeID])
      // When the code ear is open, re-open it after a tiny delay
      // to make sure it is still positioned correctly after opening the verbatim sidebar (in wizard)
      if (this.ears.show) setTimeout(() => { this.showEars(this.ears.codeID) }, 10)
    },

    /** ************************ HISTORY METHODS ************************ **/

    /**
     * Go one step ahead in the history queue
     */
    redo () {
      if (this.currentHistPosition >= (this.histSize - 1)) throw Error(`Redo not allowed, we're at the end of the queue`)
      this.currentHistPosition++
      this.setHistoryState(nonReactiveData.history[this.currentHistPosition])
    },

    /**
     * Go one step backwards in the history queue
     */
    undo () {
      if (this.currentHistPosition < 1) throw Error(`Undo not allowed, we're at the start of the queue`)
      this.currentHistPosition--
      this.setHistoryState(nonReactiveData.history[this.currentHistPosition])
    },

    /**
     * Reset the entire codebook to the initial state
     */
    resetToInitialState () {
      this.setHistoryState(nonReactiveData.initialState)
      nonReactiveData.history.splice(0)
      this.pushHistoryState()
      this.$emit('initial-state')
    },

    /**
     * Set the current state from a history state
     * @param {Object} state Object with all elements in HIST_VARS as key
     */
    setHistoryState (state) {
      this.closeEars()
      HIST_VARS.forEach(v => {
        this.$set(this, v, _.cloneDeep(state[v]))
      })
    },

    /**
     * Get the current state from the data
     * @return {Object} state Object with all elements in HIST_VARS as key
     */
    getHistoryState () {
      let state = {}
      HIST_VARS.forEach(v => {
        state[v] = _.cloneDeep(this[v])
      })
      return state
    },

    /**
     * Add the current state to the history queue
     * If we are not at the last history position, remove everything in front
     */
    pushHistoryState () {
      if (!this.history) return
      if (this.currentHistPosition !== (this.histSize - 1)) {
        // If we're not at the end of the stack, remove everything in front of us
        nonReactiveData.history.splice(this.currentHistPosition + 1, this.histSize)
      }

      if (nonReactiveData.history.length >= MAX_HIST_SIZE) nonReactiveData.history.shift()

      nonReactiveData.history.push(this.getHistoryState())
      this.histSize = nonReactiveData.history.length
      this.currentHistPosition = this.histSize - 1
    }
  }
}

</script>

<style lang=scss>
  @import '~css/codebook';
</style>

<i18n locale='en' src='@/i18n/en/components/CodebookEditor.json' />
<i18n locale='de' src='@/i18n/de/components/CodebookEditor.json' />