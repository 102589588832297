<template>
  <DialogWrapper
    v-model="internalValue"
    :width="
      step === 'verbatims' &&
        addOrEditPayload.datasets &&
        addOrEditPayload.datasets[0] &&
        addOrEditPayload.datasets[0].question &&
        addOrEditPayload.datasets[0].question !== null ? 600 : 1220
    "
  >
    <DialogHeader>
      <template slot="title">
        {{ $t('add_or_edit.title') }}
      </template>
      <template slot="controls">
        <v-icon
          @click="closeDialog"
          :size="20"
        >
          mdi-close
        </v-icon>
      </template>
    </DialogHeader>
    <DialogContent
      class="px-4 py-2 dashboard-add-or-edit"
      style="position: relative;"
    >
      <DashboardDetailAddOrEditElementTypeSelect
        v-if="step === 'type-select'"
        @gotoAddElementStep="setStep"
      />
      <DashboardDetailAddOrEditElementCharts
        v-else-if="step === 'chart'"
        v-model="addOrEditPayload"
      />
      <DashboardDetailAddOrEditElementText
        v-else-if="step === 'text'"
        v-model="addOrEditPayload"
      />
      <DashboardDetailAddOrEditElementVerbatim
        v-else-if="step === 'verbatims'"
        v-model="addOrEditPayload"
      />
    </DialogContent>
    <DialogActions>
      <template slot="rightSide">
        <v-btn
          color="primary"
          outlined
          class="mr-2"
          @click.native="closeDialog"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          v-if="saveAddOrEditAvailable"
          color="primary"
          @click.native="save"
        >
          {{ basicData.chart ? $t('save.title') : $t('add_or_edit.add') }}
        </v-btn>
      </template>
    </DialogActions>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import DashboardDetailAddOrEditElementTypeSelect from '@/components/modals/DashboardDetailAddOrEditElement/DashboardDetailAddOrEditElementTypeSelect'
import DashboardDetailAddOrEditElementCharts from '@/components/modals/DashboardDetailAddOrEditElement/DashboardDetailAddOrEditElementCharts'
import DashboardDetailAddOrEditElementText from '@/components/modals/DashboardDetailAddOrEditElement/DashboardDetailAddOrEditElementText'
import DashboardDetailAddOrEditElementVerbatim from '@/components/modals/DashboardDetailAddOrEditElement/DashboardDetailAddOrEditElementVerbatim'

import DataMixin from '@/components/customUi/mixins/data'
import DialogMixin from '@/mixins/dialogMixin.js'
import { generateProvide } from '@/components/customUi/mixins/provideInject'

import Vuex from 'vuex'

const VERBATIM_DEFAULT = {
  name: '',
  type: 'P_VRB',
  config: {},
  datasets: []
}

const TEXT_DEFAULT = {
  name: '',
  type: 'P_TXT',
  config: {
    title: '',
    text: '',
    alignTitle: 'center',
    alignText: 'center',
    colorTitle: 'rgba(66, 66, 66, 1.0)',
    colorText: 'rgba(66, 66, 66, 1.0)',
    colorBG: 'rgba(255, 255, 255, 1.0)'
  }
}
const CHART_DEFAULT = {
  chart: {
    id: null,
    type: ''
  }
}

const DEFAULT_MAP = {
  'chart': CHART_DEFAULT,
  'text': TEXT_DEFAULT,
  'verbatims': VERBATIM_DEFAULT
}

export default {
  name: 'DashboardDetailAddOrEditElement',
  codit: true,

  components: {
    DialogWrapper,
    DialogHeader,
    DialogContent,
    DialogActions,
    DashboardDetailAddOrEditElementTypeSelect,
    DashboardDetailAddOrEditElementCharts,
    DashboardDetailAddOrEditElementText,
    DashboardDetailAddOrEditElementVerbatim
  },

  mixins: [DataMixin, DialogMixin],

  props: {
    basicData: { type: [Object, null], default: null }
  },

  data () {
    return {
      step: 'type-select',
      addOrEditPayload: null,
      creditsOpen: 0,
      creditsOpenQuestions: [],
      lastTextboxColorScheme: null
    }
  },

  provide () {
    return generateProvide.call(this, {
      creditsOpen: 'creditsOpen',
      creditsOpenQuestions: 'creditsOpenQuestions'
    })
  },

  computed: {
    ...Vuex.mapGetters({
      userCreditsRemaining: 'userCreditsRemaining'
    }),

    ...Vuex.mapGetters({
      metaManager: 'metaManager/overall'
    }),

    /**
    * Returns true if the "add" button in the add or edit dialog should be shown
    */
    saveAddOrEditAvailable () {
      if (this.step === 'text') {
        return true
      } else if (
        this.step === 'verbatims' &&
        this.addOrEditPayload?.datasets?.[0]?.question &&
        this.userCreditsRemaining >= this.creditsOpen
      ) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    internalValue (val) {
      if (val) {
        this.init()
      } else {
        this.saveTextboxColorScheme()
        this.reset()
      }
    },

    'addOrEditPayload' () {
      if (this.step === 'chart' && !!this.addOrEditPayload.chart.id) this.save()
    }
  },

  methods: {
    save () {
      let payload
      const newEl = {
        x: this.basicData.x,
        y: this.basicData.y,
        width: this.basicData.width,
        height: this.basicData.height
      }

      if (
        this.step === 'verbatims' &&
        this.addOrEditPayload.config?.filters?.some(({ type }) => type === 'topics')
      ) {
        const filterTopicIndex = _.findIndex(this.addOrEditPayload.config.filters, ({ type }) => type === 'topics')
        this.addOrEditPayload.config.filters[filterTopicIndex].value = this.addOrEditPayload.config.filters[filterTopicIndex].value.map(({ id, sentiment }) => [id, sentiment || 'any'])
      }

      if (
        this.step === 'chart'
      ) {
        payload = {
          ...newEl,
          ...this.addOrEditPayload,
          status: { dirty: true }
        }
      } else if (
        this.basicData.chart
      ) {
        payload = this.addOrEditPayload
      } else {
        payload = {
          ...newEl,
          ...this.addOrEditPayload,
          status: { dirty: true }
        }
      }

      this.$emit('addElement', _.cloneDeep({
        payload,
        step: this.step,
        edit: !!this.basicData.chart
      }))
      this.closeDialog()
    },

    setStep (value) {
      this.step = value
      let defaultElement = _.cloneDeep(DEFAULT_MAP[value])

      if (
        value === 'text' &&
        this.lastTextboxColorScheme !== null
      ) {
        defaultElement.config = {
          ...defaultElement.config,
          ...this.lastTextboxColorScheme
        }
      }

      this.$set(this, 'addOrEditPayload', defaultElement)
    },

    init () {
      // Update the user, to get the currently available credits
      this.$root.getUser()

      if (!this.basicData?.chart?.type) {
        this.step = 'type-select'
        return
      } else if (
        this.basicData.chart.type === 'P_TXT'
      ) {
        this.step = 'text'
        this.$set(this, 'addOrEditPayload', _.cloneDeep(this.basicData.chart))
      } else if (
        this.basicData.chart.type === 'P_VRB'
      ) {
        this.step = 'verbatims'
        this.$set(this, 'addOrEditPayload', {
          ...this.basicData.chart,
          config: _.cloneDeep(this.basicData.chart.config),
          datasets: [{
            ...this.basicData.chart.datasets[0],
            settings: _.cloneDeep(this.basicData.chart.datasets[0].settings),
            filters: _.cloneDeep(this.basicData.chart.datasets[0].filters),
            question: this.basicData.chart.datasets[0].question
          }]
        })
      }

      if (
        this.basicData?.chart.type === 'P_VRB' &&
        this.basicData.chart.config?.filters?.some(({ type }) => type === 'topics')
      ) {
        const filterTopicIndex = _.findIndex(this.basicData.chart.config.filters, ({ type }) => type === 'topics')
        const correctedValue = this.basicData.chart.config.filters[filterTopicIndex].value.map(
          ([id, sentiment]) => ({
            ...this.metaManager[this.basicData.chart.id].topics_complete_union.find(topic => topic.id === id),
            sentiment
          })
        )

        this.$set(this.addOrEditPayload.config.filters[filterTopicIndex], 'value', correctedValue)
      }
    },

    reset () {
      this.step = 'type-select'
      this.addOrEditPayload = null
      this.creditsOpen = 0
      this.creditsOpenQuestions = []
    },

    saveTextboxColorScheme () {
      if (this.step !== 'text') return
      this.lastTextboxColorScheme = _.pick(this.addOrEditPayload.config, ['colorTitle', 'colorText', 'colorBG'])
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />