<template>
  <div>
    <div class="organize-columns__header mb-1 d-flex justify-space-between align-center position-relative">
      <span class="d-flex align-center">
        <helptip
          class="column-helptip mr-1 ml-0"
          position="bottom"
          color="primary"
          :icon-style="`font-size: 16px; color: ${getColumnColors(getColumnType(column))};`"
          :width="250"
          x-offset="107px"
        >
          <!--offset it to make offset 10px-->
          <span>
            <div v-if="isNullColumn(getColumnType(column))">
              {{ getTypeLabelDescription(getColumnType(column)) }}
            </div>
            <div v-if="column.n_chars" class="d-flex align-center justify-space-between">
              {{ $t('steps.organize.stats.n_chars') }}:
              <b>{{ Number(column.n_chars.avg).toFixed(0) }}</b>
            </div>
            <div v-if="column.min" class="d-flex align-center justify-space-between">
              {{ $t('steps.organize.stats.min') }}:
              <b>{{ Number(column.min).toFixed(0) }}</b>
            </div>
            <div v-if="column.max" class="d-flex align-center justify-space-between">
              {{ $t('steps.organize.stats.max') }}:
              <b>{{ Number(column.max).toFixed(0) }}</b>
            </div>
            <div v-if="column.n_non_empty" class="d-flex align-center justify-space-between">
              {{ $t('steps.organize.stats.n_non_empty') }}:
              <b>{{ Number(column.n_non_empty).toFixed(0) }}</b>
            </div>
          </span>
        </helptip>
        <div class="organize-columns__header d-flex align-center">
          <div class="organize-columns__header__name">
            {{ getColumnLabel(column.name) }}
          </div>
          <v-icon
            v-if="append && column.isQuestion"
            small
            class="ml-1 mr-1 d-flex"
            color="primary"
          >
            mdi-arrow-right
          </v-icon>
          <div v-if="append && column.isQuestion" class="organize-columns__header--match d-flex mr-4">
            {{ getMatchingQuestionName(column) }}
          </div>
        </div>
      </span>

      <v-checkbox
        v-if="append"
        :input-value="column.isQuestion"
        :disabled="!column.isQuestion && questionsToAppend.length >= project.questions.length"
        @change="$emit('toggle-question-change', column, column.idx)"
        :label="$t('steps.append.is_question')"
        :ripple="false"
        class="mt-0 mb-0 font-weight-regular text-no-wrap"
        hide-details
        flat
      />
    </div>
    <div
      :class="{
        'organize-columns__column--bordered': (!append && isQuestionColumnType(column.semantic_type)) ||
          (append && column.isQuestion),
        'organize-columns__column--bordered-error': !append && !replace && columnHasNamingError(column) || columnHasWordingError(column),
        'organize-columns__column--disabled': append && !column.isQuestion,
        'organize-columns__column--append': append
      }"
      class="organize-columns__column w-100"
    >
      <div
        v-if="!append && (isPossibleQuestionColumnType(column) || isPossibleLanguageIdentifierColumn(column))"
        class="d-flex organize-columns__column__inputs align-center"
      >
        <div
          :class="(!isQuestionColumnType(column.semantic_type) || (replace && isQuestionColumnType(column.semantic_type))) && 'organize-columns__column__inputs__input-container--disabled'"
          class="organize-columns__column__inputs__input-container organize-columns__column__inputs__input-container--bordered mr-5"
        >
          <div v-if="isQuestionColumnType(column.semantic_type) && !replace && !append" class="w-100 h-100 d-flex align-center">
            <div class="d-flex align-center input-label">
              <label
                :for="`column-${column.idx}-name`"
                class="hover"
                :class="columnHasNamingError(column) && 'with-error'"
              >
                {{ $t('steps.organize.name') }}
              </label>
              <helptip
                position="bottom"
                color="primary"
                class="ml-1 mr-2"
              >
                <span v-html="$t('steps.organize.naming_helptip')" />
              </helptip>
            </div>
            <v-text-field
              :value="column.questionName.value"
              @input="v => $emit('name-change', v, column, column.idx)"
              :id="`column-${column.idx}-name`"
              type="text"
              :max="50"
              :class="columnHasNamingError(column) && 'with-error'"
              required
              outlined
              dense
              class="search-bar__search mb-0"
              :height="30"
            />
            <!-- :error-messages="$t(column.questionName.error)" -->
          </div>
        </div>
        <div
          :class="(!isQuestionColumnType(column.semantic_type) || (replace && isQuestionColumnType(column.semantic_type))) && 'organize-columns__column__inputs__input-container--disabled'"
          class="organize-columns__column__inputs__input-container mr-auto"
        >
          <div v-if="isQuestionColumnType(column.semantic_type) && !replace" class="w-100 h-100 d-flex align-center">
            <div class="d-flex align-center input-label">
              <label
                :for="`column-${column.idx}-wording`"
                class="hover"
                :class="columnHasWordingError(column) && 'with-error'"
              >
                {{ $t('steps.organize.wording') }}
              </label>
              <helptip
                position="bottom"
                color="primary"
                class="ml-1 mr-2"
                x-offset="-122px"
              >
                <!--offset it to make offset 10px-->
                <span v-html="$t('steps.organize.wording_helptip')" />
              </helptip>
            </div>
            <v-text-field
              :value="column.questionWording.value"
              @input="v => $emit('wording-change', v, column, column.idx)"
              :id="`column-${column.idx}-wording`"
              :max="255"
              type="text"
              :class="columnHasWordingError(column) && 'with-error'"
              required
              outlined
              dense
              class="search-bar__search mb-0"
              :height="30"
            />
            <!-- :error-messages="$t(column.questionWording.error)" -->
          </div>
        </div>
        <v-switch
          v-if="isPossibleLanguageIdentifierColumn(column)"
          inset
          color="green"
          flat
          dense
          :ripple="false"
          class="v-input--reverse v-input--description v-input--switch--small mt-0 pt-0"
          hide-details
          small
          :input-value="isLanguageColumnType(column.semantic_type)"
          @change="val => changeLanguageColumnColumnType(val, column)"
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <div class="text-sm font-weight-medium text-label pr-0">
                {{ $t('steps.organize.language_identifier') }}
              </div>
            </div>
          </template>
        </v-switch>

        <v-switch
          v-else
          inset
          color="green"
          flat
          dense
          :ripple="false"
          class="v-input--reverse v-input--description v-input--switch--small mt-0 pt-0"
          hide-details
          small
          :input-value="isQuestionColumnType(column.semantic_type)"
          @change="val => changeQuestionColumnColumnType(val, column)"
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <div class="text-sm font-weight-medium text-label pr-0">
                {{ $t('steps.organize.ai_analysis') }}
              </div>
            </div>
          </template>
        </v-switch>
      </div>
      <div
        :class="replace && isQuestionColumnType(column.semantic_type) && 'organize-columns__column__data--disabled'"
        class="d-flex organize-columns__column__data w-100 pt-1 pb-1 position-relative align-center"
      >
        <!-- <div class="mr-2 h-100 d-flex align-center">
          <label class="mr-2 flex-shrink-0">
            {{ $t('steps.organize.data') }}
          </label>
        </div> -->
        <div
          v-if="column.sample_data.length"
          class="d-flex flex-wrap w-100 h-100"
        >
          <div
            v-for="(item, sampleIndex) in column.sample_data"
            :key="sampleIndex"
            :style="`color: ${getColumnColors(getColumnType(column))}; background-color: ${getColumnColors(getColumnType(column), true)};`"
            class="organize-columns__column__data__item mr-2 mt-1 mb-1"
          >
            {{ item }}
          </div>
          <div
            v-if="column.n_non_empty > column.sample_data.length"
            :style="`color: ${getColumnColors(getColumnType(column))}; background-color: ${getColumnColors(getColumnType(column), true)};`"
            class="organize-columns__column__data__item mr-2 mt-1 mb-1"
          >
            +{{ column.n_non_empty - column.sample_data.length }} {{ $t('steps.organize.more') }}
          </div>
        </div>
        <div v-else>
          {{ $t('steps.organize.no_data') }}
        </div>
        <label class="text-sm">
          {{ getColumnTypeLabel(column.type) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import {
  COLUMN_TYPE_QUESTION,
  COLUMN_TYPE_AUXILIARY,
  COLUMN_TYPE_LANGUAGE,
  COLUMN_TYPE_NULL
} from '@/settings/constants'

export default {
  codit: true,
  name: 'Column',
  props: {
    column: { type: Object, default: () => {} },
    answersInput: { type: Object, default: () => {} },
    getTypeLabel: { type: Function, default: () => {} },
    getTypeLabelDescription: { type: Function, default: () => {} },
    getColumnLabel: { type: Function, default: () => {} },
    getColumnColors: { type: Function, default: () => {} },
    append: { type: Boolean, default: false },
    replace: { type: Boolean, default: false },
    questionsToAppend: { type: Array, default: () => [] },
    project: { type: Object, default: () => {} }
  },

  computed: {
    ...Vuex.mapGetters([
      'isQualtricsIntegration'
    ])
  },

  methods: {
    /**
     * Handler for ai analysis switcher
     */
    changeQuestionColumnColumnType (value, column) {
      this.$emit('type-change', value ? COLUMN_TYPE_QUESTION : COLUMN_TYPE_AUXILIARY, column, column.idx)
      this.$store.dispatch('updateUpload')
    },

    /**
     * Handler for language switcher
     */
    changeLanguageColumnColumnType (value, column) {
      this.$emit('type-change', value ? COLUMN_TYPE_LANGUAGE : COLUMN_TYPE_AUXILIARY, column, column.idx)
    },

    /**
     * returns the columns type
     */
    getColumnType (column) {
      return (column.semantic_type === COLUMN_TYPE_QUESTION || column.semantic_type === COLUMN_TYPE_LANGUAGE) ? column.semantic_type : column.type
    },

    /**
     * Returns the label of the column type
     * @return {String}
     */
    getColumnTypeLabel (type) {
      return this.$t(`steps.organize.types.${type.toLowerCase()}`)
    },

    /**
     * Determines if passed in column has a wording error
     * @return {Boolean}
     */
    columnHasWordingError (column) {
      if (!this.append && !this.replace) {
        return this.isQuestionColumnType(column.semantic_type) && column.questionWording.error
      }

      return false
    },

    /**
     * Determines if passed in column has a naming error
     * @return {Boolean}
     */
    columnHasNamingError (column) {
      if (!this.append && !this.replace) {
        return this.isQuestionColumnType(column.semantic_type) && column.questionName.error
      }

      return false
    },

    /**
     * Determines if column is of type question
     * @return {Boolean}
     */
    isQuestionColumnType (type) {
      return type === COLUMN_TYPE_QUESTION
    },

    isLanguageColumnType (type) {
      return type === COLUMN_TYPE_LANGUAGE
    },

    isPossibleQuestionColumnType (column) {
      return _.find([column.semantic_type, ...column.alternative_semantic_types], type => type === COLUMN_TYPE_QUESTION && (column.sample_data.length || this.isQualtricsIntegration))
    },

    /**
     * Determines if column is language identifier column
     */
    isPossibleLanguageIdentifierColumn (column) {
      return _.find([column.semantic_type, ...column.alternative_semantic_types], type => type === COLUMN_TYPE_LANGUAGE && column.sample_data.length)
    },

    /**
     * Determines if column is of type null
     * @retrun {Boolean}
     */
    isNullColumn (type) {
      return type === COLUMN_TYPE_NULL
    },

    /**
     * Returns the matching question (appending)
     * @return {String}
    */
    getMatchingQuestionName (column) {
      return this.getColumnLabel(this.project.questions[_.findIndex(this.questionsToAppend, column)].name)
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/pages/Upload.json' />
<i18n locale='de' src='@/i18n/de/pages/Upload.json' />