<template>
  <div class="no-op-wrapper">
    <v-select
      v-if="valueInternal.type === null"
      :items="filterOptions"
      :label="$t('ds.filters.type_label')"
      item-value="type"
      hide-details
      class="type-select mt-4"
      @change="setFilterType($event)"
      clearable
      outlined
      dense
    />

    <template v-else>
      <div v-if="['reviewed', 'changed', 'empty', 'highlighted'].indexOf(valueInternal.type) !== -1">
        <label class="font-weight-medium text-label text-sm ml-1">
          {{ $t(`ds.filters.labels.${valueInternal.type}`) }}
        </label>
        <div class="filters__list__check">
          <v-checkbox
            :input-value="statusIsSelected('True')"
            @change="handleStatusSelect('True')"
            primary
            hide-details
            :ripple="false"
            color="green"
            off-icon="mdi-checkbox-blank"
            flat
            :label="valueInternal.type === 'empty' ? $t('ds.filters.show_empty') : $t('yes')"
          />
        </div>
        <div class="filters__list__check">
          <v-checkbox
            :input-value="statusIsSelected('False')"
            @change="handleStatusSelect('False')"
            primary
            hide-details
            :ripple="false"
            color="green"
            off-icon="mdi-checkbox-blank"
            :label="valueInternal.type === 'empty' ? $t('ds.filters.show_non_empty') : $t('no')"
            flat
          />
        </div>
      </div>

      <v-text-field
        v-if="valueInternal.type === 'text'"
        v-model="valueInternal.value"
        @input="changeFilterItem(true)"
        @blur="changeFilterItem"
        :label="$t(`ds.filters.labels.${valueInternal.type}`)"
        hide-details
        outlined
        dense
      />

      <div v-if="valueInternal.type === 'sentiment'">
        <label class="font-weight-medium text-label text-sm ml-1">
          {{ $t(`ds.filters.labels.sentiment_v2`) }}
        </label>
        <div
          class="filters__list__check d-flex align-center"
          v-for="(sentiment, idx) in ['positive', 'neutral', 'negative']"
          :key="idx"
        >
          <v-checkbox
            primary
            hide-details
            :ripple="false"
            color="green"
            off-icon="mdi-checkbox-blank"
            @change="handleStatusSelect(sentiment)"
            :input-value="statusIsSelected(sentiment)"
            flat
          >
            <template v-slot:label>
              <sentiment :sentiment="sentiment" />
              <div class="ml-2">
                {{ capitalize(sentiment) }}
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>

      <template v-if="valueInternal.type === 'topics'">
        <topic-select
          v-model="valueInternal.value"
          @update="value => {
            valueInternal.value = value
            changeFilterItem(false)
          }"
          :placeholder="$t('ds.filters.labels.topics')"
          sync
          static
          topic-filter
          :disabled="valueInternal.showEmpty"
          :id="id"
        />
        <v-checkbox
          :input-value="valueInternal.showEmpty"
          @change="value => {
            valueInternal.showEmpty = !valueInternal.showEmpty
            changeFilterItem(false)
            $forceUpdate()
          }"
          primary
          hide-details
          :ripple="false"
          color="green"
          off-icon="mdi-checkbox-blank"
          :label="$t('ds.filters.labels.no_topics')"
          class="mt-4"
          :disabled="!!valueInternal.value.length"
          flat
        >
          <template v-slot:label>
            <div class="text-sm">
              {{ $t('ds.filters.labels.no_topics') }}
            </div>
          </template>
        </v-checkbox>

        <v-checkbox
          :input-value="valueInternal.dontShowEmpty"
          @change="value => {
            valueInternal.dontShowEmpty = !valueInternal.dontShowEmpty
            changeFilterItem(false)
            $forceUpdate()
          }"
          primary
          hide-details
          :ripple="false"
          color="green"
          off-icon="mdi-checkbox-blank"
          :label="$t('ds.filters.labels.not_no_topics')"
          class="mt-4"
          flat
        >
          <template v-slot:label>
            <div class="text-sm">
              {{ $t('ds.filters.labels.not_no_topics') }}
            </div>
          </template>
        </v-checkbox>
      </template>

      <topic-select
        v-if="valueInternal.type === 'categories'"
        v-model="valueInternal.value"
        @update="value => {
          valueInternal.value = value
          changeFilterItem(false)
        }"
        :placeholder="$t('ds.filters.labels.categories')"
        sync
        static
        category-select
        :id="id"
      />

      <template v-if="valueInternal.type === 'created' || valueInternal.type === 'modified'">
        <date-range-filter
          v-model="valueInternal.value"
          @input="changeFilterItem(false)"
          :show-picker="true"
        />
      </template>

      <template v-if="valueInternal.type === 'auxiliary'">
        <div class="search-bar flex-column align-start p-0" v-if="valueInternal.value.kind === 'text'">
          <label class="font-weight-medium text-label text-sm mb-1">
            {{ auxiliaryColumnNames[valueInternal.value.col] }}
          </label>
          <v-text-field
            v-model="valueInternal.value.value"
            @input="changeFilterItem(true)"
            class="search-bar__search search-bar__search--auxiliary"
            prepend-inner-icon="mdi-magnify"
            hide-details
            :placeholder="$t('ds.filters.labels.auxiliary_column_text_placeholder')"
            outlined
            dense
            single-line
            small
            :elevation="0"
          />
        </div>

        <template v-if="valueInternal.value.kind === 'range'">
          <label class="font-weight-medium text-label text-sm">
            {{ auxiliaryColumnNames[valueInternal.value.col] }}
          </label>

          <div class="d-flex align-center mt-2">
            <v-number-field
              v-model="valueInternal.value.value[0]"
              @input="changeFilterItem(true)"
              hide-details
              :label="$t('ds.filters.labels.auxiliary_column_range_min', { column: '' })"
              class="min-max mr-1"
              outlined
              dense
            />

            <v-number-field
              v-model="valueInternal.value.value[1]"
              @input="changeFilterItem(true)"
              hide-details
              :label="$t('ds.filters.labels.auxiliary_column_range_max', { column: '' })"
              class="min-max ml-1"
              outlined
              dense
            />
          </div>
        </template>

        <template v-if="valueInternal.value.kind.startsWith('date_range')">
          <label class="font-weight-medium text-label text-sm">
            {{ auxiliaryColumnNames[valueInternal.value.col] }}
          </label>
          <date-range-filter
            v-model="valueInternal.value.value"
            @input="changeFilterItem(false)"
            :show-picker="true"
          />
        </template>

        <template v-if="valueInternal.value.kind === 'select'">
          <label class="font-weight-medium text-label text-sm mb-1">
            {{ auxiliaryColumnNames[valueInternal.value.col] }}
          </label>
          <v-autocomplete
            v-model="valueInternal.value.value"
            @input="changeFilterItem(true)"
            dense
            class="search-bar__search search-bar__search--large w-100"
            :items="auxiliaryTypes[valueInternal.value.col] ? auxiliaryTypes[valueInternal.value.col].metadata.values : []"
            :placeholder="$t('ds.filters.labels.auxiliary_column_select', {
              column: auxiliaryColumnNames[valueInternal.value.col]
            })"
            hide-details
            multiple
            :height="40"
            :menu-props="{
              'offset-y': true,
              'ripple': false
            }"
          >
            <template v-slot:selection="selectItem">
              <div class="v-select__selection text-sm" v-if="selectItem.index === 0">
                {{ selectItem.item }}
              </div>
              <span v-if="selectItem.index === 1" class="text-xs ml-1 text-label">
                (+{{ valueInternal.value.value.length - 1 }} {{ $t('ds.filters.select_others') }})
              </span>
            </template>
            <template v-slot:item="{ item, index }">
              <div
                class="filters__list__check w-100 d-flex align-center"
                :key="index"
              >
                <v-checkbox
                  :input-value="valueInternal.value.value.includes(item)"
                  primary
                  hide-details
                  :ripple="false"
                  color="green"
                  off-icon="mdi-checkbox-blank"
                  flat
                  :label="item"
                />
              </div>
            </template>
          </v-autocomplete>
        </template>
      </template>

      <!--
      <v-checkbox
        v-model="valueInternal.invert"
        @change="changeFilterItem(false)"
        hide-details
        v-if="['text', 'codes'].indexOf(valueInternal.type) !== -1 || (valueInternal.type === 'auxiliary' && valueInternal.value.kind === 'text')"
        class="invert mt-1 mb-1"
        :label="$t('ds.filters.invert')"
      /> -->

      <slot name="options" />
    </template>
  </div>
</template>

<script>
import TopicSelect from '@/components/coding/TopicSelectv2'
import DateRangeField from '@/components/DateRangeField.vue'
import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import Sentiment from '@/components/coding/Sentiment'

export default {
  name: 'FilterEditor',
  codit: true,
  components: {
    'date-range-filter': DateRangeField,
    Sentiment,
    TopicSelect
  },

  mixins: [answerFilters, auxiliaryColumnsMixin],

  props: {
    /**
     * If are used in Dashboard page
     * Used in order not to debounce values as that causes issues, unneccessary because we have an apply button in Dashboard
     */
    isDashboardFilter: {
      type: Boolean,
      default: false
    },
    /**
     * Max code-chip width
     * @type {Number}
     */
    maxCodeWidth: {
      type: Number,
      default: 275
    },
    /**
     * The filter which is being modified by this component
     * @type {Object}
     */
    value: {
      type: Object,
      default: () => ({})
    },

    /**
     * The codebook of the question which is being filtered
     * @type {List}
     */
    topics: {
      type: Array,
      default: () => []
    },

    /**
     * Array of the auxiliary column names of the question (i.e. project)
     * @type {Array}
     */
    auxiliaryColumnNames: {
      type: Array,
      default: () => []
    },

    /**
     * The types of the auxiliary columns: These determine how the filtering looks like
     * All types need to be handled by the `answerFilters` mixin
     * @type {Object}
     */
    auxiliaryTypes: {
      type: Array,
      default: () => []
    },

    /**
     * A list of all other filters that are applied.
     * Will determine which options are available when creating new filter
     * E.g.: Filtering by empty rows is only allowed once
     * @type {List}
     */
    allFilters: {
      type: Array,
      default: () => []
    },

    /**
     * flag to load master filters only
     * @type {Boolean}
     */
    isMaster: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      // The internal copy of the filter
      valueInternal: {},
      debouncedEmit: _.debounce(() => {
        this.$emit('input', _.cloneDeep(this.valueInternal))
      }, 1000)
    }
  },

  computed: {
    /**
     * return valueLabel provided by answerFilters mixin
     * @return {Function}
     */
    formatValueLabel () {
      const { type } = this.valueInternal
      const filterOption = _.find(this.filterOptions, option => option.type === type)
      return filterOption ? filterOption.valueLabel : v => (v.value || v)
    }
  },

  watch: {
    /**
     * Watch the prop value and update internal value as soon as it changes
     */
    value: {
      immediate: true,
      deep: true,
      handler () {
        if (!_.isEqual(this.value, this.valueInternal)) this.$set(this, 'valueInternal', _.cloneDeep(this.value))
      }
    }

  },

  methods: {
    handleStatusSelect (v) {
      if (this.valueInternal.value.includes(v)) {
        this.valueInternal.value = this.valueInternal.value.filter(s => s !== v)
      } else {
        this.valueInternal.value = [...this.valueInternal.value, v]
      }

      this.changeFilterItem()
    },

    statusIsSelected (v) {
      return this.valueInternal.value.includes(v)
    },

    /**
     * Set the default value of a filter, after the user has chosen its type from the dropdown
     */
    setFilterType (type) {
      let initialFilter = this.initializeNewFilter(type, this.auxiliaryTypes)

      this.$set(this.valueInternal, 'type', initialFilter.type)
      this.$set(this.valueInternal, 'value', initialFilter.value)

      // Wait for the next tick (until the new value has been emitted)
      // before emitting to update the count as well
      this.$nextTick(this.updateFilterCounts)
    },

    /**
     * Emit message that the row count for this filter needs to be updated
     */
    updateFilterCounts () {
      this.$emit('update-count')
    },

    /**
     * Callback when any filter item is changed
     */
    changeFilterItem (shouldDebounce = false) {
      if (shouldDebounce && !this.isDashboardFilter) {
        this.debouncedEmit()
      } else {
        this.$emit('input', _.cloneDeep(this.valueInternal))
      }

      this.$emit('update-count-debounced')
    },

    capitalize (word) {
      return _.capitalize(word)
    }
  }
}

</script>

<style lang=scss>
  .no-op-wrapper {
    all: inherit;
  }

  .filter-separator {
    border-bottom: 1px solid #D8E2E8;
  }
</style>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
