<template>
  <div>
    <slot name="header-features" />
    <div class="feature"
         v-if="showPlanFeatures[planId].coding"
    >
      <span v-text="$t(`features.coding`)" />
      <helptip :width="300">
        <span v-html="$t('helptips.coding')" />
      </helptip>
    </div>
    <div class="feature"
         v-if="showPlanFeatures[planId].codebook_generator"
    >
      <span v-text="$t(`features.codebook_generator`)" />
      <helptip :width="300" x-offset="-75">
        <span v-html="$t('helptips.codebook_generator')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].codebook_templates"
    >
      <span v-text="$t(`features.codebook_templates`)" />
      <helptip :width="300" x-offset="-75">
        <span v-html="$t('helptips.codebook_templates')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].visualizations_access"
    >
      <span v-text="$t(`features.visualizations_access`)" />
      <helptip :width="300">
        <span v-html="$t('helptips.visualizations_access')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].api_access"
    >
      <span v-text="$t(`features.api_access`)" />
      <helptip :width="300" x-offset="50">
        <span v-html="$t('helptips.api_access')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].basic_filetypes"
    >
      <span v-text="$t(`features.basic_filetypes`)" />
    </div>
    <div class="feature"
         v-if="showPlanFeatures[planId].advanced_filetypes"
    >
      <span v-text="$t(`features.advanced_filetypes`)" />
    </div>
    <div class="feature"
         v-if="showPlanFeatures[planId].google_translate"
    >
      <span v-text="$t(`features.google_translate`)" />
    </div>
    <div class="feature"
         v-if="showPlanFeatures[planId].deepl"
    >
      <span v-text="$t(`features.deepl_translate`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].corporate_colors"
    >
      <span v-text="$t(`features.corporate_colors`)" />
      <helptip :width="300" x-offset="-30">
        <span v-html="$t('helptips.corporate_colors')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].visualizations_whitelabel"
    >
      <span v-text="$t(`features.visualizations_whitelabel`)" />
      <helptip :width="300" x-offset="-20">
        <span v-html="$t('helptips.visualizations_whitelabel')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].feature_prioritization"
    >
      <span v-text="$t(`features.feature_prioritization`)" />
      <helptip :width="300" x-offset="0">
        <span v-html="$t('helptips.feature_prioritization')" />
      </helptip>
    </div>

    <slot name="header-account" />

    <div class="feature"
         v-if="showPlanFeatures[planId].max_accounts"
    >
      <span>
        {{ $t(`features.max_accounts`) }}:
        {{
          showPlanFeatures[planId].max_accounts === -1 ?
            $t('features.unlimited_accounts') :
            showPlanFeatures[planId].max_accounts
        }}
      </span>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].allowed_roles"
    >
      <span>{{ $t(`features.user_roles_predefined`) }}: {{ showPlanFeatures[planId].allowed_roles.length }}</span>
      <helptip :width="500" x-offset="-60">
        <span v-html="$t(`helptips.user_roles_predefined_${showPlanFeatures[planId].allowed_roles.length === 1 ? '1' : 'many'}`)" />
        <ul v-if="showPlanFeatures[planId].allowed_roles.length > 1">
          <li v-for="role in showPlanFeatures[planId].allowed_roles" :key="role">
            <strong>{{ $t(`roles.${role}.name`) }}: </strong><span v-html="$t(`roles.${role}.description`)" />
          </li>
        </ul>
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].object_level_permissions"
    >
      <span>{{ $t(`features.object_level_permissions`) }}</span>
      <helptip :width="300" x-offset="-120">
        <span v-html="$t('helptips.object_level_permissions')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].custom_roles"
    >
      <span>{{ $t(`features.user_roles_custom`) }}</span>
      <helptip :width="300" x-offset="-25">
        <span v-html="$t('helptips.user_roles_custom')" />
      </helptip>
    </div>

    <slot name="header-support" />

    <div class="feature"
         v-if="showPlanFeatures[planId].standard_support"
    >
      <span v-text="$t(`features.standard_support`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].priority_support"
    >
      <span v-text="$t(`features.priority_support`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].premium_support"
    >
      <span v-text="$t(`features.premium_support`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].data_uploads_optional"
    >
      <span v-text="$t(`features.data_uploads_optional`)" />
      <helptip :width="300" x-offset="-120">
        <span v-html="$t('helptips.data_uploads')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].data_uploads"
    >
      <span v-text="$t(`features.data_uploads`, { n: showPlanFeatures[planId].data_uploads })" />
      <helptip :width="300" x-offset="-120">
        <span v-html="$t('helptips.data_uploads')" />
      </helptip>
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].trainings_optional"
    >
      <span v-text="$t(`features.trainings_optional`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].trainings_included"
    >
      <span v-text="$t(`features.trainings_included`, { n: showPlanFeatures[planId].trainings_included })" />
    </div>

    <slot name="header-admin" />

    <div class="feature"
         v-if="showPlanFeatures[planId].cc_payment"
    >
      <span v-text="$t(`features.cc_payment`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].invoice_payment"
    >
      <span v-text="$t(`features.invoice_payment`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].custom_agreements"
    >
      <span v-text="$t(`features.custom_agreements`)" />
    </div>

    <div class="feature"
         v-if="showPlanFeatures[planId].sla"
    >
      <span v-text="$t(`features.sla`)" />
      <helptip position="top" :width="300" x-offset="-40">
        <span v-html="$t('helptips.sla')" />
      </helptip>
    </div>
    <div class="feature"
         v-if="showPlanFeatures[planId].anonymization"
    >
      <span v-text="$t(`features.anonymization`)" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'PlanFeatures',
  codit: true,
  props: {
    planId: { type: Number, default: 0 },
    showPlanFeatures: { type: Object, default: () => ({}) }
  }
}

</script>
<i18n locale='en' src='@/i18n/en/Subscription.json' />
<i18n locale='de' src='@/i18n/de/Subscription.json' />
<i18n locale='en' src='@/i18n/en/Roles.json' />
<i18n locale='de' src='@/i18n/de/Roles.json' />