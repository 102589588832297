<template>
  <v-menu
    v-model="showModal"
    @change="showDateRangeModal = false"
    :close-on-content-click="false"
    :nudge-width="0"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="search-bar flex-column align-start p-0">
        <v-text-field
          :value="formatValueLabel(value)"
          hide-details
          class="mt-1 search-bar__search search-bar__search--auxiliary"
          readonly
          outlined
          dense
          v-bind="attrs"
          v-on="on"
        />
      </div>
    </template>
    <v-card class="filters__list__menu filters__list__menu--calendar" color="" :style="isDashboardFilter ? 'width: 468px;' : 'width: 294px'" light>
      <div class="h-100-after_footer" :class="showDateRangeModal && 'h-100-after_footer__padded'">
        <v-slide-x-transition hide-on-leave>
          <date-range-field v-if="showDateRangeModal" v-model="temporaryDateRange" show-picker />
          <v-list v-else dense class="flex-grow-1 h-100 pt-1 pb-1">
            <v-list-item-group
              color="primary"
            >
              <template v-for="(delta, i) in deltaOptions">
                <v-list-item
                  :key="i"
                  @click="() => {
                    value.kind = 'date_range_dynamic'
                    value.value = delta
                    $emit('change')
                  }"
                >
                  <v-list-item-title v-text="$t(`ds.filters.date.${delta}`)" />
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-slide-x-transition>
      </div>
      <v-card-actions
        class="filters__list__actions"
        :class="!showDateRangeModal && 'p-0'"
      >
        <v-scroll-x-transition hide-on-leave>
          <v-btn
            v-if="!showDateRangeModal"
            text
            @click="showDateRangeModal = true"
            class="w-100 filters__list__actions__custom-date"
          >
            {{ $t("ds.filters.date_range_selector.custom_period") }}
          </v-btn>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <div v-if="showDateRangeModal">
            <v-btn
              text
              @click="showDateRangeModal = false"
              outlined
              class="mr-1"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="applyTemporaryRange"
              :disabled="temporaryDateRange.length !== 2 || $_.isEqual(value.value, temporaryDateRange)"
            >
              {{ $t("ds.filters.date_range_selector.apply") }}
            </v-btn>
          </div>
        </v-scroll-x-transition>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import DateRangeField from './DateRangeField'

export default {
  name: 'DateRangeFilter',

  components: {
    'date-range-field': DateRangeField
  },

  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: Object, default: () => ({ kind: 'date_range', value: [] }), required: true },
    events: { type: Array, default: () => ([]) },
    formatValueLabel: { type: Function, default: () => {} },
    isDashboardFilter: { type: Boolean, default: false }
  },

  data () {
    let isoToday = this.$options.filters.shortiso(new Date())
    return {
      temporaryDateRange: [isoToday, isoToday], // to use with apply button
      showModal: false,
      showDateRangeModal: false,
      isoToday,
      deltaOptions: [
        'ALL_TIME',
        'THIS_MONTH',
        'LAST_30_DAYS',
        'LAST_MONTH',
        'THIS_QUARTER',
        'LAST_QUARTER',
        'LAST_3_MONTHS',
        'THIS_YEAR',
        'LAST_YEAR',
        'LAST_12_MONTHS'
      ]
    }
  },

  watch: {
    showDateRangeModal (open) {
      if (open) {
        // reset temporary date range
        this.temporaryDateRange = Array.isArray(this.value.value) ? [...this.value.value] : [this.isoToday, this.isoToday]
      }
    }
  },

  methods: {
    applyTemporaryRange () {
      this.showModal = false
      this.showDateRangeModal = false
      this.$emit('input', { ...this.value, kind: 'date_range', value: this.temporaryDateRange })
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss">
// TODO: REFACTOR TO USE FILTERS.SCSS
.filters__list {
  &__date-picker {
    display: block;
    max-width: 320px;
    margin: 0 auto;

    .v-picker__title {
      display: none;
    }
    .v-picker__body {
      /* width: 420px !important; */
      margin: 0;
    }
    .v-date-picker-table {
      .v-btn--rounded {
        border-radius: 5px;
      }
    }
  }
  &__actions {
    background: white;
    border-top: 1px solid #DAEAF2;
    justify-content: flex-end;
    padding: 12px !important;

    &__custom-date {
      height: 45px !important;
      color: $primary-color !important;
      letter-spacing: normal !important;
    }
  }
  &__menu {
    .h-100-after_footer {
      height: calc(100% - 57px);
      background: white;
      /* max-width: 300px; */
      /* background: 0 auto; */

      .v-list {
        background: white;
      }

      &__padded {
        padding: 8px;
      }
    }
  }
  &__date-range-input {
    width: 47%;

    fieldset {
      border: none !important;
    }

    .v-input__slot {
      border: 1px solid #D8E2E8;

      &:before, &:after {
        border-style: none !important;
        display: none !important;
      }
    }
  }
}
</style>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/AppPage.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/AppPage.json' />
