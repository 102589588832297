import {
  API_ENUM_TO_CHART_TYPES,
  CHART_TYPES_TO_API_ENUM
} from '@/settings/constants'
import CHART_LIST from '@/settings/chartList'
const DS_STATUS_TEMPLATE = { loading: false, failed: false }

export default {
  methods: {
    /**
    * Prepares chart data from frontend for sending it to backend
    * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
    * @param {Object} chart - Current chart reference
    * @return {Object} Combined data over rendering the chart
    */
    chartToAPIObject (chart) {
      let { name, type, config } = chart
      return {
        name,
        type: CHART_TYPES_TO_API_ENUM[type] || type,
        config,
        datasets: _.map(chart.datasets, ({ filters, settings, question }) => ({
          question: question.id ? question.id : question,
          filters: filters.filter(f => !!f.type), // don't save empty filters
          settings
        }))
      }
    },

    /**
    * Prepares loaded charts for work with frontend
    * resolves some mapping issues
    * @param {Object} chart - Incoming chart data from backend
    * @return {Object} Modified chart data
    */
    preprocessLoadedChart (chart) {
      let modifiedProps = {}

      if (
        ['P_VRB', 'P_TXT'].indexOf(chart.type) === -1
      ) {
        modifiedProps = {
          typeSelection: API_ENUM_TO_CHART_TYPES[chart.type],
          type: CHART_LIST[API_ENUM_TO_CHART_TYPES[chart.type]]['type']
        }
      }

      return {
        ..._.cloneDeep(chart),
        ...modifiedProps,
        datasets: chart.datasets.map(i => ({
          ...i,
          status: DS_STATUS_TEMPLATE
        }))
      }
    }
  }
}