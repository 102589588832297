<template>
  <div class="helptip" :class="disabled && 'helptip--disabled'">
    <span ref="anchor"
          class="anchor"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
          :class="{ [position]: true, active: active > 0 && !disabled || show }"
    >
      <span class="anchor-text">{{ anchorText }}</span>
      <slot name="anchor-icon">
        <i class="mdi" :class="{ 'superscript': superscript, [anchorIcon]: true }" :style="iconStyle" />
      </slot>
    </span>
    <div ref="content"
         @mouseover="onMouseOver"
         @mouseout="onMouseOut"
         class="helptip-content"
         :class="{ dark, [position]: true, 'hide-animation': hideAnimation }"
         :style="{ 'width': Number.isInteger(width) ? width + 'px' : width, 'max-height': height + 'px' }"
         v-show="active > 0 || hideAnimation || show"
    >
      <div class="content-wrapper">
        <slot />
      </div>
      <div class="arrow" ref="arrow" />
    </div>
  </div>
</template>

<script>

import Popper from 'popper.js'

export default {
  codit: true,
  name: 'Helptip',
  props: {
    anchorText: { type: String, default: '' },
    anchorIcon: { type: String, default: 'mdi-information-outline' },
    superscript: { type: Boolean, default: false },
    dark: { type: Boolean, default: true },
    width: { type: [Number, String], default: 300 },
    height: { type: Number, default: 600 },
    position: { type: String, default: 'bottom' },
    xOffset: { type: String, default: '0' },
    preventOverflow: { type: Boolean, default: true },
    iconStyle: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    show: { type: Boolean, default: false }
  },

  data () {
    return {
      active: 0,
      popper: null,
      hideAnimation: false,
      hideAnimationTimeout: null
    }
  },

  watch: {
    active (curr, prev) {
      curr = curr > 0
      prev = prev > 0
      if (!prev && curr) {
        if (!this.popper) this.initPopper()
        else this.popper.update()
      }
      if (curr && !prev) this.hideAnimation = false
      if (prev && !curr) {
        this.hideAnimation = true
        this.hideAnimationTimeout = setTimeout(() => { this.hideAnimation = false }, 250)
      }
    }
  },

  methods: {
    initPopper () {
      this.popper = new Popper(this.$refs.anchor, this.$refs.content, {
        placement: this.position,
        modifiers: {
          preventOverflow: { enabled: this.preventOverflow },
          flip: { enabled: false },
          offset: { enabled: true, offset: `${this.xOffset},10px` },
          arrow: { element: this.$refs.arrow },
          hide: { enabled: false }
        }
      })
    },

    onMouseOver () {
      if (this.disabled) return

      if (this.active > 0) this.active += 1
      else this.$_.delay(v => { this.active += 1 }, 200)
    },

    onMouseOut () {
      if (this.active > 0) this.$_.delay(v => { this.active -= 1 }, 250)
      else this.active -= 1
    }
  }
}

</script>

<style lang=scss>

$color-dark: #05202E;
$color-light: #FFF;

.helptip {
  display: inline-block;
  position: relative;

  &--disabled {
    pointer-events: none !important;
  }
}

.helptip .mdi {
  margin-left: 1px;
  font-size: larger !important;
  vertical-align: text-top;
  line-height: 1.1;
  letter-spacing: 0;
  color: var(--v-primary-base) !important;
}

.helptip .mdi.superscript {
   vertical-align: top;
   font-size:smaller;
}

.helptip .anchor {
  cursor: help;
  .anchor-text {
    border-bottom: 1px dashed;
  }

  &:before {
    content: '';
    cursor: help;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  &:not(.active):before { display: none }
}
.helptip .anchor.top::before {
  top:-15px; bottom:-2px;
}
.helptip .anchor.bottom::before { bottom:-16px; top:2px; }

.helptip-content {
  cursor: default;
  z-index: 100;
  animation: fadeIn 0.3s ease;

  .content-wrapper {
    white-space: normal;
    text-shadow: none;
    text-align: left;
    min-width: 200px;
    background-color: $color-light;
    animation: fadePlop 0.3s ease;
    filter: drop-shadow(0px 4px 8px rgba(14, 93, 136, 0.05));
    border-radius: 5px;
    line-height: 16px !important;
    padding: 8px !important;
    font-size: 13px !important;
    background: white;
    color: $text-color !important;
    border: 1px solid #D8E2E8 !important;
  }

  .arrow {
    position: absolute;
    content: '';
    width:0;
    height: 0;
    border:6px solid transparent;
  }

  &.top .arrow {
    bottom:-11px;
    border-top-color: #D8E2E8;
    animation: fadeInPopDown 0.3s ease;
  }
  &.bottom .arrow {
    top:-11px;
    border-bottom-color: #D8E2E8;
    animation: fadeInPopUp 0.3s ease;
  }

  &.top.dark .arrow { border-top-color: $color-dark; }
  &.bottom.dark .arrow { border-bottom-color: $color-dark; }

  &.hide-animation {
    pointer-events: none;
    animation: fadeOut 0.3s ease forwards;
    .content-wrapper { animation: scaleOut 0.3s ease forwards; }
    &.bottom .arrow { animation: fadeOutPopUp 0.3s ease forwards; }
    &.top .arrow { animation: fadeOutPopDown 0.3s ease forwards; }

  }

  &.dark {
    .content-wrapper {
      background-color: #05202E !important;
      border: none !important;
      color: white !important;

      a:not(.v-btn) {
        color: var(--v-accent-base);
        white-space: nowrap;
      }
    }
  }
}

.v-input .helptip .anchor {
  display: inline-block;
  color: rgba(0,0,0,.24)
}

</style>
