<template>
  <div class="pre markdown-container" v-html="parsedMarkdown" />
</template>

<script>
import { marked } from 'marked'
import sanitizeHtml from 'sanitize-html'

export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    parsedMarkdown () {
      // escape html tags before parsing
      const text = this.text.replaceAll('<', '\\<')

      const parsed = marked.parse(text, {
        // apply xhtml requirements
        xhtml: true
      })

      // sanitization options:
      // https://github.com/apostrophecms/sanitize-html#default-options
      return sanitizeHtml(parsed, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
        transformTags: {
          a: sanitizeHtml.simpleTransform('a', { target: '_blank' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.markdown-container {
  :deep(img) {
    max-width: 50%;
  }
}
</style>