import Vuex from 'vuex'

// Maximum number of ties we allow socket to fail before irreversibly closing session
const MAX_SOCKET_FAILED_CNT = 10
const MAX_INACTIVITY_S = 360

export default {
  data () {
    return {
      socket: {
        projectID: '',
        questionRef: '',
        conn: null,
        failedCnt: 0,
        created: null,
        lastOnline: null,
        waiting: false,
        shouldClose: false,
        closeConnectionTimeout: null,
        takeoverSessionTimeout: null,
        getCodingUpdateETAInterval: null
      }
    }
  },

  created () {
    this.socket.created = this.$moment()
    this.getCodingUpdateETAInterval = setInterval(this.getCodingUpdateETA, 30000)
  },

  computed: {
    /**
     * The last activity resembles the last moment, where there definitely was a
     * connetion to the server: Either when the page was loaded, or when the last
     * websocket message was sent. We want to prevent situations, where the computer
     * sleeps for hours / days, then the connection re-opens because there was no failed
     * connection
     * @return {Object} moment js object
     */
    lastActivity () {
      if (this.socket.lastOnline !== null) return this.socket.lastOnline
      else return this.socket.created
    },

    ...Vuex.mapState({
      sessionIssue: state => state.coding.sessionIssue
    })
  },

  watch: {
    // Make sure the takeoverSessionTimeout is cleared when closing the takeover dialog
    'sessionIssue.show' (val) { if (!val && this.takeoverSessionTimeout !== null) clearTimeout(this.takeoverSessionTimeout) }
  },

  beforeDestroy () {
    // Make sure the takeoverSessionTimeout is cleared when leaving the page
    if (this.takeoverSessionTimeout !== null) clearTimeout(this.takeoverSessionTimeout)
    clearInterval(this.getCodingUpdateETAInterval)
    this.closeWs()
  },

  methods: {
    /**
     * Close the websocket connection
     */
    closeWs () {
      this.socket.shouldClose = true
      if (this.socket.closeConnectionTimeout) clearTimeout(this.socket.closeConnectionTimeout)
      if (this.socket.conn) {
        console.log(`Closing proj=${this.socket.projectID}?${this.socket.conn.connectionID}`)
        this.socket.conn.close()
      } else console.log(`websocket worker proj=${this.socket.projectID} seems already closed...`)
    },

    /**
     * Check if the last active time has been too long ago
     * If yes, close the session and do not reopen it again
     * @return {Boolean} True if should be inactive, False if not
     */
    checkIfInactiveForTooLong () {
      if (moment().diff(this.lastActivity, 'seconds') > MAX_INACTIVITY_S) {
        console.log(`Closing session because of max inactivity time ${MAX_INACTIVITY_S}`)
        this.$store.commit('hasSessionIssue', 'connection_lost')
        this.closeWs()
        return true
      } else return false
    },

    /**
     * Open the websocket connection, keep it open and parse the messages
     * @param  {String} projectID The project ID to open the worker for
     * @param  {String} questionRef The question ref to open the worker for
     */
    openWs (projectID, questionRef) {
      if (this.checkIfInactiveForTooLong()) return
      if (this.socket.projectID && (this.socket.projectID !== projectID || this.socket.questionRef !== questionRef)) {
        throw Error(`Tried opening websocket connection, where another \
                    question has already open connection: \
                    ${projectID}/${questionRef} vs ${this.socket.projectID}/${this.socket.questionRef}`)
      }

      this.socket.projectID = projectID
      this.socket.questionRef = questionRef

      if (this.socket.shouldClose || this.destroyed || this.storeDestroyed) {
        console.log(`Socket proj=${projectID} is dead / coding destroyed / wrong question. Not opening worker.`)
        this.closeWs()
        return
      }
      console.log(`Opening worker for proj=${projectID}/${questionRef}`)
      ws.open(`ws/project-worker/${projectID}/${questionRef}`).then(socket => {
        console.log(`Project worker proj=${projectID}/${questionRef}?${socket.connectionID} connection established`)
        if (this.socket.shouldClose) {
          socket.close()
          this.socket.conn = null
          return
        }
        if (this.socket.closeConnectionTimeout) clearTimeout(this.socket.closeConnectionTimeout)
        // Close the socket every 20 hours, to prevent error from being closed by server after 24h
        // and to check that authentication session is still valid
        this.socket.closeConnectionTimeout = setTimeout(() => {
          console.log(`Closing & reopening websocket worker \
                       proj=${projectID}/${questionRef}?${socket.connectionID} \
                       connection (long-lived connection)`)
          // Close the connection
          // But as the shouldClose flag is not set, the socket connection will automatically
          // tried to be re-established
          if (this.socket.conn) this.socket.conn.close()
          this.socket.closeConnectionTimeout = null
        }, 1000 * 3600 * 20)

        this.socket.conn = socket
        this.socket.failedCnt = 0
        socket.onappheartbeat = () => {
          if (!this.checkIfInactiveForTooLong()) this.socket.lastOnline = this.$moment()
        }

        socket.onappmessage = (data) => {
          this.socket.waiting = false

          if ('error' in data) throw new Error(`Socket returned error: ${data.error}`)
          else if ('users_online' in data) this.$store.commit('setUsersOnline', data.users_online[projectID])
          else if ('inference_completed' in data) {
            this.onInferenceCompleted()
            this.getCodingUpdateETA()
          } else if (data.user_err) {
            switch (data.user_err) {
              case 'multiple_tabs':
                this.$store.commit('hasSessionIssue', 'multiple_tabs')
                this.closeWs()
                break
              case 'multiple_users':
                this.$store.commit('isNotEditable')
                this.$store.commit('hasSessionIssue', 'multiple_users')

                // Make sure the user cannot keep the takeover dialog open for ages, and then take over control
                // This might lead to inconsistencies in the data, as another user might have worked on the question
                // in the mean time. Therefore, close this takeover dialog after 60s when no action has been taken
                this.takeoverSessionTimeout = setTimeout(() => {
                  if (this.sessionIssue && this.sessionIssue.type === 'multiple_users') {
                    this.$store.commit('closeSessionIssue')
                  }
                }, 60000)
                break
              case 'takeover_by':
                this.$store.commit('isNotEditable')
                this.$store.commit('hasSessionIssue', 'takeover_by')
                this.closeWs()
                break
              case 'rows_uploaded':
                this.$store.commit('hasSessionIssue', 'rows_uploaded')
                this.closeWs()
                break
              case 'takeover_confirm':
                this.$store.commit('isEditable')
                this.$store.commit('hasSessionIssue', 'takeover_confirm')
                break
              default:
                throw Error(`Unknown user error ${data.user_err}`)
            }
          } else if ('training_countdown_update' in data) {
            this.$store.commit('trainingCountdownUpdate', data.training_countdown_update.value)
          } else if ('coding_update_eta' in data) {
            let eta = data.coding_update_eta.value
            if (eta) {
              const now = this.$moment()
              eta = this.$moment(eta)
              if (eta < now || eta.diff(now, 'seconds') < 40) eta = 'Any moment'
              else eta = eta.fromNow()
            }
            this.$store.commit('setCodingUpdateETA', eta)
          } else if ('training_reqested' in data) {
            this.getCodingUpdateETA()
          } else {
            throw Error(`Unknown project worker message ${JSON.stringify(data)}`)
          }
        }

        socket.onclose = (event) => {
          this.socket.conn = null
          console.log(`Project worker connection proj=${projectID}/${questionRef}?${socket.connectionID} closed.`)
          if (event.wasClean && this.socket.shouldClose) return
          // Try reconnecting in 5 seconds
          console.error(`Project worker proj=${projectID}/${questionRef}?${socket.connectionID} closed unexpectedly. \
                         Code=${event.code} reason=${event.reason}. Reestablishing connection in 5s`)
          setTimeout(() => this.openWs(projectID, questionRef), 5000)
        }
      }).catch(({ err, event }) => {
        if (event.status === 403 || event.status === 401) {
          console.error('Websockets got permission denied.')
          this.$root.on403()
        }
        this.socket.failedCnt += 1
        if (this.socket.failedCnt >= MAX_SOCKET_FAILED_CNT) {
          this.$store.commit('hasSessionIssue', 'connection_lost')
          this.$onError(new Error(`Failed to connect to socket proj=${projectID}/${questionRef} \
                                  ${MAX_SOCKET_FAILED_CNT} times, stopping session`))
          // Make sure no attempt is made to re-open session
          this.closeWs()
        } else setTimeout(() => this.openWs(projectID, questionRef), 5000)
        console.error(err)
        console.error(`Project worker ${projectID} failed to connect.`)
      })
    },

    /**
     * Take back control of the session if another user opened it in the meantime
     */
    retakeSessionControl () {
      this.socket.waiting = true
      this.socket.conn.send(JSON.stringify({ 'takeover_control': true }))
    },

    getCodingUpdateETA () {
      if (this.socket.conn) this.socket.conn.send(JSON.stringify({ 'get_coding_update_eta': true }))
    }
  }
}