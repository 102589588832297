import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/component/title'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/dataset'
import 'echarts/lib/component/graphic'
import 'zrender/lib/svg/svg'

import EChart from '@/components/EChart'

import answerFilters from '@/mixins/answerFilters'
import colorPalettes from '@/mixins/colorPalettes'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import { checkScrollbar, omitDeep } from '@/utils/funcs'
import { PNG, SVG, CHART_TYPE_SCORE, SCORING_TYPE_AVERAGE, SENTIMENT_RANGE } from '@/settings/constants'

import { mapGettersWithKey } from '@/utils/vuex.js'
import { modifyChartPayloadConfig } from '@/utils/chartConfigModificator.js'

import Vuex from 'vuex'
import axios from 'axios'

const N_ROWS_KEY = '____N_ROWS____'

const chartMixin = {
  mixins: [answerFilters, colorPalettes, auxiliaryColumnsMixin],

  components: {
    'v-chart': EChart
  },

  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    value: { type: Object, default: () => ({}) },
    type: { type: String, default: () => '' },
    datasets: { type: Array, default: () => [] },
    hideMasterFilters: { type: Boolean, default: false },
    hideControls: { type: Boolean, default: false },
    isDashboard: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    isDirty: { type: Boolean, default: false },
    externalResults: { type: [Object, undefined], default: undefined },
    hideVerbatimBrowser: { type: Boolean, default: false },
    noWatermark: { type: Boolean, default: false },
    selectedPaneOption: { type: String, default: '' },
    showPaneDrawer: { type: Function, default: () => ({}) },
    currentConfig: { type: Object, default: () => ({}) },
    prevChartConfigs: { type: Object, default: () => ({}) },
    allDatasetsReady: { type: Boolean, default: true },
    invalidSettingsForChartType: { type: Boolean, default: false },
    chartUniqueName: { type: String, default: null },
    fieldsToDeleteBeforeRequest: { type: Array, default: () => ([]) },
    meta: { type: Object, default: () => ({}) },
    auxiliaryColumnNames: { type: Array, default: () => [] },
    showTopNTopics: { type: Number, required: false, default: null },
    sentimentShown: { type: String, required: false, default: 'any' },
    groupByColumnValue: { type: Number, required: false, default: 0 },
    isVerbatimEnabled: { type: Boolean, required: false, default: true }
  },

  data () {
    return {
      hasScrollBar: false,
      defaultOptionsValues: {
        tooltip: {},
        animationDuration: 500
      },

      initialReady: false,
      timeCreated: null,
      chartContainerWidth: 0,

      globalResults: {},
      resultsPerRange: [],
      globalTicks: [],
      resultsComputed: {},

      remoteResults: {
        loading: false,
        failed: false,
        activeRequest: null,
        errorMessages: []
      },

      defaultConfigValues: () => ({
        // General
        title: this.$t('chart_title'),
        aggregate: this.forceAggregate,

        dimensions: {
          width: null,
          height: null,
          format: PNG,
          predefinedSize: null
        },

        // Legend
        showLegend: true,

        // Color
        colorPalette: '__cb_colors__',
        colorPaletteValue: false,

        // Labels
        dataLabelSize: 15,
        maxDataLabelLength: 25,

        // Axis
        axisLabelSize: 15,
        maxAxisLabelLength: 25,

        // Ordinal Axis
        reverseOrdinal: false,

        // Value axis
        valueAxisName: this.$t('controls.value_axis_name'),

        // Outputs
        plotTopicsFilter: { mode: 'all', select: [] },
        plotCategoriesFilter: { mode: 'all', select: [] },

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255, 1)',

        // percentage config
        percentages: false,
        decimalPlaces: 1,
        // Master Filters
        filters: []
      }),

      downloadTypes: [PNG, SVG],

      availableSettingTiles: {
        general: ['title'],
        colors: ['colorPalette'],
        labels: ['maxDataLabelLength', 'dataLabelSize', 'labelsEnabled', 'showLegend'],
        ordinalAxis: ['ordinalAxisName', 'reverseOrdinal', 'axisLabelSize', 'maxAxisLabelLength'],
        valueAxis: ['valueAxisName'],
        axis: ['xAxisName', 'yAxisName'],
        outputs: ['plotTopicsFilter', 'plotCategories']
      },

      dashboardWarning: null
    }
  },

  computed: {
    ...Vuex.mapState(['user']),

    ...mapGettersWithKey({
      isReady: 'registerManager/isReady',
      topicsByCat: 'metaManager/topicsByCat',
      topicCats: 'metaManager/topicCats',
      topicID2Topic: 'metaManager/topicID2Topic',

      valueManagerFetchState: 'valueManager/queryState'
    })(function () { return this.id }),

    datasets_str () {
      return JSON.stringify(this.datasets)
    },

    /**
     * The standard chart options that apply to all echart objects
     * @return {Object}
     */
    defaultOptions () {
      return {
        ...this.defaultOptionsValues
      }
    },

    /**
     * Minimum dimensions of container, can be overwritten by specific chart
     */
    minContainerWidth: () => 300,
    minContainerHeight: () => 400,

    /**
     * The minimum container dimensions and background, according to the chart config
     * @return {Object}
     */
    chartContainerStyle () {
      return {
        'min-width': `${this.minContainerWidth + 100}px`,
        'min-height': `${this.minContainerHeight + 100}px`
      }
    },

    chartColStyle () {
      return {
        right: this.hasScrollBar ? '-15px' : '0px'
      }
    },

    /**
     * The datasets which have been loaded
     * @return {Array} Array of datasets
     */
    datasetsLoaded () {
      return _.filter(this.datasets, ds => !ds.status.loading)
    },

    /**
     * Computes the union of all topics available in the given datasets
     * @return {Array} List of topics
     */
    topicsUnion () {
      return this.addColorToCodes(this.meta.topics_intersection)
    },

    /**
     * Mappping of codeUnion ids to their relevant categories. Required by the compute methods.
     * @return {Object}
     */
    topicsUnionID2Cat () {
      let mapping = {}
      this.topicsUnion.forEach(c => { mapping[c.id] = c.category })
      return mapping
    },

    /**
     * Computes the union of all topics available in the given datasets
     * @return {Array} List of topics
     */
    catsUnion () {
      return _.keys(_.groupBy(this.topicsUnion, 'category'))
    },

    /**
     * Computes the intersection of all topics available in the given datasets
     * @return {array} List of topics
     */
    topicsAvailable () {
      return this.addColorToCodes(this.meta.topics_intersection)
    },

    /**
     * The available topics, grouped by category
     * @return {Object} Mapping of category -> code
     */
    topicsAvailableByCat () {
      return _.groupBy(this.topicsAvailable, 'category')
    },

    /**
     * The available categories
     * @return {Array} List of categories
     */
    catsAvailable () {
      return _.keys(this.topicsAvailableByCat)
    },

    categories () {
      let filter
      let cats = this.catsAvailable
      if (
        !this.config.plotCategoriesFilter &&
        !this.config.plotTopicsFilter
      ) return cats

      if (
        !this.config.plotCategoriesFilter.select.length
      ) return cats

      if (
        this.config.plotCategoriesFilter.mode === 'all'
      ) return cats

      let catsSet = new Set(this.config.plotCategoriesFilter.select)
      if (
        this.config.plotCategoriesFilter.mode === 'exclude'
      ) filter = c => !catsSet.has(c)
      if (
        this.config.plotCategoriesFilter.mode === 'include'
      ) filter = c => catsSet.has(c)

      return cats.filter(filter)
    },

    /**
     * The topics to evaluate & plot
     * @return {Array} List of topics
     */
    topics () {
      let filter
      let topics = this.topicsAvailable
      if (
        !this.config.plotCategoriesFilter &&
        !this.config.plotTopicsFilter
      ) return topics

      // remove topics disabled manually
      let topicsSet = new Set(_.map(this.config.plotTopicsFilter.select, ({ id }) => id))

      if (
        this.config.plotCategoriesFilter.mode === 'all' &&
        this.config.plotTopicsFilter.mode === 'all'
      ) {
        filter = c => true
      } else if (
        !this.config.plotCategoriesFilter.select.length &&
        !this.config.plotTopicsFilter.select.length
      ) {
        filter = c => true
      } else if (
        this.config.plotCategoriesFilter.mode === 'exclude' &&
        this.config.plotTopicsFilter.mode === 'exclude' &&
        this.config.plotCategoriesFilter.select.length &&
        this.config.plotTopicsFilter.select.length
      ) {
        filter = c => !topicsSet.has(c.id) && this.categories.indexOf(c.category) > -1
      } else if (
        this.config.plotCategoriesFilter.mode === 'include' &&
        this.config.plotTopicsFilter.mode === 'include' &&
        this.config.plotCategoriesFilter.select.length &&
        this.config.plotTopicsFilter.select.length
      ) {
        filter = c => topicsSet.has(c.id) || this.categories.indexOf(c.category) > -1
      } else if (
        this.config.plotCategoriesFilter.mode === 'exclude' &&
        this.config.plotCategoriesFilter.select.length
      ) {
        filter = c => this.categories.indexOf(c.category) > -1
      } else if (
        this.config.plotCategoriesFilter.mode === 'include' &&
        this.config.plotCategoriesFilter.select.length
      ) {
        filter = c => this.categories.indexOf(c.category) > -1
      } else if (
        this.config.plotTopicsFilter.mode === 'exclude' &&
        this.config.plotTopicsFilter.select.length
      ) {
        filter = c => !topicsSet.has(c.id)
      } else if (
        this.config.plotTopicsFilter.mode === 'include' &&
        this.config.plotTopicsFilter.select.length
      ) {
        filter = c => topicsSet.has(c.id)
      }
      topics = topics.filter(filter)

      // remove topics without sentiment
      if (
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
      ) {
        topics = topics.filter(({ sentiment_enabled }) => !!sentiment_enabled) //eslint-disable-line
      }

      // remove topics with small number of rows
      // the number set manually
      if (
        this.showTopNTopics
      ) {
        const topicIdsFiltered = topics.map(({ id }) => id)
        const topTopicsIds = this.topicsSortedByFrequency
          .filter(({ id }) => topicIdsFiltered.indexOf(id) > -1)
          .map(({ id }) => id).splice(0, this.showTopNTopics)
        topics = topics.filter(({ id }) => topTopicsIds.indexOf(id) > -1)
      }

      return topics
    },

    topicsSortedByFrequency () {
      let topics = this.topicsAvailable
      // remove topics without sentiment
      if (
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
      ) {
        topics = topics.filter(({ sentiment_enabled }) => !!sentiment_enabled) //eslint-disable-line
      }

      if (
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 &&
        this.config.aggregate
      ) {
        return _.orderBy(topics, topic => {
          return this.results.counts.topics[0]?.[topic.id]?.sentiments[this.sentimentShown]?.counts?.overall
        }, 'desc')
      } else if (
        SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
      ) {
        return _.orderBy(topics, topic => this.results.counts.topics.reduce(
          (sum, dataset, datasetIndex) => sum + dataset[topic.id]?.sentiments[this.sentimentShown].counts.per_dataset?.[datasetIndex], 0),
        'desc')
      } else if (
        this.config.aggregate
      ) {
        return _.orderBy(topics, topic => {
          return this.results.counts.topics[0]?.[topic.id]?.results.counts?.overall
        }, 'desc')
      } else {
        return _.orderBy(topics, topic => this.results.counts.topics.reduce(
          (sum, dataset, datasetIndex) => sum + dataset[topic.id]?.results.counts.per_dataset?.[datasetIndex], 0),
        'desc')
      }
    },
    /**
     * Return the results for the topics, categories and groups for each dataset for each resultKey
     * Make sure an entry is returned for every dataset, even if that dataset is not really computed yet.
     * @return {Object}
     * Structure:
     * {
     *   topics: [{ codeID1: codeCount1, codeID2: codeCount2}, { ...dataset2 }, ...]
     *   categories: [{ category1: categoryCount1, category2: categoryCount2}, { ...dataset2 }, ...]
     *   groups: [{ groupName1: groupCount1, groupName2: groupCount2 }, { ...dataset2 }, ...],
     *   topics: [{code, sentiments}]
     *   categoriesSentiment: {code: {sentiments}}
     * }
     */
    results () {
      let res = {}
      _.each(this.resultKeys, key => {
        let catResultsAll = []
        let topicResultsAll = []
        let catResultsSentimentAll = []
        let catResultsRaw = []

        if (this.resultsComputed[key]) {
          const dsIdxes = this.getDsIdxes()
          dsIdxes.forEach(dsIdx => {
            catResultsAll.push(this.resultsComputed[key].categories[dsIdx] || {})
            topicResultsAll.push(this.resultsComputed[key].topic[dsIdx] || {})
            catResultsSentimentAll.push(this.resultsComputed[key].categoriesWithSentiment[dsIdx] || {})
            catResultsRaw.push(this.resultsComputed[key].categoriesRaw[dsIdx] || {})
          })
        }

        res[key] = {
          categories: catResultsAll,
          topics: topicResultsAll,
          categoriesSentiment: catResultsSentimentAll,
          categoriesRaw: catResultsRaw
        }
      })
      return res
    },

    colors () {
      let cp = this.colorPalettes[this.config.colorPalette]
      if (this.colorClasses) {
        let colors = []
        for (let k = 0; k < Math.ceil(this.colorClasses.length / cp.length); k++) colors = colors.concat(cp)
        return colors
      } else return cp
    },

    /**
     * Mapping of categories to their colors. Each color objects consists of three keys (soft, medium, strong)
     * For the categories where there is no color information available, generate a new one
     * @return {Object}  Object with all categories as keys and colors as values
     */
    catColors () {
      let catColors = {}
      this.meta?.topics_intersection?.forEach(topic => {
        catColors[topic.category] = this.get3ShadesForColor(topic.color)
      })

      let cnt = 0
      this.catsAvailable.forEach(cat => {
        if (!(cat in catColors)) catColors[cat] = this.get3ShadesForColor(this.$color.getMedium(cnt++))
      })

      return catColors
    },

    configColorPalettes () {
      if (this.config.colorPalette && this.config.colorPalette !== '__cb_colors__' && this.config.colorPaletteValue) {
        return { [this.config.colorPalette]: this.config.colorPaletteValue }
      } else return {}
    },

    /**
     * The default (empty) getter for the count groups.
     * Should be overwritten by specific chart if it makes use of count groups.
     * @return {Object} Mapping of IDS -> group
     */
    countGroupMapping () {
      return {}
    },

    /**
     * If all datasets are loaded and their result computed
     * @return {Boolean}
     */
    ready () {
      return _.every(this.datasets, ds => !ds.status.loading && !ds.status.dirty && ds.question) && !!this.datasets.length
    },

    /**
     * If any dataset is still loading
     * @return {Boolean}
     */
    loading () {
      return _.some(this.datasets, ds => ds.status.loading)
    },

    /**
     * If any dataset has failed to load
     * @return {Boolean}
     */
    failed () {
      return _.some(this.datasets, ds => ds.status.failed)
    },

    /**
     * If the user has access to the visualizations feature (from his plan)
     * @return {Boolean}
     */
    hasVisualizationsAccess () {
      return this.user?.subscription?.features?.visualizations_access
    },

    /**
     * The sum of the currently selected weighting column for each dataset
     * @return {Array[Number]}
     */
    sumsOfWeightingColumn () {
      return this.datasets.map(ds => {
        if (ds.settings.weighting_column === null) return 0
        else return _.sumBy(ds.answers, a => a.auxiliary_columns[ds.settings.weighting_column])
      })
    },

    /**
     * Factor to normalize the weighting: n_rows / sum(weighting_column)
     * Used to make sure the total still matches the number of rows
     * @return {Number} Weighting factor
     */
    weightingNormalizationFactor () {
      return this.datasets.map((ds, dsIdx) => {
        if (ds.settings.weighting_column === null || !ds.settings.weighting_normalize) return 1
        else return ds.answers.length / this.sumsOfWeightingColumn[dsIdx]
      })
    },

    /**
     * Determines if global chart aggregate option is disabled
     * @return {Boolean}
     */
    aggregateDisabled () {
      return this.forceAggregate
    },

    availableTilesFeatures () {
      let features = {
        colorPalette: {
          codebookColors: true
        }
      }

      return features
    },

    /**
     * The properties that will be passed to the control-pane component
     * @return {Object} The passed props
     */
    controlPaneProps () {
      return {
        'invalid-settings-for-chart-type': this.invalidSettingsForChartType,
        'force-aggregate': this.forceAggregate,
        'aggregate-disabled': this.aggregateDisabled,
        'n-datasets': this.datasets.length,
        loading: !this.initialReady,
        initialLoadingFailed: this.failed,
        'color-palettes': this.colorPalettes,
        'available-tiles-by-drawer': this.availableSettingTiles,
        'selected-pane-option': this.selectedPaneOption,
        readOnly: this.readOnly,
        datasets: this.datasets,
        ready: this.ready,
        hideControls: this.hideControls,
        invalidWeightingColumnDatasets: this.invalidWeightingColumnDatasets,
        invalidDriverColumnDatasets: this.invalidDriverColumnDatasets,
        resultsErrorMessages: this.remoteResults.errorMessages,
        id: this.id || 'ch__new',
        availableTilesFeatures: this.availableTilesFeatures
      }
    },

    /**
     * The properties that will be passed to the chart-scaffold component through v-bind
     * @return {Object} chart-scaffold properties
     */
    scaffoldProps () {
      return {
        config: this.config,
        datasets: this.datasets,
        'codes-available': this.codesAvailable,
        'topics-union': this.topicsUnion,
        'container-style': this.chartContainerStyle,
        resultKeys: this.resultKeys,
        resultsLoading: this.remoteResults.loading || !this.allDatasetsReady || !!this.valueManagerFetchState?.isLoading,
        resultsLoadingFailed: this.remoteResults.failed,
        helptip: this.$t('helptip'),
        hideControls: this.hideControls,
        availableDownloadFormats: this.downloadTypes,
        'chart-col-style': this.chartColStyle,
        aggregate: this.aggregate,
        type: this.chartUniqueName,
        name: this.name,
        id: this.id || `ch__new`,
        'is-dirty': this.isDirty,
        hideMasterFilters: this.hideMasterFilters,
        fieldsToDeleteBeforeRequest: this.fieldsToDeleteBeforeRequest,
        meta: this.meta,
        auxiliaryColumnNames: this.auxiliaryColumnNames
      }
    },

    /**
     * If aggregation is on
     * @return {Boolean}
     */
    aggregate () {
      return this.config.aggregate || this.forceAggregate
    },

    /**
     * The chart-scaffold events object that will be passed to the v-chart component through v-on
     * @return {Object} chart-scaffold events object
     */
    scaffoldEvents () {
      return {
        download: this.download,
        downloadExcelReport: this.downloadExcelReport,
        'container-width': ($event) => { this.chartContainerWidth = $event },
        'reload-results': this.computeAllResults,
        'set-download-options-val': (val, key) => this.setDownloadOptionsVal(val, key)
      }
    },

    controlPaneEvents () {
      return {
        'select-pane-option': optionToShow => {
          this.showPaneDrawer(optionToShow)
        }
      }
    },

    /**
     * The default properties that will be passed to the v-chart component through v-bind
     * Same for all chart types, is only used through chartProps property
     * @return {Object} Chart properties object
     */
    chartPropsDefault () {
      return {
        options: this.chartOptions,
        autoresize: true
      }
    },

    /**
     * The chart properties that will be passed to the v-chart component through v-bind
     * Can be customized by each chart, this is just the default value
     * @return {Object} Chart properties object
     */
    chartProps () { return this.chartPropsDefault },

    /**
     * The chart events object that will be passed to the v-chart component through v-on
     * @return {Object} Chart events object
     */
    chartEvents () {
      return {
        click: this.chartClick,
        dblclick: this.chartDblclick || function () {},
        legendselectchanged: this.legendSelectedChanged || function () {},
        mouseover: this.mouseover || function () {},
        mouseout: this.mouseout || function () {}
      }
    },

    /**
     * Mapping of question ids to array indexes with potential driver columns
     * Potential driver columns include NPS and CSAT columns
     * @return {Object} Object of arrays
     */
    potentialDriverColumnsByQuestion () {
      let mapping = {}

      this.datasets.forEach(ds => {
        if (!(ds.question.id in mapping)) {
          let potentialNPS = this.getPotentialNPSColumns(this.meta.auxiliary_column_metas, this.auxiliaryColumnNames)
          let potentialCSAT = this.getPotentialCSATColumns(this.meta.auxiliary_column_metas, this.auxiliaryColumnNames)
          mapping[ds.question.id] = _.uniq(potentialNPS.concat(potentialCSAT))
        }
      })

      return mapping
    },

    /**
     * Return the names of the datasets that have an invalid driver column
     * (because it contains non-numerical values)
     * If there are no such datasets, return an empty array
     * @return {Array} Names of datasets with invalid driver target columns
     */
    invalidDriverColumnDatasets () {
      if (!this.ready) return []
      if (!this.resultKeys.includes('relative_impact')) return []
      // Filter for datasets which contain invalid driver column
      return _(this.datasets).filter(ds => {
        const col = ds.settings.driver_target_column

        if (this.potentialDriverColumnsByQuestion[ds.question.id].indexOf(col) === -1) return true
        // else if (this.potentialDriverColumnsByQuestion[ds.question.id].indexOf(npsCol) === -1) return true
        else return false
      })
        .map(ds => ds.settings.name)
        .map(name => this.$escapeHtml(name))
        .value()
    },

    /**
     * Return the names of the datasets that have an invalid weighting column
     * (e.g. because it contains non-numerical values)
     * If there are no such datasets, return an empty array
     * @return {Array}
     */
    invalidWeightingColumnDatasets () {
      return _(this.sumsOfWeightingColumn).map((s, dsIdx) => ({ s, dsName: this.datasets[dsIdx].settings.name }))
        .filter(({ s }) => !_.isNumber(s) || _.isNaN(s))
        .map('dsName')
        .map(name => this.$escapeHtml(name))
        .value()
    },

    /**
     * If there are any invalid settings that prevent the results from being computed
     * As long as this is true, no request to chart-values will be sent
     * @return {Boolean}
     */
    invalidSettingsPreventingResults () {
      return this.invalidDriverColumnDatasets.length > 0 || this.invalidWeightingColumnDatasets.length > 0
    }
  },

  watch: {
    externalResults: {
      deep: true,
      handler () {
        if (!this.isReady) {
          return
        }
        this.computeAllResults()
      }
    },
    isReady: {
      immediate: true,
      handler (val, oldVal) {
        if (!this.initialReady && val && !this.failed && this.datasets.length) {
          this.initialReady = true
          this.computeAllResults()
        } else if (
          this.initialReady && !val && !this.datasets.length
        ) {
          this.initialReady = false
        }
      }
    },

    datasets_str: {
      immediate: false,
      deep: true,
      handler (newVal, oldVal) {
        const newDatasets = JSON.parse(newVal)
        const oldDatasets = JSON.parse(oldVal)
        const newDatasetSettings = _.map(newDatasets, ({ settings }) => settings)
        const oldDatasetSettings = _.map(oldDatasets, ({ settings }) => settings)

        // No need to recompute when some things change, seperated into settings because datsets could contain name
        // in a different sub-property than settings
        const newDatasetsNormalized = omitDeep(newDatasets, ['result', 'settings'])
        const oldDatasetsNormalized = omitDeep(oldDatasets, ['result', 'settings'])
        const newDatasetSettingsNormalized = omitDeep(newDatasetSettings, ['name', 'color'])
        const oldDatasetSettingsNormalized = omitDeep(oldDatasetSettings, ['name', 'color'])

        if (this.initialReady && (!_.isEqual(newDatasetsNormalized, oldDatasetsNormalized) || !_.isEqual(newDatasetSettingsNormalized, oldDatasetSettingsNormalized))) {
          this.$nextTick(() => {
            this.computeAllResults()
          })
        }
      }
    },

    /**
     * Emit warning messages for dashboard filters
     */
    dashboardWarning: {
      immediate: false,
      deep: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('warning', newVal)
        }
      }
    },

    /**
     * When the topics selection has changed, we need to update the counts as well,
     * as the category counts would otherwise be skewed
     */
    topics (newVal, oldVal) {
      // Only update the counts when the ids of the code selection have really changed
      if (
        !_.isEqual(
          new Set(
            _.map(newVal, 'id')
          ),
          new Set(
            _.map(oldVal, 'id')
          )
        )
      ) {
        this.computeAllResults()
      }
    },

    categories (newVal, oldVal) {
      if (!_.isEqual(new Set(newVal), new Set(oldVal))) {
        this.computeAllResults()
      }
    },

    /**
     * When the group mapping has changed, we need to update the counts
     */
    countGroupMapping (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.computeAllResults()
      }
    },

    'config.aggregate' (newVal, oldVal) {
      if (this.initialReady && newVal !== oldVal) {
        this.computeAllResults()
      }
    },

    'config.filters' (newVal, oldVal) {
      if (this.initialReady && !_.isEqual(newVal, oldVal)) {
        this.computeAllResults()
      }
    },

    // When the internal config value changes, notify parent of change
    config: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    },

    /**
     * Whenever a new color palette is chosen, set this to the config
     */
    'config.colorPalette' () {
      this.$set(this.config, 'colorPaletteValue', this.colorPalettes[this.config.colorPalette])
    },

    /**
     * When the user changes the color by field, update the palette as well
     * But only if it's still on the default value
     */
    'config.colorBy.field': {
      immediate: true,
      handler (f) {
        if (
          !this.initialReady ||
          this.hideControls
        ) {
          return
        }

        if (f !== 'category' && this.config.colorPalette === '__cb_colors__') this.config.colorPalette = 'default'
        if (f === 'category' && this.config.colorPalette === 'default') this.config.colorPalette = '__cb_colors__'
      }
    },

    // When the external config value changes, update the internal state
    value: 'updateInternalConfig'
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.checkIfWindowHasScrollbar)
    const iframe = document.getElementById('scrollbar-resize-listener')

    if (iframe) iframe.remove()
  },

  mounted () {
    this.$nextTick(() => this.checkIfWindowHasScrollbar())
  },

  updated () {
    this.checkIfWindowHasScrollbar()
  },

  created () {
    window.addEventListener('resize', this.checkIfWindowHasScrollbar)

    this.createScrollBarListener()

    this.updateInternalConfig()

    this.timeCreated = new Date().getTime()

    // Add watermark if
    //        * visualizations are not included in users subscription
    // (and)  * the user is not anonymous (meaning he is accessing a public dashboard)
    // (and)  * the noWatermark prop is not set to true
    if (!this.hasVisualizationsAccess && !this.user.isAnonymous && !this.noWatermark) {
      let dpr = window.devicePixelRatio || 1
      let canvasText = document.createElement('canvas')
      canvasText.width = canvasText.height = 300
      let ctxText = canvasText.getContext('2d')
      ctxText.textAlign = 'center'
      ctxText.textBaseline = 'middle'
      ctxText.globalAlpha = 0.4
      ctxText.fillStyle = '#FF0000'
      ctxText.font = '48px Roboto'
      ctxText.translate(140, 100)
      ctxText.rotate(-Math.PI / 6)
      ctxText.fillText('caplena.com', 0, 0)

      let canvasPattern = document.createElement('canvas')
      canvasPattern.width = canvasPattern.height = 4000 * dpr
      let ctxPattern = canvasPattern.getContext('2d')
      ctxPattern.scale(1 / dpr, 1 / dpr)
      ctxPattern.beginPath()
      ctxPattern.fillStyle = ctxPattern.createPattern(canvasText, 'repeat')
      ctxPattern.fillRect(0, 0, canvasPattern.width, canvasPattern.height)

      this.defaultOptionsValues.graphic = [{
        type: 'image',
        left: 0,
        top: 0,
        z: 100,
        silent: true,
        style: {
          image: canvasPattern,
          width: canvasPattern.width / dpr,
          height: canvasPattern.height / dpr
        }
      }]
    }
  },

  methods: {
    /**
     * custom toFixed method that discard extra fractional digits
     * @return {String}
     */
    toFixed (number = 0, maxAllowableFractionDigits = this.config.decimalPlaces) {
      // no fractional digits for bigger numbers
      return String(+(number.toFixed(maxAllowableFractionDigits)))
    },

    /* A sham that will throw a window resize event even when scrollbars are added/removed (this is not something the standard window resize event does). Tested in IE9+, Chrome & Firefox latest. */
    createScrollBarListener () {
      // Create an invisible iframe
      var iframe = document.createElement('iframe')
      iframe.id = 'scrollbar-resize-listener'
      iframe.style.cssText = 'height: 0; background-color: transparent; margin: 0; padding: 0; overflow: hidden; border-width: 0; position: absolute; width: 100%;'

      // Register our event when the iframe loads
      iframe.onload = function () {
      // The trick here is that because this iframe has 100% width
      // it should fire a window resize event when anything causes it to
      // resize (even scrollbars on the outer document)
        iframe.contentWindow.addEventListener('resize', function () {
          var evt = new UIEvent('resize')
          window.dispatchEvent(evt)
        })
      }

      // Stick the iframe somewhere out of the way
      document.body.appendChild(iframe)
    },
    /* function that actually checks if window has a scrollbar */
    checkIfWindowHasScrollbar () {
      if (checkScrollbar()) this.hasScrollBar = true
      else this.hasScrollBar = false
    },
    /**
     * Create entries in results object for each of the defint resultKeys (e.g. counts, relative_impact etc.)
     */
    initResultsContainer () {
      this.resultKeys.forEach(key => {
        this.$set(this.resultsComputed, key, _.cloneDeep({
          categories: [],
          topic: [],
          categoriesWithSentiment: [],
          topicsWithSentiment: [],
          categoriesRaw: []
        }))
      })
    },

    async computeAllResults () {
      if (!this.initialReady || this.invalidSettingsForChartType || !this.datasets.length) return

      // Only do the heavy lifting if things are really ready
      if (!('counts' in this.resultsComputed)) this.initResultsContainer()

      // Set loading to true already here, as some results will be conditionally computed
      // based on this flag, and might fail if they're not in the expected form
      if (this.invalidSettingsPreventingResults) {
        this.remoteResults.loading = false
        return
      }
      if (!this.ready) return

      if (
        typeof this.externalResults !== 'undefined'
      ) {
        this.parseRawResultsData(this.externalResults)
      } else {
        this.remoteResults.loading = true

        const results = await this.getRemoteResults()
        this.parseRawResultsData(results?.data)
      }
    },

    getDsIdxes () {
      return _.range(this.datasets.length)
    },

    /**
     * Gets the chart config for api request, basically a normalizer for the API with differences for FE
     */
    getChartConfig () {
      if (!this.config) return

      let config = {
        ...modifyChartPayloadConfig(this.config),
        aggregate: this.forceAggregate ? true : this.config.aggregate
      }

      if (
        this.type === CHART_TYPE_SCORE
      ) {
        config.scoringMethodLabel = this.config.scoringMethodLabel === SCORING_TYPE_AVERAGE ? 'None' : this.config.scoringMethodLabel
      }

      // hack to avoid showing an expty chart
      // if plotCategories mode = include
      // and no value chosen
      if (
        ['include', 'exclude'].indexOf(this.config.plotCategoriesFilter?.mode) > -1 &&
        ['include', 'exclude'].indexOf(this.config.plotTopicsFilter?.mode) > -1 &&
        !this.config.plotCategoriesFilter?.select?.length &&
        !this.config.plotTopicsFilter?.select?.length
      ) {
        config.plotCategoriesFilter.mode = 'all'
        config.plotTopicsFilter.mode = 'all'
      }

      return config
    },

    /**
     * Normalizer for API
     */
    normalizeDatasetSettings (settings) {
      if (settings.scoring_type === SCORING_TYPE_AVERAGE) {
        return _.omit(settings, 'scoring_type')
      }

      return settings
    },

    chartAPIObject () {
      return {
        type: this.APItype,
        config: this.getChartConfig(),
        datasets: _.map(this.datasets, ({ filters, settings, question }) => ({
          question,
          filters: filters.filter(f => !!f.type), // don't save empty filters,
          settings: this.normalizeDatasetSettings(settings)
        }))
      }
    },

    getRemoteResults () {
      if (!this.APItype) throw Error('APItype needs to be defined on chart when using remote results.')
      if (!this.ready) return

      if (this.remoteResults.activeRequest !== null) {
        this.remoteResults.activeRequest.cancel('New request coming in')
        this.remoteResults.activeRequest = null
      }

      this.remoteResults.loading = true

      const id = this.id && this.id !== 'ch__new' ? this.id : 'ch__'
      const source = axios.CancelToken.source()
      this.remoteResults.activeRequest = source
      this.remoteResults.errorMessages = []
      const requestData = this.chartAPIObject()

      return api.request(
        `/api/charts/${id}/values`,
        {
          cancelToken: source.token,
          method: 'post',
          data: requestData
        }
      ).catch(err => {
        // If the request was cancelled, don't stop loading and don't display failed
        // The only reason something might be cancelled, is that another request was
        // issued in the mean time
        if (!axios.isCancel(err)) {
          this.remoteResults.loading = false
          this.remoteResults.failed = true
          this.remoteResults.activeRequest = null
          if (_.hasIn(err, 'response.data.non_field_errors')) {
            this.remoteResults.errorMessages.push(err.response.data.non_field_errors)
          // Only report error if it's not something that is shown to the user
          } else this.$maybeRaiseAPIPromiseErr(err)
        }
      }).finally(() => {
        // @sasha check it
        this.remoteResults.loading = false
        this.remoteResults.failed = false
        this.remoteResults.activeRequest = null
      })
    },

    parseRawResultsData (data) {
      this.globalResults = data?.results_global || {}
      this.resultsPerRange = data?.results_per_range || {}
      this.globalTicks = data?.ticks || []
      this.dashboardWarning = data?.warnings?.length ? data.warnings : null

      if (
        typeof data === 'object' &&
        Object.keys(data).length
      ) {
        this.prepareRemoteResults(this.chartAPIObject(), data)
      }
    },

    /**
    * prepares data after loading values from API (getRemoteResults)
    * @params {Object} req        request body from chartAPIObject
    * @params {Object} res        API response.data from /api/charts/:id/values
    */
    prepareRemoteResults (req, res) {
      const dsIdxes = this.getDsIdxes()
      _.each(this.resultKeys, key => {
        if (this.resultsComputed[key]) {
          // Reset the datasets array (when switching aggregate or changing number of datasets)
          this.resultsComputed[key].categories.splice(0)
          this.resultsComputed[key].topic.splice(0)
          this.resultsComputed[key].categoriesWithSentiment.splice(0)
          this.resultsComputed[key].categoriesRaw.splice(0)

          dsIdxes.forEach(dsIdx => {
            this.emitDatasetResult({ req, res, dsIdx, key })
            this.computeResults({ req, res, dsIdx, key })
          })
        }
      })
    },

    /**
    * emits chart result per  dataset
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     dsIdx {Number}:    dataset index
    *     key   {String}:    result key from resultKeys
    */
    emitDatasetResult ({ res, dsIdx }) {
      this.$emit('result', dsIdx, _.get(res.results_global.counts_raw.per_dataset, dsIdx, 0))
    },

    /**
    * prepares the resultsComputed
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     dsIdx {Number}:    dataset index
    *     key   {String}:    result key from resultKeys
    */
    computeResults ({ res, dsIdx, key }) {
      let { catResult, topicResult, catResultSentiment, catResultRaw } = this._getEmptyResultsContainer()
      // Set the code results

      // Set the category results
      res?.results_per_category?.forEach(cat => {
        catResult[cat.category] = this.aggregate ? cat?.results?.[key]?.overall : cat.results?.[key]?.per_dataset?.[dsIdx]
      })

      // Set the topic results
      res?.results_per_category?.forEach(cat => {
        catResultRaw[cat.category] = cat
      })

      // Set the topic results
      res?.results_per_topic?.forEach(topic => {
        topicResult[topic.id] = topic
      })

      /*
      * sentiment data for categories
      * use it for sentiment bar chart
      */
      res?.results_per_category?.forEach(cat => {
        catResultSentiment[cat.category] = cat?.sentiments
      })

      this.$set(this.resultsComputed[key].categories, dsIdx, catResult)
      this.$set(this.resultsComputed[key].topic, dsIdx, topicResult)
      this.$set(this.resultsComputed[key].categoriesWithSentiment, dsIdx, catResultSentiment)
      this.$set(this.resultsComputed[key].categoriesRaw, dsIdx, catResultRaw)
    },

    /*
    * Define initial values for data in use for chart building
    * @return {Object}
    * Structure:
    * {
    *   codeResult,
    *   catResult,
    *   topicResult,
    *   catResultSentiment
    * } Basic values
    */
    _getEmptyResultsContainer () {
      let codeResult = Object.assign({}, ...this.topicsUnion.map(code => ({ [code.id]: 0 })))
      let catResult = Object.assign({}, ...this.catsUnion.map(cat => ({ [cat]: 0 })))
      let topicResult = Object.assign({})
      let catResultRaw = Object.assign({})

      let catResultSentiment = Object.assign({})

      return { codeResult, catResult, topicResult, catResultSentiment, catResultRaw }
    },

    /**
     * Updates the internal data "config" with values given by external "value"
     */
    updateInternalConfig () {
      let _defaultConfigValues = this.defaultConfigValues()

      // If the given external and internal configs are the same, do nothing
      if (_.isEqual(this.config, this.value)) return

      const previouslySelectedChartTypeConfig = this.prevChartConfigs[`${this.type}-config`]

      // Go through all config keys defined on *this* chart as well as previously selected config
      // All other elements of config will be ignored
      _.each(this.config, (val, key) => {
        // Undefined value is treated in special way: It is assigned the global default, defined in this mixin
        if (val === undefined) {
          // if (!(key in this.defaultConfigValues) && process.env.NODE_ENV === 'development') console.warn(`No default value for config '${key}' available!`)
          // Don't assign default if value is given by external config
          if (!(key in this.value)) this.$set(this.config, key, _.cloneDeep(_defaultConfigValues[key]))
        }
        if (previouslySelectedChartTypeConfig && key in previouslySelectedChartTypeConfig && previouslySelectedChartTypeConfig[key] !== undefined) this.$set(this.config, key, previouslySelectedChartTypeConfig[key])
        if (key in this.value && this.value[key] !== undefined) this.$set(this.config, key, this.value[key])
      })
    },

    maybeTruncateLabel (text, { maxLength = undefined, ellipsis = true } = {}) {
      if (text === undefined) return ''
      if (maxLength === undefined) maxLength = this.config.maxDataLabelLength
      return text.length > maxLength ? text.slice(0, maxLength) + (ellipsis ? '...' : '') + (text.endsWith('}') ? '}' : '') : text
    },

    /**
     * Get the weighting settings for a specific dataset, to be passed to getAnsWeight later on
     * @param  {Number} dsIdx The dataset index for which to return the settings
     * @return {Object}       The resulting weighting settings
     */
    getAnsWeightSettings (dsIdx) {
      return Object.freeze({
        weightingCol: this.datasets[dsIdx].settings.weighting_column,
        normalizationFactor: this.weightingNormalizationFactor[dsIdx]
      })
    },

    /**
     * Get the weight of an answer
     * @param  {Object} ans               The answer
     * @param  {Object} weightingSettings The weighting settings, as generated by getAnsWeightSettings
     * @return {Number}       The weight (1 if no weighting column defined)
     */
    getAnsWeight (ans, { weightingCol, normalizationFactor }) {
      let factor = 1

      let hasIdenticalIds = 'identical_ids' in ans
      let hasWeightingCol = weightingCol !== null

      if (hasIdenticalIds && hasWeightingCol) throw Error('Weighting column as well as grouping activated. This is not supported.')
      if (hasIdenticalIds) factor = 1 + ans.identical_ids.length
      if (hasWeightingCol) factor = normalizationFactor * ans.auxiliary_columns[weightingCol]

      return factor
    },

    /*
      Sets the config download options by passed in key to value
      * @param  {string} key key to set
      * @param  {string} val value of key
    */
    setDownloadOptionsVal (key, val) {
      this.config.dimensions = ({
        ...this.config.dimensions,
        [key]: val
      })
    },

    /**
     * Download chart excel report
     */
    downloadExcelReport () {
      api.request(
        this.$route.params.id === 'new' ? `/api/charts/ch__/values?fmt=xlsx` : `/api/charts/${this.$route.params.id}/values?fmt=xlsx`,
        {
          method: this.$route.params.id === 'new' || this.isDirty ? 'post' : 'get',
          data: this.chartAPIObject(),
          responseType: 'blob'
        }
      ).then(res => {
        const blob = new Blob([res.data], { type: '' })
        const link = document.createElement('a')
        link.setAttribute('target', '_blank')
        link.setAttribute('type', 'hidden')
        link.href = URL.createObjectURL(blob)
        link.download = `${this.name}.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(link.href)
      })
    },

    forceRefresh () {
      this.$refs.chart.refresh()
    },

    /**
     * Download function: Converts canvas to image, sets the image as data url and clicks it
     * @param  {String} format The format in which to download the chart: {png,svg}
     */
    download () {
      const { format, height, width } = this.config.dimensions

      let link = document.createElement('a')
      link.download = `${this.name || 'preview'}.${format}`

      // Define a getDataURL function, which returns the link which will be set on the a element
      let getDataURL

      // Recreate the echarts object as svg or canvas in order to set proper height,
      // then an object url
      const chart = this.$refs.chart

      // The container, which will be the temporary home of the new chart
      let container = document.createElement('div')
      // Set the container to the same dimensions as the container of the rendered echarts element
      // https://github.com/caplena/surveyfrontend/issues/388,
      // height / width dimensions are not defined in cockpit view so default value of chart container is given
      container.style.width = `${width || this.chartContainerWidth}px`
      container.style.height = `${height || this.chartContainerWidth}px`
      // Create new echarts element, rendered as svg
      const resizedChart = echarts.init(container, chart.theme, { renderer: format === 'svg' ? 'svg' : 'canvas' })
      // Set the options for that echarts element
      const chartOptions = _.cloneDeep(chart.options)

      // Render title on canvas
      chartOptions.title = { text: this.config.title }

      // Disable animations on all series, such that we can download the resulting svg almost instantly
      chartOptions.series.length ? chartOptions.series.forEach(s => { s.animation = false }) : chartOptions.series.animation = false
      resizedChart.setOption(chartOptions)

      switch (format) {
        case 'png':
          // PNG is very simple: Get the dataurl directly from the canvas element
          getDataURL = () => ({ href: resizedChart.getDataURL({ type: 'png', pixelRatio: window.devicePixelRatio || 1 }) })
          break
        case 'svg': {
          getDataURL = () => {
            // Get the actual SVG node
            let serializedSvg = new XMLSerializer().serializeToString(container.getElementsByTagName('svg')[0])
            // Hack to replace rich text, which is not supported by SVG on echarts < 5
            serializedSvg = serializedSvg.replace(/{(pls|min)\|}/gm, '')
            if (!this.config.enableBackground) {
              serializedSvg = serializedSvg.replace('style="fill: transparent;"', 'style="fill: rgb(255, 255, 255);" fill-opacity="0"')
            } else {
              const colors = this.config.background.substring(this.config.background.indexOf('(') + 1, this.config.background.lastIndexOf(')')).split(/,\s*/)
              const r = colors[0]
              const g = colors[1]
              const b = colors[2]
              const a = colors[3]

              const bgUnrounded = `rgba(${r}, ${g}, ${b}, ${a})`
              const bgRounded = `rgba(${r}, ${g}, ${b}, ${_.round(a, 2)})`

              let replaceableBg = bgUnrounded
              if (serializedSvg.search(bgRounded) > -1) replaceableBg = bgRounded

              serializedSvg = serializedSvg.replace(`style="fill: ${replaceableBg};"`, `style="fill: rgb(${r}, ${g}, ${b});" fill-opacity="${a}"`)
            }
            serializedSvg = serializedSvg.replaceAll(/rgba?\((\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*),((?:\s*[0-9.]*\s*)?)\)" fill-opacity="*[0-9.]"/g, 'rgb($1)" fill-opacity="0$2"')

            const svgBlob = new Blob([serializedSvg], { type: 'image/svg+xml;charset=utf-8' })
            const url = URL.createObjectURL(svgBlob)

            // Return both the href, as well as a destroyer, which will free the objectUrl
            return { href: url, destroyer: () => URL.revokeObjectURL(url) }
          }
          break
        }
        default:
          throw Error(`Unsupported chart download format ${format}`)
      }

      setTimeout(() => {
        const dataUrl = getDataURL()
        link.href = dataUrl.href
        link.setAttribute('type', 'hidden')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        if (dataUrl.destroyer) dataUrl.destroyer()
      }, 50)
    }
  }
}

export { N_ROWS_KEY, chartMixin }
