const modifyFilters = (initialConfig) => {
  let config = _.cloneDeep(initialConfig)
  const filterTopicsIndex = config?.filters
    ? _.findIndex(config.filters, ({ type }) => type === 'topics')
    : null

  if (
    !config.filters?.[filterTopicsIndex]?.value
  ) {
    return config
  }

  config.filters[filterTopicsIndex].value = config.filters[filterTopicsIndex].value.map(({ id, sentiment }) => [id, sentiment || 'any'])

  return config
}

const modifyPlotTopicsFilters = (initialConfig) => {
  let config = _.cloneDeep(initialConfig)

  if (
    !config.plotTopicsFilter?.select ||
    !Array.isArray(config.plotTopicsFilter.select)
  ) {
    return config
  }

  config.plotTopicsFilter.select = config.plotTopicsFilter.select.map(({ id, sentiment }) => id)

  return config
}

const modifyRangeBoundaries = (initialConfig) => {
  let config = _.cloneDeep(initialConfig)
  let rangeBoundries = config.rangeBoundries

  if (!config?.rangeBoundries?.length) {
    return config
  }

  config.rangeBoundries = rangeBoundries.map(boundry => Number(boundry))

  return config
}

const clearChartPayloadConfig = (initialConfig, fieldsToDeleteBeforeRequest) => {
  let config = _.cloneDeep(initialConfig)

  if (
    !fieldsToDeleteBeforeRequest ||
    !Array.isArray(fieldsToDeleteBeforeRequest)
  ) {
    return config
  }

  fieldsToDeleteBeforeRequest.forEach((item) => {
    delete config[item]
  })

  return config
}

const modifyChartPayloadConfig = (config, fieldsToDeleteBeforeRequest) => {
  if (!config) {
    return {}
  }
  let result = config
  result = modifyFilters(result)
  result = modifyPlotTopicsFilters(result)
  result = modifyRangeBoundaries(result)
  result = clearChartPayloadConfig(result, fieldsToDeleteBeforeRequest)
  return result
}

export {
  modifyFilters,
  modifyPlotTopicsFilters,
  clearChartPayloadConfig,
  modifyChartPayloadConfig
}