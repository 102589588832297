<template>
  <div
    class="control-drawer d-flex align-center justify-space-between pt-2 pb-2 pl-2 pr-2"
  >
    <div class="d-flex">
      <div :class="`mr-3 default-border rounded icon-container ${highlighted && 'highlighted'}`">
        <v-icon v-if="icon.length">
          {{ icon }}
        </v-icon>
        <slot v-else name="icon" />
      </div>
      <div class="d-flex align-center">
        {{ title }}
      </div>
    </div>
    <v-icon>mdi-chevron-right</v-icon>
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'SettingsTile',
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    highlighted: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
  .control-drawer {
    .icon-container {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F3F7F9;
      border-color: #F3F7F9 !important;
      i {
        transition: all 0.3s ease;
        color: #708189 !important;
      }
    }
    &:hover {
      cursor: pointer;
      i {
        color: var(--v-primary-base) !important;
      }
    }
  }
</style>
