<template>
  <div class="main-content">
    <page-header>
      <template slot="headline">
        {{ $t('request_trial.title') }}
      </template>

      <template slot="navigation-buttons" v-if="!loading">
        <v-btn outlined
               color="green"
               :to="{ name: 'upload' }"
               v-if="success"
        >
          <v-icon left>
            mdi-cloud-upload
          </v-icon>
          {{ $t('menu.upload') }}
        </v-btn>

        <v-btn outlined
               color="green"
               exact
               :to="{ name: 'projects-manage' }"
        >
          <v-icon left>
            mdi-file-multiple
          </v-icon>
          {{ $t('menu.projects_group') }}
        </v-btn>
      </template>
    </page-header>

    <loading :is-loading="loading" :title="$t('request_trial.loading')" tagline="">
      <v-alert v-if="success"
               type="success"
               outlined
               icon="mdi-test-tube"
               text
               prominent
      >
        <span v-html="$t('request_trial.success', { credits })" />
      </v-alert>

      <v-alert v-else-if="failed"
               type="error"
               outlined
               text
               prominent
      >
        <div v-html="$t('request_trial.failure')" />
        <div v-text="error" />
      </v-alert>
    </loading>
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'RequestTrial',
  data () {
    return {
      loading: true,
      failed: false,
      success: false,
      error: '',
      credits: 0,
      routeReplaced: false
    }
  },

  created () {
    this.requestTrial()
  },

  /**
   * We don't want this page to be in the history, because people will often land here again
   * and thus trigger api errors.
   */
  beforeRouteLeave (to, from, next) {
    if (!this.routeReplaced) {
      this.routeReplaced = true
      this.$router.replace(to)
    } else next()
  },

  methods: {
    /**
     * Request activation of free trial. On success, reload user.
     */
    requestTrial () {
      this.loading = true
      this.failed = false
      api.post('/api/subscription/activate-trial').then(res => {
        this.loading = false
        this.success = true
        this.credits = res.data.credits
        this.$root.getUser()
        this.$root.intercomCloseWidget()
      }).catch(err => {
        this.loading = false
        this.failed = true
        if (err.response && err.response.data) this.error = String(err.response.data.error)
        if (!(err.response && err.response.status === 400)) this.$maybeRaiseAPIPromiseErr(err)
      })
    }
  }
}

</script>

<i18n locale='de' src='@/i18n/de/pages/RequestTrial.json' />
<i18n locale='en' src='@/i18n/en/pages/RequestTrial.json' />