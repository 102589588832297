<template>
  <transition name="drawer-transition">
    <v-card
      class="w-100 default-bs settings-drawer mb-3"
      v-if="selectedPaneOptionLabel && selectedPaneOptionLabel.length && render"
    >
      <div class="d-flex align-items-center pt-4 pb-4 pl-3 pr-3 position-relative justify-space-between">
        <div class="default-border rounded">
          <v-icon @click="onClick" :disabled="backDisabled">
            mdi-chevron-left
          </v-icon>
        </div>
        <div class="title absolute-center capitalize">
          {{ selectedPaneOptionLabel }}
        </div>
        <div>
          <slot name="matches" />
        </div>
      </div>
      <div class="pl-3 pr-3 pt-0 pb-0">
        <slot name="settings" class="bordered-bottom" />
      </div>
    </v-card>
  </transition>
</template>

<script>
export default {
  codit: true,
  name: 'SettingsDrawer',
  props: {
    selectedPaneOptionLabel: { type: String, default: '' },
    onClick: { type: Function, default: function () {} },
    backDisabled: { type: Boolean, default: false },
    render: { type: Boolean, default: true }
  }
}
</script>

<style lang="scss">
.controls-container .settings-drawer:last-child {
  margin-bottom: 50px !important;
}

.settings-drawer {
  .sub-text {
    color: $grey-color;
  }

  .bordered-bottom {
    border-bottom: 1px solid $blue-200;
  }

  .bordered-top {
    border-top: 1px solid $blue-200;
  }

  .gray-bg {
    background: $light-blue;
  }
}

.drawer-transition-enter-active, .drawer-transition-leave-active {
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(.64,0,.35,1);
  }

.drawer-transition-enter, .drawer-transition-leave-to {
  opacity: 0;
}
</style>