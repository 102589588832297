<template>
  <div
    ref="pageContainer"
    :class="[
      'main-content dashboard-page',
      customCompanyColors ? 'branded-dashboard-page branded-dashboard-page--' + customCompanyColors : ''
    ]"
  >
    <v-alert type="error" v-if="status.loadingFailed" text outlined>
      {{ $t('error', {'step': $t('loading.while')}) }}
    </v-alert>

    <loading v-else-if="status.loading"
             :is-loading="true"
             :dont-animate-entry="true"
             :title="$t('loading.title')"
             min-height="300px"
             tagline=""
    />

    <template v-else>
      <v-alert v-if="status.savingFailed" type="error" text outlined>
        {{ $t('error', {'step': $t('save.saving')}) }}
      </v-alert>

      <v-alert
        v-if="!user.isAnonymous && dashboardElements.length >= MAX_DASHBOARD_ITEMS_COUNT"
        class="w-100 text-center"
        type="warning"
        outlined
        text
        prominent
      >
        {{ $t('warning_limit', {limit: MAX_DASHBOARD_ITEMS_COUNT}) }}
      </v-alert>

      <DashboardBrandingPanel
        v-if="user.isAnonymous"
        :dashboard="dashboard"
      />

      <div class="dname" v-if="user.isAnonymous">
        {{ dashboard.name }}
      </div>

      <div class="editing-toolbar d-flex align-center justify-space-between" v-else>
        <v-text-field
          v-model="dashboard.name"
          :label="$t('editing_toolbar.name')"
          :error="!dashboard.name.length"
          class="name default-bs"
          @input="status.dirty = true"
          :disabled="status.readOnly"
          counter
          maxlength="50"
          hide-details
          outlined
          dense
        />

        <DashboardNoEditPermHint v-if="status.readOnly && !user.isAnonymous && user.id !== -1" :dashboard="dashboard" :user="user" />

        <div v-else class="ml-auto d-flex align-center">
          <v-tooltip bottom :disabled="!isDirty">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="d-flex align-center">
                <v-switch
                  v-model="dashboard.is_public"
                  :disabled="isDirty || status.readOnly"
                  @change="save"
                  inset
                  color="green"
                  flat
                  dense
                  :ripple="false"
                  class="is-public-switch v-input--reverse"
                  hide-details
                >
                  <template #label>
                    {{ $t('editing_toolbar.is_public_label') }}
                    <helptip position="bottom" :width="400" class="is-public-helptip">
                      <span v-html="$t('editing_toolbar.is_public_helptip')" />
                    </helptip>
                  </template>
                </v-switch>
              </div>
            </template>
            <span v-html="$t('editing_toolbar.is_public_disabled_dirty')" />
          </v-tooltip>

          <input type="hidden" id="public-link" :value="publicLink">
          <input type="hidden" id="public-link-with-filters" :value="publicLinkWithFilters">
          <input type="hidden" id="embed-link" :value="embedLink">
          <input type="hidden" id="embed-link-with-filters" :value="embedLinkWithFilters">

          <v-select
            :value="dashboardFilters.mockFilterValue"
            :items="copyLinkOptions"
            @change="copySharableText"
            :disabled="!dashboard.is_public"
            class="copy-select ml-2 mr-5"
            :class="{'copy-select--disabled': !dashboard.is_public}"
            :label="$t('copy')"
            append-icon=""
            small
            hide-details
            dense
          />

          <v-tooltip bottom :disabled="hasVisualizationsAccess">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="save">
                <v-btn
                  color="primary"
                  class="ml-4"
                  @click="save"
                  :error="status.savingFailed"
                  :loading="status.saving"
                  :disabled="!hasVisualizationsAccess || status.readOnly || status.loading || status.saving || !isDirty || !dashboard.name.length"
                >
                  {{ $t('save.title') }}
                </v-btn>
              </div>
            </template>
            <span v-html="$t('warning_no_access')" />
          </v-tooltip>
        </div>
      </div>

      <v-alert v-if="!hasVisualizationsAccess && !user.isAnonymous && user.id !== -1"
               type="warning"
               text
               outlined
      >
        <div style="display: flex">
          <span v-html="$t('warning_no_access')" />
          <template v-if="$root.isRootAccount">
            <v-spacer />
            <v-btn color="white"
                   class="manage-subscription"
                   dense
                   small
                   :to="{ name: 'account', query: { tab: 'subscription' } }"
            >
              {{ $t('feature_not_included.btn') }}
            </v-btn>
          </template>
        </div>
        <div v-if="!$root.isRootAccount"
             style="margin-top: 10px;"
             v-html="$t('feature_not_included.contact_admin', { email: user.organization.root_user_email })"
        />
      </v-alert>

      <div
        v-scroll="onWindowScroll"
        ref="filterPanelTrigger"
        class="filter-panel-trigger"
      />

      <DashboardBrandingPanel
        v-if="!user.isAnonymous && !!customCompanyColors"
        :dashboard="dashboard"
        class="branding--inline"
      />

      <div
        :class="[
          'filter-panel',
          {
            'filter-panel--sticky': !filtersDisabled,
            'filter-panel--sticky-branded': !filtersDisabled && !!customCompanyColors && !user.isAnonymous,
            'filter-panel--active': !filtersDisabled && isFilterSticky,
            'filter-panel--active-branded': !filtersDisabled && isFilterSticky && !!customCompanyColors && !user.isAnonymous
          }
        ]"
      >
        <v-divider class="filter-panel__divider" v-if="!filtersDisabled && isFilterSticky && (user.isAnonymous || !customCompanyColors)" />
        <v-tooltip bottom :disabled="!addFiltersDisabled">
          <template v-slot:activator="{ on }">
            <filters-list
              v-on="on"
              :max-filters="5"
              multi-line
              :question="{ auxiliaryTypes }"
              :disabled="filtersDisabled"
              :add-options="dashboardFiltersAddOptions"
              :filters.sync="filtersGate"
              @add="handleFilterAdd"
              @open="$event => openFilterEditor($event.idx)"
              @modified="handleFilterApply(true)"
            />
          </template>
          <span v-html="$t('editing_toolbar.filters_disabled_tooltip')" />
        </v-tooltip>
      </div>

      <v-divider class="editing-toolbar__separator" />

      <Grid
        v-model="dashboardElements"
        :warnings="dashboardElementsWarnings"
        :status="status"
        :items-limit="MAX_DASHBOARD_ITEMS_COUNT"
        @openDialog="openAddOrEdit"
        @remove="removeElementHandler"
      >
        <template v-slot="{ el, classes, styles }">
          <!-- @sasha technical debt: check how to delete template wrapper -->
          <GridItem
            :key="el.id"
            v-bind="{ el, classes, styles}"
            v-observe-visibility="(e) => chartVisibleHandler(e, el.chart.id, el.id)"
          >
            <template
              v-if="el.chart.type === 'P_VRB' && isVerbatimSummaryEnabled"
              slot="header-controls"
            >
              <v-btn
                v-if="controls.verbatimSummary[el.id]"
                outlined
                class="mr-2 my-n2"
                @click="toggleSummaryDisplaying(el.id)"
              >
                {{ $t('verbatim.controls.switch_to_rows') }}
              </v-btn>
              <v-btn
                v-else
                outlined
                class="mr-2 my-n2"
                @click="toggleSummaryDisplaying(el.id)"
              >
                {{ $t('verbatim.controls.switch_to_summary') }}
              </v-btn>
            </template>
            <div
              v-if="valueManager[el.chart.id] && valueManager[el.chart.id].warnings && valueManager[el.chart.id].warnings[0]"
              class="pa-4 position-absolute"
            >
              <notification
                :title="$escapeHtml(valueManager[el.chart.id].warnings[0].message)"
                type="warning"
              />
            </div>

            <pseudo-chart-text v-else-if="el.chart.type === 'P_TXT'" :chart="el.chart" />

            <div
              class="d-flex justify-center align-center h-100"
              v-else-if="!isEntityReady(el.chart.id) || !shownItems[el.id]"
            >
              <v-progress-circular
                class="mx-auto"
                color="primary"
                indeterminate
                :size="50"
              />
            </div>

            <verbatim-browser-v2-standalone
              v-else-if="el.chart.type === 'P_VRB' && el.chart && el.chart.id && configManager[el.chart.id] && configManager[el.chart.id].config"
              :id="el.chart.id"
              :config="configManager[el.chart.id].config"
              :query-params="verbatimQueryApplied[el.chart.id]"
              :is-summary-shown="controls.verbatimSummary[el.id]"
              :is-summary-enabled="isVerbatimSummaryEnabled"
              @clear-search="() => clearVerbatimFilters(el.chart.id)"
            />

            <component
              v-else-if="el.chart && el.chart.id && configManager[el.chart.id] && configManager[el.chart.id].type"
              :is="`chart-${configManager[el.chart.id].type}`"
              :value="configManager[el.chart.id].config"
              :id="el.chart.id"
              :type="configManager[el.chart.id].type"
              :datasets="configManager[el.chart.id].datasets"
              is-dashboard
              hide-controls
              :hide-master-filters="false"
              :chart-unique-name="configManager[el.chart.id].typeSelection"
              :meta="metaManager[el.chart.id]"
              :sentiment-shown="controls.sentimentShown[el.id]"
              :show-top-n-topics="controls.showTopNTopics[el.id]"
              :group-by-column-value="controls.groupByColumnValue[el.id]"
              :is-verbatim-enabled="!isPublic || dashboard.public_show_rows"
              :external-results="valueManager[el.chart.id] || {}"
            >
              <template v-slot:chart-filters>
                <div
                  class="d-flex justify-space-between flex-wrap mx-n1 my-n2"
                >
                  <div class="my-2">
                    <VerticalAccordion
                      v-model="controls.controllerAccordionFilterItemShown[el.id]"
                    >
                      <VerticalAccordionItem
                        v-if="!!configManager[el.chart.id].config.filters && !!configManager[el.chart.id].config.filters.length"
                        :is-bordered="false"
                        :is-active="true"
                        :tooltip-text="$t('controls_panel.filters.tooltip')"
                      >
                        <template slot="accordion-trigger">
                          <img src="@/assets/icons/filter.svg">
                          <span
                            v-if="configManager[el.chart.id].config.filters && configManager[el.chart.id].config.filters.length > 0"
                            class="ml-1"
                          >
                            {{ configManager[el.chart.id].config.filters.length }}
                          </span>
                        </template>
                      </VerticalAccordionItem>
                      <template
                        v-if="
                          controls.controllerAccordionFilterItemShown[el.id] === 0
                        "
                      >
                        <FiltersListItem
                          class="mx-1 my-2"
                          v-for="(item, itemIndex) in configManager[el.chart.id].config.filters"
                          :key="itemIndex"
                          :value="configManager[el.chart.id].config.filters[itemIndex]"
                          :meta="metaManager[el.chart.id].auxiliary_column_metas"
                          :readonly="true"
                        />
                      </template>
                    </VerticalAccordion>
                  </div>

                  <div class="my-2">
                    <VerticalAccordion
                      v-model="controls.controllerAccordionItemShown[el.id]"
                    >
                      <div
                        v-if="isSentimentToggleShown(el.chart, el.id)"
                        :class="[
                          'd-flex mx-1 my-2'
                        ]"
                      >
                        <switcher
                          v-if="isSentimentToggleShown(el.chart, el.id)"
                          :items="SENTIMENT_RANGE"
                          v-model="controls.sentimentShown[el.id]"
                          style="height: 100%;"
                        />
                      </div>
                      <VerticalAccordionItem
                        v-if="isMinimimTopicValueStepperShown(el.chart, el.id)"
                        :is-active="!!(controls.showTopNTopics[el.id] > 0)"
                        :tooltip-text="$t('controls_panel.top_n_topics.tooltip')"
                      >
                        <template slot="accordion-trigger">
                          <img
                            src="@/assets/icons/breakout.svg"
                          >
                          <span
                            v-if="controls.showTopNTopics[el.id] > 0"
                            class="ml-1"
                          >
                            {{ controls.showTopNTopics[el.id] }}
                          </span>
                        </template>
                        <template slot="accordion-content">
                          <NumberStepper
                            v-model="controls.showTopNTopics[el.id]"
                            placeholder="00"
                            :debounce-timeout="250"
                            :min="0"
                            :max="getAvailableTopTopicsNumber(el.chart.id)"
                            :is-bordered="false"
                          >
                            <template slot="prepend">
                              {{ $t('top_topics') }}
                            </template>
                          </NumberStepper>
                        </template>
                      </VerticalAccordionItem>
                      <VerticalAccordionItem
                        v-if="isGroupByColumnValueSelectShown(el.chart, el.id)"
                        :is-active="true"
                        :tooltip-text="$t('controls_panel.segmented_value.tooltip')"
                        class="my-2"
                      >
                        <template slot="accordion-trigger">
                          <img src="@/assets/icons/scale.svg">
                        </template>
                        <template slot="accordion-content">
                          <v-select
                            v-model="controls.groupByColumnValue[el.id]"
                            :items="getDYBARValues(el.chart.id, el.id)"
                            :label="$t('segment_by.value_title')"
                            item-value="value"
                            item-text="name"
                            hide-details
                            class="segmented-value-select"
                            outlined
                            dense
                            style="width: 220px;"
                            height="32"
                          />
                        </template>
                      </VerticalAccordionItem>
                    </VerticalAccordion>
                  </div>
                </div>
              </template>
            </component>
          </GridItem>
        </template>
      </Grid>
    </template>

    <!-- Dashboard filter editor  -->
    <DashboardDetailFilter
      v-model="modalsShown.filters"
      :filter-editor-props="filterEditorProps"
      :filter-label="activeFilterLabel"
      @removeElement="handleFilterRemove"
      @applyElement="handleDialogFilterApply"
    />

    <DashboardDetailAddOrEditElement
      v-model="modalsShown.addOrEdit"
      :basic-data="tempAddOrNew"
      @addElement="closeAddOrEdit"
    />

    <!-- ====== Remove dashboard element Dialog ====== -->
    <DashboardDetailRemoveElement
      v-model="modalsShown.removeElement"
      @removeElement="removeDashboardElement"
    />

    <!-- ====== Not enough credits dialog ====== -->
    <DashboardDetailNotEnoughCredits
      v-model="modalsShown.notEnoughCredits"
      :credits-required="notEnoughCredits.creditsRequired"
    />
    <!-- VERBATIM BROWSER V2 (WITH SENTIMENT) -->
    <verbatim-browser-v2-dialog
      :query-params="dashboardFilters.filterQueryParams || urlQueryParams"
      :is-summary-enabled="isVerbatimSummaryEnabled"
    />
  </div>
</template>

<script>

import Vuex from 'vuex'

import Dataset from '@/components/visualize/Dataset'
import ChartBar from '@/components/visualize/ChartBar'
import ChartLinePie from '@/components/visualize/ChartLinePie'
import ChartDateLinePie from '@/components/visualize/ChartDateLinePie'
import ChartRowsCountBar from '@/components/visualize/ChartRowsCountBar'
import ChartTreemap from '@/components/visualize/ChartTreemap'
import ChartGraph from '@/components/visualize/ChartGraph'
import ChartSentimentBar from '@/components/visualize/ChartSentimentBar'
import ChartScore from '@/components/visualize/ChartScore'
import ChartDriverScatter from '@/components/visualize/ChartDriverScatter'
import ChartDynamicBar from '@/components/visualize/ChartDynamicBar'
import PseudoChartText from '@/components/visualize/Text.vue'

import VerbatimBrowserV2Standalone from '@/components/VerbatimBrowserv2/VerbatimBrowserv2Standalone'
import VerbatimBrowserV2Dialog from '@/components/VerbatimBrowserv2/VerbatimBrowserv2Dialog'

import FiltersList from '@/components/FiltersList'
import FiltersListAddButton from '@/components/FiltersList/FiltersListAddButton'
import FiltersListItem from '@/components/FiltersList/FiltersListItem'

import Grid from '@/components/customUi/grid/Grid.vue'
import GridItem from '@/components/customUi/grid/GridItem.vue'

import VerticalAccordion from '@/components/customUi/verticalAccordion/VerticalAccordion.vue'
import VerticalAccordionItem from '@/components/customUi/verticalAccordion/VerticalAccordionItem.vue'

import Switcher from '@/components/customUi/switcher/Switcher.vue'
import NumberStepper from '@/components/customUi/numberStepper/NumberStepper.vue'

import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import colorPalettes from '@/mixins/colorPalettes'
import HideBodyScrollForDialogs from '@/mixins/hideBodyScrollForDialogs'

import DashboardChartControls from '@/pages/_dashboardMixins/chartControls'
import DashboardCrossPlatformDiffResolver from '@/pages/_dashboardMixins/crossPlatformDiffResolver'
import DashboardEdit from '@/pages/_dashboardMixins/edit'
import DashboardEnvironment from '@/pages/_dashboardMixins/environment'
import DashboardFetch from '@/pages/_dashboardMixins/fetch'
import DashboardFilters from '@/pages/_dashboardMixins/filters'
import DashboardGrid from '@/pages/_dashboardMixins/grid'
import DashboardInit from '@/pages/_dashboardMixins/init'
import DashboardLazyLogic from '@/pages/_dashboardMixins/lazyLogic'

/*
* Page related
*/

import DashboardDetailAddOrEditElement from '@/components/modals/DashboardDetailAddOrEditElement/DashboardDetailAddOrEditElement'
import DashboardDetailFilter from '@/components/modals/DashboardDetailFilter.vue'
import DashboardDetailRemoveElement from '@/components/modals/DashboardDetailRemoveElement.vue'
import DashboardDetailNotEnoughCredits from '@/components/modals/DashboardDetailNotEnoughCredits.vue'
import DashboardBrandingPanel from '@/components/dashboard/DashboardBrandingPanel.vue'
import DashboardNoEditPermHint from '@/components/dashboard/DashboardNoEditPermHint.vue'

import {
  CHART_TYPE_BAR,
  CHART_TYPE_SENTIMENT_BAR,
  CHART_TYPE_LINE_PIE,
  CHART_TYPE_DATE_LINE_PIE,
  CHART_TYPE_ROWS_COUNT_BAR,
  CHART_TYPE_TREEMAP,
  CHART_TYPE_GRAPH,
  CHART_TYPE_DRIVER_SCATTER,
  CHART_TYPE_DYNAMIC_BAR,
  CHART_TYPE_SCORE
} from '@/settings/constants'

const EMPTY_FILTERS_FALLBACK_VALUE = [{}]

function getDashboardScaffold () {
  return {
    id: 'new',
    name: this.$t('editing_toolbar.name_new'),
    is_public: false,
    public_uuid: ''
  }
}

const MODELS_SHOWN_SCAFFOLD = {
  filters: false,
  addOrEdit: false,
  removeElement: false,
  notEnoughCredits: false
}

const DASHBOARD_STATUS_SCAFFOLD = {
  loading: false,
  loadingFailed: false,
  dirty: false,
  readOnly: false,
  saving: false,
  savingFailed: false,
  programmaticRouteChange: true
}

export default {
  codit: true,
  name: 'Dashboard',
  components: {
    'verbatim-browser-v2-standalone': VerbatimBrowserV2Standalone,
    'verbatim-browser-v2-dialog': VerbatimBrowserV2Dialog,
    'dataset': Dataset,
    'filters-list': FiltersList,
    FiltersListAddButton,
    FiltersListItem,
    [`chart-${CHART_TYPE_BAR}`]: ChartBar,
    [`chart-${CHART_TYPE_SENTIMENT_BAR}`]: ChartSentimentBar,
    [`chart-${CHART_TYPE_LINE_PIE}`]: ChartLinePie,
    [`chart-${CHART_TYPE_DATE_LINE_PIE}`]: ChartDateLinePie,
    [`chart-${CHART_TYPE_ROWS_COUNT_BAR}`]: ChartRowsCountBar,
    [`chart-${CHART_TYPE_TREEMAP}`]: ChartTreemap,
    [`chart-${CHART_TYPE_GRAPH}`]: ChartGraph,
    [`chart-${CHART_TYPE_DRIVER_SCATTER}`]: ChartDriverScatter,
    [`chart-${CHART_TYPE_SCORE}`]: ChartScore,
    [`chart-${CHART_TYPE_DYNAMIC_BAR}`]: ChartDynamicBar,
    Grid,
    GridItem,
    VerticalAccordion,
    VerticalAccordionItem,
    PseudoChartText,
    Switcher,
    NumberStepper,
    DashboardDetailAddOrEditElement,
    DashboardDetailFilter,
    DashboardDetailRemoveElement,
    DashboardDetailNotEnoughCredits,
    DashboardBrandingPanel,
    DashboardNoEditPermHint
  },

  mixins: [
    answerFilters,
    auxiliaryColumnsMixin,
    colorPalettes,
    HideBodyScrollForDialogs,

    DashboardChartControls,
    DashboardCrossPlatformDiffResolver,
    DashboardEdit,
    DashboardEnvironment,
    DashboardFetch,
    DashboardFilters,
    DashboardGrid,
    DashboardInit,
    DashboardLazyLogic
  ],

  data () {
    return {
      status: _.cloneDeep(DASHBOARD_STATUS_SCAFFOLD),

      notEnoughCredits: {
        creditsRequired: 0
      },

      dashboard: getDashboardScaffold.call(this),

      modalsShown: _.cloneDeep(MODELS_SHOWN_SCAFFOLD)
    }
  },

  computed: {
    ...Vuex.mapState(['user']),

    ...Vuex.mapGetters({
      metaManager: 'metaManager/overall',
      configManager: 'configManager/overall',
      registerManager: 'registerManager/overall',
      valueManager: 'valueManager/overall',
      userCreditsRemaining: 'userCreditsRemaining'
    }),

    /**
     * If the page has any unsaved changes. Enables the save method.
     */
    isDirty () {
      return this.status.dirty || !!this.dashboardElements.filter(el => el.status.dirty).length
    },

    dashboardAPIObject () {
      let { name, is_public } = this.dashboard // eslint-disable-line camelcase
      return {
        name,
        is_public,
        elements: this.dashboardElements.map(({ x, y, width, height, chart }) => ({
          x, y, width, height, chart: chart.id
        }))
      }
    },

    /**
     * GET query params that should be sent with every request: The public_uuid, if we're viewing
     * the dashboard anonymously
     * @return {Object}
     */
    urlQueryParams () {
      return { ...(this.user.isAnonymous) && { 'public_uuid': this.dashboard.public_uuid }, filters: JSON.stringify(EMPTY_FILTERS_FALLBACK_VALUE) }
    },

    chartValuesParams () {
      const nonEmptryFilters = this.dashboardFilters.filters.filter(filter => filter.type)
      return {
        ...this.urlQueryParams,
        'filters': nonEmptryFilters.length ? this.filtersTransformedToQueryParams() : JSON.stringify(EMPTY_FILTERS_FALLBACK_VALUE)
      }
    },

    /**
     * Shared auxilary columns between the dashboard elements
     */
    auxiliaryTypes () {
      return this.dashboard.shared_auxiliary_column_metas
    },

    /**
     * Names of shared auxilary columns shared between dashboard elements
     */
    sharedAuxilaryColumnNames () {
      return this.dashboard?.shared_auxiliary_column_metas?.map(({ name }) => name)
    },

    /*
    * Shared settings for vuex stored charts.
    * Let them use correct extra payload while fetching.
    */
    extraQueryParams () {
      let params = {
        filters: this.$route.query.filters || this.urlQueryParams.filters
      }
      if (
        this.isPublic
      ) {
        params.public_uuid = this.dashboard.public_uuid
      }
      return params
    },

    customCompanyColors () {
      if (
        this.dashboard?.owner_organization_id === 71 // dhl
      ) {
        return 'dhl'
      }
      return null
    }
  },

  watch: {
    async '$route.params.id' () {
      await this.$store.dispatch(
        `registerManager/deleteMany`,
        {
          list: this.dashboardElements.map(item => item.chart.id)
        }
      )

      this.dashboardElements = [] // can't move it to dedicated file, since all depends on this variable

      this.dashboard = getDashboardScaffold.call(this)

      this.modalsShown = _.cloneDeep(MODELS_SHOWN_SCAFFOLD)

      this.status = _.cloneDeep(DASHBOARD_STATUS_SCAFFOLD)

      this.init()
    }
  },

  async created () {
    this.init()
  },

  methods: {
    async init () {
      if (
        this.$route.params?.id === 'new'
      ) {
        this.setBreadcrumbProps()
        this.status.dirty = true
      }
      if (
        this.$route.params?.id === 'new' &&
        'charts' in this.$route.query
      ) {
        this.initFromQuery()
      }
      if (
        this.$route.params?.id === 'new'
      ) return
      if (
        this.$route.params?.id &&
        this.isPublic
      ) this.$store.commit('userIsAnonymous')
      await this.initExisitng()
      this.initFilters()
      this.setBreadcrumbProps()

      if (this.user.isAnonymous) {
        this.$root.posthogSetPersonPropertiesForFlags({ 'organization_id': this.dashboard.owner_organization_id })
      }
      if (!this.$hasPermission('dashboards_edit', this.dashboard)) this.status.readOnly = true
    },

    isEntityReady (id) {
      return this.$store.getters['registerManager/isReady'](id)
    },

    /*
    * Update page breadcrumbs component
    */
    setBreadcrumbProps () {
      this.$store.commit('setBreadcrumbProps', {
        dashboardID: this.dashboard.id,
        dashboardName: this.dashboard.name,
        charts: this.$route.query.charts
      })
    },

    /**
     * Active non-empty dashboard filters mapped for the backend to consume by query params
     */
    filtersTransformedToQueryParams () {
      const filters = this.dashboardFilters
        .filters.filter(filter => filter.type)
        .map(filter => {
          if (filter.type === 'auxiliary') {
            return {
              ...filter,
              value: {
                ...filter.value,
                col: this.auxiliaryTypes[filter.value.col] ? this.auxiliaryTypes[filter.value.col].name : filter.value.col
              }
            }
          }

          return filter
        })
      return JSON.stringify(filters)
    },

    /*
    * Save dashboard changes
    */
    async save () {
      this.status.saving = true
      this.status.savingFailed = false
      this.status.dirty = false

      let res
      try {
        if (this.dashboard.id === 'new') res = await api.post(`/api/dashboards/`, this.dashboardAPIObject)
        else res = await api.patch(`/api/dashboards/${this.dashboard.id}`, this.dashboardAPIObject)

        if (res.status >= 400) throw Error('Dashboard creation failed.')
        if (res.status === 201 || res.status === 200) {
          this.$set(this, 'dashboard', res.data)
        }
        if (res.status === 201) {
          // Mark this as programmatic change, i.e. we don't need to update the state through the route guard
          this.status.programmaticRouteChange = true
          this.$router.replace({ // Set the id in the URI
            name: 'dashboard-details',
            params: { id: res.data.id },
            // TODO: check if necessary
            query: { ...this.chartValuesParams }
          })
        }

        this.setBreadcrumbProps()
        this.status.saving = false
        this.dashboardElements.forEach(item => {
          item.status.dirty = false
        })
      } catch (error) {
        this.status.saving = false
        this.status.savingFailed = true
        this.status.dirty = true
        this.$maybeRaiseAPIPromiseErr(error)
      }
    }
  }
}

</script>

<style lang=scss>
  @import '~css/chart';
  @import '~css/dashboard';

  .segmented-value-select {
    .v-input__slot{
      min-height: 34px !important;
      font-size: 14px !important;
    }
    .v-input__append-inner{
      margin-top: 5px !important;
    }
    fieldset{
      border: 0 !important;

      legend{
        background: #fff;
      }
    }
  }

  .branded-dashboard-page{
    margin: -12px !important;
    padding: 17px 20px 92px;
    min-height: calc(100% + 24px);

    .branding{
      padding: 5px 0 0 !important;

      &--inline{
        top: 65px !important;
        position: sticky !important;
        padding: 0 !important;
        margin: 0 -20px !important;

        .branding__inner{
          padding: 0 20px !important;
        }
      }

      &__inner{
        padding: 0 16px;
      }

      img{
        height: 60px;
        max-height: 75%;
      }
    }

    .filter-panel{
      &--active{
        background: transparent !important;

        &:before{
          background-color: #fff;
          top:9px;
          bottom: 0;
          left: 0;
          right: 0;
          content: '';
          position: absolute;
        }

        hr{
          opacity: 0;
        }
      }
      &--active-branded{
        &:before{
          top: 0px;
        }
      }

      .dashboard-filters{
        position: relative;
      }
    }

    &--dhl{
      background-color: #fff;

      .dashboard {
        .dashboard-el {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
        }
      }

      .branding{
        &__inner{
          background: rgb(255,204,0) !important;
          background: linear-gradient(90deg, rgba(255,204,0,1) 0%, rgba(255,204,0,0.35) 100%) !important;
        }
        &--inline{
          .branding__inner{
            border-top: 5px solid #d40511 !important;
          }
        }
      }

      .v-progress-circular.primary--text{
        color: #d40511 !important;
      }
    }
  }
</style>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />
