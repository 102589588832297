<template>
  <div>
    <template>
      <settings-drawer-item
        :title="$t('ds.name')"
        v-if="!isMaster"
      >
        <template slot="content">
          <div class="d-flex align-center justify-space-between">
            <v-text-field
              :value="settingsInternal.name"
              ref="dsName"
              outlined
              hide-details
              dense
              class="dsName"
              maxlength="100"
              :rules="[(val) => val.length >= 1 || '']"
              :label="$t('ds.name')"
              :style="{ maxWidth: disabledSettings.name ? '89%' : '' }"
              :disabled="disabledSettings.name"
              @input="updateTitleDebounced"
            />
            <helptip position="bottom" class="ml-3">
              <span v-html="$t('ds.helptip_disabled')" />
            </helptip>
          </div>
        </template>
      </settings-drawer-item>

      <settings-drawer-item
        :title="$t('ds.settings.weighting_column')"
        v-if="availableSettings.weighting"
      >
        <template slot="content">
          <v-autocomplete v-model="settingsInternal.weighting_column"
                          :items="weightingColumnOptions"
                          :label="$t('ds.settings.weighting_column')"
                          item-value="value"
                          class="mt-2"
                          hide-details
                          dense
                          clearable
                          @click:clear="clearWeightingColumn"
                          outlined
          />
          <div class="d-flex align-center mt-3 justify-space-between">
            <v-checkbox v-model="settingsInternal.weighting_normalize"
                        :label="$t('ds.settings.weighting_normalize')"
                        :disabled="settingsInternal.weighting_column === null"
                        hide-details
                        dense
                        small
                        class="mt-0"
            />
            <helptip position="top" :width="600">
              <span v-html="$t('ds.settings.weighting_column_helptip')" />
            </helptip>
          </div>
        </template>
      </settings-drawer-item>

      <settings-drawer-item
        :title="$t('ds.settings.color.override')"
        v-if="availableSettings.coloring && !isMaster"
      >
        <template slot="content">
          <div class="d-flex align-center justify-space-between">
            <v-checkbox
              v-model="settingsInternal.color.override"
              :label="$t('ds.settings.color.override_label')"
              hide-details
              dense
              small
              class="mt-0"
            />
            <helptip position="bottom" :width="480">
              <span v-html="$t('ds.settings.color.helptip')" />
            </helptip>
          </div>
          <div
            v-if="settingsInternal.color.override"
            class="d-flex align-center theme--light v-label mt-2"
          >
            <div
              class="color-indicator mr-2"
              :style="{ 'background-color': settingsInternal.color.value }"
              @click.stop="showColorPicker($event)"
            />
            {{ $t('ds.settings.color.title') }}
          </div>
          <color-picker
            v-model="colorPicker.val"
            :show.sync="colorPicker.active"
            :offset-top="colorPicker.offsetTop"
            :offset-left="colorPicker.offsetLeft"
            :min-top="-500"
            :indicator-height="25"
            :indicator-width="25"
            lazy
          />
        </template>
      </settings-drawer-item>

      <settings-drawer-item
        :title="$t('settings.driver_column.label')"
        v-if="availableSettings.driver"
      >
        <template slot="content">
          <div v-if="availableSettings.driver" class="list-allow-overflow">
            <v-autocomplete v-model="settingsInternal.driver_target_column"
                            :items="potentialDriverColumnSelectItems"
                            :label="$t('settings.driver_column.label')"
                            item-text="text"
                            item-value="value"
                            hide-details
                            outlined
                            dense
                            class="mt-4"
            />
            <div class="d-flex align-items-center justify-end mt-2">
              <helptip position="top" :width="650" style="font-weight: normal">
                <span v-html="$t('settings.driver_column.helptip') + $t('settings.driver_column.supported_formats', {
                  csat: CSATTermsFormatted
                })"
                />
              </helptip>
            </div>
          </div>
        </template>
      </settings-drawer-item>
    </template>
  </div>
</template>

<script>

import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import colorPalettes from '@/mixins/colorPalettes'
import ColorPicker from '@/components/ColorPicker'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { generateSigleFunctionCallComputedWithSameArgs } from '@/utils/genericComputed.js'

import { ADDITIONAL_COLUMNS, DS_SETTINGS_TEMPLATE } from '@/settings/constants'

_ = require('lodash')

export default {
  codit: true,
  name: 'DatasetSettings',

  components: {
    'color-picker': ColorPicker,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [auxiliaryColumnsMixin, colorPalettes],

  props: {
    settings: { type: Object, default: () => ({}) },
    question: { type: Object, default: () => ({}) },
    availableSettings: { type: Object, default: () => ({}) },
    disabledSettings: { type: Object, default: () => ({}) },
    applyMaster: { type: Function, default: () => ({}) },
    isMaster: { type: Boolean, default: false }
  },

  data () {
    return {
      settingsInternal: _.cloneDeep(DS_SETTINGS_TEMPLATE),

      additionalColumns: ADDITIONAL_COLUMNS,

      colorPicker: {
        active: false,
        offsetLeft: 0,
        offsetTop: 0,
        val: 'rgba(0,0,0,1.0)'
      },

      result: [],

      updateTitleDebounced: _.debounce((val) => {
        this.settingsInternal.name = val
      }, 250),

      emitSettingsDebounced: _.debounce((val) => {
        console.log('emitSettingsDebounced', this.settings, val)
        this.$emit('update:settings', _.cloneDeep(this.settingsInternal))
        if (!_.isEqual(this.settings, val)) {
          this.$nextTick(() => {
            this.$emit('modified', true)
          })
        }
      }, 250),

      colorPickerUpdatedDebounced: _.debounce((val) => {
        this.settingsInternal.color.value = val
      }, 100)
    }
  },

  computed: {
    ...generateSigleFunctionCallComputedWithSameArgs({
      potentialDriverColumnSelectItems: 'getPotentialDriverColumnSelectItems'
    })(function () {
      return [
        this.question.auxiliary_column_metas,
        this.question.auxiliary_column_metas.map(({ name }) => name)
      ]
    }),

    /**
     * Computed getter for the getAuxiliaryOptions method in auxiliaryColumnsMixin
     * @return {Array} Array of objects with index & name of auxiliary columns which can be passed to a v-select
     */
    auxiliaryOptions () {
      return this.getAuxiliaryOptions(this.question.auxiliary_column_metas.map(({ name }) => name))
    },

    /**
     * Auxiliary columns which would be valid weighting columns. Outpu can be used in v-select
     * @return {Array}
     */
    weightingColumnOptions () {
      if (!this.question.auxiliary_column_metas) return []
      else {
        return this.auxiliaryOptions.filter(({ value, text }) => {
          let t = this.question.auxiliary_column_metas[value]
          return t && t.type === 'number'
        })
      }
    }
  },

  watch: {
    /**
     * Watch the internal state of settings for changes, emit update when change has occurred
     */
    settingsInternal: {
      deep: true,
      handler: 'emitSettingsDebounced'
    },

    /**
     * Watch the state of the settings prop
     * If it has changed, check if the value is the same as the internal object
     * Note: The objects should *never* be the same as the internal one, as we cloneDeep when emitting
     */
    settings: {
      immediate: true,
      deep: true,

      handler (settings) {
        // Update the internal state, but only overwrite keys that are given from external
        // this is necessary for making new settings applied in the future backwards compatible with old charts
        if (!_.isEqual(settings, this.settingsInternal)) {
          _.each(settings, (val, key) => {
            this.$set(this.settingsInternal, key, val)
          })
        }
      }
    },

    /**
     * Update the color setting when a new value is chosen in the color picker
     */
    'colorPicker.val' (newVal, oldVal) {
      if (newVal !== oldVal) this.colorPickerUpdatedDebounced(newVal)
    }
  },

  methods: {
    clearWeightingColumn () {
      this.$nextTick(() => {
        this.settingsInternal.weighting_column = null
        this.settingsInternal.weighting_normalize = false
      })
    },

    /**
     * Open the color picker, to set the override color for the current dataset
     * @param  {Object} $event The click object that triggered the opening of the picker
     */
    showColorPicker ($event) {
      this.colorPicker.offsetLeft = $event.target.offsetLeft
      this.colorPicker.offsetTop = $event.target.offsetTop
      this.colorPicker.val = this.settingsInternal.color.value
      this.colorPicker.active = true
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
