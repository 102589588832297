<template>
  <v-dialog v-model="valueInternal"
            max-width="600px"
            @keydown.esc="closeDialog"
            content-class="stripe-payment-method"
            :persistent="loading"
  >
    <v-card>
      <v-card-title class="headline">
        <slot name="title">
          <span v-html="$t('payment_method.title')" />
        </slot>
      </v-card-title>

      <v-card-text>
        <v-progress-linear v-if="intentLoading" indeterminate />
        <form v-show="!intentLoading" @submit.prevent="saveCard">
          <div v-show="!success" ref="card" />
        </form>

        <v-expand-transition>
          <div v-show="error">
            <v-alert type="error"
                     outlined
                     dense
                     text
            >
              {{ errorMessage }}
            </v-alert>
          </div>
        </v-expand-transition>
        <div v-show="success">
          <v-alert type="success"
                   outlined
                   text
          >
            {{ $t('payment_method.success') }}
          </v-alert>
        </div>
      </v-card-text>

      <v-spacer />

      <v-card-actions>
        <v-spacer />
        <v-btn outlined
               :disabled="loading"
               @click.native="closeDialog"
               v-text="success ? $t('close') : $t('cancel')"
        />
        <v-btn v-if="!success"
               color="primary"
               :loading="loading"
               :disabled="intentLoading"
               @click.native="saveCard"
        >
          {{ $t('save.title') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import stripePayment from '../mixins/stripePayment.js'

export default {
  mixins: [stripePayment],

  props: {
    value: { type: Boolean, default: false },
    closeOnSuccess: { type: Boolean, default: false }
  },

  data () {
    return {
      secret: '',
      stripe: null,
      card: null,

      intentLoading: true,

      error: false,
      errorMessage: '',
      loading: false,
      success: false
    }
  },

  computed: {
    valueInternal: {
      set (value) { this.$emit('input', value) },
      get () { return this.value }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) {
          this.reset()
          this.init()
        }
      }
    }
  },

  methods: {
    reset () {
      this.error = false
      this.errorMessage = false
      this.success = false
    },

    init () {
      this.intentLoading = true

      api.post('/api/subscription/create-payment-setup-intent').then(res => {
        this.secret = res.data.intent_secret
        this.intentLoading = false
        this.mount()
      }).catch(err => {
        this.intentLoading = false
        this.error = true
        this.errorMessage = this.$t('payment_method.intent_loading_failed')
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    mount () {
      this.stripe = window.Stripe(this.STRIPE_PK)
      let elements = this.stripe.elements()

      let style = {
        base: {
          color: '#32325d',
          fontFamily: 'Roboto,sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '18px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }

      this.card = elements.create('card', { style })
      this.card.mount(this.$refs.card)
    },

    closeDialog () {
      if (this.loading) return
      this.valueInternal = false
      if (!this.success) this.$emit('cancelled')
    },

    saveCard () {
      this.loading = true

      this.stripe.confirmCardSetup(
        this.secret, { payment_method: { card: this.card } }
      ).then(({ setupIntent, error }) => {
        if (error) {
          this.error = true
          this.errorMessage = error.message
          if (error.type === 'validation_error') setTimeout(() => { this.error = false }, 3000)
          this.loading = false
        } else if (setupIntent.status === 'succeeded') {
          api.post('/api/subscription/payment-methods', { intent_payment_method: setupIntent.payment_method }).then(res => {
            // The setup has succeeded. Display a success message and send
            // setupIntent.payment_method to the API to store the card with the customer
            this.$emit('added', res.data.payment_method)
            this.success = true
            this.loading = false
            if (this.closeOnSuccess) this.closeDialog()
          }).catch(err => {
            this.loading = false
            this.error = true
            this.errorMessage = this.$t('payment_method.saving_failed')
            // Reload the entire form, as we cannot reuse the same setup intent twice
            this.init()
            this.$maybeRaiseAPIPromiseErr(err)
          })
        }
      })
    }
  }
}

</script>

<style lang=scss>

.stripe-payment-method {
  .v-card {
    background-color: #f7f8f9!important;
  }

  .v-alert {
    margin: 0;
  }

  .StripeElement {
    box-sizing: border-box;

    height: 60px;

    padding: 20px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    margin-bottom: 15px;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
}

</style>

<i18n locale='en' src='@/i18n/en/components/StripePaymentMethod.json' />
<i18n locale='de' src='@/i18n/de/components/StripePaymentMethod.json' />