<template>
  <v-tooltip
    content-class="chart-type-preview__tooltip"
    bottom
    :max-width="250"
    :open-delay="150"
    :close-delay="150"
    ref="tooltip"
  >
    <template v-slot:activator="{ on }">
      <div
        v-on="on"
        :class="[
          'chart-type',
          {
            active,
            disabled
          },
        ]"
      >
        <v-img
          :src="imageSource"
        />
        <v-img
          v-if="CHART_BADGE_IMAGES[CHART_TYPES_TO_API_ENUM[chartType]]"
          :src="CHART_BADGE_IMAGES[CHART_TYPES_TO_API_ENUM[chartType]]"
          class="h-4 ml-n1 mt-n1 type-icon-badge"
        />
        <v-icon
          v-if="CHART_BADGES[CHART_TYPES_TO_API_ENUM[chartType]]"
          class="type-icon-badge"
        >
          {{ CHART_BADGES[CHART_TYPES_TO_API_ENUM[chartType]] }}
        </v-icon>
      </div>
    </template>

    <div
      @mouseenter="openTooltip"
      @mouseleave="closeTooltip"
    >
      <b>
        {{ $t(`chart.types.${chartType}.title`) }}:
        {{ $t(`chart.types.${chartType}.slogan`) }}
      </b>

      <span>
        {{ $t(`chart.types.${chartType}.subtitle`) }}
      </span>

      <div class="warning--text mt-4" v-if="!chartSettings.enabled">
        {{ $t(`chart.warnings.disabled`) }}
      </div>

      <div
        class="mt-4"
        v-if="isTooltipLink && chartType === 'nps'"
      >
        <p
          v-html="
            $t('settings.column.no_valid_column', {
              helplink:
                'https://caplena.com/docs/knowledge-base/85eh1l8jcavy0-data-import#leverage-non-text-data',
            })
          "
        />
        <div v-html="$t('settings.column.supported_formats')" />
      </div>
      <div
        class="mt-4"
        v-else-if="isTooltipLink && chartType === 'driver-scatter'"
      >
        <p
          v-html="
            $t('settings.driver_column.no_valid_column', {
              helplink:
                'https://caplena.com/docs/knowledge-base/85eh1l8jcavy0-data-import#leverage-non-text-data',
            })
          "
        />
        <div
          v-html="
            $t('settings.driver_column.supported_formats', {
              csat: CSATTermsFormatted,
            })
          "
        />
      </div>
      <div
        class="mt-4"
        v-else-if="isTooltipLink && chartType === 'dynamic-bar'"
      >
        <p
          v-html="
            $t('settings.segment_column.no_valid_column')
          "
        />
      </div>
    </div>
  </v-tooltip>
</template>

<script>
import { getChartTileImage } from '@/utils/funcs'
import {
  badges as CHART_BADGES,
  badgeImages as CHART_BADGE_IMAGES
} from '@/settings/chartIcons'

import {
  CHART_TYPES_TO_API_ENUM
} from '@/settings/constants'

import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin' // need for CSATTermsFormatted

export default {
  mixins: [auxiliaryColumnsMixin],

  props: {
    chartType: { type: String, default: '' },
    chartSettings: { type: Object, default: () => ({}) },
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  data () {
    return {
      CHART_TYPES_TO_API_ENUM,
      CHART_BADGES,
      CHART_BADGE_IMAGES
    }
  },

  computed: {
    isTooltipLink () {
      return !this.chartSettings.enabled && ['nps', 'driver-scatter', 'dynamic-bar'].indexOf(this.chartType) > -1
    },

    imageSource () {
      return getChartTileImage(this.chartType)
    }
  },

  methods: {
    openTooltip () {
      if (
        !this.isTooltipLink
      ) {
        return
      }

      this.$refs.tooltip.runDelay('open')
    },

    closeTooltip () {
      this.$refs.tooltip.runDelay('close')
    }
  }
}
</script>

<style>
.chart-type-preview__tooltip.v-tooltip__content {
  pointer-events: initial !important;
}
</style>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />