<template>
  <DialogWrapper
    v-model="internalValue"
    :width="500"
  >
    <DialogHeader>
      <template slot="title">
        {{ $t('ds.filters.type_label') }}: {{ filterLabel }}
      </template>
      <template slot="controls">
        <v-icon
          @click="closeDialog"
          :size="20"
        >
          mdi-close
        </v-icon>
      </template>
    </DialogHeader>
    <DialogContent
      class="px-4 py-2"
    >
      <FilterEditorV1WithTopics
        v-bind="filterEditorProps"
        @input="updateFilterData"
      />
    </DialogContent>
    <DialogActions>
      <template slot="leftSide">
        <v-btn
          color="primary"
          outlined
          class="mr-2"
          @click.native="removeElement"
        >
          {{ $t('ds.filters.delete') }}
        </v-btn>
      </template>
      <template slot="rightSide">
        <v-btn
          color="primary"
          outlined
          class="mr-2"
          @click.native="closeDialog"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click.native="applyFilter"
          :disabled="isApplyDisabled"
        >
          {{ $t('ds.filters.apply') }}
        </v-btn>
      </template>
    </DialogActions>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/customUi/dialog/DialogWrapper'
import DialogHeader from '@/components/customUi/dialog/DialogHeader'
import DialogContent from '@/components/customUi/dialog/DialogContent'
import DialogActions from '@/components/customUi/dialog/DialogActions'

import FilterEditorV1WithTopics from '@/components/FilterEditorV1WithTopics'

import DataMixin from '@/components/customUi/mixins/data'
import DialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'FilterEditorDialog',
  codit: true,

  components: {
    DialogWrapper,
    DialogHeader,
    DialogContent,
    DialogActions,
    FilterEditorV1WithTopics
  },

  mixins: [DataMixin, DialogMixin],

  props: {
    filterEditorProps: { type: Object, default: () => ({}), required: true },
    filterLabel: { type: String, default: '', required: true }
  },

  data () {
    return {
      filterData: null
    }
  },

  computed: {
    isApplyDisabled () {
      return !!this.filterEditorProps?.allFilters?.some(({ type }) => type === 'sentiment')
    }
  },

  watch: {
    internalValue (val) {
      if (val) return
      this.$set(this, 'filterData', null)
    }
  },

  methods: {
    updateFilterData (val) {
      this.$set(this, 'filterData', val)
    },
    removeElement () {
      this.$emit('removeElement')
    },
    applyFilter () {
      this.closeDialog()
      this.$emit('applyElement', this.filterData)
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />