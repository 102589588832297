var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'body-2 switcher',
    {
      'switcher--border': _vm.isBordered
    }
  ]},_vm._l((_vm.items),function(item,itemIndex){return _c('div',{key:itemIndex,class:[
      'switcher__item d-flex align-center',
      {
        'switcher__item--active': item.value === _vm.internalValue
      }
    ],on:{"click":function($event){return _vm.setInternalValue(item.value)}}},[(item.icon)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('img',_vm._g({staticClass:"switcher__icon",attrs:{"src":item.icon.image}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.icon.label))])]):_c('span',[_vm._v("\n      "+_vm._s(item.label)+"\n    ")])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }