<template>
  <div>
    <v-overlay
      class="focus-mode-overlay"
      :value="focusMode"
      color="rgba(5, 32, 46, 0.8) !important"
    />
    <v-dialog
      :value="focusMode"
      @input="closeDialog"
      @keydown.esc="closeDialog"
      hide-overlay
      eager
      :width="'900px'"
      content-class="dashboard-filter-editor focus-mode"
    >
      <div
        v-if="focusMode"
        class="d-flex flex-column justify-space-between h-100"
      >
        <div class="dashboard-filter-editor__content h-100">
          <v-icon
            class="dashboard-filter-editor__close hover"
            @click.stop="closeDialog"
            :size="21"
          >
            mdi-close
          </v-icon>
          <div v-if="activeRowIndex === 'empty'" class="pt-4 pl-4">
            {{ $t("no_focus_results") }}
          </div>
          <div v-else-if="loading">
            <div class="focus-mode__padding">
              <div class="coding__row coding__row__loading d-flex">
                <div class="w-100">
                  <div class="coding__row__name" />
                  <div class="d-flex coding__row__topics">
                    <div class="coding__row__topic" />
                    <div class="coding__row__topic coding__row__topic--2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="activeRowIndex !== null"
            class="h-100 d-flex flex-column justify-space-between"
          >
            <div class="d-flex h-100">
              <div class="focus-mode__review-container">
                <div
                  class="coding__drawer__padding focus-mode__padding focus-mode__review-container__bg"
                >
                  <div class="coding__drawer__sentiment d-flex align-center">
                    <div>
                      <sentiment-icon
                        :sentiment="activeRow.codingColumn.sentiment_overall"
                      />
                    </div>
                    <div
                      class="ml-2 coding__drawer__sentiment__text"
                      :style="{
                        color: getColorForSentiment(
                          activeRow.codingColumn.sentiment_overall
                        )
                      }"
                    >
                      {{ activeRow.codingColumn.sentiment_overall }}
                    </div>
                  </div>
                  <div class="coding__drawer__review mt-3 font-weight-medium">
                    {{
                      shouldShowTranslatedValue
                        ? activeRow.codingColumn.translated_value
                        : activeRow.codingColumn.value
                    }}
                    <div class="d-flex align-center">
                      <div
                        v-if="
                          shouldShowTranslatedValue &&
                            activeRow.codingColumn.translated_value
                        "
                        class="coding__drawer__translate d-flex align-center"
                      >
                        <svg
                          class="mr-1"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.499 13.5L10.999 6.5L7.49902 13.5"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.49902 11.5H13.499"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.49902 2V3.5"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.49902 3.5H9.49902"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.49902 3.5C7.49902 5.0913 6.86688 6.61742 5.74166 7.74264C4.61645 8.86786 3.09032 9.5 1.49902 9.5"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.04395 6C4.52307 7.04397 5.29176 7.9285 6.25871 8.54854C7.22567 9.16857 8.35026 9.49806 9.49893 9.49788"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div
                          class="coding__row__auxiliaries__value coding__row__auxiliaries__value--clickable text-grey"
                          @click.stop="
                            setTranslateRowOverwrite(activeRow, false)
                          "
                        >
                          {{ $t("see_original") }}
                        </div>
                      </div>
                      <div
                        v-else-if="activeRow.codingColumn.translated_value"
                        class="coding__drawer__translate d-flex align-center"
                      >
                        <svg
                          class="mr-1"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.499 13.5L10.999 6.5L7.49902 13.5"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.49902 11.5H13.499"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.49902 2V3.5"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.49902 3.5H9.49902"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.49902 3.5C7.49902 5.0913 6.86688 6.61742 5.74166 7.74264C4.61645 8.86786 3.09032 9.5 1.49902 9.5"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.04395 6C4.52307 7.04397 5.29176 7.9285 6.25871 8.54854C7.22567 9.16857 8.35026 9.49806 9.49893 9.49788"
                            stroke="#708189"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div
                          class="coding__row__auxiliaries__value coding__row__auxiliaries__value--clickable text-grey"
                          @click.stop="
                            setTranslateRowOverwrite(activeRow, true)
                          "
                        >
                          {{ $t("see_translated") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="focus-mode__padding">
                  <topic-select
                    :id="id"
                    :value="topicSelectValue"
                    allow-new
                    hide-label
                    :editable="editable"
                    @update="onRowUpdate"
                    @open="$store.commit('setCodingDrawerDropdownOpen', $event)"
                    ref="topic-select"
                  />
                </div>
                <!-- <div
                  class="coding__drawer__translate d-flex align-center justify-end text-grey text-xs"
                  @click.stop="markAsReviewedAndNext"
                  v-if="!activeRow.codingColumn.was_reviewed && editable"
                >
                  <svg
                    class="mr-1" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.00025 8.00057C2.73625 8.00057 2.48125 7.89657 2.29325 7.70757L0.29325 5.70757C-0.09775 5.31657 -0.09775 4.68457 0.29325 4.29357C0.68425 3.90257 1.31625 3.90257 1.70725 4.29357L2.84525 5.43157L6.16825 0.445572C6.47425 -0.0144275 7.09425 -0.138428 7.55525 0.168572C8.01425 0.475572 8.13825 1.09557 7.83225 1.55557L3.83225 7.55557C3.66625 7.80457 3.39625 7.96657 3.09925 7.99557C3.06525 7.99857 3.03325 8.00057 3.00025 8.00057Z" fill="#00C58D" />
                  </svg>
                  {{ $t('mark_reviewed_next') }}
                </div> -->
              </div>
              <div
                class="coding__drawer__padding coding__drawer__padding--auxiliaries"
              >
                <div v-if="activeRow.otherColumns.length">
                  <div
                    v-for="(column, index) in activeRow.otherColumns"
                    class="coding__drawer__auxiliary"
                    :class="
                      index === activeRow.otherColumns.length - 1 && 'mb-0 pb-3'
                    "
                    :key="index"
                  >
                    <div>
                      <div class="coding__drawer__auxiliary__title">
                        {{ getColumnName(column.ref) }}
                      </div>
                      <div class="coding__drawer__auxiliary__value">
                        {{ column.value || "-" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-sm text-secondary" v-else>
                  No additional column data
                </div>
              </div>
            </div>
          </div>
          <div class="focus-mode__padding" v-else>
            <div class="coding__row coding__row__loading d-flex">
              <div class="w-100">
                <div class="coding__row__name" />
                <div class="d-flex coding__row__topics">
                  <div class="coding__row__topic" />
                  <div class="coding__row__topic coding__row__topic--2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="dashboard-filter-editor__actions d-flex justify-space-between"
        >
          <v-btn
            outlined
            :disabled="
              !activeRowIndex || activeRowIndex === 'empty' || !editable || loading
            "
            @click="setPreviousActiveRow"
          >
            <svg
              class="mr-1"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.7903 4.3871L6.70711 4.29289C6.34662 3.93241 5.77939 3.90468 5.3871 4.2097L5.29289 4.29289L2.29289 7.29289L2.2515 7.33685V7.33685L2.19633 7.40469V7.40469L2.12467 7.51594V7.51594L2.07123 7.62866V7.62866L2.03585 7.73401V7.73401L2.00691 7.8819V7.8819L2 8L2.00279 8.07524V8.07524L2.02024 8.20073V8.20073L2.04974 8.31214V8.31214L2.09367 8.42322V8.42322L2.146 8.52071V8.52071L2.21969 8.62545V8.62545L2.29289 8.70711L5.29289 11.7071C5.68342 12.0976 6.31658 12.0976 6.70711 11.7071C7.06759 11.3466 7.09532 10.7794 6.7903 10.3871L6.70711 10.2929L5.415 9L13 9C13.5523 9 14 8.55228 14 8C14 7.44772 13.5523 7 13 7L5.415 7L6.70711 5.70711C7.06759 5.34662 7.09532 4.77939 6.7903 4.3871L6.70711 4.29289L6.7903 4.3871Z"
                :fill="
                  !activeRowIndex || activeRowIndex === 'empty'
                    ? '#A1A1A1'
                    : '#3B3B3B'
                "
              />
            </svg>
            {{ $t("previous") }}
          </v-btn>
          <div>
            <v-btn
              outlined
              class="no-border mr-1 pl-0 pr-0"
              :disabled="
                activeRowIndex + 1 >= rowData.meta.count ||
                  activeRowIndex === 'empty' ||
                  !editable || loading
              "
              @click="setNextActiveRow"
            >
              <span class="text-sm text-grey">
                {{ $t("skip") }}
              </span>
            </v-btn>

            <v-tooltip bottom>
              <div class="d-flex align-center">
                <!-- {{ $t('mark_reviewed_short') }} -->
                <div class="keyboard-shortcut keyboard-shortcut--small mr-1">
                  <div>⌘</div>
                </div>
                +
                <div class="keyboard-shortcut keyboard-shortcut--small ml-1">
                  <div>enter</div>
                </div>
              </div>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  outlined
                  class="mr-2"
                  :disabled="
                    activeRowIndex >= rowData.meta.count ||
                      activeRowIndex === 'empty' ||
                      !editable || loading
                  "
                  @click="markAsReviewedAndNext"
                >
                  {{ $t("mark_reviewed_short") }}
                  <svg
                    class="ml-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.2097 4.3871L9.29289 4.29289C9.65338 3.93241 10.2206 3.90468 10.6129 4.2097L10.7071 4.29289L13.7071 7.29289L13.7485 7.33685V7.33685L13.8037 7.40469V7.40469L13.8753 7.51594V7.51594L13.9288 7.62866V7.62866L13.9642 7.73401V7.73401L13.9931 7.8819V7.8819L14 8V8L13.9972 8.07524V8.07524L13.9798 8.20073V8.20073L13.9503 8.31214V8.31214L13.9063 8.42322V8.42322L13.854 8.52071V8.52071L13.7803 8.62545V8.62545L13.7071 8.70711L10.7071 11.7071C10.3166 12.0976 9.68342 12.0976 9.29289 11.7071C8.93241 11.3466 8.90468 10.7794 9.2097 10.3871L9.29289 10.2929L10.585 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H10.585L9.29289 5.70711C8.93241 5.34662 8.90468 4.77939 9.2097 4.3871L9.29289 4.29289L9.2097 4.3871Z"
                      :fill="
                        activeRowIndex + 1 >= rowData.meta.count ||
                          activeRowIndex === 'empty'
                          ? '#A1A1A1'
                          : '#3B3B3B'
                      "
                    />
                  </svg>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Vuex from 'vuex'
import Sentiment from '@/components/coding/Sentiment'
import TopicSelect from '@/components/coding/TopicSelectv2'
import { mapGettersWithKey } from '@/utils/vuex.js'
import { createQueryStringFromObj } from '@/utils/filters'

export default {
  codit: true,
  name: 'FocusMode',

  components: {
    'sentiment-icon': Sentiment,
    TopicSelect
  },

  props: {
    blockedByModals: { type: Boolean, default: false },

    id: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...Vuex.mapState({
      activeRowIndex: state => state.coding.focusRowIndex,
      project: state => state.coding.project,
      allRowsSelected: state => state.coding.allRowsSelected,
      newTopicDialog: state => state.coding.newTopicDialog,
      editable: state => state.coding.editable,
      focusMode: state => state.coding.focusMode,
      refetchAfterRowChange: state => state.coding.refetchAfterRowChange
    }),

    ...Vuex.mapGetters([
      'showTranslations',
      'getRowByIndex'
    ]),

    ...mapGettersWithKey({
      rowData: 'verbatimManager/rowData',
      columnsShown: 'verbatimManager/columnsShown',
      topicID2Topic: 'metaManager/topicID2Topic'
    })(() => 'ch__new'),

    activeRow () {
      return this.getRowByIndex(this.activeRowIndex)
    },

    topicSelectValue () {
      return this.activeRow.codingColumn.topics
    },

    /**
     * If the translated row value should be shown
     */
    shouldShowTranslatedValue () {
      if (this.activeRow.translateOverwritten === true) return true
      if (this.activeRow.translateOverwritten === false) return false
      if (this.showTranslations && this.activeRow.codingColumn.translated_value) { return true }
      return false
    },

    /**
     * If the current row is loading
     * @return {bool}
     */
    loading () {
      return this.activeRow === undefined
    }
  },

  mounted () {
    window.addEventListener('keydown', this.keydown)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.keydown)
  },

  methods: {
    /**
     * Close focus mode
     */
    closeDialog () {
      this.$store.commit('setFocusMode', false)
      this.$store.commit('setFocusRowIndex', null)
      this.$store.commit('setActiveRow', null)
      this.$root.snackMsg(this.$t('focus_mode.off'))
    },

    /**
     * Overwrites the translate row settings for given row
     */
    setTranslateRowOverwrite (row, overwrite) {
      this.$store.commit('setRowTranslateOverwritten', { row, overwrite })
    },

    markAsReviewedAndNext () {
      this.$store.dispatch('setRowReviewStatus', {
        id: this.activeRow.id,
        reviewed: true
      })
      let snackMsg = this.$t('focus_mode.successfully_reviewed')
      snackMsg += ' ' + _.sample(['🎉', '🍾', '👏', '👍🏾', '🙌', '🤙🏽', '🥳', '🎊', '🪅', '🎈'])
      this.$root.snackMsg(snackMsg)
      this.setNextActiveRow()
    },

    /**
     * Get the displayed column name by ref
     */
    getColumnName (ref) {
      return _.find(this.project.columns, column => column.ref === ref).name
    },

    /**
     * Update the rows was_reviewed property
     */
    onRowUpdate () {
      this.$store.dispatch('maybeSetActiveRowAsReviewed')
      this.$store.dispatch('handleActiveRowChange')
    },

    keydown (e) {
      let prevent = false

      if (!this.focusMode || this.loading) return

      if (e.shiftKey || e.metaKey || e.ctrlKey) {
        if (e.keyCode === 39) {
          this.setNextActiveRow()
          prevent = true
        }

        if (e.keyCode === 37) {
          this.setPreviousActiveRow()
          prevent = true
        }

        // enter
        if (e.keyCode === 13) {
          this.markAsReviewedAndNext()
        }
      }

      if (this.codingDrawerDropdownOpen || this.newTopicDialog.open) return

      // If a dialog is open, let key values be default
      switch (e.keyCode) {
        case 39:
          // Arrow up
          // Focus one previous, if not already at zero
          this.setNextActiveRow()
          prevent = true
          break
        case 37:
          // Arrow down
          // Focus next one, if not at last el or focus first if not selected
          this.setPreviousActiveRow()
          prevent = true
          break

        case 27:
          this.closeDialog()
          prevent = true
          break
      }

      if (prevent) e.preventDefault()
    },

    /**
     * Get color for sentiment
     */
    getColorForSentiment (sentiment) {
      if (sentiment === 'negative') return '#FF5151'
      if (sentiment === 'positive') return '#00C58D'
      return '#708189'
    },

    /**
     * Set next row as focused
     */
    setNextActiveRow () {
      // In the focus mode our filtering actually takes care of getting the right rows
      // So after we have gone through all rows on a "page", we just reload the page
      // and start from the 0th index again (given there are any rows left matching our criteria)
      const nextRow = this.getRowByIndex(this.activeRowIndex + 1)

      if (!nextRow || this.refetchAfterRowChange) {
        // Row not loaded yet, so go to start again
        // First we must reset the rows, in order to have a loading indicator
        this.$store.commit('setRefetchAfterRowChange', false)
        this.$store.dispatch('resetRows')
        this.$store
          .dispatch('verbatimManager/loadRows', {
            id: this.id,
            scrolledPage: 1,
            queryString: createQueryStringFromObj(this.$router.currentRoute.query)
          },
          { root: true }
          )
          .then(() => {
            if (!this.rowData.meta.count) {
              this.$root.snackMsg(this.$t('focus_mode.all_reviewed'))
            }
          })
      } else {
        this.$store.commit('setFocusRowIndex', this.activeRowIndex + 1)
      }

      this.$store.commit('setActiveRow', nextRow)
    },

    /**
     * Set previous row as focused
     */
    setPreviousActiveRow () {
      if (!this.activeRowIndex) return
      this.$store.commit(
        'setActiveRow',
        this.getRowByIndex(this.activeRowIndex - 1)
      )
      this.$store.commit('setFocusRowIndex', this.activeRowIndex - 1)
    }
  }
}
</script>

<i18n locale="en" src="@/i18n/en/pages/Codingv2.json" />
<i18n locale="de" src="@/i18n/de/pages/Codingv2.json" />