import Vue from 'vue'

export default {
  namespaced: true,

  state () {
    return {
      entities: {},
      queries: {}
    }
  },

  mutations: {
    register (state, { entityId }) {
      Vue.set(state.entities, entityId, {})
    },
    update (state, { entityId, entityData }) {
      Vue.set(state.entities, entityId, entityData)
    },

    registerQuery (state, { entityId, entityData }) {
      Vue.set(state.queries, entityId, entityData)
    },
    deleteQuery (state, { entityId }) {
      Vue.delete(state.queries, entityId)
    }
  },

  actions: {
    async register ({ commit, dispatch, rootState }, { entityId, entityData }) {
      if (!rootState.coding.registerManager.entities[entityId]) return

      commit('register', { entityId })
      return dispatch('fetch', { entityId })
    },

    async registerMany ({ commit, dispatch }, { list }) {
      list.forEach(({ entityId, entityData }) => commit('register', { entityId, entityData }))
      return dispatch('fetchAll', { list })
    },

    async fetch ({ commit, rootState }, { entityId }) {
      commit('registerQuery', {
        entityId,
        entityData: {
          isLoading: true
        }
      })

      const method = rootState.coding.registerManager.entities[entityId]?.method || 'post'
      const extraQueryParams = rootState.coding.registerManager.entities[entityId]?.extraQueryParams || {}
      const fetchChartId = method === 'post' ? 'ch__' : entityId
      let { data } = await api[method](
        `/api/charts/${fetchChartId}/values`,
        {
          params: {
            ...extraQueryParams
          }
        }
      )
      commit('update', { entityId, entityData: Object.freeze(data) })
      commit('deleteQuery', { entityId })
      return data
    },

    async fetchAll ({ state, dispatch }, { list }) {
      const promises = list.map(
        ({ entityId }) => dispatch('fetch', { entityId })
      )
      return Promise.all(promises)
    },

    async update ({ commit, dispatch, rootState }, { entityId, entityData, extraQueryParams }) {
      if (!rootState.coding.registerManager.entities[entityId]) return

      return dispatch('fetch', { entityId, entityData, extraQueryParams })
    }
  },

  getters: {
    overall: (state, getters) => { return _.cloneDeep(state.entities) },

    state: (state, getters) => (id) => {
      return state.entities[id]
    },

    queryState: (state, getters) => (id) => {
      return state.queries[id]
    }
  }
}
