<template>
  <div v-if="!loading && !loadingFailed && loaded" class="coding__model h-100">
    <div
      v-if="globalScore <= 0"
      class="coding__model__padding"
    >
      <notification
        :title="$t('sviz.no_score_title')"
        :text="$t('sviz.no_score_detail')"
        :closeable="false"
      />
    </div>
    <template v-else>
      <div class="scroller">
        <div class="chart-container py-4">
          <v-chart
            ref="chart"
            class="sc-viz-chart h-100 mx-auto"
            :options="chartOptions"
            autoresize
          />
        </div>
        <div class="coding__model__help d-flex align-center justify-space-between">
          <div class="text-xs font-weight-medium">
            {{ $t('sviz.score') }}: {{ globalScoreDisplayed }}
          </div>
          <div class="d-flex align-center">
            <div class="text-xs text-grey mt-1 mr-1">
              {{ $t('sviz.description.about') }}
            </div>
            <helptip position="top" :width="400">
              <div>
                <span
                  v-html="$t('sviz.description.chart_intro', { perc: MIN_CNT_FRACTION_TO_PLOT * 100 })"
                />
                <ul class="mt-2 mb-2">
                  <li v-for="(p, idx) in $ta('sviz.description.chart_props')" :key="idx" v-html="p" />
                </ul>
              </div>
              <div v-html="$t('sviz.description.table')" />
            </helptip>
          </div>
        </div>
        <div class="coding__model__table">
          <div class="coding__model__table__row coding__model__table__headers">
            <div class="coding__model__table__row__cell coding__model__table__row__cell--cat">
              {{ this.$t('topics.category') }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--nme">
              {{ this.$t('topics.label') }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--scr">
              {{ this.$t('sviz.score') }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--cnt">
              {{ this.$t('sviz.count') }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--inf">
              {{ this.$t('sviz.influence') }}
            </div>
          </div>
          <div
            v-for="(score, idx) in scoresValid"
            :key="idx"
            class="coding__model__table__row"
          >
            <div class="coding__model__table__row__cell coding__model__table__row__cell--cat d-flex align-center">
              <div
                class="coding__model__table__row__cell__indicator"
                :style="`background-color: ${catColors[topicID2Topic[score.id].category].strong};`"
              />
              {{ score.category }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--nme">
              {{ score.label }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--scr">
              {{ score.results.score ? (score.results.score * 100).toFixed(0) : '-' }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--cnt">
              {{ score.results.counts.overall }}
            </div>
            <div class="coding__model__table__row__cell coding__model__table__row__cell--inf">
              {{ score.results.influence_on_total_score ? score.results.influence_on_total_score.toFixed(1) : '-' }}
            </div>
          </div>
        </div>
      </div>
      <div class="coding__column__options coding__column__options--bottom d-flex align-center justify-space-between pl-0">
        <v-menu
          :close-on-content-click="false"
          :rounded="'lg'"
          offset-y
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="filters__pagination__select__button ml-0"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              hide-details
              solo
              small
              dense
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.61295 2.2097C8.22066 1.90468 7.65343 1.93241 7.29294 2.29289L4.29294 5.29289L4.21683 5.37747C3.70262 6.01449 4.14788 7 5.00005 7L11.0001 7L11.1137 6.99402C11.9277 6.90718 12.3097 5.89547 11.7072 5.29289L8.70716 2.29289L8.61295 2.2097ZM11.7072 10.7071C12.3371 10.0771 11.891 9 11.0001 9H5.00005C4.10915 9 3.66298 10.0771 4.29294 10.7071L7.29294 13.7071C7.68347 14.0976 8.31663 14.0976 8.70716 13.7071L11.7072 10.7071Z" fill="#708189" />
              </svg>
              {{ selectedSortingFilterLabel }}
            </v-btn>
          </template>

          <v-list class="filters__list filters__list--sort">
            <div class="filters__list__label text-label">
              {{ $t('sort_by') }}
            </div>
            <div
              v-for="item in sortOptions"
              :key="item.value"
              class="filters__list__item"
              :class="item.value === sortBy && 'filters__list__item--active'"
              @click="sortBy = item.value"
            >
              {{ item.label }}
            </div>
          </v-list>
        </v-menu>
      </div>
    </template>
  </div>
  <div v-else class="coding__model--loading">
    <div class="coding__model--loading__skeleton" />
  </div>
</template>

<script>

import Vuex from 'vuex'
import EChart from '@/components/EChart'

import ResizeObserver from 'resize-observer-polyfill'

import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markPoint'
import 'echarts/lib/component/dataset'
import 'echarts/lib/component/graphic'

import { getterWithKeyDeconstructed } from '@/utils/vuex.js'

let pattern = require('patternomaly')

const MIN_CNT_FRACTION_TO_PLOT = 0.025
const CHART_HEIGHT = 0.8

let oldTopics

export default {
  codit: true,
  name: 'ModelScoreVisualizer',

  components: {
    'v-chart': EChart
  },

  data () {
    return {
      destroyed: false,
      MIN_CNT_FRACTION_TO_PLOT,
      resizeObserver: null,
      containerHeight: 0,
      loading: true,
      failed: false,
      loadingFailed: false,
      data: null,
      prevColor: null,
      sortBy: 'category',

      fetchDataDebounced: _.debounce(this.fetchData, 10000)
    }
  },

  computed: {
    selectedSortingFilterLabel () {
      return _.find(this.sortOptions, option => option.value === this.sortBy).label
    },

    /**
     * The overall score of the question
     * @return {Number}
     */
    globalScore () {
      return this.data.results_global.score
    },

    globalScoreDisplayed () {
      return (this.globalScore * 100).toFixed(0)
    },

    /**
     * Sort by options
     */
    sortOptions () {
      return [
        { label: this.$t('topics.category'), value: 'category' },
        { label: this.$t('topics.label'), value: 'label' },
        { label: this.$t('sviz.score'), value: 'results.score' },
        { label: this.$t('sviz.influence'), value: 'results.influence_on_total_score' },
        { label: this.$t('sviz.count'), value: 'results.counts.overall' }
      ]
    },

    /**
     * Create score object for every code that has a score
     * @return {Array}
     */
    scoresChart () {
      return this.scoresValid.filter(({ results }) => results.score !== null)
    },

    /**
     * All scores, which refer to a valid code, sorted by this.sortBy value
     */
    scoresValid () {
      return _.orderBy(_.filter(this.data.results_per_topic, t => t.id in this.topicID2Topic), result => this.sortBy === 'results.counts.overall' ? _.get(result, this.sortBy, 0) : [_.get(result, this.sortBy, 0)], [(this.sortBy === 'category' || this.sortBy === 'label') ? 'asc' : 'desc'])
    },

    /**
     * Questions metadata
     * @return {Object}
     */
    questionMetadata () {
      return _.find(this.project.columns, column => column.ref === this.$route.params.ref)?.metadata
    },

    /**
     * The final options object for the pie chart
     * @return {Object}
     */
    chartOptions () {
      return {
        series: this.chartSeries,
        tooltip: {
          trigger: 'item',
          formatter: this.tooltipFormatter
        }
      }
    },

    /**
     * The markPoint object, a radial line around the pie chart
     * which indicates the global score of the question
     * @return {Object}
     */
    averageRadialLine () {
      return {
        symbol: 'circle',
        symbolSize: this.globalScore * this.containerHeight * CHART_HEIGHT,
        silent: true,
        tooltip: {
          show: true,
          formatter: () => `Average score ${Math.round(this.globalScore * 100)}`
        },
        itemStyle: {
          color: 'rgba(255, 255, 255, 0)',
          borderColor: '#F00'
        },
        data: [{
          x: '50%',
          y: '50%'
        }]
      }
    },

    /**
     * The series for the pie chart
     * Every code in the `scores` array has two entries:
     * * An opaque one for the score, with tooltip & label *disabled*
     * * A lighter one (overlaying) for the score_remaining, with tooltip & labe *enabled*
     *
     * The series makes use of the hacked echarts pie series, which makes the slices' radius
     * be determined by the second entry in the `value` property. In standard echarts, this
     * would simply be ignored and the size of the radius be determined by the first entry as well
     * @return {Array}
     */
    chartSeries () {
      return [
        // The score data
        {
          type: 'pie',
          radius: ['0%', `${CHART_HEIGHT * 100}%`],
          roseType: 'radius',
          label: { show: false },
          labelLine: { show: false },
          emphasis: { },
          tooltip: { show: false },
          data: _.map(this.scoresChart, s => (
            {
              value: [s.results.counts.overall, Math.max(s.results.score * 100, 20)],
              score: s.results.score * 100,
              count: s.results.counts.overall,
              name: s.label,
              category: s.category,
              itemStyle: {
                color: this.getItemColor(this.catColors[this.topicID2Topic[s.id].category].medium)
              }
            }
          ))
        },
        {
          type: 'pie',
          radius: ['0%', `${CHART_HEIGHT * 100}%`],
          roseType: 'radius',
          label: {
            show: true
          },
          emphasis: { labelLine: { show: true, lineStyle: { width: 3 } } },
          // Overlay over opaque, first series
          data: _.map(this.scoresChart, s => (
            {
              value: [s.results.counts.overall, Math.max(s.results.score_remaining * 100, 20)],
              score: s.results.score_remaining * 100,
              count: s.results.counts.overall,
              name: s.label,
              category: s.category,
              itemStyle: { color: this.getItemColor(this.catColors[this.topicID2Topic[s.id].category].medium) },
              label: {
                color: this.catColors[this.topicID2Topic[s.id].category].medium,
                // alignTo: 'labelLine',
                overflow: 'break',
                minMargin: 5,
                edgeDistance: 12,
                lineHeight: 15
              },
              labelLine: {
                // length: 0,
                // length2: 0,
                maxSurfaceAngle: 80,
                color: this.catColors[this.topicID2Topic[s.id].category].medium
              }
            }
          )),
          markPoint: this.averageRadialLine
        }
      ]
    },

    ...Vuex.mapState({
      user: state => state.user,
      question: state => state.coding.question,
      topics: state => state.coding.topics,
      stats: state => state.coding.stats,
      project: state => state.coding.project,
      isInitialized: state => state.coding.isInitialized,
      inferenceUpdater: state => state.coding.inferenceUpdater
    }),

    ...Vuex.mapGetters([
      'topicID2Topic',
      'catColors'
    ]),

    ...getterWithKeyDeconstructed('verbatimManager/state')(() => 'ch__new')(['loaded'])
  },

  watch: {
    isInitialized (val, oldVal) {
      if (val && !oldVal) {
        this.fetchData()
      }
    },

    inferenceUpdater (val, oldVal) {
      if (val && val !== oldVal) {
        this.fetchData()
      }
    },

    /**
     * If the topics change, we need to update the chart to show the updated topics
     */
    topics (val, oldVal) {
      if (!_.isEqual(val, oldTopics) && oldTopics !== undefined) this.fetchDataDebounced()
      oldTopics = _.cloneDeep(val)
    }
  },

  mounted () {
    this.checkIfResizeObserverInitiated()
  },

  created () {
    this.fetchData()
  },

  updated () {
    this.checkIfResizeObserverInitiated()
  },

  beforeDestroy () {
    this.destroyed = true
    if (this.resizeObserver) this.resizeObserver.disconnect()
  },

  methods: {
    fetchData () {
      if (this.destroyed) return

      const questionID = this.questionMetadata?.question_id
      if (!questionID) return

      // Fetch model score values
      return api.post(`/api/charts/ch__/values`, {
        'config': {
          'title': this.project.title
        },
        'datasets': [{
          'question': questionID,
          'filters': [],
          'settings': {}
        }],
        'type': 'MODEL'
      })
        .then((res) => {
          this.loading = false
          this.loadingFailed = false
          this.data = res.data

          this.$store.commit('setModelScore', (res.data.results_global.score && (res.data.results_global.score * 100).toFixed(0)))
        })
        .catch((err) => {
          this.$maybeRaiseAPIPromiseErr(err)
          this.loading = false
          this.loadingFailed = true
        })
    },

    /**
     * Check if the resize observer is initialized and if yes, set it to update the
     * height of the chart element
     */
    checkIfResizeObserverInitiated () {
      if (!this.resizeObserver && this.$refs.chart && this.$refs.chart.$el) {
        // Observer the height of the chart div, and save it
        // We need this to correctly scale the averageRadialLine object
        this.resizeObserver = new ResizeObserver(entries => {
          entries.forEach(entry => {
            this.containerHeight = entry.contentRect.height
          })
        })
        this.resizeObserver.observe(this.$refs.chart.$el)
      }
    },

    /**
     * If the color is the same as the previous results, return a pattern
     */
    getItemColor (color, isLast = false) {
      let setColor = color

      if (this.prevColor && this.prevColor === color) {
        setColor = pattern.draw('circle', color, undefined, 4)
      }

      this.prevColor = setColor
      return setColor
    },

    /**
     * Report code category, label, score and count when hovering a slice
     * @param  {Object} el The hovered el
     * @return {String}    The tooltip content
     */
    tooltipFormatter (el) {
      return `${this.$escapeHtml(el.data.category)}: ${this.$escapeHtml(el.data.name)}<br>Score: ${Math.round(el.data.score)} | Count: ${el.data.count}`
    }
  }
}

</script>

<style lang="scss" scoped>
  .chart-container {
    height: 250px;

    @media (min-height: 600px) {
      height: 350px;
    }
  }

  .scroller {
    height: 100%;
    overflow: auto;
    padding-bottom: 55px;

    @media (min-height: 820px) {
      overflow: hidden;
    }
  }

  .coding__model__table{
    @media (min-height: 820px) {
      overflow: auto;
      height: calc(100% - 380px);
    }
  }
</style>

<i18n locale='en' src='@/i18n/en/components/coding/ModelScoreVisualizer.json' />
<i18n locale='de' src='@/i18n/de/components/coding/ModelScoreVisualizer.json' />