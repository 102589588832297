import { DOWNLOAD_FIELD_ORDER, DOWNLOAD_CODES_INTERLEAVED } from '@/settings/overridable'
import { generateProvide } from '@/components/customUi/mixins/provideInject'

const CODES_ORDER_NO_LEGACY_CODES_FORMAT_BINARY = ['label', 'category']
const DOWNLOAD_FIELDS = ['auxiliary', 'text', 'translated_text', 'source_language', 'codes', 'additional']
const CODE_FIELDS = ['id', 'name', 'category', 'sentiment', 'sentiment_label', 'label']

export default {
  data () {
    return {
      // data for form, to be aggregated and modifyed before sending
      formData: {
        kind: 0,
        format: 'xlsx',
        codes_format: 'binary',

        codes_order: CODES_ORDER_NO_LEGACY_CODES_FORMAT_BINARY,
        field_order: _.cloneDeep(DOWNLOAD_FIELD_ORDER),

        legacy_format: false,
        consolidate_categories: false,
        codes_interleaved: DOWNLOAD_CODES_INTERLEAVED,
        add_category_columns: false
      },

      // data sources, to render as select/switcher options
      sources: {
        kindOptions: [
          { text: this.$t('download_dialog.form_items.kind.rows_label'), value: 'rows' },
          { text: this.$t('download_dialog.form_items.kind.summary_label'), value: 'topics' }
        ],
        settingsOptions: [
          { text: this.$t('download_dialog.form_items.options.default_label'), value: 0 },
          { text: this.$t('download_dialog.form_items.options.advanced_label'), value: 1 }
        ],
        formatOptions: [
          { text: this.$t('download_dialog.form_items.filetype.excel_label'), advanced_type: false, value: 'xlsx' },
          { text: this.$t('download_dialog.form_items.filetype.csv_label'), advanced_type: false, value: 'csv' },
          { text: this.$t('download_dialog.form_items.filetype.spss_label'), advanced_type: true, value: 'sav' }
        ],
        codesFormatOptions: [
          { text: this.$t('download_dialog.form_items.format.inorder_label'), value: 'inorder' },
          { text: this.$t('download_dialog.form_items.format.binary_label'), value: 'binary' }
        ],
        codeFields: CODE_FIELDS,
        downloadFields: DOWNLOAD_FIELDS
      },

      formState: {
        settings: undefined
      }
    }
  },

  provide () {
    return generateProvide.call(this, {
      isAdditionalFieldEnabled: 'formState.settings'
    })
  }
}