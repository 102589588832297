import * as Sentry from '@sentry/browser'
import { TOPIC_TEMPLATE } from '@/settings/constants'
import { get3ShadesForColor } from '@/utils/colorUtils'
import Vue from 'vue'

function recursiveCompareAttributesNamesAndTypesOfObjToTemplate (obj, template) {
  let keys = _.uniq(_.keys(obj).concat(_.keys(template)))
  keys.forEach(key => {
    // eslint-disable-next-line no-useless-return
    if (!(key in obj)) throw Error(`Expected key ${key}`)
    if (!(key in template)) throw Error(`Unexpected key ${key}`)
    if (typeof obj[key] !== typeof template[key]) throw Error(`Expected type ${typeof template[key]} for key ${key}, got ${typeof obj[key]}`)
    else if (_.isObject(template[key]) && !_.isArray(template[key])) {
      recursiveCompareAttributesNamesAndTypesOfObjToTemplate(obj[key], template[key])
    }
  })
}

function validateTopic (topic) {
  try {
    recursiveCompareAttributesNamesAndTypesOfObjToTemplate(topic, TOPIC_TEMPLATE)
  } catch (err) {
    throw Error(`Validation failed for topic ${topic.id} (${topic.label}): ${err}`)
  }
}

export default {
  state () {
    return {
      topics: []
    }
  },

  mutations: {
    /** =========== Topic Modifiers =========== **/

    /**
     * Set the topicbook
     * @param  {Object} state The state object
     * @param  {Array}  topics The array of topics
     */
    setTopics: (state, topics) => {
      // Make sure topics of a category are displayed together
      if (!Array.isArray(topics)) throw Error('Expected topics array.')
      topics = _(topics).groupBy('category').flatMap().value()
      // setTimeout(() => { topics.forEach(validateTopic) })
      // todo: Reactivate again when we can figure out where
      // we have editable topics (with keywords, description etc.) and when not
      Vue.set(state, 'topics', topics)
    },

    /**
     * Add topic to the topics list.
     * If position is given, add new topic at that index of topics array. Otherwise add it to the end.
     * @param  {Object} state The state object
     * @param  {Object}       Topic to insert and position at which it should be inserted
     */
    addTopic: (state, { topic, position }) => {
      state.topics.splice(position > -1 ? position : state.topics.length, 0, topic)
    },

    /**
     * Modify a topic
     * @param  {Object} state                 The state object
     * @param  {Object} options.topic         The topic object to modify
     * @param  {Object} options.newAttributes The new attributes to add to topic
     * @return {[type]}                       [description]
     */
    modifyTopic: (state, { topic, newAttributes }) => {
      if (state.topics.indexOf(topic) === -1) {
        Sentry.captureException(new Error('Could not find topic to modify in topicbook'), { topicbook: state.topics, topic })
      }

      const recuriveAttributeOverride = (existingObj, newAttributes) => {
        _.each(newAttributes, (val, key) => {
          if (!(key in existingObj)) {
            let err = new Error(`Attempted to set non-existing attribute ${key} on topic`)
            Sentry.captureException(err, { topic, newAttributes })
            console.error(err)
          } else if (_.isObject(val) && !_.isArray(val)) {
            recuriveAttributeOverride(existingObj[key], newAttributes[key])
          } else {
            Vue.set(existingObj, key, _.cloneDeep(newAttributes[key]))
          }
        })
      }

      recuriveAttributeOverride(topic, newAttributes)
    },

    /**
     * Delete a topic by its index
     * @param  {Object} state                 The state object
     * @param  {Number} options.topicIdx       The index of the topic to delete
     */
    deleteTopic: (state, { topicIdx }) => state.topics.splice(topicIdx, 1)
  },

  getters: {
    /**
     * Mapping of categories to their list of topics
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Object}                       {category:String->Array(topics)}
     */
    topicsByCat: (state, getters) => { return _.groupBy(state.topics, 'category') },

    // Make sure the codes are sorted by category, after first appearance
    topicsSorted: (state, getters) => _.flatMap(getters.topicsByCat),

    /**
     * Mapping of internal topic IDs to the topic object
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Object}                       {id:String->topic:Object}
     */
    topicID2Topic: (state, getters) => {
      let mapping = {}
      state.topics.forEach((c, idx) => { mapping[c.id] = c })
      return mapping
    },

    /**
     * Mapping of internal topic IDs to the topic index
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Object}                       {id:String->index:Number}
     */
    topicID2Idx: (state, getters) => {
      let mapping = {}
      state.topics.forEach((c, idx) => { mapping[c.id] = idx })
      return mapping
    },

    /**
     * Mapping of internal topic IDs to the category index
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Object}                       {id:String->categoryIndex:Number}
     */
    topicID2CatIdx: (state, getters) => {
      let mapping = {}
      state.topics.forEach(c => { mapping[c.id] = getters.topicCats2CatIdx[c.category] })
      return mapping
    },

    /**
     * Mapping of internal topic IDs to the category name
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Object}                       {id:String->category:String}
     */
    topicID2Cat: (state, getters) => {
      let mapping = {}
      state.topics.forEach(c => { mapping[c.id] = c.category })
      return mapping
    },

    // Make sure the topics are sorted by category, after first appearance
    // topicsSorted: (state, getters) => _.flatMap(getters.topicsByCat),

    /**
     * List of topic categories
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Array}                        Array of category names
     */
    topicCats: (state, getters) => { return _.keys(getters.topicsByCat) },

    /**
     * Mapping of topic categories to the category index
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Object}                       {category:String->index:Number}
     */
    topicCats2CatIdx: (state, getters) => {
      let mapping = {}
      getters.topicCats.forEach((cat, idx) => { mapping[cat] = idx })
      return mapping
    },

    // topicID2IdxSorted: (state, getters) => {
    //   // mapping of topic ids to topic indexes (in topics)
    //   let idToIdx = {}
    //   getters.topicsSorted.forEach((topic, topicIdx) => { idToIdx[topic.id] = topicIdx })
    //   return idToIdx
    // },

    // topicCat2CatIdx: (state, getters) => {
    //   let mapping = {}
    //   getters.topicCats.forEach((c, idx) => { mapping[c] = idx })
    //   return mapping
    // },

    /**
     * Mapping of topic categories to their respective colors
     * Returns an object with three shades for each color object
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Object}                       {category:String->colors:Object}
     */
    catColors: (state, getters) => {
      let mapping = {}
      getters.topicCats.forEach(cat => {
        mapping[cat] = get3ShadesForColor(getters.topicsByCat[cat][0].color)
      })
      return mapping
    },

    /**
     * Getter function which returns the topic object from a given ID
     * @param  {Object} state                 The state object
     * @param  {Object} getters               The getters object
     * @return {Function}                     A function returning the topic from a given ID
     */
    topicByID: (state, getters) => id => {
      if (!(id in getters.topicID2Topic)) throw new Error(`Invalid topic ID ${id} provided`)
      else return getters.topicID2Topic[id]
    }
  },

  actions: {
    /** =========== Topic Actions =========== **/
    /**
     * Add a new topic to the topicbook
     * @param  {Object} topic             Object with all properties a topic needs to have
     */
    addTopic ({ commit, state, getters, dispatch }, { topic, position }) {
      validateTopic(topic)
      commit('addTopic', { topic, position })
    },

    /**
     * Modify some attributes of a topic
     * @param  {Number} options.topicID        The internal id of the topic to modify
     * @param  {Object} options.newAttributes A nested object with the changed values. Only keys present in this object will be changed on the topic.
     * @param  {Number} options.position      The position in the topicbook where to insert the new topic [optional]
     */
    modifyTopic ({ commit, state, getters, dispatch }, { topicID, newAttributes, position }) {
      if (position !== undefined) {
        let topic = getters.topicByID(topicID)
        commit('deleteTopic', { topicIdx: getters.topicID2Idx[topicID] })
        commit('addTopic', { topic, position })
      }

      commit('modifyTopic', { topic: getters.topicByID(topicID), newAttributes })
    },

    /**
     * Remove a topic from the topicbook
     * @param  {[type]} the topicID to remove
     */
    deleteTopic ({ commit, state, getters, dispatch }, topicID) {
      let topicIdx = getters.topicID2Idx[topicID]
      commit('deleteTopic', { topicIdx })
    },

    /**
     * Merge two topics
     * @param  {Number} options.parentID       The id of the parent topic
     * @param  {String} options.parentAttitude The attitude to merge the parent topic into, or null
     * @param  {Array}  options.childID        The child topic to merge into the parent
     */
    mergeTopics ({ commit, state, getters, dispatch }, { parentID, childID, childTargetSentiment, parentTargetSentiment, newProps }) {
      return api.post(`/api/ui/projects/topics/merge-adhoc`, {
        given_topics: state.topics,
        parent_topic_id: parentID,
        child_topic_id: childID,
        child_target_sentiment: childTargetSentiment,
        parent_target_sentiment: parentTargetSentiment,
        parent_topic_new_props: newProps
      })
        .then(res => {
          commit('setTopics', res.data)

          if (
            !this._actions['metaManager/manualUpdate']
          ) {
            return
          }
          dispatch('metaManager/manualUpdate', { entityId: state.id, data: res.data }, { root: true })
        })
        .catch(err => {
          dispatch('maybeRaiseAPIPromiseErr', err)
          throw err
        })
    }
  }
}