import {
  /*
  * use unique names of charts
  */
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_TREEMAP,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_DYNAMIC_BAR,
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_DRIVER_SCATTER,
  SENTIMENT_RANGE,
  MAX_DASHBOARD_ITEMS_COUNT
} from '@/settings/constants'

function generateSentimentRangeTuned () {
  return [
    'any',
    ...SENTIMENT_RANGE
  ].map((item, index) => {
    let switcherItemData = {
      value: item,
      label: this.$i18n.t(`topics.sentiment_${item}`).label,
      icon: {
        image: require(`@/assets/icons/sentiment_${item}.svg`),
        label: this.$i18n.t(`sentiment_toggle.${item}`)
      }
    }

    return switcherItemData
  })
}

const CONTROLS_SCAFFOLD = {
  sentimentShown: {},
  showTopNTopics: {},
  groupByColumnValue: {},
  controllerAccordionItemShown: {},
  controllerAccordionFilterItemShown: {},
  verbatimSummary: {}
}

export default {
  data () {
    return {
      SENTIMENT_RANGE: generateSentimentRangeTuned.call(this),

      MAX_DASHBOARD_ITEMS_COUNT,

      controls: _.cloneDeep(CONTROLS_SCAFFOLD)
    }
  },

  computed: {
    dashboardElementsCount () {
      return this.dashboardElements.length
    },

    isVerbatimSummaryEnabled () {
      return this.$root.posthogFlagEnabled('openai_enabled')
    }
  },

  watch: {
    '$route.params.id' () {
      this.controls = _.cloneDeep(CONTROLS_SCAFFOLD)
    },

    dashboardElements: {
      deep: true,
      handler () {
        this.dashboardElements.forEach(item => {
          if (
            typeof this.controls.sentimentShown[item.id] === 'undefined'
          ) {
            this.$set(this.controls.sentimentShown, item.id, item.chart.config?.controls?.sentimentShown)
          }
          if (
            typeof this.controls.showTopNTopics[item.id] === 'undefined'
          ) {
            this.$set(this.controls.showTopNTopics, item.id, item.chart.config?.controls?.showTopNTopics)
          }
          if (
            typeof this.controls.groupByColumnValue[item.id] === 'undefined'
          ) {
            this.$set(this.controls.groupByColumnValue, item.id, 0)
          }

          if (
            item.chart.typeSelection === CHART_UNIQUE_DYNAMIC_BAR
          ) {
            this.$set(this.controls.controllerAccordionItemShown, item.id, 0)
          } else {
            this.$set(this.controls.controllerAccordionItemShown, item.id, null)
          }
          this.$set(this.controls.controllerAccordionFilterItemShown, item.id, null)

          if (
            item.chart.type === 'P_VRB' &&
            typeof this.controls.verbatimSummary[item.id] === 'undefined'
          ) {
            this.$set(this.controls.verbatimSummary, item.id, item.chart.config?.controls?.verbatimSummary || false)
          }
        })
      }
    },

    dashboardElementsCount (to, from) {
      if (
        !this.status.loading &&
        this.dashboardElements.length >= MAX_DASHBOARD_ITEMS_COUNT &&
        from < MAX_DASHBOARD_ITEMS_COUNT
      ) {
        this.$root.snackMsg(this.$t('warning_limit', { limit: MAX_DASHBOARD_ITEMS_COUNT }))
      }
    }
  },

  methods: {
    isSentimentToggleShown (chart, dashboardItemId) {
      return [
        CHART_UNIQUE_BAR,
        CHART_UNIQUE_DATE_LINE_PIE,
        CHART_UNIQUE_TREEMAP,
        CHART_UNIQUE_GRAPH,
        CHART_UNIQUE_DYNAMIC_BAR
      ].indexOf(chart.typeSelection) > -1 &&
      this.metaManager[chart.id].topics_complete_union?.some(
        ({ sentiment_enabled }) => !!sentiment_enabled // eslint-disable-line
      ) &&
      this.controls?.sentimentShown[dashboardItemId]
    },

    isMinimimTopicValueStepperShown (chart, dashboardItemId) {
      return [
        CHART_UNIQUE_BAR,
        CHART_UNIQUE_DATE_LINE_PIE,
        CHART_UNIQUE_TREEMAP,
        CHART_UNIQUE_GRAPH,
        CHART_UNIQUE_SENTIMENT_BAR,
        CHART_UNIQUE_DRIVER_SCATTER
      ].indexOf(chart.typeSelection) > -1
    },

    isGroupByColumnValueSelectShown (chart, dashboardItemId) {
      return !!([
        CHART_UNIQUE_DYNAMIC_BAR
      ].indexOf(chart.typeSelection) > -1)
    },

    getDYBARValues (id) {
      return this.valueManager[id]?.ticks
        ?.map((item, index) => ({
          name: item,
          value: index
        }))
    },

    getAvailableTopTopicsNumber (id) {
      const topicsNumber = this.metaManager[id].topics_complete_union.length

      if (
        topicsNumber > 99
      ) {
        return 99
      }
      return topicsNumber
    },

    toggleSummaryDisplaying (id) {
      this.controls.verbatimSummary[id] = !this.controls.verbatimSummary[id]

      if (this.user.isAnonymous || !this.$hasPermission('dashboards_edit')) return

      let el = _.find(this.dashboardElements, item => item.id === id)
      this.patchItem({
        ..._.cloneDeep(el.chart),
        config: {
          ..._.cloneDeep(el.chart.config),
          controls: {
            verbatimSummary: this.controls.verbatimSummary[id]
          }
        }
      })
    },

    /*
    * Remove all applied filters for a verbatim
    * in order to avoid displaying no results.
    * Triggered deliberately by user.
    */
    async clearVerbatimFilters (entityId) {
      let entityData = _.cloneDeep(this.configManager[entityId])
      entityData.config.filters = []
      await this.patchItem(entityData)
      await this.$store.dispatch(
        `registerManager/update`,
        {
          entityId,
          entityData,
          settings: {
            method: 'get'
          },
          extraQueryParams: this.extraQueryParams
        }
      )
      const elementIndex = this.dashboardElements.findIndex(item => item.chart.id === entityId)
      this.$set(this.dashboardElements[elementIndex], 'chart', entityData)
    }
  }
}