// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import router from './router.js'
import i18n from './i18n'

import vuetify from './plugins/vuetify'
import VueLocalStorage from 'vue-ls'

import { defineVueFilters } from './plugins/filters.js'
import { defineVueDirectives } from './plugins/directives.js'
import { defineGlobalComponents } from './plugins/global-components.js'
import { defineGlobalVariables } from './plugins/global-variables.js'
import { initTracking } from './utils/tracking'
import { useSentry } from '@/plugins/sentry'

import '@/plugins/moment'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css' // Ensure using css-loader
import 'css/tooltips.scss'

import AppPage from './AppPage'

Vue.use(VueLocalStorage)

defineGlobalVariables()
defineGlobalComponents()
defineVueFilters()
defineVueDirectives()
useSentry()
initTracking()

Vue.config.devtools = process.env.VUE_APP_CAPLENA_ENV !== 'prod'
// Vue.config.performance = process.env.VUE_APP_CAPLENA_ENV !== 'prod'
Vue.config.productionTip = false

// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
// Allow `<zapier-full-experience>` custom element
// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
Vue.config.ignoredElements = ["zapier-full-experience"]; // eslint-disable-line

let AppComponent = Vue.component('app', AppPage)
const app = new AppComponent({
  el: '#mountpoint',
  vuetify,
  router,
  i18n
})

window.app = app
