<template>
  <div
    v-if="visible"
  >
    <v-overlay
      :value="visible"
      color="rgba(5, 32, 46, 0.5)"
    />
    <v-dialog
      :value="visible"
      @input="() => closeable && close()"
      @keydown.esc="() => closeable && close()"
      hide-overlay
      eager
      :persistent="!closeable || persistent"
      :width="maxWidth"
      :content-class="`dashboard-filter-editor ${className}`"
    >
      <div class="dashboard-filter-editor-container d-flex flex-column h-100">
        <div class="dashboard-filter-editor__title w-100 d-flex align-center justify-space-between">
          <span v-html="title" />
          <v-icon
            v-if="closeable"
            class="hover"
            @click.stop="close"
            :size="20"
          >
            mdi-close
          </v-icon>
        </div>
        <div class="dashboard-filter-editor__content pt-4">
          <slot />
        </div>
        <div class="dashboard-filter-editor__actions d-flex justify-space-between">
          <div />
          <div>
            <v-btn
              outlined
              @click.stop="close"
              class="mr-2"
            >
              {{ $t('close') }}
            </v-btn>
            <v-btn
              v-if="action.length"
              :color="actionType"
              :disabled="disabled"
              :loading="loading"
              :class="disabled && 'disabled'"
              @click.stop="actionClick"
            >
              {{ action }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

export default {
  codit: true,
  name: 'Modal',
  props: {
    visible: { type: Boolean, default: false },
    closeable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    maxWidth: { type: String, default: '100%' },
    className: { type: String, default: '' },
    title: { type: String, default: '' },
    action: { type: String, default: '' },
    actionClick: { type: Function, default: () => {} },
    actionType: { type: String, default: 'primary' },
    close: { type: Function, default: () => {} },
    persistent: { type: Boolean, default: false }
  },

  data () {
    return {

    }
  },

  computed: {
  }
}

</script>

<style lang="scss">
// TODO Aleks: rename and get rid of these styles from scss files, as well as replace all existing modals
.dashboard-filter-editor {
  overflow: hidden;
  position: relative;
  // max-width: 1220px !important;

  .dashboard-filter-editor-container {
    max-height: 85vh;
  }

  &--verbatim-browser {
    max-width: 750px !important;
  }

  &.v-dialog {
    background: white;
    box-shadow: 0px 8px 32px rgba(14, 93, 136, 0.05);
    border-radius: 10px;
  }

  &__title {
    font-size: 16px;
    padding: 16px;
    width: 100%;
  }

  &__close {
    position: absolute !important;
    top: 14px !important;
    right: 14px !important;
    z-index: 10 !important;
  }

  &__content {
    width: 100%;
    border-top: 1px solid #F1F1F1;
    border-bottom: 1px solid #F1F1F1;
    padding: 20px 16px;
    max-height: 70vh !important;
    overflow-y: auto;
    overflow-x: hidden;

    p {
      margin: 0 !important;
    }

    .v-input--selection-controls {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }

    .no-op-wrapper {
      padding: 20px 16px;
      border: none;
    }

    fieldset {
      border-color: #DBE2E5 !important;
    }

    .v-input.invert {
      // border-top: 1px solid #F1F1F1;
      padding-top: 16px !important;
      // margin-top: 20px !important;
    }
  }

  &__actions {
    padding: 16px;
    background: white;
    // padding-left: 0;

    &__remove {
      .v-btn__content {
        color: $red-color;
      }
    }

    .v-btn__content {
      text-transform: none !important;
      letter-spacing: normal !important;
    }

    .v-btn--outlined {
      border-color: #DAEAF2;
    }
  }
}
</style>
