<template>
  <div
    class="coding__row__topic coding__row__topic--abstract"
    :style="{
      'border-top-color': color.strong || color,
      'border-left-color': color.strong || color,
      'border-right-color': color.strong || color,
      'border-bottom-color': color.strong || color,
      'box-shadow': `${color.softSolid || color} 3px 3px 0px 0`,
      'padding-left': deletable || fromDrawer ? '16px' : '8px'
    }"
    :class="{
      'coding__row__topic--highlighted': isHighlighted,
      'coding__row__topic--collapsed': collapsed
    }"
  >
    <div class="d-flex align-center justify-space-between w-100">
      <div
        class="d-flex align-center w-100 coding__row__topic__info"
      >
        <div :style="{ 'order': deletable ? 1 : 0 }">
          <div
            class="coding__row__topic__category"
            :style="`color: ${color.strong || color };`"
          >
            {{ category }}
          </div>
          <div class="coding__row__topic__label">
            {{ label }}
          </div>
        </div>
        <div
          v-if="sentimentEnabled"
          :style="{ 'order': deletable ? 1 : 0 }"
          :class="deletable && 'mr-2'"
          class="d-flex align-center"
        >
          <v-menu
            v-if="deletable"
            v-model="sentimentMenu"
            :close-on-content-click="true"
            :rounded="'lg'"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="d-flex align-center coding__row__topic__sentiment-select"
              >
                <sentiment-icon :sentiment="sentiment || 'neutral'" />
                <v-icon :size="19">
                  mdi-chevron-down
                </v-icon>
              </div>
            </template>

            <v-list class="filters__list filters__list--sort filters__list--sentiment">
              <div class="filters__list__label">
                {{ $t('answer_fields.sentiment') }}
              </div>
              <div
                v-for="item in SENTIMENT_OPTIONS"
                :key="item"
                @click.stop="$emit('change-sentiment', id, item, false)"
                class="filters__list__item"
              >
                <sentiment-icon :sentiment="item" />
                <div class="text-sm ml-2">
                  {{ item }}
                </div>
              </div>
            </v-list>
          </v-menu>
          <sentiment-icon
            v-else
            :sentiment="sentiment || 'neutral'"
          />
        </div>
      </div>
      <v-btn
        v-if="deletable"
        @click.stop.prevent="$emit('delete')"
        icon
        small
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C10.5523 2 11 2.44772 11 3V4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H13V11C13 12.6569 11.8807 14 10.5 14H5.5C4.11929 14 3 12.6569 3 11V6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H5V3C5 2.44772 5.44772 2 6 2H10ZM6 6C5.48716 6 5.06449 6.38604 5.00673 6.88338L5 7V11C5 11.5523 5.44772 12 6 12C6.51284 12 6.93551 11.614 6.99327 11.1166L7 11V7C7 6.44772 6.55228 6 6 6ZM10 6C9.48716 6 9.06449 6.38604 9.00673 6.88338L9 7V11C9 11.5523 9.44772 12 10 12C10.5128 12 10.9355 11.614 10.9933 11.1166L11 11V7C11 6.44772 10.5523 6 10 6Z" fill="#708189" />
        </svg>
      </v-btn>
    </div>
  </div>
</template>

<script>

import Vuex from 'vuex'
import Sentiment from '@/components/coding/Sentiment'

const SENTIMENT_OPTIONS = ['positive', 'neutral', 'negative']

export default {
  codit: true,
  name: 'Sentiment',

  components: {
    'sentiment-icon': Sentiment
  },

  props: {
    id: {
      type: String,
      default: ''
    },
    sentimentEnabled: {
      type: Boolean,
      default: false
    },
    sentiment: {
      type: String,
      default: 'neutral'
    },
    category: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    color: {
      type: undefined,
      default: () => {}
    },
    deletable: {
      type: Boolean,
      default: false
    },
    isHighlighted: {
      type: Boolean,
      default: false
    },
    fromDrawer: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      sentimentMenu: false,
      SENTIMENT_OPTIONS
    }
  },

  computed: {
    ...Vuex.mapState({
      topicSentimentDrawer: state => state.coding?.topicSentimentDrawer
    })
  },

  watch: {
    topicSentimentDrawer (val) {
      if (this.fromDrawer && val && this.id === val) {
        this.sentimentMenu = true
      }
    }
  },

  methods: {
  }
}

</script>