<template>
  <div>
    <file-upload
      @input="handleFileUpload"
      :accept="acceptFileTypes"
      required
      :multiple="false"
      :drop="true"
      :drop-directory="false"
      class="wizard__question__tile d-flex"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div class="wizard__question__tile__title">
        {{ $t('question_steps.file_upload') }}
      </div>
    </file-upload>
    <modal
      :visible="!!inheritModal"
      :close="() => closeInheritModal()"
      :title="$t('question_steps.inherit')"
      class-name="wizard__inherit topic-fullscreen-modal"
      :action="$t('confirm')"
      :action-click="setTopicsFromInherit"
      :disabled="confirmDisabled"
      persistent
      max-width="1375px"
    >
      <div
        v-if="meta"
        class="coding__column__options coding__column__options--topic-search coding__column__options--file-inherit d-flex justify-space-between"
      >
        <div class="w-100 mb-3 d-flex align-center">
          <div
            v-if="meta?.sheet_names?.length"
            style="max-width: 24%"
          >
            <label class="font-weight-medium text-sm text-label">
              Sheet
            </label>
            <v-select
              v-model="sheet"
              @input="v => handleFileUpload(file, v)"
              :items="meta.sheet_names"
              class="search-bar__search search-bar__search--large text-sm"
              placeholder="Select sheet"
              hide-details
              dense
              :height="40"
              outlined
              :menu-props="{
                'offset-y': true,
                'ripple': false
              }"
            />
          </div>
          <div
            class="w-100 d-flex align-center ml-4"
            style="margin-top: 22px;"
          >
            <v-checkbox
              v-model="headerRow"
              @change="v => handleFileUpload(file)"
              primary
              hide-details
              :ripple="false"
              color="green"
              off-icon="mdi-checkbox-blank"
              flat
              label="Has header row"
            />
          </div>
        </div>
        <div
          style="max-width: 24%"
        >
          <label class="font-weight-medium text-sm text-label">
            Topic column
          </label>
          <v-select
            v-model="topicColumn"
            @input="handleParameterChange"
            :items="meta.file_columns"
            :no-data-text="$t('question_steps.choose_language')"
            class="search-bar__search search-bar__search--large text-sm"
            placeholder="Select template"
            hide-details
            dense
            :height="40"
            outlined
            :menu-props="{
              'offset-y': true,
              'ripple': false
            }"
          />
        </div>
        <div
          style="max-width: 24%"
        >
          <label class="font-weight-medium text-sm text-label">
            Category column
          </label>
          <v-select
            v-model="categoryColumn"
            @input="handleParameterChange"
            :items="meta.file_columns"
            :no-data-text="$t('question_steps.choose_language')"
            class="search-bar__search search-bar__search--large text-sm"
            placeholder="Select template"
            hide-details
            dense
            :height="40"
            outlined
            :menu-props="{
              'offset-y': true,
              'ripple': false
            }"
          />
        </div>
        <div
          style="max-width: 24%"
        >
          <label class="font-weight-medium text-sm text-label">
            ID column
          </label>
          <v-select
            v-model="idColumn"
            @input="handleParameterChange"
            :items="meta.file_columns"
            :no-data-text="$t('question_steps.choose_language')"
            class="search-bar__search search-bar__search--large text-sm"
            placeholder="Select template"
            hide-details
            dense
            :height="40"
            outlined
            :menu-props="{
              'offset-y': true,
              'ripple': false
            }"
          />
        </div>
        <div
          style="max-width: 24%"
        >
          <label class="font-weight-medium text-sm text-label">
            Sentiment column
          </label>
          <v-select
            v-model="sentimentColumn"
            @input="handleParameterChange"
            :items="meta.file_columns"
            :no-data-text="$t('question_steps.choose_language')"
            class="search-bar__search search-bar__search--large text-sm"
            placeholder="Select template"
            hide-details
            dense
            clearable
            :height="40"
            outlined
            :menu-props="{
              'offset-y': true,
              'ripple': false
            }"
          />
        </div>
      </div>

      <topics-editor
        v-if="!loading && !parameterError && !inheritError"
        :id="id"
        :active="true"
        :editable="false"
        store-name="wizard"
        history
        wizard-view
        template-select
      />
      <loading
        v-if="loading"
        class="h-100 position-relative mb-5 pb-5"
        title="Uploading"
        :is-loading="true"
        dont-animate-entry
      />
      <error-state
        v-if="parameterError || inheritError"
        class-name="mb-5 pb-5"
        title="Parameter error"
        :text="(parameterError || inheritError) ? (parameterError?.message || parameterError) || (inheritError?.message || inheritError) : 'Error uploading file with given parameters'"
      />
      <div class="notification-container">
        <notification
          v-for="(warning, idx) in warnings"
          :key="idx"
          type="warning"
          title="Warning"
          max-width="400px"
          closeable
          class-name="mb-2"
          :text="$escapeHtml(warning)"
        />
      </div>
    </modal>
  </div>
</template>

<script>

import Vuex from 'vuex'
import TopicsEditor from '@/components/TopicsEditor'
import FileUpload from 'vue-upload-component'
import { ACCEPT_UPLOAD_FILETYPES } from '@/settings/overridable'

export default {
  codit: true,
  name: 'FileInherit',
  components: {
    TopicsEditor,
    FileUpload
  },

  mixins: [],

  data () {
    return {
      inheritModal: false,
      inheritFile: [],
      inheritError: null,
      loading: false,
      file: null,
      meta: null,
      warnings: [],
      sheet: null,
      topicColumn: null,
      categoryColumn: null,
      idColumn: null,
      sentimentColumn: null,
      parameterError: null,
      headerRow: true
    }
  },

  computed: {
    ...Vuex.mapState({
      topics: state => state.wizard.topics,
      id: state => state.wizard.id
    }),

    /**
     * Template selection confirm button disabled state
     */
    confirmDisabled () {
      if (!this.topics.length) return true
      return false
    },

    acceptFileTypes: () => ACCEPT_UPLOAD_FILETYPES,

    validParams () {
      return this.topicColumn && this.categoryColumn && this.idColumn
    }
  },

  watch: {
  },

  methods: {

    /**
     * One of the options has been changed by the user
     */
    handleParameterChange () {
      // validation
      if (!this.validParams) return

      this.loading = true
      this.inheritError = false
      this.parameterError = false
      this.warnings = []

      let data = new FormData()

      const body = {
        has_header: this.headerRow,
        sheet_name: this.sheet,
        columns: {
          code: this.idColumn,
          topic: this.topicColumn,
          category: this.categoryColumn,
          sentiment: this.sentimentColumn
        }
      }

      data.append('file', this.file[0].file)
      data.set('body', JSON.stringify(body))

      this.makeRequest(data, true)
    },

    makeRequest (data, parameter = false) {
      this.loading = true
      this.inheritError = false
      this.parameterError = false
      this.warnings = []
      this.setInheritModal(true)

      return api.post(`/api/ui/projects/import-topics`, data)
        .then(res => {
          const { data: { topics, meta, warnings = [] } } = res

          this.meta = meta
          this.warnings = warnings

          this.topicColumn = meta.configured_columns.topic
          this.categoryColumn = meta.configured_columns.category
          this.idColumn = meta.configured_columns.code
          this.sentimentColumn = meta.configured_columns.sentiment

          // Validate properties of new codes and give them colors
          let topicsToSet = []
          let codeCats = []
          let catColors = {}

          topics.forEach(c => {
            if (codeCats.indexOf(c.category) === -1) codeCats.push(c.category)
            if (!(c.category in catColors)) catColors[c.category] = this.$color.getMedium(codeCats.indexOf(c.category))
            topicsToSet.push({
              ...c,
              color: catColors[c.category]
            })
          })

          this.loading = false
          this.$store.commit('setTopics', topicsToSet)

          return res
        })
        .catch(err => {
          this.loading = false
          this.$store.commit('setTopics', [])
          this.inheritError = err.response?.data?.message || true
          if (parameter) this.parameterError = err.response?.data?.message || true
          this.$maybeRaiseAPIPromiseErr(err)
          throw err
        })
    },

    /**
     * Upload codebook file
     */
    handleFileUpload (fileArr, sheet = false) {
      const file = fileArr[0]
      if (!file) return

      this.inheritError = false
      this.parameterError = false
      this.warnings = []
      this.loading = true
      this.file = fileArr

      let data = new FormData()
      data.append('file', file.file)

      let body = {}

      if (sheet) {
        body['sheet_name'] = sheet
      }

      body['has_header'] = this.headerRow

      // should be all defaults
      data.set('body', JSON.stringify(body))

      this.makeRequest(data)
        .then(res => {
          const { data: { meta } } = res

          if (!sheet && meta.sheet_names && meta.sheet_names.length) {
            this.sheet = meta.sheet_names[0]
          }
        })
    },

    /**
     * Open project list to inherit a question
     */
    setInheritModal (value) {
      this.inheritModal = value
    },

    closeInheritModal () {
      this.parameterError = false
      this.warnings = []
      this.$store.commit('setTopics', [])
      this.inheritError = false
      this.setInheritModal(false)
    },

    /**
     * Callback from project list question select
     */
    setTopicsFromInherit () {
      this.$store.commit('incrementStep')
      this.$root.snackMsg(this.$t('question_steps.inherited_topics'))
      this.setInheritModal(false)
    }
  }
}

</script>

<style lang="scss">
</style>

<i18n locale='en' src='@/i18n/en/pages/Wizardv2.json' />
<i18n locale='de' src='@/i18n/de/pages/Wizardv2.json' />