<template>
  <v-tooltip
    v-model="showTooltip"
    class="hint"
    max-width="250"
    z-index="100"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-alert
        class="hint__activator text-xs mb-0 px-2 py-1"
        icon="mdi-information-outline"
        type="warning"
        text
        outlined
        dense
        @mouseenter="setTooltipDebounced(true)"
        @mouseleave="setTooltipDebounced(false)"
      >
        {{ $t('warning_read_only_short') }}
      </v-alert>
    </template>
    <div
      class="hint__content"
      @mouseenter="setTooltipDebounced(true)"
      @mouseleave="setTooltipDebounced(false)"
    >
      <div v-html="$t('warning_read_only')" />
      <div v-html="$t('request_access', {
        root_name: $escapeHtml(user.organization.root_user_name),
        root_email: user.organization.root_user_email,
        owner_name: $escapeHtml(dashboard.owner),
        owner_email: dashboard.owner_email
      })
      "
      />
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'DashboardNoEditPermHint',
  codit: true,

  props: {
    dashboard: { type: Object, default: () => ({}), required: true },
    user: { type: Object, default: () => ({}), required: true }
  },

  data () {
    return {
      showTooltip: false,

      setTooltipDebounced: _.debounce((val) => {
        this.showTooltip = val
      }, 150)
    }
  }
}
</script>

<style lang="scss">
  .hint{
    &__activator{
      cursor: default;

      .v-icon{
        font-size: 14px !important;
        margin-right: 6px !important;
        min-width: 0 !important;
      }
    }
    &__content{
      pointer-events: initial;

      a {
        color: inherit !important;
        text-decoration: underline;
      }
    }
  }
</style>

<i18n locale='en' src='@/i18n/en/pages/Dashboard.json' />
<i18n locale='de' src='@/i18n/de/pages/Dashboard.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />