var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{ref:"tooltip",attrs:{"content-class":"chart-type-preview__tooltip","bottom":"","max-width":250,"open-delay":150,"close-delay":150},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({class:[
        'chart-type',
        {
          active: _vm.active,
          disabled: _vm.disabled
        },
      ]},on),[_c('v-img',{attrs:{"src":_vm.imageSource}}),_vm._v(" "),(_vm.CHART_BADGE_IMAGES[_vm.CHART_TYPES_TO_API_ENUM[_vm.chartType]])?_c('v-img',{staticClass:"h-4 ml-n1 mt-n1 type-icon-badge",attrs:{"src":_vm.CHART_BADGE_IMAGES[_vm.CHART_TYPES_TO_API_ENUM[_vm.chartType]]}}):_vm._e(),_vm._v(" "),(_vm.CHART_BADGES[_vm.CHART_TYPES_TO_API_ENUM[_vm.chartType]])?_c('v-icon',{staticClass:"type-icon-badge"},[_vm._v("\n        "+_vm._s(_vm.CHART_BADGES[_vm.CHART_TYPES_TO_API_ENUM[_vm.chartType]])+"\n      ")]):_vm._e()],1)]}}])},[_vm._v(" "),_c('div',{on:{"mouseenter":_vm.openTooltip,"mouseleave":_vm.closeTooltip}},[_c('b',[_vm._v("\n      "+_vm._s(_vm.$t(`chart.types.${_vm.chartType}.title`))+":\n      "+_vm._s(_vm.$t(`chart.types.${_vm.chartType}.slogan`))+"\n    ")]),_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(_vm.$t(`chart.types.${_vm.chartType}.subtitle`))+"\n    ")]),_vm._v(" "),(!_vm.chartSettings.enabled)?_c('div',{staticClass:"warning--text mt-4"},[_vm._v("\n      "+_vm._s(_vm.$t(`chart.warnings.disabled`))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isTooltipLink && _vm.chartType === 'nps')?_c('div',{staticClass:"mt-4"},[_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('settings.column.no_valid_column', {
            helplink:
              'https://caplena.com/docs/knowledge-base/85eh1l8jcavy0-data-import#leverage-non-text-data',
          })
        )}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('settings.column.supported_formats'))}})]):(_vm.isTooltipLink && _vm.chartType === 'driver-scatter')?_c('div',{staticClass:"mt-4"},[_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('settings.driver_column.no_valid_column', {
            helplink:
              'https://caplena.com/docs/knowledge-base/85eh1l8jcavy0-data-import#leverage-non-text-data',
          })
        )}}),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('settings.driver_column.supported_formats', {
            csat: _vm.CSATTermsFormatted,
          })
        )}})]):(_vm.isTooltipLink && _vm.chartType === 'dynamic-bar')?_c('div',{staticClass:"mt-4"},[_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('settings.segment_column.no_valid_column')
        )}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }