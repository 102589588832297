<template>
  <div>
    <!-- FILTERS HERE -->
    <div v-if="!filtersInternal.length" class="text-center d-flex align-center flex-column bordered-top pt-4 overflown pb-4">
      {{ $t('ds.filters.no_items') }}
    </div>
    <div
      v-for="(item, idx) in filtersInternal"
      :key="idx"
      class="filter-item list-allow-overflow"
      :class="{ 'overheight': item.type === 'codes' }"
    >
      <!-- ??? matches prop -->
      <settings-drawer-item
        :title="(filtersInternal[idx].type && filtersInternal[idx].type.length) ? $t(`ds.filters.drawer_labels.${filtersInternal[idx].type}`) : ''"
        :is-filter="!!(filtersInternal[idx].type && filtersInternal[idx].type.length)"
        :is-master="isMaster"
        :on-remove-click="() => removeFilterItem(idx)"
        :matches="$t('ds.filters.show_matches')"
        :on-matches-click="() => $emit('show-answers', [item], $t('ds.verbatim_browser.title_filter'), 'codes', { dataset: dsIdx, dataset_filter: idx }, dsIdx)"
        is-closable
        :animate="idx === internalHighlightIdx"
      >
        <template slot="content">
          <filter-editor-v1-with-topics
            v-model="filtersInternal[idx]"
            :codes="codebookWithColors"
            :auxiliary-column-names="auxiliaryСolumnNames"
            :auxiliary-types="question.auxiliary_column_metas"
            :all-filters="filtersInternal"
            :is-master="isMaster"
            :is-dashboard-filter="isDashboardFilter"
            :id="id"
          />
        </template>
      </settings-drawer-item>
    </div>
    <v-menu
      offset-y
      :close-on-content-click="true"
      :rounded="'lg'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mt-4 mb-4 ml-auto mr-auto d-flex default-bs"
          style="position: absolute; left: 50%; transform: translateX(-50%);"
          depressed
          color="white"
        >
          <v-icon size="18" class="mr-1">
            mdi-plus-circle
          </v-icon>
          {{ $t('ds.filters.add') }}
        </v-btn>
      </template>

      <v-list class="dashboard-filters__list filters__list w-100 pt-0">
        <div
          v-for="(item, index) in addOptions"
          :key="index"
        >
          <v-divider v-if="item.divider" class="dashboard-filters__list__separator" />
          <div
            v-else-if="item.header"
            class="dashboard-filters__list__header"
          >
            {{ item.header }}
            <helptip v-if="item.helptip" position="bottom" class="ml-1">
              <div v-html="$t('require_matching_auxiliaries')" />
            </helptip>
          </div>
          <template v-else>
            <div
              v-if="item.disabled"
              class="dashboard-filters__list__item dashboard-filters__list__item--disabled"
            >
              {{ item.text }}
              <helptip v-if="item.disabled" position="bottom" class="ml-1">
                <div v-html="$t('disabled_filters.one_codes_or_categories_only')" />
              </helptip>
            </div>
            <div
              v-else
              class="dashboard-filters__list__item"
              @click="addFilterItem(item.type)"
            >
              {{ item.text }}
            </div>
          </template>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

import FilterEditorV1WithTopics from '@/components/FilterEditorV1WithTopics' // single place where it's used
import answerFilters from '@/mixins/answerFilters'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'
import colorPalettes from '@/mixins/colorPalettes'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'
import { FILTER_OPTIONS_TYPES } from '@/settings/constants'

const { STANDARD_FILTERS_WITH_TOPICS, DATASET_FILTERS } = FILTER_OPTIONS_TYPES

export default {
  codit: true,
  name: 'DatasetFilters',

  components: {
    'filter-editor-v1-with-topics': FilterEditorV1WithTopics,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [answerFilters, auxiliaryColumnsMixin, colorPalettes],

  props: {
    settings: { type: Object, default: () => ({}) },
    forceNew: { type: Boolean, default: false },
    highlightIdx: { type: Number, default: -1 },
    filters: { type: Array, default: () => [] },
    question: { type: Object, default: () => ({}) },
    answers: { type: Array, default: () => [] },
    isMaster: { type: Boolean, default: false },
    applyMaster: { type: Function, default: () => ({}) },
    dsIdx: { type: Number, default: () => 0 },
    onlyCodeOrCategory: { type: Boolean, default: true },
    id: { type: String, default: '' },
    isDashboardFilter: { type: Boolean, default: false }
  },

  data () {
    return {
      filtersInternal: [],
      filterCounts: [],
      internalHighlightIdx: null
    }
  },

  computed: {
    /**
     * The codebook with standard palette colors added, if they were not defined on the codebook already
     * (for legacy support, because I was to f'ing lazy to update the DB)
     * @return {Array} Codebook
     */
    codebookWithColors () {
      return this.addColorToCodes(this.question.codebook)
    },

    /**
     * calculate possible options for adding new filters
     * @return {Array} filters
     */
    addOptions () {
      const auxiliaryOptions = this.getAuxiliaryOptions(this.auxiliaryСolumnNames)
      return this.filterOptionsSelect(this.filtersInternal, auxiliaryOptions, this.isMaster ? STANDARD_FILTERS_WITH_TOPICS : DATASET_FILTERS)
        .map(f => this.filterOptionsTransformer(f))
    },

    auxiliaryСolumnNames () {
      if (
        !this.question?.auxiliary_column_metas?.length
      ) {
        return []
      }
      return this.question?.auxiliary_column_metas.map(({ name }) => name)
    },

    categoryOrCodesApplied () {
      const filterTypes = this.filtersInternal.map(({ type }) => type)
      return filterTypes.includes('topics') || filterTypes.includes('categories')
    }
  },

  watch: {
    /**
     * Watch the internal state of filters for changes, emit update when change has occurred
     */
    filtersInternal: {
      deep: true,
      handler (filtersInternal) {
        if (!_.isEqual(this.filters, filtersInternal)) {
          this.$emit('modified', true)
        }
        this.$emit('update:filters', _.cloneDeep(this.filtersInternal))
        if (this.isMaster) {
          this.applyMaster()
        }
      }
    },

    /**
     * Watch the state of the filters prop
     * If it has changed, check if the value is the same as the internal object
     * Note: The objects should *never* be the same as the internal one, as we cloneDeep when emitting
     */
    filters: {
      immediate: true,
      deep: true,
      handler (filters) {
        if (!_.isEqual(filters, this.filtersInternal)) {
          this.$set(this, 'filtersInternal', filters)
        }
      }
    },

    /**
     * Set the height of the verbatim browser according to the available space in the window
     */
    'verbatimBrowser.show' () {
      this.verbatimBrowser.height = window.innerHeight * 0.8
    },

    highlightIdx (idx) {
      this.internalHighlightIdx = idx
    }
  },

  mounted () {
    this.internalHighlightIdx = this.highlightIdx
    if (this.forceNew) {
      this.internalHighlightIdx = this.filtersInternal.length
      this.addFilterItem()
    }
  },

  beforeDestroy () {
    this.discardEmptyFilters()
  },

  methods: {
    /**
     * Add a new filter
     * @param {String} type  Type of filter to add (see answerFilters.js mixin)
     * @param {String,Object} value The value of the new filter
     */
    addFilterItem (type = null, value = null) {
      const initialFilter = this.initializeNewFilter(type, this.question.auxiliary_column_metas)
      initialFilter.invert = false
      this.filtersInternal.push(initialFilter)
    },

    /**
     * Remove the given filter item by index
     * @param  {Number} idx
     */
    removeFilterItem (idx) {
      this.filtersInternal.splice(idx, 1)
      if (this.isMaster) {
        this.$nextTick(() => this.applyMaster())
      }
    },

    /**
     * discard empty filters before closing dataset filters
     */
    discardEmptyFilters () {
      const nonEmptyFilters = this.filtersInternal.filter(filter => filter.type)
      if (this.filtersInternal.length !== nonEmptyFilters.length) {
        this.$emit('update:filters', _.cloneDeep(nonEmptyFilters))
      }
    },

    filterOptionsTransformer (opt) {
      if (this.onlyCodeOrCategory && this.categoryOrCodesApplied && (['topics', 'categories']).includes(opt.type)) return { ...opt, disabled: true }
      return opt
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
