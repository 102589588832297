<template>
  <v-dialog
    v-model="internalValue"
    origin=""
    :width="`${width}px`"
    :persistent="!closable"
    overlay-color="rgba(5, 32, 46, 0.5)"
    content-class="download-dialog-v2 overrided dialog injected-dialog-style"
    @keydown.esc="closeDialog"
  >
    <slot />
  </v-dialog>
</template>

<script>
import DataMixin from '@/components/customUi/mixins/data'

export default {
  mixins: [
    DataMixin
  ],

  props: {
    value: { type: Boolean, default: false },
    width: { type: Number, default: 430 },
    closable: { type: Boolean, default: true }
  },

  data () {
    return {
      isInEmbedded: false,
      customStyle: undefined
    }
  },

  watch: {
    internalValue (val) {
      if (
        val
      ) {
        return
      }
      this.$emit('close')
    }
  },

  created () {
    // check if the dialog is in an iframe
    this.isInEmbedded = window.self !== window.top
    if (this.isInEmbedded && this.$route.query.dialogOnTop) this.$route.query.dialogStyle = 'align-self:flex-start;margin-top: 60px'
    if (this.isInEmbedded && this.$route.query.dialogStyle) this.injectDialogStyles()
  },

  methods: {
    closeDialog () {
      if (
        !this.closable
      ) {
        return
      }
      this.internalValue = false
    },

    injectDialogStyles () {
      const style = document.createElement('style')
      const splittedStyles = this.$route.query.dialogStyle.split(';').map((s) => `${s} !important;`).join('\n')
      console.log('splitted styles')

      style.id = 'injected-dialog-style'
      style.innerHTML = `.injected-dialog-style {
        ${splittedStyles}
      }`

      if (!document.querySelector('#injected-dialog-style')) {
        document.head.appendChild(style)
      }
    }
  }
}
</script>

<style lang="scss">
.dialog{
  overflow: auto !important;

  &__header{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 100;
    border-bottom: 1px solid #F1F1F1;

    & + .dialog__actions{
      border-top: none;
    }
  }
  &__content{
    & + .dialog__content{
      border-top: 1px solid #F1F1F1;
    }
  }
  &__actions{
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 100;
    border-top: 1px solid #F1F1F1;

    .v-btn {
      .v-btn__content {
        text-transform: none !important;
        letter-spacing: normal !important;
      }
    }
  }
}
</style>