const OVERRIDE = window.CODIT_SETTINGS = {}

const OVERRIDE_OR_DEFAULT = (key, def) => (OVERRIDE[key] !== undefined ? OVERRIDE[key] : def)

const acceptFiletypes = '.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/plain,text/csv,application/x-spss-sav,.xlsx,.xls,application/spss,.sav'

/* eslint-disable no-multi-spaces */
const exportObj =   {
  overrideSettings: (key, val) => { OVERRIDE[key] = val },

  // Maximum number of answers a survey is allowed to have
  get MAX_ANSWERS_PER_SURVEY ()   { return OVERRIDE_OR_DEFAULT('MAX_ANSWERS_PER_SURVEY', 200000) },

  // Maximum number of questions a project is allowed to have
  get MAX_QUESTIONS_PER_PROJECT () { return OVERRIDE_OR_DEFAULT('MAX_QUESTIONS_PER_PROJECT', 25) },

  // Maximum number of topics a question is allowed to have
  get MAX_TOPICS_SEMIOPEN ()      { return OVERRIDE_OR_DEFAULT('MAX_TOPICS_SEMIOPEN', 800) },

  // Maximum number of topics a question is allowed to have
  get MAX_TOPICS ()               { return OVERRIDE_OR_DEFAULT('MAX_TOPICS', 800) },

  // Minimal number of answers that have to be changed before training is requested again
  get MIN_RETRAIN_ANSWERS ()      { return OVERRIDE_OR_DEFAULT('MIN_RETRAIN_ANSWERS', 20) },

  // The filetypes which are accepted when uploading answers or codes
  get ACCEPT_UPLOAD_FILETYPES ()  { return OVERRIDE_OR_DEFAULT('ACCEPT_UPLOAD_FILETYPES', acceptFiletypes) },

  // The maximum file size allowed when uploading
  get MAX_UPLOAD_FILESIZE_MB ()   { return OVERRIDE_OR_DEFAULT('MAX_UPLOAD_FILESIZE_MB', 150) },

  // Activation of threshold slider
  get THRESHOLD_SLIDER ()         { return OVERRIDE_OR_DEFAULT('THRESHOLD_SLIDER', false) },

  // The answer fields to download
  get DOWNLOAD_FIELD_ORDER ()     { return OVERRIDE_OR_DEFAULT('DOWNLOAD_FIELD_ORDER', ['auxiliary', 'text', 'translated_text', 'codes', 'additional']) },

  // The code fields to download
  get DOWNLOAD_CODES_ORDER ()     { return OVERRIDE_OR_DEFAULT('DOWNLOAD_CODES_ORDER', ['id', 'name', 'category']) },

  // If the downloaded code attributes should be interleaved
  get DOWNLOAD_CODES_INTERLEAVED () { return OVERRIDE_OR_DEFAULT('DOWNLOAD_CODES_INTERLEAVED', true) },

  // The maximum number of splits for the auxiliary columns to suggest as template to the user
  get MAX_VALS_TO_SUGGEST_SEGMENT () { return OVERRIDE_OR_DEFAULT('MAX_VALS_TO_SUGGEST_SEGMENT', 15) },

  get ANONYMIZATION_DEFAULT () { return OVERRIDE_OR_DEFAULT('ANONYMIZATION_DEFAULT', true) }
}

var proxyHandler = {
  /**
   * Mapper to make sure not to rely on undefined settings:
   * Raises error if getter on exportObj is not defined
   */
  get: function (obj, name) {
    if (name === '__esModule') return undefined
    else if (name in obj) return obj[name]
    else throw Error(`Undefined override config ${name}`)
  },
  set: function (obj, name, value) {
    throw Error(`Setting overridable values is not supported ${name}`)
  }
}

// use prox instead of exportObj to use get/set interceptor functions
var prox = new Proxy(exportObj, proxyHandler)

module.exports = prox
