<template>
  <div class="search-bar flex-column align-start p-0">
    <label class="font-weight-medium text-label text-xs d-flex">
      {{ $attrs.label }}
      <slot name="label" />
    </label>
    <v-text-field
      v-bind="$attrs"
      v-on="listeners"
      :key="refresh"
      v-model.number="internalModel"
      @input="debauncedHandleBlur"
      :error="!isSafe"
      :title="`max: ${max} - min: ${min}`"
      type="number"
      label=""
      class="search-bar__search search-bar__search--auxiliary"
    >
      <slot name="prepend" slot="prepend" />
      <slot name="append" slot="append" />
      <slot name="append-outer" slot="append-outer" />
      <slot name="progress" slot="progress" />
    </v-text-field>
  </div>
</template>
<script>
export default {
  name: 'NumberField',
  props: {
    value: {
      type: Number,
      required: false,
      default: null
    },
    debounceTimeout: {
      type: Number,
      default: 0,
      required: false
    },
    integer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      refresh: 0,
      initialSafeValue: 0,
      internalModel: 0,
      debauncedHandleBlur: this.debounceTimeout ? _.debounce(this.handleBlur, this.debounceTimeout) : this.handleBlur
    }
  },
  computed: {
    /**
     * omits input event from $listeners to prevent double `@input` call on parent
     * @return {Object} of other listeners
     */
    listeners () {
      return _.omit(this.$listeners, ['input', 'blur'])
    },

    /**
     * checks the validity of internal model
     * @return {Boolean}
     */
    isSafe () {
      const isSafeNumber = !isNaN(this.internalModel) && this.internalModel !== ''
      const isInteger = !this.integer || this.internalModel === ~~this.internalModel
      const isUnderMax = this.internalModel <= +this.max
      const isAboveMin = this.internalModel >= +this.min
      return isSafeNumber && isInteger && isUnderMax && isAboveMin
    },

    /**
     * safe minimum boundary
     * @return {Number}
     */
    min () {
      return !isNaN(this.$attrs.min) ? +this.$attrs.min : -Infinity
    },

    /**
     * safe maximum boundary
     * @return {Number}
     */
    max () {
      return !isNaN(this.$attrs.max) ? +this.$attrs.max : +Infinity
    }

  },
  watch: {
    // watch for parent changes
    value (value) {
      this.internalModel = value
      this.initialSafeValue = value
    }
  },
  mounted () {
    // save initial value as initialSafeValue
    this.internalModel = this.value
    this.initialSafeValue = this.value
  },
  methods: {
    /**
     * dispatches 'input' event on blur
     * or retset input value to initial safe value
     */
    handleBlur (force) {
      if (this.isSafe) {
        const internalModel = Math.max(this.min, Math.min(this.max, this.internalModel))
        this.$emit('input', this.integer ? ~~internalModel : internalModel)
      } else if (force === true) {
        this.$nextTick(() => {
          this.internalModel = this.initialSafeValue || 0
          this.refresh++
        })
      }
    }
  }
}
</script>
