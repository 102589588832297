<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane v-model="config"
                    v-bind="controlPaneProps"
                    v-on="controlPaneEvents"
                    ref="control-pane"
                    slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>

        <template v-slot:colors>
          <settings-drawer-item
            :title="$t('colors.palette.title')"
          >
            <template slot="content">
              <div
                class="d-flex align-center theme--light v-label mb-3"
              >
                <div
                  class="color-indicator mr-2"
                  :style="{ 'background-color': config.barColor }"
                  @click.stop="handleShowRangeColorPicker($event)"
                />
                {{ $t('colors.palette.item') }}
              </div>
            </template>
          </settings-drawer-item>
          <color-picker
            :show.sync="showColorPicker"
            v-model="config.barColor"
            :offset-top="rangeColorPickerConfig.offsetTop"
            :offset-left="rangeColorPickerConfig.offsetLeft + 5"
            :min-top="-80"
            lazy
          />
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.percentages"
                :label="$t('controls.value_axis.percentages')"
              />
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>

          <settings-drawer-item
            :title="$t('controls.labels.label_position.title')"
          >
            <template slot="content">
              <v-radio-group v-model="config.labelPosition" column hide-details class="mt-0">
                <v-radio
                  :label="$t('controls.labels.label_position.outside')"
                  value="outside"
                />
                <v-radio
                  :label="$t('controls.labels.label_position.inside')"
                  value="inside"
                />
              </v-radio-group>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-checkbox
            v-model="config.showSampleSize"
            hide-details
            :label="$t('settings.sample_size.label_control')"
            class="mt-2"
          />
        </template>

        <template v-slot:chart-type-selection>
          <slot name="chart-type-selection" />
          <!-- START: temporary date configuration slot (APP-535) -->
          <settings-drawer-item
            :title="$t('settings.date.select_column_label')"
          >
            <template slot="content">
              <div class="settings-selector">
                <div class="d-flex align-center justify-space-between">
                  <v-select
                    v-model="config.dateColumn"
                    :items="potentialDateColumnSelectItems"
                    :label="`${$t('settings.column.label')}`"
                    item-text="text"
                    style="max-width: 89%;"
                    item-value="value"
                    hide-details
                    outlined
                    dense
                  />
                  <helptip position="bottom" class="ml-3">
                    <span v-html="$t('settings.date.column_helptip')" />
                  </helptip>
                </div>
              </div>
            </template>
          </settings-drawer-item>
          <settings-drawer-item
            :title="$t('settings.date.select_aggregation_label')"
          >
            <template slot="content">
              <div class="settings-selector">
                <v-radio-group v-model="config.dateAggregation" column hide-details class="mt-0">
                  <v-radio v-for="opt in dateAggregationOptions" :key="opt" :label="$t(`settings.date.aggregations.${opt}`)" :value="opt" />
                </v-radio-group>
              </div>
            </template>
          </settings-drawer-item>
          <!-- END: temporary date configuration slot (APP-535) -->
        </template>

        <slot
          name="datasets"
          slot="datasets"
          :dataset-props="{ disableFilters: true, duplicatable: false, editable: false }"
          :disable-master-settings="true"
        />
        <!-- <slot name="master-settings" slot="master-settings" /> -->
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <!-- <slot name="dataset-filters" slot="dataset-filters" /> -->
        <slot name="matches" slot="matches" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <v-chart
          ref="chart"
          v-if="initialReady"
          :style="chartStyle"
          v-bind="chartProps"
          v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import ColorPicker from '@/components/ColorPicker'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'
import { generateSigleFunctionCallComputedWithSameArgs } from '@/utils/genericComputed.js'
import { DATE_AGG_ENUM } from '@/settings/constants'
import auxiliaryColumnsMixin from '@/mixins/auxiliaryColumnsMixin'

import 'echarts/lib/chart/bar'

export default {
  codit: true,
  name: 'ChartRowsCountBar',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem,
    'color-picker': ColorPicker
  },

  mixins: [chartMixin, auxiliaryColumnsMixin],

  data () {
    return {
      resultKeys: ['counts'],
      APItype: 'DCNT',
      forceAggregate: false,

      dateAggregationOptions: Object.values(DATE_AGG_ENUM),

      config: {
        // General
        title: undefined,
        aggregate: true,

        dimensions: undefined,

        colorBy: {},

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255,1)',
        barColor: this.$color.paletteMedium[0],

        // Ordinal axis
        ordinalAxisName: undefined,
        reverseOrdinal: undefined,

        // Value axis
        valueAxisName: undefined,
        valueAxisToFullData: false,

        // Grid line
        gridLine: true,

        percentages: true,

        // Labels
        labelsEnabled: true,
        dataLabelSize: 12,
        axisLabelSize: 12,
        decimalPlaces: 0,
        showSampleSize: true,
        labelPosition: 'outside',

        // Master Filters
        filters: [],

        // date configs
        dateColumn: null,
        dateAggregation: 'MONTH',

        controls: {
          sentimentShown: 'any',
          groupByColumnValue: 0
        }
      },

      availableSettingTiles: {
        ...this.availableSettingTiles,
        valueAxis: ['valueAxisName', 'valueAxisToFullData']
      },

      chartContainerWidth: 0,

      showColorPicker: false,
      selectedCustomColor: 0,
      rangeColorPickerConfig: {}
    }
  },

  computed: {
    ...generateSigleFunctionCallComputedWithSameArgs({
      potentialDateColumnSelectItems: 'getPotentialDateColumnSelectItems'
    })(function () {
      return [
        this.meta.auxiliary_column_metas,
        this.auxiliaryColumnNames
      ]
    }),

    chartDependentEnv () {
      return {
        config: this.config
      }
    },

    chartContainerStyle () {
      return {
        'min-height': `400px`
      }
    },

    chartDataset () {
      if (
        !this.initialReady
      ) {
        return []
      }

      const headerDataset = [
        'yfield',
        ...this.globalTicks.map(item => item)
      ]

      const calcPercentage = (value, total) => value ? 100 * value / total : 0
      const calcItems = (array, total, preprocess, initialValue) => array.map((item, index) =>
        preprocess
          ? preprocess(item, total)
          : item
      )

      return {
        counts: [
          headerDataset,
          [
            'rows',
            ...calcItems(this.resultsComputed.counts.rows)
          ]
        ],
        percentages: [
          headerDataset,
          [
            'rows',
            ...calcItems(this.resultsComputed.counts.rows, this.globalResults.counts?.per_dataset[0], calcPercentage)
          ]
        ]
      }
    },

    source () {
      return this.chartDataset[this.config.percentages ? 'percentages' : 'counts']
    },

    ordinalAxis () {
      return {
        type: 'category',
        name: this.config.ordinalAxisName,
        nameLocation: 'end',

        nameTextStyle: {
          fontStyle: 'italic',
          color: 'grey',
          align: 'right',
          verticalAlign: 'top',
          lineHeight: 30,
          fontSize: this.config.axisLabelSize,
          padding: [25, 12, 0, 0]
        },

        axisTick: false,

        data: this.source[0].slice(1)
      }
    },

    valueAxis () {
      let options = {
        name: this.config.valueAxisName,
        nameLocation: 'end',
        nameTextStyle: {
          fontStyle: 'italic',
          color: 'grey',
          align: 'left',
          fontSize: this.config.axisLabelSize,
          verticalAlign: 'top',
          padding: [(this.config.axisLabelSize * -1.4 - 30), 0, 0, -10],
          lineHeight: 30
        },
        gridIndex: 0,
        axisLabel: {
          fontSize: this.config.axisLabelSize,
          formatter: (value) => `${value}${this.valueUnit}`
        },
        splitLine: {
          show: this.config.gridLine
        }
      }

      if (this.config.valueAxisToFullData) {
        const dataMaxValue = this.config.percentages ? 100 : _.max(this.globalResults.counts.per_tick)

        options.max = dataMaxValue
      }

      // For showing sample size:
      if (
        this.config.showSampleSize &&
        typeof this.globalResults.counts?.per_dataset?.[0] === 'number'
      ) {
        options.name += ` (n=${this.globalResults.counts.per_dataset[0]})`
      }

      return options
    },

    xAxis () {
      return this.ordinalAxis
    },

    yAxis () {
      return this.valueAxis
    },

    valueUnit () { return this.config.percentages ? '%' : '' },

    grid () {
      return {
        top: this.config.axisLabelSize * 1.4 + 50,
        left: 40,
        bottom: 40,
        right: 40,
        containLabel: true
      }
    },

    series () {
      const contrastWithBackground = this.getContrast(this.config.barColor)
      const labelColor = contrastWithBackground === 'low'
        ? 'grey'
        : contrastWithBackground === 'high'
          ? '#ffffff'
          : ''

      return {
        type: 'bar',
        id: 'bar',
        seriesLayoutBy: 'row',
        itemStyle: {
          emphasis: {
            barBorderRadius: [5, 5]
          },
          normal: {
            barBorderRadius: [5, 5],
            color: this.config.barColor
          }
        },
        label: {
          show: !!this.config.labelPosition && this.config.labelsEnabled,
          position: this.config.labelPosition === 'inside' ? 'insideTop' : 'top',
          color: this.config.labelPosition === 'outside' ? 'grey' : labelColor,
          rotate: 0,
          offset: [0, 0],
          fontSize: this.config.dataLabelSize,
          distance: 12,
          padding: [2, 3],
          formatter: (el) => {
            if (this.config.percentages) {
              return `${
                this.toFixed(el.value[1], this.config.decimalPlaces)
              }%`
            }
            return el.value[1]
          }
        },
        animation: false
      }
    },

    /**
     * Return the results for the rows for each dataset for each resultKey
     * Make sure an entry is returned for every dataset, even if that dataset is not really computed yet.
     * @return {Object}
     */
    results () {
      let res = {}
      _.each(this.resultKeys, key => {
        res[key] = {
          rows: this.resultsComputed.rows
        }
      })

      res['rows'] = {
        rows: this.resultsComputed.rows
      }

      return res
    },

    tooltip () {
      return {
        formatter: (params) => {
          const title = this.chartDataset.counts[0][params.dataIndex + 1]
          const value = this.chartDataset.counts[1][params.dataIndex + 1]
          const perc = this.chartDataset.percentages[1][params.dataIndex + 1]
          return `
            ${title}<br>
            <div>${value} (${this.toFixed(perc, this.config.decimalPlaces)}%)</div>
          `
        }
      }
    },

    chartOptions () {
      if (this.initialReady) {
        return {
          ...this.defaultOptions,
          chartDependentEnv: this.chartDependentEnv,
          dataset: {
            source: this.source
          },
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          series: this.series,
          grid: this.grid,
          tooltip: this.tooltip,
          backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
        }
      }

      return {}
    }
  },

  watch: {
    results: {
      immediate: false,
      handler (val) {
        this.$nextTick(() => {
          if (this.$refs.chart) this.$refs.chart.refresh()
        })
      }
    },

    'config.dateColumn' (newVal, oldVal) {
      if (this.initialReady && !_.isEqual(newVal, oldVal)) {
        this.computeAllResults()
      }
    },

    'config.dateAggregation' (newVal, oldVal) {
      if (this.initialReady && !_.isEqual(newVal, oldVal)) {
        this.computeAllResults()
      }
    }
  },

  methods: {
    /**
     * Create entries in results object for each of the defint resultKeys (e.g. counts, relative_impact etc.)
     */
    initResultsContainer () {
      this.resultKeys.forEach(key => {
        this.$set(this.resultsComputed, key, _.cloneDeep({
          rows: []
        }))
      })
    },

    /**
     * Handles showing and properly aligning the color picker in control panel
     * @param  {Number} index index of the selected range
     * @param  {Object} $event
    */
    handleShowRangeColorPicker ($event) {
    // set previously picked color's active property to false
      if (this.selectedCustomColor) {
        this.showColorPicker = false
      }

      if (!this.rangeColorPickerConfig) this.rangeColorPickerConfig = {}

      this.rangeColorPickerConfig.offsetLeft = $event.target.offsetLeft
      this.rangeColorPickerConfig.offsetTop = $event.target.offsetTop + 5

      this.showColorPicker = true
    },

    /**
    * prepares data after loading values from API (getRemoteResults)
    * @params {Object} req        request body from chartAPIObject
    * @params {Object} res        API response.data from /api/charts/:id/values
    */
    prepareRemoteResults (req, res) {
      const dsIdxes = this.getDsIdxes()

      _.each(this.resultKeys, key => {
        if (this.resultsComputed[key]) {
          // Reset the datasets array (when switching aggregate or changing number of datasets)

          dsIdxes.forEach(dsIdx => {
            this.emitDatasetResult({ req, res, dsIdx, key })
          })

          this.computeResults({ res, key })
        }
      })
    },

    /**
    * emits chart result per  dataset
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     dsIdx {Number}:    dataset index
    *     key   {String}:    result key from resultKeys
    */
    emitDatasetResult ({ res, dsIdx }) {
      this.$emit('result', dsIdx, _.get(res.results_global.counts.per_dataset, dsIdx, 0))
    },

    /**
    * prepares the resultsComputed
    * @params {Object} params: { req, res, dsIdx, key }
    *     req   {Object}:    request body from chartAPIObject
    *     res   {Object}:    API response.data from /api/charts/:id/values
    *     tckIdx{Number}:    tick index
    *     key   {String}:    result key from resultKeys
    */
    computeResults ({ res, key }) {
      // Set the rows results
      this.$set(this.resultsComputed[key], 'rows', res.results_global.counts.per_tick)
    },

    chartClick ($evt) {
      if (
        $evt.componentType === 'title'
      ) {
        this.showPaneDrawer('general')
      } else if (
        $evt.componentType === 'xAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      } else if (
        $evt.componentType === 'yAxis' &&
        $evt.targetType === 'axisName'
      ) {
        this.showPaneDrawer('axes')
      }
      if (
        $evt.componentType !== 'series' ||
        !this.isVerbatimEnabled
      ) {
        return
      }

      let tick = $evt.data[0]

      this.$store.dispatch('verbatimDialog/show', {
        item: this.id || 'ch__new',
        filters: [
          {
            type: 'tick',
            value: tick,
            htmlText: `<div class="font-weight-medium">${this.$t('verbatim.filters.tick')}</div>:&nbsp;${tick}`
          }
        ]
      })
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartRowsCountBar.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartRowsCountBar.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />