<template>
  <div class="w-100 h-100">
    <chart-scaffold v-bind="scaffoldProps"
                    v-on="scaffoldEvents"
                    ref="scaffold"
    >
      <template v-slot:chart-title v-if="!hideControls">
        {{ config.title }}
      </template>
      <control-pane v-model="config"
                    v-bind="controlPaneProps"
                    v-on="controlPaneEvents"
                    ref="control-pane"
                    slot="control-pane"
      >
        <template v-slot:actions>
          <slot name="actions" />
        </template>

        <template v-slot:labels>
          <settings-drawer-item
            :title="$t('controls.percentages_label')"
          >
            <template slot="content">
              <v-checkbox
                v-model="config.percentages"
                :label="$t('controls.labels.percentages')"
              />
              <v-row dense>
                <v-col>
                  <v-number-field
                    v-model="config.decimalPlaces"
                    :debounce-timeout="250"
                    :label="$t('settings.decimal_places.label')"
                    :min="0"
                    :max="2"
                    integer
                    hide-details
                    :hint="$t('settings.decimal_places.hint')"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <div class="pt-2 text-center text--secondary">
                    {{ $t('settings.decimal_places.sample') }}<span>{{ (1.23456789).toFixed(config.decimalPlaces) }}</span>%
                  </div>
                </v-col>
              </v-row>
            </template>
          </settings-drawer-item>
        </template>

        <template v-slot:show-legend-count>
          <v-checkbox
            v-model="config.showSampleSize"
            hide-details
            :label="$t('settings.sample_size.label_control')"
            class="mt-2"
          />
        </template>

        <slot
          name="datasets"
          slot="datasets"
          :dataset-props="{ editable: false }"
        />
        <slot name="master-settings" slot="master-settings" />
        <slot name="master-filters" slot="master-filters" />
        <slot name="dataset-settings" slot="dataset-settings" />
        <slot name="dataset-filters" slot="dataset-filters" />
        <slot name="matches" slot="matches" />
        <slot name="chart-type-selection" slot="chart-type-selection" />
      </control-pane>

      <slot name="chart-filters" slot="before-chart" />

      <template v-slot:default="{ chartStyle }">
        <div class="treemap-zoom-control">
          <span class="info-box" v-text="$t('controls.zoom.info')" />&nbsp;|&nbsp;
          <a @click="zoomToNode(false)" v-text="$t('controls.zoom.reset')" />
        </div>
        <v-chart ref="chart"
                 v-if="initialReady"
                 :style="chartStyle"
                 v-bind="chartProps"
                 v-on="chartEvents"
        />
      </template>
    </chart-scaffold>
  </div>
</template>

<script>

import ChartScaffold from '@/components/visualize/ChartScaffold'
import ControlPane from '@/components/visualize/ControlPane'
import SettingsDrawerItem from '@/components/visualize/SettingsDrawerItem'

import { chartMixin } from '@/mixins/chart'
import { encodeStr } from '@/utils/filters'

import {
  SENTIMENT_RANGE
} from '@/settings/constants'

import 'echarts/lib/chart/treemap'

export default {
  codit: true,
  name: 'ChartTreemap',

  components: {
    'chart-scaffold': ChartScaffold,
    'control-pane': ControlPane,
    'settings-drawer-item': SettingsDrawerItem
  },

  mixins: [chartMixin],

  data () {
    return {
      resultKeys: ['counts'],
      APItype: 'TREE',
      forceAggregate: true,

      config: {
        // General
        title: undefined,

        dimensions: undefined,

        aggregate: undefined,

        // Color
        colorBy: {},
        colorPalette: undefined,
        colorPaletteValue: undefined,

        // Canvas background
        enableBackground: false,
        background: 'rgba(255,255,255,1)',

        // Labels
        percentages: false,
        decimalPlaces: 0,
        labelsEnabled: true,
        dataLabelSize: 12,
        // showLegend: false,
        showSampleSize: true,
        axisLabelSize: 11,

        // Master Filters
        filters: [],

        // Outputs
        plotTopicsFilter: undefined,
        plotCategoriesFilter: undefined,

        controls: {
          sentimentShown: 'any',
          showTopNTopics: null,
          groupByColumnValue: 0
        }
      },

      chartContainerWidth: 0,

      handleChartClickDelayed: _.debounce(this.handleChartClick, 500)
    }
  },

  computed: {
    topicsByCatFiltered () {
      return _.groupBy(
        this.topics,
        'category'
      )
    },

    chartDataset () {
      if (!this.initialReady) return []

      let childMapping = this.topicsByCatFiltered
      let res = []
      const nRows = this.globalResults.counts?.overall || this.globalResults.counts?.per_dataset?.[0]

      const source = SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
        ? this.results.counts.categoriesSentiment?.[0]
        : this.results.counts.categories?.[0]

      _.each(source, (item, gname) => {
        if (gname in childMapping) {
          let children = childMapping[gname].map(topic => {
            const item = _.find(this.results.counts.topics[0], ({ id }) => id === topic.id)
            let value

            if (
              SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
            ) {
              value = item?.sentiments[this.sentimentShown].counts?.overall
            } else {
              value = item?.results.counts?.overall
            }

            return {
              value: value,
              perc: 100 * value / nRows,
              name: topic.label,
              topicID: topic.id,
              topicCategory: gname,
              description: topic.description,
              id: this.topicCats.indexOf(gname)
            }
          })

          let value

          if (
            SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1
          ) {
            value = item?.[this.sentimentShown].counts?.overall
          } else {
            value = item
          }

          res.push({
            value: value,
            perc: 100 * value / nRows,
            name: gname,
            topicID: -100,
            topicCategory: gname,
            itemStyle: this.useCBColors ? {
              color: this.catColors[gname].medium
            } : undefined,
            children
          })
        }
      })

      return res
    },

    series () {
      const { toFixed } = this
      const { decimalPlaces, percentages } = this.config
      return [{
        width: '98%',
        type: 'treemap',
        nodeClick: false,
        roam: 'pan',
        top: 35,
        bottom: this.config.axisLabelSize * 1.4 + 10,
        percentages: this.config.percentages, // required to make it responsive
        label: {
          show: this.config.labelsEnabled,
          fontSize: this.config.dataLabelSize,
          color: 'white',
          opacity: 1.0,
          formatter: (el) => el.data.name + (percentages ? ` (${toFixed(el.data.perc, decimalPlaces)}%)` : '')
        },
        upperLabel: {
          show: true,
          color: 'white',
          height: 30
        },
        levels: [{
          itemStyle: {
            borderWidth: 0,
            gapWidth: 5
          },
          upperLabel: {
            fontSize: this.config.dataLabelSize,
            show: false
          }
        },
        {
          itemStyle: {
            gapWidth: 1,
            borderColorSaturation: 0.6,
            borderWidth: 10
          },
          upperLabel: {
            fontSize: this.config.dataLabelSize,
            fontWeight: '900'
          }
        },
        {
          itemStyle: {
            gapWidth: 1,
            borderColorSaturation: 0.6
          }
        }],
        data: this.chartDataset,
        breadcrumb: {
          show: false // if true set height to 85%, if false set it to 92%
        },
        animation: false
      }]
    },

    title () {
      const options = {
        textStyle: {
          fontStyle: 'italic',
          fontSize: this.config.axisLabelSize,
          fontWeight: 'normal',
          color: 'grey',
          width: 200,
          align: 'right'
        },
        bottom: 0,
        right: 0
      }

      if (
        this.config.showSampleSize &&
        typeof this.globalResults?.counts?.overall === 'number'
      ) {
        options.text = `n=${this.globalResults.counts.overall}`
      }

      return options
    },

    legend () {
      return {
        top: 30
      }
    },

    tooltip () {
      return {
        appendToBody: true,
        formatter: (el) => {
          let tt = ''
          if (el.treePathInfo.length > 2) tt = `${this.$escapeHtml(el.treePathInfo[1].name)}<br />`
          tt += `${this.$escapeHtml(el.data.name)}: ${Math.round(el.data.value)} (${this.toFixed(el.data.perc, this.config.decimalPlaces)}%)`
          if (el.data.description) tt += `<br>${this.$escapeHtml(el.data.description)}`
          return tt
        }
      }
    },

    useCBColors () {
      return this.config.colorPalette === '__cb_colors__'
    },

    chartOptions () {
      let opts = {
        ...this.defaultOptions,
        series: this.series,
        tooltip: this.tooltip,
        title: this.title,
        backgroundColor: this.config.enableBackground ? this.config.background : 'transparent'
      }
      if (!this.useCBColors) opts.color = this.colors
      return opts
    }
  },

  watch: {
    initialReady: {
      immediate: false,
      handler () {
        setTimeout(() => {
          if (this && this.$refs.chart) this.$refs.chart.chart.on('updateAxisPointer', this.setPieSeries)
        }, 100)
      }
    },

    /**
      hack in order to rerender the chart after result refresh, unfortunately the animation doesn't work because of this, possibly find another solution. Don't really understand why this only happens on some chart types (line-pie and this one more specifically
    */
    results: {
      immediate: false,
      handler (val) {
        this.$nextTick(() => {
          if (this.$refs.chart) this.$refs.chart.refresh()
        })
      }
    }
  },

  methods: {
    /**
     * Handler when an element in the chart is clicked
     * This handler calls a delayed execution of the actual handler, which can still be canceled
     * when it turns out the user did a double click after all
     * @param  {Object} $evt The event object
     */
    chartClick ($evt) {
      this.handleChartClickDelayed($evt)
    },

    /**
     * The actual handler for a single click chart event
     * @param  {Object} $evt
     */
    handleChartClick ($evt) {
      let filter
      const sentiment = SENTIMENT_RANGE.indexOf(this.sentimentShown) > -1 ? this.sentimentShown : 'any'

      if ($evt.componentType === 'title') {
        this.showPaneDrawer('general')
      } else if (
        !$evt.data ||
        $evt.data.topicCategory === undefined ||
        $evt.data.topicID === undefined
      ) {
        return
      } else if (
        $evt.componentType === 'series' &&
        $evt.data.topicID === -100
      ) {
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.category.${encodeStr($evt.data.topicCategory, true)}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.categories')}</div>:&nbsp;${this.$escapeHtml($evt.data.topicCategory)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      } else if (
        $evt.componentType === 'series'
      ) {
        filter = {
          type: 'text_to_analyze',
          // TODO: map series name
          value: `topic.${$evt.data.topicID}:${sentiment}`,
          htmlText: `<div class="font-weight-medium">${this.$t('answer_fields.topics')}</div>:&nbsp;${this.$escapeHtml($evt.data.name)} &&nbsp; <div class="font-weight-medium">${this.$t('answer_fields.sentiment')}</div>:&nbsp;${sentiment}`
        }
      }

      if (
        !this.isVerbatimEnabled
      ) {
        return
      }

      if ($evt.componentType === 'series') {
        this.$store.dispatch('verbatimDialog/show', {
          item: this.id || 'ch__new',
          filters: [filter]
        })
      }
    },

    /**
     * The couble click on chart handler: Zoom into the node that was clicked
     * @param  {Object} $evt The event object
     */
    chartDblclick ($evt) {
      this.handleChartClickDelayed.cancel()
      this.zoomToNode($evt.name)
    },

    /**
     * Zoom into the node given by nodeName
     * if nodeName is not defined, zoom out to the default node
     * @param  {String} nodeName The node to zoom to
     */
    zoomToNode (nodeName) {
      if (!nodeName) nodeName = this.datasets[0].settings.name
      // Get the chart view -> https://github.com/apache/incubator-echarts/blob/master/src/chart/treemap/TreemapView.js
      let cView = this.$refs.chart.chart._chartsViews[0]
      // Find the node given by the nodeName
      let node = _.find(cView.__model._viewRoot.hostTree._nodes, { name: nodeName })
      // Call the zoom function on the chart view
      cView._zoomToNode({ node })
    }
  }
}

</script>

<style lang=scss>

.treemap-zoom-control {
  z-index: 4;
  text-align: center;
  font-size: 10px;
  color: #666;
  background: rgba(255, 255, 255, 0.5);
  padding: 5px 0 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

</style>

<i18n locale='en' src='@/i18n/en/components/visualize/ChartGlobals.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartGlobals.json' />
<i18n locale='en' src='@/i18n/en/components/visualize/ChartTreemap.json' />
<i18n locale='de' src='@/i18n/de/components/visualize/ChartTreemap.json' />
<i18n locale='en' src='@/i18n/en/pages/Dataset.json' />
<i18n locale='de' src='@/i18n/de/pages/Dataset.json' />
<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />
