<template>
  <div class="h-100 d-flex flex-column verbatim-standalone justify-space-between">
    <div
      class="d-flex flex-column"
      :style="{
        height: !isSummaryShown && !state.loadingRows ? 'calc(100% - 54px)' : '100%'
      }"
    >
      <div
        class="verbatim-standalone__content pl-4 pt-3 pb-3 pr-4"
        v-if="filtersProps.filtersFromQuery.length || filtersProps.filtersFromConfig.length || filtersProps.filtersFromOpenAction.length"
      >
        <VerbatimBrowserv2Filters
          v-bind="filtersProps"
        />
      </div>

      <slot name="alert" />

      <div
        v-if="!isSummaryShown"
        class="verbatim-standalone__content d-flex width-100 h-100 overflow-x-hidden overflow-y-auto flex-1"
      >
        <VerbatimBrowserv2RowsViewer
          :id="id"
          :query-params="queryParams"
          :config="config"
        />
      </div>
      <div
        v-else
        class="verbatim-standalone__content d-flex width-100 h-100 overflow-x-hidden overflow-y-auto flex-1"
      >
        <VerbatimBrowserv2SummaryViewer
          :id="id"
          :query-params="queryParams"
          :config="config"
        />
      </div>
    </div>

    <div
      v-if="!isSummaryShown && !state.loadingRows"
      class="verbatim-standalone__footer d-flex justify-space-between px-3 py-4"
    >
      {{ paginationString }}
    </div>
  </div>
</template>

<script>

import VerbatimBrowserv2Filters from '@/components/VerbatimBrowserv2/components/VerbatimBrowserv2Filters.vue'
import VerbatimBrowserv2RowsViewer from '@/components/VerbatimBrowserv2/components/VerbatimBrowserv2RowsViewer'
import VerbatimBrowserv2SummaryViewer from '@/components/VerbatimBrowserv2/components/VerbatimBrowserv2SummaryViewer'

import VerbatimBrowserv2Mixin from '@/components/VerbatimBrowserv2/mixins/VerbatimBrowserv2.js'

export default {
  codit: true,
  name: 'VerbatimBrowser',

  components: {
    VerbatimBrowserv2Filters,
    VerbatimBrowserv2RowsViewer,
    VerbatimBrowserv2SummaryViewer
  },

  mixins: [VerbatimBrowserv2Mixin],

  props: {
    config: { type: Object, default: () => ({}) },
    id: { type: [String, undefined], default: undefined },
    isSummaryShown: { type: Boolean, default: false }
  }
}

</script>

<style lang=scss>
@import '~@/css/tooltips.scss';

.verbatim-standalone{
  &__content{
    // max-height: 100%;

    &:not(:last-child){
      border-bottom: 1px solid #F1F1F1;
    }
  }
  &__footer{
    border-top: 1px solid #F1F1F1;
    color: #708189;
    font-size: 14px;
  }
}
</style>

<i18n locale='en' src='@/i18n/en/components/VerbatimBrowserv2.json' />
<i18n locale='de' src='@/i18n/de/components/VerbatimBrowserv2.json' />
<i18n locale='en' src='@/i18n/en/components/Translate.json' />
<i18n locale='de' src='@/i18n/de/components/Translate.json' />