import {
  /*
  * use unique names of charts
  */
  CHART_UNIQUE_BAR,
  CHART_UNIQUE_LINE_PIE,
  CHART_UNIQUE_DATE_LINE_PIE,
  CHART_UNIQUE_GRAPH,
  CHART_UNIQUE_SCORE,
  CHART_UNIQUE_SENTIMENT,
  CHART_UNIQUE_SENTIMENT_BAR,
  CHART_UNIQUE_ROWS_COUNT_BAR,
  CHART_UNIQUE_DYNAMIC_BAR,
  /*
  * vars on scoring types
  */
  SATISFACTION_SCORING_TYPES
} from '@/settings/constants'

import CHART_LIST from '@/settings/chartList'

export default {
  methods: {
    /**
    * Prefill the chart settings with best guesses, if possible, or set initial value
    * for NPS calculate the best suitable column/method
    * we use it while initializing NPS chart
    */
    prefillSettingValues (meta) {
      _.forEach(CHART_LIST[this.chart.typeSelection].requiredSettings, (_, settingName) => {
        switch (settingName) {
          case 'SETT_SCORING_TYPE':
            if (
              !this.potentialNumberColumnSelectItems.length
            ) {
              return
            }
            const initialColumnIndex = this.findInitialAuxiliaryColumnIndexForNpsChartSelect(meta, this.potentialNumberColumnSelectItems) // eslint-disable-line
            const initialTypeIndex = this.findInitialAuxiliaryScoringTypeIndexForNpsChartSelect(meta, this.potentialNumberColumnSelectItems[initialColumnIndex].value) // eslint-disable-line
            if (this.settings.scoring_type === null) {
              this.$set(
                this.settings,
                'scoring_type',
                this.firstDataset.settings.scoring_type || SATISFACTION_SCORING_TYPES[initialTypeIndex]
              )
            }
            if (this.settings.scoring_column === null) {
              this.$set(
                this.settings,
                'scoring_column',
                this.firstDataset.settings.scoring_column !== null
                  ? this.firstDataset.settings.scoring_column
                  : this.potentialNumberColumnSelectItems.length
                    ? this.potentialNumberColumnSelectItems[initialColumnIndex].value
                    : -1
              )
            }
            break
          case 'SETT_DRIVER':
            if (this.settings.driver_target_column === null) {
              this.$set(
                this.settings,
                'driver_target_column',
                this.firstDataset.settings.driver_target_column ||
                (this.potentialDriverColumnSelectItems.length ? this.potentialDriverColumnSelectItems[0].value : -1)
              )
            }
            break
          case 'SETT_DATE_COLUMN':
            if (!this.chart.config.dateColumn) {
              this.$set(
                this.chart.config,
                'dateColumn',
                this.potentialDateColumnSelectItems.length ? this.potentialDateColumnSelectItems[0].value : null
              )
            }
            break
        }
      })
    },
    // Check if there are any settings to apply to the dataset
    // Apply these settings before potentially adding further datasets
    applyDatasetSettings () {
      _.keys(CHART_LIST[this.chart.typeSelection].requiredSettings).forEach(settingName => {
        switch (settingName) {
          case 'SETT_NPS': {
            const props = ['scoring_type', 'scoring_column']

            this.datasets.forEach(ds => {
              props.forEach(p => {
                // const current = ds.settings[p]
                // if (current === null || current === undefined) this.$set(ds.settings, p, this.settings[p])
                this.$set(ds.settings, p, this.settings[p])
              })
            })
            break
          }
          case 'SETT_DRIVER':
            this.datasets.forEach(ds => this.$set(ds.settings, 'driver_target_column', this.settings.driver_target_column))
            break
        }
      })
    },
    prefillConfigValues () {
      this.chart.config.percentages = (
        [
          CHART_UNIQUE_SENTIMENT,
          CHART_UNIQUE_DYNAMIC_BAR,
          CHART_UNIQUE_DATE_LINE_PIE,
          CHART_UNIQUE_SENTIMENT_BAR
        ]
      ).includes(this.chart.typeSelection)

      this.chart.config.aggregate = !(
        [
          CHART_UNIQUE_BAR,
          CHART_UNIQUE_SENTIMENT_BAR,
          CHART_UNIQUE_LINE_PIE,
          CHART_UNIQUE_DATE_LINE_PIE,
          CHART_UNIQUE_DYNAMIC_BAR
        ]
      ).includes(this.chart.typeSelection)

      this.chart.config.showLegend = (
        [
          CHART_UNIQUE_LINE_PIE,
          CHART_UNIQUE_DATE_LINE_PIE,
          CHART_UNIQUE_GRAPH,
          CHART_UNIQUE_SENTIMENT_BAR,
          CHART_UNIQUE_SCORE,
          CHART_UNIQUE_SENTIMENT,
          CHART_UNIQUE_DYNAMIC_BAR
        ]
      ).includes(this.chart.typeSelection)

      if (
        this.chart.typeSelection === CHART_UNIQUE_SENTIMENT
      ) {
        this.chart.config.type = 'pie-doughnut'
      }
      if (
        this.chart.typeSelection === CHART_UNIQUE_SCORE
      ) {
        this.chart.config.type = 'pie-gauge'
      }
      if (
        this.chart.typeSelection === CHART_UNIQUE_DYNAMIC_BAR
      ) {
        this.chart.config.groupByColumn = this.potentialDYBARSelectItems[0].name
        this.chart.config.controls.groupByColumnValue = 0
      }

      if (
        this.chart.config.colorBy &&
        this.chart.typeSelection === CHART_UNIQUE_DYNAMIC_BAR
      ) {
        this.chart.config.colorBy.field = 'segment'
        this.chart.config.colorPalette = 'default'
      } else if (
        this.chart.config.colorBy
      ) {
        this.chart.config.colorBy.field = 'category'
      }

      if (
        this.chart.type === CHART_UNIQUE_SCORE ||
        this.chart.type === CHART_UNIQUE_SENTIMENT ||
        this.chart.type === CHART_UNIQUE_SENTIMENT_BAR
      ) {
        this.chart.config.ranges = []
        this.chart.config.rangeBoundries = []
      }
    },
    setDefaultAxisNames () {
      if (
        [
          CHART_UNIQUE_ROWS_COUNT_BAR,
          CHART_UNIQUE_DATE_LINE_PIE
        ].indexOf(this.chart.typeSelection) > -1
      ) {
        this.chart.config.valueAxisName = this.$t('counts')
        this.chart.config.ordinalAxisName = this.chart.config.dateColumn
      } else if (
        [
          CHART_UNIQUE_BAR,
          CHART_UNIQUE_SENTIMENT_BAR,
          CHART_UNIQUE_DYNAMIC_BAR
        ].indexOf(this.chart.typeSelection) > -1
      ) {
        this.chart.config.ordinalAxisName = this.$t('answer_fields.topics')
        this.chart.config.valueAxisName = this.$t('controls.value_axis_name')
      }
    },

    /**
    * Finds the best column
    * to use by default
    * @param  {Array} filteredIndexes The list of aux columns in use for NPS
    * @return {Number} Index of aux column suiataable for use
    */
    findInitialAuxiliaryColumnIndexForNpsChartSelect (meta, filteredIndexes) {
      const indexNPSInOriginalArray = meta.auxiliary_column_metas.findIndex(
        ({ semantic_type }) => ['NPS_10', 'NPS_100'].indexOf(semantic_type) > -1 // eslint-disable-line
      )
      const indexStar5InOriginalArray = meta.auxiliary_column_metas.findIndex(
        ({ semantic_type }) => semantic_type === 'STAR_5' // eslint-disable-line
      )
      const indexCSATInOriginalArray = meta.auxiliary_column_metas.findIndex(
        ({ semantic_type, min, max }) => semantic_type === 'GENERIC_AUXILIARY' && min === 1 && max === 5 // eslint-disable-line
      )
      const indexAVGInOriginalArray = meta.auxiliary_column_metas.findIndex(
        ({ semantic_type, type }) => semantic_type === 'GENERIC_AUXILIARY' && type === 'number' // eslint-disable-line
      )

      let indexInOriginalArray

      if (
        indexNPSInOriginalArray > -1
      ) {
        indexInOriginalArray = indexNPSInOriginalArray
      } else if (
        indexStar5InOriginalArray > -1
      ) {
        indexInOriginalArray = indexStar5InOriginalArray
      } else if (
        indexCSATInOriginalArray > -1
      ) {
        indexInOriginalArray = indexCSATInOriginalArray
      } else if (
        indexAVGInOriginalArray > -1
      ) {
        indexInOriginalArray = indexAVGInOriginalArray
      }

      if (
        indexInOriginalArray === null
      ) {
        return
      }

      return filteredIndexes.findIndex(({ value }) => value === indexInOriginalArray)
    },
    /**
    * Finds the best scoring method for column
    * to use by default
    * @param  {Array} filteredIndexes The list of aux columns in use for NPS
    * @return {Number} Index of aux column suiataable for use
    */
    findInitialAuxiliaryScoringTypeIndexForNpsChartSelect (meta, index) {
      if (
        meta.auxiliary_column_metas[index].semantic_type === 'NPS_10' ||
        meta.auxiliary_column_metas[index].semantic_type === 'NPS_100'
      ) {
        return SATISFACTION_SCORING_TYPES.findIndex(item => item === 'NPS')
      } else if (
        meta.auxiliary_column_metas[index].semantic_type === 'GENERIC_AUXILIARY' &&
        meta.auxiliary_column_metas[index].min === 1 &&
        meta.auxiliary_column_metas[index].max === 5
      ) {
        return SATISFACTION_SCORING_TYPES.findIndex(item => item === 'CSAT')
      } else if (
        meta.auxiliary_column_metas[index].semantic_type === 'STAR_5'
      ) {
        return SATISFACTION_SCORING_TYPES.findIndex(item => item === 'AVG')
      } else if (
        meta.auxiliary_column_metas[index].semantic_type === 'GENERIC_AUXILIARY' &&
        meta.auxiliary_column_metas[index].type === 'number'
      ) {
        return SATISFACTION_SCORING_TYPES.findIndex(item => item === 'AVG')
      }
    }
  }
}