export default {
  data () {
    return {
      verbatimQueryApplied: {},
      shownItems: {},
      visibleItems: {}
    }
  },

  watch: {
    '$route.params.id' () {
      this.verbatimQueryApplied = {}
      this.shownItems = {}
      this.visibleItems = {}
    },

    'dashboardFilters.filterQueryParams' () {
      Object.values(this.visibleItems).forEach(entityId => {
        this.changeChartState(entityId)
      })
    }
  },

  methods: {
    chartVisibleHandler (isVisible, entityId, gridItemId) {
      if (
        isVisible &&
        this.visibleItems[gridItemId]
      ) return

      if (
        !isVisible
      ) {
        delete this.visibleItems[gridItemId]
        return
      }

      this.$set(this.visibleItems, gridItemId, entityId)
      this.$set(this.shownItems, gridItemId, true)

      this.changeChartState(entityId)
    },

    changeChartState (entityId) {
      const chartType = this.dashboardElements.find(({ chart }) => chart.id === entityId)?.chart.type

      if (
        chartType === 'P_VRB' &&
        this.registerManager[entityId]?.extraQueryParams?.filters !== this.extraQueryParams.filters
      ) {
        this.$set(this.verbatimQueryApplied, entityId, _.cloneDeep(this.extraQueryParams))
      }

      if (!this.registerManager[entityId]) {
        this.lazyManageChart(entityId, 'register')
      }

      if (
        this.registerManager[entityId].extraQueryParams.filters !== this.extraQueryParams.filters
      ) {
        this.lazyManageChart(entityId, 'update')
      }
    },

    /*
    * Register chart once shown
    * in order to download corresponding data
    * and show it.
    */
    lazyManageChart (entityId, method) {
      const entityData = this.dashboardElements.find(({ chart }) => chart.id === entityId)?.chart

      if (
        !entityData
      ) return

      return this.$store.dispatch(`registerManager/${method}`, {
        entityId,
        entityData: _.cloneDeep(entityData),
        settings: {
          method: 'get'
        },
        extraQueryParams: this.extraQueryParams
      })
    }
  }
}