<template>
  <div v-if="$root?.posthogFlagEnabled?.('caplena_revamp', false)">
    <slot :new-app-url="newAppUrl" />
  </div>
</template>

<script>

export default {
  name: 'NewAppLink',
  computed: {
    newAppUrl () {
      const appURLMap = {
        local: 'https://app.dev.caplena.dev',
        dev: 'https://app.dev.caplena.dev',
        stage: 'https://app.stage.caplena.dev',
        prod: 'https://app.caplena.com'
      }

      return appURLMap[process.env.VUE_APP_CAPLENA_ENV] || 'https://app.dev.caplena.dev'
    }
  }
}
</script>