import Vuex from 'vuex'

import { createSlider } from '@typeform/embed'
import '@typeform/embed/build/css/slider.css'

const TYPEFORM_TRIAL_ACTIVATION_FORM_ID = 'aIuOPQpX'

export default {
  data () {
    return {
      trialActivationSlider: null
    }
  },

  methods: {
    /**
     * Initialize the trial activation object from the typeform SDK
     * See https://developer.typeform.com/embed/modal/#popup
     */
    initTrialActivationSurvey () {
      this.trialActivationSlider = createSlider(TYPEFORM_TRIAL_ACTIVATION_FORM_ID, {
        // Don't really save results unless on prod
        enableSandbox: !this.$root.isProdEnv,
        hidden: {
          email: this.user.email,
          user_id: this.user.id
        }
      })
    },

    /**
     * Open the trial activation slider
     */
    openTrialActivationSurvey () {
      if (this.trialActivationSlider === null) this.initTrialActivationSurvey()
      this.trialActivationSlider.toggle()
    }
  },

  computed: {
    ...Vuex.mapState(['user'])
  }
}