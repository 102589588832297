<template>
  <div class="main-content home">
    <!-- GENERAL STUFF -->
    <v-alert v-if="failed"
             prominent
             type="error"
             outlined
             text
             border="left"
             class="flex-center"
    >
      {{ $t('error_loading_retry') }}

      <div class="spacer" />

      <v-btn color="accent" outlined @click="loadData">
        {{ $t('actions.retry') }}
      </v-btn>
    </v-alert>

    <!-- HEADER -->
    <page-header>
      <template slot="headline">
        <span v-text="$t('title', { name: user.first_name })" data-private />
      </template>
      <template slot="subtitle">
        {{ $t('subtitle') }}
      </template>

      <template slot="navigation-buttons">
        <v-btn outlined
               color="green"
               :to="{ name: 'upload' }"
        >
          <v-icon left>
            mdi-cloud-upload
          </v-icon>
          {{ $t('menu.upload') }}
        </v-btn>

        <v-btn outlined
               color="green"
               :to="{ name: 'account' }"
        >
          <v-icon left>
            mdi-account-box
          </v-icon>
          {{ $t('menu.profile') }}
        </v-btn>
      </template>
    </page-header>

    <div class="home-elements">
      <!-- PROJECTS, CHARTS, DASHBOARDS -->
      <data-preview-tabs :loading="loading"
                         :projects="projects"
                         :charts="charts"
                         :dashboards="dashboards"
                         class="block-el"
      >
        <template slot="actions-projects">
          <div>
            <div slot="content-footer"
                 class="content-footer"
                 v-html="$t(`latest_preview_items_${user.organization ? 'org' : 'standalone'}`)"
            />
          </div>
          <v-btn outlined
                 small
                 color="green"
                 :to="{ name: 'projects-manage' }"
                 v-text="$t('preview.all_projects')"
          />
        </template>

        <template slot="actions-charts">
          <div>
            <div slot="content-footer"
                 class="content-footer"
                 v-html="$t(`latest_preview_items_${user.organization ? 'org' : 'standalone'}`)"
            />
          </div>
          <v-btn outlined
                 small
                 color="green"
                 :to="{ name: 'charts-manage' }"
                 v-text="$t('preview.all_charts')"
          />
        </template>

        <template slot="actions-dashboards">
          <div>
            <div slot="content-footer"
                 class="content-footer"
                 v-html="$t(`latest_preview_items_${user.organization ? 'org' : 'standalone'}`)"
            />
          </div>
          <v-btn outlined
                 small
                 color="green"
                 :to="{ name: 'dashboards-manage' }"
                 v-text="$t('preview.all_dashboards')"
          />
        </template>
      </data-preview-tabs>

      <!-- ACHIEVEMENTS (BADGES, TEAM) -->
      <v-card class="block-el highlight-header achievements">
        <v-card-title v-text="$t('achievements.title')" />

        <v-card-text>
          <fieldset class="badges">
            <legend>
              {{ $t('achievements.your') }} <a @click="showBadgesBrowser()" v-text="$t('achievements.badges')" />
            </legend>
            <div class="badges-elements">
              <div v-for="(props, b) in BADGES"
                   :key="b"
                   class="badge"
                   :class="[`level${ownBadges[b]}`]"
                   @click="showBadgesBrowser(b)"
              >
                <v-skeleton-loader v-if="loading" type="image" />
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <img :src="getBadgeImage(b, ownBadges[b])">
                      <div class="level" v-text="ownBadges[b]" />
                    </div>
                  </template>
                  <div style="font-weight: bold">
                    {{ $t(`badges.${b}.title`) }} {{ $t(`badges.level`) }} {{ ownBadges[b] }}
                  </div>
                  <div v-if="ownBadges[b] < props.maxLevel">
                    {{ $t(`badges.next_requirement`) }} <span v-html="$t(`badges.${b}.levels.${ownBadges[b] + 1}`)" />
                  </div>
                  <div v-else v-html="$t('badges.max_level')" />
                </v-tooltip>
              </div>
            </div>
          </fieldset>
        </v-card-text>

        <v-card-text v-if="!loading && !failed">
          <v-alert v-if="!user.organization || !team.length"
                   type="info"
                   class="flex-center"
                   text
          >
            <span v-html="$t('achievements.team.no_team')" />

            <v-btn outlined
                   color="primary"
                   :to="{ name: 'account' }"
            >
              <v-icon left>
                mdi-account-box
              </v-icon>
              {{ $t('menu.profile') }}
            </v-btn>
          </v-alert>

          <fieldset v-else class="team">
            <legend v-text="$t('achievements.your_team')" />

            <v-data-table :headers="[{ value: 'name' }, ...$_.map(BADGES, (v, k) => ({ value: `badge_${k}` }))]"
                          :items="team"
                          :items-per-page="5"
                          :loading="loading"
                          :footer-props="{
                            'items-per-page-options': [5]
                          }"
                          sort-by="name"
                          class="team-table"
                          hide-default-header
            >
              <template v-slot:item.name="{ item }">
                <div>
                  {{ item.name }}
                  <span v-if="item.roles.length"
                        class="role"
                        v-text="$te(`roles.${item.roles[0]}.name`) ? $t(`roles.${item.roles[0]}.name`) : item.roles[0]"
                  />
                </div>
                <div class="email">
                  <a :href="`mailto: ${item.email}`" v-text="item.email" />
                </div>
              </template>

              <template v-for="(props, b) in BADGES" v-slot:[`item.badge_${b}`]="{ item }">
                <div class="badges-container" :key="`user-${item.id}-badge-${b}`">
                  <div v-if="item.badges[b]"
                       class="badge tooltip tooltip-left"
                       :class="[`level${item.badges[b]}`]"
                       @click="showBadgesBrowser(b)"
                       :data-tooltip="`${$t(`badges.${b}.title`)} ${$t('badges.level')} ${item.badges[b]}`"
                  >
                    <img :src="getBadgeImage(b, item.badges[b])">
                    <div class="level" v-text="item.badges[b]" />
                  </div>
                </div>
              </template>
            </v-data-table>
          </fieldset>
        </v-card-text>
      </v-card>

      <!-- STATISTICS -->
      <v-card class="block-el highlight-header stats">
        <v-card-title v-text="$t('stats.title')" />
        <v-card-text>
          <fieldset class="stats-tbl">
            <legend v-text="$t(`stats.subtitle_${user.organization ? 'org' : 'standalone'}`)" />

            <table class="stats-tbl">
              <tr>
                <td v-text="$t('stats.n_projects') + ':'" />
                <td><span v-text="stats.n_projects" /></td>
              </tr>
              <tr>
                <td v-text="$t('stats.n_questions') + ':'" />
                <td><span v-text="stats.n_questions" /></td>
              </tr>
              <tr>
                <td v-text="$t('stats.n_answers') + ':'" />
                <td><span v-text="stats.n_answers" /></td>
              </tr>
              <tr>
                <td v-text="$t('stats.n_charts') + ':'" />
                <td><span v-text="stats.n_charts" /></td>
              </tr>
              <tr>
                <td v-text="$t('stats.n_dashboards') + ':'" />
                <td><span v-text="stats.n_dashboards" /></td>
              </tr>
            </table>
          </fieldset>
        </v-card-text>
      </v-card>

      <!-- NEWS -->
      <v-card class="block-el highlight-header news">
        <v-card-text>
          <v-alert type="info" text icon="mdi-newspaper-variant" color="blue-grey">
            <span>News</span>
          </v-alert>

          <template v-if="headwayNews === null">
            <v-skeleton-loader v-for="k in 3"
                               :key="k"
                               type="image"
            />
          </template>
          <ul v-else class="news-list">
            <li v-for="(item, idx) in headwayNews" :key="idx">
              <div class="title-row">
                <span class="tag"> {{ item.categories[0] }}</span>
                <span class="ntitle" v-text="item.title" />
              </div>
              <div class="content">
                <span v-html="item.content.long" />
                <a v-if="item.content.more" :href="item.url" target="_blank" class="more">Read more</a>
              </div>
            </li>
          </ul>
        </v-card-text>
      </v-card>

      <!-- BLOG -->
      <v-card class="block-el highlight-header resources">
        <v-card-text>
          <v-alert type="info" text icon="mdi-book-open-page-variant" color="blue-grey">
            <span v-html="$t('resources.blog_intro')" />
          </v-alert>

          <fieldset class="articles">
            <legend v-html="$t('resources.relevant_articles', {
              resource: `<a href='https://caplena.com/blog/' target='_blank'>${$t('resources.blog_label')}</a>`
            })"
            />
            <ul>
              <li>
                <div>
                  <a href="https://caplena.com/blog/sentiment-analysis-netflix-app/"
                     target="_blank"
                  >
                    Walkthrough: Netflix App reviews
                  </a>
                  <div class="description">
                    A 3½-step video tutorial on analyzing open-ends in Netflix app reviews.
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <a href="https://caplena.com/blog/how-to-create-codebook/" target="_blank">
                    How to design a meaningful codebook
                  </a>
                </div>
                <div class="description">
                  Six steps will boost your ability to create meaningful codebooks.
                </div>
              </li>
              <li>
                <div>
                  <a href="https://caplena.com/blog/how-to-automate-trackers/" target="_blank">
                    Coop Case Study
                  </a>
                </div>
                <div class="description">
                  How Switzerland's largest retailer gets Actionable Insight from mass Feedback Analysis
                </div>
              </li>
            </ul>
          </fieldset>
        </v-card-text>
      </v-card>

      <!-- HELP CENTER -->
      <v-card class="block-el highlight-header resources">
        <v-card-text>
          <v-alert type="info" text icon="mdi-teach" color="blue-grey">
            <span v-html="$t('resources.help_intro')" />
          </v-alert>

          <fieldset class="articles">
            <legend v-html="$t('resources.relevant_articles', {
              resource: `<a href='https://caplena.com/docs/' target='_blank'>${$t('resources.help_label')}</a>`
            })"
            />
            <ul>
              <li>
                <div>
                  <a href="https://caplena.com/docs/knowledge-base/ezyy60tig7uzy-data-structure-projects-text-to-analyze-rows-etc" target="_blank">
                    Data structure explained
                  </a>
                </div>
                <div class="description">
                  What projects, questions, answers and rows are in <em>Excel terms</em>.
                </div>
              </li>
              <li>
                <div>
                  <a :href="HELP_RESOURCE_CREDITS" target="_blank">
                    How credits work
                  </a>
                </div>
                <div class="description">
                  Credits are Caplenas <em>currency</em>, but they are not immediately deducted when uploading a project. Learn when and for what you are billed.
                </div>
              </li>
            </ul>
          </fieldset>
        </v-card-text>
      </v-card>
    </div>

    <!-- <v-progress-linear v-if="loading" :indeterminate="true" /> -->
    <badges-browser v-model="badgesBrowser.show"
                    :focus="badgesBrowser.focus"
                    :user-levels="ownBadges"
    />
  </div>
</template>

<script>

import Vuex from 'vuex'

import BadgesBrowser from '@/components/BadgesBrowser'
import DataPreviewTabs from '@/components/DataPreviewTabs'
import { BADGES } from '@/settings/constants'
import { HELP_RESOURCE_CREDITS } from '@/settings/help-resources'

export default {
  codit: true,
  name: 'Home',

  components: {
    'data-preview-tabs': DataPreviewTabs,
    'badges-browser': BadgesBrowser
  },
  mixins: [],

  data () {
    return {
      loading: true,
      failed: false,

      dataTab: 0,

      projects: [],
      dashboards: [],
      charts: [],
      team: [],
      ownBadges: {},
      stats: {},

      badgesBrowser: {
        show: false,
        focus: ''
      },

      BADGES,
      HELP_RESOURCE_CREDITS
    }
  },

  computed: {
    ...Vuex.mapState(['user', 'headwayNews'])
  },

  created () {
    this.loadData()
    this.$store.commit('setPageTutorialID', '1588232323mLhe0197')
  },

  methods: {
    /**
     * Load the home page data
     */
    loadData () {
      this.loading = true
      this.failed = false

      // Fetch question info and codebook
      api.get(`/api/home`).then((res) => {
        this.$set(this, 'projects', res.data.projects)
        this.$set(this, 'dashboards', res.data.dashboards)
        this.$set(this, 'charts', res.data.charts)
        this.$set(this, 'team', res.data.team)
        this.$set(this, 'ownBadges', res.data.self.badges)
        this.$set(this, 'stats', res.data.stats)

        this.loading = false
      }).catch(err => {
        this.loading = false
        this.failed = true
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    /**
     * Get the image for a badge and level
     * @param  {String} badge The badge identifier
     * @param  {Number} level The level of the badge
     * @return {String}       Path to relevant image
     */
    getBadgeImage (badge, level) {
      return require(`../assets/badges/${badge}_${Math.max(level, 1)}.svg`)
    },

    /**
     * Open the badge browser
     * @param  {String} focus The badge on which to focus the browser
     */
    showBadgesBrowser (focus = '') {
      this.badgesBrowser.focus = focus
      this.badgesBrowser.show = true
    }
  }
}
</script>

<style lang=scss>

.home {
  .home-elements {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;

    > .v-card {
      margin-right: 12px;
      margin-bottom: 16px;
      > .v-card__title {
        height: 72px;
      }
    }
  }

  .preview-card {
    flex: 1;
    min-width: 450px;
    min-height: 430px;

    .content-footer {
      background: #FFF;
      font-size: smaller;
      color: #666;
      margin: 0 auto 10px auto;
      padding: 3px 8px;
      border-radius: 4px;
      display: inline-flex;
    }
  }

  .v-card.highlight-header {
    .v-card__title {
      margin-bottom: 8px;
      background: #EEE;
    }
  }

  fieldset.team, fieldset.badges, fieldset.articles, fieldset.stats-tbl {
    border: 4px solid #EEE;
    border-radius: 8px;
    display: flex;

    > legend {
      color: #666;
      font-size: smaller;
      padding: 0 8px;
      margin-left: 8px;
      flex: 0 0 auto;
      white-space: nowrap;
      z-index: 4;
      position: relative;
    }
  }

  .achievements {
    flex: 1;
    min-width: 550px;

    .badge {
      cursor: pointer;
    }

    .team-table {
      flex: 1;

      td {
        white-space: nowrap;
        padding: 0 8px;
      }

      td:first-child {
        width: 100%;
        padding: 0 16px;
      }

      .role {
        font-size: smaller;
        color: #666;
        border-left: 1px solid #999;
        padding-left: 4px;
        margin-left: 4px;
      }

      .email {
        display: block;
        font-size: smaller;
        line-height: 10px;
      }

      .badge {
        width: 35px;
        height: 35px;
      }

      tr:hover {
        background: $col-ans-focus-bg!important;
      }
    }

    .badges-elements {
      margin-top: -10px;
      margin-bottom: -1px;
      margin-left: 125px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    .badges {
      .badge, .v-skeleton-loader {
        height: 70px;
        width: 70px;
        margin-right: 36px;
        transition: transform ease 0.1s;
        transform: scale(1.1, 1.1);
        background: #FFF;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          transform: scale(1.2, 1.2);
        }
      }
    }
  }

  .news {
    flex: 1;
    min-width: 400px;
    .v-card__title {
      border-bottom: 1px solid #EEE;
    }

    .v-skeleton-loader {
      height: 120px;
      margin-bottom: 24px;
    }

    .v-card__text {
      max-height: 430px;
      overflow: scroll;
    }

    .news-list {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: visible;

      li {
        vertical-align: middle;
      }

      .title-row {
        display: flex;
        margin-bottom: 8px;
      }

      .tag {
        font-size: 10px;
        background: #EEE;
        border-radius: 4px;
        padding: 0px 4px;
        line-height: 16px;
        margin-right: 8px;
      }

      .ntitle {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        display: inline-block;
        margin-bottom: -4px;
      }

      .content {
        color: #666;
        border: 4px solid #EEE;
        border-radius: 6px;
        padding: 8px 12px 0;
        margin-bottom: 24px;
        h1, h2, h3, h4 {
          font-size: inherit;
        }

        .more {
          display: block;
          text-align: center;
          padding: 3px 0;
          margin: 8px -12px 0;

          &:hover {
            background: #EEE;
          }
        }
      }
    }
  }

  .resources {
    flex: 1;
    min-width: 400px;

    .v-card__text > .v-alert {
      a:not(.v-btn) {
        text-decoration: underline;
      }
      margin-bottom: 8px;
    }

    .articles {
      display: flex;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        flex: 1;
      }
      li {
        flex: 1;
        padding: 4px 16px 10px;
        border-bottom: 1px solid #EEE;
        .description {
          color: #424242;
          font-size: smaller;
          line-height: 14px;
        }
        &:hover {
          background: $col-ans-focus-bg;
        }
      }
    }
  }

  .stats {
    min-width: 400px;

    .stats-tbl {
      table {
        border-collapse: collapse;
        width: 100%;
      }

      tr:hover {
        background: $col-ans-focus-bg!important;
        border-radius: 4px;
      }
      td {
        padding: 4px 8px;
      }
      td:first-child {
        color: #9e9e9e;
        width: 200px;
      }
      td:last-child {
        text-align: right;
        span {
          display: inline-block;
          background-color: #f5f5f5;
          color: #bd4147;
          box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
          border-radius: 3px;
          font-weight: 900;
          font-family: monospace,monospace;
          font-size: 85%;
          padding: 0 5px;
          margin-bottom: 2px;
        }
      }
    }
  }
}

</style>

<i18n locale='en' src='@/i18n/en/pages/Home.json' />
<i18n locale='de' src='@/i18n/de/pages/Home.json' />
<i18n locale='en' src='@/i18n/en/components/Badges.json' />
<i18n locale='de' src='@/i18n/de/components/Badges.json' />
<i18n locale='en' src='@/i18n/en/Roles.json' />
<i18n locale='de' src='@/i18n/de/Roles.json' />