<template>
  <v-app :class="{
    [`${$route.name}-page`]: true,
    [`${$i18n.locale}`]: true
  }"
  >
    <iframe-controls v-if="isEmbedded" />

    <v-navigation-drawer app v-model="menuVisible" temporary width="300" v-if="!user.isAnonymous">
      <div style="display: flex; width: 100%; align-items: center">
        <img style="margin: 0 auto" src="./assets/logo.svg" alt="Logo caplena.com" width="150">
      </div>

      <v-list nav dense class="main-nav">
        <template v-if="!isDashboardOnlyUser">
          <!-- HOME -->
          <v-list-item exact :to="{ name: 'home' }">
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.home') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <!-- PROJECTS -->
          <v-list-group prepend-icon="mdi-clipboard-text" no-action :value="routeName.startsWith('project')">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.projects_group') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <!-- Projects Manage -->
            <v-list-item exact :to="{ name: 'projects-manage' }">
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.manage') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Upload -->
            <v-list-item exact :to="{ name: 'upload' }">
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.upload') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-divider />

          <!-- CHARTS -->
          <v-list-group prepend-icon="mdi-chart-bar" no-action :value="routeName.startsWith('chart')">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.charts_group') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <!-- Charts Manage -->
            <v-list-item exact :to="{ name: 'charts-manage' }">
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.manage') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Charts Manage -->
            <v-list-item exact :to="{ name: 'chart-details', params: { id: 'new' } }">
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.new') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>

        <!-- DASHBOARDS -->
        <v-list-group prepend-icon="mdi-monitor-dashboard" no-action :value="routeName.startsWith('dashboard')">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.dashboards_group') }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- Charts Manage -->
          <v-list-item exact :to="{ name: 'dashboards-manage' }">
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.manage') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Charts Manage -->
          <v-list-item exact :to="{ name: 'dashboard-details', params: { id: 'new' } }" v-if="!isDashboardOnlyUser">
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.new') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider />

        <!-- Account -->
        <v-list-item exact :to="{ name: 'account' }">
          <v-list-item-action>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Logout -->
        <v-list-item @click="logout(false)">
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div id="env-bar" v-if="!isProdEnv" />
    <div
      id="trial-bar"
      v-if="trialDaysRemaining !== undefined"
      :class="{ 'getting-tight': trialDaysRemaining <= 2 }"
      v-html="trialDaysRemaining > 1 ?
        $t('trial_remaining.n_days', { n: trialDaysRemaining }) :
        $t('trial_remaining.last_day')
      "
    />
    <v-app-bar app height="60" v-if="!user.isAnonymous" class="app-bar" :class="{
      'shift-lower-trial': trialDaysRemaining !== undefined,
      'shift-lower-env': !isProdEnv
    }"
    >
      <v-app-bar-nav-icon color="primary" class="app-bar__hamburger" id="mainMenuTrigger" @click="menuVisible = !menuVisible" />

      <div class="d-flex align-center mr-6">
        <router-link
          :to="{
            name: isDashboardOnlyUser ? 'dashboards-manage' : 'home'
          }"
        >
          <img class="pt-2" src="./assets/logo_notext.svg" alt="Logo caplena.com" width="50">
        </router-link>
      </div>

      <div class="ml-2 app-bar__title">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="p-0"
        >
          <template v-slot:divider>
            <v-icon style="font-size: 14px" class="mr-1 ml-1">
              mdi-arrow-right
            </v-icon>
          </template>
        </v-breadcrumbs>
      </div>

      <v-spacer />

      <NewAppLink
        class="mr-2"
      >
        <template #default="{ newAppUrl }">
          <a :href="newAppUrl" style="font-size: 14px" class="no-external-icon" target="_blank">
            <span class="d-flex align-center">
              {{ $t('discoverV3') }}
              <page-next-outline :size="16" class="d-flex align-center ml-1" />
            </span>
          </a>
        </template>
      </NewAppLink>
      <v-menu ref="i18nMenu">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" style="width: 40px; height: 40px;">
            {{ $i18n.locale }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item :class="{ 'v-list-item--active' : $i18n.locale === 'de' }"
                       @click="$root.$i18n.locale='de'"
                       color="primary"
          >
            Deutsch
          </v-list-item>
          <v-list-item :class="{ 'v-list-item--active' : $i18n.locale === 'en' }"
                       @click="$root.$i18n.locale='en'"
                       color="primary"
          >
            English
          </v-list-item>
        </v-list>
      </v-menu>

      <div id="headwayContainer" />

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon style="width: 40px; height: 40px">
            <v-icon>
              mdi-help-circle-outline
            </v-icon>
          </v-btn>
        </template>

        <div id="help-menu">
          <div class="header" v-text="$t('help.title')" />

          <div class="content">
            <v-btn v-if="pageTutorialID !== null"
                   @click="startTutorial"
                   outlined
                   small
                   color="secondary"
                   v-text="$t('help.tutorial')"
            />

            <v-btn href="https://caplena.com/docs/"
                   target="_blank"
                   outlined
                   small
                   color="secondary"
                   v-text="$t('help.help_center')"
            />

            <v-btn @click="openChat"
                   outlined
                   small
                   color="secondary"
                   v-text="$t('help.chat')"
            />

            <a class="email" href="mailto:support@caplena.com">support@caplena.com</a>
          </div>
        </div>
      </v-menu>

      <v-menu content-class="allow-overflow">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" data-private icon style="width: 40px; height: 40px;">
            <v-icon>
              mdi-account-circle-outline
            </v-icon>
            <!-- {{ user.first_name }} -->
          </v-btn>
        </template>

        <div>
          <v-list>
            <v-list-item exact :to="{ name: 'account' }">
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.profile') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-account-box</v-icon>
              </v-list-item-action>
            </v-list-item>

            <!-- Logout -->
            <v-list-item @click="logout(false)">
              <v-list-item-content>
                <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider />

          <div class="credits-left" v-if="!isDashboardOnlyUser">
            {{ $t('credits_remaining', { credits: userCreditsRemaining }) }}
            <helptip x-offset="-120px" :prevent-overflow="false">
              <div>{{ $t('helptip_credit_acctmenu') }}</div>
              <a :href="HELP_RESOURCE_CREDITS" target="_blank" v-text="$t('actions.more_information')" />
            </helptip>
          </div>
        </div>
      </v-menu>
    </v-app-bar>

    <v-main
      class="content-container"
      :class="{
        'shift-lower-trial': trialDaysRemaining !== undefined,
        'shift-lower-env': !isProdEnv
      }"
    >
      <v-container fluid style="height: 100%">
        <access-denied v-if="errorStatus === 403" />
        <page-not-found v-else-if="errorStatus === 404" />
        <router-view v-else />
      </v-container>
    </v-main>

    <v-dialog v-model="privacyPolicyAcceptance.show" width="800" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('privacy_policy.change_acceptance.title' ) }}
        </v-card-title>

        <v-card-text>
          <p class="subtitle-1"
             v-html="$t('privacy_policy.change_acceptance.intro', { href: `https://caplena.com/${$i18n.locale}/?privacy-policy` })"
          />

          <p v-html="privacyPolicyAcceptance.summary_changes" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="acceptPrivacyPolicy">
            {{ $t('privacy_policy.change_acceptance.accept' ) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="notification-container" v-if="websocketReady && !connectivity.websocket">
      <notification
        type="warning"
        text
        class="mb-0 mt-3 default-bs"
        border="left"
        title="Connection Problem"
        dense
        :closeable="true"
        max-width="400px"
      >
        <div>
          <b>Your computer</b> seems to <b>block connections</b> to the Caplena. Possible reasons:
        </div>
        <ul>
          <li>A <b>firewall or extension</b> is blocking Websocket requests to <code>api.caplena.com</code> <em>(likely)</em></li>
          <li>Caplena is currently experiencing technical difficulties <br><em>(unlikely, as other connections look fine)</em></li>
        </ul>
        <br><p>You can dismiss this dialog, but we will keep checking on this issue in the background and continue to nag you if things don't work. <b>Some parts of the application will not work correctly without websocket connections.</b></p>

        <div v-if="connectivity.api">
          What can you do to resolve this:
          <ul>
            <li>Try a different browser. If this works, you likely have an extension installed which blocks websocket traffic.</li>
            <li>Contact your IT department: There is probably a firewall installed on your machine, your coporate network or your router that <b>blocks websocket traffic</b>. Show this message to your IT department and ask them to unblock websocket traffic to <code>api.caplena.com</code> - websockets are only TCP connections too, nothing bad about them 🤷‍♂️😇.</li>
          </ul>
          <br><p>For more information contact our support team: <a href="mailto:support@caplena.com">support@caplena.com</a>.</p>
        </div>
      </notification>
    </div>

    <v-dialog v-model="connectivity.issue" width="800" :persistent="!connectivity.api">
      <v-card>
        <v-card-title class="headline grey lighten-2"
                      primary-title
        >
          Connection Problem
        </v-card-title>

        <v-card-text>
          <v-alert prominent type="error" style="margin-top: 24px">
            <template v-if="!connectivity.internet">
              Your computer seems to have issues <b>connecting to the internet</b>. <br>Please make sure you have a working network connection and then reload the page.
            </template>
            <template v-else-if="!connectivity.api">
              <div v-if="connectivity.maintenance" v-html="connectivity.maintenance" />
              <template v-else>
                We are having issues connecting to the Caplena servers. We might be in the process of updating our applications. Please try reloading the page in 1-2 minutes. If the problem persists, please contact <a href="mailto:support@caplena.com">support@caplena.com</a>.
              </template>
            </template>
          </v-alert>
        </v-card-text>

        <v-divider />

        <v-card-actions v-if="connectivity.api">
          <v-spacer />
          <v-btn outlined @click="connectivity.issue = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar bottom v-model="snackbarOpen" :timeout="snackbarDuration" class="snack-msg">
      <div class="text" v-html="snackbarMsg" />
      <!-- <svg
        @click="snackbarOpen=false"
        class="ml-2"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.41425 7.99976L12.7072 4.70676C13.0982 4.31576 13.0982 3.68376 12.7072 3.29276C12.3162 2.90176 11.6843 2.90176 11.2933 3.29276L8.00025 6.58576L4.70725 3.29276C4.31625 2.90176 3.68425 2.90176 3.29325 3.29276C2.90225 3.68376 2.90225 4.31576 3.29325 4.70676L6.58625 7.99976L3.29325 11.2928C2.90225 11.6838 2.90225 12.3158 3.29325 12.7068C3.48825 12.9018 3.74425 12.9998 4.00025 12.9998C4.25625 12.9998 4.51225 12.9018 4.70725 12.7068L8.00025 9.41376L11.2933 12.7068C11.4883 12.9018 11.7442 12.9998 12.0002 12.9998C12.2562 12.9998 12.5122 12.9018 12.7072 12.7068C13.0982 12.3158 13.0982 11.6838 12.7072 11.2928L9.41425 7.99976Z" fill="white" />
      </svg> -->
    </v-snackbar>

    <template v-slot:extension>
      <slot name="extension" />
    </template>
  </v-app>
</template>

<script>
import Vuex from 'vuex'
import PageNextOutline from 'vue-material-design-icons/PageNextOutline.vue'

import { store } from './store'
import { PLAN_TRIAL } from '@/settings/constants'
import { HELP_RESOURCE_CREDITS } from '@/settings/help-resources'
import IframeControls from '@/components/IframeControls.vue'
import NewAppLink from '@/components/NewAppLink.vue'

import { overrideSettings } from '@/settings/overridable'

import PageNotFound from '@/pages/PageNotFound'
import AccessDenied from '@/pages/AccessDenied.vue'

import Intercom from '@/mixins/intercom.js'
import PostHog from '@/mixins/posthog.js'
import LogRocket from 'logrocket'
import { Userpilot } from 'userpilot'

import { STATUS_JSON_URI } from '@/api.js'
const CONN_CHECK_MAX_RETRY = 3
const RELOAD_USER_INTERVAL = 5 * 60 * 1000

export default {
  codit: true,
  name: 'App',
  components: {
    'access-denied': AccessDenied,
    'page-not-found': PageNotFound,
    'iframe-controls': IframeControls,
    NewAppLink,
    PageNextOutline
  },
  mixins: [Intercom, PostHog],
  data () {
    return {
      toolbar: true,
      theme: 'default',
      snackbarMsg: '',
      snackbarOpen: false,
      snackbarDuration: 3000,
      userReady: false,
      menuVisible: false,
      nonUserMode: false,
      websocketReady: false,
      isEmbedded: false,

      privacyPolicyAcceptance: {
        show: false,
        summary_changes: ''
      },

      connectivity: {
        api: false,
        internet: false,
        websocket: false,
        issue: false,
        maintenance: ''
      },

      HELP_RESOURCE_CREDITS,
      caplenaRevampEnabled: false
    }
  },
  store,
  computed: {
    /**
     * If we're currently on the prod environment
     * @return {Boolean}
     */
    isProdEnv () {
      return process.env.VUE_APP_CAPLENA_ENV === 'prod'
    },

    /**
     * Return the number of days that remain on the free trial.
     * If the user is not on the free trial, return undefined
     * @return {Number,undefined}
     */
    trialDaysRemaining () {
      if (this.user.subscription.plan_id === PLAN_TRIAL) {
        let ends = this.$moment(this.user.subscription.end)
        ends.set({ hour: 1, minute: 0, second: 0 })
        let diff = ends.diff(this.$moment(), 'days') + 1
        return diff
      } else return undefined
    },

    routeName () {
      return this.$route.name || ''
    },

    routesByName () {
      let mapping = {}
      this.$router.options.routes.forEach(r => {
        mapping[r.name] = r
      })
      return mapping
    },

    breadcrumbs () {
      if (!this.$route.name) return []
      // Get the list of route names that are breadcrumbs to the current page
      let breadcrumbNames = this.$route.meta.breadcrumbs || []
      // Always add home and current route to the breadcrumbs
      let breadcrumbs = [...breadcrumbNames]
      if (this.$route.name !== 'home') breadcrumbs.push(this.$route.name)

      return _.map(breadcrumbs, bc => {
        // Create breadcrumb, with all relevant entries
        let bcMeta = this.routesByName[bc].meta
        let params = bcMeta.breadcrumbParams ? bcMeta.breadcrumbParams.bind(this)(this.$store.state.breadcrumbProps) : {}
        let query = bcMeta.breadcrumbQuery ? bcMeta.breadcrumbQuery.bind(this)(this.$store.state.breadcrumbProps) : {}
        return {
          text: this.$maybeTruncate(bcMeta.breadcrumbName.bind(this)(this.$store.state.breadcrumbProps) || '', 35),
          // If params returned false explicitely, then the link is not valid
          to: params === false ? undefined : { name: bc, params, query },
          exact: true
        }
      })
    },

    headerTitle () {
      if (this.$route.meta.title) {
        return this.$route.meta.title.bind(this)()
      }

      return this.breadcrumbs.length ? _.last(this.breadcrumbs).text : ''
    },

    /**
     * If the user is not in an organization or the root user of his organization
     * @return {Boolean}
     */
    isRootAccount () {
      return !this.user.organization || this.user.organization.root_user_id === this.user.id
    },

    isDashboardOnlyUser () {
      return this.user.is_dashboard_only
    },

    ...Vuex.mapState(['pageTitle', 'pageTutorialID', 'user', 'errorStatus']),
    ...Vuex.mapGetters(['userCreditsRemaining'])
  },
  watch: {
    '$i18n.locale' (val) {
      window.localStorage.setItem('language', val)
      window.language = val
      this.$moment.locale(val)
      moment.locale(val)
      if (val !== this.user.language) {
        // Save the language on the user's profile
        // When that request has complete, get the new language options
        this.$store.dispatch('updateUser', { language: val })
          .then(() => this.$router.go())
          .catch(err => this.$maybeRaiseAPIPromiseErr(err))
      }
    }
  },

  created () {
    // Detect if being run in some testing mode
    if (window.Cypress !== undefined) this.nonUserMode = true
    this.isEmbedded = window.self !== window.top

    this.initUser()

    this.checkConnectivity()

    // Load language options
    this.getLanguageOptions()
  },
  methods: {
    /**
     * Loads the language options and localized strings from the API
     */
    getLanguageOptions () {
      api.get('/api/constants/language-options').then(res => {
        // Nasty Error: Outlooks advanced threat protection will replce links in mails with a
        // safelinks.protection.outlook link. When clicking such a link, a request will be made
        // by a microsoft server to our server, but *without* the correct CORS headers.
        // The response will be empty, but still have a response code 200.
        // We can safely ignore that.
        if (res.status === 200 && !res.data) return

        if (!_.isObject(res.data.iso)) throw Error(`Expected translate languages key iso to be an object. Got data ${res.data}`)
        if (!_.isArray(res.data.caplena_supported)) throw Error('Expected translate languages key caplena_supported to be an array.')
        if (!_.isArray(res.data.google_translate)) throw Error('Expected translate languages key google_translate to be an array.')
        if (!_.isArray(res.data.deepl)) throw Error('Expected translate languages key deepl to be an array.')
        if (!_.isArray(res.data.ui)) throw Error('Expected translate languages key ui to be an array.')

        this.$store.commit('setLanguageOptions', res.data)
      }).catch(err => this.$maybeRaiseAPIPromiseErr(err))
    },

    initUser () {
      let deleteCSRFandLogout = () => {
        if (this.$router.allowAnonymous()) return
        console.error('Deleting CSRF cookie and logging out.')
        this.clearCookie('csrftoken')
        this.logout(true)
      }

      // Check if the csrftoken is valid, otherwise delete cookie and logout
      api.post('/api/csrftest', {}, { dontReport: [401, 403] }).then(res => {
        if (res.data !== 'ok') deleteCSRFandLogout()
      }).catch(err => {
        if (!err.response || err.response.status === 401 || err.response.status === 403) {
          console.error('CSRF test failed.')
          deleteCSRFandLogout()
          return
        }
        this.$maybeRaiseAPIPromiseErr(err)
      })

      // Get the login information of the user
      this.getUser().then(res => {
        this.$i18n.locale = res.data.language
        this.userReady = true

        if (res.data.privacy_policy_new) {
          this.privacyPolicyAcceptance.show = true
          this.privacyPolicyAcceptance.summary_changes = res.data.privacy_policy_new[`summary_changes_${this.$i18n.locale}`]
        }

        setInterval(this.getUser, RELOAD_USER_INTERVAL)

        // initial redirect for people not allowed to see all information
        // following-up handlers are in router.js
        if (
          this.isDashboardOnlyUser &&
          ['dashboards-manage', 'dashboard-details', 'account'].indexOf(this.$route.name) === -1
        ) {
          this.$router.replace({
            name: 'dashboards-manage'
          })
        }

        this.initHeadway()
        this.initPosthog()

        // Failing to load one of these services shouldn't lead to forwarding to homepage
        // Don't run any of those services locally
        if (process.env.VUE_APP_CAPLENA_ENV !== 'local') {
          this.initSentry()
          // this.initLogrocket()
        }
      }).catch(err => {
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error('Access denied during GET user.')
          if (this.$router.allowAnonymous()) {
            this.$store.commit('userIsAnonymous')
            this.initPosthog()
            if (process.env.VUE_APP_CAPLENA_ENV !== 'local') this.initUserpilot({ isAnonymous: true })
          } else window.location = this.getLogoutLocation(true)
        // If there hasn't been a proper status, something's wrong
        } else throw err
      })
    },

    /**
     * Get the path where a logout leads to. If the logout was due to an invalid session, pass additional
     * parameters indicating that and where the user came from
     * @param  {Boolean} sessionInvalid     If the logout is due to invalid session
     * @return {String}                     The location to redirect to
     */
    getLogoutLocation (sessionInvalid) {
      if (sessionInvalid) {
        // Redirect to new app in production login with go_to parameter
        if (this.isProdEnv) {
          return `https://app.caplena.com/?legacy=true&go_to=${encodeURIComponent(window.location.pathname + window.location.search)}`
        }

        // For all other enviroments keep old functionality
        return `/${this.$i18n.locale}/` + (sessionInvalid ? `?session_invalid&go_to=${encodeURIComponent(window.location.pathname + window.location.search)}` : '')
      }

      return `/${this.$i18n.locale}/`
    },

    /**
     * Get the current user and set his information in the app store
     * @return {Promise} The promise with the request
     */
    getUser () {
      return api.get('/api/ui/auth/user/', { dontReport: [401, 403] }).then(res => {
        console.log('res.data', res.data)
        this.$store.dispatch('setUser', res.data)
        this.$store.commit('setUserLoaded')
        _.forEach(res.data.app_settings, (val, key) => overrideSettings(key, val))
        return res
      }).catch(err => {
        this.$store.commit('setUserLoaded')
        throw err
      })
    },

    /**
     * Start the tutorial of the current page
     */
    startTutorial () {
      window.userpilot.trigger(this.pageTutorialID)
    },

    checkConnectivity () {
      let checkWebsocketConnectivityWithRetry = () => {
        this.checkWebsocketConnectivity().then(() => {
          // Halelujah, everything is good
          this.websocketReady = true
          this.connectivity.websocket = true
          console.log('Websocket connectivity successfully established')
        }).catch(() => {
          // Failed to establish websocket connection
          // Raise the issue and retry connecting every 60s
          // This should be enough of a nuisance to make the user
          // invstigate the problem
          this.websocketReady = true
          this.connectivity.websocket = false
          setTimeout(checkWebsocketConnectivityWithRetry, 5000)
        })
      }

      this.checkAPIConnectivity().then(() => {
        // API works, all good so far
        console.log('API connectivity successfully established')
        this.connectivity.internet = this.connectivity.api = true
        checkWebsocketConnectivityWithRetry()
      }).catch(err => {
        // API doesn't work
        console.error('API unavailable. Checking if internet is working at all')
        console.error(err)
        this.checkInternetConnectivity().then(() => {
          // Internet works, but there's still an issue with the API
          console.log('Internet OK')
          this.connectivity.internet = true
          this.connectivity.issue = true
        }).catch(err => {
          // Internet doesn't work at all
          // (at least, that's the assumption. But if both caplena and aws are not working,
          // there's a big chance the user doesn't have any connectivity)
          console.error('Internet unavailable')
          console.error(err)
          this.connectivity.issue = true
        })
      })
    },

    checkAPIConnectivity () {
      let retryCnt = 0
      return new Promise((resolve, reject) => {
        let get = () => {
          retryCnt++
          api.get('/api/readyz').then(res => {
            if (res.data === 'ok') resolve()
            else if (retryCnt < CONN_CHECK_MAX_RETRY) get()
            else reject(new Error(`Failed to connect to API. Response was ${res.data}`))
          }).catch(err => {
            if (retryCnt < CONN_CHECK_MAX_RETRY) get()
            else reject(err)
            console.error(err)
          })
        }
        get()
      })
    },

    checkInternetConnectivity () {
      let retryCnt = 0
      return new Promise((resolve, reject) => {
        let get = () => {
          retryCnt++
          api.get(STATUS_JSON_URI, { params: { ts: new Date().getTime() }, headers: { Pragma: 'no-cache' }, withCredentials: false }).then(res => {
            // Here we accept anything with a status code 200
            if (res.status === 200) {
              if (res.data.maintenance && res.data.maintenance.active) {
                this.connectivity.maintenance = res.data.maintenance[`msg_${this.$i18n.locale}`]
              }
              resolve()
            } else if (retryCnt < CONN_CHECK_MAX_RETRY) get()
            else reject(new Error(`Failed to connect to Internet. Response was ${res.data}`))
          }).catch(err => {
            if (retryCnt < CONN_CHECK_MAX_RETRY) get()
            else reject(err)
            console.error(err)
          })
        }
        get()
      })
    },

    checkWebsocketConnectivity () {
      let retryCnt = 0
      return new Promise((resolve, reject) => {
        let get = () => {
          retryCnt++

          ws.open(`ws/connectivity-check/`).then(socket => {
            // If there is no answer for 10 seconds, close the socket and retry
            let alreadyRetrying = false
            let answerTimeout = setTimeout(() => {
              socket.close()
              if (retryCnt < CONN_CHECK_MAX_RETRY) {
                alreadyRetrying = true
                get()
              } else reject(new Error())
            }, 5000)

            // Connection established
            socket.onappmessage = (data) => {
              // Got message from socket
              // It's a throwaway connection anyways, no need to keep it open
              socket.close()
              clearTimeout(answerTimeout)
              if ('polo' in data) resolve()
              // Something's not right
              else {
                console.error(`WS connectivity check received invalid response: ${event.data}`)
                if (retryCnt < CONN_CHECK_MAX_RETRY) {
                  alreadyRetrying = true
                  get()
                } else reject(new Error())
              }
            }

            socket.onclose = (event) => {
              // Socket can be closed either cleanley or not
              // Make sure to not call the get method from multiple places
              clearTimeout(answerTimeout)
              if (!event.wasClean && !alreadyRetrying) {
                if (retryCnt < CONN_CHECK_MAX_RETRY) {
                  alreadyRetrying = true
                  get()
                } else reject(new Error())
              }
            }

            // Send dict with key marco
            // expect answer with key polo
            // then all is good
            socket.send(JSON.stringify({ marco: true }))
          }).catch(({ event, err }) => {
            // Failed to connect at all
            // Retry a few times
            if (retryCnt < CONN_CHECK_MAX_RETRY) get()
            else {
              console.warn(event)
              console.error(err)
              reject(new Error())
            }
          })
        }
        get()
      })
    },

    acceptPrivacyPolicy () {
      this.privacyPolicyAcceptance.show = false
      api.post('/api/privacy-policy-accept-now/', {}).then(res => {
        this.$store.dispatch('updateUser', { privacy_policy_accepted: res.data.accepted, dontPatch: true })
      }).catch(err => this.$maybeRaiseAPIPromiseErr(err))
    },

    initLogrocket () {
      if (this.nonUserMode) return
      if (!this.user.isAnonymous && this.user.user_settings.opt_out_tracking) return

      const logrocketEnvIdentifiers = { stage: 'ezamhm/caplena-stage', prod: 'ezamhm/codit-prod' }
      if (process.env.VUE_APP_CAPLENA_ENV in logrocketEnvIdentifiers) {
        LogRocket.init(logrocketEnvIdentifiers[process.env.VUE_APP_CAPLENA_ENV], {
          network: {
            requestSanitizer: request => {
              // scrub out request body
              request.body = null
              return request
            },
            responseSanitizer: response => {
              // scrub out response body
              response.body = null
              return response
            }
          }
        })
        LogRocket.identify(this.user.id, { id: this.user.id })
      }
    },

    initHeadway () {
      if (this.nonUserMode) return
      let script = document.createElement('script')
      let headwayConfig = {
        selector: '#headwayContainer',
        account: '720Aoy',
        enabled: true,
        callbacks: {
          onWidgetReady: (widget) => {
            this.$store.commit('setHeadwayNews', widget.getChangelogs())
          }
        }
      }
      script.async = true
      script.src = '//cdn.headwayapp.co/widget.js'
      script.onload = v => { window.Headway.init(headwayConfig) }
      document.body.appendChild(script)
    },

    initPosthog () {
      this.posthogInit({ identity: this.user.id,
        disableSessionRecording: false,
        onFeatureFlags: () => {
          if (process.env.VUE_APP_CAPLENA_ENV !== 'local') this.initUserpilot({ isAnonymous: false })

          // Don't run any of those services locally
          if (
            process.env.VUE_APP_CAPLENA_ENV !== 'local' &&
          this.$root.posthogFlagEnabled('intercom', true)
          ) {
            this.loadIntercom()
          }
        }
      })
    },

    initUserpilot ({ isAnonymous }) {
      if (this.nonUserMode) return

      Userpilot.initialize('7gu8n9')
      if (isAnonymous) {
        Userpilot.anonymous()
      } else {
        Userpilot.identify(
          `${process.env.VUE_APP_CAPLENA_ENV}_${this.user.id}`,
          {
            id: this.user.id,
            environment: process.env.VUE_APP_CAPLENA_ENV,
            organization_id: String(this.user.organization?.id),
            created_at: moment(this.user.date_joined).unix(),
            plan_id: this.user.subscription.plan_id,
            plan_name: this.user.subscription.plan_name,
            language: this.user.language
          }
        )
      }
    },

    initSentry () {
      this.$Sentry.configureScope((scope) => {
        console.log('this.user', this.user)
        scope.setUser({
          id: this.user.id,
          email: this.user.email
        })
      })
    },

    clearCookie (name) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
    },

    snackMsg (msg) {
      this.snackbarMsg = msg
      this.snackbarOpen = true
    },

    /**
     * Try opening the Intercom Chat window, if it is loaded
     */
    openChat () {
      if (window.Intercom) window.Intercom('show')
    },

    on403 () {
      // Access denied -> We init the user again, if this failed the user is redirected to the home page
      this.initUser()
      // Otherwise we display the access denied page
      this.$store.commit('setErrorStatus', 403)
    },

    on404 () {
      this.$store.commit('setErrorStatus', 404)
    },

    logout (sessionInvalid = false) {
      this.intercomLogout()
      this.posthogReset()
      api.post('/api/ui/auth/logout').then(res => {
        window.location = this.getLogoutLocation(sessionInvalid)
      }).catch(err => {
        window.location = this.getLogoutLocation(sessionInvalid)
        this.$maybeRaiseAPIPromiseErr(err)
      })
    },

    hasPermission (permission, obj) {
      if (this.user.isAnonymous || this.user.id === '') return false
      if (this.user.organization === null) return true
      if (this.user.view_permissions && this.user.view_permissions[permission]) return true
      if (this.user.organization.root_user_id === this.user.id) return true
      if (obj !== undefined && obj.owner_id === this.user.id) return true
      if (obj !== undefined && 'permissions' in obj && obj.permissions[permission]) return true
      return false
    }
  }
}
</script>

<style lang=scss>

@import '~@/css/userpilot';
@import '~@/css/page-backgrounds';

a:not(.v-btn):not(.no-external-icon)[target="_blank"] {
    padding-right: 1em;
    position: relative;
    white-space: nowrap;
    &:after {
      font-family: "Material Design Icons";
      content: "\F03CC";
      position: absolute;
      text-decoration: none;
      margin-left: 0.1em;
      font-size: smaller;
      line-height: 100%;
      top: 50%;
      margin-top: -0.5em;
    }
}

.v-alert.info--text {
  a:not(.v-btn) {
    color: var(--v-accent-base);
  }
}

.v-alert.flex-center .v-alert__content {
  display: flex;
  align-items: center;
}

div:not(.v-alert).flex-center {
  display: flex;
  align-items: center;
}

button:focus, button::-moz-focus-inner {
  outline: none!important;
}

/* Fix for https://github.com/vuetifyjs/vuetify/issues/9130 */
.v-card {
  @extend .default-bs;
  /* border: none !important; */
}

.v-card__text, .v-card__title {
  word-break: normal!important;
}

#help-menu {
  .header {
    padding: 8px 12px;
    background: #EEE;
    font-weight: bold;
  }
  .content {
    margin: 8px 12px;
    display: flex;
    flex-direction: column;
  }
  .v-btn {
    width: auto;
    margin: 4px 0;
  }
  a.email {
    display: block;
    text-align: center;
    margin: 4px 0;
    // margin-bottom: -7px;
    font-size: smaller;
  }
}

.theme--light.v-card {
  &, .v-card__text { color: rgba(0,0,0,.87)!important }
}

.v-app-bar .v-breadcrumbs {
  li.v-breadcrumbs__divider {
    padding: 0 3px;
  }

  a {
    line-height: 1.3;
    color: #708189;
    font-size: 14px;
  }

  a.v-breadcrumbs__item--disabled {
    color: #05202E;
    /* font-weight: 500; */
    /* border-bottom: 2px solid var(--v-primary-base); */
  }
}

.main-nav {
  .v-list-item__title { font-size: 14px!important }
}

/* @import '~cssvars'; */

.main-content {
  margin: 5px 8px 80px;
  /* margin: 16px 8px 0px; */
}

.hopscotch-content {
  line-height: 1.5!important;
}

a:hover { text-decoration-color: inherit }
a, .v-list a, .code-select a, .label-select a {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.15s ease-in-out;
}

.v-menu__content.allow-overflow {
  overflow: visible;
  contain: none;
}

.v-dialog.allow-overflow {
  overflow: visible;
}

.v-toolbar__content {
  i {
    color: $grey-color !important;
  }
}

.v-dialog > .v-card > .v-card__text {
  padding-top: 12px!important;
}

.v-data-table td {
  font-size: 13px;
}

html {
  min-width: 1024px;
  overflow: auto !important;
}

.v-menu__content { background: #FFF }

.credits-left {
  font-size: 14px;
  padding: 4px 16px;
  white-space: normal;
  color: #0e5d88;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 3px;
}

ul { list-style-type: square; }

.highlight-text {
  &:not(.hljs) {
    background: rgba(255, 229, 127, 0.2)!important;
    color: #ff8f00!important;
    margin-left: 1px;
    margin-right: 1px;
    padding: 0 4px;
    display: inline-block;
    border-radius: 2px;
    font-family: "Operator Mono", "Fira Code", Menlo, Hack, "Roboto Mono", "Liberation Mono", Monaco, monospace;

    pre {
      margin: 8px 0;
    }
  }
}

#headwayContainer { width: 32px; height: 32px; margin-right: 3px; }
#HW_badge { background-color: #ff5252!important }
#HW_badge.HW_softHidden { background: $grey-color !important; }

.beta-burst {
    background: #ff5252;
    width: 35px;
    height: 35px;
    position: absolute;
    text-align: center;
    display: inline;
    margin-left: 35px;
    margin-top: -8px;
    cursor: default;
    span {
      position: absolute;
      z-index: 20;
      left: 2px;
      top: 6px;
      color: #FFF;
      font-size: 16px;
      font-weight: 500;
    }
}
.beta-burst:before, .beta-burst:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    background: #ff5252;
}
.beta-burst:before {
    transform: rotate(30deg);
}
.beta-burst:after {
    transform: rotate(60deg);
}

/* Fix vuetify issue https://github.com/vuetifyjs/vuetify/issues/4473 */
.v-input.v-text-field .v-label:not(.v-label--active) {
    cursor: text;
}

.label {
  border-radius: 3px;
  margin: 0 2px;
  padding: 2px 4px;
  font-family: Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  background: #999;
  line-height: 19px;
}

.c-alert.retry {
  display: flex;
  > div { display: flex }
  .text { margin: auto 0; flex: 1; }
  .v-btn { flex: 0 0 auto }
}

.sentiment-doughnut {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  transform: rotate(45deg);
  border-style: solid;
  border-width: 8px;
  margin-top: 2px;
  transition: border-color 0.25s ease-in-out;
  border-color: #BBB;

  &.negH {
    border-bottom-color: #FF5252;
    border-left-color: #FF5252;
  }
  &.negF {
    border-color: #FF5252;
  }
  &.posH {
    border-right-color: #4CAF50;
    border-top-color: #4CAF50;
  }
  &.posF {
    border-color: #4CAF50;
  }
}

.select-menu-with-helptips {
  overflow: visible!important;
  contain: none!important;
  .helptip {
    pointer-events: auto!important;
  }
}

.block-el {
  box-shadow: 0 0.25rem .5rem rgba(48,55,66,.15);
  border: 1px solid $border-color !important;
}

.no-scroll {
  height: 100%;
  overflow: hidden!important;
}

.snack-msg .v-snack__content {
  display: flex;
  align-items: center;
  .text {
    flex: 1;
    font-size: 16px;
  }
  .v-btn {
    margin-left: 16px;
    background: #444;
  }
}

#trial-bar {
  position: fixed;
  z-index: 5;
  border-bottom: 1px solid #E9FCF7;
  background: #00c58d;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 100%;
  font-size: 14px;

  &.getting-tight {
    background: $col-strong-orange;
  }
}

#env-bar {
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
}

#env-bar ~ #trial-bar {
  top: 5px;
}

header.shift-lower-trial {
  transform: translateY(27px)!important;
}
header.shift-lower-env {
  transform: translateY(5px)!important;
}
header.shift-lower-trial.shift-lower-env {
  transform: translateY(33px)!important;
}

main.content-container {
  /* background-color: $light-blue; */
  /* Default padding is 60px */
  &.shift-lower-env {
    padding-top: 65px !important;
  }
  &.shift-lower-trial {
    padding-top: 90px !important;
  }
  &.shift-lower-trial.shift-lower-env {
    padding-top: 95px !important;
  }
}

.app-bar {
  box-shadow: 0px 8px 32px rgba(14, 93, 136, 0.05) !important;
  background: white !important;

  .v-toolbar__content {
    padding: 4px 18px;
  }

  &__hamburger i {
    font-size: 25px !important;
  }

  &__title {
    h1 {
      font-size: 22px;
      font-weight: 600;
      line-height: 1;
    }
  }
}

</style>
<i18n locale='en' src='@/i18n/en/AppPage.json' />
<i18n locale='de' src='@/i18n/de/AppPage.json' />