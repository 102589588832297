import {
  FILTER_OPTIONS_TYPES
} from '@/settings/constants'

const EMPTY_FILTERS_FALLBACK_VALUE = [{}]
const { DASHBOARD_FILTERS } = FILTER_OPTIONS_TYPES

const FILTERS_SCAFFOLD = {
  filters: [{
    invert: false,
    type: null,
    value: null
  }],
  filterEditorIdx: null,
  filterQueryParams: false,
  mockFilterValue: undefined
}

export default {
  data () {
    return {
      dashboardFilters: _.cloneDeep(FILTERS_SCAFFOLD)
    }
  },

  computed: {
    /**
     * Is an existing dashboard
     */
    isSaved () {
      return this.$route.params.id && this.$route.params.id !== 'new'
    },

    /**
     * If adding filters is disabled
     */
    addFiltersDisabled () {
      return this.dashboardFilters.filters.length >= 3 || this.filtersDisabled
    },

    /**
     * If editing filters is disabled
     */
    filtersDisabled () {
      return !this.isSaved || !this.dashboardElements.length || this.isDirty
    },

    /**
     * compute possible fiter options to use inside dashboard
     */
    dashboardFiltersAddOptions () {
      return this.filterOptionsSelect(this.dashboardFilters.filters, this.getAuxiliaryOptions(this.sharedAuxilaryColumnNames), DASHBOARD_FILTERS)
    },

    /**
     * memoized filter indexes has warning
     */
    filterIndexesWithWarning () {
      return _.flattenDeep(
        _.map(
          _.filter(this.dashboardElements, el => el.warning),
          elWithWarning => _.map(
            elWithWarning.warning,
            ({ invalid_params: invalid }) => _.map(invalid, (x) => {
              return x.param
            })
          )
        )
      )
    },

    filterEditorProps () {
      return {
        isDashboardFilter: true,
        auxiliaryColumnNames: this.sharedAuxilaryColumnNames,
        auxiliaryTypes: this.auxiliaryTypes,
        allFilters: this.dashboardFilters.filters,
        value: this.dashboardFilters.filters[this.dashboardFilters.filterEditorIdx]
      }
    },

    /**
     * filtersGate
     */
    filtersGate: {
      // get filters with index(=idx) and warnings (=hasWarning)
      get () {
        return this.dashboardFilters.filters.map((filter, idx) => ({
          ...filter,
          idx,
          hasWarning: this.filterIndexesWithWarning.includes(idx)
        }))
      },
      // set filters directly
      set (filters) {
        this.$set(this.dashboardFilters, 'filters', filters)
      }
    },

    /**
     * Label for actively selected filter
     */
    activeFilterLabel () {
      if (this.dashboardFilters.filterEditorIdx === null) return ''
      return this.$t(`ds.filters.labels.${this.dashboardFilters.filters[this.dashboardFilters.filterEditorIdx].type}`)
    }
  },

  watch: {
    async '$route.params.id' () {
      this.dashboardFilters = _.cloneDeep(FILTERS_SCAFFOLD)
    }
  },

  methods: {
    /**
     * Add a new dashboard filter and open it's editor
     */
    handleFilterAdd (newFilterType) {
      // reset v-select value, in order to always show '+ add filter'
      this.dashboardFilters.mockFilterValue = newFilterType
      this.$nextTick(() => { this.dashboardFilters.mockFilterValue = '' })

      const { type, value } = this.initializeNewFilter(newFilterType, this.auxiliaryTypes)
      const newFilter = { type, value, invert: false }

      if (!this.dashboardFilters.filters[0] || this.dashboardFilters.filters[0].type === null) {
        this.$set(this.dashboardFilters.filters, 0, newFilter)
        this.openFilterEditor(0)
      } else {
        this.openFilterEditor(this.dashboardFilters.filters.push(newFilter) - 1)
      }
    },

    handleDialogFilterApply (val) {
      if (val) this.$set(this.dashboardFilters.filters, this.dashboardFilters.filterEditorIdx, val)
      this.handleFilterApply()
    },

    /**
     * Update query params passed into dashbaord elements with current filters
     */
    handleFilterApply (redirect = true) {
      this.dashboardFilters.filterEditorIdx = null
      this.modalsShown.filters = false

      if (this.dashboardFilters.filters.length === 0) {
        this.dashboardFilters.filters = EMPTY_FILTERS_FALLBACK_VALUE
      }
      if (!_.isEqual(this.dashboardFilters.filterQueryParams, this.chartValuesParams)) {
        this.dashboardFilters.filterQueryParams = this.chartValuesParams

        if (redirect) {
          this.$router.replace({
            name: this.$router.currentRoute.name,
            params: this.$router.currentRoute.params,
            query: {
              ...this.$router.currentRoute.query,
              ...this.chartValuesParams
            }
          })
        }
      }
    },

    /**
     * Close the dashboard filter editor and set the filters to the previously selected ones
     */
    handleFilterClose () {
      this.dashboardFilters.filterEditorIdx = null
      this.modalsShown.filters = false
      this.dashboardFilters.filters = this.$route.query.filters ? this.filtersTransformedFromQueryParams(JSON.parse(_.cloneDeep(this.$route.query.filters)))
        : EMPTY_FILTERS_FALLBACK_VALUE
    },

    /**
     * Removes selected filter by index
     */
    handleFilterRemove () {
      this.$delete(this.dashboardFilters.filters, this.dashboardFilters.filterEditorIdx)
      this.handleFilterApply()
    },

    /**
     * Open v-dialog of filter to edit, if there is no warning and filters are enabled
    */
    openFilterEditor (filterIdx) {
      if (this.filtersDisabled || this.filterIndexesWithWarning.includes(filterIdx)) return
      this.dashboardFilters.filterEditorIdx = filterIdx
      this.modalsShown.filters = true
    },

    /**
     * Because the backend expects the auxiliary filters to have values as names (instead of col idx) when saved in query params,
     * the filters that are saved need to be transformed back in a way that can be understood by the components
     */
    filtersTransformedFromQueryParams (filters) {
      return _.map(filters, filter => {
        if (filter.type === 'auxiliary') {
          const col = _.findIndex(this.sharedAuxilaryColumnNames, name => name === filter.value.col)

          return {
            ...filter,
            value: {
              ...filter.value,
              col: col === -1 ? filter.value.col : col
            }
          }
        }

        return filter
      })
    }
  }
}