import { render, staticRenderFns } from "./DashboardsManage.vue?vue&type=template&id=5af1f24e&scoped=true"
import script from "./DashboardsManage.vue?vue&type=script&lang=js"
export * from "./DashboardsManage.vue?vue&type=script&lang=js"
import style0 from "./DashboardsManage.vue?vue&type=style&index=0&id=5af1f24e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5af1f24e",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/pages/DashboardList.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fpages%2FDashboardsManage.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/pages/DashboardList.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Fpages%2FDashboardsManage.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)

export default component.exports