// source https://stackoverflow.com/a/57194342

import { mapGetters } from 'vuex'

/**
 * It allows to call many getters with arguments
 * Arguments is calculatable
 *
 * Example #1
 * ...mapGettersWithKey
 * ({ preventLoadFromListUpdate: 'verbatimManager/preventLoadFromListUpdate'})
 * (function () { return this.id }),
 *
 * Example #2
 * ...mapGettersWithKey
 * ({ preventLoadFromListUpdate: 'verbatimManager/preventLoadFromListUpdate'})
 * (() => 'stringId'),
 *
 * @param {Array} getters
 * @param {Function} args
 * @returns {Array}
 *
 * Example #3
 * ...mapGettersWithKey
 * (['verbatimManager/preventLoadFromListUpdate'])
 * (() => 'stringId'),
 *
 * @param {Array|Object} getters
 * @param {Function} args
 * @returns {Array}
 */
export const mapGettersWithKey = (originalGetters) => (args) => {
  const getters = mapGetters(originalGetters)
  const entries = Object.entries(getters)
  const initialAccumulator = {}
  return entries.reduce((acc, [getterName, getterHandler]) => {
    acc[getterName] = (state) => {
      return typeof state.$store.getters[originalGetters[getterName]] !== 'undefined' ? getterHandler.call(state)(args.call(state)) : undefined
    }
    return acc
  }, initialAccumulator)
}

/**
 * It allows to attach many getters and won't throw errors if no getter exists
 * Interface is the same as for Vuex.mapGetters
 *
 * @param {Array|Object} getters
 * @returns {Array}
 */
export const mapGettersSilently = (originalGetters) => {
  const getters = mapGetters(originalGetters)
  const entries = Object.entries(getters)
  const initialAccumulator = {}
  return entries.reduce((acc, [getterName, getterHandler]) => {
    acc[getterName] = (state) => {
      return typeof state.$store.getters[getterName] !== 'undefined' ? getterHandler.call(state) : undefined
    }
    return acc
  }, initialAccumulator)
}

/**
 * It allows to call a getter with arguments and deconstruct it
 * Arguments is calculatable
 *
 * @param {String} getterName
 * @param {Function} args
 * @param {Array} keys
 * @returns {Array}
 */
export const getterWithKeyDeconstructed = (getterName) => (args) => (keys) => {
  const getters = mapGetters([getterName])

  return Object.entries(keys).reduce((acc, [key, keyToGet]) => {
    const propertyName = Array.isArray(keys) ? keyToGet : key
    acc[propertyName] = (state) => {
      return typeof state.$store.getters[getterName] !== 'undefined' &&
        args.call(state)
        ? getters[getterName].call(state)(args.call(state))[keyToGet]
        : undefined
    }
    return acc
  }, {})
}

/**
 * It allows to call a getter, get data by path and deconstruct it
 * Arguments is calculatable
 *
 * @param {String} getterName
 * @param {Function} args
 * @param {Array} keys
 * @returns {Array}
 */
export const getterDeconstructed = (getterName) => (path) => (keys) => {
  const getters = mapGetters([getterName])

  if (
    typeof keys === 'function'
  ) {
    keys = keys()
  }

  return Object.entries(keys).reduce((acc, [key, keyToGet]) => {
    const propertyName = Array.isArray(keys) ? keyToGet : key
    acc[propertyName] = (state) => {
      return _.get(getters[getterName].call(state), path.call(state))[keyToGet]
    }
    return acc
  }, {})
}